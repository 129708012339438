import React, { FC, memo, useState, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { get } from 'lodash/fp'
import styled from 'styled-components/macro'
import { addMinutes, formatISO, isFuture, parseISO, startOfMinute } from 'date-fns'

import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'
import { Form, FormRow } from '../../../components/Form'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { localeContext } from '../../../context/locale'
import SwitchField from '../../../components/SwitchField'
import FormField from '../../../components/FormField'
import { pastDate } from '../../../utils/calendar'

const DISABLED_DATES = [pastDate]

const PreWrap = styled.div`
  white-space: pre-wrap;
`

interface IEventWorkflowCancelModalProps {
  event: {
    name: string | null
    venueName: string | null
    date: string | null
    timezoneName: string | null
    primaryVenue?: { value: string; label: string | null } | null
    venues: Array<{ value: string; label: string | null } | null> | null
  }
  onClose: () => void
  onSave: (values: { notifyAt: string | null }) => Promise<void>
}

const EventWorkflowCancelModal: FC<React.PropsWithChildren<IEventWorkflowCancelModalProps>> = ({
  event,
  onClose,
  onSave,
}) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const [saving, setSaving] = useState(false)

  const [notifyAt, setNotifyAt] = useState<string | null>(() => formatISO(addMinutes(startOfMinute(new Date()), 1)))
  const updateNotifyAt = useCallback((_: any, value: any) => setNotifyAt(value), [])

  const [notify, setNotify] = useState(true)
  const toggleNotify = useCallback(() => setNotify((v) => !v), [])

  const venueName = get('primaryVenue.label', event) || event.venueName || get(['venues', 0, 'label'], event)

  const clickSave = useCallback(() => {
    setSaving(true)
    onSave({ notifyAt: notify ? notifyAt : null }).finally(() => {
      setSaving(false)
    })
  }, [notify, notifyAt, onSave])

  const isValid = useMemo(() => {
    if (!notify) return true

    return !!notifyAt && isFuture(parseISO(notifyAt))
  }, [notify, notifyAt])

  return (
    <Modal closeButton onClose={onClose} modalTitle={intl.formatMessage({ id: 'event_workflow.cancel_event' })}>
      <ModalBody>
        <Form>
          <FormRow>
            <PreWrap>
              {intl.formatMessage(
                { id: 'event_workflow.cancel_modal.prompt' },
                {
                  event: event.name || 'Untitled',
                  location: venueName,
                  // prettier-ignore
                  date: event.date
                    ? intl.formatDate(event.date, {
                      ...DATETIME_FORMATS.LONG(locale),
                      timeZone: event.timezoneName,
                    })
                    : intl.formatMessage({ id: 'na' }),
                }
              )}
            </PreWrap>
          </FormRow>
          <FormRow>
            <SwitchField
              label={intl.formatMessage({ id: 'event_workflow.cancel_modal.notify_fans.label' })}
              hint={intl.formatMessage({ id: 'event_workflow.cancel_modal.notify_fans.hint' })}
              checked={notify}
              onChange={toggleNotify}
            />
          </FormRow>
          {notify && (
            <FormRow>
              <FormField
                name="notifyAt"
                timezone={event.timezoneName || undefined}
                label={intl.formatMessage({ id: 'event_workflow.cancel_modal.notify_at.label' })}
                hint={intl.formatMessage({ id: 'event_workflow.cancel_modal.notify_at.hint' })}
                control="datetime"
                value={notifyAt}
                setFieldValue={updateNotifyAt}
                error={!isValid && ' '}
                locale={locale}
                required
                disabledDates={DISABLED_DATES}
              />
            </FormRow>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl color="error" data-id="confirm" disabled={!isValid} onClick={clickSave} loading={saving}>
          {intl.formatMessage({ id: 'event_workflow.cancel_modal.cta' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" data-id="reject" onClick={onClose} disabled={saving}>
          {intl.formatMessage({ id: 'event_workflow.cancel_modal.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(EventWorkflowCancelModal)
