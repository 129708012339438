/**
 * @generated SignedSource<<64c1806e9cca05fc62c3de6f5cf2347a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventPartDraftProgress_event$data = {
  readonly completedSteps: number | null;
  readonly id: string;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly " $fragmentType": "EventPartDraftProgress_event";
};
export type EventPartDraftProgress_event$key = {
  readonly " $data"?: EventPartDraftProgress_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartDraftProgress_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventPartDraftProgress_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedSteps",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "299fa356507ae5420f91d404333e8625";

export default node;
