import React, { FC, useCallback, useState, useMemo, useEffect, memo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import { getOr, find } from 'lodash/fp'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { useIntl } from 'react-intl'
import FormField from '../../../components/FormField'
// eslint-disable-next-line max-len
import { EventTicketTypeSeatingAreaTypeSelectQuery } from '../../../__generated__/EventTicketTypeSeatingAreaTypeSelectQuery.graphql'
import { ITicketType } from '../types/Tickets'
import { isNew } from '../../../utils/entityStatus'

const EventTicketTypeSeatingAreaTypeSelect: FC<
  React.PropsWithChildren<{
    disabled?: boolean
    values: ITicketType
    setFieldValue: (name: string, val: any) => void
    handleBlur: (e: Event) => void
    touched: FormikTouched<FormikValues>
    errors: FormikErrors<FormikValues>
    validateForm: () => void
  }>
> = ({ values, setFieldValue, handleBlur, touched, errors, disabled, validateForm }) => {
  const intl = useIntl()
  const environment = useRelayEnvironment()

  const onChange = useCallback(
    (id: any) => {
      setFieldValue('attractiveSeatingAreaType', id)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, validateForm]
  )

  const [attractiveSeatingAreaTypeOptions, setAttractiveSeatingAreaTypeOptions] = useState([])
  useEffect(() => {
    let stillActive = true

    const query = graphql`
      query EventTicketTypeSeatingAreaTypeSelectQuery {
        viewer {
          externalEntities(entityType: "seating_area_type", integrationType: "attractive") {
            label: name
            value
          }
        }
      }
    `

    fetchQuery_DEPRECATED<EventTicketTypeSeatingAreaTypeSelectQuery>(environment, query, {}).then((data) => {
      if (stillActive) {
        const arr = getOr([], 'viewer.externalEntities', data)
        setAttractiveSeatingAreaTypeOptions(arr)
      }
    })

    return () => {
      stillActive = false
    }
  }, [environment])

  const attractiveSeatingAreaTypeOption = useMemo(
    () => find(['value', values.attractiveSeatingAreaType], attractiveSeatingAreaTypeOptions),
    [attractiveSeatingAreaTypeOptions, values.attractiveSeatingAreaType]
  )

  return (
    <FormField
      name="attractiveSeatingAreaType"
      label={intl.formatMessage({ id: 'new_event.tickets.seating_type.label' })}
      control="select"
      value={attractiveSeatingAreaTypeOption}
      options={attractiveSeatingAreaTypeOptions}
      onChange={onChange}
      onBlur={handleBlur}
      error={touched.attractiveSeatingAreaType || !isNew(values) ? errors.attractiveSeatingAreaType : null}
      disabled={disabled}
      required
    />
  )
}

export default memo(EventTicketTypeSeatingAreaTypeSelect)
