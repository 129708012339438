import React, { FC, memo, useMemo, useState, useEffect, useCallback, useContext, Fragment } from 'react'
import { useIntl, IntlShape } from 'react-intl'
import {
  map,
  get,
  getOr,
  compose,
  find,
  isNil,
  isArray,
  compact,
  concat,
  isNaN,
  isObject,
  sortBy,
  isEmpty,
  keyBy,
  reject,
} from 'lodash/fp'
import styled from 'styled-components/macro'
import { createFragmentContainer, fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { parseISO, parse } from 'date-fns'
import { FormikErrors, yupToFormErrors } from 'formik'
import { MarkOptional } from 'ts-essentials'
import { sanitize } from 'dompurify'

import { Modal, ModalBody, ModalFooter, ModalFooterControl } from './Modal'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'
import { EventReviewModal_event$data } from '../__generated__/EventReviewModal_event.graphql'
import { EventReviewModalScheduleQuery } from '../__generated__/EventReviewModalScheduleQuery.graphql'
import { localeContext } from '../context/locale'
import { ILocale } from '../intl'
import { color } from '../utils/variables'
import { CURRENCY } from '../utils/formatters/number'
import { trackingContext } from '../context/tracking'
import unwrapId from '../utils/unwrapId'
import { getNameByAlpha2 } from '../utils/countries'
import { EventType } from '../enums.generated'
import { Form, FormRow } from './Form'
import FormGroup from './FormGroup'
import EventFormErrors, { renderErrorValue } from '../flows/EventForm/components/EventFormErrors'
import IEventForm from '../flows/EventForm/types'
import EventSchema from '../flows/EventForm/validation/Event'
import { authContext } from '../context/auth'
import useEventFormErrors from '../flows/EventForm/hooks/useEventFormErrors'
import Danger from './Danger'
import checkOverallocation from '../utils/checkOverallocation'
import RelayLoader from './RelayLoader'
import checkCurrencyMismatch from '../utils/checkCurrency'
import useMaxSeatsCapacity from '../flows/EventForm/hooks/useMaxSeatsCapacity'
import { isValidDate } from '../utils/calendar'
import usePreviewEventOnDiceCallback from '../utils/hooks/usePreviewEventOnDiceCallback'
import { markdownToHtml } from '../utils/markdown'

const EVENT_SCHEDULE_QUERY = graphql`
  query EventReviewModalScheduleQuery(
    $announceDate: Time!
    $onSaleDate: Time!
    $offSaleDate: Time!
    $date: Time!
    $endDate: Time!
    $frequency: RepeatFrequency!
    $repeatEnds: RepeatEnds!
    $occurrences: Int
    $until: Time
    $timezoneName: String
    $repeatOn: RepeatOn
  ) {
    viewer {
      schedule: recurrentEventsSchedule(
        announceDate: $announceDate
        onSaleDate: $onSaleDate
        offSaleDate: $offSaleDate
        date: $date
        endDate: $endDate

        frequency: $frequency
        repeatEnds: $repeatEnds
        occurrences: $occurrences
        until: $until
        repeatOn: $repeatOn
        timezoneName: $timezoneName
      ) {
        date
      }
    }
  }
`

const FieldList = styled.dl`
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;

  dt {
    width: 144px;
    min-height: 17px;

    margin: 0;
    padding: 0;

    font-weight: bold;
    text-align: right;
  }

  dd {
    min-height: 17px;

    flex: 1;
    flex-basis: calc(100% - 250px);

    margin: 0 0 16px 32px;
    padding: 0;

    overflow: hidden;
    text-overflow: ellipsis;

    & > div {
      margin-bottom: 16px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }

    &:last-child {
      margin: 0 0 0 32px;
    }

    ol {
      list-style: inside decimal;
      padding-left: 8px;
      margin: 8px 0;
    }

    ul {
      list-style: inside disc;
      padding-left: 8px;
      margin: 8px 0;
    }
  }
`

const Free = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: ${color.primary};
`

const ErrorSpan = styled.span`
  color: ${color.error};
`

interface IProps {
  id: string
  event: EventReviewModal_event$data
  onClose?: () => void
  onSave?: (id: string, event: EventReviewModal_event$data) => Promise<void>
}

interface IDetailRow {
  label: string
  getFn: (
    data: EventReviewModal_event$data,
    intl: IntlShape,
    recurrentData: string[] | null,
    locale: ILocale
  ) => string[] | string | null
}

const formatDate = (d: string | null | undefined, timezoneName: string | null, intl: IntlShape, locale: ILocale) => {
  if (!d) return null

  const date = parseISO(d)

  if (!isValidDate(date)) {
    console.error(new Error(`Trying to format invalid date ${d}`))
    return null
  }

  const formatted = intl.formatDate(date, {
    ...DATETIME_FORMATS.LONG(locale),
    timeZone: timezoneName || undefined,
  })

  return formatted
}

const formatNumber = (n: number | null, intl: IntlShape) => (isNil(n) ? null : intl.formatNumber(n))

type IPricableArr = ReadonlyArray<{ readonly allocation: number; readonly faceValue: number }>

const formatTicketType = (
  tt: NonNullable<IProps['event']['ticketTypes']>[number],
  intl: IntlShape,
  currency: NonNullable<IProps['event']['costCurrency']>
) => {
  if (!tt) return null

  const formatPrice = (p?: number | null) => (p ? intl.formatNumber(p, CURRENCY(p, currency)) : '_free_')

  const priceableArr = (tt.priceTiers && tt.priceTiers.length > 0 ? compact(tt.priceTiers) : [tt]) as IPricableArr

  const formula =
    !tt.priceTierType || tt.priceTierType === 'allocation'
      ? map((pt) => `${pt.allocation || 0} × ${formatPrice(pt.faceValue / 100)}`, priceableArr).join(' + ')
      : `${tt.allocation} × (${map((pt) => formatPrice(pt.faceValue / 100), priceableArr).join(' + ')})`

  const parts = formula.split('_free_')

  return (
    <>
      {tt.name}
      {': '}
      {parts.map((p, idx) => (
        <Fragment key={idx}>
          {p}
          {idx !== parts.length - 1 && <Free>{intl.formatMessage({ id: 'free' })}</Free>}
        </Fragment>
      ))}
    </>
  )
}

const FIELDS: (eventType: EventType | null) => Array<IDetailRow> = (eventType) => [
  { label: 'basics.name', getFn: get('name') },
  {
    label: 'basics.event_type',
    getFn: (data, intl) =>
      compose([
        (type) =>
          type &&
          intl.formatMessage({
            // @intl-meta project:Tags
            id: `tags_hierarchy.${type.parent.name}_${type.name}`,
            defaultMessage: type.label,
          }),
        find(['kind', 'type']),
        get('hierarchicalTags'),
      ])(data),
  },
  {
    label: eventType === 'STREAM' ? 'basics.streaming_from' : 'basics.venue',
    getFn: (data) => get('primaryVenue.name', data) || get('venueName', data) || get(['venues', 0, 'name'], data),
  },
  {
    label: 'timeline.start',
    getFn: (data, intl, _, locale) => formatDate(data.date, data.timezoneName, intl, locale),
  },
  {
    label: 'timeline.end',
    getFn: (data, intl, _, locale) => formatDate(data.endDate, data.timezoneName, intl, locale),
  },
  {
    label: 'timeline.announced',
    getFn: (data, intl, _, locale) => formatDate(data.announceDate, data.timezoneName, intl, locale),
  },
  {
    label: 'timeline.on_sale',
    getFn: (data, intl, _, locale) => formatDate(data.onSaleDate, data.timezoneName, intl, locale),
  },
  {
    label: 'timeline.off_sale',
    getFn: (data, intl, _, locale) => formatDate(data.offSaleDate, data.timezoneName, intl, locale),
  },
  {
    label: 'review_details.line_up',
    getFn: (data, intl, _, locale) =>
      data.lineup?.map((l: any) => {
        const format = l.time && l.time.toLowerCase().match(/(a|p)m/) ? 'h:mm a' : 'HH:mm'
        const dt = l.time && parse(l.time, format, new Date())
        const time = dt && !isNaN(dt.getTime()) && intl.formatDate(dt, { ...DATETIME_FORMATS.TIME(locale) })
        return `${l.details}${time ? ` — ${time}` : ''}`
      }) || null,
  },
  {
    label: 'review_details.recurring',
    getFn: (data, intl, recurrentData, locale) => {
      if (!data.recurrentEventSchedule) return null
      if (!recurrentData) return ''
      if (recurrentData.length === 0) return null

      return intl.formatMessage(
        { id: 'new_event.review_details.recurring.value' },
        {
          count: recurrentData.length,
          period: intl
            .formatMessage({
              // eslint-disable-next-line max-len
              id: `new_event.timeline.recurring.frequency.options.${data.recurrentEventSchedule.frequency?.toLowerCase()}`,
            })
            .toLowerCase(),
          startDate: formatDate(recurrentData[0], data.timezoneName, intl, locale),
          endDate: formatDate(recurrentData[recurrentData.length - 1], data.timezoneName, intl, locale),
        }
      )
    },
  },
  { label: 'review_details.ticket_limit', getFn: (data, intl) => formatNumber(data.maxTicketsLimit, intl) },
  {
    label: 'review_details.restricted_countries',
    getFn: (data, intl, _, locale) =>
      map((countryKey) => getNameByAlpha2(countryKey || '', locale), data.restrictCountries || []).join(', '),
  },
  {
    label: 'review_details.ticketing',
    getFn: (data, intl) =>
      compact(
        reject('archived', data.ticketTypes || []).map((tt) => formatTicketType(tt, intl, data.costCurrency || 'GBP'))
      ) || null,
  },
  {
    label: 'information.description',
    getFn: (data) => data.description && { __html: markdownToHtml(data.description) },
  },
  { label: 'information.presented_by', getFn: get('presentedBy') },
  { label: 'information.age_limit', getFn: get('ageLimit') },
  {
    label: 'settings.doorlist',
    getFn: (data) =>
      concat(map('email', data.doorlistRecipients || []), data.doorlistAdditionalRecipients || []).join(', '),
  },
  { label: 'settings.guest_access', getFn: (data) => map('email', data.eventSharingObjects || []).join(', ') },
  { label: 'settings.billing_account', getFn: (data) => data.billingPromoter?.name },
]

const renderValue = (intl: IntlShape, f: { key: string; label: string; value: any }) => {
  if (isNil(f.value) || f.value === '' || (isArray(f.value) && f.value.length === 0)) {
    return intl.formatMessage({ id: 'na' })
  }

  if (isObject(f.value) && '__html' in f.value)
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: f.value
            ? sanitize((f.value as any).__html, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] })
            : '',
        }}
      />
    )

  if (!isArray(f.value)) return f.value

  return f.value.map((v, i) => (
    <div key={i} data-id={`${f.key}[${i}]`}>
      {v}
    </div>
  ))
}

const EventReviewModal: FC<React.PropsWithChildren<IProps>> = ({ onClose, onSave, event, id }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const { trackEvent } = useContext(trackingContext)
  const environment = useRelayEnvironment()

  const isRecurringEventPartAlready = useMemo(
    () => (event.recurrentEventsGroup?.length || 0) > 1,
    [event.recurrentEventsGroup?.length]
  )

  const previewEventOnDice = usePreviewEventOnDiceCallback(event.previewToken)

  const [recurrentData, setRecurrentData] = useState<string[] | null>(null)

  useEffect(() => {
    if (isRecurringEventPartAlready) {
      setRecurrentData(compose([compact, map('date'), sortBy('date')])(event.recurrentEventsGroup || []))
      return
    }

    if (
      !event.recurrentEventSchedule ||
      !event.recurrentEventSchedule.frequency ||
      !event.recurrentEventSchedule.repeatEnds ||
      !event.date ||
      !event.endDate ||
      !event.onSaleDate ||
      !event.offSaleDate ||
      !event.announceDate
    ) {
      setRecurrentData([])
      return
    }

    let stillActive = true

    fetchQuery_DEPRECATED<EventReviewModalScheduleQuery>(environment, EVENT_SCHEDULE_QUERY, {
      frequency: event.recurrentEventSchedule.frequency,
      repeatEnds: event.recurrentEventSchedule.repeatEnds,
      occurrences: event.recurrentEventSchedule.occurrences,
      until: event.recurrentEventSchedule.until,
      date: event.date,
      endDate: event.endDate,
      announceDate: event.announceDate,
      onSaleDate: event.onSaleDate,
      offSaleDate: event.offSaleDate,
    }).then((rs: EventReviewModalScheduleQuery['response']) => {
      if (stillActive) setRecurrentData(map('date', getOr([], 'viewer.schedule', rs)))
    })

    return () => {
      stillActive = false
    }
  }, [
    event.recurrentEventSchedule,
    event.onSaleDate,
    event.offSaleDate,
    event.announceDate,
    event.date,
    event.endDate,
    event.recurrentEventsGroup,
    isRecurringEventPartAlready,
    environment,
  ])

  const fields = useMemo(
    () =>
      compact(
        map((f) => {
          try {
            return {
              key: f.label,
              label: intl.formatMessage({ id: `new_event.${f.label}.label`, defaultMessage: f.label }),
              value: f.getFn(event, intl, recurrentData, locale) || '',
            }
          } catch (e) {
            console.error(`Error formatting event field ${f?.label} for review`, e)
            return null
          }
        }, FIELDS(event.eventType))
      ),
    [event, intl, locale, recurrentData]
  )

  const [isSubmitting, setSubmitting] = useState(false)

  const trackData = useMemo(
    () => ({
      event_id: unwrapId(id),
      event_id_live: event?.eventIdLive,
    }),
    [event, id]
  )

  const doSave = useCallback(() => {
    if (onSave) {
      trackEvent('create_event_submitted', trackData)
      setSubmitting(true)
      const result = onSave(id, event)
      if (result instanceof Promise) {
        result.catch(() => {
          setSubmitting(false)
        })
      }
    }
  }, [onSave, trackEvent, trackData, id, event])

  const [errors, setErrors] = useState<FormikErrors<Partial<IEventForm>>>({})
  useEffect(() => {
    let stillMounted = true

    const errorsPromise = EventSchema.validate(event, {
      context: { initialValues: event, viewer: user, locale },
      abortEarly: false,
    })
      .then(() => ({}))
      .catch(yupToFormErrors)

    errorsPromise.then((errors) => {
      if (stillMounted) {
        setErrors(errors)
      }
    })

    return () => {
      stillMounted = false
    }
  }, [event, locale, user])

  const { fields: errorFields, remainingErrors } = useEventFormErrors(event.eventType, errors)
  const errorFieldMap = useMemo(() => keyBy('label', errorFields), [errorFields])

  const maxSeatsCapacity = useMaxSeatsCapacity(event.eventSeatingChart?.id || null)

  const { overallocation, venueCapacity } = useMemo(
    () => checkOverallocation(event, locale, maxSeatsCapacity),
    [event, locale, maxSeatsCapacity]
  )

  const currencyMismatch = useMemo(() => checkCurrencyMismatch(event, locale), [event, locale])

  return recurrentData !== null ? (
    <Modal
      trackId="create_event_review"
      trackData={trackData}
      closeButton
      onClose={onClose}
      modalTitle={intl.formatMessage({ id: 'new_event.review_details.title' })}
    >
      <ModalBody>
        <Form>
          {!isEmpty(remainingErrors) && (
            <FormRow>
              <FormGroup label={intl.formatMessage({ id: 'event_details.errors.label' })}>
                <EventFormErrors errors={remainingErrors} eventType={event.eventType} />
              </FormGroup>
            </FormRow>
          )}
          {overallocation > 0 && !event.eventSeatingChart && (
            <FormRow>
              <Danger>
                {intl.formatMessage(
                  { id: 'venue_capacity_allocation.warning' },
                  { number: overallocation, capacity: venueCapacity, b: (str: string) => <strong>{str}</strong> }
                )}
              </Danger>
            </FormRow>
          )}
          {currencyMismatch && (
            <FormRow>
              <Danger>{intl.formatMessage({ id: 'venue_currency_mismatch.warning' })}</Danger>
            </FormRow>
          )}
          <FormRow>
            <FormGroup
              label={
                !isEmpty(remainingErrors) ? intl.formatMessage({ id: 'new_event.review_details.label' }) : undefined
              }
            >
              <FieldList>
                {fields.map((f) => (
                  <React.Fragment key={f.key}>
                    <dt data-id={f.key}>{f.label}</dt>
                    <dd data-id={f.key}>
                      {renderValue(intl, f)}
                      {errorFieldMap[f.label] && (
                        <ErrorSpan> [{renderErrorValue(intl, errorFieldMap[f.label])}]</ErrorSpan>
                      )}
                    </dd>
                  </React.Fragment>
                ))}
              </FieldList>
            </FormGroup>
          </FormRow>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl
          onClick={doSave}
          data-id="save"
          loading={isSubmitting}
          color={!isEmpty(errors) ? 'warning' : undefined}
        >
          {intl.formatMessage(
            {
              id:
                recurrentData.length < 2 || isRecurringEventPartAlready
                  ? isEmpty(errors)
                    ? 'actions.submit'
                    : 'submit_with_errors'
                  : 'new_event.review_details.create_draft_button_recurrent',
            },
            { count: recurrentData.length }
          )}
        </ModalFooterControl>
        <ModalFooterControl onClick={previewEventOnDice} disabled={!event.previewToken} preset="secondary">
          {intl.formatMessage({ id: 'event_list.quick_actions.preview_event_on_dice' })}
        </ModalFooterControl>

        <ModalFooterControl preset="secondary" onClick={onClose} data-id="cancel" disabled={isSubmitting}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  ) : null
}

const EventReviewModalNoLoader = createFragmentContainer(memo(EventReviewModal), {
  event: graphql`
    fragment EventReviewModal_event on Event {
      ...EventDetails_event @relay(mask: false)
      id
      eventIdLive
      eventType
      name
      state
      timezoneName
      date
      endDate
      announceDate
      onSaleDate
      offSaleDate
      venueName
      restrictCountries
      addressCountry
      countryCode
      primaryVenue {
        name
        addressCountry
        countryCode
      }
      venues {
        name
        capacity
        addressCountry
        countryCode
      }
      lineup
      hierarchicalTags {
        id
        kind
        name
        parent {
          id
          name
        }
      }
      recurrentEventsGroup {
        id
        date
      }
      recurrentEventSchedule {
        frequency
        repeatEnds
        occurrences
        until
        repeatOn
      }
      maxTicketsLimit
      costCurrency
      description
      presentedBy
      ageLimit
      doorlistRecipients {
        email
      }
      billingPromoter {
        name
      }
      doorlistAdditionalRecipients
      eventSharingObjects {
        email
      }
      ticketTypes(doorSalesOnly: false, includeArchived: true) {
        id
        name
        archived
        hidden
        onSaleDate
        offSaleDate
        allocation
        faceValue
        priceTierType
        priceTiers {
          id
          name
          allocation
          faceValue
          time
        }
      }
    }
  `,
})

export default ({ id, event, ...restProps }: MarkOptional<IProps, 'event'>) => {
  const DataLoader = useMemo(
    () =>
      RelayLoader(EventReviewModalNoLoader, {
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
        query: graphql`
          query EventReviewModalQuery($id: ID!) {
            event: node(id: $id) {
              ...EventReviewModal_event
            }
          }
        `,
      }),
    [id]
  )

  if (!id) return null

  return !event ? (
    <DataLoader id={id} {...restProps} />
  ) : (
    <EventReviewModalNoLoader id={id} event={event as any} {...restProps} />
  )
}
