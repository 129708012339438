/**
 * @generated SignedSource<<03d2089a05ba53f196ff164e33f4e81e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventTimingField = "ANNOUNCE_DATE" | "DATE" | "END_DATE" | "LINE_UP_DOORS_OPEN" | "OFF_SALE_DATE" | "ON_SALE_DATE" | "ON_SALE_REMINDER_TIME" | "RETURN_DEADLINE" | "TICKET_TYPE_ANNOUNCE_DATE" | "TICKET_TYPE_OFF_SALE_DATE" | "TICKET_TYPE_ON_SALE_DATE" | "TRANSFER_DEADLINE" | "%future added value";
export type EventTimingType = "DEFAULT" | "PROMOTER" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type ScheduleStatus = "POSTPONED" | "RESCHEDULED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Timeline_event$data = {
  readonly allowedLifecycleUpdates: {
    readonly announceDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly date: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly endDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly lineUp: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly offSaleDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly onSaleDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly attractiveFields: {
    readonly forceSubscription: boolean | null;
    readonly forceSubscriptionLimit: number | null;
    readonly linkedEvents: ReadonlyArray<{
      readonly date: string | null;
      readonly endDate: string | null;
      readonly id: string;
      readonly name: string | null;
      readonly primaryVenue: {
        readonly name: string | null;
      } | null;
      readonly timezoneName: string | null;
    } | null> | null;
    readonly subscriptionCode: string | null;
  } | null;
  readonly attractiveStatus: {
    readonly status: string | null;
  } | null;
  readonly billingPromoter: {
    readonly autoRescheduledEventRefunds: {
      readonly active: boolean | null;
      readonly cutoffDays: number | null;
    } | null;
    readonly defaultEventTimings: ReadonlyArray<{
      readonly offset: number | null;
      readonly sourceField: EventTimingField | null;
      readonly targetField: EventTimingField | null;
      readonly type: EventTimingType | null;
    } | null> | null;
  } | null;
  readonly closeEventDate: string | null;
  readonly date: string | null;
  readonly defaultEventTimings: ReadonlyArray<{
    readonly offset: number | null;
    readonly sourceField: EventTimingField | null;
    readonly targetField: EventTimingField | null;
    readonly type: EventTimingType | null;
  } | null> | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly eventType: EventType | null;
  readonly flags: {
    readonly autoRescheduledEventRefunds: IBackendMap | null;
    readonly enabledPwl: IBackendMap | null;
    readonly ticketTransfer: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly lineup: ReadonlyArray<IBackendMap | null> | null;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly primaryVenue: {
    readonly value: string;
  } | null;
  readonly recurrentEventsGroup: ReadonlyArray<{
    readonly date: string | null;
    readonly id: string;
  } | null> | null;
  readonly sales: {
    readonly totalAppSold: number;
    readonly totalPosSold: number;
  } | null;
  readonly scheduleStatus: ScheduleStatus | null;
  readonly scheduleStatusUpdatedAt: string | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly timezoneName: string | null;
  readonly " $fragmentType": "Timeline_event";
};
export type Timeline_event$key = {
  readonly " $data"?: Timeline_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"Timeline_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezoneName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "EventTiming",
  "kind": "LinkedField",
  "name": "defaultEventTimings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceField",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetField",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "minValue",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Timeline_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeEventDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleStatusUpdatedAt",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lineup",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutoRescheduledEventRefunds",
          "kind": "LinkedField",
          "name": "autoRescheduledEventRefunds",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cutoffDays",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationStatus",
      "kind": "LinkedField",
      "name": "attractiveStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttractiveFields",
      "kind": "LinkedField",
      "name": "attractiveFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forceSubscription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forceSubscriptionLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Event",
          "kind": "LinkedField",
          "name": "linkedEvents",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Venue",
              "kind": "LinkedField",
              "name": "primaryVenue",
              "plural": false,
              "selections": [
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabledPwl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketTransfer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autoRescheduledEventRefunds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "recurrentEventsGroup",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "primaryVenue",
      "plural": false,
      "selections": [
        {
          "alias": "value",
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "announceDate",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "onSaleDate",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "offSaleDate",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "date",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "endDate",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesLineUp",
          "kind": "LinkedField",
          "name": "lineUp",
          "plural": false,
          "selections": [
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalAppSold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPosSold",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "161d770ecd7f583661be4cab25220251";

export default node;
