/**
 * @generated SignedSource<<0bb167352ba6e5abc6c81c1e3ec3b4a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHierarchicalTags_viewer$data = {
  readonly hierarchicalTags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly kind: string | null;
        readonly label: string | null;
        readonly parent: {
          readonly name: string | null;
        } | null;
        readonly value: string;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "useHierarchicalTags_viewer";
};
export type useHierarchicalTags_viewer$key = {
  readonly " $data"?: useHierarchicalTags_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHierarchicalTags_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useHierarchicalTags_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "kind": "type"
          }
        }
      ],
      "concreteType": "HierarchicalTagsConnection",
      "kind": "LinkedField",
      "name": "hierarchicalTags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HierarchicalTagsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HierarchicalTag",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "value",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": "label",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HierarchicalTag",
                  "kind": "LinkedField",
                  "name": "parent",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "hierarchicalTags(first:100,where:{\"kind\":\"type\"})"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d27bd5bc675306c48fce2b02454f616d";

export default node;
