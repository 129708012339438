import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components/macro'
import { isPast, parseISO } from 'date-fns'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay'

import { Modal, ModalBody, ModalDialog, ModalFooter, ModalFooterControl } from './Modal'
import Svg from './Svg'
import { authContext } from '../context/auth'
import { color, font, mediaQuery } from '../utils/variables'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'
import RelayLoader from './RelayLoader'
import { StripeProblemBannerLoginQuery } from '../__generated__/StripeProblemBannerLoginQuery.graphql'
import { getStripeAccountUrl } from '../utils/stripe/url'
import { StripeProblemBannerQuery } from '../__generated__/StripeProblemBannerQuery.graphql'
import { textStyle } from '../utils/typography'

const AlertSvg = styled(Svg)`
  margin-top: -48px;
  margin-bottom: 16px;
  color: ${color.error};

  ${mediaQuery.lessThan('tablet')`
    margin-top: 0;
  `}
`

const Title = styled.p`
  ${textStyle.heading.lg}
  padding: 0 16px;
`

const Description = styled.p`
  ${textStyle.functional.sm}
  text-align: center;

  margin: 16px 0 -8px 0;
  padding: 11px 12px;

  background: rgba(255, 28, 94, 0.06);
  border-radius: 4px;

  white-space: pre-wrap;
`

const StyledModal = styled(Modal)`
  ${ModalDialog} {
    width: 400px;

    ${mediaQuery.lessThan('tablet')`
      width: 100%;
    `}
  }

  ${ModalBody} {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const CONTACT_EMAIL = 'clientsuccess@dice.fm'

const StripeProblemBanner: FC = () => {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)
  const navigate = useNavigate()
  const location = useLocation()

  const { viewer } = useLazyLoadQuery<StripeProblemBannerQuery>(
    graphql`
      query StripeProblemBannerQuery {
        viewer {
          account {
            isDisabled
            stripeAccountId
            stripeAccountState
            stripeAccountType
            billingAccountDueDate
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' }
  )

  const account = viewer?.account

  const [stripeIsBroken, dueDate] = useMemo(() => {
    const dueDate =
      account?.billingAccountDueDate && !isPast(parseISO(account?.billingAccountDueDate))
        ? parseISO(account?.billingAccountDueDate)
        : null

    const stripeIsBroken = !!account && !account.isDisabled && (account.stripeAccountState !== 'OK' || !!dueDate)

    return [stripeIsBroken, dueDate] as const
  }, [account])

  const isEventSubmission = location.pathname === '/events/new'

  const [visible, setVisible] = useState(stripeIsBroken && location.pathname !== '/account')

  useEffect(() => {
    if (!isEventSubmission || !stripeIsBroken) return

    setVisible(true)
  }, [isEventSubmission, stripeIsBroken])

  const onClose = useCallback(() => setVisible(false), [])

  const toAccount = useCallback(() => {
    onClose()
    navigate('/account')
  }, [navigate, onClose])

  const i18nKeyTitle = useMemo(() => {
    if (isEventSubmission) return 'stripe_problem_banner.title'

    if (account?.stripeAccountState === 'CHARGES_DISABLED') {
      return 'stripe_problem_banner.title_charges_blocked'
    } else if (account?.stripeAccountState === 'PAYOUTS_DISABLED') {
      return 'stripe_problem_banner.title_payouts_blocked'
    }

    const kind = account?.stripeAccountType === 'express' ? 'express' : 'custom'

    return kind === 'express' ? 'stripe_problem_banner.title_express' : 'stripe_problem_banner.title'
  }, [account?.stripeAccountState, account?.stripeAccountType, isEventSubmission])

  const i18nKey = useMemo(() => {
    if (isEventSubmission) return `stripe_problem_banner.description_event${!dueDate ? '_no_date' : ''}`

    const kind = account?.stripeAccountType === 'express' ? 'express' : 'custom'

    if (account?.stripeAccountState === 'CHARGES_DISABLED') {
      return `stripe_problem_banner.description_${kind}_charges_blocked`
    } else if (account?.stripeAccountState === 'PAYOUTS_DISABLED') {
      return `stripe_problem_banner.description_${kind}_payouts_blocked`
    }

    return `stripe_problem_banner.description_${kind}_soon${!dueDate ? '_no_date' : ''}`
  }, [account?.stripeAccountState, account?.stripeAccountType, dueDate, isEventSubmission])

  const ctaControl = useMemo(() => {
    if (isEventSubmission)
      return (
        <ModalFooterControl block onClick={toAccount}>
          {intl.formatMessage({ id: 'stripe_problem_banner.cta' })}
        </ModalFooterControl>
      )

    if (account?.stripeAccountType === 'express') {
      const LoginUrlLoader = RelayLoader<StripeProblemBannerLoginQuery['response']>(
        ({ viewer }) => (
          <ModalFooterControl
            block
            disabled={!viewer?.account?.stripeLoginUrl}
            href={viewer?.account?.stripeLoginUrl || '#'}
            target="_blank"
          >
            {intl.formatMessage({ id: 'stripe_problem_banner.cta_express' })}
          </ModalFooterControl>
        ),
        {
          fetchPolicy: 'network-only',
          query: graphql`
            query StripeProblemBannerLoginQuery {
              viewer {
                account {
                  stripeLoginUrl
                }
              }
            }
          `,
          customLoader: (
            <ModalFooterControl block disabled>
              {intl.formatMessage({ id: 'stripe_problem_banner.cta_express' })}
            </ModalFooterControl>
          ),
        }
      )

      return hasPermission('perform_payout:balances') ? (
        <LoginUrlLoader />
      ) : (
        <ModalFooterControl
          block
          disabled={!account?.stripeAccountId}
          href={account?.stripeAccountId ? getStripeAccountUrl(account?.stripeAccountId) : '#'}
          target="_blank"
        >
          {intl.formatMessage({ id: 'stripe_problem_banner.cta_express' })}
        </ModalFooterControl>
      )
    }

    return (
      <ModalFooterControl block target="_blank" href={`mailto:${CONTACT_EMAIL}?subject=Custom Stripe account issues`}>
        {intl.formatMessage({ id: 'stripe_problem_banner.cta_custom' })}
      </ModalFooterControl>
    )
  }, [account?.stripeAccountId, account?.stripeAccountType, hasPermission, intl, isEventSubmission, toAccount])

  if (!account || !visible) return null

  return (
    <StyledModal closeButton onClose={isEventSubmission ? toAccount : onClose}>
      <ModalBody>
        <AlertSvg icon="alert" width={112} height={112} />
        <Title>{intl.formatMessage({ id: i18nKeyTitle })}</Title>
        <Description>
          {intl.formatMessage(
            { id: i18nKey },
            {
              date: dueDate
                ? intl.formatDate(dueDate, { ...DATETIME_FORMATS.SHORT, timeZone: user.timezoneName })
                : '???',
              b: (str: string) => <strong>{str}</strong>,
              email: CONTACT_EMAIL,
            }
          )}
        </Description>
      </ModalBody>
      <ModalFooter>{ctaControl}</ModalFooter>
    </StyledModal>
  )
}

export default memo(StripeProblemBanner)
