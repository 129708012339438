import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { createRoot } from 'react-dom/client'
import 'webcrypto-shim'
import 'fast-text-encoding'

import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { StyleSheetManager } from 'styled-components/macro'

import initSentry from './sentry'
import StyleSheets from './styles/StyleSheets'

import { DEFAULT_LOCALE, intlPolyfills, loadLocale } from './intl'
import { setAuthToken } from './utils/api'
import GenericError from './components/GenericError'
import patchDOM from './patchDOM'
import { SvgSprite } from './components/Svg'
import chartSetup from './chartSetup'
import Router from './routing/Router'

patchDOM()
initSentry()
chartSetup()

const cookieToken = Cookies.get('kim-auth')
if (cookieToken) {
  setAuthToken(cookieToken)
  Cookies.remove('kim-auth', { path: '/', domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.dice.fm' })
}

async function init() {
  let ok = true

  try {
    await intlPolyfills()
    await loadLocale(DEFAULT_LOCALE)
  } catch (e) {
    ok = false

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(document.getElementById('app')!)
    root.render(
      <>
        <SvgSprite />
        <StyleSheetManager target={document.head}>
          <StyleSheets />
        </StyleSheetManager>
        <Helmet>
          <title>DICE MIO | Error</title>
        </Helmet>
        <StyleSheetManager target={document.body}>
          <GenericError title="Error loading MIO" description="Please try to refresh the page" />
        </StyleSheetManager>
      </>
    )
  }

  if (ok) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(document.getElementById('app')!)
    root.render(
      <>
        <SvgSprite />
        <StyleSheetManager target={document.head}>
          <StyleSheets />
        </StyleSheetManager>
        <StyleSheetManager target={document.body}>
          <Router />
        </StyleSheetManager>
      </>
    )
  }
}

init()
