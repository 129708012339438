import React, { FC, HTMLProps } from 'react'
import styled from 'styled-components/macro'

import { color } from '../utils/variables'

interface IProps {
  active?: boolean
  disabled?: boolean
}

const MenuItem: FC<React.PropsWithChildren<IProps & HTMLProps<HTMLLIElement>>> = ({ children, active, ...rest }) => (
  <MenuListItem active={active} {...(rest as any)}>
    {children}
  </MenuListItem>
)

const Menu = styled('nav')`
  list-style: none;
  margin: 0;
  padding: 0;
`

const MenuListItem = styled.li<IProps>`
  display: flex;
  align-items: flex-start;
  padding: 10px 16px;
  margin: 6px 0;
  font-size: initial;
  color: initial;
  cursor: pointer;

  & > a {
    margin: -10px -16px;
    padding: 10px 16px;
  }

  &:hover {
    background: ${color.palegrey};
    a:hover {
      color: inherit;
    }
  }
  ${({ active }) =>
    active &&
    `
    color: ${color.primary};
  `};
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  `};
`

export { Menu, MenuItem }
