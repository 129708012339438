/**
 * @generated SignedSource<<584831523e822bec05731be9c049d800>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompatibilityAe = "_0" | "_1000" | "_5000" | "%future added value";
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type PaymentMethods = "AFTERPAY_CLEARPAY" | "DICE_SPLIT" | "GIROPAY" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type ReservedSeatingTypes = "assignBestSeat" | "selectSeat" | "%future added value";
export type TvPlatform = "DICE" | "EXTERNAL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventTicketTypeModal_event$data = {
  readonly addressCountry: string | null;
  readonly allowedActions: {
    readonly managePromotions: boolean | null;
    readonly manageTickets: boolean | null;
    readonly minorUpdate: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly ticketTypes: {
      readonly canAdd: boolean | null;
      readonly canChangeAllocation: boolean | null;
      readonly canChangeDoorSalesEnabled: boolean | null;
      readonly canChangeExternalSkus: boolean | null;
      readonly canChangeIcon: boolean | null;
      readonly canChangeIncrements: boolean | null;
      readonly canChangeOffSaleDate: boolean | null;
      readonly canChangeOnSaleDate: boolean | null;
      readonly canChangeOrder: boolean | null;
      readonly canChangeSeatmap: boolean | null;
      readonly canChangeTierNames: boolean | null;
      readonly canDelete: boolean | null;
      readonly canHide: boolean | null;
      readonly canUpdate: boolean | null;
      readonly canUpdatePrice: ReadonlyArray<string | null> | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly attractiveFields: {
    readonly compatibilityAe: CompatibilityAe | null;
    readonly integrationDisabled: boolean;
    readonly siaeGenreType: string | null;
    readonly taxFree: boolean | null;
  } | null;
  readonly attractiveStatus: {
    readonly status: string | null;
  } | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly countryCode: string | null;
  readonly date: string | null;
  readonly diceTvPlatform: TvPlatform | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly eventSeatingChart: {
    readonly id: string;
    readonly seatsIoEventReport: IBackendMap | null;
  } | null;
  readonly eventType: EventType | null;
  readonly flags: {
    readonly seated: IBackendMap | null;
    readonly shoppingCart: IBackendMap | null;
    readonly unicorn: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly maxTicketsLimit: number | null;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly ticketType: {
        readonly id: string;
        readonly ticketPoolId: string | null;
      };
      readonly ticketTypeId: string;
      readonly totalAppSold: number;
      readonly totalPosSold: number;
      readonly totalReserved: number;
      readonly totalSold: number;
      readonly totalTerminalSold: number;
    } | null>;
  } | null;
  readonly state: EventState | null;
  readonly ticketPools: ReadonlyArray<{
    readonly id: string;
    readonly maxAllocation: number | null;
    readonly name: string | null;
  } | null> | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly activateCodeDateOffset: number | null;
    readonly additionalPaymentMethods: ReadonlyArray<PaymentMethods | null> | null;
    readonly allocation: number;
    readonly allowSeatChange: boolean | null;
    readonly announceDate: string | null;
    readonly archived: boolean | null;
    readonly attractivePriceType: string | null;
    readonly attractiveSeatingAreaType: string | null;
    readonly codeLocked: boolean;
    readonly description: string | null;
    readonly doorSalesEnabled: boolean;
    readonly doorSalesPrice: number | null;
    readonly doorSalesPriceTaxed: number | null;
    readonly doorSalesTax: number | null;
    readonly endDate: string | null;
    readonly externalSkus: ReadonlyArray<string | null> | null;
    readonly faceValue: number;
    readonly fees: ReadonlyArray<{
      readonly amount: number | null;
      readonly applicable: boolean | null;
      readonly split: ReadonlyArray<{
        readonly amount: number | null;
        readonly destination: FeeDestination | null;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly type: FeeType;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly hidden: boolean;
    readonly icon: string | null;
    readonly id: string;
    readonly increment: number | null;
    readonly isStream: boolean;
    readonly maximumIncrements: number | null;
    readonly name: string | null;
    readonly offSaleDate: string | null;
    readonly onSaleDate: string | null;
    readonly presale: boolean;
    readonly priceBreakdown: {
      readonly breakdown: ReadonlyArray<{
        readonly applicable: boolean | null;
        readonly computed: number;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly computed: number;
          readonly destination: FeeDestination | null;
        } | null>;
        readonly type: FeeType;
      } | null>;
      readonly faceValue: number;
      readonly friendlyFaceValue: number | null;
      readonly friendlyPrice: number | null;
      readonly split: ReadonlyArray<{
        readonly computed: number;
        readonly destination: FeeDestination;
      } | null>;
      readonly total: number;
      readonly totalWithPwl: number | null;
      readonly totalWithoutPwl: number | null;
    } | null;
    readonly priceHidden: boolean;
    readonly priceTierType: PriceTierTypes | null;
    readonly priceTiers: ReadonlyArray<{
      readonly allocation: number | null;
      readonly attractivePriceType: string | null;
      readonly doorSalesPrice: number | null;
      readonly doorSalesPriceTaxed: number | null;
      readonly faceValue: number;
      readonly fees: ReadonlyArray<{
        readonly amount: number | null;
        readonly applicable: boolean | null;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null> | null;
        readonly type: FeeType;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly id: string;
      readonly name: string | null;
      readonly priceBreakdown: {
        readonly breakdown: ReadonlyArray<{
          readonly applicable: boolean | null;
          readonly computed: number;
          readonly split: ReadonlyArray<{
            readonly amount: number | null;
            readonly computed: number;
            readonly destination: FeeDestination | null;
          } | null>;
          readonly type: FeeType;
        } | null>;
        readonly faceValue: number;
        readonly friendlyFaceValue: number | null;
        readonly friendlyPrice: number | null;
        readonly split: ReadonlyArray<{
          readonly computed: number;
          readonly destination: FeeDestination;
        } | null>;
        readonly total: number;
        readonly totalWithPwl: number | null;
        readonly totalWithoutPwl: number | null;
      } | null;
      readonly time: string | null;
    } | null> | null;
    readonly requiresAddress: boolean | null;
    readonly requiresOtherTypeIds: ReadonlyArray<string | null> | null;
    readonly reservedSeating: boolean | null;
    readonly reservedSeatingType: ReservedSeatingTypes | null;
    readonly salesLimit: number | null;
    readonly seatCategories: ReadonlyArray<{
      readonly id: string | null;
      readonly name: string;
      readonly seatsIoKey: string | null;
    } | null> | null;
    readonly seatmapUrl: string | null;
    readonly startDate: string | null;
    readonly streamLink: string | null;
    readonly ticketPoolId: string | null;
    readonly venueScheduleId: string | null;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly venueSchedules: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly capacity: number;
    readonly countryCode: string | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventTicketTypeDoorPricing_event" | "EventTicketTypeIncrements_event" | "EventTicketTypePricing_event" | "EventTicketTypeTimeline_event">;
  readonly " $fragmentType": "EventTicketTypeModal_event";
};
export type EventTicketTypeModal_event$key = {
  readonly " $data"?: EventTicketTypeModal_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventTicketTypeModal_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "announceDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onSaleDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offSaleDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticketPoolId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doorSalesPrice",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doorSalesPriceTaxed",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attractivePriceType",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applicable",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destination",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Fee",
  "kind": "LinkedField",
  "name": "fees",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v14/*: any*/),
        (v18/*: any*/),
        (v16/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "computed",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "priceBreakdown",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeOutput",
      "kind": "LinkedField",
      "name": "breakdown",
      "plural": true,
      "selections": [
        (v20/*: any*/),
        (v15/*: any*/),
        (v17/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FeeSplitOutput",
          "kind": "LinkedField",
          "name": "split",
          "plural": true,
          "selections": [
            (v14/*: any*/),
            (v20/*: any*/),
            (v18/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithPwl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithoutPwl",
      "storageKey": null
    },
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v20/*: any*/),
        (v18/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyFaceValue",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventTicketTypeModal_event",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeIncrements_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypePricing_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeDoorPricing_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeTimeline_event"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceTvPlatform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTicketsLimit",
      "storageKey": null
    },
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managePromotions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageTickets",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shoppingCart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unicorn",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueSchedule",
      "kind": "LinkedField",
      "name": "venueSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketPool",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "doorSalesOnly",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "includeArchived",
          "value": true
        }
      ],
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresOtherTypeIds",
          "storageKey": null
        },
        (v0/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archived",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalPaymentMethods",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activateCodeDateOffset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "venueScheduleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "codeLocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStream",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v9/*: any*/),
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "presale",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "increment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maximumIncrements",
          "storageKey": null
        },
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesEnabled",
          "storageKey": null
        },
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesTax",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attractiveSeatingAreaType",
          "storageKey": null
        },
        (v13/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seatmapUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceTierType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalSkus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceHidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSeatChange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedSeating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedSeatingType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatCategory",
          "kind": "LinkedField",
          "name": "seatCategories",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seatsIoKey",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v19/*: any*/),
        (v21/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceTier",
          "kind": "LinkedField",
          "name": "priceTiers",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v7/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v10/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            },
            (v13/*: any*/),
            (v19/*: any*/),
            (v21/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationStatus",
      "kind": "LinkedField",
      "name": "attractiveStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSeatingChart",
      "kind": "LinkedField",
      "name": "eventSeatingChart",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "group",
              "value": "BY_CATEGORY_KEY"
            }
          ],
          "kind": "ScalarField",
          "name": "seatsIoEventReport",
          "storageKey": "seatsIoEventReport(group:\"BY_CATEGORY_KEY\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttractiveFields",
      "kind": "LinkedField",
      "name": "attractiveFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "compatibilityAe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "siaeGenreType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFree",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketTypeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalReserved",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalTerminalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketTypes",
          "kind": "LinkedField",
          "name": "ticketTypes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canAdd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeAllocation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeDoorSalesEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeIcon",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeIncrements",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOffSaleDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOnSaleDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOrder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeSeatmap",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeTierNames",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canDelete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canHide",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdatePrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeExternalSkus",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "5f1bf5438e358cb07da1a7dd332242fd";

export default node;
