/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isEqual, parseISO } from 'date-fns'
import { compact, map } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'

import { getTimezone } from 'countries-and-timezones'
import { formatAtTimezone, getTimezoneOption, getTimezoneOptions, isValidDate } from '../../../utils/calendar'
import { allowedEventAction } from '../services/allowedEventAction'
import IEventForm from '../types'
import { IPriceTier, ITicketType } from '../types/Tickets'
import { autofillLineup, autofillDefaultTimings } from '../services/autofillDates'
import { authContext } from '../../../context/auth'

const DROP_RECURRENCY_FIELDS = new Set(['date', 'endDate', 'announceDate', 'onSaleDate', 'offSaleDate'])

const adjustForTimezone = (time: string | null, newTz: string): string | null => {
  return time ? formatAtTimezone(parseISO(time), newTz) : time
}

export default function useTimelineDates(readOnly: boolean) {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { isSubmitting, values, setFieldValue, setFieldTouched } = useFormikContext<IEventForm>()

  const [autofillNumber, setAutofillNumber] = useState(0)
  const lastUpdatedDate = useRef<string | null>(null)

  const setDateValue = useCallback(
    (field: string, value: any) => {
      const prevValue = values[field as keyof typeof values] as string | null

      if (prevValue && value && isEqual(parseISO(prevValue), parseISO(value))) {
        return
      }

      setFieldValue(field, value)

      const theDate = field === 'date' ? value : values.date
      const dateIsValid = !!theDate && isValidDate(parseISO(theDate))

      if (theDate && !dateIsValid) {
        console.error('Invalid event date', theDate, parseISO(theDate))
      }

      const wouldAutofill = !readOnly && !isSubmitting

      Sentry.addBreadcrumb({
        category: 'event_timeline',
        message: `Change event ${field}`,
        data: { prevValue, value, wouldAutofill },
        level: 'info',
      })

      if (wouldAutofill) {
        lastUpdatedDate.current = field
        setTimeout(() => setAutofillNumber((v) => v + 1), 0)
      }

      const isAlreadyRecurrent = (values.recurrentEventsGroup?.length || 0) > 1
      if (!readOnly && values.state === 'DRAFT' && !isAlreadyRecurrent && DROP_RECURRENCY_FIELDS.has(field)) {
        Sentry.addBreadcrumb({
          category: 'event_timeline',
          message: 'Drop recurrency',
          data: {},
          level: 'info',
        })

        setFieldValue('recurrentEventSchedule', null)
      }
    },
    [isSubmitting, readOnly, setFieldValue, values]
  )

  // Update any other dates that depend on the last updated date, based on promoter's default timings
  useEffect(() => {
    if (!lastUpdatedDate.current) return
    autofillDefaultTimings(values, lastUpdatedDate.current, setFieldValue, setFieldTouched, readOnly, user.diceStaff)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autofillNumber])

  const handleTimezoneChange = useCallback(
    (newTz: any) => {
      // Since there are many different names for the same time zone (eg. "America/Grand_Turk" === "America/New_York"),
      //  This code checks if the user's selected timezone is the same offset as the venue's timezone.
      //  If so, set venues timezone instead of user's selection.
      //  This should be invisible to the user, but prevents validation errors
      //  where event timezoneName !== venue timezoneName
      //  Even though they're both "(GMT-05:00) Eastern Standard Time".
      const newTzParams = getTimezone(newTz)
      let venueTzOption = null
      let venueTzParams = null

      const venueTzVal = values?.venues?.[0]?.timezoneName
      if (values.venues?.length && venueTzVal) {
        venueTzOption = getTimezoneOption(intl, venueTzVal)
        venueTzParams = getTimezone(venueTzVal)
      }
      if (
        venueTzOption &&
        venueTzParams &&
        newTzParams &&
        newTzParams.utcOffset === venueTzParams.utcOffset &&
        newTzParams.dstOffset === venueTzParams.dstOffset
      ) {
        setFieldValue('timezoneName', venueTzOption.value)
      } else {
        setFieldValue('timezoneName', newTz)
      }

      if (
        values.date &&
        values.lineup &&
        values.timezoneName &&
        values.lineup.length > 0 &&
        allowedEventAction(values.allowedLifecycleUpdates, 'lineUp')
      ) {
        setFieldValue('lineup', autofillLineup(values, compact(values.lineup), newTz))
      }
    },
    [intl, setFieldValue, values]
  )
  const timeZoneValue = useMemo(
    () => getTimezoneOption(intl, values.timezoneName, values.date ? parseISO(values.date) : null),
    [intl, values.date, values.timezoneName]
  )
  const timeZoneOptions = useMemo(
    () => getTimezoneOptions(intl, values.date ? parseISO(values.date) : null),
    [intl, values.date]
  )

  // Update any set times to the same apparent time in a new timezone
  // (ie. 5pm stays 5pm despite change in timezone)
  const adjustTimelineTimesForTimezone = useCallback(
    (newTz: any) => {
      // Update timeline dates if they exist
      setFieldValue('announceDate', adjustForTimezone(values.announceDate, newTz))
      setFieldValue('onSaleDate', adjustForTimezone(values.onSaleDate, newTz))
      setFieldValue('offSaleDate', adjustForTimezone(values.offSaleDate, newTz))
      setFieldValue('date', adjustForTimezone(values.date, newTz))
      setFieldValue('endDate', adjustForTimezone(values.endDate, newTz))
      setFieldValue('closeEventDate', adjustForTimezone(values.closeEventDate, newTz))
      setFieldValue('onSaleNotificationAt', adjustForTimezone(values.onSaleNotificationAt, newTz))
      setFieldValue('doorlistSendAt', adjustForTimezone(values.doorlistSendAt, newTz))
      setFieldValue('offSaleSentAt', adjustForTimezone(values.offSaleSentAt, newTz))
      setFieldValue('diceStreamRewatchEnabledUntil', adjustForTimezone(values.diceStreamRewatchEnabledUntil, newTz))
      if (values.recurrentEventSchedule?.until) {
        setFieldValue('recurrentEventSchedule.until', adjustForTimezone(values.recurrentEventSchedule.until, newTz))
      }

      // Update venueSchedules if they exist
      if (values.venueSchedules && values.venueSchedules.length) {
        const newVenueSchedules = map((schedule) => {
          if (schedule && (schedule.date || schedule.endDate)) {
            const newSchedule = {
              ...schedule,
              date: adjustForTimezone(schedule.date, newTz),
              endDate: adjustForTimezone(schedule.endDate, newTz),
            }
            return newSchedule
          } else {
            return schedule
          }
        }, values.venueSchedules)
        setFieldValue('venueSchedules', newVenueSchedules)
      }

      // Update ticket type times if they exist
      if (values.ticketTypes?.length) {
        const newTtys = map((tty: ITicketType) => {
          const newTty: ITicketType = {
            ...tty,
            announceDate: adjustForTimezone(tty.announceDate, newTz),
            onSaleDate: adjustForTimezone(tty.onSaleDate, newTz),
            offSaleDate: adjustForTimezone(tty.offSaleDate, newTz),
            startDate: adjustForTimezone(tty.startDate, newTz),
            endDate: adjustForTimezone(tty.endDate, newTz),
          }
          if (tty.priceTierType === 'time' && tty.priceTiers?.length) {
            newTty.priceTiers = map((tier: IPriceTier | null) => {
              if (tier === null) {
                return null
              }
              return {
                ...tier,
                time: adjustForTimezone(tier?.time, newTz),
              } as IPriceTier
            }, tty.priceTiers)
          }
          return newTty
        }, values.ticketTypes)
        setFieldValue('ticketTypes', newTtys)
      }
    },
    [
      setFieldValue,
      values.announceDate,
      values.closeEventDate,
      values.date,
      values.diceStreamRewatchEnabledUntil,
      values.doorlistSendAt,
      values.endDate,
      values.offSaleDate,
      values.offSaleSentAt,
      values.onSaleDate,
      values.onSaleNotificationAt,
      values.recurrentEventSchedule,
      values.ticketTypes,
      values.venueSchedules,
    ]
  )

  // If timezoneName changes from null, update all previously set times
  //  to the new timezone so that the times set by user don't change from user's POV
  const previousTimezoneName = useRef(values.timezoneName)
  useEffect(() => {
    if (previousTimezoneName.current === null && values.timezoneName) {
      adjustTimelineTimesForTimezone(values.timezoneName)
    }
    previousTimezoneName.current = values.timezoneName
  }, [adjustTimelineTimesForTimezone, values.timezoneName])

  return {
    timeZoneValue,
    timeZoneOptions,
    handleTimezoneChange,
    setDateValue,
  }
}
