import React, { FC, memo, useContext } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { parseISO } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import { FormRow } from '../../../components/Form'
import FormGroup from '../../../components/FormGroup'
import { breakpoints, color, font, mediaQuery } from '../../../utils/variables'
import Svg from '../../../components/Svg'
import Button from '../../../components/Button'
import IconButton from '../../../components/IconButton'
import useEventFeatured from '../hooks/useEventFeatured'
import EventFeatureModal from '../../../components/Event/EventFeatureModal'

import IEventFormInformation from '../types/Information'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { localeContext } from '../../../context/locale'
import { formatLocation } from '../../../utils/formatters/location'
import { textStyle } from '../../../utils/typography'

const EmptyState = styled.div`
  display: flex;
  align-items: center;

  border: 2px solid ${color.lightgrey};
  border-radius: 4px;

  padding: 28px 32px;

  ${Button} {
    min-width: 130px;
  }

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;

    padding: 24px 16px;
  `}
`

const Icon = styled.div<{ small?: boolean }>`
  width: ${({ small }) => (small ? 40 : 48)}px;
  height: ${({ small }) => (small ? 40 : 48)}px;
  min-width: ${({ small }) => (small ? 40 : 48)}px;

  border: 2px solid ${color.text};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Main = styled.div`
  flex: 1;

  margin: 0 32px;

  color: ${color.greyer};

  & > strong {
    display: block;
    margin-bottom: 8px;

    color: ${color.text};
    ${textStyle.functional.lg}
  }

  ${mediaQuery.lessThan('tablet')`
    margin: 16px 0;
    text-align: center;
  `}
`

const Field = styled.div`
  margin: 0 16px;

  min-width: 25%;

  & + & {
    margin-left: 24px;
  }

  white-space: nowrap;

  & > small {
    letter-spacing: 0.01em;
    font-size: ${font.size.sm}px;

    color: ${color.greyer};

    margin-bottom: 4px;
  }

  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${mediaQuery.lessThan('tablet')`
    display: none;
  `}
`

const Placeholder = styled.div`
  flex: 1;
`

const Featured = styled.li`
  display: flex;
  align-items: center;

  padding: 16px 0;

  border-bottom: 1px solid ${color.disabled};

  & > strong {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    flex: 1;

    margin: 0 16px;

    ${textStyle.functional.lg}
  }

  ${IconButton}, ${Button} {
    margin-left: 8px;
  }
`

const FeatureButton = styled.label`
  display: flex;
  align-items: center;

  margin: 16px 0;

  font-weight: bold;
  cursor: pointer;

  ${IconButton} {
    margin-right: 16px;
  }
`

interface IProps {
  readOnly?: boolean
}

const EventFeatured: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { values } = useFormikContext<IEventFormInformation>()

  const {
    featurings,
    doRemove,
    creating,
    openCreateModal,
    closeCreateModal,
    doCreate,
    editingIdx,
    openEditModal,
    closeEditModal,
    doSave,
  } = useEventFeatured()

  return (
    <FormRow columnOnMobile>
      <FormGroup label={intl.formatMessage({ id: 'new_event.information.featured.label' })}>
        {(featurings?.length || 0) === 0 ? (
          <EmptyState>
            <Icon>
              <Svg icon="featured" />
            </Icon>
            <Main>
              <strong>{intl.formatMessage({ id: 'new_event.information.featured.title' })}</strong>
              <div>{intl.formatMessage({ id: 'new_event.information.featured.description' })}</div>
            </Main>
            <Button data-id="createFeatured" disabled={readOnly} onClick={openCreateModal}>
              {intl.formatMessage({ id: 'new_event.information.featured.cta' })}
            </Button>
          </EmptyState>
        ) : (
          <ul>
            {featurings?.map(
              (feat, idx) =>
                feat && (
                  <Featured key={idx} data-id={`featured[${idx}]`}>
                    <Icon small>
                      <Svg icon="featured" />
                    </Icon>
                    <Field>
                      <small>{intl.formatMessage({ id: 'new_event.information.featured.dates.label' })}</small>
                      <div data-id="featuredDates">
                        {!!feat.startDate &&
                          intl.formatDate(parseISO(feat.startDate as string), {
                            ...DATETIME_FORMATS.SHORTEST,
                            timeZone: values.timezoneName || undefined,
                          })}
                        {' · '}
                        {!!feat.endDate &&
                          intl.formatDate(parseISO(feat.endDate as string), {
                            ...DATETIME_FORMATS.SHORT,
                            timeZone: values.timezoneName || undefined,
                          })}
                      </div>
                    </Field>
                    <Field>
                      <small>{intl.formatMessage({ id: 'new_event.information.featured.location.label' })}</small>
                      <div data-id="featuredLocation">{formatLocation(intl, locale, feat)}</div>
                    </Field>

                    {isMobile ? (
                      <strong data-id="featuredLocation">{formatLocation(intl, locale, feat)}</strong>
                    ) : (
                      <Placeholder />
                    )}

                    <Button data-id="editFeatured" data-idx={idx} onClick={openEditModal}>
                      {intl.formatMessage({ id: 'actions.edit' })}
                    </Button>
                    <IconButton
                      icon="trash"
                      data-id="removeFeatured"
                      data-idx={idx}
                      title={intl.formatMessage({ id: 'actions.remove' })}
                      disabled={readOnly}
                      onClick={doRemove}
                    />

                    {editingIdx === idx && (
                      <EventFeatureModal
                        event={values}
                        readOnly={readOnly}
                        featured={feat}
                        onClose={closeEditModal}
                        onSave={doSave}
                      />
                    )}
                  </Featured>
                )
            )}

            {!readOnly && (
              <FeatureButton>
                <IconButton
                  color="black"
                  icon="plus"
                  title={intl.formatMessage({ id: 'new_event.information.featured.cta' })}
                  disabled={readOnly}
                  onClick={openCreateModal}
                  data-id="createFeatured"
                />
                {intl.formatMessage({ id: 'new_event.information.featured.cta' })}
              </FeatureButton>
            )}
          </ul>
        )}
        {creating && !readOnly && <EventFeatureModal event={values} onClose={closeCreateModal} onSave={doCreate} />}
      </FormGroup>
    </FormRow>
  )
}

export default memo(EventFeatured)
