import React, { FC, memo, useCallback, useContext } from 'react'
import { compose, concat, indexOf, uniq, without } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { FormikContextType } from 'formik'

import Collapsible from '../../../components/Collapsible'
import { allowedEventAction } from '../services/allowedEventAction'
import { ICountrySpecificEvent } from '../../../utils/isCountryEvent'
import { FormRow } from '../../../components/Form'
import SwitchField from '../../../components/SwitchField'
import { ITicketType } from '../types/Tickets'
import { EventState } from '../../../enums.generated'
import { authContext } from '../../../context/auth'
import { featureFlagsContext } from '../../../context/featureFlags'

interface IProps extends Pick<FormikContextType<ITicketType>, 'values' | 'setFieldValue'> {
  event: (ICountrySpecificEvent & { state: EventState | null; date: string | null }) | null | undefined
  allowedLifecycleUpdates: {} | null
  readOnly?: boolean
}

const EventTicketTypePaymentMethods: FC<IProps> = ({
  event,
  readOnly,
  allowedLifecycleUpdates,
  values,
  setFieldValue,
}) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)

  const setAdditionalPaymentMethod = useCallback(
    (e: any) => {
      const method = e.target.dataset.id
      const checked = e.target.checked

      if (checked) {
        setFieldValue(
          'additionalPaymentMethods',
          compose([
            without(
              method === 'AFTERPAY_CLEARPAY' ? ['DICE_SPLIT'] : method === 'DICE_SPLIT' ? ['AFTERPAY_CLEARPAY'] : []
            ),
            concat([method]),
            uniq,
          ])(values.additionalPaymentMethods || [])
        )
      } else {
        setFieldValue(
          'additionalPaymentMethods',
          compose([without([method]), uniq])(values.additionalPaymentMethods || [])
        )
      }
    },
    [setFieldValue, values.additionalPaymentMethods]
  )

  const isDiceSplitAvailable = user.diceStaff && hasFeatureFlag('diceSplitInPaymentMethods')

  return (
    <Collapsible
      dice
      label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.additional_payment_methods.label' })}
      initialCollapsed={(values.additionalPaymentMethods?.length || 0) === 0}
    >
      <FormRow columnOnMobile>
        <SwitchField
          label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.clearpay.label' })}
          hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.clearpay.hint' })}
          data-id="AFTERPAY_CLEARPAY"
          name="additionalPaymentMethodClearpay"
          checked={indexOf('AFTERPAY_CLEARPAY', values.additionalPaymentMethods) !== -1}
          disabled={readOnly || (!allowedEventAction(allowedLifecycleUpdates, 'forbidden') && event?.state !== 'DRAFT')}
          onChange={setAdditionalPaymentMethod}
        />
      </FormRow>

      {isDiceSplitAvailable && (
        <FormRow columnOnMobile>
          <SwitchField
            label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.dice_split.label' })}
            hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.dice_split.hint' })}
            data-id="DICE_SPLIT"
            name="additionalPaymentMethodDiceSplit"
            checked={indexOf('DICE_SPLIT', values.additionalPaymentMethods) !== -1}
            disabled={
              readOnly || (!allowedEventAction(allowedLifecycleUpdates, 'forbidden') && event?.state !== 'DRAFT')
            }
            onChange={setAdditionalPaymentMethod}
            dice
          />
        </FormRow>
      )}
    </Collapsible>
  )
}

export default memo(EventTicketTypePaymentMethods)
