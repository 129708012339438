/**
 * @generated SignedSource<<f47feca99e0b6bd7cafa57d8c4e310ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type ChangePoolCapacityModalQuery$variables = {
  id: string;
};
export type ChangePoolCapacityModalQuery$data = {
  readonly event: {
    readonly allowedLifecycleUpdates?: {
      readonly ticketPools: {
        readonly canChangeAllocation: boolean | null;
        readonly canUpdate: boolean | null;
      } | null;
    } | null;
    readonly eventType?: EventType | null;
    readonly id?: string;
    readonly sales?: {
      readonly ticketTypesBreakdown: ReadonlyArray<{
        readonly ticketType: {
          readonly archived: boolean | null;
          readonly id: string;
          readonly ticketPoolId: string | null;
        };
        readonly totalAppSold: number;
        readonly totalDigitalValue: number;
        readonly totalPosSold: number;
        readonly totalReserved: number;
        readonly totalTerminalSold: number;
      } | null>;
    } | null;
    readonly ticketPools?: ReadonlyArray<{
      readonly id: string;
      readonly maxAllocation: number | null;
      readonly name: string | null;
    } | null> | null;
  } | null;
};
export type ChangePoolCapacityModalQuery = {
  response: ChangePoolCapacityModalQuery$data;
  variables: ChangePoolCapacityModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketPool",
  "kind": "LinkedField",
  "name": "ticketPools",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxAllocation",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AllowedLifecycleUpdates",
  "kind": "LinkedField",
  "name": "allowedLifecycleUpdates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdatesTicketPools",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canChangeAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Sales",
  "kind": "LinkedField",
  "name": "sales",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketTypeBreakdown",
      "kind": "LinkedField",
      "name": "ticketTypesBreakdown",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalAppSold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPosSold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalTerminalSold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalReserved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalDigitalValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketType",
          "kind": "LinkedField",
          "name": "ticketType",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archived",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketPoolId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePoolCapacityModalQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePoolCapacityModalQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0356ceb3b9a24573097b79b98c9c496",
    "id": null,
    "metadata": {},
    "name": "ChangePoolCapacityModalQuery",
    "operationKind": "query",
    "text": "query ChangePoolCapacityModalQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ... on Event {\n      id\n      ticketPools {\n        id\n        name\n        maxAllocation\n      }\n      allowedLifecycleUpdates {\n        ticketPools {\n          canUpdate\n          canChangeAllocation\n        }\n      }\n      eventType\n      sales {\n        ticketTypesBreakdown {\n          totalAppSold\n          totalPosSold\n          totalTerminalSold\n          totalReserved\n          totalDigitalValue\n          ticketType {\n            id\n            archived\n            ticketPoolId\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "29151754720051554946d684644069ab";

export default node;
