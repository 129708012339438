import React, { FC } from 'react'
import RealAutosizeInput from 'react-input-autosize'

type IProps = RealAutosizeInput['props']

const InputAutosize: FC<React.PropsWithChildren<IProps & { className?: string }>> = ({ className, ...props }) => (
  <RealAutosizeInput inputClassName={className} {...(props as any)} />
)

export default InputAutosize
