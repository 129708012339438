import React, { FC, memo, useContext, useMemo } from 'react'
import { find } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { DiceBadgeOverlay } from '../../../../components/DiceBadge'
import { authContext } from '../../../../context/auth'
import useEventListType from '../../hooks/useEventListType'
import { SIDEBAR_ROUTER } from '../../services/sidebarNav'
import { SidebarList, SidebarListName, SidebarMenu, SidebarMenuIcon, SidebarMenuItem } from './Styles'

const Events: FC = () => {
  const intl = useIntl()
  const listType = useEventListType()
  const { user, account, hasPermission, availableAccounts } = useContext(authContext)
  const { pathname } = useLocation()

  const isCollaborator = useMemo(
    () => find(['id', account?.id], availableAccounts)?.membershipType === 'COLLABORATORS',
    [account?.id, availableAccounts]
  )

  return (
    <SidebarList>
      <SidebarListName>
        <span>{intl.formatMessage({ id: 'sidebar.events' })}</span>
      </SidebarListName>
      <SidebarMenu>
        <SidebarMenuItem active={listType === 'live'} to={SIDEBAR_ROUTER.events.live[0]} data-id="sidebarLive">
          <SidebarMenuIcon icon="date-picker" />
          <span>{intl.formatMessage({ id: 'sidebar.live' })}</span>
        </SidebarMenuItem>
        {(hasPermission('create:event') || isCollaborator) && (
          <SidebarMenuItem active={listType === 'draft'} to={SIDEBAR_ROUTER.events.drafts[0]} data-id="sidebarDrafts">
            <SidebarMenuIcon icon="edit" />
            <span>{intl.formatMessage({ id: 'sidebar.drafts' })}</span>
          </SidebarMenuItem>
        )}
        <SidebarMenuItem active={listType === 'past'} to={SIDEBAR_ROUTER.events.past[0]} data-id="sidebarPast">
          <SidebarMenuIcon icon="check" />
          <span>{intl.formatMessage({ id: 'sidebar.past' })}</span>
        </SidebarMenuItem>
        <SidebarMenuItem
          active={listType === 'cancelled'}
          to={SIDEBAR_ROUTER.events.cancelled[0]}
          data-id="sidebarCancelled"
        >
          <SidebarMenuIcon icon="archive" />
          <span>{intl.formatMessage({ id: 'sidebar.cancelled' })}</span>
        </SidebarMenuItem>
        {user.diceStaff && (
          <SidebarMenuItem
            active={listType === 'submission'}
            to={SIDEBAR_ROUTER.events.submissions[0]}
            data-id="sidebarSubmissions"
          >
            <DiceBadgeOverlay>
              <SidebarMenuIcon icon="flag" />
            </DiceBadgeOverlay>
            <span>{intl.formatMessage({ id: 'sidebar.submissions' })}</span>
          </SidebarMenuItem>
        )}
        {user.diceStaff && hasPermission('read:balances') && (
          <SidebarMenuItem
            active={pathname === SIDEBAR_ROUTER.events.payouts[0]}
            to={SIDEBAR_ROUTER.events.payouts[0]}
            data-id="sidebarPayouts"
          >
            <DiceBadgeOverlay>
              <SidebarMenuIcon icon="coins" />
            </DiceBadgeOverlay>
            <span>{intl.formatMessage({ id: 'sidebar.payouts' })}</span>
          </SidebarMenuItem>
        )}
      </SidebarMenu>
    </SidebarList>
  )
}

export default memo(Events)
