import { useCallback, useMemo } from 'react'
import { compact, concat, find, isString, without, isEqual, filter, uniqBy } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'

import IEventFormBasics from '../types/Basics'
import { allowedEventAction } from '../services/allowedEventAction'
import { getDefaultLineup } from '../services/getDefaultEvent'
import { autofillLineup } from '../services/autofillDates'
import IEventForm from '../types'
import { ILineup } from '../components/EventSchedule'

type IAdditionalArtist = NonNullable<NonNullable<IEventFormBasics['additionalArtists']>[number]>

function useAdditionalArtists() {
  const intl = useIntl()
  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormBasics>()

  const allowEditLineup = useMemo(
    () => values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'lineUp'),
    [values.allowedLifecycleUpdates, values.state]
  )

  const additionalArtists = useMemo(() => compact(values.additionalArtists || []), [values.additionalArtists])
  const addAdditionalArtist = useCallback(
    ({ name, description = null, hierarchicalTags = null }: Partial<IAdditionalArtist>) => {
      const v = name || ''

      if (!isString(v)) {
        console.error(new Error(`Attempt to set non-string additional artist ${name}`))
        return
      }

      const newValue = concat(additionalArtists, { id: null, name: v, description, isNew: true, hierarchicalTags })

      setFieldValue('additionalArtists', newValue)

      if (allowEditLineup) {
        const normalizedLineup: ILineup[] =
          !values.lineup || values.lineup.length === 0
            ? autofillLineup(values as IEventForm, getDefaultLineup(intl), values.timezoneName)
            : compact(values.lineup)

        const lineupItem = find(['details', v], normalizedLineup)
        if (!lineupItem) {
          setFieldValue(
            'lineup',
            concat(normalizedLineup as ILineup[], {
              details: v,
              time: '',
            })
          )
        }
      }

      if (hierarchicalTags && hierarchicalTags.length) {
        setFieldValue('hierarchicalTags', uniqBy('value', concat(values.hierarchicalTags || [], hierarchicalTags)))
      }
    },
    [additionalArtists, allowEditLineup, intl, setFieldValue, values]
  )

  const updateAdditionalArtist = useCallback(
    (artist: Partial<IAdditionalArtist>, updates: Partial<IAdditionalArtist>) => {
      const newValue = additionalArtists.map((art) => (isEqual(artist, art) ? { ...art, ...updates } : art))

      setFieldValue('additionalArtists', newValue)

      if (allowEditLineup && values.lineup?.length) {
        setFieldValue(
          'lineup',
          values.lineup.map((item) => (item?.details === artist.name ? { ...item, details: updates.name } : item))
        )
        setFieldTouched('lineup', true, true)
      }

      if (updates.hierarchicalTags && updates.hierarchicalTags.length) {
        setFieldValue(
          'hierarchicalTags',
          uniqBy('value', concat(values.hierarchicalTags || [], updates.hierarchicalTags))
        )
      }
    },
    [additionalArtists, allowEditLineup, setFieldTouched, setFieldValue, values.hierarchicalTags, values.lineup]
  )

  const removeAdditionalArtist = useCallback(
    (artist: IAdditionalArtist) => {
      setFieldValue('additionalArtists', without([artist], additionalArtists))
      setFieldTouched('additionalArtists', true, true)

      if (allowEditLineup) {
        const normalizedLineup =
          !values.lineup || values.lineup.length === 0
            ? autofillLineup(values as IEventForm, getDefaultLineup(intl), values.timezoneName)
            : compact(values.lineup)

        const lineupItem = find(['details', artist.name], normalizedLineup)
        if (lineupItem) {
          setFieldValue('lineup', without([lineupItem], normalizedLineup))
          setFieldTouched('lineup', true, true)
        }
      }

      setTimeout(() => validateForm(), 0)
    },
    [additionalArtists, allowEditLineup, intl, setFieldTouched, setFieldValue, validateForm, values]
  )

  return {
    additionalArtists,
    addAdditionalArtist,
    updateAdditionalArtist,
    removeAdditionalArtist,
  }
}

export default useAdditionalArtists
