import graphql from 'babel-plugin-relay/macro'
import { fetchQuery_DEPRECATED } from 'relay-runtime'
import { get, getOr, map, debounce } from 'lodash/fp'
import { Environment } from 'react-relay'

import { trackSearchSpotifyQuery } from '../__generated__/trackSearchSpotifyQuery.graphql'
import { trackSearchAppleMusicQuery } from '../__generated__/trackSearchAppleMusicQuery.graphql'
import { trackSearchArtistSpotifyQuery } from '../__generated__/trackSearchArtistSpotifyQuery.graphql'

export const TRACK_SEARCH_DEBOUNCE_MS = 1000

export interface ITrack {
  open_url?: string
  preview_url?: string
  image?: string
  name: string
}

export type ICallback = (value: (ITrack & { value: string; label: string })[]) => void

export const searchSpotifyArtistImmediately = (environment: Environment, str: string, callback: ICallback) => {
  const promise = fetchQuery_DEPRECATED<trackSearchArtistSpotifyQuery>(
    environment,
    graphql`
      query trackSearchArtistSpotifyQuery($q: String!) {
        spotify {
          search(limit: 10, q: $q, type: [artist]) {
            artists {
              items {
                value: id
                name
                externalUrls {
                  spotify
                }
                images {
                  url
                }
              }
            }
          }
        }
      }
    `,
    { q: str }
  )

  promise
    .then((results) => {
      callback(
        map(
          ({ value, name, externalUrls: { spotify }, images, ...artist }: any) => ({
            ...artist,
            artist_id: value,
            open_url: spotify,
            image: get('0.url', images),
            label: name,
            name,
          }),
          getOr([], 'spotify.search.artists.items', results)
        )
      )
    })
    .catch((err) => {
      console.error(err)
      callback([])
    })
}
export const searchSpotifyArtistFn = (environment: Environment) =>
  debounce(TRACK_SEARCH_DEBOUNCE_MS, (str: string, callback: ICallback) =>
    searchSpotifyArtistImmediately(environment, str, callback)
  )

export const searchSpotifyTrackImmediately = (environment: Environment, str: string, callback: ICallback) => {
  const promise = fetchQuery_DEPRECATED<trackSearchSpotifyQuery>(
    environment,
    graphql`
      query trackSearchSpotifyQuery($q: String!) {
        spotify {
          search(limit: 10, q: $q, type: [track]) {
            tracks {
              items {
                value: id
                name
                preview_url: previewUrl
                externalUrls {
                  spotify
                }
                artists {
                  name
                  images {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
    { q: str }
  )

  promise
    .then((results) => {
      callback(
        map(
          ({ name, externalUrls: { spotify }, artists, ...track }: any) => ({
            ...track,
            open_url: spotify,
            image: get('0.images.0.url', artists),
            label: `${artists && artists[0] ? `${artists[0].name} - ` : ''}${name}`,
            name,
          }),
          getOr([], 'spotify.search.tracks.items', results)
        )
      )
    })
    .catch((err) => {
      console.error(err)
      callback([])
    })
}

export const searchSpotifyTrackFn = (environment: Environment) =>
  debounce(TRACK_SEARCH_DEBOUNCE_MS, (str: string, callback: ICallback) =>
    searchSpotifyTrackImmediately(environment, str, callback)
  )

export const searchAppleMusicTrackImmediately = (environment: Environment, str: string, callback: ICallback) => {
  if (!str || str.length < 2) {
    callback([])
    return
  }

  const promise = fetchQuery_DEPRECATED<trackSearchAppleMusicQuery>(
    environment,
    graphql`
      query trackSearchAppleMusicQuery($q: String!) {
        appleMusic {
          search(q: $q) {
            results {
              value: openUrl
              label: name
              image
              preview_url: previewUrl
              open_url: openUrl
            }
          }
        }
      }
    `,
    { q: str }
  )

  promise
    .then((results) => {
      callback(getOr([], 'appleMusic.search.results', results))
    })
    .catch((err) => {
      console.error(err)
      callback([])
    })
}
export const searchAppleMusicTrackFn = (environment: Environment) =>
  debounce(TRACK_SEARCH_DEBOUNCE_MS, (str: string, callback: ICallback) =>
    searchAppleMusicTrackImmediately(environment, str, callback)
  )
