import React, { FC, useMemo } from 'react'
import { compact, some, reject } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'

import styled from 'styled-components/macro'

import { breakpoints, spacing, mediaQuery } from '../../../../utils/variables'
import { CURRENCY } from '../../../../utils/formatters/number'

import Value from '../../../../components/Value'
import { Text } from '../../../../components/Text'

import { TicketBreakdown_event$data } from '../../../../__generated__/TicketBreakdown_event.graphql'

export const SalesWrapper = styled.div`
  display: flex;

  ${mediaQuery.lessThan('desktop')`
  margin-top: ${spacing.xs}px;
  flex-direction: column;
`}
`

export const SplitSection = styled.div`
  & + & {
    margin-top: ${spacing.xs}px;
  }

  ${mediaQuery.lessThan('desktop')`
  & + & {
    margin-top: ${spacing.xs}px;
  }
`}
`

export const SalesSplit = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQuery.lessThan('desktop')`
    margin: ${spacing.xs}px 0 0 0;
  `}
`

interface IProps {
  event: TicketBreakdown_event$data
  ticketType: NonNullable<NonNullable<NonNullable<TicketBreakdown_event$data['sales']>['ticketTypesBreakdown']>[number]>
}

const TicketSales: FC<React.PropsWithChildren<IProps>> = ({ event, ticketType: tt }) => {
  const intl = useIntl()

  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.desktop}px)` })

  const ttys = useMemo(
    () => reject('ticketType.archived', compact(event.sales?.ticketTypesBreakdown || [])),
    [event.sales]
  )

  const hasBoxOffice = useMemo(() => some((tty) => tty.ticketType.doorSalesEnabled, ttys), [ttys])

  return (
    <SalesWrapper>
      {hasBoxOffice ? (
        <SalesSplit>
          <SplitSection>
            <Text color="darkgrey">
              {intl.formatMessage({ id: 'event_overview.ticket_breakdown.sales_digital.label' })}
            </Text>{' '}
            <Text>
              {intl.formatNumber(tt.totalAppSold || 0, { maximumFractionDigits: 0 })}
              {'・'}
              {intl.formatNumber(
                (tt.totalDigitalValue || 0) / 100,
                CURRENCY(tt.totalDigitalValue || 0, event.costCurrency)
              )}
            </Text>
          </SplitSection>
          <SplitSection>
            <Text color="darkgrey">
              {intl.formatMessage({ id: 'event_overview.ticket_breakdown.sales_box_office.label' })}
            </Text>{' '}
            <Text>
              {intl.formatNumber(tt.totalPosSold || 0, { maximumFractionDigits: 0 })}
              {'・'}
              {intl.formatNumber(
                tt.totalPosValue / 100,
                CURRENCY(
                  tt.totalPosValue || (tt.totalPosSold || 0) * (tt.ticketType.doorSalesPrice || 0),
                  event.costCurrency
                )
              )}
            </Text>
          </SplitSection>
        </SalesSplit>
      ) : (
        <Value
          compact={isTablet}
          label={intl.formatMessage({ id: 'event_overview.ticket_breakdown.total_face_value.label' })}
          descriptionFontSize="base"
          value={intl.formatNumber(
            (tt.totalDigitalValue || 0) / 100,
            CURRENCY(tt.totalDigitalValue, event.costCurrency)
          )}
        />
      )}
    </SalesWrapper>
  )
}

export default TicketSales
