import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { every, isEmpty, lowerCase } from 'lodash/fp'

import { parseISO } from 'date-fns'
import { color, font } from '../utils/variables'
import { authContext } from '../context/auth'
import IconButton from './IconButton'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'
import { localeContext } from '../context/locale'
import NotesViewer, { INotesViewerProps } from './NotesViewer'
import { textStyle } from '../utils/typography'

export const NOTES_PANEL_WIDTH = 435

interface IProps extends INotesViewerProps {
  event?: {
    eventIdLive?: string | null
    billingPromoter?: {
      name: string | null
      tier: string | null
    } | null
    createdBy?: {
      email: string | null
      firstName: string | null
      lastName: string | null
    } | null
    id?: string
    name?: string | null
    state?: string | null
    submittedAt?: string | null
  } | null

  onClose: () => void
}

const NotesPanel: FC<React.PropsWithChildren<IProps>> = ({ event, onClose, ...notesViewerProps }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const shouldShowInfoBox = event?.state || event?.submittedAt || !!event?.createdBy || event?.billingPromoter?.name

  const state = (event?.state === 'APPROVED' && event?.eventIdLive ? 'LIVE' : event?.state) || 'DRAFT'

  return (
    <Container>
      <CloseButton icon="close-view" onClick={onClose} alt={intl.formatMessage({ id: 'actions.close' })} />
      {shouldShowInfoBox && (
        <GreyBox>
          {event?.state && (
            <>
              <dt>{intl.formatMessage({ id: 'state' })}</dt>
              <dd>
                {intl.formatMessage({
                  id: state === 'REVIEW' ? 'event_state.in_review' : `event_workflow.states.${lowerCase(state)}`,
                })}
              </dd>
            </>
          )}
          {event?.submittedAt && (
            <>
              <dt>{intl.formatMessage({ id: 'event_list.heading.submitted_at' })}</dt>
              <dd>
                {intl.formatDate(parseISO(event.submittedAt), {
                  ...DATETIME_FORMATS.MEDIUM,
                  timeZone: user.timezoneName || undefined,
                })}
                {' · '}
                {intl.formatDate(parseISO(event.submittedAt), {
                  ...DATETIME_FORMATS.TIME(locale),
                  timeZone: user.timezoneName || undefined,
                })}
              </dd>
            </>
          )}
          {event?.createdBy && (
            <>
              <dt>{intl.formatMessage({ id: 'event_list.heading.created_by' })}</dt>
              <dd>
                {`${event?.createdBy?.firstName} ${event?.createdBy?.lastName}`.trim()}
                {' · '}
                {event?.createdBy?.email}
              </dd>
            </>
          )}

          <dt>{intl.formatMessage({ id: 'account' })}</dt>
          <dd>
            {event?.billingPromoter?.name}
            {event?.billingPromoter?.tier &&
              ' · ' + intl.formatMessage({ id: `promoter_tier.${lowerCase(event?.billingPromoter?.tier || '')}` })}
          </dd>
        </GreyBox>
      )}
      <NotesViewer {...notesViewerProps} border />
    </Container>
  )
}

export default memo(NotesPanel)

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: ${NOTES_PANEL_WIDTH}px;
  width: ${NOTES_PANEL_WIDTH}px;
  padding: 32px;
  background-color: ${color.white};
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`

const GreyBox = styled.dl`
  background-color: ${color.palegrey};
  border-radius: 4px;
  padding: 16px;
  margin: 48px 0 32px;
  display: flex;
  flex-wrap: wrap;

  dt,
  dd {
    margin: 0 0 16px 0;
    ${textStyle.functional.sm}

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dt {
    color: ${color.greyer};
    flex-basis: 30%;
  }

  dd {
    flex-grow: 1;
    flex-basis: 45%;
  }
`
