import React, { FC, memo, useState, useCallback, useMemo, ComponentProps, ReactNode } from 'react'
import styled from 'styled-components/macro'
import cn from 'classnames'
import ReactSelect, { components, OptionsType } from 'react-select'
import ReactSelectCreatable from 'react-select/creatable'
import ReactSelectAsync from 'react-select/async'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import { concat, map, set, find, merge, omit, always, isFunction, reject, keyBy, isArray, noop } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { nanoid } from 'nanoid'
import { Merge } from 'ts-essentials'

import Svg from '../Svg'
import { Loader } from '../Loader'
import { color } from '../../utils/variables'
import { ClearIcon } from '../FilterStyles'

const SearchIcon = styled(Svg)`
  position: absolute;
  top: 8px;
  left: 8px;
  background: ${color.white};
`

const IconContainer = styled(components.SelectContainer)`
  position: relative;

  &.-disabled {
    background-color: ${color.palegrey};
    ${SearchIcon} {
      background-color: ${color.palegrey};
    }
  }

  .react-select__control {
    padding-left: 20px;
  }

  .react-select__value-container--is-multi.react-select__value-container--has-value {
    padding-left: 12px;
  }
`

const CreateOption = styled.div`
  padding: 16px;
  border-top: 1px solid ${color.lightgrey};
  margin: -8px -16px;
  background-color: transparent;
  cursor: pointer;
`

const Option = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    word-break: break-word;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  & > div + div {
    margin-left: 16px;
  }
`

const Hint = styled.div`
  color: ${color.darkgrey};
  text-align: right;

  && {
    flex: 2;
  }
`

const RemoveIcon = styled(Svg)`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`

type IOption = {
  label?: ReactNode | null
  value?: string | number | null
  hint?: string | null
  options?: OptionsType<{ label?: string | null; value: string | null; hint?: string | null }>
}

type IOptions = OptionsType<IOption>

type IRawProps =
  | ComponentProps<typeof ReactSelectAsyncCreatable<IOption, true>>
  | ComponentProps<typeof ReactSelectAsyncCreatable<IOption, false>>
  | ComponentProps<typeof ReactSelectAsync<IOption>>
  | ComponentProps<typeof ReactSelectCreatable<IOption>>
  | ComponentProps<typeof ReactSelect<IOption>>

interface IOwnProps {
  id?: string
  className?: string
  options?: IOptions
  readOnlyValues?: IOptions | null
  name?: string
  defaultValue?: any
  disabled?: boolean
  required?: boolean
  hasError?: boolean
  size?: string
  placeholder?: string | null
  multiple?: boolean
  allowCreate?: boolean
  clearable?: boolean
  searchable?: boolean
  loading?: boolean
  inputRef?: () => void
  async?: boolean
  appendOnly?: boolean
  defaultOptions?: true | IOptions
  createOptionLabel?: ReactNode | ((input: string) => ReactNode)
  alwaysShowCreateOption?: boolean
  optionLabel?: any
  components?: any
  value?: any
  onBlur?: (e: any) => void
  onChange?: (ids: any, opt: any) => void
  onChangeOption?: (opt: any) => void
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsType<{ label?: string | null; value: string | null; hint?: string | null }>) => void
  ) => Promise<any> | void
}

export type ISelectProps = Merge<
  Pick<
    IRawProps,
    | 'hideSelectedOptions'
    | 'isLoading'
    | 'onCreateOption'
    | 'autoFocus'
    | 'onInputChange'
    | 'menuIsOpen'
    | 'menuPlacement'
    | 'menuPortalTarget'
  >,
  IOwnProps
>

const Select: FC<React.PropsWithChildren<ISelectProps>> = ({
  id,
  className,
  disabled,
  required,
  hasError,
  size,
  multiple,
  allowCreate,
  searchable,
  options,
  clearable,
  inputRef,
  onChange,
  async,
  name,
  createOptionLabel,
  alwaysShowCreateOption,
  defaultOptions,
  optionLabel,
  appendOnly,
  value,
  readOnlyValues,
  placeholder,
  menuPlacement,
  components: customComponentsProp,
  ...rest
}) => {
  const intl = useIntl()
  const noOptions = useCallback(
    (inputValue: string) =>
      inputValue || !async
        ? intl.formatMessage({ id: 'select.no_results' })
        : intl.formatMessage({ id: 'select.start_typing' }),
    [async, intl]
  )
  const createLabel = useCallback(
    (input: any) => (
      <CreateOption>{isFunction(createOptionLabel) ? createOptionLabel(input) : createOptionLabel}</CreateOption>
    ),
    [createOptionLabel]
  )

  const formatOptionLabel = useCallback(
    ({ value, label, hint, i18n }: any) => (
      <Option>
        <div data-option={value}>{i18n ? intl.formatMessage({ id: i18n }) : label}</div>
        {hint && <Hint>{hint}</Hint>}
      </Option>
    ),
    [intl]
  )

  const [selectValue, setSelectValue] = useState(undefined)

  const handleChange = useCallback(
    (newValue: any, { action, removedValue, option }: any) => {
      if (onChange) {
        if (multiple && isArray(newValue)) {
          const readOnlyIds = new Set(map('value', readOnlyValues || []))
          const opt = removedValue || find(['value', option.value], value)
          const canRemove = appendOnly ? !!opt?.isNew : !readOnlyIds.has(opt?.value)

          if (!canRemove && (action === 'pop-value' || action === 'deselect-option' || action === 'remove-value')) {
            return
          } else if ((!canRemove || appendOnly) && action === 'clear') {
            return
          }

          const oldOnes = keyBy('value', value)

          onChange(
            map('value', newValue),
            map((v) => {
              if (oldOnes[v.value]) return v
              else return set('isNew', true, v)
            }, newValue) as any
          )
        } else {
          let v = newValue ? (newValue as any).value : ''
          if (clearable) {
            v = selectValue === v ? undefined : v
          }
          onChange(v, newValue)
          setSelectValue(v)
        }
      }
    },
    [appendOnly, clearable, multiple, onChange, readOnlyValues, selectValue, value]
  )

  const selectProps = useMemo(
    () =>
      merge(
        {
          id,
          className: cn('react-select', {
            '-disabled': disabled,
            '-required': required,
            '-has-error': hasError,
            [`-size-${size}`]: !!size,
            [className || '']: !!className,
          }),
          classNamePrefix: 'react-select',
          onChange: handleChange,
          options,
          defaultOptions,
          isMulti: multiple,
          hideSelectedOptions: false,
          ref: inputRef,
          isSearchable: searchable || false,
          isClearable: clearable || false,
          blurInputOnSelect: !multiple,
          inputId: name || nanoid(),
          name,
          formatCreateLabel: createLabel,
          formatOptionLabel: optionLabel || formatOptionLabel,
          menuPlacement,
          value: readOnlyValues ? concat(readOnlyValues, value) : value,
          noOptionsMessage: ({ inputValue }: any) => noOptions(inputValue),
          loadingMessage: always(intl.formatMessage({ id: 'loading' })),
          getOptionLabel: (option: any) => (option.i18n ? intl.formatMessage({ id: option.i18n }) : option.label),
          placeholder: placeholder || intl.formatMessage({ id: 'please_select' }),
          isValidNewOption: alwaysShowCreateOption ? (inputValue: string) => !!inputValue : undefined,
        },
        omit(['id', 'onChange', 'async'], rest)
      ),
    [
      id,
      disabled,
      required,
      hasError,
      size,
      className,
      handleChange,
      options,
      defaultOptions,
      multiple,
      inputRef,
      searchable,
      clearable,
      name,
      createLabel,
      optionLabel,
      formatOptionLabel,
      menuPlacement,
      readOnlyValues,
      value,
      intl,
      placeholder,
      alwaysShowCreateOption,
      rest,
      noOptions,
    ]
  )

  const selectComponents = useMemo(
    () => ({
      DropdownIndicator:
        searchable && !options
          ? always(null)
          : ({ isOpen }: { isOpen: boolean }) => (
            <Svg icon="chevron-select" className={cn('Select-arrow-icon', isOpen && '-open')} />
          ),
      ClearIndicator: clearable
        ? ({ clearValue }: { clearValue: () => void }) => <ClearIcon icon="close-view" onClick={clearValue} />
        : always(null),
      LoadingIndicator: always(<Loader />),
      Menu: (props: any) => (
        <components.Menu
          {...props}
          className={cn(props.className, {
            '-top': menuPlacement === 'top',
          })}
        />
      ),
      MenuList: ({ children, ...props }: any) => {
        const visibleOptions = reject('__isNew__', props.options || [])
        const newOption = find('__isNew__', props.options || [])
        const inputValue = props?.selectProps?.inputValue || ''
        return (
          <components.MenuList {...props}>
            {allowCreate && visibleOptions.length === 0 && !!newOption ? (
              <>
                <components.NoOptionsMessage {...props}>{noOptions(inputValue)}</components.NoOptionsMessage>
                {children}
              </>
            ) : (
              <>{children}</>
            )}
          </components.MenuList>
        )
      },
      SelectContainer: ({ children, ...props }: any) =>
        searchable && !options ? (
          <IconContainer {...props}>
            {children}
            <SearchIcon icon="search" />
          </IconContainer>
        ) : (
          <components.SelectContainer {...props}>{children}</components.SelectContainer>
        ),
      MultiValueRemove: (props: any) => (
        <components.MultiValueRemove {...props}>
          <RemoveIcon icon="close-view" width={16} height={16} />
        </components.MultiValueRemove>
      ),
      ...(customComponentsProp || {}),
    }),
    [searchable, options, clearable, customComponentsProp, menuPlacement, allowCreate, noOptions]
  )

  // Reset styles
  const styles: any = useMemo(() => {
    const readOnlyIds = new Set(map('value', readOnlyValues || []))
    return {
      clearIndicator: () => {},
      container: () => {},
      control: () => {},
      dropdownIndicator: () => {},
      group: () => {},
      groupHeading: () => {},
      indicatorsContainer: () => {},
      indicatorSeparator: () => null,
      input: () => {},
      loadingIndicator: () => {},
      loadingMessage: () => {},
      menu: () => {},
      menuList: () => {},
      menuPortal: () => {},
      multiValue: () => {},
      multiValueLabel: () => {},
      multiValueRemove: (_base: any, state: any) => {
        return (appendOnly && !state.data.isNew) || readOnlyIds.has(state.data.value) ? { display: 'none' } : null
      },
      noOptionsMessage: () => {},
      option: () => {},
      placeholder: () => {},
      singleValue: () => {},
      valueContainer: () => {},
    }
  }, [appendOnly, readOnlyValues])

  return async ? (
    allowCreate ? (
      <ReactSelectAsyncCreatable
        styles={styles}
        components={selectComponents}
        {...selectProps}
        loadOptions={selectProps.loadOptions || noop}
      />
    ) : (
      <ReactSelectAsync
        styles={styles}
        components={selectComponents}
        {...selectProps}
        loadOptions={selectProps.loadOptions || noop}
      />
    )
  ) : allowCreate ? (
    <ReactSelectCreatable styles={styles} components={selectComponents} {...selectProps} />
  ) : (
    <ReactSelect styles={styles} components={selectComponents} {...selectProps} />
  )
}

export default memo(Select)
