/* eslint-disable max-lines */
import React, { memo, useCallback, useRef, FC, ChangeEvent, useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { concat, reject, get, set, compact, isNil, map, includes } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import { markAsClientOnly } from '../../../utils/entityStatus'
import { REGEX_INTEGER } from '../../../utils/regex'
import { textStyle } from '../../../utils/typography'
import { mediaQuery, color, input, breakpoints } from '../../../utils/variables'

import Button from '../../../components/Button'
import { OnDesktop } from '../../../components/Breakpoints'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import FormGroup from '../../../components/FormGroup'
import FormField from '../../../components/FormField'
import { Divider, Form, FormRow } from '../../../components/Form'
import IconButton from '../../../components/IconButton'
import Svg from '../../../components/Svg'
import SwitchField from '../../../components/SwitchField'
import { TabMenu, TabMenuItem } from '../../../components/TabMenu'

import { IProduct, IProductVariant } from '../types/Extras'
import { ProductOptionType, ProductRootType } from '../../../enums.generated'

const rejectWithIdx = (reject as any).convert({ cap: false })

const VariantsWrapper = styled.div`
  border: 2px solid ${input.borderColor};
  border-radius: 6px;
  padding: 24px;
`

const Variants = styled.div``

const Counter = styled.div<{ count: number; maxCount: number }>`
  position: absolute;
  display: none;
  top: 12px;
  right: 8px;
  white-space: nowrap;
  background: #fff;
  ${textStyle.functional.sm};
  padding: 4px 6px;
  margin: -4px -6px;
  color: ${({ count, maxCount }) => (count > maxCount ? color.error : color.darkgrey)};
`

const Row = styled.div`
  position: relative;
  background-color: ${color.white};
  margin-bottom: 16px;
  padding-right: 48px;
  padding-left: 48px;
  .text-input.-focus + ${Counter} {
    display: block;
  }
`

const RemoveButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  top: 0px;
  ${mediaQuery.lessThan('tablet')`
    position: absolute;
    top: 50%;
    margin-top: -20px
  `}
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  flex: none;
  max-width: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.grey};
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};
  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  ${mediaQuery.lessThan<{ disabled?: boolean }>('desktop')`
    margin: 0;
    top: 50%;
    margin-top: -20px;
  `}

  svg {
    pointer-events: none;
  }
`

interface IItemProps {
  idx: number
  productType: ProductRootType | null
  item: IProductVariant
  itemType: ProductOptionType | null
  setItem: (idx: number, value: IProductVariant) => void
  handleBlur?: (...args: any[]) => any
  remove: (idx: number) => void
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
  allowEdit?: boolean
  noRemove?: boolean
  disabled?: boolean
  readOnly?: boolean
}

const SIZES = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']

const SortableDragHandle = SortableHandle<{ disabled?: boolean }>(({ disabled }: { disabled?: boolean }) => (
  <DragHandle disabled={disabled}>
    <Svg icon="hamburger" />
  </DragHandle>
))

const VariantItem = SortableElement<IItemProps>(
  ({
    idx,
    productType,
    item,
    itemType,
    handleBlur,
    remove,
    setItem,
    touched,
    errors,
    allowEdit,
    noRemove,
    readOnly,
  }: IItemProps) => {
    const intl = useIntl()
    const { allocation, name, sku, id } = item

    const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

    const onRemove = useCallback(() => remove(idx), [remove, idx])

    const handleChangeSize = useCallback(
      (_id: any, value: any) => setItem(idx, set('name', value.value, item)),
      [setItem, idx, item]
    )

    const handleChangeName = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value || ''
        return setItem(idx, set('name', value, item))
      },
      [idx, item, setItem]
    )

    const handleChangeAllocation = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (REGEX_INTEGER.test(value) && value.length < 15) {
          setItem(idx, set('allocation', parseInt(value, 10), item))
        }
        if (value === '') {
          setItem(idx, set('allocation', null, item))
        }
        return
      },
      [idx, item, setItem]
    )

    const handleChangeSKU = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value || ''
        return setItem(idx, set('sku', value, item))
      },
      [idx, item, setItem]
    )

    const canEdit = useMemo(() => allowEdit || id.startsWith('new'), [allowEdit, id])
    const canRemove = useMemo(() => (allowEdit || id.startsWith('new')) && !noRemove, [allowEdit, id, noRemove])

    return (
      <Form spacing="small" className="draggable mt-zero">
        <Row>
          <SortableDragHandle disabled={!canEdit} />
          <FormRow columnOnMobile spacing="small">
            {itemType === 'SIZE' ? (
              <FormField
                name={`variants[${idx}].name`}
                placeholder={intl.formatMessage({ id: 'new_event.extras.form.variants.size.label' })}
                onChange={handleChangeSize}
                control="select"
                options={SIZES.map((s) => ({ label: s, value: s }))}
                value={name ? { label: name, value: name } : null}
                onBlur={handleBlur}
                error={get(`variants[${idx}].name`, touched) && get(`variants[${idx}].name`, errors)}
                disabled={!canEdit}
                required
              />
            ) : (
              <FormField
                name={`variants[${idx}].name`}
                placeholder={isMobile ? intl.formatMessage({ id: 'new_event.extras.form.variants.option.label' }) : ''}
                onChange={handleChangeName}
                value={name || ''}
                onBlur={handleBlur}
                error={get(`variants[${idx}].name`, touched) && get(`variants[${idx}].name`, errors)}
                disabled={!canEdit}
                required
              >
                <Counter maxCount={12} count={(name || '').length}>
                  {(name || '').length}/{12}
                </Counter>
              </FormField>
            )}
            <FormField
              name={`variants[${idx}].allocation`}
              placeholder={
                isMobile ? intl.formatMessage({ id: 'new_event.extras.form.variants.allocation.label' }) : ''
              }
              type="number"
              min={0}
              step={1}
              value={isNil(allocation) ? '' : allocation}
              onChange={handleChangeAllocation}
              onBlur={handleBlur}
              error={get(`variants[${idx}].allocation`, touched) && get(`variants[${idx}].allocation`, errors)}
              disabled={!canEdit}
              required
            />
            {productType === 'MERCH' && (
              <FormField
                name={`variants[${idx}].sku`}
                placeholder={isMobile ? intl.formatMessage({ id: 'new_event.extras.form.variants.sku.label' }) : ''}
                onChange={handleChangeSKU}
                value={sku || ''}
                onBlur={handleBlur}
                error={get(`variants[${idx}].sku`, touched) && get(`variants[${idx}].sku`, errors)}
                disabled={readOnly}
              />
            )}
          </FormRow>
          <RemoveButton
            icon="trash"
            className="ml-sm"
            onClick={onRemove}
            disabled={!canRemove}
          />
        </Row>
      </Form>
    )
  }
)

interface IContainerProps {
  productType: ProductRootType | null
  variantsType: ProductOptionType | null
  variants: Array<IProductVariant>
  setVariants: (v: IProductVariant[]) => void
  handleBlur?: (...args: any[]) => any
  remove: (idx: number) => void
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
  allowEdit?: boolean
  readOnly?: boolean
  noRemove?: boolean
}

const ListVariants = SortableContainer<IContainerProps>(
  ({
    productType,
    variantsType,
    variants = [],
    setVariants,
    handleBlur,
    remove,
    touched,
    errors,
    allowEdit,
    readOnly,
    noRemove,
  }: IContainerProps) => {
    const intl = useIntl()
    const setItem = useCallback(
      (idx: number, value: IProductVariant) => setVariants(set(`${idx}`, value, variants || [])),
      [setVariants, variants]
    )

    return (
      <Variants>
        <OnDesktop>
          <Form spacing="small">
            <Row className="mb-zero">
              <FormRow columnOnMobile spacing="small">
                <FormGroup
                  required
                  label={
                    variantsType === 'SIZE'
                      ? intl.formatMessage({ id: 'new_event.extras.form.variants.size.label' })
                      : intl.formatMessage({ id: 'new_event.extras.form.variants.option.label' })
                  }
                />
                <FormGroup
                  required
                  label={intl.formatMessage({ id: 'new_event.extras.form.variants.allocation.label' })}
                />
                {productType === 'MERCH' && (
                  <FormGroup label={intl.formatMessage({ id: 'new_event.extras.form.variants.sku.label' })} />
                )}
              </FormRow>
            </Row>
          </Form>
        </OnDesktop>
        {variants.map((item, idx) => (
          <VariantItem
            key={idx}
            idx={idx}
            index={idx}
            productType={productType}
            item={item}
            itemType={variantsType}
            handleBlur={handleBlur}
            remove={remove}
            setItem={setItem}
            touched={touched}
            errors={errors}
            disabled={!allowEdit && !item.id.startsWith('new')}
            allowEdit={allowEdit}
            readOnly={readOnly}
            noRemove={noRemove}
          />
        ))}
      </Variants>
    )
  }
)

interface IProps {
  readOnly: boolean
  values: IProduct
  handleChange: (e: string | ChangeEvent) => void
  handleBlur: (e: Event) => void
  setFieldValue: (name: string, value: any) => void
  setFieldTouched: (name: string, p1: boolean, p2: boolean) => void
  validateForm: () => void
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
  noRestrictions: boolean
  canAdd?: boolean
  allowEdit?: boolean
  noRemove?: boolean
}

const EventProductVariants: FC<React.PropsWithChildren<IProps>> = ({
  values,
  setFieldValue,
  setFieldTouched,
  validateForm,
  handleBlur,
  errors,
  touched,
  noRestrictions,
  readOnly,
  canAdd,
  allowEdit,
  noRemove,
}) => {
  const intl = useIntl()

  const ref = useRef<HTMLDivElement>(null)
  const [turnOffVariants, setTurnOffVariants] = useState(false)
  const toggleConfirmationModal = useCallback(() => setTurnOffVariants((v) => !v), [])

  const getDefaultVariants = useCallback(
    (variantsType: ProductOptionType) =>
      variantsType === 'SIZE'
        ? [
          markAsClientOnly<IProductVariant>({ name: 'XS', allocation: 0, sku: '', type: 'SIZE' }),
          markAsClientOnly<IProductVariant>({ name: 'S', allocation: 0, sku: '', type: 'SIZE' }),
          markAsClientOnly<IProductVariant>({ name: 'M', allocation: 0, sku: '', type: 'SIZE' }),
          markAsClientOnly<IProductVariant>({ name: 'L', allocation: 0, sku: '', type: 'SIZE' }),
          markAsClientOnly<IProductVariant>({ name: 'XL', allocation: 0, sku: '', type: 'SIZE' }),
        ]
        : [
          markAsClientOnly<IProductVariant>({ name: '', allocation: 0, sku: '', type: 'CUSTOM' }),
          markAsClientOnly<IProductVariant>({ name: '', allocation: 0, sku: '', type: 'CUSTOM' }),
          markAsClientOnly<IProductVariant>({ name: '', allocation: 0, sku: '', type: 'CUSTOM' }),
        ],
    []
  )

  const productType = useMemo(() => values.category.rootType, [values.category])
  const hasVariants = useMemo(() => values.hasVariants, [values.hasVariants])
  const variantsType = useMemo(() => values.optionType, [values.optionType])
  const variants = useMemo(() => compact(values.variants), [values.variants])

  const setHasVariants = useCallback(
    (v: any) => {
      setFieldValue('hasVariants', v)
      // setFieldTouched('hasVariants', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, validateForm]
  )

  const setVariantsType = useCallback(
    (v: any) => {
      setFieldValue('optionType', v)
      // setFieldTouched('optionType', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, validateForm]
  )

  const setVariants = useCallback(
    (v: any) => {
      setFieldValue('variants', v)
      // setFieldTouched('variants', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, validateForm]
  )

  const setDefaultVariants = useCallback(
    (variantsType: ProductOptionType) => {
      const defaultVariants = getDefaultVariants(variantsType === 'SIZE' ? 'SIZE' : 'CUSTOM')
      setVariants(defaultVariants)
    },
    [getDefaultVariants, setVariants]
  )

  const handleHasVariants = useCallback(() => {
    if (hasVariants) {
      setVariantsType(null)
      setHasVariants(false)
      setVariants([])
      setFieldTouched('variants', false, true)
    } else {
      setVariantsType(productType === 'MERCH' ? 'SIZE' : 'CUSTOM')
      setHasVariants(true)
      setDefaultVariants(productType === 'MERCH' ? 'SIZE' : 'CUSTOM')
    }
    setTurnOffVariants(false)
  }, [productType, hasVariants, setDefaultVariants, setFieldTouched, setHasVariants, setVariants, setVariantsType])

  const toggleHasVariants = useCallback(() => {
    if (hasVariants) {
      return toggleConfirmationModal()
    }
    handleHasVariants()
  }, [handleHasVariants, hasVariants, toggleConfirmationModal])

  const handleChangeVariantsType = useCallback(
    (e: any) => {
      const value = e.currentTarget.dataset['type']
      setVariantsType(value)
      setDefaultVariants(value)
      setFieldTouched('variants', false, true)
    },
    [setDefaultVariants, setFieldTouched, setVariantsType]
  )

  const addVariant = useCallback(() => {
    const normalizedVaraints = compact(variants)
    const idx = normalizedVaraints.length
    if (variantsType === 'SIZE') {
      const usedSizes = map('name', variants)
      const remainingSizeOptions = reject((size) => includes(size, usedSizes), SIZES)

      if (remainingSizeOptions.length > 0) {
        setVariants(
          concat(
            normalizedVaraints,
            markAsClientOnly<IProductVariant>({ name: remainingSizeOptions[0], allocation: 0, sku: '', type: 'SIZE' })
          )
        )
      }
    } else if (variantsType === 'CUSTOM') {
      setVariants(
        concat(
          normalizedVaraints,
          markAsClientOnly<IProductVariant>({ name: '', allocation: 0, sku: '', type: 'CUSTOM' })
        )
      )
    }

    setTimeout(() => {
      if (ref.current) {
        const node = ref.current.querySelector(`input[name="variants[${idx}].name"]`) as HTMLInputElement
        if (node) node.focus()
      }
    }, 500)

    return
  }, [variants, variantsType, setVariants])

  const remove = useCallback(
    (removedIdx: any) => {
      setVariants(rejectWithIdx((_: any, idx: number) => idx === removedIdx, variants))
    },
    [setVariants, variants]
  )

  const reorder = useCallback(
    ({ oldIndex, newIndex }: any) => {
      setVariants(arrayMove(variants || [], oldIndex, newIndex))
    },
    [setVariants, variants]
  )

  return (
    <VariantsWrapper>
      <FormRow>
        <SwitchField
          label={intl.formatMessage({
            id: productType === 'MERCH' ? 'new_event.merch.variants.label' : 'new_event.extras.variants.label',
          })}
          hint={intl.formatMessage({
            id: productType === 'MERCH' ? 'new_event.merch.variants.hint' : 'new_event.extras.variants.hint',
          })}
          name="sendReceiptViaSms"
          checked={!!hasVariants}
          disabled={!allowEdit}
          onChange={toggleHasVariants}
        />
      </FormRow>
      {hasVariants && (
        <>
          <FormRow>
            <Divider className="mt-zero mb-zero" />
          </FormRow>
          <FormRow>
            <div>
              {productType === 'MERCH' && (
                <TabMenu className="mb-lg">
                  <TabMenuItem
                    data-type="SIZE"
                    active={variantsType === 'SIZE'}
                    onClick={handleChangeVariantsType}
                    disabled={!allowEdit}
                  >
                    {intl.formatMessage({ id: 'new_event.extras.variants.types.size' })}
                  </TabMenuItem>
                  <TabMenuItem
                    data-type="CUSTOM"
                    active={variantsType === 'CUSTOM'}
                    onClick={handleChangeVariantsType}
                    disabled={!allowEdit}
                  >
                    {intl.formatMessage({ id: 'new_event.extras.variants.types.custom' })}
                  </TabMenuItem>
                </TabMenu>
              )}
              <ListVariants
                productType={productType}
                variantsType={variantsType}
                variants={(variants || []) as IProductVariant[]}
                handleBlur={handleBlur}
                setVariants={setVariants}
                remove={remove}
                touched={touched}
                errors={errors}
                allowEdit={allowEdit}
                readOnly={readOnly}
                onSortEnd={reorder}
                lockAxis="y"
                useDragHandle
                noRemove={noRemove}
              />

              {canAdd && (variants?.length || 0) < SIZES.length && (
                <Button icon="plus" preset="link" className="color-primary ml-sm" onClick={addVariant}>
                  {intl.formatMessage({
                    id: `new_event.extras.variants.actions.add_${variantsType?.toLowerCase() || 'size'}`,
                  })}
                </Button>
              )}
            </div>
          </FormRow>
        </>
      )}
      {turnOffVariants && (
        <ConfirmationModal
          icon="alert"
          title={intl.formatMessage({
            id:
              productType === 'MERCH'
                ? 'new_event.merch.variants.confirmation_modal.header'
                : 'new_event.extras.variants.confirmation_modal.header',
          })}
          description={intl.formatMessage({
            id:
              productType === 'MERCH'
                ? 'new_event.merch.variants.confirmation_modal.description'
                : 'new_event.extras.variants.confirmation_modal.description',
          })}
          onConfirm={handleHasVariants}
          onReject={toggleConfirmationModal}
        />
      )}
    </VariantsWrapper>
  )
}

export default memo(EventProductVariants)
