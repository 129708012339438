import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'
import Svg from './Svg'
import Button from './Button'
import { font } from '../utils/variables'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.h1`
  margin: 16px 0;
  line-height: 110%;
`

const Description = styled.div`
  font-size: ${font.size.sm}px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.01em;

  max-width: 372px;
  margin-bottom: 32px;
`

interface IProps {
  className?: string
  icon?: string
  title: string
  description?: string
  backLink?: string
  backText?: string
}

const GenericErrorRaw: FC<React.PropsWithChildren<IProps>> = ({
  className,
  icon,
  title,
  description,
  backLink,
  backText,
}) => {
  return (
    <Container className={className}>
      <Svg icon={icon || 'error'} width={64} height={64} />
      <Header>{title}</Header>
      {description && <Description>{description}</Description>}
      {backLink && backText && (
        <Button preset="secondary" to={backLink}>
          {backText}
        </Button>
      )}
    </Container>
  )
}

const GenericError = memo(GenericErrorRaw)

export const GenericErrorSub = styled(GenericError)`
  max-height: calc(100% - 262px);
`

export const GenericErrorWithHeader = styled(GenericError)`
  max-height: calc(100% - 72px);
`

export default GenericError
