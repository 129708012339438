/**
 * @generated SignedSource<<2b09eb956d29ef6372ab019d6e3dff66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventPartSalesBreakdown_event$data = {
  readonly id: string;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly ticketType: {
        readonly allocation: number;
        readonly id: string;
        readonly name: string | null;
        readonly ticketPoolId: string | null;
      };
      readonly totalAppSold: number;
      readonly totalPosSold: number;
      readonly totalWlRequests: number;
    } | null>;
  } | null;
  readonly ticketPools: ReadonlyArray<{
    readonly id: string;
    readonly maxAllocation: number | null;
  } | null> | null;
  readonly " $fragmentType": "EventPartSalesBreakdown_event";
};
export type EventPartSalesBreakdown_event$key = {
  readonly " $data"?: EventPartSalesBreakdown_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartSalesBreakdown_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventPartSalesBreakdown_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allocation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ticketPoolId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalWlRequests",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketPool",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "f5867b7116343005b643d6908450c640";

export default node;
