/**
 * @generated SignedSource<<d0fb3eece8d6242cf0f9f61847a8f62f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventBalanceAvailability = "AVAILABLE" | "INSUFFICIENT" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEventPayout_event$data = {
  readonly addressCountry: string | null;
  readonly adjustmentsStats: {
    readonly failed: number;
    readonly notProcessed: number;
  } | null;
  readonly allowedActions: {
    readonly performPayout: boolean | null;
  } | null;
  readonly balance: {
    readonly amountPromoterOwed: number;
    readonly amountPromoterTotal: number;
    readonly availability: EventBalanceAvailability;
    readonly isProcessing: boolean;
    readonly payouts: ReadonlyArray<{
      readonly status: string;
    } | null> | null;
  } | null;
  readonly billingPromoter: {
    readonly payoutsEnabled: boolean | null;
    readonly remittanceRecipients: ReadonlyArray<string | null> | null;
  } | null;
  readonly countryCode: string | null;
  readonly endDate: string | null;
  readonly selfPayoutBlockers: IBackendMap | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly countryCode: string | null;
  } | null> | null;
  readonly " $fragmentType": "useEventPayout_event";
};
export type useEventPayout_event$key = {
  readonly " $data"?: useEventPayout_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEventPayout_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEventPayout_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performPayout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdjustmentsStats",
      "kind": "LinkedField",
      "name": "adjustmentsStats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "failed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notProcessed",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selfPayoutBlockers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remittanceRecipients",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payoutsEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventBalance",
      "kind": "LinkedField",
      "name": "balance",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availability",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isProcessing",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountPromoterOwed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountPromoterTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Payout",
          "kind": "LinkedField",
          "name": "payouts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "b8d65d8b4a18f0e69c8fe7db15315811";

export default node;
