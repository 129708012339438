// eslint-disable-next-line lodash-fp/use-fp
import { assign } from 'lodash'

const copyToClipboard = (text?: string | null): Promise<void> => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text || '')
  }

  return new Promise<void>((resolve, reject) => {
    const el = document.createElement('input')
    el.type = 'text'
    el.contentEditable = 'true'
    el.readOnly = false
    el.value = text || ''

    // SIC! use mutating version here!
    // eslint-disable-next-line lodash-fp/no-unused-result
    assign(el.style, {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '2em',
      height: '2em',
      padding: 0,
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      background: 'transparent',
      zIndex: -1,
      opacity: 0,
    })

    document.body.appendChild(el)
    try {
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        const range = document.createRange()
        range.selectNodeContents(el)
        const sel = window.getSelection()
        if (sel) {
          sel.removeAllRanges()
          sel.addRange(range)
        }
        el.setSelectionRange(0, 999999)
      } else {
        el.select()
      }
      const success = document.execCommand('copy')
      el.blur()

      if (success) {
        resolve()
      } else {
        reject(null)
      }
    } catch (err) {
      reject(err)
    } finally {
      document.body.removeChild(el)
    }
  })
}

export default copyToClipboard
