import { keyBy } from 'lodash/fp'

export const IGNORED_TRACKING_PROPS = [
  'anonymous_id',
  'context_ip',
  'event',
  'event_text',
  'id',
  'original_timestamp',
  'received_at',
  'sent_at',
  'title',
  'url',
  'event_id',
  'event_id_live',
  'promoter_id',
]

export interface IAction {
  key: string
  name: string
  description?: string
  kind: 'event' | 'page'
  properties: Array<string[]>
}

export const getTrackingActions = async () => {
  const module = await import(/* webpackChunkName: "tracking" */ './actionRegistry')
  return keyBy('key', module.default as IAction[])
}
