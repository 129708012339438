import { useCallback } from 'react'
import { useFragment, useMutation } from 'react-relay'
import { useNavigate } from 'react-router'
import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { useIntl } from 'react-intl'
import { useImpersonationMutation } from '../../../__generated__/useImpersonationMutation.graphql'
import { clearAuthToken, setAuthToken } from '../../../utils/api'
import unwrapId from '../../../utils/unwrapId'
import { useImpersonation_viewer$key } from '../../../__generated__/useImpersonation_viewer.graphql'

function useImpersonation(viewerKey: useImpersonation_viewer$key, onUserChange?: () => void) {
  const intl = useIntl()
  const navigate = useNavigate()

  const viewer = useFragment(
    graphql`
      fragment useImpersonation_viewer on Viewer {
        account {
          id
        }
        impersonator {
          id
        }
      }
    `,
    viewerKey
  )

  const [commitImpersonation] = useMutation<useImpersonationMutation>(graphql`
    mutation useImpersonationMutation($input: ImpersonateUserInput!) {
      impersonateUser(input: $input) {
        token
      }
    }
  `)

  const impersonateAs = useCallback(
    (userId: string, reason?: string | null, accountId?: string | null) => {
      const promise = new Promise<string>((resolve, reject) =>
        commitImpersonation({
          variables: {
            input: {
              clientMutationId: nanoid(),
              id: userId,
              reason: reason || intl.formatMessage({ id: 'na', defaultMessage: 'N/A' }),
              accountId: accountId || null,
            },
          },
          onCompleted(data) {
            const token = data.impersonateUser?.token
            if (!token) {
              reject(new Error('No impersonation token!'))
            } else {
              resolve(token)
            }
          },
          onError: reject,
        })
      )

      return promise.then((newToken) => {
        const oldToken = clearAuthToken()
        if (oldToken) {
          window.localStorage.setItem('preImpToken', oldToken)
        }
        setAuthToken(newToken)
        if (onUserChange) onUserChange()
        return newToken
      })
    },
    [commitImpersonation, intl, onUserChange]
  )

  const exitImpersonation = useCallback(() => {
    const oldToken = window.localStorage.getItem('preImpToken')
    clearAuthToken()
    if (oldToken) {
      setAuthToken(oldToken)
    }
    if (onUserChange) onUserChange()
    if (viewer.account?.id) {
      navigate(`/promoters/${btoa(`Promoter:${unwrapId(viewer.account?.id)}`)}/contacts`)
    } else {
      navigate('/dashboard')
    }
  }, [navigate, onUserChange, viewer.account?.id])

  const isImpersonated = !!viewer.impersonator?.id

  return {
    impersonateAs: !isImpersonated ? impersonateAs : undefined,
    exitImpersonation: isImpersonated ? exitImpersonation : undefined,
    isImpersonated,
  }
}

export default useImpersonation
