import React, { FC, memo, useCallback, useMemo, useState, useContext, useRef, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components/macro'
import { concat, map, reject, compact, find, uniq, uniqBy, unset, values, some } from 'lodash/fp'
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay'
import { FormikErrors, FormikTouched, FormikValues } from 'formik'
import { FormRow } from '../../../components/Form'
import graphqlOptionsLoader from '../../../utils/graphqlOptionsLoader'

import IEventFormBasics, { ICharacteristic } from '../types/Basics'
import { EventState, EventType } from '../../../enums.generated'
import EventCustomVenue from './EventCustomVenue'
import EventPrimaryVenue from './EventPrimaryVenue'
import EventSelectedVenue from './EventSelectedVenue'
import IconButton from '../../../components/IconButton'
import Button from '../../../components/Button'
import { color } from '../../../utils/variables'
import { FlexFormField } from '../../../components/FormField'
import { authContext } from '../../../context/auth'
import { EventVenuesRestrictedQuery } from '../../../__generated__/EventVenuesRestrictedQuery.graphql'
import useSeatingState from '../hooks/useSeatingState'
import { ConfirmationModal } from '../../../components/ConfirmationModal'

const AdditionalVenues = styled.div`
  margin-top: 32px;
`

const AddButton = styled(Button)`
  && {
    font-weight: bold;
    color: ${color.primary};
    margin-top: -16px;
  }
`

const QUERY_LOAD_VENUES = graphql`
  query EventVenuesVenueQuery($searchTerm: String) {
    viewer {
      options: venues(searchTerm: $searchTerm, first: 50) {
        edges {
          node {
            isTest
            capacity
            ageLimit
            addressCountry
            countryCode
            addressLocality
            addressRegion
            addressState
            postalCode
            streetAddress
            value: id
            label: name
            hint: fullAddress
            fullAddress
            timezoneName
            tier
            latitude
            longitude
            ticketType
            barcodeType
            atLeastOneSeatingChart: seatingCharts(first: 1) {
              edges {
                node {
                  id
                }
              }
            }
            labels {
              value: id
              label: name
            }
            profileDetails {
              imageAttachment {
                cdnUrl
              }
              imageCropRegion {
                x
                y
                width
                height
              }
            }
            venueImages {
              attachment {
                cdnUrl
              }
            }
            venueOwners {
              value: id
              label: name
              associatedMarketeers {
                value: id
                label: name
              }
            }
            venueSpaces {
              value: id
              label: name
            }
            configurations {
              value: id
              label: name
              attractiveRoomSiaeCode
              capacity
              seatingAreaConfigs {
                capacity
                seatingArea
              }
            }
            characteristics {
              value: id
              label: name
            }
          }
        }
      }
    }
  }
`

type IVenue = NonNullable<NonNullable<IEventFormBasics['venues']>[number]>

export interface IVenueAddress {
  addressCountry?: string
  countryCode?: string
  addressLocality?: string
  addressLocalityId?: string
  addressLocalityLat?: number
  addressLocalityLon?: number
  postalCode?: string
  streetAddress?: string
  addressRegion?: string
  addressState?: string
  addressCapacity?: number
  addressSiaeCode?: string
  name?: string
}

type IRestrictedVenue = NonNullable<
  NonNullable<
    NonNullable<NonNullable<NonNullable<EventVenuesRestrictedQuery['response']['viewer']>['venues']>['edges']>[number]
  >['node']
>

interface IProps {
  eventType: EventType | null
  primaryVenueId: string | null
  setPrimaryVenueId: (id: string | null, venue: IVenue | null) => void
  state: EventState | null
  venues: ReadonlyArray<IVenue | null>
  setVenues: (ids: (string | null)[], venues: (IVenue | null)[]) => void
  customVenue?: IVenueAddress
  setCustomVenue: (val?: IVenueAddress) => void
  venueSpace?: { value: string; label: string } | null
  setVenueSpace?: (val: { value: string; label: string } | null) => void
  handleBlur?: (e: Event) => void
  errors?: FormikErrors<FormikValues>
  touched?: FormikTouched<FormikValues>
  allowEdit?: boolean
  forceEditable?: boolean
  handleVenueCharacteristics?: (characteristics: Array<ICharacteristic | null> | null) => void
  showVenueChangedFeesWarning?: boolean | null
  ntsComponent?: ReactNode
  isSeatedEvent?: boolean | null
}

const EventVenues: FC<React.PropsWithChildren<IProps>> = ({
  eventType,
  primaryVenueId,
  setPrimaryVenueId,
  state,
  venues,
  setVenues,
  customVenue,
  setCustomVenue,
  venueSpace,
  setVenueSpace,
  handleBlur,
  errors,
  touched,
  allowEdit: allowEditRaw,
  forceEditable,
  handleVenueCharacteristics,
  showVenueChangedFeesWarning,
  ntsComponent,
  isSeatedEvent,
}) => {
  const allowEdit = !!allowEditRaw || !!forceEditable
  const customVenueName = useRef('')
  const intl = useIntl()
  const { user, hasPermission, account, availableAccounts } = useContext(authContext)
  const environment = useRelayEnvironment()

  const isCollaborator = useMemo(
    () => find(['id', account?.id], availableAccounts)?.membershipType === 'COLLABORATORS',
    [account?.id, availableAccounts]
  )

  const venueLoader = useMemo(
    () => graphqlOptionsLoader(environment, QUERY_LOAD_VENUES, { fullText: true }),
    [environment]
  )

  const otherVenueLoader = useCallback(
    (primary?: string) =>
      // eslint-disable-next-line lodash-fp/no-extraneous-function-wrapping
      graphqlOptionsLoader(environment, QUERY_LOAD_VENUES, {
        postProcess: (opts) => reject(['value', primary], opts),
        fullText: true,
      }),
    [environment]
  )

  const primaryVenue: IVenue | null = useMemo(
    () => find(['value', primaryVenueId], venues) || null,
    [primaryVenueId, venues]
  )
  const otherVenues = useMemo(() => reject(['value', primaryVenueId], venues), [primaryVenueId, venues])

  const setPrimaryVenue = useCallback(
    (id: null | string, venue: null | IVenue) => {
      if (!id) {
        setVenues(uniq(map('value', otherVenues || [])), uniqBy('value', otherVenues || []))
      } else {
        const ids = concat(id, map('value', otherVenues || []))
        const vals = concat(venue, otherVenues || [])
        setVenues(uniq(ids), uniqBy('value', vals))
      }
      setPrimaryVenueId(id, venue)

      if (venue?.characteristics && handleVenueCharacteristics) {
        handleVenueCharacteristics(venue.characteristics)
      }
    },
    [handleVenueCharacteristics, otherVenues, setPrimaryVenueId, setVenues]
  )

  const setOtherVenues = useCallback(
    (ids: string[], venues: IVenue[]) =>
      setVenues(
        uniq(concat(primaryVenue?.value || null, ids || [])),
        uniqBy('value', concat(primaryVenue, venues || []))
      ),
    [primaryVenue, setVenues]
  )

  const otherVenueLoaderInstance = useMemo(
    () => otherVenueLoader(primaryVenue?.value),
    [otherVenueLoader, primaryVenue?.value]
  )

  const shouldUseCustom = useMemo(
    () => !primaryVenueId && compact(values(customVenue)).length > 0,
    [customVenue, primaryVenueId]
  )

  const [showCustom, setShowCustom] = useState(false)

  const { seatingWarn, confirmSeatingWarn, cancelSeatingWarn, onChangeSeated } = useSeatingState()

  const showAddress = useCallback(
    (venueName: string) => {
      customVenueName.current = venueName
      setOtherVenues([], [])
      setPrimaryVenue(null, null)
      setShowCustom(true)
    },
    [setShowCustom, setOtherVenues, setPrimaryVenue]
  )

  const hideAddress = useCallback(() => {
    setShowCustom(false)
    setCustomVenue(undefined)
  }, [setShowCustom, setCustomVenue])

  const removePrimaryOrCustomVenue = useCallback(() => {
    if (seatingWarn) {
      confirmSeatingWarn()
    }
    if (setVenueSpace) setVenueSpace(null)
    setPrimaryVenue(null, null)
    hideAddress() // and clear custom address if any
  }, [confirmSeatingWarn, hideAddress, seatingWarn, setPrimaryVenue, setVenueSpace])

  const warnClearVenue = useCallback(() => {
    if (isSeatedEvent) {
      // Warn about losing seated ticket types before committing
      onChangeSeated()
    } else {
      removePrimaryOrCustomVenue()
    }
  }, [isSeatedEvent, onChangeSeated, removePrimaryOrCustomVenue])

  const otherVenuesNoHint = useMemo(() => map(unset('hint'), otherVenues || []), [otherVenues])
  const [showOtherVenues, setShowOtherVenues] = useState(
    (eventType !== 'STREAM' && otherVenuesNoHint.length > 0) ||
      user.diceStaff ||
      hasPermission('full_manage_access:event')
  )

  const doShowOtherVenues = useCallback(() => {
    setOtherVenues([], [])
    setShowOtherVenues(true)
  }, [setOtherVenues])

  const removeOtherVenues = useCallback(() => {
    setOtherVenues([], [])
    setShowOtherVenues(false)
  }, [setOtherVenues])

  const dontQueryRestrictions = user.diceStaff || !allowEdit || venues.length === 0 || isCollaborator

  const restrictionsRs = useLazyLoadQuery<EventVenuesRestrictedQuery>(
    graphql`
      query EventVenuesRestrictedQuery($count: Int!, $ids: [ID]!) {
        viewer {
          venues(first: $count, where: { id: { in: $ids } }) {
            edges {
              node {
                id
                allowedForSubmission
              }
            }
          }
        }
      }
    `,
    {
      count: venues.length || 0,
      ids: map('value', venues),
    },
    {
      fetchPolicy: dontQueryRestrictions ? 'store-only' : 'store-and-network',
    }
  )

  const restrictionSet = useMemo(() => {
    if (dontQueryRestrictions) return null

    const nodes: IRestrictedVenue[] = compact(map('node', restrictionsRs.viewer?.venues?.edges || []))

    const restrictedVenues = reject('allowedForSubmission', nodes)

    return new Set(map('id', restrictedVenues))
  }, [dontQueryRestrictions, restrictionsRs.viewer?.venues?.edges])

  const additionalVenues = showOtherVenues ? (
    <AdditionalVenues>
      {showOtherVenues ? (
        <FormRow columnOnMobile>
          <FlexFormField
            name="otherVenues"
            label={intl.formatMessage({ id: 'new_event.basics.venue.additional_venues.label' })}
            hint={intl.formatMessage({ id: 'new_event.basics.venue.additional_venues.hint' })}
            control="select"
            multiple
            searchable
            async
            loadOptions={otherVenueLoaderInstance}
            placeholder={intl.formatMessage({ id: 'new_event.basics.additional_collapsible.venues.placeholder' })}
            value={otherVenuesNoHint}
            onChange={setOtherVenues}
            disabled={state !== 'DRAFT' && !forceEditable}
            onBlur={handleBlur}
            error={
              some((v) => restrictionSet?.has(v?.value || ''), otherVenuesNoHint)
                ? intl.formatMessage({ id: 'new_event.basics.venue.restricted_error' })
                : touched?.venues && errors?.otherVenues
            }
            dice
          >
            <IconButton
              data-id="removeOtherVenues"
              icon="trash"
              onClick={removeOtherVenues}
              disabled={state !== 'DRAFT' && !forceEditable}
            />
          </FlexFormField>
        </FormRow>
      ) : (
        eventType !== 'STREAM' &&
        (state === 'DRAFT' || forceEditable) &&
        (!!primaryVenue || showCustom) && (
          <AddButton preset="link" onClick={doShowOtherVenues}>
            {intl.formatMessage({ id: 'new_event.basics.venue.additional_venues.add_button' })}
          </AddButton>
        )
      )}
    </AdditionalVenues>
  ) : null

  if (primaryVenue) {
    return (
      <>
        <EventSelectedVenue
          error={
            restrictionSet?.has(primaryVenueId || '')
              ? intl.formatMessage({ id: 'new_event.basics.venue.restricted_error' })
              : errors?.primaryVenue
          }
          allowClear={allowEdit}
          eventType={eventType}
          venue={primaryVenue}
          venueSpace={venueSpace}
          setVenueSpace={setVenueSpace}
          doClear={warnClearVenue}
          customVenueName={customVenue?.name || null}
          required
          showVenueChangedFeesWarning={showVenueChangedFeesWarning}
          ntsComponent={ntsComponent}
        >
          {additionalVenues}
        </EventSelectedVenue>
        {seatingWarn && (
          <ConfirmationModal
            title={intl.formatMessage({ id: 'new_event.tickets.remove_venue_with_seatmap.description' })}
            onConfirm={removePrimaryOrCustomVenue}
            onReject={cancelSeatingWarn}
          />
        )}
      </>
    )
  }

  if (shouldUseCustom && customVenue && !showCustom) {
    return (
      <EventSelectedVenue
        required
        notInMIO
        allowEdit={allowEdit}
        allowClear={allowEdit}
        eventType={eventType}
        venue={{
          ...customVenue,
          venueImages: [],
          label: customVenue.name || null,
          fullAddress: `${customVenue.streetAddress}, ${customVenue.addressLocality} ${customVenue.postalCode}`,
        }}
        doClear={warnClearVenue}
        // eslint-disable-next-line react/jsx-no-bind
        doEdit={() => {
          setShowCustom(true)
        }}
        ntsComponent={ntsComponent}
      >
        {additionalVenues}
      </EventSelectedVenue>
    )
  }

  return (
    <>
      {!showCustom && (
        <EventPrimaryVenue
          allowCreate={state === 'DRAFT'}
          disabled={!allowEdit}
          eventType={eventType}
          venue={primaryVenue}
          setVenue={setPrimaryVenue}
          venueLoader={venueLoader}
          error={touched?.primaryVenue && errors?.primaryVenue}
          onBlur={handleBlur}
          onCustom={showAddress}
          required
        >
          {additionalVenues}
        </EventPrimaryVenue>
      )}
      {showCustom && customVenue && (
        <EventCustomVenue
          eventType={eventType}
          // eslint-disable-next-line react/jsx-no-bind
          onClose={() => {
            setShowCustom(false)
          }}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={(values) => {
            setShowCustom(false)
            setCustomVenue(values)
          }}
          customVenue={{
            ...customVenue,
            name: customVenue.name || customVenueName.current,
          }}
          disabled={state !== 'DRAFT' && !forceEditable}
        />
      )}
    </>
  )
}

export default memo(EventVenues)
