import { EnumTypeOfOrganizer, PlatformAccountCode } from '../../../enums.generated'
import { LinkInput } from '../../../__generated__/savePromoterUpdateMutation.graphql'
import { ViewProfileModal_profile$key } from '../../../__generated__/ViewProfileModal_profile.graphql'

export const TYPES_OF_ORGANIZER = [
  { value: 'PROMOTER', i18n: 'promoter_form.type_of_organizer.option.promoter' },
  { value: 'ARTIST', i18n: 'promoter_form.type_of_organizer.option.artist' },
  { value: 'BRAND', i18n: 'promoter_form.type_of_organizer.option.brand' },
  { value: 'VENUE', i18n: 'promoter_form.type_of_organizer.option.venue' },
  { value: 'AGENT', i18n: 'promoter_form.type_of_organizer.option.agent' },
  {
    value: 'ARTIST_MANAGEMENT',
    i18n: 'promoter_form.type_of_organizer.option.artist_management',
  },
  { value: 'LABEL', i18n: 'promoter_form.type_of_organizer.option.label' },
]

export interface IProfileOverride {
  id: string
  subjects: Array<{
    name: string | null
    actions: Array<{
      name: string | null
    } | null> | null
  } | null>
  permissionProfile:
    | ({
        id: string
        caption: string
        roleName: string
      } & ViewProfileModal_profile$key)
    | null
}

interface IPromoterFormBasics {
  id: string | null
  name: string | null
  displayName: string | null
  typeOfOrganizer: EnumTypeOfOrganizer | null
  links: Array<LinkInput | null> | null
  partnerPermissionProfileOverrides: ReadonlyArray<IProfileOverride | null> | null
  permissionProfileOverrides: ReadonlyArray<IProfileOverride | null> | null
  accountManagers: ReadonlyArray<{
    value: string
    label: string | null
  } | null> | null
  clientSuccessManagers: ReadonlyArray<{
    value: string
    label: string | null
  } | null> | null
  dicePartner: boolean | null
  allowSkipReview: boolean | null

  timezoneName: string | null
  fullAddress: string | null
  addressCountry: string | null
  countryCode: string | null
  addressRegion: string | null
  addressState?: string | null
  addressLocality: string | null
  addressLocalityId?: string | null
  cityId?: string | null
  streetAddress: string | null
  postalCode: string | null
  latitude: number | null
  longitude: number | null

  postOfficeBoxNumber: string | null

  allowedLifecycleUpdates: null | {
    accountManagers: {
      canUpdate: boolean | null
    } | null
    clientSuccessManagers: {
      canUpdate: boolean | null
    } | null
  } | null
}

export default IPromoterFormBasics
