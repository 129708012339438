import React, { FC } from 'react'
import styled from 'styled-components/macro'

import { IMaxPermissionAction } from '../utils/structure'
import { TitleTooltip, TooltipHelpIcon } from '../../../components/Tooltip'
import { mediaQuery, color } from '../../../utils/variables'

const ActionContainer = styled.div`
  display: flex;
  align-items: center;

  min-width: 50%;

  padding: 8px 0;

  ${TooltipHelpIcon} {
    margin: 0 0 0 8px;
  }

  ${mediaQuery.lessThan('tablet')`
    min-width: 100%;
  `}
`

const ActionComment = styled.div`
  color: ${color.greyer};
`

interface IProps {
  action: IMaxPermissionAction
}

const ActionConfig: FC<IProps> = ({ action }) => {
  return (
    <ActionContainer key={action.name}>
      <div>
        <span dangerouslySetInnerHTML={{ __html: action.label }}></span>
        {action.comment ? <ActionComment>{action.comment}</ActionComment> : null}
      </div>
      {action.tooltip && (
        <TitleTooltip title={action.tooltip}>
          <TooltipHelpIcon icon="help" width={16} height={16} />
        </TitleTooltip>
      )}
    </ActionContainer>
  )
}

export default ActionConfig
