import { camelCase, compose, filter, find, keys, orderBy } from 'lodash/fp'

export interface ISeriousBlockers {
  noMoneyInAccount?: boolean
  promoterAccountIsBlocked?: boolean
  nowhereToSendRemittanceReport?: boolean
  adjustmentsInProgress?: boolean
}

export interface IBlockers {
  event_postponed?: boolean
  rolling_payments?: boolean
  event_completed_recently?: boolean
  refunds_after?: boolean
  stripe_account_inconsistent?: boolean
  hold_payouts?: boolean
}

const HAS_SPECIAL_MESSAGE = new Set<keyof IBlockers>([
  'event_postponed',
  'rolling_payments',
  'event_completed_recently',
  'refunds_after',
  'stripe_account_inconsistent',
  'hold_payouts',
])

export const GENERIC_MAJOR_BLOCKER = 'majorBlocker'

export function extractFocusedBlockers(
  diceStaff: boolean,
  blockers: IBlockers | null,
  seriousBlockers: ISeriousBlockers | null
) {
  const seriousBlocker = (find((k: keyof typeof seriousBlockers) => !!seriousBlockers?.[k], keys(seriousBlockers)) ||
    null) as keyof ISeriousBlockers | null

  const sortedBlockerTypes = compose([
    filter((k: keyof IBlockers) => !!blockers?.[k]),
    orderBy(
      [
        (k: keyof IBlockers) => {
          if (k === 'rolling_payments') {
            return -2
          } else if (k === 'event_completed_recently') {
            return -1
          } else if (HAS_SPECIAL_MESSAGE.has(k)) {
            return 0
          } else {
            return 1
          }
        },
      ],
      ['asc']
    ),
    keys,
  ])(blockers) as Array<keyof IBlockers>

  const commonBlocker = sortedBlockerTypes[0] || null

  let diceBlocker = null
  if (diceStaff && (seriousBlocker || commonBlocker)) {
    diceBlocker = camelCase(seriousBlocker || commonBlocker)
  }

  let selfBlocker = null
  if (commonBlocker === 'event_completed_recently') {
    selfBlocker = 'eventCompletedRecentlySelfNew'
  } else if (seriousBlocker) {
    selfBlocker = GENERIC_MAJOR_BLOCKER
  } else if (commonBlocker === 'event_postponed') {
    selfBlocker = 'eventPostponed'
  } else if (commonBlocker === 'rolling_payments') {
    selfBlocker = 'rollingPaymentsSelfNew'
  } else if (HAS_SPECIAL_MESSAGE.has(commonBlocker)) {
    selfBlocker = `${camelCase(commonBlocker)}Self`
  } else if (commonBlocker) {
    selfBlocker = GENERIC_MAJOR_BLOCKER
  }

  return [diceBlocker, selfBlocker] as const
}
