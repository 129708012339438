/**
 * @generated SignedSource<<326e77b1c9e42e9d768ebf868be43a3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type RepeatEnds = "OCCURRENCES" | "UNTIL" | "%future added value";
export type RepeatFrequency = "BI_WEEKLY" | "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type RepeatOn = "LAST_WEEK_DAY" | "SAME_DAY" | "SAME_WEEK_AND_DAY" | "%future added value";
export type CloneEventInput = {
  clientMutationId: string;
  frequency: RepeatFrequency;
  id: string;
  occurrences?: number | null;
  repeatEnds: RepeatEnds;
  repeatOn?: RepeatOn | null;
  until?: string | null;
};
export type QuickActionsDraftsCloneDraftsMutation$variables = {
  input: CloneEventInput;
};
export type QuickActionsDraftsCloneDraftsMutation$data = {
  readonly cloneEvent: {
    readonly events: ReadonlyArray<{
      readonly state: EventState | null;
    } | null> | null;
  } | null;
};
export type QuickActionsDraftsCloneDraftsMutation = {
  response: QuickActionsDraftsCloneDraftsMutation$data;
  variables: QuickActionsDraftsCloneDraftsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuickActionsDraftsCloneDraftsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CloneEventPayload",
        "kind": "LinkedField",
        "name": "cloneEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "events",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuickActionsDraftsCloneDraftsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CloneEventPayload",
        "kind": "LinkedField",
        "name": "cloneEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "events",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93887449f1f889a11db274224db2b9de",
    "id": null,
    "metadata": {},
    "name": "QuickActionsDraftsCloneDraftsMutation",
    "operationKind": "mutation",
    "text": "mutation QuickActionsDraftsCloneDraftsMutation(\n  $input: CloneEventInput!\n) {\n  cloneEvent(input: $input) {\n    events {\n      state\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f64796c7ef0483a340ca480bcb483020";

export default node;
