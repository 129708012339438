import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { compact, filter, flow, reject, sumBy } from 'lodash/fp'

import {
  Progress,
  Relative,
  CustomProgressBar,
  ProgressBarValues,
  SoldValue,
  AllocationValue,
  RemainingValue,
} from './TicketBreakdownStyles'

import { TicketPoolSalesProgress_event$key } from '../../../../__generated__/TicketPoolSalesProgress_event.graphql'

interface IProps {
  event: TicketPoolSalesProgress_event$key
  ticketPool: {
    id: string
    maxAllocation: number | null
  }
}

const TicketPoolSalesProgress: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, ticketPool }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment TicketPoolSalesProgress_event on Event {
        flags {
          waitingList
        }
        sales {
          ticketTypesBreakdown {
            totalAppSold
            totalPosSold
            totalDigitalValue
            totalWlRequests
            ticketType {
              id
              archived
              ticketPoolId
            }
          }
        }
      }
    `,
    eventKey
  )

  const poolsTicketTypes = useMemo(
    () =>
      flow(
        compact,
        reject('ticketType.archived'),
        filter((tt: any) => tt.ticketType.ticketPoolId === ticketPool.id)
      )(event.sales?.ticketTypesBreakdown || []) as any[],
    [event.sales?.ticketTypesBreakdown, ticketPool.id]
  )

  const isSold = sumBy((t) => t.totalAppSold + t.totalPosSold, poolsTicketTypes) >= (ticketPool.maxAllocation || 0)

  return (
    <Progress
      primary={
        <Relative>
          <CustomProgressBar
            size="small"
            gradient
            color={isSold ? 'secondary' : 'primary'}
            value={
              ticketPool.maxAllocation
                ? (100 * sumBy((t) => t.totalAppSold + t.totalPosSold, poolsTicketTypes)) / ticketPool.maxAllocation
                : 0
            }
          />
        </Relative>
      }
      secondary={
        <ProgressBarValues>
          <>
            <div>
              <SoldValue>
                {intl.formatNumber(sumBy((t) => t.totalAppSold + t.totalPosSold, poolsTicketTypes) || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </SoldValue>
              {' / '}
              <AllocationValue>
                {intl.formatNumber(ticketPool.maxAllocation || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </AllocationValue>
            </div>
            <RemainingValue>
              {!isSold && (
                <span>
                  {intl.formatNumber(
                    (ticketPool.maxAllocation || 0) - sumBy((t) => t.totalAppSold + t.totalPosSold, poolsTicketTypes),
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}{' '}
                  {intl.formatMessage({
                    id: 'event_overview.ticket_breakdown.tickets_remaining',
                  })}
                </span>
              )}
              {!!event.flags?.waitingList?.active && (
                <span>
                  {intl.formatNumber(sumBy('totalWlRequests', poolsTicketTypes) || 0, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{' '}
                  {intl.formatMessage({
                    id: 'event_overview.ticket_breakdown.tickets_wl',
                  })}
                </span>
              )}
            </RemainingValue>
          </>
        </ProgressBarValues>
      }
    />
  )
}

export default memo(TicketPoolSalesProgress)
