import React, { memo, ChangeEvent } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { get, sumBy } from 'lodash/fp'

import { FormikValues, FormikTouched, FormikErrors } from 'formik'

import { CURRENCY } from '../../../utils/formatters/number'
import { color, font, mediaQuery } from '../../../utils/variables'

import { OnDesktop } from '../../../components/Breakpoints'
import { Form, FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import FormGroup from '../../../components/FormGroup'
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from '../../../components/Table'
import { IPriceTier } from '../types/Tickets'
import { EventCostCurrency } from '../../../enums.generated'

interface IProps {
  doorPriceBreakdown: Array<{
    doorSalesPrice: number | null
    doorSalesTax: number | null
    doorSalesPriceTaxed: number | null
  }> | null
  isLoading: boolean
  tiers: IPriceTier[]
  handleChange: (e: string | ChangeEvent) => void
  handleBlur: (e: Event) => void
  currency?: EventCostCurrency
  timezoneName?: string
  setFieldValue: (key: string, val: any) => void
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
  disabled: boolean
}

const PriceTier = styled.li<{ itIsDraggable?: boolean }>`
  position: relative;
  margin-bottom: 16px;
  list-style: none;
  background-color: ${color.white};

  &:last-child {
    margin-left: 0;
    margin-bottom: 0;
  }
`

const DoorPriceInfo = styled.div<{ isLoading?: boolean }>`
  position: relative;
  margin: 24px 0 0;
  padding: 6px 14px;
  border-radius: 8px;
  background-color: ${color.palegrey};
  font-size: ${font.size.sm}px;
  border: 2px solid transparent;

  ${HeaderCell} {
    border-bottom: 1px solid ${color.lightgrey};
    ${mediaQuery.lessThan('tablet')`
      min-width: 0;
      white-space: normal;
      vertical-align: bottom;
    `}
  }
  ${HeaderCell}, ${Cell} {
    width: 20%;
    &:first-child {
      width: 25%;
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  filter: ${({ isLoading }) => (isLoading ? 'blur(3px)' : 'none')};
`

interface ITierProps {
  tier: IPriceTier
  idx: number
  handleChange: (e: ChangeEvent | string) => void
  handleBlur: (e: Event) => void
  currency?: EventCostCurrency
  timezoneName?: string
  setFieldValue: (key: string, val: any) => void
  errors: FormikErrors<FormikValues>
  disabled: boolean
}

const PriceTierRow = ({ tier, idx, handleChange, currency, handleBlur, errors, disabled }: ITierProps) => {
  const intl = useIntl()

  return (
    <PriceTier>
      <Form spacing="small">
        <FormRow columnOnMobile spacing="small">
          <FormField
            name={`priceTiers[${idx}].name`}
            placeholder={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.placeholder' })}
            value={tier.name || ''}
            disabled
          />
          <FormField name={`priceTiers[${idx}].faceValue`} currency={currency} value={tier.faceValue} disabled />
          <FormField
            name={`priceTiers[${idx}].doorSalesPrice`}
            currency={currency}
            value={tier.doorSalesPrice}
            onChange={handleChange}
            onBlur={handleBlur}
            error={get(`priceTiers[${idx}].doorSalesPrice`, errors)}
            required
            disabled={disabled}
          />
        </FormRow>
      </Form>
    </PriceTier>
  )
}

const EventTicketTypePriceTiersDoorSales = ({
  isLoading,
  doorPriceBreakdown,
  currency,
  tiers,
  handleChange,
  setFieldValue,
  handleBlur,
  errors,
  disabled,
}: IProps) => {
  const intl = useIntl()
  const hasUSTax = sumBy('doorSalesTax', doorPriceBreakdown) > 0

  return (
    <>
      <OnDesktop>
        <Form spacing="small">
          <FormRow columnOnMobile spacing="small">
            <FormGroup
              required
              label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.label' })}
            />
            <FormGroup
              required
              label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.price.label' })}
            />
            <FormGroup
              required
              label={intl.formatMessage({
                id: 'new_event.tickets.ticket_type_edit.box_office_price.label',
              })}
            />
          </FormRow>
        </Form>
      </OnDesktop>
      <ul>
        {tiers.map((tier, idx) => (
          <PriceTierRow
            key={tier.id}
            tier={tier}
            idx={idx}
            handleChange={handleChange}
            handleBlur={handleBlur}
            currency={currency}
            setFieldValue={setFieldValue}
            errors={errors}
            disabled={disabled && !tier.id.startsWith('new')}
          />
        ))}
      </ul>
      {hasUSTax && (
        <DoorPriceInfo isLoading={isLoading}>
          <Table>
            <TableHeader>
              <Row>
                <HeaderCell>
                  {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.label' })}
                </HeaderCell>
                <HeaderCell textAlign="right">
                  {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.box_office_price.label' })}
                </HeaderCell>
                <HeaderCell textAlign="right">{intl.formatMessage({ id: 'fees.salesTax' })}</HeaderCell>
                <HeaderCell textAlign="right">
                  {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.box_office_fan_price.label' })}
                </HeaderCell>
              </Row>
            </TableHeader>
            <TableBody>
              {doorPriceBreakdown?.map(
                (tier, idx) =>
                  tier && (
                    <Row key={`doorSalePriceTax-${idx}`}>
                      <Cell>{tiers[idx].name || '-'}</Cell>
                      <Cell textAlign="right">
                        {intl.formatNumber(
                          (tier.doorSalesPrice || 0) / 100,
                          CURRENCY(tier.doorSalesPrice || 0, currency)
                        )}
                      </Cell>
                      <Cell textAlign="right">
                        {intl.formatNumber(
                          (tier.doorSalesTax || 0) / 100,
                          CURRENCY((tier.doorSalesTax || 0) / 100, currency)
                        )}
                      </Cell>
                      <Cell textAlign="right">
                        {intl.formatNumber(
                          (tier.doorSalesPriceTaxed || 0) / 100,
                          CURRENCY((tier.doorSalesPriceTaxed || 0) / 100, currency)
                        )}
                      </Cell>
                    </Row>
                  )
              )}
            </TableBody>
          </Table>
        </DoorPriceInfo>
      )}
    </>
  )
}

export default memo(EventTicketTypePriceTiersDoorSales)
