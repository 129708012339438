import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { concat, find, map, snakeCase, uniq } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { EventCostCurrency, PrintedTicketFormat } from '../../../enums.generated'
import getCountries, { getNameByAlpha2 } from '../../../utils/countries'
import { localeContext } from '../../../context/locale'
import { getCurrencySymbol, OFFICIALLY_SUPPORTED_CURRENCIES } from '../../../utils/currency'
import IEventFormTickets from '../types/Tickets'
import { BARCODE_TYPES } from '../../../constants/barcodeTypes'
import { TICKET_TYPES } from '../../../constants/ticketTypes'
import useCountryRestrictionsActive from './useCountryRestrictionsActive'

const PRINTED_TICKET_FORMATS: Array<PrintedTicketFormat> = [
  'BOCA_55X2',
  'BOCA_6X3_NO_LOGO',
  'NO_PRINTER',
  'STAR_RECEIPT',
  'STAR_RECEIPT_ETICKET',
]

const useTicketsFields = () => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const isFirstMount = useRef(true)

  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormTickets>()

  const printedTicketFormatOptions = useMemo(
    () =>
      map(
        (f) => ({
          value: f,
          label: intl.formatMessage({ id: `new_event.settings.printed_ticket_format.options.${f.toLowerCase()}` }),
        }),
        PRINTED_TICKET_FORMATS
      ),
    [intl]
  )
  const setPrintedTicketFormat = useCallback((id: any) => setFieldValue('printedTicketFormat', id), [setFieldValue])

  const printedTicketFormatOption = useMemo(
    () => find(['value', values.printedTicketFormat], printedTicketFormatOptions),
    [printedTicketFormatOptions, values.printedTicketFormat]
  )

  const barcodeTypeOptions = useMemo(
    () =>
      map(
        (f) => ({
          value: f,
          label: f
            ? intl.formatMessage({ id: `new_event.tickets.barcode_type.options.${snakeCase(f)}` })
            : intl.formatMessage({ id: 'na' }),
        }),
        BARCODE_TYPES
      ),
    [intl]
  )
  const setBarcodeType = useCallback((id: any) => setFieldValue('barcodeType', id), [setFieldValue])

  const barcodeTypeOption = useMemo(
    () => find(['value', values.barcodeType || ''], barcodeTypeOptions),
    [barcodeTypeOptions, values.barcodeType]
  )

  const countriesList = useMemo(
    () => map(({ alpha2, label }) => ({ value: alpha2, label }), getCountries(intl, locale)),
    [intl, locale]
  )

  const restrictCountries = useMemo(
    () => map((value) => ({ value, label: getNameByAlpha2(value || '', locale) }), values.restrictCountries || []),
    [values.restrictCountries, locale]
  )

  const setRestrictCountries = useCallback(
    (value: any) => setFieldValue('restrictCountries', value || []),
    [setFieldValue]
  )

  const [countryRestrictionsActive, setCountryRestrictionsActive] = useCountryRestrictionsActive()

  const changeCountryRestrictionsActive = useCallback(
    (e: any) => {
      const isCustom = e.target.checked as boolean
      if (!isCustom) {
        setFieldValue('restrictCountries', [])
      }
      setCountryRestrictionsActive(isCustom)
    },
    [setCountryRestrictionsActive, setFieldValue]
  )

  useEffect(() => {
    if (isFirstMount.current) {
      setCountryRestrictionsActive((values.restrictCountries || []).length > 0)
    }

    isFirstMount.current = false
  }, [setCountryRestrictionsActive, values.restrictCountries])

  const restrictScope = values.eventType !== 'LIVE' && values.diceTvPlatform === 'DICE' ? 'streaming' : 'normal'
  const restrictKind = values.restrictCountriesKind?.toLowerCase()

  const costCurrencyOptions: { value: string; label: string }[] = useMemo(() => {
    const currencyList = uniq(concat(OFFICIALLY_SUPPORTED_CURRENCIES, values.costCurrency || 'GBP'))

    return map((c: EventCostCurrency) => {
      const currencySymbol = getCurrencySymbol(intl, c)
      return { value: c, label: `${currencySymbol} ${c}` }
    }, currencyList) as any
  }, [intl, values.costCurrency])

  const setCostCurrency = useCallback((id: any) => setFieldValue('costCurrency', id), [setFieldValue])

  const costCurrencyOption = useMemo(
    () => find(['value', values.costCurrency], costCurrencyOptions),
    [costCurrencyOptions, values.costCurrency]
  )

  const ticketTypeOptions = useMemo(
    () =>
      map(
        (f) => ({
          value: f,
          label: intl.formatMessage({ id: `venue_form.tickets.ticket_type.options.${snakeCase(f)}` }),
        }),
        TICKET_TYPES
      ),
    [intl]
  )
  const setTicketType = useCallback((id: any) => setFieldValue('ticketType', id), [setFieldValue])

  const ticketTypeOption = useMemo(
    () => (values.ticketType ? find(['value', values.ticketType || ''], ticketTypeOptions) : null),
    [ticketTypeOptions, values.ticketType]
  )

  return {
    costCurrencyOptions,
    costCurrencyOption,
    setCostCurrency,

    printedTicketFormatOptions,
    printedTicketFormatOption,
    setPrintedTicketFormat,

    barcodeTypeOptions,
    barcodeTypeOption,
    setBarcodeType,

    ticketTypeOptions,
    ticketTypeOption,
    setTicketType,

    restrictCountries,
    setRestrictCountries,
    countriesList,
    countryRestrictionsActive,
    changeCountryRestrictionsActive,
    restrictScope,
    restrictKind,
  }
}

export default useTicketsFields
