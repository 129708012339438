/**
 * @generated SignedSource<<845b48f2313eb0ff8ddb571a5d7b4be6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RepeatEnds = "OCCURRENCES" | "UNTIL" | "%future added value";
export type RepeatFrequency = "BI_WEEKLY" | "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type RepeatOn = "LAST_WEEK_DAY" | "SAME_DAY" | "SAME_WEEK_AND_DAY" | "%future added value";
export type EventReviewModalScheduleQuery$variables = {
  announceDate: string;
  date: string;
  endDate: string;
  frequency: RepeatFrequency;
  occurrences?: number | null;
  offSaleDate: string;
  onSaleDate: string;
  repeatEnds: RepeatEnds;
  repeatOn?: RepeatOn | null;
  timezoneName?: string | null;
  until?: string | null;
};
export type EventReviewModalScheduleQuery$data = {
  readonly viewer: {
    readonly schedule: ReadonlyArray<{
      readonly date: string | null;
    } | null> | null;
  } | null;
};
export type EventReviewModalScheduleQuery = {
  response: EventReviewModalScheduleQuery$data;
  variables: EventReviewModalScheduleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "announceDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "frequency"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "occurrences"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offSaleDate"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onSaleDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "repeatEnds"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "repeatOn"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timezoneName"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "until"
},
v11 = {
  "alias": "schedule",
  "args": [
    {
      "kind": "Variable",
      "name": "announceDate",
      "variableName": "announceDate"
    },
    {
      "kind": "Variable",
      "name": "date",
      "variableName": "date"
    },
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "endDate"
    },
    {
      "kind": "Variable",
      "name": "frequency",
      "variableName": "frequency"
    },
    {
      "kind": "Variable",
      "name": "occurrences",
      "variableName": "occurrences"
    },
    {
      "kind": "Variable",
      "name": "offSaleDate",
      "variableName": "offSaleDate"
    },
    {
      "kind": "Variable",
      "name": "onSaleDate",
      "variableName": "onSaleDate"
    },
    {
      "kind": "Variable",
      "name": "repeatEnds",
      "variableName": "repeatEnds"
    },
    {
      "kind": "Variable",
      "name": "repeatOn",
      "variableName": "repeatOn"
    },
    {
      "kind": "Variable",
      "name": "timezoneName",
      "variableName": "timezoneName"
    },
    {
      "kind": "Variable",
      "name": "until",
      "variableName": "until"
    }
  ],
  "concreteType": "EventSchedule",
  "kind": "LinkedField",
  "name": "recurrentEventsSchedule",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventReviewModalScheduleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v10/*: any*/),
      (v9/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventReviewModalScheduleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b1b716f4a06aabcf4f6547447fd85a6",
    "id": null,
    "metadata": {},
    "name": "EventReviewModalScheduleQuery",
    "operationKind": "query",
    "text": "query EventReviewModalScheduleQuery(\n  $announceDate: Time!\n  $onSaleDate: Time!\n  $offSaleDate: Time!\n  $date: Time!\n  $endDate: Time!\n  $frequency: RepeatFrequency!\n  $repeatEnds: RepeatEnds!\n  $occurrences: Int\n  $until: Time\n  $timezoneName: String\n  $repeatOn: RepeatOn\n) {\n  viewer {\n    schedule: recurrentEventsSchedule(announceDate: $announceDate, onSaleDate: $onSaleDate, offSaleDate: $offSaleDate, date: $date, endDate: $endDate, frequency: $frequency, repeatEnds: $repeatEnds, occurrences: $occurrences, until: $until, repeatOn: $repeatOn, timezoneName: $timezoneName) {\n      date\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfe28bd67e76cbfa4748a17df9c15c0c";

export default node;
