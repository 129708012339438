import { IntlShape } from 'react-intl/src/types'
import { IFeaturedArea } from '../../components/Event/EventFeatureModal'
import { getNameByAlpha2 } from '../countries'
import { ILocale } from '../../intl'

export const formatLocation = (intl: IntlShape, locale: ILocale, feat: IFeaturedArea) => {
  if (feat.locationString) {
    return `${feat.locationString} · ${intl.formatMessage(
      { id: `units.${feat.locationUnits}` },
      {
        num: intl.formatNumber(feat.locationRadius || 0, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      }
    )}`
  }
  
  return feat.countryCode
    ? getNameByAlpha2(feat.countryCode, locale)
    : intl.formatMessage({ id: 'event_feature_modal.worldwide.label' })
}