import React, { lazy } from 'react'
import { Route, Routes } from 'react-router'
import PermissionGate from '../gates/PermissionGate'

const LinkoutsPage = lazy(() => import(/* webpackChunkName: "linkouts" */ '../../flows/Linkouts/LinkoutsPage'))
const LinkoutPage = lazy(() => import(/* webpackChunkName: "linkouts" */ '../../flows/Linkout/LinkoutPage'))

const LinkoutsSection = () => (
  <Routes>
    <Route
      index
      element={
        <PermissionGate permission="read:linkout">
          <LinkoutsPage />
        </PermissionGate>
      }
    />
    <Route
      path="new"
      element={
        <PermissionGate permission="create:linkout">
          <LinkoutPage />
        </PermissionGate>
      }
    />
    <Route
      path=":id"
      element={
        <PermissionGate permission="read:linkout">
          <LinkoutPage />
        </PermissionGate>
      }
    />
  </Routes>
)

export default LinkoutsSection
