/**
 * @generated SignedSource<<3c297d7e060d2ec3acade2bfaa9e78b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PriceTierTypes = "allocation" | "time" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTierSelector_event$data = {
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly priceTiersBreakdown: ReadonlyArray<{
        readonly appSold: number;
        readonly posSold: number;
        readonly priceTier: {
          readonly allocation: number | null;
          readonly id: string;
          readonly name: string | null;
        };
        readonly reserved: number;
      } | null>;
      readonly ticketType: {
        readonly allocation: number;
        readonly archived: boolean | null;
        readonly id: string;
        readonly isStream: boolean;
        readonly name: string | null;
        readonly priceTierType: PriceTierTypes | null;
      };
      readonly totalAppSold: number;
      readonly totalPosSold: number;
      readonly totalReserved: number;
    } | null>;
  } | null;
  readonly " $fragmentType": "useTierSelector_event";
};
export type useTierSelector_event$key = {
  readonly " $data"?: useTierSelector_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTierSelector_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTierSelector_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceTierType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isStream",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalReserved",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceTierBreakdown",
              "kind": "LinkedField",
              "name": "priceTiersBreakdown",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "posSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reserved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceTier",
                  "kind": "LinkedField",
                  "name": "priceTier",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "21146952284b25f54c2ac4f86b5201b4";

export default node;
