/**
 * @generated SignedSource<<ac4c7721fc4b9b4dc6025a8864bbab97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type trackSearchSpotifyQuery$variables = {
  q: string;
};
export type trackSearchSpotifyQuery$data = {
  readonly spotify: {
    readonly search: {
      readonly tracks: {
        readonly items: ReadonlyArray<{
          readonly artists: ReadonlyArray<{
            readonly images: ReadonlyArray<{
              readonly url: string;
            } | null> | null;
            readonly name: string;
          } | null> | null;
          readonly externalUrls: {
            readonly spotify: string;
          } | null;
          readonly name: string;
          readonly preview_url: string | null;
          readonly value: string;
        } | null>;
      } | null;
    } | null;
  } | null;
};
export type trackSearchSpotifyQuery = {
  response: trackSearchSpotifyQuery$data;
  variables: trackSearchSpotifyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SpotifyViewer",
    "kind": "LinkedField",
    "name": "spotify",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "limit",
            "value": 10
          },
          {
            "kind": "Variable",
            "name": "q",
            "variableName": "q"
          },
          {
            "kind": "Literal",
            "name": "type",
            "value": [
              "track"
            ]
          }
        ],
        "concreteType": "SpotifySearchResponse",
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SpotifyTracksResponse",
            "kind": "LinkedField",
            "name": "tracks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SpotifyTrackObject",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": "value",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": "preview_url",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "previewUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpotifyExternalUrlObject",
                    "kind": "LinkedField",
                    "name": "externalUrls",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spotify",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpotifyArtistObject",
                    "kind": "LinkedField",
                    "name": "artists",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SpotifyImagesObject",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackSearchSpotifyQuery",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackSearchSpotifyQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7e0b8333fb3fedb06ed3613af83552a6",
    "id": null,
    "metadata": {},
    "name": "trackSearchSpotifyQuery",
    "operationKind": "query",
    "text": "query trackSearchSpotifyQuery(\n  $q: String!\n) {\n  spotify {\n    search(limit: 10, q: $q, type: [track]) {\n      tracks {\n        items {\n          value: id\n          name\n          preview_url: previewUrl\n          externalUrls {\n            spotify\n          }\n          artists {\n            name\n            images {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed09d3876b3d808eaf1dd42864d7c99a";

export default node;
