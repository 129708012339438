import { isNil, isNumber } from 'lodash/fp'
import React, { forwardRef, memo, ReactNode, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { authContext } from '../context/auth'
import { notificationContext } from '../context/notification'
import copyToClipboard from '../utils/copyToClipboard'
import { isSaved } from '../utils/entityStatus'
import { unwrapStringId } from '../utils/unwrapId'
import { color, font } from '../utils/variables'

const DIGITS_ONLY = /^[0-9]+$/

const Tag = styled.button`
  display: inline-flex;
  align-items: center;
  height: 22px;

  padding: 4px 6px;

  border: 1px solid ${color.lightgrey};
  border-radius: 4px;

  color: ${color.text};
  font-weight: normal;
  background: none;
  outline: none;

  font-size: ${font.size.xs}px;
  line-height: 120%;
  white-space: nowrap;

  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${color.lightgrey};
  }
`

interface IProps {
  id: number | string | undefined | null
  successMessage?: ReactNode
  errorMessage?: ReactNode
  className?: string
  noLabel?: boolean
}

const IdTag = forwardRef<HTMLButtonElement, IProps>(({ id, successMessage, errorMessage, className, noLabel }, ref) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)

  const isRealId = !isNil(id) && (isNumber(id) || isSaved({ id }))
  const value = isRealId ? (isNumber(id) || DIGITS_ONLY.test(id) ? String(id) : String(unwrapStringId(id) || id)) : null

  const doCopy = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      if (!value) return

      copyToClipboard(value)
        .then(() => addNotification('success', successMessage || intl.formatMessage({ id: 'action.copy_success' })))
        .catch(() => addNotification('error', errorMessage || intl.formatMessage({ id: 'action.copy_error' })))
    },
    [addNotification, errorMessage, intl, successMessage, value]
  )

  if (!user.diceStaff || !value) return null

  return (
    <Tag type="button" ref={ref} data-id={value} data-raw={id} onClick={doCopy} className={className}>
      {noLabel ? value : intl.formatMessage({ id: 'id_tag.label' }, { id: value })}
    </Tag>
  )
})

export default styled(memo(IdTag))``
