import React, { FC, useContext, useMemo, ReactNode, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { compact } from 'lodash/fp'
import Svg from '../../../components/Svg'
import { input, color, font, mediaQuery } from '../../../utils/variables'
import { Divider, FormRow } from '../../../components/Form'
import FormGroup from '../../../components/FormGroup'
import IconButton from '../../../components/IconButton'
import { EventType, VenueTier } from '../../../enums.generated'
import venueImage from '../../Venues/utils/venueImage'
import MapboxMap from '../../../components/MapboxMap'
import DiceBadge from '../../../components/DiceBadge'
import { authContext } from '../../../context/auth'
import Badge from '../../../components/Badge'
import VenueFeesWarning from './VenueFeesWarning'
import { textStyle } from '../../../utils/typography'
import FormField from '../../../components/FormField'
import { TitleTooltip, TooltipHelpIcon } from '../../../components/Tooltip'
import Checkbox from '../../../components/Checkbox'

const Venue = styled.div<{ hasError?: boolean; joinNtsGroup: boolean }>`
  border: 2px solid ${({ hasError }) => (hasError ? color.error : color.lightgrey)};
  border-radius: 4px;

  min-height: 132px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 8px;

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
    height: auto;
  `}

  ${(props) =>
    props.joinNtsGroup &&
    css`
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`

const VenueContainer = styled.div`
  display: flex;
`

const ImagePlaceholder = styled.div<{ extraTall?: boolean }>`
  position: relative;
  width: ${({ extraTall }) => (extraTall ? 144 : 128)}px;

  ${mediaQuery.lessThan('tablet')`
    display: none;
  `}

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }
`

const ImagePreview = styled.img<{ extraTall?: boolean }>`
  width: ${({ extraTall }) => (extraTall ? 144 : 128)}px;
  object-fit: cover;

  border-radius: 4px;

  ${mediaQuery.lessThan('tablet')`
    display: none;
  `}
`

const VenueInfoPanel = styled.div<{ hasError?: boolean }>`
  border-style: solid;
  border-width: 0 0 0 2px;
  border-color: ${({ hasError }) => (hasError ? color.error : color.lightgrey)};
  padding: 24px;
  color: ${color.darkgrey};
  display: flex;
  flex: 1;
  overflow: hidden;
  align-items: center;

  strong {
    color: ${color.text};
  }

  ${mediaQuery.lessThan('tablet')`
    border-width: 0;
    font-size: ${font.size.sm}px;
    padding: 16px;
  `}
`

const VenueInfoAddress = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`

const VenueInfoActions = styled.div`
  display: flex;
  align-self: center;
  margin-left: 32px;

  ${mediaQuery.lessThan('tablet')`
    margin-left: 16px;
  `}
`

const VenueName = styled.div`
  padding-top: 3px;
  color: ${color.text};
  ${textStyle.interactive.lg}
  margin-bottom: 8px;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${mediaQuery.lessThan('tablet')`
    max-width: calc(100vw - 120px);
    font-size: ${font.size.base}px;
    padding: 0;
    margin-bottom: 4px;
  `}
`

const RemoveButton = styled(IconButton)`
  color: ${color.black};

  &:hover {
    color: ${color.black};
  }
`

const Map = styled(MapboxMap)`
  margin-top: 16px;
`

const Hint = styled.div`
  margin-top: 8px;
  align-items: center;
  color: ${color.greyer};
  ${textStyle.functional.sm};

  a {
    font-weight: bold;
    color: ${color.text};
  }
`

const SBadge = styled(Badge)`
  background-color: ${color.white};
  color: ${color.text};
  border: 1px solid ${color.lightgrey};
  margin-bottom: 8px;
  flex-shrink: 0;

  svg {
    width: 18px;
    height: 18px;
    margin: -3px 2px 0 -2px;
  }
`

const NotInMIOBadge = styled(Badge)`
  margin-top: 8px;
  width: max-content;
  color: ${color.text};
`

const NtsGroup = styled.div<{ hasNts: boolean }>`
  ${(props) =>
    props.hasNts &&
    css`
      padding: 0 16px 16px;
      border: 2px solid ${input.borderColor};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      ${Map} {
        margin: 0 -16px;
      }
    `}
`

const NtsContent = styled.div<{ mtSm: boolean }>`
  margin-top: 24px;

  ${mediaQuery.greaterThan('tablet')`
    margin-top: 32px;
  `}

  ${(props) =>
    props.mtSm &&
    css`
      margin-top: 16px !important;
    `}
`

const VenueSpace = styled.div``
const VenueSpaceCheckbox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  ${Checkbox} {
    display: block;
  }
  & > svg {
    margin-top: 4px;
  }
`
const VenueSpaceSelector = styled.div`
  margin-left: 32px;
  margin-top: 12px;
`
const VenueSpaceHint = styled.span`
  display: block;
  margin-top: 4px;
  ${textStyle.functional.sm};
  color: ${color.darkgrey};
`

interface IVenue {
  value?: string
  label: string | null

  tier?: VenueTier | null

  latitude?: number | null
  longitude?: number | null
  fullAddress: string | null

  venueImages: null | ReadonlyArray<null | { attachment: null | { cdnUrl: null | string } }>
  profileDetails?: null | {
    imageAttachment: null | {
      cdnUrl: null | string
    }
    imageCropRegion: null | {
      x: number | null
      y: number | null
      width: number | null
      height: number | null
    }
  }
  venueSpaces?: Array<{
    value: string
    label: string
  } | null> | null
}

interface IProps {
  eventType: EventType | null
  allowClear?: boolean
  allowEdit?: boolean
  venue: IVenue
  doClear?: () => void
  doEdit?: () => void
  error?: any
  customVenueName?: string | null
  venueSpace?: { value: string; label: string } | null
  setVenueSpace?: (v: any) => void
  required?: boolean
  hideLabel?: boolean | null
  showVenueChangedFeesWarning?: boolean | null
  notInMIO?: boolean
  ntsComponent?: ReactNode
}

const EventSelectedVenue: FC<React.PropsWithChildren<IProps>> = ({
  eventType,
  allowClear,
  allowEdit,
  venue,
  doClear,
  doEdit,
  error,
  customVenueName,
  venueSpace,
  setVenueSpace,
  children,
  required,
  hideLabel,
  showVenueChangedFeesWarning,
  notInMIO,
  ntsComponent,
}) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const [showVenueSpaces, setShowVenueSpaces] = useState<boolean>(!!venueSpace)

  const venueImageUrl = useMemo(() => venueImage(venue, 300), [venue])

  const isPureStream = eventType === 'STREAM'

  const fieldLabel = useMemo(() => {
    // prettier-ignore
    return hideLabel
      ? null
      : intl.formatMessage({
        id: isPureStream ? 'new_event.basics.streaming_from.label' : 'new_event.basics.venue.label',
      })
  }, [hideLabel, intl, isPureStream])

  const hasVenueSpaces = useMemo(() => (venue.venueSpaces?.length || 0) > 0, [venue])
  const venueSpacesOptions = useMemo(() => compact(venue.venueSpaces), [venue])
  const handleChangeUseVenueSpace = useCallback(() => {
    if (setVenueSpace && showVenueSpaces) {
      setVenueSpace(null)
    }
    setShowVenueSpaces((v) => !v)
  }, [setVenueSpace, showVenueSpaces])
  const handleChangeVenueSpace = useCallback(
    (id: string, venueSpace: { label: string; value: string }) => {
      if (setVenueSpace && venueSpace) {
        setVenueSpace(venueSpace)
      }
    },
    [setVenueSpace]
  )

  const hasFeesWarning = !!(showVenueChangedFeesWarning && venue.value)
  const hasMap = !!(venue && venue.longitude && venue.latitude)
  const hasHint = !!(user.diceStaff && venue.value)

  return (
    <>
      <FormRow columnOnMobile data-name="primaryVenue" data-disabled={!allowClear}>
        <div>
          <FormGroup label={fieldLabel} error={error} required={required}>
            <Venue hasError={!!error} joinNtsGroup={!!ntsComponent && !hasMap}>
              <VenueContainer>
                {venueImageUrl ? (
                  <ImagePreview
                    extraTall={user.diceStaff && !!venue.tier}
                    src={venueImageUrl}
                    alt={venue.label || ''}
                  />
                ) : (
                  <ImagePlaceholder extraTall={user.diceStaff && !!venue.tier}>
                    <Svg icon="venue" />
                  </ImagePlaceholder>
                )}
                <VenueInfoPanel hasError={!!error}>
                  <VenueInfoAddress>
                    {user.diceStaff && venue.tier && (
                      <SBadge>
                        <DiceBadge />
                        {intl.formatMessage({ id: 'price_tier' })}{' '}
                        {venue.tier.substring(venue.tier.indexOf('_') + 1, venue.tier.length)}
                      </SBadge>
                    )}
                    <VenueName>{venueSpace?.label || venue.label}</VenueName>
                    <div>{venue.fullAddress || customVenueName}</div>
                    {user.diceStaff && notInMIO && (
                      <NotInMIOBadge>{intl.formatMessage({ id: 'new_event.basics.not_in_mio' })}</NotInMIOBadge>
                    )}
                  </VenueInfoAddress>
                  <VenueInfoActions>
                    {allowEdit && <RemoveButton icon="edit" onClick={doEdit} data-id="editVenue" />}
                    {allowClear && <RemoveButton icon="trash" onClick={doClear} data-id="removeVenue" />}
                  </VenueInfoActions>
                </VenueInfoPanel>
              </VenueContainer>
              {hasVenueSpaces && (allowClear || allowEdit || !!venueSpace) && (
                <>
                  <Divider className="mt-md mb-md" />
                  <VenueSpace>
                    <VenueSpaceCheckbox>
                      <Checkbox
                        label={intl.formatMessage({ id: 'new_event.basics.venue_space.add_space' })}
                        checked={!!showVenueSpaces}
                        onChange={handleChangeUseVenueSpace}
                        disabled={!allowEdit && !allowClear}
                      />
                    </VenueSpaceCheckbox>
                    {showVenueSpaces && (
                      <VenueSpaceSelector>
                        <FormField
                          control="select"
                          placeholder={intl.formatMessage({ id: 'new_event.basics.venue_space.placeholder' })}
                          options={venueSpacesOptions || []}
                          value={venueSpace}
                          onChange={handleChangeVenueSpace}
                          disabled={!allowEdit && !allowClear}
                        />
                        <VenueSpaceHint>
                          {intl.formatMessage({ id: 'new_event.basics.venue_space.hint' })}
                        </VenueSpaceHint>
                      </VenueSpaceSelector>
                    )}
                  </VenueSpace>
                </>
              )}
            </Venue>
          </FormGroup>

          <NtsGroup hasNts={!!ntsComponent}>
            {hasFeesWarning && (
              <FormRow className="mt-md mb-md" key="fees">
                <VenueFeesWarning
                  venueId={venue.value as string}
                  message={intl.formatMessage({
                    id: user.diceStaff
                      ? 'new_event.basics.venue_changed_fees_warning_dice'
                      : 'new_event.basics.venue_changed_fees_warning',
                  })}
                />
              </FormRow>
            )}
            {hasMap && (
              <Map
                key="map"
                withLink
                longitude={venue.longitude as number}
                latitude={venue.latitude as number}
                zoom={13}
                className="mt-zero"
              />
            )}
            {hasHint && (
              <Hint key="hint">
                <DiceBadge />
                {intl.formatMessage(
                  { id: 'new_event.basics.venue.hint' },
                  {
                    a: (str: string) => (
                      <Link target="_blank" to={`/venues/${venue.value}/details`}>
                        {str}
                      </Link>
                    ),
                  }
                )}
              </Hint>
            )}
            {ntsComponent && <NtsContent mtSm={!hasFeesWarning && !hasMap && !hasHint}>{ntsComponent}</NtsContent>}
          </NtsGroup>

          {children}
        </div>
      </FormRow>
    </>
  )
}

export default EventSelectedVenue
