import { useContext, useMemo } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { compact, find } from 'lodash/fp'
import { DeepReadonly } from 'ts-essentials'

import { IFee } from '../types/Tickets'
import { useAllowedAdhocFeesQuery } from '../../../__generated__/useAllowedAdhocFeesQuery.graphql'
import { authContext } from '../../../context/auth'

const useAllowedAdhocFees = (
  accountId: string | undefined | null,
  eventId: string | undefined | null
): DeepReadonly<IFee[]> => {
  const { account, availableAccounts } = useContext(authContext)

  const isCollaborator = useMemo(
    () => find(['id', account?.id], availableAccounts)?.membershipType === 'COLLABORATORS',
    [account?.id, availableAccounts]
  )

  const { account: feesAccount } = useLazyLoadQuery<useAllowedAdhocFeesQuery>(
    graphql`
      query useAllowedAdhocFeesQuery($accountId: ID!, $eventId: ID) {
        account: node(id: $accountId) {
          ... on Account {
            allowedAdhocFees(eventId: $eventId) {
              type
              unit
              amount
              split {
                destination
                amount
                unit
              }
            }
          }
        }
      }
    `,
    { accountId: accountId || 'oops', eventId },
    { fetchPolicy: accountId && !isCollaborator ? 'store-and-network' : 'store-only' }
  )

  const allowedAdhocFees = useMemo(() => compact(feesAccount?.allowedAdhocFees || []), [feesAccount?.allowedAdhocFees])

  return allowedAdhocFees
}

export default useAllowedAdhocFees
