import React, { FC, useMemo, AnchorHTMLAttributes } from 'react'
import { string } from 'yup'

const OpenUrl: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({ href = '', children, ...rest }) => {
  const url = useMemo<string>(() => {
    try {
      string().url().validateSync(href)
      return href
    } catch (err) {
      return '#'
    }
  }, [href])

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  )
}

export default OpenUrl
