import { object, string, array, ValidationError } from 'yup'
import { find, getOr, isInteger, isNil } from 'lodash/fp'
import { parseISO, differenceInSeconds, startOfMinute, subHours, differenceInMinutes } from 'date-fns'
import { gtDate, IDatePredicate, ISO_DATE_REGEX, ltDate, parseAtTimezone, TIME_REGEX } from '../../../utils/calendar'
import IEventFormTimeline from '../types/Timeline'
import IEventForm from '../types'
import { isItalianEvent } from '../../../utils/isCountryEvent'

const either =
  (f1: (...args: any) => any, f2: (...args: any) => any) =>
    (...args: any) =>
      f1(...args) || f2(...args)

type IDateField = 'date' | 'endDate' | 'announceDate' | 'onSaleDate' | 'offSaleDate'

function getDateRestrictions(field: IDateField, initialValues: Partial<IEventFormTimeline>) {
  const allowedDateAction = initialValues?.allowedLifecycleUpdates && initialValues?.allowedLifecycleUpdates[field]

  const fn = either(
    ltDate(field === 'announceDate' ? null : allowedDateAction?.minValue, initialValues.timezoneName),
    gtDate(allowedDateAction?.maxValue, initialValues.timezoneName)
  ) as IDatePredicate

  const now = new Date()
  const upTo24hrAroundEvent =
    initialValues.date &&
    initialValues.endDate &&
    subHours(parseISO(initialValues.date), 24) <= now &&
    parseISO(initialValues.endDate) >= now

  const offSale = initialValues.statusAsOfNow === 'off-sale'

  const onSaleTicketSold =
    initialValues.statusAsOfNow === 'on-sale' &&
    (initialValues.sales?.totalAppSold || 0) + (initialValues.sales?.totalPosSold || 0) > 0

  return allowedDateAction &&
    allowedDateAction.canUpdate &&
    !!initialValues.eventIdLive &&
    (onSaleTicketSold || upTo24hrAroundEvent || offSale)
    ? fn
    : null
}

const LineupSchema = object().shape({
  details: string().required(),
  time: string().nullable().matches(TIME_REGEX),
})

export function timezoneNameMatchesPrimaryVenueTimezoneFunction(this: any, values: IEventForm) {
  if (!(values.venues && values.venues.length)) {
    return true
  }
  const primaryVenueTz = values.venues[0]?.timezoneName
  let valid = true
  if (primaryVenueTz && values.timezoneName) {
    valid = values.timezoneName === primaryVenueTz
  }
  return (
    valid ||
    this.createError({ path: 'timezoneName', message: 'event_errors.timeline.timezone_must_match_venue_timezone' })
  )
}

function isNts(values: IEventForm, locale: any) {
  const isItalian = isItalianEvent(values, locale)
  const eventType = values.eventType

  const integrationDisabled = !!getOr(true, 'attractiveFields.integrationDisabled', values)

  const streamingTicketsIntegrationDisabled = !!getOr(
    true,
    'attractiveFields.streamingTicketsIntegrationDisabled',
    values
  )

  const isNts = isItalian && !(eventType === 'STREAM' && streamingTicketsIntegrationDisabled) && !integrationDisabled
  return isNts
}

const TimelineSchema = object()
  .shape({
    date: string().nullable().matches(ISO_DATE_REGEX).required(),
    endDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    onSaleDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    offSaleDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    announceDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    timezoneName: string().required(),
    lineup: array().nullable().of(LineupSchema),
  })
  .test(
    'timezoneNameMatchesPrimaryVenueTimezone',
    'Timezone should match primary venue timezone',
    timezoneNameMatchesPrimaryVenueTimezoneFunction
  )
  .test('announceDateBeforeOnSale', 'Announce date should be before on sale date', function (values) {
    const valid =
      isNil(values.announceDate) ||
      isNil(values.onSaleDate) ||
      startOfMinute(parseISO(values.announceDate)) <= startOfMinute(parseISO(values.onSaleDate))
    return valid || this.createError({ path: 'onSaleDate', message: 'event_errors.timeline.on_sale_before_announce' })
  })
  .test('onSaleDateBeforeOffSale', 'On sale date should be before off sale date', function (values) {
    const valid =
      isNil(values.onSaleDate) ||
      isNil(values.offSaleDate) ||
      startOfMinute(parseISO(values.onSaleDate)) < startOfMinute(parseISO(values.offSaleDate))
    return valid || this.createError({ path: 'offSaleDate', message: 'event_errors.timeline.off_sale_before_on_sale' })
  })
  .test('offSaleDateBeforeEnd', 'Off sale date should be before event end date', function (values) {
    const valid =
      isNil(values.offSaleDate) ||
      isNil(values.endDate) ||
      startOfMinute(parseISO(values.offSaleDate)) <= startOfMinute(parseISO(values.endDate))
    return valid || this.createError({ path: 'offSaleDate', message: 'event_errors.timeline.off_sale_after_end' })
  })
  .test('startDateBeforeEnd', 'Event start date should be before event end date', function (values) {
    const valid =
      isNil(values.date) ||
      isNil(values.endDate) ||
      startOfMinute(parseISO(values.date)) < startOfMinute(parseISO(values.endDate))
    return valid || this.createError({ path: 'date', message: 'event_errors.timeline.date_after_end' })
  })
  .test('famousOnSaleTwoHoursRule', 'On sale should not be closer than two hours to event start', function (values) {
    const valid =
      isNil(values.onSaleDate) ||
      isNil(values.date) ||
      differenceInSeconds(startOfMinute(parseISO(values.date)), startOfMinute(parseISO(values.onSaleDate))) > 7200
    return valid || this.createError({ path: 'onSaleDate', message: 'event_errors.timeline.on_sale_2hrs_before_date' })
  })
  .test('serverLifecycleLimits', 'Respects lifecycle limits', function (values) {
    const initialValues = (this.options.context as any)?.initialValues

    if (!initialValues) throw new Error('Validation serverLifecycleLimits needs initialValues in context')

    const fields: IDateField[] = ['date', 'endDate', 'announceDate', 'onSaleDate', 'offSaleDate']

    let err: ValidationError | null = null
    fields.forEach((field) => {
      const fn = getDateRestrictions(field, initialValues)
      if (fn) {
        const invalid = fn(parseAtTimezone(values[field], values.timezoneName), 'time')
        if (invalid) {
          if (!err) {
            err = new ValidationError(' ', values, 'timeline')
          }
          err.inner.push(this.createError({ path: field, message: 'new_event.timeline.server_lifecycle_limits_error' }))
        }
      }
    })

    return err || true
  })
  .test(
    'customCutoffDays',
    'Must choose a cutoff days option if custom cutoffDays option is chosen for "Refunds for scheduled events"',
    function (values) {
      const error =
        values.scheduleStatus === 'RESCHEDULED' &&
        !!values.flags.autoRescheduledEventRefunds.active &&
        !!values.flags.autoRescheduledEventRefunds.cutoffDaysCustom &&
        !values.flags.autoRescheduledEventRefunds.cutoff_days
      return error ? this.createError({ path: 'flags.autoRescheduledEventRefunds.cutoff_days', message: ' ' }) : true
    }
  )
  .test('subscriptionCode', 'Italian event should have subscriptionCode if it is subscription', function (values) {
    const locale = (this.options.context as any).locale

    if (!isNts(values, locale)) return true

    const isForceSubscription =
      !!getOr(true, 'attractiveFields.forceSubscription', values) || (values.venueSchedules || []).length > 0

    if (!values.attractiveFields?.subscriptionCode && isForceSubscription) {
      return this.createError({
        path: 'attractiveFields.subscriptionCode',
        message: 'new_event.abbonamento.errors.subscription_code',
      })
    }
    return true
  })
  .test(
    'forceSubscriptionLimit',
    'Italian event should have forceSubscriptionLimit if it is subscription',
    function (values) {
      const locale = (this.options.context as any).locale

      const ctx = (this.options.context as any).viewer || {}
      const { diceStaff } = ctx

      if (!isNts(values, locale)) return true

      const isForceSubscription =
        !!getOr(true, 'attractiveFields.forceSubscription', values) || (values.venueSchedules || []).length > 0

      if (
        isForceSubscription &&
        (!isInteger(values?.attractiveFields?.forceSubscriptionLimit) ||
          values?.attractiveFields?.forceSubscriptionLimit < 0 ||
          (!diceStaff && values?.attractiveFields?.forceSubscriptionLimit < 1))
      ) {
        return this.createError({
          path: 'attractiveFields.forceSubscriptionLimit',
          message: 'new_event.abbonamento.errors.subscription_limit',
        })
      }
      return true
    }
  )
  .test(
    'forceSubscriptionLimitMax',
    'Italian abbonamento event should have forceSubscriptionLimit between 0 and 99',
    function (values) {
      const locale = (this.options.context as any).locale

      if (!isNts(values, locale)) return true
      const isForceSubscription =
        !!getOr(true, 'attractiveFields.forceSubscription', values) || (values.venueSchedules || []).length > 0

      if (
        isForceSubscription &&
        (!isInteger(values?.attractiveFields?.forceSubscriptionLimit) ||
          values?.attractiveFields?.forceSubscriptionLimit < 0 ||
          values?.attractiveFields?.forceSubscriptionLimit > 99)
      ) {
        return this.createError({
          path: 'attractiveFields.forceSubscriptionLimit',
          message: 'new_event.abbonamento.errors.subscription_limit_bounds',
        })
      }
      return true
    }
  )
  .test(
    'forceSubscriptionLimitLinkedEvents',
    'Italian event should have events linked if it is subscription, unless dice staff (only validate when DRAFT)',
    function (values) {
      const locale = (this.options.context as any).locale

      const ctx = (this.options.context as any).viewer || {}
      const { diceStaff } = ctx

      if (diceStaff) return true
      if (values.state !== 'DRAFT') return true

      if (!isNts(values, locale)) return true

      const isForceSubscription =
        !!getOr(true, 'attractiveFields.forceSubscription', values) || (values.venueSchedules || []).length > 0

      if (
        isForceSubscription &&
        isInteger(values?.attractiveFields?.forceSubscriptionLimit) &&
        (values.attractiveFields.linkedEvents?.length || 0) !== (values?.attractiveFields?.forceSubscriptionLimit || 0)
      ) {
        return this.createError({
          path: 'attractiveFields.linkedEvents',
          message: 'new_event.abbonamento.errors.linked_events',
        })
      }
      return true
    }
  )
  .test(
    'forceSubscriptionLimitExtraLinkedEvents',
    'Italian event should not have more events linked than forceSubscriptionLimit (unless DICE staff)',
    function (values) {
      const locale = (this.options.context as any).locale

      const ctx = (this.options.context as any).viewer || {}
      const { diceStaff } = ctx

      if (diceStaff) return true

      if (!isNts(values, locale)) return true

      const isForceSubscription =
        !!getOr(true, 'attractiveFields.forceSubscription', values) || (values.venueSchedules || []).length > 0

      if (
        isForceSubscription &&
        isInteger(values?.attractiveFields?.forceSubscriptionLimit) &&
        (values.attractiveFields.linkedEvents?.length || 0) > (values?.attractiveFields?.forceSubscriptionLimit || 0)
      ) {
        return this.createError({
          path: 'attractiveFields.linkedEvents',
          message: 'new_event.abbonamento.errors.linked_events',
        })
      }
      return true
    }
  )
  .test(
    'forceSubscription24HourEvents',
    'Italian event must be abbonamento if event is >24h, unless dice staff',
    function (values) {
      const locale = (this.options.context as any).locale

      if (!values.date || !values.endDate) return true
      if (!isNts(values, locale)) return true

      const isForceSubscription =
        !!getOr(true, 'attractiveFields.forceSubscription', values) || (values.venueSchedules || []).length > 0

      const eventIs24h = differenceInMinutes(parseISO(values.endDate), parseISO(values.date)) > 1440

      if (eventIs24h && !isForceSubscription) {
        return this.createError({
          path: 'attractiveFields.forceSubscription',
          message: ' ',
        })
      }
      return true
    }
  )
  .test(
    'forceSubscriptionLimitLinkedAbbonamentos',
    'Abbonamento event should not have other abbonamento events linked',
    function (values) {
      const locale = (this.options.context as any).locale

      if (!isNts(values, locale)) return true

      const isForceSubscription =
        !!getOr(true, 'attractiveFields.forceSubscription', values) || (values.venueSchedules || []).length > 0

      const linkedEventsContainsAbbonamento = !!find(
        (e) => e?.attractiveFields?.forceSubscription,
        values.attractiveFields.linkedEvents ?? []
      )

      if (isForceSubscription && linkedEventsContainsAbbonamento) {
        return this.createError({
          path: 'attractiveFields.linkedEvents',
          message: 'new_event.abbonamento.errors.linked_event_is_abbonamento',
        })
      }
      return true
    }
  )

export default TimelineSchema
