import { compact, filter, map } from 'lodash/fp'
import { Environment } from 'react-relay'

import { markAsServer } from '../../../utils/entityStatus'
import uploadAttachment from './uploadAttachment'

const uploadEventImages = async (environment: Environment, event: any) => {
  const attachmentId = await (!event.rawImage || event.rawImage.cdnUrl.startsWith('http')
    ? null
    : uploadAttachment(environment, event.rawImage.cdnUrl, 'jpg').then((arr) => arr[0]))

  const logoAttachmentId = await (!event.rawBrandLogo || event.rawBrandLogo.cdnUrl.startsWith('http')
    ? null
    : uploadAttachment(environment, event.rawBrandLogo.cdnUrl, 'jpg').then((arr) => arr[0]))

  return filter(
    'attachmentId',
    map((img: any) => {
      const result = {
        attachmentId: img.attachment?.id || (img.type === 'brand' ? logoAttachmentId : attachmentId),
        cropRegion: img.cropRegion,
        type: img.type,
        id: img.id,
      }
      return markAsServer<typeof result>(result)
    }, compact(event.eventImages || []))
  )
}

export default uploadEventImages
