import React, { useEffect, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { map, isArray } from 'lodash/fp'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'

import graphqlOptionsLoader from '../../../utils/graphqlOptionsLoader'

const SELECTED_VENUES_QUERY = graphql`
  query useVenueOptionsSelectedVenuesQuery($where: VenueWhereInput) {
    viewer {
      venues(where: $where, first: 100) {
        edges {
          node {
            value: id
            label: name
            fullAddress
            profileDetails {
              imageAttachment {
                cdnUrl
              }
              imageCropRegion {
                height
                width
                x
                y
              }
            }
            venueImages {
              attachment {
                cdnUrl
              }
            }
          }
        }
      }
    }
  }
`

const QUERY_LOAD_VENUES = graphql`
  query useVenueOptionsVenueSearchQuery($searchTerm: String) {
    viewer {
      options: venues(searchTerm: $searchTerm, first: 50) {
        edges {
          node {
            value: id
            label: name
            hint: fullAddress
            fullAddress
            profileDetails {
              imageAttachment {
                cdnUrl
              }
              imageCropRegion {
                height
                width
                x
                y
              }
            }
            venueImages {
              attachment {
                cdnUrl
              }
            }
          }
        }
      }
    }
  }
`

export interface IVenue {
  value: string
  label: string | null
  hint?: string | null
  fullAddress?: string | null
  venueImages?: {
    attachment: {
      cdnUrl: string
    }
  }
}

function useVenueOptions(initialVenueIds?: string[] | string | null) {
  const environment = useRelayEnvironment()
  const [selectedVenues, setSelectedVenues] = useState<IVenue[] | IVenue | null>()

  const venueLoader = graphqlOptionsLoader(environment, QUERY_LOAD_VENUES, { fullText: true })

  // Get preselected venues
  useEffect(() => {
    let stillMounted = true
    if (initialVenueIds) {
      const where = isArray(initialVenueIds) ? { id: { in: initialVenueIds } } : { id: { eq: initialVenueIds } }
      fetchQuery_DEPRECATED(environment, SELECTED_VENUES_QUERY, { where }).then((data) => {
        const venues = map((edge) => edge.node, data?.viewer?.venues?.edges)
        if (stillMounted && venues && venues.length) {
          setSelectedVenues(venues)
        }
      })
    }
    return () => {
      stillMounted = false
    }
  }, [environment, initialVenueIds])

  return { selectedVenues, setSelectedVenues, venueLoader }
}

export default useVenueOptions
