/**
 * @generated SignedSource<<72f73958ab0139ee08e3a8b9f0ae9b95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type TvPlatform = "DICE" | "EXTERNAL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useEventNavigation_event$data = {
  readonly addressCountry: string | null;
  readonly allowedActions: {
    readonly createSocialLinks: boolean | null;
    readonly manageBoxOffice: boolean | null;
    readonly manageFacebook: boolean | null;
    readonly managePromotions: boolean | null;
    readonly readAdvancedStats: boolean | null;
    readonly readAllCustomerData: boolean | null;
    readonly readAnalytics: boolean | null;
    readonly readDoorSalesActivities: boolean | null;
    readonly readDoorlist: boolean | null;
    readonly readDoorlistExtras: boolean | null;
    readonly readMarketingOptIns: boolean | null;
    readonly readSocialLinks: boolean | null;
  } | null;
  readonly countryCode: string | null;
  readonly diceTvPlatform: TvPlatform | null;
  readonly eventIdLive: string | null;
  readonly eventSeatingChart: {
    readonly id: string;
  } | null;
  readonly eventType: EventType | null;
  readonly flags: {
    readonly seated: IBackendMap | null;
  } | null;
  readonly hdrTtys: ReadonlyArray<{
    readonly doorSalesEnabled: boolean;
    readonly id: string;
    readonly reservedSeating: boolean | null;
  } | null> | null;
  readonly id: string;
  readonly marketeers: ReadonlyArray<{
    readonly accessToken: string | null;
    readonly appOptInEnabled: boolean | null;
    readonly fbAccessToken: string | null;
    readonly fbPixelId: string | null;
    readonly gaTrackingId: string | null;
    readonly googleAdsConversionId: string | null;
    readonly googleAdsPurchaseConversionLabel: string | null;
    readonly id: string;
    readonly privacyPolicyLink: string | null;
    readonly tiktokPixelId: string | null;
    readonly twitterCheckoutInitiatedPixelId: string | null;
    readonly twitterPixelId: string | null;
    readonly twitterPurchasePixelId: string | null;
    readonly webOptInEnabled: boolean | null;
  } | null> | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly tokens: {
    readonly allCustomerDataToken: string | null;
  } | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly countryCode: string | null;
  } | null> | null;
  readonly " $fragmentType": "useEventNavigation_event";
};
export type useEventNavigation_event$key = {
  readonly " $data"?: useEventNavigation_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEventNavigation_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEventNavigation_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceTvPlatform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSeatingChart",
      "kind": "LinkedField",
      "name": "eventSeatingChart",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "hdrTtys",
      "args": null,
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedSeating",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readAnalytics",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readAdvancedStats",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readDoorlist",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readDoorlistExtras",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readAllCustomerData",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readMarketingOptIns",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createSocialLinks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readSocialLinks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageFacebook",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageBoxOffice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readDoorSalesActivities",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managePromotions",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TokensList",
      "kind": "LinkedField",
      "name": "tokens",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allCustomerDataToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Marketeer",
      "kind": "LinkedField",
      "name": "marketeers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fbAccessToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fbPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gaTrackingId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleAdsConversionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleAdsPurchaseConversionLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "privacyPolicyLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterCheckoutInitiatedPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterPurchasePixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webOptInEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appOptInEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "abd8ea4b5d90c4881635bc80d06464d7";

export default node;
