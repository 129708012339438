import styled from 'styled-components/macro'
import { color, mediaQuery } from '../utils/variables'
import { pageStickyTop } from '../utils/sticky'

export const NavItems = styled.ul`
  padding: 32px 0 0 32px;
  position: sticky;
  font-weight: bold;
  ${pageStickyTop('70px')}

  ${mediaQuery.lessThan('desktop')`
    position: relative;
    padding: 0 8px;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: space-between;
  `}
`

export const NavItem = styled.li<{ active?: boolean }>`
  color: ${({ active }) => (active ? color.primary : color.text)};
  cursor: pointer;

  & + & {
    margin-top: 32px;
  }
`

export const NavPanel = styled.div`
  background: ${color.white};
  border-right: 2px solid ${color.text};
  min-width: 240px;

  ${mediaQuery.lessThan('desktop')`
    display: none;
  `}
`
