import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import { filter, get } from 'lodash/fp'
import { FormattedMessage } from 'react-intl'

import graphqlOptionsLoader from '../graphqlOptionsLoader'

const DEFAULT_ACCOUNT_OWNER_FILTER_VALUE = {
  value: 'default_filter_value',
  label: <FormattedMessage id="all_account_owners" />,
}

const APPLIED_ACCOUNT_OWNER_FILTER_QUERY = graphql`
  query useAccountOwnerOptionsAppliedQuery($id: ID!) {
    node(id: $id) {
      value: id
      ... on User {
        label: email
      }
    }
  }
`

function useAccountOwnerOptions(initialAccountOwnerId?: string | null) {
  const environment = useRelayEnvironment()
  const [accountOwnersOptions, setAccountOwnersOptions] = useState<{ value: string; label: ReactNode }[]>([])

  const accountOwnerLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query useAccountOwnerOptionsLoaderQuery($where: UsersWhereInput) {
            viewer {
              options: users(first: 20, where: $where) {
                edges {
                  node {
                    value: id
                    label: email
                  }
                }
              }
            }
          }
        `,
        { searchField: 'email' }
      ),
    [environment]
  )

  // Get accounts
  useEffect(() => {
    let stillMounted = true
    if (initialAccountOwnerId) {
      fetchQuery_DEPRECATED(environment, APPLIED_ACCOUNT_OWNER_FILTER_QUERY, {
        id: initialAccountOwnerId,
      }).then((data) => {
        accountOwnerLoader('', (result) => {
          if (stillMounted) {
            const accountFilter = get('node', data)
            const filteredOptions = filter((v) => v.value !== accountFilter.value, result)
            setAccountOwnersOptions([DEFAULT_ACCOUNT_OWNER_FILTER_VALUE, accountFilter, ...filteredOptions])
          }
        })
      })
    } else {
      accountOwnerLoader('', (result) => {
        if (stillMounted) {
          setAccountOwnersOptions([DEFAULT_ACCOUNT_OWNER_FILTER_VALUE, ...result])
        }
      })
    }
    return () => {
      stillMounted = false
    }
  }, [accountOwnerLoader, environment, initialAccountOwnerId, setAccountOwnersOptions])

  return { accountOwnersOptions, accountOwnerLoader, allAccountOwnersOption: DEFAULT_ACCOUNT_OWNER_FILTER_VALUE }
}

export default useAccountOwnerOptions
