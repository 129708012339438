/**
 * @generated SignedSource<<24af4cded7d2a0c8bde6613d4f1dcdc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventPartSales_event$data = {
  readonly allocation: number;
  readonly id: string;
  readonly sales: {
    readonly totalAppSold: number;
    readonly totalPosSold: number;
    readonly totalWlRequests: number;
  } | null;
  readonly soldToday: number;
  readonly " $fragmentType": "EventPartSales_event";
};
export type EventPartSales_event$key = {
  readonly " $data"?: EventPartSales_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartSales_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventPartSales_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allocation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "soldToday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalAppSold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPosSold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalWlRequests",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "e67d8229298dc66b4d3e66ed7dc00134";

export default node;
