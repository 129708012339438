import { isSaved } from './entityStatus'

const DIGITS_ONLY = /^[0-9]+$/

export const splitId = (id?: string | null): [string, string] | null => {
  if (!id || !isSaved({ id })) return null
  try {
    return atob(id).split(':').slice(0, 2) as [string, string]
  } catch (err) {
    console.error(err)
    return null
  }
}

const unwrapId = (id?: string | null): number | null => {
  const split = splitId(id)
  if (!split) return null

  try {
    return parseInt(split[1], 10)
  } catch (err) {
    console.error(err)
    return null
  }
}

export default unwrapId

export const unwrapStringId = (id?: string | null): string | number | null => {
  const split = splitId(id)
  if (!split) return null

  return DIGITS_ONLY.test(split[1]) ? unwrapId(id) : split[1]
}
