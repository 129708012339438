import React, { lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router'
import { GenericErrorSub } from '../../components/GenericError'
import IntlConsumer from '../../components/IntlConsumer'
import { Loader, LoaderContainer } from '../../components/Loader'
import EventDetailsPage from '../../flows/EventDetails/EventDetailsPage'
import EventHeader from '../../flows/EventHeader/EventHeader'
import EventListPage from '../../flows/EventList/EventListPage'
import EventOverviewPage from '../../flows/EventOverview/EventOverviewPage'
import EventSubmissionPage from '../../flows/EventSubmission/EventSubmissionPage'
import EventSuccessPage from '../../flows/EventSuccess/EventSuccessPage'
import DiceGate from '../gates/DiceGate'
import PermissionGate from '../gates/PermissionGate'

const EventStreamPage = lazy(
  () => import(/* webpackChunkName: "ev_stream" */ '../../flows/EventStream/EventStreamPage')
)
const EventStreamAnalyticsPage = lazy(
  () => import(/* webpackChunkName: "ev_stream" */ '../../flows/EventStreamAnalytics/EventStreamAnalyticsPage')
)

const EventFinancesPage = lazy(
  () => import(/* webpackChunkName: "ev_finances" */ '../../flows/EventFinances/EventFinancesPage')
)
const EventFanSurveyPage = lazy(
  () => import(/* webpackChunkName: "ev_survey" */ '../../flows/EventFanSurvey/EventFanSurveyPage')
)
const EventDoorlistPage = lazy(
  () => import(/* webpackChunkName: "ev_doorlist" */ '../../flows/EventDoorlist/EventDoorlistPage')
)
const EventSeatmapPage = lazy(
  () => import(/* webpackChunkName: "ev_seatmap" */ '../../flows/EventSeatmap/EventSeatmapPage')
)
const EventSeatmapEditPage = lazy(
  () => import(/* webpackChunkName: "ev_seatmap" */ '../../flows/EventSeatmapEdit/EventSeatmapEditPage')
)
const EventBoxOfficePage = lazy(
  () => import(/* webpackChunkName: "ev_boxoffice" */ '../../flows/EventBoxOffice/EventBoxOfficePage')
)
const EventPromotionsPage = lazy(
  () => import(/* webpackChunkName: "ev_promotions" */ '../../flows/EventPromotions/EventPromotionsPage')
)
const EventPromotionsCreate = lazy(
  () => import(/* webpackChunkName: "ev_promotions" */ '../../flows/EventPromotions/EventPromotionsCreate')
)
const EventMarketingPage = lazy(
  () => import(/* webpackChunkName: "ev_marketing" */ '../../flows/EventMarketing/EventMarketingPage')
)
const EventMarketingLinksReport = lazy(
  () =>
    import(/* webpackChunkName: "ev_marketing" */ '../../flows/EventMarketingLinksReport/EventMarketingLinksReportPage')
)
const EventAnalyticsPage = lazy(
  () => import(/* webpackChunkName: "ev_analytics" */ '../../flows/EventAnalytics/EventAnalyticsPage')
)
const EventPayoutsPage = lazy(
  () => import(/* webpackChunkName: "ev_payouts" */ '../../flows/EventPayouts/EventPayoutsPage')
)
const EventActivityPage = lazy(
  () => import(/* webpackChunkName: "ev_activity" */ '../../flows/EventActivity/EventActivityPage')
)
const EventContactsPage = lazy(
  () => import(/* webpackChunkName: "ev_contacts" */ '../../flows/EventContacts/EventContactsPage')
)
const EventRefund = lazy(() => import(/* webpackChunkName: "ev_refund" */ '../../flows/EventRefund/EventRefund'))
const EventSyncPage = lazy(() => import(/* webpackChunkName: "ev_sync" */ '../../flows/EventSync/EventSyncPage'))

const EventSubmissionsListPage = lazy(
  () => import(/* webpackChunkName: "ev_submissions" */ '../../flows/EventSubmissionsList/EventSubmissionsListPage')
)
const FinancePayoutsPage = lazy(
  () => import(/* webpackChunkName: "fin_payouts" */ '../../flows/FinancePayouts/FinancePayoutsPage')
)

const EventsSection = () => {
  const loader = (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )

  return (
    <Routes>
      <Route path="live" element={<EventListPage key="live" />} />
      <Route path="drafts" element={<EventListPage key="drafts" />} />
      <Route path="past" element={<EventListPage key="past" />} />
      <Route path="cancelled" element={<EventListPage key="cancelled" />} />
      <Route path="submissions" element={<EventSubmissionsListPage key="submissions" />} />
      <Route
        path="payouts"
        element={
          <DiceGate>
            <PermissionGate permission="read:balances">
              <FinancePayoutsPage />
            </PermissionGate>
          </DiceGate>
        }
      />

      <Route
        path="new"
        element={
          <PermissionGate permission="create:event">
            <EventSubmissionPage />
          </PermissionGate>
        }
      />

      <Route path=":id/success" element={<EventSuccessPage />} />
      <Route
        path=":id/box-office"
        element={
          <Suspense fallback={loader}>
            <EventBoxOfficePage />
          </Suspense>
        }
      />

      <Route path=":id/marketing/links" element={<EventMarketingLinksReport />} />
      <Route path=":id/analytics/links" element={<EventMarketingLinksReport />} />

      <Route
        path=":id/refund/:fanEmail"
        element={
          <Suspense fallback={loader}>
            <EventRefund />
          </Suspense>
        }
      />

      <Route
        path=":id"
        element={
          <>
            <EventHeader>
              <Outlet />
            </EventHeader>
          </>
        }
      >
        <Route index element={<Navigate replace to="overview" />} />

        <Route path="edit" element={<EventSubmissionPage />} />
        <Route
          path="fan-survey"
          element={
            <PermissionGate sub permission="manage_data_collection:fan">
              <EventFanSurveyPage />
            </PermissionGate>
          }
        />
        <Route
          path="finances"
          element={
            <PermissionGate sub permission="read:balances">
              <EventFinancesPage />
            </PermissionGate>
          }
        />
        <Route path="analytics" element={<EventAnalyticsPage />} />
        <Route path="marketing" element={<EventMarketingPage />} />
        <Route
          path="seatmap"
          element={
            <PermissionGate sub permission="read_seatmap:event">
              <EventSeatmapPage />
            </PermissionGate>
          }
        />
        <Route
          path="seatmap-edit"
          element={
            <PermissionGate sub permission="read_seatmap:event">
              <EventSeatmapEditPage />
            </PermissionGate>
          }
        />
        <Route path="door-list" element={<EventDoorlistPage />} />
        <Route path="promotions" element={<EventPromotionsPage />} />
        <Route path="promotions/new" element={<EventPromotionsCreate />} />
        <Route path="promotions/:promotionId" element={<EventPromotionsCreate />} />
        <Route path="promotions/clone/:promotionId" element={<EventPromotionsCreate />} />

        <Route path="overview" element={<EventOverviewPage />} />
        <Route path="details" element={<EventDetailsPage />} />
        <Route path="stream" element={<EventStreamPage />} />

        <Route path="stream-analytics" element={<Navigate replace to="live" />} />

        <Route
          path="stream-analytics/:mode"
          element={
            <PermissionGate sub permission="read_analytics:event">
              <EventStreamAnalyticsPage />
            </PermissionGate>
          }
        />
        <Route
          path="payouts"
          element={
            <PermissionGate sub permission="read:balances">
              <EventPayoutsPage />
            </PermissionGate>
          }
        />

        <Route
          path=":id"
          element={
            <PermissionGate sub permission="update:event">
              <Navigate replace to="edit" />
            </PermissionGate>
          }
        />

        <Route path="contacts" element={<EventContactsPage />} />
        <Route path="activity" element={<EventActivityPage />} />
        <Route path="sync" element={<EventSyncPage />} />
        <Route
          path="*"
          element={
            <IntlConsumer>
              {(intl) => (
                <GenericErrorSub
                  title={intl.formatMessage({ id: 'generic_error.title' })}
                  description={intl.formatMessage({ id: 'generic_error.not_found_description' })}
                />
              )}
            </IntlConsumer>
          }
        />
      </Route>

      <Route path="*" element={<Navigate replace to="live" />} />
    </Routes>
  )
}

export default EventsSection
