import { fetchQuery_DEPRECATED, GraphQLTaggedNode } from 'relay-runtime'
import { map, debounce, identity, get } from 'lodash/fp'
import { Environment } from 'react-relay'

export type IOptions = Array<{
  value: string
  label: string
  [key: string]: any
}>

interface IBackendOptions {
  edges: [
    {
      node: {
        id: string
        value?: string
        label?: string
        name: string
      }
    }
  ]
}

type ICallback = (value: IOptions) => void
export type ILoader = (str: string, callback: ICallback) => void
type ILoaderOptions = {
  postProcess?: (opts: IOptions) => IOptions
  where?: any
  simple?: boolean
  fullText?: boolean
  immediate?: boolean
  searchField?: string
  root?: string
}

const graphqlOptionsLoader = (
  environment: Environment,
  query: GraphQLTaggedNode,
  loaderOptions?: ILoaderOptions,
  additionalParams?: any
): ILoader => {
  const { postProcess, where, simple, fullText, immediate, searchField, root } = loaderOptions || {}

  const searchFn = (str: string, callback: ICallback) => {
    const strSafe = str || ''

    const params = fullText
      ? { searchTerm: strSafe }
      : { where: { ...(where || {}), [searchField || 'name']: simple ? strSafe : { iLike: `%${strSafe}%` } } }

    const promise = fetchQuery_DEPRECATED(environment, query, {
      ...params,
      ...additionalParams,
    })
    const filter: (opts: IOptions, term?: string) => IOptions = postProcess || identity
    promise
      .then((rs) => {
        const options: IBackendOptions | undefined = root ? get(`${root}.options`, rs) : get('viewer.options', rs)
        callback(
          filter(
            map(
              ({ node: { value, label, id, name, ...rest } }) => ({
                value: value || id,
                label: label || name,
                ...rest,
              }),
              options
                ? options.edges ||
                    map(
                      (o) => ({
                        node: o,
                      }),
                      options
                    )
                : []
            )
          )
        )
      })
      .catch((err) => {
        console.error(err)
        callback([])
      })
  }

  return immediate ? searchFn : debounce(500, searchFn)
}

export default graphqlOptionsLoader
