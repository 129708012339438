import React, { FC, Suspense, useContext } from 'react'
import { Outlet } from 'react-router'

import ErrorBoundary from '../components/ErrorBoundary'
import { Loader, LoaderContainer } from '../components/Loader'
import { Page, SettingsPageContainer } from '../components/Page'
import { localeContext } from '../context/locale'
import PromoterSettingsSidebar from '../flows/PromoterSettingsSidebar/PromoterSettingsSidebar'

const PromoterSettingsLayout: FC = () => {
  const { phraseEnabled } = useContext(localeContext)

  return (
    <Page>
      <PromoterSettingsSidebar />
      <SettingsPageContainer ICEenabled={phraseEnabled}>
        <ErrorBoundary>
          <Suspense
            fallback={
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            }
          >
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </SettingsPageContainer>
    </Page>
  )
}

export default PromoterSettingsLayout
