import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { compact, filter, flow, reject, sumBy } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import { breakpoints } from '../../../../utils/variables'

import Value from '../../../../components/Value'
import { CURRENCY } from '../../../../utils/formatters/number'

import { TicketPoolSales_event$key } from '../../../../__generated__/TicketPoolSales_event.graphql'

interface IProps {
  event: TicketPoolSales_event$key
  ticketPool: {
    id: string
    maxAllocation: number | null
  }
}

const TicketPoolSales: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, ticketPool }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment TicketPoolSales_event on Event {
        costCurrency
        sales {
          ticketTypesBreakdown {
            totalDigitalValue
            ticketType {
              id
              archived
              ticketPoolId
            }
          }
        }
      }
    `,
    eventKey
  )

  const poolsTicketTypes = useMemo(
    () =>
      flow(
        compact,
        reject('ticketType.archived'),
        filter((tt: any) => tt.ticketType.ticketPoolId === ticketPool.id)
      )(event?.sales?.ticketTypesBreakdown || []) as any[],
    [event?.sales?.ticketTypesBreakdown, ticketPool.id]
  )

  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.desktop}px)` })

  return (
    <Value
      compact={isTablet}
      label={intl.formatMessage({ id: 'event_overview.ticket_breakdown.total_face_value.label' })}
      descriptionFontSize="base"
      value={intl.formatNumber(
        (sumBy('totalDigitalValue', poolsTicketTypes) || 0) / 100,
        CURRENCY(sumBy('totalDigitalValue', poolsTicketTypes) || 0, event.costCurrency)
      )}
    />
  )
}

export default memo(TicketPoolSales)
