/**
 * @generated SignedSource<<dbc09c7915065714e8f24868dc184695>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EnumTypeOfOrganizer = "AGENT" | "ARTIST" | "ARTIST_MANAGEMENT" | "BRAND" | "LABEL" | "PROMOTER" | "VENUE" | "%future added value";
export type StripeRestrictionStatus = "ANY_PROBLEM" | "CHARGES_ONLY" | "FULLY_RESTRICTED" | "LONG_UNVERIFIED" | "OK" | "PAYOUTS_ONLY" | "SOON_RESTRICTED" | "%future added value";
export type PromoterWhereInput = {
  _or?: ReadonlyArray<PromoterWhereInput | null> | null;
  accountManagerId?: OperatorsIdEqInput | null;
  chargesEnabled?: OperatorsBooleanEqInput | null;
  countryCode?: OperatorsString | null;
  destinationFallback?: OperatorsBooleanEqInput | null;
  dicePartner?: OperatorsBooleanEqInput | null;
  id?: OperatorsIdEqInput | null;
  name?: OperatorsString | null;
  payoutsEnabled?: OperatorsBooleanEqInput | null;
  stripeRestrictionStatus?: OperatorsEnumStripeRestrictionStatus | null;
  typeOfOrganizer?: OperatorsEnumTypeOfOrganizer | null;
  verificationDueBy?: OperatorsDateInput | null;
};
export type OperatorsIdEqInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
};
export type OperatorsBooleanEqInput = {
  eq?: boolean | null;
  ne?: boolean | null;
  neOrNull?: boolean | null;
};
export type OperatorsString = {
  eq?: string | null;
  iLike?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notLike?: string | null;
  null?: boolean | null;
};
export type OperatorsEnumStripeRestrictionStatus = {
  eq?: StripeRestrictionStatus | null;
  in?: ReadonlyArray<StripeRestrictionStatus | null> | null;
};
export type OperatorsEnumTypeOfOrganizer = {
  eq?: EnumTypeOfOrganizer | null;
  in?: ReadonlyArray<EnumTypeOfOrganizer | null> | null;
};
export type OperatorsDateInput = {
  between?: ReadonlyArray<string | null> | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  notBetween?: ReadonlyArray<string | null> | null;
  null?: boolean | null;
};
export type useAccountOptionsAppliedQuery$variables = {
  where?: PromoterWhereInput | null;
};
export type useAccountOptionsAppliedQuery$data = {
  readonly viewer: {
    readonly promoters: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly label: string | null;
          readonly value: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useAccountOptionsAppliedQuery = {
  response: useAccountOptionsAppliedQuery$data;
  variables: useAccountOptionsAppliedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAccountOptionsAppliedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PromotersConnection",
            "kind": "LinkedField",
            "name": "promoters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PromotersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Promoter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAccountOptionsAppliedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PromotersConnection",
            "kind": "LinkedField",
            "name": "promoters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PromotersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Promoter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5392e506e75da1899612d44fb1869534",
    "id": null,
    "metadata": {},
    "name": "useAccountOptionsAppliedQuery",
    "operationKind": "query",
    "text": "query useAccountOptionsAppliedQuery(\n  $where: PromoterWhereInput\n) {\n  viewer {\n    promoters(where: $where, first: 1) {\n      edges {\n        node {\n          value: id\n          label: name\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0976868b7607e899e57c7d1ae4bb0ef";

export default node;
