/* eslint-disable array-callback-return */

const errorsToTouched = (errors: any) => {
  if (!errors) return null

  const touched: any = {}

  try {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        errors[key].map((val: any) => {
          if (!touched[key]) touched[key] = []
          touched[key].push(errorsToTouched(val))
        })
      } else {
        touched[key] = true
      }
    })
  } catch (e) {
    console.error(e)
    // Don't crash - it's not important function
  }

  return touched
}

export default errorsToTouched
