import React, { FC, memo, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'

import PermissionCheck from '../../../components/PermissionCheck'
import { authContext } from '../../../context/auth'
import { IFactory, preload, SIDEBAR_ROUTER } from '../services/sidebarNav'
import { SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'
import { featureFlagsContext } from '../../../context/featureFlags'

const Marketing: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user, hasPermission } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)

  const toBundlesList = useCallback(() => navigate(SIDEBAR_ROUTER.bundles[0] as string), [navigate])
  const toMarketeersList = useCallback(() => navigate(SIDEBAR_ROUTER.marketeers[0] as string), [navigate])

  const menuItemsPresent =
    user.diceStaff ||
    hasPermission('read:bundle') ||
    hasPermission('read:linkout') ||
    hasPermission('read:featured_area')

  return menuItemsPresent ? (
    <>
      {hasFeatureFlag('eventsCollection') && (
        <PermissionCheck permission="read:bundle">
          <SidebarMenuItem
            active={pathname && pathname.startsWith(SIDEBAR_ROUTER.marketing.bundles[0] as string)}
            to={SIDEBAR_ROUTER.marketing.bundles[0]}
            data-id="sidebarBundles"
            onMouseEnter={preload(SIDEBAR_ROUTER.marketing.bundles[1] as IFactory)}
            onReload={toBundlesList}
          >
            <SidebarMenuIcon icon="bundle" />
            <span>{intl.formatMessage({ id: 'sidebar.bundles' })}</span>
          </SidebarMenuItem>
        </PermissionCheck>
      )}

      {hasPermission('read:linkout') && (
        <SidebarMenuItem
          active={pathname && pathname.startsWith(SIDEBAR_ROUTER.marketing.linkouts[0] as string)}
          to={SIDEBAR_ROUTER.marketing.linkouts[0]}
          data-id="sidebarLinkouts"
          onMouseEnter={preload(SIDEBAR_ROUTER.marketing.linkouts[1] as IFactory)}
        >
          <SidebarMenuIcon icon="link" />
          <span>{intl.formatMessage({ id: 'sidebar.website_widget' })}</span>
        </SidebarMenuItem>
      )}
      {user.diceStaff && (
        <SidebarMenuItem
          dice
          active={pathname && pathname.startsWith(SIDEBAR_ROUTER.marketing.marketeers[0] as string)}
          to={SIDEBAR_ROUTER.marketing.marketeers[0]}
          data-id="sidebarMarketeers"
          onMouseEnter={preload(SIDEBAR_ROUTER.marketing.marketeers[1] as IFactory)}
          onReload={toMarketeersList}
        >
          <SidebarMenuIcon icon="filter" />

          <span>{intl.formatMessage({ id: 'sidebar.marketeers' })}</span>
        </SidebarMenuItem>
      )}
      {hasPermission('read:featured_area') && (
        <SidebarMenuItem
          active={pathname === SIDEBAR_ROUTER.marketing.featured[0]}
          to={SIDEBAR_ROUTER.marketing.featured[0]}
          data-id="sidebarFeatured"
          onMouseEnter={preload(SIDEBAR_ROUTER.marketing.featured[1] as IFactory)}
        >
          <SidebarMenuIcon icon="featured" />

          <span>{intl.formatMessage({ id: 'sidebar.featured' })}</span>
        </SidebarMenuItem>
      )}
      {user.diceStaff && (
        <SidebarMenuItem
          dice
          active={pathname === SIDEBAR_ROUTER.marketing.qr[0]}
          to={SIDEBAR_ROUTER.marketing.qr[0]}
          data-id="sidebarQR"
          onMouseEnter={preload(SIDEBAR_ROUTER.marketing.qr[1] as IFactory)}
        >
          <SidebarMenuIcon icon="qr" />

          <span>{intl.formatMessage({ id: 'sidebar.qr' })}</span>
        </SidebarMenuItem>
      )}
    </>
  ) : null
}

export default memo(Marketing)
