import { find, map } from 'lodash/fp'
import { ILocale } from '../intl'

// Those countries support live events
export const OPERATING_COUNTRIES: Array<{
  value: string
  label: string
  i18n: string
  locale?: ILocale
  alpha2: string
}> = [
  { alpha2: 'GB', value: 'United Kingdom', label: 'United Kingdom', i18n: 'countries.united_kingdom', locale: 'en-GB' },
  { alpha2: 'FR', value: 'France', label: 'France', i18n: 'countries.france', locale: 'fr' },
  { alpha2: 'IT', value: 'Italy', label: 'Italy', i18n: 'countries.italy', locale: 'it' },
  { alpha2: 'ES', value: 'Spain', label: 'Spain', i18n: 'countries.spain', locale: 'es' },
  { alpha2: 'US', value: 'United States', label: 'United States', i18n: 'countries.united_states', locale: 'en-US' },
  { alpha2: 'AU', value: 'Australia', label: 'Australia', i18n: 'countries.australia' },
  { alpha2: 'IE', value: 'Ireland', label: 'Ireland', i18n: 'countries.ireland', locale: 'en-GB' },
  { alpha2: 'PT', value: 'Portugal', label: 'Portugal', i18n: 'countries.portugal', locale: 'pt' },
  { alpha2: 'DE', value: 'Germany', label: 'Germany', i18n: 'countries.germany', locale: 'de' },
  { alpha2: 'IN', value: 'India', label: 'India', i18n: 'countries.india', locale: 'en-IN' },
  { alpha2: 'CA', value: 'Canada', label: 'Canada', i18n: 'countries.canada', locale: 'en-CA' },
]

export const getCountryLocale = (alpha2: string) => find(['alpha2', alpha2], OPERATING_COUNTRIES)?.locale || 'en-GB'

export const OPERATING_COUNTRY_CODES_SET = new Set(map('alpha2', OPERATING_COUNTRIES))
