import graphql from 'babel-plugin-relay/macro'
import { useMemo } from 'react'
import { useFragment } from 'react-relay'
import { usePermissions_viewer$key } from '../../../__generated__/usePermissions_viewer.graphql'
import { toPermissions } from '../util/convert'
import buildHasPermission from '../util/permissions'

function usePermissions(viewerKey: usePermissions_viewer$key) {
  const viewer = useFragment(
    graphql`
      fragment usePermissions_viewer on Viewer {
        permissions
      }
    `,
    viewerKey
  )

  const hasPermission = useMemo(() => {
    const permissionSet = toPermissions(viewer)
    return buildHasPermission(permissionSet)
  }, [viewer])

  return hasPermission
}

export default usePermissions
