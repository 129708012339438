/**
 * @generated SignedSource<<784382b31cb035191328693b20e6d9f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Priority_viewer$data = {
  readonly priority: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly eventIdLive: string | null;
        readonly id: string;
        readonly state: EventState | null;
        readonly " $fragmentSpreads": FragmentRefs<"DashboardEventCardInfo_event" | "DashboardEventSalesProgress_event">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "Priority_viewer";
};
export type Priority_viewer$key = {
  readonly " $data"?: Priority_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Priority_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "priority"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./PriorityQuery.graphql')
    }
  },
  "name": "Priority_viewer",
  "selections": [
    {
      "alias": "priority",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "dateASC"
          ]
        },
        {
          "kind": "Literal",
          "name": "scopes",
          "value": {
            "lifeCycleState": [
              "LIVE",
              "DRAFT"
            ]
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "priority": {
              "eq": true
            }
          }
        }
      ],
      "concreteType": "EventConnection",
      "kind": "LinkedField",
      "name": "__Priority_priority_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Event",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eventIdLive",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DashboardEventCardInfo_event"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DashboardEventSalesProgress_event"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__Priority_priority_connection(orderBy:[\"dateASC\"],scopes:{\"lifeCycleState\":[\"LIVE\",\"DRAFT\"]},where:{\"priority\":{\"eq\":true}})"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "4a17bc5c5b159ce6e12297ba255c4a64";

export default node;
