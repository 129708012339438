import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { compose, find, get } from 'lodash/fp'

import { DATETIME_FORMATS } from '../../utils/formatters/datetime'
import { color } from '../../utils/variables'

import FormField, { IStyledFormField } from '../FormField'

const Option = styled.div`
  display: flex;
  align-items: center;
  & > * {
    word-break: break-word;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`
const EventImage = styled.div`
  flex: 40px 0 0;
  width: 40px;
  height: 40px;
  background: ${color.lightgrey};
  margin-right: 8px;

  img {
    height: 100%;
  }
`
const EventName = styled.span`
  display: inline-block;
  margin: 0 16px 0 0;
`
const EventDate = styled.span`
  display: inline-block;
  margin-left: auto;
  color: ${color.darkgrey};
  text-align: right;
`
const StyledEventSelector = styled(FormField)`
  .react-select__value-container {
    ${EventImage} {
      display: none;
    }
  }
` as IStyledFormField

const EventSelector = (props: any) => {
  const intl = useIntl()

  const customOptionLabel = useCallback(
    ({ eventImages, label, date, timezoneName }: any) => {
      const imgUrl = compose(
        (url) => (url ? url + '&h=80&w=80&auto=compress' : undefined),
        get('cdnUrl'),
        find(['type', 'square'])
      )(eventImages)
      return (
        <Option>
          <EventImage>{imgUrl && <img src={imgUrl} alt={label} />}</EventImage>
          <EventName>{label}</EventName>
          {date && (
            <EventDate>
              {intl.formatDate(date as Date, {
                ...DATETIME_FORMATS.MEDIUM,
                timeZone: timezoneName || undefined,
              })}
            </EventDate>
          )}
        </Option>
      )
    },
    [intl]
  )

  return <StyledEventSelector optionLabel={customOptionLabel} {...props} />
}

export default EventSelector
