import { color } from '../utils/variables'

export const EVENT_STATES: {
  [key: string]: { key: string; defaultValue: string; i18n: string; color: keyof typeof color }
} = {
  review: {
    key: 'review',
    defaultValue: 'In review',
    i18n: 'event_state.in_review',
    color: 'grey',
  },
  submitted: {
    key: 'submitted',
    defaultValue: 'Submitted for review',
    i18n: 'event_state.submitted',
    color: 'grey',
  },
  approved: {
    key: 'approved',
    defaultValue: 'Approved',
    i18n: 'event_state.approved',
    color: 'grey',
  },
}
