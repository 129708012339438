import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { compose, isEqual, isNil, omit, omitBy } from 'lodash/fp'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { useDebounce } from '../../../../utils/hooks/useDebounce'
import { mediaQuery } from '../../../../utils/variables'

import Svg from '../../../../components/Svg'
import IconButton from '../../../../components/IconButton'
import { OnDesktop, OnMobile } from '../../../../components/Breakpoints'

import EventFilterModal from './EventFilterModal'
import { initFilters } from './EventFilterConfig'
import StatusFilter from './components/QuickFilters/StatusFilter'
import DateFilter from './components/QuickFilters/DateFilter'
import MobileOrdering from './components/QuickFilters/MobileOrdering'
import { trackingContext } from '../../../../context/tracking'
import {
  ClearFilters,
  ClearFiltersText,
  FilterAdditional,
  FilterAdditionalItem,
  FilterSearch,
  FilterSearchInput,
  SearchIcon,
} from '../../../../components/FilterStyles'
import EventListTypeContext from '../../util/eventListTypeContext'
import TierFilter from './components/QuickFilters/TierFilter'
import { authContext } from '../../../../context/auth'
import { convertViewerIdToUserId } from '../../../../utils/convertViewerIdToUserId'

const IGNORED_FIELDS = ['orderBy']

const EventListFilterBar: FC = () => {
  const intl = useIntl()
  const listType = useContext(EventListTypeContext)
  const navigate = useNavigate()
  const { user } = useContext(authContext)

  const [advancedFilters, setAdvancedFilters] = useState(false)
  const { values, setValues, handleSubmit, setFieldValue } = useFormikContext<{
    search: string
  }>()
  const [searchStr, setSearchStr] = useState(values.search)
  const debounceSearch = useDebounce(searchStr, 500)
  const { trackEvent } = useContext(trackingContext)

  useEffect(() => {
    if (debounceSearch === searchStr) {
      setFieldValue('search', searchStr)
      handleSubmit()
    }
  }, [debounceSearch, searchStr, handleSubmit, setFieldValue])

  const doSearch = useCallback((e: any) => setSearchStr(e.target.value), [setSearchStr])
  const clearSearch = useCallback(() => setSearchStr(''), [setSearchStr])

  const showAdvancedFilters = useCallback(() => setAdvancedFilters(true), [setAdvancedFilters])
  const closeAdvancedFilters = useCallback(() => setAdvancedFilters(false), [setAdvancedFilters])

  const trackData = useMemo(() => {
    return { event_category: listType }
  }, [listType])

  const clearFilters = useCallback(() => {
    trackEvent('events_filter_cleared', trackData)
    navigate({ search: '' })
    setSearchStr('')
    setValues(initFilters(listType))
    handleSubmit()
  }, [trackEvent, trackData, navigate, setValues, listType, handleSubmit])

  const allowClearFilters = useMemo(() => {
    const ignoredFields = [...IGNORED_FIELDS]

    if (listType === 'submission') {
      // Ignore AssigneeFilterTabs values
      const assigneeId = (values as any).assigneeId
      const userId = convertViewerIdToUserId(user.id)
      if (assigneeId === null || assigneeId === userId) {
        ignoredFields.push('assigneeId')
      }
    }

    return !isEqual(
      compose([omitBy(isNil), omit(ignoredFields)])(values),
      compose([omitBy(isNil), omit(ignoredFields)])(initFilters(listType))
    )
  }, [listType, user.id, values])

  return (
    <EventListFilterBarWrapper>
      <FilterSearch>
        <SearchIcon icon="search" />
        <FilterSearchInput
          name="search"
          placeholder={intl.formatMessage({ id: 'search' })}
          onChange={doSearch}
          value={searchStr}
          autoComplete="off"
        />
        {values.search && <IconButton icon="close-view" onClick={clearSearch} />}
      </FilterSearch>
      <FilterAdditional>
        <OnDesktop>
          <>
            {listType === 'submission' && <TierFilter />}
            {['live', 'draft', 'past'].includes(listType) && <StatusFilter />}
            {['live', 'draft', 'past'].includes(listType) && <DateFilter />}
            <FilterAdditionalItem onClick={showAdvancedFilters}>
              <Svg icon="filter" />
              <strong>{intl.formatMessage({ id: 'filters' })}</strong>
            </FilterAdditionalItem>
          </>
        </OnDesktop>
        <OnMobile>
          <>
            <FilterAdditionalItem onClick={showAdvancedFilters}>
              <Svg icon="filter" />
            </FilterAdditionalItem>
            <MobileOrdering />
          </>
        </OnMobile>
      </FilterAdditional>
      {advancedFilters && <EventFilterModal onClose={closeAdvancedFilters} />}
      {allowClearFilters && (
        <ClearFilters onClick={clearFilters}>
          <ClearFiltersText>{intl.formatMessage({ id: 'actions.clear_filters' })}</ClearFiltersText>
          <Svg icon="close-view" />
        </ClearFilters>
      )}
    </EventListFilterBarWrapper>
  )
}

export default EventListFilterBar

// Styles
const EventListFilterBarWrapper = styled.div`
  position: relative;
  display: flex;
  min-width: 100%;
  flex-grow: 1;
  margin: 16px -32px -16px;
  border-top: 2px solid #000;
  height: 58px;
  ${mediaQuery.lessThan('tablet')`
    margin: 12px -58px -16px -58px;
  `}
`
