import React, { FC, memo, ReactNode } from 'react'
import styled from 'styled-components/macro'
import DiceBadge from './DiceBadge'
import { FormRow } from './Form'

const NoMarginH2 = styled.h2`
  margin-bottom: 8px;
  ${DiceBadge} {
    margin: 0 0 -2px 4px;
  }
`

interface IProps {
  className?: string
  header: ReactNode
  subheader?: ReactNode
  dice?: boolean
}

const FormHeader: FC<React.PropsWithChildren<IProps>> = ({ className, header, subheader, dice }) => {
  return (
    <FormRow columnOnMobile className={className}>
      <div>
        {header && (
          <NoMarginH2>
            {header}
            {dice && <DiceBadge />}
          </NoMarginH2>
        )}
        {subheader && <div>{subheader}</div>}
      </div>
    </FormRow>
  )
}

export default memo(FormHeader)
