/**
 * @generated SignedSource<<598d9ded7fddea9ff0184257a8e1f1b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatePermissionProfilePageQuery$variables = Record<PropertyKey, never>;
export type CreatePermissionProfilePageQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"CreatePermissionProfile_viewer">;
  } | null;
};
export type CreatePermissionProfilePageQuery = {
  response: CreatePermissionProfilePageQuery$data;
  variables: CreatePermissionProfilePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePermissionProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreatePermissionProfile_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CreatePermissionProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PermissionProfileStructure",
            "kind": "LinkedField",
            "name": "permissionProfileStructure",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Subject",
                "kind": "LinkedField",
                "name": "subjects",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Action",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "category",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0ce2948dbc91eee1d5b4c8f05d75f3c",
    "id": null,
    "metadata": {},
    "name": "CreatePermissionProfilePageQuery",
    "operationKind": "query",
    "text": "query CreatePermissionProfilePageQuery {\n  viewer {\n    ...CreatePermissionProfile_viewer\n    id\n  }\n}\n\nfragment CreatePermissionProfile_viewer on Viewer {\n  ...PermissionConfig_viewer\n}\n\nfragment PermissionConfig_viewer on Viewer {\n  permissionProfileStructure {\n    subjects {\n      name\n      actions {\n        name\n        category\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c1dfea84364555c9872409a0a7ac2ce7";

export default node;
