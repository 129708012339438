import React, { useState, useContext, useCallback, useMemo, FC } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { commitMutation, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { notificationContext } from '../../../../context/notification'

import Checkbox from '../../../../components/Checkbox'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'
import { Paragraph } from '../../../../components/Text'
import unwrapId from '../../../../utils/unwrapId'
import { trackingContext } from '../../../../context/tracking'

interface IProps {
  eventId: string
  eventIdLive?: string | null
  onClose: () => void
}

export const MarkBackAllocationModal: FC<IProps> = ({ eventId, eventIdLive, onClose }) => {
  const intl = useIntl()
  const [userAgreed, setUserAgreed] = useState(false)
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)
  const environment = useRelayEnvironment()

  const trackData = useMemo(
    () => ({
      event_id: unwrapId(eventId),
      event_id_live: eventIdLive,
      ticket_type_id: null,
    }),
    [eventIdLive, eventId]
  )

  const handleSubmit = useCallback(() => {
    if (!userAgreed || !eventId) return

    trackEvent('allocation_mark_back_confirmed', trackData)

    return commitMutation(environment, {
      mutation: graphql`
        mutation MarkBackAllocationModalMutation($input: MarkBackAllocationsInput!) {
          markBackAllocations(input: $input) {
            event {
              id
              allocation
            }
          }
        }
      `,
      variables: {
        input: {
          clientMutationId: eventId,
          id: eventId,
        },
      },
      onCompleted: () => {
        addNotification('success', 'Mark back allocations completed')
        onClose()
      },
      onError: () => {
        addNotification('error', 'Something went wrong')
        onClose()
      },
    })
  }, [addNotification, environment, eventId, onClose, trackData, trackEvent, userAgreed])

  const toggleAgree = useCallback(() => setUserAgreed((ua) => !ua), [setUserAgreed])

  return (
    <Modal
      trackId="allocation_mark_back"
      trackData={trackData}
      modalTitle={intl.formatMessage({ id: 'mark_back_allocations' })}
      closeButton
      onClose={onClose}
    >
      <ModalBody>
        <Paragraph>{intl.formatMessage({ id: 'mark_back_allocations.note' })}</Paragraph>
        <br />
        <Checkbox
          label={intl.formatMessage({ id: 'mark_back_allocations.checkbox' })}
          checked={userAgreed}
          onChange={toggleAgree}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl disabled={!userAgreed} onClick={handleSubmit}>
          {intl.formatMessage({ id: 'actions.confirm' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

MarkBackAllocationModal.propTypes = {
  eventId: PropTypes.string.isRequired,
  eventIdLive: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

MarkBackAllocationModal.defaultProps = {
  onClose: () => {},
  eventIdLive: null,
}

export default MarkBackAllocationModal
