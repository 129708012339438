import React, { FC, useCallback, useState, useMemo, useEffect, memo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import { getOr, find } from 'lodash/fp'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { useIntl } from 'react-intl'
import FormField from '../../../components/FormField'
// eslint-disable-next-line max-len
import { EventTicketTypePriceTypeSelectQuery } from '../../../__generated__/EventTicketTypePriceTypeSelectQuery.graphql'
import { isNew } from '../../../utils/entityStatus'

const EventTicketTypePriceTypeSelect: FC<
  React.PropsWithChildren<{
    name: string
    disabled?: boolean
    values: { id: string; attractivePriceType: string | null }
    setFieldValue: (name: string, val: any) => void
    handleBlur: (e: Event) => void
    touched: FormikTouched<FormikValues>
    errors: FormikErrors<FormikValues>
    required?: boolean
    label?: string | null
    validateForm: () => void
  }>
> = ({ name, label, values, setFieldValue, handleBlur, touched, errors, disabled, validateForm }) => {
  const intl = useIntl()
  const environment = useRelayEnvironment()

  const onChange = useCallback(
    (id: any) => {
      setFieldValue(name, id)
      setTimeout(() => validateForm(), 0)
    },
    [name, setFieldValue, validateForm]
  )

  const [attractivePriceTypeOptions, setAttractivePriceTypeOptions] = useState([])
  useEffect(() => {
    let stillActive = true

    const query = graphql`
      query EventTicketTypePriceTypeSelectQuery {
        viewer {
          externalEntities(entityType: "price_type", integrationType: "attractive") {
            label: name
            value
          }
        }
      }
    `

    fetchQuery_DEPRECATED<EventTicketTypePriceTypeSelectQuery>(environment, query, {}).then(
      (data: EventTicketTypePriceTypeSelectQuery['response']) => {
        if (stillActive) {
          const arr = getOr([], 'viewer.externalEntities', data)
          setAttractivePriceTypeOptions(arr)
        }
      }
    )

    return () => {
      stillActive = false
    }
  }, [environment])

  const attractivePriceTypeOption = useMemo(
    () => find(['value', values.attractivePriceType], attractivePriceTypeOptions),
    [attractivePriceTypeOptions, values.attractivePriceType]
  )

  return (
    <FormField
      name={name}
      label={label === undefined ? intl.formatMessage({ id: 'new_event.tickets.price_type.label' }) : label}
      control="select"
      value={attractivePriceTypeOption}
      options={attractivePriceTypeOptions}
      onChange={onChange}
      onBlur={handleBlur}
      error={touched.attractivePriceType || !isNew(values) ? errors.attractivePriceType : null}
      disabled={disabled}
      required
    />
  )
}

export default memo(EventTicketTypePriceTypeSelect)
