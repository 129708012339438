import React, { FC, memo, useCallback, useMemo } from 'react'
import { compact, compose, isEmpty, map, take, times } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { usePaginationFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { List, ListTitle } from '../../DashboardEvents'
import { DashboardEventCardList, DashboardEventCard, DashboardEventCardEmpty } from '../DashboardEvent/DashboardEvent'
import DashboardEventCardInfo from '../DashboardEvent/DashboardEventCardInfo'

import { Priority_viewer$data, Priority_viewer$key } from '../../../../__generated__/Priority_viewer.graphql'
import { color, font } from '../../../../utils/variables'
import Button from '../../../../components/Button'
import { Loader } from '../../../../components/Loader'
import DiceBadge from '../../../../components/DiceBadge'
import DashboardEventSalesProgress from '../DashboardEvent/DashboardEventSalesProgress'

const ToBottom = styled.div`
  margin-top: auto;
`

const LoadMoreCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  min-height: 256px;
  border: 2px dashed ${color.lightgrey};
  padding: 8px;
  border-radius: 8px;
`

const LoadMoreOptions = styled.div`
  display: flex;
  flex-direction: column;
`

const Or = styled.div`
  margin: 8px;
  font-size: ${font.size.sm}px;
  color: ${color.greyer};
`

type IEvent = NonNullable<
  NonNullable<NonNullable<NonNullable<Priority_viewer$data['priority']>['edges']>[number]>['node']
>

interface IProps {
  viewer: Priority_viewer$key
}

const Priority: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const intl = useIntl()

  const {
    data: viewer,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    graphql`
      fragment Priority_viewer on Viewer
      @refetchable(queryName: "PriorityQuery")
      @argumentDefinitions(count: { type: "Int", defaultValue: 10 }, cursor: { type: "String", defaultValue: null }) {
        priority: events(
          first: $count
          after: $cursor
          scopes: { lifeCycleState: [LIVE, DRAFT] }
          where: { priority: { eq: true } }
          orderBy: [dateASC]
        ) @connection(key: "Priority_priority") {
          edges {
            node {
              id
              state
              eventIdLive
              ...DashboardEventCardInfo_event
              ...DashboardEventSalesProgress_event
            }
          }
        }
      }
    `,
    viewerKey
  )

  const validEvents: IEvent[] = useMemo(
    () =>
      compose([(arr: IEvent[]) => take(arr.length - (hasNext ? 1 : 0), arr), compact, map('node')])(
        viewer.priority?.edges || []
      ),
    [hasNext, viewer.priority?.edges]
  )

  const fetchMore = useCallback(() => {
    loadNext(10)
  }, [loadNext])

  if (isEmpty(validEvents)) {
    return null
  }

  return (
    <List>
      <ListTitle>
        <DiceBadge />
        {intl.formatMessage({ id: 'dashboard.event_list.priority' })}
      </ListTitle>
      <DashboardEventCardList>
        {validEvents.map((event) => (
          <DashboardEventCard key={event.id}>
            <DashboardEventCardInfo event={event} />
            {!!event.eventIdLive && (
              <ToBottom>
                <DashboardEventSalesProgress event={event} />
              </ToBottom>
            )}
          </DashboardEventCard>
        ))}
        {hasNext && (
          <DashboardEventCard>
            <LoadMoreCard>
              {isLoadingNext ? (
                <Loader />
              ) : (
                <LoadMoreOptions>
                  <Button preset="secondary" onClick={fetchMore}>
                    {intl.formatMessage({ id: 'actions.load_more' })}
                  </Button>
                  <Or>
                    <span>{intl.formatMessage({ id: 'or' })}</span>
                  </Or>
                  <Button preset="link" to="/events/drafts?priority=true&orderBy=dateASC">
                    {intl.formatMessage({ id: 'actions.view_all' })} {intl.formatMessage({ id: 'sidebar.drafts' })}
                  </Button>
                  <Or>
                    <span>{intl.formatMessage({ id: 'or' })}</span>
                  </Or>
                  <Button preset="link" to="/events/live?priority=true&orderBy=dateASC">
                    {intl.formatMessage({ id: 'actions.view_all' })} {intl.formatMessage({ id: 'sidebar.live' })}
                  </Button>
                </LoadMoreOptions>
              )}
            </LoadMoreCard>
          </DashboardEventCard>
        )}
        {times(
          (idx) => (
            <DashboardEventCardEmpty key={`event-onSale-${idx}`} />
          ),
          hasNext ? 3 : 4
        )}
      </DashboardEventCardList>
    </List>
  )
}

export default memo(Priority)
