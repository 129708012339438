import { Chart } from 'chart.js'
import { findIndex, map } from 'lodash/fp'
import { useCallback, useEffect, useRef, useState } from 'react'

interface IDataConfig {
  datasets: Array<{
    id: string
  }>
}

function useChartLegend(dataConfig: IDataConfig) {
  const chartRef = useRef<Chart<'bar' | 'line'> | null>(null)

  const [checkboxes, setCheckboxes] = useState(() => map((ds) => ds.id, dataConfig.datasets))
  const syncCheckboxes = useCallback(() => {
    const chart = chartRef.current
    if (chart) {
      const visibleDs = chart.getSortedVisibleDatasetMetas()
      const vids = map((d: any) => d._dataset.id, visibleDs)
      setCheckboxes(vids)
    }
  }, [])

  const toggleDataset = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      const chart = chartRef.current
      if (chart) {
        const dss = chart.data.datasets
        const dsIdx = findIndex(['id', id], dss)

        const visible = chart.isDatasetVisible(dsIdx)
        chart.setDatasetVisibility(dsIdx, !visible)
        chart.update()
      }

      syncCheckboxes()
    },
    [syncCheckboxes]
  )

  useEffect(() => {
    if (!dataConfig) return
    syncCheckboxes()
  }, [dataConfig, syncCheckboxes])

  return { chartRef, checkboxes, toggleDataset }
}

export default useChartLegend
