/**
 * @generated SignedSource<<2ef75f7e8150bb67d584fd1bb47cc0de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CountryCode = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AN" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type EventTimingField = "ANNOUNCE_DATE" | "DATE" | "END_DATE" | "LINE_UP_DOORS_OPEN" | "OFF_SALE_DATE" | "ON_SALE_DATE" | "ON_SALE_REMINDER_TIME" | "RETURN_DEADLINE" | "TICKET_TYPE_ANNOUNCE_DATE" | "TICKET_TYPE_OFF_SALE_DATE" | "TICKET_TYPE_ON_SALE_DATE" | "TRANSFER_DEADLINE" | "%future added value";
export type EventTimingType = "DEFAULT" | "PROMOTER" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type PrintedTicketFormat = "BOCA_55X2" | "BOCA_6X3_NO_LOGO" | "NO_PRINTER" | "STAR_RECEIPT" | "STAR_RECEIPT_ETICKET" | "%future added value";
export type RestrictionKind = "ALLOW" | "DENY" | "%future added value";
export type SalesforceContractStatus = "ACTIVATED" | "CANCELLED" | "EXPIRED" | "SIGNED" | "SUPERSEDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventSubmission_viewer$data = {
  readonly dicePartner: boolean;
  readonly diceStaff: boolean;
  readonly hierarchicalTags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly kind: string | null;
        readonly label: string | null;
        readonly parent: {
          readonly name: string | null;
        } | null;
        readonly value: string;
      } | null;
    } | null> | null;
  } | null;
  readonly singlePromoterCandidate: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly addressCountry: string | null;
        readonly allowSkipReview: boolean | null;
        readonly associatedMarketeers: ReadonlyArray<{
          readonly label: string | null;
          readonly value: string;
        } | null> | null;
        readonly autoRescheduledEventRefunds: {
          readonly active: boolean | null;
          readonly cutoffDays: number | null;
        } | null;
        readonly billingNotes: string | null;
        readonly coolingOffPeriod: boolean | null;
        readonly coolingOffPeriodHours: number | null;
        readonly countryCode: string | null;
        readonly defaultEventTimings: ReadonlyArray<{
          readonly offset: number | null;
          readonly sourceField: EventTimingField | null;
          readonly targetField: EventTimingField | null;
          readonly type: EventTimingType | null;
        } | null> | null;
        readonly disableUsTax: boolean | null;
        readonly displayName: string | null;
        readonly eventDefaults: {
          readonly addArtistsToEvent: boolean | null;
          readonly disableAttractiveIntegration: boolean | null;
          readonly disableDayOfEventComms: boolean | null;
          readonly hideFromDiscovery: boolean | null;
          readonly manualValidationEnabled: boolean | null;
          readonly printedTicketFormat: PrintedTicketFormat | null;
          readonly requiresBoxOfficeTicketNomination: boolean | null;
          readonly restrictCountries: ReadonlyArray<CountryCode | null> | null;
          readonly restrictCountriesKind: RestrictionKind | null;
          readonly taxFree: boolean | null;
          readonly ticketTransfer: boolean | null;
          readonly waitingList: boolean | null;
        } | null;
        readonly fanSupportNotes: {
          readonly body: string | null;
        } | null;
        readonly holdPayouts: boolean | null;
        readonly isTest: boolean | null;
        readonly label: string | null;
        readonly labels: ReadonlyArray<{
          readonly label: string;
          readonly value: string;
        } | null> | null;
        readonly licenseNumber: string;
        readonly platformAccountCode: PlatformAccountCode | null;
        readonly resoldEnabled: boolean | null;
        readonly salesforceFields: {
          readonly defaultContract: {
            readonly id: string;
            readonly name: string | null;
            readonly num: string | null;
            readonly opportunityName: string | null;
            readonly sfAccountId: string | null;
            readonly sfId: string | null;
            readonly startDate: string | null;
            readonly status: SalesforceContractStatus | null;
          } | null;
        } | null;
        readonly sendReceiptViaSms: boolean | null;
        readonly showPriceSuggestions: boolean;
        readonly stripeAccountId: string | null;
        readonly stripeFallbackAccountId: string | null;
        readonly stripeFallbackPlatformCode: PlatformAccountCode | null;
        readonly tags: ReadonlyArray<{
          readonly label: string | null;
          readonly value: string;
        } | null> | null;
        readonly value: string;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"Basics_viewer" | "Extras_viewer" | "Merch_viewer" | "Settings_viewer" | "Tickets_viewer" | "usePromoters_viewer">;
  readonly " $fragmentType": "EventSubmission_viewer";
};
export type EventSubmission_viewer$key = {
  readonly " $data"?: EventSubmission_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventSubmission_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventSubmission_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStaff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicePartner",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePromoters_viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "kind": "type"
          }
        }
      ],
      "concreteType": "HierarchicalTagsConnection",
      "kind": "LinkedField",
      "name": "hierarchicalTags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HierarchicalTagsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HierarchicalTag",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HierarchicalTag",
                  "kind": "LinkedField",
                  "name": "parent",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "hierarchicalTags(first:100,where:{\"kind\":\"type\"})"
    },
    {
      "alias": "singlePromoterCandidate",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "PromotersConnection",
      "kind": "LinkedField",
      "name": "promoters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PromotersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Promoter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isTest",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripeAccountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripeFallbackAccountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripeFallbackPlatformCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "platformAccountCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showPriceSuggestions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "addressCountry",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "countryCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "licenseNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowSkipReview",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "resoldEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disableUsTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "holdPayouts",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tag",
                  "kind": "LinkedField",
                  "name": "tags",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "labels",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billingNotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FanSupportNotes",
                  "kind": "LinkedField",
                  "name": "fanSupportNotes",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "body",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coolingOffPeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coolingOffPeriodHours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sendReceiptViaSms",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Marketeer",
                  "kind": "LinkedField",
                  "name": "associatedMarketeers",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoRescheduledEventRefunds",
                  "kind": "LinkedField",
                  "name": "autoRescheduledEventRefunds",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "active",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cutoffDays",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EventTiming",
                  "kind": "LinkedField",
                  "name": "defaultEventTimings",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "offset",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sourceField",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "targetField",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EventDefaults",
                  "kind": "LinkedField",
                  "name": "eventDefaults",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "addArtistsToEvent",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "disableAttractiveIntegration",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "disableDayOfEventComms",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hideFromDiscovery",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "manualValidationEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "printedTicketFormat",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "requiresBoxOfficeTicketNomination",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "restrictCountries",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "restrictCountriesKind",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taxFree",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ticketTransfer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "waitingList",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesforcePromoterFields",
                  "kind": "LinkedField",
                  "name": "salesforceFields",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesforceContract",
                      "kind": "LinkedField",
                      "name": "defaultContract",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "num",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "opportunityName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sfAccountId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sfId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "promoters(first:10)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Basics_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Tickets_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Extras_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Merch_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Settings_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "4b7f70182ff64220681776d48e7e91d2";

export default node;
