import React, { useState, useCallback, useContext, FC, memo, Suspense } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { getOr } from 'lodash/fp'
import styled from 'styled-components/macro'
import { API_URL } from '../../../../env'

import { authContext } from '../../../../context/auth'

import IconButton from '../../../../components/IconButton'
import { Menu, MenuItem } from '../../../../components/Menu'
import { Dropdown, DropdownTrigger, DropdownContent } from '../../../../components/Dropdown'
import { OnDesktopLarge, OnDesktop, OnMobile, LessThanDesktopLarge } from '../../../../components/Breakpoints'

import MarkBackAllocationModal from '../Modals/MarkBackAllocationModal'
import ChangeAllocationModal from '../Modals/ChangeAllocationModal'
import ChangePoolCapacityModal from '../Modals/ChangePoolCapacityModal'
import PermissionCheck from '../../../../components/PermissionCheck'
import { EventListItemControlWrapper } from './QuickActions'

import { QuickActionsLive_event$key } from '../../../../__generated__/QuickActionsLive_event.graphql'
import { trackingContext } from '../../../../context/tracking'
import { collectTrackingData } from '../../../../utils/tracking'
import { ConfirmationModal } from '../../../../components/ConfirmationModal'
import useCopyEvent from '../../hooks/useCopyEvent'
import DiceBadge from '../../../../components/DiceBadge'
import { Loader, LoaderContainer } from '../../../../components/Loader'
import ListTypeProtector from './ListTypeProtector'
import { dicefmEvent } from '../../../../utils/dicefm'
import useDeleteEvent from '../../hooks/useDeleteEvent'
import copyToClipboard from '../../../../utils/copyToClipboard'
import { notificationContext } from '../../../../context/notification'

const Placeholder = styled.div`
  width: 40px;
`

interface IProps {
  event: QuickActionsLive_event$key
  stripeIsBroken: boolean
}

const QuickActionsLive: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, stripeIsBroken }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { user } = useContext(authContext)
  const { trackEvent } = useContext(trackingContext)
  const { addNotification } = useContext(notificationContext)

  const event = useFragment(
    graphql`
      fragment QuickActionsLive_event on Event {
        id
        eventType
        eventIdLive
        state
        organicSocialLink
        announceDate
        date
        onSaleDate
        endDate
        tokens {
          doorlistExportToken
        }
        flags {
          seated
        }
        ticketPools {
          id
        }
        allowedActions {
          manageTickets
        }
      }
    `,
    eventKey
  )

  const [open, setOpen] = useState(false)
  const [markBackModal, setMarkBackModal] = useState(false)
  const [changeAllocationModal, setChangeAllocationModal] = useState(false)
  const [changePoolCapacityModal, setChangePoolCapacityModal] = useState(false)
  const [duplicateConfirmationModal, setDuplicateConfirmationModal] = useState(false)

  const { removeConfirmationModal, setRemoveConfirmationModal, deleteEvent, deleting } = useDeleteEvent(event.id)

  const clickOutside = useCallback(() => open && setOpen(false), [open])
  const toggleDropdown = useCallback(() => {
    setOpen(!open)
  }, [open])

  const toggleRemoveModal = useCallback(() => {
    setOpen(false)
    setRemoveConfirmationModal(!removeConfirmationModal)
  }, [removeConfirmationModal, setRemoveConfirmationModal])

  const toggleMarkBackModal = useCallback(() => {
    setOpen(false)
    setMarkBackModal(!markBackModal)
  }, [markBackModal])

  const toggleChangeAllocationModal = useCallback(() => {
    setOpen(false)
    setChangeAllocationModal(!changeAllocationModal)
  }, [changeAllocationModal])

  const toggleChangePoolCapacityModal = useCallback(() => {
    setOpen(false)
    setChangePoolCapacityModal(!changePoolCapacityModal)
  }, [changePoolCapacityModal])

  const doChangeDates = useCallback(() => {
    setOpen(false)
    navigate(`/events/${event.id}/details`, { state: { scrollSlug: 'timeline' } })
  }, [event.id, navigate])

  const toggleDuplicateModal = useCallback(() => {
    setOpen(false)
    setDuplicateConfirmationModal(!duplicateConfirmationModal)
  }, [duplicateConfirmationModal])

  const eventLink = event.organicSocialLink || (event.eventIdLive ? dicefmEvent(event.eventIdLive) : null)

  const showEventOnDice = useCallback(() => {
    setOpen(false)
    if (eventLink) {
      trackEvent('event_dice_page_opened', collectTrackingData(event))
      window.open(eventLink, '_blank')
    }
  }, [event, eventLink, trackEvent])

  const copyEventLinkToClipboard = useCallback(() => {
    copyToClipboard(eventLink)
      .then(() => {
        addNotification('success', intl.formatMessage({ id: 'event_list.quick_actions.url_copied' }))
      })
      .catch((err) => {
        console.error(err)
      })
  }, [addNotification, eventLink, intl])

  const downloadDoorlist = useCallback(async () => {
    setOpen(false)
    window.open(`${API_URL}/export?token=${event?.tokens?.doorlistExportToken}`, '_blank')
  }, [event])

  const copyEvent = useCopyEvent(event)

  const isSeated = getOr(false, 'flags.seated.active', event)

  const pooled = (event.ticketPools?.length || 0) > 0

  const canManageTickets = !!event.allowedActions?.manageTickets

  return (
    <EventListItemControlWrapper>
      <Suspense
        fallback={
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        }
      >
        <OnDesktopLarge>
          {!isSeated && canManageTickets ? (
            !pooled ? (
              <IconButton
                icon="ticket"
                onClick={toggleChangeAllocationModal}
                title={intl.formatMessage({ id: 'change_allocation' })}
                data-id="changeAllocationButton"
              />
            ) : (
              <IconButton
                icon="ticket"
                onClick={toggleChangePoolCapacityModal}
                title={intl.formatMessage({ id: 'change_pool_capacity' })}
                data-id="changePoolCapacityButton"
              />
            )
          ) : (
            <Placeholder />
          )}
        </OnDesktopLarge>
        <Dropdown active={open} onClickOutside={clickOutside}>
          <DropdownTrigger role="button" onClick={toggleDropdown} data-id="quickActions">
            <OnDesktop>
              <IconButton icon="more" />
            </OnDesktop>
            <OnMobile>
              <IconButton outlineColor="grey" icon="more" />
            </OnMobile>
          </DropdownTrigger>
          <DropdownContent active={open}>
            <Menu>
              {!isSeated && (
                <LessThanDesktopLarge>
                  {canManageTickets &&
                    (!pooled ? (
                      <MenuItem onClick={toggleChangeAllocationModal} data-id="changeAllocation">
                        {intl.formatMessage({ id: 'change_allocation' })}
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={toggleChangePoolCapacityModal} data-id="changePoolCapacity">
                        {intl.formatMessage({ id: 'change_pool_capacity' })}
                      </MenuItem>
                    ))}
                </LessThanDesktopLarge>
              )}

              {user.diceStaff && canManageTickets && (
                <>
                  {!isSeated && !pooled && (
                    <MenuItem onClick={toggleMarkBackModal} data-id="markBack">
                      <DiceBadge />
                      {intl.formatMessage({ id: 'event_list.quick_actions.mark_back_all_allocation' })}
                    </MenuItem>
                  )}
                  <MenuItem onClick={doChangeDates} data-id="changeDates">
                    <DiceBadge />
                    {intl.formatMessage({ id: 'event_list.quick_actions.change_dates' })}
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={showEventOnDice} data-id="showOnDice">
                {intl.formatMessage({ id: 'event_list.quick_actions.view_event_on_dice' })}
              </MenuItem>
              <MenuItem onClick={copyEventLinkToClipboard} data-id="copyEventUrl">
                {intl.formatMessage({ id: 'event_list.quick_actions.copy_event_url' })}
              </MenuItem>
              <PermissionCheck permission="read_doorlist:event">
                <MenuItem onClick={downloadDoorlist} data-id="downloadDoorlist">
                  {intl.formatMessage({ id: 'event_list.quick_actions.download_doorlist' })}
                </MenuItem>
              </PermissionCheck>
              {(event.eventType === 'LIVE' || user.diceStaff) && (
                <PermissionCheck permission="create:event">
                  <MenuItem onClick={toggleDuplicateModal} data-id="duplicateEvent" disabled={stripeIsBroken}>
                    {intl.formatMessage({ id: 'event_list.quick_actions.duplicate_event' })}
                  </MenuItem>
                </PermissionCheck>
              )}
              {user.diceStaff && !event.eventIdLive && (
                <PermissionCheck permission="delete:event">
                  <MenuItem onClick={toggleRemoveModal} data-id="deleteEvent">
                    <DiceBadge />
                    {intl.formatMessage({ id: 'event_list.quick_actions.delete_event' })}
                  </MenuItem>
                </PermissionCheck>
              )}
            </Menu>
          </DropdownContent>
        </Dropdown>

        {/* Modals */}
        {changePoolCapacityModal && (
          <ChangePoolCapacityModal eventId={event.id} onClose={toggleChangePoolCapacityModal} />
        )}
        {changeAllocationModal && (
          <ChangeAllocationModal
            eventId={event.id}
            onClose={toggleChangeAllocationModal}
            preSelectTierId={null}
            preSelectTtyId={null}
          />
        )}
        {markBackModal && (
          <MarkBackAllocationModal eventId={event.id} eventIdLive={event.eventIdLive} onClose={toggleMarkBackModal} />
        )}
        {duplicateConfirmationModal && (
          <ConfirmationModal
            title={intl.formatMessage({ id: 'confirmation.duplicate_event.title' })}
            description={intl.formatMessage({ id: 'confirmation.duplicate_event.description' })}
            onConfirm={copyEvent}
            onReject={toggleDuplicateModal}
          />
        )}
        {removeConfirmationModal && (
          <ConfirmationModal
            title={intl.formatMessage({ id: 'confirmation.delete_event.title' })}
            description={intl.formatMessage({ id: 'confirmation.delete_event.description' })}
            onConfirm={deleteEvent}
            onReject={toggleRemoveModal}
            loading={deleting}
          />
        )}
      </Suspense>
    </EventListItemControlWrapper>
  )
}

export default memo(ListTypeProtector(QuickActionsLive, 'live'))
