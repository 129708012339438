/**
 * @generated SignedSource<<f589ce1cc2eb3ddae9d47d76e6759619>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumTypeOfOrganizer = "AGENT" | "ARTIST" | "ARTIST_MANAGEMENT" | "BRAND" | "LABEL" | "PROMOTER" | "VENUE" | "%future added value";
export type EventSpecialFilters = "onSaleToday" | "runningLow" | "soldOut" | "toBePaid" | "today" | "%future added value";
export type PromoterTier = "CORE" | "MAX" | "PLUS" | "PRO" | "WHALE" | "%future added value";
export type EventWhereInput = {
  _or?: ReadonlyArray<EventWhereInput | null> | null;
  accountId?: OperatorsIdEqInput | null;
  accountManagerId?: OperatorsIdEqInput | null;
  addressCountry?: OperatorsString | null;
  addressRegion?: OperatorsString | null;
  addressState?: OperatorsString | null;
  announceDate?: OperatorsDateInput | null;
  artistIds?: OperatorsIdEqInput | null;
  artistName?: OperatorsString | null;
  billingPromoter?: OperatorsJsonInput | null;
  billingPromoterName?: OperatorsString | null;
  bundleIds?: OperatorsIdEqInput | null;
  cityIds?: OperatorsListOfStringInput | null;
  closeEventDate?: OperatorsDateInput | null;
  countryCode?: OperatorsString | null;
  createdAt?: OperatorsDateInput | null;
  date?: OperatorsDateInput | null;
  description?: OperatorsString | null;
  endDate?: OperatorsDateInput | null;
  endSaleDate?: OperatorsDateInput | null;
  eventIdLive?: OperatorsString | null;
  eventIdPreview?: OperatorsString | null;
  eventStatus?: OperatorsString | null;
  eventType?: OperatorsString | null;
  flagNames?: OperatorsListOfStringInput | null;
  free?: OperatorsBooleanEqInput | null;
  fullAddress?: OperatorsString | null;
  hierarchicalTagNames?: OperatorsListOfStringInput | null;
  id?: OperatorsIdEqInput | null;
  labelNames?: OperatorsListOfStringInput | null;
  name?: OperatorsString | null;
  offSaleDate?: OperatorsDateInput | null;
  onSaleDate?: OperatorsDateInput | null;
  permName?: OperatorsString | null;
  priority?: OperatorsBooleanEqInput | null;
  promoterName?: OperatorsString | null;
  promoterPayoutsEnabled?: OperatorsBooleanEqInput | null;
  promoterTier?: OperatorsEnumPromoterTier | null;
  promoterTypeOfOrganizer?: OperatorsEnumTypeOfOrganizer | null;
  recurring?: OperatorsBooleanEqInput | null;
  scheduleStatus?: OperatorsString | null;
  special?: EventSpecialFilters | null;
  stages?: OperatorsListOfStringInput | null;
  state?: OperatorsString | null;
  stripeAccountId?: OperatorsString | null;
  stripeVerified?: OperatorsBooleanEqInput | null;
  submittedAt?: OperatorsDateInput | null;
  tagNames?: OperatorsListOfStringInput | null;
  venue?: OperatorsString | null;
  venueIds?: OperatorsIdEqInput | null;
  venueSeatingChartIds?: OperatorsIdEqInput | null;
  venueTier?: OperatorsString | null;
};
export type OperatorsIdEqInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
};
export type OperatorsString = {
  eq?: string | null;
  iLike?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notLike?: string | null;
  null?: boolean | null;
};
export type OperatorsDateInput = {
  between?: ReadonlyArray<string | null> | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  notBetween?: ReadonlyArray<string | null> | null;
  null?: boolean | null;
};
export type OperatorsJsonInput = {
  eq?: IBackendMap | null;
  ne?: IBackendMap | null;
  neOrNull?: IBackendMap | null;
};
export type OperatorsListOfStringInput = {
  contains?: ReadonlyArray<string | null> | null;
  eq?: ReadonlyArray<string | null> | null;
  ne?: ReadonlyArray<string | null> | null;
  notContains?: ReadonlyArray<string | null> | null;
};
export type OperatorsBooleanEqInput = {
  eq?: boolean | null;
  ne?: boolean | null;
  neOrNull?: boolean | null;
};
export type OperatorsEnumPromoterTier = {
  eq?: PromoterTier | null;
  in?: ReadonlyArray<PromoterTier | null> | null;
};
export type OperatorsEnumTypeOfOrganizer = {
  eq?: EnumTypeOfOrganizer | null;
  in?: ReadonlyArray<EnumTypeOfOrganizer | null> | null;
};
export type DashboardEventsRecentlyViewedQuery$variables = {
  recentlyViewedWhere: EventWhereInput;
};
export type DashboardEventsRecentlyViewedQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewed_viewer">;
  } | null;
};
export type DashboardEventsRecentlyViewedQuery = {
  response: DashboardEventsRecentlyViewedQuery$data;
  variables: DashboardEventsRecentlyViewedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "recentlyViewedWhere"
  }
],
v1 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "recentlyViewedWhere"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardEventsRecentlyViewedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "RecentlyViewed_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardEventsRecentlyViewedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "recentlyViewed",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 5
              },
              (v1/*: any*/)
            ],
            "concreteType": "EventConnection",
            "kind": "LinkedField",
            "name": "events",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eventIdLive",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timezoneName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusAsOfNow",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventFlags",
                        "kind": "LinkedField",
                        "name": "flags",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hidden",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventImage",
                        "kind": "LinkedField",
                        "name": "eventImages",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cdnUrl",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eventType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "venueName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Venue",
                        "kind": "LinkedField",
                        "name": "primaryVenue",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Venue",
                        "kind": "LinkedField",
                        "name": "venues",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Promoter",
                        "kind": "LinkedField",
                        "name": "billingPromoter",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "doorSalesOnly",
                            "value": false
                          },
                          {
                            "kind": "Literal",
                            "name": "includeArchived",
                            "value": false
                          }
                        ],
                        "concreteType": "TicketType",
                        "kind": "LinkedField",
                        "name": "ticketTypes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "streamLink",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:false)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allocation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "costCurrency",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Sales",
                        "kind": "LinkedField",
                        "name": "sales",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalSold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalValue",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc953af5b89212b593b50c5d14d10b0e",
    "id": null,
    "metadata": {},
    "name": "DashboardEventsRecentlyViewedQuery",
    "operationKind": "query",
    "text": "query DashboardEventsRecentlyViewedQuery(\n  $recentlyViewedWhere: EventWhereInput!\n) {\n  viewer {\n    ...RecentlyViewed_viewer_cULSt\n    id\n  }\n}\n\nfragment DashboardEventCardInfo_event on Event {\n  id\n  name\n  state\n  date\n  timezoneName\n  statusAsOfNow\n  flags {\n    hidden\n  }\n  eventImages {\n    type\n    cdnUrl\n    id\n  }\n  eventType\n  venueName\n  primaryVenue {\n    id\n    name\n  }\n  venues {\n    id\n    name\n  }\n  billingPromoter {\n    id\n    name\n  }\n  ticketTypes(doorSalesOnly: false, includeArchived: false) {\n    streamLink\n    id\n  }\n}\n\nfragment DashboardEventSalesProgress_event on Event {\n  id\n  allocation\n  costCurrency\n  sales {\n    totalSold\n    totalValue\n  }\n}\n\nfragment RecentlyViewed_viewer_cULSt on Viewer {\n  recentlyViewed: events(first: 5, where: $recentlyViewedWhere) {\n    edges {\n      node {\n        id\n        eventIdLive\n        ...DashboardEventCardInfo_event\n        ...DashboardEventSalesProgress_event\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f384ea853eb419321007f5757216957";

export default node;
