import { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { compact, compose, concat, filter, find, flatMap, intersection, keyBy, map, reject, uniqBy } from 'lodash/fp'
import { useFragment, Environment, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import IEventFormBasics from '../types/Basics'
import useHierarchicalTags from '../../../utils/hooks/useHierarchicalTags'
import { useBasicsTags_viewer$key } from '../../../__generated__/useBasicsTags_viewer.graphql'
import graphqlOptionsLoader from '../../../utils/graphqlOptionsLoader'

export default function useBasicsTags(viewerKey: useBasicsTags_viewer$key) {
  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormBasics>()

  const viewer = useFragment(
    graphql`
      fragment useBasicsTags_viewer on Viewer {
        ...useHierarchicalTags_viewer
        festivalTags: tags(first: 10, where: { name: { in: ["festival", "type:festival"] } }) {
          edges {
            node {
              value: id
              label: name
            }
          }
        }
      }
    `,
    viewerKey
  )

  const {
    genres,
    setGenres,
    genresLoader,
    typeOptions: eventTypeOptions,
    type: eventType,
  } = useHierarchicalTags(viewer)

  const environment = useRelayEnvironment()

  const getCharacteristicsLoader = (environment: Environment) => {
    const where = {}
    return graphqlOptionsLoader(
      environment,
      graphql`
        query useBasicsTagsCharacteristicsQuery($where: CharacteristicWhereInput) {
          viewer {
            options: characteristics(first: 100, where: $where) {
              edges {
                node {
                  value: id
                  label: name
                }
              }
            }
          }
        }
      `,
      { postProcess: (opts) => opts, where, searchField: 'name' }
    )
  }

  const characteristicsLoader = useMemo(() => getCharacteristicsLoader(environment), [environment])

  const setCharacteristics = useCallback(
    (_ids: any, selection: any) => {
      setFieldValue('characteristics', uniqBy('value', selection))
      setFieldTouched('characteristics', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const changeEventType = useCallback(
    (_id: any, type: any) => {
      const newGenres = compose([
        filter(['kind', 'genre']),
        flatMap('hierarchicalTags'),
        filter(
          (artist: NonNullable<NonNullable<IEventFormBasics['eventArtists']>[number]>['artist']) =>
            type?.value === find(['kind', 'type'], artist?.hierarchicalTags || [])?.value
        ),
        map('artist'),
      ])(values.eventArtists || [])

      setFieldValue('hierarchicalTags', uniqBy('value', concat(type, newGenres)))

      setFieldTouched('hierarchicalTags', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm, values.eventArtists]
  )

  const festivalTags = useMemo(() => map('node', viewer?.festivalTags?.edges), [viewer])

  const festival = useMemo(
    () => intersection(map('value', values.tags || []), map('value', festivalTags)).length > 0,
    [festivalTags, values.tags]
  )

  const setFestival = useCallback(
    (isFestival: boolean) => {
      if (isFestival) {
        setFieldValue('tags', compact(uniqBy('value', concat(values.tags || [], festivalTags))))
      } else {
        const obj = keyBy('value', festivalTags)
        setFieldValue(
          'tags',
          reject((t) => !t || !!obj[t.value], values.tags || [])
        )
      }
    },
    [festivalTags, setFieldValue, values.tags]
  )

  return {
    eventTypeOptions,
    eventType,
    changeEventType,
    genres,
    setGenres,
    genresLoader,
    characteristicsLoader,
    setCharacteristics,
    festival,
    setFestival,
  }
}
