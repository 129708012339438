import React, { FC, memo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'
import { Form, FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import TextareaAutosize from '../../../components/TextareaAutosize'
import SwitchField from '../../../components/SwitchField'

interface IEventWorkflowCommentModalProps {
  cta?: string
  onClose: () => void
  onSave: (values: { comment: string | null; priority: boolean | null }) => Promise<void>
}

const EventWorkflowCommentModal: FC<React.PropsWithChildren<IEventWorkflowCommentModalProps>> = ({
  cta,
  onClose,
  onSave,
}) => {
  const intl = useIntl()

  const [priority, setPriority] = useState(false)
  const [comment, setComment] = useState<string | null>(null)
  const [saving, setSaving] = useState(false)

  const clickSave = useCallback(() => {
    setSaving(true)
    onSave({ comment, priority }).finally(() => {
      setSaving(false)
    })
  }, [comment, onSave, priority])

  const handleChange = useCallback((e: any) => setComment(e.target.value), [])
  const togglePriority = useCallback((e: any) => setPriority((v) => !v), [])

  return (
    <Modal closeButton onClose={onClose} modalTitle={intl.formatMessage({ id: 'event_workflow.move_back' })}>
      <ModalBody>
        <Form>
          <FormRow>
            <FormField
              name="comment"
              control={TextareaAutosize}
              label={intl.formatMessage({ id: 'event_workflow.comment_modal.comment.label' })}
              hint={intl.formatMessage({ id: 'event_workflow.comment_modal.comment.hint' })}
              value={comment || ''}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <SwitchField
              name="priority"
              label={intl.formatMessage({ id: 'event_workflow.priority.label' })}
              hint={intl.formatMessage({ id: 'event_workflow.priority.hint' })}
              checked={priority}
              onChange={togglePriority}
            />
          </FormRow>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl data-id="save" onClick={clickSave} loading={saving}>
          {cta || intl.formatMessage({ id: 'save_changes' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" data-id="cancel" onClick={onClose} disabled={saving}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(EventWorkflowCommentModal)
