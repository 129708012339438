/* eslint-disable max-lines */
import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormik, yupToFormErrors } from 'formik'
import { compact, find, isNil, map, take, isEmpty, concat, some, includes, reject, filter } from 'lodash/fp'
import { formatISO, isBefore, parseISO, subDays } from 'date-fns'

import graphql from 'babel-plugin-relay/macro'
import { useFragment, useRelayEnvironment } from 'react-relay'

import { authContext } from '../../../context/auth'
import { localeContext } from '../../../context/locale'
import { ILocale } from '../../../intl'
import { isNew, isSaved } from '../../../utils/entityStatus'
import { CURRENCY } from '../../../utils/formatters/number'
import graphqlOptionsLoader, { IOptions } from '../../../utils/graphqlOptionsLoader'
import { color, font } from '../../../utils/variables'
import { parseMarkdown, renderMarkdown } from '../../../utils/markdown'
import { parseAtTimezone } from '../../../utils/calendar'

import AlertBox from '../../../components/AlertBox'
import Collapsible from '../../../components/Collapsible'
import FeeBreakdownTooltip from '../../../components/Event/FeeBreakdownTooltip'
import { Form, FormRow } from '../../../components/Form'
import FormGroup from '../../../components/FormGroup'
import FormField, { IStyledFormField } from '../../../components/FormField'
import MarkdownEditor from '../../../components/MarkdownEditor'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'
import Radio from '../../../components/Radio'
import Svg from '../../../components/Svg'
import SwitchField from '../../../components/SwitchField'
import { TabMenu, TabMenuItem } from '../../../components/TabMenu'

import { ProductSchema } from '../validation/Extras'
import EventProductSellingPoints from './EventProductSellingPoints'
import EventProductTicketTypes from './EventProductTicketTypes'
import EventProductVariants from './EventProductVariants'
import EventFeeContractPreview from './EventFeeContractPreview'
import EventOverrideProductFees from './EventOverrideProductFees'
import EventSelectedVenue from './EventSelectedVenue'
import EventPrimaryVenue from './EventPrimaryVenue'
import ProductImages from './ProductImages/ProductImages'

import IEventFormExtras, { IProduct, IFee } from '../types/Extras'

import useProductPriceBreakdown from '../hooks/useProductPriceBreakdown'
import useProductFeeContractPreview from '../hooks/useProductFeeContractPreview'
import { allowedEventAction } from '../services/allowedEventAction'
import { EventProductModal_event$key } from '../../../__generated__/EventProductModal_event.graphql'
import { REGEX_INTEGER } from '../../../utils/regex'

const PriceInfo = styled(FormRow)<{ isLoading: boolean }>`
  padding: 16px;
  background-color: ${color.palegrey};
  border-radius: 8px;

  font-size: ${font.size.sm}px;

  filter: ${({ isLoading }) => (isLoading ? 'blur(3px)' : 'none')};
`

const PriceBreakdown = styled.div`
  div + div {
    margin-top: 6px;
  }
`

const PotentialRevenue = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  span {
    display: inline-block;
    margin-left: 4px;
    vertical-align: bottom;
    font-size: ${font.size.base}px;
    font-weight: ${font.weight.bold};
    word-break: break-word;
  }
`

const PaddedForm = styled(Form)`
  padding: 12px 0 0 32px;
`

const CustomToggle = styled.div`
  position: relative;
  padding: 16px 24px 16px 48px;
  border: 1px solid ${color.grey};
  border-radius: 4px;
  & > svg {
    position: absolute;
    top: 14px;
    left: 16px;
  }
`

const Counter = styled.div<{ count: number; maxCount: number }>`
  position: absolute;
  top: 0;
  right: 0;
  white-space: nowrap;
  font-size: ${font.size.sm}px;
  color: ${({ count, maxCount }) => (count > maxCount ? color.error : color.darkgrey)};
`

const MarginH3 = styled.h3`
  && {
    font-size: ${font.size.md}px;
    margin-bottom: 20px;
    margin-top: 52px;
  }
`

const Small = styled.span`
  font-size: ${font.size.sm}px;
`

const Divider = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${color.black};
  margin: 52px 0;
`

const SLink = styled.a`
  color: ${color.primary};
`

const MarkdownTextarea = styled(FormField)`
  .rdw-editor-main {
    min-height: 100px;
  }
` as IStyledFormField<typeof MarkdownEditor>

const getUserGuidelinesLink = (locale: ILocale) => {
  switch (locale) {
    case 'fr':
      return 'https://support.dice.fm/article/774-mio-user-guidelines-french-language'
    case 'it':
      return 'https://support.dice.fm/article/776-mio-user-guidelines-italian-language'
    case 'es':
      return 'https://support.dice.fm/article/770-mio-user-guidelines-spanish-language'
    case 'pt':
      return 'https://support.dice.fm/article/779-mio-user-guidelines-portuguese-language'
    case 'de':
      return 'https://support.dice.fm/article/948-mio-user-guidelines-german-language'
    default:
      return 'https://support.dice.fm/article/761-mio-user-guidelines-english-language'
  }
}

interface IProps {
  event: EventProductModal_event$key | null
  eventForm?: IEventFormExtras
  productId: string
  productType: 'extras' | 'merch'
  onClose: () => void
  onSave: (product: IProduct) => void
  readOnly?: boolean
}

const EventProductModal: FC<IProps> = ({
  event: eventKey,
  eventForm,
  productType,
  productId,
  onClose,
  onSave,
  readOnly: externalReadOnly,
}) => {
  const environment = useRelayEnvironment()

  const parentCategoriesLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query EventProductModalProductCategoriesQuery {
            viewer {
              options: productCategories {
                value: id
                label: name
                type
                categories {
                  value: id
                  label: name
                  rootType
                  type
                  coverBackgroundColor
                  coverImageUrl
                }
              }
            }
          }
        `
      ),
    [environment]
  )

  const venueLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query EventProductModalVenueLoaderQuery($searchTerm: String) {
            viewer {
              options: venues(searchTerm: $searchTerm, first: 50) {
                edges {
                  node {
                    value: id
                    label: name
                    hint: fullAddress

                    latitude
                    longitude
                    fullAddress

                    profileDetails {
                      imageAttachment {
                        cdnUrl
                      }
                      imageCropRegion {
                        x
                        y
                        width
                        height
                      }
                    }

                    venueImages {
                      attachment {
                        cdnUrl
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        { fullText: true }
      ),
    [environment]
  )

  const event = useFragment(
    graphql`
      fragment EventProductModal_event on Event {
        id
        state
        eventIdLive
        costCurrency
        timezoneName
        date
        endDate
        disableUsTax
        feesBehaviour
        basePriceFees
        postFanPriceFees
        fees {
          amount
          type
          unit
          applicable

          split {
            amount
            destination
            unit
          }
        }
        billingPromoter {
          value: id
          label: name
          stripeAccountId
          platformAccountCode
          showPriceSuggestions
          addressCountry
          countryCode
          accountId
          allowSkipReview
          resoldEnabled
          coolingOffPeriod
          disableUsTax
        }
        allowedActions {
          addProducts
          manageProductsAllocation
          minorUpdate
        }
        allowedLifecycleUpdates {
          products {
            canAdd
            canChangeOrder
            canDelete
            canUpdate
            canUpdatePrice
            canUpdateImages
          }
        }
        venues {
          value: id
        }
        ticketTypes(doorSalesOnly: false, includeArchived: true) {
          id
          ticketPoolId
          name
          archived
          hidden
          faceValue
          fees {
            amount
            type
            unit
            applicable

            split {
              amount
              destination
              unit
            }
          }
          priceBreakdown {
            total
            totalWithPwl
            totalWithoutPwl
            faceValue
            breakdown {
              type
              applicable
              computed
            }
            split {
              computed
              destination
            }
            friendlyFaceValue
            friendlyPrice
          }
          priceTierType
          priceTiers {
            id
            name
            faceValue
            fees {
              amount
              type
              unit
              applicable

              split {
                amount
                destination
                unit
              }
            }
            priceBreakdown {
              breakdown {
                computed
                type
                applicable
                split {
                  amount
                  computed
                  destination
                }
              }
              total
              totalWithPwl
              totalWithoutPwl
              faceValue
              split {
                computed
                destination
              }
              friendlyPrice
              friendlyFaceValue
            }
          }
        }
        products {
          rootType
          allTicketTypes
          category {
            value: id
            label: name
            type
            rootType
            coverBackgroundColor
            coverImageUrl
            parentCategory {
              value: id
              label: name
              type
            }
          }
          hasVariants
          optionType
          variants {
            id
            name
            allocation
            sku
          }
          fulfilledBy
          fees {
            amount
            type
            unit
            applicable

            split {
              amount
              destination
              unit
            }
          }
          hasSeparateAccessBarcodes
          customCover
          venue {
            value: id
            label: name

            latitude
            longitude
            fullAddress

            profileDetails {
              imageAttachment {
                cdnUrl
              }
              imageCropRegion {
                x
                y
                width
                height
              }
            }

            venueImages {
              attachment {
                cdnUrl
              }
            }
          }
          locationNote
          date
          endDate
          description
          id
          allocation
          sku
          onSaleDate
          offSaleDate
          name
          faceValue
          productType
          purchaseConfirmationMessage
          productImages {
            id
            cdnUrl
            cropRegion {
              x
              y
              width
              height
            }
            attachment {
              id
            }
          }
          sellingPoints {
            name
          }
          ticketTypes {
            id
            name
          }
        }
      }
    `,
    eventKey
  )

  const {
    id: eventId,
    eventIdLive,
    costCurrency: currency,
    allowedLifecycleUpdates: realAllowedLifecycleUpdates,
    state,
    allowedActions,
    venues,
    ticketTypes,
    products,
    billingPromoter,
    feesBehaviour,
    timezoneName,
    date,
    endDate,
    // basePriceFees,
    // postFanPriceFees,
    disableUsTax,
    fees: eventFees,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = (eventForm || event)!

  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const modalBodyRef = useRef<any>(null)

  const userGuideLink = useMemo(() => getUserGuidelinesLink(locale), [locale])

  const readOnly = isNil(externalReadOnly) ? !allowedActions?.minorUpdate : externalReadOnly

  const isLiveAndCanAddProduct =
    !readOnly && state !== 'DRAFT' && allowedEventAction(realAllowedLifecycleUpdates, 'products', 'canAdd')

  const product = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => find(['id', productId], products || [])! as any,
    [productId, products]
  )

  const noRestrictions = useMemo(() => !!isLiveAndCanAddProduct && !isSaved(product), [isLiveAndCanAddProduct, product])
  const allowedLifecycleUpdates = noRestrictions ? null : realAllowedLifecycleUpdates

  const onSubmit = useCallback((values: any) => onSave(values), [onSave])

  const onCancel = useCallback(() => onClose(/* no args! */), [onClose])

  const isExisting = !isNew(product)

  const convertedVariants = useMemo(
    () => map((variant) => variant && { ...variant, type: product?.optionType || null }, product?.variants || []),
    [product?.optionType, product.variants]
  )

  const initialValues = useMemo(
    () => ({
      ...product,
      event: { id: eventId || null },
      parentCategory: product?.category?.parentCategory || null,
      category: product?.category || null,
      productType: 'ADDON',
      hasSeparateAccessBarcodes: isExisting ? product?.hasSeparateAccessBarcodes : true,
      sameVenue: !product?.venue,
      venue: product?.venue || null,
      locationNote: product?.locationNote || null,
      sameDates: !(product?.date && product?.endDate) ? 'DEFAULT' : 'CUSTOM',
      date: product?.date || null,
      endDate: product?.endDate || null,
      allocation: product.allocation || null,
      saleDate: !(product.onSaleDate && product.offSaleDate) ? 'DEFAULT' : 'CUSTOM',
      onSaleDate: product.onSaleDate || null,
      offSaleDate: product.offSaleDate || null,
      linkTo: !!product.allTicketTypes ? 'ALL' : 'CUSTOM',
      descriptionDraft: parseMarkdown(product.description),
      purchaseConfirmationMessageDraft: parseMarkdown(product.purchaseConfirmationMessage),
      hasVariants: !!product?.hasVariants,
      optionType: product?.optionType || null,
      variants: convertedVariants || [],
      fulfilledBy: product?.fulfilledBy || null,
      productImages: product.productImages || [],
      customCover: !!product?.customCover,
    }),
    [convertedVariants, eventId, isExisting, product]
  )

  const validate = useCallback(
    async (product: any) => {
      try {
        await ProductSchema.validate(product, {
          abortEarly: false,
          context: { diceStaff: user.diceStaff, dicePartner: user.dicePartner, ...(eventForm || event) },
        })
      } catch (err) {
        return yupToFormErrors(err)
      }
    },
    [event, eventForm, user.dicePartner, user.diceStaff]
  )

  const formik = useFormik<typeof initialValues & { limit?: number }>({
    initialValues,
    onSubmit,
    validate,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: isExisting,
  })

  const {
    values,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleBlur,
    isValid,
    touched,
    errors,
    validateForm,
    resetForm,
    setFieldTouched,
  } = formik

  useEffect(() => {
    if (values.linkTo) {
      setFieldValue('allTicketTypes', values.linkTo === 'ALL')
    }
  }, [setFieldValue, values.linkTo])

  const [parentCategoryOptions, setParentCategoryOptions] = useState<IOptions>([])
  useEffect(
    () =>
      parentCategoriesLoader('', (loadedOptions) => {
        const resultOptions =
          productType === 'merch' ? filter(['type', 'MERCH'], loadedOptions) : reject(['type', 'MERCH'], loadedOptions)
        setParentCategoryOptions(resultOptions)
      }),
    [parentCategoriesLoader, productType]
  )

  useEffect(() => {
    if (!isEmpty(parentCategoryOptions) && productType === 'merch') {
      setFieldValue(
        'parentCategory',
        values.parentCategory
          ? find(['value', values.parentCategory.value], parentCategoryOptions)
          : parentCategoryOptions[0]
      )
    }
  }, [parentCategoryOptions, setFieldValue, values.parentCategory, user.diceStaff, productType])

  const setParentCategory = useCallback(
    (_: any, value: any) => {
      if (value.value === values.parentCategory) return
      resetForm({})
      setFieldValue('parentCategory', value)
      setFieldValue('category', null)
    },
    [resetForm, setFieldValue, values.parentCategory]
  )

  const categoryOptions = useMemo(
    () => find(['value', values.parentCategory?.value], parentCategoryOptions)?.categories || [],
    [parentCategoryOptions, values.parentCategory]
  )

  const setCategory = useCallback(
    (_: any, value: any) => {
      if (value.value === values.category?.value) return
      setFieldValue('category', { ...value, parentCategory: values.parentCategory })
      setFieldTouched('category', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm, values.category, values.parentCategory]
  )

  const setProductImages = useCallback(
    (v: any) => {
      setFieldValue('productImages', v)
      setFieldTouched('productImages', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const setSellingPoints = useCallback(
    (v: any) => {
      setFieldValue('sellingPoints', v)
      setFieldTouched('sellingPoints', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const handleChangeAllocation = useCallback(
    (e: any) => {
      const value = e.target.value
      if (REGEX_INTEGER.test(value) && value.length < 15) {
        setFieldValue('allocation', parseInt(value, 10))
      }
      if (value === '') {
        setFieldValue('allocation', null)
      }
      return
    },
    [setFieldValue]
  )

  const setDescriptionDraft = useCallback(
    (draft: Draft.EditorState) => {
      setFieldValue('descriptionDraft', draft)
      setFieldValue('description', renderMarkdown(draft))
    },
    [setFieldValue]
  )

  const setPurchaseMessageDraft = useCallback(
    (draft: Draft.EditorState) => {
      setFieldValue('purchaseConfirmationMessageDraft', draft)
      setFieldValue('purchaseConfirmationMessage', renderMarkdown(draft))
    },
    [setFieldValue]
  )

  const toggleVenue = useCallback(
    (e: any) => {
      const value = e.target.value === 'default' ? true : false
      if (value === values.sameVenue) return
      setFieldValue('venue', null)
      setFieldValue('locationNote', null)
      setFieldValue('sameVenue', value)
    },
    [values.sameVenue, setFieldValue]
  )
  const setVenue = useCallback(
    (_: any, venue: any) => {
      setFieldValue('venue', venue)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, validateForm]
  )
  const removeVenue = useCallback(() => setFieldValue('venue', null), [setFieldValue])

  const handleSameDatesChange = useCallback(
    (e: any) => {
      const mode = e.target.value

      if (mode === 'DEFAULT') {
        setFieldValue('date', null)
        setFieldValue('endDate', null)
      }
      handleChange(e)

      setTimeout(() => validateForm(), 0)
    },
    [handleChange, setFieldValue, validateForm]
  )

  const linkTicketTypes = useCallback(
    (v: any) => {
      setFieldValue('ticketTypes', v)
      setFieldTouched('ticketTypes', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const handleSaleDateChange = useCallback(
    (e: any) => {
      const mode = e.target.value

      if (mode === 'DEFAULT') {
        setFieldValue('onSaleDate', null)
        setFieldValue('offSaleDate', null)
      }
      handleChange(e)

      setTimeout(() => validateForm(), 0)
    },
    [handleChange, setFieldValue, validateForm]
  )

  const breakdownInputs = useMemo(() => {
    return [
      {
        initialBreakdown: values.priceBreakdown,
        faceValue: values.faceValue || 0,
        categoryId: values.category?.value || null,
        fees: concat((eventFees || []) as IFee[], values.fees || []),
      },
    ]
  }, [eventFees, values.category?.value, values.faceValue, values.fees, values.priceBreakdown])

  const venueIds: string[] = useMemo(() => compact(map('value', venues || [])), [venues])

  const breakdownCtx = useMemo(
    () => ({
      venueId: venueIds[0],

      // TODO: uncomment after fees override will be releases
      // eventId: feesBehaviour === 'OVERRIDE' ? null : eventId,
      // billingPromoterId: feesBehaviour === 'OVERRIDE' ? null : billingPromoter?.value || null,
      // basePriceFees: feesBehaviour === 'OVERRIDE' ? basePriceFees : null,
      // postFanPriceFees: feesBehaviour === 'OVERRIDE' ? postFanPriceFees : null,

      // TODO: remove after fees override will be releases
      eventId: eventId,
      billingPromoterId: billingPromoter?.value || null,
      basePriceFees: null,
      postFanPriceFees: null,

      disableUsTax: !!disableUsTax,
    }),
    [billingPromoter?.value, disableUsTax, eventId, venueIds]
  )

  const { loading, priceBreakdowns } = useProductPriceBreakdown(breakdownInputs, breakdownCtx)

  const potentialRevenue = useMemo(
    () => (values.allocation || 0) * (priceBreakdowns?.[0]?.split?.billingPromoter || 0),
    [values.allocation, priceBreakdowns]
  )

  const allocationError = useMemo(() => {
    if (touched.allocation && errors.allocation) {
      return intl.formatMessage({ id: 'new_event.extras.form.allocation.validation_error' }, { minAllocation: 1 })
    }
    return null
  }, [errors.allocation, intl, touched.allocation])

  const { contract } = useProductFeeContractPreview(
    billingPromoter?.value || null,
    eventId,
    !!disableUsTax,
    breakdownInputs?.[0]?.categoryId,
    breakdownInputs?.[0]?.faceValue,
    venueIds[0]
  )

  // TODO: uncomment after fees override will be releases
  const showOverrideFees = false && feesBehaviour === 'OVERRIDE'
  const canEditFees = !readOnly

  const hasRebate = useMemo(() => some('rebate', priceBreakdowns || []), [priceBreakdowns])

  const canChangePrice =
    state === 'DRAFT' ||
    noRestrictions ||
    allowedEventAction(allowedLifecycleUpdates, 'forbidden') ||
    includes(values.id, allowedLifecycleUpdates?.products?.canUpdatePrice || [])

  const canChangeImages =
    state === 'DRAFT' ||
    noRestrictions ||
    allowedEventAction(allowedLifecycleUpdates, 'forbidden') ||
    includes(values.id, allowedLifecycleUpdates?.products?.canUpdateImages || [])

  const canManageVariants =
    (state === 'DRAFT' && !eventIdLive) || noRestrictions || allowedEventAction(allowedLifecycleUpdates, 'forbidden')

  const redeemSectionDisabled = useMemo(() => {
    if (date) {
      const now = parseAtTimezone(formatISO(new Date()), timezoneName || undefined)
      const deadlineDate = parseAtTimezone(formatISO(subDays(parseISO(date), 1)), timezoneName || undefined)

      return deadlineDate && now
        ? !isBefore(now, deadlineDate) &&
            state !== 'DRAFT' &&
            !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
            !noRestrictions
        : false
    }
    return false
  }, [allowedLifecycleUpdates, date, noRestrictions, state, timezoneName])

  const productCategoryType = useMemo(() => values.parentCategory?.type || null, [values.parentCategory])

  return (
    <Modal
      size="fullscreen"
      closeButton
      onClose={onCancel}
      modalTitle={intl.formatMessage({
        id: isExisting
          ? `new_event.${productType}.form.header.edit_extra`
          : `new_event.${productType}.form.header.create`,
      })}
    >
      <ModalBody ref={modalBodyRef}>
        <form noValidate onSubmit={handleSubmit}>
          <Form>
            {productType !== 'merch' && (
              <FormRow columnOnMobile>
                <FormField
                  name="parentCategory"
                  label={intl.formatMessage({ id: 'new_event.extras.form.parent_category.label' })}
                  value={values.parentCategory}
                  onChange={setParentCategory}
                  control="select"
                  options={parentCategoryOptions}
                  onBlur={handleBlur}
                  error={touched.parentCategory && errors.parentCategory}
                  required
                  disabled={
                    readOnly ||
                    (state !== 'DRAFT' &&
                      !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
                      !noRestrictions) ||
                    isEmpty(parentCategoryOptions) ||
                    isExisting
                  }
                />
              </FormRow>
            )}
            <FormRow columnOnMobile>
              <FormField
                name="category"
                label={intl.formatMessage({ id: `new_event.${productType}.form.category.label` })}
                value={values.category}
                onChange={setCategory}
                control="select"
                options={categoryOptions}
                onBlur={handleBlur}
                error={touched.category && errors.category}
                required
                disabled={
                  readOnly ||
                  !values.parentCategory ||
                  isEmpty(parentCategoryOptions) ||
                  (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !noRestrictions)
                }
              />
            </FormRow>
            <FormRow columnOnMobile>
              <FormField
                name="name"
                label={intl.formatMessage({ id: `new_event.${productType}.form.name.label` })}
                hint={intl.formatMessage({ id: `new_event.${productType}.form.name.hint` })}
                value={values.name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(touched.name || isExisting) && errors.name}
                disabled={
                  readOnly ||
                  (state !== 'DRAFT' &&
                    !!eventIdLive &&
                    !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
                    !noRestrictions)
                }
                required
              >
                <Counter maxCount={45} count={(values.name || '').length}>
                  {(values.name || '').length}/{45}
                </Counter>
              </FormField>
            </FormRow>

            {values.category && (
              <>
                <FormRow>
                  <FormGroup
                    label={intl.formatMessage({ id: `new_event.${productType}.form.product_images.label` })}
                    required={values.category.rootType === 'MERCH'}
                  >
                    <Counter
                      maxCount={values.customCover || values.category.rootType === 'MERCH' ? 8 : 7}
                      count={values.productImages.length}
                    >
                      {values.productImages.length +
                        (values.customCover || values.category.rootType === 'MERCH' ? 0 : 1)}
                      /{8}
                    </Counter>
                    <ProductImages
                      refContainer={modalBodyRef}
                      staticCover={
                        !values.customCover && values.category.rootType !== 'MERCH'
                          ? {
                            imgUrl: values.category.coverImageUrl,
                            bgColor: values.category.coverBackgroundColor,
                            hint: intl.formatMessage({
                              id: 'new_event.extras.form.product_images.static_cover.hint_new',
                            }),
                          }
                          : null
                      }
                      productImages={values.productImages}
                      setProductImages={setProductImages}
                      hasError={!!errors.productImages}
                      touched={touched || isExisting}
                      allowEdit={!readOnly && !!canChangeImages}
                      noMove={(values.productImages?.length || 0) === 1}
                      noRemove={(values.productImages?.length || 0) === 1}
                    />
                    {values.category.rootType !== 'MERCH' && user.diceStaff ? (
                      <SwitchField
                        className="mt-sm"
                        label={intl.formatMessage({
                          id: 'new_event.extras.form.product_images.custom_cover.label',
                        })}
                        hint={intl.formatMessage({
                          id: 'new_event.extras.form.product_images.custom_cover.hint',
                        })}
                        name="customCover"
                        checked={!!values.customCover}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        dice
                      />
                    ) : null}
                  </FormGroup>
                </FormRow>
                <FormRow>
                  <EventProductSellingPoints
                    productCategoryType={productCategoryType}
                    handleBlur={handleBlur}
                    sellingPoints={values.sellingPoints || null}
                    setSellingPoints={setSellingPoints}
                    errors={errors}
                    touched={touched || isExisting}
                    allowEdit={!readOnly}
                    noMove={(values.sellingPoints?.length || 0) === 1}
                    noRemove={(values.sellingPoints?.length || 0) === 1}
                  />
                </FormRow>
                <FormRow columnOnMobile>
                  <MarkdownTextarea
                    name="description"
                    control={MarkdownEditor}
                    label={intl.formatMessage({ id: `new_event.${productType}.form.description.label` })}
                    placeholder={
                      productCategoryType !== 'MERCH'
                        ? intl.formatMessage({ id: 'new_event.extras.form.description.placeholder' })
                        : undefined
                    }
                    hint={intl.formatMessage({ id: `new_event.${productType}.form.description.hint` })}
                    value={values.descriptionDraft}
                    onChange={setDescriptionDraft}
                    onBlur={handleBlur}
                    error={(touched.description || isExisting) && errors.description}
                    disabled={readOnly}
                    required
                  >
                    <Counter maxCount={1300} count={(values.description || '').length}>
                      {(values.description || '').length}/{1300}
                    </Counter>
                  </MarkdownTextarea>
                </FormRow>

                {/* Pricing and quantity */}
                <MarginH3>
                  {productCategoryType === 'MERCH'
                    ? intl.formatMessage({ id: 'new_event.merch.form.variants.pricing_and_quantity.header' })
                    : intl.formatMessage({ id: 'new_event.extras.form.variants.pricing_and_quantity.header' })}
                </MarginH3>
                <FormRow columnOnMobile>
                  <EventProductVariants
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    validateForm={validateForm}
                    noRestrictions={noRestrictions}
                    readOnly={readOnly}
                    touched={touched}
                    errors={errors}
                    canAdd={state === 'DRAFT' || !!isLiveAndCanAddProduct || noRestrictions}
                    allowEdit={!readOnly && canManageVariants}
                    noRemove={(values.variants?.length || 0) < 3}
                  />
                </FormRow>
                <FormRow>
                  <FormField
                    name="faceValue"
                    currency={currency}
                    label={intl.formatMessage({ id: 'price' })}
                    value={values.faceValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.faceValue && errors.faceValue}
                    disabled={readOnly || !canChangePrice}
                    required
                  />
                  {productCategoryType !== 'MERCH' && !values.hasVariants && (
                    <FormField
                      name="allocation"
                      label={intl.formatMessage({ id: 'new_event.extras.form.allocation.label' })}
                      type="number"
                      min={0}
                      step={1}
                      value={isNil(values.allocation) ? '' : values.allocation}
                      onChange={handleChangeAllocation}
                      onBlur={handleBlur}
                      error={touched.allocation && errors.allocation}
                      disabled={
                        readOnly ||
                        (state !== 'DRAFT' &&
                          !!eventIdLive &&
                          !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
                          !noRestrictions)
                      }
                      required
                    />
                  )}
                </FormRow>
                {productCategoryType === 'MERCH' && !values.hasVariants && (
                  <FormRow>
                    <FormField
                      name="allocation"
                      label={intl.formatMessage({ id: 'new_event.extras.form.allocation.label' })}
                      type="number"
                      min={0}
                      step={1}
                      value={isNil(values.allocation) ? '' : values.allocation}
                      onChange={handleChangeAllocation}
                      onBlur={handleBlur}
                      error={touched.allocation && errors.allocation}
                      disabled={
                        readOnly ||
                        (state !== 'DRAFT' &&
                          !!eventIdLive &&
                          !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
                          !noRestrictions)
                      }
                      required
                    />
                    <FormField
                      name="sku"
                      label={intl.formatMessage({ id: 'new_event.extras.form.sku.label' })}
                      onChange={handleChange}
                      value={values.sku || ''}
                      onBlur={handleBlur}
                      disabled={
                        readOnly ||
                        (state !== 'DRAFT' &&
                          !!eventIdLive &&
                          !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
                          !noRestrictions)
                      }
                    />
                  </FormRow>
                )}
                <FormRow>
                  <AlertBox fullWidth icon="bulb">
                    <span>
                      {intl.formatMessage(
                        { id: `new_event.${productType}.form.allocation.info` },
                        {
                          b: (str: string) => <strong>{str}</strong>,
                        }
                      )}
                    </span>
                  </AlertBox>
                </FormRow>
                {user.diceStaff && showOverrideFees && (
                  <>
                    <Collapsible label={intl.formatMessage({ id: 'fees' })} initialCollapsed dataId="ticketsFees" dice>
                      {contract && contract.length > 0 && canEditFees && (
                        <FormRow>
                          <FormGroup label={intl.formatMessage({ id: 'new_event.tickets.contract_fees' })}>
                            <EventFeeContractPreview contract={contract} currency={currency || 'GBP'} />
                          </FormGroup>
                        </FormRow>
                      )}

                      {((values.fees && values.fees.length > 0) || canEditFees) && (
                        <FormRow>
                          <FormGroup
                            label={`${intl.formatMessage({ id: 'extras' })} ${intl.formatMessage({ id: 'fees' })}`}
                          >
                            <EventOverrideProductFees
                              contract={contract}
                              currency={currency}
                              fees={values.fees}
                              name="fees"
                              readOnly={!canEditFees}
                              formikContext={formik}
                            />
                          </FormGroup>
                        </FormRow>
                      )}
                    </Collapsible>
                    <FormRow />
                  </>
                )}
                <FormRow>
                  {take(1, priceBreakdowns).map((priceBreakdown, idx) => (
                    <PriceInfo key={idx} isLoading={loading}>
                      <PriceBreakdown>
                        <div>
                          {intl.formatMessage({ id: 'price' })}
                          {': '}
                          {intl.formatNumber(
                            (priceBreakdown?.faceValue || 0) / 100,
                            CURRENCY(priceBreakdown?.faceValue || 0, currency)
                          )}
                          {(priceBreakdown?.vatRate || 0) > 0 && (
                            <>
                              &nbsp;
                              {intl.formatMessage(
                                { id: 'new_event.extras.form.pricing.price_inc_vat' },
                                { rate: priceBreakdown?.vatRate }
                              )}
                            </>
                          )}
                        </div>
                        <div>
                          {intl.formatMessage({ id: 'fees' })}
                          {': '}
                          <FeeBreakdownTooltip
                            trigger={intl.formatNumber(
                              (priceBreakdowns[0]?.fees || 0) / 100,
                              CURRENCY(priceBreakdowns[0]?.fees || 0, currency)
                            )}
                            currency={currency}
                            priceBreakdown={priceBreakdowns[0]}
                            hasRebate={hasRebate}
                          />
                        </div>
                        <div>
                          {intl.formatMessage({ id: 'fan_price' })}
                          {': '}
                          {intl.formatNumber(
                            (priceBreakdown?.total || 0) / 100,
                            CURRENCY(priceBreakdown?.total || 0, currency)
                          )}
                        </div>
                      </PriceBreakdown>
                      <PotentialRevenue>
                        {intl.formatMessage({
                          id: 'new_event.tickets.ticket_type_edit.price_suggestion.potential_revenue',
                        })}
                        {':'}
                        <span>{intl.formatNumber(potentialRevenue / 100, CURRENCY(potentialRevenue, currency))}</span>
                      </PotentialRevenue>
                    </PriceInfo>
                  ))}
                </FormRow>
                <MarginH3 className="mb-sm">
                  {intl.formatMessage({ id: `new_event.${productType}.form.how_and_when.header` })}
                </MarginH3>
                <Small className="block color-darkgrey mb-lg">
                  {intl.formatMessage({ id: `new_event.${productType}.form.availability.hint` })}
                </Small>
                <FormRow>
                  <div>
                    <strong className="block mb-xs mb-md">
                      {intl.formatMessage({ id: `new_event.${productType}.form.tickets.label` })}
                    </strong>
                    <Radio
                      name="linkTo"
                      checked={values.linkTo === 'ALL'}
                      value="ALL"
                      label={intl.formatMessage({ id: 'new_event.extras.form.tickets.options.all_tickets' })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={readOnly}
                    />
                    <div className="mt-sm"></div>
                    <Radio
                      name="linkTo"
                      checked={values.linkTo === 'CUSTOM'}
                      value="CUSTOM"
                      label={intl.formatMessage({ id: 'new_event.extras.form.tickets.options.custom' })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={readOnly}
                    />
                    {values.linkTo === 'CUSTOM' && (
                      <EventProductTicketTypes
                        currency={currency}
                        ticketTypes={ticketTypes as IEventFormExtras['ticketTypes']}
                        productTty={values.ticketTypes}
                        onSelect={linkTicketTypes}
                        readOnly={readOnly}
                        hasError={!!(touched.ticketTypes && errors.ticketTypes)}
                      />
                    )}
                  </div>
                </FormRow>
                <strong className="block mt-lg mb-md">
                  {intl.formatMessage({ id: `new_event.${productType}.form.timeline.label` })}
                </strong>
                <Radio
                  name="saleDate"
                  checked={values.saleDate === 'DEFAULT'}
                  value="DEFAULT"
                  label={intl.formatMessage({ id: 'new_event.extras.form.tickets_timeline.options.default' })}
                  disabled={readOnly}
                  onChange={handleSaleDateChange}
                  onBlur={handleBlur}
                />
                <div className="mt-sm"></div>
                <Radio
                  name="saleDate"
                  checked={values.saleDate === 'CUSTOM'}
                  value="CUSTOM"
                  label={intl.formatMessage({ id: 'new_event.extras.form.timeline.options.custom' })}
                  disabled={readOnly}
                  onChange={handleSaleDateChange}
                  onBlur={handleBlur}
                />
                {values.saleDate === 'CUSTOM' && (
                  <PaddedForm>
                    <FormRow columnOnMobile>
                      <FormField
                        name="onSaleDate"
                        timezone={timezoneName || undefined}
                        placeholder={intl.formatMessage({
                          id: 'new_event.extras.form.timeline.custom.on_sale.placeholder',
                        })}
                        label={intl.formatMessage({ id: 'new_event.extras.form.timeline.custom.on_sale.label' })}
                        control="datetime"
                        value={values.onSaleDate}
                        error={touched.onSaleDate && errors.onSaleDate}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        locale={locale}
                        disabled={readOnly}
                        required
                      />
                      <FormField
                        name="offSaleDate"
                        timezone={timezoneName || undefined}
                        placeholder={intl.formatMessage({
                          id: 'new_event.extras.form.timeline.custom.off_sale.placeholder',
                        })}
                        label={intl.formatMessage({ id: 'new_event.extras.form.timeline.custom.off_sale.label' })}
                        control="datetime"
                        value={values.offSaleDate}
                        error={touched.offSaleDate && errors.offSaleDate}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        locale={locale}
                        disabled={readOnly}
                        required
                      />
                    </FormRow>
                  </PaddedForm>
                )}

                <Divider />

                <MarginH3 className="mb-md">
                  {intl.formatMessage({ id: `new_event.${productType}.form.redeem.header` })}
                </MarginH3>
                <FormRow>
                  <AlertBox fullWidth icon="bulb">
                    <span>
                      {intl.formatMessage(
                        { id: `new_event.${productType}.form.purchase_confirmation.hint` },
                        {
                          a: (str: string) => (
                            <SLink href={userGuideLink} target="_blank" rel="noopener noreferrer">
                              {str}
                            </SLink>
                          ),
                        }
                      )}
                    </span>
                  </AlertBox>
                </FormRow>

                <FormRow>
                  <div>
                    {productCategoryType !== 'MERCH' && (
                      <FormRow columnOnMobile>
                        <TabMenu>
                          <TabMenuItem
                            onClick={toggleVenue}
                            value="default"
                            active={values.sameVenue}
                            disabled={readOnly || redeemSectionDisabled}
                          >
                            {intl.formatMessage({ id: 'new_event.extras.form.venue_options.default' })}
                          </TabMenuItem>
                          <TabMenuItem
                            onClick={toggleVenue}
                            value="custom"
                            active={!values.sameVenue}
                            disabled={readOnly || redeemSectionDisabled}
                          >
                            {intl.formatMessage({ id: 'new_event.extras.form.venue_options.custom' })}
                          </TabMenuItem>
                        </TabMenu>
                      </FormRow>
                    )}
                    {values.sameVenue ? (
                      <FormRow columnOnMobile>
                        <FormField
                          name="locationNote"
                          label={intl.formatMessage({ id: `new_event.${productType}.form.location_note.label` })}
                          hint={intl.formatMessage({ id: `new_event.${productType}.form.location_note.hint` })}
                          value={values.locationNote || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.locationNote && errors.locationNote}
                          disabled={readOnly || redeemSectionDisabled}
                        >
                          <Counter maxCount={20} count={(values.locationNote || '').length}>
                            {(values.locationNote || '').length}/{20}
                          </Counter>
                        </FormField>
                      </FormRow>
                    ) : (
                      <FormRow columnOnMobile>
                        {values.venue ? (
                          <EventSelectedVenue
                            error={touched?.primaryVenue && errors?.venue}
                            allowClear={!readOnly && !redeemSectionDisabled}
                            eventType="LIVE"
                            venue={values.venue}
                            doClear={removeVenue}
                            required
                          />
                        ) : (
                          <EventPrimaryVenue
                            allowCreate={false}
                            disabled={readOnly || redeemSectionDisabled}
                            eventType="LIVE"
                            venue={values.venue}
                            setVenue={setVenue}
                            venueLoader={venueLoader}
                            error={touched?.primaryVenue && errors?.venue}
                            onBlur={handleBlur}
                            required
                          />
                        )}
                      </FormRow>
                    )}
                    <FormRow columnOnMobile>
                      <div>
                        <strong className="block mb-md">
                          {intl.formatMessage({ id: 'new_event.extras.form.redeem_timeline.label' })}
                        </strong>
                        <Radio
                          name="sameDates"
                          checked={values.sameDates === 'DEFAULT'}
                          value="DEFAULT"
                          label={intl.formatMessage({ id: 'new_event.extras.form.event_timeline.options.default' })}
                          disabled={readOnly || redeemSectionDisabled}
                          onChange={handleSameDatesChange}
                          onBlur={handleBlur}
                        />
                        <div className="mt-sm"></div>
                        <Radio
                          name="sameDates"
                          checked={values.sameDates === 'CUSTOM'}
                          value="CUSTOM"
                          label={intl.formatMessage({ id: 'new_event.extras.form.timeline.options.custom' })}
                          disabled={readOnly || redeemSectionDisabled}
                          onChange={handleSameDatesChange}
                          onBlur={handleBlur}
                        />
                        {values.sameDates === 'CUSTOM' && (
                          <PaddedForm>
                            <FormRow columnOnMobile>
                              <FormField
                                name="date"
                                timezone={timezoneName || undefined}
                                locale={locale}
                                label={intl.formatMessage({ id: 'new_event.extras.form.date.label' })}
                                control="datetime"
                                value={values.date || (values.sameDates === 'DEFAULT' && date)}
                                onBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                disabled={readOnly || values.sameDates === 'DEFAULT' || redeemSectionDisabled}
                                error={touched.date && errors.date}
                                required
                              />
                              <FormField
                                name="endDate"
                                timezone={timezoneName || undefined}
                                locale={locale}
                                label={intl.formatMessage({ id: 'new_event.extras.form.end_date.label' })}
                                control="datetime"
                                value={values.endDate || (values.sameDates === 'DEFAULT' && endDate)}
                                onBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                disabled={readOnly || values.sameDates === 'DEFAULT' || redeemSectionDisabled}
                                error={touched.endDate && errors.endDate}
                                required
                              />
                            </FormRow>
                          </PaddedForm>
                        )}
                      </div>
                    </FormRow>
                  </div>
                </FormRow>
                {productCategoryType === '_MERCH' && (
                  <FormRow columnOnMobile>
                    <FormField
                      name="fulfilledBy"
                      label={intl.formatMessage({ id: 'new_event.extras.form.fulfilled_by.label' })}
                      value={values.fulfilledBy || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fulfilledBy && errors.fulfilledBy}
                      disabled={readOnly || redeemSectionDisabled}
                      required
                    />
                  </FormRow>
                )}
                <FormRow columnOnMobile>
                  <MarkdownTextarea
                    name="purchaseConfirmationMessage"
                    control={MarkdownEditor}
                    label={intl.formatMessage({ id: `new_event.${productType}.form.purchase_confirmation.label` })}
                    hint={intl.formatMessage({ id: 'new_event.extras.form.purchase_confirmation.help' })}
                    value={values.purchaseConfirmationMessageDraft}
                    onChange={setPurchaseMessageDraft}
                    onBlur={handleBlur}
                    error={(touched.purchaseConfirmationMessage || isExisting) && errors.purchaseConfirmationMessage}
                    disabled={readOnly || redeemSectionDisabled}
                    required
                  >
                    <Counter maxCount={2000} count={(values.purchaseConfirmationMessage || '').length}>
                      {(values.purchaseConfirmationMessage || '').length}/{2000}
                    </Counter>
                  </MarkdownTextarea>
                </FormRow>
                <FormRow columnOnMobile>
                  <CustomToggle>
                    <Svg icon="qr" />
                    <SwitchField
                      label={intl.formatMessage({ id: `new_event.${productType}.form.qr_code.label` })}
                      hint={intl.formatMessage({ id: `new_event.${productType}.form.qr_code.hint` })}
                      name="hasSeparateAccessBarcodes"
                      disabled={readOnly || redeemSectionDisabled}
                      checked={!!values.hasSeparateAccessBarcodes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </CustomToggle>
                </FormRow>
              </>
            )}
          </Form>
        </form>
      </ModalBody>
      {!readOnly && (
        <ModalFooter>
          <ModalFooterControl
            loading={isSubmitting}
            disabled={readOnly || isSubmitting || !isValid}
            onClick={handleSubmit}
          >
            {intl.formatMessage({
              id: isExisting ? 'actions.save_changes' : `new_event.${productType}.form.actions.create_extra`,
            })}
          </ModalFooterControl>
          <ModalFooterControl preset="secondary" loading={isSubmitting} disabled={isSubmitting} onClick={onCancel}>
            {intl.formatMessage({ id: 'actions.cancel' })}
          </ModalFooterControl>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default memo(EventProductModal)
