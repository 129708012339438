import React, { useCallback, useState, FC, memo } from 'react'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Button from '../../../components/Button'
import IconButton from '../../../components/IconButton'
import RemittanceModal from './Modals/RemittanceModal/RemittanceModal'

import { EventPartRemittance_event$key } from '../../../__generated__/EventPartRemittance_event.graphql'

interface IProps {
  event: EventPartRemittance_event$key
  onOpen?: () => void
  simpleTitle?: string
  iconTitle?: string
  disabled?: boolean
}

const EventPartRemittance: FC<React.PropsWithChildren<IProps>> = ({
  event: eventKey,
  simpleTitle,
  iconTitle,
  onOpen,
  disabled,
}) => {
  const event = useFragment(
    graphql`
      fragment EventPartRemittance_event on Event {
        id
        ...RemittanceModal_event
      }
    `,
    eventKey
  )

  const [remittanceModalOpened, setRemittanceModalOpened] = useState(false)
  const toggleRemittanceModal = useCallback(() => {
    setRemittanceModalOpened(!remittanceModalOpened)
    if (onOpen && !remittanceModalOpened) {
      onOpen()
    }
  }, [onOpen, remittanceModalOpened])

  return (
    <>
      {simpleTitle ? (
        <Button preset="link" disabled={!!disabled} onClick={toggleRemittanceModal}>
          {simpleTitle}
        </Button>
      ) : (
        <IconButton icon="remittance" disabled={!!disabled} onClick={toggleRemittanceModal} title={iconTitle} />
      )}
      {remittanceModalOpened && <RemittanceModal event={event} onClose={toggleRemittanceModal} />}
    </>
  )
}

export default memo(EventPartRemittance)
