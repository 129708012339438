import React, { FC, memo, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { DiceBadgeOverlay } from '../../../../components/DiceBadge'

import { authContext } from '../../../../context/auth'
import { IFactory, preload, SIDEBAR_ROUTER } from '../../services/sidebarNav'
import { featureFlagsContext } from '../../../../context/featureFlags'
import { SidebarList, SidebarListName, SidebarMenu, SidebarMenuIcon, SidebarMenuItem } from './Styles'

const Tools: FC = () => {
  const { pathname, search } = useLocation()
  const intl = useIntl()
  const { user, account, hasPermission } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)

  const allowIntegrations =
    (account &&
      ((account.countryCode === 'FR' && hasPermission('nf525:balances')) ||
        hasPermission(['update:account', 'create_facebook:marketing']) ||
        hasPermission(['update:account']) ||
        hasPermission(['mailchimp:marketing']))) ||
    hasPermission('allow_third_party_access:account')

  const shouldReloadSupport = useMemo(() => pathname + search === SIDEBAR_ROUTER.support[0], [pathname, search])

  return (
    <SidebarList>
      <SidebarListName>
        <span>{intl.formatMessage({ id: 'sidebar.tools' })}</span>
      </SidebarListName>
      <SidebarMenu>
        {allowIntegrations && (
          <SidebarMenuItem
            active={pathname === SIDEBAR_ROUTER.tools.integrations[0]}
            to={SIDEBAR_ROUTER.tools.integrations[0]}
            data-id="sidebarIntegrations"
            onMouseEnter={preload(SIDEBAR_ROUTER.tools.integrations[1] as IFactory)}
          >
            <SidebarMenuIcon icon="archive" />
            <span>{intl.formatMessage({ id: 'sidebar.integrations' })}</span>
          </SidebarMenuItem>
        )}
        {hasPermission('read:linkout') && (
          <SidebarMenuItem
            active={pathname && pathname.startsWith(SIDEBAR_ROUTER.linkouts[0] as string)}
            to={SIDEBAR_ROUTER.linkouts[0]}
            data-id="sidebarLinkouts"
            onMouseEnter={preload(SIDEBAR_ROUTER.linkouts[1] as IFactory)}
          >
            <SidebarMenuIcon icon="link" />
            <span>{intl.formatMessage({ id: 'sidebar.website_widget' })}</span>
          </SidebarMenuItem>
        )}
        {hasPermission('connect_via_email:fan') && (
          <SidebarMenuItem
            active={pathname === SIDEBAR_ROUTER.tools.fanConnect[0]}
            to={SIDEBAR_ROUTER.tools.fanConnect[0]}
            data-id="sidebarConnect"
            onMouseEnter={preload(SIDEBAR_ROUTER.tools.fanConnect[1] as IFactory)}
          >
            <SidebarMenuIcon icon="letter" />
            <span>{intl.formatMessage({ id: 'sidebar.fan_connect' })}</span>
          </SidebarMenuItem>
        )}
        {(user.diceStaff || (!!account && hasPermission('manage_users:account'))) && (
          <SidebarMenuItem
            active={
              pathname === SIDEBAR_ROUTER.tools.users[0] ||
              pathname === SIDEBAR_ROUTER.tools.usersInviteUser[0] ||
              pathname === SIDEBAR_ROUTER.tools.permissions[0] ||
              pathname === SIDEBAR_ROUTER.tools.permissionsNewProfile[0]
            }
            to={SIDEBAR_ROUTER.tools.users[0]}
            data-id="sidebarUsersAndPermissions"
            onMouseEnter={preload(SIDEBAR_ROUTER.tools.users[1] as IFactory)}
          >
            <SidebarMenuIcon icon="user" />
            <span>{intl.formatMessage({ id: 'sidebar.users' })}</span>
          </SidebarMenuItem>
        )}
        {hasPermission('read:featured_area') && (
          <SidebarMenuItem
            active={pathname === SIDEBAR_ROUTER.tools.featured[0]}
            to={SIDEBAR_ROUTER.tools.featured[0]}
            data-id="sidebarFeatured"
            onMouseEnter={preload(SIDEBAR_ROUTER.tools.featured[1] as IFactory)}
          >
            <DiceBadgeOverlay>
              <SidebarMenuIcon icon="featured" />
            </DiceBadgeOverlay>
            <span>{intl.formatMessage({ id: 'sidebar.featured' })}</span>
          </SidebarMenuItem>
        )}
        {user.diceStaff && (
          <SidebarMenuItem
            active={pathname === SIDEBAR_ROUTER.tools.hierarchy[0] || pathname === SIDEBAR_ROUTER.tools.tags[0]}
            to={SIDEBAR_ROUTER.tools.hierarchy[0]}
            data-id="sidebarTagsAndHierarchy"
            onMouseEnter={preload(SIDEBAR_ROUTER.tools.hierarchy[1] as IFactory)}
          >
            <DiceBadgeOverlay>
              <SidebarMenuIcon icon="tag" />
            </DiceBadgeOverlay>
            <span>{intl.formatMessage({ id: 'sidebar.tags' })}</span>
          </SidebarMenuItem>
        )}
        {user.diceStaff && (
          <SidebarMenuItem
            active={pathname === SIDEBAR_ROUTER.tools.qr[0]}
            to={SIDEBAR_ROUTER.tools.qr[0]}
            data-id="sidebarQR"
            onMouseEnter={preload(SIDEBAR_ROUTER.tools.qr[1] as IFactory)}
          >
            <DiceBadgeOverlay>
              <SidebarMenuIcon icon="qr" />
            </DiceBadgeOverlay>
            <span>{intl.formatMessage({ id: 'sidebar.qr' })}</span>
          </SidebarMenuItem>
        )}
        {user.diceStaff && hasFeatureFlag('devSettings') && (
          <SidebarMenuItem
            active={pathname === SIDEBAR_ROUTER.tools.inspector[0]}
            to={SIDEBAR_ROUTER.tools.inspector[0]}
            data-id="sidebarPlatformInspector"
            onMouseEnter={preload(SIDEBAR_ROUTER.tools.inspector[1] as IFactory)}
          >
            <DiceBadgeOverlay>
              <SidebarMenuIcon icon="bulb" />
            </DiceBadgeOverlay>
            <span>{intl.formatMessage({ id: 'sidebar.inspector' })}</span>
          </SidebarMenuItem>
        )}
        <SidebarMenuItem
          shouldReload={shouldReloadSupport}
          active={pathname === SIDEBAR_ROUTER.support[0]}
          to={SIDEBAR_ROUTER.support[0]}
          data-id="sidebarHelpCentre"
          onMouseEnter={preload(SIDEBAR_ROUTER.support[1] as IFactory)}
        >
          <SidebarMenuIcon icon="info" />
          <span>{intl.formatMessage({ id: 'sidebar.help_centre' })}</span>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarList>
  )
}

export default memo(Tools)
