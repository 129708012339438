import React, { FC, memo, useContext } from 'react'
import { useIntl } from 'react-intl'

import { useLocation } from 'react-router'
import { PROMOTER_SETTINGS_SIDEBAR_ROUTER } from '../services/promoterSettingsSidebarNav'
import { SidebarMenuIcon, SidebarMenuItem } from '../../Sidebar/components/legacy/Styles'

import CollapsibleSidebarList from '../../Sidebar/components/CollapsibleSidebarList'
import { authContext } from '../../../context/auth'
import { IFactory, preload } from '../../Sidebar/services/sidebarNav'

const PromoterSettings: FC = () => {
  const intl = useIntl()
  const { pathname } = useLocation()

  const { account, hasPermission } = useContext(authContext)

  const allowIntegrations =
    (account &&
      ((account.countryCode === 'FR' && hasPermission('nf525:balances')) ||
        hasPermission(['update:account', 'create_facebook:marketing']) ||
        hasPermission(['update:account']) ||
        hasPermission(['mailchimp:marketing']))) ||
    hasPermission('allow_third_party_access:account')

  return (
    <CollapsibleSidebarList
      title={account?.name ?? intl.formatMessage({ id: 'promoter_settings_sidebar.promoter_details' })}
    >
      <SidebarMenuItem
        active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.promoterDetails[0] as string)}
        to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.promoterDetails[0]}
        onMouseEnter={preload(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.promoterDetails[1] as IFactory)}
        data-id="settingsSidebarPromoterDetails"
      >
        <SidebarMenuIcon icon="promoter" />
        <span>{intl.formatMessage({ id: 'promoter_settings_sidebar.promoter_details' })}</span>
      </SidebarMenuItem>
      <SidebarMenuItem
        active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.bankDetails[0] as string)}
        to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.bankDetails[0]}
        onMouseEnter={preload(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.bankDetails[1] as IFactory)}
        data-id="settingsSidebarBankDetails"
      >
        <SidebarMenuIcon icon="coins" />
        <span>{intl.formatMessage({ id: 'promoter_settings_sidebar.bank_details' })}</span>
      </SidebarMenuItem>
      {hasPermission('read_attractive_fiscal_info:event') && (
        <SidebarMenuItem
          active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.nts[0] as string)}
          to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.nts[0]}
          data-id="sidebarNts"
          onMouseEnter={preload(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.nts[1] as IFactory)}
        >
          <SidebarMenuIcon icon="tick" />
          <span>{intl.formatMessage({ id: 'sidebar.nts_fiscal_info' })}</span>
        </SidebarMenuItem>
      )}

      {!!account && hasPermission('manage_users:account') && (
        <>
          <SidebarMenuItem
            active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.users[0] as string)}
            to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.users[0]}
            data-id="settingsSidebarUsers"
          >
            <SidebarMenuIcon icon="user-alt" />
            <span>{intl.formatMessage({ id: 'promoter_settings_sidebar.users' })}</span>
          </SidebarMenuItem>
          <SidebarMenuItem
            active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.permissions[0] as string)}
            to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.permissions[0]}
            data-id="settingsSidebarPermissions"
          >
            <SidebarMenuIcon icon="privacy" />
            <span>{intl.formatMessage({ id: 'promoter_settings_sidebar.permissions' })}</span>
          </SidebarMenuItem>
        </>
      )}

      {allowIntegrations && (
        <SidebarMenuItem
          active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.integrations[0] as string)}
          to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.integrations[0]}
          onMouseEnter={preload(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.integrations[1] as IFactory)}
          data-id="settingsSidebarIntegrations"
        >
          <SidebarMenuIcon icon="settings" />
          <span>{intl.formatMessage({ id: 'sidebar.integrations' })}</span>
        </SidebarMenuItem>
      )}

      <SidebarMenuItem
        active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.venues[0] as string)}
        to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.venues[0]}
        onMouseEnter={preload(PROMOTER_SETTINGS_SIDEBAR_ROUTER.promoter.venues[1] as IFactory)}
        data-id="settingsSidebarVenues"
      >
        <SidebarMenuIcon icon="venue" />
        <span>{intl.formatMessage({ id: 'sidebar.venues' })}</span>
      </SidebarMenuItem>
    </CollapsibleSidebarList>
  )
}

export default memo(PromoterSettings)
