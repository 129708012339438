import React, { FC, memo, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { compact, concat, get, without } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components/macro'

import { Col, FormRow } from './Form'
import FormField, { FlexFormField } from './FormField'
import IconButton from './IconButton'
import { breakpoints, mediaQuery } from '../utils/variables'
import EmptyList from './EmptyList'
import ListAddButton from './ListAddButton'

const SlimFormRow = styled(FormRow)`
  margin-top: 16px;

  ${mediaQuery.lessThan('tablet')`
      margin-top: 32px;
  `}

  && + && {
    margin-top: 8px;

    ${mediaQuery.lessThan('tablet')`
      margin-top: 32px;
    `}
  }

  ${Col} + ${Col} {
    margin: 0 0 0 8px;
    flex: 0.6;
  }

  ${mediaQuery.lessThan('tablet')`
    ${Col} + ${Col} {
      margin-top: 16px;
    }
  `}
`

const AddRow = styled(FormRow)<{ canUpdate: boolean }>`
  ${SlimFormRow} + && {
    margin-top: 8px;
    ${mediaQuery.lessThan('tablet')`
      margin-top: 16px;
    `}
  }
  padding-top: ${({ canUpdate }) => (canUpdate ? '16px' : '0')};
  min-height: 32px;
`

interface IProps {
  readOnly?: boolean
  canUpdate?: boolean
  emptyMessage?: string
  className?: string
}

const LinksForm: FC<React.PropsWithChildren<IProps>> = ({ className, readOnly, canUpdate, emptyMessage }) => {
  const intl = useIntl()

  const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched, validateForm } =
    useFormikContext<{
      links: Array<{
        name: string | null
        url: string | null
        isNew?: boolean
      } | null> | null
    }>()

  const links = useMemo(() => compact(values.links || []), [values.links])
  const addLink = useCallback(() => {
    setFieldValue('links', concat(links, { name: null, url: null, isNew: true }))

    setTimeout(() => {
      const input = document.querySelector(`input[data-linkidx="${links.length}"]`)
      if (input) {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(input as HTMLInputElement).focus()
      }
    }, 100)
  }, [links, setFieldValue])

  const removeLink = useCallback(
    (e: any) => {
      const idx = e.currentTarget.dataset['idx']
      const artist = links[idx]
      setFieldValue('links', without([artist], links))
      setFieldTouched('links', true, true)

      setTimeout(() => validateForm(), 0)
    },
    [links, setFieldTouched, setFieldValue, validateForm]
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const noLinks = !links || links.length === 0

  return (
    <div className={className}>
      {links.map((link, idx) => (
        <SlimFormRow columnOnMobile key={idx}>
          <FormField
            name={`links[${idx}].url`}
            data-linkidx={idx}
            label={idx === 0 || isMobile ? intl.formatMessage({ id: 'new_event.settings.links.link_url.label' }) : null}
            value={link.url || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={get(['links', idx, 'url'], touched) && get(['links', idx, 'url'], errors)}
            disabled={readOnly || (!canUpdate && !get(['links', idx, 'isNew'], values))}
            required
          />
          <div>
            <FlexFormField
              name={`links[${idx}].name`}
              label={
                idx === 0 || isMobile ? intl.formatMessage({ id: 'new_event.settings.links.link_name.label' }) : null
              }
              value={link.name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={readOnly || (!canUpdate && !get(['links', idx, 'isNew'], values))}
              error={get(['links', idx, 'name'], touched) && get(['links', idx, 'name'], errors)}
              required
            >
              <IconButton
                data-idx={idx}
                icon="trash"
                onClick={removeLink}
                disabled={readOnly || (!canUpdate && !get(['links', idx, 'isNew'], values))}
              />
            </FlexFormField>
          </div>
        </SlimFormRow>
      ))}
      {!readOnly && canUpdate && (
        <AddRow columnOnMobile canUpdate={!noLinks}>
          {noLinks ? (
            <EmptyList
              icon="link"
              cta={intl.formatMessage({ id: 'new_event.settings.links.add_button' })}
              title={intl.formatMessage({ id: 'new_event.settings.links.empty_title' })}
              subTitle={emptyMessage}
              hasError={touched.links && !!errors.links}
              onAdd={addLink}
              disabled={readOnly}
            />
          ) : (
            <ListAddButton
              label={intl.formatMessage({ id: 'new_event.settings.links.add_button' })}
              onClick={addLink}
            />
          )}
        </AddRow>
      )}
    </div>
  )
}

export default memo(LinksForm)
