import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { useMediaQuery } from 'react-responsive'
import { Card, EventName } from '../../../EventList/components/EventListItemGrid'
import EventPartDateTime from '../../../EventList/components/EventPartDateTime'

import { Cell, Row } from '../../../../components/Table'

import EventPartImage from '../../../EventList/components/EventPartImage'

import { EventAbbonamentoPickerTableRow_event$key } from '../../../../__generated__/EventAbbonamentoPickerTableRow_event.graphql'
import EventPartStatus from '../../../EventList/components/EventPartStatus'
import Checkbox from '../../../../components/Checkbox'
import { IAbbonamentoEvent } from '../../types/Timeline'
import { DisablableAbbonamentoRow, EventInfoContainer } from './EventAbbonamentoTableStyles'
import { breakpoints } from '../../../../utils/variables'

interface IProps {
  event: EventAbbonamentoPickerTableRow_event$key
  selected: boolean
  onSelect: (event: IAbbonamentoEvent) => void
  disabled?: boolean
}

const EventAbbonamentoPickerTableRow: FC<React.PropsWithChildren<IProps>> = ({
  event: eventKey,
  selected,
  onSelect,
  disabled,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const event = useFragment(
    graphql`
      fragment EventAbbonamentoPickerTableRow_event on Event {
        id
        name
        date
        endDate
        timezoneName
        primaryVenue {
          name
        }
        attractiveFields {
          forceSubscription
        }

        ...EventPartImage_event
        ...EventPartStatus_event
      }
    `,
    eventKey
  )

  const preventClick = useCallback((e: any) => e.stopPropagation(), [])

  const onCheckboxChange = useCallback(() => {
    onSelect(event)
  }, [event, onSelect])

  return (
    event && (
      <DisablableAbbonamentoRow disabled={disabled}>
        <Cell className="-narrow">
          <Checkbox
            name="event"
            checked={selected}
            disabled={disabled}
            onChange={onCheckboxChange}
            aria-label="Select event"
          />
        </Cell>
        <Cell textAlign="left">
          <EventInfoContainer>
            {!isMobile && <EventPartImage showHidden event={event} />}
            <Card onClick={preventClick}>
              <EventName as="div" title={event.name || undefined}>
                {event.name}
              </EventName>
              <EventPartStatus showHidden event={event} />
              {isMobile && (
                <div className="mobile-date">
                  <EventPartDateTime date={event.date} endDate={event.endDate} timezoneName={event.timezoneName} />
                </div>
              )}
            </Card>
          </EventInfoContainer>
        </Cell>
        {!isMobile && (
          <>
            <Cell>
              <EventPartDateTime date={event.date} endDate={event.endDate} timezoneName={event.timezoneName} />
            </Cell>
            <Cell>{event.primaryVenue?.name}</Cell>
          </>
        )}
      </DisablableAbbonamentoRow>
    )
  )
}

export default EventAbbonamentoPickerTableRow
