/**
 * @generated SignedSource<<e1b89e9b45825e492aaf58c272857546>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventGuests_viewer$data = {
  readonly permissionProfiles: ReadonlyArray<{
    readonly caption: string;
    readonly diceStaff: boolean;
    readonly roleName: string;
    readonly value: string;
  } | null> | null;
  readonly " $fragmentType": "EventGuests_viewer";
};
export type EventGuests_viewer$key = {
  readonly " $data"?: EventGuests_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventGuests_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventGuests_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PermissionProfile",
      "kind": "LinkedField",
      "name": "permissionProfiles",
      "plural": true,
      "selections": [
        {
          "alias": "value",
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "caption",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "diceStaff",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c407457640609d477449e30a9fac8c67";

export default node;
