export const COLOR_SCHEME = {
  event: {
    views: '#F36967',
  },
  tickets: {
    faceValue: '#FFB067',
    sold: '#A375F3',
  },
  extras: {
    faceValue: '#106E6A',
    sold: '#102C4B',
  },
  merch: {
    faceValue: '#2AE5B2',
    sold: '#75D0EE',
  },
} as const
