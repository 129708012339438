import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { Outlet, useLocation } from 'react-router'
import graphql from 'babel-plugin-relay/macro'
import { getOr } from 'lodash/fp'
import styled, { keyframes } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import ClassList from 'classlist'

import AuthProvider, { authContext } from '../context/auth'
import FeatureFlagsProvider from '../context/featureFlags'
import { localeContext } from '../context/locale'
import MapsProvider from '../context/maps'
import TrackingProvider, { DicePageViewTracker } from '../context/tracking'
import { LOCALE_MAPPING, RELEASED_LOCALES } from '../intl'

import GenericError from '../components/GenericError'

import { MainLayoutQuery } from '../__generated__/MainLayoutQuery.graphql'
import { color, mediaQuery, zIndex } from '../utils/variables'
import ImpersonationBanner from './components/ImpersonationBanner'

const rotate = keyframes`
  50% {
    transform: rotateY(180deg);
  }
`

const Maintenance = styled(GenericError)`
  svg {
    animation: ${rotate} 10s infinite cubic-bezier(0.9, 0, 0.1, 1);
  }
`

const ImpersonationFrame = styled.div<{ isActive?: boolean }>`
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid ${color.primary};
    z-index: ${zIndex.max};
    pointer-events: none;

    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  }

  display: flex;
  min-height: 100%;
  width: 100%;

  & > * {
    width: 100%;
  }

  padding: ${({ isActive }) => (isActive ? '62px 4px 4px 4px' : '0')};

  ${mediaQuery.lessThan<{ isActive?: boolean }>('tablet')`
    padding: ${({ isActive }) => (isActive ? '4px 4px 4px 4px' : '0')};
  `}
`

interface IProps {
  onUserChange: () => void
}

const ImpersonatedOutlet = () => {
  const { isImpersonated } = useContext(authContext)

  useEffect(() => {
    const bodyClasses = ClassList(document.body)
    if (isImpersonated) {
      bodyClasses.add('-impersonating')
    } else {
      bodyClasses.remove('-impersonating')
    }

    return () => {
      bodyClasses.remove('-impersonating')
    }
  }, [isImpersonated])

  return (
    <ImpersonationFrame isActive={isImpersonated}>
      {isImpersonated && <ImpersonationBanner />}
      <Outlet />
    </ImpersonationFrame>
  )
}

const MainLayout: FC<IProps> = ({ onUserChange }) => {
  const { viewer, featureFlags } = useLazyLoadQuery<MainLayoutQuery>(
    graphql`
      query MainLayoutQuery {
        featureFlags {
          ticketPools
          diceSplitInPaymentMethods
          devSettings
          socialLinkExperiment
          eventsCollection
        }
        viewer {
          ...auth_viewer
          diceStaff
          underMaintenance
          preferredLanguage
          mioRedesignV2
        }
      }
    `,
    {}
  )

  const intl = useIntl()
  const { setLocale } = useContext(localeContext)
  const [readyForTracking, setReadyForTracking] = useState(false)

  useEffect(() => {
    if (viewer) {
      // Do not trigger readyForTracking if viewer is not loaded yet!
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const newLocale = getOr(LOCALE_MAPPING.EN, viewer.preferredLanguage || 'EN', LOCALE_MAPPING)!
      if (RELEASED_LOCALES.has(newLocale) || newLocale || viewer.diceStaff) {
        setLocale(newLocale)
      }
      setReadyForTracking(true)
    }
  }, [viewer, setLocale])

  const location = useLocation()

  useEffect(() => {
    if (location.pathname) window.scrollTo(0, 0)
  }, [location.pathname])

  if (!viewer) return null

  if (viewer.underMaintenance) {
    return (
      <Maintenance
        icon="fan"
        title={intl.formatMessage({ id: 'generic_error.maintenance_title' })}
        description={intl.formatMessage({ id: 'generic_error.maintenance_description_sales_affected' })}
      />
    )
  }

  return (
    <MapsProvider>
      <AuthProvider viewer={viewer} onUserChange={onUserChange}>
        <TrackingProvider readyForTracking={readyForTracking}>
          <FeatureFlagsProvider flags={featureFlags}>
            <DicePageViewTracker key={location.key} />
            <ImpersonatedOutlet />
          </FeatureFlagsProvider>
        </TrackingProvider>
      </AuthProvider>
    </MapsProvider>
  )
}

export default MainLayout
