import React, { FC, memo, useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { compact, concat, find, get, map, unset, update, without } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay'

import { Col, Form, FormRow } from '../../../components/Form'
import FormGroup from '../../../components/FormGroup'
import IEventFormBasics from '../types/Basics'
import { mediaQuery } from '../../../utils/variables'
import IconButton from '../../../components/IconButton'
import FormField, { FlexFormField } from '../../../components/FormField'
import { markAsClientOnly } from '../../../utils/entityStatus'
import graphqlOptionsLoader from '../../../utils/graphqlOptionsLoader'
import subscriptionCode from '../utils/subscriptionCode'
import { localeContext } from '../../../context/locale'
import { EventVenueSchedulePreloadQuery } from '../../../__generated__/EventVenueSchedulePreloadQuery.graphql'
import IEventFormTickets from '../types/Tickets'
import EmptyList from '../../../components/EmptyList'
import ListAddButton from '../../../components/ListAddButton'

const StyledForm = styled(Form)`
  padding-top: 8px;

  ${mediaQuery.lessThan('tablet')`
    ${FormRow} + ${FormRow} {
      margin-top: 32px;
    }

    ${Col} + ${Col} {
      margin-top: 8px;
    }
  `}
`

const Spacer = styled.div`
  padding: 8px;
`

type IScheduleItem = NonNullable<NonNullable<IEventFormBasics['venueSchedules']>[number]>

interface IItemRow {
  item: IScheduleItem
  idx: number
  readOnly?: boolean
  onRemove: (idx: number) => void
  timezoneName: string | null | undefined
}

const VenueScheduleItem: FC<React.PropsWithChildren<IItemRow>> = ({ item, idx, readOnly, onRemove, timezoneName }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const { touched, errors, handleChange, handleBlur, setFieldValue } = useFormikContext<IEventFormBasics>()

  const removeItem = useCallback(() => {
    onRemove(idx)
  }, [idx, onRemove])

  const venueLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query EventVenueScheduleVenueQuery($searchTerm: String) {
            viewer {
              options: venues(searchTerm: $searchTerm, first: 50) {
                edges {
                  node {
                    value: id
                    label: name
                    hint: fullAddress

                    configurations {
                      value: id
                      label: name
                      attractiveRoomSiaeCode
                      capacity

                      seatingAreaConfigs {
                        capacity
                        seatingArea
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        { fullText: true }
      ),
    [environment]
  )

  const { venue: preloadedVenue } = useLazyLoadQuery<EventVenueSchedulePreloadQuery>(
    graphql`
      query EventVenueSchedulePreloadQuery($id: ID!) {
        venue: node(id: $id) {
          ... on Venue {
            value: id
            label: name

            configurations {
              value: id
              label: name
              attractiveRoomSiaeCode
              capacity

              seatingAreaConfigs {
                capacity
                seatingArea
              }
            }
          }
        }
      }
    `,
    {
      id: item.venueId || 'oops',
    },
    {
      fetchPolicy: item.venueId ? 'store-or-network' : 'store-only',
    }
  )

  const [venueOption, setVenueOption] = useState<null | {
    value: string
    label: string
    configurations: { value: string; label: string }[]
    fake?: boolean
  }>(
    // prettier-ignore
    item.venueId
      ? {
        value: item.venueId,
        label: `??? [${item.venueId}]`,
        configurations: [],
        fake: true,
      }
      : null
  )

  useEffect(() => {
    if (venueOption?.fake && item.venueId && preloadedVenue?.value && preloadedVenue?.label) {
      setVenueOption(preloadedVenue as any)
    }
  }, [item.venueId, preloadedVenue, venueOption?.fake])

  const handleVenueChange = useCallback(
    (id: any, opt: any) => {
      setFieldValue(`venueSchedules[${idx}].venueId`, id, true)
      setVenueOption(unset('hint', opt))
    },
    [idx, setFieldValue]
  )

  const venueConfigurationOptions = useMemo(() => venueOption?.configurations || [], [venueOption?.configurations])
  const venueConfigurationOption = useMemo(() => {
    if (!item.venueConfigurationId) return null
    return (
      find(['value', item.venueConfigurationId], venueConfigurationOptions) || {
        value: item.venueConfigurationId,
        label: `??? [${item.venueConfigurationId}]`,
      }
    )
  }, [item.venueConfigurationId, venueConfigurationOptions])

  const handleVenueConfigurationChange = useCallback(
    (id: any) => setFieldValue(`venueSchedules[${idx}].venueConfigurationId`, id, true),
    [idx, setFieldValue]
  )

  return (
    <>
      {idx > 0 && (
        <FormRow>
          <Spacer />
        </FormRow>
      )}
      <FormRow columnOnMobile data-id={`venueSchedulesRowOne[${item.id}]`}>
        <FlexFormField
          label={intl.formatMessage({ id: 'new_event.basics.venue_schedule.name.label' })}
          placeholder={intl.formatMessage({ id: 'new_event.basics.venue_schedule.name.label' })}
          required
          name={`venueSchedules[${idx}].name`}
          value={item.name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={get(`venueSchedules[${idx}].name`, touched) && get(`venueSchedules[${idx}].name`, errors)}
          disabled={readOnly}
        >
          <IconButton data-id="removeItemButton" icon="trash" onClick={removeItem} disabled={readOnly} />
        </FlexFormField>
      </FormRow>
      <FormRow columnOnMobile data-id={`venueSchedulesRowTwo[${item.id}]`}>
        <FormField
          label={intl.formatMessage({ id: 'new_event.basics.venue_schedule.venue.label' })}
          placeholder={intl.formatMessage({ id: 'new_event.basics.venue_schedule.venue.label' })}
          control="select"
          name={`venueSchedules[${idx}].venueId`}
          value={venueOption}
          loadOptions={venueLoader}
          required
          searchable
          async
          onChange={handleVenueChange}
          onBlur={handleBlur}
          disabled={readOnly}
          error={get(`venueSchedules[${idx}].venueId`, touched) && get(`venueSchedules[${idx}].venueId`, errors)}
        />
        <FormField
          label={intl.formatMessage({ id: 'new_event.basics.venue_schedule.venue_configuration.label' })}
          placeholder={intl.formatMessage({ id: 'new_event.basics.venue_schedule.venue_configuration.label' })}
          control="select"
          name={`venueSchedules[${idx}].venueConfigurationId`}
          value={venueConfigurationOption}
          options={venueConfigurationOptions}
          required
          searchable
          onChange={handleVenueConfigurationChange}
          onBlur={handleBlur}
          disabled={readOnly}
          error={
            get(`venueSchedules[${idx}].venueConfigurationId`, touched) &&
            get(`venueSchedules[${idx}].venueConfigurationId`, errors)
          }
        />
      </FormRow>
      <FormRow columnOnMobile data-id={`venueSchedulesRowThree[${item.id}]`}>
        <FormField
          name={`venueSchedules[${idx}].date`}
          timezone={timezoneName || undefined}
          label={intl.formatMessage({ id: 'new_event.timeline.start.label' })}
          placeholder={intl.formatMessage({ id: 'new_event.timeline.start.label' })}
          control="datetime"
          placement="top-start"
          value={item.date}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          error={get(`venueSchedules[${idx}].date`, touched) && get(`venueSchedules[${idx}].date`, errors)}
          locale={locale}
          disabled={readOnly}
          required
        />
        <FormField
          name={`venueSchedules[${idx}].endDate`}
          timezone={timezoneName || undefined}
          label={intl.formatMessage({ id: 'new_event.timeline.end.label' })}
          placeholder={intl.formatMessage({ id: 'new_event.timeline.end.label' })}
          control="datetime"
          placement="top-start"
          value={item.endDate}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          error={get(`venueSchedules[${idx}].endDate`, touched) && get(`venueSchedules[${idx}].endDate`, errors)}
          locale={locale}
          disabled={readOnly}
          required
        />
      </FormRow>
    </>
  )
}

interface IProps {
  readOnly?: boolean
}

const EventVenueSchedule: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()

  const ref = useRef<HTMLDivElement>(null)

  const { values, touched, errors, setFieldValue, setFieldTouched, validateForm } = useFormikContext<
    IEventFormBasics & IEventFormTickets
  >()

  const items: IScheduleItem[] = useMemo(() => compact(values.venueSchedules || []), [values.venueSchedules])

  const addItem = useCallback(() => {
    if (!values.attractiveFields?.subscriptionCode) {
      setFieldValue('attractiveFields.subscriptionCode', subscriptionCode(), true)
      setFieldTouched('attractiveFields.subscriptionCode', true)
    }

    const idx = (items || []).length
    const newItem = markAsClientOnly<IScheduleItem>({
      name: null,
      date: null,
      endDate: null,
      venueId: null,
      venueConfigurationId: null,
    })

    setFieldValue('venueSchedules', concat(items, newItem), true)

    setFieldTouched('venueSchedules', true, true)
    setTimeout(() => validateForm(), 0)

    setTimeout(() => {
      if (ref.current) {
        const node = ref.current.querySelector(`input[name="venueSchedules[${idx}].name"]`) as HTMLInputElement
        if (node) node.focus()
      }
    }, 500)
  }, [items, setFieldTouched, setFieldValue, validateForm, values.attractiveFields?.subscriptionCode])

  const removeItem = useCallback(
    (idx: number) => {
      const item = items[idx]
      setFieldValue('venueSchedules', without([item], items))

      if (values.ticketTypes && values.ticketTypes.length > 0) {
        setFieldValue(
          'ticketTypes',
          map(
            update('venueScheduleId', (id) => (id === item.id ? null : id)),
            values.ticketTypes
          )
        )
      }

      setFieldTouched('venueSchedules', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [items, setFieldTouched, setFieldValue, validateForm, values.ticketTypes]
  )

  return (
    <FormRow columnOnMobile>
      <FormGroup
        dice
        data-name="venueSchedule"
        label={intl.formatMessage({ id: 'new_event.basics.venue_schedule.label' })}
        help={intl.formatMessage({ id: 'new_event.basics.venue_schedule.help' })}
        error={touched.venueSchedules && errors.venueSchedules}
        disabled={readOnly}
      >
        <div ref={ref}>
          <StyledForm spacing="small">
            {items.map((item, idx) => (
              <VenueScheduleItem
                key={item.id}
                item={item}
                timezoneName={values.timezoneName}
                idx={idx}
                readOnly={readOnly}
                onRemove={removeItem}
              />
            ))}
            <FormRow columnOnMobile>
              {items.length === 0 ? (
                <EmptyList
                  icon="venue"
                  cta={intl.formatMessage({ id: 'new_event.basics.venue_schedule.add_button.label' })}
                  title={intl.formatMessage({ id: 'new_event.basics.venue_schedule.empty_title' })}
                  subTitle={intl.formatMessage({ id: 'new_event.basics.venue_schedule.empty_message' })}
                  hasError={touched.venueSchedules && !!errors.venueSchedules}
                  onAdd={addItem}
                  disabled={readOnly}
                />
              ) : (
                <>
                  {!readOnly && (
                    <ListAddButton
                      label={intl.formatMessage({ id: 'new_event.basics.venue_schedule.add_button.label' })}
                      onClick={addItem}
                    />
                  )}
                  <div />
                </>
              )}
            </FormRow>
          </StyledForm>
        </div>
      </FormGroup>
    </FormRow>
  )
}

export default memo(EventVenueSchedule)
