import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { PageViewTracker } from '../../../context/tracking'
import { textStyle } from '../../../utils/typography'

import { font, mediaQuery } from '../../../utils/variables'
import AuthSimpleLayout from '../../Auth/AuthSimpleLayout'

const Layout = styled.div`
  min-height: 100%;
  display: flex;
  z-index: 20;
  position: relative;
`

const Buffer = styled.div`
  flex-grow: 1;
`

const TextBlock = styled.div`
  align-self: center;
  flex-basis: 620px;
  flex-grow: 0;
  text-align: center;
  color: #ffffff;
  margin: 0 32px;
`

const Headline = styled.div`
  ${textStyle.heading.xxxl}

  margin-bottom: 16px;
  ${mediaQuery.lessThan('tablet')`
    ${textStyle.heading.xxl}
  `}
`

const Description = styled.div`
   ${textStyle.heading.xl}

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.lg}px;
    padding: 0;
  `}
`

const SignupComplete: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageViewTracker trackId="account_create_activate_account_prompt" />
      <AuthSimpleLayout>
        <Layout>
          <Buffer />
          <TextBlock>
            <Headline>{intl.formatMessage({ id: 'signup_complete.headline_new' })}</Headline>
            <Description>{intl.formatMessage({ id: 'signup_complete.description_new' })}</Description>
          </TextBlock>
          <Buffer />
        </Layout>
      </AuthSimpleLayout>
    </>
  )
}

export default SignupComplete
