import React, { FC, memo, Suspense, useMemo, useContext } from 'react'
import styled from 'styled-components/macro'
import { isEmpty } from 'lodash/fp'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { mediaQuery, color } from '../../utils/variables'

import RelayLoader from '../../components/RelayLoader'
import { PageBody } from '../../components/Page'
import RecentlyOnSale from './components/DashboardEventsList/RecentlyOnSale'
import RecentlyViewed from './components/DashboardEventsList/RecentlyViewed'

import { DashboardEvents_viewer$key } from '../../__generated__/DashboardEvents_viewer.graphql'
import OnSaleToday from './components/DashboardEventsList/OnSaleToday'
import { LoaderContainer } from '../../components/UploaderStyles'
import { Loader } from '../../components/Loader'
import CollaboratorsBanner from '../../components/CollaboratorsBanner'
import Priority from './components/DashboardEventsList/Priority'
import { authContext } from '../../context/auth'
import AccountDeactivatedBanner from '../../components/AccountDeactivatedBanner'
import ReadyForPayoutBanner from '../../components/ReadyForPayoutBanner'

const Wrapper = styled(PageBody)`
  min-height: auto !important;
  max-width: 1200px;
  padding-bottom: 24px;
  overflow: hidden;
  ${mediaQuery.lessThan('desktop')`
    max-width: none;
    margin: 0;
    padding: 16px 16px 24px;
  `}
  ${mediaQuery.lessThan('tablet')`
    padding: 0 0 72px;
  `}
`
export const List = styled.div`
  margin: 8px -8px 24px;
  padding: 0 8px;
  ${mediaQuery.lessThan('desktop')`
    margin: 0 -16px;
    padding: 16px 16px 0;
    &:first-child {
      padding-top: 0;
    }
    & + & {
      border-top: 2px solid ${color.text};
    }
  `}
`
export const ListTitle = styled.strong`
  display: block;
  margin-bottom: 8px;
  ${mediaQuery.lessThan('desktop')`
    margin: 16px;
  `}
`

interface IProps {
  viewer: DashboardEvents_viewer$key
}

const DashboardEvents: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const { user } = useContext(authContext)

  const viewer = useFragment(
    graphql`
      fragment DashboardEvents_viewer on Viewer
      @argumentDefinitions(onSaleWhere: { type: "EventWhereInput!", defaultValue: {} }) {
        ...OnSaleToday_viewer
        ...Priority_viewer
        ...RecentlyOnSale_viewer @arguments(where: $onSaleWhere)
        ...ReadyForPayoutBanner_viewer
        ...AccountDeactivatedBanner_viewer
      }
    `,
    viewerKey
  )

  const RecentlyViewedEvents = useMemo(() => {
    if (isEmpty(window.localStorage.getItem('recently_viewed'))) {
      return 'div'
    }
    const recentlyViewedIds = (window.localStorage.getItem('recently_viewed') || '').split(',')
    return RelayLoader(RecentlyViewed, {
      variables: {
        recentlyViewedWhere: { id: { in: recentlyViewedIds }, priority: user.diceStaff ? { eq: false } : undefined },
      },
      query: graphql`
        query DashboardEventsRecentlyViewedQuery($recentlyViewedWhere: EventWhereInput!) {
          viewer {
            ...RecentlyViewed_viewer @arguments(where: $recentlyViewedWhere)
          }
        }
      `,
    })
  }, [user.diceStaff])

  return (
    <Wrapper>
      {!user.diceStaff && <ReadyForPayoutBanner viewer={viewer} />}
      <CollaboratorsBanner />
      <AccountDeactivatedBanner viewer={viewer} />
      {user.diceStaff && <Priority viewer={viewer} />}
      <RecentlyViewedEvents />
      <RecentlyOnSale viewer={viewer} />
      <Suspense
        fallback={
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        }
      >
        <OnSaleToday viewer={viewer} />
      </Suspense>
    </Wrapper>
  )
}

export default memo(DashboardEvents)
