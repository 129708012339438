import React, { PropsWithChildren, memo, useMemo } from 'react'
import styled from 'styled-components'
import { color } from '../utils/variables'

const AVATAR_SIZE = {
  xs: {
    circle: 32,
    font: 12,
  },
  sm: {
    circle: 38,
    font: 12,
  },
  account: {
    circle: 40,
    font: 16,
  },
  accountv2: {
    circle: 20,
    font: 9,
  },
  md: {
    circle: 72,
    font: 28,
  },
  xl: {
    circle: 160,
    font: 60,
  },
}

type Size = keyof typeof AVATAR_SIZE
type CircleStyle = 'outline' | null

interface IProps {
  size?: Size
  circleStyle?: CircleStyle
  firstName?: string | null
  lastName?: string | null
}

const Initials: React.FC<PropsWithChildren<IProps>> = ({ size = 'md', circleStyle, firstName, lastName, children }) => {
  const initials = useMemo(() => {
    if (!firstName && !lastName) return null
    return (firstName || '').substring(0, 1) + (lastName || '').substring(0, 1)
  }, [firstName, lastName])

  return (
    <InitialsContainer size={size as Size} circleStyle={circleStyle as CircleStyle}>
      {initials || children}
    </InitialsContainer>
  )
}

export default memo(Initials)

const InitialsContainer = styled.div<{ size: Size; circleStyle?: CircleStyle | undefined }>`
  height: ${({ size }) => AVATAR_SIZE[size].circle}px;
  width: ${({ size }) => AVATAR_SIZE[size].circle}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: ${({ size }) => AVATAR_SIZE[size].font}px;
  background-color: ${color.lightblue};
  color: ${color.primary};

  ${({ circleStyle }) =>
    circleStyle === 'outline'
      ? `
    background-color: transparent;
    color: currentColor;
    border: 2px solid currentColor;
  `
      : null}
`
