import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import styled from 'styled-components/macro'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { compact } from 'lodash/fp'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'
import IEventFormTimeline, { IAbbonamentoEvent } from '../../types/Timeline'
import { textStyle } from '../../../../utils/typography'
import StaleSuspense from '../../../../components/StaleSuspense'
import { EventListLoader } from '../../../EventList/components/EventListLoader'
import {
  EventAbbonamentoPickerModalQuery,
  EventAbbonamentoPickerModalQuery$variables,
  EventsConnectionOrder,
} from '../../../../__generated__/EventAbbonamentoPickerModalQuery.graphql'
import EventAbbonamentoPickerTable from './EventAbbonamentoPickerTable'
import { useDebounce } from '../../../../utils/hooks/useDebounce'
import { FilterSearch, FilterSearchInput, SearchIcon } from '../../../../components/FilterStyles'
import IconButton from '../../../../components/IconButton'
import { input, mediaQuery } from '../../../../utils/variables'
import { authContext } from '../../../../context/auth'

interface IWrapperProps extends EventAbbonamentoPickerModalQuery$variables {
  loading?: boolean
  orderByRaw: string
  setOrderBy: (v: string) => void
  selectedEvents: IAbbonamentoEvent[]
  setSelectedEvents: (ids: IAbbonamentoEvent[]) => void
  maxEventsSelected: boolean
}

const ListWrapper: FC<IWrapperProps> = ({
  orderByRaw,
  setOrderBy,
  loading,
  selectedEvents,
  setSelectedEvents,
  maxEventsSelected,
  ...vars
}) => {
  const { viewer } = useLazyLoadQuery<EventAbbonamentoPickerModalQuery>(
    graphql`
      query EventAbbonamentoPickerModalQuery(
        $where: EventWhereInput
        $orderBy: [EventsConnectionOrder]
        $scopes: EventScopesInput
        $searchTerm: String
      ) {
        viewer {
          ...EventAbbonamentoPickerTable_viewer
            @arguments(where: $where, orderBy: $orderBy, scopes: $scopes, searchTerm: $searchTerm)
        }
      }
    `,
    vars,
    { fetchPolicy: 'store-and-network' }
  )
  return (
    viewer && (
      <EventAbbonamentoPickerTable
        viewer={viewer}
        loading={loading}
        orderBy={orderByRaw}
        setOrderBy={setOrderBy}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
        maxEventsSelected={maxEventsSelected}
      />
    )
  )
}

interface IProps {
  onCancel: () => void
  onConfirm: (events: any) => void
  selectedEvents: (IAbbonamentoEvent | null)[]
}

const EventAbbonamentoPickerModal: FC<IProps> = ({ onCancel, onConfirm, selectedEvents: preSelectedEvents }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { values } = useFormikContext<IEventFormTimeline>()

  const [selectedEvents, setSelectedEvents] = useState<Array<IAbbonamentoEvent>>(compact(preSelectedEvents))

  const doConfirm = useCallback(() => onConfirm(selectedEvents), [onConfirm, selectedEvents])

  const [term, setTerm] = useState('')
  const debouncedTerm = useDebounce(term, 300)

  const onChangeTerm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value)
  }, [])

  const clearSearch = useCallback(() => {
    setTerm('')
  }, [])

  const [orderBy, setOrderBy] = useState('dateASC')
  const updateOrderBy = useCallback((o: string) => setOrderBy(o), [])

  const listProps: IWrapperProps = useMemo(() => {
    return {
      orderBy: [orderBy as EventsConnectionOrder],
      searchTerm: debouncedTerm,
      where: values.id ? { id: { ne: values.id } } : {},
      scopes: { lifeCycleState: ['DRAFT', 'LIVE'] },
      noSelfPayouts: false,
      orderByRaw: orderBy,
      setOrderBy: updateOrderBy,
      selectedEvents: selectedEvents,
      setSelectedEvents: setSelectedEvents,
      maxEventsSelected: selectedEvents.length === values.attractiveFields?.forceSubscriptionLimit,
    }
  }, [
    debouncedTerm,
    orderBy,
    selectedEvents,
    updateOrderBy,
    values.attractiveFields?.forceSubscriptionLimit,
    values.id,
  ])

  return (
    <Modal
      modalTitle={intl.formatMessage({ id: 'new_event.abbonamento.link_events_button' })}
      closeButton
      onClose={onCancel}
    >
      <ModalBody>
        <StyledFilterSearch>
          <SearchIcon icon="search" />
          <FilterSearchInput
            name="search"
            placeholder={intl.formatMessage({ id: 'search' })}
            onChange={onChangeTerm}
            value={term}
            autoComplete="off"
          />
          {term && <IconButton icon="close-view" onClick={clearSearch} />}
        </StyledFilterSearch>
        <StaleSuspense component={ListWrapper} props={listProps} fallback={<StyledEventListLoader />} />
      </ModalBody>
      <StyledModalFooter className="mt-zero">
        <ModalFooterControl
          data-id="linkButton"
          onClick={doConfirm}
          disabled={selectedEvents.length !== values.attractiveFields?.forceSubscriptionLimit && !user.diceStaff}
        >
          {intl.formatMessage({ id: 'new_event.abbonamento.link_events_button' })}
        </ModalFooterControl>
        <Counter>
          {intl.formatMessage(
            { id: 'new_event.abbonamento.events_linked_indicator' },
            {
              arg0: selectedEvents.length || 0,
              arg1: values.attractiveFields?.forceSubscriptionLimit || 0,
            }
          )}
        </Counter>
        <ModalFooterControl data-id="cancelButton" preset="secondary" onClick={onCancel}>
          {intl.formatMessage({ id: 'cancel' })}
        </ModalFooterControl>
      </StyledModalFooter>
    </Modal>
  )
}

export default EventAbbonamentoPickerModal

const StyledFilterSearch = styled(FilterSearch)`
  min-height: 60px;
  margin-left: -32px;
  margin-right: -32px;
  margin-top: -12px;
  border-bottom: 1px solid ${input.borderColor};

  ${mediaQuery.lessThan('tablet')`
      padding-left: 32px;
      padding-right: 32px;
  `}
`

const StyledEventListLoader = styled(EventListLoader)`
  height: 530px;
  position: relative;
`

const StyledModalFooter = styled(ModalFooter)`
  ${mediaQuery.lessThan('tablet')`
    display: flex;
    flex-direction: column;
    gap: 16px
  `}
`

const Counter = styled.div`
  ${textStyle.bodycopy.regular}
  opacity: 0.5;
  align-self: center;
  margin-left: 16px;

  ${mediaQuery.lessThan('tablet')`
    order: -1;
  `}
`
