import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Svg from '../../../../../components/Svg'
import Value from '../../../../../components/Value'

import { color, font, mediaQuery } from '../../../../../utils/variables'
import { CURRENCY } from '../../../../../utils/formatters/number'
// eslint-disable-next-line max-len
import { RemittanceModalDataMobile_event$key } from '../../../../../__generated__/RemittanceModalDataMobile_event.graphql'
import { textStyle } from '../../../../../utils/typography'

const ColBig = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 3;
`

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
`

const MDASH = styled.i`
  margin: 0 4px;
  color: ${color.lightgrey};
  font-style: normal;
  &:before {
    content: '—';
  }
`

const TicketTypeRow = styled.div`
  display: flex;
  position: relative;
  padding-left: 56px;
  flex-wrap: wrap;
  align-items: center;
  & + & {
    margin-top: 32px;
  }
  ${mediaQuery.lessThan('tablet')`
    padding-left: 0;
    margin: 0 -8px;
    & > ${ColBig} {
      flex: 100% 0 0;
      padding-left: 64px;
      margin-bottom: 8px;
    }
  `}
`

const TicketTypeIcon = styled.span`
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 50%;
  ${mediaQuery.lessThan('tablet')`
    top: 4px;
    left: 8px;
  `}
`

const TicketTypeName = styled.div`
  font-size: ${font.size.md}px;
  line-height: 25px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 6px;
  ${mediaQuery.lessThan('tablet')`
    margin-bottom: 2px;
  `}
`

const PriceTierRow = styled.div`
  display: flex;
  flex: 1;
  flex: 100% 0 0;
  width: 100%;
  border-bottom: 1px solid ${color.lightgrey};
  flex: 100% 0 0;
  width: 100%;
  align-items: center;
  margin: 4px 0px -4px;
  ${mediaQuery.lessThan('tablet')`
    flex-wrap: wrap;
    margin: 8px -20px -8px;
    padding: 8px 20px 0;
    flex: auto;
    ${ColBig} {
      flex: 100% 0 0;
      padding: 0 8px;
      font-weight: bold;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `}
`

const PriceTierColHeader = styled.small`
  display: none;
  ${mediaQuery.lessThan('tablet')`
    display: block;
    text-transform: uppercase;
    white-space: nowrap;

    ${textStyle.button.sm}
  `}
`

const RemittanceModalDataMobile: FC<React.PropsWithChildren<{ event: RemittanceModalDataMobile_event$key }>> = (
  props
) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment RemittanceModalDataMobile_event on Event {
        id
        costCurrency
        sales {
          ticketTypesBreakdown {
            ticketTypeId
            totalAppSold
            totalFaceValue
            totalRebate
            ticketType {
              id
              name
              icon
              faceValue
              rebate
              price
              priceTiers {
                id
                name
                price
                order
                faceValue
                rebate
              }
            }
            priceTiersBreakdown {
              value
              appSold
              posSold
              rebate
            }
          }
        }
      }
    `,
    props.event
  )

  return (
    <>
      {event?.sales?.ticketTypesBreakdown.map(
        (tt) =>
          tt && (
            <TicketTypeRow key={tt.ticketTypeId}>
              <TicketTypeIcon>
                <Svg icon={`ticket-type-${tt.ticketType.icon}`} />
              </TicketTypeIcon>
              <ColBig>
                <TicketTypeName>{tt.ticketType.name}</TicketTypeName>
                <span>
                  {intl.formatNumber(
                    (tt.ticketType.price || 0) / 100,
                    CURRENCY(tt.ticketType.price || 0, event.costCurrency)
                  )}
                </span>
              </ColBig>
              <Col>
                <Value label={intl.formatMessage({ id: 'remittance.col.sold' })} value={tt.totalAppSold || 0} />
              </Col>
              <Col>
                <Value
                  label={intl.formatMessage({ id: 'remittance.col.face_value' })}
                  value={intl.formatNumber(
                    (tt.ticketType.faceValue || 0) / 100,
                    CURRENCY(tt.ticketType.faceValue || 0, event.costCurrency)
                  )}
                />
              </Col>
              <Col>
                <Value
                  label={intl.formatMessage({ id: 'remittance.col.rebate' })}
                  value={intl.formatNumber(
                    (tt.ticketType.rebate || 0) / 100,
                    CURRENCY(tt.ticketType.rebate || 0, event.costCurrency)
                  )}
                />
              </Col>
              <Col>
                <Value
                  label={intl.formatMessage({ id: 'remittance.col.total' })}
                  value={intl.formatNumber(
                    (tt.totalFaceValue || 0) / 100,
                    CURRENCY(tt.totalFaceValue || 0, event.costCurrency)
                  )}
                />
              </Col>
              {tt.ticketType.priceTiers?.map(
                (pt, idx) =>
                  pt && (
                    <PriceTierRow key={pt.id}>
                      <ColBig>
                        <span>{pt.name || intl.formatMessage({ id: 'price_tier_num' }, { num: idx + 1 })}</span>
                        <MDASH />
                        <span>{intl.formatNumber((pt.price || 0) / 100, CURRENCY(pt.price, event.costCurrency))}</span>
                      </ColBig>
                      <Col>
                        <PriceTierColHeader>{intl.formatMessage({ id: 'remittance.col.sold' })}</PriceTierColHeader>
                        <span>{tt.priceTiersBreakdown[idx]?.appSold || 0}</span>
                      </Col>
                      <Col>
                        <PriceTierColHeader>
                          {intl.formatMessage({ id: 'remittance.col.face_value' })}
                        </PriceTierColHeader>
                        <span>
                          {intl.formatNumber((pt.faceValue || 0) / 100, CURRENCY(pt.faceValue, event.costCurrency))}
                        </span>
                      </Col>
                      <Col>
                        <PriceTierColHeader>{intl.formatMessage({ id: 'remittance.col.rebate' })}</PriceTierColHeader>
                        <span>
                          {intl.formatNumber((pt.rebate || 0) / 100, CURRENCY(pt.rebate, event.costCurrency))}
                        </span>
                      </Col>
                      <Col>
                        <PriceTierColHeader>{intl.formatMessage({ id: 'remittance.col.total' })}</PriceTierColHeader>
                        <span>
                          {intl.formatNumber(
                            (tt.priceTiersBreakdown[idx]?.value || 0) / 100,
                            CURRENCY(tt.priceTiersBreakdown[idx]?.value || 0, event.costCurrency)
                          )}
                        </span>
                      </Col>
                    </PriceTierRow>
                  )
              )}
            </TicketTypeRow>
          )
      )}
    </>
  )
}

export default memo(RemittanceModalDataMobile)
