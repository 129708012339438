import { createGlobalStyle } from 'styled-components/macro'
import { color, font } from '../../utils/variables'
import { textStyle } from '../../utils/typography'

export function lightNDark(colorCode: string, amount: number) {
  let usePound = false

  if (colorCode[0] === '#') {
    colorCode = colorCode.slice(1)
    usePound = true
  }

  const num = parseInt(colorCode, 16)

  let r = (num >> 16) + amount
  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }

  let g = (num & 0x0000ff) + amount
  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }

  let b = ((num >> 8) & 0x00ff) + amount
  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }

  let color = (g | (b << 8) | (r << 16)).toString(16)
  while (color.length < 6) {
    color = 0 + color
  }

  return (usePound ? '#' : '') + color
}

export const ButtonStyle = createGlobalStyle`
  .button {
    display: inline-block;
    vertical-align: middle;
    color: ${color.white};
    background: ${color.text};
    border: 2px solid ${color.text};
    border-radius: 20px;
    outline: none;
    ${textStyle.button.lg}
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 24px;
    height: 40px;
    white-space: nowrap;
    user-select: none;
    touch-action: manipulation;
    cursor: pointer;
    position: relative;
    text-align: center;
    transition: color .1s cubic-bezier(0.4, 0.0, 0.6, 1),
    border-color .1s cubic-bezier(0.4, 0.0, 0.6, 1), background-color .1s cubic-bezier(0.4, 0.0, 0.6, 1);
    & + & {
      margin-left: 8px;
      +mobile {
        margin-left: 0;
        margin-top: 8px;
      }
    }
    &.-disabled, &:disabled {
      color: ${color.greyer};
      pointer-events: none;
      &, &:hover {
        cursor: not-allowed;
        transition: none !important;
      }
    }
    &:hover, &.-hover {
      background: ${color.primary};
      border-color: ${color.primary};
      color: ${color.white};
    }
    &.-loading {
      pointer-events: none;
      .button_container {
        opacity: 0;
        height: 0;
      }
    }
    &.-block {
      width: 100%;
    }
    &.-size-small {
      height: 32px;
      padding: 0 12px;
      ${textStyle.button.sm}
      .button_icon-container {
        &:first-child {
          margin-right: 4px;
        }
        &:last-child {
          margin-left: 4px;
        }
      }
      .button_icon {
        width: 18px;
        height: 18px;
      }
    }
    &.-align-left {
      .button_container {
        justify-content: flex-start;
      }
    }
    &.-align-right {
      .button_container {
        justify-content: flex-end;
      }
    }
    &.-color {
      &-primary, &-blue {
        background-color: ${color.primary};
        border-color: ${color.primary};
        &:hover {
          background-color: ${lightNDark(color.primary, -10)};
          border-color: ${lightNDark(color.primary, -10)};
          color: ${color.white};
        }
      }
      &-secondary, &-green {
        background-color: ${color.secondary};
        border-color: ${color.secondary};
        &:hover {
          background-color: ${lightNDark(color.secondary, -10)};
          border-color: ${lightNDark(color.secondary, -10)};
          color: ${color.white};
        }
      }
      &-white {
        color: ${color.text};
        background-color: ${color.white};
        border-color: ${color.white};
        &:hover {
          background-color: ${lightNDark(color.white, -10)};
          border-color: ${lightNDark(color.white, -10)};
        }
      }
      &-error, &-red {
        background-color: ${color.error};
        border-color: ${color.error};
        &:hover {
          background-color: ${lightNDark(color.error, -10)};
          border-color: ${lightNDark(color.error, -10)};
          color: ${color.white};
        }
      }
      &-warning, &-orange {
        background-color: ${color.warning};
        border-color: ${color.warning};
        &:hover {
          background-color: ${lightNDark(color.warning, -10)};
          border-color: ${lightNDark(color.warning, -10)};
          color: ${color.white};
        }
      }
      &.-disabled, &:disabled {
        color: ${color.white};
      }
    }
    &.-preset-secondary {
      background: ${color.lightgrey};
      border-color: ${color.lightgrey};
      color: ${color.text};
      &:hover, &.-hover {
        color: ${color.primary};
      }
      &.-color {
        &-error, &-red {
          color: ${color.error};
          &:hover, &.-hover {
            color: ${color.white};
          }
        }
      }
      &.-disabled, &:disabled {
        color: ${color.greyer};
      }
    }
    &.-preset-outline {
      background-color: transparent;
      color: ${color.text};
      &:hover, &.-hover {
        border-color: ${color.primary};
        color: ${color.primary};
      }
      &.-color {
        &-primary, &-blue {
          background-color: transparent;
          border-color: ${color.primary};
          color: ${color.primary};
          &:hover, &.-hover {
            border-color: ${lightNDark(color.primary, -10)};
            color: ${lightNDark(color.primary, -10)};
          }
        }
        &-secondary, &-green {
          background-color: transparent;
          border-color: ${color.secondary};
          color: ${color.secondary};
          &:hover, &.-hover {
            border-color: ${lightNDark(color.secondary, -10)};
            color: ${lightNDark(color.secondary, -10)};
          }
        }
        &-tertiary {
          background-color: transparent;
          border-color: ${color.tertiary};
          color: ${color.tertiary};
          &:hover, &.-hover {
            border-color: ${lightNDark(color.tertiary, -10)};
            color: ${lightNDark(color.tertiary, -10)};
          }
        }
        &-white {
          background-color: transparent;
          border-color: ${color.white};
          color: ${color.white};
          &:hover, &.-hover {
            border-color: ${lightNDark(color.white, -10)};
            color: ${lightNDark(color.white, -10)};
          }
        }
        &-error, &-red {
          background-color: transparent;
          border-color: ${color.error};
          color: ${color.error};
          &:hover, &.-hover {
            border-color: ${lightNDark(color.error, -10)};
            color: ${lightNDark(color.error, -10)};
          }
        }
        &-warning, &-orange {
          background-color: transparent;
          border-color: ${color.warning};
          color: ${color.warning};
          &:hover, &.-hover {
            border-color: ${lightNDark(color.warning, -10)};
            color: ${lightNDark(color.warning, -10)};
          }
        }
      }
      &.-disabled, &:disabled {
        opacity: 0.5;
      }
    }
    &.-preset-primary-link {
      background-color: transparent !important;
      color: ${color.text};
      border-color: transparent !important;
      padding: 0;
      height: auto;
      min-width: 0;
      &:hover {
        color: ${color.primary};
      }
      &.-disabled, &:disabled {
        color: ${color.disabled};
      }
    }
    &.-preset-link {
      background-color: transparent !important;
      color: ${color.text};
      border-color: transparent !important;
      text-transform: none;
      padding: 0;
      margin: 0;
      border: 0;
      height: auto;
      min-width: 0;
      font-weight: inherit;
      font-size: inherit;
      letter-spacing: inherit;
      &:hover, &.-hover {
        color: ${color.primary};
      }
      &.-color {
        &-primary, &-blue {
          color: ${color.primary};
          &:hover, &.-hover {
            color: ${lightNDark(color.primary, -10)};
          }
        }
        &-secondary, &-green {
          color: ${color.secondary};
          &:hover, &.-hover {
            color: ${lightNDark(color.secondary, -10)};
          }
        }
        &-warning, &-orange {
          color: ${color.warning};
          &:hover, &.-hover {
            color: ${lightNDark(color.warning, -10)};
          }
        }
        &-error, &-red {
          color: ${color.error};
          &:hover, &.-hover {
            color: ${lightNDark(color.error, -10)};
          }
        }
      }
      &.-disabled, &:disabled {
        opacity: .25;
      }
    }
    &.-preset-transparent {
      background-color: transparent;
      color: ${color.text};
      border-color: transparent;
      min-width: 0;
      &:hover {
        color: ${color.primary};
      }
    }
  }
  .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: opacity .1s cubic-bezier(0.4, 0.0, 0.6, 1);
  }
  .button_content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .button_icon-container {
    display: block;
    line-height: 0;
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
  .button_icon {
    width: 24px;
    height: 24px;
  }
  .button_file-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    overflow: hidden;
  }
  .button_loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 70%;
    color: inherit;
  }
`

export const IconButtonStyle = createGlobalStyle`
  .icon-button {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    cursor: pointer;
    line-height: 0;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid transparent;
    background: none;
    outline: none;
    color: inherit;
    transition: color .1s cubic-bezier(0.4, 0.0, 0.6, 1),
    border-color .1s cubic-bezier(0.4, 0.0, 0.6, 1),
    background .1s cubic-bezier(0.4, 0.0, 0.6, 1);
    & + & {
      margin-left: 8px;
    }
    &:hover, &:focus, &:active {
      background: ${color.lightgrey};
      color: inherit;
    }
    &:not(button).-disabled {
      pointer-events: none;
    }
    &.-disabled, &:disabled {
      cursor: not-allowed;
      .icon-button_container {
        opacity: 0.25;
      }
    }
    &.-loading {
      .icon-button_container {
        opacity: 0;
      }
    }

    &.-color {
      &-black {
        background: ${color.black};
        color: ${color.white};
        &:hover, &:focus, &:active {
          background: ${color.primary};
        }
      }
      &-grey, &-lightgrey {
        background: ${color.lightgrey};
        &:hover, &:focus, &:active {
          background: ${lightNDark(color.lightgrey, -10)};
        }
      }
      &-primary, &-blue {
        background: ${color.primary};
        color: ${color.white};
        &:hover, &:focus, &:active {
          background: ${lightNDark(color.primary, -10)};
        }
      }
      &-secondary, &-green {
        background: ${color.secondary};
        color: ${color.white};
        &:hover, &:focus, &:active {
          background: ${lightNDark(color.secondary, -10)};
        }
      }
      &-tertiary {
        background: ${color.tertiary};
        color: ${color.white};
        &:hover, &:focus, &:active {
          background: ${lightNDark(color.tertiary, -10)};
        }
      }
      &-warning, &-orange {
        background: ${color.warning};
        color: ${color.white};
        &:hover, &:focus, &:active {
          background: ${lightNDark(color.warning, -10)};
        }
      }
      &-error, &-red {
        background: ${color.error};
        color: ${color.white};
        &:hover, &:focus, &:active {
          background: ${lightNDark(color.error, -10)};
        }
      }
    }

    &.-outline-color {
      &-black {
        border-color: ${color.black};
        &:hover, &:focus, &:active {
          background: ${color.black};
          color: ${color.white};
        }
      }
      &-grey, &-lightgrey {
        border-color: ${color.lightgrey};
        &:hover, &:focus, &:active {
          background: ${color.lightgrey};
        }
      }
      &-blue, &-primary {
        border-color: ${color.primary};
        &:hover, &:focus, &:active {
          background: ${color.primary};
          color: ${color.white};
        }
      }
      &-green, &-secondary {
        border-color: ${color.secondary};
        &:hover, &:focus, &:active {
          background: ${color.secondary};
          color: ${color.white};
        }
      }
      &-tertiary {
        color: ${color.tertiary};
        border-color: ${color.tertiary};
        &:hover, &:focus, &:active {
          background: ${color.tertiary};
          color: ${color.white};
        }
      }
      &-warning, &-orange {
        border-color: ${color.warning};
        &:hover, &:focus, &:active {
          background: ${color.warning};
          color: ${color.white};
        }
      }
      &-error, &-red {
        border-color: ${color.error};
        &:hover, &:focus, &:active {
          background: ${color.error};
          color: ${color.white};
        }
      }
    }
  }
  .icon-button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    height: 100%;
  }
  .icon-button_loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`
