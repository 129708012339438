import { createGlobalStyle } from 'styled-components/macro'
import { color, font, zIndex } from '../utils/variables'

export const GlobalStyle = createGlobalStyle`
  html, body, #app {
    min-height: 100%;
    height: 100%;
    min-width: 320px;
  }

  body > .draggable {
    background-color: ${color.white};
    cursor: grabbing;
    pointer-events: auto !important;
    z-index: ${zIndex.max};
    * {
      cursor: grabbing;
    }
    .-hide-on-drag {
      display: none;
    }
  }

  * {
    box-sizing: border-box;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    font-family: ${font.family.base};
    font-size: ${font.size.base}px;
    font-weight: normal;
    color: ${color.text};
    background-color: ${color.white};
    line-height: 1.25em;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    padding: 0;
    margin: 0;
  }

  body.-no-scroll-bounce, body.-test-page {
    overscroll-behavior: none;
  }

  input,
  button,
  select,
  textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    cursor: pointer;
    color: currentColor;
    text-decoration: none;
    &:hover {
      color: ${color.primary};
    }
  }

  figure {
    margin: 0;
  }

  img {
    vertical-align: middle;
  }

  hr {
    margin: 0;
    height: 0;
    border: 0;
    border-top: 1px solid #666;
    clear: both;
  }

  [role="button"] {
    cursor: pointer;
  }

  input::-ms-clear, input::-ms-reveal {
    display: none;
  }
`
