/**
 * @generated SignedSource<<93beabf29dbd7ac4e1777df4f799abb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnSaleTodayQuery$variables = {
  count?: number | null;
  cursor?: string | null;
};
export type OnSaleTodayQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OnSaleToday_viewer">;
  } | null;
};
export type OnSaleTodayQuery = {
  response: OnSaleTodayQuery$data;
  variables: OnSaleTodayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "dateASC"
    ]
  },
  {
    "kind": "Literal",
    "name": "scopes",
    "value": {
      "lifeCycleState": "LIVE"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "special": "onSaleToday"
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnSaleTodayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "OnSaleToday_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnSaleTodayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "onSaleToday",
            "args": (v1/*: any*/),
            "concreteType": "EventConnection",
            "kind": "LinkedField",
            "name": "events",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timezoneName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusAsOfNow",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventFlags",
                        "kind": "LinkedField",
                        "name": "flags",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hidden",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventImage",
                        "kind": "LinkedField",
                        "name": "eventImages",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cdnUrl",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eventType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "venueName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Venue",
                        "kind": "LinkedField",
                        "name": "primaryVenue",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Venue",
                        "kind": "LinkedField",
                        "name": "venues",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Promoter",
                        "kind": "LinkedField",
                        "name": "billingPromoter",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "doorSalesOnly",
                            "value": false
                          },
                          {
                            "kind": "Literal",
                            "name": "includeArchived",
                            "value": false
                          }
                        ],
                        "concreteType": "TicketType",
                        "kind": "LinkedField",
                        "name": "ticketTypes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "streamLink",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:false)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allocation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "costCurrency",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Sales",
                        "kind": "LinkedField",
                        "name": "sales",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalSold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalValue",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "onSaleToday",
            "args": (v1/*: any*/),
            "filters": [
              "scopes",
              "where",
              "orderBy"
            ],
            "handle": "connection",
            "key": "OnSaleToday_onSaleToday",
            "kind": "LinkedHandle",
            "name": "events"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b552e915e947cc2281414825634b767e",
    "id": null,
    "metadata": {},
    "name": "OnSaleTodayQuery",
    "operationKind": "query",
    "text": "query OnSaleTodayQuery(\n  $count: Int = 10\n  $cursor: String = null\n) {\n  viewer {\n    ...OnSaleToday_viewer_1G22uz\n    id\n  }\n}\n\nfragment DashboardEventCardInfo_event on Event {\n  id\n  name\n  state\n  date\n  timezoneName\n  statusAsOfNow\n  flags {\n    hidden\n  }\n  eventImages {\n    type\n    cdnUrl\n    id\n  }\n  eventType\n  venueName\n  primaryVenue {\n    id\n    name\n  }\n  venues {\n    id\n    name\n  }\n  billingPromoter {\n    id\n    name\n  }\n  ticketTypes(doorSalesOnly: false, includeArchived: false) {\n    streamLink\n    id\n  }\n}\n\nfragment DashboardEventSalesProgress_event on Event {\n  id\n  allocation\n  costCurrency\n  sales {\n    totalSold\n    totalValue\n  }\n}\n\nfragment OnSaleToday_viewer_1G22uz on Viewer {\n  onSaleToday: events(first: $count, after: $cursor, scopes: {lifeCycleState: LIVE}, where: {special: onSaleToday}, orderBy: [dateASC]) {\n    edges {\n      node {\n        id\n        ...DashboardEventCardInfo_event\n        ...DashboardEventSalesProgress_event\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ddb8078f753eade9698c9235c5d2c65";

export default node;
