import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { get, getOr, toInteger } from 'lodash/fp'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Divider, Form, FormRow } from '../../../../components/Form'
import SwitchField from '../../../../components/SwitchField'
import { color, input, mediaQuery } from '../../../../utils/variables'
import FormField from '../../../../components/FormField'
import { authContext } from '../../../../context/auth'
import Button from '../../../../components/Button'
import { textStyle } from '../../../../utils/typography'
import Svg from '../../../../components/Svg'
import EventAbbonamentoPickerModal from './EventAbbonamentoPickerModal'
import EventAbbonamentoSortableList from './EventAbbonamentoSortableList'
import { FormGroupLabel, RequiredMark } from '../../../../components/FormGroup'
import AlertBox from '../../../../components/AlertBox'
import { ConfirmationModal } from '../../../../components/ConfirmationModal'
import subscriptionCode from '../../utils/subscriptionCode'
import IEventFormTimeline from '../../types/Timeline'

interface IProps {
  readOnly: boolean
}

const EventAbbonamento: FC<IProps> = ({ readOnly }) => {
  const { user } = useContext(authContext)
  const intl = useIntl()
  const { values, setFieldValue, setFieldTouched, handleBlur, handleChange, touched, errors } =
    useFormikContext<IEventFormTimeline>()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const onChangeForceSubscription = useCallback(() => {
    if (values.attractiveFields?.forceSubscription && values.attractiveFields?.linkedEvents?.length) {
      setShowConfirmation(true)
    } else {
      if (values.attractiveFields?.forceSubscription) {
        setFieldValue('attractiveFields.forceSubscriptionLimit', 0)
      } else if (!values.attractiveFields?.forceSubscription && !values.attractiveFields?.subscriptionCode) {
        setFieldValue('attractiveFields.subscriptionCode', subscriptionCode(), true)
        setFieldTouched('attractiveFields.subscriptionCode', true)
      }
      setFieldValue('attractiveFields.forceSubscription', !values.attractiveFields?.forceSubscription)
    }
  }, [
    setFieldTouched,
    setFieldValue,
    values.attractiveFields?.forceSubscription,
    values.attractiveFields?.linkedEvents?.length,
    values.attractiveFields?.subscriptionCode,
  ])

  const onChangeForceSubscriptionLimit = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value
      setFieldValue('attractiveFields.forceSubscriptionLimit', toInteger(newVal))
    },
    [setFieldValue]
  )

  const eventCountError = useMemo(
    () => values.attractiveFields?.linkedEvents?.length !== values.attractiveFields?.forceSubscriptionLimit,
    [values.attractiveFields?.forceSubscriptionLimit, values.attractiveFields?.linkedEvents?.length]
  )

  const [showEventPicker, setShowEventPicker] = useState(false)

  const toggleEventPicker = useCallback(() => setShowEventPicker(!showEventPicker), [showEventPicker])

  const setSelectedEvents = useCallback(
    (events: any) => {
      setFieldValue('attractiveFields.linkedEvents', events)
      toggleEventPicker()
    },
    [setFieldValue, toggleEventPicker]
  )

  const ticketsHaveSold = useMemo(
    () => (values.sales?.totalAppSold || 0) + (values.sales?.totalPosSold || 0) > 0,
    [values.sales?.totalAppSold, values.sales?.totalPosSold]
  )

  const onConfirmSwitchOff = useCallback(() => {
    setFieldValue('attractiveFields.forceSubscription', false)
    setFieldValue('attractiveFields.forceSubscriptionLimit', 0)
    setFieldValue('attractiveFields.linkedEvents', [])
    setShowConfirmation(false)
  }, [setFieldValue])

  const onRejectSwitchOff = useCallback(() => setShowConfirmation(false), [])

  return (
    <Wrapper>
      {get('attractiveFields.forceSubscription', errors) && (
        <AlertBox icon="info-msg">{intl.formatMessage({ id: 'new_event.abbonamento.24h_alert' })}</AlertBox>
      )}
      <Frame>
        <FormRow>
          <SwitchField
            label={intl.formatMessage({ id: 'new_event.abbonamento.active.label' })}
            hint={
              <>
                {intl.formatMessage({ id: 'new_event.abbonamento.active.hint' })}{' '}
                <HelpLink to="/support?article=6273f46ec5cfff5d83fcd6c4" target="_blank">
                  {intl.formatMessage({ id: 'new_event.abbonamento.active.hint.help_link' })}
                  <Svg icon="open" height={18} />
                </HelpLink>
              </>
            }
            name="attractiveFields.forceSubscription"
            checked={!!values.attractiveFields?.forceSubscription}
            disabled={readOnly || (!user.diceStaff && values.state !== 'DRAFT')}
            onChange={onChangeForceSubscription}
            onBlur={handleBlur}
          />
        </FormRow>

        {!!values.attractiveFields?.forceSubscription && (
          <>
            <GreyBox>
              <FormRow columnOnMobile>
                <FormField
                  name="attractiveFields.forceSubscriptionLimit"
                  label={intl.formatMessage({ id: 'new_event.abbonamento.number_of_events.label' })}
                  hint={
                    !(
                      get('attractiveFields.forceSubscriptionLimit', touched) &&
                      get('attractiveFields.forceSubscriptionLimit', errors)
                    ) && intl.formatMessage({ id: 'new_event.abbonamento.number_of_events.hint' })
                  }
                  type="number"
                  min={0}
                  max={99}
                  step={1}
                  value={values.attractiveFields.forceSubscriptionLimit}
                  onChange={onChangeForceSubscriptionLimit}
                  onBlur={handleBlur}
                  disabled={readOnly || ticketsHaveSold || (!user.diceStaff && values.state !== 'DRAFT')}
                  error={
                    get('attractiveFields.forceSubscriptionLimit', touched) &&
                    get('attractiveFields.forceSubscriptionLimit', errors)
                  }
                  required
                />

                <FormField
                  name="attractiveFields.subscriptionCode"
                  label={intl.formatMessage({ id: 'new_event.abbonamento.reporting_code.label' })}
                  hint={
                    !(
                      get('attractiveFields.subscriptionCode', touched) &&
                      get('attractiveFields.subscriptionCode', errors)
                    ) && intl.formatMessage({ id: 'new_event.abbonamento.reporting_code.hint' })
                  }
                  value={values.attractiveFields.subscriptionCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={readOnly || !user.diceStaff}
                  error={
                    get('attractiveFields.subscriptionCode', touched) &&
                    get('attractiveFields.subscriptionCode', errors)
                  }
                  required
                />
              </FormRow>

              {values.attractiveFields.linkedEvents?.length ? (
                <ListWrapper>
                  <FormGroupLabel>
                    {intl.formatMessage({ id: 'new_event.abbonamento.linked_events' })}
                    <RequiredMark />
                  </FormGroupLabel>
                  <EventAbbonamentoSortableList
                    events={values.attractiveFields.linkedEvents}
                    disabled={readOnly || (!user.diceStaff && values.state !== 'DRAFT')}
                  />
                </ListWrapper>
              ) : (
                <Divider />
              )}

              <Controls>
                <Button
                  icon="add"
                  size="small"
                  onClick={toggleEventPicker}
                  disabled={
                    (values.attractiveFields.forceSubscriptionLimit || 0) < 1 ||
                    (values.attractiveFields.forceSubscriptionLimit || 0) ===
                      (values.attractiveFields.linkedEvents?.length || 0) ||
                    (!user.diceStaff && values.state !== 'DRAFT')
                  }
                >
                  {intl.formatMessage({ id: 'new_event.abbonamento.link_events_button' })}
                </Button>
                <Counter>
                  {(values.attractiveFields.forceSubscriptionLimit || 0) > 0 && (
                    <IconWrapper error={eventCountError}>
                      <Svg icon={eventCountError ? 'alert' : 'check'} height={18} />
                    </IconWrapper>
                  )}
                  {intl.formatMessage(
                    { id: 'new_event.abbonamento.events_linked_indicator' },
                    {
                      arg0: values.attractiveFields.linkedEvents?.length || 0,
                      arg1: values.attractiveFields.forceSubscriptionLimit || 0,
                    }
                  )}
                </Counter>
              </Controls>
            </GreyBox>

            {!!get('attractiveFields.linkedEvents', errors) &&
              !getOr(null, 'attractiveFields.forceSubscriptionLimit', errors) && (
              <LinkedEventsError>
                {intl.formatMessage(
                  { id: get('attractiveFields.linkedEvents', errors) },
                  {
                    arg0: values.attractiveFields.forceSubscriptionLimit,
                  }
                )}
              </LinkedEventsError>
            )}
          </>
        )}
      </Frame>
      {showEventPicker && (
        <EventAbbonamentoPickerModal
          onCancel={toggleEventPicker}
          onConfirm={setSelectedEvents}
          selectedEvents={values.attractiveFields?.linkedEvents || []}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          icon="alert"
          onReject={onRejectSwitchOff}
          onConfirm={onConfirmSwitchOff}
          cta={intl.formatMessage({ id: 'confirm' })}
          description={intl.formatMessage({ id: 'new_event.abbonamento.confirmation.description' })}
          title={intl.formatMessage({ id: 'new_event.abbonamento.confirmation.title' })}
        />
      )}
    </Wrapper>
  )
}

export default EventAbbonamento

const Wrapper = styled(Form)`
  margin-top: 32px;

  ${AlertBox} {
    width: 100%;
  }
`

const Frame = styled.div`
  border: ${input.borderWidth}px solid ${input.borderColor};
  border-radius: ${input.borderRadius}px;

  padding: 16px;
  margin-top: 16px;
`

const HelpLink = styled(Link)`
  color: ${color.primary};

  ${Svg} {
    margin-bottom: -4px;
  }
`

const GreyBox = styled.div`
  background-color: ${color.palegrey};
  border-radius: 4px;
  padding: 16px;
  margin-top: 32px;
`

const ListWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: -8px;

  ${FormGroupLabel} {
    margin-bottom: 0;
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 24px;
  `}
`

const Counter = styled.div`
  border: ${input.borderWidth}px solid ${input.borderColor};
  border-radius: ${input.borderRadius}px;
  ${textStyle.label.regular}
  text-transform: uppercase;
  padding: 4px;
  display: flex;
  gap: 4px;
  align-items: center;
`

const IconWrapper = styled.div<{ error?: boolean }>`
  border-radius: ${input.borderRadius}px;
  background: ${({ error }) => (error ? color.error + '40' : color.success + '40')};
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
`

const LinkedEventsError = styled.div`
  ${textStyle.functional.sm};
  color: ${color.error};
  margin-top: 16px;
`
