import { isPast, parseISO } from 'date-fns'
import { find, findIndex, get, keyBy, map, mapValues, sortBy } from 'lodash/fp'
import { PriceTierTypes } from '../enums.generated'

interface ISimpleTierContainer {
  appSold: number
  posSold: number
  priceTier: {
    id: string
    time: string | null
    allocation: number | null
  }
}

export const closedPriceTier = (
  priceTierType: PriceTierTypes | null,
  priceTiers: ReadonlyArray<ISimpleTierContainer | null>,
  pt: ISimpleTierContainer,
  isTicketPool?: boolean
) => {
  if (
    priceTierType === 'allocation' &&
    isTicketPool &&
    pt.priceTier.id === priceTiers[priceTiers.length - 1]?.priceTier.id
  ) {
    return false
  }
  if (priceTierType === 'allocation' && (pt.appSold || 0) + (pt.posSold || 0) >= (pt.priceTier.allocation || 0)) {
    return true
  }

  if (priceTierType === 'time') {
    const sortedPT = sortBy('priceTier.time', priceTiers)
    const ptIndex = findIndex(pt, sortedPT)
    const lastIndex = sortedPT.length - 1
    const nextPt = ptIndex !== lastIndex ? sortedPT[ptIndex + 1] : null
    return nextPt?.priceTier?.time ? isPast(parseISO(nextPt.priceTier.time)) : false
  }

  return false
}

export const isPriceTierActive = (
  tt: {
    ticketType: { priceTierType: PriceTierTypes | null; ticketPoolId?: string | null }
    priceTiersBreakdown: ReadonlyArray<ISimpleTierContainer | null>
  },
  pt: ISimpleTierContainer
): boolean => {
  const isTicketPool = !!tt.ticketType.ticketPoolId
  const sortedPT = (
    tt.ticketType.priceTierType === 'time'
      ? sortBy('priceTier.time', tt.priceTiersBreakdown || [])
      : tt.priceTiersBreakdown || []
  ) as ISimpleTierContainer[]

  const closednessOfTiers = mapValues(
    get('closed'),
    keyBy(
      'id',
      map(
        (pt) =>
          pt && {
            id: pt.priceTier.id,
            closed: closedPriceTier(tt.ticketType.priceTierType, sortedPT, pt, isTicketPool),
          },
        sortedPT
      )
    )
  )

  const firstOpenTier = find((pt) => pt && !closednessOfTiers[pt.priceTier.id], sortedPT) as
    | { priceTier: { id: string } | null }
    | undefined
    | null

  return firstOpenTier?.priceTier?.id === pt.priceTier.id
}
