import React, { FC, useCallback } from 'react'
import { useIntl } from 'react-intl'
import qs from 'qs'
import graphql from 'babel-plugin-relay/macro'
import { commitMutation, Environment, useRelayEnvironment } from 'react-relay'
import { nanoid } from 'nanoid'
import { useLocation } from 'react-router'

import Button from '../../../../components/Button'
import ConfirmationPage, { STATUSES } from '../../../../components/ConfirmationPage'

const finishConnect = (environment: Environment, code: string) =>
  new Promise<'success' | 'failure'>((resolve, reject) => {
    commitMutation(environment, {
      mutation: graphql`
        mutation MailchimpConfirmationMutation($input: RequestMailchimpCredentialsInput!) {
          requestMailchimpCredentials(input: $input) {
            clientMutationId
          }
        }
      `,
      variables: {
        input: {
          clientMutationId: nanoid(),
          code,
        },
      },
      onCompleted: (_data, errors) => {
        if (errors && errors.length > 0) {
          console.error('Error finishing mailchimp connect', errors)
        }
        const status = (!(errors && errors.length > 0) ? STATUSES.SUCCESS : STATUSES.FAILURE) as 'success' | 'failure'

        resolve(status)
      },
      onError: reject,
    })
  })

const MailchimpConfirmation: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const location = useLocation()
  const environment = useRelayEnvironment()

  const extractParams = useCallback(() => {
    const { code } = qs.parse(location.search, { ignoreQueryPrefix: true })
    return code ? [environment, code] : null
  }, [environment, location.search])

  return (
    <ConfirmationPage prefix="mailchimp.connect_confirmation" extractParams={extractParams} fetchData={finishConnect}>
      <Button className="mt-md" color="secondary" to="/tools/integrations">
        {intl.formatMessage({ id: 'actions.continue' })}
      </Button>
    </ConfirmationPage>
  )
}

export default MailchimpConfirmation
