import React, { FC, memo, useCallback, useState, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { useMediaQuery } from 'react-responsive'
import {
  compact,
  groupBy,
  concat,
  find,
  findIndex,
  reject,
  set,
  update,
  without,
  compose,
  isEmpty,
  filter,
} from 'lodash/fp'
import arrayMove from 'array-move'

import { authContext } from '../../../context/auth'
import { allowedEventAction } from '../services/allowedEventAction'
import { IFormStep } from '../services/getStepsConfig'
import { isNew, markAsClientOnly } from '../../../utils/entityStatus'
import { breakpoints, color, font, mediaQuery } from '../../../utils/variables'

import Badge from '../../../components/Badge'
import Button from '../../../components/Button'
import FormHeader from '../../../components/FormHeader'
import FormGroup from '../../../components/FormGroup'
import { Form, FormRow } from '../../../components/Form'
import ListAddButton from '../../../components/ListAddButton'
import Svg from '../../../components/Svg'

import IEventFormExtras, { IProduct } from '../types/Extras'

import useEventProduct from '../hooks/useEventProduct'
import EventProductSummary from '../components/EventProductSummary'
import EventProductModal from '../components/EventProductModal'
import EventProductList from '../components/EventProductList'

const EmptyListWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  background: rgba(0, 0, 254, 0.08);
  border-radius: 8px;

  ${Badge} {
    font-weight: ${font.weight.bold};
    background: none;
    border: 1px solid ${color.tertiary};
    color: ${color.tertiary};
    letter-spacing: normal;
    line-height: 15px;
    margin-bottom: 8px;
  }

  & > svg {
    flex: 152px 0 0;
    width: 152px;
    height: 152px;

    ${mediaQuery.lessThan('tablet')`
      display: none;
    `}
  }
`

const EmptyListText = styled.div`
  max-width: 430px;
`

export const EXTRAS_TYPES_ORDER = ['EXPERIENCE', 'TRAVEL_AND_ACCOMMODATION', 'FOOD_AND_DRINK', 'MERCH']

const MerchStep: FC<React.PropsWithChildren<IFormStep>> = ({ viewer, children, readOnly }) => {
  const intl = useIntl()
  const { user, account, hasPermission } = useContext(authContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { values, setFieldValue, setFieldTouched, errors, validateForm } = useFormikContext<IEventFormExtras>()

  const groupedProducts = useMemo(
    () =>
      compose([
        groupBy((product: IProduct) => (product.archived ? 'archived' : 'live')),
        filter((product: IProduct) => product?.category?.parentCategory?.type === 'MERCH'),
      ])(values.products || []) || {},
    [values.products]
  )

  const allProducts = useMemo(() => compact(values.products || []) as any, [values.products])

  const [modalId, setModalId] = useState<string | null>(null)

  const ctx = { onAdd: setModalId }
  const { addProduct, copyProduct, removeProduct, restoreProduct } = useEventProduct(allProducts, ctx)

  const reorderProducts = useCallback(
    ({ oldIndex, newIndex }: any, e: any) => {
      const restOfProducts = without(groupedProducts['live'], allProducts)
      setFieldValue('products', concat(arrayMove(groupedProducts['live'], oldIndex, newIndex), restOfProducts))
      setFieldTouched('products', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [allProducts, groupedProducts, setFieldTouched, setFieldValue, validateForm]
  )

  const openEditModal = useCallback((productId: string) => {
    setModalId(productId)
  }, [])

  const closeEditModal = useCallback(() => {
    setModalId(null)
    setFieldValue('products', reject(isNew, allProducts))
  }, [allProducts, setFieldValue])

  const saveProduct = useCallback(
    (product: IProduct) => {
      setFieldValue(
        'products',
        set(
          [findIndex(['id', product.id], values.products)],
          update('id', (id) => (isNew({ id }) ? markAsClientOnly({ id }).id : id), product),
          allProducts
        )
      )
      setModalId(null)
      setFieldTouched('products', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, values.products, allProducts, setFieldTouched, validateForm]
  )

  const canAdd = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canAdd')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )
  const canChangeOrder = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canChangeOrder')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )
  const canRemove = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canDelete')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )
  const canUpdate = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canUpdate')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )

  const canAddExtras = useMemo(
    () => hasPermission('add_extras:event') && (values.state === 'DRAFT' || values?.allowedActions?.addProducts),
    [hasPermission, values?.allowedActions?.addProducts, values.state]
  )

  return user.diceStaff || account?.merchEnabled || values?.allowedActions?.readExtras ? (
    <Form spacing={isMobile ? 'default' : 'extra'} className="mb-md">
      {(groupedProducts['live']?.length || 0) > 0 ? (
        <>
          <FormHeader
            header={intl.formatMessage({ id: 'new_event.steps.merch' })}
            subheader={intl.formatMessage({ id: 'new_event.merch.description' })}
          />
          {/* LIVE */}
          <FormRow>
            <div>
              <EventProductList
                allProducts={allProducts}
                products={groupedProducts['live'] || []}
                currency={values.costCurrency || undefined}
                onSortEnd={reorderProducts}
                remove={removeProduct}
                restore={restoreProduct}
                copy={copyProduct}
                showEditModal={openEditModal}
                lockAxis="y"
                useDragHandle
                errors={errors}
                modalId={modalId}
                sortingDisabled={!canAddExtras || readOnly || !canChangeOrder}
                removeDisabled={!canAddExtras || readOnly || !canRemove}
                copyDisabled={!canAddExtras || readOnly || !canAdd}
              />
            </div>
          </FormRow>
          {!readOnly && canAdd && (
            <FormRow className="mt-zero pt-md">
              <ListAddButton
                className="mt-zero"
                label={intl.formatMessage({ id: 'new_event.merch.list.add_button' })}
                onClick={addProduct}
              />
            </FormRow>
          )}
        </>
      ) : (
        <FormRow>
          <EmptyListWrapper>
            <EmptyListText>
              <Badge>{intl.formatMessage({ id: 'new_event.step_badge.beta' })}</Badge>
              <FormHeader
                header={intl.formatMessage({ id: 'new_event.steps.merch' })}
                subheader={intl.formatMessage({ id: 'new_event.merch.empty_placeholder.description' })}
              />
              {!readOnly && canAdd && (
                <FormRow>
                  <Button icon="add" size="small" onClick={addProduct}>
                    {intl.formatMessage({ id: 'new_event.merch.list.empty_add_button' })}
                  </Button>
                </FormRow>
              )}
            </EmptyListText>
            <Svg icon="extras_merch" />
          </EmptyListWrapper>
        </FormRow>
      )}

      {/* ARCHIVED */}
      {user.diceStaff && (groupedProducts['archived']?.length || 0) > 0 && (
        <FormRow columnOnMobile>
          <FormGroup
            dice
            label={`[${intl.formatMessage({
              id: 'new_event.tickets.ticket_types.archived_label',
            })}] ${intl.formatMessage({ id: 'new_event.extras.list.type.merch' })}`}
          >
            <FormRow>
              <div>
                <EventProductList
                  allProducts={allProducts}
                  products={groupedProducts['archived'] || []}
                  currency={values.costCurrency || undefined}
                  onSortEnd={reorderProducts}
                  remove={removeProduct}
                  restore={restoreProduct}
                  copy={copyProduct}
                  showEditModal={openEditModal}
                  lockAxis="y"
                  useDragHandle
                  errors={errors}
                  modalId={modalId}
                  sortingDisabled
                  removeDisabled
                  copyDisabled
                />
              </div>
            </FormRow>
          </FormGroup>
        </FormRow>
      )}

      {/* SUMMARY */}
      {!isEmpty(groupedProducts['live']) && (
        <FormRow>
          <EventProductSummary type="merch" />
        </FormRow>
      )}

      {modalId !== null && find(['id', modalId], values.products) ? (
        <EventProductModal
          event={null}
          eventForm={values}
          productId={modalId}
          productType="merch"
          onClose={closeEditModal}
          onSave={saveProduct}
          readOnly={readOnly || !canUpdate}
        />
      ) : null}
    </Form>
  ) : null
}

export default createFragmentContainer(memo(MerchStep), {
  viewer: graphql`
    fragment Merch_viewer on Viewer {
      id
    }
  `,
  event: graphql`
    fragment Merch_event on Event {
      ...EventProductModal_event @relay(mask: false)

      id
      eventType
      date
      endDate
      onSaleDate
      offSaleDate
      closeEventDate
      announceDate
      scheduleStatus
      timezoneName
      state
      statusAsOfNow
      eventIdLive
      allowedActions {
        addProducts
        readExtras
        readMerch
        manageProductsAllocation
        minorUpdate
      }
      allowedLifecycleUpdates {
        products {
          canAdd
          canChangeOrder
          canDelete
          canUpdate
          canUpdatePrice
        }
      }
      feesBehaviour
      fees {
        amount
        type
        unit
        applicable

        split {
          amount
          destination
          unit
        }
      }
      disableUsTax
      products {
        id
        name
        archived
        productType
        rootType
        event {
          id
        }
        category {
          value: id
          label: name
          type
          rootType
          parentCategory {
            value: id
            label: name
            type
          }
        }
        optionType
        hasVariants
        variants {
          id
          allocation
          sku
          name
        }
        fulfilledBy
        sellingPoints {
          name
        }
        hasSeparateAccessBarcodes
        venue {
          value: id
          label: name
        }
        locationNote
        date
        endDate
        description
        faceValue
        allocation
        onSaleDate
        offSaleDate
        allTicketTypes
        ticketTypes {
          id
          name
        }
        priceBreakdown {
          total
          faceValue
          salesTax
          fees
          vatAmount
          vatRate
          breakdown {
            type
            applicable
            computed
            split {
              amount
              computed
              destination
              unit
            }
          }
          split {
            computed
            destination
          }
        }
        purchaseConfirmationMessage
      }
    }
  `,
})
