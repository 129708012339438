import React, { FC, memo, useCallback, useContext, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { commitMutation, useFragment, useRelayEnvironment } from 'react-relay'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import { array, object, string } from 'yup'
import { compact, map } from 'lodash/fp'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router'

import GenericError from '../../components/GenericError'
import { authContext } from '../../context/auth'
import { CreatePermissionProfile_viewer$key } from '../../__generated__/CreatePermissionProfile_viewer.graphql'
import { REGEX_VALIDATE_EMAIL } from '../../utils/regex'
import { breakpoints, color, mediaQuery } from '../../utils/variables'
import AutoScroller, { scrollToSection, useAutoScroll } from '../../components/AutoScroller'
import Button from '../../components/Button'
import { localeContext } from '../../context/locale'
import { Form, FormRow } from '../../components/Form'
import FormField from '../../components/FormField'
import TagsInput from '../../components/TagsInput'
import { unflattenPermissionList } from '../UsersAndPermissions/utils/structure'
import { CreatePermissionProfileMutation } from '../../__generated__/CreatePermissionProfileMutation.graphql'
import { notificationContext } from '../../context/notification'
import PermissionConfig from '../UsersAndPermissions/components/PermissionConfig'
import FormHeader from '../../components/FormHeader'
import { CancelButton, FormControls, FormControlsInner } from '../../components/FormControlsStyles'
import { NavItem, NavItems, NavPanel } from '../../components/NavBarStyles'
import useInviteUser from '../UsersAndPermissions/hooks/useInviteUser'

const Layout = styled.div`
  display: flex;
  min-height: calc(100% - 72px);

  ${mediaQuery.lessThan('desktop')`
    flex-direction: column;
  `};
`

const BodyContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  width: 100%;
  ${FormControls} {
    margin-top: auto;
  }
`

const Section = styled(AutoScroller)`
  & > div {
    padding: 48px;
    margin: auto;
    max-width: calc(100vw - 74px);

    ${mediaQuery.lessThan('desktop')`
      padding: 16px;
      max-width: none;
    `}
    ${mediaQuery.greaterThan('desktop')`
      max-width: calc(100vw - 314px);
    `}
    ${mediaQuery.greaterThan('desktopLarge')`
      max-width: 800px;
    `}
  }

  &:first-child {
    min-height: 50vh;
  }

  & + & {
    border-top: 2px solid ${color.text};
    padding-top: 16px;
  }
`

const CreateProfileSchema = object().shape({
  profileName: string().nullable().required(),
  inviteEmails: array().of(string().nullable().email().required()),
  enabledPermissions: array().of(string().nullable().required()).min(1),
})

interface IFormState {
  profileName: string | null
  inviteEmails: Array<string | null>
  enabledPermissions: Array<string>
}

interface IProps {
  viewer: CreatePermissionProfile_viewer$key
}

const CreatePermissionProfile: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const environment = useRelayEnvironment()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { phraseEnabled } = useContext(localeContext)
  const { account } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)

  const viewer = useFragment(
    graphql`
      fragment CreatePermissionProfile_viewer on Viewer {
        ...PermissionConfig_viewer
      }
    `,
    viewerKey
  )

  const [activeStep, setActiveStep] = useState('profile')
  useAutoScroll(setActiveStep, [])
  const doScroll = useCallback((e: any) => {
    const slug = e.currentTarget.dataset['slug']
    scrollToSection(slug, false, 72)
  }, [])

  const inviteUser = useInviteUser()

  const formik = useFormik<IFormState>({
    initialValues: {
      profileName: null,
      inviteEmails: [],
      enabledPermissions: [],
    },
    validationSchema: CreateProfileSchema,
    onSubmit: async (values) => {
      const struct = unflattenPermissionList(values.enabledPermissions)

      const response = await new Promise<CreatePermissionProfileMutation['response']>((resolve, rejectPromise) =>
        commitMutation<CreatePermissionProfileMutation>(environment, {
          mutation: graphql`
            mutation CreatePermissionProfileMutation($input: CreatePermissionProfileInput!) {
              createPermissionProfile(input: $input) {
                permissionProfile {
                  id
                  roleName
                  caption

                  subjects {
                    name
                    actions {
                      name
                    }
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              clientMutationId: nanoid(),
              caption: values.profileName,
              subjects: struct,
            },
          },
          onCompleted: (data, errors) => {
            if (errors) {
              rejectPromise(errors)
              return
            }
            resolve(data)
          },
          onError: rejectPromise,
        })
      )

      const permissionProfileId = response.createPermissionProfile?.permissionProfile?.id

      if (permissionProfileId && values.inviteEmails.length > 0 && account) {
        try {
          await Promise.all(map((email) => inviteUser(permissionProfileId, email), compact(values.inviteEmails)))
        } catch (err) {
          console.error(err)
          // Bad luck but we have to proceed, errors were reported automatically
        }
      }

      if (permissionProfileId) {
        addNotification('success', intl.formatMessage({ id: 'permissions.create_profile_success' }))
        navigate('/tools/permissions')
      }
    },
  })

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    isSubmitting,
    setFieldTouched,
    handleChange,
    submitForm,
    handleReset,
    handleSubmit,
  } = formik

  const setInviteEmails = useCallback(
    (emails: any) => {
      setFieldValue('inviteEmails', emails || [])
      // Normally we touch on blur but this inout can be updated without blur due to its nature
      setFieldTouched('inviteEmails')
    },
    [setFieldTouched, setFieldValue]
  )

  const setEnabledPermissions = useCallback(
    (perms: any) => {
      setFieldValue('enabledPermissions', perms || [])
      // Normally we touch on blur but this inout can be updated without blur due to its nature
      setFieldTouched('enabledPermissions')
    },
    [setFieldTouched, setFieldValue]
  )

  if (!account) {
    return (
      <GenericError
        icon="access-denied"
        title={intl.formatMessage({ id: 'generic_error.access_denied_title' })}
        description={intl.formatMessage({ id: 'generic_error.access_denied_description' })}
        backText={intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
        backLink="/dashboard"
      />
    )
  }

  return (
    <Layout>
      <NavPanel>
        <NavItems>
          <NavItem active={activeStep === 'profile'} data-slug="profile" onClick={doScroll}>
            {intl.formatMessage({ id: 'permissions.new_profile.steps.create_profile.name' })}
          </NavItem>
          <NavItem active={activeStep === 'invites'} data-slug="invites" onClick={doScroll}>
            {intl.formatMessage({ id: 'permissions.new_profile.steps.invite_users.name' })}
          </NavItem>
        </NavItems>
      </NavPanel>
      <BodyContainer>
        <Section slug="profile">
          <Form spacing={isMobile ? 'default' : 'extra'}>
            <form noValidate onSubmit={handleSubmit} onReset={handleReset}>
              <FormHeader
                header={intl.formatMessage({ id: 'permissions.new_profile.steps.create_profile.title' })}
                subheader={intl.formatMessage({ id: 'permissions.new_profile.steps.create_profile.description' })}
              />

              <FormRow columnOnMobile>
                <FormField
                  name="profileName"
                  label={intl.formatMessage({ id: 'permissions.new_profile.steps.create_profile.profile_name.label' })}
                  value={values.profileName || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.profileName && errors.profileName}
                  required
                  autoFocus
                />
              </FormRow>

              <FormRow columnOnMobile>
                <PermissionConfig
                  viewer={viewer}
                  enabledPermissions={values.enabledPermissions}
                  setEnabledPermissions={setEnabledPermissions}
                />
              </FormRow>
            </form>
          </Form>
        </Section>
        <Section slug="invites">
          <Form spacing={isMobile ? 'default' : 'extra'}>
            <FormHeader
              header={intl.formatMessage({ id: 'permissions.new_profile.steps.invite_users.title' })}
              subheader={intl.formatMessage({ id: 'permissions.new_profile.steps.invite_users.description' })}
            />

            <FormRow columnOnMobile>
              <FormField
                name="inviteEmails"
                label={intl.formatMessage({ id: 'permissions.new_profile.steps.invite_users.email.label' })}
                control={TagsInput}
                placeholder={intl.formatMessage({ id: 'permissions.new_profile.steps.invite_users.email.placeholder' })}
                value={values.inviteEmails}
                onChange={setInviteEmails}
                onBlur={handleBlur}
                validationRegex={REGEX_VALIDATE_EMAIL}
                error={touched.inviteEmails && errors.inviteEmails}
              />
            </FormRow>
          </Form>
        </Section>

        <FormControls ICEenabled={phraseEnabled}>
          <FormControlsInner>
            <Button type="submit" data-id="saveButton" loading={isSubmitting} disabled={!isValid} onClick={submitForm}>
              {intl.formatMessage({ id: 'permissions.new_profile.save_button' })}
            </Button>

            <div />

            <CancelButton preset="secondary" data-id="cancel" to="/tools/permissions">
              {intl.formatMessage({ id: 'actions.cancel' })}
            </CancelButton>
          </FormControlsInner>
        </FormControls>
      </BodyContainer>
    </Layout>
  )
}

export default memo(CreatePermissionProfile)
