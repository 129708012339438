import React, { FC, memo, Suspense, useCallback, useContext, useMemo, useState } from 'react'
import { compact, concat, find, map, reject, snakeCase } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { Writable } from 'ts-essentials'
import { isClientOnly } from '../utils/entityStatus'

import useLabels from '../utils/hooks/useLabels'
import Collapsible from './Collapsible'
import { ConfirmationModal } from './ConfirmationModal'
import Danger from './Danger'
import { FormRow } from './Form'
import FormField from './FormField'
import IconButton from './IconButton'
import { Loader, LoaderContainer } from './Loader'
import PermissionCheck from './PermissionCheck'
import SwitchField from './SwitchField'
import { trackingContext } from '../context/tracking'
import { authContext } from '../context/auth'

const StyledCollapsible = styled(Collapsible)`
  & > div {
    margin-top: 0;
  }
`

const StyledIconButton = styled(IconButton)`
  margin-left: 8px;

  width: 24px;
  height: 24px;
`

type ILabelOptions = ReadonlyArray<{
  value: string
  label: string | null
} | null> | null

interface IProps {
  kind?: 'promoter' | 'venue' | null
  readOnly?: boolean
  dice?: boolean
  promoterName?: string | null
  labels: ILabelOptions
  setLabels: (labels: Writable<ILabelOptions>) => void
}

const LabelsData: FC<IProps> = ({ kind, dice, readOnly, labels, setLabels, promoterName }) => {
  const intl = useIntl()

  const { user } = useContext(authContext)
  const { trackDiceEvent } = useContext(trackingContext)

  const [predefinedLabelOptions, doCreateLabel, creating] = useLabels(kind || undefined, promoterName)

  const otherLabels = useMemo(() => {
    const prefefinedSet = new Set(map((opt) => opt.name.toLowerCase(), predefinedLabelOptions))
    return compact(reject((lbl) => !lbl?.label || prefefinedSet.has(lbl.label.toLowerCase()), labels || []))
  }, [predefinedLabelOptions, labels])

  const [createModal, setCreateModal] = useState<null | string>(null)

  const confirmCreateLabel = useCallback((e: any) => {
    const id = e.currentTarget.dataset['id']
    setCreateModal(id)
  }, [])
  const closeCreateLabel = useCallback(() => setCreateModal(null), [])

  const createMissingLabel = useCallback(() => {
    const opt = find(['id', createModal], predefinedLabelOptions)
    if (opt) {
      doCreateLabel(opt.name, opt.description || null).then(() => {
        closeCreateLabel()
      })
    }
  }, [closeCreateLabel, createModal, doCreateLabel, predefinedLabelOptions])

  const changeLabels = useCallback(
    (e: any) => {
      const id = e.target.value
      const checked = e.target.checked

      const opt = find(['id', id], predefinedLabelOptions)

      if (user.diceStaff) {
        trackDiceEvent(`label_toggle_${snakeCase(opt?.name || 'unknown')}`)
      }

      setLabels(
        !checked
          ? reject(['value', id], labels || [])
          : concat(labels || ([] as ILabelOptions), [
            {
              value: id,
              label: opt?.name || null,
            },
          ] as ILabelOptions)
      )
    },
    [labels, predefinedLabelOptions, setLabels, trackDiceEvent, user.diceStaff]
  )

  return (
    <FormRow columnOnMobile>
      <StyledCollapsible
        label={intl.formatMessage({ id: 'new_event.settings.labels.label' })}
        initialCollapsed={(labels || []).length === 0}
        dice={dice}
        dataId="collapseButtonLabels"
      >
        {predefinedLabelOptions.map((lbl) => (
          <FormRow key={lbl.id}>
            <div>
              <SwitchField
                name={lbl.name}
                value={lbl.id}
                label={lbl.name}
                hint={
                  !isClientOnly(lbl) ? (
                    lbl.description
                  ) : (
                    <Danger>
                      {intl.formatMessage({ id: 'new_event.settings.labels.missing_error' })}
                      <PermissionCheck permission="create:label">
                        <StyledIconButton
                          icon="options"
                          title={intl.formatMessage({ id: 'new_event.settings.labels.missing_cta' })}
                          data-id={lbl.id}
                          onClick={confirmCreateLabel}
                        />
                      </PermissionCheck>
                    </Danger>
                  )
                }
                checked={!!find(['value', lbl.id], labels)}
                onChange={changeLabels}
                disabled={readOnly || isClientOnly(lbl)}
              />
              {createModal === lbl.id && (
                <ConfirmationModal
                  title={intl.formatMessage({ id: 'new_event.settings.labels.create_title' })}
                  description={intl.formatMessage(
                    { id: 'new_event.settings.labels.create_description' },
                    {
                      name: lbl.name,
                    }
                  )}
                  loading={creating}
                  onConfirm={createMissingLabel}
                  onReject={closeCreateLabel}
                />
              )}
            </div>
          </FormRow>
        ))}
        {otherLabels.length > 0 && (
          <FormRow>
            <FormField
              name="labels"
              label={intl.formatMessage({ id: 'new_event.settings.legacy_labels.label' })}
              hint={intl.formatMessage({ id: 'new_event.settings.legacy_labels.hint' })}
              control="select"
              multiple
              options={otherLabels}
              value={otherLabels}
              disabled
            />
          </FormRow>
        )}
      </StyledCollapsible>
    </FormRow>
  )
}

const Labels: FC<IProps> = (props) => (
  <Suspense
    fallback={
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    }
  >
    <LabelsData {...props} />
  </Suspense>
)

export default memo(Labels)
