/**
 * @generated SignedSource<<d55d2b7fe8f845c020634c2509006a34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Extras_viewer$data = {
  readonly id: string;
  readonly " $fragmentType": "Extras_viewer";
};
export type Extras_viewer$key = {
  readonly " $data"?: Extras_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Extras_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Extras_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "1cd01ef868afb5d7ba6efb9c439e2e44";

export default node;
