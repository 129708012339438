/**
 * @generated SignedSource<<10f402cd030461d4a0676a144ac9d1ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeAllocationInput = {
  allocation: number;
  clientMutationId: string;
  id: string;
};
export type ChangeAllocationModalMutation$variables = {
  input: ChangeAllocationInput;
};
export type ChangeAllocationModalMutation$data = {
  readonly changeAllocation: {
    readonly object: {
      readonly allocation: number;
      readonly id: string;
      readonly lockVersion: number;
      readonly previewToken: string | null;
      readonly sales: {
        readonly ticketTypesBreakdown: ReadonlyArray<{
          readonly priceTiersBreakdown: ReadonlyArray<{
            readonly appSold: number;
            readonly posSold: number;
            readonly priceTier: {
              readonly allocation: number | null;
              readonly id: string;
              readonly name: string | null;
            };
            readonly reserved: number;
          } | null>;
          readonly ticketType: {
            readonly allocation: number;
            readonly id: string;
            readonly name: string | null;
          };
          readonly totalAppSold: number;
          readonly totalPosSold: number;
          readonly totalReserved: number;
        } | null>;
      } | null;
    } | null;
  } | null;
};
export type ChangeAllocationModalMutation = {
  response: ChangeAllocationModalMutation$data;
  variables: ChangeAllocationModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeAllocationPayload",
    "kind": "LinkedField",
    "name": "changeAllocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "object",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previewToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Sales",
            "kind": "LinkedField",
            "name": "sales",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketTypeBreakdown",
                "kind": "LinkedField",
                "name": "ticketTypesBreakdown",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketType",
                    "kind": "LinkedField",
                    "name": "ticketType",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalAppSold",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalPosSold",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalReserved",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceTierBreakdown",
                    "kind": "LinkedField",
                    "name": "priceTiersBreakdown",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appSold",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "posSold",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reserved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceTier",
                        "kind": "LinkedField",
                        "name": "priceTier",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeAllocationModalMutation",
    "selections": (v4/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeAllocationModalMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "bd138aec3227f73a1ba41235bc00dd48",
    "id": null,
    "metadata": {},
    "name": "ChangeAllocationModalMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeAllocationModalMutation(\n  $input: ChangeAllocationInput!\n) {\n  changeAllocation(input: $input) {\n    object {\n      id\n      allocation\n      lockVersion\n      previewToken\n      sales {\n        ticketTypesBreakdown {\n          ticketType {\n            id\n            name\n            allocation\n          }\n          totalAppSold\n          totalPosSold\n          totalReserved\n          priceTiersBreakdown {\n            appSold\n            posSold\n            reserved\n            priceTier {\n              id\n              name\n              allocation\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "edcb1aeb983fef95ac0cd96fc7801e6f";

export default node;
