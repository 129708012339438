import React, { memo, FC, useMemo, useCallback, useEffect, useContext } from 'react'
import { useFormikContext } from 'formik'
import { IntlShape, useIntl } from 'react-intl'
import { compact, compose, find, flatMap, get, map, set } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import EventAttractiveGenreSelect from './EventAttractiveGenreSelect'
import IEventFormBasics, { IVenue, IVenueConfiguration } from '../types/Basics'
import { allowedEventAction } from '../services/allowedEventAction'
import { breakpoints } from '../../../utils/variables'
import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import SwitchField from '../../../components/SwitchField'
import { authContext } from '../../../context/auth'
import EventVenueSchedule from './EventVenueSchedule'
import subscriptionCode from '../utils/subscriptionCode'
import EventSeatingAreas from './EventSeatingAreas'
import { EventNtsSeatingAreaTypeSelectQuery } from '../../../__generated__/EventNtsSeatingAreaTypeSelectQuery.graphql'

const TEXT_MAX_LENGTH = 50
const DICE_TEXT_MAX_LENGTH = 255

const decorateVenueConfiguration =
  (intl: IntlShape, venueName: string | null, shortHint: boolean) => (cfg: IVenueConfiguration | null) =>
    cfg &&
    // prettier-ignore
    compose([
      set('label', venueName ? `${cfg.label} - ${venueName}` : cfg.label),
      set(
        'hint',
        shortHint
          ? `${intl.formatNumber(cfg.capacity || 0, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })} / ${cfg.attractiveRoomSiaeCode || intl.formatMessage({ id: 'na' })}`
          : intl.formatMessage(
            { id: 'new_event.basics.venue_configuration.params' },
            {
              capacity: intl.formatNumber(cfg.capacity || 0, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              roomSiaeCode: cfg.attractiveRoomSiaeCode || intl.formatMessage({ id: 'na' }),
            }
          )
      ),
    ])(cfg)

interface IProps {
  readOnly?: boolean
}

const EventNts: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { viewer } = useLazyLoadQuery<EventNtsSeatingAreaTypeSelectQuery>(
    graphql`
      query EventNtsSeatingAreaTypeSelectQuery {
        viewer {
          externalEntities(entityType: "seating_area_type", integrationType: "attractive") {
            label: name
            value
          }
        }
      }
    `,
    {}
  )

  const seatingAreaTypeOptions = useMemo(() => compact(viewer?.externalEntities || []), [viewer?.externalEntities])

  const {
    initialValues,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleChange,
  } = useFormikContext<IEventFormBasics>()

  const setCompatibilityAe = useCallback(
    (v: any) => {
      setFieldValue('attractiveFields.compatibilityAe', v)
      setFieldTouched('attractiveFields.compatibilityAe', true, true)

      const isRequiresNomination = v === '_5000'

      setFieldValue('requiresTicketNomination', isRequiresNomination)
      setFieldValue('requiresBoxOfficeTicketNomination', isRequiresNomination)
      setFieldTouched('requiresTicketNomination', isRequiresNomination, true)
      setFieldTouched('requiresBoxOfficeTicketNomination', isRequiresNomination, true)

      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const compatibilityAeOptions = useMemo(
    () => [
      { value: '_0', label: intl.formatMessage({ id: 'na' }) },
      { value: '_1000', label: '+1000' },
      { value: '_5000', label: '+5000' },
    ],
    [intl]
  )

  const compatibilityAe = useMemo(
    () =>
      values.attractiveFields?.compatibilityAe &&
      find(['value', values.attractiveFields?.compatibilityAe || '_0'], compatibilityAeOptions),
    [values.attractiveFields?.compatibilityAe, compatibilityAeOptions]
  )

  const setVenueConfiguration = useCallback(
    (_: any, cfg: IVenueConfiguration) => {
      setFieldValue('venueConfiguration', cfg)
      setFieldTouched('venueConfiguration', true, true)

      let compatibilityAe = '_0'

      if (cfg.capacity > 5000) {
        compatibilityAe = '_5000'
      } else if (cfg.capacity > 1000) {
        compatibilityAe = '_1000'
      }

      setCompatibilityAe(compatibilityAe)

      setFieldValue('attractiveFields.seatingAreaConfigs', cfg.seatingAreaConfigs || null, true)
      setFieldTouched('attractiveFields.seatingAreaConfigs', true, true)

      setTimeout(() => validateForm(), 0)
    },
    [setCompatibilityAe, setFieldTouched, setFieldValue, validateForm]
  )

  const venueConfigurationOptions = useMemo(
    () =>
      compose([
        map(([venueNeme, cfg]) => decorateVenueConfiguration(intl, venueNeme, isMobile)(cfg)),
        flatMap((v: IVenue) => map((cfg: IVenueConfiguration) => [v.label, cfg], v.configurations || [])),
        compact,
      ])(values.venues || []),
    [intl, values.venues, isMobile]
  )

  const venueConfiguration = useMemo(
    () => values.venueConfiguration && find(['value', values.venueConfiguration.value], venueConfigurationOptions),
    [values.venueConfiguration, venueConfigurationOptions]
  )

  const handleAttractiveTaxFree = useCallback(() => {
    setFieldValue('attractiveFields.taxFree', !values.attractiveFields?.taxFree, true)
    setFieldTouched('attractiveFields.taxFree', true)
  }, [setFieldTouched, setFieldValue, values.attractiveFields?.taxFree])

  useEffect(() => {
    const canChange = !(
      readOnly ||
      (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
    )

    if (!canChange) return

    if (!venueConfiguration && venueConfigurationOptions.length === 1) {
      const defaultOption = venueConfigurationOptions[0]
      setVenueConfiguration(defaultOption.value, defaultOption)
    }

    if (!compatibilityAe && compatibilityAeOptions.length > 0) {
      setCompatibilityAe(compatibilityAeOptions[0].value)
    }
  }, [
    compatibilityAe,
    compatibilityAeOptions,
    readOnly,
    setCompatibilityAe,
    setVenueConfiguration,
    values.allowedLifecycleUpdates,
    values.state,
    venueConfiguration,
    venueConfigurationOptions,
  ])

  const isAlreadyIntegrated = !!values.attractiveStatus

  return (
    <>
      {user.diceStaff && (
        <FormRow columnOnMobile>
          <SwitchField
            label={intl.formatMessage({
              id: 'new_event.tickets.ticket_type_edit.attractive_tax_free.label',
            })}
            hint={intl.formatMessage({
              id: 'new_event.tickets.ticket_type_edit.attractive_tax_free.hint',
            })}
            name="attractiveFields.taxFree"
            checked={!!values.attractiveFields?.taxFree}
            disabled={
              readOnly ||
              !hasPermission('allow_attractive_admin_edits:event') ||
              (values.state !== 'DRAFT' &&
                !(allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') && !isAlreadyIntegrated))
            }
            onChange={handleAttractiveTaxFree}
            onBlur={handleBlur}
            dice
          />
        </FormRow>
      )}

      <FormRow columnOnMobile>
        <FormField
          name="venueConfiguration"
          label={intl.formatMessage({ id: 'new_event.basics.venue_configuration.label' })}
          control="select"
          searchable
          value={venueConfiguration}
          options={venueConfigurationOptions}
          onBlur={handleBlur}
          onChange={setVenueConfiguration}
          error={touched.venueConfiguration && errors.venueConfiguration}
          disabled={
            readOnly ||
            (values.state !== 'DRAFT' &&
              !allowedEventAction(values.allowedLifecycleUpdates, 'venues') &&
              !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
          }
        />
      </FormRow>

      <FormRow columnOnMobile>
        <FormField
          name="attractiveFields.compatibilityAe"
          label={intl.formatMessage({ id: 'new_event.basics.compatibility_ae.label' })}
          control="select"
          searchable
          value={compatibilityAe}
          options={compatibilityAeOptions}
          onBlur={handleBlur}
          onChange={setCompatibilityAe}
          error={
            (touched as any).attractiveFields?.compatibilityAe && (errors as any).attractiveFields?.compatibilityAe
          }
          disabled={
            readOnly ||
            !values.allowedLifecycleUpdates && !user.diceStaff ||
            (!allowedEventAction(values.allowedLifecycleUpdates, 'attractiveCompatibilityAe') &&
            !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
          }
        />
      </FormRow>

      <EventAttractiveGenreSelect
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        values={values}
        initialValues={initialValues}
        setFieldValue={setFieldValue}
        disabled={
          readOnly || (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
        }
      />

      {['01', '45', '49', '54', '59'].includes(values?.attractiveFields?.siaeGenreType as string) && (
        <>
          <FormRow columnOnMobile>
            <FormField
              name="attractiveFields.author"
              label={intl.formatMessage({ id: 'new_event.basics.author.label' })}
              placeholder={intl.formatMessage({ id: 'new_event.basics.author.placeholder' })}
              maxLength={
                user.diceStaff || hasPermission('full_manage_access:event') ? DICE_TEXT_MAX_LENGTH : TEXT_MAX_LENGTH
              }
              value={values.attractiveFields?.author || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(touched as any).attractiveFields?.author && (errors as any).attractiveFields?.author}
              disabled={
                readOnly ||
                (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
              }
            />

            <FormField
              name="attractiveFields.distributor"
              label={intl.formatMessage({ id: 'new_event.basics.distributor.label' })}
              placeholder={intl.formatMessage({ id: 'new_event.basics.distributor.placeholder' })}
              maxLength={
                user.diceStaff || hasPermission('full_manage_access:event') ? DICE_TEXT_MAX_LENGTH : TEXT_MAX_LENGTH
              }
              value={values.attractiveFields?.distributor || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(touched as any).attractiveFields?.distributor && (errors as any).attractiveFields?.distributor}
              disabled={
                readOnly ||
                (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
              }
            />
          </FormRow>

          <FormRow columnOnMobile>
            <FormField
              name="attractiveFields.nationality"
              label={intl.formatMessage({ id: 'new_event.basics.nationality.label' })}
              placeholder={intl.formatMessage({ id: 'new_event.basics.nationality.placeholder' })}
              maxLength={
                user.diceStaff || hasPermission('full_manage_access:event') ? DICE_TEXT_MAX_LENGTH : TEXT_MAX_LENGTH
              }
              value={values.attractiveFields?.nationality || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(touched as any).attractiveFields?.nationality && (errors as any).attractiveFields?.nationality}
              disabled={
                readOnly ||
                (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
              }
            />

            <FormField
              name="attractiveFields.producer"
              label={intl.formatMessage({ id: 'new_event.basics.producer.label' })}
              placeholder={intl.formatMessage({ id: 'new_event.basics.producer.placeholder' })}
              maxLength={
                user.diceStaff || hasPermission('full_manage_access:event') ? DICE_TEXT_MAX_LENGTH : TEXT_MAX_LENGTH
              }
              value={values.attractiveFields?.producer || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(touched as any).attractiveFields?.producer && (errors as any).attractiveFields?.producer}
              disabled={
                readOnly ||
                (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
              }
            />
          </FormRow>

          {values?.attractiveFields?.siaeGenreType !== '01' && (
            <FormRow columnOnMobile>
              <FormField
                name="attractiveFields.performer"
                label={intl.formatMessage({ id: 'new_event.basics.performer.label' })}
                placeholder={intl.formatMessage({ id: 'new_event.basics.performer.placeholder' })}
                maxLength={
                  user.diceStaff || hasPermission('full_manage_access:event') ? DICE_TEXT_MAX_LENGTH : TEXT_MAX_LENGTH
                }
                value={values.attractiveFields?.performer || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(touched as any).attractiveFields?.performer && (errors as any).attractiveFields?.performer}
                disabled={
                  readOnly ||
                  (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
                }
              />
            </FormRow>
          )}
        </>
      )}

      {user.diceStaff && (
        <>
          <EventSeatingAreas
            seatingAreaTypeOptions={seatingAreaTypeOptions}
            readOnly={
              readOnly || (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
            }
          />
          <EventVenueSchedule
            readOnly={
              readOnly ||
              (values.state !== 'DRAFT' &&
                !(allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') && !isAlreadyIntegrated))
            }
          />
        </>
      )}
    </>
  )
}

export default memo(EventNts)
