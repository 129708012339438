import React, { forwardRef } from 'react'
import cn from 'classnames'
import { merge, omit } from 'lodash/fp'
import { Link, NavLink } from 'react-router-dom'

interface IProps {
  component?: any
  className?: string
  active?: boolean
  activeClassName?: string
  to?: any
  href?: string
  target?: string
  type?: string
  role?: string
  disabled?: boolean
  loading?: boolean
  hover?: boolean
}

const ButtonBase = forwardRef<any, IProps & { [prop: string]: any }>((props, ref) => {
  const {
    component = 'button',
    children,
    className,
    active,
    activeClassName,
    to,
    href,
    target,
    type = 'button',
    role,
    disabled,
    loading,
    hover,
  } = props

  let Element = component
  const elProps = merge(
    {
      className: cn({
        [className || '']: true,
        '-disabled': disabled,
        '-loading': loading,
        '-hover': hover,
        '-active': active,
        [activeClassName || '']: activeClassName && active,
      }),
    },
    omit(
      [
        'className',
        'component',
        'active',
        'activeClassName',
        'to',
        'href',
        'target',
        'type',
        'role',
        'disabled',
        'loading',
        'hover',
      ],
      props
    )
  ) as any

  if (href) {
    Element = 'a'
    elProps.href = href
    if (target) {
      elProps.target = target
    }
  } else if (to) {
    if (activeClassName && active === undefined) {
      Element = NavLink
      elProps.className = ({ isActive }: any) => (isActive ? cn(className, '-active') : className)
    } else {
      Element = Link
    }
    elProps.to = to
  }

  if (Element === 'button') {
    elProps.type = type
    elProps.disabled = disabled
  } else if (Element !== 'a') {
    elProps.role = role || 'button'
  }

  return (
    <Element ref={ref} {...elProps}>
      {children}
    </Element>
  )
})

export default ButtonBase
