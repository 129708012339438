/**
 * @generated SignedSource<<a7641ec94d7a4ee063a2b493a2fb8e6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketPoolSalesProgress_event$data = {
  readonly flags: {
    readonly waitingList: IBackendMap | null;
  } | null;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly ticketType: {
        readonly archived: boolean | null;
        readonly id: string;
        readonly ticketPoolId: string | null;
      };
      readonly totalAppSold: number;
      readonly totalDigitalValue: number;
      readonly totalPosSold: number;
      readonly totalWlRequests: number;
    } | null>;
  } | null;
  readonly " $fragmentType": "TicketPoolSalesProgress_event";
};
export type TicketPoolSalesProgress_event$key = {
  readonly " $data"?: TicketPoolSalesProgress_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketPoolSalesProgress_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketPoolSalesProgress_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waitingList",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDigitalValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalWlRequests",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ticketPoolId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "49b0dc98f6a5fa28d44e6d570aebd9b9";

export default node;
