import React, { useContext, useCallback, useMemo, FC, memo } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { find, get, getOr, map } from 'lodash/fp'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { notificationContext } from '../../../../context/notification'
import { font, color } from '../../../../utils/variables'

import Checkbox from '../../../../components/Checkbox'
import FormGroup from '../../../../components/FormGroup'
import FormField from '../../../../components/FormField'
import { Form, FormRow } from '../../../../components/Form'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'
import EventCardValue from '../../../../components/Event/EventCardValue'
import ProgressBar from '../../../../components/ProgressBar'

import { ChangeProductAllocationModalQuery } from '../../../../__generated__/ChangeProductAllocationModalQuery.graphql'
import { ChangeProductAllocationModalMutation } from '../../../../__generated__/ChangeProductAllocationModalMutation.graphql'

const ProgressBarValues = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: ${font.size.sm}px;
  margin-top: 14px;
  margin-bottom: -6px;
`

const SoldValue = styled.span`
  font-size: ${font.size.lg}px;
  color: ${color.text};
`

const AllocationValue = styled.span`
  font-size: ${font.size.base}px;
  color: ${color.text};
`

const AgreementCheckbox = styled(Checkbox)`
  margin-top: 24px;
`

const TermsLink = styled.a`
  font-weight: bold;
  &:hover {
    color: ${color.primary};
  }
`

interface IProps {
  eventId: string
  onClose: () => void
  preSelectProductId?: string | null
  preSelectProductVariantId?: string | null
}

const ChangeProductAllocationModal: FC<IProps> = ({
  eventId,
  onClose,
  preSelectProductId,
  preSelectProductVariantId,
}) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)

  const { event } = useLazyLoadQuery<ChangeProductAllocationModalQuery>(
    graphql`
      query ChangeProductAllocationModalQuery($id: ID!) {
        event: node(id: $id) {
          ... on Event {
            id
            eventIdLive

            productsSales {
              productBreakdown {
                productId
                product {
                  id
                  name
                  category {
                    name
                  }
                  allocation
                  hasVariants
                  optionType
                  variants {
                    id
                    name
                    allocation
                  }
                }
                variantBreakdown {
                  variantId
                  variant {
                    id
                    name
                    allocation
                    product {
                      id
                      name
                    }
                  }
                  totalSold
                }
                totalAppSold
                totalSold
              }
            }
          }
        }
      }
    `,
    { id: eventId },
    { fetchPolicy: 'network-only' }
  )

  const currentProduct = useMemo(
    () => find(['productId', preSelectProductId], event?.productsSales?.productBreakdown) || null,
    [event?.productsSales?.productBreakdown, preSelectProductId]
  )

  const variantOptions = useMemo(
    () =>
      map(
        (variantsBreakdown) => ({
          label: variantsBreakdown?.variant?.name,
          value: variantsBreakdown?.variant?.id,
          ...variantsBreakdown,
        }),
        currentProduct?.variantBreakdown || []
      ),
    [currentProduct?.variantBreakdown]
  )

  const currentProductVariant = useMemo(
    () =>
      preSelectProductVariantId ? find(['variantId', preSelectProductVariantId], variantOptions) : variantOptions[0],
    [preSelectProductVariantId, variantOptions]
  )

  const initialValues = useMemo(
    () => ({
      product: currentProduct,
      variant: currentProduct?.product.hasVariants ? currentProductVariant : null,
      allocation: currentProduct?.product.hasVariants
        ? currentProductVariant?.variant?.allocation || 0
        : currentProduct?.product?.allocation || 0,
      userAgreed: false,
    }),
    [currentProduct, currentProductVariant]
  )

  const [commitUpdate, updating] = useMutation<ChangeProductAllocationModalMutation>(graphql`
    mutation ChangeProductAllocationModalMutation($input: ChangeAllocationInput!) {
      changeAllocation(input: $input) {
        object {
          id
          allocation
          lockVersion
          productsSales {
            productBreakdown {
              product {
                id
                name
                allocation
                variants {
                  id
                  name
                  allocation
                }
              }
              totalAppSold
            }
          }
        }
      }
    }
  `)

  const onSubmit = useCallback(
    async ({ product, variant, allocation }: typeof initialValues, formikProps: any) => {
      if (!product) return

      const id = variant?.variantId || product.productId

      if (product.product.hasVariants) {
        const isAllocationIncreased = (allocation || 0) >= (variant?.variant?.allocation || 0)

        if (!isAllocationIncreased) {
          const soldOut = (variant?.variant?.allocation || 0) <= (variant?.totalSold || 0)
          const minQty = soldOut ? variant?.variant?.allocation || 0 : variant?.totalSold || 0
          if ((allocation || 0) < minQty) {
            return formikProps.setErrors({
              allocation: intl.formatMessage({ id: 'change_extras_qty_modal.allocation.error' }, { minQty }),
            })
          }
        }
      } else {
        const isAllocationIncreased = (allocation || 0) >= (product.product?.allocation || 0)
        if (!isAllocationIncreased) {
          const soldOut = (product.product?.allocation || 0) <= product.totalSold
          const minQty = soldOut ? product.product?.allocation || 0 : product.totalSold || 0
          if ((allocation || 0) < minQty) {
            return formikProps.setErrors({
              allocation: intl.formatMessage({ id: 'change_extras_qty_modal.allocation.error' }, { minQty }),
            })
          }
        }
      }

      try {
        await new Promise<void>((resolve, reject) => {
          commitUpdate({
            variables: {
              input: {
                clientMutationId: nanoid(),
                id,
                allocation: allocation || 0,
              },
            },
            onCompleted: (_data, errors) => {
              if (errors && errors.length > 0) {
                reject()
                return
              }
              resolve()
            },
            onError: reject,
          })
        })
        addNotification('success', intl.formatMessage({ id: 'change_extras_qty_modal.succeess' }))
        onClose()
      } catch (e) {
        addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
      }
    },
    [addNotification, commitUpdate, intl, onClose]
  )

  const { values, touched, errors, setFieldValue, handleChange, handleSubmit } = useFormik({ initialValues, onSubmit })

  const setVariant = useCallback(
    (id: any, v: any) => {
      setFieldValue('variant', v)
      setFieldValue('allocation', v.variant.allocation || 0)
    },
    [setFieldValue]
  )

  const soldPercentage = useMemo(() => {
    return values.product?.product.hasVariants
      ? ((values.variant?.totalSold || 0) * 100) / (values.variant?.variant?.allocation || 0)
      : ((values.product?.totalSold || 0) * 100) / (values.product?.product?.allocation || 0)
  }, [values.product, values.variant])

  const gradientColor = soldPercentage === 100 ? 'secondary' : 'primary'

  return (
    <Modal modalTitle={intl.formatMessage({ id: 'change_quantity' })} closeButton onClose={onClose}>
      <ModalBody>
        <Form>
          <FormRow>
            <FormField
              label={intl.formatMessage({ id: 'new_event.extras.form.category.label' })}
              value={getOr('', 'product.category.name', values.product)}
              disabled
            />
            <FormField
              label={intl.formatMessage({ id: 'new_event.extras.form.name.label' })}
              value={getOr('', 'product.name', values.product)}
              disabled
            />
          </FormRow>
          {values.product?.product?.hasVariants && (
            <FormRow>
              <FormField
                label={
                  values.product?.product.optionType === 'SIZE'
                    ? intl.formatMessage({ id: 'new_event.extras.form.variants.size.label' })
                    : intl.formatMessage({ id: 'new_event.extras.form.variants.option.label' })
                }
                control="select"
                options={variantOptions}
                value={values.variant}
                onChange={setVariant}
                searchable
                required
                disabled={!!preSelectProductVariantId}
              />
            </FormRow>
          )}
          <FormRow columnOnMobile>
            <FormField
              name="allocation"
              label={intl.formatMessage({ id: 'new_quantity' })}
              value={values.allocation}
              error={get('allocation', errors) && get('allocation', touched) ? errors?.allocation : null}
              type="number"
              min={0}
              step={1}
              onChange={handleChange}
            />
            <div>
              <FormGroup label={intl.formatMessage({ id: 'current_status' })} />
              <EventCardValue
                primary={<ProgressBar size="small" color={gradientColor} gradient value={soldPercentage} />}
                secondary={
                  <ProgressBarValues>
                    <div>
                      <SoldValue>
                        {values.product?.product.hasVariants
                          ? values.variant?.totalSold || 0
                          : values.product?.totalSold || 0}
                      </SoldValue>{' '}
                      /{' '}
                      <AllocationValue>
                        {values.product?.product.hasVariants
                          ? values.variant?.variant?.allocation || 0
                          : values.product?.product?.allocation || 0}
                      </AllocationValue>
                    </div>
                    <span>
                      {(values.product?.product.hasVariants
                        ? values.variant?.variant?.allocation || 0
                        : values.product?.product?.allocation || 0) -
                        (values.product?.product.hasVariants
                          ? values.variant?.totalSold || 0
                          : values.product?.totalSold || 0)}{' '}
                      {intl.formatMessage({ id: 'remaining' })}
                    </span>
                  </ProgressBarValues>
                }
              />
            </div>
          </FormRow>
        </Form>
        <AgreementCheckbox
          name="userAgreed"
          label={
            <>
              {intl.formatMessage({ id: 'i_accept_the' })}{' '}
              <TermsLink href="https://dice.fm/terms_and_conditions.html" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'terms_and_conditions' })}
              </TermsLink>
            </>
          }
          checked={values.userAgreed}
          onChange={handleChange}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl disabled={!values.userAgreed} loading={updating} onClick={handleSubmit}>
          {intl.formatMessage({ id: 'change_quantity' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ChangeProductAllocationModal)
