import React, { FC, memo, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { map, includes } from 'lodash/fp'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { color } from '../../utils/variables'

import Svg from '../Svg'
import { TitleTooltip } from '../Tooltip'

import { getPromotionStatus } from '../../utils/promotions'

import { TicketPromotion_event$key } from '../../__generated__/TicketPromotion_event.graphql'
import { TicketPromotion_ticketType$key } from '../../__generated__/TicketPromotion_ticketType.graphql'

const PromotionIndicator = styled.div<{ status: string }>`
  display: flex;
  position: absolute;
  width: 24px;
  height: 24px;
  padding: 3px;
  right: -10px;
  top: -10px;
  align-items: center;
  justify-content: center;
  background: ${({ status }) => {
    switch (status) {
      case 'upcoming':
        return color.primary
      case 'ongoing':
        return color.secondary
      case 'ended':
      default:
        return color.disabled
    }
  }};
  border: 2px solid ${color.white};
  color: #fff;
  border-radius: 50%;
  svg {
    width: 16px;
    height: 16px;
  }
`

interface IProps {
  event: TicketPromotion_event$key
  tty: TicketPromotion_ticketType$key
  className?: string
}

const TicketPromotion: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, tty: ttyKey, className }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment TicketPromotion_event on Event {
        id
        onSaleDate
        offSaleDate
      }
    `,
    eventKey
  )

  const tty = useFragment(
    graphql`
      fragment TicketPromotion_ticketType on TicketType {
        eventPromotions {
          id
          startDate
          endDate
          isEnded
          accessType
          timesUsedUnique
          codeLocks(first: 1) {
            count
          }
        }
      }
    `,
    ttyKey
  )

  const status = useMemo(() => {
    const promotionStatuses = map((p) => getPromotionStatus(event, p), tty.eventPromotions || [])
    if (includes('ongoing', promotionStatuses)) return 'ongoing'
    if (includes('upcoming', promotionStatuses)) return 'upcoming'
    return 'ended'
  }, [event, tty.eventPromotions])

  return (
    <TitleTooltip title={intl.formatMessage({ id: `ticket_promotion_indicator.tooltip.${status}` })}>
      <PromotionIndicator status={status} className={className}>
        <Svg icon="tag" />
      </PromotionIndicator>
    </TitleTooltip>
  )
}

export default styled(memo(TicketPromotion))``
