import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { map, without } from 'lodash/fp'
import FEE_TYPES from '../../../utils/feeTypes'
import { FeeType } from '../../../enums.generated'

const useFeeTypeOption = () => {
  const intl = useIntl()

  return useMemo(
    () =>
      map(
        (fee: FeeType) => ({ value: fee, label: intl.formatMessage({ id: `fees.${fee}` }) }),
        without(['tierDiff', 'boxOfficeFee'], FEE_TYPES)
      ) as unknown as Array<{ value: FeeType; label: string }>,
    [intl]
  )
}

export default useFeeTypeOption
