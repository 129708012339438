import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { get, getOr, compact, find } from 'lodash/fp'
import { isPast, parseISO } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import { Form, FormRow } from '../../../components/Form'
import Radio from '../../../components/Radio'
import FormField from '../../../components/FormField'
import { IFormStep } from '../services/getStepsConfig'
import IEventFormTickets from '../types/Tickets'
import Collapsible from '../../../components/Collapsible'
import { authContext } from '../../../context/auth'
import { RestrictionKind, TvPlatform } from '../../../enums.generated'
import { color, breakpoints } from '../../../utils/variables'
import { allowedEventAction } from '../services/allowedEventAction'
import { localeContext } from '../../../context/locale'
import { Tickets_viewer$data } from '../../../__generated__/Tickets_viewer.graphql'
import useTicketsFields from '../hooks/useTicketsFields'
import EventTicketTypesConfig from '../components/EventTicketTypesConfig'
import useDiceStream from '../hooks/useDiceStream'
import FormHeader from '../../../components/FormHeader'
import SwitchField from '../../../components/SwitchField'
import { isItalianEvent, isUSEvent, isFrenchEvent, isCanadaEvent } from '../../../utils/isCountryEvent'
import TaxRates from '../components/TaxRates'
import DiceBadge from '../../../components/DiceBadge'
import EventWaitingList from '../components/EventWaitingList'
import EventTicketTransfer from '../components/EventTicketTransfer'
import EventShoppingCart from '../components/EventShoppingCart'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import AlertBox from '../../../components/AlertBox'
import EventFees from '../components/EventFees'
import EventHasTicketsOnDoor from '../components/EventHasTicketsOnDoor'

const FormFieldWarning = styled(AlertBox).attrs({ color: color.warning })`
  margin-top: 16px;
`

const TV_PLATFORMS: Array<TvPlatform> = ['DICE', 'EXTERNAL']

export const GEO_RESTRICTIONS_KINDS: Array<RestrictionKind> = ['ALLOW', 'DENY']

const NoMarginFormRow = styled(FormRow)`
  margin-top: 16px !important;
`

interface IProps {
  viewer?: Tickets_viewer$data
}

const TicketsStep: FC<React.PropsWithChildren<IFormStep & IProps>> = ({ children, readOnly, viewer }) => {
  const intl = useIntl()
  const { user, account, hasPermission } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const formik = useFormikContext<IEventFormTickets>()
  const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = formik

  const isItalian = useMemo(() => isItalianEvent(values, locale), [locale, values])
  const isUS = useMemo(() => isUSEvent(values, locale), [locale, values])
  const isFrench = useMemo(() => isFrenchEvent(values, locale), [locale, values])

  const primaryVenue = useMemo(
    () => find(['value', values.primaryVenue?.value], values.venues),
    [values.primaryVenue?.value, values.venues]
  )

  const hasTax = useMemo(() => isUSEvent(values, locale) || isCanadaEvent(values, locale), [locale, values])

  const {
    costCurrencyOptions,
    costCurrencyOption,
    setCostCurrency,

    printedTicketFormatOptions,
    printedTicketFormatOption,
    setPrintedTicketFormat,

    barcodeTypeOptions,
    barcodeTypeOption,
    setBarcodeType,

    ticketTypeOptions,
    ticketTypeOption,
    setTicketType,

    restrictCountries,
    setRestrictCountries,
    countriesList,
    countryRestrictionsActive,
    changeCountryRestrictionsActive,
    restrictScope,
    restrictKind,
  } = useTicketsFields()

  const {
    onChangeStreamRewatchEnabled,
    diceStreamDurationOptions,
    diceStreamDurationOption,
    setDiceStreamDuration,
    diceStreamDVRError,
    diceStreamRewatchDurationHint,
  } = useDiceStream()

  const handleChangeWithTouch = useCallback(
    (e: any) => {
      setFieldValue(e.target.name, e.target.checked, true)
      setFieldTouched(e.target.name, true)
    },
    [setFieldTouched, setFieldValue]
  )

  const barcodeTypeLocked =
    values.state !== 'DRAFT' &&
    !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') &&
    !!values.eventIdLive &&
    !!values.onSaleDate &&
    isPast(parseISO(values.onSaleDate))

  const [confirmDisableTax, setConfirmDisableTax] = useState(false)
  const askDisableTax = useCallback(() => setConfirmDisableTax(true), [])
  const closeDisableTax = useCallback(() => setConfirmDisableTax(false), [])

  const toggleDisableTax = useCallback(() => {
    setFieldValue('disableUsTax', !values.disableUsTax)
    setConfirmDisableTax(false)
  }, [setFieldValue, values.disableUsTax])

  const isFrenchPromoter = values?.billingPromoter?.countryCode === 'FR'
  const taxSettingsDisabled = readOnly || (!user.diceStaff && values.state !== 'DRAFT')
  const isUnicornActive = getOr(false, 'flags.unicorn.active', values)
  const isShoppingCartVisible = hasPermission('toggle_cart:event') && (values.state === 'DRAFT' || isUnicornActive)
  const ticketSettingsVisible = user.diceStaff || account?.allowSkipReview || hasPermission('update:event')

  const ticketsTaxSettings = compact([
    user.diceStaff && (
      <FormField
        key="clubNight"
        control="checkbox"
        name="taxSettings.clubNight"
        label={intl.formatMessage({ id: 'new_event.tickets.tax_settings.club_night.label' })}
        checked={!!values.taxSettings?.clubNight}
        onChange={handleChangeWithTouch}
        onBlur={handleBlur}
        error={touched.taxSettings && get('taxSettings.clubNight', errors)}
        disabled={readOnly}
      />
    ),
    (user.diceStaff || (isFrenchPromoter && isFrench)) && (
      <FormField
        key="franceMainstream"
        control="checkbox"
        name="taxSettings.franceMainstream"
        label={intl.formatMessage({ id: 'new_event.tickets.tax_settings.france_mainstream.label' })}
        checked={!!values.taxSettings?.franceMainstream}
        onChange={handleChangeWithTouch}
        onBlur={handleBlur}
        error={touched.taxSettings && get('taxSettings.franceMainstream', errors)}
        disabled={taxSettingsDisabled}
      />
    ),
  ])

  return (
    <Form spacing={isMobile ? 'default' : 'extra'}>
      <FormHeader
        header={intl.formatMessage({ id: 'new_event.steps.tickets' })}
        subheader={intl.formatMessage({ id: 'new_event.tickets.description' })}
      />

      {user.diceStaff && values.eventType !== 'LIVE' && (
        <>
          <FormRow columnOnMobile>
            <div>
              <div>{intl.formatMessage({ id: 'new_event.stream.description' })}</div>
            </div>
          </FormRow>

          <NoMarginFormRow columnOnMobile>
            {TV_PLATFORMS.map((v) => (
              <Radio
                key={v}
                name="diceTvPlatform"
                label={
                  <>
                    <DiceBadge />
                    {intl.formatMessage({ id: `new_event.tickets.tv_platform.${v.toLowerCase()}.label` })}
                  </>
                }
                value={v}
                checked={values.diceTvPlatform === v}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={
                  readOnly ||
                  v !== 'DICE' ||
                  (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
                }
              />
            ))}
          </NoMarginFormRow>

          {values.diceTvPlatform === 'DICE' && (
            <>
              <FormRow columnOnMobile>
                <FormField
                  name="diceStreamDuration"
                  label={intl.formatMessage({ id: 'new_event.tickets.dice_stream_duration.label' })}
                  hint={intl.formatMessage({ id: 'new_event.tickets.dice_stream_duration.hint' })}
                  control="select"
                  options={diceStreamDurationOptions}
                  value={diceStreamDurationOption}
                  error={errors.diceStreamDuration}
                  onChange={setDiceStreamDuration}
                  onBlur={handleBlur}
                  disabled={readOnly}
                  required
                  searchable
                  dice
                />
              </FormRow>
              <FormRow columnOnMobile>
                <SwitchField
                  label={intl.formatMessage({ id: 'new_event.tickets.dice_stream_dvr_enabled.label' })}
                  hint={intl.formatMessage({ id: 'new_event.tickets.dice_stream_dvr_enabled.hint' })}
                  error={diceStreamDVRError}
                  name="diceStreamDvrEnabled"
                  checked={!!values.diceStreamDvrEnabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={readOnly}
                  dice
                />
              </FormRow>
              <FormRow columnOnMobile>
                <SwitchField
                  label={intl.formatMessage({ id: 'new_event.tickets.dice_stream_rewatch_enabled.label' })}
                  hint={intl.formatMessage({ id: 'new_event.tickets.dice_stream_rewatch_enabled.hint' })}
                  name="diceStreamRewatchEnabled"
                  checked={!!values.diceStreamRewatchEnabledUntil}
                  onChange={onChangeStreamRewatchEnabled}
                  onBlur={handleBlur}
                  disabled={readOnly}
                  dice
                />
              </FormRow>
              {!!values.diceStreamRewatchEnabledUntil && (
                <>
                  <FormRow columnOnMobile>
                    <FormField
                      name="diceStreamRewatchEnabledUntil"
                      timezone={values.timezoneName || undefined}
                      label={intl.formatMessage({ id: 'new_event.tickets.dice_stream_rewatch_enabled_until.label' })}
                      hint={diceStreamRewatchDurationHint}
                      control="datetime"
                      value={values.diceStreamRewatchEnabledUntil}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      error={errors.diceStreamRewatchEnabledUntil}
                      locale={locale}
                      disabled={readOnly}
                      required
                      dice
                    />
                  </FormRow>
                </>
              )}
            </>
          )}
        </>
      )}

      <EventTicketTypesConfig viewer={viewer} readOnly={readOnly} />

      {isShoppingCartVisible && (
        <FormRow>
          <EventShoppingCart />
        </FormRow>
      )}

      {hasTax && user.diceStaff && (
        <>
          <FormRow columnOnMobile>
            <SwitchField
              label={intl.formatMessage({ id: `promoter_form.disable_${isUS ? 'us' : 'ca'}_tax.label` })}
              hint={
                !hasPermission('disable_us_tax:balances')
                  ? intl.formatMessage({ id: `promoter_form.disable_${isUS ? 'us' : 'ca'}_tax.hint_no_permission` })
                  : intl.formatMessage({ id: `promoter_form.disable_${isUS ? 'us' : 'ca'}_tax.hint` })
              }
              name="disableUsTax"
              checked={values.disableUsTax || false}
              onChange={askDisableTax}
              onBlur={handleBlur}
              disabled={readOnly || !hasPermission('disable_us_tax:balances')}
              error={touched.disableUsTax && errors.disableUsTax}
              dice
            />
          </FormRow>
          {confirmDisableTax && (
            <ConfirmationModal
              title={intl.formatMessage({ id: `promoter_form.disable_${isUS ? 'us' : 'ca'}_tax.confirmation.title` })}
              description={intl.formatMessage({
                id: values.disableUsTax
                  ? 'promoter_form.disable_us_tax.confirmation.description_off'
                  : 'promoter_form.disable_us_tax.confirmation.description_on',
              })}
              onConfirm={toggleDisableTax}
              onReject={closeDisableTax}
              cta={intl.formatMessage({ id: 'yes' })}
              rejectCta={intl.formatMessage({ id: 'no' })}
            />
          )}
        </>
      )}

      {(ticketSettingsVisible || hasPermission('manage_geo_restrictions:event')) && (
        <Collapsible
          label={intl.formatMessage({ id: 'new_event.tickets.ticket_settings.label' })}
          initialCollapsed
          dataId="ticketsSettings"
        >
          {ticketSettingsVisible && (
            <>
              <EventHasTicketsOnDoor readOnly={readOnly} />
              <EventTicketTransfer readOnly={readOnly} />
              <EventWaitingList readOnly={readOnly} />
            </>
          )}
          {hasPermission('manage_geo_restrictions:event') && (
            <>
              <FormRow columnOnMobile>
                <SwitchField
                  label={intl.formatMessage({ id: 'new_event.tickets.restrict_countries_active.label' })}
                  hint={intl.formatMessage({ id: 'new_event.tickets.restrict_countries_active.normal.hint' })}
                  name="countryRestrictionsActive"
                  disabled={readOnly}
                  checked={countryRestrictionsActive}
                  onChange={changeCountryRestrictionsActive}
                />
              </FormRow>

              {countryRestrictionsActive && (
                <>
                  <NoMarginFormRow columnOnMobile>
                    {GEO_RESTRICTIONS_KINDS.map((v) => (
                      <Radio
                        key={v}
                        data-id={`restrictCountriesKind[${v}]`}
                        name="restrictCountriesKind"
                        label={intl.formatMessage({
                          id: `new_event.tickets.restrict_countries_kind.${v.toLowerCase()}.label`,
                        })}
                        value={v}
                        checked={values.restrictCountriesKind === v}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={readOnly}
                      />
                    ))}
                  </NoMarginFormRow>

                  {values.restrictCountriesKind && (
                    <FormRow columnOnMobile data-id="restrictCountriesControl">
                      <FormField
                        name="restrictCountries"
                        label={intl.formatMessage({
                          id: `new_event.tickets.restrict_countries.${restrictKind}.normal.label`,
                        })}
                        hint={intl.formatMessage({
                          id: `new_event.tickets.restrict_countries.${restrictKind}.normal.hint`,
                        })}
                        placeholder={intl.formatMessage({ id: 'new_event.tickets.restrict_countries.placeholder' })}
                        control="select"
                        multiple
                        searchable
                        value={restrictCountries}
                        options={countriesList}
                        onBlur={handleBlur}
                        onChange={setRestrictCountries}
                        error={touched.restrictCountries && errors.restrictCountries}
                        disabled={readOnly}
                      />
                    </FormRow>
                  )}
                </>
              )}
            </>
          )}
        </Collapsible>
      )}

      {ticketsTaxSettings.length > 0 && (
        <Collapsible
          label={intl.formatMessage({ id: 'new_event.tickets.tax_settings.label' })}
          help={values.state === 'DRAFT' ? null : <TaxRates event={values as any} />}
          initialCollapsed
          dataId="ticketsTaxSettings"
          dice
        >
          <FormRow columnOnMobile>{ticketsTaxSettings}</FormRow>
        </Collapsible>
      )}

      {user.diceStaff && (
        <>
          <EventFees readOnly={readOnly} />

          <Collapsible
            label={intl.formatMessage({ id: 'admin_settings' })}
            initialCollapsed={!(!!values.id && !!errors.onSaleNotificationAt)}
            dataId="ticketsAdminSettings"
            dice
          >
            <FormRow columnOnMobile>
              <FormField
                control="checkbox"
                name="flags.competition.active"
                label={intl.formatMessage({ id: 'new_event.settings.flags.competition.label' })}
                checked={getOr(false, 'flags.competition.active', values)}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get('flags.competition.active', touched) && get('flags.competition.active', errors)}
                disabled={readOnly}
              />
              <FormField
                control="checkbox"
                name="flags.codeLocked.active"
                label={intl.formatMessage({ id: 'new_event.settings.flags.codelocked.label' })}
                checked={getOr(false, 'flags.codeLocked.active', values)}
                onChange={handleChange}
                onBlur={handleBlur}
                error={get('flags.codeLocked.active', touched) && get('flags.codeLocked.active', errors)}
                disabled={readOnly || isUnicornActive}
                title={
                  isUnicornActive
                    ? intl.formatMessage({
                      id: 'new_event.tickets.ticket_type_edit.code_locked.not_compatible_with_cart',
                    })
                    : undefined
                }
              />
            </FormRow>

            <FormRow columnOnMobile>
              {values.eventType !== 'STREAM' && (
                <FormField
                  control="checkbox"
                  name="flags.paperTicket.active"
                  label={intl.formatMessage({ id: 'new_event.settings.flags.paper_tickets.label' })}
                  checked={getOr(false, 'flags.paperTicket.active', values)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={get('flags.paperTicket.active', touched) && get('flags.paperTicket.active', errors)}
                  disabled={readOnly}
                />
              )}

              <FormField
                control="checkbox"
                name="flags.generateNewCodeOnTransfer.active"
                label={intl.formatMessage({ id: 'new_event.tickets.flags.generate_new_code_on_transfer.label' })}
                checked={isItalian || getOr(false, 'flags.generateNewCodeOnTransfer.active', values)}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  get('flags.generateNewCodeOnTransfer.active', touched) &&
                  get('flags.generateNewCodeOnTransfer.active', errors)
                }
                disabled={readOnly || isItalian}
              />
            </FormRow>

            {values.eventType !== 'STREAM' && (
              <>
                <FormRow columnOnMobile>
                  <div>
                    <FormField
                      name="ticketType"
                      label={intl.formatMessage({ id: 'venue_form.tickets.ticket_type.label' })}
                      control="select"
                      options={ticketTypeOptions}
                      value={ticketTypeOption}
                      onBlur={handleBlur}
                      onChange={setTicketType}
                      error={touched.ticketType && errors.ticketType}
                    />
                    {primaryVenue?.ticketType && primaryVenue.ticketType !== values.ticketType && (
                      <FormFieldWarning>
                        {intl.formatMessage(
                          { id: 'new_event.tickets.ticket_type.warning' },
                          {
                            setting: find(['value', primaryVenue.ticketType], ticketTypeOptions)?.label || '',
                          }
                        )}
                      </FormFieldWarning>
                    )}
                  </div>
                  <div>
                    <FormField
                      name="barcodeType"
                      label={intl.formatMessage({ id: 'new_event.tickets.barcode_type.label' })}
                      control="select"
                      options={barcodeTypeOptions}
                      value={barcodeTypeOption}
                      onBlur={handleBlur}
                      onChange={setBarcodeType}
                      error={touched.barcodeType && errors.barcodeType}
                      disabled={readOnly || (!user.diceStaff && barcodeTypeLocked)}
                    />
                    {primaryVenue?.barcodeType && primaryVenue.barcodeType !== values.barcodeType && (
                      <FormFieldWarning>
                        {intl.formatMessage(
                          { id: 'new_event.tickets.barcode_type.warning' },
                          {
                            setting: find(['value', primaryVenue.barcodeType], barcodeTypeOptions)?.label || '',
                          }
                        )}
                      </FormFieldWarning>
                    )}
                  </div>
                </FormRow>
                <FormRow columnOnMobile>
                  <FormField
                    name="printedTicketFormat"
                    label={intl.formatMessage({ id: 'new_event.settings.printed_ticket_format.label' })}
                    control="select"
                    options={printedTicketFormatOptions}
                    value={printedTicketFormatOption}
                    onBlur={handleBlur}
                    onChange={setPrintedTicketFormat}
                    error={touched.printedTicketFormat && errors.printedTicketFormat}
                    disabled={readOnly}
                  />
                </FormRow>
              </>
            )}
          </Collapsible>
        </>
      )}

      {children}
    </Form>
  )
}

export default createFragmentContainer(memo(TicketsStep), {
  viewer: graphql`
    fragment Tickets_viewer on Viewer {
      account {
        financialDataProvided
        taxFormProvided
      }
    }
  `,
  event: graphql`
    fragment Tickets_event on Event {
      ...EventTicketTypeModal_event @relay(mask: false)

      id
      eventIdLive
      state
      statusAsOfNow
      eventType
      feesBehaviour
      basePriceFees
      postFanPriceFees
      isTicketAvailableAtDoor
      fees {
        amount
        type
        unit
        applicable

        split {
          amount
          destination
          unit
        }
      }

      sales {
        ticketTypesBreakdown {
          totalSold
          totalReserved
          ticketTypeId
          totalPosSold
          totalAppSold
          ticketType {
            id
            ticketPoolId
          }
          priceTiersBreakdown {
            priceTier {
              id
            }
            reserved
            sold
            appSold
            posSold
            terminalSold
          }
        }
      }

      disableUsTax
      allowedLifecycleUpdates {
        ticketPools {
          canAdd
          canUpdate
          canChangeAllocation
          canRemove
        }
        maxTicketsLimit {
          canUpdate
        }
        endDate {
          canUpdate
        }
        onSaleDate {
          canUpdate
        }
      }
      waitingListExchangeWindows {
        id
        duration
        offset
      }
      freeEvent
      maxTicketsLimit
      ticketType
      restrictCountries
      restrictCountriesKind
      diceTvPlatform
      diceStreamDuration
      diceStreamDvrEnabled
      diceStreamRewatchEnabledUntil
      flags {
        competition
        ticketTransfer
        waitingList
        paperTicket
        codeLocked
        seated
        generateNewCodeOnTransfer
        enabledPwl
        coolingOffPeriod
        fanPickSeat
      }
      costCurrency
      timezoneName
      date
      closeEventDate
      endDate
      onSaleDate
      offSaleDate
      announceDate
      addressCountry
      countryCode
      barcodeType
      allocation

      eventSeatingChart {
        id
        seatsIoEventId
        chartManagerCredentials {
          secretKey
        }
        seatingChannels {
          seatsIoChannel
          channelType
          name
        }
        venueChart {
          value: id
          label: name
        }
      }

      venues {
        capacity
        addressCountry
        countryCode
        addressLocality
        addressRegion
        addressState
        postalCode
        streetAddress

        value: id
        label: name
        fullAddress
        timezoneName
        latitude
        longitude

        atLeastOneSeatingChart: seatingCharts(first: 1) {
          edges {
            node {
              id
            }
          }
        }

        profileDetails {
          imageAttachment {
            cdnUrl
          }
          imageCropRegion {
            x
            y
            width
            height
          }
        }

        venueImages {
          attachment {
            cdnUrl
          }
        }
      }
      printedTicketFormat

      taxSettings {
        clubNight
        franceMainstream
      }

      ...TaxRates_event

      onSaleNotification
      onSaleNotificationAt
      onSaleSmsReminders
      onSaleNotificationStatus
      smsTtys: ticketTypes(doorSalesOnly: false, includeArchived: true) {
        id
        name
        archived
        onSaleNotificationSentAt
        onSaleDate
      }

      ticketPools {
        id
        maxAllocation
        name
      }
    }
  `,
})
