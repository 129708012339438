import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { find, reduce } from 'lodash/fp'
import { isPast, parseISO } from 'date-fns'

import { CURRENCY } from '../../../utils/formatters/number'
import { EventCostCurrency } from '../../../enums.generated'
import { TicketBreakdown_event$data } from '../../../__generated__/TicketBreakdown_event.graphql'
import { color } from '../../../utils/variables'
import { isPriceTierActive } from '../../../utils/tiers'
import DetailedPriceBreakdownTooltip from '../../../components/Event/DetailedPriceBreakdownTooltip'

type ITtysBreakdown = NonNullable<NonNullable<TicketBreakdown_event$data['sales']>['ticketTypesBreakdown']>
type ITtyBreakdown = NonNullable<ITtysBreakdown[number]>
type IPriceTiers = NonNullable<ITtyBreakdown['priceTiersBreakdown']>
type IPriceTier = NonNullable<IPriceTiers[number]>
type ITicketPool = NonNullable<TicketBreakdown_event$data['ticketPools']>[number]

type IPoolSalesMap = {
  [ticketPoolId: string]: {
    maxAllocation?: number
    totalSold?: number
    totalReserved?: number
    totalWlPurchases?: number
  }
}

const basketColor = (tt: ITtyBreakdown) => {
  const isSold = (tt.totalAppSold || 0) + (tt.totalPosSold || 0) === tt.ticketType.allocation
  if (isSold) {
    return tt.totalWlRequests > 0 ? color.warning : color.grey
  } else {
    return tt.totalReserved > 0 ? color.secondary : color.grey
  }
}

const priceTierDotPercentage = (tt: ITtyBreakdown, pt: IPriceTier) => {
  if (tt.ticketType.priceTierType !== 'time') {
    const allocatedSoFar = reduce(
      (acc: number, cpt: IPriceTier | null) =>
        cpt?.priceTier && pt?.priceTier && cpt.priceTier.order <= pt.priceTier.order
          ? (cpt.priceTier.allocation || 0) + acc
          : acc,
      0,
      tt.priceTiersBreakdown || []
    )

    return (100 * allocatedSoFar) / tt.ticketType.allocation
  }

  const soldSoFar = reduce(
    (acc: number, cpt: IPriceTier | null) =>
      cpt?.priceTier && pt?.priceTier && cpt.priceTier.order <= pt.priceTier.order
        ? (cpt.appSold || 0) + (cpt.posSold || 0) + acc
        : acc,
    0,
    tt.priceTiersBreakdown || []
  )

  return (100 * soldSoFar) / tt.ticketType.allocation
}

const isOffSalePast = (tt: ITtyBreakdown) => !!tt.ticketType.offSaleDate && isPast(parseISO(tt.ticketType.offSaleDate))

function useTicketTypeCalculations(
  costCurrency: EventCostCurrency | undefined,
  ticketTypesBreakdown?: ITtysBreakdown | undefined | null,
  ticketPools?: readonly ITicketPool[] | undefined | null
) {
  const intl = useIntl()

  const ticketPoolsSales: IPoolSalesMap = useMemo(
    () =>
      reduce((poolsMap: any, ttyb: ITtyBreakdown) => {
        const poolId = ttyb.ticketType.ticketPoolId || ''
        const pool = find((pool) => pool?.id === poolId, ticketPools)
        const poolBreakdown = poolsMap[poolId] || {}
        const newPoolBreakdown = {
          maxAllocation: pool?.maxAllocation,
          totalSold: (poolBreakdown.totalSold || 0) + (ttyb.totalAppSold || 0) + (ttyb.totalPosSold || 0),
          totalReserved: poolBreakdown.totalReserved || 0 + ttyb.totalReserved,
          totalWlPurchases: poolBreakdown.totalWlPurchases || 0 + ttyb.totalWlPurchases,
        }
        poolsMap[poolId] = newPoolBreakdown
        return poolsMap
      }, {})(ticketTypesBreakdown as any),
    [ticketPools, ticketTypesBreakdown]
  )

  const isSold = useCallback(
    (tt: ITtyBreakdown) => {
      if (!tt.ticketType.ticketPoolId) {
        return (tt.totalAppSold || 0) + (tt.totalPosSold || 0) === tt.ticketType.allocation
      } else {
        const pool = ticketPoolsSales[tt.ticketType.ticketPoolId]
        const poolSold = pool ? pool.totalSold === pool.maxAllocation : false
        const ttySold = tt.ticketType.salesLimit
          ? tt.ticketType.salesLimit === (tt.totalAppSold || 0) + (tt.totalPosSold || 0)
          : false
        return poolSold || ttySold
      }
    },
    [ticketPoolsSales]
  )

  const isPoolSold = useCallback(
    (tt: ITtyBreakdown) => {
      if (!tt.ticketType.ticketPoolId) {
        return false
      } else {
        const pool = ticketPoolsSales[tt.ticketType.ticketPoolId]
        return pool ? pool.totalSold === pool.maxAllocation : false
      }
    },
    [ticketPoolsSales]
  )

  const basketCount = useCallback(
    (tt: ITtyBreakdown) => (isSold(tt) ? tt.totalWlPurchases : tt.totalReserved),
    [isSold]
  )

  const ttPrice = useMemo(
    () => (tt: ITtyBreakdown) => {
      let faceValue = tt.ticketType.faceValue || 0
      let priceBreakdown = tt.ticketType.priceBreakdown || null

      if (tt.ticketType.priceTierType !== null && tt.priceTiersBreakdown && tt.priceTiersBreakdown.length > 0) {
        const activeTier = find((pt) => pt && isPriceTierActive(tt, pt), tt.priceTiersBreakdown || []) as
          | IPriceTier
          | null
          | undefined
        faceValue = (activeTier ? activeTier.priceTier.faceValue : tt.priceTiersBreakdown[0]?.priceTier.faceValue) || 0
        priceBreakdown =
          (activeTier ? activeTier.priceTier.priceBreakdown : tt.priceTiersBreakdown[0]?.priceTier.priceBreakdown) ||
          null
      }

      return (
        <span>
          {intl.formatNumber(faceValue / 100, CURRENCY(faceValue, costCurrency))}{' '}
          <DetailedPriceBreakdownTooltip
            title={intl.formatMessage(
              { id: 'price.fees_title' },
              { name: tt.ticketType.name || intl.formatMessage({ id: 'new_event.basics.name.placeholder' }) }
            )}
            currency={costCurrency || null}
            priceBreakdown={priceBreakdown}
            placement="right"
          />
        </span>
      )
    },
    [costCurrency, intl]
  )

  return {
    isSold,
    isPoolSold,
    isOffSalePast,
    ttPrice,
    basketCount,
    basketColor,
    priceTierDotPercentage,
  }
}

export default useTicketTypeCalculations
