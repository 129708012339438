import React, { useState, useCallback, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { Dropdown, DropdownTrigger, DropdownContent } from '../../../../../../components/Dropdown'
import { Menu, MenuItem } from '../../../../../../components/Menu'
import Svg from '../../../../../../components/Svg'

import { FilterAdditionalItemDropdown } from '../../../../../../components/FilterStyles'
import EventListTypeContext from '../../../../util/eventListTypeContext'

function DateFilter() {
  const intl = useIntl()
  const listType = useContext(EventListTypeContext)
  const { values, handleSubmit, setFieldValue } = useFormikContext<{
    timeFrameValue?: string
  }>()
  const [open, setOpen] = useState(false)

  const currentValue = useMemo(() => {
    if (!values.timeFrameValue) return intl.formatMessage({ id: 'event_filter_bar.dates.any_dates' })
    return intl.formatMessage({ id: `dates.${values.timeFrameValue}` })
  }, [intl, values.timeFrameValue])

  const onClickOutside = useCallback(() => open && setOpen(false), [open, setOpen])
  const toggleDropdown = useCallback(() => setOpen(!open), [open, setOpen])

  const toggleDate = useCallback(
    (e: any) => {
      const value = e.target.getAttribute('name') || undefined
      if (value !== 'custom_date_range') {
        setFieldValue('timeFrameMinDate', undefined)
        setFieldValue('timeFrameMaxDate', undefined)
      }
      setFieldValue('timeFrameValue', values.timeFrameValue === value ? undefined : value)
      onClickOutside()
      handleSubmit()
    },
    [values.timeFrameValue, setFieldValue, onClickOutside, handleSubmit]
  )

  return (
    <FilterAdditionalItemDropdown
      as={Dropdown}
      className={!!values.timeFrameValue && '-active'}
      active={open}
      onClickOutside={onClickOutside}
    >
      <DropdownTrigger onClick={toggleDropdown}>
        <Svg icon="date-picker" />
        <strong>{currentValue}</strong>
      </DropdownTrigger>
      <DropdownContent active={open}>
        <Menu>
          <MenuItem active={!values.timeFrameValue} onClick={toggleDate}>
            {intl.formatMessage({ id: 'event_filter_bar.dates.any_dates' })}
          </MenuItem>

          {listType === 'past' ? (
            <>
              <MenuItem name="previous_week" active={values.timeFrameValue === 'previous_week'} onClick={toggleDate}>
                {intl.formatMessage({ id: 'dates.previous_week' })}
              </MenuItem>
              <MenuItem
                name="previous_fortnight"
                active={values.timeFrameValue === 'previous_fortnight'}
                onClick={toggleDate}
              >
                {intl.formatMessage({ id: 'dates.previous_fortnight' })}
              </MenuItem>
              <MenuItem name="previous_month" active={values.timeFrameValue === 'previous_month'} onClick={toggleDate}>
                {intl.formatMessage({ id: 'dates.previous_month' })}
              </MenuItem>
              <MenuItem name="yesterday" active={values.timeFrameValue === 'yesterday'} onClick={toggleDate}>
                {intl.formatMessage({ id: 'dates.yesterday' })}
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem name="upcoming_week" active={values.timeFrameValue === 'upcoming_week'} onClick={toggleDate}>
                {intl.formatMessage({ id: 'dates.upcoming_week' })}
              </MenuItem>
              <MenuItem
                name="upcoming_fortnight"
                active={values.timeFrameValue === 'upcoming_fortnight'}
                onClick={toggleDate}
              >
                {intl.formatMessage({ id: 'dates.upcoming_fortnight' })}
              </MenuItem>
              <MenuItem name="upcoming_month" active={values.timeFrameValue === 'upcoming_month'} onClick={toggleDate}>
                {intl.formatMessage({ id: 'dates.upcoming_month' })}
              </MenuItem>
              <MenuItem name="on_sale_today" active={values.timeFrameValue === 'on_sale_today'} onClick={toggleDate}>
                {intl.formatMessage({ id: 'dates.on_sale_today' })}
              </MenuItem>
            </>
          )}

          {/* TODO: Show Datepicker with range onClick */}
          {/* <MenuItem
            name="custom_date_range"
            active={values.timeFrameValue === 'custom_date_range'}
            onClick={toggleDate}
          >
            {intl.formatMessage({ id: 'dates.custom_date_range' })}
          </MenuItem> */}
        </Menu>
      </DropdownContent>
    </FilterAdditionalItemDropdown>
  )
}

export default DateFilter
