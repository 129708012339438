/**
 * @generated SignedSource<<a5aa16fd2fb4356bd0843a8e283df01f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventOverview_event$data = {
  readonly allowedActions: {
    readonly readExtras: boolean | null;
    readonly readMerch: boolean | null;
  } | null;
  readonly announceDate: string | null;
  readonly date: string | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly extraNotes: string | null;
  readonly id: string;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly products: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly sales: {
    readonly totalSold: number;
  } | null;
  readonly state: EventState | null;
  readonly timezoneName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Notes_event" | "TimelineChart_event">;
  readonly " $fragmentType": "EventOverview_event";
};
export type EventOverview_event$key = {
  readonly " $data"?: EventOverview_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventOverview_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventOverview_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readExtras",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readMerch",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSold",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "products",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimelineChart_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Notes_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "6edf5bfdd3a089ed8d72e557797f62b4";

export default node;
