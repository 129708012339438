import { compact, map } from 'lodash/fp'

import { MediaItemTypes } from '../../../enums.generated'
import IEventForm from '../types'

interface IMediaInput {
  id: string | null
  type: MediaItemTypes
  values: any
}

type ConvertMedia = (event: Partial<IEventForm>) => IMediaInput[]

export const convertMedia: ConvertMedia = (event: any) => {
  const media = event.media as IMediaInput[]

  return map(
    ({ id, type, values }: { id: string | null; type: MediaItemTypes; values: any }) => ({
      id,
      type,
      // prettier-ignore
      values: type === 'trailer'
        ? {
          video: values.video,
        }
        : {
          artistId: values.artist_id,
          image: values.image,
          mediaUri: values.media_uri,
          name: values.name,
          openUrl: values.open_url,
          previewUrl: values.preview_url,
        },
    }),
    compact(media)
  )
}

export default convertMedia
