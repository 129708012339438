import { Environment, RecordSource, Store, RequestParameters } from 'relay-runtime'
import {
  RelayNetworkLayer,
  perfMiddleware,
  errorMiddleware,
  authMiddleware,
  urlMiddleware,
  RRNLRequestError,
} from 'react-relay-network-modern'
import * as Sentry from '@sentry/react'
import { find, get, isString } from 'lodash/fp'
import { stripIgnoredCharacters } from 'graphql/utilities/stripIgnoredCharacters'

import { API_URL, ENABLE_PERF_MIDDLEWARE } from './env'
import { clearAuthToken, persistLocation } from './utils/api'

const isSilentOp = (op: string | null | undefined) => !!op && /trackSearch/.test(op)

declare global {
  interface Window {
    __pendingQueries: number
  }
}

// Added for automation tests team
window.__pendingQueries = 0

const network = new RelayNetworkLayer(
  [
    urlMiddleware({ url: `${API_URL}/graphql`, mode: 'cors' }),
    (next) => async (req) => {
      Sentry.addBreadcrumb({
        category: 'graphql',
        message: `GraphQL ${req.isMutation() ? 'mutation' : 'query'} ${req.getID()}`,
        data: req.getVariables(),
        level: 'info',
      })

      try {
        window.__pendingQueries++

        const res = await next(req)

        if (res.errors) {
          const errors = res.errors

          Sentry.withScope((scope) => {
            if (res?._res) {
              const xRequestId = res._res.headers.get('x-request-id')
              if (xRequestId) {
                scope.setTag('kim_request_id', xRequestId)
              }
            }

            if ('variables' in req) {
              scope.setExtra('requestVariables', req.variables)
            }

            scope.setExtra('responseStatus', res.status)
            scope.setTag('response_status', res.status)

            if ('operation' in req) {
              scope.setTag('graphql_op_name', req.operation.name)
              scope.setTag('graphql_op_kind', req.operation.operationKind)
            }

            const silent = 'operation' in req ? isSilentOp(req.operation?.name) : false

            const accountSwitchError = find((e) => e.message.startsWith('loginToAccount:'), errors)

            if (accountSwitchError) {
              const [, accountId] = accountSwitchError.message.split(':')
              if (window.__switchAccount && accountId) {
                // eslint-disable-next-line no-console
                console.info('Switch account to', accountId)
                window.__switchAccount(accountId).then(() => {
                  window.localStorage.setItem('mioAccountWasAutoSwitched', 'yes')
                  window.location.reload()
                })
              }
            } else {
              errors.forEach((e) => {
                if (window.__notifications && !silent) {
                  window.__notifications.addNotification('error', e.message)
                }

                const err: any = new Error(e.message)
                err.locations = JSON.stringify(e.locations)
                err.path = JSON.stringify((e as any).path)
                console.error(err)
                Sentry.captureException(err)
              })
            }
          })
        }

        return res
      } catch (e) {
        if (e instanceof RRNLRequestError) {
          Sentry.withScope((scope) => {
            if (e.res?._res) {
              const xRequestId = e.res._res.headers.get('x-request-id')
              if (xRequestId) {
                scope.setTag('kim_request_id', xRequestId)
              }
            }

            scope.setExtra('requestVariables', e.req.variables)
            scope.setExtra('responseStatus', e.res.status)
            scope.setExtra('responseText', e.res.text)

            scope.setTag('response_status', e.res.status)
            scope.setTag('graphql_op_name', e.req.operation.name)
            scope.setTag('graphql_op_kind', e.req.operation.operationKind)

            Sentry.captureException(e)
          })
        } else {
          Sentry.captureException(e)
        }

        throw e
      } finally {
        window.__pendingQueries--
      }
    },
    process.env.NODE_ENV === 'development' && ENABLE_PERF_MIDDLEWARE ? perfMiddleware() : null,
    errorMiddleware({
      disableServerMiddlewareTip: true,
    }),
    authMiddleware({
      token: () => localStorage.getItem('token') || '',
      tokenRefreshPromise: () => {
        clearAuthToken()
        persistLocation()
        window.location.replace('/auth/login')
        return ''
      },
    }),
    (next) => async (req) => {
      if (isString(req.fetchOpts.body)) {
        const json: any = JSON.parse(req.fetchOpts.body)
        if (json.query) {
          json.query = stripIgnoredCharacters(json.query as string)
        }
        req.fetchOpts.body = JSON.stringify(json)
      }
      const res = await next(req)
      return res
    },
  ],
  { noThrow: true }
)

export const graphiqlFetcher = ({
  query,
  operationName,
  variables,
}: {
  query: string
  operationName?: string | null
  variables?: any
}) =>
  network
    .execute({ text: query, name: operationName } as RequestParameters, variables || {}, {})
    .toPromise()
    .then(get('json'))

export const createEnvironment = () =>
  new Environment({
    network,
    store: new Store(new RecordSource()),
  })
