import { FormatDateOptions } from 'react-intl'

import { ILocale } from '../../intl'

export const USE_12HR = new Set(['en-GB', 'en-IN', 'en-US', 'en'])

type IFormatConstructor = (locale: ILocale) => FormatDateOptions

export interface IFormats {
  [key: string]: FormatDateOptions | IFormatConstructor
}

const DATETIME_FORMATS_SAFE: IFormats = {
  // 'Thu, 7 Jun 1973, 0:00:00 BST'
  FULL(locale: ILocale) {
    const hour12 = USE_12HR.has(locale)
    return {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
      hourCycle: hour12 ? 'h12' : 'h23',
    }
  },

  // 'Thu, 7 Jun 1973, 00:00'
  LONG(locale: ILocale) {
    const hour12 = USE_12HR.has(locale)
    return {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: hour12 ? 'h12' : 'h23',
    }
  },

  // '7 Jun 1973, 00:00'
  DATETIME(locale: ILocale) {
    const hour12 = USE_12HR.has(locale)
    return {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: hour12 ? 'h12' : 'h23',
    }
  },

  // '07/06/1973, 00:00'
  NUMERIC: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },

  // '07/06/1973'
  NUMERIC_DATE_ONLY: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },

  // 'Thu, 7 Jun 1973'
  MEDIUM: {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },

  // '7 Jun 1973'
  SHORT: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },

  // '7 April'
  SHORTEST_FULL: {
    day: 'numeric',
    month: 'long',
  },

  // '7 Jun'
  SHORTEST: {
    day: 'numeric',
    month: 'short',
  },

  // '7'
  DAY: {
    day: 'numeric',
  },

  // '07:30'
  TIME(locale: ILocale) {
    return (USE_12HR.has(locale) ? this.TIME_12HR : this.TIME_24HR) as FormatDateOptions
  },

  // '07:30 BST'
  TIME_FULL(locale: ILocale) {
    return {
      ...(USE_12HR.has(locale) ? this.TIME_12HR : this.TIME_24HR),
      timeZoneName: 'short',
    } as FormatDateOptions
  },

  // '7:30 am'
  TIME_12HR: {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
  },

  // '07:30'
  TIME_24HR: {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  },

  // '07:30'
  TIME_24HR_NO_LEADING_ZERO: {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  },

  // '07:30:00'
  TIME_SECONDS: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hourCycle: 'h23',
  },
}

export const DATETIME_FORMATS = DATETIME_FORMATS_SAFE as any
