import React, { FC, Fragment, memo, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { filter, groupBy, keys, map, reject } from 'lodash/fp'
import styled from 'styled-components/macro'

import { authContext } from '../context/auth'
import { localeContext } from '../context/locale'
import { EventCostCurrency } from '../enums.generated'
import { getTimezoneOption } from '../utils/calendar'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'

import ICreateContractForm from '../flows/CreateContract/types'
import FeeRulePreview from '../flows/CreateContract/components/FeeRulePreveiw'

import { Divider, Form, FormRow } from './Form'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from './Modal'
import OverviewPlate from './OverviewPlate'

import { font } from '../utils/variables'

const Small = styled.small`
  font-size: ${font.size.sm}px;
`

interface IProps {
  feesConfiguration: ICreateContractForm | null
  onSave?: () => Promise<void>
  onClose?: () => void
  currency: EventCostCurrency
  timezone?: string | null
}

export const ContractReviewModal: FC<IProps> = ({ feesConfiguration, currency, timezone, onClose, onSave }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const [saving, setSaving] = useState(false)
  const doSave = useCallback(() => {
    if (!onSave) return
    setSaving(true)

    onSave().finally(() => setSaving(false))
  }, [onSave])

  const extrasFeeConfiguration = useMemo(
    () => reject(['feeTarget', 'merch'], feesConfiguration?.products || []),
    [feesConfiguration?.products]
  )
  const extrasFees = useMemo(
    () => groupBy((e) => e?.parentCategory?.label || intl.formatMessage({ id: 'na' }), extrasFeeConfiguration),
    [extrasFeeConfiguration, intl]
  )

  const merchFeeConfiguration = useMemo(
    () => filter(['feeTarget', 'merch'], feesConfiguration?.products || []),
    [feesConfiguration?.products]
  )
  const merchFees = useMemo(
    () => groupBy((e) => e?.parentCategory?.label || intl.formatMessage({ id: 'na' }), merchFeeConfiguration),
    [merchFeeConfiguration, intl]
  )
  return (
    <Modal modalTitle={intl.formatMessage({ id: 'new_event.tickets.contract_fees' })} closeButton onClose={onClose}>
      <ModalBody>
        <Form>
          <FormRow columnOnMobile>
            <div data-id="effectiveDate">
              <Small className="color-darkgrey block">
                {intl.formatMessage({ id: 'fees.contract.effective_date.label' })}
              </Small>
              <span className="block">
                {feesConfiguration?.effectiveDate
                  ? intl.formatDate(feesConfiguration.effectiveDate, {
                    ...DATETIME_FORMATS.DATETIME(locale),
                    timeZone: timezone || user.timezoneName,
                  })
                  : intl.formatMessage({ id: 'na' })}
              </span>
            </div>
            <div data-id="endDate">
              <Small className="color-darkgrey block">
                {intl.formatMessage({ id: 'fees.contract.end_date.label' })}
              </Small>
              <span className="block">
                {feesConfiguration?.endDate
                  ? intl.formatDate(feesConfiguration.endDate, {
                    ...DATETIME_FORMATS.DATETIME(locale),
                    timeZone: timezone || user.timezoneName,
                  })
                  : intl.formatMessage({ id: 'na' })}
              </span>
            </div>
          </FormRow>
          <FormRow columnOnMobile>
            <div data-id="timezoneName">
              <Small className="color-darkgrey block">{intl.formatMessage({ id: 'account.timezone' })}</Small>
              <span className="block">
                {getTimezoneOption(intl, timezone || user.timezoneName)?.label || intl.formatMessage({ id: 'na' })}
              </span>
            </div>
            <div data-id="timezoneCode">
              <Small className="color-darkgrey block">{intl.formatMessage({ id: 'account.timezone_code' })}</Small>
              <span className="block">{timezone || user.timezoneName || intl.formatMessage({ id: 'na' })}</span>
            </div>
          </FormRow>
          <FormRow columnOnMobile>
            <div data-id="taxId">
              <Small className="color-darkgrey block">{intl.formatMessage({ id: 'fees.contract.tax_id.label' })}</Small>
              <span className="block">{feesConfiguration?.taxId || intl.formatMessage({ id: 'na' })}</span>
            </div>
            <div data-id="taxExempt">
              <Small className="color-darkgrey block">
                {intl.formatMessage({ id: 'fees.contract.tax_exempt.label' })}
              </Small>
              <span className="block">{intl.formatMessage({ id: feesConfiguration?.taxExempt ? 'yes' : 'no' })}</span>
            </div>
          </FormRow>
          <FormRow columnOnMobile>
            <div data-id="basePriceFees">
              <Small className="color-darkgrey block">
                {intl.formatMessage({ id: 'fees.contract.base_price_fees.label' })}
              </Small>
              <span className="block">
                {feesConfiguration?.basePriceFees && feesConfiguration.basePriceFees.length > 0
                  ? feesConfiguration.basePriceFees.map((fee) => intl.formatMessage({ id: `fees.${fee}` })).join(', ')
                  : intl.formatMessage({ id: 'na' })}
              </span>
            </div>
            <div data-id="postFanPriceFees">
              <Small className="color-darkgrey block">
                {intl.formatMessage({ id: 'fees.contract.post_fan_price_fees.label' })}
              </Small>
              <span className="block">
                {feesConfiguration?.postFanPriceFees && feesConfiguration?.postFanPriceFees.length > 0
                  ? feesConfiguration.postFanPriceFees
                    .map((fee) => intl.formatMessage({ id: `fees.${fee}` }))
                    .join(', ')
                  : intl.formatMessage({ id: 'na' })}
              </span>
            </div>
          </FormRow>
          <FormRow columnOnMobile>
            <div data-id="boxOfficeFee">
              <Small className="color-darkgrey block">
                {intl.formatMessage({ id: 'fees.contract.box_office_fee.label' })}
              </Small>
              <span className="block">
                {feesConfiguration?.boxOfficeFee
                  ? intl.formatNumber(feesConfiguration.boxOfficeFee / 100, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    style: 'percent',
                  })
                  : intl.formatMessage({ id: 'na' })}
              </span>
            </div>
          </FormRow>

          <FormRow>
            <Divider className="mt-zero mb-zero" />
          </FormRow>

          {feesConfiguration?.ticketFeeRanges && feesConfiguration?.ticketFeeRanges.length > 0 && (
            <FormRow>
              <div data-id="ticketsFeeRules">
                <span className="block mb-md">
                  <strong>{intl.formatMessage({ id: 'tickets' })}</strong>
                </span>
                {feesConfiguration?.ticketFeeRanges && (
                  <OverviewPlate>
                    <FeeRulePreview
                      ruleId="ticketFeeRanges"
                      title={intl.formatMessage({ id: 'all_tickets' })}
                      feeRanges={feesConfiguration?.ticketFeeRanges}
                      currency={currency}
                    />
                  </OverviewPlate>
                )}
              </div>
            </FormRow>
          )}

          {extrasFeeConfiguration && extrasFeeConfiguration.length > 0 && (
            <FormRow>
              <Form data-id="extrasFees">
                {keys(extrasFees).map((extraCategory) => (
                  <FormRow key={extraCategory}>
                    <div>
                      <span className="block mb-md">
                        <strong>{intl.formatMessage({ id: 'extras' })}</strong>
                        <small className="fs-12">&ensp;&bull;&ensp;</small>
                        <span>{extraCategory}</span>
                      </span>
                      {extrasFees[extraCategory].length > 0 && (
                        <OverviewPlate>
                          {extrasFees[extraCategory].map(
                            (product, idx) =>
                              product && (
                                <Fragment key={product.id}>
                                  {idx > 0 && <Divider />}
                                  <FeeRulePreview
                                    key={product.id}
                                    ruleId={product.id}
                                    title={map('label', product.feeTargetCategories || [])}
                                    feeRanges={product.feeRanges}
                                    currency={currency}
                                  />
                                </Fragment>
                              )
                          )}
                        </OverviewPlate>
                      )}
                    </div>
                  </FormRow>
                ))}
              </Form>
            </FormRow>
          )}

          {merchFeeConfiguration && merchFeeConfiguration.length > 0 && (
            <FormRow>
              <Form data-id="merchFees">
                {keys(merchFees).map((merchCategory) => (
                  <FormRow key={merchCategory}>
                    <div>
                      <span className="block mb-md">
                        <strong>{intl.formatMessage({ id: 'merch' })}</strong>
                      </span>
                      {merchFees[merchCategory].length > 0 && (
                        <OverviewPlate>
                          {merchFees[merchCategory].map(
                            (product, idx) =>
                              product && (
                                <Fragment key={product.id}>
                                  {idx > 0 && <Divider />}
                                  <FeeRulePreview
                                    key={product.id}
                                    ruleId={product.id}
                                    title={map('label', product.feeTargetCategories || [])}
                                    feeRanges={product.feeRanges}
                                    currency={currency}
                                  />
                                </Fragment>
                              )
                          )}
                        </OverviewPlate>
                      )}
                    </div>
                  </FormRow>
                ))}
              </Form>
            </FormRow>
          )}

          {feesConfiguration?.allowedAdhocFeeTypes && feesConfiguration?.allowedAdhocFeeTypes.length > 0 && (
            <FormRow>
              <div data-id="adhocFees">
                <span className="block mb-md">
                  <strong>{intl.formatMessage({ id: 'other_fees' })}</strong>
                </span>
                {feesConfiguration.allowedAdhocFeeTypes && (
                  <OverviewPlate>
                    <FeeRulePreview
                      isAdhoc
                      ruleId="adhocFees"
                      title={intl.formatMessage({ id: 'fees.contract.adhoc_fees' })}
                      feeRanges={[{ id: 'adhoc', fromBasePrice: 0, fees: feesConfiguration.allowedAdhocFeeTypes }]}
                      currency={currency}
                    />
                  </OverviewPlate>
                )}
              </div>
            </FormRow>
          )}
        </Form>
      </ModalBody>
      {onSave && (
        <ModalFooter>
          <ModalFooterControl data-id="saveButton" loading={!!saving} block={!onClose} onClick={doSave}>
            {intl.formatMessage({ id: 'save_changes' })}
          </ModalFooterControl>
          {onClose && (
            <ModalFooterControl data-id="cancelButton" preset="secondary" onClick={onClose}>
              {intl.formatMessage({ id: 'actions.cancel' })}
            </ModalFooterControl>
          )}
        </ModalFooter>
      )}
    </Modal>
  )
}

export default memo(ContractReviewModal)
