/* eslint-disable amp/prefer-amp-typography */

/**
 * Reference
 * https://www.figma.com/file/ZrJmCd4UVtkWr78ZLCWcMvDS/%F0%9F%A7%A9-Amp-Web?node-id=8901%3A23610&mode=dev
 */

import { css } from 'styled-components/macro'
import { font } from './variables'

export const textStyle = {
  heading: {
    // TODO: not in AMP?!
    xxxl: css`
      font-weight: ${font.weight.bold};

      letter-spacing: 0;

      font-size: 64px;
      line-height: 60px;
    `,
    xxl: css`
      font-weight: ${font.weight.bold};

      letter-spacing: 0;

      font-size: 48px;
      line-height: 52px;
    `,
    xl: css`
      font-weight: ${font.weight.light};

      letter-spacing: 0;

      font-size: 32px;
      line-height: 36px;
    `,
    lg: css`
      font-weight: ${font.weight.light};

      letter-spacing: 0;

      font-size: 28px;
      line-height: 32px;
    `,
    md: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 24px;
      line-height: 28px;
    `,
    sm: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 20px;
      line-height: 24px;
    `,
    xs: css`
      font-weight: ${font.weight.bold};

      letter-spacing: 0;

      font-size: 16px;
      line-height: 20px;
    `,
  },
  bodycopy: {
    regular: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 16px;
      line-height: 22px;
    `,
  },
  functional: {
    lg: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 18px;
      line-height: 22px;
    `,
    md: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 16px;
      line-height: 20px;
    `,
    sm: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0.01em;

      font-size: 14px;
      line-height: 18px;
    `,
    xs: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0.01em;

      font-size: 12px;
      line-height: 16px;
    `,
  },
  interactive: {
    lg: css`
      font-weight: ${font.weight.bold};

      letter-spacing: 0;

      font-size: 18px;
      line-height: 22px;
    `,
    md: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 16px;
      line-height: 20px;
    `,
    sm: css`
      font-weight: ${font.weight.bold};

      letter-spacing: 0.01em;

      font-size: 14px;
      line-height: 18px;
    `,
  },
  button: {
    lg: css`
      font-weight: ${font.weight.bold};

      letter-spacing: 0.06em;

      font-size: 12px;
      line-height: 16px;
    `,
    sm: css`
      font-weight: ${font.weight.bold};

      letter-spacing: 0.06em;

      font-size: 10px;
      line-height: 12px;
    `,
  },
  label: {
    regular: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0.06em;

      font-size: 12px;
      line-height: 14px;
    `,
  },
  numeric: {
    xl: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 48px;
      line-height: 48px;
    `,
    lg: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 32px;
      line-height: 32px;
    `,
    md: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 24px;
      line-height: 24px;
    `,
    sm: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 16px;
      line-height: 16px;
    `,
    data: css`
      font-weight: ${font.weight.normal};

      letter-spacing: 0;

      font-size: 14px;
      line-height: 18px;
    `,
  },
} as const
