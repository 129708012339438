import { useMemo } from 'react'
import { compact, isArray, maxBy, sum } from 'lodash/fp'
import { IPriceBreakdown } from '../../../utils/getPriceBreakdown'

interface IPriceSuggestionInput {
  faceValue: number
  priceBreakdown: IPriceBreakdown | null
}

interface ISuggestionResult {
  potentialRevenue: number
  priceSuggestions: Array<{
    friendlyTotal: number
    friendlyFaceValue: number
    idx: number
  }>
}

function usePriceSuggestions(allocation: number | number[], inputs: IPriceSuggestionInput[]): ISuggestionResult {
  const potentialRevenue = useMemo(() => {
    let values
    if (isArray(allocation)) {
      values = inputs.map(({ priceBreakdown }, idx) => allocation[idx] * (priceBreakdown?.split?.billingPromoter || 0))
    } else {
      values = [
        allocation *
          (maxBy(({ priceBreakdown }) => priceBreakdown?.split?.billingPromoter || 0, inputs)?.priceBreakdown?.split
            ?.billingPromoter || 0),
      ]
    }
    return sum(values)
  }, [allocation, inputs])

  const priceSuggestions = useMemo(() => {
    return compact(
      inputs.map(({ priceBreakdown, faceValue }, idx) => {
        if (!priceBreakdown || !priceBreakdown.friendlyFaceValue || !priceBreakdown.friendlyPrice) return null

        if (priceBreakdown.friendlyPrice === faceValue || priceBreakdown.faceValue !== faceValue) {
          return null
        }

        return {
          idx,
          friendlyTotal: priceBreakdown.friendlyPrice || 0,
          friendlyFaceValue: priceBreakdown.friendlyFaceValue || 0,
        }
      })
    )
  }, [inputs])

  return { potentialRevenue, priceSuggestions }
}

export default usePriceSuggestions
