import { IntlShape } from 'react-intl'
import { filter } from 'lodash/fp'
import { CURRENCY } from './formatters/number'
import { EventCostCurrency } from '../enums.generated'
import { ILocale } from '../intl'

export const OFFICIALLY_SUPPORTED_CURRENCIES = ['GBP', 'USD', 'EUR', 'AUD', 'INR', 'CAD']
const OFFICIALLY_SUPPORTED_CURRENCIES_SET = new Set(OFFICIALLY_SUPPORTED_CURRENCIES)

export const officialOrFallbackCurrency = (
  currency: EventCostCurrency | null,
  fallbackCurrency?: EventCostCurrency | null
): EventCostCurrency | null => {
  if (currency && OFFICIALLY_SUPPORTED_CURRENCIES_SET.has(currency)) return currency
  if (fallbackCurrency && OFFICIALLY_SUPPORTED_CURRENCIES_SET.has(fallbackCurrency)) return fallbackCurrency
  return 'GBP'
}

export function getCurrencySymbol(intl: IntlShape, currency: EventCostCurrency | null, locale?: ILocale): string {
  if (!currency) return ''

  const parts = filter(
    ['type', 'currency'],
    intl.formatNumberToParts(0, {
      ...CURRENCY(0, currency, locale),
      useGrouping: false,
    })
  )

  return parts[0]?.value || ''
}
