import { createGlobalStyle } from 'styled-components/macro'
import { color, input } from '../../utils/variables'

export const TextInputStyle = createGlobalStyle`
  .text-input {
    display: block;
    position: relative;
    background: white;
    border-radius: 4px;
    
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: ${input.borderWidth}px solid ${input.borderColor};
      border-radius: 6px;
      pointer-events: none;
      transition: border-color .1s cubic-bezier(0.4, 0.0, 0.6, 1);
    }
    &:hover {
      &:before {
        border-color: #bfbfbf;
      }
    }
    &.-focus {
      &:before {
        border-color: ${input.borderColorActive};
        z-index: 2;
      }
    }
    &.-has-error {
      color: $input-error-color;
      .text-input_input:-webkit-autofill {
        color: ${input.errorColor} !important;
        -webkit-text-fill-color: ${input.errorColor} !important;
      }
      &:before {
        border-color: ${input.errorColor};
      }
    }
  }

  .text-input_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-input.-disabled & {
      color: ${color.darkgrey};
      background: ${color.palegrey};
    }
  }

  .text-input_input {
    display: block;
    width: 100%;
    padding: ${input.paddingVertical}px ${input.paddingHorizontal}px;
    line-height: ${input.lineHeight};
    height: ${input.height}px;
    color: inherit;
    font-family: inherit;
    font-size: ${input.fontSize};
    font-weight: normal;
    background: transparent;
    outline: none;
    border: none;
    text-transform: inherit;
    &[list]::-webkit-calendar-picker-indicator {
      opacity: 100;
      padding: 0;
      height: 6px;
      width: 10px;
    }
    &::placeholder {
      color: ${input.placeholderColor};
    }
    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 9999px white;
      -webkit-text-fill-color: inherit !important;
      background: transparent !important;
      color: inherit !important;
    }
    &:invalid, &:-moz-ui-invalid {
      box-shadow: none;
    }
  }

  textarea.text-input_input {
    height: auto;
    resize: none;
  }

  .text-input_phone-container {
    display: block;
    width: 100%;
  }

  .text-input_search {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    height: 100%;
    padding: 0 10px 0 0;
  }

  .text-input_search-loading {
    margin: 0 8px 0 -4px;
  }

  .text-input_currency {
    width: 24px;
    height: 24px;
    margin: 2px -16px 0 16px;
    line-height: 24px;
  }
`
