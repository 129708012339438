/**
 * @generated SignedSource<<c14fa8d27fde5ee0e71ba28921f28991>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type useEventNotesQuery$variables = {
  eventId: string;
  newEvent: boolean;
  promoterIds?: ReadonlyArray<string | null> | null;
  venueIds?: ReadonlyArray<string | null> | null;
};
export type useEventNotesQuery$data = {
  readonly event?: {
    readonly billingPromoter?: {
      readonly name: string | null;
      readonly tier: string | null;
    } | null;
    readonly createdBy?: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
    readonly eventIdLive?: string | null;
    readonly extraNotes?: string | null;
    readonly id?: string;
    readonly name?: string | null;
    readonly notes?: string | null;
    readonly primaryVenue?: {
      readonly name: string | null;
    } | null;
    readonly state?: EventState | null;
    readonly submittedAt?: string | null;
  } | null;
  readonly viewer: {
    readonly id: string;
    readonly promotersNote: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly notes: string;
          readonly tier: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly venuesNote: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly notes: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useEventNotesQuery = {
  response: useEventNotesQuery$data;
  variables: useEventNotesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newEvent"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "promoterIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "venueIds"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tier",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Viewer",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": "promotersNote",
      "args": [
        (v5/*: any*/),
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "in",
                  "variableName": "promoterIds"
                }
              ],
              "kind": "ObjectValue",
              "name": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "PromotersConnection",
      "kind": "LinkedField",
      "name": "promoters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PromotersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Promoter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "venuesNote",
      "args": [
        (v5/*: any*/),
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "in",
                  "variableName": "venueIds"
                }
              ],
              "kind": "ObjectValue",
              "name": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "VenuesConnection",
      "kind": "LinkedField",
      "name": "venues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VenuesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Venue",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraNotes",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventIdLive",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submittedAt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ThinUserProfile",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useEventNotesQuery",
    "selections": [
      (v9/*: any*/),
      {
        "condition": "newEvent",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "event",
            "args": (v10/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Promoter",
                    "kind": "LinkedField",
                    "name": "billingPromoter",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Venue",
                    "kind": "LinkedField",
                    "name": "primaryVenue",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Event",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useEventNotesQuery",
    "selections": [
      (v9/*: any*/),
      {
        "condition": "newEvent",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "event",
            "args": (v10/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Promoter",
                    "kind": "LinkedField",
                    "name": "billingPromoter",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Venue",
                    "kind": "LinkedField",
                    "name": "primaryVenue",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Event",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "e1871d4c75d799c7cfedd8f37cb6d46b",
    "id": null,
    "metadata": {},
    "name": "useEventNotesQuery",
    "operationKind": "query",
    "text": "query useEventNotesQuery(\n  $eventId: ID!\n  $promoterIds: [ID]\n  $venueIds: [ID]\n  $newEvent: Boolean!\n) {\n  viewer {\n    id\n    promotersNote: promoters(first: 50, where: {id: {in: $promoterIds}}) {\n      edges {\n        node {\n          id\n          name\n          notes\n          tier\n        }\n      }\n    }\n    venuesNote: venues(first: 50, where: {id: {in: $venueIds}}) {\n      edges {\n        node {\n          id\n          name\n          notes\n        }\n      }\n    }\n  }\n  event: node(id: $eventId) @skip(if: $newEvent) {\n    __typename\n    ... on Event {\n      id\n      name\n      notes\n      extraNotes\n      eventIdLive\n      state\n      submittedAt\n      createdBy {\n        firstName\n        lastName\n        email\n      }\n      billingPromoter {\n        name\n        tier\n        id\n      }\n      primaryVenue {\n        name\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "beb521a1bff0fc5862e42e7eea23b52e";

export default node;
