import React, { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import PermissionGate from '../gates/PermissionGate'

const BundlesPage = lazy(() => import(/* webpackChunkName: "bundles" */ '../../flows/Bundles/BundlesPage'))
const BundleHeader = lazy(() => import(/* webpackChunkName: "bundles" */ '../../flows/Bundle/components/BundleHeader'))
const BundlePage = lazy(() => import(/* webpackChunkName: "bundles" */ '../../flows/Bundle/BundlePage'))
const BundleEventsPage = lazy(
  () => import(/* webpackChunkName: "bundles" */ '../../flows/EventList/EmbeddedEventListPage')
)

const BundlesSection = () => (
  <Routes>
    <Route path="*" element={<PermissionGate permission="read:bundle" />}>
      <Route index element={<BundlesPage />} />
      <Route
        path="new"
        element={
          <>
            <BundleHeader />
            <PermissionGate permission="create:bundle">
              <BundlePage />
            </PermissionGate>
          </>
        }
      />
      <Route
        path=":id"
        element={
          <>
            <BundleHeader />
            <Outlet />
          </>
        }
      >
        <Route index element={<BundlePage />} />
        <Route path="events" element={<BundleEventsPage />} />
      </Route>
    </Route>
  </Routes>
)

export default BundlesSection
