import React, { FC, useMemo } from 'react'
import graphql from 'babel-plugin-relay/macro'

import RelayLoader from '../../components/RelayLoader'
import { StripeRefreshPageQuery } from '../../__generated__/StripeRefreshPageQuery.graphql'

const StripeRefreshPage: FC<React.PropsWithChildren<unknown>> = () => {
  const DataLoader = useMemo(() => {
    return RelayLoader<StripeRefreshPageQuery['response']>(
      ({ viewer }) => {
        window.location.href = viewer?.account?.stripeLoginUrl || '/'
        return null
      },
      {
        fetchPolicy: 'store-and-network',
        query: graphql`
          query StripeRefreshPageQuery {
            viewer {
              account {
                stripeLoginUrl
              }
            }
          }
        `,
        customLoader: <></>,
      }
    )
  }, [])

  return <DataLoader />
}

export default StripeRefreshPage
