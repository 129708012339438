import React, { FC, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { Link, useLocation } from 'react-router-dom'

import Svg from '../../components/Svg'

import { mediaQuery, zIndex, color, font } from '../../utils/variables'
import { trackingContext } from '../../context/tracking'

const Header = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.header};

  ${mediaQuery.lessThan('tablet')`
    color: ${color.white};
  `}
`

const LogoWrapper = styled.div`
  margin-left: 32px;
  margin-top: 24px;
  flex-grow: 0;
  color: #fff;
  svg {
    width: 87px;
    height: 56px;
  }
  ${mediaQuery.lessThan('tablet')`
    margin-left: 24px;
    svg {
      width: 60px;
      height: 24px;
    }
  `}
`

const Menu = styled.div`
  display: flex;
  margin: 0 32px 0 0;
  flex-direction: row-reverse;

  ${mediaQuery.lessThan('tablet')`
    margin: 12px 24px 0 0;
  `}
`

const MenuItem = styled.div`
  margin-left: 32px;
  align-self: center;
  flex-grow: 0;
  font-weight: bold;

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.sm}px;
  `}
  ${mediaQuery.lessThan('tablet')`
    a:hover {
      color: white;
    }
  `}
`

const Buffer = styled.div`
  flex-grow: 1;
`

const AuthHeader: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const location = useLocation()
  const { trackEvent } = useContext(trackingContext)

  const iAmSignup = location.pathname === '/auth/signup'

  const trackLogin = useCallback(() => trackEvent('login_started', { source_name: 'signup' }), [trackEvent])

  return (
    <Header>
      <LogoWrapper>
        <Svg icon="MIO" />
      </LogoWrapper>
      <Buffer />
      <Menu>
        {iAmSignup && (
          <MenuItem>
            <Link to="/auth/login" onClick={trackLogin}>
              {intl.formatMessage({ id: 'sign_in' })}
            </Link>
          </MenuItem>
        )}
      </Menu>
    </Header>
  )
}

export default AuthHeader
