import React, { FC, memo } from 'react'

import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { OnDesktop, OnMobile } from '../../../../../components/Breakpoints'
import { ModalBody } from '../../../../../components/Modal'

import RemittanceModalDataDesktop from './RemittanceModalDataDesktop'
import RemittanceModalSalesBreakdown from './RemittanceModalSalesBreakdown'
import RemittanceModalDataMobile from './RemittanceModalDataMobile'
import RemittanceModalFooter from './RemittanceModalFooter'

import { RemittanceModalDataQuery } from '../../../../../__generated__/RemittanceModalDataQuery.graphql'

interface IProps {
  eventId: string
  onClose: () => void
}

const RemittanceModalData: FC<IProps> = ({ eventId, onClose }) => {
  const { event } = useLazyLoadQuery<RemittanceModalDataQuery>(
    graphql`
      query RemittanceModalDataQuery($id: ID!) {
        event: node(id: $id) {
          ... on Event {
            id

            sales {
              totalFaceValue
              totalRebate
              totalPromoterIncome
            }

            ...RemittanceModalDataDesktop_event
            ...RemittanceModalDataMobile_event
            ...RemittanceModalSalesBreakdown_event
            ...RemittanceModalFooter_event
          }
        }
      }
    `,
    { id: eventId || 'oops' },
    { fetchPolicy: !eventId ? 'store-only' : 'store-or-network' }
  )

  if (!event?.sales) return null

  return (
    <>
      <ModalBody>
        <OnDesktop>
          <RemittanceModalDataDesktop event={event} />
        </OnDesktop>
        <OnMobile>
          <RemittanceModalDataMobile event={event} />
        </OnMobile>
        <RemittanceModalSalesBreakdown event={event} />
      </ModalBody>
      <RemittanceModalFooter event={event} onClose={onClose} />
    </>
  )
}

export default memo(RemittanceModalData)
