import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { compose, filter, fromPairs, identity, isEqual, isNil, toPairs } from 'lodash/fp'
import qs from 'qs'

import { useDebounce } from './hooks/useDebounce'

const removeNulls = compose([fromPairs, filter((p: [string, any]) => !isNil(p[1])), toPairs])

export const parseQueryParams = (search: string, process?: (p: any) => any) =>
  removeNulls((process || identity)(qs.parse(search, { ignoreQueryPrefix: true, comma: true })))

function useQuerySync<P extends {}>(params: P, setParams: (p: P) => void, debounceMs = 200) {
  const { search } = useLocation()
  const navigate = useNavigate()

  const initFilters = useRef(false)
  useEffect(() => {
    if (!search || initFilters.current) return

    const newParams: any = parseQueryParams(search)

    if (!isEqual(newParams, params)) {
      setParams(newParams)
    }

    initFilters.current = true
  }, [params, search, setParams])

  const debouncedParams = useDebounce(params, debounceMs)
  useEffect(() => {
    if (!debouncedParams) return

    const newSearch = qs.stringify(removeNulls(debouncedParams), {
      addQueryPrefix: true,
      arrayFormat: 'comma',
      encodeValuesOnly: true,
    })

    if (search !== newSearch) {
      navigate({ search: newSearch }, { replace: true })
    }
  }, [debouncedParams, navigate, search])
}

export default useQuerySync
