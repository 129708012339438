import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import Svg from '../../../../components/Svg'

import { getCroppedUrl } from './ProductImages'
import {
  ProductImageWrapper,
  CoverInfoButton,
  CoverInfo,
  CoverInfoHint,
  CoverBadge,
  ProductImage,
  ProductImagePreview,
} from './ProductImagesStyles'

export type IProductImageStaticCover = { imgUrl: string; bgColor: string; hint?: string }

interface IProps {
  cover: IProductImageStaticCover
}

const ProductImageStaticCover: FC<IProps> = ({ cover }) => {
  const intl = useIntl()
  return (
    <ProductImageWrapper disabled bgColor={cover.bgColor}>
      {cover.hint ? (
        <>
          <CoverInfoButton>
            <Svg icon="info" />
          </CoverInfoButton>
          <CoverInfo>
            <CoverInfoHint>{cover.hint}</CoverInfoHint>
            <CoverBadge>
              <Svg icon="lock" />
              <span>{intl.formatMessage({ id: 'new_event.extras.form.product_images.badge.cover' })}</span>
            </CoverBadge>
          </CoverInfo>
        </>
      ) : (
        <CoverBadge>
          <Svg icon="lock" />
          <span>{intl.formatMessage({ id: 'new_event.extras.form.product_images.badge.cover' })}</span>
        </CoverBadge>
      )}
      <ProductImage disabled>
        <ProductImagePreview>
          <img src={getCroppedUrl(cover.imgUrl)} alt="" />
        </ProductImagePreview>
      </ProductImage>
    </ProductImageWrapper>
  )
}

export default ProductImageStaticCover
