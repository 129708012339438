import { find } from 'lodash/fp'
import React, { FC, memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { authContext } from '../../../context/auth'
import useEventListType from '../hooks/useEventListType'
import { SIDEBAR_ROUTER } from '../services/sidebarNav'
import { SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'

const Events: FC = () => {
  const intl = useIntl()
  const listType = useEventListType()
  const { user, account, hasPermission, availableAccounts } = useContext(authContext)

  const isCollaborator = useMemo(
    () => find(['id', account?.id], availableAccounts)?.membershipType === 'COLLABORATORS',
    [account?.id, availableAccounts]
  )

  return (
    <>
      <SidebarMenuItem active={listType === 'live'} to={SIDEBAR_ROUTER.events.live[0]} data-id="sidebarLive">
        <SidebarMenuIcon icon="date-picker" />
        <span>{intl.formatMessage({ id: 'sidebar.live' })}</span>
      </SidebarMenuItem>
      {(hasPermission('create:event') || isCollaborator) && (
        <SidebarMenuItem active={listType === 'draft'} to={SIDEBAR_ROUTER.events.drafts[0]} data-id="sidebarDrafts">
          <SidebarMenuIcon icon="edit" />
          <span>{intl.formatMessage({ id: 'sidebar.drafts' })}</span>
        </SidebarMenuItem>
      )}
      <SidebarMenuItem active={listType === 'past'} to={SIDEBAR_ROUTER.events.past[0]} data-id="sidebarPast">
        <SidebarMenuIcon icon="check" />
        <span>{intl.formatMessage({ id: 'sidebar.past' })}</span>
      </SidebarMenuItem>
      <SidebarMenuItem
        active={listType === 'cancelled'}
        to={SIDEBAR_ROUTER.events.cancelled[0]}
        data-id="sidebarCancelled"
      >
        <SidebarMenuIcon icon="archive" />
        <span>{intl.formatMessage({ id: 'sidebar.cancelled' })}</span>
      </SidebarMenuItem>
      {user.diceStaff && (
        <SidebarMenuItem
          dice
          active={listType === 'submission'}
          to={SIDEBAR_ROUTER.events.submissions[0]}
          data-id="sidebarSubmissions"
        >
          <SidebarMenuIcon icon="flag" />

          <span>{intl.formatMessage({ id: 'sidebar.submissions' })}</span>
        </SidebarMenuItem>
      )}
    </>
  )
}

export default memo(Events)
