import React, { FC, useCallback } from 'react'
import { useIntl } from 'react-intl'

import Checkbox from '../../../../../components/Checkbox'
import { IOptions } from '../../../../../utils/graphqlOptionsLoader'

interface IProps {
  type: IOptions[number]
  checked: boolean
  onChange: (k: string, v: boolean) => void
}

const TypeCheckbox: FC<IProps> = ({ type, checked, onChange }) => {
  const intl = useIntl()
  const handleChange = useCallback(() => onChange(type.label, checked), [onChange, type, checked])
  return (
    <Checkbox
      key={type.value}
      name="type"
      checked={checked}
      label={intl.formatMessage({
        // @intl-meta project:Tags
        id: `tags_hierarchy.${type.parent.name}_${type.label}`,
        defaultMessage: type.label,
      })}
      onChange={handleChange}
    />
  )
}

export default TypeCheckbox
