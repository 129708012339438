import React, { FC, memo, useCallback, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useFormik, yupToFormErrors } from 'formik'
import styled from 'styled-components/macro'
import { object, string, array } from 'yup'
import { pick } from 'lodash/fp'
import { useNavigate } from 'react-router'
import { DeepWritable } from 'ts-essentials'
import { useRelayEnvironment } from 'react-relay'

import { EventSuccessRow_attrs$data } from '../../../__generated__/EventSuccessRow_attrs.graphql'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'
import { Form, FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { color, font } from '../../../utils/variables'
import EventSchedule, { ILineup } from '../../EventForm/components/EventSchedule'
import submitDraft from '../../EventForm/services/submitDraft'
import { notificationContext } from '../../../context/notification'
import { authContext } from '../../../context/auth'
import IEventForm from '../../EventForm/types'
import MarkdownEditor from '../../../components/MarkdownEditor'
import { renderMarkdown, parseMarkdown } from '../../../utils/markdown'
import createOrUpdateEvent from '../../EventForm/services/createOrUpdateEvent'
import { localeContext } from '../../../context/locale'

interface ICounterProps {
  count: number
  maxCount: number
  minCount: number
}

const Counter = styled.div<ICounterProps>`
  position: absolute;
  top: 0;
  right: 0;
  white-space: nowrap;
  font-size: ${font.size.sm}px;
  ${({ count, maxCount, minCount }) => (count < minCount || count > maxCount ? `color: ${color.error}` : undefined)}
`

const QuickEditSchema = object({
  name: string().required(),
  description: string().required(),
  lineup: array()
    .of(
      object({
        details: string().required(),
        time: string().nullable(),
      })
    )
    .nullable()
    .required()
    .min(1),
}).test('limitDescription', 'Description must not exceed the char limit', function (values) {
  const ctx = (this.options.context as any).viewer || {}
  const { diceStaff, dicePartner } = ctx

  let charLimit = null
  if (!diceStaff) {
    charLimit = dicePartner ? 1000 : 300
  }

  const valid = !charLimit || (values.description || '').length <= charLimit
  return valid || this.createError({ path: 'description', message: ' ' })
})

type IQuickEditForm = Pick<IEventForm, 'id' | 'name' | 'description' | 'descriptionDraft' | 'lineup' | 'state'>

const convertEvent = (evt: EventSuccessRow_attrs$data): IQuickEditForm =>
  evt && {
    ...(evt as DeepWritable<EventSuccessRow_attrs$data>),
    descriptionDraft: parseMarkdown(evt.description),
  }

const QuickEditModal: FC<React.PropsWithChildren<{ event: EventSuccessRow_attrs$data; onClose: () => void }>> = ({
  event,
  onClose,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addNotification } = useContext(notificationContext)
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const onSubmit = useCallback(
    async (values: IQuickEditForm) => {
      if (!values.id) return
      try {
        await createOrUpdateEvent(
          environment,
          pick(['id', 'name', 'description', 'lineup', 'state', 'lockVersion'], values),
          locale,
          true
        )
        await submitDraft(environment, values.id)
        onClose()
      } catch (err) {
        console.error(err)
        addNotification('error', intl.formatMessage({ id: 'event_success.submit_error' }))
      }
    },
    [environment, locale, onClose, addNotification, intl]
  )

  const validateSchema = useCallback(
    async (evt: any) => {
      try {
        await QuickEditSchema.validate(evt, { context: { viewer: user } })
      } catch (err) {
        return yupToFormErrors(err)
      }
    },
    [user]
  )

  const initialValues = useMemo(() => convertEvent(event), [event])

  const { values, handleSubmit, handleChange, handleBlur, isSubmitting, isValid, touched, errors, setFieldValue } =
    useFormik<IQuickEditForm>({
      initialValues,
      onSubmit,
      validate: validateSchema,
      validateOnMount: true,
    })

  const onEditDraft = useCallback(() => {
    onClose()
    navigate(`/events/${event.id}/edit`)
  }, [onClose, event.id, navigate])

  const setLineup = useCallback(
    (v: ILineup[]) => {
      setFieldValue('lineup', v)
    },
    [setFieldValue]
  )

  const descriptionCharLimit = useMemo(() => {
    return user.dicePartner || user.diceStaff ? 1500 : 300
  }, [user])

  const setDescriptionDraft = useCallback(
    (draft: Draft.EditorState) => {
      setFieldValue('descriptionDraft', draft)
      setFieldValue('description', renderMarkdown(draft))
    },
    [setFieldValue]
  )

  return (
    <Modal closeButton onClose={onClose} modalTitle={intl.formatMessage({ id: 'event_success.quick_edit.title' })}>
      <ModalBody>
        <form noValidate onSubmit={handleSubmit}>
          <Form>
            <FormRow columnOnMobile>
              <FormField
                name="name"
                label={intl.formatMessage({ id: 'event_success.quick_edit.name.label' })}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && errors.name}
              />
            </FormRow>

            <FormRow columnOnMobile>
              <EventSchedule
                handleBlur={handleBlur}
                lineup={values.lineup as ILineup[] | null}
                setLineup={setLineup}
                event={values}
                errors={errors}
                touched={touched}
                compact
                timezoneName={event.timezoneName}
              />
            </FormRow>

            <FormRow columnOnMobile>
              <FormField
                name="description"
                control={MarkdownEditor}
                label={intl.formatMessage({ id: 'event_success.quick_edit.description.label' })}
                hint={intl.formatMessage({ id: 'new_event.information.description.hint' })}
                placeholder={intl.formatMessage({ id: 'new_event.information.description.placeholder' })}
                value={values.descriptionDraft}
                onChange={setDescriptionDraft}
                onBlur={handleBlur}
                error={
                  (values.description || '').length > descriptionCharLimit
                    ? ' '
                    : touched.description && errors.description
                }
              >
                <Counter minCount={1} maxCount={descriptionCharLimit} count={(values.description || '').length}>
                  {(values.description || '').length}/{descriptionCharLimit}
                </Counter>
              </FormField>
            </FormRow>
          </Form>
        </form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl loading={isSubmitting} disabled={isSubmitting || !isValid} onClick={handleSubmit}>
          {intl.formatMessage({ id: 'actions.submit' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" disabled={isSubmitting} onClick={onEditDraft}>
          {intl.formatMessage({ id: 'event_success.quick_edit.open_as_draft' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(QuickEditModal)
