import React, { memo, FC, useState, useCallback, useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { fetchQuery, useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { Dictionary } from 'ts-essentials'
import { capitalize } from 'lodash/fp'

import Button from '../../../components/Button'
import { Dropdown, DropdownContent, DropdownTrigger } from '../../../components/Dropdown'
import { Menu, MenuItem } from '../../../components/Menu'
import { color, font, mediaQuery } from '../../../utils/variables'
import IEventForm from '../types'
import { notificationContext } from '../../../context/notification'
import { EventState } from '../../../enums.generated'
import { EventWorkflowMutation } from '../../../__generated__/EventWorkflowMutation.graphql'
import { EventWorkflowSyncMutation } from '../../../__generated__/EventWorkflowSyncMutation.graphql'
import EventWorkflowCommentModal from './EventWorkflowCommentModal'
import { EventWorkflowCommentMutation } from '../../../__generated__/EventWorkflowCommentMutation.graphql'
import { authContext } from '../../../context/auth'
import EventWorkflowCancelModal from './EventWorkflowCancelModal'
import TicketFeesConfirmationModal from '../../EventDetails/TicketFeesConfirmationModal'
import Icon from '../../../components/Svg'
import { EventWorkflowEventReviewQuery } from '../../../__generated__/EventWorkflowEventReviewQuery.graphql'
import {
  EventWorkflowEventReviewMutation,
  UpdateEventReviewInput,
} from '../../../__generated__/EventWorkflowEventReviewMutation.graphql'
import Checkbox, { CheckboxLabel } from '../../../components/Checkbox'
import { textStyle } from '../../../utils/typography'

const BACKEND_DONT_SUPPORT_NULL = '2001-01-01T00:00:00.000Z'

const LIVE_ACTIONS = '$$LIVE_ACTIONS$$'

type IExtendedState = EventState | typeof LIVE_ACTIONS

const TRANSITIONS_MAP: Partial<Dictionary<[EventState, IExtendedState], EventState>> = {
  SUBMITTED: ['DRAFT', 'REVIEW'],
  REVIEW: ['SUBMITTED', 'APPROVED'],
  APPROVED: ['REVIEW', LIVE_ACTIONS],
}

const nextState = (s: EventState | null): IExtendedState | null =>
  TRANSITIONS_MAP[s as keyof typeof TRANSITIONS_MAP]?.[1] || null

const prevState = (s: EventState | null): EventState | null =>
  TRANSITIONS_MAP[s as keyof typeof TRANSITIONS_MAP]?.[0] || null

const StyledDropdown = styled(Dropdown)`
  ${Button} + & {
    margin-left: 16px;
  }

  ${mediaQuery.lessThan('tablet')`
    width: 100%;

    margin-top: 16px;

    && {
      margin-left: 0;
    }
  `}
`

const Actions = styled(Button)`
  display: block;
  background-color: ${color.text};
  padding: 0 24px;
  border: 2px solid ${color.text};

  width: 100%;

  .button_icon-container:last-child > .button_icon {
    width: 8px;
    height: 8px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  min-width: 300px;

  display: flex;
  align-items: center;
  gap: 12px;

  font-size: ${font.size.base}px;
  font-weight: ${font.weight.bold};
  line-height: 20px;
  padding: 10px 20px;

  &:hover,
  &:active {
    background: none;
    color: ${color.primary};
  }

  ${mediaQuery.lessThan('tablet')`
    min-width: calc(100vw - 32px);
  `}

  ${Checkbox} {
    ${CheckboxLabel} {
      margin-left: 12px;
    }
  }
`

const MenuDivider = styled.div`
  height: 1px;
  background-color: ${color.disabled};
  margin: 10px 20px;
`

const MenuHint = styled.div`
  ${textStyle.functional.sm}
  color: ${color.darkgrey};
  padding: 0 20px 0 57px;
  overflow: auto;
  white-space: pre-wrap;
  margin-top: -16px;
`

interface IProps {
  disabled?: boolean
  forceEdit?: boolean
  onForceEdit?: () => void
}

const EventWorkflow: FC<React.PropsWithChildren<IProps>> = ({ disabled, forceEdit, onForceEdit }) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { user } = useContext(authContext)
  const environment = useRelayEnvironment()

  const [actions, setActions] = useState(false)
  const closeActions = useCallback(() => setActions(false), [])
  const toggleActions = useCallback(() => setActions((v) => !v), [])

  const { values } = useFormikContext<IEventForm>()

  const [loading, setLoading] = useState(false)

  const [commenting, setCommenting] = useState(false)
  const startCommenting = useCallback(() => setCommenting(true), [])
  const stopCommenting = useCallback(() => setCommenting(false), [])

  const [cancelling, setCancelling] = useState(false)
  const startCancelling = useCallback(() => setCancelling(true), [])
  const stopCancelling = useCallback(() => setCancelling(false), [])

  const { viewer } = useLazyLoadQuery<EventWorkflowEventReviewQuery>(
    graphql`
      query EventWorkflowEventReviewQuery($id: ID!) {
        viewer {
          eventReviews(first: 1, where: { event: { id: { eq: $id } } }) {
            edges {
              node {
                id
                priority
                status
                event {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {
      id: values.id,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  )

  const eventReview = useMemo(() => {
    const edges = viewer?.eventReviews?.edges
    return edges?.length ? edges[0]?.node : null
  }, [viewer?.eventReviews?.edges])

  const [commitCreateComment] = useMutation<EventWorkflowCommentMutation>(graphql`
    mutation EventWorkflowCommentMutation($input: CreateCommentActivityInput!) {
      createCommentActivity(input: $input) {
        activity {
          id
        }
      }
    }
  `)

  const [commitUpdateState] = useMutation<EventWorkflowMutation>(graphql`
    mutation EventWorkflowMutation($input: UpdateEventStateInput!) {
      updateEventState(input: $input) {
        event {
          state
          statusAsOfNow
          allowedLifecycleUpdates {
            venues {
              canUpdate
            }
          }
        }
      }
    }
  `)

  const [commitSync] = useMutation<EventWorkflowSyncMutation>(graphql`
    mutation EventWorkflowSyncMutation($input: SyncEventInput!) {
      syncEvent(input: $input) {
        event {
          state
          statusAsOfNow
          eventIdLive
        }
      }
    }
  `)

  const [commitUpdateEventReview] = useMutation<EventWorkflowEventReviewMutation>(graphql`
    mutation EventWorkflowEventReviewMutation($input: UpdateEventReviewInput!) {
      updateEventReview(input: $input) {
        successful
        messages {
          message
        }
        result {
          id
          priority
          status
          event {
            id
            lockVersion
            previewToken
          }
        }
      }
    }
  `)

  const [prevStateValue, nextStateValue] = useMemo(() => {
    let p = prevState(values.state)
    if (p === 'DRAFT' && !!values.eventIdLive) {
      p = null
    }

    const n = nextState(values.state)
    return [p, n]
  }, [values.eventIdLive, values.state])

  const addComment = useCallback(
    (comment: string) => {
      commitCreateComment({
        variables: {
          input: {
            clientMutationId: nanoid(),
            itemId: values.id,
            content: comment,
          },
        },
        onError() {
          addNotification('error', intl.formatMessage({ id: 'event_workflow.comment_error' }))
        },
      })
    },
    [addNotification, commitCreateComment, intl, values.id]
  )

  const updateEventReview = useCallback(
    (input: Pick<UpdateEventReviewInput, 'priority' | 'status'>) => {
      commitUpdateEventReview({
        variables: {
          input: {
            clientMutationId: nanoid(),
            eventId: values.id,
            priority: input.priority,
            status: input.status,
          },
        },
        onError() {
          addNotification('error', intl.formatMessage({ id: 'event_workflow.priority_error' }))
        },
      })
    },
    [addNotification, commitUpdateEventReview, intl, values.id]
  )

  const updateReviewStatus = useCallback(
    (e: React.MouseEvent<HTMLLIElement> | null) => {
      const status = (e?.currentTarget.dataset['id'] as UpdateEventReviewInput['status']) || null
      updateEventReview({ status: eventReview?.status === status ? null : status })
    },
    [eventReview?.status, updateEventReview]
  )

  // Backend is non-transactional so let's refetch things after errors just in case
  const forceRefetchEventState = useCallback(
    () =>
      new Promise<void>((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(
          fetchQuery(
            environment,
            graphql`
              query EventWorkflowForceRefetchQuery($id: ID!) {
                event: node(id: $id) {
                  ... on Event {
                    state
                    statusAsOfNow
                    lockVersion
                    billingNotes
                  }
                }
              }
            `,
            { id: values.id },
            { fetchPolicy: 'network-only' }
          ) as any
        ).subscribe({
          complete() {
            resolve()
          },
          error(e: any) {
            console.error(e)
            resolve()
          },
        })
      }),
    [environment, values.id]
  )

  const moveToState = useCallback(
    async (newState: EventState | null, notifyAt?: string | null) => {
      if (!newState) return

      return new Promise<void>((resolve, reject) =>
        commitUpdateState({
          variables: {
            input: {
              clientMutationId: nanoid(),
              id: values.id,
              state: newState,
              notify: !!notifyAt,
              notifyOn: notifyAt || BACKEND_DONT_SUPPORT_NULL,
            },
          },
          onCompleted(_payload, errors) {
            setLoading(false)
            if (!errors || errors.length === 0) {
              addNotification('success', intl.formatMessage({ id: 'event_workflow.move_success' }))
              resolve()
            } else {
              reject()
            }
          },
          onError() {
            setLoading(false)
            addNotification('error', intl.formatMessage({ id: 'event_workflow.move_error' }))
            reject()
          },
        })
      )
    },
    [addNotification, commitUpdateState, intl, values.id]
  )

  const moveForward = useCallback(async () => {
    if (!nextStateValue || nextStateValue === LIVE_ACTIONS) return
    setLoading(true)

    closeActions()

    const shouldClearStatus = nextStateValue === 'APPROVED'

    if (shouldClearStatus) {
      updateEventReview({
        status: null,
        priority: false,
      })
    }

    try {
      await moveToState(nextStateValue)
    } catch (_) {
      await forceRefetchEventState()
    }
  }, [closeActions, forceRefetchEventState, moveToState, nextStateValue, updateEventReview])

  const moveBack = useCallback(
    async ({ comment, priority }: any) => {
      if (!prevStateValue) return

      setCommenting(false)
      setLoading(true)

      closeActions()

      if (comment) {
        // Async! Not a big deal if it fails
        addComment(comment)
      }

      const shouldClearStatus = prevStateValue === 'DRAFT'
      const shouldUpdatePriority = !!priority !== !!eventReview?.priority

      if (shouldUpdatePriority || shouldClearStatus) {
        updateEventReview({
          priority: !!priority,
          status: shouldClearStatus ? null : eventReview?.status,
        })
      }

      try {
        await moveToState(prevStateValue)
      } catch (_) {
        await forceRefetchEventState()
      }
    },
    [
      addComment,
      closeActions,
      eventReview?.priority,
      eventReview?.status,
      forceRefetchEventState,
      moveToState,
      prevStateValue,
      updateEventReview,
    ]
  )

  const [showTicketFeesModal, setShowTicketFeesModal] = useState(false)

  const toggleTicketFeesModal = useCallback(() => {
    closeActions()
    setLoading(!showTicketFeesModal)
    setShowTicketFeesModal(!showTicketFeesModal)
  }, [closeActions, showTicketFeesModal])

  const syncCreate = useCallback(() => {
    setShowTicketFeesModal(false)
    closeActions()
    setLoading(true)

    commitSync({
      variables: {
        input: {
          clientMutationId: nanoid(),
          id: values.id,
        },
      },
      onCompleted(_payload, errors) {
        setLoading(false)
        if (!errors || errors.length === 0) {
          addNotification('success', intl.formatMessage({ id: 'event_workflow.move_success' }))
        }
      },
      onError() {
        forceRefetchEventState().then(() => {
          setLoading(false)
          addNotification('error', intl.formatMessage({ id: 'event_workflow.move_error' }))
        })
      },
    })
  }, [addNotification, closeActions, commitSync, forceRefetchEventState, intl, values.id])

  const cancelEvent = useCallback(
    async ({ notifyAt }: any) => {
      setLoading(true)
      closeActions()
      try {
        await moveToState('CANCELLED', notifyAt)
      } finally {
        // Refetch for updated billingNotes and lockVersion to prevent getting a
        // "Another user has submitted the form while you were editing it. Please reload the page." error
        // if user tries to make edits right after cancelling the event
        await forceRefetchEventState()
      }
    },
    [closeActions, forceRefetchEventState, moveToState]
  )

  const state = (values.state === 'APPROVED' && values.eventIdLive ? 'LIVE' : values.state) || 'DRAFT'
  const isSubmittedState = values.state === 'REVIEW' || values.state === 'SUBMITTED'

  const dropdownTriggerMessage = useMemo(() => {
    const reviewStatus = eventReview?.status || null
    const key = reviewStatus ? reviewStatus.toLowerCase() : state.toLowerCase()

    return intl.formatMessage({
      id: `event_workflow.states.${key}`,
      defaultMessage: key,
    })
  }, [eventReview?.status, intl, state])

  if (!user.diceStaff) return null

  return (
    <>
      <StyledDropdown active={actions} onClickOutside={closeActions}>
        <DropdownTrigger
          as={Actions}
          icon="dice-badge"
          preset="outline"
          color="tertiary"
          iconAfter={actions ? 'caret-up' : 'caret-down'}
          data-id="workflowActions"
          disabled={disabled || !(nextStateValue || prevStateValue)}
          loading={loading}
          onClick={toggleActions}
        >
          {dropdownTriggerMessage}
        </DropdownTrigger>
        <DropdownContent direction="up" align="left" active={actions}>
          <Menu>
            {nextStateValue === LIVE_ACTIONS && !values.eventIdLive && (
              <StyledMenuItem data-id="syncCreate" onClick={toggleTicketFeesModal} disabled={loading}>
                {intl.formatMessage({ id: 'event_workflow.sync_create' })}
              </StyledMenuItem>
            )}

            {nextStateValue === LIVE_ACTIONS && !!values.eventIdLive && (
              <StyledMenuItem data-id="cancelEvent" onClick={startCancelling} disabled={loading} color="error">
                {intl.formatMessage({ id: 'event_workflow.cancel_event' })}
              </StyledMenuItem>
            )}

            {nextStateValue && nextStateValue !== LIVE_ACTIONS && (
              <StyledMenuItem
                data-id="moveForward"
                onClick={moveForward}
                disabled={loading || nextStateValue === state}
              >
                <Icon icon="check" height={24} width={24} />
                {intl.formatMessage({
                  id: `event_workflow.move_forward.${state?.toLowerCase()}`,
                  defaultMessage: `Move to "${capitalize(nextStateValue)}"`,
                })}
              </StyledMenuItem>
            )}

            {prevStateValue && (
              <StyledMenuItem
                data-id="moveBack"
                onClick={startCommenting}
                disabled={loading || prevStateValue === state}
              >
                <Icon icon="ticket-return" height={24} width={24} />
                {intl.formatMessage({ id: 'event_workflow.move_back' })}
              </StyledMenuItem>
            )}

            {isSubmittedState && (
              <>
                <MenuDivider />

                <StyledMenuItem
                  data-id="ON_HOLD"
                  onClick={updateReviewStatus}
                  disabled={loading || eventReview?.status === 'ESCALATED'}
                >
                  <Checkbox
                    label={intl.formatMessage({ id: 'event_workflow.mark_as_on_hold' })}
                    checked={eventReview?.status === 'ON_HOLD'}
                  />
                </StyledMenuItem>
                <MenuHint>
                  {intl.formatMessage(
                    { id: 'event_workflow.mark_as_on_hold.hint' },
                    {
                      b: (str: string) => <strong>{str}</strong>,
                    }
                  )}
                </MenuHint>

                <StyledMenuItem
                  data-id="ESCALATED"
                  onClick={updateReviewStatus}
                  disabled={loading || eventReview?.status === 'ON_HOLD'}
                >
                  <Checkbox
                    label={intl.formatMessage({ id: 'event_workflow.mark_as_escalated' })}
                    checked={eventReview?.status === 'ESCALATED'}
                  />
                </StyledMenuItem>
                <MenuHint>
                  {intl.formatMessage(
                    { id: 'event_workflow.mark_as_escalated.hint' },
                    {
                      b: (str: string) => <strong>{str}</strong>,
                    }
                  )}
                </MenuHint>
              </>
            )}

            <MenuDivider />

            {!forceEdit && (
              <StyledMenuItem data-id="forceEdit" onClick={onForceEdit} disabled={loading}>
                {intl.formatMessage({ id: 'event_workflow.force_edit' })}
              </StyledMenuItem>
            )}
          </Menu>
        </DropdownContent>
      </StyledDropdown>
      {commenting && (
        <EventWorkflowCommentModal
          cta={
            // prettier-ignore
            prevStateValue
              ? intl.formatMessage(
                { id: 'event_workflow.back_to' },
                {
                  state: intl.formatMessage({
                    id: `event_workflow.states.${prevStateValue.toLowerCase()}`,
                    defaultMessage: capitalize(prevStateValue),
                  }),
                }
              )
              : undefined
          }
          onSave={moveBack}
          onClose={stopCommenting}
        />
      )}
      {showTicketFeesModal && (
        <TicketFeesConfirmationModal
          firstSync
          values={values}
          onConfirm={syncCreate}
          onReject={toggleTicketFeesModal}
        />
      )}
      {cancelling && <EventWorkflowCancelModal event={values} onSave={cancelEvent} onClose={stopCancelling} />}
    </>
  )
}

export default memo(EventWorkflow)
