import React, { FC, memo, ReactNode, useContext, useMemo } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useIntl } from 'react-intl'
import { map, without } from 'lodash/fp'

import { OtherCurrenciesHintQuery } from '../../../__generated__/OtherCurrenciesHintQuery.graphql'
import { EventCostCurrency } from '../../../enums.generated'
import { getAlpha2ByName, getCurrencyByCountryCode } from '../../../utils/countries'
import { localeContext } from '../../../context/locale'

interface IProps {
  promoterId: string
  message?: ReactNode
  fallbackMessage?: ReactNode
  refCurrency?: EventCostCurrency | null
  refCountry?: string | null
}

const OtherCurrenciesHint: FC<IProps> = ({ promoterId, message, fallbackMessage, refCountry, refCurrency }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const { promoter } = useLazyLoadQuery<OtherCurrenciesHintQuery>(
    graphql`
      query OtherCurrenciesHintQuery($id: ID!) {
        promoter: node(id: $id) {
          ... on Promoter {
            addressCountry
            countryCode
            stripeAccountCurrencies
          }
        }
      }
    `,
    {
      id: promoterId,
    },
    {
      fetchPolicy: 'store-or-network',
    }
  )

  const currencies = useMemo(
    () => map((s) => s?.toUpperCase(), promoter?.stripeAccountCurrencies || []) as EventCostCurrency[],
    [promoter?.stripeAccountCurrencies]
  )

  const countryCurrency = useMemo(
    () =>
      getCurrencyByCountryCode(
        refCountry || promoter?.countryCode || getAlpha2ByName(promoter?.addressCountry, locale)
      ),
    [locale, promoter?.addressCountry, promoter?.countryCode, refCountry]
  )

  const hasOtherCurrencies = useMemo(
    () => without([refCurrency || countryCurrency || 'GBP'], currencies).length > 0,
    [countryCurrency, currencies, refCurrency]
  )

  return (
    <>
      {hasOtherCurrencies
        ? message ||
          intl.formatMessage({ id: 'promoters.currencies_warning' }, { b: (str: string) => <strong>{str}</strong> })
        : fallbackMessage || null}
    </>
  )
}

export default memo(OtherCurrenciesHint)
