import { useEffect, useRef, useState } from 'react'
import { compose, isEqual, keyBy, map, mapValues, set, some, update } from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'

import { useDebounce } from '../../../utils/hooks/useDebounce'
import getProductPriceBreakdown from '../../../utils/getProductPriceBreakdown'
import { IProduct, IFee } from '../types/Extras'

export const convertBreakdown = compose([
  (pb) => set('rebate', (pb.split?.billingPromoter || 0) - (pb.faceValue || 0), pb),
  update('split', compose([mapValues('computed'), keyBy('destination')])),
])

interface IProps {
  inputs: {
    categoryId: string | null
    faceValue: number
    initialBreakdown: IProduct['priceBreakdown']
    fees: Array<IFee | null> | null
  }
  ctx: {
    venueId: string | null
    eventId: string | null
    billingPromoterId: string | null
    basePriceFees: ReadonlyArray<string | null> | null
    postFanPriceFees: ReadonlyArray<string | null> | null
    disableUsTax: boolean
  }
}

function useProductPriceBreakdown(inputs: IProps['inputs'][], ctx: IProps['ctx']) {
  const environment = useRelayEnvironment()

  const debouncedInputs = useDebounce(inputs, 500)
  const [loading, setLoading] = useState(false)
  const [priceBreakdowns, setPriceBreakdowns] = useState(() =>
    map(({ initialBreakdown }) => initialBreakdown && convertBreakdown(initialBreakdown), inputs)
  )

  const firstTime = useRef(true)
  const prevData = useRef({ ctx, inputs })

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false
      const hasMissingData = some((inp) => !inp.initialBreakdown, inputs || [])
      if (!hasMissingData) {
        return
      }
    } else {
      const data = { ctx, inputs: debouncedInputs }
      if (isEqual(prevData.current, data)) {
        return
      }
      prevData.current = data
    }

    let stillMounted = true
    setLoading(true)

    const { venueId, eventId, billingPromoterId, basePriceFees, postFanPriceFees, disableUsTax } = ctx
    const promises = map(
      ({ categoryId, faceValue, fees }) =>
        getProductPriceBreakdown(environment, {
          billingPromoterId,
          eventId,
          fees,
          basePriceFees,
          postFanPriceFees,
          disableUsTax,
          categoryId,
          faceValue,
          venueId,
        }),
      debouncedInputs
    ) as any

    Promise.all(promises)
      .then((results) => {
        if (stillMounted) {
          setPriceBreakdowns(results)
        }
      })
      .finally(() => {
        if (stillMounted) {
          setLoading(false)
        }
      })

    return () => {
      stillMounted = false
    }
  }, [debouncedInputs, ctx, inputs, environment])

  return {
    loading,
    priceBreakdowns,
  }
}

export default useProductPriceBreakdown
