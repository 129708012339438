import React, { memo, useState, useContext, useMemo, useCallback, FC, Fragment, Suspense } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { isFuture, isPast, parseISO } from 'date-fns'
import graphql from 'babel-plugin-relay/macro'
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay'
import { nanoid } from 'nanoid'

import { useMediaQuery } from 'react-responsive'

import { PONTARE_URL } from '../../../../env'
import { authContext } from '../../../../context/auth'
import { notificationContext } from '../../../../context/notification'
import { trackingContext } from '../../../../context/tracking'
import { textStyle } from '../../../../utils/typography'
import { breakpoints, mediaQuery, zIndex } from '../../../../utils/variables'
import { collectTrackingData } from '../../../../utils/tracking'
import { isPinned, pinEvent, unpinEvent } from '../../../../utils/pinning'
import { stashAuth } from '../../../../utils/api'
import { dicefmEvent, dicefmPreview } from '../../../../utils/dicefm'
import unwrapId from '../../../../utils/unwrapId'
import copyToClipboard from '../../../../utils/copyToClipboard'
import useRendered from '../../../../utils/useRendered'

import Button from '../../../../components/Button'
import IconButton from '../../../../components/IconButton'
import { OnMobile, OnDesktop } from '../../../../components/Breakpoints'
import EventPartPayout from '../../../EventList/components/EventPartPayout'
import { ConfirmationModal } from '../../../../components/ConfirmationModal'
import { Dropdown, DropdownTrigger, DropdownContent } from '../../../../components/Dropdown'
import { Menu, MenuItem as MenuItemComponent } from '../../../../components/Menu'
import PermissionCheck from '../../../../components/PermissionCheck'
import PayoutModal from '../../../EventList/components/Modals/PayoutModal'
import DiceBadge from '../../../../components/DiceBadge'
import CopyButton from '../../../../components/CopyButton'
import EventAssigneeControl from '../../../../components/EventAssigneeControl'

import { EventControls_event$key } from '../../../../__generated__/EventControls_event.graphql'
import { EventControlsPriorityMutation } from '../../../../__generated__/EventControlsPriorityMutation.graphql'
import useCopyEvent from '../../../EventList/hooks/useCopyEvent'
import usePreviewEventOnDiceCallback from '../../../../utils/hooks/usePreviewEventOnDiceCallback'
import { EventControlsReviewQuery } from '../../../../__generated__/EventControlsReviewQuery.graphql'
import useEventPayout from '../../../../utils/hooks/useEventPayout'

const Controls = styled.div`
  position: absolute;
  display: flex;
  flex: 40px 0 0;
  right: 32px;
  z-index: ${zIndex.dropdown + 1};

  ${Button} + ${Button},
  ${IconButton} + ${Button},
  ${Button} + ${IconButton},
  ${IconButton} + ${Dropdown},
  ${Button} + ${Dropdown} {
    margin-left: 8px;
  }

  ${mediaQuery.lessThan('tablet')`
    top: 16px;
    right: 16px;
  `}
`

const MenuItem = styled(MenuItemComponent)`
  display: block;
`

const OverriddenFeesError = styled.div`
  width: 200px;
  margin-top: 4px;
  white-space: normal;
  ${textStyle.functional.xs};
`

interface IProps {
  event: EventControls_event$key
  eventId: string
  stripeIsBroken: boolean
}

const EventControls: FC<IProps> = (props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { user, hasPermission, account } = useContext(authContext)
  const { trackEvent } = useContext(trackingContext)
  const { addNotification } = useContext(notificationContext)

  const [confirmationModal, setConfirmationModal] = useState(false)

  const event = useFragment(
    graphql`
      fragment EventControls_event on Event
      @argumentDefinitions(needBalance: { type: "Boolean!" }, isDraft: { type: "Boolean!" }) {
        id
        eventIdLive
        eventType
        previewToken
        name
        state
        announceDate
        onSaleDate
        date
        endDate
        organicSocialLink @skip(if: $isDraft)
        costCurrency
        allowedActions {
          performPayout
        }
        feesBehaviour

        ...EventPartPayout_event @include(if: $needBalance)

        detectBalance: id @include(if: $needBalance)
        ...useEventPayout_event @include(if: $needBalance)
      }
    `,
    props.event
  )

  const { viewer } = useLazyLoadQuery<EventControlsReviewQuery>(
    graphql`
      query EventControlsReviewQuery($id: ID!) {
        viewer {
          eventReviews(first: 1, where: { event: { id: { eq: $id } } }) {
            edges {
              node {
                id
                priority
                ...EventAssigneeControl_eventReview
              }
            }
          }
        }
      }
    `,
    {
      id: props.eventId,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  )

  const eventReview = useMemo(() => {
    const edges = viewer?.eventReviews?.edges
    return edges?.length ? edges[0]?.node : null
  }, [viewer?.eventReviews?.edges])

  const toggleModal = useCallback(() => {
    setConfirmationModal((confirmationModal) => !confirmationModal)
  }, [])

  const eventType = useMemo(() => {
    if (event.state === 'DRAFT') return 'draft'
    if (event.state === 'CANCELLED') return 'cancelled'
    if (event.endDate && isFuture(parseISO(event.endDate))) return 'live'
    if (!event.eventIdLive) return 'draft'
    return 'past'
  }, [event])

  const eventLink = event.organicSocialLink || (event.eventIdLive ? dicefmEvent(event.eventIdLive) : null)
  const previewEventOnDiceLink = event.previewToken && dicefmPreview(event.previewToken)

  const showEventOnDice = useCallback(() => {
    if (eventLink) {
      trackEvent('event_dice_page_opened', collectTrackingData(event))
      window.open(eventLink, '_blank')
    }
  }, [event, eventLink, trackEvent])

  const previewEventOnDice = usePreviewEventOnDiceCallback(event.previewToken)

  const copyEventLinkToClipboard = useCallback(() => {
    copyToClipboard(event.eventIdLive ? eventLink : previewEventOnDiceLink)
      .then(() => {
        addNotification('success', intl.formatMessage({ id: 'event_list.quick_actions.url_copied' }))
      })
      .catch((err) => {
        console.error(err)
      })
  }, [addNotification, event.eventIdLive, eventLink, intl, previewEventOnDiceLink])

  const copyEvent = useCopyEvent(event)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const closeDropdown = useCallback(() => setDropdownOpen(false), [])
  const toggleDropdown = useCallback(() => setDropdownOpen((v) => !v), [])

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const canPin = useMemo(() => {
    if (user.diceStaff || !event.date || !event.endDate || event.state === 'CANCELLED') return false
    if (!isMobile) return false

    return !isPast(parseISO(event.endDate))
  }, [event.date, event.endDate, event.state, isMobile, user.diceStaff])

  const [pinKey, setPinKey] = useState('initial')

  const pinMe = useCallback(() => {
    trackEvent('pinned_event_added', {
      event_id: unwrapId(event.id || undefined),
    })

    pinEvent(account?.id, event.id)
    addNotification('success', intl.formatMessage({ id: 'notification.event_pinned' }, { name: event.name }))
    setPinKey(nanoid())
    navigate('/dashboard')
  }, [account?.id, addNotification, event.id, event.name, navigate, intl, trackEvent])

  const unpinMe = useCallback(() => {
    if (!isPinned(account?.id, event.id)) return

    trackEvent('pinned_event_removed', {
      event_id: unwrapId(event.id || undefined),
    })

    unpinEvent(account?.id)
    setPinKey(nanoid())
    addNotification('success', intl.formatMessage({ id: 'notification.event_unpinned' }))
  }, [account?.id, addNotification, event.id, intl, trackEvent])

  const [commitUpdatePriority] = useMutation<EventControlsPriorityMutation>(graphql`
    mutation EventControlsPriorityMutation($input: UpdateEventReviewInput!) {
      updateEventReview(input: $input) {
        successful
        messages {
          message
        }
        result {
          priority
        }
      }
    }
  `)

  const [prioritizing, setPrioritizing] = useState(false)
  const togglePriority = useCallback(() => {
    setPrioritizing(true)
    setDropdownOpen(false)

    commitUpdatePriority({
      variables: {
        input: {
          clientMutationId: nanoid(),
          eventId: event.id,
          priority: eventReview ? !eventReview.priority : false,
        },
      },
      onCompleted() {
        setPrioritizing(false)
      },
      onError() {
        setPrioritizing(false)
        addNotification('error', intl.formatMessage({ id: 'event_workflow.priority_error' }))
      },
    })
  }, [addNotification, commitUpdatePriority, event.id, eventReview, intl])

  const { isPaidOut } = useEventPayout(!!event.detectBalance ? event : null)

  const [payoutModalLoading, PayoutLoaderSpy] = useRendered()
  const [payoutModal, setPayoutModal] = useState(false)
  const togglePayoutModal = useCallback(() => {
    closeDropdown()
    setPayoutModal((v) => !v)
  }, [closeDropdown])

  const isAnnouncedSynced = useMemo(
    () => !!event.eventIdLive && !!event.announceDate && isPast(parseISO(event.announceDate)),
    [event.announceDate, event.eventIdLive]
  )

  const fullVersion = (
    <>
      {(event.eventType === 'LIVE' || user.diceStaff) && hasPermission('create:event') && (
        <Button preset="secondary" onClick={toggleModal} data-id="duplicateButton" disabled={props.stripeIsBroken}>
          {intl.formatMessage({ id: 'event_list.quick_actions.duplicate_event' })}
        </Button>
      )}
      {event.allowedActions?.performPayout && eventType === 'past' && !isPaidOut ? (
        <EventPartPayout
          event={event}
          color="black"
          type="button"
          loading={payoutModalLoading}
          onClick={togglePayoutModal}
        />
      ) : (
        <>
          {event.eventIdLive && (
            <Button
              data-id="showOnDiceButton"
              onClick={showEventOnDice}
              disabled={!event.eventIdLive || event.state !== 'APPROVED'}
            >
              {intl.formatMessage({ id: 'event_list.quick_actions.view_event_on_dice' })}
            </Button>
          )}
          {event.previewToken && !isAnnouncedSynced && (
            <Button data-id="previewOnDiceButton" onClick={previewEventOnDice}>
              {intl.formatMessage({ id: 'event_list.quick_actions.preview_event_on_dice' })}
            </Button>
          )}
          {(event.eventIdLive || event.previewToken) && (
            <CopyButton
              icon="link"
              link={(event.eventIdLive ? eventLink : previewEventOnDiceLink) || ''}
              title={intl.formatMessage({
                id: event.eventIdLive
                  ? 'event_list.quick_actions.copy_event_url'
                  : 'event_list.quick_actions.copy_preview_url',
              })}
              successMessage={intl.formatMessage({ id: 'event_list.quick_actions.url_copied' })}
            />
          )}
        </>
      )}
      {canPin && (
        <Fragment key={pinKey}>
          {isPinned(account?.id, event.id) ? (
            <IconButton
              outlineColor="primary"
              color="primary"
              icon="pin"
              onClick={unpinMe}
              title={intl.formatMessage({ id: 'event_list.quick_actions.unpin_event' })}
              data-id="unpinEventButton"
            />
          ) : (
            <IconButton
              icon="pin"
              outlineColor="black"
              onClick={pinMe}
              title={intl.formatMessage({ id: 'event_list.quick_actions.pin_event' })}
              data-id="pinEventButton"
            />
          )}
        </Fragment>
      )}
    </>
  )

  const compactVersion = (
    <>
      <Dropdown active={dropdownOpen} onClickOutside={closeDropdown}>
        <DropdownTrigger role="button" onClick={toggleDropdown} data-id="menuButton">
          <OnDesktop>
            <IconButton icon="more" />
          </OnDesktop>
          <OnMobile>
            <IconButton outlineColor="grey" icon="more" />
          </OnMobile>
        </DropdownTrigger>
        <DropdownContent active={dropdownOpen}>
          <Menu>
            {user.diceStaff && (
              <>
                {event.eventIdLive && (
                  <MenuItem>
                    <a
                      onClick={stashAuth}
                      href={`${PONTARE_URL}/event/${event.eventIdLive}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-id="openInPontareLink"
                    >
                      <DiceBadge />
                      {intl.formatMessage({ id: 'event_list.quick_actions.open_in_pontare' })}
                    </a>
                  </MenuItem>
                )}
                <OnMobile>
                  {eventType !== 'past' && eventReview && (
                    <MenuItem onClick={togglePriority} data-id="togglePriority">
                      {intl.formatMessage({
                        id: eventReview.priority
                          ? 'event_list.quick_actions.deprioritize_event'
                          : 'event_list.quick_actions.prioritize_event',
                      })}
                    </MenuItem>
                  )}
                </OnMobile>
              </>
            )}
            {canPin && (
              <>
                {isPinned(account?.id, event.id) ? (
                  <MenuItem onClick={unpinMe} data-id="unpinEventButton">
                    {intl.formatMessage({ id: 'event_list.quick_actions.unpin_event' })}
                  </MenuItem>
                ) : (
                  <MenuItem onClick={pinMe} data-id="pinEventButton">
                    {intl.formatMessage({ id: 'event_list.quick_actions.pin_event' })}
                  </MenuItem>
                )}
              </>
            )}
            {(event.eventType === 'LIVE' || user.diceStaff) && (
              <PermissionCheck permission="create:event">
                <MenuItem
                  onClick={toggleModal}
                  data-id="duplicateButton"
                  disabled={props.stripeIsBroken || (!user.diceStaff && event.feesBehaviour === 'OVERRIDE')}
                >
                  {intl.formatMessage({ id: 'event_list.quick_actions.duplicate_event' })}
                  {!user.diceStaff && event.feesBehaviour === 'OVERRIDE' && (
                    <OverriddenFeesError>
                      {intl.formatMessage({ id: 'event_list.quick_actions.duplicate_event.overridden_fees_error' })}
                    </OverriddenFeesError>
                  )}
                </MenuItem>
              </PermissionCheck>
            )}
            {event.allowedActions?.performPayout && eventType === 'past' && !isPaidOut ? (
              <EventPartPayout
                event={event}
                color="black"
                type="menu"
                loading={payoutModalLoading}
                onClick={togglePayoutModal}
              />
            ) : (
              <>
                <MenuItem
                  data-id="showOnDiceButton"
                  onClick={showEventOnDice}
                  disabled={!event.eventIdLive || (event.state !== 'APPROVED' && event.state !== 'CANCELLED')}
                >
                  {intl.formatMessage({ id: 'event_list.quick_actions.view_event_on_dice' })}
                </MenuItem>

                {event.previewToken && !isAnnouncedSynced && (
                  <MenuItem data-id="previewOnDiceButton" onClick={previewEventOnDice}>
                    {intl.formatMessage({ id: 'event_list.quick_actions.preview_event_on_dice' })}
                  </MenuItem>
                )}
                {(event.eventIdLive || event.previewToken) && (
                  <MenuItem data-id="copyEventLinkButton" onClick={copyEventLinkToClipboard}>
                    {intl.formatMessage({
                      id: event.eventIdLive
                        ? 'event_list.quick_actions.copy_event_url'
                        : 'event_list.quick_actions.copy_preview_url',
                    })}
                  </MenuItem>
                )}
              </>
            )}
          </Menu>
        </DropdownContent>
      </Dropdown>
    </>
  )

  return (
    <Controls>
      {user.diceStaff ? (
        <>
          <OnDesktop>
            {event.eventIdLive && (
              <Button
                data-id="showOnDiceButton"
                onClick={showEventOnDice}
                disabled={!event.eventIdLive || event.state !== 'APPROVED'}
              >
                {intl.formatMessage({ id: 'event_list.quick_actions.view_event_on_dice' })}
              </Button>
            )}
            {!!eventReview && (
              <>
                <EventAssigneeControl eventReview={eventReview} />
                {eventType !== 'past' && (
                  <IconButton
                    key={eventReview.priority ? 'priority' : 'nonpriority'}
                    icon={eventReview.priority ? 'star-filled' : 'star'}
                    outlineColor="tertiary"
                    onClick={togglePriority}
                    loading={prioritizing}
                    title={intl.formatMessage({
                      id: eventReview.priority
                        ? 'event_list.quick_actions.deprioritize_event'
                        : 'event_list.quick_actions.prioritize_event',
                    })}
                    data-id="starButton"
                    className="ml-md"
                  />
                )}
              </>
            )}
          </OnDesktop>
          {compactVersion}
        </>
      ) : (
        <>
          <OnDesktop>{fullVersion}</OnDesktop>
          <OnMobile>{compactVersion}</OnMobile>
        </>
      )}
      {confirmationModal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'confirmation.duplicate_event.title' })}
          description={intl.formatMessage({ id: 'confirmation.duplicate_event.description' })}
          onConfirm={copyEvent}
          onReject={toggleModal}
        />
      )}
      {payoutModal && (
        <Suspense fallback={<PayoutLoaderSpy />}>
          <PayoutModal eventId={event.id} onClose={togglePayoutModal} />
        </Suspense>
      )}
    </Controls>
  )
}

export default memo(EventControls)
