/**
 * @generated SignedSource<<d9604f1da6a16be0b9ec46a4aebc9533>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CitiesWhereInput = {
  name?: string | null;
};
export type useSettingsFieldsCityQuery$variables = {
  where?: CitiesWhereInput | null;
};
export type useSettingsFieldsCityQuery$data = {
  readonly viewer: {
    readonly options: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
  } | null;
};
export type useSettingsFieldsCityQuery = {
  response: useSettingsFieldsCityQuery$data;
  variables: useSettingsFieldsCityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "alias": "options",
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "where"
    }
  ],
  "concreteType": "City",
  "kind": "LinkedField",
  "name": "cities",
  "plural": true,
  "selections": [
    {
      "alias": "value",
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "label",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSettingsFieldsCityQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSettingsFieldsCityQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4be404ac8d1c1fd5dcbf95af4d237a2",
    "id": null,
    "metadata": {},
    "name": "useSettingsFieldsCityQuery",
    "operationKind": "query",
    "text": "query useSettingsFieldsCityQuery(\n  $where: CitiesWhereInput\n) {\n  viewer {\n    options: cities(where: $where) {\n      value: id\n      label: name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbe61c23615553be76e8ad9a8a39fdbb";

export default node;
