import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import { compact, filter, find, flow, reject, some, sortBy } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components/macro'
import { isPast, parseISO } from 'date-fns'
import { breakpoints, color } from '../../../../utils/variables'

import Badge from '../../../../components/Badge'
import Svg from '../../../../components/Svg'
import { Text } from '../../../../components/Text'
import { TitleTooltip } from '../../../../components/Tooltip'
import { AlertModal } from '../../../../components/AlertModal'
import TicketPromotion from '../../../../components/Event/TicketPromotion'

import useTicketTypeCalculations from '../../hooks/useTicketTypeCalculations'
import useBreakdownState from '../../hooks/useBreakdownState'

import {
  Basket,
  DetailsToggle,
  TicketType,
  TicketTypeCol,
  TicketTypeIcon,
  TicketTypeName,
  TicketTypeNameWithBadge,
  TicketTypePrice,
  TicketTypeRow,
  TicketTypes,
} from './TicketBreakdownStyles'
import PriceTiersBreakdownTable from './PriceTiersBreakdownTable'
import PromotionsBreakdown from './PromotionsBreakdown'
import TicketSalesProgress from './TicketSalesProgress'
import TicketActions from './TicketActions'
import TicketSales from './TicketSales'
import AddPromotion from './AddPromotion'

import { TicketBreakdown_event$key } from '../../../../__generated__/TicketBreakdown_event.graphql'
import { authContext } from '../../../../context/auth'
import IdTag from '../../../../components/IdTag'

const TTYPrice = styled(TicketTypePrice)`
  display: flex;
`

const hasDiscountPromotion = (promotions: any) => {
  return !!find((p) => p?.eventPromotion?.promotionType === 'DISCOUNT', promotions)
}

interface IProps {
  event: TicketBreakdown_event$key
  ticketPoolId?: string | null
  className?: string
}

const TicketBreakdown: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, ticketPoolId, className }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment TicketBreakdown_event on Event {
        ...EventTicketTypeModal_event
        ...PriceTiersBreakdownTable_event
        ...TicketPromotion_event
        id
        eventIdLive
        lockVersion
        previewToken
        state
        allowedActions {
          minorUpdate
          manageTickets
        }
        allowedLifecycleUpdates {
          ticketTypes {
            canChangeOnSaleDate
            canChangeOffSaleDate
          }
        }
        costCurrency
        timezoneName
        diceTvPlatform
        eventType
        date
        announceDate
        onSaleDate
        offSaleDate
        maxTicketsLimit

        flags {
          seated
          waitingList
        }

        venues {
          addressCountry
          countryCode
          capacity
        }

        venueSchedules {
          id
        }

        ticketTypes(doorSalesOnly: false, includeArchived: true) {
          id
          archived
        }

        ticketPools {
          id
          maxAllocation
        }

        sales {
          ticketTypesBreakdown {
            ticketType {
              id
              salesLimit
              ticketPoolId
              name
              archived
              hidden
              isStream
              doorSalesEnabled
              icon
              codeLocked
              reservedSeating
              eventPromotions {
                id
              }
              priceTierType
              onSaleDate
              offSaleDate
              doorSalesPrice
              allocation
              faceValue
              price
              priceHidden

              priceBreakdown {
                total
                totalWithPwl
                totalWithoutPwl
                faceValue
              }

              ...TicketPromotion_ticketType
            }
            totalAppSold
            totalPosSold
            totalReserved
            totalWlRequests
            totalWlPurchases
            totalFaceValue
            totalDigitalValue
            totalPosValue
            totalSold
            promotionsBreakdown {
              eventPromotion {
                name
                promotionType
              }
              faceValue
              price
              priceWithPwl
              priceWithoutPwl
              posSold
              value
              sold
              payoutValue
              appSold
              rebate
              fees {
                computed
                rebate
                type
              }
            }
            ...PriceTiersBreakdownTable_ticketType
            priceTiersBreakdown {
              appSold
              posSold
              priceTier {
                id
                order
                faceValue
                price
                allocation
                time

                priceBreakdown {
                  total
                  totalWithPwl
                  totalWithoutPwl
                  faceValue
                }
              }
            }
          }
        }
      }
    `,
    eventKey
  )

  const {
    dropdownId,
    closeDropdown,
    toggleDropdown,
    allocationId,
    closeAllocation,
    doChangeAllocation,
    problemAlert,
    closeProblemAlert,
  } = useBreakdownState(event.id, event.eventIdLive)

  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.desktop}px)` })

  const ttys = useMemo(
    () =>
      flow(
        compact,
        user.diceStaff ? sortBy('ticketType.archived') : reject('ticketType.archived'),
        filter((tt: any) => tt.ticketType.ticketPoolId === ticketPoolId)
      )(event.sales?.ticketTypesBreakdown || []) as any[],
    [event.sales?.ticketTypesBreakdown, user.diceStaff, ticketPoolId]
  )

  const hasBoxOffice = useMemo(() => some((tty) => tty.ticketType.doorSalesEnabled, ttys), [ttys])
  const isReadOnly = !event?.allowedActions?.minorUpdate && !event?.allowedActions?.manageTickets

  const { isSold, isPoolSold, isOffSalePast, ttPrice, basketCount, basketColor } = useTicketTypeCalculations(
    event.costCurrency || undefined,
    event.sales?.ticketTypesBreakdown,
    event.ticketPools
  )

  const [expanded, setExpanded] = useState<any>([])
  const toggleDetails = useCallback(
    (e: any) => {
      const tty = e.currentTarget.dataset['tty']
      setExpanded(some((id) => id === tty, expanded) ? reject((id) => id === tty, expanded) : [...expanded, tty])
    },
    [expanded]
  )

  return (
    <>
      <TicketTypes className={className}>
        {ttys.map((tt) => {
          const onSaleDate = tt.ticketType.onSaleDate || event.onSaleDate
          const isOnSale = onSaleDate && isPast(parseISO(onSaleDate)) && !isOffSalePast(tt)

          return (
            <TicketTypeRow
              key={tt.ticketType.id}
              data-id={tt.ticketType.id}
              isArchived={!!tt.ticketType.archived}
              isSold={isSold(tt) && !isPoolSold(tt)}
            >
              <TicketType>
                <TicketTypeCol flexDirection="column" justify="center" order={0}>
                  <TicketTypeIcon hidden={tt.ticketType.hidden}>
                    {tt.ticketType.archived ? (
                      <Svg icon="trash" />
                    ) : (
                      <Svg icon={tt.ticketType.hidden ? 'eye-crossed' : `ticket-type-${tt.ticketType.icon}`} />
                    )}
                    {(tt.ticketType.eventPromotions?.length || 0) > 0 && (
                      <TicketPromotion event={event} tty={tt.ticketType} />
                    )}
                  </TicketTypeIcon>
                  <TicketTypeNameWithBadge>
                    <TicketTypeName>
                      {tt.ticketType.codeLocked && <Svg icon="lock" />}
                      <strong>{tt.ticketType.name}</strong>
                    </TicketTypeName>
                    <IdTag
                      id={tt.ticketType.id}
                      successMessage={intl.formatMessage({ id: 'action.ticket_id_copied' })}
                    />
                    {(isOffSalePast(tt) || isSold(tt)) && !tt.ticketType.ticketPoolId && (
                      <div>
                        <Badge>
                          {intl.formatMessage({ id: isSold(tt) ? 'event_status.sold-out' : 'event_status.off-sale' })}
                        </Badge>
                      </div>
                    )}
                  </TicketTypeNameWithBadge>
                  {!tt.priceTiersBreakdown.length && (
                    <TTYPrice>
                      {tt.ticketType.priceHidden && (
                        <TitleTooltip
                          title={intl.formatMessage({
                            id: isOnSale
                              ? 'new_event.tickets.ticket_types.price_hidden.on_sale.tooltip'
                              : 'new_event.tickets.ticket_types.price_hidden.tooltip',
                          })}
                        >
                          <Svg icon="eye-crossed" height="20px" color={isOnSale ? color.error : undefined} />
                        </TitleTooltip>
                      )}
                      {!hasDiscountPromotion(tt.promotionsBreakdown) && ttPrice(tt)}
                      {tt.promotionsBreakdown?.length > 0 && (
                        <DetailsToggle
                          data-tty={tt.ticketType.id}
                          expanded={some((id) => id === tt.ticketType.id, expanded)}
                          onClick={toggleDetails}
                        >
                          {some((id) => id === tt.ticketType.id, expanded)
                            ? intl.formatMessage({ id: 'event_overview.ticket_breakdown.hide_details' })
                            : intl.formatMessage({ id: 'event_overview.ticket_breakdown.show_details' })}
                          <Svg icon="collapsible" />
                        </DetailsToggle>
                      )}
                    </TTYPrice>
                  )}
                </TicketTypeCol>

                <TicketTypeCol order={isTablet ? 3 : 1}>
                  {basketCount(tt) > 0 && (
                    <TitleTooltip
                      title={intl.formatMessage(
                        {
                          id: `event_overview.ticket_breakdown.${
                            isSold(tt) ? 'waiting_list_sold' : 'reserved'
                          }_tooltip`,
                        },
                        { count: basketCount(tt) }
                      )}
                    >
                      <Basket borderColor={basketColor(tt)}>
                        <Svg icon={isSold(tt) ? 'ticket-wl' : 'basket'} />
                        <Text display="block" color="text">
                          {basketCount(tt)}
                        </Text>
                      </Basket>
                    </TitleTooltip>
                  )}
                  <TicketSalesProgress event={event} ticketType={tt} pooled={!!ticketPoolId} />
                </TicketTypeCol>

                <TicketTypeCol shifted={hasBoxOffice || !!ticketPoolId} order={2} nonInteractive>
                  <TicketSales event={event} ticketType={tt} />
                </TicketTypeCol>

                {!isReadOnly && <TicketActions event={event} ticketType={tt} />}

                {!tt.priceTiersBreakdown.length && some((id) => id === tt.ticketType.id, expanded) && (
                  <PromotionsBreakdown
                    promotions={tt.promotionsBreakdown}
                    currency={event.costCurrency}
                    type="ticketType"
                    hasBoxOffice={hasBoxOffice}
                    hasDiscount={hasDiscountPromotion(tt.promotionsBreakdown)}
                  />
                )}
              </TicketType>

              <PriceTiersBreakdownTable
                event={event}
                ticketType={tt}
                isOnSale={isOnSale}
                hasBoxOffice={hasBoxOffice}
                hasDiscount={hasDiscountPromotion(tt.promotionsBreakdown)}
                allocationId={allocationId}
                closeAllocation={closeAllocation}
                doChangeAllocation={doChangeAllocation}
                dropdownId={dropdownId}
                closeDropdown={closeDropdown}
                toggleDropdown={toggleDropdown}
              />

              {!isSold(tt) &&
                tt.ticketType.codeLocked &&
                !reject('isEnded', tt.ticketType.eventPromotions || []).length && (
                <AddPromotion event={event} ticketType={tt} />
              )}
            </TicketTypeRow>
          )
        })}
      </TicketTypes>
      {problemAlert && (
        <AlertModal
          title={intl.formatMessage({ id: 'active_ticket_error.title' })}
          description={intl.formatMessage({ id: 'active_ticket_error.description' })}
          onClose={closeProblemAlert}
        />
      )}
    </>
  )
}

export default memo(TicketBreakdown)
