/**
 * @generated SignedSource<<de1d72248cc4c4a0510212bb7d4f3b2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventDetails_viewer$data = {
  readonly dicePartner: boolean;
  readonly diceStaff: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"Basics_viewer" | "Extras_viewer" | "Merch_viewer" | "Settings_viewer" | "Tickets_viewer">;
  readonly " $fragmentType": "EventDetails_viewer";
};
export type EventDetails_viewer$key = {
  readonly " $data"?: EventDetails_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventDetails_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventDetails_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStaff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicePartner",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Basics_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Tickets_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Extras_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Merch_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Settings_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "3ecdc19a493b03f82acfb2e958ca518d";

export default node;
