/**
 * @generated SignedSource<<1bfe8f4f22bcb385b79ddc83a2315bd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type DashboardWeeklyQuery$variables = {
  endDate?: string | null;
  initialCurrency?: EventCostCurrency | null;
  startDate?: string | null;
};
export type DashboardWeeklyQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DashboardSales_viewer">;
  } | null;
};
export type DashboardWeeklyQuery = {
  response: DashboardWeeklyQuery$data;
  variables: DashboardWeeklyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "initialCurrency"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v3 = [
  {
    "kind": "Variable",
    "name": "currency",
    "variableName": "initialCurrency"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardWeeklyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "DashboardSales_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DashboardWeeklyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RevenueReportItem",
            "kind": "LinkedField",
            "name": "revenueReport",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "soldTickets",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "totalEvents",
            "args": (v3/*: any*/),
            "concreteType": "AggregatedSalesReport",
            "kind": "LinkedField",
            "name": "aggregatedSales",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onSaleEventsCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a7d69ae73600f466fe21f8c10ad9902",
    "id": null,
    "metadata": {},
    "name": "DashboardWeeklyQuery",
    "operationKind": "query",
    "text": "query DashboardWeeklyQuery(\n  $startDate: Time\n  $endDate: Time\n  $initialCurrency: EventCostCurrency\n) {\n  viewer {\n    ...DashboardSales_viewer_3tocBY\n    id\n  }\n}\n\nfragment DashboardSales_viewer_3tocBY on Viewer {\n  revenueReport(startDate: $startDate, endDate: $endDate, currency: $initialCurrency) {\n    revenue\n    soldTickets\n  }\n  totalEvents: aggregatedSales(startDate: $startDate, endDate: $endDate, currency: $initialCurrency) {\n    onSaleEventsCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb7d789e6520bb029c96b74525f5f740";

export default node;
