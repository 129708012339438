import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { color } from '../../utils/variables'
import { textStyle } from '../../utils/typography'

interface IProps {
  label: string
  value: string
}

const TimezoneOptionLabel: FC<IProps> = ({ label, value }) => {
  return (
    <TimezoneOption>
      <span>{label}</span>
      <span>{value.replace('_', ' ')}</span>
    </TimezoneOption>
  )
}

export default TimezoneOptionLabel

const TimezoneOption = styled.div`
  display: flex;
  flex-direction: column;

  & > span:last-child {
    color: ${color.darkgrey};
    ${textStyle.functional.sm};
    margin-top: 4px;
  }
`
