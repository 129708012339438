import { DICE_FM_URL } from '../env'

export const dicefmEvent = (eventIdLive: string | null | undefined) =>
  eventIdLive ? `${DICE_FM_URL}/event/${eventIdLive}` : ''

export const dicefmPreview = (previewToken: string | null | undefined) =>
  previewToken ? `${DICE_FM_URL}/preview/event/${previewToken}` : ''

export const dicefmStream = (streamUrl: string | null | undefined) =>
  streamUrl ? `${DICE_FM_URL}/stream/player?playback_url=${encodeURIComponent(streamUrl)}` : ''

export const dicefmArtist = (permName: string | null | undefined) =>
  permName ? `${DICE_FM_URL}/artist/${encodeURIComponent(permName)}` : ''

export const dicefmBundle = (permName: string | null | undefined) =>
  permName ? `${DICE_FM_URL}/bundles/${encodeURIComponent(permName)}` : ''

export const dicefmPromoter = (permName: string | null | undefined) =>
  permName ? `${DICE_FM_URL}/promoters/${encodeURIComponent(permName)}` : ''

export const dicefmVenue = (permName: string | null | undefined) =>
  permName ? `${DICE_FM_URL}/venue/${encodeURIComponent(permName)}` : ''
