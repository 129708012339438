import React, { FC, HTMLProps, memo, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { color } from '../utils/variables'

const RadioBox = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid currentColor;
  border-radius: 50%;
`

const RadioControl = styled.label<{
  checked?: boolean
  hasError?: boolean
  color?: keyof typeof color
  disabled?: boolean
}>`
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: ${(props) => (props.color ? color[props.color] : color.grey)};

  ${({ checked, color: propsColor, hasError }) =>
    checked &&
    `
    ${RadioBox} {
      border-color: ${propsColor === 'grey' ? color[hasError ? 'error' : 'primary'] : 'currentColor'};
      border-width: 8px;
    }
  `}

  ${({ hasError }) =>
    hasError &&
    `
    color: ${color.error};
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  `}
`

const RadioInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
`

const RadioContainer = styled.div`
  display: block;
  position: relative;
`

const RadioInner = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const RadioLabel = styled.span`
  display: block;
  min-height: 24px;
  line-height: 24px;
  color: ${color.text};
  flex: 1 1 auto;
  margin-left: 8px;
  cursor: pointer;
  user-select: none;
`

export type IRadioProps = Omit<
  Omit<
    Omit<
      HTMLProps<HTMLInputElement> & {
        color?: keyof typeof color
        hasError?: boolean
      },
      'as'
    >,
    'ref'
  >,
  'label'
> & { label?: ReactNode }

const Radio: FC<React.PropsWithChildren<IRadioProps>> = ({
  className,
  checked,
  hasError,
  label,
  color = 'grey',
  disabled,
  onChange,
  ...inputProps
}) => (
  <RadioControl
    data-testid="radioLabel"
    className={className}
    hasError={hasError}
    checked={checked}
    color={color}
    disabled={disabled}
  >
    <RadioInput type="radio" checked={checked} disabled={disabled} onChange={onChange} {...inputProps} />
    <RadioInner>
      <RadioContainer>
        <RadioBox className="radio-box" />
      </RadioContainer>
      {label && <RadioLabel>{label}</RadioLabel>}
    </RadioInner>
  </RadioControl>
)

export default styled(memo(Radio))``
