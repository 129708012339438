import React from 'react'
import styled from 'styled-components/macro'
import { SkeletonLine } from '../../../components/SkeletonLine'

import { color, mediaQuery } from '../../../utils/variables'

const SkeletonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 32px 68px;
  border-bottom: 2px solid ${color.text};
  ${mediaQuery.lessThan('tablet')`
    padding: 0 16px 16px 16px;
  `}

  animation: opacityAnimation 1.5s infinite;
  @keyframes opacityAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

const Col = styled.div<{ grow?: boolean }>`
  display: flex;
  flex: ${({ grow }) => (grow ? '2 0 0' : '1 0 0')};
  align-items: flex-end;
  ${mediaQuery.lessThan('desktop')`
    flex: auto;
  `}
  ${mediaQuery.lessThan('tablet')`
    display: block
  `}
  & + & {
    margin-top: 24px;
  }
`

const SkeletonImage = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 16px;
  position: relative;
  flex: 160px 0 0;
  width: 160px;
  height: 160px;
  margin-bottom: auto;
  background: ${color.disabled};
  ${mediaQuery.lessThan('tablet')`
    margin-top: 16px;
  `}
`

const SkeletonInfoStatus = styled(SkeletonLine)`
  margin: 16px 0 8px;
  height: 12px;
  max-width: 120px;
  ${mediaQuery.lessThan('tablet')`
    margin-top: 8px;
  `}
`

const SkeletonInfoTitle = styled(SkeletonLine)`
  margin: 0 0 auto;
  height: 24px;
  ${mediaQuery.lessThan('desktop')`
    margin-right: 200px;
    width: auto;
  `}
  ${mediaQuery.lessThan('tablet')`
    margin-bottom: 16px;
  `}
`
const SkeletonInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-left: 22px;
  ${mediaQuery.lessThan('tablet')`
    margin: 16px 0;
  `}
`

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  ${SkeletonLine} {
    margin-top: 0;
  }
`

export default () => (
  <SkeletonWrapper>
    <Col grow>
      <SkeletonImage />
      <SkeletonInfo>
        <SkeletonInfoStatus />
        <SkeletonInfoTitle width="88%" />
        <div>
          <SkeletonLine width="70%" />
          <SkeletonLine width="60%" />
        </div>
      </SkeletonInfo>
    </Col>
    <Col>
      <FlexWrapper>
        <Flex>
          <SkeletonLine width="130px" height="40px" />
          <SkeletonLine width="130px" height="40px" />
        </Flex>
        <SkeletonLine width="100%" height="24px" />
      </FlexWrapper>
    </Col>
  </SkeletonWrapper>
)
