import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { trackingContext } from '../../../../context/tracking'
import { CURRENCY } from '../../../../utils/formatters/number'
import unwrapId from '../../../../utils/unwrapId'
import { color, font, mediaQuery } from '../../../../utils/variables'

import AlertBox from '../../../../components/AlertBox'
import { Modal, ModalBody, ModalDialog, ModalFooter, ModalFooterControl } from '../../../../components/Modal'
import { Loader } from '../../../../components/Loader'
import Svg from '../../../../components/Svg'

import { PayoutModal_event$key } from '../../../../__generated__/PayoutModal_event.graphql'
import { authContext } from '../../../../context/auth'
import usePerformPayout from '../../../../utils/hooks/usePerformPayout'
import PayoutBlockers from '../../../../components/PayoutBlockers'
import useEventPayout from '../../../../utils/hooks/useEventPayout'
import { notificationContext } from '../../../../context/notification'
import { textStyle } from '../../../../utils/typography'
import { PayoutModalLazyQuery } from '../../../../__generated__/PayoutModalLazyQuery.graphql'

const ModalPayout = styled(Modal)`
  ${ModalDialog} {
    max-width: 375px;
  }

  ${mediaQuery.lessThan('tablet')`
    ${ModalDialog} {
      max-width: unset;
    }
  `}
`

const PayoutAmount = styled.span`
  display: block;
  margin: 16px 0 -16px 0;

  ${textStyle.heading.xl}
`

const ProcessingOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -76px;
  right: -32px;
  bottom: 0;
  left: -32px;
  background: #fff;

  ${textStyle.functional.xs}

  text-transform: uppercase;
  z-index: 10;
  ${Loader} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
    height: 220px;
    color: ${color.primary};
    circle {
      stroke-width: 0.5;
    }
  }
  ${mediaQuery.lessThan('tablet')`
    left: -20px;
    right: -20px;
    top: -84px;
    bottom: -32px;
  `}
`

const SuccessWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 -16px;
  ${mediaQuery.lessThan('tablet')`
    margin: 0;
  `}
  h3 {
    margin: 16px 0 8px;
    font-weight: ${font.weight.light};
  }
`

const RoundWrapper = styled.div`
  position: relative;
  color: ${color.secondary};
  padding: 16px;
  margin-top: -54px;
  ${mediaQuery.lessThan('tablet')`
    margin: 0;
  `}
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 2px solid currentColor;
    opacity: 0.8;
  }
  svg {
    display: block;
    width: 80px;
    height: 80px;
  }
`

const SModalFooter = styled(ModalFooter)`
  display: block;

  ${ModalFooterControl} + ${ModalFooterControl} {
    margin-top: 16px;
  }
`

const ProcessingState = () => {
  const intl = useIntl()
  return (
    <ProcessingOverlay>
      <Loader size="small" />
      {intl.formatMessage({ id: 'payout.modal.processing' })}
    </ProcessingOverlay>
  )
}

const SuccessState = () => {
  const intl = useIntl()
  return (
    <SuccessWrapper>
      <RoundWrapper>
        <Svg icon="check" />
      </RoundWrapper>
      <h3>{intl.formatMessage({ id: 'payout.modal.success' })}</h3>
      <p>{intl.formatMessage({ id: 'payout.modal.success.description' })}</p>
    </SuccessWrapper>
  )
}

const AntiMargin = styled.div`
  margin-bottom: -50px;
`

interface IProps {
  event: PayoutModal_event$key
  onClose: () => void
}

const PayoutModalData: FC<IProps> = ({ event: eventKey, onClose }) => {
  const intl = useIntl()
  const { trackEvent } = useContext(trackingContext)
  const { user, hasPermission } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)

  const event = useFragment(
    graphql`
      fragment PayoutModal_event on Event {
        id
        name
        endDate
        timezoneName
        eventIdLive
        costCurrency
        state
        ...useEventPayout_event
        ...usePerformPayout_event
        ...PayoutBlockers_event

        billingPromoter {
          name
          remittanceRecipients
        }
      }
    `,
    eventKey
  )

  const trackData = useMemo(
    () => ({
      event_id: unwrapId(event.id),
      event_id_live: event.eventIdLive,
    }),
    [event.eventIdLive, event.id]
  )

  const {
    selfPayoutDisabled,
    adminPayoutDisabled,
    payoutAmount,
    isPaidOut,
    isOverPaid,
    selfPayoutBlockers,
    seriousPayoutBlockers,
  } = useEventPayout(event)

  const { doPerformPayout, payoutInProgress } = usePerformPayout(event)

  const doAdminPayout = useCallback(async () => {
    try {
      doPerformPayout(payoutAmount)
      addNotification(
        'success',
        intl.formatMessage(
          { id: 'event_payouts.perform_payout.success' },
          {
            amount: intl.formatNumber(payoutAmount / 100, CURRENCY(payoutAmount, event.costCurrency)),
            promoter: event.billingPromoter?.name,
          }
        )
      )
    } catch (_) {
      addNotification('error', intl.formatMessage({ id: 'event_payouts.perform_payout.error' }))
    }
  }, [addNotification, doPerformPayout, event.billingPromoter?.name, event.costCurrency, intl, payoutAmount])

  const doSelfPayout = useCallback(() => {
    try {
      doPerformPayout(payoutAmount)
    } catch (_) {
      addNotification('error', intl.formatMessage({ id: 'event_payouts.perform_payout.error' }))
      onClose()
    }
  }, [addNotification, doPerformPayout, intl, onClose, payoutAmount])

  const confirmPayout = useCallback(() => {
    trackEvent('event_remittance_pay_clicked', trackData)
    doSelfPayout()
  }, [trackEvent, trackData, doSelfPayout])

  if (user.diceStaff && hasPermission('perform_payout:balances')) {
    return (
      <ModalPayout
        modalTitle={
          isPaidOut
            ? intl.formatMessage({ id: 'event_status.paid-out' })
            : intl.formatMessage({ id: 'payout.modal.title' })
        }
        closeButton
        onClose={onClose}
      >
        <ModalBody className="pb-lg">
          <>
            {!isPaidOut && (
              <PayoutBlockers event={event} seriousBlockers={seriousPayoutBlockers} blockers={selfPayoutBlockers} />
            )}

            <p>{intl.formatMessage({ id: 'payout.modal.description' }, { name: event.name })}</p>
            <PayoutAmount>
              {intl.formatNumber(payoutAmount / 100, CURRENCY(payoutAmount, event.costCurrency))}
            </PayoutAmount>
            <AntiMargin />
          </>
        </ModalBody>
        <SModalFooter>
          <ModalFooterControl
            block
            icon={isPaidOut && !isOverPaid ? 'check' : undefined}
            disabled={adminPayoutDisabled}
            loading={payoutInProgress}
            color={isOverPaid ? 'error' : isPaidOut ? 'success' : 'black'}
            preset={isOverPaid || isPaidOut ? 'outline' : undefined}
            onClick={doAdminPayout}
          >
            {
              // prettier-ignore
              isPaidOut && !isOverPaid
                ? intl.formatMessage({ id: 'event_status.paid-out' })
                : intl.formatMessage(
                  { id: isOverPaid ? 'event_payouts.payouts.overpaid' : 'payout.modal.confirm_action' },
                  {
                    amount: intl.formatNumber(
                      payoutAmount / 100,
                      CURRENCY(payoutAmount, event.costCurrency)
                    ),
                  }
                )
            }
          </ModalFooterControl>
          <ModalFooterControl block preset="secondary" to={`/events/${event.id}/payouts`}>
            {intl.formatMessage({ id: 'event_payouts.payouts.payouts_log' })}
          </ModalFooterControl>
        </SModalFooter>
      </ModalPayout>
    )
  }

  if (!hasPermission('perform_payout:balances')) {
    return (
      <ModalPayout
        trackId="event_remittance"
        modalTitle={intl.formatMessage({ id: 'payout.modal.finalising' })}
        trackData={trackData}
        closeButton
        onClose={onClose}
      >
        <ModalBody className="pb-lg">
          <AlertBox className="mb-md">{intl.formatMessage({ id: 'payout.alert.finalising' })}</AlertBox>
          <p>{intl.formatMessage({ id: 'payout.description.payout_will_be' }, { name: event.name })}</p>
          <PayoutAmount>
            {intl.formatNumber(payoutAmount / 100, CURRENCY(payoutAmount, event.costCurrency))}
          </PayoutAmount>
        </ModalBody>
      </ModalPayout>
    )
  }

  if (isPaidOut) {
    return (
      <ModalPayout trackId="event_remittance_pay_confirmed" trackData={trackData} closeButton onClose={onClose}>
        <ModalBody>
          <SuccessState />
        </ModalBody>
        <ModalFooter>
          <ModalFooterControl block onClick={onClose}>
            {intl.formatMessage({ id: 'payout.modal.success.button' })}
          </ModalFooterControl>
        </ModalFooter>
      </ModalPayout>
    )
  }

  if (selfPayoutDisabled) {
    return (
      <ModalPayout
        trackId="event_remittance"
        modalTitle={
          selfPayoutBlockers?.event_completed_recently || seriousPayoutBlockers
            ? intl.formatMessage({ id: 'payout.modal.almost_ready' })
            : intl.formatMessage({ id: 'payout.modal.on_the_way' })
        }
        trackData={trackData}
        closeButton
        onClose={onClose}
      >
        <ModalBody className="pb-lg">
          {!isPaidOut && (
            <PayoutBlockers event={event} seriousBlockers={seriousPayoutBlockers} blockers={selfPayoutBlockers} />
          )}

          <p>{intl.formatMessage({ id: 'payout.description.payout_will_be' }, { name: event.name })}</p>
          <PayoutAmount>
            {intl.formatNumber(payoutAmount / 100, CURRENCY(payoutAmount, event.costCurrency))}
          </PayoutAmount>
        </ModalBody>
      </ModalPayout>
    )
  }

  return (
    <ModalPayout
      trackId="event_remittance"
      trackData={trackData}
      modalTitle={intl.formatMessage({ id: 'payout.modal.get_paid' })}
      closeButton
      onClose={onClose}
    >
      {payoutInProgress && <ProcessingState />}
      <ModalBody>
        <p>{intl.formatMessage({ id: 'payout.description.payout_is' }, { name: event.name })}</p>
        <PayoutAmount>{intl.formatNumber(payoutAmount / 100, CURRENCY(payoutAmount, event.costCurrency))}</PayoutAmount>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl block loading={payoutInProgress} onClick={confirmPayout} disabled={selfPayoutDisabled}>
          {intl.formatMessage({ id: 'actions.confirm' })}
        </ModalFooterControl>
      </ModalFooter>
    </ModalPayout>
  )
}

const PayoutModal: FC<Omit<IProps, 'event'> & { eventId: string }> = ({ eventId, ...props }) => {
  const { event } = useLazyLoadQuery<PayoutModalLazyQuery>(
    graphql`
      query PayoutModalLazyQuery($id: ID!) {
        event: node(id: $id) {
          ...PayoutModal_event
        }
      }
    `,
    { id: eventId },
    { fetchPolicy: 'store-and-network' }
  )
  return event && <PayoutModalData event={event} {...props} />
}

export default memo(PayoutModal)
