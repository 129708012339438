import { useFormik, type FormikConfig } from 'formik'
import { find } from 'lodash/fp'
import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Form, FormRow } from '../../../components/Form'
import FormField, { IStyledFormField } from '../../../components/FormField'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'
import Svg from '../../../components/Svg'
import TextInput from '../../../components/TextInput'
import { localeContext } from '../../../context/locale'
import { mapsContext } from '../../../context/maps'
import { EventType } from '../../../enums.generated'
import CANADA_STATES from '../../../utils/canadaStates'
import getCountries, { Country as CountryOption } from '../../../utils/countries'
import USA_STATES from '../../../utils/usaStates'
import { IVenueAddress } from './EventVenues'

const states: Record<string, string[]> = {
  US: USA_STATES,
  CA: CANADA_STATES,
}

const stateOptionalForCountries = ['GB', 'FR', 'DE']

const validationSchema = Yup.object({
  countryCode: Yup.string(),
  addressRegion: Yup.string(),
  name: Yup.string().required(),
  postalCode: Yup.string().required(),
  streetAddress: Yup.string().required(),
  addressCountry: Yup.string().required(),
  addressLocality: Yup.string().required(),
  addressCapacity: Yup.number()
    .min(0)
    .when('countryCode', {
      is: 'IT',
      then: Yup.number().min(0).required(),
    }),
  addressState: Yup.string().when('countryCode', {
    is: (value) => stateOptionalForCountries.includes(value),
    then: Yup.string(),
    otherwise: Yup.string().required(),
  }),
  addressSiaeCode: Yup.string().when('countryCode', {
    is: 'IT',
    then: Yup.string()
      .length(13, 'new_event.basics.venue.address.siae_code.validation')
      .matches(/^\d+$/, 'new_event.basics.venue.address.siae_code.validation')
      .required(),
  }),
})

const LocationIcon = styled(Svg)`
  margin-left: 8px;
  margin-right: -10px;
  pointer-events: none;
`

const StyledFormField = styled(FormField)`
  .react-autosuggest__suggestions-container {
    z-index: 6;
    left: -2px;
    overflow-y: auto;
    max-height: 240px;
  }
` as IStyledFormField

const LocationInput = (props: any) => <TextInput {...props} prefix={<LocationIcon icon="map-marker" />} type="text" />

interface CityOption {
  lat: number
  lon: number
  label: string
  value: string
  placeId: string
}

interface IProps {
  disabled: boolean
  onClose: () => void
  eventType: EventType | null
  customVenue: IVenueAddress
  onSubmit: FormikConfig<IVenueAddress>['onSubmit']
}

const EventCustomVenue: FC<IProps> = ({ onClose, onSubmit, disabled, eventType, customVenue }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { suggestCities } = useContext(mapsContext)
  const countryOptions = useMemo(() => getCountries(intl, locale), [intl, locale])

  const { values, touched, errors, handleChange, handleBlur, submitForm, setFieldValue, setValues } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      name: customVenue?.name || '',
      postalCode: customVenue?.postalCode || '',
      countryCode: customVenue?.countryCode || '',
      addressState: customVenue?.addressState || '',
      streetAddress: customVenue?.streetAddress || '',
      addressRegion: customVenue?.addressRegion || '',
      addressCountry: customVenue?.addressCountry || '',
      addressCapacity: customVenue?.addressCapacity || 0,
      addressLocality: customVenue?.addressLocality || '',
      addressSiaeCode: customVenue?.addressSiaeCode || '',
    },
  })

  const isPureStream = eventType === 'STREAM'

  const localityOption = useMemo(() => {
    const value = values.addressLocality
    return value ? { value, label: value } : null
  }, [values.addressLocality])

  const countryOption = useMemo(
    () => find(['value', values.addressCountry], countryOptions),
    [countryOptions, values.addressCountry]
  )

  const stateSuggestions = useMemo(() => states[countryOption?.alpha2 || ''], [countryOption?.alpha2])
  const loadCities = useMemo(() => suggestCities(countryOption?.alpha2), [countryOption?.alpha2, suggestCities])
  const defaultLocalityOptions = useMemo(() => (localityOption ? [localityOption] : undefined), [localityOption])

  const handleCountryChange = useCallback(
    (_: any, value: CountryOption) => {
      setValues({
        ...values,
        countryCode: value.alpha2,
        addressCountry: value.value,
        ...(value.alpha2 !== 'IT' ? { addressCapacity: 0, addressSiaeCode: '' } : {}),
      })
    },
    [setValues, values]
  )

  const handleLocalityChange = useCallback(
    (_: any, value: CityOption) => {
      setValues({
        ...values,
        addressLocality: value.value,
        addressLocalityLat: value.lat,
        addressLocalityLon: value.lon,
        addressLocalityId: value.placeId,
      })
    },
    [setValues, values]
  )

  const handleStateChange = useCallback(
    (_: any, value: string) => {
      setFieldValue('addressState', value)
    },
    [setFieldValue]
  )

  return (
    <Modal
      closeButton
      onClose={onClose}
      modalTitle={intl.formatMessage({ id: 'new_event.basics.venue.custom_venue.title' })}
    >
      <ModalBody>
        <Form>
          <FormRow columnOnMobile data-name="primaryVenue" data-disabled={disabled}>
            <FormField
              required
              name="name"
              disabled={disabled}
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched?.name && errors?.name}
              control={isPureStream ? LocationInput : undefined}
              label={intl.formatMessage({
                id: isPureStream
                  ? 'new_event.basics.streaming_from.label'
                  : 'new_event.basics.venue.address.name.label',
              })}
              placeholder={
                isPureStream ? intl.formatMessage({ id: 'new_event.basics.streaming_from.location_hint' }) : undefined
              }
            />
            <FormField
              required
              searchable
              control="select"
              disabled={disabled}
              name="addressCountry"
              value={countryOption}
              options={countryOptions}
              onChange={handleCountryChange}
              error={touched?.addressCountry && errors?.addressCountry}
              label={intl.formatMessage({ id: 'new_event.basics.venue.address.country.label' })}
            />
          </FormRow>
          {!isPureStream && (
            <>
              <FormRow columnOnMobile>
                <FormField
                  required
                  onBlur={handleBlur}
                  disabled={disabled}
                  name="streetAddress"
                  onChange={handleChange}
                  value={values.streetAddress || ''}
                  error={touched?.streetAddress && errors?.streetAddress}
                  label={intl.formatMessage({ id: 'new_event.basics.venue.address.line1.label' })}
                  placeholder={intl.formatMessage({ id: 'new_event.basics.venue.address.line1.placeholder' })}
                />
              </FormRow>
              <FormRow columnOnMobile>
                {stateSuggestions ? (
                  <StyledFormField
                    name="addressState"
                    onBlur={handleBlur}
                    autoComplete="none"
                    disabled={disabled}
                    value={values.addressState}
                    suggestions={stateSuggestions}
                    setFieldValue={handleStateChange}
                    prefix={<LocationIcon icon="search" />}
                    error={touched?.addressState && errors?.addressState}
                    required={!stateOptionalForCountries.includes(values.countryCode || '')}
                    label={intl.formatMessage({ id: 'new_event.basics.venue.address.state.label' })}
                  />
                ) : (
                  <FormField
                    disabled={disabled}
                    name="addressState"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.addressState}
                    error={touched?.addressState && errors?.addressState}
                    required={!stateOptionalForCountries.includes(values.countryCode || '')}
                    label={intl.formatMessage({ id: 'new_event.basics.venue.address.state.label' })}
                  />
                )}
                <FormField
                  name="addressRegion"
                  onBlur={handleBlur}
                  disabled={disabled}
                  onChange={handleChange}
                  value={values.addressRegion}
                  error={touched?.addressRegion && errors?.addressRegion}
                  label={intl.formatMessage({ id: 'new_event.basics.venue.address.region.label' })}
                />
              </FormRow>
              <FormRow columnOnMobile>
                <FormField
                  async
                  required
                  searchable
                  control="select"
                  disabled={disabled}
                  name="addressLocality"
                  value={localityOption}
                  loadOptions={loadCities}
                  onChange={handleLocalityChange}
                  defaultOptions={defaultLocalityOptions}
                  error={touched?.addressLocality && errors?.addressLocality}
                  label={intl.formatMessage({ id: 'new_event.basics.venue.address.city.label' })}
                  placeholder={intl.formatMessage({ id: 'new_event.basics.venue.address.city.placeholder' })}
                />
                <FormField
                  required
                  name="postalCode"
                  onBlur={handleBlur}
                  disabled={disabled}
                  onChange={handleChange}
                  value={values.postalCode}
                  error={touched?.postalCode && errors?.postalCode}
                  label={intl.formatMessage({ id: 'new_event.basics.venue.address.zip.label' })}
                  placeholder={intl.formatMessage({ id: 'new_event.basics.venue.address.zip.placeholder' })}
                />
              </FormRow>
              <FormRow columnOnMobile>
                <FormField
                  min={0}
                  type="number"
                  name="addressCapacity"
                  onBlur={handleBlur}
                  disabled={disabled}
                  onChange={handleChange}
                  value={values.addressCapacity}
                  required={values.countryCode === 'IT'}
                  error={touched?.addressCapacity && errors?.addressCapacity}
                  label={intl.formatMessage({ id: 'new_event.basics.venue.address.capacity.label' })}
                />
                {values.countryCode === 'IT' && (
                  <FormField
                    required
                    onBlur={handleBlur}
                    disabled={disabled}
                    name="addressSiaeCode"
                    onChange={handleChange}
                    placeholder="0000000000000"
                    value={values.addressSiaeCode}
                    error={touched?.addressSiaeCode && errors?.addressSiaeCode}
                    label={intl.formatMessage({ id: 'new_event.basics.venue.address.siae_code.label' })}
                  />
                )}
              </FormRow>
            </>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl data-id="confirm" onClick={submitForm}>
          {intl.formatMessage({ id: 'new_event.basics.venue.custom_venue.actions.confirm' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" data-id="reject" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default EventCustomVenue
