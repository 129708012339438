/**
 * @generated SignedSource<<f60b774d67eeda6752acf8c8bc4d4943>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventPartRemittance_event$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"RemittanceModal_event">;
  readonly " $fragmentType": "EventPartRemittance_event";
};
export type EventPartRemittance_event$key = {
  readonly " $data"?: EventPartRemittance_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartRemittance_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventPartRemittance_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemittanceModal_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "cc7131589f5ec4009310a0c7ce91a986";

export default node;
