import React, { FC, useCallback, useState, useMemo, useEffect, memo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components/macro'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import { getOr, find, get, isNil } from 'lodash/fp'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { useIntl } from 'react-intl'
import FormField from '../../../components/FormField'
// eslint-disable-next-line max-len
import { EventAttractiveGenreSelectQuery } from '../../../__generated__/EventAttractiveGenreSelectQuery.graphql'
import IEventFormBasics from '../types/Basics'
import { FormRow } from '../../../components/Form'
import { color } from '../../../utils/variables'

const Warn = styled.span`
  color: ${color.warning};
`

const EventAttractiveGenreSelect: FC<
  React.PropsWithChildren<{
    disabled?: boolean
    initialValues: IEventFormBasics
    values: IEventFormBasics
    setFieldValue: (name: string, val: any) => void
    handleBlur: (e: Event) => void
    touched: FormikTouched<FormikValues>
    errors: FormikErrors<FormikValues>
  }>
> = ({ initialValues, values, setFieldValue, handleBlur, touched, errors, disabled }) => {
  const intl = useIntl()
  const environment = useRelayEnvironment()

  const warning = useMemo(() => {
    const isAlreadyIntegrated = !!values.attractiveStatus
    const isChanged = values.attractiveFields?.siaeGenreType !== initialValues.attractiveFields?.siaeGenreType

    return isAlreadyIntegrated && isChanged ? (
      <Warn>{intl.formatMessage({ id: 'warning.siae_genre_changed' })}</Warn>
    ) : null
  }, [
    initialValues.attractiveFields?.siaeGenreType,
    intl,
    values.attractiveFields?.siaeGenreType,
    values.attractiveStatus,
  ])

  const onChangePercent = useCallback(
    (e: any) =>
      setFieldValue(
        'attractiveFields.entertainmentPercent',
        isNil(e.target.value) || e.target.value === '' ? null : Number(e.target.value)
      ),
    [setFieldValue]
  )

  const [attractiveGenreOptions, setAttractiveGenreOptions] = useState<null | any[]>(null)

  const attractiveGenreOption: { value: string; label: string } | undefined = useMemo(
    () => find(['value', values.attractiveFields?.siaeGenreType], attractiveGenreOptions || []),
    [attractiveGenreOptions, values.attractiveFields?.siaeGenreType]
  )

  const onChangeGenre = useCallback(
    (id: any, genre: any) => {
      if (values.attractiveFields?.siaeGenreType === id) return

      setFieldValue('attractiveFields.siaeGenreType', id)

      if (!['01', '45', '49', '54', '59'].includes(id)) {
        setFieldValue('attractiveFields.author', null)
        setFieldValue('attractiveFields.distributor', null)
        setFieldValue('attractiveFields.nationality', null)
        setFieldValue('attractiveFields.producer', null)
        setFieldValue('attractiveFields.performer', null)
      }

      const isEntertainment = getOr(false, 'isEntertainment', attractiveGenreOption)
      if (!isNil(isEntertainment) && isEntertainment === genre.isEntertainment) {
        // Someone just set value for same kind of genre - lets not reset percentage
        return
      }

      if (!genre.isEntertainment) {
        setFieldValue('attractiveFields.entertainmentPercent', null)
      } else {
        setFieldValue('attractiveFields.entertainmentPercent', 100)
      }
    },
    [attractiveGenreOption, setFieldValue, values.attractiveFields?.siaeGenreType]
  )

  useEffect(() => {
    if (attractiveGenreOptions) return

    let stillActive = true

    const query = graphql`
      query EventAttractiveGenreSelectQuery {
        viewer {
          externalEntities(entityType: "event_genre", integrationType: "attractive") {
            label: name
            value
            isEntertainment
          }
        }
      }
    `

    fetchQuery_DEPRECATED<EventAttractiveGenreSelectQuery>(environment, query, {}).then(
      (data: EventAttractiveGenreSelectQuery['response']) => {
        if (stillActive) {
          const arr = getOr([], 'viewer.externalEntities', data)
          setAttractiveGenreOptions(arr)
          if (arr.length > 0 && !values.attractiveFields?.siaeGenreType && !disabled) {
            onChangeGenre(arr[0].value, arr[0])
          }
        }
      }
    )

    return () => {
      stillActive = false
    }
  }, [attractiveGenreOptions, disabled, environment, onChangeGenre, values.attractiveFields?.siaeGenreType])

  // Start of hack for validation
  const isEntertainmentCurrent = getOr(false, 'isEntertainment', values.attractiveFields)
  useEffect(() => {
    if (!attractiveGenreOptions) return

    const isEntertainment = getOr(false, 'isEntertainment', attractiveGenreOption)

    if (isEntertainmentCurrent !== isEntertainment) {
      setFieldValue('attractiveFields.isEntertainment', !!isEntertainment)
    }
  }, [attractiveGenreOption, attractiveGenreOptions, disabled, isEntertainmentCurrent, setFieldValue])
  // End of hack for validation

  const defaultOptions = useMemo(
    () => (attractiveGenreOption ? [attractiveGenreOption] : undefined),
    [attractiveGenreOption]
  )

  return (
    <>
      <FormRow columnOnMobile>
        <FormField
          required
          name="attractiveFields.siaeGenreType"
          label={intl.formatMessage({ id: 'new_event.tickets.siae_genre_type.label' })}
          control="select"
          value={attractiveGenreOption}
          options={attractiveGenreOptions || []}
          defaultOptions={defaultOptions}
          onChange={onChangeGenre}
          onBlur={handleBlur}
          hint={warning}
          error={touched.attractiveFields && get('attractiveFields.siaeGenreType', errors)}
          disabled={disabled}
        />
      </FormRow>
      {getOr(false, 'isEntertainment', attractiveGenreOption) && (
        <FormRow columnOnMobile>
          <FormField
            name="attractiveFields.entertainmentPercent"
            label={intl.formatMessage({ id: 'new_event.tickets.entertainment_percent.label' })}
            value={
              isNil(values.attractiveFields?.entertainmentPercent) ? '' : values.attractiveFields?.entertainmentPercent
            }
            onChange={onChangePercent}
            onBlur={handleBlur}
            disabled={disabled}
            error={touched.attractiveFields && get('attractiveFields.entertainmentPercent', errors)}
            required
            type="number"
            min={0}
            step={1}
            max={100}
          />
        </FormRow>
      )}
    </>
  )
}

export default memo(EventAttractiveGenreSelect)
