import { useFormik, type FormikConfig, useFormikContext } from 'formik'
import React, { FC, memo, useMemo } from 'react'
import { compact, find } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useHierarchicalTags_viewer$key } from '../../../__generated__/useHierarchicalTags_viewer.graphql'
import { Form, FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'
import useHierarchicalTags from '../../../utils/hooks/useHierarchicalTags'
import IEventFormBasics from '../types/Basics'
import { AdditionalArtistSchema } from '../validation/Basics'

type IAdditionalArtist = NonNullable<NonNullable<IEventFormBasics['additionalArtists']>[number]>

export interface FormValues {
  name: string
  description: string
  hierarchicalTags?: Array<{
    label: string | null
    value: string | null
    kind: string | null
  } | null> | null
}

interface IEventCustomArtistProps {
  onClose: () => void
  artist: Partial<IAdditionalArtist>
  viewer: useHierarchicalTags_viewer$key
  onSubmit: FormikConfig<FormValues>['onSubmit']
}

const EventCustomArtist: FC<React.PropsWithChildren<IEventCustomArtistProps>> = ({
  artist,
  viewer,
  onClose,
  onSubmit,
}) => {
  const intl = useIntl()
  const parentFormik = useFormikContext<IEventFormBasics>()

  const initialHierarchicalTags = useMemo(() => {
    const tags = artist?.hierarchicalTags || []
    const type = find(['kind', 'type'], tags)
    const parentType = find(['kind', 'type'], parentFormik.values.hierarchicalTags || [])

    return type?.value === parentType?.value ? tags : compact([parentType])
  }, [artist?.hierarchicalTags, parentFormik.values.hierarchicalTags])

  const formik = useFormik<FormValues>({
    onSubmit,
    initialValues: {
      name: artist.name || '',
      description: artist?.description || '',
      hierarchicalTags: initialHierarchicalTags,
    },
    validationSchema: AdditionalArtistSchema,
  })
  const { values, touched, errors, handleChange, handleBlur, submitForm } = formik

  const { genres, setGenres, genresLoader } = useHierarchicalTags(viewer, formik)

  return (
    <Modal
      closeButton
      onClose={onClose}
      modalTitle={intl.formatMessage({ id: 'new_event.basics.artists.additional_artist.title' })}
    >
      <ModalBody>
        <Form>
          <FormRow>
            <FormField
              required
              name="name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.name && errors.name}
              label={intl.formatMessage({ id: 'new_event.basics.artists.additional_artist_name.label' })}
            />
          </FormRow>
          <FormRow>
            <FormField
              required
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              error={touched.description && errors.description}
              label={intl.formatMessage({ id: 'new_event.basics.artists.additional_artist_description.label' })}
            />
          </FormRow>
          <FormRow>
            <FormField
              async
              multiple
              searchable
              name="hierarchicalTags"
              defaultOptions
              control="select"
              onBlur={handleBlur}
              label="Artist genre"
              onChange={setGenres}
              value={genres || []}
              loadOptions={genresLoader}
              error={touched.hierarchicalTags && errors.hierarchicalTags}
              hint={intl.formatMessage({ id: 'new_event.basics.artists.additional_artist.genre.hint' })}
              placeholder={intl.formatMessage({ id: 'new_event.basics.genre.placeholder' })}
            />
          </FormRow>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl data-id="confirm" onClick={submitForm}>
          {intl.formatMessage({ id: 'new_event.basics.artists.additional_artist.actions.confirm' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" data-id="reject" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(EventCustomArtist)
