import { capitalize } from 'lodash/fp'
import React, { FC, memo, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'
import { getSalesforceContractUrl, getSalesforceStatusColor } from '../utils/salesforce'
import { textStyle } from '../utils/typography'
import { color, mediaQuery } from '../utils/variables'
import Badge from './Badge'
import Button from './Button'
import IconButton from './IconButton'
import InfoField from './InfoField'
import SalesforceContractsModal, { IContract } from './SalesforceContractsModal'
import Svg from './Svg'

const SubTitle = styled.strong`
  display: block;

  margin-bottom: 24px;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 24px 16px;

  & > * {
    flex: 1;
  }

  & > .-wide {
    min-width: 100%;
  }
`

const Narrow = styled.div`
  ${InfoField} {
    display: inline-block;
  }
`

const Relative = styled.div`
  position: relative;
`

const SIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;

  color: ${color.darkgrey};
  border-radius: 4px;
  border: 1px solid ${color.grey};

  svg {
    width: 20px;
    height: 20px;
  }

  margin-left: 4px;
`

const SButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;

  .button_content {
    ${textStyle.functional.md};
    font-weight: bold;
    color: ${color.primary};
    text-decoration: underline;
  }
`

const Icon = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;

  border-radius: 50%;
  background-color: ${color.lightgrey};

  display: flex;
  align-items: center;
  justify-content: center;
`

const NoContract = styled.div`
  flex: 1;

  margin: 0 16px;

  color: ${color.greyer};

  ${textStyle.functional.sm}

  & > strong {
    display: block;
    margin-bottom: 4px;

    color: ${color.text};
    ${textStyle.functional.md}
    font-weight: bold;
  }

  ${mediaQuery.lessThan('tablet')`
    margin: 16px 0;
    text-align: center;
  `}
`

const Flex = styled.div`
  display: flex;
  align-items: center;

  padding: 24px;
`

const Clamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

interface IProps {
  accountId: string | null
  parentAccountId: string | null

  contracts: ReadonlyArray<IContract | null> | null
  contract: IContract | null

  onSelect: (contract: IContract | null) => void
  showExpired?: boolean
  disabled?: boolean
}

const SalesforceContract: FC<IProps> = ({
  contract,
  contracts,
  accountId,
  parentAccountId,
  onSelect,
  showExpired,
  disabled,
}) => {
  const intl = useIntl()

  const [selectModal, setSelectModal] = useState(false)
  const hideModal = useCallback(() => setSelectModal(false), [])
  const toggleModal = useCallback(() => setSelectModal((v) => !v), [])

  const doSelect = useCallback(
    (contract: IContract | null) => {
      onSelect(contract)
      hideModal()
    },
    [hideModal, onSelect]
  )

  return (
    <>
      {!contract ? (
        <Flex data-id="noSalesforceContract">
          <Icon>
            <Svg icon="legal-paper" />
          </Icon>
          <NoContract>
            <strong>
              {intl.formatMessage({ id: 'promoter_form.salesforce_integration.no_default_contract.title' })}
            </strong>
            <div>
              {intl.formatMessage({ id: 'promoter_form.salesforce_integration.no_default_contract.description' })}
            </div>
          </NoContract>
          <Button disabled={disabled} data-id="selectSalesforceContract" onClick={toggleModal}>
            {intl.formatMessage({ id: 'promoter_form.salesforce_integration.no_default_contract.cta' })}
          </Button>
        </Flex>
      ) : (
        <Relative data-id="salesforceContract">
          <SubTitle>
            {intl.formatMessage({ id: 'promoter_form.salesforce_integration.default_contract.label' })}
          </SubTitle>

          <SButton disabled={disabled} data-id="changeSalesforceContractId" preset="link" onClick={toggleModal}>
            {intl.formatMessage({ id: 'actions.change' })}
          </SButton>

          <Container>
            <InfoField
              className="-wide"
              label={intl.formatMessage({
                id: 'promoter_form.salesforce_integration.default_contract.opportunity.label',
              })}
              fallback={<Clamp>{contract.name || contract.opportunityName || intl.formatMessage({ id: 'na' })}</Clamp>}
            />

            <Narrow>
              <InfoField
                label={intl.formatMessage({
                  id: 'promoter_form.salesforce_integration.default_contract.number.label',
                })}
                value={contract.num}
              />
              <SIconButton href={getSalesforceContractUrl(contract.sfId)} target="_blank" icon="open" />
            </Narrow>

            <InfoField
              label={intl.formatMessage({
                id: 'promoter_form.salesforce_integration.default_contract.status.label',
              })}
              fallback={
                <Badge color={getSalesforceStatusColor(contract.status)}>
                  {intl.formatMessage({
                    id: `promoter_form.salesforce_integration.contract_status.options.${(
                      contract.status || 'unknown'
                    )?.toLowerCase()}`,
                    defaultMessage: capitalize(contract.status || 'unknown'),
                  })}
                </Badge>
              }
            />

            <InfoField
              label={intl.formatMessage({
                id: 'promoter_form.salesforce_integration.default_contract.start_date.label',
              })}
              fallback={
                contract.startDate
                  ? intl.formatDate(contract.startDate, DATETIME_FORMATS.MEDIUM)
                  : intl.formatMessage({ id: 'na' })
              }
            />
          </Container>
        </Relative>
      )}
      {selectModal && accountId && (
        <SalesforceContractsModal
          accountId={accountId}
          parentAccountId={parentAccountId}
          contracts={contracts}
          selectedId={contract?.id || null}
          onSelect={doSelect}
          onClose={hideModal}
          showExpired={showExpired}
        />
      )}
    </>
  )
}

export default memo(SalesforceContract)
