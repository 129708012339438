import React, { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import PermissionGate from '../gates/PermissionGate'

const PayoutsPage = lazy(() => import(/* webpackChunkName: "payouts" */ '../../flows/Payouts/PayoutsPage'))
const FinancesPage = lazy(() => import(/* webpackChunkName: "finances" */ '../../flows/Finances/FinancesPage'))

const FinancesSection = () => (
  <Routes>
    <Route
      path="*"
      element={
        <PermissionGate permission="read:balances">
          <Outlet />
        </PermissionGate>
      }
    >
      <Route index element={<FinancesPage />} />
      <Route path="payouts" element={<PayoutsPage />} />
    </Route>
  </Routes>
)

export default FinancesSection
