/**
 * @generated SignedSource<<154fcca4672230faad75e4e0b72c14ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Merch_viewer$data = {
  readonly id: string;
  readonly " $fragmentType": "Merch_viewer";
};
export type Merch_viewer$key = {
  readonly " $data"?: Merch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Merch_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Merch_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e61fee19c7f0513f6f7765b33fa3eb68";

export default node;
