import React, { FC, memo, useContext, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router'
import styled from 'styled-components/macro'

import { useIntl } from 'react-intl'
import AccountSwitcher from '../../../components/AccountSwitcher'
import SidebarAccount from '../../../components/SidebarAccount'
import Tooltip from '../../../components/Tooltip'
import { authContext } from '../../../context/auth'
import { breakpoints, color, input, mediaQuery, zIndex } from '../../../utils/variables'
import { USER_ACCOUNT_TRIGGER_ID } from '../services/sidebarTrigger'
import { SidebarList, SidebarMenu, SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'
import { IFactory, SIDEBAR_ROUTER, preload } from '../services/sidebarNav'

const TRIGGER = ['click']
const OFFSET_MOBILE = { offset: [0, -21] }

export const SidebarFooter = styled.div`
  position: sticky;
  bottom: 0;
  background: ${color.white};
  margin: auto 0 0;
  padding: 16px;
  border-top: 1px solid ${input.borderColor};
  z-index: ${zIndex.formField};
  box-shadow: 0px -8px 10px ${color.white};

  ${SidebarList} {
    margin: 0;
  }

  ${SidebarMenu} {
    margin-bottom: 0;
  }
`

export const SidebarUser = styled.div`
  display: flex;
  align-items: center;
  body.compact-sidebar & {
    ${mediaQuery.greaterThan('tablet')`
      display: block;
    `}
  }
  ${mediaQuery.between('tablet', 'desktop')`
    display: block;
  `}
`

interface IProps {
  expandOnMobile: boolean
}

const Footer: FC<IProps> = ({ expandOnMobile }) => {
  const { key, pathname, search } = useLocation()
  const intl = useIntl()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })
  const { user, account } = useContext(authContext)
  const accountName = user.diceStaff ? 'DICE' : account?.name

  const shouldReloadSupport = useMemo(() => pathname + search === SIDEBAR_ROUTER.support[0], [pathname, search])

  return (
    <SidebarFooter>
      <SidebarList>
        <SidebarMenu>
          <SidebarMenuItem
            shouldReload={shouldReloadSupport}
            active={pathname === SIDEBAR_ROUTER.support[0]}
            to={SIDEBAR_ROUTER.support[0]}
            data-id="sidebarHelpCentre"
            onMouseEnter={preload(SIDEBAR_ROUTER.support[1] as IFactory)}
          >
            <SidebarMenuIcon icon="info" />
            <span>{intl.formatMessage({ id: 'sidebar.help_centre' })}</span>
          </SidebarMenuItem>

          <Tooltip
            key={`${key}:${expandOnMobile}`}
            trigger={TRIGGER}
            overlayClassName="-fixed"
            placement={isMobile ? 'top' : 'rightBottom'}
            align={isMobile ? OFFSET_MOBILE : undefined}
            handle={
              <SidebarMenuItem>
                <SidebarUser id={USER_ACCOUNT_TRIGGER_ID}>
                  <SidebarAccount v2 name={user.name || ''} accountName={accountName} data-id="account" />
                </SidebarUser>
              </SidebarMenuItem>
            }
          >
            <AccountSwitcher />
          </Tooltip>
        </SidebarMenu>
      </SidebarList>
    </SidebarFooter>
  )
}

export default memo(Footer)
