/**
 * @generated SignedSource<<030cf67b5c97d04cd8870eaabb626c6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventAssigneeControl_eventReview$data = {
  readonly assignee: {
    readonly email: string | null;
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly name: string | null;
  } | null;
  readonly event: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "EventAssigneeControl_eventReview";
};
export type EventAssigneeControl_eventReview$key = {
  readonly " $data"?: EventAssigneeControl_eventReview$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAssigneeControl_eventReview">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAssigneeControl_eventReview",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "assignee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "EventReview",
  "abstractKey": null
};
})();

(node as any).hash = "3ee993b70fa60d7992fd33ce2a6d5063";

export default node;
