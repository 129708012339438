import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { getOr } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import IEventFormSettings from '../../../flows/EventForm/types/Settings'
import IEventFormTickets from '../../../flows/EventForm/types/Tickets'
import SwitchField from '../../../components/SwitchField'
import Button from '../../../components/Button'
import { FormRow } from '../../../components/Form'
import { Modal, ModalDialog } from '../../../components/Modal'
import { color, spacing } from '../../../utils/variables'
import { textStyle } from '../../../utils/typography'
import Svg from '../../../components/Svg'
import { authContext } from '../../../context/auth'

interface IProps {
  children?: React.ReactNode
}

const Container = styled.div`
  border: 2px solid ${color.lightgrey};
  border-radius: 8px;
  padding: ${spacing.md}px;
`

const Top = styled.div`
  align-items: center;
  display: flex;
`

const Label = styled.div`
  ${textStyle.heading.xs};

  align-items: center;
  display: flex;
  margin-bottom: 4px;
`

const Text = styled.div`
  ${textStyle.functional.sm};

  color: ${color.darkgrey};

  ul {
    list-style: disc;

    li {
      margin-left: 20px;
    }
  }
`

const Hint = styled.div`
  ${textStyle.functional.sm};

  background-color: ${color.palegrey};
  border-radius: 8px;
  display: flex;
  margin-top: ${spacing.md}px;
  padding: ${spacing.md}px;

  p {
    margin: 0 0 ${spacing.sm}px;
  }

  ul {
    list-style: none;
    li {
      margin-left: 0;
      margin-bottom: 2px;
    }
  }
`

const HintIcon = styled(Svg)`
  height: 16px;
  width: 16px;
  margin-right: ${spacing.xs}px;
`

const ToggleFormRow = styled(FormRow)`
  height: 20px;
`

const LearnMore = styled.button`
  color: ${color.primary};
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  white-space: nowrap;
  align-items: center;
  display: inline-flex;
`

const LearnMoreIcon = styled(Svg)<{ collapsed: boolean }>`
  height: 18px;
  margin-left: 4px;
  width: 18px;

  ${({ collapsed }) => (collapsed ? '' : 'transform: rotate(-180deg);')}
`

const Icon = styled(Svg)`
  height: 64px;
  width: 64px;
`

const StyledModal = styled(Modal)`
  text-align: center;

  ${ModalDialog} {
    max-width: 360px;
  }

  h1 {
    ${textStyle.heading.md};
    margin-top: 0;
    margin-bottom: 12px;
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
  }

  ul {
    display: inline-block;
    list-style: disc;
    margin: 0 auto;
    text-align: left;
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  button {
    margin: 0 8px;
  }
`

const Badge = styled.span`
  border: 1px ${color.tertiary} solid;
  border-radius: 4px;
  color: ${color.tertiary};
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  padding: 1px 4px 0;
  margin-left: 6px;
  text-transform: uppercase;
`

const EventShoppingCart: FC<React.PropsWithChildren<IProps>> = () => {
  const intl = useIntl()
  const { hasPermission } = useContext(authContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [hintOpen, setHintOpen] = useState(false)

  const { values, handleChange, setFieldValue } = useFormikContext<IEventFormSettings>()
  const { values: ticketValues } = useFormikContext<IEventFormTickets>()

  const handleToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && values.state === 'DRAFT') {
        setModalOpen(true)
        return
      }

      // Switch off Unicorn if the event is not published
      if (values.state === 'DRAFT') {
        setFieldValue('flags.unicorn.active', false)
      }
      handleChange(event)
    },
    [handleChange, setFieldValue, values.state]
  )

  const handleCartActivate = useCallback(() => {
    setModalOpen(false)
    setFieldValue('flags.shoppingCart.active', true)

    // Enable Unicorn
    setFieldValue('flags.unicorn.active', true)

    if (ticketValues.ticketTypes && ticketValues.ticketTypes.length > 0) {
      setFieldValue(
        'ticketTypes',
        ticketValues.ticketTypes.map((ticketType) => ({ ...ticketType, codeLocked: false, doorSalesEnabled: false }))
      )
    }
  }, [setFieldValue, ticketValues.ticketTypes])

  const handleCancel = useCallback(() => {
    setModalOpen(false)
  }, [])

  const handleHintClick = useCallback(() => {
    setHintOpen(!hintOpen)
  }, [hintOpen])

  const isPwlEnabled = getOr(false, 'flags.enabledPwl.active', values)
  const isPromotionsEnabled = getOr(false, 'flags.promotions.active', values)
  const isCodeLocked =
    getOr(false, 'flags.codeLocked.active', values) ||
    ticketValues.ticketTypes?.some((ticketType) => ticketType?.codeLocked)
  const isDoorSalesEnabled = ticketValues.ticketTypes?.some((ticketType) => ticketType?.doorSalesEnabled)
  const isSeatingEnabled = getOr(false, 'flags.seated.active', values)

  const isDisabled = isPwlEnabled || isPromotionsEnabled || isCodeLocked || isDoorSalesEnabled || isSeatingEnabled

  const disabledMessage = useMemo(() => {
    if (isPwlEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.shopping_cart.not_compatible_with_pwl' })
    }
    if (isPromotionsEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.shopping_cart.not_compatible_with_promotions' })
    }
    if (isCodeLocked) {
      return intl.formatMessage({ id: 'new_event.settings.shopping_cart.not_compatible_with_code_lock' })
    }
    if (isDoorSalesEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.shopping_cart.not_compatible_with_door_sales' })
    }
    if (isSeatingEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.shopping_cart.not_compatible_with_seated' })
    }
    return ''
  }, [intl, isCodeLocked, isDoorSalesEnabled, isSeatingEnabled, isPwlEnabled, isPromotionsEnabled])

  return (
    <>
      <Container>
        <Top>
          <div>
            <Label>
              {intl.formatMessage({ id: 'new_event.settings.shopping_cart.title' })}
              <Badge>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.beta' })}</Badge>
            </Label>
            <Text>
              {intl.formatMessage({ id: 'new_event.settings.shopping_cart.description' })}{' '}
              <LearnMore type="button" onClick={handleHintClick}>
                {intl.formatMessage({ id: 'learn_more' })}
                <LearnMoreIcon icon="collapsible" collapsed={!hintOpen} />
              </LearnMore>
            </Text>
          </div>
          <div>
            <ToggleFormRow>
              <SwitchField
                name="flags.shoppingCart.active"
                checked={getOr(false, 'flags.shoppingCart.active', values)}
                onChange={handleToggle}
                disabled={isDisabled}
                title={disabledMessage}
              />
            </ToggleFormRow>
          </div>
        </Top>
        {hintOpen && (
          <Hint>
            <div>
              <HintIcon icon="info" />
            </div>
            <div>
              <p>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.hint.title' })}</p>
              <Text>
                <ul>
                  <li>{intl.formatMessage({ id: 'new_event.tickets.flags.pwl_enabled.label' })}</li>
                  <li>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.hint.promotions' })}</li>
                  <li>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.hint.code_locks' })}</li>
                  {hasPermission('edit_all:door_sale') && (
                    <li>{intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.door_sales.label' })}</li>
                  )}
                  <li>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.hint.seating' })}</li>
                </ul>
              </Text>
            </div>
          </Hint>
        )}
      </Container>
      {modalOpen && (
        <StyledModal onClose={handleCancel}>
          <div>
            <Icon icon="info" />
            <h1>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.alert.title' })}</h1>
            <p>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.alert.text' })}</p>
            <Text>
              <ul>
                <li>{intl.formatMessage({ id: 'new_event.tickets.flags.pwl_enabled.label' })}</li>
                <li>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.hint.promotions' })}</li>
                <li>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.hint.code_locks' })}</li>
                {hasPermission('edit_all:door_sale') && (
                  <li>{intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.door_sales.label' })}</li>
                )}
                <li>{intl.formatMessage({ id: 'new_event.settings.shopping_cart.hint.seating' })}</li>
              </ul>
            </Text>
          </div>
          <Buttons>
            <Button type="button" onClick={handleCartActivate}>
              {intl.formatMessage({ id: 'ok' })}
            </Button>
            <Button type="button" preset="secondary" onClick={handleCancel}>
              {intl.formatMessage({ id: 'actions.cancel' })}
            </Button>
          </Buttons>
        </StyledModal>
      )}
    </>
  )
}

export default EventShoppingCart
