/**
 * @generated SignedSource<<cd712d6759ea5865ad5af69797dac636>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompatibilityAe = "_0" | "_1000" | "_5000" | "%future added value";
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type MediaItemTypes = "appleMusicTrack" | "spotifyArtist" | "spotifyTrack" | "trailer" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type SalesforceContractStatus = "ACTIVATED" | "CANCELLED" | "EXPIRED" | "SIGNED" | "SUPERSEDED" | "%future added value";
export type TvPlatform = "DICE" | "EXTERNAL" | "%future added value";
export type VenueTier = "TIER_1" | "TIER_2" | "TIER_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Basics_event$data = {
  readonly additionalArtists: ReadonlyArray<{
    readonly description: string | null;
    readonly hierarchicalTags: ReadonlyArray<{
      readonly kind: string | null;
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly additionalInfos: ReadonlyArray<{
    readonly content: string | null;
    readonly ctaLabel: string | null;
    readonly ctaLink: string | null;
    readonly id: string;
    readonly includeOnPurchaseEmail: boolean | null;
    readonly includeOnReminderEmail: boolean | null;
  } | null> | null;
  readonly addressCapacity: number | null;
  readonly addressCountry: string | null;
  readonly addressLocality: string | null;
  readonly addressRegion: string | null;
  readonly addressSiaeCode: string | null;
  readonly addressState: string | null;
  readonly allowedActions: {
    readonly manageLinks: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly artistIds: {
      readonly canAdd: boolean | null;
      readonly canRemove: boolean | null;
      readonly canUpdate: boolean | null;
    } | null;
    readonly attractiveCompatibilityAe: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly eventImages: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly lineUp: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly media: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly name: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly venues: {
      readonly canUpdate: boolean | null;
    } | null;
  } | null;
  readonly attractiveFields: {
    readonly author: string | null;
    readonly compatibilityAe: CompatibilityAe | null;
    readonly distributor: string | null;
    readonly entertainmentPercent: number | null;
    readonly integrationDisabled: boolean;
    readonly nationality: string | null;
    readonly performer: string | null;
    readonly producer: string | null;
    readonly seatingAreaConfigs: ReadonlyArray<{
      readonly capacity: number;
      readonly seatingArea: string;
    } | null>;
    readonly siaeGenreType: string | null;
    readonly streamingTicketsIntegrationDisabled: boolean;
    readonly taxFree: boolean | null;
  } | null;
  readonly attractiveStatus: {
    readonly status: string | null;
  } | null;
  readonly billingPromoter: {
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly associatedMarketeers: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly autoRescheduledEventRefunds: {
      readonly active: boolean | null;
      readonly cutoffDays: number | null;
    } | null;
    readonly billingNotes: string | null;
    readonly coolingOffPeriod: boolean | null;
    readonly coolingOffPeriodHours: number | null;
    readonly countryCode: string | null;
    readonly disableUsTax: boolean | null;
    readonly displayName: string | null;
    readonly fanSupportNotes: {
      readonly body: string | null;
    } | null;
    readonly holdPayouts: boolean | null;
    readonly isTest: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly licenseNumber: string;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly salesforceFields: {
      readonly defaultContract: {
        readonly id: string;
        readonly name: string | null;
        readonly num: string | null;
        readonly opportunityName: string | null;
        readonly sfAccountId: string | null;
        readonly sfId: string | null;
        readonly startDate: string | null;
        readonly status: SalesforceContractStatus | null;
      } | null;
    } | null;
    readonly sendReceiptViaSms: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly value: string;
  } | null;
  readonly characteristics: ReadonlyArray<{
    readonly label: string | null;
    readonly value: string;
  } | null> | null;
  readonly charityEvent: boolean | null;
  readonly charityId: string | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly countryCode: string | null;
  readonly date: string | null;
  readonly diceTvPlatform: TvPlatform | null;
  readonly eventArtists: ReadonlyArray<{
    readonly artist: {
      readonly hierarchicalTags: ReadonlyArray<{
        readonly kind: string | null;
        readonly label: string | null;
        readonly parent: {
          readonly name: string | null;
        } | null;
        readonly value: string;
      } | null> | null;
      readonly hint: string | null;
      readonly label: string | null;
      readonly media: ReadonlyArray<{
        readonly id: string;
        readonly type: MediaItemTypes;
        readonly values: IBackendMap | null;
      } | null>;
      readonly profileImageAttachment: {
        readonly cdnUrl: string;
      } | null;
      readonly profileImageCropRegion: {
        readonly height: number | null;
        readonly width: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
      readonly tags: ReadonlyArray<{
        readonly label: string | null;
        readonly value: string;
      } | null> | null;
      readonly value: string;
    } | null;
    readonly headliner: boolean | null;
  } | null> | null;
  readonly eventType: EventType | null;
  readonly freeEvent: boolean | null;
  readonly fullAddress: string | null;
  readonly hierarchicalTags: ReadonlyArray<{
    readonly kind: string | null;
    readonly label: string | null;
    readonly parent: {
      readonly name: string | null;
    } | null;
    readonly value: string;
  } | null> | null;
  readonly id: string;
  readonly licenseNumber: string | null;
  readonly lineup: ReadonlyArray<IBackendMap | null> | null;
  readonly media: ReadonlyArray<{
    readonly id: string;
    readonly type: MediaItemTypes;
    readonly values: IBackendMap | null;
  } | null> | null;
  readonly name: string | null;
  readonly permName: string | null;
  readonly platformAccountCode: PlatformAccountCode | null;
  readonly postalCode: string | null;
  readonly primaryVenue: {
    readonly label: string | null;
    readonly value: string;
    readonly venueSpaces: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
  } | null;
  readonly promoters: ReadonlyArray<{
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly associatedMarketeers: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly autoRescheduledEventRefunds: {
      readonly active: boolean | null;
      readonly cutoffDays: number | null;
    } | null;
    readonly billingNotes: string | null;
    readonly coolingOffPeriod: boolean | null;
    readonly coolingOffPeriodHours: number | null;
    readonly countryCode: string | null;
    readonly disableUsTax: boolean | null;
    readonly displayName: string | null;
    readonly fanSupportNotes: {
      readonly body: string | null;
    } | null;
    readonly holdPayouts: boolean | null;
    readonly isTest: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly licenseNumber: string;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly salesforceFields: {
      readonly defaultContract: {
        readonly id: string;
        readonly name: string | null;
        readonly num: string | null;
        readonly opportunityName: string | null;
        readonly sfAccountId: string | null;
        readonly sfId: string | null;
        readonly startDate: string | null;
        readonly status: SalesforceContractStatus | null;
      } | null;
    } | null;
    readonly sendReceiptViaSms: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly value: string;
  } | null> | null;
  readonly salesforceContract: {
    readonly id: string;
    readonly name: string | null;
    readonly num: string | null;
    readonly opportunityName: string | null;
    readonly sfAccountId: string | null;
    readonly sfId: string | null;
    readonly startDate: string | null;
    readonly status: SalesforceContractStatus | null;
  } | null;
  readonly state: EventState | null;
  readonly streetAddress: string | null;
  readonly stripeAccountId: string | null;
  readonly tags: ReadonlyArray<{
    readonly label: string | null;
    readonly value: string;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly venueConfiguration: {
    readonly attractiveRoomSiaeCode: string | null;
    readonly capacity: number;
    readonly label: string;
    readonly seatingAreaConfigs: ReadonlyArray<{
      readonly capacity: number;
      readonly seatingArea: string;
    } | null>;
    readonly value: string;
  } | null;
  readonly venueName: string | null;
  readonly venueSchedules: ReadonlyArray<{
    readonly date: string | null;
    readonly endDate: string | null;
    readonly id: string;
    readonly name: string | null;
    readonly venueConfigurationId: string | null;
    readonly venueId: string | null;
  } | null> | null;
  readonly venueSpace: {
    readonly label: string;
    readonly value: string;
  } | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly addressLocality: string | null;
    readonly addressRegion: string | null;
    readonly addressState: string | null;
    readonly ageLimit: string | null;
    readonly capacity: number;
    readonly configurations: ReadonlyArray<{
      readonly attractiveRoomSiaeCode: string | null;
      readonly capacity: number;
      readonly label: string;
      readonly seatingAreaConfigs: ReadonlyArray<{
        readonly capacity: number;
        readonly seatingArea: string;
      } | null>;
      readonly value: string;
    } | null> | null;
    readonly countryCode: string | null;
    readonly fullAddress: string | null;
    readonly isTest: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly postalCode: string | null;
    readonly profileDetails: {
      readonly imageAttachment: {
        readonly cdnUrl: string;
      } | null;
      readonly imageCropRegion: {
        readonly height: number | null;
        readonly width: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
    } | null;
    readonly streetAddress: string | null;
    readonly tier: VenueTier | null;
    readonly timezoneName: string | null;
    readonly value: string;
    readonly venueImages: ReadonlyArray<{
      readonly attachment: {
        readonly cdnUrl: string;
      } | null;
    } | null> | null;
    readonly venueSpaces: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "Basics_event";
};
export type Basics_event$key = {
  readonly " $data"?: Basics_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"Basics_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseNumber",
  "storageKey": null
},
v3 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SeatingAreaConfig",
  "kind": "LinkedField",
  "name": "seatingAreaConfigs",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seatingArea",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezoneName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLocality",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressRegion",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressState",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAddress",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "MediaItem",
  "kind": "LinkedField",
  "name": "media",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "values",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeAccountId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platformAccountCode",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTest",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Label",
  "kind": "LinkedField",
  "name": "labels",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v26 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "num",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "opportunityName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sfAccountId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sfId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startDate",
    "storageKey": null
  },
  (v6/*: any*/)
],
v27 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v24/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v22/*: any*/),
  (v23/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceSuggestions",
    "storageKey": null
  },
  (v12/*: any*/),
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountId",
    "storageKey": null
  },
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowSkipReview",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "resoldEnabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "disableUsTax",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "holdPayouts",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "coolingOffPeriod",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "coolingOffPeriodHours",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sendReceiptViaSms",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "billingNotes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "FanSupportNotes",
    "kind": "LinkedField",
    "name": "fanSupportNotes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v25/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Marketeer",
    "kind": "LinkedField",
    "name": "associatedMarketeers",
    "plural": true,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AutoRescheduledEventRefunds",
    "kind": "LinkedField",
    "name": "autoRescheduledEventRefunds",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cutoffDays",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SalesforcePromoterFields",
    "kind": "LinkedField",
    "name": "salesforceFields",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SalesforceContract",
        "kind": "LinkedField",
        "name": "defaultContract",
        "plural": false,
        "selections": (v26/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v28 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "attractiveRoomSiaeCode",
    "storageKey": null
  },
  (v7/*: any*/),
  (v8/*: any*/)
],
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "VenueSpace",
  "kind": "LinkedField",
  "name": "venueSpaces",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v30 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cdnUrl",
    "storageKey": null
  }
],
v31 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "x",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "y",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  }
],
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "HierarchicalTag",
  "kind": "LinkedField",
  "name": "hierarchicalTags",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v32/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "HierarchicalTag",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Basics_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permName",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceTvPlatform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "charityEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "charityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Characteristic",
      "kind": "LinkedField",
      "name": "characteristics",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationStatus",
      "kind": "LinkedField",
      "name": "attractiveStatus",
      "plural": false,
      "selections": [
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttractiveFields",
      "kind": "LinkedField",
      "name": "attractiveFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "compatibilityAe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "siaeGenreType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entertainmentPercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamingTicketsIntegrationDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFree",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "author",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distributor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nationality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "producer",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageLinks",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "venues",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "eventImages",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesArtistIds",
          "kind": "LinkedField",
          "name": "artistIds",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canAdd",
              "storageKey": null
            },
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canRemove",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesLineUp",
          "kind": "LinkedField",
          "name": "lineUp",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "media",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "attractiveCompatibilityAe",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressSiaeCode",
      "storageKey": null
    },
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "venueName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lineup",
      "storageKey": null
    },
    (v20/*: any*/),
    (v21/*: any*/),
    (v22/*: any*/),
    (v23/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": (v27/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesforceContract",
      "kind": "LinkedField",
      "name": "salesforceContract",
      "plural": false,
      "selections": (v26/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "promoters",
      "plural": true,
      "selections": (v27/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueConfiguration",
      "kind": "LinkedField",
      "name": "venueConfiguration",
      "plural": false,
      "selections": (v28/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueSpace",
      "kind": "LinkedField",
      "name": "venueSpace",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v24/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ageLimit",
          "storageKey": null
        },
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v19/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        },
        (v25/*: any*/),
        (v29/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDetails",
          "kind": "LinkedField",
          "name": "profileDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "imageAttachment",
              "plural": false,
              "selections": (v30/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CropRegion",
              "kind": "LinkedField",
              "name": "imageCropRegion",
              "plural": false,
              "selections": (v31/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VenueImage",
          "kind": "LinkedField",
          "name": "venueImages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "attachment",
              "plural": false,
              "selections": (v30/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VenueConfiguration",
          "kind": "LinkedField",
          "name": "configurations",
          "plural": true,
          "selections": (v28/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "primaryVenue",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v29/*: any*/)
      ],
      "storageKey": null
    },
    (v33/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventArtist",
      "kind": "LinkedField",
      "name": "eventArtists",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headliner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Artist",
          "kind": "LinkedField",
          "name": "artist",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": "hint",
              "args": null,
              "kind": "ScalarField",
              "name": "disambiguation",
              "storageKey": null
            },
            (v21/*: any*/),
            (v33/*: any*/),
            (v34/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "profileImageAttachment",
              "plural": false,
              "selections": (v30/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CropRegion",
              "kind": "LinkedField",
              "name": "profileImageCropRegion",
              "plural": false,
              "selections": (v31/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventAdditionalInfo",
      "kind": "LinkedField",
      "name": "additionalInfos",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeOnPurchaseEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeOnReminderEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaLink",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalArtist",
      "kind": "LinkedField",
      "name": "additionalArtists",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HierarchicalTag",
          "kind": "LinkedField",
          "name": "hierarchicalTags",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v32/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v34/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueSchedule",
      "kind": "LinkedField",
      "name": "venueSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v20/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "venueId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "venueConfigurationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "0bee8be5c499341709325911efcea09e";

export default node;
