import React, { FC, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { ConfirmationModal } from './ConfirmationModal'
import { CURRENCY } from '../utils/formatters/number'
import IEventForm from '../flows/EventForm/types'

interface Props {
  onClose: () => void
  onSaveDraft?: (values: IEventForm, helpers: any, isDraft: boolean) => Promise<any>
}

const EventIsFreeModal: FC<Props> = ({ onSaveDraft, onClose }) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const formik = useFormikContext<IEventForm>()

  const handleConfirm = useCallback(() => {
    setLoading(true)
    if (onSaveDraft) {
      onSaveDraft(
        {
          ...formik.values,
          freeEvent: true,
        },
        formik,
        false
      ).finally(onClose)
    } else {
      formik.setFieldValue('freeEvent', true)
      setTimeout(onClose, 0)
    }
  }, [formik, onClose, onSaveDraft])

  return (
    <ConfirmationModal
      icon="info"
      loading={loading}
      onReject={onClose}
      onConfirm={handleConfirm}
      cta={intl.formatMessage({ id: 'yes' })}
      description={intl.formatMessage(
        { id: 'new_event.free_event.confirmation.description' },
        {
          amount: intl.formatNumber(0, {
            ...CURRENCY(0, formik.values.costCurrency),
            minimumFractionDigits: 2,
          }),
        }
      )}
      title={intl.formatMessage({ id: 'new_event.free_event.confirmation.title' })}
    />
  )
}

export default EventIsFreeModal
