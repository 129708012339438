import { nanoid } from 'nanoid'
import { COMMIT_HASH } from '../env'

export default async function versionCheck() {
  if (!COMMIT_HASH) {
    return true
  }

  const res = await fetch(`/version?_rnd=${encodeURIComponent(nanoid())}`)
  if (res.ok) {
    const txt = await res.text()
    if (txt !== COMMIT_HASH) {
      console.warn('App version mismatch!', txt, COMMIT_HASH)
    }
    return txt === COMMIT_HASH
  }
}
