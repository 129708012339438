import React, { FC, Fragment, memo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Table, { TableBody, TableHeader, Row, HeaderCell, Cell } from '../../../../../components/Table'

import { color } from '../../../../../utils/variables'
import { CURRENCY } from '../../../../../utils/formatters/number'
// eslint-disable-next-line max-len
import { RemittanceModalDataDesktop_event$key } from '../../../../../__generated__/RemittanceModalDataDesktop_event.graphql'

const MDASH = styled.i`
  margin: 0 4px;
  color: ${color.lightgrey};
  font-style: normal;
  &:before {
    content: '—';
  }
`

const TableWrapper = styled.div`
  position: relative;
`

const RowTier = styled(Row)`
  td {
    &:first-child {
      padding-left: 24px;
    }
  }
`

const RemittanceModalDataDesktop: FC<React.PropsWithChildren<{ event: RemittanceModalDataDesktop_event$key }>> = (
  props
) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment RemittanceModalDataDesktop_event on Event {
        id
        costCurrency
        sales {
          ticketTypesBreakdown {
            ticketTypeId
            totalAppSold
            totalFaceValue
            totalRebate
            ticketType {
              id
              name
              icon
              faceValue
              rebate
              price
              priceTiers {
                id
                name
                price
                order
                faceValue
                rebate
              }
            }
            priceTiersBreakdown {
              value
              appSold
              posSold
              rebate
            }
          }
        }
      }
    `,
    props.event
  )

  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          <Row>
            <HeaderCell width="auto">{intl.formatMessage({ id: 'ticket_type' })}</HeaderCell>
            <HeaderCell width="auto" textAlign="right">
              {intl.formatMessage({ id: 'remittance.col.sold' })}
            </HeaderCell>
            <HeaderCell width="auto" textAlign="right">
              {intl.formatMessage({ id: 'remittance.col.face_value' })}
            </HeaderCell>
            <HeaderCell width="auto" textAlign="right">
              {intl.formatMessage({ id: 'remittance.col.rebate' })}
            </HeaderCell>
            <HeaderCell width="auto" textAlign="right">
              {intl.formatMessage({ id: 'remittance.col.total' })}
            </HeaderCell>
          </Row>
        </TableHeader>
        <TableBody>
          {event?.sales?.ticketTypesBreakdown.map(
            (tt) =>
              tt && (
                <Fragment key={tt.ticketTypeId}>
                  <Row>
                    {tt.ticketType.priceTiers?.length ? (
                      <Cell wrapText colSpan={5}>
                        <strong>{tt.ticketType.name}</strong>
                      </Cell>
                    ) : (
                      <>
                        <Cell wrapText>
                          <strong>{tt.ticketType.name}</strong>
                          <MDASH />
                          <span>
                            {intl.formatNumber(
                              (tt.ticketType.price || 0) / 100,
                              CURRENCY(tt.ticketType.price || 0, event.costCurrency)
                            )}
                          </span>
                        </Cell>
                        <Cell textAlign="right">{tt.totalAppSold}</Cell>
                        <Cell textAlign="right">
                          {intl.formatNumber(
                            (tt.ticketType.faceValue || 0) / 100,
                            CURRENCY(tt.ticketType.faceValue || 0, event.costCurrency)
                          )}
                        </Cell>
                        <Cell textAlign="right">
                          {intl.formatNumber(
                            (tt.ticketType.rebate || 0) / 100,
                            CURRENCY(tt.ticketType.rebate || 0, event.costCurrency)
                          )}
                        </Cell>
                        <Cell textAlign="right">
                          {intl.formatNumber(
                            ((tt.totalFaceValue || 0) + (tt.totalRebate || 0)) / 100,
                            CURRENCY((tt.totalFaceValue || 0) + (tt.totalRebate || 0), event.costCurrency)
                          )}
                        </Cell>
                      </>
                    )}
                  </Row>
                  {tt.ticketType.priceTiers?.map(
                    (pt, idx) =>
                      pt && (
                        <RowTier key={pt.id}>
                          <Cell>
                            <span>{pt.name || intl.formatMessage({ id: 'price_tier_num' }, { num: idx + 1 })}</span>
                            <MDASH />
                            <span>
                              {intl.formatNumber((pt.price || 0) / 100, CURRENCY(pt.price || 0, event.costCurrency))}
                            </span>
                          </Cell>
                          <Cell textAlign="right">{tt.priceTiersBreakdown[idx]?.appSold || 0}</Cell>
                          <Cell textAlign="right">
                            {intl.formatNumber(
                              (pt.faceValue || 0) / 100,
                              CURRENCY(pt.faceValue || 0, event.costCurrency)
                            )}
                          </Cell>
                          <Cell textAlign="right">
                            {intl.formatNumber((pt.rebate || 0) / 100, CURRENCY(pt.rebate || 0, event.costCurrency))}
                          </Cell>
                          <Cell textAlign="right">
                            {intl.formatNumber(
                              ((tt.priceTiersBreakdown[idx]?.value || 0) + (tt.priceTiersBreakdown[idx]?.rebate || 0)) /
                                100,
                              CURRENCY(
                                (tt.priceTiersBreakdown[idx]?.value || 0) + (tt.priceTiersBreakdown[idx]?.rebate || 0),
                                event.costCurrency
                              )
                            )}
                          </Cell>
                        </RowTier>
                      )
                  )}
                </Fragment>
              )
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

export default memo(RemittanceModalDataDesktop)
