import { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react'
import { filter, find, omit, snakeCase } from 'lodash/fp'
import { FormikHelpers, useFormik } from 'formik'
import qs from 'qs'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

import { getCountryLocale, OPERATING_COUNTRY_CODES_SET } from '../../../constants/operatingCountries'
import getCountries, { getTimezonesByAlpha2 } from '../../../utils/countries'
import { localeContext } from '../../../context/locale'
import { notificationContext } from '../../../context/notification'
import { trackingContext } from '../../../context/tracking'
import hasEmoji from '../../../utils/emoji'
import countrySpecificFields from '../util/countrySpecificFields'
import REQUESTS, { IPreferredLanguage } from '../../../utils/requests'

export const organizerTypes = [
  { value: 'promoter', i18n: 'organiser_types.promoter', hint: 'organiser_types_hint.promoter' },
  { value: 'artist', i18n: 'organiser_types.artist', hint: 'organiser_types_hint.artist' },
  { value: 'artist_management', i18n: 'organiser_types.artist_manager', hint: 'organiser_types_hint.artist_manager' },
  { value: 'agent', i18n: 'organiser_types.artist_agent', hint: 'organiser_types_hint.artist_agent' },
  { value: 'venue', i18n: 'organiser_types.venue', hint: 'organiser_types_hint.venue' },
] as const

const SignupSchema = Yup.object()
  .shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    country: Yup.object({ value: Yup.string().required() }).required(),
    city: Yup.object({ value: Yup.string().required() }).required(),
    companyName: Yup.string().required(),
    displayName: Yup.string().required(),
    typeOfOrganizer: Yup.object({ value: Yup.string().required() }).required(),
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    countrySpecificFields: Yup.object(),
  })
  .test('noEmoji', 'No emoji allowed in companyName', function (values) {
    return hasEmoji(values.companyName) ? this.createError({ path: 'companyName', message: ' ' }) : true
  })
  .test('countrySpecificFields', 'Country specific fields validation', function (values) {
    if (!values.countrySpecificFields || !values.country?.alpha2) return true

    const countrySchema = countrySpecificFields(values.country.alpha2)

    if (countrySchema) {
      try {
        countrySchema.validateSync(values.countrySpecificFields, { abortEarly: false })
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const err = error as Yup.ValidationError
          if (err.inner) {
            err.inner.forEach((innerError) => {
              innerError.path = innerError.path && `countrySpecificFields.${innerError.path}`
            })
          }
          return err
        }
        throw error
      }
    }

    return true
  })

export interface ISignupForm {
  firstName: string | null
  lastName: string | null
  country: { value: string; label: string; alpha2: string } | null
  city: { value: string; label: string } | null
  companyName: string | null
  displayName: string | null
  typeOfOrganizer: typeof organizerTypes[number] | null
  email: string | null
  password: string | null
  countrySpecificFields: {
    licenseNumber: string | null | undefined
    hasUSEvents: boolean | undefined
    hasEUEvents: boolean | undefined
    postalCode: string | null | undefined
    addressState: string | null | undefined
    vatNumber: string | null | undefined
    taxCode: string | null | undefined
  }
}

function useSignupForm(setStep?: Dispatch<SetStateAction<number>>) {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)

  const countryOptions = useMemo(
    () => filter(({ alpha2 }) => OPERATING_COUNTRY_CODES_SET.has(alpha2), getCountries(intl, locale)),
    [intl, locale]
  )

  const initialValues = useMemo(() => {
    const { country: countryCode } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const country = find(['alpha2', (countryCode?.toString() || 'GB').toUpperCase()], countryOptions)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const initialCountry = country || find(['alpha2', 'GB'], countryOptions)!

    return {
      firstName: '',
      lastName: '',
      country: initialCountry,
      city: null,
      companyName: '',
      displayName: '',
      typeOfOrganizer: organizerTypes[0],
      email: '',
      password: '',
      countrySpecificFields: {
        licenseNumber: undefined,
        hasUSEvents: undefined,
        hasEUEvents: undefined,
        postalCode: undefined,
        addressState: undefined,
        vatNumber: undefined,
        taxCode: undefined,
      },
    }
  }, [countryOptions])

  const onSubmit = useCallback(
    async (values: ISignupForm, { setErrors, setStatus, setFieldValue }: FormikHelpers<ISignupForm>) => {
      trackEvent('account_create_submitted')

      const countrySchema = countrySpecificFields(values.country?.alpha2 || null)
      const countryFieldValues = countrySchema ? countrySchema.cast(values.countrySpecificFields) : null

      const tzList = values?.country?.alpha2 ? getTimezonesByAlpha2(values.country.alpha2) : []

      const { account, errors } = await REQUESTS.CREATE_ACCOUNT({
        ...countryFieldValues,
        firstName: values.firstName || '',
        lastName: values.lastName || '',
        country: values.country?.value || '',
        countryCode: values.country?.alpha2 || '',
        addressLocality: values.city?.value || '',
        companyName: values.companyName || '',
        displayName: values.displayName || '',
        typeOfOrganizer: values.typeOfOrganizer?.value || '',
        email: values.email || '',
        password: values.password || '',
        preferredLanguage: values.country?.alpha2
          ? (snakeCase(getCountryLocale(values.country?.alpha2)?.toLowerCase()) as IPreferredLanguage)
          : 'en_gb',
        timezoneName: tzList[0] || 'Europe/London',
      })
      if (account) {
        setStatus({ success: true })
      } else {
        const { user, account } = errors || {}

        const newErrors = {
          countrySpecificFields: account && {
            ...omit(['address_country', 'name', 'type_of_organizer', 'taxCode', 'accountVatNumber'], account),
            taxCode: account?.taxCode,
            vatNumber: account?.accountVatNumber,
          },
          firstName: user?.first_name,
          lastName: user?.last_name,
          email: user?.email,
          password: user?.password,
          country: account?.address_country || account?.country_code,
          companyName: account?.name,
          typeOfOrganizer: account?.type_of_organizer,
        }

        setErrors(newErrors)

        if (setStep && (newErrors?.countrySpecificFields?.taxCode || newErrors?.countrySpecificFields?.vatNumber)) {
          setStep(1)
        }

        addNotification('error', intl.formatMessage({ id: 'error.could_not_create_an_account' }))
      }
    },
    [addNotification, intl, setStep, trackEvent]
  )

  const formik = useFormik<ISignupForm>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: SignupSchema,
  })

  return { formik, countryOptions }
}

export default useSignupForm
