/**
 * @generated SignedSource<<60cf557f69f3fd5eefc2e1bbdfd61bb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getSalesTaxQuery$variables = {
  amount: number;
  eventId: string;
  venueId: string;
};
export type getSalesTaxQuery$data = {
  readonly viewer: {
    readonly salesTaxCalculation: number | null;
  } | null;
};
export type getSalesTaxQuery = {
  response: getSalesTaxQuery$data;
  variables: getSalesTaxQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "venueId"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "amount",
      "variableName": "amount"
    },
    {
      "kind": "Variable",
      "name": "eventId",
      "variableName": "eventId"
    },
    {
      "kind": "Variable",
      "name": "venueId",
      "variableName": "venueId"
    }
  ],
  "kind": "ScalarField",
  "name": "salesTaxCalculation",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getSalesTaxQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "getSalesTaxQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f45cad6656d2ab37f379a70f90c3d866",
    "id": null,
    "metadata": {},
    "name": "getSalesTaxQuery",
    "operationKind": "query",
    "text": "query getSalesTaxQuery(\n  $amount: Int!\n  $venueId: ID!\n  $eventId: ID!\n) {\n  viewer {\n    salesTaxCalculation(amount: $amount, venueId: $venueId, eventId: $eventId)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec772f76bbb02b2703955198d87a9d53";

export default node;
