import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Loader, LoaderContainer } from '../../components/Loader'

interface IProps {
  find: string
  replace: string
}

const RedirectGate: FC<IProps> = ({ find, replace }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const path = window.location.pathname.replace(find, replace)
    navigate(path, { replace: true })
  }, [navigate, replace, find])

  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )
}

export default RedirectGate
