import React, { ComponentProps, FC, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { getOr, get, minBy, isNil, isEmpty, isArray, compose, some } from 'lodash/fp'
import { FormikErrors } from 'formik'
import { useIntl } from 'react-intl'
import { Dictionary } from 'ts-essentials'

import cn from 'classnames'
import { isPast, parseISO } from 'date-fns'
import { color, font, mediaQuery } from '../../../utils/variables'
import IconButton from '../../../components/IconButton'
import IEventFormTickets, { ITicketType } from '../types/Tickets'
import Svg from '../../../components/Svg'
import { CURRENCY } from '../../../utils/formatters/number'
import { TitleTooltip } from '../../../components/Tooltip'
import IdTag from '../../../components/IdTag'
import { EventCostCurrency } from '../../../enums.generated'

const Icon = styled.div`
  position: relative;
  border: 2px solid ${color.text};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 16px;
  margin-top: 3px;

  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }

  ${mediaQuery.lessThan('desktop')`
    display: none;
  `}
`

const Data = styled.div`
  flex: 1;
  font-size: ${font.size.md}px;
  line-height: 25px;
  text-align: right;

  & + & {
    margin-left: 8px;
  }

  & > div:first-child {
    font-size: ${font.size.sm}px;
    line-height: 17px;
    color: ${color.darkgrey};
    margin-bottom: 4px;
  }
`

const TicketTypePrice = styled.div`
  display: flex;
  justify-content: flex-end;

  &.-price-hidden {
    color: ${color.darkgrey};

    svg {
      color: ${color.black};
    }
  }

  &.-on-sale svg {
    color: ${color.error};
  }
`

const BigData = styled(Data)`
  flex: 2;
  text-align: left;

  ${mediaQuery.lessThan('desktop')`
    max-width: calc(100% - 100px);
  `}
`

const AlertSvg = styled(Svg)`
  margin-bottom: -6px;
  color: ${color.error};
  cursor: help;
`

const LinkSvg = styled(Svg)`
  display: inline-block;
  transform: translateY(3px);
  width: 20px;
  height: 20px;
`

const Flex = styled.div`
  display: flex;
  align-items: baseline;
  ${IdTag} {
    margin: -3px 0 -2px 4px;
  }
`

const TicketTypeRow = styled.li<{ isArchived?: boolean; draggable?: boolean }>`
  position: relative;
  display: flex;
  padding: 16px 0;
  ${({ draggable }) => (draggable !== false ? 'margin-left: 40px;' : null)}

  & > ${Data} {
    opacity: ${({ isArchived }) => (isArchived ? 0.5 : 1)};
  }

  & + & {
    border-top: 1px solid ${color.lightgrey};
  }

  ${mediaQuery.lessThan('tablet')`
    margin-right: 40px;
  `}

  ${mediaQuery.lessThan('desktopLarge')`
    & > ${Data} {
      display: none;
    }

    & > ${BigData} {
      display: block;
    }
  `}
`

const EditButton = styled(IconButton)`
  margin-left: 16px;
  ${({ outlineColor }) =>
    `color: ${getOr(color.text, outlineColor === 'red' ? 'error' : outlineColor || 'text', color)};`};
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 18px;
  left: -48px;

  color: ${color.grey};

  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};

  user-select: none;

  ${mediaQuery.lessThan<{ disabled?: boolean }>('tablet')`
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
    margin: 0;
    position: absolute;
    left: -40px;
    top: 18px;
  `}

  svg {
    pointer-events: none;
  }
`

const Buttons = styled.div`
  display: flex;

  ${mediaQuery.lessThan('tablet')`
    margin: 0;
    position: absolute;
    right: -40px;
  `}
`

const Name = styled.div`
  font-weight: bold;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AttractiveInfo = styled.div`
  display: inline-flex;
  margin-top: 4px;

  font-weight: normal;
  font-size: ${font.size.xs}px;
  line-height: 140%;

  border: 1px solid ${color.grey};
  border-radius: 4px;
  padding: 0 8px;

  div,
  span {
    white-space: nowrap;
  }

  & > div:first-child {
    display: inline-flex;
    border-right: 1px solid ${color.grey};
    padding-right: 8px;

    align-items: center;
    justify-content: center;
  }

  & > div:last-child {
    display: inline-flex;
    padding-left: 8px;
    flex-wrap: wrap;
    gap: 0 8px;
  }
`

interface IContainerProps<T> {
  ticketTypes: ReadonlyArray<T>
  soldOutMap: Dictionary<boolean>
  ticketTypeTotalSales: Dictionary<number>
  currency?: EventCostCurrency
  removeTicketType: (id: string) => void
  restoreTicketType: (id: string) => void
  copyTicketType: (id: string) => void
  showEditModal: (id: string) => void
  errors: FormikErrors<IEventFormTickets>
  modalId: null | string
  sortingDisabled: boolean
  removeDisabled: boolean
  copyDisabled: boolean
  isTicketPool: boolean
  isSeated: boolean
  isItalian: boolean
  eventOnSaleDate: string | null
  isShoppingCartEnabled: boolean
}

interface IElementProps<T> {
  tt: T
  currency?: EventCostCurrency
  idx: number
  noMove: boolean
  noRemove: boolean
  noCopy: boolean
  removeTicketType: (id: string) => void
  restoreTicketType: (id: string) => void
  copyTicketType: (id: string) => void
  showEditModal: (id: string) => void
  errors: FormikErrors<IEventFormTickets>
  modalOpen?: boolean
  isTicketPool: boolean
  isSeated: boolean
  isSoldOut: boolean
  hasSoldTickets: boolean
  isItalian: boolean
  eventOnSaleDate: string | null
  isShoppingCartEnabled: boolean
}

const SortableDragHandle = SortableHandle<{ disabled?: boolean }>(({ disabled }: { disabled?: boolean }) => (
  <DragHandle disabled={disabled}>
    <Svg icon="hamburger" />
  </DragHandle>
))

const SortableTicketTypeRow = SortableElement<IElementProps<ITicketType>>(
  ({
    tt,
    currency,
    isTicketPool,
    isSeated,
    idx,
    removeTicketType,
    restoreTicketType,
    copyTicketType,
    showEditModal,
    noMove,
    noRemove,
    noCopy,
    errors,
    modalOpen,
    isSoldOut,
    hasSoldTickets,
    isItalian,
    eventOnSaleDate,
    isShoppingCartEnabled,
  }: IElementProps<ITicketType>) => {
    const intl = useIntl()
    const onRemove = useCallback(() => removeTicketType(tt.id), [removeTicketType, tt.id])
    const onRestore = useCallback(() => restoreTicketType(tt.id), [restoreTicketType, tt.id])
    const onEdit = useCallback(() => showEditModal(tt.id), [showEditModal, tt.id])
    const onCopy = useCallback(() => copyTicketType(tt.id), [copyTicketType, tt.id])

    const formattedPrice = useMemo(() => {
      let price = compose([get('faceValue'), minBy('faceValue')])(tt.priceTiers || [])
      if (isNil(price)) {
        price = tt.faceValue
      }

      return price && intl.formatNumber((price || 0) / 100, CURRENCY(price, currency))
    }, [tt.priceTiers, tt.faceValue, currency, intl])

    const hasErrors = useMemo(
      () =>
        !modalOpen &&
        !tt.archived &&
        !isEmpty(errors.ticketTypes) &&
        (!isArray(errors.ticketTypes) || !!errors.ticketTypes[idx]),
      [errors.ticketTypes, idx, modalOpen, tt.archived]
    )

    const hasAttractiveInfo = useMemo(
      () =>
        isItalian &&
        (!!tt.attractiveSeatingAreaType ||
          !!tt.attractivePriceType ||
          some((pt) => !!pt?.attractivePriceType, tt.priceTiers || [])),
      [isItalian, tt.attractivePriceType, tt.attractiveSeatingAreaType, tt.priceTiers]
    )

    const isOnSale = useMemo(
      () => isPast(parseISO(tt.onSaleDate || eventOnSaleDate || '')),
      [eventOnSaleDate, tt.onSaleDate]
    )

    return (
      <TicketTypeRow
        className="draggable"
        isArchived={!!tt.archived}
        draggable={!isTicketPool}
        data-name={`ticketTypes.${idx}.innerError`}
      >
        {!isTicketPool && <SortableDragHandle disabled={noMove || !!tt.archived} />}
        <Icon style={{ opacity: tt.hidden ? '0.25' : '1' }}>
          <Svg icon={tt.archived ? 'trash' : tt.hidden ? 'eye-crossed' : `ticket-type-${tt.icon}`} />
        </Icon>
        <BigData>
          <Flex>
            <div>{intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.name.label' })}</div>
            <IdTag id={tt.id} successMessage={intl.formatMessage({ id: 'action.ticket_id_copied' })} />
          </Flex>
          <Name data-id={`ticketType[${idx}].name`}>
            <div>
              {isShoppingCartEnabled && tt.requiresOtherTypeIds && tt.requiresOtherTypeIds.length > 0 && (
                <TitleTooltip
                  title={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.has_required_tickets' })}
                >
                  <LinkSvg icon="link" />
                </TitleTooltip>
              )}
              {tt.name}
            </div>
            {hasAttractiveInfo && (
              <AttractiveInfo>
                <div>{tt.attractiveSeatingAreaType || '??'}</div>
                <div>
                  {!!tt.priceTierType ? (
                    <>{tt.priceTiers?.map((pt) => pt && <span key={pt.id}>{pt.attractivePriceType || '??'}</span>)}</>
                  ) : (
                    <span>{tt.attractivePriceType || '??'}</span>
                  )}
                </div>
              </AttractiveInfo>
            )}
          </Name>
        </BigData>
        {!isTicketPool && (
          <Data>
            <div>
              {intl.formatMessage({
                id: isSeated
                  ? 'new_event.tickets.ticket_types.list.max.label'
                  : 'new_event.tickets.ticket_types.list.allocation.label',
              })}
            </div>
            <div data-id={`ticketType[${idx}].allocation`}>
              {tt.archived && !isSoldOut && (
                <TitleTooltip title={intl.formatMessage({ id: 'archive_with_allocation_ticket_error.inline' })}>
                  <AlertSvg icon="warning" />
                </TitleTooltip>
              )}
              {tt.allocation || 0}
            </div>
          </Data>
        )}

        {isTicketPool && (
          <Data>
            <div>{intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.sales_limit.label' })}</div>
            <div data-id={`ticketType[${idx}].salesLimit`}>
              {tt.salesLimit ?? intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.sales_limit.none' })}
            </div>
          </Data>
        )}
        <Data>
          <div>{intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.price.label' })}</div>
          <TicketTypePrice
            data-id={`ticketType[${idx}].price`}
            className={cn({
              '-on-sale': isOnSale,
              '-price-hidden': tt.priceHidden,
            })}
          >
            {tt.priceHidden && (
              <TitleTooltip
                title={intl.formatMessage({
                  id: isOnSale
                    ? 'new_event.tickets.ticket_types.price_hidden.on_sale.tooltip'
                    : 'new_event.tickets.ticket_types.price_hidden.tooltip',
                })}
              >
                <Svg icon="eye-crossed" />
              </TitleTooltip>
            )}
            {formattedPrice || intl.formatMessage({ id: 'free' })}
            {tt.priceTiers && tt.priceTiers.length > 0 && '+'}
          </TicketTypePrice>
        </Data>
        <Buttons>
          <EditButton
            disabled={!!tt.archived}
            outlineColor={hasErrors ? 'red' : 'text'}
            icon="edit"
            onClick={onEdit}
            data-id={`editTicketTypeMobile[${idx}]`}
          />
          <IconButton
            title={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.copy_button' })}
            icon="copy"
            onClick={onCopy}
            data-id={`copyTicketType[${idx}]`}
            disabled={noCopy || !!tt.archived}
          />
          {tt.archived ? (
            <IconButton
              title={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.restore_button' })}
              icon="reload"
              onClick={onRestore}
              data-id={`restoreTicketType[${idx}]`}
            />
          ) : (
            <IconButton
              title={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.remove_button' })}
              icon="trash"
              onClick={onRemove}
              data-id={`removeTicketType[${idx}]`}
              disabled={noRemove || hasSoldTickets}
            />
          )}
        </Buttons>
      </TicketTypeRow>
    )
  }
)

const EventTicketTypeList: FC<IContainerProps<ITicketType>> = ({
  ticketTypes,
  currency,
  removeTicketType,
  restoreTicketType,
  copyTicketType,
  showEditModal,
  errors,
  modalId,
  sortingDisabled,
  removeDisabled,
  copyDisabled,
  isTicketPool,
  isSeated,
  soldOutMap,
  ticketTypeTotalSales,
  isItalian,
  eventOnSaleDate,
  isShoppingCartEnabled,
}) => {
  return (
    <ul>
      {ticketTypes.map(
        (tt, idx) =>
          tt && (
            <SortableTicketTypeRow
              key={tt.id}
              index={idx}
              idx={idx}
              tt={tt}
              eventOnSaleDate={eventOnSaleDate}
              isSoldOut={soldOutMap[tt.id]}
              hasSoldTickets={(ticketTypeTotalSales[tt.id] || 0) > 0}
              currency={currency}
              removeTicketType={removeTicketType}
              restoreTicketType={restoreTicketType}
              copyTicketType={copyTicketType}
              showEditModal={showEditModal}
              disabled={sortingDisabled || ticketTypes.length === 1}
              noMove={sortingDisabled || ticketTypes.length === 1}
              noRemove={removeDisabled}
              noCopy={copyDisabled}
              errors={errors}
              modalOpen={modalId === tt.id}
              isTicketPool={isTicketPool}
              isSeated={isSeated && !tt.isStream}
              isItalian={isItalian}
              isShoppingCartEnabled={isShoppingCartEnabled}
            />
          )
      )}
    </ul>
  )
}

export default SortableContainer<ComponentProps<typeof EventTicketTypeList>>(EventTicketTypeList)
