import graphql from 'babel-plugin-relay/macro'
import React, { FC, memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Badge from '../../../components/Badge'
import { authContext } from '../../../context/auth'
import { localeContext } from '../../../context/locale'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'

import { color, font, mediaQuery } from '../../../utils/variables'
import { EventHeaderDataBasic_event$key } from '../../../__generated__/EventHeaderDataBasic_event.graphql'
import { EventHeaderDataQuery } from '../../../__generated__/EventHeaderDataQuery.graphql'
import { EventHeaderData_event$key } from '../../../__generated__/EventHeaderData_event.graphql'
import EventPartStatus from '../../EventList/components/EventPartStatus'
import payoutStatus, { PAYOUTS_STATUS_LABELS } from '../../FinancePayouts/util/payoutStatus'
import EventControls from './parts/EventControls'
import EventImage from './parts/EventImage'
import EventProgress from './parts/EventProgress'

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const SBadge = styled(Badge)`
  max-width: unset;
`

const Wrapper = styled.div`
  position: relative;
  padding: 32px 32px 0;
  ${mediaQuery.lessThan('tablet')`
    padding: 0 16px 32px;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 22px;
  min-height: 100%;
  align-self: stretch;
  ${mediaQuery.lessThan('tablet')`
    margin: 0 16px 0 0;
  `}
  h1 {
    margin: 0 0 auto;
    line-height: 1.25em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  p {
    display: flex;
    flex-wrap: wrap;
    font-size: ${font.size.base}px;
    margin: 8px 0 0;

    ${mediaQuery.lessThan('tablet')`
      font-size: ${font.size.sm}px;
    `}
  }
`

const Row = styled.div`
  display: flex;
  flex-grow: 1;
  ${mediaQuery.lessThan('desktop')`
    flex-wrap: wrap;
    flex-direction: column;
  `}
`

const Col = styled.div<{ grow?: boolean }>`
  display: flex;
  flex: ${({ grow }) => (grow ? '2 0 0' : '1 0 0')};
  align-items: flex-end;
  ${mediaQuery.lessThan('desktop')`
    flex: auto;
  `}
  ${mediaQuery.lessThan('tablet')`
    display: block;
    padding: 0;
  `}
  & + & {
    margin-top: 24px;

    ${mediaQuery.lessThan('tablet')`
      margin-top: 16px;
    `}
  }
`

const GreyText = styled.span`
  color: ${color.darkgrey};
`

const EventTitle = styled.div`
  margin: auto 22px auto 0;
  word-break: break-word;

  ${mediaQuery.lessThan('tablet')`
    margin: 16px 22px 8px 0;
  `}
`

interface IProps {
  id: string
  isDraft: boolean
  needBalance: boolean
  stripeIsBroken: boolean
}

export const EventHeaderDataBasic: FC<{ event: EventHeaderDataBasic_event$key }> = ({ event: eventKey }) => {
  const { user } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment EventHeaderDataBasic_event on Event
      @argumentDefinitions(needBalance: { type: "Boolean!" }, isDraft: { type: "Boolean!" }) {
        id
        eventIdLive
        name
        previewToken
        timezoneName
        date
        endDate

        scheduleStatus
        autopaymentFailed
        selfPayoutBlockers
        feesBehaviour

        sales {
          totalPayoutValue(useCache: true)
          totalPromoterIncome(useCache: true)
        }

        billingPromoter {
          id
          name
        }

        venueName
        primaryVenue {
          id
          name
        }
        venues {
          id
          name
        }
        ...EventImage_event
        ...EventPartStatus_event @arguments(needBalance: $needBalance, isDraft: $isDraft)
      }
    `,
    eventKey
  )

  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const venue = useMemo(
    () =>
      event?.primaryVenue || (event?.venueName && { id: null, name: event?.venueName }) || event?.venues?.[0] || null,
    [event?.primaryVenue, event?.venueName, event?.venues]
  )

  const venueName = venue?.name || intl.formatMessage({ id: 'na' })
  const venueLink = user.diceStaff && venue?.id ? <Link to={`/venues/${venue.id}`}>{venue.name}</Link> : venueName

  const promoter =
    user.diceStaff && event?.billingPromoter ? (
      <Link to={`/promoters/${event.billingPromoter.id}`}>{event.billingPromoter.name}</Link>
    ) : (
      event?.billingPromoter?.name || intl.formatMessage({ id: 'na' })
    )

  const eventDate =
    event?.date &&
    intl.formatDate(event.date, {
      ...DATETIME_FORMATS.SHORT,
      timeZone: event.timezoneName || undefined,
    })

  const eventEndDate =
    event?.endDate &&
    intl.formatDate(event.endDate, {
      ...DATETIME_FORMATS.SHORT,
      timeZone: event.timezoneName || undefined,
    })

  const startTime =
    event?.date &&
    intl.formatDate(event.date, {
      ...DATETIME_FORMATS.TIME(locale),
      timeZone: event.timezoneName || undefined,
    })

  const endTime =
    event?.endDate &&
    intl.formatDate(event.endDate, {
      ...DATETIME_FORMATS.TIME(locale),
      timeZone: event.timezoneName || undefined,
    })

  const payStatus = payoutStatus(event)

  return (
    <>
      <EventImage event={event} />
      <Container>
        <EventTitle>
          <Flex>
            <EventPartStatus event={event} showHidden />
            {user.diceStaff && (
              <>
                <SBadge icon="dice-badge" color="tertiary">
                  {intl.formatMessage({ id: PAYOUTS_STATUS_LABELS[payStatus] })}
                </SBadge>

                {event.feesBehaviour === 'OVERRIDE' && (
                  <SBadge icon="dice-badge" color="tertiary">
                    {intl.formatMessage({ id: 'event_badge.overridden_fees' })}
                  </SBadge>
                )}
              </>
            )}
          </Flex>
          <h1>{event.name}</h1>
        </EventTitle>
        <p>
          <span className="nowrap">
            {eventDate}
            {eventDate !== eventEndDate && ` - ${eventEndDate}`}&nbsp;&nbsp;
          </span>
          <GreyText className="nowrap">
            {startTime} - {endTime}
          </GreyText>
        </p>
        <p>
          <span>{venueLink}&nbsp;&nbsp;</span>
          <GreyText className="nowrap">{promoter}</GreyText>
        </p>
      </Container>
    </>
  )
}

const EventHeaderData: FC<IProps> = ({ id, isDraft, needBalance, stripeIsBroken }) => {
  const { user } = useContext(authContext)

  const { node } = useLazyLoadQuery<EventHeaderDataQuery>(
    graphql`
      query EventHeaderDataQuery($id: ID!, $needBalance: Boolean!, $isDraft: Boolean!) {
        node(id: $id) {
          ...EventHeaderData_event @arguments(needBalance: $needBalance, isDraft: $isDraft)
        }
      }
    `,
    {
      id,
      needBalance,
      isDraft,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  )

  const event = useFragment<EventHeaderData_event$key>(
    graphql`
      fragment EventHeaderData_event on Event
      @argumentDefinitions(needBalance: { type: "Boolean!" }, isDraft: { type: "Boolean!" }) {
        id
        eventIdLive
        previewToken
        ...EventHeaderDataBasic_event @arguments(needBalance: $needBalance, isDraft: $isDraft)
        ...EventProgress_event
        ...EventControls_event @arguments(needBalance: $needBalance, isDraft: $isDraft)
      }
    `,
    node
  )

  const hasControls = user.diceStaff || !!event?.eventIdLive || !!event?.previewToken

  return !event ? null : (
    <Wrapper>
      {hasControls && <EventControls event={event} eventId={event.id} stripeIsBroken={stripeIsBroken} />}
      <Row>
        <Col grow>
          <EventHeaderDataBasic event={event} />
        </Col>
        <Col>
          <EventProgress event={event} />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default memo(EventHeaderData)
