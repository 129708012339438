import React, { FC, memo, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { useFormikContext } from 'formik'
import { compact, compose, concat, filter, map, reject, some, sortBy, sumBy } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { color } from '../../../utils/variables'
import { CURRENCY } from '../../../utils/formatters/number'
import { EventCostCurrency, FeesBehaviour } from '../../../enums.generated'

import IEventForm from '../types'
import { IFee, IProduct } from '../types/Extras'

import FeeBreakdownTooltip from '../../../components/Event/FeeBreakdownTooltip'
import Table, { Cell, Row, TableBody, TableHeader } from '../../../components/Table'

import useProductPriceBreakdown from '../hooks/useProductPriceBreakdown'
import { EXTRAS_TYPES_ORDER } from '../steps/Extras'

const Container = styled.div<{ isLoading?: boolean }>`
  padding: 6px 16px;
  background-color: ${color.palegrey};
  border-radius: 8px;
`

const TTRow = styled(Row)<{ isLoading?: boolean; isBordered?: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      filter: blur(3px);
    `}
  ${({ isBordered }) =>
    isBordered &&
    css`
      border-top: 1px solid ${color.black};
    `}
`

const StyledTable = styled(Table)`
  table-layout: initial;
  width: 100%;
  word-break: break-word;
  td {
    width: 14%;
    padding: 10px 0;
    white-space: initial;
  }
  td:first-child {
    width: 32%;
  }
  td + td {
    padding-left: 16px;
  }
`

const StyledTableHeader = styled(TableHeader)`
  border-bottom: 1px solid ${color.lightgrey};
  color: ${color.darkgrey};
`

const Clamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const SummaryRow: FC<
  React.PropsWithChildren<{
    item: IProduct
    itemIdx: number
    currency: EventCostCurrency | null
    venueIds: string[]
    eventId: string | null
    promoterId: string | null
    disableUsTax: boolean
    eventFees: Array<IFee | null> | null
    feesBehaviour: FeesBehaviour | null
    basePriceFees: Array<string | null> | null
    postFanPriceFees: Array<string | null> | null
    bordered: boolean
  }>
> = ({
  item,
  itemIdx,
  eventFees,
  // basePriceFees,
  // postFanPriceFees,
  // feesBehaviour,
  disableUsTax,
  currency,
  eventId,
  promoterId,
  venueIds,
  bordered,
}) => {
  const intl = useIntl()

  const breakdownInputs = useMemo(() => {
    return [
      {
        initialBreakdown: item.priceBreakdown,
        faceValue: item.faceValue || 0,
        categoryId: item.category?.value || null,
        fees: concat(eventFees || [], item.fees || []) || [],
      },
    ]
  }, [eventFees, item.category?.value, item.faceValue, item.fees, item.priceBreakdown])

  const breakdownCtx = useMemo(
    () => ({
      venueId: venueIds[0],
      // TODO: uncomment after fees override will be releases
      // eventId: feesBehaviour === 'OVERRIDE' ? null : eventId,
      // billingPromoterId: feesBehaviour === 'OVERRIDE' ? null : promoterId,
      // basePriceFees: feesBehaviour === 'OVERRIDE' ? basePriceFees : null,
      // postFanPriceFees: feesBehaviour === 'OVERRIDE' ? postFanPriceFees : null,

      // TODO: remove after fees override will be releases
      eventId: eventId,
      billingPromoterId: promoterId,
      basePriceFees: null,
      postFanPriceFees: null,

      disableUsTax,
    }),
    [disableUsTax, eventId, promoterId, venueIds]
  )

  const { loading, priceBreakdowns } = useProductPriceBreakdown(breakdownInputs, breakdownCtx)

  const hasRebate = useMemo(() => some('rebate', priceBreakdowns || []), [priceBreakdowns])

  const itemAllocation = useMemo(() => {
    switch (item?.category?.parentCategory?.type) {
      // case 'MERCH':
      //   return sumBy('allocation', item?.variants || [])
      case 'EXPERIENCE':
      default:
        return item.hasVariants ? sumBy('allocation', item?.variants || []) : item.allocation
    }
  }, [item])

  return (
    <>
      <TTRow isLoading={!!loading} data-id={`productSummary.product[${itemIdx}]`} isBordered={bordered}>
        <Cell bold data-id={`productSummary.product[${itemIdx}].name`}>
          <Clamp>{item.name}</Clamp>
        </Cell>

        <Cell textAlign="right" data-id={`productSummary.product[${itemIdx}].allocation`}>
          {itemAllocation || 0}
        </Cell>
        <Cell textAlign="right" data-id={`productSummary.product[${itemIdx}].price`}>
          {intl.formatNumber((item.faceValue || 0) / 100, CURRENCY(item.faceValue, currency))}
        </Cell>
        <Cell textAlign="right" data-id={`productSummary.product[${itemIdx}].fees`}>
          <FeeBreakdownTooltip
            trigger={intl.formatNumber(
              (priceBreakdowns[0]?.fees || 0) / 100,
              CURRENCY(priceBreakdowns[0]?.fees || 0, currency)
            )}
            currency={currency}
            priceBreakdown={priceBreakdowns[0]}
            hasRebate={hasRebate}
          />
        </Cell>
        <Cell textAlign="right" data-id={`productSummary.product[${itemIdx}].fanPrice`}>
          {intl.formatNumber(
            (priceBreakdowns[0]?.total || 0) / 100,
            CURRENCY(priceBreakdowns[0]?.total || 0, currency)
          )}
        </Cell>
      </TTRow>
    </>
  )
}

const EventProductSummary: FC<{ type: 'extras' | 'merch' }> = ({ type }) => {
  const intl = useIntl()
  const { values } = useFormikContext<IEventForm>()

  const products = useMemo(
    () =>
      compose([
        sortBy((product: IProduct) => {
          const idx = EXTRAS_TYPES_ORDER.indexOf(product?.category?.parentCategory?.type || '')
          return idx >= 0 ? idx : 99999999
        }),
        type === 'merch'
          ? filter((product: IProduct) => product?.category?.parentCategory?.type === 'MERCH')
          : reject((product: IProduct) => product?.category?.parentCategory?.type === 'MERCH'),
        reject((product: IProduct) => product.archived),
        compact,
      ])(values.products || []),
    [values.products, type]
  )

  const venueIds: string[] = useMemo(() => compact(map('value', values.venues || [])), [values.venues])

  return (
    <Container>
      <StyledTable>
        <StyledTableHeader>
          <Row>
            <Cell>{intl.formatMessage({ id: 'name' })}</Cell>
            <Cell textAlign="right">{intl.formatMessage({ id: 'quantity' })}</Cell>
            <Cell textAlign="right">{intl.formatMessage({ id: 'price' })}</Cell>
            <Cell textAlign="right">{intl.formatMessage({ id: 'fees' })}</Cell>
            <Cell textAlign="right">{intl.formatMessage({ id: 'fan_price' })}</Cell>
          </Row>
        </StyledTableHeader>
        <TableBody>
          {products.map(
            (product: IProduct, productIdx: number) =>
              product && (
                <SummaryRow
                  key={product.id}
                  item={product}
                  itemIdx={productIdx}
                  currency={values.costCurrency}
                  venueIds={venueIds}
                  eventId={values.id || null}
                  promoterId={values.billingPromoter?.value || null}
                  feesBehaviour={values.feesBehaviour || null}
                  basePriceFees={values.basePriceFees}
                  postFanPriceFees={values.postFanPriceFees}
                  disableUsTax={!!values.disableUsTax}
                  eventFees={values.fees || null}
                  bordered={
                    productIdx > 0 &&
                    product?.category?.parentCategory?.type !== products[productIdx - 1]?.category?.parentCategory?.type
                  }
                />
              )
          )}
        </TableBody>
      </StyledTable>
    </Container>
  )
}

export default memo(EventProductSummary)
