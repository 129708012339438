import { useMemo, useCallback, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import {
  find,
  get,
  reject,
  concat,
  debounce,
  flatten,
  compose,
  groupBy,
  toPairs,
  map,
  compact,
  set,
  sortBy,
  mapValues,
} from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'

import IEventFormInformation from '../types/Information'
import {
  ITrack,
  searchAppleMusicTrackImmediately,
  searchSpotifyTrackImmediately,
  TRACK_SEARCH_DEBOUNCE_MS,
} from '../../../utils/trackSearch'
import simplifyLabel from '../../../utils/simplifyLabel'
import { IOptions } from '../../../utils/graphqlOptionsLoader'

type ITrackOption = ITrack & { value?: string; label?: string; uid?: string }

function useMusicTrack(kind: 'spotifyTrack' | 'appleMusicTrack', onSelectCallback?: (track: ITrackOption) => void) {
  const { values, setFieldValue } = useFormikContext<IEventFormInformation>()
  const track: ITrackOption = useMemo(() => get('values', find(['type', kind], values.media)), [kind, values.media])

  const removeTrack = useCallback(
    () =>
      setFieldValue(
        'media',
        reject((m) => m && m.type.startsWith(kind), values.media || [])
      ),
    [setFieldValue, values.media, kind]
  )
  const selectTrack = useCallback(
    (_: any, track: ITrack & { value?: string; label?: string }) => {
      setFieldValue(
        'media',
        concat(values.media || [], {
          id: null,
          type: kind,
          values: {
            ...track,
            name: track.label || track.name,
          },
        })
      )

      if (onSelectCallback) onSelectCallback(track)
    },
    [setFieldValue, values.media, kind, onSelectCallback]
  )
  return { track, selectTrack, removeTrack }
}

interface ISearchResult {
  value: string
  label: string
  tracks: Array<ITrack & { source: 'appleMusic' | 'spotify' }>
}

type ISearchCallback = (value: Array<ISearchResult>) => void

export default function useInformationMusic() {
  const environment = useRelayEnvironment()

  const {
    track: appleMusicTrack,
    selectTrack: selectAppleMusicTrack,
    removeTrack: removeAppleMusic,
  } = useMusicTrack('appleMusicTrack')

  const {
    track: spotifyTrack,
    selectTrack: selectSpotifyTrack,
    removeTrack: removeSpotify,
  } = useMusicTrack('spotifyTrack')

  const searchTracksNoDebounce = useCallback(
    (str: string, callback: ISearchCallback) => {
      const resultsPromise = Promise.all([
        new Promise<IOptions>((resolve) => searchAppleMusicTrackImmediately(environment, str, resolve)),
        new Promise<IOptions>((resolve) => searchSpotifyTrackImmediately(environment, str, resolve)),
      ])

      const groupResults = compose([
        callback,
        sortBy('label'),
        map(([k, v]) => {
          const tracks = compact([find(['source', 'spotify'], v), find(['source', 'appleMusic'], v)])
          return {
            value: k,
            label: tracks[0].label,
            tracks,
          }
        }),
        toPairs,
        mapValues(sortBy('label')),
        groupBy((t: ITrackOption) => t.label && simplifyLabel(t.label)),
        flatten,
        ([appleMusic, spotify]) => [
          map(set('source', 'appleMusic'), appleMusic),
          map(set('source', 'spotify'), spotify),
        ],
      ])

      resultsPromise.then(groupResults)
    },
    [environment]
  )

  const searchTracks = useMemo(
    () => debounce(TRACK_SEARCH_DEBOUNCE_MS, searchTracksNoDebounce),
    [searchTracksNoDebounce]
  )

  const [queue, setQueue] = useState<{ uid: string | null; kind: string; value: ITrackOption | null }[]>([])

  const [trackWarn, setTrackWarn] = useState<null | string>(null)

  const setTracks = useCallback((uid: string | null, selection: ISearchResult | null) => {
    const newAppleMusicTrack = find(['source', 'appleMusic'], selection?.tracks || [])
    setQueue(concat({ uid, kind: 'appleMusic', value: newAppleMusicTrack ? (newAppleMusicTrack as ITrack) : null }))

    const newSpotifyTrack = find(['source', 'spotify'], selection?.tracks || [])
    setQueue(concat({ uid, kind: 'spotify', value: newSpotifyTrack ? (newSpotifyTrack as ITrack) : null }))

    if (uid && newAppleMusicTrack && !newSpotifyTrack) {
      searchSpotifyTrackImmediately(environment, uid, (t) => {
        const track = find((it) => simplifyLabel(it.label) === uid, t)
        if (track) {
          setQueue(concat({ uid: uid as string | null, kind: 'spotify', value: track as any }))
        } else {
          setTrackWarn('apple_only')
        }
      })
    } else if (uid && !newAppleMusicTrack && newSpotifyTrack) {
      searchAppleMusicTrackImmediately(environment, uid, (t) => {
        const track = find((it) => simplifyLabel(it.label) === uid, t)
        if (track) {
          setQueue(concat({ uid: uid as string | null, kind: 'appleMusic', value: track as any }))
        } else {
          setTrackWarn('spotify_only')
        }
      })
    } else {
      setTrackWarn(null)
    }
  }, [environment])

  useEffect(() => {
    if (queue.length === 0) return
    const [{ uid, kind, value }, ...tail] = queue

    if (kind === 'spotify') {
      if (value) {
        selectSpotifyTrack(null, set('uid', uid, value))
      } else {
        removeSpotify()
      }
    } else if (kind === 'appleMusic') {
      if (value) {
        selectAppleMusicTrack(null, set('uid', uid, value))
      } else {
        removeAppleMusic()
      }
    }

    setQueue(tail)
  }, [queue, removeAppleMusic, removeSpotify, selectAppleMusicTrack, selectSpotifyTrack])

  const removeTracks = useCallback(() => setTracks(null, null), [setTracks])

  const doSelectAppleMusicTrack = useCallback(
    (id: any, val: any) => {
      selectAppleMusicTrack(id, val)
      setTrackWarn(null)
    },
    [selectAppleMusicTrack]
  )

  const doSelectSpotifyTrack = useCallback(
    (id: any, val: any) => {
      selectSpotifyTrack(id, val)
      setTrackWarn(null)
    },
    [selectSpotifyTrack]
  )

  const doRemoveAppleMusic = useCallback(() => {
    removeAppleMusic()
    setTrackWarn(null)
  }, [removeAppleMusic])

  const doRemoveSpotify = useCallback(() => {
    removeSpotify()
    setTrackWarn(null)
  }, [removeSpotify])

  return {
    searchTracks,
    spotifyTrack,
    appleMusicTrack,
    setTracks,
    removeTracks,
    removeAppleMusic: doRemoveAppleMusic,
    removeSpotify: doRemoveSpotify,
    selectAppleMusicTrack: doSelectAppleMusicTrack,
    selectSpotifyTrack: doSelectSpotifyTrack,
    trackWarn,
  }
}
