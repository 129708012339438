import React, { FC, memo, useCallback, useContext, useEffect, useRef, useState, ReactNode, useMemo } from 'react'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { notificationContext } from '../context/notification'
import copyToClipboard from '../utils/copyToClipboard'
import { color } from '../utils/variables'
import Icon from './Svg'
import IconButton from './IconButton'
import { Loader } from './Loader'
import { TitleTooltip } from './Tooltip'

const StyledLink = styled.a`
  display: block;
  color: ${color.primary};
  word-break: break-all;
  white-space: pre-wrap;

  ${Loader} {
    margin-bottom: -4px;
  }
`

const StyledIcon = styled(Icon)`
  margin-bottom: -4px;
`

interface IProps {
  style?: 'button' | 'link'
  link: string
  title: ReactNode
  successMessage?: string
  errorMessage?: string
  icon?: string
  className?: string
  titlePlacement?: string
  titleSuccess?: string
}

const CopyButton: FC<React.PropsWithChildren<IProps>> = ({
  style = 'button',
  link,
  title,
  successMessage,
  errorMessage,
  icon,
  className,
  titlePlacement,
  children,
  titleSuccess,
}) => {
  const { addNotification } = useContext(notificationContext)
  const [loading, setLoading] = useState(false)

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const [done, setDone] = useState(false)
  const resetDone = useCallback(() => setDone(false), [])

  const tooltipId = useMemo(() => (title ? nanoid() : undefined), [title])

  const doCopy = useCallback(() => {
    setLoading(true)
    copyToClipboard(link)
      .then(() => {
        if (successMessage) {
          addNotification('success', successMessage)
        }
      })
      .catch((err) => {
        console.error(err)
        addNotification('error', errorMessage || err.message)
      })
      .finally(() => {
        if (mounted.current) {
          setTimeout(() => {
            setLoading(false)
            setDone(true)
          }, 350)
        }
      })
  }, [link, successMessage, addNotification, errorMessage])

  const linkContent = (
    <StyledLink onClick={doCopy}>
      {children}{' '}
      {loading ? (
        <Loader className="icon-button_loader" size="small" />
      ) : (
        <StyledIcon icon="copy" width={20} height={20} />
      )}
    </StyledLink>
  )

  const copyLink = title ? (
    <TitleTooltip id={tooltipId} title={done ? titleSuccess : title} placement={titlePlacement || 'top'}>
      {linkContent}
    </TitleTooltip>
  ) : (
    linkContent
  )

  return style === 'button' ? (
    <IconButton
      className={className}
      icon={icon || 'copy'}
      onClick={doCopy}
      loading={loading}
      title={done ? titleSuccess : title}
      titlePlacement={titlePlacement}
      onTitleVisibleChange={resetDone}
    />
  ) : (
    copyLink
  )
}

export default styled(memo(CopyButton))``
