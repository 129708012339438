/**
 * @generated SignedSource<<5586799b6b190875c8fa8ff6b1e1d857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventReviewStatus = "ESCALATED" | "ON_HOLD" | "%future added value";
export type UpdateEventReviewInput = {
  assigneeId?: string | null;
  clientMutationId: string;
  eventId: string;
  priority?: boolean | null;
  status?: EventReviewStatus | null;
};
export type EventWorkflowEventReviewMutation$variables = {
  input: UpdateEventReviewInput;
};
export type EventWorkflowEventReviewMutation$data = {
  readonly updateEventReview: {
    readonly messages: ReadonlyArray<{
      readonly message: string | null;
    } | null> | null;
    readonly result: {
      readonly event: {
        readonly id: string;
        readonly lockVersion: number;
        readonly previewToken: string | null;
      } | null;
      readonly id: string;
      readonly priority: boolean | null;
      readonly status: EventReviewStatus | null;
    } | null;
    readonly successful: boolean;
  } | null;
};
export type EventWorkflowEventReviewMutation = {
  response: EventWorkflowEventReviewMutation$data;
  variables: EventWorkflowEventReviewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateEventReviewPayload",
    "kind": "LinkedField",
    "name": "updateEventReview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EventReview",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priority",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewToken",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventWorkflowEventReviewMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventWorkflowEventReviewMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0f4d1d62ac064e625e9038341ce06be8",
    "id": null,
    "metadata": {},
    "name": "EventWorkflowEventReviewMutation",
    "operationKind": "mutation",
    "text": "mutation EventWorkflowEventReviewMutation(\n  $input: UpdateEventReviewInput!\n) {\n  updateEventReview(input: $input) {\n    successful\n    messages {\n      message\n    }\n    result {\n      id\n      priority\n      status\n      event {\n        id\n        lockVersion\n        previewToken\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d12c8c3082e0450d7045b5d48a1cb3b";

export default node;
