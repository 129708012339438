/**
 * @generated SignedSource<<5dc5d7c4d7a84b78ee58cca971e81e8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PinnedEventQuery$variables = {
  id: string;
};
export type PinnedEventQuery$data = {
  readonly event: {
    readonly date?: string | null;
    readonly endDate?: string | null;
    readonly eventImages?: ReadonlyArray<{
      readonly cdnUrl: string;
      readonly type: string | null;
    } | null> | null;
    readonly id?: string;
    readonly name?: string | null;
    readonly timezoneName?: string | null;
  } | null;
};
export type PinnedEventQuery = {
  response: PinnedEventQuery$data;
  variables: PinnedEventQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezoneName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cdnUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PinnedEventQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventImage",
                "kind": "LinkedField",
                "name": "eventImages",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PinnedEventQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventImage",
                "kind": "LinkedField",
                "name": "eventImages",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90d5f380f7cdda79863cef9b78046a99",
    "id": null,
    "metadata": {},
    "name": "PinnedEventQuery",
    "operationKind": "query",
    "text": "query PinnedEventQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ... on Event {\n      id\n      name\n      date\n      endDate\n      timezoneName\n      eventImages {\n        type\n        cdnUrl\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c5a7a6f1cdc4c218622b84c8038ecda";

export default node;
