import { useMemo } from 'react'
import { Dictionary } from 'ts-essentials'
import { find } from 'lodash/fp'
import { useFormikContext } from 'formik'

import IEventFormTickets from '../types/Tickets'

function useTicketPoolTotalSales(): Dictionary<number> {
  const { values } = useFormikContext<IEventFormTickets>()

  const ticketPoolTotalSales = useMemo(() => {
    const pools: Dictionary<number> = {}

    ;(values.sales?.ticketTypesBreakdown || []).forEach((ttb) => {
      if (!ttb || !ttb.ticketTypeId) return

      const tty = find(['id', ttb.ticketTypeId], values.ticketTypes)
      const poolId = tty?.ticketPoolId

      if (!poolId) return

      const totalSold = ttb.totalAppSold + ttb.totalPosSold
      pools[poolId] = (pools[poolId] || 0) + totalSold
    })

    return pools
  }, [values.sales?.ticketTypesBreakdown, values.ticketTypes])

  return ticketPoolTotalSales
}

export default useTicketPoolTotalSales
