import React, { FC, memo, useMemo } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { useIntl } from 'react-intl'
import cn from 'classnames'

import { color, mediaQuery } from '../utils/variables'
import InitialsAvatar from './InitialsAvatar'

const AccountWrapper = styled(NavLink)<{ v2?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  &.-active {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -24px;
      height: 24px;
      width: 2px;
      background: currentColor;
      ${mediaQuery.lessThan('desktopLarge')`
        left: -16px;
      `}
      ${mediaQuery.lessThan('tablet')`
        left: -24px;
      `}
    }
  }
  &.-active,
  &:hover {
    color: ${({ v2 }) => (v2 ? 'inherit' : color.primary)};
  }

  ${({ v2 }) => v2 && 'margin-left: 2px;'}
`

const AccountTitleLegacy = styled.span`
  margin-left: 8px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.8, 0, 0.3, 1);
  transition-delay: 150ms;
  ${mediaQuery.between('769px', '1200px')`
    .sidebar:not(.expanded) & {
      opacity: 0;
    }
  `}
  body.compact-sidebar .sidebar:not(.expanded) & {
    ${mediaQuery.greaterThan('tablet')`
      & {
        opacity: 0;
      }
    `}
  }
  ${mediaQuery.lessThan('tablet')`
    & span {
      opacity: 1;
    }
  `}
`

const AccountTitle = styled.span`
  margin-left: 10px;
  transition: opacity 0.1s cubic-bezier(0.8, 0, 0.3, 1);
  transition-delay: 0;
  ${mediaQuery.between('769px', '1200px')`
    .sidebar:not(.expanded) & {
      transition: opacity 0.3s cubic-bezier(0.8, 0, 0.3, 1);
      opacity: 0;
    }
  `}
  body.compact-sidebar .sidebar:not(.expanded) & {
    ${mediaQuery.greaterThan('tablet')`
      & {
        transition: opacity 0.3s cubic-bezier(0.8, 0, 0.3, 1);
        opacity: 0;
      }
    `}
  }
  ${mediaQuery.lessThan('tablet')`
    & span {
      opacity: 1;
    }
  `}
`

interface IProps {
  className?: string
  imageSrc?: string | null
  name?: string | null
  accountName?: string | null
  to?: string | null
  noTitle?: boolean
  v2?: boolean
}

const SidebarAccount: FC<IProps> = ({ className, imageSrc, name, accountName, to, noTitle, v2 }) => {
  const intl = useIntl()
  const accountInitials = useMemo(() => {
    if (!name) return '?'
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .substring(0, 2)
      .toUpperCase()
  }, [name])

  const body = (
    <>
      <InitialsAvatar size={v2 ? 'accountv2' : 'account'} circleStyle="outline">
        {accountInitials}
      </InitialsAvatar>
      {!noTitle &&
        (v2 ? (
          <AccountTitle>{accountName || intl.formatMessage({ id: 'account' })}</AccountTitle>
        ) : (
          <AccountTitleLegacy>{accountName || intl.formatMessage({ id: 'account' })}</AccountTitleLegacy>
        ))}
    </>
  )

  if (to) {
    return (
      <AccountWrapper
        to={to}
        // eslint-disable-next-line react/jsx-no-bind
        className={({ isActive }) => (isActive ? cn(className, '-active') : className)}
        v2={v2}
      >
        {body}
      </AccountWrapper>
    )
  }

  return !noTitle ? (
    <AccountWrapper as="div" className={className} v2={v2}>
      {body}
    </AccountWrapper>
  ) : (
    <div className={className}>{body}</div>
  )
}

export default styled(memo(SidebarAccount))``
