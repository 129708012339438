import React, { FC, memo, useCallback, useContext } from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import { authContext } from '../../../context/auth'
import { trackingContext } from '../../../context/tracking'

import { SidebarFooter } from '../../Sidebar/components/Footer'
import Button from '../../../components/Button'
import IconButton from '../../../components/IconButton'

interface IProps {
  expanded?: boolean | null
}
const Footer: FC<IProps> = ({ expanded }) => {
  const intl = useIntl()
  const { trackEvent } = useContext(trackingContext)
  const { logOut } = useContext(authContext)

  const onLogout = useCallback(() => {
    trackEvent('logout_clicked')
    logOut()
  }, [logOut, trackEvent])

  return (
    <SidebarFooter>
      <ButtonContainer>
        <StyledButton icon="signout" onClick={onLogout} expanded={expanded}>
          {intl.formatMessage({ id: 'sign_out' })}
        </StyledButton>
        <StyledIconButton icon="signout" onClick={onLogout} expanded={expanded}>
          {intl.formatMessage({ id: 'sign_out' })}
        </StyledIconButton>
      </ButtonContainer>
    </SidebarFooter>
  )
}

export default memo(Footer)

const ButtonContainer = styled.div`
  position: relative;
  height: 40px;
`

const StyledButton = styled(Button)<{ expanded?: boolean | null }>`
  transition: opacity 200ms;
  position: absolute;
  opacity: 0;

  ${({ expanded }) =>
    expanded &&
    `
    opacity: 1;
  `}
`

const StyledIconButton = styled(IconButton)<{ expanded?: boolean | null }>`
  transition: opacity 200ms;
  position: absolute;
  opacity: 1;

  ${({ expanded }) =>
    expanded &&
    `
    opacity: 0;
  `}
`
