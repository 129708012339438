import React, { ComponentType } from 'react'
import { Schema } from 'yup'
import { ContainerProps } from 'react-relay'
import { map } from 'lodash/fp'

import Basics from '../steps/Basics'
import Timeline from '../steps/Timeline'
import Information from '../steps/Information'
import Tickets from '../steps/Tickets'
import Extras from '../steps/Extras'
import Merch from '../steps/Merch'
import Settings from '../steps/Settings'

import BasicsSchema from '../validation/Basics'
import TimelineSchema from '../validation/Timeline'
import InformationSchema from '../validation/Information'
import TicketsSchema from '../validation/Tickets'
import ExtrasSchema from '../validation/Extras'
import SettingsSchema from '../validation/Settings'

export interface IFormStep {
  event: null
  readOnly: boolean
  viewer: any
}

export type IFormStepConfig = Array<{
  slug: string
  component: ComponentType<
    React.PropsWithChildren<ContainerProps<IFormStep> & { componentRef?: (ref: any) => void } & any>
  >
  schema: Schema<object>
  badge?: string
}>

const STEPS: IFormStepConfig = [
  { slug: 'basics', component: Basics, schema: BasicsSchema },
  { slug: 'timeline', component: Timeline, schema: TimelineSchema },
  { slug: 'information', component: Information, schema: InformationSchema },
  { slug: 'tickets', component: Tickets, schema: TicketsSchema },
  { slug: 'extras', component: Extras, schema: ExtrasSchema, badge: 'new' },
  { slug: 'merch', component: Merch, schema: ExtrasSchema, badge: 'beta' },
  { slug: 'settings', component: Settings, schema: SettingsSchema },
]

export default STEPS

export const STEP_COUNT = STEPS.length

export const STEP_SLUGS = map('slug', STEPS)
