import graphql from 'babel-plugin-relay/macro'
import { compact, keyBy } from 'lodash/fp'
import { useMemo } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { Dictionary } from 'ts-essentials'
import { useRevenueReportQuery } from '../../../__generated__/useRevenueReportQuery.graphql'
import { useRevenueReportQuery_event$key } from '../../../__generated__/useRevenueReportQuery_event.graphql'
import {
  useRevenueReportQuery_viewer$key,
  useRevenueReportQuery_viewer$data,
} from '../../../__generated__/useRevenueReportQuery_viewer.graphql'

export type IDataPoint = NonNullable<NonNullable<useRevenueReportQuery_viewer$data['tickets']>[number]>

function useRevenueReport(eventId: string) {
  const { event: eventKey, viewer: viewerKey } = useLazyLoadQuery<useRevenueReportQuery>(
    graphql`
      query useRevenueReportQuery($eventId: ID!) {
        viewer {
          ...useRevenueReportQuery_viewer @arguments(eventId: $eventId)
        }
        event: node(id: $eventId) {
          ...useRevenueReportQuery_event
        }
      }
    `,
    { eventId },
    { fetchPolicy: 'store-and-network' }
  )

  const event = useFragment<useRevenueReportQuery_event$key>(
    graphql`
      fragment useRevenueReportQuery_event on Event {
        costCurrency
        timezoneName
        onSaleDate
        offSaleDate
        billingPromoter {
          extrasEnabled
          merchEnabled
        }
        sales {
          totalSold
          totalPayoutValue
        }
        productsSales {
          productBreakdown {
            product {
              rootType
            }
            totalSold
            totalFaceValue
          }
        }
        allocation
        products {
          rootType
          allocation
          faceValue
        }
        ticketTypes {
          faceValue
          priceTiers {
            faceValue
          }
        }
      }
    `,
    eventKey
  )

  const viewer = useFragment<useRevenueReportQuery_viewer$key>(
    graphql`
      fragment useRevenueReportQuery_viewer on Viewer @argumentDefinitions(eventId: { type: "ID!" }) {
        # TODO: views data

        tickets: revenueReport(eventId: $eventId) {
          time
          faceValue
          sold: soldTickets
        }

        extras: productsRevenueReport(eventId: $eventId, rootType: EXTRAS) {
          time
          faceValue
          sold: soldItems
        }

        merch: productsRevenueReport(eventId: $eventId, rootType: MERCH) {
          time
          faceValue
          sold: soldItems
        }
      }
    `,
    viewerKey
  )

  const transformFn = <T extends IDataPoint | { time: string; views: number }>(arr: ReadonlyArray<null | T>) =>
    keyBy('time', compact(arr || []) as T[])

  const result = useMemo(
    () =>
      ({
        event,
        data: {
          tickets: transformFn(viewer?.tickets || []),
          extras: transformFn(viewer?.extras || []),
          merch: transformFn(viewer?.merch || []),

          // TODO: real data
          views: transformFn([]) as Dictionary<{ time: string; views: number }>,
        },
      } as const),
    [event, viewer?.extras, viewer?.merch, viewer?.tickets]
  )

  return result
}

export type IRevenueReport = ReturnType<typeof useRevenueReport>

export default useRevenueReport
