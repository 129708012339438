/**
 * @generated SignedSource<<bb663dcc5c3e23fe40aae3951c0a7235>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountMembershipType = "COLLABORATORS" | "MEMBER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useSwitchAccount_viewer$data = {
  readonly availableAccounts: ReadonlyArray<{
    readonly account: {
      readonly addressCountry: string;
      readonly allowSkipReview: boolean;
      readonly automaticRollingPaymentsEnabled: boolean | null;
      readonly countryCode: string | null;
      readonly extrasEnabled: boolean | null;
      readonly forbidSelfPayouts: boolean | null;
      readonly id: string;
      readonly isDisabled: boolean | null;
      readonly merchEnabled: boolean | null;
      readonly name: string;
    };
    readonly membershipType: AccountMembershipType;
    readonly permissionProfile: {
      readonly caption: string;
      readonly id: string;
      readonly roleName: string;
    };
  } | null>;
  readonly " $fragmentType": "useSwitchAccount_viewer";
};
export type useSwitchAccount_viewer$key = {
  readonly " $data"?: useSwitchAccount_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSwitchAccount_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSwitchAccount_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountAvailability",
      "kind": "LinkedField",
      "name": "availableAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allowSkipReview",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extrasEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "merchEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "addressCountry",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countryCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDisabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forbidSelfPayouts",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "automaticRollingPaymentsEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membershipType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PermissionProfile",
          "kind": "LinkedField",
          "name": "permissionProfile",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "caption",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "313fe37a7b50e8b40b02503b8372f4a3";

export default node;
