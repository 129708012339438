import React, { ComponentType, ReactNode, Suspense, useEffect, useState, useTransition } from 'react'
import { isEqual, omit } from 'lodash/fp'
import { Loader, LoaderContainer } from './Loader'

export interface ILoadable {
  loading?: boolean
}

interface IProps<P extends ILoadable> {
  fallback?: ReactNode
  component: ComponentType<P>
  props: Omit<P, 'loading'>
  setLoading?: (loading: boolean) => void
}

const StaleSuspense = <P extends ILoadable>({
  component: Cmp,
  props: upstreamProps,
  fallback,
  setLoading,
}: IProps<P>) => {
  const [props, setProps] = useState<Omit<P, 'loading'>>(() => omit(['loading'])(upstreamProps) as Omit<P, 'loading'>)

  const [loading, startTransition] = useTransition()

  useEffect(() => {
    if (isEqual(upstreamProps, props)) return

    startTransition(() => {
      setProps(upstreamProps)
    })
  }, [props, upstreamProps])

  useEffect(() => {
    if (!setLoading) return
    setLoading(loading)
  }, [loading, setLoading])

  return (
    <Suspense
      fallback={
        fallback || (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
      }
    >
      <Cmp {...(props as P)} loading={loading} />
    </Suspense>
  )
}

export default StaleSuspense
