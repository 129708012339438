import { useCallback, useContext } from 'react'
import { commitMutation, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { isArray } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { notificationContext } from '../../../context/notification'
import { authContext } from '../../../context/auth'
import { useInviteUserMutation } from '../../../__generated__/useInviteUserMutation.graphql'

function useInviteUser() {
  const intl = useIntl()
  const { account } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)
  const environment = useRelayEnvironment()

  const inviteUser = useCallback(
    (profileId: string, email: string) => {
      if (!account) return Promise.reject(new Error('No account specified'))

      return new Promise<null | NonNullable<useInviteUserMutation['response']['inviteUser']>['result']>(
        (resolve, rejectPromise) =>
          commitMutation<useInviteUserMutation>(environment, {
            mutation: graphql`
              mutation useInviteUserMutation($input: InviteUserInput!) {
                inviteUser(input: $input) {
                  messages {
                    code
                    field
                    message
                  }
                  result {
                    id
                    email
                    status
                    user {
                      id
                      name
                    }
                    permissionProfile {
                      usedBy
                    }
                  }
                  successful
                }
              }
            `,
            variables: {
              input: {
                clientMutationId: nanoid(),
                email,
                permissionProfileId: profileId,
                accountId: account?.id,
              },
            },
            onCompleted: (data, errors) => {
              const payload = data.inviteUser
              if (errors || (payload?.messages && payload.messages.length > 0) || !payload?.successful) {
                rejectPromise(errors || payload?.messages || [])
                return
              }
              resolve(payload.result)
            },
            onError: rejectPromise,
          })
      ).catch((err) => {
        console.error('Error inviting user', email, err)
        if (isArray(err)) {
          err.forEach((e) => addNotification('error', `${e.message} (${email})`))
        } else {
          addNotification('error', `${intl.formatMessage({ id: 'users.invite_user_error' })} (${email})`)
        }
        return null
      })
    },
    [account, addNotification, environment, intl]
  )

  return inviteUser
}

export default useInviteUser
