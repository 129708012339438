import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { OnDesktop } from '../../components/Breakpoints'
import { NOTES_PANEL_WIDTH } from '../../components/NotesPanel'
import { transition, zIndex } from '../../utils/variables'
import EventNotesView from './components/EventNotesView'
import eventNotesContext, { INotesEvent } from './context/eventNotes'
import { authContext } from '../../context/auth'

interface IProps {
  eventId?: string | null
  notesOpenByDefault?: boolean
}

const EventNotesPageWrapper: FC<React.PropsWithChildren<IProps>> = ({ eventId, notesOpenByDefault, children }) => {
  const { user } = useContext(authContext)
  const [notesOpen, setNotesOpen] = useState<boolean>(notesOpenByDefault || false)

  const toggleNotesOpen = useCallback(() => {
    setNotesOpen(!notesOpen)
  }, [notesOpen])

  useEffect(() => {
    if (!user.diceStaff) return
    if (notesOpen) {
      document.body.classList.add('compact-sidebar')
    } else {
      document.body.classList.remove('compact-sidebar')
    }
    return () => {
      document.body.classList.remove('compact-sidebar')
    }
  }, [notesOpen, user.diceStaff])

  const [promoterIds, setPromoterIds] = useState<Array<string>>([])
  const [venueIds, setVenueIds] = useState<Array<string>>([])
  const [notesEvent, setNotesEvent] = useState<INotesEvent>({
    id: eventId,
  })

  return user.diceStaff ? (
    <eventNotesContext.Provider
      value={{
        notesOpen,
        toggleNotesOpen,
        promoterIds,
        venueIds,
        event: notesEvent,
        setPromoterIds,
        setVenueIds,
        setEvent: setNotesEvent,
      }}
    >
      <BladeContainer>
        <LeftSide bladeOpen={notesOpen}>{children}</LeftSide>
        <OnDesktop>
          <Notes open={notesOpen}>
            <EventNotesView />
          </Notes>
        </OnDesktop>
      </BladeContainer>
    </eventNotesContext.Provider>
  ) : (
    <>{children}</>
  )
}

export default EventNotesPageWrapper

const BladeContainer = styled.div`
  display: flex;
  min-height: 100vh;
`

const LeftSide = styled.div<{ bladeOpen: boolean }>`
  flex-grow: 1;
  transition-delay: ${transition.sidebar.transitionDelay};
  transition: ${transition.sidebar.transition('width')};
  width: 100%;

  ${({ bladeOpen }) =>
    bladeOpen &&
    `
      width: calc(100% - ${NOTES_PANEL_WIDTH}px);
  `}
`

const Notes = styled.div<{ open: boolean }>`
  box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.1);
  min-width: 0px;
  width: 0px;
  height: 100vh;
  position: sticky;
  top: 0;
  transition-delay: ${transition.sidebar.transitionDelay};
  transition: ${transition.sidebar.transition(['width', 'min-width'])};
  overflow: hidden;
  z-index: ${zIndex.sidebar};

  ${({ open }) =>
    open &&
    `
      min-width: ${NOTES_PANEL_WIDTH}px;
      width: ${NOTES_PANEL_WIDTH}px;
  `}

  .-impersonating & {
    height: calc(100vh - 66px);
    position: sticky;
    top: 62px;
  }
`
