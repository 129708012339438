import React, { FC, memo, useState, useCallback, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { FileRejection, useDropzone } from 'react-dropzone'
import { concat, noop, some } from 'lodash/fp'

import FormGroup from './FormGroup'
import Svg from './Svg'
import { notificationContext } from '../context/notification'
import useFileDragIncoming from '../utils/hooks/useFileDragIncoming'
import { OnDesktop, OnMobile } from './Breakpoints'
import {
  ImagePreview,
  ImagePreviewWrapper,
  RemoveButton,
  UploadButton,
  Uploader,
  UploadFileName,
  UploadInfoPanel,
} from './UploaderStyles'

interface IProps {
  name: string
  mimeType: string | string[]
  label: string
  minSizeLabel: any
  fileFormatLabel: any
  existingFileLabel: string
  values: any
  setFieldValue: (key: string, value: any) => void
  fileFormatErrorSuggestion: string
  allowEdit?: boolean
  required?: boolean
  error?: any
  mobileUploadPrompt?: string
  desktopUploadPrompt?: string
  onFileNameChange?: (name: string) => void
}

const SimpleUploader: FC<React.PropsWithChildren<IProps>> = ({
  existingFileLabel,
  mimeType,
  label,
  minSizeLabel,
  fileFormatLabel,
  name,
  values,
  setFieldValue,
  fileFormatErrorSuggestion,
  mobileUploadPrompt,
  desktopUploadPrompt,
  error,
  allowEdit = true,
  required = false,
  onFileNameChange = noop,
}) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)

  const [fileName, setFileName] = useState('')

  const image: string | null = values[name]
  const setImage = useCallback((img: string | null) => setFieldValue(name, img), [name, setFieldValue])

  const emptyMessage = useMemo(
    () =>
      image && !fileName ? existingFileLabel : intl.formatMessage({ id: 'new_event.basics.images.no_file_chosen' }),
    [intl, fileName, image, existingFileLabel]
  )

  const incoming = useFileDragIncoming(mimeType)

  const removeImage = useCallback(() => {
    setImage(null)
    setFileName('')
    onFileNameChange('')
  }, [onFileNameChange, setImage])

  const onUpload = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if ((!acceptedFiles || acceptedFiles.length === 0) && (rejectedFiles?.length || 0) > 0) {
        addNotification('error', intl.formatMessage({ id: 'upload.bad_format' }, { fmt: fileFormatErrorSuggestion }))
        return
      }

      const file = acceptedFiles[0]

      const allowedMimes = concat([], mimeType)
      const hasMimeMatch = some((mt) => file.type === mt, allowedMimes)

      if (!hasMimeMatch) {
        addNotification('error', intl.formatMessage({ id: 'upload.bad_format' }, { fmt: fileFormatErrorSuggestion }))
        return
      }

      const url = URL.createObjectURL(file)

      setFileName(file.name)
      onFileNameChange(file.name)
      setImage(url)
    },
    [mimeType, onFileNameChange, setImage, addNotification, intl, fileFormatErrorSuggestion]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onUpload,
    accept: mimeType,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    noDrag: !incoming,
    disabled: !allowEdit,
  })

  let leftButton = null

  if (!allowEdit && !image) {
    leftButton = (
      <ImagePreviewWrapper>
        <Svg icon="image-placeholder" />
      </ImagePreviewWrapper>
    )
  } else if (image) {
    leftButton = (
      <ImagePreviewWrapper>
        <ImagePreview src={image} alt="preview" />
      </ImagePreviewWrapper>
    )
  } else {
    leftButton = (
      <UploadButton data-id={`uploadImage[${name}]`} onClick={open}>
        <Svg icon="add" />
        <OnMobile>{mobileUploadPrompt || intl.formatMessage({ id: 'new_event.basics.images.upload_image' })}</OnMobile>
        <OnDesktop>{desktopUploadPrompt || intl.formatMessage({ id: 'image_drag_drop.hint' })}</OnDesktop>
      </UploadButton>
    )
  }

  return (
    <>
      <FormGroup label={label} focus={incoming || isDragActive} required={required} error={error}>
        <Uploader {...getRootProps()} focus={incoming || isDragActive} error={!!error}>
          {leftButton}
          <input {...getInputProps({ name: 'image' })} />
          <UploadInfoPanel focus={incoming || isDragActive} error={!!error}>
            <UploadFileName>{fileName || emptyMessage}</UploadFileName>

            <div>{minSizeLabel}</div>
            <div>{fileFormatLabel}</div>

            {image && allowEdit && <RemoveButton icon="trash" onClick={removeImage} data-id={`removeImage[${name}]`} />}
          </UploadInfoPanel>
        </Uploader>
      </FormGroup>
    </>
  )
}

export default memo(SimpleUploader)
