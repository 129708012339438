/**
 * @generated SignedSource<<d7c88411be209d1a52a765d318b758ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useEventListTypeQuery$variables = Record<PropertyKey, never>;
export type useEventListTypeQuery$data = {
  readonly viewer: {
    readonly __typename: "Viewer";
    readonly eventListType: string | null;
  } | null;
};
export type useEventListTypeQuery = {
  response: useEventListTypeQuery$data;
  variables: useEventListTypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventListType",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useEventListTypeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useEventListTypeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f312f2bdf6c82728efcac37bf6451a8f",
    "id": null,
    "metadata": {},
    "name": "useEventListTypeQuery",
    "operationKind": "query",
    "text": "query useEventListTypeQuery {\n  viewer {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9216a54804a04fd627c615f6240278e6";

export default node;
