import React, { FC, memo, useCallback, useLayoutEffect, useContext, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { compose, map, omit, sortBy, find, findIndex, getOr, filter } from 'lodash/fp'

import { parseISO } from 'date-fns'
import { TimelineChart_event$data, TimelineChart_event$key } from '../../../__generated__/TimelineChart_event.graphql'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { color, font, mediaQuery, zIndex } from '../../../utils/variables'
import Svg from '../../../components/Svg'
import { localeContext } from '../../../context/locale'

const TIMELINE: Array<{
  id: string
  field: keyof TimelineChart_event$data
}> = [
  { id: 'announced', field: 'announceDate' },
  { id: 'on_sale', field: 'onSaleDate' },
  { id: 'off_sale', field: 'offSaleDate' },
  { id: 'transfer_deadline', field: 'closeEventDate' },
  { id: 'start', field: 'date' },
  { id: 'end', field: 'endDate' },
]

const GrandContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`

const SuperContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
`

const ScrollBlurLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 32px;
  pointer-events: none;
  height: 100%;
  z-index: ${zIndex.tooltip};

  ${mediaQuery.lessThan('tablet')`
    display: block;
  `}
`

const ScrollBlurRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 32px;
  pointer-events: none;
  height: 100%;
  z-index: ${zIndex.tooltip};

  ${mediaQuery.lessThan('tablet')`
    display: block;
  `}
`

const Container = styled.div`
  width: 100%;
  padding: 32px;

  display: flex;
  flex-wrap: wrap;

  ${mediaQuery.lessThan('tablet')`
    padding: 16px 20vw 16px 20vw;
    width: 350vw;
  `}
`

const TimelineDate = styled.div`
  flex: 1;
  text-align: center;
  font-size: ${font.size.sm}px;
  line-height: 17px;
  color: ${color.darkgrey};

  & > strong {
    display: block;
    font-size: ${font.size.sm}px;
    line-height: 20px;
    margin-bottom: 8px;
    color: ${color.text};
  }
`

const Timeline = styled.div`
  min-width: 100%;
  margin-bottom: 8px;
  display: flex;
  height: 32px;
`

const TimelineElement = styled.div`
  position: relative;
  flex: 1;
  text-align: center;

  & > svg {
    z-index: 1;
    position: relative;
  }
`

type IIconState = 'active' | 'disabled' | 'current'

interface ITimelineLineProps {
  stateLeft: IIconState
  stateRight: IIconState
}

const leftColor = ({ stateLeft }: ITimelineLineProps) =>
  ({
    active: color.secondary,
    disabled: color.lightgrey,
    current: color.primary,
  }[(stateLeft || 'disabled') as IIconState])

const rightColor = ({ stateRight }: ITimelineLineProps) =>
  ({
    active: color.secondary,
    disabled: color.lightgrey,
    current: color.primary,
  }[(stateRight || 'disabled') as IIconState])

const TimelineLine = styled.div<ITimelineLineProps>`
  position: absolute;
  width: calc(100% - 24px);
  height: 2px;
  top: calc(50% - 1px);
  left: calc(50% + 12px);
  background: linear-gradient(to right, ${leftColor}, ${rightColor});
  z-index: 0;
`

// eslint-disable-next-line no-unused-vars
const ColoredSvg = styled(({ state, small, ...props }) => <Svg {...props} />)<{
  small?: boolean
  state?: IIconState
}>`
  background-color: white;
  margin: ${({ small }) => (small ? '12px' : '0')};
  outline: ${({ state }) => (state === 'current' ? `8px solid ${color.white}` : 'none')};
  color: ${({ state }) =>
    ({
      active: color.secondary,
      disabled: color.lightgrey,
      current: color.primary,
    }[(state || 'disabled') as IIconState])};
`

const TimelineChart: FC<React.PropsWithChildren<{ event: TimelineChart_event$key }>> = ({ event: eventKey }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const event = useFragment(
    graphql`
      fragment TimelineChart_event on Event {
        announceDate
        onSaleDate
        offSaleDate
        date
        endDate
        closeEventDate
        timezoneName

        flags {
          ticketTransfer
        }
      }
    `,
    eventKey
  )

  const transferEnabled = getOr(false, 'flags.ticketTransfer.active', event) && !!event.closeEventDate

  const realTimeline: typeof TIMELINE = useMemo(
    () =>
      compose([
        filter((it: typeof TIMELINE[number]) => transferEnabled || it.id !== 'transfer_deadline'),
        map(omit(['date'])),
        sortBy('date'),
        map((t: typeof TIMELINE[number]) => ({ ...t, date: parseISO(event[t.field] as string) })),
      ])(TIMELINE),
    [event, transferEnabled]
  )

  const isDisabled = useCallback(
    (id: string) => {
      const timelineElement = find(['id', id], realTimeline)
      const date = timelineElement && parseISO(event[timelineElement.field] as string)
      const now = new Date()
      return !date || date > now
    },
    [event, realTimeline]
  )

  const isCurrent = useCallback(
    (id: string) => {
      const timelineElementIdx = findIndex(['id', id], realTimeline)

      const dateA = timelineElementIdx >= 0 ? parseISO(event[realTimeline[timelineElementIdx].field] as string) : null
      const dateB =
        timelineElementIdx >= 0 && timelineElementIdx < realTimeline.length - 1
          ? parseISO(event[realTimeline[timelineElementIdx + 1].field] as string)
          : null

      const now = new Date()
      return !!dateA && now >= dateA && (!dateB || now < dateB)
    },
    [event, realTimeline]
  )

  const getState = useCallback(
    (id?: string): IIconState => {
      if (!id) return 'disabled'
      if (isCurrent(id)) return 'current'
      return isDisabled(id) ? 'disabled' : 'active'
    },
    [isCurrent, isDisabled]
  )

  useLayoutEffect(() => {
    const currentId = find((tl) => isCurrent(tl.id), realTimeline)?.id
    if (currentId) {
      const el = document.querySelector(`*[data-timeline-id="${currentId}"]`)
      if (el) {
        el.scrollIntoView({ inline: 'center' })
        window.scrollTo(0, 0)
      }
    }
  }, [isCurrent, realTimeline])

  return (
    <GrandContainer>
      <SuperContainer>
        <Container>
          <Timeline>
            {realTimeline.map((tl, idx) => (
              <TimelineElement key={tl.id} data-timeline-id={tl.id}>
                {idx < realTimeline.length - 1 && (
                  <TimelineLine stateLeft={getState(tl.id)} stateRight={getState(realTimeline[idx + 1]?.id)} />
                )}
                {isCurrent(tl.id) ? (
                  <ColoredSvg icon="diamond-concentric" width={32} height={32} state="current" />
                ) : (
                  <ColoredSvg
                    icon="circle"
                    small
                    width={8}
                    height={8}
                    state={isDisabled(tl.id) ? 'disabled' : 'active'}
                  />
                )}
              </TimelineElement>
            ))}
          </Timeline>
          {realTimeline.map((tl) => (
            <TimelineDate key={tl.id}>
              <strong>{intl.formatMessage({ id: `event_overview.timeline.${tl.id}` })}</strong>
              <div>
                {!!event[tl.field] &&
                  intl.formatDate(parseISO(event[tl.field] as string), {
                    ...DATETIME_FORMATS.MEDIUM,
                    timeZone: event.timezoneName || undefined,
                  })}
              </div>
              <div>
                {!!event[tl.field] &&
                  intl.formatDate(parseISO(event[tl.field] as string), {
                    ...DATETIME_FORMATS.TIME(locale),
                    timeZone: event.timezoneName || undefined,
                  })}
              </div>
            </TimelineDate>
          ))}
        </Container>
      </SuperContainer>
      <ScrollBlurLeft />
      <ScrollBlurRight />
    </GrandContainer>
  )
}

export default memo(TimelineChart)
