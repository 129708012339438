/**
 * @generated SignedSource<<c335709cb3e106a6025cd48d70f48f60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventPartImage_event$data = {
  readonly eventImages: ReadonlyArray<{
    readonly cdnUrl: string;
    readonly type: string | null;
  } | null> | null;
  readonly flags: {
    readonly hidden: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly state: EventState | null;
  readonly " $fragmentType": "EventPartImage_event";
};
export type EventPartImage_event$key = {
  readonly " $data"?: EventPartImage_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartImage_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventPartImage_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventImage",
      "kind": "LinkedField",
      "name": "eventImages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cdnUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "ce36030e2b895c98caf376911ac32655";

export default node;
