import React, { FC, Suspense, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { RelayEnvironmentProvider } from 'react-relay'
import { Outlet } from 'react-router'
import { find } from 'lodash/fp'
import qs from 'qs'

import { Loader, LoaderContainer } from '../components/Loader'
import LocaleProvider from '../context/locale'
import NotificationProvider from '../context/notification'
import { OPERATING_COUNTRIES } from '../constants/operatingCountries'
import { DEFAULT_LOCALE, RELEASED_LOCALES } from '../intl'

import VersionChecker from './components/VersionChecker'
import AccountSwitchNotifier from './components/AccountSwitchNotifier'
import AndroidScrollHack from './components/AndroidScrollHack'
import { createEnvironment } from '../environment'

const AppLayout: FC = () => {
  const initialLocale = useMemo(() => {
    const { country: countryCode } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const country = find(['alpha2', (countryCode?.toString() || 'GB').toUpperCase()], OPERATING_COUNTRIES)
    const locale = country?.locale || DEFAULT_LOCALE
    if (RELEASED_LOCALES.has(locale)) return locale

    console.warn(`Locale ${locale} is not supported yet!`)

    return DEFAULT_LOCALE
  }, [])

  const environment = useMemo(() => createEnvironment(), [])

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Suspense
        fallback={
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        }
      >
        <LocaleProvider initialLocale={initialLocale}>
          <NotificationProvider>
            <Helmet>
              <title>DICE MIO</title>
            </Helmet>
            <VersionChecker />
            <AccountSwitchNotifier />
            <AndroidScrollHack />

            <Outlet />
          </NotificationProvider>
        </LocaleProvider>
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default AppLayout
