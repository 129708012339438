import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import { DashboardWrapper } from './Dashboard'
import { PageHeader, PageTitle } from '../../components/Page'
import { Text } from '../../components/Text'

import { font, mediaQuery } from '../../utils/variables'
import Svg from '../../components/Svg'

const EmptyCover = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 820px;
  padding: 64px 32px 24px;
  margin: 0 auto;
  height: 100%;
  flex: 1;
  text-align: center;
  align-items: center;
  ${mediaQuery.lessThan('desktop')`
    padding: 64px 16px 52px;
  `}
`

const Title = styled.div<{ textAlign?: string; spacing?: string }>`
  font-size: ${font.size.xlg}px;
  font-weight: 300;
  line-height: 110%;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  margin: ${({ spacing }) => spacing || '0 0 32px'};
`

const SubTitle = styled.div<{ textAlign?: string; fontSize?: keyof typeof font.size; spacing?: string }>`
  text-align: ${({ textAlign }) => textAlign || 'left'};
  font-size: ${({ fontSize }) => (fontSize ? font.size[fontSize] : font.size.base)}px;
  margin: ${({ spacing }) => spacing || '0'};
  line-height: 1.4em;
`

const OnboardingIcon = styled(Svg)`
  width: 73px;
  height: 100px;
  margin-bottom: 32px;
`

function DashbordEmpty() {
  const intl = useIntl()

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'dashboard.title' })} | DICE MIO</title>
      </Helmet>
      <PageHeader>
        <PageTitle>{intl.formatMessage({ id: 'dashboard.title' })}</PageTitle>
      </PageHeader>
      <DashboardWrapper>
        <EmptyCover>
          <OnboardingIcon icon="fan" />
          <Title textAlign="center" spacing="0 0 8px">
            {intl.formatMessage({ id: 'new_event.onboarding.title' })}
          </Title>
          <SubTitle textAlign="center" spacing="0 0 64px">
            {intl.formatMessage({ id: 'new_event.onboarding.subtitle_short' })}
          </SubTitle>
          <Text color="grey" fontSize="md">
            {intl.formatMessage({ id: 'event_list.empty_copy' })}
          </Text>
        </EmptyCover>
      </DashboardWrapper>
    </>
  )
}

export default DashbordEmpty
