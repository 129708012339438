import React, { FC, createContext, useMemo, useState, useEffect, useCallback } from 'react'
import { IntlProvider } from 'react-intl'
import { noop, reduce, merge, keys } from 'lodash/fp'
import * as Sentry from '@sentry/react'

import { DEFAULT_LOCALE, fallbackMessages, ILocale, loadLocale } from '../intl'
import enTranslationMessages from '../translations/en.json'

interface ILocaleContext {
  locale: ILocale
  setLocale: (locale: ILocale) => void
  phraseEnabled: boolean
  setPhraseEnabled: (v: boolean) => void
}

declare global {
  interface Window {
    __currentLocale?: ILocale
  }
}

export const localeContext = createContext<ILocaleContext>({
  locale: DEFAULT_LOCALE,
  setLocale: noop,
  phraseEnabled: false,
  setPhraseEnabled: noop,
})

const initPhraseMessages = () => {
  const editorKeys = enTranslationMessages
  return reduce(
    (formattedMessages, key) => {
      const formattedMessage = `[[__phrase_${key}__]]`
      return merge({ [key]: formattedMessage }, formattedMessages)
    },
    {},
    keys(editorKeys)
  )
}

const LocaleProvider: FC<React.PropsWithChildren<{ initialLocale?: ILocale }>> = ({
  children,
  initialLocale = DEFAULT_LOCALE,
}) => {
  const translatorMode = window.localStorage.getItem('translatorMode')
  const [locale, setLocale] = useState<ILocale>(initialLocale)
  const [phraseEnabled, setPhraseEnabled] = useState<boolean>(!!translatorMode)

  const ctxValue = useMemo(
    () => ({
      locale,
      setLocale,
      phraseEnabled,
      setPhraseEnabled,
    }),
    [locale, phraseEnabled]
  )

  useEffect(() => {
    Sentry.configureScope(function (scope) {
      scope.setTag('page_locale', ctxValue.locale)
    })
  }, [ctxValue])

  const onError = useCallback(
    (err: any) => {
      if (err.code === 'MISSING_TRANSLATION') {
        if (err.descriptor?.id && err.descriptor.id.startsWith('countries.')) return
        if (err.descriptor.defaultMessage === ' ') return

        // eslint-disable-next-line no-console
        console.info('Missing translation', locale, err.descriptor)
      } else if (err.code === 'MISSING_DATA') {
        // eslint-disable-next-line no-console
        console.warn(err.message)
      } else {
        console.error(err)
        Sentry.captureException(err)
      }
    },
    [locale]
  )

  const [messages, setMessages] = useState(() => (phraseEnabled ? initPhraseMessages() : fallbackMessages))

  useEffect(() => {
    let stillMounted = true

    loadLocale(locale)
      .then((msg) => {
        if (stillMounted) {
          window.__currentLocale = locale
          setMessages(phraseEnabled ? initPhraseMessages() : msg)
        }
      })
      .catch(console.error)

    return () => {
      stillMounted = false
    }
  }, [locale, phraseEnabled])

  return (
    <localeContext.Provider value={ctxValue}>
      <IntlProvider locale={locale} messages={messages} onError={onError} wrapRichTextChunksInFragment={true}>
        {children}
      </IntlProvider>
    </localeContext.Provider>
  )
}

export default LocaleProvider
