import React, { lazy } from 'react'
import { Route, Routes } from 'react-router'

const ReportsPage = lazy(() => import(/* webpackChunkName: "reports" */ '../../flows/Reports/ReportsPage'))
const ReportInstancesPage = lazy(
  () => import(/* webpackChunkName: "reports" */ '../../flows/ReportInstances/ReportInstancesPage')
)
const ReportWizardPage = lazy(
  () => import(/* webpackChunkName: "reports" */ '../../flows/ReportWizard/ReportWizardPage')
)
const ReportSalesAttributionPage = lazy(
  () => import(/* webpackChunkName: "reports" */ '../../flows/ReportSalesAttribution/ReportSalesAttributionPage')
)

const ReportingSection = () => (
  <Routes>
    <Route index element={<ReportsPage />} />

    <Route path="attribution" element={<ReportSalesAttributionPage />} />

    <Route path="new" element={<ReportWizardPage />} />
    <Route path=":id" element={<ReportInstancesPage />} />
  </Routes>
)

export default ReportingSection
