export const REVIEW_STATUSES: {
  [key: string]: { key: string; defaultValue: string; i18n: string }
} = {
  escalated: {
    key: 'ESCALATED',
    defaultValue: 'Escalated',
    i18n: 'event_list.status.escalated',
  },
  core: {
    key: 'ON_HOLD',
    defaultValue: 'On Hold',
    i18n: 'event_list.status.on_hold',
  },
}
