import React, { memo, useCallback, useContext, useRef, FC, ChangeEvent } from 'react'
import styled from 'styled-components/macro'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { concat, reject, get, set, isString, compact } from 'lodash/fp'
import { parse, format } from 'date-fns'
import { useIntl } from 'react-intl'

import { mediaQuery, color } from '../../../utils/variables'
import IconButton from '../../../components/IconButton'
import FormGroup from '../../../components/FormGroup'
import { FlexFormField, ReverseFlexFormField } from '../../../components/FormField'
import Svg from '../../../components/Svg'
import { localeContext } from '../../../context/locale'
import { dateFnsLocales, ILocale } from '../../../intl'
import { getDefaultLineup } from '../services/getDefaultEvent'
import { autofillLineup } from '../services/autofillDates'

const rejectWithIdx = (reject as any).convert({ cap: false })

const AddTimeButton = styled(IconButton)`
  margin-right: 16px;
`

const AddTimeLabel = styled.label`
  display: block;
  margin-top: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 250px;
`

const LineupItem = styled.div`
  display: flex;

  & > div {
    flex: 0.5;
    margin-left: 8px;
  }
  & > div:first-child {
    flex: 1;
    margin-left: 0;
  }

  ${mediaQuery.lessThan('desktop')`
    flex-wrap: wrap;
    & > div {
      min-width: 100%;
      margin-left: 0;
      margin-top: 8px;
    }
  `}
`

const Lineup = styled.div<{ compact?: boolean }>`
  margin-top: 16px;
  padding: ${({ compact }) => (compact ? '0 30px' : '0')};
  ${mediaQuery.lessThan('desktop')`
    padding: 0;
  `}
`

const Placeholder = styled.div`
  max-width: 48px;
  width: 40px;
  height: 40px;

  ${mediaQuery.lessThan('desktop')`
    display: none;
  `}
`

const LineupRow = styled.div`
  position: relative;
  background-color: ${color.white};
  margin-bottom: 8px;

  ${mediaQuery.lessThan('desktop')`
    padding: 0 40px;

    &:first-child {
      padding: 0 40px;
    }
  `}
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  flex: none;
  max-width: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${color.grey};
  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};

  user-select: none;

  ${mediaQuery.lessThan<{ disabled?: boolean }>('desktop')`
    margin: 0;
    position: absolute;
    left: -40px;
    top: 24px;
  `}

  svg {
    pointer-events: none;
  }
`

const RemoveButton = styled(IconButton)`
  ${mediaQuery.lessThan<{ disabled?: boolean }>('desktop')`
    margin: 0;
    position: absolute;
    right: -40px;
    top: -24px;
  `}
`

const SortableDragHandle = SortableHandle<{ disabled?: boolean }>(({ disabled }: { disabled?: boolean }) => (
  <DragHandle disabled={disabled}>
    <Svg icon="hamburger" />
  </DragHandle>
))

interface IItemProps {
  idx: number
  lineupItem: ILineup
  timezone?: string
  locale: ILocale
  setLineupItem: (idx: number, value: ILineup) => void
  handleBlur?: (...args: any[]) => any
  removeLineup: (idx: number) => void
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
  noMove?: boolean
  allowEdit?: boolean
}

const LineupSortableItem = SortableElement<IItemProps>(
  ({
    idx,
    lineupItem,
    handleBlur,
    removeLineup,
    setLineupItem,
    touched,
    errors,
    noMove,
    allowEdit,
    locale,
  }: IItemProps) => {
    const { details, time } = lineupItem

    const intl = useIntl()
    const onRemove = useCallback(() => removeLineup(idx), [removeLineup, idx])

    const handleChangeDetails = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value || ''

        if (!isString(v)) {
          console.error(new Error(`Attempt to set non-string lineup details ${v}`))
          return
        }

        setLineupItem(idx, set('details', v, lineupItem))
      },
      [setLineupItem, idx, lineupItem]
    )

    const handleChangeTime = useCallback(
      (nm: string, strTime: string) => {
        // Backend wants this value to be in format 1:00 PM,
        // but field is free form, so we convert for proper parsing
        let v = strTime
        if (v) {
          const now = new Date()

          const str = strTime
            .toLowerCase()
            .replace(/\.\s*/g, '')
            .replace(/[^0-9amp :]/g, '')

          const fmt = str.match(/(a|p)m/) ? 'h:mm a' : 'HH:mm'
          const dt = parse(strTime, fmt, now)
          v = isNaN(dt.getTime()) ? '' : format(dt, 'h:mm aaa', { locale: dateFnsLocales['en-GB'] })
        }

        setLineupItem(idx, set('time', v ? v.toUpperCase() : '', lineupItem))
      },
      [setLineupItem, idx, lineupItem]
    )

    return (
      <LineupRow className="draggable">
        <LineupItem>
          <ReverseFlexFormField
            name={`lineup[${idx}].details`}
            onChange={handleChangeDetails}
            value={noMove ? intl.formatMessage({ id: 'doors_open' }) : details || ''}
            onBlur={handleBlur}
            placeholder={intl.formatMessage({ id: 'new_event.timeline.schedule.details.placeholder' })}
            error={get(`lineup[${idx}].details`, touched) && get(`lineup[${idx}].details`, errors)}
            disabled={!allowEdit || noMove}
          >
            {!noMove ? <SortableDragHandle disabled={!allowEdit} /> : <Placeholder />}
          </ReverseFlexFormField>

          <FlexFormField
            name={`lineup[${idx}].time`}
            value={time || ''}
            placeholder={intl.formatMessage({ id: 'new_event.timeline.schedule.time.placeholder' })}
            control="datetime"
            mode="time"
            onBlur={handleBlur}
            setFieldValue={handleChangeTime}
            error={get(`lineup[${idx}].time`, touched) && get(`lineup[${idx}].time`, errors)}
            disabled={!allowEdit}
            locale={locale}
          >
            {allowEdit && !noMove ? (
              <RemoveButton icon="trash" onClick={onRemove} disabled={noMove} data-id={`removeLineup[${idx}]`} />
            ) : (
              <Placeholder />
            )}
          </FlexFormField>
        </LineupItem>
      </LineupRow>
    )
  }
)

interface IContainerProps {
  lineup: Array<ILineup>
  timezone?: string
  handleBlur?: (...args: any[]) => any
  removeLineup: (idx: number) => void
  locale: ILocale
  setLineup: (v: ILineup[]) => void
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
  compact?: boolean
  allowEdit?: boolean
}

const SortableLineup = SortableContainer<IContainerProps>(
  ({
    compact,
    lineup = [],
    handleBlur,
    removeLineup,
    timezone,
    locale,
    setLineup,
    touched,
    errors,
    allowEdit,
  }: IContainerProps) => {
    const setLineupItem = useCallback(
      (idx: number, value: ILineup) => {
        setLineup(set(`${idx}`, value, lineup || []))
      },
      [setLineup, lineup]
    )

    return (
      <Lineup compact={compact}>
        {lineup.map((lineupItem, idx) => (
          <LineupSortableItem
            key={idx}
            idx={idx}
            index={idx}
            lineupItem={lineupItem}
            handleBlur={handleBlur}
            removeLineup={removeLineup}
            timezone={timezone}
            locale={locale}
            setLineupItem={setLineupItem}
            touched={touched}
            errors={errors}
            disabled={!allowEdit || idx === 0}
            noMove={idx === 0}
            allowEdit={allowEdit}
            collection={idx === 0 ? 0 : 1}
          />
        ))}
      </Lineup>
    )
  }
)

export interface ILineup {
  details?: string
  time?: string
}

interface IProps {
  handleBlur: (e: Event) => void
  event: FormikValues
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  lineup?: ReadonlyArray<ILineup> | null
  setLineup: (v: Array<ILineup>) => void
  compact?: boolean
  allowEdit?: boolean
  timezoneName: string | null
}

const EventSchedule: FC<React.PropsWithChildren<IProps>> = ({
  compact: isCompact,
  lineup,
  setLineup,
  handleBlur,
  event,
  errors,
  touched,
  allowEdit,
  timezoneName,
}) => {
  const intl = useIntl()

  const ref = useRef<HTMLDivElement>(null)

  const addLineup = useCallback(() => {
    const normalizedLineup =
      !lineup || lineup.length === 0
        ? autofillLineup(event as any, getDefaultLineup(intl), timezoneName)
        : compact(lineup)

    const idx = normalizedLineup.length

    setLineup(
      concat(normalizedLineup, {
        details: '',
        time: '',
      })
    )

    setTimeout(() => {
      if (ref.current) {
        const node = ref.current.querySelector(`input[name="lineup[${idx}].details"]`) as HTMLInputElement
        if (node) node.focus()
      }
    }, 500)
  }, [lineup, event, intl, timezoneName, setLineup])

  const removeLineup = useCallback(
    (removedIdx: any) => {
      setLineup(rejectWithIdx((_: any, idx: number) => idx === removedIdx, lineup))
    },
    [setLineup, lineup]
  )

  const reorderLineup = useCallback(
    ({ oldIndex, newIndex }: any) => {
      setLineup(arrayMove(lineup || [], oldIndex, newIndex))
    },
    [setLineup, lineup]
  )

  const { locale } = useContext(localeContext)

  return (
    <div ref={ref}>
      <FormGroup
        label={intl.formatMessage({ id: 'new_event.timeline.schedule.label' })}
        hint={intl.formatMessage({ id: 'new_event.timeline.schedule.hint' })}
        help={intl.formatMessage({ id: 'new_event.timeline.schedule.help' })}
        required
      />

      <SortableLineup
        lineup={(lineup || []) as ILineup[]}
        timezone={undefined}
        locale={locale}
        handleBlur={handleBlur}
        setLineup={setLineup}
        removeLineup={removeLineup}
        onSortEnd={reorderLineup}
        lockAxis="y"
        useDragHandle
        touched={touched}
        errors={errors}
        compact={isCompact}
        allowEdit={allowEdit}
      />
      {allowEdit && (
        <AddTimeLabel>
          <AddTimeButton icon="add" color="black" onClick={addLineup} data-id="addLineup" />
          {intl.formatMessage({ id: 'new_event.timeline.lineup.add' })}
        </AddTimeLabel>
      )}
    </div>
  )
}

export default memo(EventSchedule)
