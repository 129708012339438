import React, { ChangeEvent, FC, memo, Suspense, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import graphql from 'babel-plugin-relay/macro'
import { concat, getOr, groupBy, some, uniqBy } from 'lodash/fp'
import { createFragmentContainer, useRelayEnvironment } from 'react-relay'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import { Form, FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'

import EventVenues from '../components/EventVenues'
import EventArtists from '../components/EventArtists'

import { IFormStep } from '../services/getStepsConfig'
import { allowedEventAction } from '../services/allowedEventAction'

import { Basics_viewer$data } from '../../../__generated__/Basics_viewer.graphql'

import IEventFormBasics, { ICharacteristic } from '../types/Basics'
import useBasicsVenues from '../hooks/useBasicsVenues'
import useBasicsFields from '../hooks/useBasicsFields'
import useBasicsTags from '../hooks/useBasicsTags'
import Tooltip from '../../../components/Tooltip'
import EventStyleguide, { IData } from '../components/EventStyleguide'
import validate from '../../../utils/styleguide'
import { breakpoints, color, font } from '../../../utils/variables'
import Checkbox from '../../../components/Checkbox'
import { RequiredMark } from '../../../components/FormGroup'
import { authContext } from '../../../context/auth'
import { isItalianEvent } from '../../../utils/isCountryEvent'
import FormHeader from '../../../components/FormHeader'
import SwitchField from '../../../components/SwitchField'
import usePromoters, { getPromoterLoader } from '../hooks/usePromoters'
import InputWithCopy from '../../../components/InputWithCopy'
import { Loader, LoaderContainer } from '../../../components/Loader'
import EventNts from '../components/EventNts'
import { localeContext } from '../../../context/locale'
import Note from '../../../components/Note'
import OtherCurrenciesHint from '../../Promoter/components/OtherCurrenciesHint'
import EventLicenseNumber from '../components/EventLicenseNumber'
import SalesforceDetails from '../components/SalesforceDetails'

const SNote = styled(Note)`
  margin-top: 8px;
  align-items: center;

  & > svg {
    margin-top: 0;
  }

  a {
    font-weight: bold;
    color: ${color.text};
  }
`

const StyleGuideHandle = styled.span<{ error: boolean }>`
  cursor: pointer;
  color: ${({ error }) => (error ? color.error : color.primary)};
  white-space: nowrap;
`

const EventNameHint = styled.div`
  display: flex;
  justify-content: space-between;
`

const CharCounter = styled.div<{ count: number; maxCount: number }>`
  white-space: nowrap;
  font-weight: normal;
  font-size: ${font.size.sm}px;
  margin-right: 24px;
  ${({ count, maxCount }) => (count === 0 || count > maxCount ? `color: ${color.error}` : undefined)}
`

const SLink = styled(Link)`
  text-decoration: underline;
`

const NAME_MAX_LENGTH = 50

interface IProps {
  viewer?: Basics_viewer$data
}

const BasicsStep: FC<React.PropsWithChildren<IFormStep & IProps>> = ({ children, viewer, readOnly }) => {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const { values, touched, errors, handleBlur, handleChange, setFieldValue, validateForm, initialValues } =
    useFormikContext<IEventFormBasics>()

  const { venues, setVenues, customVenue, setCustomVenue, setPrimaryVenueId } = useBasicsVenues()

  const {
    eventTypeOptions,
    eventType,
    changeEventType,
    genres,
    setGenres,
    genresLoader,
    setCharacteristics,
    characteristicsLoader,
    festival,
    setFestival,
  } = useBasicsTags(viewer)

  const { toggleCharity } = useBasicsFields()

  const handleChangeFestival = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setFestival(e.target.checked),
    [setFestival]
  )

  const nameHint = useMemo(() => {
    const styleguideData = groupBy('kind', validate(values.name || '', intl, values)) as IData
    return intl.formatMessage(
      { id: 'new_event.basics.name.hint' },
      {
        a: (str: string) => (
          <Tooltip
            placement="bottom"
            handle={
              <StyleGuideHandle
                error={some(
                  (rule: IData[keyof IData][number]) => !rule.valid,
                  concat(styleguideData.forbidden, styleguideData.styleguide)
                )}
              >
                {str}
              </StyleGuideHandle>
            }
          >
            <EventStyleguide data={styleguideData} />
          </Tooltip>
        ),
      }
    )
  }, [intl, values])

  const nameLabel = useMemo(() => {
    const count = (values.name || '').length

    return (
      <EventNameHint>
        <div>
          {intl.formatMessage({ id: 'new_event.basics.title.label' })}
          <RequiredMark />
        </div>

        <CharCounter count={count} maxCount={NAME_MAX_LENGTH}>
          {count}/{NAME_MAX_LENGTH}
        </CharCounter>
      </EventNameHint>
    )
  }, [intl, values.name])

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const isItalian = useMemo(() => isItalianEvent(values, locale), [locale, values])

  const streamingTicketsIntegrationDisabled = !!getOr(
    true,
    'attractiveFields.streamingTicketsIntegrationDisabled',
    values
  )

  const toggleStreamingIntegration = useCallback(() => {
    setFieldValue('attractiveFields.streamingTicketsIntegrationDisabled', !streamingTicketsIntegrationDisabled)

    setTimeout(() => validateForm(), 0)
  }, [setFieldValue, streamingTicketsIntegrationDisabled, validateForm])

  const toggleIntegrationDisabled = useCallback(() => {
    const newVal = !values.attractiveFields?.integrationDisabled

    setFieldValue('attractiveFields.integrationDisabled', newVal)

    // Let it reset to default on main switch flip, but not for streams
    setFieldValue('attractiveFields.streamingTicketsIntegrationDisabled', values.eventType === 'STREAM' ? newVal : true)

    setTimeout(() => validateForm(), 0)
  }, [setFieldValue, validateForm, values.attractiveFields?.integrationDisabled, values.eventType])

  const { billingPromoter, setBillingPromoter, stripeAccountValue, stripeAccountOptions, setStripeAccount } =
    usePromoters(viewer)

  const promoterLoader = useMemo(() => getPromoterLoader(environment), [environment])

  const isNts =
    isItalian &&
    !(values.eventType === 'STREAM' && streamingTicketsIntegrationDisabled) &&
    !values.attractiveFields?.integrationDisabled

  const showStreamingNtsSwitch =
    isItalian && user.diceStaff && values.eventType === 'HYBRID' && !values.attractiveFields?.integrationDisabled

  const isAlreadyIntegrated = !!values.attractiveStatus

  const handleVenueCharacteristics = useCallback(
    (characteristics: Array<ICharacteristic | null> | null) => {
      const merged = uniqBy('value', concat(values.characteristics, characteristics || []))
      setCharacteristics(undefined, merged)
    },
    [setCharacteristics, values.characteristics]
  )

  const venueHasChanged = useMemo(
    () => !!initialValues.primaryVenue?.value && initialValues.primaryVenue.value !== values.primaryVenue?.value,
    [initialValues.primaryVenue?.value, values.primaryVenue?.value]
  )

  const setVenueSpace = useCallback(
    (v: { label: string; value: string } | null) => {
      setFieldValue('venueSpace', v)
    },
    [setFieldValue]
  )

  return (
    <Form spacing={isMobile ? 'default' : 'extra'}>
      <FormHeader
        header={intl.formatMessage({ id: 'new_event.steps.basics_title' })}
        subheader={intl.formatMessage({ id: 'new_event.basics.description' })}
      />

      {values.eventIdLive && (
        <FormRow columnOnMobile>
          <FormField
            name="eventIdLive"
            label={intl.formatMessage({ id: 'new_event.basics.event_id_live.label' })}
            help={intl.formatMessage({ id: 'new_event.basics.event_id_live.help' })}
            hint={intl.formatMessage({ id: 'new_event.basics.event_id_live.hint' })}
            value={values.eventIdLive}
            disabled
            control={InputWithCopy}
          />
        </FormRow>
      )}

      {user.diceStaff && values.state !== 'DRAFT' && (
        <FormRow columnOnMobile>
          <FormField
            name="permName"
            label={intl.formatMessage({ id: 'new_event.basics.permName.label' })}
            value={values.permName || undefined}
            disabled
            dice
            control={InputWithCopy}
          />
        </FormRow>
      )}

      {user.diceStaff && (
        <FormRow columnOnMobile>
          <FormField
            name="billingPromoter"
            label={intl.formatMessage({ id: 'new_event.settings.billing_account.label' })}
            placeholder={intl.formatMessage({ id: 'new_event.settings.billing_account.placeholder' })}
            hint={
              values.billingPromoter?.value ? (
                <SNote>
                  {intl.formatMessage(
                    { id: 'new_event.settings.billing_account.hint' },
                    {
                      a: (str: string) => (
                        <Link target="_blank" to={`/promoters/${values.billingPromoter?.value}`}>
                          {str}
                        </Link>
                      ),
                    }
                  )}
                </SNote>
              ) : undefined
            }
            control="select"
            value={billingPromoter}
            onChange={setBillingPromoter}
            onBlur={handleBlur}
            error={
              (touched.billingPromoter && errors.billingPromoter) || (touched.stripeAccountId && errors.stripeAccountId)
            }
            required
            async
            searchable={user.diceStaff}
            loadOptions={promoterLoader}
            defaultOptions
            disabled={
              readOnly || (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
            }
            dice
          />
        </FormRow>
      )}

      <EventLicenseNumber />

      {user.diceStaff && (
        <FormRow columnOnMobile>
          <FormField
            name="stripeAccountId"
            label={intl.formatMessage({ id: 'new_event.settings.destination_account.label' })}
            placeholder={intl.formatMessage({ id: 'new_event.settings.destination_account.placeholder' })}
            hint={
              values.stripeAccountId &&
              (values.billingPromoter?.stripeAccountId === values.stripeAccountId ? (
                <Suspense fallback={null}>
                  <OtherCurrenciesHint
                    promoterId={values.billingPromoter?.value}
                    message={intl.formatMessage(
                      { id: 'new_event.settings.destination_account.currency_hint' },
                      {
                        platformCode: values.platformAccountCode || '???',
                        b: (str: string) => <strong>{str}</strong>,
                        a: (str: string) => (
                          <SLink target="_blank" to={`/promoters/${values.billingPromoter?.value}`}>
                            {str}
                          </SLink>
                        ),
                      }
                    )}
                    fallbackMessage={intl.formatMessage(
                      { id: 'new_event.settings.destination_account.hint' },
                      {
                        id: values.stripeAccountId,
                        platformCode: values.platformAccountCode || '???',
                      }
                    )}
                  />
                </Suspense>
              ) : (
                intl.formatMessage(
                  { id: 'new_event.settings.destination_account.hint' },
                  {
                    id: values.stripeAccountId,
                    platformCode: values.platformAccountCode || '???',
                  }
                )
              ))
            }
            control="select"
            searchable
            options={stripeAccountOptions}
            value={stripeAccountValue}
            onChange={setStripeAccount}
            onBlur={handleBlur}
            error={touched.stripeAccountId && errors.stripeAccountId}
            required={values.state !== 'DRAFT'}
            disabled={readOnly}
            dice
          />
        </FormRow>
      )}

      <FormRow columnOnMobile>
        <FormField
          name="eventType"
          label={intl.formatMessage({ id: 'new_event.basics.event_type.label' })}
          control="select"
          options={eventTypeOptions}
          value={eventType}
          onChange={changeEventType}
          onBlur={handleBlur}
          error={touched.hierarchicalTags && errors.hierarchicalTags}
          disabled={
            readOnly ||
            (values.state !== 'DRAFT' &&
              !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') &&
              !hasPermission('full_manage_access:event'))
          }
          required
          help={intl.formatMessage({ id: 'new_event.basics.event_type.help' })}
        />
      </FormRow>

      <FormRow columnOnMobile>
        <Checkbox
          name="charityEvent"
          label={intl.formatMessage({ id: 'new_event.tickets.charity_checkbox.label' })}
          checked={values.charityEvent}
          onChange={toggleCharity}
          onBlur={handleBlur}
          disabled={
            readOnly || (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
          }
        />
        <Checkbox
          name="festival"
          label={intl.formatMessage({ id: 'new_event.basics.festival.label' })}
          checked={festival}
          onChange={handleChangeFestival}
          onBlur={handleBlur}
          disabled={
            readOnly ||
            (values.state !== 'DRAFT' &&
              !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') &&
              !hasPermission('full_manage_access:event'))
          }
        />
      </FormRow>

      {values.charityEvent && (
        <FormRow columnOnMobile>
          <FormField
            name="charityId"
            label={intl.formatMessage({ id: 'new_event.tickets.charity_id.label' })}
            value={values.charityId || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={
              readOnly || (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
            }
            error={touched.charityId && errors.charityId}
            required
          />
        </FormRow>
      )}

      <EventArtists readOnly={readOnly} viewer={viewer} />

      <FormRow columnOnMobile>
        <FormField
          label={nameLabel}
          hint={nameHint}
          placeholder={intl.formatMessage({ id: 'new_event.basics.title.placeholder' })}
          name="name"
          maxLength={user.diceStaff || hasPermission('full_manage_access:event') ? undefined : NAME_MAX_LENGTH}
          value={values.name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'name')}
          help={intl.formatMessage({ id: 'new_event.basics.title.help' })}
        />
      </FormRow>

      <FormRow columnOnMobile>
        <FormField
          key={eventType?.value || ''}
          name="genres"
          label={intl.formatMessage({ id: 'new_event.basics.genre.label' })}
          control="select"
          multiple
          defaultOptions
          searchable
          async
          value={genres || []}
          loadOptions={genresLoader}
          onBlur={handleBlur}
          onChange={setGenres}
          error={(touched as any).genres && (errors as any).genres}
          placeholder={intl.formatMessage({ id: 'new_event.basics.genre.placeholder' })}
          appendOnly={
            values.state !== 'DRAFT' &&
            !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') &&
            !hasPermission('full_manage_access:event')
          }
          disabled={
            readOnly ||
            (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'artistIds', 'canAdd'))
          }
          required={(values.eventArtists || []).length + (values.additionalArtists || []).length === 0}
          help={intl.formatMessage({ id: 'new_event.basics.genre.help' })}
          hint={
            (genres?.length || 0) > 5
              ? intl.formatMessage({ id: 'new_event.basics.genre.too_many_genres_warn' })
              : undefined
          }
        />
      </FormRow>

      {user.diceStaff ? (
        <FormRow columnOnMobile>
          <FormField
            dice
            name="characteristics"
            label={intl.formatMessage({ id: 'new_event.basics.characteristics.label' })}
            control="select"
            multiple
            defaultOptions
            searchable
            async
            value={values.characteristics || []}
            loadOptions={characteristicsLoader}
            onBlur={handleBlur}
            onChange={setCharacteristics}
            error={(touched as any).characteristics && (errors as any).characteristics}
            placeholder={intl.formatMessage({ id: 'new_event.basics.characteristics.placeholder' })}
            disabled={readOnly}
          />
        </FormRow>
      ) : null}

      <Suspense
        fallback={
          <FormRow>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          </FormRow>
        }
      >
        <EventVenues
          eventType={values.eventType}
          state={values.state}
          venues={venues}
          primaryVenueId={values.primaryVenue?.value || null}
          handleVenueCharacteristics={handleVenueCharacteristics}
          setVenues={setVenues}
          setPrimaryVenueId={setPrimaryVenueId}
          customVenue={customVenue}
          setCustomVenue={setCustomVenue}
          venueSpace={values.venueSpace}
          setVenueSpace={setVenueSpace}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          allowEdit={!readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'venues')}
          forceEditable={allowedEventAction(values.allowedLifecycleUpdates, 'forbidden')}
          showVenueChangedFeesWarning={venueHasChanged}
          isSeatedEvent={!!values.flags?.seated?.active}
          ntsComponent={
            isItalian &&
            (user.diceStaff || showStreamingNtsSwitch || isNts) && (
              <>
                {user.diceStaff && (
                  <FormRow columnOnMobile>
                    <SwitchField
                      dice
                      label={intl.formatMessage({ id: 'new_event.basics.attractive_disabled.label' })}
                      hint={intl.formatMessage({ id: 'new_event.basics.attractive_disabled.hint' })}
                      name="attractiveFields.integrationDisabled"
                      checked={values.freeEvent || !!values.attractiveFields?.integrationDisabled}
                      onChange={toggleIntegrationDisabled}
                      disabled={
                        readOnly ||
                        values.freeEvent ||
                        (values.state !== 'DRAFT' &&
                          !(allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') && !isAlreadyIntegrated))
                      }
                    />
                  </FormRow>
                )}

                {showStreamingNtsSwitch && (
                  <FormRow columnOnMobile>
                    <SwitchField
                      dice
                      label={intl.formatMessage({
                        id: 'new_event.basics.streaming_tickets_integration_disabled.label',
                      })}
                      hint={intl.formatMessage({ id: 'new_event.basics.streaming_tickets_integration_disabled.hint' })}
                      name="streamingTicketsIntegrationDisabled"
                      checked={streamingTicketsIntegrationDisabled}
                      onChange={toggleStreamingIntegration}
                      disabled={
                        readOnly ||
                        (values.state !== 'DRAFT' &&
                          !(allowedEventAction(values.allowedLifecycleUpdates, 'forbidden') && !isAlreadyIntegrated))
                      }
                    />
                  </FormRow>
                )}

                {isNts && (
                  <Suspense
                    fallback={
                      <LoaderContainer>
                        <Loader />
                      </LoaderContainer>
                    }
                  >
                    <EventNts readOnly={readOnly} />
                  </Suspense>
                )}
              </>
            )
          }
        />
      </Suspense>

      <SalesforceDetails />

      {children}
    </Form>
  )
}

export default createFragmentContainer(memo(BasicsStep), {
  event: graphql`
    fragment Basics_event on Event {
      id
      permName
      name
      diceTvPlatform
      eventType
      charityEvent
      charityId
      state
      licenseNumber

      characteristics {
        value: id
        label: name
      }

      attractiveStatus {
        status
      }
      freeEvent
      attractiveFields {
        compatibilityAe
        siaeGenreType
        integrationDisabled
        entertainmentPercent
        streamingTicketsIntegrationDisabled
        taxFree
        seatingAreaConfigs {
          capacity
          seatingArea
        }
        author
        distributor
        nationality
        performer
        producer
      }

      allowedActions {
        manageLinks
      }

      allowedLifecycleUpdates {
        name {
          canUpdate
        }
        venues {
          canUpdate
        }
        eventImages {
          canUpdate
        }
        artistIds {
          canAdd
          canUpdate
          canRemove
        }
        lineUp {
          canUpdate
        }
        media {
          canUpdate
        }
        attractiveCompatibilityAe {
          canUpdate
        }
      }
      timezoneName
      addressCountry
      countryCode
      addressLocality
      addressRegion
      addressState
      addressCapacity
      addressSiaeCode
      postalCode
      streetAddress
      fullAddress
      venueName
      costCurrency
      lineup
      date
      media {
        id
        type
        values
      }
      stripeAccountId
      platformAccountCode
      billingPromoter {
        value: id
        label: name
        isTest
        displayName
        stripeAccountId
        platformAccountCode
        showPriceSuggestions
        addressCountry
        countryCode
        accountId
        licenseNumber
        allowSkipReview
        resoldEnabled
        disableUsTax
        holdPayouts
        coolingOffPeriod
        coolingOffPeriodHours
        sendReceiptViaSms
        billingNotes
        fanSupportNotes {
          body
        }
        labels {
          value: id
          label: name
        }
        associatedMarketeers {
          value: id
          label: name
        }
        autoRescheduledEventRefunds {
          active
          cutoffDays
        }
        salesforceFields {
          defaultContract {
            id
            name
            num
            opportunityName
            sfAccountId
            sfId
            startDate
            status
          }
        }
      }
      salesforceContract {
        id
        name
        num
        opportunityName
        sfAccountId
        sfId
        startDate
        status
      }
      promoters {
        value: id
        label: name
        isTest
        displayName
        stripeAccountId
        platformAccountCode
        showPriceSuggestions
        addressCountry
        countryCode
        accountId
        licenseNumber
        allowSkipReview
        resoldEnabled
        disableUsTax
        holdPayouts
        coolingOffPeriod
        coolingOffPeriodHours
        sendReceiptViaSms
        billingNotes
        fanSupportNotes {
          body
        }
        labels {
          value: id
          label: name
        }
        associatedMarketeers {
          value: id
          label: name
        }
        autoRescheduledEventRefunds {
          active
          cutoffDays
        }
        salesforceFields {
          defaultContract {
            id
            name
            num
            opportunityName
            sfAccountId
            sfId
            startDate
            status
          }
        }
      }
      venueConfiguration {
        value: id
        label: name
        attractiveRoomSiaeCode
        capacity

        seatingAreaConfigs {
          capacity
          seatingArea
        }
      }
      venueSpace {
        value: id
        label: name
      }
      venues {
        isTest
        capacity
        ageLimit
        addressCountry
        countryCode
        addressLocality
        addressRegion
        addressState
        postalCode
        streetAddress
        value: id
        label: name
        fullAddress
        timezoneName
        latitude
        longitude
        tier
        labels {
          value: id
          label: name
        }
        venueSpaces {
          value: id
          label: name
        }
        profileDetails {
          imageAttachment {
            cdnUrl
          }
          imageCropRegion {
            x
            y
            width
            height
          }
        }
        venueImages {
          attachment {
            cdnUrl
          }
        }
        configurations {
          value: id
          label: name
          attractiveRoomSiaeCode
          capacity

          seatingAreaConfigs {
            capacity
            seatingArea
          }
        }
      }
      primaryVenue {
        value: id
        label: name
        venueSpaces {
          value: id
          label: name
        }
      }
      hierarchicalTags {
        value: id
        label: name
        kind
        parent {
          name
        }
      }
      eventArtists {
        headliner
        artist {
          value: id
          label: name
          hint: disambiguation
          media {
            id
            type
            values
          }
          hierarchicalTags {
            value: id
            label: name
            kind
            parent {
              name
            }
          }
          tags {
            value: id
            label: name
          }
          profileImageAttachment {
            cdnUrl
          }
          profileImageCropRegion {
            x
            y
            width
            height
          }
        }
      }
      additionalInfos {
        id
        content
        includeOnPurchaseEmail
        includeOnReminderEmail
        ctaLabel
        ctaLink
      }
      additionalArtists {
        id
        name
        description
        hierarchicalTags {
          value: id
          label: name
          kind
        }
      }
      tags {
        value: id
        label: name
      }
      venueSchedules {
        id
        name
        date
        endDate
        venueId
        venueConfigurationId
      }
    }
  `,
  viewer: graphql`
    fragment Basics_viewer on Viewer {
      ...usePromoters_viewer
      ...useBasicsTags_viewer
      ...useHierarchicalTags_viewer
    }
  `,
})
