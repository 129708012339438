import { FC, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { notificationContext } from '../../context/notification'

const AccountSwitchNotifier: FC = () => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)

  useEffect(() => {
    if (localStorage.getItem('mioAccountWasAutoSwitched') === 'yes') {
      addNotification('success', intl.formatMessage({ id: 'account_auto_switched' }))
    }
    localStorage.removeItem('mioAccountWasAutoSwitched')
  }, [addNotification, intl])

  return null
}

export default AccountSwitchNotifier
