import React, { FC, memo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'

import PermissionCheck from '../../../components/PermissionCheck'
import { authContext } from '../../../context/auth'
import { IFactory, preload, SIDEBAR_ROUTER } from '../services/sidebarNav'
import { SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'

const FanSupport: FC = () => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { user, hasPermission } = useContext(authContext)

  return hasPermission('connect_via_email:fan') || hasPermission('access_fan_profile:fan_support') ? (
    <>
      {hasPermission('connect_via_email:fan') && (
        <SidebarMenuItem
          active={pathname === SIDEBAR_ROUTER.tools.fanConnect[0]}
          to={SIDEBAR_ROUTER.tools.fanConnect[0]}
          data-id="sidebarConnect"
          onMouseEnter={preload(SIDEBAR_ROUTER.tools.fanConnect[1] as IFactory)}
        >
          <SidebarMenuIcon icon="letter" />
          <span>{intl.formatMessage({ id: 'sidebar.fan_connect' })}</span>
        </SidebarMenuItem>
      )}
      {user.diceStaff && (
        <PermissionCheck permission="access_fan_profile:fan_support">
          <SidebarMenuItem
            dice
            active={pathname === SIDEBAR_ROUTER.fanSupport.fans[0]}
            to={SIDEBAR_ROUTER.fanSupport.fans[0]}
          >
            <SidebarMenuIcon icon="fans" />
            <span>{intl.formatMessage({ id: 'sidebar.fans' })}</span>
          </SidebarMenuItem>
        </PermissionCheck>
      )}
    </>
  ) : null
}

export default memo(FanSupport)
