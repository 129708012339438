import { FormikContextType } from 'formik'
import React, { FC, memo, useContext } from 'react'
import { useIntl } from 'react-intl'

import Collapsible from '../../../components/Collapsible'
import { FormRow } from '../../../components/Form'
import SimpleUploader from '../../../components/SimpleUploader'
import SwitchField from '../../../components/SwitchField'
import { authContext } from '../../../context/auth'
import { EventState } from '../../../enums.generated'
import { allowedEventAction } from '../services/allowedEventAction'
import { ITicketType } from '../types/Tickets'
import { ISeatingChart } from '../hooks/useTicketTypeSeating'
import Checkbox from '../../../components/Checkbox'
import FormGroup from '../../../components/FormGroup'

interface IProps {
  formik: FormikContextType<ITicketType>
  allowedLifecycleUpdates: null | {
    ticketTypes: {
      canChangeSeatmap: boolean | null
    } | null
  }
  state: EventState | null
  eventSeatingChart: null | (ISeatingChart & { id: string | null })
  noRestrictions?: boolean
  readOnly?: boolean
}

const EventTicketTypeAdminSettings: FC<IProps> = ({
  readOnly,
  formik,
  allowedLifecycleUpdates,
  state,
  noRestrictions,
  eventSeatingChart,
}) => {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)

  const { values, handleChange, handleBlur, setFieldValue } = formik

  const canAccessSection = user.diceStaff || hasPermission('full_manage_access:event')

  return canAccessSection ? (
    <Collapsible
      label={intl.formatMessage({ id: 'admin_settings' })}
      initialCollapsed={!values.seatmapUrl && !values.requiresAddress && !values.presale}
      dataId="ttyAdminSettings"
      dice
    >
      {user.diceStaff && (
        <>
          {!values.isStream &&
            !!eventSeatingChart?.id &&
            values.reservedSeating &&
            values.reservedSeatingType === 'assignBestSeat' && (
            <FormRow columnOnMobile>
              <FormGroup
                label={intl.formatMessage({
                  id: 'new_event.tickets.ticket_type_edit.reserved_seating_type.label',
                })}
              >
                <Checkbox
                  name="allowSeatChange"
                  label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.allow_seat_change.label' })}
                  checked={!!values.allowSeatChange}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={
                    state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !noRestrictions
                  }
                />
              </FormGroup>
            </FormRow>
          )}
        </>
      )}

      <FormRow columnOnMobile>
        <SimpleUploader
          name="seatmapUrl"
          mimeType="image/png"
          label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.seatmap.label' })}
          minSizeLabel={intl.formatMessage(
            { id: 'new_event.tickets.ticket_type_edit.seatmap.min_size' },
            { b: (str: string) => <strong>{str}</strong> }
          )}
          fileFormatLabel={intl.formatMessage(
            { id: 'new_event.tickets.ticket_type_edit.seatmap.file_format' },
            { b: (str: string) => <strong>{str}</strong> }
          )}
          existingFileLabel={intl.formatMessage({
            id: 'new_event.tickets.ticket_type_edit.seatmap.existing_image',
          })}
          fileFormatErrorSuggestion="PNG"
          values={values}
          setFieldValue={setFieldValue}
          allowEdit={
            !readOnly &&
            (state === 'DRAFT' || allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeSeatmap'))
          }
        />
      </FormRow>

      {user.diceStaff && (
        <FormRow columnOnMobile>
          <SwitchField
            label={intl.formatMessage({
              id: 'new_event.tickets.ticket_type_edit.presale.label',
            })}
            hint={intl.formatMessage({
              id: 'new_event.tickets.ticket_type_edit.presale.hint',
            })}
            name="presale"
            checked={!!values.presale}
            disabled
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
      )}
    </Collapsible>
  ) : null
}

export default memo(EventTicketTypeAdminSettings)
