import React, { useCallback, useContext, useMemo, ChangeEvent, useRef } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { chunk, filter, find, get, isNil, sumBy } from 'lodash/fp'
import { SortableHandle, SortableContainer, SortableElement } from 'react-sortable-hoc'

import { FormikValues, FormikTouched, FormikErrors } from 'formik'
import { Form, FormRow } from '../../../components/Form'
import FormField, { FlexFormField, ReverseFlexFormField } from '../../../components/FormField'
import { mediaQuery, color } from '../../../utils/variables'
import { localeContext } from '../../../context/locale'
import IEventForm from '../types'
import IconButton from '../../../components/IconButton'
import FormGroup from '../../../components/FormGroup'
import Svg from '../../../components/Svg'
import { OnDesktop } from '../../../components/Breakpoints'
import { authContext } from '../../../context/auth'
import EventTicketTypePriceTypeSelect from './EventTicketTypePriceTypeSelect'
import { Text } from '../../../components/Text'
import { IFee, ITicketPool } from '../types/Tickets'
import Collapsible, { CollapseButton } from '../../../components/Collapsible'
import LimitedInput, { ILimitedInputProps } from '../../../components/LimitedInput'
import { REGEX_INTEGER } from '../../../utils/regex'
import { IPriceTiersSales } from './EventTicketTypePricing'
import { EventCostCurrency } from '../../../enums.generated'

type ITicketType = NonNullable<NonNullable<IEventForm['ticketTypes']>[number]>
type IPriceTier = NonNullable<NonNullable<ITicketType['priceTiers']>[number]>

interface IProps {
  priceTierType: ITicketType['priceTierType']
  tiers: IPriceTier[]
  tiersSales: IPriceTiersSales | null
  ticketPool?: ITicketPool
  handleChange: (e: string | ChangeEvent) => void
  handleBlur: (e: Event) => void
  currency?: EventCostCurrency
  onRemove: (idx: number) => void
  timezoneName?: string
  setFieldValue: (key: string, val: any) => void
  validateForm: () => void
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
  canReorder: boolean
  ttyOnSaleDate: string | null
  canRename: boolean
  canRemove: boolean
  canEdit: boolean
  canEditFirstTier: boolean
  canEditPrice: boolean
  canEditAllocation: boolean
  sortOnlyNew: boolean
  isItalian: boolean
  isStream: boolean
  showAppendToContractFees: boolean
  children: any
  salesLimit?: number | null
}

const Container = styled.div`
  margin: 32px 0;
`

const PriceTier = styled.li<{ itIsDraggable?: boolean }>`
  position: relative;
  margin-bottom: 16px;
  list-style: none;
  background-color: ${color.white};

  &:last-child {
    margin-left: 0;
    margin-bottom: 0;
  }

  ${mediaQuery.lessThan<{ itIsDraggable?: boolean }>('tablet')`
    padding: ${({ itIsDraggable }) => (itIsDraggable ? '0 32px' : '0 32px 0 0')};
    margin-bottom: 24px;
  `}
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 40px;
  height: 40px;
  max-width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${color.grey};

  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};

  user-select: none;

  ${mediaQuery.lessThan<{ disabled?: boolean }>('tablet')`
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
    margin: 0;
    position: absolute;
    left: -40px;
    top: 48px;
  `}

  svg {
    pointer-events: none;
  }
`

const RemoveButton = styled(IconButton)`
  ${mediaQuery.lessThan<{ disabled?: boolean }>('tablet')`
    margin: 0;
    position: absolute;
    right: -40px;
    top: -48px;
  `}
`

const Arrow = styled(Svg)<{ isLong?: boolean }>`
  position: absolute;
  left: 14px;
  top: ${({ isLong }) => (isLong ? 62 : 40)}px;
  color: ${color.text};

  ${mediaQuery.lessThan('tablet')`
    top: 95px;
  `}
`

const StyledCollapsible = styled(Collapsible)<{ isPadded?: boolean }>`
  margin-left: ${({ isPadded }) => (isPadded ? '36px' : '0')};
  margin-bottom: -8px;

  & > div {
    margin: 0;
  }

  ${CollapseButton} {
    margin: 16px 0;
  }
`

const SortableDragHandle = SortableHandle<{ disabled?: boolean; last?: boolean; long?: boolean }>(
  ({ disabled, last, long }: { disabled?: boolean; last?: boolean; long?: boolean }) => (
    <DragHandle disabled={disabled}>
      <Svg icon="hamburger" />
      {!last && <Arrow isLong={long} className="-hide-on-drag" icon="price-tier-arrow" width={12} height={14} />}
    </DragHandle>
  )
)

const PaddedFormGroup = styled(FormGroup)<{ padding: number }>`
  padding-left: ${({ padding }) => padding}px;
`

interface ITierProps {
  tier: IPriceTier
  tierSales: null | NonNullable<IPriceTiersSales>[number]
  ticketPool?: ITicketPool
  idx: number
  handleChange: (e: ChangeEvent | string) => void
  handleBlur: (e: Event) => void
  currency?: EventCostCurrency
  priceTierType: ITicketType['priceTierType']
  onRemove: (idx: number) => void
  timezoneName?: string
  setFieldValue: (key: string, val: any) => void
  validateForm: () => void
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
  canRemove: boolean
  canMove: boolean
  canEdit: boolean
  canEditPrice: boolean
  canEditAllocation: boolean
  canRename: boolean
  last: boolean
  ttyOnSaleDate: string | null
  isItalian: boolean
  isStream: boolean
  showAppendToContractFees: boolean
  salesLimit?: number | null
}

const PriceTierRow = SortableElement<ITierProps>(
  ({
    tier,
    tierSales,
    idx,
    handleChange,
    currency,
    priceTierType,
    onRemove,
    timezoneName,
    setFieldValue,
    validateForm,
    handleBlur,
    touched,
    ticketPool,
    errors,
    canRemove,
    canEditPrice,
    canEditAllocation,
    canMove,
    canEdit,
    canRename,
    last,
    ttyOnSaleDate,
    isItalian,
    isStream,
    showAppendToContractFees,
    salesLimit,
  }: ITierProps) => {
    const intl = useIntl()
    const { locale } = useContext(localeContext)
    const { user } = useContext(authContext)

    const maxTicketAllocation = salesLimit || ticketPool?.maxAllocation
    const tierMinAllocation = useMemo(() => {
      const { appSold = 0, posSold = 0, terminalSold = 0 } = tierSales ?? {}
      return appSold + posSold + terminalSold
    }, [tierSales])
    const tierOriginalAllocation = useRef(tier.allocation)

    const removeTier = useCallback(() => onRemove(idx), [idx, onRemove])
    const priceTierNameSuggestions = useMemo(() => {
      return [
        intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.options.early_bird' }),
        intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.options.first_release' }),
        intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.options.second_release' }),
        intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.options.final_release' }),
      ]
    }, [intl])

    const onChangeTierAllocation = useCallback(
      (e: any) => {
        const value = e.target.value
        if (REGEX_INTEGER.test(value) && value.length < 15) {
          let newAllocation = parseInt(value, 10)
          if (maxTicketAllocation) {
            newAllocation = newAllocation > maxTicketAllocation ? maxTicketAllocation : newAllocation
          }
          if (tierMinAllocation) {
            newAllocation = newAllocation < tierMinAllocation ? tierMinAllocation : newAllocation
          }
          setFieldValue(`priceTiers[${idx}].allocation`, newAllocation)
        }
        if (value === '') {
          setFieldValue(`priceTiers[${idx}].allocation`, null)
        }
        return
      },
      [idx, setFieldValue, maxTicketAllocation, tierMinAllocation]
    )

    const formatAllocationLimitText = useCallback(
      ({ limit }: ILimitedInputProps) => {
        if (!isNil(tier.allocation)) {
          return ''
        }

        return intl.formatMessage(
          { id: 'new_event.tickets.ticket_type_edit.price_tier.allocation.limit_text' },
          {
            limit,
          }
        )
      },
      [intl, tier.allocation]
    )

    return (
      <PriceTier className="draggable" itIsDraggable={priceTierType === 'allocation' && !ticketPool}>
        <Form spacing="small">
          <FormRow columnOnMobile spacing="small">
            <ReverseFlexFormField
              name={`priceTiers[${idx}].name`}
              placeholder={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.placeholder' })}
              value={tier.name || ''}
              setFieldValue={setFieldValue}
              suggestions={priceTierNameSuggestions}
              onBlur={handleBlur}
              maxLength={user.diceStaff ? undefined : 30}
              error={get(`priceTiers[${idx}].name`, errors)}
              required
              disabled={!canRename}
            >
              {priceTierType === 'allocation' && !ticketPool && (
                <SortableDragHandle disabled={!canMove} last={last} long={showAppendToContractFees} />
              )}
            </ReverseFlexFormField>
            {priceTierType === 'allocation' ? (
              <FormField
                name={`priceTiers[${idx}].allocation`}
                type="tel"
                // convert to string strips leading zeroes in UI
                value={isNil(tier.allocation) ? '' : tier.allocation + ''}
                control={LimitedInput}
                min={tierMinAllocation ?? 0}
                step={1}
                formatLimitText={formatAllocationLimitText}
                limit={!!ticketPool ? maxTicketAllocation : undefined}
                onChange={onChangeTierAllocation}
                onBlur={handleBlur}
                error={get(`priceTiers[${idx}].allocation`, errors)}
                required
                disabled={
                  !ticketPool
                    ? !canEdit
                    : !canEditAllocation ||
                      (tierOriginalAllocation.current && tierMinAllocation >= tierOriginalAllocation.current) ||
                      last
                }
              />
            ) : (
              <FormField
                name={`priceTiers[${idx}].time`}
                timezone={timezoneName}
                control="datetime"
                value={idx === 0 && canEdit ? ttyOnSaleDate : tier.time}
                setFieldValue={setFieldValue}
                locale={locale}
                onBlur={handleBlur}
                error={idx === 0 ? undefined : get(`priceTiers[${idx}].time`, errors)}
                required
                disabled={!canEdit || idx === 0}
              />
            )}
            {isItalian && !isStream && (
              <EventTicketTypePriceTypeSelect
                name={`priceTiers[${idx}].attractivePriceType`}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                values={tier}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
                disabled={!canEditPrice}
                label={null}
                required
              />
            )}
            <FlexFormField
              name={`priceTiers[${idx}].faceValue`}
              currency={currency}
              value={tier.faceValue}
              onChange={handleChange}
              onBlur={handleBlur}
              error={get(`priceTiers[${idx}].faceValue`, errors)}
              required
              disabled={!canEditPrice}
            >
              <RemoveButton
                icon="trash"
                disabled={!canRemove}
                onClick={removeTier}
                data-id={`removePriceTier[${idx}]`}
              />
            </FlexFormField>
          </FormRow>
          {showAppendToContractFees && (tier.fees?.length || 0) > 0 && (
            <StyledCollapsible
              initialCollapsed={!tier.fees || sumBy('amount', tier.fees) === 0}
              isPadded={priceTierType === 'allocation'}
              label={intl.formatMessage({ id: 'fees' })}
            >
              {chunk(3, tier.fees || []).map((ch: Array<IFee | null>, cidx) => (
                <FormRow key={cidx} columnOnMobile>
                  {ch.map((fee, index) => (
                    <FormField
                      name={`priceTiers[${idx}].fees[${cidx * 3 + index}].amount`}
                      key={fee?.type}
                      currency={currency}
                      label={intl.formatMessage({ id: `fees.${fee?.type}` })}
                      value={get(`fees[${cidx * 3 + index}].amount`, tier)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!canEditPrice}
                      required
                    />
                  ))}
                </FormRow>
              ))}
              <FormRow>
                <div>&nbsp;</div>
              </FormRow>
            </StyledCollapsible>
          )}
        </Form>
      </PriceTier>
    )
  }
)

const EventTicketTypePriceTiers = SortableContainer<IProps>(
  ({
    currency,
    tiers,
    tiersSales,
    priceTierType,
    handleChange,
    onRemove,
    setFieldValue,
    validateForm,
    timezoneName,
    handleBlur,
    ticketPool,
    touched,
    errors,
    canReorder,
    ttyOnSaleDate,
    canRename,
    canRemove,
    canEdit,
    canEditFirstTier,
    canEditPrice,
    canEditAllocation,
    sortOnlyNew,
    isItalian,
    isStream,
    showAppendToContractFees,
    children,
    salesLimit,
  }: IProps) => {
    const intl = useIntl()

    // When a user adds a new tier, and event is on sale, we want the tier that was previously the last one
    //  (and previously infinite allocation) to be editable so they can add an allocation (PX-1333)
    //  This is only relevant for ticket pool events
    const isPreviouslyLastTier = useCallback(
      (index: number) => {
        if (!ticketPool?.id) return false
        const newTiersExist = !!find((tier) => tier.id.startsWith('new'), tiers)
        if (!newTiersExist) return false
        const existingTiers = filter((tier) => !tier.id.startsWith('new'), tiers)
        return index === existingTiers.length - 1
      },
      [ticketPool?.id, tiers]
    )

    return (
      <Container>
        {tiers.length > 0 ? (
          <>
            <OnDesktop>
              <Form spacing="small">
                <FormRow columnOnMobile spacing="small">
                  <PaddedFormGroup
                    required
                    label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.label' })}
                    padding={priceTierType === 'allocation' && !ticketPool ? 40 : 0}
                  />
                  <FormGroup
                    required
                    label={
                      priceTierType === 'allocation'
                        ? !!ticketPool
                          ? intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.allocation_limit.label' })
                          : intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.allocation.label' })
                        : intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.time.label' })
                    }
                  />
                  {isItalian && !isStream && (
                    <FormGroup required label={intl.formatMessage({ id: 'new_event.tickets.price_type.label' })} />
                  )}
                  <FormGroup
                    required
                    label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.price.label' })}
                  />
                </FormRow>
              </Form>
            </OnDesktop>
            <ul>
              {tiers.map((tier, idx) => (
                <PriceTierRow
                  key={tier.id}
                  tier={tier}
                  tierSales={find((pt) => pt?.priceTier.id === tier.id, tiersSales) || null}
                  idx={idx}
                  index={idx}
                  last={idx === tiers.length - 1}
                  collection={sortOnlyNew && tier.id.startsWith('new') ? 1 : 0}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  currency={currency}
                  priceTierType={priceTierType}
                  onRemove={onRemove}
                  timezoneName={timezoneName}
                  setFieldValue={setFieldValue}
                  validateForm={validateForm}
                  ticketPool={ticketPool}
                  touched={touched}
                  errors={errors}
                  canEditPrice={canEditPrice || tier.id.startsWith('new')}
                  canEditAllocation={canEditAllocation}
                  canRemove={(canRemove && tiers.length > 1) || tier.id.startsWith('new')}
                  canMove={canReorder || (sortOnlyNew && tier.id.startsWith('new'))}
                  canEdit={
                    (idx === 0 ? canEditFirstTier : canEdit) || tier.id.startsWith('new') || isPreviouslyLastTier(idx)
                  }
                  canRename={canRename || tier.id.startsWith('new')}
                  disabled={!canReorder && !(sortOnlyNew && tier.id.startsWith('new'))}
                  ttyOnSaleDate={ttyOnSaleDate}
                  isItalian={isItalian}
                  isStream={isStream}
                  showAppendToContractFees={showAppendToContractFees}
                  salesLimit={salesLimit}
                />
              ))}
            </ul>
          </>
        ) : (
          <Text color="error">
            {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.min_tiers_error' })}
          </Text>
        )}
        {children}
      </Container>
    )
  }
)

export default EventTicketTypePriceTiers
