import React, { FC, useMemo } from 'react'
import cn from 'classnames'
import { nanoid } from 'nanoid'
import styled from 'styled-components/macro'

import ButtonBase from './ButtonBase'
import Icon from './Svg'
import { Loader } from './Loader'
import { TitleTooltip } from './Tooltip'

interface IProps {
  className?: string
  icon: string
  width?: number
  height?: number
  iconClassName?: string
  loading?: boolean
  color?: string
  outlineColor?: string
  titlePlacement?: 'top' | 'bottom' | 'left' | 'right'
  onTitleVisibleChange?: (visible: boolean) => void
}

const IconButton: FC<React.PropsWithChildren<IProps & { [prop: string]: any }>> = ({
  className,
  icon,
  width,
  height,
  iconClassName,
  loading,
  color,
  outlineColor,
  title,
  titlePlacement,
  onTitleVisibleChange,
  ...rest
}) => {
  const tooltipId = useMemo(() => (title ? nanoid() : undefined), [title])

  const btn = (
    <ButtonBase
      className={cn(
        'icon-button',
        {
          [`-outline-color-${outlineColor}`]: outlineColor,
          [`-color-${color}`]: color,
        },
        className
      )}
      loading={loading}
      aria-describedby={tooltipId}
      {...rest}
    >
      {loading ? (
        <Loader className="icon-button_loader" size="small" />
      ) : (
        <div className="icon-button_container">
          <Icon icon={icon} className={cn('icon-button_icon', iconClassName)} width={width} height={height} />
        </div>
      )}
    </ButtonBase>
  )

  return title ? (
    <TitleTooltip
      id={tooltipId}
      title={title}
      placement={titlePlacement || 'top'}
      onVisibleChange={onTitleVisibleChange}
    >
      {btn}
    </TitleTooltip>
  ) : (
    btn
  )
}

export default styled(IconButton)``
