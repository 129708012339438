import React, { FC, memo, useContext, useMemo } from 'react'
import { keys } from 'lodash/fp'
import styled from 'styled-components/macro'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { addHours, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { color, mediaQuery } from '../utils/variables'
import { authContext } from '../context/auth'
import AlertBox, { AlertBoxContent } from './AlertBox'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'
import { localeContext } from '../context/locale'
import { PayoutBlockers_event$key } from '../__generated__/PayoutBlockers_event.graphql'
import { extractFocusedBlockers, IBlockers, ISeriousBlockers } from '../utils/payoutBlockers'

const SAlertBox = styled(AlertBox)`
  margin: -8px 0 16px 0;

  ${AlertBoxContent} {
    display: flex;
    align-items: center;
  }

  ${mediaQuery.lessThan('tablet')`
    margin: -8px 0 32px 0;
  `}
`

interface IProps {
  event: PayoutBlockers_event$key | null
  seriousBlockers: ISeriousBlockers | null | undefined
  blockers: IBlockers | null | undefined
}

const PayoutBlockers: FC<IProps> = ({ event: eventKey, blockers, seriousBlockers }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const event = useFragment(
    graphql`
      fragment PayoutBlockers_event on Event {
        endDate
        timezoneName
      }
    `,
    eventKey
  )

  const [diceBlocker, selfBlocker] = useMemo(
    () => extractFocusedBlockers(user.diceStaff, blockers || null, seriousBlockers || null),
    [blockers, seriousBlockers, user.diceStaff]
  )

  return (
    <>
      {diceBlocker && (
        <SAlertBox fullWidth color={color.tertiary} icon="dice-badge">
          <span data-dice-blocker={diceBlocker} data-blockers={keys(blockers)} data-problems={keys(seriousBlockers)}>
            {intl.formatMessage({
              id: `event_payouts.payout_blockers.${diceBlocker}`,
              defaultMessage: diceBlocker,
            })}
          </span>
        </SAlertBox>
      )}
      {selfBlocker && (
        <SAlertBox fullWidth color={color.warning}>
          <span data-self-blocker={selfBlocker} data-blockers={keys(blockers)} data-problems={keys(seriousBlockers)}>
            {intl.formatMessage(
              {
                id: `event_payouts.payout_blockers.${selfBlocker}`,
                defaultMessage: selfBlocker,
              },
              {
                // prettier-ignore
                payoutDate: event?.endDate
                  ? intl.formatDate(addHours(parseISO(event.endDate), 48), {
                    ...DATETIME_FORMATS.DATETIME(locale),
                    timeZone: event.timezoneName || user.timezoneName,
                  })
                  : intl.formatMessage({ id: 'na' }),
              }
            )}
          </span>
        </SAlertBox>
      )}
    </>
  )
}

export default memo(PayoutBlockers)
