/**
 * Types relating to promoter default timings, used for auto-filling dates based on other dates
 */

import { EventTimingField, EventTimingType } from '../../../enums.generated'

export const TICKET_TYPE_DATES = ['TICKET_TYPE_ANNOUNCE_DATE', 'TICKET_TYPE_ON_SALE_DATE', 'TICKET_TYPE_OFF_SALE_DATE']

export interface EventDefaultTiming {
  offset: number | null
  sourceField: EventTimingField | null
  targetField: EventTimingField | null
  type: EventTimingType | null
}

interface TimelineDefaultFieldMeta {
  fieldName: any
  location: 'event' | 'ticketType'
  // Any prerequisite fields that need specific values for fieldName to be editable
  dependencies?: {
    fieldName: string
    value: any
  }[]
  // fields to use instead of fieldName in allowedEventAction() function
  allowedUpdatesOverride?: {
    action: string
    field?: string
  }
}
export const DEFAULTS_FIELDS_MAP: { [key: string]: TimelineDefaultFieldMeta } = {
  ANNOUNCE_DATE: {
    fieldName: 'announceDate',
    location: 'event',
  },
  DATE: {
    fieldName: 'date',
    location: 'event',
  },
  END_DATE: {
    fieldName: 'endDate',
    location: 'event',
  },
  OFF_SALE_DATE: {
    fieldName: 'offSaleDate',
    location: 'event',
  },
  ON_SALE_DATE: {
    fieldName: 'onSaleDate',
    location: 'event',
  },
  RETURN_DEADLINE: {
    fieldName: 'flags.enabledPwl.deadline',
    location: 'event',
    dependencies: [
      {
        fieldName: 'flags.enabledPwl.active',
        value: true,
      },
    ],
    allowedUpdatesOverride: { action: 'endDate' },
  },
  TICKET_TYPE_ANNOUNCE_DATE: {
    fieldName: 'announceDate',
    location: 'ticketType',
    allowedUpdatesOverride: {
      action: 'ticketTypes',
      field: 'canChangeOnSaleDate',
    },
  },
  TICKET_TYPE_OFF_SALE_DATE: {
    fieldName: 'offSaleDate',
    location: 'ticketType',
    allowedUpdatesOverride: {
      action: 'ticketTypes',
      field: 'canChangeOffSaleDate',
    },
  },
  TICKET_TYPE_ON_SALE_DATE: {
    fieldName: 'onSaleDate',
    location: 'ticketType',
    allowedUpdatesOverride: {
      action: 'ticketTypes',
      field: 'canChangeOnSaleDate',
    },
  },
  TRANSFER_DEADLINE: {
    fieldName: 'closeEventDate',
    location: 'event',
  },
  ON_SALE_REMINDER_TIME: {
    fieldName: 'onSaleNotificationAt',
    location: 'event',
    dependencies: [
      {
        fieldName: 'onSaleNotification',
        value: true,
      },
    ],
  },
}
