import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { isFuture, parseISO } from 'date-fns'
import { getOr } from 'lodash/fp'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import cn from 'classnames'
import styled from 'styled-components/macro'

import { useIntl } from 'react-intl'
import {
  Card,
  Col,
  EventListCard,
  EventListCardInfo,
  EventListCardTitleWrapper,
  EventListRow,
  EventName,
  ExtraCol,
  MainCol,
  RowItem,
} from './EventListItemGrid'

import { color } from '../../../utils/variables'
import EventPartImage, { EventListCardImage } from './EventPartImage'
import EventPartStatus from './EventPartStatus'
import EventPartDateTime from './EventPartDateTime'
import EventPartVenue from './EventPartVenue'
import EventPartDraftProgress from './EventPartDraftProgress'
import EventPartSales from './EventPartSales'
import EventPartRevenue from './EventPartRevenue'

import { OnDesktop, OnDesktopLarge, OnMobile, LessThanDesktopLarge } from '../../../components/Breakpoints'

import QuickActionsDrafts from './QuickActions/QuickActionsDrafts'
import QuickActionsLive from './QuickActions/QuickActionsLive'
import QuickActionsPast from './QuickActions/QuickActionsPast'
import { EventListItem_event$key } from '../../../__generated__/EventListItem_event.graphql'
import EventListTypeContext from '../util/eventListTypeContext'
import { authContext } from '../../../context/auth'
import Badge from '../../../components/Badge'

const Placeholder = styled.div`
  width: 16px;
`

const TestEventBadge = styled(Badge)`
  background-color: ${color.lightyellow};
`

const TestEventImage = styled.div`
  width: 72px;
  height: 72px;
  flex: 72px 0 0;
  overflow: hidden;
  position: relative;
  margin: 0 16px 0 0;
  border-radius: 8px;

  ${EventListCardImage} {
    margin: 0;
  }

  ${TestEventBadge} {
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    position: absolute;
    text-align: center;
  }
`

const Tags = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`

const MobileTags = styled(EventListRow)`
  margin-top: -10px;
`

interface IProps {
  event: EventListItem_event$key
  stripeIsBroken: boolean
}

const EventListItem: FC<IProps> = ({ event: eventKey, stripeIsBroken }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const listType = useContext(EventListTypeContext)
  const { account } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment EventListItem_event on Event
      @argumentDefinitions(
        noSelfPayouts: { type: "Boolean", defaultValue: false }
        needBalance: { type: "Boolean", defaultValue: false }
        isDraft: { type: "Boolean", defaultValue: true }
      ) {
        id
        name
        state
        date
        isTest
        endDate
        timezoneName
        statusAsOfNow
        eventIdLive
        flags {
          hidden
        }

        ...EventPartDraftProgress_event
        ...EventPartImage_event

        ...EventPartStatus_event
          @arguments(needBalance: $needBalance, isDraft: $isDraft, hideFreeTag: $needBalance)
          @skip(if: $noSelfPayouts)

        ...EventPartVenue_event
        ...EventPartSales_event @skip(if: $isDraft)
        ...EventPartRevenue_event @skip(if: $isDraft) @arguments(hideFreeTag: $needBalance)

        ...QuickActionsPast_event @arguments(noSelfPayouts: $noSelfPayouts) @include(if: $needBalance)
        ...QuickActionsLive_event @skip(if: $isDraft)
        ...QuickActionsDrafts_event @include(if: $isDraft)
      }
    `,
    eventKey
  )

  const type = useMemo(() => {
    if (
      listType === 'draft' ||
      event.state === 'DRAFT' ||
      event.statusAsOfNow === 'to-be-approved' ||
      (event.state === 'APPROVED' && !event.eventIdLive)
    )
      return 'draft'
    if (event.state === 'CANCELLED') return 'cancelled'
    if (event.endDate && isFuture(parseISO(event.endDate))) return 'live'
    return 'past'
  }, [event.endDate, event.eventIdLive, event.state, event.statusAsOfNow, listType])

  const eventPath = useMemo(
    () =>
      event.state === 'DRAFT'
        ? `/events/${event.id}/edit`
        : event.statusAsOfNow === 'to-be-approved'
          ? `/events/${event.id}/details`
          : `/events/${event.id}/overview`,
    [event]
  )

  const hidden = getOr(false, 'flags.hidden.active', event)

  const goToEvent = useCallback(() => {
    navigate(eventPath)
  }, [navigate, eventPath])

  const preventClick = useCallback((e: any) => e.stopPropagation(), [])

  const canSeeStatus = listType !== 'past' || !account || !account.forbidSelfPayouts

  return (
    <>
      <OnDesktop>
        <RowItem isHidden={hidden} onClick={goToEvent}>
          <MainCol>
            <Col grow>
              <OnDesktopLarge>
                {event.isTest ? (
                  <TestEventImage>
                    <EventPartImage showHidden event={event} />
                    <TestEventBadge>{intl.formatMessage({ id: 'test' })}</TestEventBadge>
                  </TestEventImage>
                ) : (
                  <EventPartImage showHidden event={event} />
                )}
              </OnDesktopLarge>
              <Card onClick={preventClick}>
                <EventName to={eventPath} title={event.name || undefined}>
                  {event.name}
                </EventName>
                <Tags>
                  {canSeeStatus && <EventPartStatus showHidden event={event} />}
                  {event.isTest && (
                    <LessThanDesktopLarge>
                      <TestEventBadge>{intl.formatMessage({ id: 'test' })}</TestEventBadge>
                    </LessThanDesktopLarge>
                  )}
                </Tags>
              </Card>
            </Col>
            <Col>
              <EventPartDateTime date={event.date} timezoneName={event.timezoneName} />
            </Col>
          </MainCol>
          <ExtraCol>
            <OnDesktopLarge>
              <Col>
                <EventPartVenue event={event} />
              </Col>
            </OnDesktopLarge>
            {type === 'draft' ? (
              <Col grow>
                <EventPartDraftProgress event={event} />
                <QuickActionsDrafts event={event} stripeIsBroken={stripeIsBroken} />
              </Col>
            ) : (
              <Col grow>
                <Col grow>
                  <EventPartSales event={event} />
                </Col>
                <Col grow className={cn(type !== 'cancelled' && '-controls')}>
                  <EventPartRevenue event={event} textAlign="right" grow />
                  {type === 'live' ? (
                    <QuickActionsLive event={event} stripeIsBroken={stripeIsBroken} />
                  ) : (
                    type !== 'cancelled' && <QuickActionsPast event={event} stripeIsBroken={stripeIsBroken} />
                  )}
                </Col>
              </Col>
            )}
          </ExtraCol>
        </RowItem>
      </OnDesktop>
      <OnMobile>
        <EventListCard isHidden={hidden}>
          <EventListRow>
            <EventListCardInfo>
              <EventListCardTitleWrapper>
                {canSeeStatus ? <EventPartStatus noTitle event={event} /> : <Placeholder />}
                <EventName to={eventPath}>{event.name}</EventName>
              </EventListCardTitleWrapper>
            </EventListCardInfo>
          </EventListRow>
          {event.isTest && (
            <MobileTags>
              <TestEventBadge>{intl.formatMessage({ id: 'test' })}</TestEventBadge>
            </MobileTags>
          )}
          <EventListRow>
            <EventPartDateTime date={event.date} timezoneName={event.timezoneName} />
          </EventListRow>
          {type === 'draft' ? (
            <>
              <EventListRow>
                <EventPartDraftProgress event={event} />
              </EventListRow>
              <QuickActionsDrafts event={event} stripeIsBroken={stripeIsBroken} />
            </>
          ) : (
            <>
              <EventListRow>
                <EventPartSales event={event} />
                <EventPartRevenue textAlign="right" event={event} />
              </EventListRow>
              {type === 'live' ? (
                <QuickActionsLive event={event} stripeIsBroken={stripeIsBroken} />
              ) : (
                type !== 'cancelled' && <QuickActionsPast event={event} stripeIsBroken={stripeIsBroken} />
              )}
            </>
          )}
        </EventListCard>
      </OnMobile>
    </>
  )
}

export default EventListItem
