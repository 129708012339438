import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import { filter, get } from 'lodash/fp'
import { FormattedMessage } from 'react-intl'

import graphqlOptionsLoader from '../graphqlOptionsLoader'

export const DEFAULT_ACCOUNT_FILTER_VALUE: IAccountOption = {
  value: 'default_filter_value',
  label: <FormattedMessage id="all_accounts" />,
}

const APPLIED_ACCOUNT_FILTER_QUERY = graphql`
  query useAccountOptionsAppliedQuery($where: PromoterWhereInput) {
    viewer {
      promoters(where: $where, first: 1) {
        edges {
          node {
            value: id
            label: name
          }
        }
      }
    }
  }
`

const ACCOUNT_QUERY = graphql`
  query useAccountOptionsLoaderQuery($searchTerm: String) {
    viewer {
      options: promoters(searchTerm: $searchTerm, first: 50) {
        edges {
          node {
            value: id
            label: name
          }
        }
      }
    }
  }
`

interface IAccountOption {
  value: string
  label: ReactNode
}

function useAccountOptions(initialAccountId?: string | null) {
  const environment = useRelayEnvironment()
  const [accountsOptions, setAccountsOptions] = useState<IAccountOption[]>([])

  const accountLoaderImmediate = useMemo(
    () => graphqlOptionsLoader(environment, ACCOUNT_QUERY, { immediate: true }),
    [environment]
  )

  // Get accounts
  useEffect(() => {
    let stillMounted = true
    if (initialAccountId) {
      fetchQuery_DEPRECATED(environment, APPLIED_ACCOUNT_FILTER_QUERY, {
        where: { id: { eq: initialAccountId } },
      }).then((data) => {
        accountLoaderImmediate('', (result) => {
          if (stillMounted) {
            const accountFilter = get('viewer.promoters.edges[0].node', data)
            const filteredOptions = filter((v) => v.value !== accountFilter.value, result)
            setAccountsOptions([DEFAULT_ACCOUNT_FILTER_VALUE, accountFilter, ...filteredOptions])
          }
        })
      })
    } else {
      accountLoaderImmediate('', (result) => {
        if (stillMounted) {
          setAccountsOptions([DEFAULT_ACCOUNT_FILTER_VALUE, ...result])
        }
      })
    }
    return () => {
      stillMounted = false
    }
  }, [accountLoaderImmediate, environment, initialAccountId, setAccountsOptions])

  const accountLoader = useMemo(
    () => graphqlOptionsLoader(environment, ACCOUNT_QUERY, { fullText: true }),
    [environment]
  )

  return { accountsOptions, accountLoader, allAccountsOption: DEFAULT_ACCOUNT_FILTER_VALUE }
}

export default useAccountOptions
