/**
 * @generated SignedSource<<2a105008c2960f76a88d4128816fbd89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type auth_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useImpersonation_viewer" | "usePermissions_viewer" | "useSwitchAccount_viewer" | "useUserInfo_viewer">;
  readonly " $fragmentType": "auth_viewer";
};
export type auth_viewer$key = {
  readonly " $data"?: auth_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"auth_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "auth_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useUserInfo_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSwitchAccount_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useImpersonation_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissions_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "a9872a2e3cdd7e3203cdeb6198cc76bb";

export default node;
