import React, { FC, memo, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'

import { authContext } from '../../../context/auth'
import { IFactory, preload, SIDEBAR_ROUTER } from '../services/sidebarNav'
import CollapsibleSidebarList from './CollapsibleSidebarList'
import { SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'
import { featureFlagsContext } from '../../../context/featureFlags'

const Internal: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user, account, hasPermission } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)

  const toPromotersList = useCallback(() => navigate(SIDEBAR_ROUTER.promoters[0] as string), [navigate])
  const toVenueList = useCallback(() => navigate(SIDEBAR_ROUTER.venues[0] as string), [navigate])
  const toArtistsList = useCallback(() => navigate(SIDEBAR_ROUTER.artists[0] as string), [navigate])

  const allowIntegrations =
    (account &&
      ((account.countryCode === 'FR' && hasPermission('nf525:balances')) ||
        hasPermission(['update:account', 'create_facebook:marketing']) ||
        hasPermission(['update:account']) ||
        hasPermission(['mailchimp:marketing']))) ||
    hasPermission('allow_third_party_access:account')

  return user.diceStaff ? (
    <>
      <SidebarMenuItem
        dice
        active={pathname && pathname.startsWith(SIDEBAR_ROUTER.internal.promoters[0] as string)}
        to={SIDEBAR_ROUTER.internal.promoters[0]}
        data-id="sidebarPromoters"
        onMouseEnter={preload(SIDEBAR_ROUTER.internal.promoters[1] as IFactory)}
        onReload={toPromotersList}
      >
        <SidebarMenuIcon icon="promoter" />

        <span>{intl.formatMessage({ id: 'sidebar.promoters' })}</span>
      </SidebarMenuItem>
      <SidebarMenuItem
        dice
        active={pathname && pathname.startsWith(SIDEBAR_ROUTER.internal.venues[0] as string)}
        to={SIDEBAR_ROUTER.internal.venues[0]}
        data-id="sidebarVenues"
        onMouseEnter={preload(SIDEBAR_ROUTER.internal.venues[1] as IFactory)}
        onReload={toVenueList}
      >
        <SidebarMenuIcon icon="map-marker" />
        <span>{intl.formatMessage({ id: 'sidebar.venues' })}</span>
      </SidebarMenuItem>

      <SidebarMenuItem
        dice
        active={pathname && pathname.startsWith(SIDEBAR_ROUTER.internal.artists[0] as string)}
        to={SIDEBAR_ROUTER.internal.artists[0]}
        data-id="sidebarArtists"
        onMouseEnter={preload(SIDEBAR_ROUTER.internal.artists[1] as IFactory)}
        onReload={toArtistsList}
      >
        <SidebarMenuIcon icon="artist" />

        <span>{intl.formatMessage({ id: 'sidebar.artists' })}</span>
      </SidebarMenuItem>

      <SidebarMenuItem
        dice
        active={
          pathname === SIDEBAR_ROUTER.internal.users[0] ||
          pathname === SIDEBAR_ROUTER.internal.usersInviteUser[0] ||
          pathname === SIDEBAR_ROUTER.internal.permissions[0] ||
          pathname === SIDEBAR_ROUTER.internal.permissionsNewProfile[0]
        }
        to={SIDEBAR_ROUTER.internal.users[0]}
        data-id="sidebarUsersAndPermissions"
        onMouseEnter={preload(SIDEBAR_ROUTER.internal.users[1] as IFactory)}
      >
        <SidebarMenuIcon icon="user" />
        <span>{intl.formatMessage({ id: 'sidebar.users' })}</span>
      </SidebarMenuItem>

      {user.diceStaff && (
        <SidebarMenuItem
          dice
          active={pathname === SIDEBAR_ROUTER.internal.hierarchy[0] || pathname === SIDEBAR_ROUTER.internal.tags[0]}
          to={SIDEBAR_ROUTER.internal.hierarchy[0]}
          data-id="sidebarTagsAndHierarchy"
          onMouseEnter={preload(SIDEBAR_ROUTER.internal.hierarchy[1] as IFactory)}
        >
          <SidebarMenuIcon icon="tag" />

          <span>{intl.formatMessage({ id: 'sidebar.tags' })}</span>
        </SidebarMenuItem>
      )}
      {allowIntegrations && (
        <SidebarMenuItem
          dice
          active={pathname === SIDEBAR_ROUTER.tools.integrations[0]}
          to={SIDEBAR_ROUTER.tools.integrations[0]}
          data-id="sidebarIntegrations"
          onMouseEnter={preload(SIDEBAR_ROUTER.tools.integrations[1] as IFactory)}
        >
          <SidebarMenuIcon icon="archive" />
          <span>{intl.formatMessage({ id: 'sidebar.integrations' })}</span>
        </SidebarMenuItem>
      )}
      {user.diceStaff && hasFeatureFlag('devSettings') && (
        <SidebarMenuItem
          dice
          active={pathname === SIDEBAR_ROUTER.tools.inspector[0]}
          to={SIDEBAR_ROUTER.tools.inspector[0]}
          data-id="sidebarPlatformInspector"
          onMouseEnter={preload(SIDEBAR_ROUTER.tools.inspector[1] as IFactory)}
        >
          <SidebarMenuIcon icon="bulb" />
          <span>{intl.formatMessage({ id: 'sidebar.inspector' })}</span>
        </SidebarMenuItem>
      )}
    </>
  ) : null
}

export default memo(Internal)
