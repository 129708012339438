export const allowedEventAction = <U>(
  allowedLifecycleUpdates: null | U,
  action: keyof U | 'forbidden',
  field = 'canUpdate'
): boolean => {
  // Can be overridden by dice staff
  if (!allowedLifecycleUpdates) return true

  // Forever forbidden BUT STILL can be overridden by dice staff
  if (action === 'forbidden') return false

  // Otherwise do whatever backnd says to do
  return !!(allowedLifecycleUpdates[action] as any)?.[field]
}
