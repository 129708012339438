import React, { FC, memo, useCallback, useMemo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { get, set, map, concat, find, findIndex, without } from 'lodash/fp'
import ReactPlayer from 'react-player/lazy'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import { useRelayEnvironment } from 'react-relay'

import { FormRow } from '../../../components/Form'
import simplifyLabel from '../../../utils/simplifyLabel'
import useInformationMusic from '../hooks/useInformationMusic'
import { allowedEventAction } from '../services/allowedEventAction'
import IEventFormInformation from '../types/Information'
import EventTrackPreview from './EventTrackPreview'
import FormField from '../../../components/FormField'
import Warning from '../../../components/Warning'
import { searchAppleMusicTrackFn, searchSpotifyTrackFn } from '../../../utils/trackSearch'
import Collapsible from '../../../components/Collapsible'
import { breakpoints } from '../../../utils/variables'
import { Loader, LoaderContainer } from '../../../components/Loader'
import { authContext } from '../../../context/auth'
import { useDebounce } from '../../../utils/hooks/useDebounce'

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IProps {
  readOnly?: boolean
}

const EventMedia: FC<IProps> = ({ readOnly }) => {
  const intl = useIntl()
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })
  const { user } = useContext(authContext)
  const environment = useRelayEnvironment()

  const { values, handleBlur, touched, errors, setFieldValue } = useFormikContext<IEventFormInformation>()

  const trailerUrl = useMemo(() => {
    const url = find(['type', 'trailer'], values.media || [])?.values?.video
    return url ? (url as string) : null
  }, [values.media])
  const trailerIdx = useMemo(() => findIndex(['type', 'trailer'], values.media || []), [values.media])

  const onChangeTrailer = useCallback(
    (e: any) => {
      const url = (e.target.value || '').trim()

      const media = values.media || []

      if (!url && trailerIdx >= 0) {
        setFieldValue('media', without([media[trailerIdx]], media))
      } else if (!url && trailerIdx < 0) {
        // Do nothing
      } else if (url && trailerIdx >= 0) {
        setFieldValue(
          'media',
          map((it) => (it === media[trailerIdx] ? it && set('values.video', url, it) : it), media)
        )
      } else if (url && trailerIdx < 0) {
        setFieldValue(
          'media',
          concat(media, [
            {
              id: null,
              type: 'trailer',
              values: { video: url },
            },
          ])
        )
      }
    },
    [setFieldValue, trailerIdx, values.media]
  )

  const {
    searchTracks,
    setTracks,
    removeTracks,
    spotifyTrack,
    appleMusicTrack,
    removeAppleMusic,
    removeSpotify,
    selectAppleMusicTrack,
    selectSpotifyTrack,
    trackWarn,
  } = useInformationMusic()

  const combinedTrack = useMemo(() => {
    if (
      !(
        spotifyTrack &&
        appleMusicTrack &&
        (spotifyTrack.uid || simplifyLabel(spotifyTrack.label || spotifyTrack.name || '')) ===
          (appleMusicTrack.uid || simplifyLabel(appleMusicTrack.label || appleMusicTrack.name || ''))
      )
    )
      return null
    return {
      open_url: spotifyTrack.open_url || appleMusicTrack.open_url,
      preview_url: spotifyTrack.preview_url || appleMusicTrack.preview_url,
      image: spotifyTrack.image || appleMusicTrack.image,
      name: spotifyTrack.name || appleMusicTrack.name,
      value: spotifyTrack.value || appleMusicTrack.value,
      label: spotifyTrack.label || appleMusicTrack.label,
      uid: spotifyTrack.uid || appleMusicTrack.uid,
    }
  }, [appleMusicTrack, spotifyTrack])

  const musicSearchFn = useMemo(() => {
    if (spotifyTrack) return searchAppleMusicTrackFn(environment)
    if (appleMusicTrack) return searchSpotifyTrackFn(environment)
    return searchTracks
  }, [appleMusicTrack, environment, searchTracks, spotifyTrack])

  const musicSetFn = useMemo(() => {
    if (spotifyTrack) return selectAppleMusicTrack
    if (appleMusicTrack) return selectSpotifyTrack
    return setTracks
  }, [appleMusicTrack, selectAppleMusicTrack, selectSpotifyTrack, setTracks, spotifyTrack])

  const musicSearchLabel = useMemo(() => {
    if (spotifyTrack) return intl.formatMessage({ id: 'new_event.information.audio_tracks.label.apple' })
    if (appleMusicTrack) return intl.formatMessage({ id: 'new_event.information.audio_tracks.label.spotify' })
    return intl.formatMessage({ id: 'new_event.information.audio_tracks.label' })
  }, [appleMusicTrack, intl, spotifyTrack])

  const debouncedTrailerUrl = useDebounce(trailerUrl, 500)

  return (
    <>
      {combinedTrack ? (
        <FormRow columnOnMobile>
          <EventTrackPreview
            track={combinedTrack}
            type="combinedTrack"
            onRemove={removeTracks}
            allowEdit={!readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'media')}
          />
        </FormRow>
      ) : (
        <>
          {spotifyTrack && (
            <FormRow columnOnMobile>
              <EventTrackPreview
                track={spotifyTrack}
                type="spotifyTrack"
                onRemove={removeSpotify}
                allowEdit={!readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'media')}
              />
            </FormRow>
          )}

          {appleMusicTrack && (
            <FormRow columnOnMobile>
              <EventTrackPreview
                track={appleMusicTrack}
                type="appleMusicTrack"
                onRemove={removeAppleMusic}
                allowEdit={!readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'media')}
              />
            </FormRow>
          )}
        </>
      )}
      {trackWarn && (
        <FormRow columnOnMobile>
          <Warning>{intl.formatMessage({ id: `new_event.information.audio_tracks.warn.${trackWarn}` })}</Warning>
        </FormRow>
      )}
      {!combinedTrack && !(appleMusicTrack && spotifyTrack) && (
        <FormRow columnOnMobile>
          <FormField
            name="trackPreview"
            control="select"
            label={musicSearchLabel}
            placeholder={intl.formatMessage({ id: 'new_event.information.audio_tracks.search.placeholder' })}
            hint={intl.formatMessage({ id: 'new_event.information.audio_tracks.search.hint' })}
            value={null}
            onChange={musicSetFn}
            onBlur={handleBlur}
            disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'media')}
            searchable
            async
            loadOptions={musicSearchFn}
          />
        </FormRow>
      )}

      {user.diceStaff && (
        <FormRow columnOnMobile>
          <div>
            <FormField
              dice
              name="trailerUrl"
              label={intl.formatMessage({ id: 'new_event.information.trailer_url.label' })}
              value={trailerUrl || ''}
              onChange={onChangeTrailer}
              onBlur={handleBlur}
              error={get('trailerUrl', touched) && get('trailerUrl', errors)}
              disabled={readOnly}
              placeholder="https://"
            />
            {debouncedTrailerUrl && !get('trailerUrl', errors) && (
              <Collapsible
                initialCollapsed={isMobile}
                label={intl.formatMessage({ id: 'new_event.information.trailer_preview.label' })}
              >
                <Center>
                  <ReactPlayer
                    controls
                    loop
                    playsinline
                    url={debouncedTrailerUrl}
                    fallback={
                      <LoaderContainer>
                        <Loader />
                      </LoaderContainer>
                    }
                  />
                </Center>
              </Collapsible>
            )}
          </div>
        </FormRow>
      )}
    </>
  )
}

export default memo(EventMedia)
