import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { textStyle } from '../utils/typography'
import { color } from '../utils/variables'
import Svg from './Svg'

const DangerContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: rgba(255, 28, 94, 0.06);
  border-radius: 4px;

  ${textStyle.functional.sm}

  && {
    color: ${color.text};
  }

  & > svg {
    color: ${color.error};
    margin-top: -4px;
    margin-right: 4px;
    min-width: 24px;
  }
`

const Danger: FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <DangerContainer className={className}>
    <Svg icon="warning" />
    <div>{children}</div>
  </DangerContainer>
)

export default styled(Danger)``
