import React, { FC, useState, useContext, useEffect, memo } from 'react'
import { useIntl } from 'react-intl'
import { compact, concat, isNil, map, reject } from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'

import IEventForm from '../EventForm/types'
import { authContext } from '../../context/auth'
import { IBreakdownInput } from '../EventForm/hooks/usePriceBreakdown'
import { IPriceTier, ITicketType } from '../EventForm/types/Tickets'
import getPriceBreakdown, { IPriceBreakdown } from '../../utils/getPriceBreakdown'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../components/Modal'

interface IFeesModalProps {
  values: IEventForm
  onReject: () => void
  onConfirm: () => void
  firstSync?: boolean
}

const TicketFeesConfirmationModal: FC<React.PropsWithChildren<IFeesModalProps>> = ({
  values,
  onConfirm,
  onReject,
  firstSync = false,
}) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const environment = useRelayEnvironment()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Bypass unless the event is synced or this is the first time syncing
    if (!values.eventIdLive && !firstSync) {
      onConfirm()
      return
    }

    let stillMounted = true

    // Don't include archived ticket types
    const ticketTypes = reject((tty: ITicketType | null) => !!tty?.archived, values.ticketTypes)

    const breakdownInputArrays = ticketTypes.map((tt) => {
      return tt?.priceTierType === null
        ? [
            {
              initialBreakdown: tt.priceBreakdown,
              faceValue: tt.faceValue || 0,
              fee: concat(values.fees || [], tt.fees || []) || [],
              forcePwlActive: tt.priceBreakdown
                ? !isNil(tt.priceBreakdown.totalWithPwl) && tt.priceBreakdown.totalWithPwl === tt.priceBreakdown.total
                : null,
            } as IBreakdownInput,
        ]
        : map(
          (pt: IPriceTier | null) =>
            ({
              initialBreakdown: pt?.priceBreakdown || null,
              faceValue: pt?.faceValue || 0,
              fee: concat(values.fees || [], concat(tt?.fees || [], pt?.fees || [])),
              forcePwlActive: pt?.priceBreakdown
                ? !isNil(pt.priceBreakdown.totalWithPwl) && pt.priceBreakdown.totalWithPwl === pt.priceBreakdown.total
                : null,
            } as IBreakdownInput),
          tt?.priceTiers || []
        )
    })
    const breakdownInputs = breakdownInputArrays.flat(1)

    const venueIds: string[] = compact(map('value', values.venues || []))
    const feesBehaviour = values.feesBehaviour
    const promoterId = values.billingPromoter?.value || null

    const breakdownCtx = {
      venueIds,
      eventId: feesBehaviour === 'OVERRIDE' ? null : values.id,
      billingPromoterId: feesBehaviour === 'OVERRIDE' ? null : promoterId,
      basePriceFees: feesBehaviour === 'OVERRIDE' ? values.basePriceFees : null,
      postFanPriceFees: feesBehaviour === 'OVERRIDE' ? values.postFanPriceFees : null,
      disableUsTax: !!values.disableUsTax,
    }

    const promises = map(
      ({ faceValue, fee, forcePwlActive }) =>
        getPriceBreakdown(environment, {
          billingPromoterId: breakdownCtx.billingPromoterId,
          eventId: breakdownCtx.eventId || null,
          faceValue,
          venueIds,
          fees: fee,
          basePriceFees: breakdownCtx.basePriceFees,
          postFanPriceFees: breakdownCtx.postFanPriceFees,
          ignorePwlFee: !user.diceStaff,
          disableUsTax: breakdownCtx.disableUsTax,
          forcePwlActive,
        }),
      breakdownInputs
    ) as any as Promise<IPriceBreakdown | null>[]

    Promise.all(promises).then((results) => {
      const feelessTicketsExist = results.some((res) => {
        const faceValue = res?.faceValue || 0
        const total = res?.total || 0
        return faceValue > 0 && total - faceValue === 0
      })
      if (feelessTicketsExist) {
        setIsLoading(false)
      } else {
        if (stillMounted) {
          onConfirm()
        }
      }
    })

    return () => {
      stillMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstSync,
    user.diceStaff,
    values.basePriceFees,
    values.billingPromoter?.value,
    values.disableUsTax,
    values.eventIdLive,
    values.fees,
    values.feesBehaviour,
    values.id,
    values.postFanPriceFees,
    values.ticketTypes,
    values.venues,
  ])

  return isLoading ? null : (
    <Modal onClose={onReject} modalTitle={intl.formatMessage({ id: 'ticket_fees_modal.title' })}>
      <ModalBody>
        {intl.formatMessage({
          id: 'ticket_fees_modal.description',
        })}
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl data-id="confirm" onClick={onConfirm}>
          {intl.formatMessage({ id: 'ticket_fees_modal.cta_confirm' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" data-id="cancel" onClick={onReject}>
          {intl.formatMessage({ id: 'ticket_fees_modal.cta_cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(TicketFeesConfirmationModal)
