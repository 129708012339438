import { isPast, parseISO } from 'date-fns'
import { useCallback, useEffect } from 'react'
import { commitLocalUpdate, useRelayEnvironment } from 'react-relay'
import { EventHeaderBasicQuery$data } from '../../../__generated__/EventHeaderBasicQuery.graphql'

const useSetEventListType = (event: EventHeaderBasicQuery$data['event']) => {
  const environment = useRelayEnvironment()

  const setEventListType = useCallback(
    (value: string | null) => {
      commitLocalUpdate(environment, (store) => {
        const viewer = store.getRoot().getLinkedRecord('viewer')
        viewer?.setValue(value, 'eventListType')
      })
    },
    [environment]
  )

  useEffect(() => {
    if (!event) {
      return
    }

    switch (event.state) {
      case 'DRAFT':
        return setEventListType('draft')
      case 'CANCELLED':
        return setEventListType('cancelled')
      case 'REVIEW':
      case 'SUBMITTED':
        return setEventListType('submission')
    }

    if (event.eventIdLive) {
      const isPastEvent = event.endDate && isPast(parseISO(event.endDate))

      setEventListType(isPastEvent ? 'past' : 'live')
    }
  }, [event, setEventListType])
}

export default useSetEventListType
