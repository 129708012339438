/**
 * @generated SignedSource<<b5ce80d101a29f460c4bd32f0473748c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventGuestsAccountQuery$variables = {
  id: string;
};
export type EventGuestsAccountQuery$data = {
  readonly account: {
    readonly customPermissionProfiles?: ReadonlyArray<{
      readonly caption: string;
      readonly diceStaff: boolean;
      readonly roleName: string;
      readonly value: string;
    } | null> | null;
  } | null;
};
export type EventGuestsAccountQuery = {
  response: EventGuestsAccountQuery$data;
  variables: EventGuestsAccountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roleName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "diceStaff",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventGuestsAccountQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "customPermissionProfiles",
                "args": null,
                "concreteType": "PermissionProfile",
                "kind": "LinkedField",
                "name": "accountPermissionProfiles",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Promoter",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventGuestsAccountQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "customPermissionProfiles",
                "args": null,
                "concreteType": "PermissionProfile",
                "kind": "LinkedField",
                "name": "accountPermissionProfiles",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Promoter",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e681c1acfba78421fa45100d88a2fe3",
    "id": null,
    "metadata": {},
    "name": "EventGuestsAccountQuery",
    "operationKind": "query",
    "text": "query EventGuestsAccountQuery(\n  $id: ID!\n) {\n  account: node(id: $id) {\n    __typename\n    ... on Promoter {\n      customPermissionProfiles: accountPermissionProfiles {\n        value: id\n        roleName\n        caption\n        diceStaff\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b9b937629dbfb485870067669da2b2b";

export default node;
