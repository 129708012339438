import React, { FC, memo, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'

import PermissionCheck from '../../../components/PermissionCheck'
import { authContext } from '../../../context/auth'
import { IFactory, preload, SIDEBAR_ROUTER } from '../services/sidebarNav'
import CollapsibleSidebarList from './CollapsibleSidebarList'
import { SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'

const Finances: FC = () => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { user, hasPermission } = useContext(authContext)

  return (
    <>
      <PermissionCheck permission="read:balances">
        <SidebarMenuItem
          active={pathname && pathname.endsWith(SIDEBAR_ROUTER.finance.finances[0] as string)}
          to={SIDEBAR_ROUTER.finance.finances[0]}
          data-id="sidebarFinances"
          onMouseEnter={preload(SIDEBAR_ROUTER.finance.finances[1] as IFactory)}
        >
          <SidebarMenuIcon icon="diamond" />
          <span>{intl.formatMessage({ id: 'sidebar.performance' })}</span>
        </SidebarMenuItem>
      </PermissionCheck>

      <SidebarMenuItem
        active={pathname === SIDEBAR_ROUTER.finance.payouts[0]}
        to={SIDEBAR_ROUTER.finance.payouts[0]}
        data-id="sidebarPayouts"
      >
        <SidebarMenuIcon icon="coins" />
        <span>{intl.formatMessage({ id: 'sidebar.payouts' })}</span>
      </SidebarMenuItem>

      {hasPermission('read_attractive_fiscal_info:event') && user.diceStaff && (
        <SidebarMenuItem
          active={pathname && pathname.startsWith(SIDEBAR_ROUTER.finance.nts[0] as string)}
          to={SIDEBAR_ROUTER.finance.nts[0]}
          data-id="sidebarNts"
          onMouseEnter={preload(SIDEBAR_ROUTER.finance.nts[1] as IFactory)}
        >
          <SidebarMenuIcon icon="tick" />
          <span>{intl.formatMessage({ id: 'sidebar.nts_fiscal_info' })}</span>
        </SidebarMenuItem>
      )}

      {user.diceStaff && hasPermission('read:balances') && (
        <SidebarMenuItem
          dice
          active={pathname === SIDEBAR_ROUTER.finance.managePayouts[0]}
          to={SIDEBAR_ROUTER.finance.managePayouts[0]}
          data-id="sidebarManagePayouts"
        >
          <SidebarMenuIcon icon="coins" />
          <span>{intl.formatMessage({ id: 'sidebar.manage_payouts' })}</span>
        </SidebarMenuItem>
      )}
    </>
  )
}

export default memo(Finances)
