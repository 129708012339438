import React, { Dispatch, FC, memo, SetStateAction, useCallback, useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'

import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { ISignupForm } from '../hooks/useSignupForm'
import { mapsContext } from '../../../context/maps'
import { USA_STATE_OPTIONS } from '../../../utils/usaStates'
import { AgreementCheckbox, AgreementCheckboxItem } from '../../Auth/AuthStyles'
import Checkbox from '../../../components/Checkbox'
import { IOptions } from '../../../utils/graphqlOptionsLoader'
import { PageViewTracker } from '../../../context/tracking'
import { CANADA_STATES_OPTIONS } from '../../../utils/canadaStates'

interface IProps {
  countryOptions: IOptions
  italianLegalRequirements: boolean
  setItalianLegalRequirements: Dispatch<SetStateAction<boolean>>
}

const Step2: FC<IProps> = ({ countryOptions, italianLegalRequirements, setItalianLegalRequirements }) => {
  const intl = useIntl()
  const { suggestCities } = useContext(mapsContext)

  const { values, setFieldValue, handleChange, handleBlur, touched, errors, setFieldTouched, validateForm } =
    useFormikContext<ISignupForm>()

  const addressStateOption = useMemo(
    () =>
      // prettier-ignore
      values.countrySpecificFields.addressState
        ? {
          label: values.countrySpecificFields.addressState,
          value: values.countrySpecificFields.addressState,
        }
        : null,
    [values.countrySpecificFields.addressState]
  )

  const setAddressState = useCallback(
    (state: string) => {
      setFieldValue('countrySpecificFields.addressState', state, true)
      setFieldTouched('countrySpecificFields.addressState', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const loadCities = useMemo(
    () => suggestCities(values.country?.alpha2 || undefined, true),
    [suggestCities, values.country?.alpha2]
  )

  const setCity = useCallback(
    (_: string | null, city: IOptions[number] | null) => {
      setFieldValue('city', city, true)
      setFieldTouched('city', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const handleCountryChange = useCallback(
    (_value: any, obj: any) => {
      setFieldValue('country', obj)
      setFieldValue('countrySpecificFields', {})
      setCity(null, null)
    },
    [setCity, setFieldValue]
  )

  const handleUsEvents = useCallback(
    () => setFieldValue('countrySpecificFields.hasUSEvents', !values.countrySpecificFields.hasUSEvents),
    [setFieldValue, values.countrySpecificFields.hasUSEvents]
  )

  const handleEuEvents = useCallback(
    () => setFieldValue('countrySpecificFields.hasEUEvents', !values.countrySpecificFields.hasEUEvents),
    [setFieldValue, values.countrySpecificFields.hasEUEvents]
  )

  const toggleItalianLegalRequirements = useCallback(
    () => setItalianLegalRequirements((v) => !v),
    [setItalianLegalRequirements]
  )

  const willYouHoldEvents = (
    <FormRow columnOnMobile>
      {values.country?.alpha2 !== 'US' && values.country?.alpha2 !== 'CA' ? (
        <AgreementCheckbox>
          <AgreementCheckboxItem>
            <Checkbox
              name="countrySpecificFields.hasUSEvents"
              checked={!!values.countrySpecificFields?.hasUSEvents}
              onChange={handleUsEvents}
              onBlur={handleBlur}
              label={intl.formatMessage({ id: 'account.countrySpecificFields.hasUSEvents' })}
            />
          </AgreementCheckboxItem>
        </AgreementCheckbox>
      ) : (
        <AgreementCheckbox>
          <AgreementCheckboxItem>
            <Checkbox
              name="countrySpecificFields.hasEUEvents"
              checked={!!values.countrySpecificFields?.hasEUEvents}
              onChange={handleEuEvents}
              onBlur={handleBlur}
              label={intl.formatMessage({ id: 'account.countrySpecificFields.hasEUEvents' })}
            />
          </AgreementCheckboxItem>
        </AgreementCheckbox>
      )}
    </FormRow>
  )

  return (
    <>
      <PageViewTracker trackId="account_create_location_details" />
      <FormRow columnOnMobile>
        <FormField
          name="country"
          label={intl.formatMessage({ id: 'account.country' })}
          control="select"
          options={countryOptions}
          searchable
          value={values.country}
          onChange={handleCountryChange}
        />

        {values.country?.alpha2 === 'US' || values.country?.alpha2 === 'CA' ? (
          <FormField
            control="select"
            name="sataaataea"
            label={intl.formatMessage({ id: 'account.countrySpecificFields.addressState' })}
            placeholder={intl.formatMessage({ id: 'account.countrySpecificFields.addressState' })}
            options={values.country?.alpha2 === 'US' ? USA_STATE_OPTIONS : CANADA_STATES_OPTIONS}
            value={addressStateOption}
            onChange={setAddressState}
            onBlur={handleBlur}
            searchable
            error={touched.countrySpecificFields?.addressState && errors.countrySpecificFields?.addressState}
          />
        ) : (
          <FormField
            name="caiataya"
            label={intl.formatMessage({ id: 'new_event.basics.venue.address.city.label' })}
            control="select"
            searchable
            async
            loadOptions={loadCities}
            value={values.city}
            onChange={setCity}
          />
        )}
      </FormRow>

      {/* Strange field names are to trick Chrome into stop autofilling them */}

      {values.country && (
        <>
          {(values.country.alpha2 === 'US' || values.country.alpha2 === 'CA') && (
            <FormRow columnOnMobile>
              <FormField
                name="caiataya"
                label={intl.formatMessage({ id: 'new_event.basics.venue.address.city.label' })}
                control="select"
                searchable
                async
                loadOptions={loadCities}
                value={values.city}
                onChange={setCity}
              />
              <FormField
                name="countrySpecificFields.postalCode"
                label={intl.formatMessage({ id: 'account.countrySpecificFields.postalCode' })}
                placeholder={intl.formatMessage({ id: 'account.countrySpecificFields.postalCode' })}
                value={values.countrySpecificFields?.postalCode || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.countrySpecificFields?.postalCode && errors.countrySpecificFields?.postalCode}
              />
            </FormRow>
          )}

          {values.country.alpha2 === 'FR' && (
            <FormRow columnOnMobile>
              <FormField
                name="countrySpecificFields.licenseNumber"
                label={intl.formatMessage({ id: 'account.countrySpecificFields.licenseNumber' })}
                placeholder={intl.formatMessage({ id: 'account.countrySpecificFields.licenseNumber' })}
                value={values.countrySpecificFields?.licenseNumber || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.countrySpecificFields?.licenseNumber && errors.countrySpecificFields?.licenseNumber}
                autoComplete="off"
              />
              <div />
            </FormRow>
          )}

          {values.country.alpha2 === 'IT' ? (
            <>
              <FormRow columnOnMobile>
                <FormField
                  name="countrySpecificFields.taxCode"
                  label={intl.formatMessage({ id: 'signup.countrySpecificFields.fiscalCode' })}
                  hint={intl.formatMessage({ id: 'signup.countrySpecificFields.fiscalCode_hint' })}
                  value={values.countrySpecificFields?.taxCode || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.countrySpecificFields?.taxCode && errors.countrySpecificFields?.taxCode}
                  autoComplete="off"
                />
                <FormField
                  name="countrySpecificFields.vatNumber"
                  label={intl.formatMessage({ id: 'signup.countrySpecificFields.ivaNumber' })}
                  hint={intl.formatMessage({ id: 'signup.countrySpecificFields.ivaNumber_hint' })}
                  value={values.countrySpecificFields?.vatNumber || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.countrySpecificFields?.vatNumber && errors.countrySpecificFields?.vatNumber}
                  autoComplete="off"
                />
              </FormRow>

              {willYouHoldEvents}

              <FormRow columnOnMobile>
                <AgreementCheckbox>
                  <AgreementCheckboxItem>
                    <Checkbox
                      name="italianLegalRequirements"
                      checked={italianLegalRequirements}
                      onChange={toggleItalianLegalRequirements}
                      onBlur={handleBlur}
                      label={intl.formatMessage({
                        id: 'account.countrySpecificFields.italianLegalRequirements',
                      })}
                    />
                  </AgreementCheckboxItem>
                </AgreementCheckbox>
              </FormRow>
            </>
          ) : (
            willYouHoldEvents
          )}
        </>
      )}
    </>
  )
}

export default memo(Step2)
