import { useCallback, useState, useMemo, useRef } from 'react'
import { fetchQuery, useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'

import { useLazyEventPreviewTokenInitialQuery } from '../../__generated__/useLazyEventPreviewTokenInitialQuery.graphql'
import { useLazyEventPreviewToken_event$key } from '../../__generated__/useLazyEventPreviewToken_event.graphql'

function useLazyEventPreviewToken(eventId: string, forceRefetch?: boolean) {
  const environment = useRelayEnvironment()
  const [fetchKey, setFetchKey] = useState('initial')

  const { event: initialEvent } = useLazyLoadQuery<useLazyEventPreviewTokenInitialQuery>(
    graphql`
      query useLazyEventPreviewTokenInitialQuery($id: ID!) {
        event: node(id: $id) {
          ...useLazyEventPreviewToken_event
        }
      }
    `,
    { id: eventId },
    {
      fetchPolicy: 'store-only',
      fetchKey: `${fetchKey}:${eventId}`,
    }
  )

  const previewEvent = useFragment<useLazyEventPreviewToken_event$key>(
    graphql`
      fragment useLazyEventPreviewToken_event on Event {
        previewToken
      }
    `,
    initialEvent
  )

  const previewToken = useMemo(() => previewEvent?.previewToken || null, [previewEvent?.previewToken])

  const loadedId = useRef<string | null>(null)
  const [loading, setLoading] = useState(false)

  const loadPreviewToken = useCallback(() => {
    if (loading || (loadedId.current === eventId && !forceRefetch)) {
      return
    }

    setLoading(true)

    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(
      fetchQuery(
        environment,
        graphql`
          query useLazyEventPreviewTokenRefetchQuery($id: ID!) {
            event: node(id: $id) {
              ...useLazyEventPreviewToken_event
            }
          }
        `,
        { id: eventId }
      ) as any
    ).subscribe({
      complete() {
        loadedId.current = eventId
        setFetchKey(nanoid())
        setLoading(false)
      },

      error() {
        setLoading(false)
      },
    })
  }, [loading, eventId, forceRefetch, environment])

  return { previewToken, loadPreviewToken, loadingPreviewToken: loading }
}

export default useLazyEventPreviewToken
