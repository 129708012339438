import React, { FC, memo, useMemo } from 'react'
import styled from 'styled-components/macro'
import { filter, isEmpty, times } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { List, ListTitle } from '../../DashboardEvents'
import DashboardEventCardInfo from '../DashboardEvent/DashboardEventCardInfo'
import DashboardEventSalesProgress from '../DashboardEvent/DashboardEventSalesProgress'
import { DashboardEventCardList, DashboardEventCard, DashboardEventCardEmpty } from '../DashboardEvent/DashboardEvent'
import { RecentlyOnSale_viewer$key } from '../../../../__generated__/RecentlyOnSale_viewer.graphql'

const ToBottom = styled.div`
  margin-top: auto;
`

interface IProps {
  viewer: RecentlyOnSale_viewer$key
}

const RecentlyOnSale: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const intl = useIntl()

  const viewer = useFragment(
    graphql`
      fragment RecentlyOnSale_viewer on Viewer
      @argumentDefinitions(where: { type: "EventWhereInput", defaultValue: {} }) {
        recentlyOnSale: events(first: 5, scopes: { lifeCycleState: LIVE }, where: $where, orderBy: [onSaleDateDESC]) {
          edges {
            node {
              id
              ...DashboardEventCardInfo_event
              ...DashboardEventSalesProgress_event
            }
          }
        }
      }
    `,
    viewerKey
  )

  const { recentlyOnSale: events } = viewer

  const { edges } = events || { edges: [] }

  const validEdges = useMemo(() => filter('node', edges as any), [edges])

  if (isEmpty(validEdges)) {
    return null
  }

  return (
    <List>
      <ListTitle>{intl.formatMessage({ id: 'dashboard.event_list.recently_on_sale' })}</ListTitle>
      <DashboardEventCardList>
        {validEdges.map(({ node: event }) => (
          <DashboardEventCard key={event.id}>
            <DashboardEventCardInfo event={event} />
            <ToBottom>
              <DashboardEventSalesProgress event={event} />
            </ToBottom>
          </DashboardEventCard>
        ))}
        {times(
          (idx) => (
            <DashboardEventCardEmpty key={`event-onSale-${idx}`} />
          ),
          4
        )}
      </DashboardEventCardList>
    </List>
  )
}

export default memo(RecentlyOnSale)
