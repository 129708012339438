/* eslint-disable max-lines */
import React, { CSSProperties, ChangeEvent, FC, memo, useCallback, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { compact, concat, get, isString, reject, isEmpty } from 'lodash/fp'
import arrayMove from 'array-move'
import { EditorState } from 'react-draft-wysiwyg'

import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'

import IEventFormInformation from '../types/Information'
import Svg from '../../../components/Svg'
import IconButton from '../../../components/IconButton'
import { color, mediaQuery } from '../../../utils/variables'
import FormField from '../../../components/FormField'
import { IFaq } from './EventFaqs'
import { textStyle } from '../../../utils/typography'
import Button from '../../../components/Button'
import { parseMarkdown, renderMarkdown } from '../../../utils/markdown'

const FaqItem = styled.div`
  display: flex;
`

const rejectWithIdx = (reject as any).convert({ cap: false })

const Faq = styled.div<{ compact?: boolean }>`
  margin-top: 16px;
  margin-bottom: 32px;
  padding: ${({ compact }) => (compact ? '0 30px' : '0')};
  ${mediaQuery.lessThan('desktop')`
    padding: 0;
  `}
`

const Placeholder = styled.div`
  max-width: 48px;
  width: 40px;
  height: 40px;

  ${mediaQuery.lessThan('desktop')`
    display: none;
  `}
`

const FaqRow = styled.div`
  position: relative;
  background-color: ${color.white};
  margin-bottom: 16px;

  ${mediaQuery.lessThan('desktop')`
    padding: 0 40px;

    &:first-child {
      padding: 0 40px;
    }
  `}
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  flex: none;
  max-width: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${color.grey};
  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};

  user-select: none;

  ${mediaQuery.lessThan<{ disabled?: boolean }>('desktop')`
    margin: 0;
    position: absolute;
    left: -40px;
    top: 24px;
  `}

  svg {
    pointer-events: none;
  }
`

const RemoveButton = styled(IconButton)`
  ${mediaQuery.lessThan<{ disabled?: boolean }>('desktop')`
    margin: 0;
    position: absolute;
    right: -40px;
    top: -24px;
  `}
`

const SortableDragHandle = SortableHandle<{ disabled?: boolean }>(({ disabled }: { disabled?: boolean }) => (
  <DragHandle disabled={disabled}>
    <Svg icon="hamburger" />
  </DragHandle>
))

interface IItemProps {
  idx: number
  faqItem: IFaq
  removeFaq: (idx: number) => void
  noMove?: boolean
  allowEdit?: boolean
}

const StyledRemoveButton = styled(RemoveButton)`
  margin-left: 8px;
`

interface IContainerProps {
  faqs: IFaq[]
  removeFaq: (idx: number) => void
  compact?: boolean
  allowEdit?: boolean
}

const FaqForms = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`

const CharCounter = styled.div`
  ${textStyle.functional.sm}
  margin-left: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-variant-numeric: tabular-nums;
  color: #666666;
  min-width: 58px;
`

const FaqsSortableItem = SortableElement<IItemProps>(({ idx, faqItem, removeFaq, noMove, allowEdit }: IItemProps) => {
  const { body, bodyDraft, title } = faqItem

  const { errors, touched, handleBlur, setFieldValue } = useFormikContext<IEventFormInformation>()

  const intl = useIntl()
  const onRemove = useCallback(() => removeFaq(idx), [removeFaq, idx])

  const handleChangeTitle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value || ''

      if (!isString(v)) {
        console.error(new Error(`Attempt to set non-string faq title ${v}`))
        return
      }

      setFieldValue(`faqs.${idx}.title`, v)
    },
    [idx, setFieldValue]
  )

  const handleChangeBody = useCallback(
    (draft: Draft.EditorState) => {
      setFieldValue(`faqs.${idx}.bodyDraft`, draft)
    },
    [idx, setFieldValue]
  )

  useEffect(() => {
    if (!bodyDraft && body) {
      setFieldValue(`faqs.${idx}.bodyDraft`, parseMarkdown(body))
    }
  }, [body, bodyDraft, idx, setFieldValue])

  return (
    <FaqRow className="draggable">
      <FaqItem>
        {!noMove ? <SortableDragHandle disabled={!allowEdit} /> : <Placeholder />}
        <FaqForms>
          <FormField
            name={`faqs[${idx}].title`}
            onChange={handleChangeTitle}
            value={title || ''}
            onBlur={handleBlur}
            placeholder={intl.formatMessage({ id: 'new_event.information.faqs.title.placeholder' })}
            error={get(`faqs[${idx}].title`, touched) && get(`faqs[${idx}].title`, errors)}
            disabled={!allowEdit}
          />

          <FormField
            name={`faqs[${idx}].body`}
            control="markdown"
            toolbar="minimal"
            onChange={handleChangeBody}
            value={bodyDraft}
            placeholder={intl.formatMessage({ id: 'new_event.information.faqs.body.placeholder' })}
            onBlur={handleBlur}
            error={get(`faqs[${idx}].body`, touched) && get(`faqs[${idx}].body`, errors)}
            disabled={!allowEdit}
          />
        </FaqForms>

        {allowEdit && !noMove ? (
          <>
            <CharCounter style={(title || '').length > 100 ? ({ color: color.warning } as CSSProperties) : {}}>
              {(title || '').length}/100
            </CharCounter>
            <StyledRemoveButton icon="trash" onClick={onRemove} disabled={noMove} data-id={`removeFaq[${idx}]`} />
          </>
        ) : (
          <Placeholder />
        )}
      </FaqItem>
    </FaqRow>
  )
})

const SortableFaq = SortableContainer<IContainerProps>(
  ({ compact, faqs = [], removeFaq, allowEdit }: IContainerProps) => {
    return (
      <Faq compact={compact}>
        {faqs.map((faqItem, idx) => (
          <FaqsSortableItem
            key={idx}
            idx={idx}
            index={idx}
            faqItem={faqItem}
            removeFaq={removeFaq}
            disabled={!allowEdit}
            allowEdit={allowEdit}
            collection={0}
          />
        ))}
      </Faq>
    )
  }
)

const ModalTitle = styled.h1`
  ${textStyle.heading.xs}
  margin-bottom: 4px;
`

const ModalHint = styled.p`
  ${textStyle.functional.md}
  color:rgba(0, 0, 0, 0.5);
  margin-bottom: 32px;
`

interface IProps {
  onClose: () => void
  onSave: () => void
  readOnly?: boolean
}

const EventFAQsModal: FC<IProps> = ({ onClose, onSave, readOnly }) => {
  const intl = useIntl()

  const {
    errors,
    values: { faqs },
    setFieldValue,
  } = useFormikContext<IEventFormInformation>()
  const ref = useRef<HTMLDivElement>(null)

  const normalizeFaqs = useCallback(() => {
    const normalizedFaqs = faqs
      ?.map((faq) =>
        faq
          ? { id: faq.id, order: faq.order, title: faq.title, body: renderMarkdown(faq.bodyDraft as EditorState) }
          : null
      )
      .filter((faq) => faq?.title || faq?.body)

    setFieldValue('faqs', normalizedFaqs)
  }, [faqs, setFieldValue])

  const handleSave = useCallback(() => {
    normalizeFaqs()

    onSave()
  }, [normalizeFaqs, onSave])

  const onCancel = useCallback(() => {
    normalizeFaqs()

    onClose(/* no args! */)
  }, [normalizeFaqs, onClose])

  const addFaq = useCallback(() => {
    const normalizedFaqs = compact(faqs)

    const idx = normalizedFaqs.length

    setFieldValue(
      'faqs',
      concat(normalizedFaqs, {
        title: '',
        body: '',
      })
    )

    setTimeout(() => {
      if (ref.current) {
        const node = ref.current.querySelector(`input[name="faqs[${idx}].title"]`) as HTMLInputElement
        if (node) node.focus()
      }
    }, 500)
  }, [faqs, setFieldValue])

  const removeFaq = useCallback(
    (removedIdx: any) => {
      setFieldValue(
        'faqs',
        rejectWithIdx((_: any, idx: number) => idx === removedIdx, faqs)
      )
    },
    [setFieldValue, faqs]
  )

  const reorderFaqs = useCallback(
    ({ oldIndex, newIndex }: any) => {
      setFieldValue('faqs', arrayMove(faqs || [], oldIndex, newIndex))
    },
    [setFieldValue, faqs]
  )

  return (
    <Modal
      size="fullscreen"
      closeButton
      onClose={onCancel}
      modalTitle={intl.formatMessage({
        id: 'new_event.information.faqs.form.header.title',
      })}
    >
      <ModalBody>
        <div ref={ref}>
          <ModalTitle>{intl.formatMessage({ id: 'new_event.information.faqs.form.header.title' })}</ModalTitle>
          <ModalHint>{intl.formatMessage({ id: 'new_event.information.faqs.hint' })}</ModalHint>
          <SortableFaq
            faqs={((faqs as any) || []) as IFaq[]}
            removeFaq={removeFaq}
            onSortEnd={reorderFaqs}
            lockAxis="y"
            useDragHandle
            allowEdit={!readOnly}
          />
          <Button preset="secondary" size="small" icon="add" onClick={addFaq}>
            {intl.formatMessage({ id: 'new_event.information.faqs.add_new' })}
          </Button>
        </div>
      </ModalBody>
      {!readOnly && (
        <ModalFooter>
          <ModalFooterControl disabled={readOnly || !isEmpty(errors.faqs)} onClick={handleSave}>
            {intl.formatMessage({
              id: 'actions.save',
            })}
          </ModalFooterControl>
          <ModalFooterControl preset="secondary" onClick={onCancel}>
            {intl.formatMessage({ id: 'actions.cancel' })}
          </ModalFooterControl>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default memo(EventFAQsModal)
