import React, { FC, useContext, ReactNode, memo } from 'react'
import { authContext } from '../context/auth'

interface IProps {
  permission: string | string[]
  fallback?: ReactNode
  children: ReactNode
}

const PermissionCheck: FC<React.PropsWithChildren<IProps>> = ({ permission, children, fallback = null }) => {
  const { hasPermission } = useContext(authContext)
  return hasPermission(permission) ? <>{children}</> : <>{fallback}</>
}

export default memo(PermissionCheck)
