import React, { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import PermissionGate from '../gates/PermissionGate'

const FansPage = lazy(() => import(/* webpackChunkName: "fans" */ '../../flows/Fans/FansPage'))
const FanHeader = lazy(() => import(/* webpackChunkName: "fans" */ '../../flows/Fan/components/FanHeader'))
const FanActivityPage = lazy(
  () => import(/* webpackChunkName: "fans" */ '../../flows/Fan/flows/Activity/FanActivityPage')
)
const FanEventsPage = lazy(() => import(/* webpackChunkName: "fans" */ '../../flows/Fan/flows/Tickets/FanEventsPage'))

const FansSection = () => (
  <Routes>
    <Route path="*" element={<PermissionGate permission="access_fan_profile:fan_support" />}>
      <Route index element={<FansPage />} />

      <Route
        path=":id"
        element={
          <>
            <FanHeader />
            <Outlet />
          </>
        }
      >
        <Route path="activity" element={<FanActivityPage />} />
        <Route path="tickets" element={<FanEventsPage />} />
      </Route>
    </Route>
  </Routes>
)

export default FansSection
