import React, { memo, SVGProps, forwardRef } from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as SvgSpriteRaw } from '../assets/sprite.generated.svg'

interface IProps {
  icon: string
  width?: number | string
  height?: number | string
}

export const SvgSprite = () => (
  <SvgSpriteRaw
    id="__SVG_SPRITE_NODE__"
    aria-hidden="true"
    style={{
      position: 'absolute',
      width: 0,
      height: 0,
    }}
  />
)

type SvgProps = IProps & SVGProps<SVGSVGElement>

const Svg = memo(
  forwardRef<SVGSVGElement, SvgProps>(({ icon, width, height, ...rest }, ref) => {
    const props = {
      width: width || '24px',
      height: height || '24px',
      ...rest,
    }
    return (
      <svg {...props} ref={ref}>
        <use xlinkHref={`#${icon}`} />
      </svg>
    )
  })
)
Svg.displayName = 'Svg'

export default styled(Svg)``
