import { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { some, compose, map, filter, isArray, pick, getOr } from 'lodash/fp'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { INavItem } from '../../../components/SubNavigation'
import { authContext } from '../../../context/auth'
import { TvPlatform } from '../../../enums.generated'
import isEventLive from '../../../utils/isEventLive'
import { useEventNavigation_event$key } from '../../../__generated__/useEventNavigation_event.graphql'
import { isItalianEvent } from '../../../utils/isCountryEvent'
import { localeContext } from '../../../context/locale'

interface IRichNavItem extends Omit<INavItem, 'label'> {
  name: string

  onlyDraft?: boolean
  onlyNonDraft?: boolean
  onlySynced?: boolean
  onlyLive?: boolean
  onlyDiceTv?: boolean
  onlyDiceTvPlatform?: TvPlatform
  onlyApproved?: boolean
  boxOfficeEnabled?: boolean
  permission?: string | string[]
  seatsEnabled?: boolean
}

const NAVIGATION = (id: string): IRichNavItem[] => [
  {
    value: `/events/${id}/overview`,
    name: 'event_subnav.overview',
    onlyNonDraft: true,
  },
  {
    value: `/events/${id}/edit`,
    name: 'event_subnav.details',
    onlyDraft: true,
  },
  {
    value: `/events/${id}/details`,
    name: 'event_subnav.details',
    onlyNonDraft: true,
  },
  {
    value: `/events/${id}/stream`,
    name: 'event_subnav.stream',
    onlyDiceTv: true,
    onlyDiceTvPlatform: 'DICE',
    onlyApproved: true,
    strict: true,
    preloader: () => import(/* webpackChunkName: "ev_stream" */ '../../EventStream/EventStreamPage'),
  },
  {
    value: `/events/${id}/stream-analytics`,
    name: 'event_subnav.stream_analytics',
    onlyDiceTv: true,
    onlyDiceTvPlatform: 'DICE',
    onlyApproved: true,
    preloader: () => import(/* webpackChunkName: "ev_stream" */ '../../EventStreamAnalytics/EventStreamAnalyticsPage'),
  },
  {
    value: `/events/${id}/analytics`,
    name: 'event_subnav.analytics',
    onlyLive: true,
    preloader: () => import(/* webpackChunkName: "ev_analytics" */ '../../EventAnalytics/EventAnalyticsPage'),
  },
  {
    value: `/events/${id}/marketing`,
    name: 'event_subnav.marketing',
    preloader: () => import(/* webpackChunkName: "ev_marketing" */ '../../EventMarketing/EventMarketingPage'),
    onlyNonDraft: true,
  },
  {
    value: `/events/${id}/promotions`,
    name: 'event_subnav.promotions',
    onlySynced: true,
    preloader: () => import(/* webpackChunkName: "ev_promotions" */ '../../EventPromotions/EventPromotionsPage'),
  },
  {
    value: `/events/${id}/seatmap`,
    name: 'event_subnav.seatmap',
    seatsEnabled: true,
    permission: 'read_seatmap:event',
    preloader: () => import(/* webpackChunkName: "ev_seatmap" */ '../../EventSeatmap/EventSeatmapPage'),
  },
  {
    value: `/events/${id}/door-list`,
    name: 'event_subnav.ticket_holders',
    onlyLive: true,
    preloader: () => import(/* webpackChunkName: "ev_doorlist" */ '../../EventDoorlist/EventDoorlistPage'),
  },
  {
    value: `/events/${id}/finances`,
    name: 'event_subnav.sales_and_reports',
    onlySynced: true,
    permission: 'read:balances',
    preloader: () => import(/* webpackChunkName: "ev_finances" */ '../../EventFinances/EventFinancesPage'),
  },
  {
    value: `/events/${id}/payouts`,
    name: 'event_subnav.payouts',
    onlySynced: true,
    permission: 'read:balances',
    preloader: () => import(/* webpackChunkName: "ev_payouts" */ '../../EventPayouts/EventPayoutsPage'),
  },
  {
    value: `/events/${id}/box-office`,
    name: 'event_subnav.box_office',
    onlyLive: true,
    boxOfficeEnabled: true,
    preloader: () => import(/* webpackChunkName: "ev_boxoffice" */ '../../EventBoxOffice/EventBoxOfficePage'),
  },
  {
    value: `/events/${id}/fan-survey`,
    name: 'event_subnav.fan_survey',
    permission: 'manage_data_collection:fan',
    preloader: () => import(/* webpackChunkName: "ev_survey" */ '../../EventFanSurvey/EventFanSurveyPage'),
  },
  {
    value: `/events/${id}/contacts`,
    name: 'subnav.contacts',
    dice: true,
    preloader: () => import(/* webpackChunkName: "ev_contacts" */ '../../EventContacts/EventContactsPage'),
  },
  {
    value: `/events/${id}/activity`,
    name: 'event_subnav.activity',
    permission: 'read_activities:event',
    dice: true,
    preloader: () => import(/* webpackChunkName: "ev_activity" */ '../../EventActivity/EventActivityPage'),
  },
  {
    value: `/events/${id}/sync`,
    name: 'event_subnav.sync',
    dice: true,
    onlySynced: true,
    preloader: () => import(/* webpackChunkName: "ev_sync" */ '../../EventSync/EventSyncPage'),
  },
]

function useEventNavigation(eventKey: useEventNavigation_event$key | null) {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { user, hasPermission } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment useEventNavigation_event on Event {
        id
        eventIdLive
        state
        statusAsOfNow
        eventType
        diceTvPlatform
        flags {
          seated
        }
        eventSeatingChart {
          id
        }
        hdrTtys: ticketTypes {
          id
          doorSalesEnabled
          reservedSeating
        }
        allowedActions {
          readAnalytics
          readAdvancedStats
          readDoorlist
          readDoorlistExtras
          readAllCustomerData
          readMarketingOptIns
          createSocialLinks
          readSocialLinks
          manageFacebook
          manageBoxOffice
          readDoorSalesActivities
          managePromotions
        }
        tokens {
          allCustomerDataToken
        }
        marketeers {
          id
          accessToken
          fbAccessToken
          fbPixelId
          gaTrackingId
          googleAdsConversionId
          googleAdsPurchaseConversionLabel
          privacyPolicyLink
          tiktokPixelId
          twitterCheckoutInitiatedPixelId
          twitterPixelId
          twitterPurchasePixelId
          webOptInEnabled
          appOptInEnabled
        }
        countryCode
        addressCountry
        venues {
          addressCountry
          countryCode
        }
      }
    `,
    eventKey
  )

  const { id, hdrTtys, eventType, state, diceTvPlatform } = event || {}

  const isSyncedEvent = useMemo(() => !!event?.eventIdLive, [event])
  const isLiveEvent = useMemo(() => (event ? isEventLive(event) : false), [event])
  const isItalian = useMemo(() => isItalianEvent(event, locale), [event, locale])

  const boxOfficeEnabled = useMemo(() => some('doorSalesEnabled', hdrTtys), [hdrTtys])
  const seatsEnabled = useMemo(
    () =>
      !!event?.eventSeatingChart?.id ||
      !!getOr(false, 'flags.seated.active', event) ||
      some('reservedSeating', hdrTtys),
    [event, hdrTtys]
  )

  const options: INavItem[] = useMemo(() => {
    if (!event || !id) return []

    return compose([
      map(
        (nav: IRichNavItem) =>
          ({
            label: intl.formatMessage({ id: nav.name }),
            ...pick(['value', 'preloader', 'strict', 'dice'], nav),
          } as INavItem)
      ),
      filter(
        (nav: IRichNavItem) =>
          !nav.permission ||
          (isArray(nav.permission) ? some(hasPermission, nav.permission) : hasPermission(nav.permission))
      ),
      filter((nav: IRichNavItem) => !nav.boxOfficeEnabled || boxOfficeEnabled),
      filter((nav: IRichNavItem) => !nav.seatsEnabled || seatsEnabled),
      filter((nav: IRichNavItem) => !nav.dice || user.diceStaff),
      filter((nav: IRichNavItem) => !nav.onlySynced || isSyncedEvent),
      filter((nav: IRichNavItem) => !nav.onlyLive || isLiveEvent),
      filter((nav: IRichNavItem) => !nav.onlyDiceTv || eventType !== 'LIVE'),
      filter((nav: IRichNavItem) => !nav.onlyDiceTvPlatform || nav.onlyDiceTvPlatform === diceTvPlatform),
      filter((nav: IRichNavItem) => !nav.onlyDraft || state === 'DRAFT'),
      filter((nav: IRichNavItem) => !nav.onlyApproved || state === 'APPROVED'),
      filter((nav: IRichNavItem) => !nav.onlyNonDraft || state !== 'DRAFT'),
      filter((nav: IRichNavItem) => {
        return !(
          (nav.name === 'event_subnav.analytics' || nav.name === 'event_subnav.stream_analytics') &&
          !(event.allowedActions?.readAnalytics || event.allowedActions?.readAdvancedStats)
        )
      }),
      filter(
        (nav: IRichNavItem) =>
          !(
            nav.name === 'event_subnav.ticket_holders' &&
            !event.allowedActions?.readDoorlist &&
            !event.allowedActions?.readDoorlistExtras
          )
      ),
      filter(
        (nav: IRichNavItem) => !(nav.name === 'event_subnav.promotions' && !event.allowedActions?.managePromotions)
      ),
      filter((nav: IRichNavItem) => {
        const canExportCustomers =
          isLiveEvent && event.allowedActions?.readAllCustomerData && !!event.tokens?.allCustomerDataToken
        const canExportMarketing =
          isLiveEvent && event.allowedActions?.readMarketingOptIns && some('accessToken', event.marketeers)

        return !(
          nav.name === 'event_subnav.marketing' &&
          !event.allowedActions?.createSocialLinks &&
          !event.allowedActions?.readSocialLinks &&
          !event.allowedActions?.manageFacebook &&
          !(canExportCustomers || canExportMarketing)
        )
      }),
      filter(
        (nav: IRichNavItem) =>
          !(
            nav.name === 'event_subnav.box_office' &&
            !event.allowedActions?.manageBoxOffice &&
            !event.allowedActions?.readDoorSalesActivities &&
            !hasPermission('create:door_sale') &&
            !hasPermission('read_door_sales_activities:door_sale')
          )
      ),
      filter((nav: IRichNavItem) => !(nav.name === 'event_subnav.fan_survey' && isItalian)),
    ])(NAVIGATION(id))
  }, [
    event,
    id,
    intl,
    hasPermission,
    boxOfficeEnabled,
    seatsEnabled,
    user.diceStaff,
    isSyncedEvent,
    isLiveEvent,
    eventType,
    diceTvPlatform,
    state,
    isItalian,
  ])

  return options
}

export default useEventNavigation
