import { isPast, max, min, parseISO, startOfDay, subDays } from 'date-fns'
import { compact, compose, concat, fromPairs, identity, keys, map, sortBy, uniq } from 'lodash/fp'
import { useContext, useMemo } from 'react'
import { Dictionary } from 'ts-essentials'
import { localeContext } from '../../../context/locale'
import { aggregateByWeeks, normalize, truncate } from '../../../utils/calendar'
import { IOptions } from '../types/options'
import { alignDateStart } from '../utils/dates'
import { IDataPoint } from './useRevenueReport'

const cumulative = (arr: { t: Date; y: number; fake?: boolean }[]) => {
  let sum = 0
  const out = []
  for (const it of arr) {
    if (it.fake) continue

    sum += it.y
    out.push({ t: it.t, y: sum })
  }
  return out
}

const DATA_POINTS = ['sold', 'faceValue', 'views'] as const

export interface IData {
  views: Dictionary<{ time: string; views: number }>
  tickets: Dictionary<IDataPoint>
  extras: Dictionary<IDataPoint>
  merch: Dictionary<IDataPoint>
}

interface IChartPoint {
  t: Date
  y: number
}

export interface IAggregatedDataItem {
  sold: IChartPoint[]
  faceValue: IChartPoint[]
  views: IChartPoint[]
}

export interface IAggregatedData {
  views: IAggregatedDataItem
  tickets: IAggregatedDataItem
  extras: IAggregatedDataItem
  merch: IAggregatedDataItem
}

function useAggregatedData(
  groupByParam: IOptions['groupBy'],
  data: IData,
  evStartDate: Date | null,
  evEndDate: Date | null
) {
  const { locale } = useContext(localeContext)

  const labels: string[] = useMemo(
    () =>
      compose([
        sortBy([identity]),
        uniq,
        concat(keys(data.tickets)),
        concat(keys(data.extras)),
        concat(keys(data.merch)),
        concat(keys(data.views)),
      ])([]),
    [data.extras, data.merch, data.tickets, data.views]
  )

  const [dataStartDate, dataEndDate] = useMemo(
    () =>
      labels.length > 0
        ? labels.length === 1
          ? [subDays(startOfDay(parseISO(labels[0])), 1), startOfDay(parseISO(labels[0]))]
          : [startOfDay(parseISO(labels[0])), startOfDay(parseISO(labels[labels.length - 1]))]
        : [subDays(startOfDay(new Date()), 1), startOfDay(new Date())],
    [labels]
  )

  const [startDate, endDate] = useMemo(
    () => [
      alignDateStart(min(compact([dataStartDate, evStartDate && startOfDay(evStartDate)])), locale),
      max(compact([dataEndDate, evEndDate && isPast(evEndDate) ? startOfDay(evEndDate) : startOfDay(new Date())])),
    ],
    [dataEndDate, dataStartDate, evEndDate, evStartDate, locale]
  )

  const preAggreagates = useMemo(() => {
    function processFn(groupBy: IOptions['groupBy']) {
      const aggregator: typeof cumulative =
        groupBy === 'week' ? aggregateByWeeks(locale === 'en-US' || locale === 'en-CA' ? 0 : 1) : identity

      const accumulator: typeof cumulative = groupBy === 'cumulative' ? cumulative : identity

      const dataFetchFn = (ds: keyof IData, v: typeof DATA_POINTS[number]) => {
        const normal = normalize<IChartPoint>(
          map(
            (t) =>
              ({
                t: parseISO(truncate(t)),
                y: v === 'views' ? data.views[t]?.views || 0 : (data[ds][t] as IDataPoint | null)?.[v] || 0,
              } as any),
            labels
          ),
          startDate,
          endDate,
          true
        ) as { t: Date; y: number }[]

        return accumulator(aggregator(normal) || []) || []
      }

      return compose([
        fromPairs,
        map((ds: keyof IData) => [ds, fromPairs(map((v) => [v, dataFetchFn(ds, v)], DATA_POINTS))]),
        keys,
      ])(data)
    }

    const aggKeys = ['day', 'week', 'cumulative'] as const
    return fromPairs(map((k) => [k, processFn(k) as IAggregatedData], aggKeys)) as Dictionary<
      IAggregatedData,
      typeof aggKeys[number]
    >
  }, [data, endDate, labels, locale, startDate])

  return { labels, data: preAggreagates[groupByParam], startDate, endDate } as const
}

export default useAggregatedData
