import React, { memo, useMemo, useCallback, FC, Fragment } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components/macro'
import { concat, find, map, min, reject } from 'lodash/fp'

import { EventCostCurrency } from '../../../enums.generated'
import { CURRENCY } from '../../../utils/formatters/number'
import { color } from '../../../utils/variables'

import Checkbox from '../../../components/Checkbox'
import { Form, FormRow } from '../../../components/Form'
import DetailedPriceBreakdownTooltip from '../../../components/Event/DetailedPriceBreakdownTooltip'

import IEventFormExtras, { IProduct } from '../types/Extras'

const Separator = styled.div`
  height: 1px;
  background-color: ${color.lightgrey};
  margin: 16px 0;
`

const TightForm = styled(Form)<{ hasError?: boolean }>`
  margin: 12px 0 0 32px;
  padding: 16px;
  border: 1px solid ${color.lightgrey};
  border-radius: 4px;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${color.error};
    `}

  ${FormRow} {
    height: 24px;
  }

  ${FormRow} + ${FormRow} {
    margin-top: 16px;
  }
`

const TicketCheckbox = styled(Checkbox)`
  display: block;
`
const TicketCheckboxLabel = styled.span`
  display: flex;
  flex: 1;
  justify-content: space-between;
`
const TierCheckbox = styled(TicketCheckbox)`
  margin-left: 32px;
  cursor: not-allowed;
  ${TicketCheckboxLabel} {
    cursor: not-allowed;
  }
`

const NoTickets = styled.div`
  color: ${color.greyer};
`

interface IProps {
  currency: EventCostCurrency | null
  ticketTypes: IEventFormExtras['ticketTypes']
  productTty: IProduct['ticketTypes']
  onSelect: (v: IProduct['ticketTypes']) => void
  readOnly?: boolean
  hasError?: boolean
}

const EventProductTicketTypes: FC<IProps> = ({ currency, ticketTypes, productTty, onSelect, hasError, readOnly }) => {
  const intl = useIntl()
  const onCheckChange = useCallback(
    (e: any) => {
      const checked = e.target.checked
      const id = e.currentTarget.dataset['tty']
      const tty = find(['id', id], ticketTypes) || null
      onSelect(checked ? concat([tty], productTty) : reject(['id', tty?.id], productTty))
    },
    [onSelect, productTty, ticketTypes]
  )

  const allChecked = useMemo(() => {
    if ((productTty?.length || 0) === 0) return false
    if ((productTty?.length || 0) === ticketTypes?.length) return true
    return null
  }, [ticketTypes, productTty])

  const onAllChange = useCallback(() => {
    const mappedTickets = map((tty) => tty && { id: tty.id, name: tty.name }, ticketTypes)
    if (allChecked || allChecked === null) {
      onSelect([])
    } else {
      onSelect(mappedTickets || [])
    }
  }, [allChecked, onSelect, ticketTypes])

  const minPrice = useCallback(
    (priceTiers: NonNullable<NonNullable<IEventFormExtras['ticketTypes']>[number]>['priceTiers']) => {
      return min(map('faceValue', priceTiers))
    },
    []
  )

  return (
    <TightForm hasError={hasError}>
      <FormRow>
        <Checkbox
          triState
          label={intl.formatMessage({ id: 'actions.select_all' })}
          checked={allChecked}
          onChange={onAllChange}
          disabled={readOnly}
        />
      </FormRow>
      <Separator />
      {ticketTypes?.length === 0 && (
        <NoTickets>{intl.formatMessage({ id: 'new_event.extras.form.tickets.create_tickets' })}</NoTickets>
      )}
      {ticketTypes?.map(
        (tt) =>
          tt && (
            <Fragment key={tt.id}>
              <FormRow>
                <TicketCheckbox
                  data-id={tt.id}
                  data-tty={tt.id}
                  label={
                    <TicketCheckboxLabel>
                      <span>{tt.name}</span>
                      {tt.priceTierType === null ? (
                        <span>
                          {intl.formatNumber(tt.faceValue / 100, CURRENCY(tt.faceValue, currency))}{' '}
                          <DetailedPriceBreakdownTooltip
                            title={intl.formatMessage(
                              { id: 'price.fees_title' },
                              { name: tt.name || intl.formatMessage({ id: 'new_event.basics.name.placeholder' }) }
                            )}
                            currency={currency || null}
                            priceBreakdown={tt.priceBreakdown}
                            placement="right"
                          />
                        </span>
                      ) : (
                        <span>
                          {intl.formatMessage(
                            { id: 'event_success.price_from' },
                            {
                              price: intl.formatNumber(
                                minPrice(tt.priceTiers || []) / 100,
                                CURRENCY(minPrice(tt.priceTiers || []), currency)
                              ),
                            }
                          )}
                        </span>
                      )}
                    </TicketCheckboxLabel>
                  }
                  checked={!!find(['id', tt.id], productTty)}
                  onChange={onCheckChange}
                  disabled={readOnly}
                />
              </FormRow>
              {tt.priceTiers?.map(
                (pt) =>
                  pt && (
                    <FormRow key={pt.id}>
                      <TierCheckbox
                        data-id={pt.id}
                        data-tty={tt.id}
                        color="greyer"
                        title={intl.formatMessage({ id: 'new_event.extras.form.tickets.tiers_disabled_hint' })}
                        label={
                          <TicketCheckboxLabel>
                            <span>{pt.name}</span>
                            <span>
                              {intl.formatNumber(pt.faceValue / 100, CURRENCY(pt.faceValue, currency))}{' '}
                              <DetailedPriceBreakdownTooltip
                                title={intl.formatMessage(
                                  { id: 'price.fees_title' },
                                  { name: pt.name || intl.formatMessage({ id: 'new_event.basics.name.placeholder' }) }
                                )}
                                currency={currency || null}
                                priceBreakdown={pt.priceBreakdown}
                                placement="right"
                              />
                            </span>
                          </TicketCheckboxLabel>
                        }
                        checked={!!find(['id', tt.id], productTty)}
                        readOnly
                      />
                    </FormRow>
                  )
              )}
            </Fragment>
          )
      )}
    </TightForm>
  )
}

export default memo(EventProductTicketTypes)
