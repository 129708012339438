/**
 * @generated SignedSource<<94fd10e638af1f471a78af511d700a28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeesBehaviour = "APPEND_TO_CONTRACT" | "OVERRIDE" | "USE_CONTRACT" | "%future added value";
export type ScheduleStatus = "POSTPONED" | "RESCHEDULED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventHeaderDataBasic_event$data = {
  readonly autopaymentFailed: boolean | null;
  readonly billingPromoter: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly date: string | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly feesBehaviour: FeesBehaviour | null;
  readonly id: string;
  readonly name: string | null;
  readonly previewToken: string | null;
  readonly primaryVenue: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly sales: {
    readonly totalPayoutValue: number;
    readonly totalPromoterIncome: number;
  } | null;
  readonly scheduleStatus: ScheduleStatus | null;
  readonly selfPayoutBlockers: IBackendMap | null;
  readonly timezoneName: string | null;
  readonly venueName: string | null;
  readonly venues: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventImage_event" | "EventPartStatus_event">;
  readonly " $fragmentType": "EventHeaderDataBasic_event";
};
export type EventHeaderDataBasic_event$key = {
  readonly " $data"?: EventHeaderDataBasic_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventHeaderDataBasic_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "useCache",
    "value": true
  }
],
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDraft"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "needBalance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventHeaderDataBasic_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autopaymentFailed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selfPayoutBlockers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feesBehaviour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v2/*: any*/),
          "kind": "ScalarField",
          "name": "totalPayoutValue",
          "storageKey": "totalPayoutValue(useCache:true)"
        },
        {
          "alias": null,
          "args": (v2/*: any*/),
          "kind": "ScalarField",
          "name": "totalPromoterIncome",
          "storageKey": "totalPromoterIncome(useCache:true)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "venueName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "primaryVenue",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventImage_event"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDraft",
          "variableName": "isDraft"
        },
        {
          "kind": "Variable",
          "name": "needBalance",
          "variableName": "needBalance"
        }
      ],
      "kind": "FragmentSpread",
      "name": "EventPartStatus_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "80e3dc821904ab9cc243c2e3a8eb6686";

export default node;
