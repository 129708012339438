import React, { FC, useContext, memo } from 'react'
import { useIntl } from 'react-intl'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { Link } from 'react-router-dom'

import { Loader } from '../../../components/Loader'
import EventCardValue from '../../../components/Event/EventCardValue'

import { EventPartVenue_event$key } from '../../../__generated__/EventPartVenue_event.graphql'
import { authContext } from '../../../context/auth'
import VenueBadge from '../../../components/VenueBadge'

interface IProps {
  event: EventPartVenue_event$key
}

const EventPartVenue: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment EventPartVenue_event on Event {
        id
        eventType
        venueName
        primaryVenue {
          id
          name
        }
        venues {
          id
          name
        }
        billingPromoter {
          id
          name
        }
        ticketTypes(doorSalesOnly: false, includeArchived: false) {
          streamLink
        }
      }
    `,
    eventKey
  )

  const promoter = event?.billingPromoter ? (
    <Link to={`/promoters/${event.billingPromoter.id}`}>{event.billingPromoter.name}</Link>
  ) : (
    intl.formatMessage({ id: 'na' })
  )

  return !event ? (
    <Loader />
  ) : (
    <EventCardValue
      primary={<VenueBadge event={event} />}
      primaryLimit="2"
      secondary={user.diceStaff ? promoter : <>&nbsp;</>}
    />
  )
}

export default memo(EventPartVenue)
