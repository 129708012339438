import React, { FC, memo } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Svg from '../../../../components/Svg'

import { color, mediaQuery } from '../../../../utils/variables'
import { ListTitle } from '../../DashboardEvents'

const Container = styled.div`
  padding: 32px;

  ${ListTitle} {
    margin: 0 0 16px 0;
  }

  ${mediaQuery.lessThan('tablet')`
    padding: 16px;
  `}
`

const Banner = styled.div`
  background: ${color.palegrey};

  border: 1px dashed ${color.darkgrey};
  border-radius: 6px;

  padding: 16px;

  display: flex;
  align-items: center;

  max-width: 50%;
  min-width: 0;

  & > svg {
    min-width: 40px;
    margin-right: 12px;
  }

  ${mediaQuery.lessThan('tablet')`
    max-width: unset;
  `}
`

const PinnedEventPlaceholder: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()

  return (
    <Container>
      <ListTitle>{intl.formatMessage({ id: 'dashboard.pinned_event' })}</ListTitle>
      <Banner>
        <Svg icon="pin-thin" width={40} height={40} />
        <div>
          {intl.formatMessage(
            { id: 'dashboard.pinned_placeholder' },
            {
              a: (str: string) => (
                <strong>
                  <Link to="/support?article=615c7b310332cb5b9e9b0e50" target="_blank">
                    {str}
                  </Link>
                </strong>
              ),
            }
          )}
        </div>
      </Banner>
    </Container>
  )
}

export default memo(PinnedEventPlaceholder)
