import React, { ChangeEvent, FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { FormikErrors, FormikTouched, FormikValues } from 'formik'
import styled from 'styled-components/macro'
import { compact, map } from 'lodash/fp'

import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'

import { allowedEventAction } from '../services/allowedEventAction'
import IEventFormTickets, { ITicketType, IPriceTier } from '../types/Tickets'
import { CURRENCY } from '../../../utils/formatters/number'
import { color, font } from '../../../utils/variables'

import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'

import EventTicketTypePriceTiersDoorSales from './EventTicketTypePriceTiersDoorSales'
import useGetSalesTax from '../hooks/useGetSalesTax'
import { EventTicketTypeDoorPricing_event$key } from '../../../__generated__/EventTicketTypeDoorPricing_event.graphql'
import { getAlpha2ByName } from '../../../utils/countries'

const DoorPriceInfo = styled.div<{ isLoading?: boolean }>`
  position: relative;
  margin: 24px 0 0;
  padding: 6px 0;
  border-radius: 8px;
  background-color: ${color.palegrey};
  font-size: ${font.size.sm}px;
  border: 2px solid transparent;

  filter: ${({ isLoading }) => (isLoading ? 'blur(3px)' : 'none')};
`

interface IProps {
  readOnly: boolean
  event: EventTicketTypeDoorPricing_event$key | null | undefined
  eventForm?: IEventFormTickets
  values: ITicketType
  handleChange: (e: string | ChangeEvent) => void
  handleBlur: (e: Event) => void
  setFieldValue: (name: string, value: any) => void
  setFieldTouched: (name: string, p1: boolean, p2: boolean) => void
  validateForm: () => void
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
}

const EventTicketTypeDoorPricing: FC<IProps> = (props) => {
  const intl = useIntl()
  const event = useFragment<EventTicketTypeDoorPricing_event$key>(
    graphql`
      fragment EventTicketTypeDoorPricing_event on Event {
        id
        state
        costCurrency
        timezoneName
        addressCountry
        countryCode
        ticketTypes(doorSalesOnly: false, includeArchived: true) {
          id
          hidden
          archived
          allocation
          isStream
          priceTierType
          priceTiers {
            allocation
          }
        }
        primaryVenue {
          value: id
          label: name
        }
        allowedLifecycleUpdates {
          onSaleDate {
            canUpdate
          }
          ticketTypes {
            canChangeTierNames
            canUpdatePrice
            canChangeDoorSalesEnabled
          }
        }
      }
    `,
    props.event
  )

  const { values, handleChange, handleBlur, setFieldValue, touched, errors, eventForm, readOnly } = props

  const {
    id: eventId,
    state,
    allowedLifecycleUpdates,
    costCurrency: currency,
    primaryVenue,
    addressCountry,
    countryCode,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = (eventForm || event)!

  const hasTax = useMemo(() => {
    const cc = countryCode || getAlpha2ByName(addressCountry)
    return cc === 'US' || cc === 'CA'
  }, [addressCountry, countryCode])

  const priceTiers = useMemo(() => compact(values.priceTiers || []) as IPriceTier[], [values.priceTiers])

  const doorPrices = useMemo(() => {
    if (values.priceTierType === null) {
      return [
        {
          doorSalesPrice: values.doorSalesPrice || 0,
          doorSalesTax: values.doorSalesTax || 0,
          doorSalesPriceTaxed: values.doorSalesPriceTaxed || 0,
        },
      ]
    }

    return map(
      (pt: IPriceTier | null) => ({
        doorSalesPrice: pt?.doorSalesPrice || 0,
        doorSalesTax: (pt?.doorSalesPriceTaxed || 0) - (pt?.doorSalesPrice || 0),
        doorSalesPriceTaxed: pt?.doorSalesPriceTaxed || 0,
      }),
      (values.priceTiers || []) as IPriceTier[]
    )
  }, [values.doorSalesPrice, values.doorSalesPriceTaxed, values.doorSalesTax, values.priceTierType, values.priceTiers])

  const { loading, doorPriceBreakdown } = useGetSalesTax(
    doorPrices,
    hasTax ? primaryVenue?.value || null : null,
    eventId
  )

  if (values.priceTiers && values.priceTiers.length > 0) {
    return (
      <FormRow columnOnMobile>
        <EventTicketTypePriceTiersDoorSales
          doorPriceBreakdown={doorPriceBreakdown}
          isLoading={loading}
          currency={currency || undefined}
          tiers={priceTiers}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          disabled={
            readOnly ||
            (state !== 'DRAFT' &&
              !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeDoorSalesEnabled'))
          }
        />
      </FormRow>
    )
  }

  return (
    <>
      <FormRow columnOnMobile>
        <FormField
          name="doorSalesPrice"
          currency={currency}
          label={intl.formatMessage({
            id: 'new_event.tickets.ticket_type_edit.box_office_price.label',
          })}
          value={values.doorSalesPrice}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            readOnly ||
            eventForm?.freeEvent ||
            (state !== 'DRAFT' &&
              !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeDoorSalesEnabled'))
          }
          error={touched.doorSalesPrice && errors.doorSalesPrice}
        />
      </FormRow>
      {hasTax && doorPriceBreakdown.length > 0 && (
        <DoorPriceInfo isLoading={loading}>
          <div className="mt-xs mb-xs">
            {intl.formatMessage({
              id: 'new_event.tickets.ticket_type_edit.box_office_price.label',
            })}
            {': '}
            {intl.formatNumber(
              (doorPriceBreakdown[0].doorSalesPrice || 0) / 100,
              CURRENCY(doorPriceBreakdown[0].doorSalesPrice || 0, currency)
            )}
          </div>
          <div className="mt-xs mb-xs">
            {intl.formatMessage({ id: 'fees.salesTax' })}
            {': '}
            {intl.formatNumber(
              (doorPriceBreakdown[0].doorSalesTax || 0) / 100,
              CURRENCY(doorPriceBreakdown[0].doorSalesTax || 0, currency)
            )}
          </div>
          <div className="mt-xs mb-xs">
            {intl.formatMessage({
              id: 'new_event.tickets.ticket_type_edit.box_office_fan_price.label',
            })}
            {': '}
            {intl.formatNumber(
              (doorPriceBreakdown[0].doorSalesPriceTaxed || 0) / 100,
              CURRENCY(doorPriceBreakdown[0].doorSalesPriceTaxed || 0, currency)
            )}
          </div>
        </DoorPriceInfo>
      )}
    </>
  )
}

export default memo(EventTicketTypeDoorPricing)
