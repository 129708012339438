/**
 * @generated SignedSource<<250a15eaa0a82ece31b69026e285a8fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCommentActivityInput = {
  clientMutationId: string;
  content: string;
  itemId: string;
};
export type EventWorkflowCommentMutation$variables = {
  input: CreateCommentActivityInput;
};
export type EventWorkflowCommentMutation$data = {
  readonly createCommentActivity: {
    readonly activity: {
      readonly id: string;
    } | null;
  } | null;
};
export type EventWorkflowCommentMutation = {
  response: EventWorkflowCommentMutation$data;
  variables: EventWorkflowCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCommentActivityPayload",
    "kind": "LinkedField",
    "name": "createCommentActivity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventWorkflowCommentMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventWorkflowCommentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6b66eda7ca3be77997e7da772840941a",
    "id": null,
    "metadata": {},
    "name": "EventWorkflowCommentMutation",
    "operationKind": "mutation",
    "text": "mutation EventWorkflowCommentMutation(\n  $input: CreateCommentActivityInput!\n) {\n  createCommentActivity(input: $input) {\n    activity {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "87cb08fed837437ec4059ab7e06f4d03";

export default node;
