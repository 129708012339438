/**
 * @generated SignedSource<<e9edf1f573fee399b2c59e462c154c7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type PromotionType = "CODE_LOCK" | "DISCOUNT" | "%future added value";
export type TvPlatform = "DICE" | "EXTERNAL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TicketBreakdown_event$data = {
  readonly allowedActions: {
    readonly manageTickets: boolean | null;
    readonly minorUpdate: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly ticketTypes: {
      readonly canChangeOffSaleDate: boolean | null;
      readonly canChangeOnSaleDate: boolean | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly date: string | null;
  readonly diceTvPlatform: TvPlatform | null;
  readonly eventIdLive: string | null;
  readonly eventType: EventType | null;
  readonly flags: {
    readonly seated: IBackendMap | null;
    readonly waitingList: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly lockVersion: number;
  readonly maxTicketsLimit: number | null;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly previewToken: string | null;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly priceTiersBreakdown: ReadonlyArray<{
        readonly appSold: number;
        readonly posSold: number;
        readonly priceTier: {
          readonly allocation: number | null;
          readonly faceValue: number;
          readonly id: string;
          readonly order: number;
          readonly price: number | null;
          readonly priceBreakdown: {
            readonly faceValue: number;
            readonly total: number;
            readonly totalWithPwl: number | null;
            readonly totalWithoutPwl: number | null;
          } | null;
          readonly time: string | null;
        };
      } | null>;
      readonly promotionsBreakdown: ReadonlyArray<{
        readonly appSold: number;
        readonly eventPromotion: {
          readonly name: string;
          readonly promotionType: PromotionType;
        } | null;
        readonly faceValue: number;
        readonly fees: ReadonlyArray<{
          readonly computed: number;
          readonly rebate: number;
          readonly type: FeeType;
        } | null>;
        readonly payoutValue: number;
        readonly posSold: number;
        readonly price: number;
        readonly priceWithPwl: number | null;
        readonly priceWithoutPwl: number | null;
        readonly rebate: number;
        readonly sold: number;
        readonly value: number;
      } | null>;
      readonly ticketType: {
        readonly allocation: number;
        readonly archived: boolean | null;
        readonly codeLocked: boolean;
        readonly doorSalesEnabled: boolean;
        readonly doorSalesPrice: number | null;
        readonly eventPromotions: ReadonlyArray<{
          readonly id: string;
        } | null> | null;
        readonly faceValue: number;
        readonly hidden: boolean;
        readonly icon: string | null;
        readonly id: string;
        readonly isStream: boolean;
        readonly name: string | null;
        readonly offSaleDate: string | null;
        readonly onSaleDate: string | null;
        readonly price: number | null;
        readonly priceBreakdown: {
          readonly faceValue: number;
          readonly total: number;
          readonly totalWithPwl: number | null;
          readonly totalWithoutPwl: number | null;
        } | null;
        readonly priceHidden: boolean;
        readonly priceTierType: PriceTierTypes | null;
        readonly reservedSeating: boolean | null;
        readonly salesLimit: number | null;
        readonly ticketPoolId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"TicketPromotion_ticketType">;
      };
      readonly totalAppSold: number;
      readonly totalDigitalValue: number;
      readonly totalFaceValue: number;
      readonly totalPosSold: number;
      readonly totalPosValue: number;
      readonly totalReserved: number;
      readonly totalSold: number;
      readonly totalWlPurchases: number;
      readonly totalWlRequests: number;
      readonly " $fragmentSpreads": FragmentRefs<"PriceTiersBreakdownTable_ticketType">;
    } | null>;
  } | null;
  readonly state: EventState | null;
  readonly ticketPools: ReadonlyArray<{
    readonly id: string;
    readonly maxAllocation: number | null;
  } | null> | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly archived: boolean | null;
    readonly id: string;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly venueSchedules: ReadonlyArray<{
    readonly id: string;
  } | null> | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly capacity: number;
    readonly countryCode: string | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventTicketTypeModal_event" | "PriceTiersBreakdownTable_event" | "TicketPromotion_event">;
  readonly " $fragmentType": "TicketBreakdown_event";
};
export type TicketBreakdown_event$key = {
  readonly " $data"?: TicketBreakdown_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketBreakdown_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onSaleDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offSaleDate",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "priceBreakdown",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithPwl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithoutPwl",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "posSold",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appSold",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rebate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketBreakdown_event",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeModal_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceTiersBreakdownTable_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketPromotion_event"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageTickets",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketTypes",
          "kind": "LinkedField",
          "name": "ticketTypes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOnSaleDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOffSaleDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceTvPlatform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTicketsLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waitingList",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressCountry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueSchedule",
      "kind": "LinkedField",
      "name": "venueSchedules",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "doorSalesOnly",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "includeArchived",
          "value": true
        }
      ],
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketPool",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "salesLimit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ticketPoolId",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hidden",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isStream",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "doorSalesEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "icon",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "codeLocked",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reservedSeating",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EventPromotion",
                  "kind": "LinkedField",
                  "name": "eventPromotions",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceTierType",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "doorSalesPrice",
                  "storageKey": null
                },
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceHidden",
                  "storageKey": null
                },
                (v9/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TicketPromotion_ticketType"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalReserved",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalWlRequests",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalWlPurchases",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalFaceValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalDigitalValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PromotionBreakdown",
              "kind": "LinkedField",
              "name": "promotionsBreakdown",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EventPromotion",
                  "kind": "LinkedField",
                  "name": "eventPromotion",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "promotionType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceWithPwl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceWithoutPwl",
                  "storageKey": null
                },
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payoutValue",
                  "storageKey": null
                },
                (v11/*: any*/),
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesFees",
                  "kind": "LinkedField",
                  "name": "fees",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "computed",
                      "storageKey": null
                    },
                    (v12/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PriceTiersBreakdownTable_ticketType"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceTierBreakdown",
              "kind": "LinkedField",
              "name": "priceTiersBreakdown",
              "plural": true,
              "selections": [
                (v11/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceTier",
                  "kind": "LinkedField",
                  "name": "priceTier",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    },
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time",
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "57a0e8eefda00efbfbfa30b0c722b552";

export default node;
