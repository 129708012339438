import { FC, useContext, useEffect } from 'react'
import { trackingContext } from '..'
import { authContext } from '../../auth'

export const DicePageViewTracker: FC = () => {
  const { trackDicePageView } = useContext(trackingContext)
  const { user } = useContext(authContext)

  useEffect(() => {
    if (!user.diceStaff) return

    trackDicePageView()
  }, [trackDicePageView, user.diceStaff])

  return null
}
