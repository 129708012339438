import React, { FC, HTMLProps } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components/macro'

import { CURRENCY } from '../../../../utils/formatters/number'
import { font, mediaQuery } from '../../../../utils/variables'

import { drawPromotionIcon, PromotionSale } from '../../../EventFinances/components/EventFinancesPromoSale'
import { PriceTier, PriceTierCol, TicketType, TicketTypeCol } from './TicketBreakdownStyles'
import DetailedPriceBreakdownTooltip from '../../../../components/Event/DetailedPriceBreakdownTooltip'
import { EventCostCurrency } from '../../../../enums.generated'
import { TicketBreakdown_event$data } from '../../../../__generated__/TicketBreakdown_event.graphql'

const Wrapper = styled.div<{ type?: 'ticketType' | 'priceTier' }>`
  margin: 4px 0 16px 0;
  flex: 100% 0 0;
  ${({ type }) =>
    type === 'ticketType' &&
    css`
      order: 100;
      ${mediaQuery.lessThan('desktop')`
        order: 1;
        margin: 0 0 8px !important;
      `}
    `}
  ${PriceTier} {
    border-bottom: 0;
    margin: 0;
  }
  ${PromotionSale} {
    padding: 6px 0;
    margin: 0;
    &:last-child {
      padding-bottom: 0;
      max-width: 100%;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${mediaQuery.lessThan('desktop')`
    ${TicketType} {
      flex-wrap: nowrap;
      ${TicketTypeCol} {
        flex: 30% 0 0;
        justify-content: flex-end;
        padding: 0;
        margin: 0;
        &:first-child {
          flex: 40% 0 0;
          justify-content: flex-start;
          min-height: auto;
          padding-left: 56px;
        }
      }
    }
  `}
  ${mediaQuery.lessThan('tablet')`
    ${TicketTypeCol} {
      font-size: ${font.size.sm}px;
    }
  `}
`

type ITicketTypeBreakdown = Array<
  NonNullable<NonNullable<NonNullable<TicketBreakdown_event$data['sales']>['ticketTypesBreakdown']>[number]>
>

interface IProps {
  promotions: ITicketTypeBreakdown[number]['promotionsBreakdown']
  currency?: EventCostCurrency | null
  type: 'ticketType' | 'priceTier'
  hasBoxOffice?: boolean
  hasDiscount?: boolean
}

const PromotionsBreakdown: FC<HTMLProps<HTMLDivElement> & IProps> = ({
  promotions,
  currency,
  type,
  hasBoxOffice,
  hasDiscount,
}) => {
  const intl = useIntl()
  const Row = type === 'ticketType' ? TicketType : PriceTier
  const Col = type === 'ticketType' ? TicketTypeCol : PriceTierCol
  if (!promotions) return null
  return (
    <Wrapper type={type}>
      {promotions?.map(
        (promoSale, index) =>
          promoSale && (
            <Row key={`promoSale-${type}-${index}`}>
              <Col>
                <PromotionSale>
                  <span>
                    {drawPromotionIcon(promoSale.eventPromotion?.promotionType)}
                    <span className="color-text">
                      {promoSale.eventPromotion
                        ? promoSale.eventPromotion.name
                        : intl.formatMessage({ id: 'event_finances.standard' })}
                    </span>
                  </span>
                  {hasDiscount && (
                    <div className="color-text nowrap">
                      <span>
                        {intl.formatNumber(promoSale?.faceValue / 100, CURRENCY(promoSale?.faceValue, currency))}
                      </span>{' '}
                      <DetailedPriceBreakdownTooltip
                        title={intl.formatMessage(
                          { id: 'price.fees_title' },
                          {
                            name:
                              promoSale.eventPromotion?.name ||
                              intl.formatMessage({ id: 'new_event.basics.name.placeholder' }),
                          }
                        )}
                        currency={currency || null}
                        priceBreakdown={{
                          faceValue: promoSale.faceValue,
                          total: promoSale.price,
                          totalWithPwl: promoSale.priceWithPwl,
                          totalWithoutPwl: promoSale.priceWithoutPwl,
                        }}
                        placement="right"
                      />
                    </div>
                  )}
                </PromotionSale>
              </Col>
              <Col>
                <PromotionSale>
                  {intl.formatNumber(promoSale.sold || 0, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </PromotionSale>
              </Col>
              <Col shifted={hasBoxOffice}>
                <PromotionSale>
                  {intl.formatNumber((promoSale.value || 0) / 100, CURRENCY(promoSale.value || 0, currency))}
                </PromotionSale>
              </Col>
            </Row>
          )
      )}
    </Wrapper>
  )
}

export default PromotionsBreakdown
