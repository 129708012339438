import { mapValues } from 'lodash/fp'

const mapKV = (mapValues as any).convert({ cap: false })

const mixed = {
  default: '',
  required: '',
  oneOf: '',
  notOneOf: '',
  notType: '',
  defined: '',
}

const string = {
  length: '',
  min: '',
  max: '',
  matches: '',
  email: '',
  url: '',
  trim: '',
  lowercase: '',
  uppercase: '',
}

const number = {
  min: '',
  max: '',
  lessThan: '',
  moreThan: '',
  notEqual: '',
  positive: '',
  negative: '',
  integer: '',
}

const date = {
  min: '',
  max: '',
}

const boolean = {}

const object = {
  noUnknown: '',
}

const array = {
  min: '',
  max: '',
}

const locale = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
}

const PHRASEAPP_YUP_LOCALE: typeof locale = mapKV(
  (v: any, section: string) => mapKV((_: any, key: string) => `validation.yup.${section}.${key}`, v),
  locale
)

PHRASEAPP_YUP_LOCALE.mixed.default = ' ' // This should be indicated in other ways
PHRASEAPP_YUP_LOCALE.mixed.required = ' ' // This should be indicated in other ways
PHRASEAPP_YUP_LOCALE.mixed.defined = ' ' // This should be indicated in other ways
PHRASEAPP_YUP_LOCALE.mixed.notType = ' ' // This should be indicated in other ways

export default PHRASEAPP_YUP_LOCALE
