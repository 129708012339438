import React, { ComponentType, FC, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import Banner from './Banner'

import IconButton from './IconButton'
import Svg from './Svg'

interface IProps {
  wrapper?: ComponentType<React.PropsWithChildren<unknown>> | string
  dismissKey: string
  className?: string
  icon?: string
}

const DismissableBanner: FC<React.PropsWithChildren<IProps>> = ({
  wrapper: Wrapper,
  icon,
  dismissKey,
  children,
  className,
}) => {
  const intl = useIntl()
  const key = `dismiss__${dismissKey}`

  const [showBanner, setShowBanner] = useState(() => !localStorage.getItem(key))
  const dismissBanner = useCallback(() => {
    setShowBanner(false)
    localStorage.setItem(key, 'dismissed')
  }, [key])

  const banner = (
    <Banner data-id={key} className={className}>
      <Svg icon={icon || 'eye'} />
      <div>{children}</div>
      <IconButton
        data-id="dismissButton"
        onClick={dismissBanner}
        icon="close-view"
        title={intl.formatMessage({ id: 'actions.close' })}
      />
    </Banner>
  )

  const result = Wrapper ? <Wrapper>{banner}</Wrapper> : banner

  return showBanner ? result : null
}

export default styled(DismissableBanner)``
