import { useMemo } from 'react'

interface IProps {
  ticketsAllocation: number
  ticketsSold: number
  ticketsMoney: number

  extrasAllocation: number
  extrasSold: number
  extrasMoney: number

  merchAllocation: number
  merchSold: number
  merchMoney: number

  cumulative: boolean
}

function useBounds({
  ticketsAllocation,
  ticketsSold,
  ticketsMoney,
  extrasAllocation,
  extrasSold,
  extrasMoney,
  merchAllocation,
  merchSold,
  merchMoney,
  cumulative,
}: IProps) {
  const [allocMax, moneyMax] = useMemo(() => {
    const ticketsMaxMoney =
      ticketsAllocation > 0 && ticketsSold > 0 ? ticketsAllocation * (ticketsMoney / ticketsSold) : 0

    const extrasMaxMoney = extrasAllocation > 0 && extrasSold > 0 ? extrasAllocation * (extrasMoney / extrasSold) : 0
    const merchMaxMoney = merchAllocation > 0 && merchSold > 0 ? merchAllocation * (merchMoney / merchSold) : 0

    const divider = cumulative ? 1 : 5

    const allocMax = Math.round(Math.max(ticketsAllocation, extrasAllocation, merchAllocation) / divider)
    const moneyMax = Math.round(Math.max(ticketsMaxMoney, extrasMaxMoney, merchMaxMoney) / divider)
    return [allocMax, moneyMax]
  }, [
    cumulative,
    extrasAllocation,
    extrasMoney,
    extrasSold,
    merchAllocation,
    merchMoney,
    merchSold,
    ticketsAllocation,
    ticketsMoney,
    ticketsSold,
  ])

  return [allocMax, moneyMax]
}

export default useBounds
