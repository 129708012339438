import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import { find, getOr, reject } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'

import { authContext } from '../context/auth'
import { color } from '../utils/variables'
import SidebarAccount from './SidebarAccount'
import Button from './Button'
import { trackingContext } from '../context/tracking'
import { notificationContext } from '../context/notification'
import Svg from './Svg'
import { TitleTooltip } from './Tooltip'
import DiceBadge from './DiceBadge'
import { textStyle } from '../utils/typography'

const Container = styled.div`
  width: min(415px, calc(100vw - 48px));
  ${textStyle.functional.md}
`

const Separator = styled.div`
  margin: 24px -24px;
  height: 1px;
  background-color: ${color.lightgrey};
`

const Role = styled.div`
  text-align: right;
  color: ${color.darkgrey};
  flex: 1;
  margin-left: 8px;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Role} {
    margin-left: 0;
    margin-top: 8px;
  }
`

const UserName = styled.div`
  margin: 8px 0 4px 0;
  ${textStyle.heading.md}

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-left: 4px;
  }
`

const AccountName = styled.div`
  ${textStyle.functional.sm}
  text-align: center;
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px -8px -8px -8px;

  ${Button} {
    display: block;
    flex-grow: 1;
    margin: 8px;
  }
`

const Accounts = styled.ul`
  list-style: none;
  max-height: 370px;
  overflow-y: auto;
  // correct for 24px padding on .rc-tooltip-inner
  margin: -24px;
  padding: 24px;
`

const Account = styled.li`
  display: flex;
  align-items: center;

  ${SidebarAccount} {
    margin-right: 8px;
  }

  & + & {
    margin-top: 24px;
  }

  cursor: pointer;

  &:hover {
    color: ${color.primary};

    ${Role} {
      color: ${color.primary};
    }
  }
`

const CheckSvg = styled(Svg)`
  color: ${color.secondary};
`

const AccountSwitcher = () => {
  const intl = useIntl()
  const { logOut, user, account, availableAccounts, switchAccount } = useContext(authContext)
  const { trackEvent } = useContext(trackingContext)
  const { addNotification } = useContext(notificationContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // Preload manage account page
    import(/* webpackChunkName: "account" */ '../flows/Account/Account').catch(console.warn)
  })

  const accounts = useMemo(() => reject(['id', account?.id], availableAccounts), [account?.id, availableAccounts])

  const permissionProfileName = useMemo(() => {
    const acc = find(['id', account?.id], availableAccounts)
    return acc?.membershipType === 'COLLABORATORS' ? null : acc?.permissionProfileName
  }, [account?.id, availableAccounts])

  const onLogout = useCallback(() => {
    trackEvent('logout_clicked')
    logOut()
  }, [logOut, trackEvent])

  const canSwitchAccount = !user.diceStaff && accounts.length > 0

  const onSwitch = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']

      const promise = switchAccount ? switchAccount(id) : Promise.resolve(null)

      promise
        .then((newToken) => {
          if (newToken) {
            if (pathname !== '/dashboard') {
              navigate('/dashboard')
            }
          } else {
            addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
          }
        })
        .catch((err) => {
          addNotification('error', err.message)
        })
    },
    [addNotification, intl, navigate, pathname, switchAccount]
  )

  const accountName = useMemo(() => {
    if (user.diceStaff)
      return (
        <>
          <DiceBadge />
          DICE
        </>
      )
    return account ? account.name : intl.formatMessage({ id: 'no_account' })
  }, [account, intl, user.diceStaff])

  const autoApproval = !!account?.allowSkipReview

  const saveLocation = useCallback(() => {
    if (!user.diceStaff && user.mioRedesignV2) {
      sessionStorage.setItem('lastLocationBeforePromoterSettings', pathname)
    }
  }, [pathname, user.diceStaff, user.mioRedesignV2])

  return (
    <Container>
      <UserInfo>
        <SidebarAccount noTitle name={getOr('', 'name', user)} />
        <UserName>
          <div>{user.name}</div>
          {autoApproval && (
            <TitleTooltip title={intl.formatMessage({ id: 'verified_for_auto_approval' })}>
              <CheckSvg icon="check" />
            </TitleTooltip>
          )}
        </UserName>
        <AccountName>{accountName}</AccountName>
        {permissionProfileName && <Role>{permissionProfileName}</Role>}
        <Buttons>
          <Button
            preset="secondary"
            to={!user.diceStaff && user.mioRedesignV2 ? '/settings' : '/account'}
            onClick={saveLocation}
          >
            {intl.formatMessage({ id: !user.diceStaff && user.mioRedesignV2 ? 'account_settings' : 'manage_account' })}
          </Button>
          <Button onClick={onLogout}>{intl.formatMessage({ id: 'sign_out' })}</Button>
        </Buttons>
      </UserInfo>
      {canSwitchAccount && (
        <>
          <Separator />
          <Accounts>
            {accounts.map((acct) => (
              <Account key={acct.id} data-id={acct.id} onClick={onSwitch}>
                <SidebarAccount noTitle name={getOr('', 'name', user)} />
                <div>
                  <strong>{user.name}</strong>
                  <div>{acct.name}</div>
                </div>
                <Role>{acct.membershipType !== 'COLLABORATORS' && acct.permissionProfileName}</Role>
              </Account>
            ))}
          </Accounts>
        </>
      )}
    </Container>
  )
}

export default memo(AccountSwitcher)
