import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { getYear } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import { breakpoints, color, font, mediaQuery } from '../../utils/variables'
import AuthSidebar from './AuthSidebar'

const Layout = styled.div`
  display: flex;
  position: absolute;
  min-height: 100%;
  width: 100%;

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
  `}
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  min-height: 100%;
  flex-direction: column;

  ${mediaQuery.lessThan('tablet')`
    margin-top: 32px;
  `}
`

const FormWrapper = styled.div`
  flex-grow: 0;
  padding: 0 96px;

  ${mediaQuery.lessThan('tablet')`
    padding: 0 24px;
  `}
`

const Buffer = styled.div`
  flex-grow: 1;
  flex-basis: 80px;

  ${mediaQuery.lessThan('tablet')`
    display: none;
  `}
`

const MobileFooter = styled.div`
  flex-grow: 0;
  text-align: center;
  padding: 32px;
  display: none;

  ${mediaQuery.lessThan('tablet')`
    display: block;
  `}
`

const FadeOut = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 138px;

  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

  color: ${color.white};
  font-size: ${font.size.sm}px;
  line-height: 100%;

  display: flex;
  align-items: flex-end;
  padding: 32px;

  ${mediaQuery.lessThan('tablet')`
    bottom: 0;
    height: 68px;
  `}
`

const Slogan = styled.div`
  position: absolute;
  left: 32px;
  top: 84px;

  color: ${color.white};
  font-size: ${font.size.sm}px;
  line-height: 125%;

  overflow: hidden;
  white-space: nowrap;

  ${mediaQuery.lessThan('tablet')`
    left: 16px;
    top: 227px;
  `}
`

const Small = styled.span`
  font-size: ${font.size.sm}px;
`

const YEAR = getYear(new Date())

interface IProps {
  withBuffer?: boolean
}

const AuthLayout: FC<React.PropsWithChildren<IProps>> = ({ withBuffer, children }) => {
  const intl = useIntl()
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  return (
    <Layout>
      <AuthSidebar>
        <FadeOut>{!isMobile && intl.formatMessage({ id: 'dice_copyright_with_year' }, { year: YEAR })}</FadeOut>
      </AuthSidebar>
      <Slogan>{intl.formatMessage({ id: 'signup.slogan' })}</Slogan>
      <Container>
        {withBuffer && <Buffer />}
        <FormWrapper>{children}</FormWrapper>
        {withBuffer && (
          <>
            <Buffer />
            <MobileFooter>
              <Small className="color-darkgrey line-height-heading">
                {intl.formatMessage({ id: 'dice_copyright_with_year' }, { year: YEAR })}
              </Small>
            </MobileFooter>
          </>
        )}
      </Container>
    </Layout>
  )
}

export default AuthLayout
