import { commitMutation, Environment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'

import { submitDraftMutation } from '../../../__generated__/submitDraftMutation.graphql'

export default async function submitDraft(environment: Environment, id: string): Promise<void> {
  return new Promise<void>((resolve, reject) =>
    commitMutation<submitDraftMutation>(environment, {
      mutation: graphql`
        mutation submitDraftMutation($input: UpdateEventStateInput!) {
          updateEventState(input: $input) {
            event {
              state
            }
          }
        }
      `,
      variables: {
        input: {
          clientMutationId: nanoid(),
          id,
          state: 'SUBMITTED',
        },
      },
      onCompleted: (_data, errors) => {
        if (errors) {
          reject(errors)
        } else {
          resolve()
        }
      },
      onError: reject,
    })
  )
}
