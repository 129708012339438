import React, { useState, useCallback, useMemo, useContext, FC, memo } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'

import { commitMutation, useFragment, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { notificationContext } from '../../../../context/notification'

import IconButton from '../../../../components/IconButton'
import { Menu, MenuItem } from '../../../../components/Menu'
import { Dropdown, DropdownTrigger, DropdownContent } from '../../../../components/Dropdown'
import { OnDesktopLarge, OnDesktop, OnMobile } from '../../../../components/Breakpoints'
import EventReviewModal from '../../../../components/EventReviewModal'
import { STEP_COUNT } from '../../../EventForm/services/getStepsConfig'
import PermissionCheck from '../../../../components/PermissionCheck'
import { ConfirmationModal } from '../../../../components/ConfirmationModal'
import { EventListItemControlWrapper } from './QuickActions'

import { QuickActionsDrafts_event$key } from '../../../../__generated__/QuickActionsDrafts_event.graphql'

import useCopyEvent from '../../hooks/useCopyEvent'
import { authContext } from '../../../../context/auth'
import usePreviewEventOnDiceCallback from '../../../../utils/hooks/usePreviewEventOnDiceCallback'
import useLazyEventPreviewToken from '../../../../utils/hooks/useLazyEventPreviewToken'
import { Loader, LoaderContainer } from '../../../../components/Loader'
import ListTypeProtector from './ListTypeProtector'
import DiceBadge from '../../../../components/DiceBadge'
import useDeleteEvent from '../../hooks/useDeleteEvent'
import { dicefmPreview } from '../../../../utils/dicefm'
import copyToClipboard from '../../../../utils/copyToClipboard'

interface IProps {
  event: QuickActionsDrafts_event$key
  stripeIsBroken: boolean
}

const QuickActionsDrafts: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, stripeIsBroken }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { addNotification } = useContext(notificationContext)
  const { user } = useContext(authContext)
  const environment = useRelayEnvironment()

  const event = useFragment(
    graphql`
      fragment QuickActionsDrafts_event on Event {
        id
        eventType
        statusAsOfNow
        completedSteps
        tokens {
          doorlistExportToken
        }
      }
    `,
    eventKey
  )

  const { previewToken, loadPreviewToken, loadingPreviewToken } = useLazyEventPreviewToken(event.id)
  const previewEventOnDice = usePreviewEventOnDiceCallback(previewToken)

  const copyPreviewLinkToClipboard = useCallback(() => {
    const previewEventOnDiceLink = previewToken && dicefmPreview(previewToken)
    copyToClipboard(previewEventOnDiceLink)
      .then(() => {
        addNotification('success', intl.formatMessage({ id: 'event_list.quick_actions.url_copied' }))
      })
      .catch((err) => {
        console.error(err)
      })
  }, [addNotification, intl, previewToken])

  const [open, setOpen] = useState(false)
  const [eventReviewModal, setEventReviewModal] = useState(false)
  const [duplicateConfirmationModal, setDuplicateConfirmationModal] = useState(false)

  const { removeConfirmationModal, setRemoveConfirmationModal, deleteEvent, deleting } = useDeleteEvent(event.id)

  const clickOutside = useCallback(() => open && setOpen(false), [open])
  const toggleDropdown = useCallback(() => {
    const newVal = !open

    if (newVal) {
      loadPreviewToken()
    }

    setOpen(newVal)
  }, [loadPreviewToken, open])

  const toggleDuplicateModal = useCallback(() => {
    setOpen(false)
    setDuplicateConfirmationModal(!duplicateConfirmationModal)
  }, [duplicateConfirmationModal])

  const allowEdit = useMemo(() => {
    // pass extra permissions here
    if (event.statusAsOfNow === 'draft') return true
    return false
  }, [event])

  const toggleRemoveModal = useCallback(() => {
    setOpen(false)
    setRemoveConfirmationModal(!removeConfirmationModal)
  }, [removeConfirmationModal, setRemoveConfirmationModal])

  const toggleEventReviewModal = useCallback(() => {
    clickOutside()
    setEventReviewModal(!eventReviewModal)
  }, [clickOutside, eventReviewModal])

  const submitEvent = useCallback(
    async (id: any, values: any) => {
      const isRecurringEventPartAlready = (values.recurrentEventsGroup?.length || 0) > 1

      if (
        !isRecurringEventPartAlready &&
        values.recurrentEventSchedule &&
        values.recurrentEventSchedule.frequency &&
        values.recurrentEventSchedule.repeatEnds
      ) {
        const input = {
          clientMutationId: id || values.id,
          id: id || values.id,
          ...values.recurrentEventSchedule,
        }

        await new Promise<void>((resolve, reject) =>
          commitMutation(environment, {
            mutation: graphql`
              mutation QuickActionsDraftsCloneDraftsMutation($input: CloneEventInput!) {
                cloneEvent(input: $input) {
                  events {
                    state
                  }
                }
              }
            `,
            variables: {
              input,
            },
            onCompleted: (data, errors) => {
              resolve()
              if (errors && errors.length > 0) {
                // DO NOTHING, errors already reported
              } else {
                navigate(`/events/${values.id}/success`)
              }
            },
            onError: () => {
              addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
              reject()
            },
          })
        )
      } else {
        await new Promise<void>((resolve, reject) =>
          commitMutation(environment, {
            mutation: graphql`
              mutation QuickActionsDraftsSubmitEventMutation($input: UpdateEventStateInput!) {
                updateEventState(input: $input) {
                  event {
                    state
                  }
                }
              }
            `,
            variables: {
              input: {
                clientMutationId: id || values.id,
                id: id || values.id,
                state: 'SUBMITTED',
              },
            },
            onCompleted: (data, errors) => {
              resolve()
              if (errors && errors.length > 0) {
                // DO NOTHING, errors already reported
              } else {
                navigate(`/events/${values.id}/success`)
              }
            },
            onError: () => {
              addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
              reject()
            },
          })
        )
      }

      toggleEventReviewModal()
    },
    [toggleEventReviewModal, environment, navigate, addNotification, intl]
  )

  const copyEvent = useCopyEvent(event)

  return (
    <EventListItemControlWrapper>
      <OnDesktopLarge>
        {(event.completedSteps || 0) < STEP_COUNT && event.statusAsOfNow === 'draft' ? (
          <PermissionCheck permission="create:event">
            <IconButton
              icon="edit"
              to={allowEdit && `/events/${event.id}/edit`}
              disabled={!allowEdit}
              title={intl.formatMessage({ id: 'event_list.quick_actions.edit_event' })}
              data-id="editEventButton"
            />
          </PermissionCheck>
        ) : (
          <IconButton
            icon="submit"
            onClick={toggleEventReviewModal}
            disabled={event.statusAsOfNow !== 'draft'}
            title={intl.formatMessage({ id: 'event_list.quick_actions.submit_event' })}
            data-id="submitEventButton"
          />
        )}
      </OnDesktopLarge>
      <Dropdown active={open} onClickOutside={clickOutside}>
        <DropdownTrigger role="button" onClick={toggleDropdown} data-id="quickActions" onMouseEnter={loadPreviewToken}>
          <OnDesktop>
            <IconButton icon="more" />
          </OnDesktop>
          <OnMobile>
            <IconButton outlineColor="grey" icon="more" />
          </OnMobile>
        </DropdownTrigger>
        <DropdownContent active={open}>
          <Menu>
            {event.completedSteps === STEP_COUNT && event.statusAsOfNow === 'draft' ? (
              <MenuItem
                onClick={toggleEventReviewModal}
                disabled={event.statusAsOfNow !== 'draft'}
                data-id="submitEvent"
              >
                {intl.formatMessage({ id: 'event_list.quick_actions.submit_event' })}
              </MenuItem>
            ) : null}
            {allowEdit && (
              <PermissionCheck permission="create:event">
                <MenuItem data-id="editEvent">
                  <Link to={`/events/${event.id}/edit`}>
                    {intl.formatMessage({ id: 'event_list.quick_actions.edit_event' })}
                  </Link>
                </MenuItem>
              </PermissionCheck>
            )}
            {loadingPreviewToken ? (
              <>
                <MenuItem>
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                </MenuItem>
                <MenuItem>
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                </MenuItem>
              </>
            ) : (
              previewToken && (
                <>
                  <MenuItem onClick={previewEventOnDice} data-id="previewEvent">
                    {intl.formatMessage({ id: 'event_list.quick_actions.preview_event_on_dice' })}
                  </MenuItem>
                  <MenuItem onClick={copyPreviewLinkToClipboard} data-id="copyPreviewUrl">
                    {intl.formatMessage({ id: 'event_list.quick_actions.copy_preview_url' })}
                  </MenuItem>
                </>
              )
            )}
            {(event.eventType === 'LIVE' || user.diceStaff) && (
              <PermissionCheck permission="create:event">
                <MenuItem onClick={toggleDuplicateModal} data-id="duplicateEvent" disabled={stripeIsBroken}>
                  {intl.formatMessage({ id: 'event_list.quick_actions.duplicate_event' })}
                </MenuItem>
              </PermissionCheck>
            )}
            {(event.statusAsOfNow === 'draft' || user.diceStaff) && (
              <PermissionCheck permission="delete:event">
                <MenuItem onClick={toggleRemoveModal} data-id="deleteEvent">
                  {event.statusAsOfNow !== 'draft' && <DiceBadge />}
                  {intl.formatMessage({ id: 'event_list.quick_actions.delete_event' })}
                </MenuItem>
              </PermissionCheck>
            )}
          </Menu>
        </DropdownContent>
      </Dropdown>
      {eventReviewModal && <EventReviewModal id={event.id} onSave={submitEvent} onClose={toggleEventReviewModal} />}
      {removeConfirmationModal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'confirmation.delete_event.title' })}
          description={intl.formatMessage({ id: 'confirmation.delete_event.description' })}
          onConfirm={deleteEvent}
          onReject={toggleRemoveModal}
          loading={deleting}
        />
      )}
      {duplicateConfirmationModal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'confirmation.duplicate_event.title' })}
          description={intl.formatMessage({ id: 'confirmation.duplicate_event.description' })}
          onConfirm={copyEvent}
          onReject={toggleDuplicateModal}
        />
      )}
    </EventListItemControlWrapper>
  )
}

export default memo(ListTypeProtector(QuickActionsDrafts, 'draft'))
