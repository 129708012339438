import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import styled from 'styled-components/macro'
import { EventAssigneeControl_eventReview$key } from '../__generated__/EventAssigneeControl_eventReview.graphql'
import { color, font, input, zIndex } from '../utils/variables'
import { Icon } from '../stories/components/informational/Svg.stories'
import { Dropdown, DropdownTrigger, DropdownContent } from './Dropdown'
import { Menu, MenuItem } from './Menu'
import useAssignEventReview from '../utils/hooks/useAssignEventReview'
import { Loader } from './Loader'
import { authContext } from '../context/auth'
import { convertViewerIdToUserId } from '../utils/convertViewerIdToUserId'
import { FlexFormField } from './FormField'
import useAssignEventReviewOptions from '../utils/hooks/useAssignEventReviewOptions'
import InitialsAvatar from './InitialsAvatar'
import Svg from './Svg'

interface IUserOption {
  value: string | null
  label: string | null
  email?: string | null
  firstName?: string | null
  lastName?: string | null
}

interface IProps {
  eventReview: EventAssigneeControl_eventReview$key
}

const EventAssigneeControl: FC<IProps> = ({ eventReview: eventKey }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const [open, setOpen] = useState(false)
  const [userOptions, setUserOptions] = useState<IUserOption[]>([])

  const clickOutside = useCallback(() => open && setOpen(false), [open])

  const toggleDropdown = useCallback(() => {
    setUserOptions([])
    setOpen(!open)
  }, [open])

  const eventReview = useFragment(
    graphql`
      fragment EventAssigneeControl_eventReview on EventReview {
        id
        assignee {
          id
          email
          name
          firstName
          lastName
        }
        event {
          id
        }
      }
    `,
    eventKey
  )

  const { assignee, event } = eventReview

  const { doAssign, assignToMe, unassign, loading } = useAssignEventReview(event?.id || null)
  const isAssignedToMe = useMemo(() => assignee?.id === convertViewerIdToUserId(user.id), [assignee?.id, user.id])

  const { userLoader } = useAssignEventReviewOptions()

  const handleAssignToMe = useCallback(() => {
    assignToMe()
    toggleDropdown()
  }, [assignToMe, toggleDropdown])

  const handleUnassign = useCallback(() => {
    unassign()
    toggleDropdown()
  }, [toggleDropdown, unassign])

  const setUser = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const userId = e.currentTarget.dataset['userId'] || null
      doAssign(userId)
      toggleDropdown()
    },
    [doAssign, toggleDropdown]
  )

  const userOptionsCallback = useCallback((options: IUserOption[]) => {
    setUserOptions(options)
  }, [])

  const handleUserSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const term = e.target.value
      userLoader(term, userOptionsCallback)
    },
    [userLoader, userOptionsCallback]
  )

  return (
    <Dropdown active={open} onClickOutside={clickOutside}>
      <DropdownTrigger role="button" onClick={toggleDropdown} data-id="quickActions">
        {loading ? (
          <AssigneeButton disabled loading>
            <Loader />
          </AssigneeButton>
        ) : (
          <AssigneeButton>
            {assignee ? (
              <UserBadge
                {...{
                  value: assignee.id,
                  label: assignee.name,
                  ...assignee,
                }}
              />
            ) : (
              <>
                <Icon icon="group" width={17} />
                <span className="ml-sm">{intl.formatMessage({ id: 'assign' })}</span>
              </>
            )}
          </AssigneeButton>
        )}
      </DropdownTrigger>
      <DropdownContent portal active={open}>
        <Menu>
          <MenuItem disabled={isAssignedToMe} data-id="assign-to-me" onClick={handleAssignToMe}>
            {intl.formatMessage({ id: 'event_list.assign_to_me' })}
          </MenuItem>
          <MenuItem disabled={!assignee} data-id="assign-to-me" onClick={handleUnassign}>
            {intl.formatMessage({ id: 'event_list.unassign' })}
          </MenuItem>
        </Menu>
        <SearchFieldWrapper>
          <FlexFormField
            name="assignee"
            control="text"
            prefix={<SearchIcon icon="search" width={32} />}
            inputClassName="assignee-input"
            placeholder={intl.formatMessage({ id: 'event_review.assign.search_user.placeholder' })}
            onChange={handleUserSearch}
          ></FlexFormField>
        </SearchFieldWrapper>
        {userOptions.length > 0 && (
          <OptionsList>
            {userOptions.map((o) => (
              <li key={o.value}>
                <button onClick={setUser} data-user-id={o.value}>
                  <UserBadge wide {...o} />
                </button>
              </li>
            ))}
          </OptionsList>
        )}
      </DropdownContent>
    </Dropdown>
  )
}

export default EventAssigneeControl

interface IUserBadgeProps extends IUserOption {
  wide?: boolean
}

const UserBadge: FC<IUserBadgeProps> = ({ firstName, lastName, email, wide = false }) => {
  return (
    <UserBadgeWrapper wide={wide}>
      <InitialsAvatar firstName={firstName} lastName={lastName} size="xs" />
      <div className="info">
        <span>
          {firstName} {lastName}
        </span>
        <span>{email}</span>
      </div>
    </UserBadgeWrapper>
  )
}

const UserBadgeWrapper = styled.div<{ wide?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${font.size.sm}px;
  min-width: 160px;

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: ${({ wide }) => (wide ? 285 : 136)}px;
    }

    span:last-child {
      opacity: 0.5;
    }
  }
`

const AssigneeButton = styled.button<{ loading?: boolean }>`
  padding: 6px 12px;
  background: ${color.white};
  border-radius: ${input.borderRadius}px;
  border: none;
  min-height: 40px;

  display: flex;
  align-items: center;

  &:hover,
  &:active {
    background: ${color.black}0f;
  }

  ${UserBadgeWrapper} {
    margin-left: -2px;
  }

  ${({ loading }) =>
    loading &&
    `
    min-width: 90px;
    justify-content: center;
  `}
`

const SearchFieldWrapper = styled.div`
  min-width: 328px;
  padding: 16px;

  .assignee-input {
    padding-left: 6px;
  }
`

const SearchIcon = styled(Svg)`
  margin-left: 8px;
`

const OptionsList = styled.ul`
  padding: 6px 0 8px;
  max-height: 320px;
  overflow: auto;

  button {
    background: none;
    border: none;
    width: 100%;
    padding: 8px 16px;

    &:hover,
    &:active {
      background: ${color.black}0f;
    }
  }
`
