import styled from 'styled-components/macro'
import { color, font, spacing } from '../utils/variables'

interface ITextProps {
  display?: string
  color?: keyof typeof color
  fontSize?: keyof typeof font.size
  bold?: boolean
  cursor?: string
  ellipsis?: boolean
}

export const Text = styled.span<ITextProps>`
  display: ${(props) => (props.display ? props.display : 'inline-block')};
  color: ${(props) => (props.color ? color[props.color] : color.text)};
  cursor: ${(props) => props.cursor || 'auto'};
  font-size: ${(props) => (props.fontSize ? `${font.size[props.fontSize]}px` : `${font.size.base}px`)};
  line-height: 1.25em;
  font-weight: ${(props) => (props.bold ? font.weight.bold : font.weight.normal)};
  ${({ ellipsis }) =>
    ellipsis &&
    `
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`

export const TextLeft = styled(Text)`
  text-align: left;
`

export const TextCenter = styled(Text)`
  text-align: center;
`

export const TextRight = styled(Text)`
  text-align: right;
`

interface IParagraphProps {
  fontSize?: keyof typeof font.size
  bold?: boolean
}

export const Paragraph = styled.p<IParagraphProps>`
  margin: ${spacing.sm}px 0;
  font-size: ${(props) => (props.fontSize ? `${font.size[props.fontSize]}px` : `${font.size.base}px`)};
  font-weight: ${(props) => (props.bold ? font.weight.bold : font.weight.normal)};
`

export const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`
