import emojiRegex from 'emoji-regex'
import emojiRegexText from 'emoji-regex/text'
import { find } from 'lodash/fp'

export default function hasEmoji(str?: string | null) {
  if (!str) return false

  if (emojiRegex().test(str)) return true

  const textMatch = str.match(emojiRegexText())
  const hasEmoji = !!find((v) => !/[0-9#]/.test(v), textMatch || [])

  return hasEmoji
}
