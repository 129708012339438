import { object, string, array, number, ValidationError, InferType, boolean } from 'yup'
import { parseISO, startOfMinute } from 'date-fns'
import { filter, isNil, map, reject, uniqBy } from 'lodash/fp'

import { ISO_DATE_REGEX } from '../../../utils/calendar'
import { IProductVariant } from '../types/Extras'

const DictSchema = object().shape({
  value: string().required(),
  label: string(),
})

const TicketTypeSchema = object().shape({
  id: string().required(),
  name: string(),
})

const SellingPointsSchema = object().shape({
  name: string().required().max(38, ' '),
})

const VariantSchema = object().shape({
  name: string().required().max(12, ' '),
  allocation: number().integer().min(0),
  sku: string().nullable(),
})

const ImageSchema = object().shape({
  id: string().required(),
  // attachmentId: string().nullable(),
  cdnUrl: string().nullable(),
  cropRegion: object().shape({
    x: number().integer().min(0),
    y: number().integer().min(0),
    width: number().integer().min(0),
    height: number().integer().min(0),
  }),
})

export const ProductSchema = object()
  .shape({
    id: string().required(),
    category: DictSchema,
    name: string().required().max(45, ' '),
    productImages: array().when(['category', 'customCover'], {
      is: (cat, customCover) => customCover || (cat && cat.rootType === 'MERCH'),
      then: array().nullable().of(ImageSchema).min(1).max(8, ' '),
      otherwise: array().nullable().of(ImageSchema).max(7, ' '),
    }),
    sellingPoints: array().nullable().of(SellingPointsSchema).min(1).max(6, ' '),
    hasSeparateAccessBarcodes: boolean().required(),
    venue: DictSchema.when('sameVenue', {
      is: false,
      then: DictSchema.required(),
      otherwise: DictSchema.nullable(),
    }),
    locationNote: string().nullable().max(20, ' '),
    date: string().when('sameDates', {
      is: 'CUSTOM',
      then: string().nullable().matches(ISO_DATE_REGEX).required(),
      otherwise: string().nullable().matches(ISO_DATE_REGEX),
    }),
    endDate: string().when('sameDates', {
      is: 'CUSTOM',
      then: string().nullable().matches(ISO_DATE_REGEX).required(),
      otherwise: string().nullable().matches(ISO_DATE_REGEX),
    }),
    description: string().required().max(1300, ' '),
    faceValue: number().integer().required().min(0),
    allocation: number().when('hasVariants', {
      is: false,
      then: number().integer().min(0),
      otherwise: number().integer().nullable(),
    }),
    variants: array().when('hasVariants', {
      is: false,
      then: array().nullable().of(VariantSchema),
      otherwise: array().nullable().of(VariantSchema).min(2).max(8, ' '),
    }),
    allTicketTypes: boolean().required(),
    ticketTypes: array().when('allTicketTypes', {
      is: false,
      then: array().nullable().of(TicketTypeSchema).min(1),
      otherwise: array().nullable().of(TicketTypeSchema),
    }),
    onSaleDate: string().nullable().matches(ISO_DATE_REGEX),
    offSaleDate: string().nullable().matches(ISO_DATE_REGEX),
    fulfilledBy: string().when('category', {
      is: (cat) => cat && cat.type === '_MERCH',
      then: string().required(),
      otherwise: string().nullable(),
    }),
    purchaseConfirmationMessage: string().required().max(2000, ' '),
  })
  .test('uniqVariant', 'Variant name should be uniq', function (values) {
    if (!values.hasVariants || values.variants.length === uniqBy('name', values.variants).length) {
      return true
    }

    const error = new ValidationError(' ', values, 'uniqVariant')
    error.inner = []

    let valid = true
    const variantsMap = map('name', values.variants)
    values.variants.forEach((v: IProductVariant, idx: number) => {
      if (filter((vm: string) => vm === v.name, variantsMap).length > 1) {
        error.inner.push(this.createError({ path: `variants[${idx}].name`, message: ' ' }))
        valid = false
      }
    })

    return valid || error
  })
  .test('uniqVariantSKU', 'Variant sku should be uniq', function (values) {
    const variantsWithSKU = filter((v) => !!v.sku, values.variants)
    if (!values.hasVariants || variantsWithSKU.length === uniqBy('sku', variantsWithSKU).length) {
      return true
    }

    const error = new ValidationError(' ', values, 'uniqVariantSKU')
    error.inner = []

    let valid = true
    const variantsMap = map('sku', variantsWithSKU)
    values.variants.forEach((v: IProductVariant, idx: number) => {
      if (filter((vm: string) => !!vm && vm === v.sku, variantsMap).length > 1) {
        error.inner.push(this.createError({ path: `variants[${idx}].sku`, message: ' ' }))
        valid = false
      }
    })

    return valid || error
  })
  .test('customSaleDates', 'Custom dates should be either all be present or all missing', function (values) {
    const hasOnSale = !isNil(values.onSaleDate)
    const hasOffSale = !isNil(values.offSaleDate)

    if ((hasOnSale && hasOffSale) || (!hasOnSale && !hasOffSale)) return true

    const error = new ValidationError(' ', values, 'customSaleDates')
    error.inner = []

    if (!hasOnSale) {
      error.inner.push(this.createError({ path: 'onSaleDate', message: ' ' }))
    }
    if (!hasOffSale) {
      error.inner.push(this.createError({ path: 'offSaleDate', message: ' ' }))
    }

    return error
  })
  .test('onSaleBeforeOffSale', 'Custom on-sale date should be before off-sale date', function (values) {
    const eventOnSaleDate: string | undefined = (this.options.context as any)?.onSaleDate
    const eventOffSaleDate: string | undefined = (this.options.context as any)?.offSaleDate

    const valid =
      isNil(values.onSaleDate || eventOnSaleDate) ||
      isNil(values.offSaleDate || eventOffSaleDate) ||
      startOfMinute(parseISO(values.onSaleDate || eventOnSaleDate)) <
        startOfMinute(parseISO(values.offSaleDate || eventOffSaleDate))

    return valid || this.createError({ path: 'onSaleDate', message: 'event_errors.tty.on_sale_before_off_sale' })
  })
  .test(
    'onSaleAfterEventOnSale',
    'Custom on-sale date should be after or equal to event on-sale date',
    function (values) {
      const onSaleDate: string | undefined = (this.options.context as any)?.onSaleDate
      const valid =
        isNil(values.onSaleDate) ||
        isNil(onSaleDate) ||
        startOfMinute(parseISO(values.onSaleDate)) >= startOfMinute(parseISO(onSaleDate))
      return valid || this.createError({ path: 'onSaleDate', message: 'event_errors.tty.on_sale_before_event' })
    }
  )
  .test('offSaleBeforeEventOffSale', 'Custom off-sale date should be before event off-sale date', function (values) {
    const offSaleDate: string | undefined = (this.options.context as any)?.offSaleDate
    const valid =
      isNil(values.offSaleDate) ||
      isNil(offSaleDate) ||
      startOfMinute(parseISO(values.offSaleDate)) <= startOfMinute(parseISO(offSaleDate))
    return valid || this.createError({ path: 'offSaleDate', message: 'event_errors.tty.off_sale_after_event' })
  })
  .test('startDateBeforeEndDate', 'Start date should be before end date', function (values) {
    const hasCustomDates = !isNil(values.date) && !isNil(values.endDate)
    const valid = !hasCustomDates || startOfMinute(parseISO(values.date)) < startOfMinute(parseISO(values.endDate))
    return valid || this.createError({ path: 'date', message: 'event_errors.extra.date_after_end_date' })
  })
  .test('startDateAfterOnSale', 'Start date should be equal or after on-sale date', function (values) {
    const eventOnSaleDate: string | undefined = (this.options.context as any)?.onSaleDate

    let valid = true
    if (!isNil(values.onSaleDate)) {
      valid = isNil(values.date) || startOfMinute(parseISO(values.date)) >= startOfMinute(parseISO(values.onSaleDate))
    } else {
      valid =
        isNil(values.date) ||
        isNil(eventOnSaleDate) ||
        startOfMinute(parseISO(values.date)) >= startOfMinute(parseISO(eventOnSaleDate))
    }
    return valid || this.createError({ path: 'date', message: 'event_errors.extra.date_after_on_sale' })
  })

type IProduct = InferType<typeof ProductSchema>

const ExtrasSchema = object()
  .shape({
    products: array().nullable(),
  })
  .test('innerProductErrors', 'Lets revalidate inner products', function (values) {
    const locale = (this.options.context as any).locale

    const products = values.products && reject('archived', values.products)

    if (!products || products.length === 0) return true

    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff, dicePartner } = ctx

    let valid = true
    const errs: ValidationError[] = []
    products.forEach((product: IProduct, idx: number) => {
      try {
        ProductSchema.validateSync(product, { context: { locale, diceStaff, dicePartner, ...values } })
      } catch (e) {
        errs.push(this.createError({ path: `products[${idx}].innerError`, message: ' ' }))
        valid = false
      }
    })

    if (valid) return true

    const error = new ValidationError(' ', values, 'innerProductErrors')
    error.inner = errs
    return error
  })

export default ExtrasSchema
