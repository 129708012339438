import React, { FC, memo } from 'react'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import { color, mediaQuery } from '../../../utils/variables'
import { Notes_event$key } from '../../../__generated__/Notes_event.graphql'

const Container = styled.div`
  padding: 32px;

  ${mediaQuery.lessThan('tablet')`
    padding: 24px 16px;
  `}
`

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`

const Info = styled.div`
  background: ${color.palegrey};
  border: 2px solid ${color.lightgrey};
  border-radius: 4px;
  padding: 10px 16px;

  min-height: 150px;
  word-break: break-word;
  white-space: pre-wrap;
`

const Notes: FC<React.PropsWithChildren<{ event?: Notes_event$key }>> = ({ event: eventKey }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment Notes_event on Event {
        extraNotes
      }
    `,
    eventKey || null
  )

  return event?.extraNotes ? (
    <Container>
      <Title>{intl.formatMessage({ id: 'event_overview.notes.title' })}</Title>
      <Info>{event.extraNotes}</Info>
    </Container>
  ) : null
}

export default memo(Notes)
