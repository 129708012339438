/**
 * @generated SignedSource<<277d2a0a5626db3e382a9a60cea0d93c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type StripeAccountIntegrityState = "CHARGES_DISABLED" | "OK" | "PAYOUTS_DISABLED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventList_viewer$data = {
  readonly account: {
    readonly stripeAccountState: StripeAccountIntegrityState;
  } | null;
  readonly events: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"EventListItem_event">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "EventList_viewer";
};
export type EventList_viewer$key = {
  readonly " $data"?: EventList_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventList_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "events"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 15,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isDraft"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "needBalance"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "noSelfPayouts"
    },
    {
      "defaultValue": [
        "dateASC"
      ],
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "scopes"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./EventListPaginationQuery.graphql')
    }
  },
  "name": "EventList_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stripeAccountState",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "events",
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "scopes",
          "variableName": "scopes"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "EventConnection",
      "kind": "LinkedField",
      "name": "__EventList_events_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Event",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "isDraft",
                      "variableName": "isDraft"
                    },
                    {
                      "kind": "Variable",
                      "name": "needBalance",
                      "variableName": "needBalance"
                    },
                    {
                      "kind": "Variable",
                      "name": "noSelfPayouts",
                      "variableName": "noSelfPayouts"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "EventListItem_event"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "8169a6ec01f954786a6b3e9a47b71773";

export default node;
