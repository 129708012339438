import React from 'react'
import styled from 'styled-components/macro'
import Svg from '../../../components/Svg'

export const PromotionSale = styled.span`
  display: block;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  svg + span {
    display: inline;
  }
`

const PromotionTypeIcon = styled(Svg)`
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
`

const PromotionSaleLock = styled(PromotionTypeIcon)`
  margin: 0 2px 0 -2px;
`

const PromotionSaleTag = styled(PromotionTypeIcon)`
  margin: 0 2px 0 -2px;
`

export const drawPromotionIcon = (promotionType: string | undefined | null) => {
  switch (promotionType) {
    case 'DISCOUNT':
      return <PromotionSaleTag icon="tag" />
    case 'CODE_LOCK':
      return <PromotionSaleLock icon="lock" />
    default:
      return null
  }
}
