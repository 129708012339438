import React, { FC, HTMLProps, useMemo } from 'react'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid'

import { color, spacing } from '../utils/variables'

import Icon from './Svg'
import { TitleTooltip } from './Tooltip'

export const CheckboxSkeleton = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  color: ${color.disabled};
  animation: opacityAnimation 1.5s infinite;
  @keyframes opacityAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
  &:before {
    content: '';
    display: block;
    flex: 24px 0 0;
    width: 24px;
    height: 24px;
    border: 2px solid currentColor;
    border-radius: 4px;
  }
  &:after {
    content: '';
    display: flex;
    flex-grow: 1;
    height: 16px;
    margin: 0 32px 0 8px;
    background: currentColor;
  }
`

const CheckboxBox = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid currentColor;
  border-radius: 4px;
`

const CheckboxCheck = styled(Icon)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: currentColor;
`

export const CheckboxLabel = styled.span<{ hasError?: boolean }>`
  display: block;
  min-height: 24px;
  line-height: 24px;
  color: ${({ hasError }) => (hasError ? color.error : color.text)};
  flex: 1 1 auto;
  margin-left: ${spacing.sm}px;
  cursor: pointer;
  user-select: none;
`

const CheckboxControl = styled.label<{
  color: keyof typeof color
  overrideColor?: string
  overrideColorHover?: string
  disabled?: boolean
  checked?: boolean | null
  triState?: boolean
  hasError?: boolean
}>`
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: ${(props) => props.overrideColor || (color[props.color] ? color[props.color] : color.grey)};
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
    cursor: not-allowed;
  `};
  &:hover {
    ${CheckboxBox} {
      border-color: ${({ checked, hasError, overrideColorHover }) =>
    hasError ? color.error : checked ? 'currentColor' : overrideColorHover || '#b2b2b2'};
    }
  }
  ${(props) =>
    (props.checked || (props.triState && props.checked === null)) &&
    `
    ${CheckboxCheck} {
      opacity: 1;
      color: ${color.white};
    }
    ${CheckboxBox} {
      border-color: ${props.hasError ? color.error : props.color === 'grey' ? color.primary : 'currentColor'};
      background: ${props.hasError ? color.error : props.color === 'grey' ? color.primary : 'currentColor'};
    }
    &:hover {
      ${CheckboxBox} {
        border-color: ${props.hasError ? color.error : props.color === 'grey' ? color.primary : 'currentColor'};
        background: ${props.hasError ? color.error : props.color === 'grey' ? color.primary : 'currentColor'};
      }
    }
  `};
  ${({ hasError }) =>
    hasError &&
    `
    color: ${color.error};
    ${CheckboxBox} {
      opacity: 1;
    }
  `}
`

const CheckboxInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
`

const CheckboxInner = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const CheckboxContainer = styled.span`
  display: block;
  flex: none;
  position: relative;
  background: #fff;
  border-radius: 4px;
`

export type ICheckboxProps = {
  checked?: boolean | null
  hasError?: boolean
  label?: any
  onChange?: (e: any) => void
  color?: keyof typeof color
  overrideColor?: string
  overrideColorHover?: string
  disabled?: boolean
  className?: string
  triState?: boolean
  titlePlacement?: 'top' | 'bottom' | 'left' | 'right'
} & Omit<Omit<Omit<Omit<HTMLProps<HTMLInputElement>, 'ref'>, 'as'>, 'checked'>, 'label'>

const Checkbox: FC<React.PropsWithChildren<ICheckboxProps>> = ({
  triState,
  className,
  checked,
  hasError,
  label,
  color = 'grey',
  overrideColor,
  overrideColorHover,
  disabled,
  title,
  titlePlacement,
  ...inputProps
}) => {
  const tooltipId = useMemo(() => (title ? nanoid() : undefined), [title])
  const checkbox = (
    <CheckboxContainer>
      <CheckboxBox />
      <CheckboxCheck icon={triState && checked === null ? 'minus' : 'check'} />
    </CheckboxContainer>
  )

  return (
    <CheckboxControl
      className={className}
      color={color}
      overrideColor={overrideColor}
      overrideColorHover={overrideColorHover}
      hasError={hasError}
      checked={checked}
      disabled={disabled}
      triState={triState}
      data-testid="checkboxLabel"
    >
      <CheckboxInput type="checkbox" checked={!!checked} disabled={disabled} {...inputProps} />
      <CheckboxInner>
        {title ? (
          <TitleTooltip id={tooltipId} title={title} placement={titlePlacement || 'top'}>
            {checkbox}
          </TitleTooltip>
        ) : (
          checkbox
        )}

        {label && <CheckboxLabel hasError={hasError}>{label}</CheckboxLabel>}
      </CheckboxInner>
    </CheckboxControl>
  )
}

export default styled(Checkbox)``
