import React, { FC, useCallback, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import qs from 'qs'
import graphql from 'babel-plugin-relay/macro'
import { commitMutation, Environment, useRelayEnvironment } from 'react-relay'
import { nanoid } from 'nanoid'
import { useLocation } from 'react-router'

import Button from '../../components/Button'
import { StripeOnboardingPageMutation } from '../../__generated__/StripeOnboardingPageMutation.graphql'
import ConfirmationPage, { STATUSES } from '../../components/ConfirmationPage'
import { trackingContext } from '../../context/tracking'

const finishOnboarding = (environment: Environment, code: string, state: string) =>
  new Promise<'success' | 'failure'>((resolve, reject) => {
    commitMutation<StripeOnboardingPageMutation>(environment, {
      mutation: graphql`
        mutation StripeOnboardingPageMutation($input: FinishStripeOnboardingInput!) {
          finishStripeOnboarding(input: $input) {
            promoter {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          clientMutationId: nanoid(),
          onboardingCode: code,
          state: state,
        },
      },
      onCompleted: (data, errors) => {
        if (errors && errors.length > 0) {
          console.error('Error finishing stripe onboarding', errors)
        }
        const status = (
          data.finishStripeOnboarding?.promoter?.id || !(errors && errors.length > 0)
            ? STATUSES.SUCCESS
            : STATUSES.FAILURE
        ) as 'success' | 'failure'

        resolve(status)
      },
      onError: reject,
    })
  })

const StripeOnboardingPage: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const location = useLocation()
  const { trackEvent } = useContext(trackingContext)
  const environment = useRelayEnvironment()

  useEffect(() => {
    trackEvent('billing_account_confirmed')
  }, [trackEvent])

  const extractParams = useCallback(() => {
    const { code, state } = qs.parse(location.search, { ignoreQueryPrefix: true })
    return code && state ? [environment, code, state] : null
  }, [environment, location.search])

  return (
    <ConfirmationPage prefix="stripe_confirmation" extractParams={extractParams} fetchData={finishOnboarding}>
      <Button color="secondary" to="/">
        {intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
      </Button>
    </ConfirmationPage>
  )
}

export default StripeOnboardingPage
