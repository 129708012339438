/**
 * @generated SignedSource<<4a29d58406dc48135bad403f817f1904>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CharacteristicLevel = "event" | "venue" | "%future added value";
export type CharacteristicWhereInput = {
  level?: CharacteristicLevel | null;
  name?: OperatorsString | null;
};
export type OperatorsString = {
  eq?: string | null;
  iLike?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notLike?: string | null;
  null?: boolean | null;
};
export type useBasicsTagsCharacteristicsQuery$variables = {
  where?: CharacteristicWhereInput | null;
};
export type useBasicsTagsCharacteristicsQuery$data = {
  readonly viewer: {
    readonly options: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly label: string | null;
          readonly value: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useBasicsTagsCharacteristicsQuery = {
  response: useBasicsTagsCharacteristicsQuery$data;
  variables: useBasicsTagsCharacteristicsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useBasicsTagsCharacteristicsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "CharacteristicConnection",
            "kind": "LinkedField",
            "name": "characteristics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CharacteristicEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Characteristic",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useBasicsTagsCharacteristicsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "CharacteristicConnection",
            "kind": "LinkedField",
            "name": "characteristics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CharacteristicEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Characteristic",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "064f7815082848f24c45cfe34a2e16d5",
    "id": null,
    "metadata": {},
    "name": "useBasicsTagsCharacteristicsQuery",
    "operationKind": "query",
    "text": "query useBasicsTagsCharacteristicsQuery(\n  $where: CharacteristicWhereInput\n) {\n  viewer {\n    options: characteristics(first: 100, where: $where) {\n      edges {\n        node {\n          value: id\n          label: name\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "458c97b62b9a7d52330c0ed8deca7b11";

export default node;
