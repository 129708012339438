/**
 * @generated SignedSource<<8047cfa7f8784fa8646dba2550852d0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useSwitchAccountQuery$variables = {
  accountId: string;
};
export type useSwitchAccountQuery$data = {
  readonly viewer: {
    readonly switchAccount: string | null;
  } | null;
};
export type useSwitchAccountQuery = {
  response: useSwitchAccountQuery$data;
  variables: useSwitchAccountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "accountId",
      "variableName": "accountId"
    }
  ],
  "kind": "ScalarField",
  "name": "switchAccount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSwitchAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSwitchAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2631ddbf2ba7294a1a2d350c60212a67",
    "id": null,
    "metadata": {},
    "name": "useSwitchAccountQuery",
    "operationKind": "query",
    "text": "query useSwitchAccountQuery(\n  $accountId: ID!\n) {\n  viewer {\n    switchAccount(accountId: $accountId)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d059e3b1f769a6c6ffecffd73a7d29a1";

export default node;
