/**
 * @generated SignedSource<<7efd6815b4e0267887455551a115277b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventTicketTypeSeatingAreaTypeSelectQuery$variables = Record<PropertyKey, never>;
export type EventTicketTypeSeatingAreaTypeSelectQuery$data = {
  readonly viewer: {
    readonly externalEntities: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string | null;
    } | null> | null;
  } | null;
};
export type EventTicketTypeSeatingAreaTypeSelectQuery = {
  response: EventTicketTypeSeatingAreaTypeSelectQuery$data;
  variables: EventTicketTypeSeatingAreaTypeSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "entityType",
      "value": "seating_area_type"
    },
    {
      "kind": "Literal",
      "name": "integrationType",
      "value": "attractive"
    }
  ],
  "concreteType": "ExternalEntity",
  "kind": "LinkedField",
  "name": "externalEntities",
  "plural": true,
  "selections": [
    {
      "alias": "label",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": "externalEntities(entityType:\"seating_area_type\",integrationType:\"attractive\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventTicketTypeSeatingAreaTypeSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventTicketTypeSeatingAreaTypeSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a12a77f581dc127118d95aca08b090b",
    "id": null,
    "metadata": {},
    "name": "EventTicketTypeSeatingAreaTypeSelectQuery",
    "operationKind": "query",
    "text": "query EventTicketTypeSeatingAreaTypeSelectQuery {\n  viewer {\n    externalEntities(entityType: \"seating_area_type\", integrationType: \"attractive\") {\n      label: name\n      value\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "55b5913e53274741820c5261d80269b9";

export default node;
