import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
  Colors,
  Title,
  TimeSeriesScale,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chartjs-adapter-date-fns'
import { font } from './utils/variables'

Chart.register(Colors)
function chartSetup() {
  Chart.register(ChartDataLabels, Filler, Tooltip, Legend, Colors, Title)
  Chart.register(TimeScale, TimeSeriesScale, LinearScale, CategoryScale)
  Chart.register(PointElement, LineElement, BarElement, ArcElement)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  Chart.defaults.plugins.datalabels!.display = false

  Chart.defaults.font.size = font.size.xs
  Chart.defaults.font.family = font.family.base
}

export default chartSetup
