import graphql from 'babel-plugin-relay/macro'
import { Environment, fetchQuery_DEPRECATED } from 'react-relay'
import { DeepWritable, Dictionary } from 'ts-essentials'
import { compose, keyBy, update, mapValues, set, map, pick, compact } from 'lodash/fp'

import { FeeDestination, FeeInput, getPriceBreakdownQuery } from '../__generated__/getPriceBreakdownQuery.graphql'
import { IFee } from '../flows/EventForm/types/Tickets'

export type IPriceBreakdownRaw = NonNullable<
  NonNullable<getPriceBreakdownQuery['response']['viewer']>['priceCalculation']
>

export interface IPriceBreakdown extends Omit<IPriceBreakdownRaw, 'split'> {
  rebate: number
  split: null | Dictionary<number, FeeDestination>
}

type IConverter = (bd: IPriceBreakdownRaw) => IPriceBreakdown

export const convertBreakdown: IConverter = compose([
  (pb: IPriceBreakdown) => set('rebate', (pb.split?.billingPromoter || 0) - (pb.faceValue || 0), pb),
  update('split', compose([mapValues('computed'), keyBy('destination')])),
])

interface IProps {
  billingPromoterId: string | null
  eventId: string | null
  faceValue: number | null | undefined
  venueIds: string[]
  fees: ReadonlyArray<IFee | null> | null | undefined
  basePriceFees: ReadonlyArray<string | null> | null | undefined
  postFanPriceFees: ReadonlyArray<string | null> | null | undefined
  ignorePwlFee: boolean
  disableUsTax: boolean
  forcePwlActive: boolean | null
}

const getPriceBreakdown = async (
  environment: Environment,
  {
    billingPromoterId,
    eventId,
    faceValue,
    venueIds,
    fees,
    basePriceFees,
    postFanPriceFees,
    ignorePwlFee,
    disableUsTax,
    forcePwlActive,
  }: IProps
): Promise<null | IPriceBreakdown> => {
  const preparedFees: FeeInput[] = compose([map(pick(['active', 'amount', 'split', 'type', 'unit'])), compact])(
    fees || []
  )

  const result = await fetchQuery_DEPRECATED<getPriceBreakdownQuery>(
    environment,
    graphql`
      query getPriceBreakdownQuery(
        $billingPromoterId: ID
        $eventId: ID
        $faceValue: Int!
        $venueIds: [ID]
        $fees: [FeeInput]
        $basePriceFees: [String]
        $postFanPriceFees: [String]
        $ignorePwlFee: Boolean!
        $disableUsTax: Boolean!
        $forcePwlActive: Boolean
      ) {
        viewer {
          priceCalculation(
            billingPromoterId: $billingPromoterId
            eventId: $eventId
            faceValue: $faceValue
            venueIds: $venueIds
            fees: $fees
            ignorePwlFee: $ignorePwlFee
            forcePwlActive: $forcePwlActive
            disableUsTax: $disableUsTax
            basePriceFees: $basePriceFees
            postFanPriceFees: $postFanPriceFees
          ) {
            total
            totalWithPwl
            totalWithoutPwl
            faceValue
            friendlyPrice
            friendlyFaceValue
            breakdown {
              computed
              amount
              unit
              type
              applicable
              split {
                amount
                unit
                computed
                destination
              }
            }
            split {
              computed
              destination
            }
          }
        }
      }
    `,
    {
      billingPromoterId,
      eventId,
      faceValue: faceValue || 0,
      venueIds: venueIds.length === 0 ? null : venueIds,
      fees: preparedFees,
      basePriceFees: (basePriceFees || []) as DeepWritable<typeof basePriceFees>,
      postFanPriceFees: (postFanPriceFees || []) as DeepWritable<typeof postFanPriceFees>,
      ignorePwlFee,
      disableUsTax,
      forcePwlActive,
    }
  )

  const price = result.viewer?.priceCalculation

  return price ? convertBreakdown(price) : null
}

export default getPriceBreakdown
