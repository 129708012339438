import React, { FC, memo, Suspense, useCallback, useContext, useDeferredValue, useMemo, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import styled from 'styled-components/macro'
import { Navigate } from 'react-router'
import { useIntl } from 'react-intl'

import { authContext } from '../../context/auth'
import { PageViewTracker } from '../../context/tracking'
import { collectTrackingData } from '../../utils/tracking'
import { mediaQuery } from '../../utils/variables'

import { Section, Block as PaddedBlock } from '../../components/PageSections'
import { Subnav, SubnavItem } from '../../components/SubNavigation'
import Svg from '../../components/Svg'
import { Loader, LoaderContainer } from '../../components/Loader'
import Notes from './components/Notes'
import { TabMenu, TabMenuItem } from '../../components/TabMenu'

import SalesStats, { SalesStatsSkeleton } from './components/SalesStats'

import EventTimeline from './components/EventTimeline/EventTimeline'

import TicketBreakdownPools from './components/TicketBreakdown/TicketBreakdownPools'
import TimelineChart from './components/TimelineChart'

import { EventOverview_event$key } from '../../__generated__/EventOverview_event.graphql'
import ProductsBreakdown from './components/TicketBreakdown/ProductsBreakdown'
import EventSalesChart from '../../components/EventSalesChart'
import ErrorBoundary from '../../components/ErrorBoundary'

const Container = styled.div<{ mute: boolean }>`
  opacity: ${(props) => (props.mute ? 0.5 : 1)};
`

const StyledLoaderContainer = styled(LoaderContainer)<{ height?: number }>`
  height: ${(props) => props.height || 100}px;
`

const Block = styled.div`
  position: relative;
  min-width: 100%;

  ${mediaQuery.lessThan('tablet')`
    max-width: 100%;
  `}
`

const BreakdownSubnav = styled(Subnav)`
  margin: 24px 0 0;

  ${mediaQuery.lessThan('tablet')`
    margin: 16px 0 0;
  `}
`

const BreakdownControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 32px 32px 0;
  ${mediaQuery.lessThan('tablet')`
    margin: 16px;
  `}
  ${TabMenuItem} svg {
    display: block;
  }
`

const SLoaderContainer = styled(LoaderContainer)`
  min-height: 550px;

  ${mediaQuery.lessThan('tablet')`
    min-height: 252px;
  `}
`

interface IProps {
  event?: EventOverview_event$key
}

const EventOverview: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()
  const { user, account } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment EventOverview_event on Event {
        id
        eventIdLive
        state
        announceDate
        onSaleDate
        offSaleDate
        timezoneName
        date
        endDate
        extraNotes
        allowedActions {
          readExtras
          readMerch
        }

        sales {
          totalSold
        }
        products {
          id
        }

        ...TimelineChart_event
        ...Notes_event
      }
    `,
    eventKey || null
  )

  const [currentBreakdown, setCurrentBreakdown] = useState<
    'ticketBreakdown' | 'extrasBreakdown' | 'merchBreakdown' | 'eventTimeline'
  >('ticketBreakdown')
  const deferredBreakdown = useDeferredValue(currentBreakdown)
  const onClickBreakdownSwitch = useCallback((e: any) => {
    const breakdown = e.currentTarget.dataset.breakdown
    setCurrentBreakdown(breakdown)
  }, [])

  const eventId = event?.id
  const trackData = useMemo(
    () => collectTrackingData(event),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId]
  )

  if (!event) return <Navigate replace to="/404" />

  if (event.state === 'DRAFT') return <Navigate replace to={`/events/${eventId}/edit`} />

  return (
    <div>
      <PageViewTracker trackId="event_overview" trackData={trackData} />
      <Section>
        <PaddedBlock>
          <ErrorBoundary>
            <Suspense
              fallback={
                <SLoaderContainer>
                  <Loader />
                </SLoaderContainer>
              }
            >
              <EventSalesChart eventId={event.id} />
            </Suspense>
          </ErrorBoundary>
        </PaddedBlock>
      </Section>
      <Section>
        <PaddedBlock>
          <Suspense fallback={<SalesStatsSkeleton />}>
            <SalesStats eventId={event.id} />
          </Suspense>
        </PaddedBlock>
      </Section>
      <Section>
        <TimelineChart event={event} />
      </Section>
      <Section>
        <Block>
          {
            // prettier-ignore
            (user.diceStaff ||
            account?.extrasEnabled ||
            !!event.allowedActions?.readExtras ||
            account?.merchEnabled ||
            !!event.allowedActions?.readMerch) &&
            (event.products?.length || 0) > 0 ? (
                <BreakdownControls>
                  <TabMenu>
                    <TabMenuItem
                      disabled={currentBreakdown === 'eventTimeline'}
                      data-breakdown="ticketBreakdown"
                      active={currentBreakdown === 'ticketBreakdown'}
                      onClick={onClickBreakdownSwitch}
                    >
                      {intl.formatMessage({ id: 'tickets' })}
                    </TabMenuItem>
                    {(user.diceStaff || account?.extrasEnabled || !!event.allowedActions?.readExtras) && (
                      <TabMenuItem
                        disabled={currentBreakdown === 'eventTimeline'}
                        data-breakdown="extrasBreakdown"
                        active={currentBreakdown === 'extrasBreakdown'}
                        onClick={onClickBreakdownSwitch}
                      >
                        {intl.formatMessage({ id: 'extras' })}
                      </TabMenuItem>
                    )}
                    {(user.diceStaff || account?.merchEnabled || !!event.allowedActions?.readMerch) && (
                      <TabMenuItem
                        disabled={currentBreakdown === 'eventTimeline'}
                        data-breakdown="merchBreakdown"
                        active={currentBreakdown === 'merchBreakdown'}
                        onClick={onClickBreakdownSwitch}
                      >
                        {intl.formatMessage({ id: 'merch' })}
                      </TabMenuItem>
                    )}
                    <TabMenuItem
                      disabled={currentBreakdown !== 'eventTimeline'}
                      data-breakdown="eventTimeline"
                      active={currentBreakdown === 'eventTimeline'}
                      onClick={onClickBreakdownSwitch}
                    >
                      {intl.formatMessage({ id: 'all' })}
                    </TabMenuItem>
                  </TabMenu>
                  <TabMenu>
                    <TabMenuItem
                      data-breakdown="ticketBreakdown"
                      active={currentBreakdown !== 'eventTimeline'}
                      onClick={onClickBreakdownSwitch}
                      title={intl.formatMessage({ id: 'event_overview.ticket_breakdown.title' })}
                    >
                      <Svg icon="list-items" />
                    </TabMenuItem>
                    <TabMenuItem
                      data-breakdown="eventTimeline"
                      active={currentBreakdown === 'eventTimeline'}
                      onClick={onClickBreakdownSwitch}
                      title={intl.formatMessage({ id: 'event_overview.event_timeline.title' })}
                    >
                      <Svg icon="clock" />
                    </TabMenuItem>
                  </TabMenu>
                </BreakdownControls>
              ) : (
                <BreakdownSubnav>
                  <SubnavItem
                    as="span"
                    data-breakdown="ticketBreakdown"
                    onClick={onClickBreakdownSwitch}
                    className={currentBreakdown === 'ticketBreakdown' ? '-active' : undefined}
                  >
                    {intl.formatMessage({ id: 'event_overview.ticket_breakdown.title' })}
                  </SubnavItem>
                  <SubnavItem
                    as="span"
                    data-breakdown="eventTimeline"
                    onClick={onClickBreakdownSwitch}
                    className={currentBreakdown === 'eventTimeline' ? '-active' : undefined}
                  >
                    {intl.formatMessage({ id: 'event_overview.event_timeline.title' })}
                  </SubnavItem>
                </BreakdownSubnav>
              )
          }
          <Suspense
            fallback={
              <StyledLoaderContainer height={120}>
                <Loader />
              </StyledLoaderContainer>
            }
          >
            <Container mute={deferredBreakdown !== currentBreakdown}>
              {deferredBreakdown === 'ticketBreakdown' && <TicketBreakdownPools eventId={event.id} />}
              {deferredBreakdown === 'extrasBreakdown' && <ProductsBreakdown eventId={event.id} productType="EXTRAS" />}
              {deferredBreakdown === 'merchBreakdown' && <ProductsBreakdown eventId={event.id} productType="MERCH" />}
              {deferredBreakdown === 'eventTimeline' && <EventTimeline eventId={event.id} />}
            </Container>
          </Suspense>
        </Block>
      </Section>
      {event.extraNotes && (
        <Section>
          <Block>
            <Notes event={event} />
          </Block>
        </Section>
      )}
    </div>
  )
}

export default memo(EventOverview)
