import { filter, compose, sumBy, countBy, toPairs, maxBy } from 'lodash/fp'
import { Dictionary } from 'ts-essentials'
import { PHYSICAL_ICONS } from '../constants/ticketTypeIcons'

export const countSeats = (seats: null | any[], mode?: 'booked' | 'total'): number =>
  compose([
    sumBy((obj: any) => {
      if (obj.status === 'marked_back') return 0

      const selectedMode = mode || 'total'

      switch (obj.objectType) {
        case 'seat':
        case 'table':
        case 'booth':
          return (selectedMode === 'total' ? 1 : obj.status === 'booked') || 0
        case 'generalAdmission':
          return (selectedMode === 'total' ? obj.capacity : obj.numBooked) || 0
        default:
          console.warn('Unknown seatmap object', obj)
          return 0
      }
    }),
    filter('forSale'),
  ])(seats || [])

const ICON_MAPPING: Dictionary<typeof PHYSICAL_ICONS[number], 'seat' | 'table' | 'booth' | 'generalAdmission'> = {
  table: 'table',
  seat: 'reserved_seating',
  booth: 'reserved_seating',
  generalAdmission: 'standing',
}

export const iconForSeats = (seats: null | any[]): typeof PHYSICAL_ICONS[number] =>
  compose([
    ([type]) => ICON_MAPPING[type as keyof typeof ICON_MAPPING] || 'reserved_seating',
    maxBy([1]),
    toPairs,
    countBy((obj: any) => (obj?.labels?.parent?.type === 'table' ? 'table' : obj.objectType)),
    filter('forSale'),
  ])(seats || [])
