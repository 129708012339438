import React, { FC, useCallback, useState, useContext, useMemo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import localforage from 'localforage'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import styled from 'styled-components/macro'
import { CollapsibleSidebarMenu, SidebarListNameToggle } from './legacy/Styles'
import Svg from '../../../components/Svg'
import { authContext } from '../../../context/auth'
import { color, zIndex } from '../../../utils/variables'

const Wrapper = styled.li<{ isDragging?: boolean | null }>`
  z-index: ${zIndex.sidebar};
  list-style: none;
  margin-left: -16px;
  padding-left: 16px;
  height: ${({ isDragging }) => (isDragging ? '40px' : 'unset')};
  overflow: ${({ isDragging }) => (isDragging ? 'hidden' : 'unset')}; ;
`

const DragHandle = styled.div<{ isDragging?: boolean }>`
  width: 16px;
  height: 40px;
  flex: none;
  max-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -16px;
  top: 0;
  padding-left: 2px;
  color: ${color.darkgrey};
  user-select: none;
  cursor: grab;

  opacity: ${({ isDragging }) => (isDragging ? 1 : 0)};

  svg {
    pointer-events: none;
  }
`

const SectionToggleWrapper = styled.div`
  position: relative;
  user-select: none;

  &:hover {
    ${DragHandle} {
      opacity: 1;
    }

    ${SidebarListNameToggle} {
      background: ${color.palegrey};
    }
  }
`

interface IProps {
  title: string
  isDragging?: any
}

const SortableDragHandle = SortableHandle<{ isDragging?: boolean }>(({ isDragging }: { isDragging?: boolean }) => (
  <DragHandle isDragging={isDragging} data-id="dragHandle">
    <Svg icon="drag" />
  </DragHandle>
))

const CollapsibleSidebarList: FC<React.PropsWithChildren<IProps>> = ({ title, children, isDragging }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    if (isDragging) setCollapsed(true)
  }, [isDragging])

  const store = useMemo(() => localforage.createInstance({ name: `sidebar_collapsed_${user.id}` }), [user.id])

  const toggleCollapsed = useCallback(() => {
    store.setItem(title, !collapsed)
    setCollapsed(!collapsed)
  }, [collapsed, store, title])

  useEffect(() => {
    store.getItem<boolean>(title).then((collapsed) => setCollapsed(collapsed || false))
  }, [store, title])

  return (
    <Wrapper isDragging={isDragging}>
      <SectionToggleWrapper>
        <SortableDragHandle isDragging={isDragging} />
        <SidebarListNameToggle onClick={toggleCollapsed} collapsed={collapsed} isDragging={isDragging}>
          <span>{intl.formatMessage({ id: title })}</span>
          <Svg icon="chevron-rounded" width={24} height={24} className="arrow-indicator" />
        </SidebarListNameToggle>
      </SectionToggleWrapper>
      <CollapsibleSidebarMenu collapsed={collapsed}>{children}</CollapsibleSidebarMenu>
    </Wrapper>
  )
}

export const SortableCollapsibleSidebarList = SortableElement<
  React.PropsWithChildren<IProps> & { isDragging?: boolean }
>(CollapsibleSidebarList)

export default CollapsibleSidebarList
