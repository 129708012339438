/**
 * @generated SignedSource<<052a847e79e07cf44b83896392a644d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventOverviewPageQuery$variables = {
  id: string;
};
export type EventOverviewPageQuery$data = {
  readonly event: {
    readonly " $fragmentSpreads": FragmentRefs<"EventOverview_event">;
  } | null;
};
export type EventOverviewPageQuery = {
  response: EventOverviewPageQuery$data;
  variables: EventOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventOverviewPageQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventOverview_event"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventOverviewPageQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventIdLive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "announceDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onSaleDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "offSaleDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezoneName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extraNotes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AllowedActions",
                "kind": "LinkedField",
                "name": "allowedActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readExtras",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readMerch",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Sales",
                "kind": "LinkedField",
                "name": "sales",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalSold",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closeEventDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventFlags",
                "kind": "LinkedField",
                "name": "flags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ticketTransfer",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "130b6339fbdf56fbc314dfb42bb7ca14",
    "id": null,
    "metadata": {},
    "name": "EventOverviewPageQuery",
    "operationKind": "query",
    "text": "query EventOverviewPageQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ...EventOverview_event\n    id\n  }\n}\n\nfragment EventOverview_event on Event {\n  id\n  eventIdLive\n  state\n  announceDate\n  onSaleDate\n  offSaleDate\n  timezoneName\n  date\n  endDate\n  extraNotes\n  allowedActions {\n    readExtras\n    readMerch\n  }\n  sales {\n    totalSold\n  }\n  products {\n    id\n  }\n  ...TimelineChart_event\n  ...Notes_event\n}\n\nfragment Notes_event on Event {\n  extraNotes\n}\n\nfragment TimelineChart_event on Event {\n  announceDate\n  onSaleDate\n  offSaleDate\n  date\n  endDate\n  closeEventDate\n  timezoneName\n  flags {\n    ticketTransfer\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f2907cb6730fad1542734d82a43c8d9";

export default node;
