import { mapValues } from 'lodash/fp'
import { createGlobalStyle, css } from 'styled-components/macro'
import { color, spacing } from '../utils/variables'

const mapKV = (mapValues as any).convert({ cap: false })

function createColorStyles() {
  let styles = ''

  mapKV((value: any, key: any) => {
    styles += `
      .color-${key} { color: ${value} !important; }
      .bg-${key} { background: ${value} !important; }
    `
  }, color)

  return css`
    ${styles}
  `
}

function createSpacingStyles() {
  let styles = ''

  mapKV((value: any, key: any) => {
    styles += `
    .mt-${key} { margin-top: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
    .mb-${key} { margin-bottom: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
    .ml-${key} { margin-left: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
    .mr-${key} { margin-right: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
    .pt-${key} { padding-top: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
    .pb-${key} { padding-bottom: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
    .pl-${key} { padding-left: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
    .pr-${key} { padding-right: ${value === 'auto' ? 'auto' : `${value}px`} !important; }
  `
  }, spacing)

  return css`
    ${styles}
  `
}

export const UtilityStyle = createGlobalStyle`
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .w-100 {
    width: 100%;
  }
  .relative {
    position: relative;
  }
  .flex {
    display: flex;
  }
  .flex-direction-column {
    flex-direction: column;
  }
  .flex-direction-row {
    flex-direction: row;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .flex-align-center {
    align-items: center;
  }
  .flex-align-end {
    align-items: flex-end;
  }
  .bold {
    font-weight: bold !important;
  }
  .strong {
    font-weight: bold !important;
  }
  .italic {
    font-style: italic !important;
  }

  .nowrap {
    white-space: nowrap !important;
  }
  .lowercase {
    text-transform: lowercase !important;
  }
  .uppercase {
    text-transform: uppercase !important;
  }
  .word-break-word {
    overflow-wrap: break-word;
    word-break: break-word;
  }
  .word-break-all {
    word-break: break-all;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  ${createSpacingStyles()}
  ${createColorStyles()}
`
