/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-ts-comment */

// This is a DOM patch to prevent React from crashing when extensions like
// Google Translate / Grammarly / (and phraseapp maybe) etc are modifying DOM text nodes
// More info: https://github.com/facebook/react/issues/11538#issuecomment-417504600
function patchDOM() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild
    //@ts-ignore
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn('Cannot remove a child from a different parent', child, this)
        }
        return child
      }
      return originalRemoveChild.apply(this, arguments as any)
    }

    const originalInsertBefore = Node.prototype.insertBefore
    //@ts-ignore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn('Cannot insert before a reference node from a different parent', referenceNode, this)
        }
        return newNode
      }
      return originalInsertBefore.apply(this, arguments as any)
    }
  }
}

export default patchDOM
