import { useCallback, useMemo, useContext, useRef } from 'react'
import { useFormikContext } from 'formik'
import { compact, pick, mapValues, some, map, uniqBy, concat, flatMap, filter } from 'lodash/fp'

import { getAlpha2ByName, getCurrencyByCountryCode, getTimezonesByAlpha2 } from '../../../utils/countries'
import { IVenue } from '../types/Basics'
import { IVenueAddress } from '../components/EventVenues'
import { authContext } from '../../../context/auth'
import { officialOrFallbackCurrency } from '../../../utils/currency'
import { mapsContext } from '../../../context/maps'
import { localeContext } from '../../../context/locale'
import useDoorlistRecipients from './useDoorlistRecipients'
import IEventForm from '../types'

export default function useBasicsVenues() {
  const { locale } = useContext(localeContext)
  const { user } = useContext(authContext)
  const { getPlaceTimezone } = useContext(mapsContext)

  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventForm>()

  const promoterIds: string[] = useMemo(() => map('value', values.promoters || []), [values.promoters])
  const setRecipients = useCallback((recList: any) => setFieldValue('doorlistRecipients', recList), [setFieldValue])
  const fetchDefaultDoorlistRecipients = useDoorlistRecipients(setRecipients)

  const venues = useMemo(() => compact(values.venues || []), [values.venues])
  const setVenues = useCallback(
    (ids: any, selection: any) => {
      setFieldValue('venues', selection || [])
      setFieldValue('venueConfiguration', null)
      setFieldValue('venueSpace', null)

      const hasVenueSeatmaps = some((venue) => (venue?.atLeastOneSeatingChart?.edges?.length || 0) > 0, selection || [])

      if (!hasVenueSeatmaps) {
        setFieldValue('flags.seated.active', false)
      }

      if (user.diceStaff) {
        fetchDefaultDoorlistRecipients([...(promoterIds || []), ...(ids || [])])
      }
    },
    [fetchDefaultDoorlistRecipients, promoterIds, setFieldValue, user.diceStaff]
  )

  const attrs = pick(
    [
      'addressCountry',
      'countryCode',
      'addressLocality',
      'addressLocalityLat',
      'addressLocalityLon',
      'addressRegion',
      'addressState',
      'addressCapacity',
      'addressSiaeCode',
      'postalCode',
      'streetAddress',
      'venueName',
    ],
    values
  )

  // Synced events should not do automated currency changes to prevent ticketing problems
  const canChangeCurrency = !values.eventIdLive

  const customVenue = useMemo(() => {
    const { venueName, addressCapacity, ...address } = attrs
    return {
      name: venueName || undefined,
      ...mapValues((v) => v || undefined, address),
      addressCapacity: addressCapacity === -1 ? undefined : addressCapacity,
    } as IVenueAddress
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(attrs)]) // SIC! deep-equals check

  const lastTimzeonePlaceId = useRef(null)
  const setCustomVenue = useCallback(
    (val: any) => {
      if (!val) {
        setFieldValue('venueName', '')
        setFieldValue('addressCountry', '')
        setFieldValue('countryCode', '')
        setFieldValue('addressLocality', '')
        setFieldValue('addressLocalityId', '')
        setFieldValue('addressLocalityLat', undefined)
        setFieldValue('addressLocalityLon', undefined)
        setFieldValue('addressRegion', '')
        setFieldValue('addressState', '')
        setFieldValue('addressCapacity', 0)
        setFieldValue('addressSiaeCode', '')
        setFieldValue('postalCode', '')
        setFieldValue('streetAddress', '')
        setFieldValue('fullAddress', '')
        setFieldValue('latitude', null)
        setFieldValue('longitude', null)
      } else {
        setFieldValue('venueName', val.name || '')
        setFieldValue('addressCountry', val.addressCountry || '')
        setFieldValue('countryCode', val.countryCode || '')
        setFieldValue('addressLocality', val.addressLocality || '')
        setFieldValue('addressLocalityId', val.addressLocalityId || '')
        setFieldValue('addressLocalityLat', val.addressLocalityLat)
        setFieldValue('addressLocalityLon', val.addressLocalityLon)
        setFieldValue('addressRegion', val.addressRegion || '')
        setFieldValue('addressState', val.addressState || '')
        setFieldValue('addressCapacity', val.addressCapacity || 0)
        setFieldValue('addressSiaeCode', val.addressSiaeCode || '')
        setFieldValue('postalCode', val.postalCode || '')
        setFieldValue('streetAddress', val.streetAddress || '')
        setFieldValue('fullAddress', '')
        setFieldValue('latitude', null)
        setFieldValue('longitude', null)

        if (canChangeCurrency) {
          setFieldValue(
            'costCurrency',
            officialOrFallbackCurrency(
              getCurrencyByCountryCode(val.countryCode),
              getCurrencyByCountryCode(values.billingPromoter?.countryCode || undefined)
            )
          )
        }

        if (val.addressLocalityId && val.addressLocalityLat && val.addressLocalityLon) {
          if (lastTimzeonePlaceId.current !== val.addressLocalityId) {
            lastTimzeonePlaceId.current = val.addressLocalityId

            getPlaceTimezone(val.addressLocalityLat, val.addressLocalityLon)
              .then((tz) => {
                if (tz) setFieldValue('timezoneName', tz)
              })
              .catch(console.error)
          }
        } else if (val.countryCode) {
          const tzName = getTimezonesByAlpha2(val.countryCode)?.[0]
          setFieldValue('timezoneName', tzName)
        }
      }
    },
    [canChangeCurrency, getPlaceTimezone, setFieldValue, values.billingPromoter?.countryCode]
  )

  const setPrimaryVenueId = useCallback(
    (id: string | null, primaryVenue: IVenue | null) => {
      setFieldValue('primaryVenue', id ? { value: id } : null)

      if (primaryVenue) {
        const eventIsLive = values.state === 'APPROVED' && !!values.eventIdLive

        if (!eventIsLive && primaryVenue.ticketType) {
          setFieldValue('ticketType', primaryVenue.ticketType)
        }

        if (!eventIsLive && primaryVenue.barcodeType) {
          setFieldValue('barcodeType', primaryVenue.barcodeType)
        }

        if (primaryVenue.timezoneName) {
          setFieldValue('timezoneName', primaryVenue.timezoneName)
        }

        if (primaryVenue.venueOwners) {
          const newPromoters = compact(uniqBy('value', concat(primaryVenue.venueOwners, values.promoters || [])))

          setFieldValue('promoters', newPromoters)
          setFieldValue(
            'marketeers',
            compact(uniqBy('value', concat(values.marketeers || [], flatMap('associatedMarketeers', newPromoters))))
          )
        }

        setFieldValue('isTest', primaryVenue.isTest ?? false)

        const countryCode = primaryVenue.countryCode || getAlpha2ByName(primaryVenue.addressCountry, locale)
        if (countryCode && canChangeCurrency) {
          setFieldValue(
            'costCurrency',
            officialOrFallbackCurrency(
              getCurrencyByCountryCode(countryCode),
              getCurrencyByCountryCode(values.billingPromoter?.countryCode || undefined)
            )
          )
        }

        if (primaryVenue.ageLimit) {
          setFieldValue('ageLimit', primaryVenue.ageLimit)
        }
        setFieldValue('addressCountry', primaryVenue.addressCountry || '')
        setFieldValue('countryCode', countryCode || '')
        setFieldValue('addressLocality', primaryVenue.addressLocality || '')
        setFieldValue('addressLocalityId', '')
        setFieldValue('addressLocalityLat', undefined)
        setFieldValue('addressLocalityLon', undefined)
        setFieldValue('addressRegion', primaryVenue.addressRegion || '')
        setFieldValue('addressState', primaryVenue.addressState || '')
        setFieldValue('streetAddress', primaryVenue.streetAddress || '')
        setFieldValue('postalCode', primaryVenue.postalCode || '')
        setFieldValue('fullAddress', primaryVenue.fullAddress || '')
        setFieldValue('latitude', primaryVenue.latitude)
        setFieldValue('longitude', primaryVenue.longitude)
      } else if (id) {
        console.error(`No such primary venue: ${id}`)
      } else {
        if (canChangeCurrency) {
          setFieldValue(
            'costCurrency',
            officialOrFallbackCurrency(getCurrencyByCountryCode(values.billingPromoter?.countryCode || undefined))
          )
        }
        setFieldValue('addressCountry', '')
        setFieldValue('countryCode', '')
        setFieldValue('addressLocality', '')
        setFieldValue('addressLocalityId', '')
        setFieldValue('addressLocalityLat', undefined)
        setFieldValue('addressLocalityLon', undefined)
        setFieldValue('addressRegion', '')
        setFieldValue('addressState', '')
        setFieldValue('streetAddress', '')
        setFieldValue('postalCode', '')
        setFieldValue('fullAddress', '')
        setFieldValue('latitude', null)
        setFieldValue('longitude', null)
        if (!!values.flags?.seated?.active) {
          setFieldValue('ticketTypes', filter('isStream', values.ticketTypes))
          setFieldValue('ticketPools', [])
          setFieldValue('eventSeatingChart', null)
          setFieldValue('flags.seated.active', false)
        }
      }

      setFieldValue('labels', uniqBy('value', concat(values.labels || [], primaryVenue?.labels || [])))

      setFieldTouched('primaryVenue', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [
      setFieldValue,
      values.labels,
      values.state,
      values.eventIdLive,
      values.promoters,
      values.marketeers,
      values.billingPromoter?.countryCode,
      values.flags?.seated?.active,
      values.ticketTypes,
      setFieldTouched,
      locale,
      canChangeCurrency,
      validateForm,
    ]
  )

  return {
    venues,
    setVenues,
    customVenue,
    setCustomVenue,
    setPrimaryVenueId,
  }
}
