import React, { FC, memo, useCallback, useMemo, useRef } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { compact, concat, find, get, without } from 'lodash/fp'

import { Col, Form, FormRow } from '../../../components/Form'
import FormGroup from '../../../components/FormGroup'
import IEventFormBasics from '../types/Basics'
import { mediaQuery } from '../../../utils/variables'
import IconButton from '../../../components/IconButton'
import FormField, { FlexFormField } from '../../../components/FormField'
import EmptyList from '../../../components/EmptyList'
import ListAddButton from '../../../components/ListAddButton'

const StyledForm = styled(Form)`
  padding-top: 8px;

  ${mediaQuery.lessThan('tablet')`
    ${FormRow} + ${FormRow} {
      margin-top: 32px;
    }

    ${Col} + ${Col} {
      margin-top: 8px;
    }
  `}
`

type ISeatingArea = NonNullable<
  NonNullable<NonNullable<IEventFormBasics['attractiveFields']>['seatingAreaConfigs']>[number]
>

interface IItemRow {
  item: ISeatingArea
  idx: number
  readOnly?: boolean
  onRemove: (idx: number) => void
  seatingAreaTypeOptions: Array<{ value: string | null; label: string | null }>
}

const SeatingAreasItem: FC<React.PropsWithChildren<IItemRow>> = ({
  item,
  idx,
  readOnly,
  onRemove,
  seatingAreaTypeOptions,
}) => {
  const intl = useIntl()

  const { touched, errors, handleChange, handleBlur, setFieldValue } = useFormikContext<IEventFormBasics>()

  const removeItem = useCallback(() => onRemove(idx), [idx, onRemove])

  const seatingAreaTypeOption = useMemo(
    () =>
      find(['value', item.seatingArea], seatingAreaTypeOptions) || {
        value: item.seatingArea,
        label: `??? (${item.seatingArea})`,
      },
    [item.seatingArea, seatingAreaTypeOptions]
  )

  const onChangeArea = useCallback(
    (id: string | null) => {
      setFieldValue(`attractiveFields.seatingAreaConfigs[${idx}].seatingArea`, id, true)
    },
    [idx, setFieldValue]
  )

  return (
    <FormRow columnOnMobile data-id={`seatingAreas[${idx}]`}>
      <FormField
        name={`attractiveFields.seatingAreaConfigs[${idx}].capacity`}
        label={intl.formatMessage({ id: 'venues.area_capacity' })}
        placeholder={intl.formatMessage({ id: 'venues.area_capacity' })}
        type="number"
        min={-1}
        step={1}
        value={item.capacity || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          get(`attractiveFields.seatingAreaConfigs[${idx}].capacity`, touched) &&
          get(`attractiveFields.seatingAreaConfigs[${idx}].capacity`, errors)
        }
        required
        disabled={readOnly}
      />
      <FlexFormField
        name={`attractiveFields.seatingAreaConfigs[${idx}].seatingArea`}
        label={intl.formatMessage({ id: 'venues.area_type' })}
        control="select"
        value={item.seatingArea && seatingAreaTypeOption}
        options={seatingAreaTypeOptions}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onChangeArea}
        onBlur={handleBlur}
        required
        error={
          get(`attractiveFields.seatingAreaConfigs[${idx}].seatingArea`, touched) &&
          get(`attractiveFields.seatingAreaConfigs[${idx}].seatingArea`, errors)
        }
        disabled={readOnly}
      >
        {!readOnly && <IconButton data-id="removeItemButton" icon="trash" onClick={removeItem} disabled={readOnly} />}
      </FlexFormField>
    </FormRow>
  )
}

interface IProps {
  readOnly?: boolean
  seatingAreaTypeOptions: Array<{ value: string | null; label: string | null }>
}

const EventSeatingAreas: FC<React.PropsWithChildren<IProps>> = ({ readOnly, seatingAreaTypeOptions }) => {
  const intl = useIntl()

  const ref = useRef<HTMLDivElement>(null)

  const { values, touched, errors, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormBasics>()

  const items: ISeatingArea[] = useMemo(
    () => compact(values.attractiveFields?.seatingAreaConfigs || []),
    [values.attractiveFields?.seatingAreaConfigs]
  )

  const addItem = useCallback(() => {
    const idx = (items || []).length
    const newItem = { capacity: 0, seatingArea: null }

    setFieldValue('attractiveFields.seatingAreaConfigs', concat(items, newItem), true)

    setFieldTouched('attractiveFields.seatingAreaConfigs', true, true)
    setTimeout(() => validateForm(), 0)

    setTimeout(() => {
      if (ref.current) {
        const node = ref.current.querySelector(`input[name="seatingAreas[${idx}].capacity"]`) as HTMLInputElement
        if (node) node.focus()
      }
    }, 500)
  }, [items, setFieldTouched, setFieldValue, validateForm])

  const removeItem = useCallback(
    (idx: number) => {
      const item = items[idx]
      setFieldValue('attractiveFields.seatingAreaConfigs', without([item], items))
      setFieldTouched('attractiveFields.seatingAreaConfigs', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [items, setFieldTouched, setFieldValue, validateForm]
  )

  return (
    <FormRow columnOnMobile>
      <FormGroup
        dice
        data-name="seatingAreas"
        label={intl.formatMessage({ id: 'new_event.basics.seating_area.label' })}
        error={
          get('attractiveFields.seatingAreaConfigs', touched) && get('attractiveFields.seatingAreaConfigs', errors)
        }
        disabled={readOnly}
      >
        <div ref={ref}>
          <StyledForm spacing="small">
            {items.map((item, idx) => (
              <SeatingAreasItem
                seatingAreaTypeOptions={seatingAreaTypeOptions}
                key={idx}
                item={item}
                idx={idx}
                readOnly={readOnly}
                onRemove={removeItem}
              />
            ))}
            <FormRow columnOnMobile>
              {items.length === 0 ? (
                <EmptyList
                  icon="seats"
                  cta={intl.formatMessage({ id: 'venue_form.tickets.venue_configurations.add_seating_area.label' })}
                  title={intl.formatMessage({ id: 'new_event.basics.seating_area.empty_title' })}
                  subTitle={intl.formatMessage({ id: 'new_event.basics.seating_area.empty_message' })}
                  hasError={
                    get('attractiveFields.seatingAreaConfigs', touched) &&
                    get('attractiveFields.seatingAreaConfigs', errors)
                  }
                  onAdd={addItem}
                  disabled={readOnly}
                />
              ) : (
                <>
                  {!readOnly && (
                    <ListAddButton
                      label={intl.formatMessage({
                        id: 'venue_form.tickets.venue_configurations.add_seating_area.label',
                      })}
                      onClick={addItem}
                    />
                  )}
                  <div />
                </>
              )}
            </FormRow>
          </StyledForm>
        </div>
      </FormGroup>
    </FormRow>
  )
}

export default memo(EventSeatingAreas)
