import { map, concat, omit, always, some, get, isString } from 'lodash/fp'
import { IntlShape } from 'react-intl'
import IEventFormBasics from '../flows/EventForm/types/Basics'

export type IKind = 'forbidden' | 'styleguide'
export const STYLEGUIDE_SECTIONS: Array<IKind> = ['forbidden', 'styleguide']

interface IValidationRule {
  message: string
  isValid: (title: string, event: IEventFormBasics) => boolean
}

export interface IStyleguideItem extends Omit<IValidationRule, 'isValid'> {
  valid: boolean
  kind: IKind
  key: string
}

const STYLEGUIDE_RULES: Array<IValidationRule> = [
  { message: 'featuring', isValid: (title) => !/(\s|^)featuring(\s|$)/i.test(title) },
  { message: 'time', isValid: (title) => !/\([^(]*(\d{1,2}\s*[ap]m)|(\d{1,2}:\d{2})[^)]*\)/i.test(title) },
  { message: 'launch', isValid: (title) => !/(\s|^)launch(\s|$)/i.test(title.replace('Launch Party', '')) },
  {
    message: 'listening_party',
    isValid: (title) => !/(\s|^)listening.*party(\s|$)/i.test(title.replace('Listening Party', '')),
  },
  { message: 'festival_lineup', isValid: (title) => !(/festival/.test(title) && /lineup/.test(title)) },
  { message: 'festival_day', isValid: (title) => !/Festival day/.test(title) },
]

const FORBIDDEN_RULES: Array<IValidationRule> = [
  { message: 'all_caps', isValid: (title) => !/(\s|^)\p{Lu}{3,}(\s|$)/u.test(title) },
  { message: 'square_brackets', isValid: (title) => !/\]|\[/.test(title) },
  { message: 'multiple_brackets', isValid: (title) => title.replace(/[^)(]/g, '').length < 3 },
  {
    message: 'promoter_name',
    isValid: (title, event) =>
      !(
        event.promoters &&
        event.promoters.length > 0 &&
        some(
          (v) => v && v.label && isString(v.label) && title.toLowerCase().indexOf(v.label.toLowerCase()) >= 0,
          event.promoters
        )
      ),
  },
  { message: 'night_and_artist_name', isValid: always(true) },
  {
    message: 'venue_name',
    isValid: (title, event) =>
      !(
        event.venues &&
        event.venues.length > 0 &&
        some(
          (v) => v && v.label && isString(v.label) && title.toLowerCase().indexOf(v.label.toLowerCase()) >= 0,
          event.venues
        )
      ) &&
      !(
        event.venueName &&
        isString(event.venueName) &&
        title.toLowerCase().indexOf(event.venueName.toLowerCase()) >= 0
      ),
  },
  { message: 'presents_or_with', isValid: (title) => !/(\s|^)(presents|with)(\s|$)/i.test(title) },
  { message: 'dates_days_or_times', isValid: always(true) },
  { message: 'extra_information', isValid: always(true) },
  { message: 'forbidden_chars', isValid: (title) => !/\.|&|\s-\s|•|@/.test(title.toLowerCase().replace('ft.', '')) },
  { message: 'with', isValid: (title) => !/(\s|^)with(\s|$)/i.test(title) },
  { message: 'culture_genre_info', isValid: always(true) },
  { message: 'backslash_lists', isValid: (title) => !/[^\\]\\[^\\]+\\/.test(title) },
  { message: 'too_many_bracketed', isValid: (title) => title.replace(/[^()]/g, '').length <= 2 },
  { message: 'too_many_colons', isValid: (title) => title.replace(/[^:]/g, '').length < 2 },
]

const run = (
  kind: IKind,
  ruleSet: Array<IValidationRule>,
  title: string | null | undefined,
  intl: IntlShape | null,
  event: IEventFormBasics
) =>
  map(
    (rule) => ({
      ...omit(['isValid', 'message'], rule),
      valid: title ? rule.isValid(title, event) : true,
      key: rule.message,
      message: (intl ? intl.formatMessage : get('id'))({
        id: `new_event.validation.styleguide.${kind}_${rule.message}`,
      }),
      kind,
    }),
    ruleSet
  )

function validate<T extends IEventFormBasics>(
  title: string | null | undefined,
  intl: IntlShape | null,
  event: T
): Array<IStyleguideItem> {
  return concat(
    run('forbidden', FORBIDDEN_RULES, title, intl, event),
    run('styleguide', STYLEGUIDE_RULES, title, intl, event)
  )
}

export default validate
