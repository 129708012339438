import React, { FC, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import qs from 'qs'
import styled from 'styled-components/macro'

import Button from '../../components/Button'
import ConfirmationPage, { STATUSES } from '../../components/ConfirmationPage'
import REQUESTS from '../../utils/requests'
import { PageViewTracker, trackingContext } from '../../context/tracking'

const StyledButton = styled(Button)`
  margin-top: 32px;
`

const checkToken = async (token: string) => {
  const { status, user } = await REQUESTS.CONFIRM_ACCOUNT(token)
  return status === 200 ? ([STATUSES.SUCCESS, user] as ['success', any]) : STATUSES.FAILURE
}

const AccountConfirmation: FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const { trackEvent } = useContext(trackingContext)

  const extractParams = useCallback(() => {
    const token = qs.parse(location.search, { ignoreQueryPrefix: true })['token']?.toString()
    return token ? [token] : null
  }, [location.search])

  const trackLogin = useCallback(
    () => trackEvent('login_started', { source_name: 'account_confirmation' }),
    [trackEvent]
  )

  return (
    <>
      <PageViewTracker trackId="account_create_activation_confirmed" />
      <ConfirmationPage
        successHeaderKey="signup.confirmed_header"
        prefix="account_confirmation_new"
        extractParams={extractParams}
        fetchData={checkToken}
      >
        <StyledButton color="white" to="/auth/login" onClick={trackLogin}>
          {intl.formatMessage({ id: 'sign_in' })}
        </StyledButton>
      </ConfirmationPage>
    </>
  )
}

export default AccountConfirmation
