import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import cn from 'classnames'
import { every, isEmpty } from 'lodash/fp'
import { Link } from 'react-router-dom'

import { color, font } from '../utils/variables'
import Svg from './Svg'
import DiceBadge from './DiceBadge'
import { authContext } from '../context/auth'
import { markdownToHtml } from '../utils/markdown'
import { textStyle } from '../utils/typography'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 1;
`

const InnerContainer = styled.div`
  display: block;
  position: sticky;
  top: 68px;
  height: 100%;
  max-height: calc(100vh - 68px);
  max-width: 238px;
  background: ${color.white};
  overflow: hidden;

  .-impersonating & {
    top: 130px;
  }
`

const NoteTrigger = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: calc(100vh - 74px);

  .-impersonating & {
    top: calc(100vh - 74px + 62px);
  }

  max-width: 240px;
  padding: 16px;
  height: 74px;
  flex: 74px 0 0;
  border-top: 2px solid ${color.text};
  background: #fff;
  cursor: pointer;
  user-select: none;
  z-index: 2;
  &:hover {
    color: ${color.primary};
  }
  span {
    margin: 0 auto 0 8px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & > svg {
    display: inline-block;
    flex: 24px 0 0;
    vertical-align: middle;
    &.arrow.-expanded {
      transform: rotate(180deg);
    }
  }
`

const Content = styled.div<{ isLoading: boolean }>`
  line-height: 1.14em;
  padding: 24px 16px;
  max-height: calc(100vh - 146px);
  overflow-y: auto;
  ${({ isLoading }) => isLoading && 'opacity: 0.5;'}
`

const NoteSection = styled.div`
  & + & {
    margin-top: 48px;
  }
`

const NoteSectionHeader = styled.div`
  display: flex;
  align-items: center;
  ${textStyle.heading.xs}
  text-transform: uppercase;
  margin-bottom: 16px;
  svg {
    flex: 24px 0 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
`

const Note = styled.div`
  & + & {
    margin-top: 32px;
  }
`

const NoteTitle = styled.strong`
  display: block;
  &:after {
    content: '';
    display: block;
    width: 24px;
    height: 4px;
    background: ${color.primary};
    margin: 4px 0 12px;
  }
`

const NoteContent = styled.div`
  ${textStyle.bodycopy.regular}
  word-break: break-word;
  margin-bottom: 16px;
  ul,
  ol {
    padding-left: 16px;
    margin: 8px 0;
    li {
      margin: 4px 0;
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  p {
    margin: 4px 0;
  }
  a {
    color: ${color.primary};
  }
`

export interface INote {
  id: string | null
  name: string | null
  notes: string | null
}

export interface INoteProps {
  type: 'promoter' | 'venue' | 'event'
  section: ReadonlyArray<INote>
}

export const NoteComponent: FC<React.PropsWithChildren<INoteProps>> = ({ type, section }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  return (
    <NoteSection>
      <NoteSectionHeader>
        <Svg icon={type === 'event' ? 'ticket' : type} />
        {intl.formatMessage({ id: `new_event.pv_notes.section_title.${type}` })}
      </NoteSectionHeader>
      {section.map((item, idx) => {
        const name = item.name || '&mdash;'

        let link = null
        if (type === 'event') {
          link = item.id && `/events/${item.id}`
        } else if (type === 'promoter') {
          link = user.diceStaff ? item.id && `/promoters/${item.id}` : null
        } else if (type === 'venue') {
          link = user.diceStaff ? item.id && `/venues/${item.id}/details` : null
        }

        return (
          <Note key={item.id || `idx:${idx}`}>
            <NoteTitle>
              {link ? (
                <Link to={link} target="_blank">
                  {name}
                </Link>
              ) : (
                name
              )}
            </NoteTitle>
            <NoteContent dangerouslySetInnerHTML={{ __html: markdownToHtml(item.notes) }} />
          </Note>
        )
      })}
    </NoteSection>
  )
}

interface IProps {
  dice?: boolean
  loading?: boolean
  notes: ReadonlyArray<{
    type: INoteProps['type']
    notes: ReadonlyArray<INote>
  }>
  expandedByDefault?: boolean
}

const NotesCollapsible: FC<React.PropsWithChildren<IProps>> = ({ dice, loading, notes, expandedByDefault }) => {
  const intl = useIntl()

  const [expanded, setExpanded] = useState(expandedByDefault || false)
  const toggleNotes = useCallback(() => setExpanded((v) => !v), [])

  const noNotes = useMemo(() => every((n) => isEmpty(n.notes), notes), [notes])

  return (
    <>
      <NoteTrigger onClick={toggleNotes}>
        <Svg icon={`eye${expanded ? '-crossed' : ''}`} />
        <span>
          {dice && <DiceBadge />}
          {intl.formatMessage({ id: `new_event.pv_notes.${expanded ? 'hide' : 'show'}_notes` })}
        </span>
        <Svg icon="collapsible" className={cn('arrow', expanded && '-expanded')} />
      </NoteTrigger>
      {expanded && (
        <Wrapper>
          <InnerContainer>
            <Content isLoading={!!loading}>
              {noNotes ? (
                <p>{intl.formatMessage({ id: 'new_event.pv_notes.empty' })}</p>
              ) : (
                notes.map((n, idx) => n.notes.length > 0 && <NoteComponent key={idx} type={n.type} section={n.notes} />)
              )}
            </Content>
          </InnerContainer>
        </Wrapper>
      )}
    </>
  )
}

export default NotesCollapsible
