import { HTMLProps } from 'react'
import styled, { css } from 'styled-components/macro'
import EventCardValue from '../../../../components/Event/EventCardValue'
import ProgressBar from '../../../../components/ProgressBar'
import Svg from '../../../../components/Svg'
import { color, font, mediaQuery } from '../../../../utils/variables'
import { textStyle } from '../../../../utils/typography'

export const DetailsToggle = styled.span<{ expanded?: boolean }>`
  display: inline-block;
  vertical-align: top;
  ${textStyle.functional.sm}
  color: ${color.primary};
  cursor: pointer;
  user-select: none;
  svg {
    width: 18px;
    height: 18px;
    margin: 1px 0 0 4px;
    vertical-align: top;
    transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  ${mediaQuery.lessThan('desktop')`
    font-size: 0;
  `}
`

export const TicketTypeIcon = styled.i<{ hidden?: boolean }>`
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 50%;
  ${({ hidden }) => hidden && 'opacity: .25;'};
`

export const TicketTypes = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px 32px 48px;
  ${mediaQuery.lessThan('tablet')`
    padding: 16px 16px 48px;
  `}
`

export const TicketType = styled.div`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  ${mediaQuery.lessThan('desktop')`
    flex-wrap: wrap;
  `}
`

export const TicketTypeRow = styled.div<{ isArchived?: boolean; isSold?: boolean }>`
  flex-direction: column;

  ${TicketType} > * {
    opacity: ${({ isArchived, isSold }) => (isArchived || isSold ? 0.5 : 1)};
  }

  & + & {
    margin-top: 48px;

    ${mediaQuery.lessThan('tablet')`
    margin-top: 32px;
  `}
  }
`

export const TicketTypeCol = styled.div<
  HTMLProps<HTMLDivElement> & {
    flex?: number
    flexDirection?: string
    justify?: string
    nowrap?: boolean
    shifted?: boolean
    minHeight?: boolean
    order?: number
    nonInteractive?: boolean
  }
>`
  pointer-events: ${({ nonInteractive }) => (nonInteractive ? 'none' : 'unset')};
  display: flex;
  position: relative;
  padding: 0 16px;
  flex: ${({ flex }) => (flex ? flex : 1)};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  min-width: 100px;
  ${({ order }) => (order || order === 0) && `order: ${order};`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ nowrap }) => nowrap && 'white-space: nowrap;'}
  &:first-child {
    padding: 0 56px;
    max-height: 48px;
  }
  ${({ shifted }) => shifted && 'margin-right: 80px; flex: 1.5;'}
  ${mediaQuery.lessThan('desktop')`
  padding: 0 0 0 56px;
  flex: 100% 0 0;
  margin: 0 0 10px;
  &:first-child {
    min-height: 42px;
    margin-bottom: 8px;
    max-height: unset;
  }
  ${DetailsToggle} {
    position: absolute;
    right: 48px;
    width: 36px;
    height: 36px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0;
      width: 24px;
      height: 24px;
    }
  }
`}
`

export const TicketTypeName = styled.strong`
  display: flex;
  margin: 0;
  ${textStyle.interactive.lg}
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  svg {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    margin: -4px 0 0 -2px;
  }
`

export const TicketTypePrice = styled.div`
  margin-top: 2px;
  white-space: nowrap;
  & > span:first-child {
    margin-right: 8px;
  }
`

export const TicketTypeNameWithBadge = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  min-width: 0;

  ${mediaQuery.lessThan('tablet')`
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 4px;
  `}
`

export const Basket = styled.div<{ borderColor: string }>`
  display: flex;
  position: absolute;
  right: 100%;
  top: 0px;
  flex-direction: column;
  align-items: center;
  min-width: 32px;
  height: 48px;
  padding: 0 4px;
  border: 2px solid ${({ borderColor }) => borderColor};
  color: ${({ borderColor }) => (borderColor === color.grey ? color.grey : color.text)};
  ${mediaQuery.lessThan('desktop')`
  left: 4px;
`}
`

export const Progress = styled(EventCardValue)`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CustomProgressBar = styled(ProgressBar)`
  margin: 4px 0;
`

export const ProgressBarValues = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${textStyle.functional.sm}
  margin-top: 6px;
`

export const ProgressValues = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${textStyle.functional.sm}
`

export const SoldValue = styled.span`
  ${textStyle.numeric.md}
  color: ${color.text};
`

export const AllocationValue = styled.span`
  ${textStyle.numeric.sm}
  color: ${color.text};
`

export const RemainingValue = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 4px;
`

export const Relative = styled.div`
  position: relative;
`

export const PriceTierCol = styled.div<
  HTMLProps<HTMLDivElement> & {
    flex?: number
    flexDirection?: string
    justify?: string
    nowrap?: boolean
    shifted?: boolean
    minHeight?: boolean
    order?: number
  }
>`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 1)};
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: center;
  padding: 0 16px;
  min-width: 100px;
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ nowrap }) => nowrap && 'white-space: nowrap;'}
  ${({ shifted }) => shifted && 'margin-right: 80px; flex: 1.5;'}
  &:first-child {
    padding-left: 0;
    padding-right: 56px;
  }
  ${mediaQuery.lessThan<{ flex?: string }>('desktop')`
    padding: 0 8px;
    flex: ${({ flex }) => (flex ? flex : 1)};
    &:first-child {
      padding-right: 8px;
      flex: 40% 0 0;
    }
  `}
  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.sm}px;
    &:first-child {
      padding-left: 10px;
    }
    &:last-child {
      justify-content: flex-end;
      padding-right: 64px;
      text-align: right;

    }
  `}
`
export const PriceTier = styled.div<HTMLProps<HTMLDivElement> & { isActive?: boolean; minHeight?: boolean }>`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  ${({ minHeight }) => minHeight && 'min-height: 42px;'}
  border-bottom: 1px solid ${color.palegrey};
  margin: -1px 0;
  ${mediaQuery.lessThan('tablet')`
    align-items: flex-start;
  `}
  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 1px solid ${color.palegrey};
      background: #f2f2f2;
      ${PriceTierStatusDot} {
        background: ${color.primary};
      }
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: -1px;
        width: 48px;
        background: #f2f2f2;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }
      &:before {
        left: -48px;
      }
      &:after {
        display: none;
      }
      ${mediaQuery.lessThan('tablet')`
        &:before, &:after {
          width: 16px;
          border-radius: 0;
        }
        &:before {
          left: -16px;
        }
        &:after {
          right: -16px;
          display: block;
        }
      `}
    `}
`

export const PriceTierName = styled.div`
  ${DetailsToggle} {
    ${mediaQuery.lessThan('tablet')`
      margin: -4px 0 0 2px;
      svg {
        width: 24px;
        height: 24px;
      }
    `}
  }
`

export const PriceTiersBreakdown = styled.div`
  position: relative;
`

export const PriceTiers = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-left: 56px;
  margin-top: 16px;
  ${mediaQuery.lessThan('tablet')`
    width: auto;
    min-width: 100%;
    padding: 0 16px;
    margin: 16px -16px 0;
  `}
  & > ${PriceTier} {
    ${PriceTierCol} {
      ${mediaQuery.lessThan('tablet')`
        padding-top: 16px;
        padding-bottom: 16px;
      `}
    }
  }
`

export const PriceTierStatusDot = styled.span`
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: -24px;
  background: ${color.lightgrey};
  ${mediaQuery.lessThan('tablet')`
    left: -6px;
    top: 22px;
  `}
`

export const PriceTierDot = styled(Svg)<{ percentage: number }>`
  position: absolute;
  opacity: 0.5;
  width: 6px;
  height: 6px;
  top: 1px;
  left: ${({ percentage }) => percentage}%;
  display: ${({ percentage }) => (percentage > 99.99 || percentage < 0.01 ? 'none' : 'block')};
  margin-left: -3px;
`
