/**
 * @generated SignedSource<<cc098c21a99ef51a917be10457d9c91b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CategoryType = "ACCESS_PASS" | "AFTER_PARTY_PASS" | "ARTIST_MEET_AND_GREET" | "CAMPING" | "COACH_BUS" | "DRINKS_ALCOHOLIC" | "DRINKS_NON_ALCOHOLIC" | "EARLY_ENTRY_PASS" | "EXPERIENCE" | "FOOD" | "FOOD_AND_DRINK" | "JUMPER" | "MERCH" | "OTHER" | "PARKING" | "QUEUE_JUMP" | "TRAVEL_AND_ACCOMMODATION" | "T_SHIRT" | "VIP_UPGRADE" | "%future added value";
export type ChannelType = "APP_SALE" | "BOX_OFFICE_SALE" | "HOLD" | "NON_DICE" | "OTHER" | "%future added value";
export type CompatibilityAe = "_0" | "_1000" | "_5000" | "%future added value";
export type CountryCode = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AN" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type DistanceUnits = "kilometers" | "miles" | "%future added value";
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventTimingField = "ANNOUNCE_DATE" | "DATE" | "END_DATE" | "LINE_UP_DOORS_OPEN" | "OFF_SALE_DATE" | "ON_SALE_DATE" | "ON_SALE_REMINDER_TIME" | "RETURN_DEADLINE" | "TICKET_TYPE_ANNOUNCE_DATE" | "TICKET_TYPE_OFF_SALE_DATE" | "TICKET_TYPE_ON_SALE_DATE" | "TRANSFER_DEADLINE" | "%future added value";
export type EventTimingType = "DEFAULT" | "PROMOTER" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type FeesBehaviour = "APPEND_TO_CONTRACT" | "OVERRIDE" | "USE_CONTRACT" | "%future added value";
export type MediaItemTypes = "appleMusicTrack" | "spotifyArtist" | "spotifyTrack" | "trailer" | "%future added value";
export type PaymentMethods = "AFTERPAY_CLEARPAY" | "DICE_SPLIT" | "GIROPAY" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type PrintedTicketFormat = "BOCA_55X2" | "BOCA_6X3_NO_LOGO" | "NO_PRINTER" | "STAR_RECEIPT" | "STAR_RECEIPT_ETICKET" | "%future added value";
export type ProductOptionType = "CUSTOM" | "SIZE" | "%future added value";
export type ProductRootType = "EXTRAS" | "MERCH" | "%future added value";
export type ProductType = "ADDON" | "%future added value";
export type RepeatEnds = "OCCURRENCES" | "UNTIL" | "%future added value";
export type RepeatFrequency = "BI_WEEKLY" | "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type RepeatOn = "LAST_WEEK_DAY" | "SAME_DAY" | "SAME_WEEK_AND_DAY" | "%future added value";
export type ReservedSeatingTypes = "assignBestSeat" | "selectSeat" | "%future added value";
export type RestrictionKind = "ALLOW" | "DENY" | "%future added value";
export type SalesforceContractStatus = "ACTIVATED" | "CANCELLED" | "EXPIRED" | "SIGNED" | "SUPERSEDED" | "%future added value";
export type ScheduleStatus = "POSTPONED" | "RESCHEDULED" | "%future added value";
export type ShowArtistDescription = "CUSTOM" | "DICE" | "NONE" | "%future added value";
export type TargetingMode = "country" | "location" | "worldwide" | "%future added value";
export type TvPlatform = "DICE" | "EXTERNAL" | "%future added value";
export type VenueTier = "TIER_1" | "TIER_2" | "TIER_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventSubmission_event$data = {
  readonly additionalArtists: ReadonlyArray<{
    readonly description: string | null;
    readonly hierarchicalTags: ReadonlyArray<{
      readonly kind: string | null;
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly additionalInfos: ReadonlyArray<{
    readonly content: string | null;
    readonly ctaLabel: string | null;
    readonly ctaLink: string | null;
    readonly id: string;
    readonly includeOnPurchaseEmail: boolean | null;
    readonly includeOnReminderEmail: boolean | null;
  } | null> | null;
  readonly addressCapacity: number | null;
  readonly addressCountry: string | null;
  readonly addressLocality: string | null;
  readonly addressRegion: string | null;
  readonly addressSiaeCode: string | null;
  readonly addressState: string | null;
  readonly ageLimit: string | null;
  readonly allocation: number;
  readonly allowedActions: {
    readonly addProducts: boolean | null;
    readonly inviteExternalGuest: boolean | null;
    readonly manageLinks: boolean | null;
    readonly manageProductsAllocation: boolean | null;
    readonly managePromotions: boolean | null;
    readonly manageTickets: boolean | null;
    readonly minorUpdate: boolean | null;
    readonly readExtras: boolean | null;
    readonly readMerch: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly ageLimit: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly announceDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly artistIds: {
      readonly canAdd: boolean | null;
      readonly canRemove: boolean | null;
      readonly canUpdate: boolean | null;
    } | null;
    readonly attractiveCompatibilityAe: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly date: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly description: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly endDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly eventImages: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly extraNotes: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly lineUp: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly links: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly manualValidationEnabled: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly maxTicketsLimit: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly media: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly name: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly offSaleDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly onSaleDate: {
      readonly canUpdate: boolean | null;
      readonly maxValue: string | null;
      readonly minValue: string | null;
    } | null;
    readonly presentedBy: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly products: {
      readonly canAdd: boolean | null;
      readonly canChangeOrder: boolean | null;
      readonly canDelete: boolean | null;
      readonly canUpdate: boolean | null;
      readonly canUpdateImages: ReadonlyArray<string | null> | null;
      readonly canUpdatePrice: ReadonlyArray<string | null> | null;
    } | null;
    readonly recurringEvents: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly requiresBoxOfficeTicketNomination: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly requiresTicketNomination: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly sendReceiptViaSms: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly ticketPools: {
      readonly canAdd: boolean | null;
      readonly canChangeAllocation: boolean | null;
      readonly canRemove: boolean | null;
      readonly canUpdate: boolean | null;
    } | null;
    readonly ticketTypes: {
      readonly canAdd: boolean | null;
      readonly canChangeAllocation: boolean | null;
      readonly canChangeDoorSalesEnabled: boolean | null;
      readonly canChangeExternalSkus: boolean | null;
      readonly canChangeIcon: boolean | null;
      readonly canChangeIncrements: boolean | null;
      readonly canChangeOffSaleDate: boolean | null;
      readonly canChangeOnSaleDate: boolean | null;
      readonly canChangeOrder: boolean | null;
      readonly canChangeSeatmap: boolean | null;
      readonly canChangeTierNames: boolean | null;
      readonly canDelete: boolean | null;
      readonly canHide: boolean | null;
      readonly canUpdate: boolean | null;
      readonly canUpdatePrice: ReadonlyArray<string | null> | null;
    } | null;
    readonly venues: {
      readonly canUpdate: boolean | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly attractiveFields: {
    readonly author: string | null;
    readonly compatibilityAe: CompatibilityAe | null;
    readonly distributor: string | null;
    readonly entertainmentPercent: number | null;
    readonly forceSubscription: boolean | null;
    readonly forceSubscriptionLimit: number | null;
    readonly integrationDisabled: boolean;
    readonly linkedEvents: ReadonlyArray<{
      readonly date: string | null;
      readonly endDate: string | null;
      readonly id: string;
      readonly name: string | null;
      readonly primaryVenue: {
        readonly name: string | null;
      } | null;
      readonly timezoneName: string | null;
    } | null> | null;
    readonly nationality: string | null;
    readonly performer: string | null;
    readonly producer: string | null;
    readonly seatingAreaConfigs: ReadonlyArray<{
      readonly capacity: number;
      readonly seatingArea: string;
    } | null>;
    readonly siaeGenreType: string | null;
    readonly streamingTicketsIntegrationDisabled: boolean;
    readonly subscriptionCode: string | null;
    readonly taxFree: boolean | null;
  } | null;
  readonly attractiveStatus: {
    readonly status: string | null;
  } | null;
  readonly barcodeType: string | null;
  readonly basePriceFees: ReadonlyArray<string | null> | null;
  readonly billingNotes: string | null;
  readonly billingPromoter: {
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly associatedMarketeers: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly autoRescheduledEventRefunds: {
      readonly active: boolean | null;
      readonly cutoffDays: number | null;
    } | null;
    readonly billingNotes: string | null;
    readonly coolingOffPeriod: boolean | null;
    readonly coolingOffPeriodHours: number | null;
    readonly countryCode: string | null;
    readonly defaultEventTimings: ReadonlyArray<{
      readonly offset: number | null;
      readonly sourceField: EventTimingField | null;
      readonly targetField: EventTimingField | null;
      readonly type: EventTimingType | null;
    } | null> | null;
    readonly disableUsTax: boolean | null;
    readonly displayName: string | null;
    readonly fanSupportNotes: {
      readonly body: string | null;
    } | null;
    readonly holdPayouts: boolean | null;
    readonly isTest: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly licenseNumber: string;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly salesforceFields: {
      readonly defaultContract: {
        readonly id: string;
        readonly name: string | null;
        readonly num: string | null;
        readonly opportunityName: string | null;
        readonly sfAccountId: string | null;
        readonly sfId: string | null;
        readonly startDate: string | null;
        readonly status: SalesforceContractStatus | null;
      } | null;
    } | null;
    readonly sendReceiptViaSms: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly stripeFallbackAccountId: string | null;
    readonly stripeFallbackPlatformCode: PlatformAccountCode | null;
    readonly tags: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly value: string;
  } | null;
  readonly bundles: ReadonlyArray<{
    readonly label: string;
    readonly value: string;
  } | null> | null;
  readonly characteristics: ReadonlyArray<{
    readonly label: string | null;
    readonly value: string;
  } | null> | null;
  readonly charityEvent: boolean | null;
  readonly charityId: string | null;
  readonly cities: ReadonlyArray<{
    readonly label: string;
    readonly value: string;
  } | null> | null;
  readonly closeEventDate: string | null;
  readonly completedSteps: number | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly countryCode: string | null;
  readonly date: string | null;
  readonly defaultEventTimings: ReadonlyArray<{
    readonly offset: number | null;
    readonly sourceField: EventTimingField | null;
    readonly targetField: EventTimingField | null;
    readonly type: EventTimingType | null;
  } | null> | null;
  readonly description: string | null;
  readonly diceStreamDuration: number | null;
  readonly diceStreamDvrEnabled: boolean | null;
  readonly diceStreamRewatchEnabledUntil: string | null;
  readonly diceTvPlatform: TvPlatform | null;
  readonly disableUsTax: boolean | null;
  readonly doorlistAdditionalRecipients: ReadonlyArray<string | null> | null;
  readonly doorlistRecipients: ReadonlyArray<{
    readonly email: string | null;
    readonly id: string;
  } | null> | null;
  readonly doorlistSendAt: string | null;
  readonly doorlistSendStatus: string | null;
  readonly endDate: string | null;
  readonly eventArtists: ReadonlyArray<{
    readonly artist: {
      readonly description: string | null;
      readonly hierarchicalTags: ReadonlyArray<{
        readonly kind: string | null;
        readonly label: string | null;
        readonly parent: {
          readonly name: string | null;
        } | null;
        readonly value: string;
      } | null> | null;
      readonly hint: string | null;
      readonly label: string | null;
      readonly media: ReadonlyArray<{
        readonly id: string;
        readonly type: MediaItemTypes;
        readonly values: IBackendMap | null;
      } | null>;
      readonly profileImageAttachment: {
        readonly cdnUrl: string;
      } | null;
      readonly profileImageCropRegion: {
        readonly height: number | null;
        readonly width: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
      readonly tags: ReadonlyArray<{
        readonly label: string | null;
        readonly value: string;
      } | null> | null;
      readonly value: string;
    } | null;
    readonly description: string | null;
    readonly headliner: boolean | null;
  } | null> | null;
  readonly eventIdLive: string | null;
  readonly eventImages: ReadonlyArray<{
    readonly attachment: {
      readonly id: string;
    } | null;
    readonly cdnUrl: string;
    readonly cropRegion: {
      readonly height: number | null;
      readonly width: number | null;
      readonly x: number | null;
      readonly y: number | null;
    } | null;
    readonly id: string;
    readonly type: string | null;
  } | null> | null;
  readonly eventLoadPredictions: ReadonlyArray<{
    readonly expectedRequestsPerMinute: number | null;
    readonly expectedStartTime: string | null;
    readonly id: string;
  } | null> | null;
  readonly eventRules: {
    readonly covidPcr: boolean | null;
    readonly covidPcrValidHours: number | null;
    readonly covidPolicyUrl: string | null;
    readonly covidRecovery: boolean | null;
    readonly covidVaccination: boolean | null;
    readonly maskRequired: boolean | null;
    readonly proofOfBeingHealthy: boolean | null;
    readonly socialDistancing: boolean | null;
  } | null;
  readonly eventSeatingChart: {
    readonly chartManagerCredentials: {
      readonly secretKey: string;
    };
    readonly id: string;
    readonly seatingChannels: ReadonlyArray<{
      readonly channelType: ChannelType;
      readonly name: string | null;
      readonly seatsIoChannel: string;
    } | null> | null;
    readonly seatsIoEventId: string;
    readonly seatsIoEventReport: IBackendMap | null;
    readonly venueChart: {
      readonly label: string;
      readonly value: string;
    };
  } | null;
  readonly eventSharingObjects: ReadonlyArray<{
    readonly email: string;
    readonly id: string | null;
    readonly permissionProfile: {
      readonly id: string;
    };
  } | null> | null;
  readonly eventType: EventType | null;
  readonly extraNotes: string | null;
  readonly fanSupportNotes: {
    readonly body: string | null;
  } | null;
  readonly faqs: ReadonlyArray<{
    readonly body: string | null;
    readonly id: string;
    readonly order: number;
    readonly title: string | null;
  } | null> | null;
  readonly featuredAreas: ReadonlyArray<{
    readonly countryCode: string | null;
    readonly description: string | null;
    readonly endDate: string;
    readonly id: string;
    readonly locationLat: number | null;
    readonly locationLng: number | null;
    readonly locationRadius: number | null;
    readonly locationString: string | null;
    readonly locationUnits: DistanceUnits | null;
    readonly mode: TargetingMode;
    readonly startDate: string;
    readonly weight: number;
  } | null> | null;
  readonly fees: ReadonlyArray<{
    readonly amount: number | null;
    readonly applicable: boolean | null;
    readonly split: ReadonlyArray<{
      readonly amount: number | null;
      readonly destination: FeeDestination | null;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly type: FeeType;
    readonly unit: FeeUnit;
  } | null> | null;
  readonly feesBehaviour: FeesBehaviour | null;
  readonly flags: {
    readonly alcoholFree: IBackendMap | null;
    readonly autoRescheduledEventRefunds: IBackendMap | null;
    readonly claimTickets: IBackendMap | null;
    readonly codeLocked: IBackendMap | null;
    readonly competition: IBackendMap | null;
    readonly coolingOffPeriod: IBackendMap | null;
    readonly disableDayOfEventComms: IBackendMap | null;
    readonly enabledPwl: IBackendMap | null;
    readonly fanPickSeat: IBackendMap | null;
    readonly generateNewCodeOnTransfer: IBackendMap | null;
    readonly hidden: IBackendMap | null;
    readonly hideFromDiscovery: IBackendMap | null;
    readonly paperTicket: IBackendMap | null;
    readonly seated: IBackendMap | null;
    readonly shoppingCart: IBackendMap | null;
    readonly ticketTransfer: IBackendMap | null;
    readonly unicorn: IBackendMap | null;
    readonly waitingList: IBackendMap | null;
  } | null;
  readonly freeEvent: boolean | null;
  readonly fullAddress: string | null;
  readonly hierarchicalTags: ReadonlyArray<{
    readonly kind: string | null;
    readonly label: string | null;
    readonly parent: {
      readonly name: string | null;
    } | null;
    readonly value: string;
  } | null> | null;
  readonly id: string;
  readonly isTest: boolean | null;
  readonly isTicketAvailableAtDoor: boolean | null;
  readonly labels: ReadonlyArray<{
    readonly label: string;
    readonly value: string;
  } | null> | null;
  readonly latitude: number | null;
  readonly licenseNumber: string | null;
  readonly lineup: ReadonlyArray<IBackendMap | null> | null;
  readonly links: ReadonlyArray<{
    readonly name: string | null;
    readonly url: string | null;
  } | null>;
  readonly lockVersion: number;
  readonly longitude: number | null;
  readonly manualValidationEnabled: boolean | null;
  readonly marketeers: ReadonlyArray<{
    readonly appOptInEnabled: boolean | null;
    readonly fbAccessToken: string | null;
    readonly fbPixelId: string | null;
    readonly gaTrackingId: string | null;
    readonly googleAdsConversionId: string | null;
    readonly googleAdsPurchaseConversionLabel: string | null;
    readonly label: string | null;
    readonly privacyPolicyLink: string | null;
    readonly tiktokPixelId: string | null;
    readonly twitterCheckoutInitiatedPixelId: string | null;
    readonly twitterPixelId: string | null;
    readonly twitterPurchasePixelId: string | null;
    readonly value: string;
    readonly webOptInEnabled: boolean | null;
  } | null> | null;
  readonly maxTicketsLimit: number | null;
  readonly media: ReadonlyArray<{
    readonly id: string;
    readonly type: MediaItemTypes;
    readonly values: IBackendMap | null;
  } | null> | null;
  readonly name: string | null;
  readonly notes: string | null;
  readonly offSaleDate: string | null;
  readonly offSaleSentAt: string | null;
  readonly offSaleSentStatus: string | null;
  readonly onSaleDate: string | null;
  readonly onSaleNotification: boolean | null;
  readonly onSaleNotificationAt: string | null;
  readonly onSaleNotificationStatus: boolean | null;
  readonly onSaleSmsReminders: number;
  readonly permName: string | null;
  readonly platformAccountCode: PlatformAccountCode | null;
  readonly postFanPriceFees: ReadonlyArray<string | null> | null;
  readonly postalCode: string | null;
  readonly presentedBy: string | null;
  readonly previewToken: string | null;
  readonly primaryVenue: {
    readonly label: string | null;
    readonly value: string;
    readonly venueSpaces: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
  } | null;
  readonly printedTicketFormat: PrintedTicketFormat | null;
  readonly products: ReadonlyArray<{
    readonly allTicketTypes: boolean;
    readonly allocation: number;
    readonly archived: boolean;
    readonly category: {
      readonly coverBackgroundColor: string | null;
      readonly coverImageUrl: string | null;
      readonly label: string;
      readonly parentCategory: {
        readonly label: string;
        readonly type: CategoryType | null;
        readonly value: string;
      } | null;
      readonly rootType: ProductRootType | null;
      readonly type: CategoryType | null;
      readonly value: string;
    };
    readonly customCover: boolean | null;
    readonly date: string | null;
    readonly description: string;
    readonly endDate: string | null;
    readonly event: {
      readonly id: string;
    } | null;
    readonly faceValue: number;
    readonly fees: ReadonlyArray<{
      readonly amount: number | null;
      readonly applicable: boolean | null;
      readonly split: ReadonlyArray<{
        readonly amount: number | null;
        readonly destination: FeeDestination | null;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly type: FeeType;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly fulfilledBy: string | null;
    readonly hasSeparateAccessBarcodes: boolean;
    readonly hasVariants: boolean;
    readonly id: string;
    readonly locationNote: string | null;
    readonly name: string;
    readonly offSaleDate: string | null;
    readonly onSaleDate: string | null;
    readonly optionType: ProductOptionType | null;
    readonly priceBreakdown: {
      readonly breakdown: ReadonlyArray<{
        readonly applicable: boolean | null;
        readonly computed: number;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly computed: number;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null>;
        readonly type: FeeType;
      } | null>;
      readonly faceValue: number;
      readonly fees: number;
      readonly salesTax: number;
      readonly split: ReadonlyArray<{
        readonly computed: number;
        readonly destination: FeeDestination;
      } | null>;
      readonly total: number;
      readonly vatAmount: number;
      readonly vatRate: number;
    } | null;
    readonly productImages: ReadonlyArray<{
      readonly attachment: {
        readonly id: string;
      } | null;
      readonly cdnUrl: string;
      readonly cropRegion: {
        readonly height: number | null;
        readonly width: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
      readonly id: string;
    } | null> | null;
    readonly productType: ProductType;
    readonly purchaseConfirmationMessage: string | null;
    readonly rootType: ProductRootType;
    readonly sellingPoints: ReadonlyArray<{
      readonly name: string;
    } | null>;
    readonly sku: string | null;
    readonly ticketTypes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
    } | null>;
    readonly variants: ReadonlyArray<{
      readonly allocation: number;
      readonly id: string;
      readonly name: string;
      readonly sku: string | null;
    } | null>;
    readonly venue: {
      readonly fullAddress: string | null;
      readonly label: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly profileDetails: {
        readonly imageAttachment: {
          readonly cdnUrl: string;
        } | null;
        readonly imageCropRegion: {
          readonly height: number | null;
          readonly width: number | null;
          readonly x: number | null;
          readonly y: number | null;
        } | null;
      } | null;
      readonly value: string;
      readonly venueImages: ReadonlyArray<{
        readonly attachment: {
          readonly cdnUrl: string;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
  readonly promoters: ReadonlyArray<{
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly associatedMarketeers: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly autoRescheduledEventRefunds: {
      readonly active: boolean | null;
      readonly cutoffDays: number | null;
    } | null;
    readonly billingNotes: string | null;
    readonly coolingOffPeriod: boolean | null;
    readonly coolingOffPeriodHours: number | null;
    readonly countryCode: string | null;
    readonly disableUsTax: boolean | null;
    readonly displayName: string | null;
    readonly fanSupportNotes: {
      readonly body: string | null;
    } | null;
    readonly holdPayouts: boolean | null;
    readonly isTest: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly licenseNumber: string;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly salesforceFields: {
      readonly defaultContract: {
        readonly id: string;
        readonly name: string | null;
        readonly num: string | null;
        readonly opportunityName: string | null;
        readonly sfAccountId: string | null;
        readonly sfId: string | null;
        readonly startDate: string | null;
        readonly status: SalesforceContractStatus | null;
      } | null;
    } | null;
    readonly sendReceiptViaSms: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly stripeFallbackAccountId: string | null;
    readonly stripeFallbackPlatformCode: PlatformAccountCode | null;
    readonly tags: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly value: string;
  } | null> | null;
  readonly recurrentEventSchedule: {
    readonly frequency: RepeatFrequency | null;
    readonly occurrences: number | null;
    readonly repeatEnds: RepeatEnds | null;
    readonly repeatOn: RepeatOn | null;
    readonly until: string | null;
  } | null;
  readonly recurrentEventsGroup: ReadonlyArray<{
    readonly date: string | null;
    readonly id: string;
  } | null> | null;
  readonly requiresBoxOfficeTicketNomination: boolean | null;
  readonly requiresTicketNomination: boolean | null;
  readonly restrictCountries: ReadonlyArray<CountryCode | null> | null;
  readonly restrictCountriesKind: RestrictionKind | null;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly priceTiersBreakdown: ReadonlyArray<{
        readonly appSold: number;
        readonly posSold: number;
        readonly priceTier: {
          readonly id: string;
        };
        readonly reserved: number;
        readonly sold: number;
        readonly terminalSold: number;
      } | null>;
      readonly ticketType: {
        readonly id: string;
        readonly ticketPoolId: string | null;
      };
      readonly ticketTypeId: string;
      readonly totalAppSold: number;
      readonly totalPosSold: number;
      readonly totalReserved: number;
      readonly totalSold: number;
      readonly totalTerminalSold: number;
    } | null>;
    readonly totalAppSold: number;
    readonly totalPosSold: number;
  } | null;
  readonly salesforceContract: {
    readonly id: string;
    readonly name: string | null;
    readonly num: string | null;
    readonly opportunityName: string | null;
    readonly sfAccountId: string | null;
    readonly sfId: string | null;
    readonly startDate: string | null;
    readonly status: SalesforceContractStatus | null;
  } | null;
  readonly scheduleStatus: ScheduleStatus | null;
  readonly scheduleStatusUpdatedAt: string | null;
  readonly sendReceiptViaSms: boolean | null;
  readonly showArtistDescription: ShowArtistDescription | null;
  readonly smsTtys: ReadonlyArray<{
    readonly archived: boolean | null;
    readonly id: string;
    readonly name: string | null;
    readonly onSaleDate: string | null;
    readonly onSaleNotificationSentAt: string | null;
  } | null> | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly streetAddress: string | null;
  readonly stripeAccountId: string | null;
  readonly tags: ReadonlyArray<{
    readonly label: string | null;
    readonly value: string;
  } | null> | null;
  readonly taxSettings: {
    readonly clubNight: boolean | null;
    readonly franceMainstream: boolean | null;
  } | null;
  readonly thirdPartySettings: {
    readonly label: string;
    readonly value: string;
  } | null;
  readonly ticketPools: ReadonlyArray<{
    readonly id: string;
    readonly maxAllocation: number | null;
    readonly name: string | null;
  } | null> | null;
  readonly ticketType: string | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly activateCodeDateOffset: number | null;
    readonly additionalPaymentMethods: ReadonlyArray<PaymentMethods | null> | null;
    readonly allocation: number;
    readonly allowSeatChange: boolean | null;
    readonly announceDate: string | null;
    readonly archived: boolean | null;
    readonly attractivePriceType: string | null;
    readonly attractiveSeatingAreaType: string | null;
    readonly codeLocked: boolean;
    readonly description: string | null;
    readonly doorSalesEnabled: boolean;
    readonly doorSalesPrice: number | null;
    readonly doorSalesPriceTaxed: number | null;
    readonly doorSalesTax: number | null;
    readonly endDate: string | null;
    readonly externalSkus: ReadonlyArray<string | null> | null;
    readonly faceValue: number;
    readonly fees: ReadonlyArray<{
      readonly amount: number | null;
      readonly applicable: boolean | null;
      readonly split: ReadonlyArray<{
        readonly amount: number | null;
        readonly destination: FeeDestination | null;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly type: FeeType;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly hidden: boolean;
    readonly icon: string | null;
    readonly id: string;
    readonly increment: number | null;
    readonly isStream: boolean;
    readonly maximumIncrements: number | null;
    readonly name: string | null;
    readonly offSaleDate: string | null;
    readonly onSaleDate: string | null;
    readonly presale: boolean;
    readonly priceBreakdown: {
      readonly breakdown: ReadonlyArray<{
        readonly applicable: boolean | null;
        readonly computed: number;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly computed: number;
          readonly destination: FeeDestination | null;
        } | null>;
        readonly type: FeeType;
      } | null>;
      readonly faceValue: number;
      readonly friendlyFaceValue: number | null;
      readonly friendlyPrice: number | null;
      readonly split: ReadonlyArray<{
        readonly computed: number;
        readonly destination: FeeDestination;
      } | null>;
      readonly total: number;
      readonly totalWithPwl: number | null;
      readonly totalWithoutPwl: number | null;
    } | null;
    readonly priceHidden: boolean;
    readonly priceTierType: PriceTierTypes | null;
    readonly priceTiers: ReadonlyArray<{
      readonly allocation: number | null;
      readonly attractivePriceType: string | null;
      readonly doorSalesPrice: number | null;
      readonly doorSalesPriceTaxed: number | null;
      readonly faceValue: number;
      readonly fees: ReadonlyArray<{
        readonly amount: number | null;
        readonly applicable: boolean | null;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null> | null;
        readonly type: FeeType;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly id: string;
      readonly name: string | null;
      readonly priceBreakdown: {
        readonly breakdown: ReadonlyArray<{
          readonly applicable: boolean | null;
          readonly computed: number;
          readonly split: ReadonlyArray<{
            readonly amount: number | null;
            readonly computed: number;
            readonly destination: FeeDestination | null;
          } | null>;
          readonly type: FeeType;
        } | null>;
        readonly faceValue: number;
        readonly friendlyFaceValue: number | null;
        readonly friendlyPrice: number | null;
        readonly split: ReadonlyArray<{
          readonly computed: number;
          readonly destination: FeeDestination;
        } | null>;
        readonly total: number;
        readonly totalWithPwl: number | null;
        readonly totalWithoutPwl: number | null;
      } | null;
      readonly time: string | null;
    } | null> | null;
    readonly requiresAddress: boolean | null;
    readonly requiresOtherTypeIds: ReadonlyArray<string | null> | null;
    readonly reservedSeating: boolean | null;
    readonly reservedSeatingType: ReservedSeatingTypes | null;
    readonly salesLimit: number | null;
    readonly seatCategories: ReadonlyArray<{
      readonly id: string | null;
      readonly name: string;
      readonly seatsIoKey: string | null;
    } | null> | null;
    readonly seatmapUrl: string | null;
    readonly startDate: string | null;
    readonly streamLink: string | null;
    readonly ticketPoolId: string | null;
    readonly venueScheduleId: string | null;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly venueConfiguration: {
    readonly attractiveRoomSiaeCode: string | null;
    readonly capacity: number;
    readonly label: string;
    readonly seatingAreaConfigs: ReadonlyArray<{
      readonly capacity: number;
      readonly seatingArea: string;
    } | null>;
    readonly value: string;
  } | null;
  readonly venueName: string | null;
  readonly venueSchedules: ReadonlyArray<{
    readonly date: string | null;
    readonly endDate: string | null;
    readonly id: string;
    readonly name: string | null;
    readonly venueConfigurationId: string | null;
    readonly venueId: string | null;
  } | null> | null;
  readonly venueSpace: {
    readonly label: string;
    readonly value: string;
  } | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly addressLocality: string | null;
    readonly addressRegion: string | null;
    readonly addressState: string | null;
    readonly ageLimit: string | null;
    readonly atLeastOneSeatingChart: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null> | null;
    } | null;
    readonly capacity: number;
    readonly configurations: ReadonlyArray<{
      readonly attractiveRoomSiaeCode: string | null;
      readonly capacity: number;
      readonly label: string;
      readonly seatingAreaConfigs: ReadonlyArray<{
        readonly capacity: number;
        readonly seatingArea: string;
      } | null>;
      readonly value: string;
    } | null> | null;
    readonly countryCode: string | null;
    readonly fullAddress: string | null;
    readonly isTest: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly postalCode: string | null;
    readonly profileDetails: {
      readonly imageAttachment: {
        readonly cdnUrl: string;
      } | null;
      readonly imageCropRegion: {
        readonly height: number | null;
        readonly width: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
    } | null;
    readonly streetAddress: string | null;
    readonly tier: VenueTier | null;
    readonly timezoneName: string | null;
    readonly value: string;
    readonly venueImages: ReadonlyArray<{
      readonly attachment: {
        readonly cdnUrl: string;
      } | null;
    } | null> | null;
    readonly venueSpaces: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
  } | null> | null;
  readonly waitingListExchangeWindows: ReadonlyArray<{
    readonly duration: number;
    readonly id: string | null;
    readonly offset: number | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventReviewModal_event" | "EventTicketTypeDoorPricing_event" | "EventTicketTypeIncrements_event" | "EventTicketTypePricing_event" | "EventTicketTypeTimeline_event" | "TaxRates_event">;
  readonly " $fragmentType": "EventSubmission_event";
};
export type EventSubmission_event$key = {
  readonly " $data"?: EventSubmission_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventSubmission_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseNumber",
  "storageKey": null
},
v3 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SeatingAreaConfig",
  "kind": "LinkedField",
  "name": "seatingAreaConfigs",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seatingArea",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezoneName",
  "storageKey": null
},
v12 = [
  (v1/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v14 = [
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canAdd",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canRemove",
  "storageKey": null
},
v17 = [
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "minValue",
    "storageKey": null
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canChangeAllocation",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canChangeOrder",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canDelete",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdatePrice",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressLocality",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressRegion",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressState",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAddress",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "concreteType": "MediaItem",
  "kind": "LinkedField",
  "name": "media",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v30/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "values",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeAccountId",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platformAccountCode",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTest",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showPriceSuggestions",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowSkipReview",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resoldEnabled",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disableUsTax",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "holdPayouts",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coolingOffPeriod",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coolingOffPeriodHours",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendReceiptViaSms",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingNotes",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "concreteType": "FanSupportNotes",
  "kind": "LinkedField",
  "name": "fanSupportNotes",
  "plural": false,
  "selections": [
    (v46/*: any*/)
  ],
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "concreteType": "Label",
  "kind": "LinkedField",
  "name": "labels",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "Marketeer",
  "kind": "LinkedField",
  "name": "associatedMarketeers",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "concreteType": "AutoRescheduledEventRefunds",
  "kind": "LinkedField",
  "name": "autoRescheduledEventRefunds",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cutoffDays",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v52 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "num",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "opportunityName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sfAccountId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sfId",
    "storageKey": null
  },
  (v51/*: any*/),
  (v6/*: any*/)
],
v53 = {
  "alias": null,
  "args": null,
  "concreteType": "SalesforcePromoterFields",
  "kind": "LinkedField",
  "name": "salesforceFields",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesforceContract",
      "kind": "LinkedField",
      "name": "defaultContract",
      "plural": false,
      "selections": (v52/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offset",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "concreteType": "EventTiming",
  "kind": "LinkedField",
  "name": "defaultEventTimings",
  "plural": true,
  "selections": [
    (v54/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceField",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetField",
      "storageKey": null
    },
    (v30/*: any*/)
  ],
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeFallbackAccountId",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeFallbackPlatformCode",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v59 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "attractiveRoomSiaeCode",
    "storageKey": null
  },
  (v7/*: any*/),
  (v8/*: any*/)
],
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageLimit",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "concreteType": "VenueSpace",
  "kind": "LinkedField",
  "name": "venueSpaces",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cdnUrl",
  "storageKey": null
},
v65 = [
  (v64/*: any*/)
],
v66 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "x",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "y",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  }
],
v67 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileDetails",
  "kind": "LinkedField",
  "name": "profileDetails",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "imageAttachment",
      "plural": false,
      "selections": (v65/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CropRegion",
      "kind": "LinkedField",
      "name": "imageCropRegion",
      "plural": false,
      "selections": (v66/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v68 = {
  "alias": null,
  "args": null,
  "concreteType": "VenueImage",
  "kind": "LinkedField",
  "name": "venueImages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachment",
      "plural": false,
      "selections": (v65/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v69 = [
  (v0/*: any*/)
],
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v71 = {
  "alias": null,
  "args": null,
  "concreteType": "HierarchicalTag",
  "kind": "LinkedField",
  "name": "hierarchicalTags",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v70/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "HierarchicalTag",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v72 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onSaleDate",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offSaleDate",
  "storageKey": null
},
v75 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "announceDate",
  "storageKey": null
},
v76 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codeLocked",
  "storageKey": null
},
v77 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hidden",
  "storageKey": null
},
v78 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAppSold",
  "storageKey": null
},
v79 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPosSold",
  "storageKey": null
},
v80 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticketPoolId",
  "storageKey": null
},
v81 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachment",
  "plural": false,
  "selections": (v69/*: any*/),
  "storageKey": null
},
v82 = {
  "alias": null,
  "args": null,
  "concreteType": "CropRegion",
  "kind": "LinkedField",
  "name": "cropRegion",
  "plural": false,
  "selections": (v66/*: any*/),
  "storageKey": null
},
v83 = [
  {
    "kind": "Literal",
    "name": "doorSalesOnly",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "includeArchived",
    "value": true
  }
],
v84 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v85 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v86 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v87 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doorSalesPrice",
  "storageKey": null
},
v88 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doorSalesPriceTaxed",
  "storageKey": null
},
v89 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attractivePriceType",
  "storageKey": null
},
v90 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v91 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v92 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applicable",
  "storageKey": null
},
v93 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destination",
  "storageKey": null
},
v94 = {
  "alias": null,
  "args": null,
  "concreteType": "Fee",
  "kind": "LinkedField",
  "name": "fees",
  "plural": true,
  "selections": [
    (v90/*: any*/),
    (v30/*: any*/),
    (v91/*: any*/),
    (v92/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v90/*: any*/),
        (v93/*: any*/),
        (v91/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v95 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "computed",
  "storageKey": null
},
v96 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v97 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceSplit",
  "kind": "LinkedField",
  "name": "split",
  "plural": true,
  "selections": [
    (v95/*: any*/),
    (v93/*: any*/)
  ],
  "storageKey": null
},
v98 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "priceBreakdown",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeOutput",
      "kind": "LinkedField",
      "name": "breakdown",
      "plural": true,
      "selections": [
        (v95/*: any*/),
        (v30/*: any*/),
        (v92/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FeeSplitOutput",
          "kind": "LinkedField",
          "name": "split",
          "plural": true,
          "selections": [
            (v90/*: any*/),
            (v95/*: any*/),
            (v93/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v96/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithPwl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithoutPwl",
      "storageKey": null
    },
    (v86/*: any*/),
    (v97/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyFaceValue",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v99 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootType",
  "storageKey": null
},
v100 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v101 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventSubmission_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedSteps",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageLinks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managePromotions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageTickets",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addProducts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageProductsAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readExtras",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readMerch",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inviteExternalGuest",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceTvPlatform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "charityEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "charityId",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Characteristic",
      "kind": "LinkedField",
      "name": "characteristics",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationStatus",
      "kind": "LinkedField",
      "name": "attractiveStatus",
      "plural": false,
      "selections": [
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttractiveFields",
      "kind": "LinkedField",
      "name": "attractiveFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "compatibilityAe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "siaeGenreType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entertainmentPercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamingTicketsIntegrationDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFree",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "author",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distributor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nationality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "producer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forceSubscription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forceSubscriptionLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Event",
          "kind": "LinkedField",
          "name": "linkedEvents",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Venue",
              "kind": "LinkedField",
              "name": "primaryVenue",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "venues",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "eventImages",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesArtistIds",
          "kind": "LinkedField",
          "name": "artistIds",
          "plural": false,
          "selections": [
            (v15/*: any*/),
            (v13/*: any*/),
            (v16/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesLineUp",
          "kind": "LinkedField",
          "name": "lineUp",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "media",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "attractiveCompatibilityAe",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "announceDate",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "onSaleDate",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "offSaleDate",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "date",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "endDate",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "presentedBy",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "ageLimit",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketTypes",
          "kind": "LinkedField",
          "name": "ticketTypes",
          "plural": false,
          "selections": [
            (v15/*: any*/),
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeDoorSalesEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeIcon",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeIncrements",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOffSaleDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOnSaleDate",
              "storageKey": null
            },
            (v19/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeSeatmap",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeTierNames",
              "storageKey": null
            },
            (v20/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canHide",
              "storageKey": null
            },
            (v13/*: any*/),
            (v21/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeExternalSkus",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketPools",
          "kind": "LinkedField",
          "name": "ticketPools",
          "plural": false,
          "selections": [
            (v15/*: any*/),
            (v13/*: any*/),
            (v18/*: any*/),
            (v16/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "maxTicketsLimit",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesProducts",
          "kind": "LinkedField",
          "name": "products",
          "plural": false,
          "selections": [
            (v15/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            (v13/*: any*/),
            (v21/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdateImages",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "extraNotes",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "links",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "recurringEvents",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "requiresTicketNomination",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "requiresBoxOfficeTicketNomination",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "manualValidationEnabled",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "sendReceiptViaSms",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v11/*: any*/),
    (v22/*: any*/),
    (v23/*: any*/),
    (v24/*: any*/),
    (v25/*: any*/),
    (v26/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressSiaeCode",
      "storageKey": null
    },
    (v27/*: any*/),
    (v28/*: any*/),
    (v29/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "venueName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lineup",
      "storageKey": null
    },
    (v9/*: any*/),
    (v31/*: any*/),
    (v32/*: any*/),
    (v33/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v34/*: any*/),
        (v35/*: any*/),
        (v32/*: any*/),
        (v33/*: any*/),
        (v36/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/),
        (v37/*: any*/),
        (v2/*: any*/),
        (v38/*: any*/),
        (v39/*: any*/),
        (v40/*: any*/),
        (v41/*: any*/),
        (v42/*: any*/),
        (v43/*: any*/),
        (v44/*: any*/),
        (v45/*: any*/),
        (v47/*: any*/),
        (v48/*: any*/),
        (v49/*: any*/),
        (v50/*: any*/),
        (v53/*: any*/),
        (v55/*: any*/),
        (v56/*: any*/),
        (v57/*: any*/),
        (v58/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesforceContract",
      "kind": "LinkedField",
      "name": "salesforceContract",
      "plural": false,
      "selections": (v52/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "promoters",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v34/*: any*/),
        (v35/*: any*/),
        (v32/*: any*/),
        (v33/*: any*/),
        (v36/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/),
        (v37/*: any*/),
        (v2/*: any*/),
        (v38/*: any*/),
        (v39/*: any*/),
        (v40/*: any*/),
        (v41/*: any*/),
        (v42/*: any*/),
        (v43/*: any*/),
        (v44/*: any*/),
        (v45/*: any*/),
        (v47/*: any*/),
        (v48/*: any*/),
        (v49/*: any*/),
        (v50/*: any*/),
        (v53/*: any*/),
        (v56/*: any*/),
        (v57/*: any*/),
        (v58/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueConfiguration",
      "kind": "LinkedField",
      "name": "venueConfiguration",
      "plural": false,
      "selections": (v59/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueSpace",
      "kind": "LinkedField",
      "name": "venueSpace",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v34/*: any*/),
        (v7/*: any*/),
        (v60/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/),
        (v24/*: any*/),
        (v25/*: any*/),
        (v26/*: any*/),
        (v27/*: any*/),
        (v28/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v29/*: any*/),
        (v11/*: any*/),
        (v61/*: any*/),
        (v62/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        },
        (v48/*: any*/),
        (v63/*: any*/),
        (v67/*: any*/),
        (v68/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "VenueConfiguration",
          "kind": "LinkedField",
          "name": "configurations",
          "plural": true,
          "selections": (v59/*: any*/),
          "storageKey": null
        },
        {
          "alias": "atLeastOneSeatingChart",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "SeatingChartConnection",
          "kind": "LinkedField",
          "name": "seatingCharts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SeatingChartEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SeatingChart",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v69/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "seatingCharts(first:1)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "primaryVenue",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v63/*: any*/)
      ],
      "storageKey": null
    },
    (v71/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventArtist",
      "kind": "LinkedField",
      "name": "eventArtists",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headliner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Artist",
          "kind": "LinkedField",
          "name": "artist",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": "hint",
              "args": null,
              "kind": "ScalarField",
              "name": "disambiguation",
              "storageKey": null
            },
            (v31/*: any*/),
            (v71/*: any*/),
            (v58/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "profileImageAttachment",
              "plural": false,
              "selections": (v65/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CropRegion",
              "kind": "LinkedField",
              "name": "profileImageCropRegion",
              "plural": false,
              "selections": (v66/*: any*/),
              "storageKey": null
            },
            (v72/*: any*/)
          ],
          "storageKey": null
        },
        (v72/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventAdditionalInfo",
      "kind": "LinkedField",
      "name": "additionalInfos",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeOnPurchaseEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeOnReminderEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaLink",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalArtist",
      "kind": "LinkedField",
      "name": "additionalArtists",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v72/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HierarchicalTag",
          "kind": "LinkedField",
          "name": "hierarchicalTags",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v70/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v58/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueSchedule",
      "kind": "LinkedField",
      "name": "venueSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "venueId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "venueConfigurationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v10/*: any*/),
    (v73/*: any*/),
    (v74/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeEventDate",
      "storageKey": null
    },
    (v75/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleStatusUpdatedAt",
      "storageKey": null
    },
    (v55/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabledPwl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketTransfer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autoRescheduledEventRefunds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableDayOfEventComms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alcoholFree",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shoppingCart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unicorn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "competition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waitingList",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paperTicket",
          "storageKey": null
        },
        (v76/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generateNewCodeOnTransfer",
          "storageKey": null
        },
        (v42/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fanPickSeat",
          "storageKey": null
        },
        (v77/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "claimTickets",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideFromDiscovery",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "recurrentEventsGroup",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        (v78/*: any*/),
        (v79/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketTypeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalReserved",
              "storageKey": null
            },
            (v78/*: any*/),
            (v79/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalTerminalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v80/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceTierBreakdown",
              "kind": "LinkedField",
              "name": "priceTiersBreakdown",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceTier",
                  "kind": "LinkedField",
                  "name": "priceTier",
                  "plural": false,
                  "selections": (v69/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reserved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "posSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terminalSold",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeaturedArea",
      "kind": "LinkedField",
      "name": "featuredAreas",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v51/*: any*/),
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationString",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationRadius",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationUnits",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationLat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationLng",
          "storageKey": null
        },
        (v23/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weight",
          "storageKey": null
        },
        (v72/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventRules",
      "kind": "LinkedField",
      "name": "eventRules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidPcr",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidPcrValidHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidRecovery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidVaccination",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maskRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "proofOfBeingHealthy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "socialDistancing",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidPolicyUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventImage",
      "kind": "LinkedField",
      "name": "eventImages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v30/*: any*/),
        (v64/*: any*/),
        (v81/*: any*/),
        (v82/*: any*/)
      ],
      "storageKey": null
    },
    (v72/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presentedBy",
      "storageKey": null
    },
    (v60/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Faq",
      "kind": "LinkedField",
      "name": "faqs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v46/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v61/*: any*/),
    (v62/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showArtistDescription",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeIncrements_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypePricing_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeDoorPricing_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeTimeline_event"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTicketsLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketPool",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v83/*: any*/),
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresOtherTypeIds",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v84/*: any*/),
        (v77/*: any*/),
        (v80/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalPaymentMethods",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activateCodeDateOffset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "venueScheduleId",
          "storageKey": null
        },
        (v76/*: any*/),
        (v51/*: any*/),
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStream",
          "storageKey": null
        },
        (v72/*: any*/),
        (v85/*: any*/),
        (v86/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "presale",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "increment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maximumIncrements",
          "storageKey": null
        },
        (v87/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesEnabled",
          "storageKey": null
        },
        (v88/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesTax",
          "storageKey": null
        },
        (v75/*: any*/),
        (v73/*: any*/),
        (v74/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attractiveSeatingAreaType",
          "storageKey": null
        },
        (v89/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seatmapUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceTierType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalSkus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceHidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSeatChange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedSeating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedSeatingType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatCategory",
          "kind": "LinkedField",
          "name": "seatCategories",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seatsIoKey",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v94/*: any*/),
        (v98/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceTier",
          "kind": "LinkedField",
          "name": "priceTiers",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v87/*: any*/),
            (v88/*: any*/),
            (v86/*: any*/),
            (v85/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            },
            (v89/*: any*/),
            (v94/*: any*/),
            (v98/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSeatingChart",
      "kind": "LinkedField",
      "name": "eventSeatingChart",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "group",
              "value": "BY_CATEGORY_KEY"
            }
          ],
          "kind": "ScalarField",
          "name": "seatsIoEventReport",
          "storageKey": "seatsIoEventReport(group:\"BY_CATEGORY_KEY\")"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seatsIoEventId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartManagerCredentials",
          "kind": "LinkedField",
          "name": "chartManagerCredentials",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "secretKey",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatingChannel",
          "kind": "LinkedField",
          "name": "seatingChannels",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seatsIoChannel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "channelType",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatingChart",
          "kind": "LinkedField",
          "name": "venueChart",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feesBehaviour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basePriceFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postFanPriceFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTicketAvailableAtDoor",
      "storageKey": null
    },
    (v94/*: any*/),
    (v40/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "WaitingListExchangeWindow",
      "kind": "LinkedField",
      "name": "waitingListExchangeWindows",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        (v54/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "restrictCountries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "restrictCountriesKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStreamDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStreamDvrEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStreamRewatchEnabledUntil",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "barcodeType",
      "storageKey": null
    },
    (v85/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printedTicketFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxSettings",
      "kind": "LinkedField",
      "name": "taxSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clubNight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "franceMainstream",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxRates_event"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleNotificationAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleSmsReminders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleNotificationStatus",
      "storageKey": null
    },
    {
      "alias": "smsTtys",
      "args": (v83/*: any*/),
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v84/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onSaleNotificationSentAt",
          "storageKey": null
        },
        (v73/*: any*/)
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "products",
      "plural": true,
      "selections": [
        (v99/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allTicketTypes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v30/*: any*/),
            (v99/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverBackgroundColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "parentCategory",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                (v30/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasVariants",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optionType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Variant",
          "kind": "LinkedField",
          "name": "variants",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v85/*: any*/),
            (v100/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fulfilledBy",
          "storageKey": null
        },
        (v94/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasSeparateAccessBarcodes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customCover",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Venue",
          "kind": "LinkedField",
          "name": "venue",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v61/*: any*/),
            (v62/*: any*/),
            (v29/*: any*/),
            (v67/*: any*/),
            (v68/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationNote",
          "storageKey": null
        },
        (v9/*: any*/),
        (v10/*: any*/),
        (v72/*: any*/),
        (v0/*: any*/),
        (v85/*: any*/),
        (v100/*: any*/),
        (v73/*: any*/),
        (v74/*: any*/),
        (v1/*: any*/),
        (v86/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "purchaseConfirmationMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductImage",
          "kind": "LinkedField",
          "name": "productImages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v64/*: any*/),
            (v82/*: any*/),
            (v81/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SellingPoint",
          "kind": "LinkedField",
          "name": "sellingPoints",
          "plural": true,
          "selections": (v12/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketType",
          "kind": "LinkedField",
          "name": "ticketTypes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v84/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Event",
          "kind": "LinkedField",
          "name": "event",
          "plural": false,
          "selections": (v69/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "priceBreakdown",
          "plural": false,
          "selections": [
            (v96/*: any*/),
            (v86/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salesTax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fees",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vatAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vatRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FeeOutput",
              "kind": "LinkedField",
              "name": "breakdown",
              "plural": true,
              "selections": [
                (v30/*: any*/),
                (v92/*: any*/),
                (v95/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FeeSplitOutput",
                  "kind": "LinkedField",
                  "name": "split",
                  "plural": true,
                  "selections": [
                    (v90/*: any*/),
                    (v95/*: any*/),
                    (v93/*: any*/),
                    (v91/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v97/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v34/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    (v45/*: any*/),
    (v47/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiresTicketNomination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiresBoxOfficeTicketNomination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualValidationEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doorlistSendStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleSentStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doorlistSendAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleSentAt",
      "storageKey": null
    },
    (v44/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ThirdPartySettings",
      "kind": "LinkedField",
      "name": "thirdPartySettings",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": "label",
          "args": null,
          "kind": "ScalarField",
          "name": "appName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Marketeer",
      "kind": "LinkedField",
      "name": "marketeers",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fbAccessToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fbPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gaTrackingId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleAdsConversionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleAdsPurchaseConversionLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "privacyPolicyLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterCheckoutInitiatedPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterPurchasePixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webOptInEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appOptInEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v48/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSharingObject",
      "kind": "LinkedField",
      "name": "eventSharingObjects",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v101/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PermissionProfile",
          "kind": "LinkedField",
          "name": "permissionProfile",
          "plural": false,
          "selections": (v69/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "doorlistRecipients",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v101/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doorlistAdditionalRecipients",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "City",
      "kind": "LinkedField",
      "name": "cities",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Bundle",
      "kind": "LinkedField",
      "name": "bundles",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecurrentEventsScheduleConfig",
      "kind": "LinkedField",
      "name": "recurrentEventSchedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frequency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "occurrences",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatEnds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "until",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventLoadPrediction",
      "kind": "LinkedField",
      "name": "eventLoadPredictions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expectedRequestsPerMinute",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expectedStartTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventReviewModal_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "58f19b571000a35f4b0b94f9cd476433";

export default node;
