/**
 * @generated SignedSource<<b469c39b1832f2d512548743853be194>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SalesStats_event$data = {
  readonly onSaleSmsReminders: number;
  readonly sales: {
    readonly totalSold: number;
    readonly totalWlIndividuals: number;
    readonly totalWlRequests: number;
  } | null;
  readonly savedCount: number;
  readonly " $fragmentType": "SalesStats_event";
};
export type SalesStats_event$key = {
  readonly " $data"?: SalesStats_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"SalesStats_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesStats_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "savedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleSmsReminders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSold",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalWlIndividuals",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalWlRequests",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "39e298bcc40164a17c05d8dc751e2d2c";

export default node;
