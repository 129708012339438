/* eslint-disable max-lines */
import React, { FC, useCallback, useMemo, useEffect, ChangeEvent, memo, useContext, ReactNode, useRef } from 'react'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import {
  compact,
  concat,
  reject,
  compose,
  map,
  unset,
  get,
  set,
  sumBy,
  isNil,
  take,
  some,
  uniqBy,
  includes,
  find,
  chunk,
  cloneDeep,
} from 'lodash/fp'
import arrayMove from 'array-move'

import graphql from 'babel-plugin-relay/macro'
import { fetchQuery, useRefetchableFragment, useRelayEnvironment } from 'react-relay'
import { FormikContextType } from 'formik/dist/types'
import { isFuture, isPast, parseISO } from 'date-fns'

import { trackingContext } from '../../../context/tracking'
import checkOverallocation from '../../../utils/checkOverallocation'
import { markAsClientOnly } from '../../../utils/entityStatus'
import { CURRENCY } from '../../../utils/formatters/number'
import { color, font, mediaQuery } from '../../../utils/variables'
import { isItalianEvent } from '../../../utils/isCountryEvent'

import usePriceBreakdown, { IBreakdownInput } from '../hooks/usePriceBreakdown'
import { allowedEventAction } from '../services/allowedEventAction'
import IEventFormTickets, { IFee, ITicketPool, ITicketType } from '../types/Tickets'

import Danger from '../../../components/Danger'
import { FormRow } from '../../../components/Form'
import FormGroup, { FormGroupControl, FormGroupError, FormGroupHint } from '../../../components/FormGroup'
import FormField from '../../../components/FormField'
import Table, { Cell, Row, TableBody, TableFooter, TableHeader } from '../../../components/Table'
import { TabMenu, TabMenuItem } from '../../../components/TabMenu'
import FeeBreakdownTooltip from '../../../components/Event/FeeBreakdownTooltip'
import Warning from '../../../components/Warning'
import useMaxSeatsCapacity from '../hooks/useMaxSeatsCapacity'

import EventTicketTypePriceTiers from './EventTicketTypePriceTiers'

import {
  EventTicketTypePricing_event$data,
  EventTicketTypePricing_event$key,
} from '../../../__generated__/EventTicketTypePricing_event.graphql'
import { EventTicketTypePricingRefetchQuery } from '../../../__generated__/EventTicketTypePricingRefetchQuery.graphql'
import EventOverrideFees from './EventOverrideFees'
import Collapsible from '../../../components/Collapsible'
import { authContext } from '../../../context/auth'
import useFeeContractPreview from '../hooks/useFeeContractPreview'
import EventFeeContractPreview from './EventFeeContractPreview'
import usePriceSuggestions from '../hooks/usePriceSuggestions'
import LimitedInput from '../../../components/LimitedInput'
import ListAddButton from '../../../components/ListAddButton'
import { localeContext } from '../../../context/locale'
import PriceBreakdownTooltip from '../../../components/Event/PriceBreakdownTooltip'
import { REGEX_INTEGER } from '../../../utils/regex'
import AlertBox, { AlertBoxIcon } from '../../../components/AlertBox'
import { featureFlagsContext } from '../../../context/featureFlags'
import SwitchField from '../../../components/SwitchField'

const rejectWithIdx = (reject as any).convert({ cap: false })

const PriceInfo = styled(FormRow)<{ isLoading: boolean }>`
  padding: 16px;
  background-color: ${color.palegrey};
  border-radius: 8px;

  font-size: ${font.size.sm}px;

  filter: ${({ isLoading }) => (isLoading ? 'blur(3px)' : 'none')};
`

const TiersFooter = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  flex-direction: row-reverse;

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
  `}
`

const TotalAllocation = styled(FormGroup)`
  margin-top: 16px;

  ${FormGroupControl}, ${FormGroupError}, ${FormGroupHint} {
    text-align: right;

    & > span {
      color: ${color.darkgrey};
      font-size: ${font.size.base}px;
      line-height: 120%;
    }

    & > strong {
      font-size: ${font.size.lg}px;
      margin-left: 8px;
      line-height: 100%;
    }
  }

  ${FormGroupControl} > span {
    white-space: nowrap;
  }

  ${FormGroupControl} > strong {
    word-break: break-word;
  }

  ${Danger} strong {
    word-break: break-word;
  }

  ${mediaQuery.lessThan('tablet')`
    width: 100%;

    ${Danger} {
      width: 100%;
      justify-content: flex-start;
    }
  `}
`

const PriceBreakdown = styled.div`
  & > div + div {
    margin-top: 6px;
  }
`

const PotentialRevenue = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  span {
    display: inline-block;
    margin-left: 4px;
    vertical-align: bottom;
    font-size: ${font.size.base}px;
    font-weight: ${font.weight.bold};
    word-break: break-word;
  }
`

const StyledTable = styled(Table)<{ hasRebate: boolean }>`
  margin: -8px 0;
  table-layout: initial;
  width: 100%;

  td {
    width: 20%;
    padding: 10px 0;
    white-space: initial;
  }

  td:first-child {
    width: ${({ hasRebate }) => (hasRebate ? 25 : 40)}%;
  }

  td + td {
    padding-left: 16px;
  }
`

const StyledTableHeader = styled(TableHeader)`
  border-bottom: 1px solid ${color.lightgrey};
  color: ${color.darkgrey};
`

const StyledTableBody = styled(TableBody)`
  tr + tr.-ticket-type {
    border-top: 1px solid ${color.lightgrey};
  }
`

const StyledTableFooter = styled(TableFooter)`
  border-top: 2px solid ${color.text};
`

const NoBreak = styled.span`
  white-space: nowrap;
`

const Red = styled.span`
  &&& {
    color: ${color.error};
  }
`

const CollapsibleFees = styled(Collapsible)`
  & > button {
    margin: 0 0 24px;
    font-size: ${font.size.base}px;
  }
`

const StyledListAddButton = styled(ListAddButton)`
  margin-top: 24px;
`

const SWarning = styled(AlertBox)`
  margin: -16px 0 -8px 0;

  line-height: 18px;
  padding: 11px 12px;

  strong {
    display: block;
    margin-bottom: 2px;
  }

  ${AlertBoxIcon} {
    align-self: start;
  }
`

type IPriceTier = NonNullable<NonNullable<ITicketType['priceTiers']>[number]>
type ITtySales = NonNullable<
  NonNullable<NonNullable<EventTicketTypePricing_event$data['sales']>['ticketTypesBreakdown']>[number]
>
export type IPriceTiersSales = NonNullable<ITtySales>['priceTiersBreakdown']

interface IProps {
  readOnly: boolean
  formikContext: FormikContextType<any>
  event: EventTicketTypePricing_event$key | null | undefined
  eventForm?: IEventFormTickets
  values: ITicketType
  handleChange: (e: string | ChangeEvent) => void
  handleBlur: (e: Event) => void
  setFieldValue: (name: string, value: any) => void
  setFieldTouched: (name: string, p1: boolean, p2: boolean) => void
  validateForm: () => void
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
  noRestrictions: boolean
  canAddPriceTiers: boolean
  children?: ReactNode
}

const newTier = (faceValue: number, allocation?: number) =>
  markAsClientOnly<IPriceTier>({
    faceValue,
    allocation,
  } as IPriceTier)

const EventTicketTypePricing: FC<React.PropsWithChildren<IProps>> = (props) => {
  const { user, hasPermission } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const [event, refetch] = useRefetchableFragment<EventTicketTypePricingRefetchQuery, EventTicketTypePricing_event$key>(
    graphql`
      fragment EventTicketTypePricing_event on Event @refetchable(queryName: "EventTicketTypePricingRefetchQuery") {
        id
        eventIdLive
        state
        statusAsOfNow
        eventType
        onSaleDate
        offSaleDate
        costCurrency
        timezoneName
        addressCountry
        countryCode
        feesBehaviour
        basePriceFees
        postFanPriceFees
        disableUsTax
        freeEvent
        fees {
          amount
          type
          unit

          split {
            amount
            destination
            unit
          }
        }

        eventSeatingChart {
          id
        }

        ticketTypes(doorSalesOnly: false, includeArchived: true) {
          id
          hidden
          archived
          allocation
          isStream
          priceTierType
          salesLimit
          priceHidden
          priceTiers {
            allocation
          }
        }
        venues {
          value: id
          addressCountry
          countryCode
          capacity
        }
        allowedLifecycleUpdates {
          onSaleDate {
            canUpdate
          }
          ticketTypes {
            canChangeTierNames
            canUpdatePrice
          }
        }
        billingPromoter {
          value: id
          label: name
          stripeAccountId
          platformAccountCode
          showPriceSuggestions
          addressCountry
          countryCode
          accountId
          allowSkipReview
          resoldEnabled
          coolingOffPeriod
          disableUsTax
        }
        ticketPools {
          id
          name
          maxAllocation
        }
        sales {
          ticketTypesBreakdown {
            ticketTypeId
            ticketType {
              id
              ticketPoolId
            }
            priceTiersBreakdown {
              priceTier {
                id
              }
              reserved
              sold
              appSold
              posSold
              terminalSold
            }
          }
        }
      }
    `,
    props.event
  )

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    validateForm,
    children,
    eventForm,
    noRestrictions,
    canAddPriceTiers,
    readOnly,
    formikContext,
  } = props

  const {
    onSaleDate: eventOnSaleDate,
    offSaleDate: eventOffSaleDate,
    state: eventState,
    eventType,
    allowedLifecycleUpdates,
    costCurrency: currency,
    timezoneName,
    billingPromoter,
    id: eventId,
    eventIdLive,
    venues,
    ticketTypes,
    addressCountry,
    countryCode,
    eventSeatingChart,
    fees: eventFees,
    feesBehaviour,
    basePriceFees,
    postFanPriceFees,
    disableUsTax,
    ticketPools,
    sales,
    fees: feeOverrides,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = (eventForm || event)!

  // ¯\_(ツ)_/¯
  // Refetch allowedLifecycleUpdates every 30s
  // TODO: Remove when backend will create true validation

  const isRefetching = useRef(false)

  const needRefetch = useMemo(
    () =>
      ((event && event.statusAsOfNow === 'on-sale') || (eventForm && eventForm.statusAsOfNow === 'on-sale')) &&
      includes(values.id, allowedLifecycleUpdates?.ticketTypes?.canUpdatePrice || []),
    [event, allowedLifecycleUpdates?.ticketTypes?.canUpdatePrice, eventForm, values.id]
  )

  const refetchFragment = useCallback(() => {
    if (isRefetching.current) return

    isRefetching.current = true

    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(
      fetchQuery(
        environment,
        graphql`
          query EventTicketTypePricingBackgroundRefetchQuery($id: ID!) {
            event: node(id: $id) {
              id
              ...EventTicketTypePricing_event
            }
          }
        `,
        { id: eventId }
      ) as any
    ).subscribe({
      complete() {
        refetch({ id: eventId }, { fetchPolicy: 'store-only' })

        isRefetching.current = false
      },
    })
  }, [environment, eventId, refetch])

  const endTick = useCallback((timer: any) => {
    clearInterval(timer)
  }, [])

  useEffect(() => {
    // refetch on open
    if (needRefetch) refetchFragment()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!needRefetch) return

    let stillMounted = true
    let timer: ReturnType<typeof setInterval> | null = null
    timer = setInterval(() => {
      if (stillMounted) {
        refetchFragment()
      }
    }, 30000) // 30s

    return () => {
      stillMounted = false
      endTick(timer)
    }
  }, [allowedLifecycleUpdates, endTick, needRefetch, refetchFragment])
  // end refetch allowedLifecycleUpdates

  const isItalian = useMemo(() => isItalianEvent(eventForm || event, locale), [event, eventForm, locale])

  const intl = useIntl()

  const { trackEvent } = useContext(trackingContext)

  const eventIsOffSale = useMemo(
    () => (eventOffSaleDate ? isPast(parseISO(eventOffSaleDate)) : false),
    [eventOffSaleDate]
  )

  const priceTiers = useMemo(
    () => (values.priceTiers || []).filter((tier) => tier?.id) as IPriceTier[],
    [values.priceTiers]
  )

  const ticketPool = useMemo(() => find(['id', values.ticketPoolId], ticketPools), [ticketPools, values.ticketPoolId])

  const tiersSales: IPriceTiersSales | null = useMemo(() => {
    if (!ticketPool?.id) return null
    if (!eventOnSaleDate || isFuture(parseISO(eventOnSaleDate))) return null
    if (!sales) return null

    const ttyBreakdown = sales.ticketTypesBreakdown || []
    const tty = find((bkdn) => (bkdn as ITtySales)?.ticketTypeId === values.id, ttyBreakdown)
    return (tty as ITtySales)?.priceTiersBreakdown || {}
  }, [eventOnSaleDate, sales, ticketPool?.id, values.id])

  const addPriceTier = useCallback(() => {
    setFieldValue(
      'priceTiers',
      concat(priceTiers, {
        ...newTier(values.faceValue),
        fees: priceTiers.length > 0 ? cloneDeep(priceTiers[priceTiers.length - 1].fees) : null,
      })
    )
    setFieldTouched('priceTiers', true, true)
    setTimeout(() => validateForm(), 0)
  }, [setFieldValue, priceTiers, values.faceValue, setFieldTouched, validateForm])

  const removePriceTier = useCallback(
    (removedIdx: number) => {
      const removedTier = priceTiers[removedIdx]
      let newTiers = rejectWithIdx((_: any, idx: number) => idx === removedIdx, priceTiers)

      if (values.ticketPoolId) {
        // Last tier should always have infinite (null) allocation for ticket pool tty
        const newFinalTier = set('allocation', null, newTiers[newTiers.length - 1])
        newTiers.splice(-1, 1, newFinalTier)
      } else if (newTiers.length === 1 && priceTiers.length === 2 && newTiers[0].allocation && removedTier.allocation) {
        newTiers = [set('allocation', (newTiers[0].allocation || 0) + (removedTier.allocation || 0), newTiers[0])]
      }

      setFieldValue('priceTiers', newTiers)

      setFieldTouched('priceTiers', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [priceTiers, values.ticketPoolId, setFieldValue, setFieldTouched, validateForm]
  )

  const handleChangeAllocation = useCallback(
    (e: any) => {
      const value = e.target.value
      if (REGEX_INTEGER.test(value) && value.length < 15) {
        setFieldValue('allocation', parseInt(value, 10))
      }
      if (value === '') {
        setFieldValue('allocation', null)
      }
      return
    },
    [setFieldValue]
  )

  const onClickPricing = useCallback(
    (e: any) => {
      const pricing = e.currentTarget.dataset['pricing']
      if (pricing === 'simple') {
        setFieldValue('fees', ((values?.fees?.length || 0) > 0 ? values?.fees : values.priceTiers?.[0]?.fees) || null)
        setFieldValue('priceTierType', null)
        setFieldValue('priceTiers', null)
      } else {
        setFieldValue('priceTierType', pricing)

        const tiers =
          values.priceTiers && values.priceTiers.length > 0
            ? values.priceTiers
            : [newTier(values.faceValue || 0), newTier(values.faceValue || 0)]

        const normalizedTiers = compose([
          (arr) =>
            arr.map((it: any, idx: number) =>
              set('fees', ((values?.fees?.length || 0) > 0 ? values?.fees : values.priceTiers?.[idx]?.fees) || null, it)
            ),
          (arr) => {
            if (!arr || arr.length === 0 || pricing !== 'allocation' || values.ticketPoolId) return arr
            return arr.map((pt: IPriceTier, idx: number) =>
              set(
                'allocation',
                idx === arr.length - 1
                  ? (values.allocation || 0) - (arr.length - 1) * Math.floor((values.allocation || 0) / arr.length)
                  : Math.floor((values.allocation || 0) / arr.length),
                pt
              )
            )
          },
          (arr) => {
            if (!arr || arr.length === 0 || pricing !== 'time') return arr
            const [first, ...rest] = arr
            return [set('time', values.onSaleDate || eventOnSaleDate || undefined, first), ...rest]
          },
          map(unset(pricing === 'time' ? 'allocation' : 'time')),
        ])(tiers)

        setFieldValue('priceTiers', normalizedTiers)
        setFieldValue('fees', null)
      }

      setFieldTouched('priceTiers', true, true)

      setTimeout(() => validateForm(), 0)
    },
    [
      eventOnSaleDate,
      setFieldTouched,
      setFieldValue,
      validateForm,
      values.allocation,
      values.faceValue,
      values?.fees,
      values.onSaleDate,
      values.priceTiers,
      values.ticketPoolId,
    ]
  )

  const tiersAllocation = useMemo(() => {
    if (values.priceTierType !== 'allocation' || !values.priceTiers) return null
    return sumBy('allocation', values.priceTiers || []) || 0
  }, [values.priceTierType, values.priceTiers])

  useEffect(() => {
    if (values.ticketPoolId) {
      if (values.allocation) {
        setFieldValue('allocation', 0)
      }
      return
    }
    if (isNil(tiersAllocation) || values.allocation === tiersAllocation) return
    setFieldValue('allocation', tiersAllocation)
  }, [setFieldValue, tiersAllocation, values.allocation, values.ticketPoolId])

  const reorderPriceTiers = useCallback(
    ({ oldIndex, newIndex }: any) => {
      setFieldValue('priceTiers', arrayMove(priceTiers, oldIndex, newIndex))
    },
    [setFieldValue, priceTiers]
  )

  const canAdd = useMemo(
    () =>
      (eventState === 'DRAFT' || noRestrictions || canAddPriceTiers) &&
      eventType !== 'STREAM' &&
      !!values.priceTierType,
    [canAddPriceTiers, eventState, eventType, noRestrictions, values.priceTierType]
  )

  const breakdownInputs: IBreakdownInput[] = useMemo(() => {
    if (values.priceTierType === null) {
      return [
        {
          // This depends on event-level things which changes we can't watch over here
          // Let's calculate it live always
          initialBreakdown: null,
          faceValue: values.faceValue || 0,
          fee: concat((eventFees || []) as IFee[], values.fees || []),
          forcePwlActive: values.priceBreakdown
            ? !isNil(values.priceBreakdown.totalWithPwl) &&
              values.priceBreakdown.totalWithPwl === values.priceBreakdown.total
            : null,
        } as IBreakdownInput,
      ]
    }

    return map(
      (pt: IPriceTier | null) =>
        ({
          // This depends on event-level things which changes we can't watch over here
          // Let's calculate it live always
          initialBreakdown: null,
          faceValue: pt?.faceValue || 0,
          fee: concat((eventFees || []) as IFee[], concat(values.fees || [], pt?.fees || [])),
          forcePwlActive: pt?.priceBreakdown
            ? !isNil(pt.priceBreakdown.totalWithPwl) && pt.priceBreakdown.totalWithPwl === pt.priceBreakdown.total
            : null,
        } as IBreakdownInput),
      (values.priceTiers || []) as IPriceTier[]
    )
  }, [eventFees, values.faceValue, values.fees, values.priceBreakdown, values.priceTierType, values.priceTiers])

  const venueIds: string[] = useMemo(() => compact(map('value', venues || [])), [venues])

  const canChangePrice =
    !eventForm?.freeEvent &&
    (eventState === 'DRAFT' ||
      noRestrictions ||
      allowedEventAction(allowedLifecycleUpdates, 'forbidden') ||
      includes(values.id, allowedLifecycleUpdates?.ticketTypes?.canUpdatePrice || []))

  const canChangePriceType = noRestrictions || (!eventIdLive && canChangePrice)

  const faceValues = useMemo(() => map('faceValue', breakdownInputs), [breakdownInputs])
  const { contracts } = useFeeContractPreview(eventId, billingPromoter?.value || null, disableUsTax, faceValues)

  const breakdownCtx = useMemo(
    () => ({
      venueIds,
      eventId: feesBehaviour === 'OVERRIDE' ? null : eventId,
      billingPromoterId: feesBehaviour === 'OVERRIDE' ? null : billingPromoter?.value || null,
      basePriceFees: feesBehaviour === 'OVERRIDE' ? basePriceFees : null,
      postFanPriceFees: feesBehaviour === 'OVERRIDE' ? postFanPriceFees : null,
      disableUsTax: !!disableUsTax,
    }),
    [basePriceFees, billingPromoter?.value, disableUsTax, eventId, feesBehaviour, postFanPriceFees, venueIds]
  )

  const { loading, priceBreakdowns } = usePriceBreakdown(breakdownInputs, breakdownCtx)

  const [breakdownAllocation, suggestionInputs] = useMemo(() => {
    const breakdownsWithFaceValue = breakdownInputs.map((bi, idx) => ({
      faceValue: bi.faceValue,
      priceBreakdown: priceBreakdowns[idx],
    }))

    if (values.priceTierType !== 'allocation') {
      return [values.allocation || 0, breakdownsWithFaceValue]
    }

    return [
      map((pt: IPriceTier) => pt.allocation || 0, (values.priceTiers || []) as IPriceTier[]),
      breakdownsWithFaceValue,
    ]
  }, [breakdownInputs, priceBreakdowns, values.allocation, values.priceTierType, values.priceTiers])

  const { potentialRevenue, priceSuggestions } = usePriceSuggestions(breakdownAllocation, suggestionInputs)

  const doRoundUp = useCallback(
    (e: any) => {
      trackEvent('round_price_clicked')

      const faceValue = Number(e.currentTarget.dataset['facevalue']) || 0
      if (!faceValue) return

      if (values.priceTierType === null) {
        setFieldValue('faceValue', faceValue)
      } else {
        const idx = Number(e.currentTarget.dataset['idx']) || 0
        setFieldValue(`priceTiers[${idx}].faceValue`, faceValue)
      }
    },
    [setFieldValue, trackEvent, values.priceTierType]
  )

  const hasRebate = useMemo(() => some((pb) => pb?.rebate, priceBreakdowns), [priceBreakdowns])

  const maxSeatsCapacity = useMaxSeatsCapacity(eventSeatingChart?.id || null)

  const [isOverallocated, totalCapacityInfo] = useMemo(() => {
    if (eventType === 'STREAM' || values.isStream || !!eventSeatingChart) return [false, null]

    const replaced = concat(values as any, ticketTypes as any) as ITicketType[]
    const ttys = uniqBy('id', compact(replaced))

    const { overallocation, venueCapacity, totalAllocationLive } = checkOverallocation(
      {
        ticketTypes: ttys,
        venues,
        countryCode,
        addressCountry,
        eventType,
      },
      locale,
      maxSeatsCapacity
    )

    const isOverallocated = overallocation > 0

    let totalCapacityInfo = null

    if (venueCapacity > 0) {
      // prettier-ignore
      totalCapacityInfo = isOverallocated
        ? intl.formatMessage(
          { id: 'venue_capacity_allocation.warning' },
          {
            number: intl.formatNumber(overallocation || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            capacity: intl.formatNumber(venueCapacity || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            b: (str: string) => <strong>{str}</strong>,
          }
        )
        : intl.formatMessage(
          { id: 'venue_capacity_allocation' },
          {
            capacity: intl.formatNumber(venueCapacity || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
            allocation: intl.formatNumber(totalAllocationLive || 0, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            number: intl.formatNumber((venueCapacity || 0) - (totalAllocationLive || 0), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            b: (str: string) => <strong>{str}</strong>,
          }
        )
    }

    return [isOverallocated, totalCapacityInfo]
  }, [
    addressCountry,
    countryCode,
    eventSeatingChart,
    eventType,
    intl,
    locale,
    maxSeatsCapacity,
    ticketTypes,
    values,
    venues,
  ])

  const tiersAllowed = eventType !== 'STREAM'

  const canShowPriceSuggestions = !!billingPromoter?.showPriceSuggestions

  const showOverrideFees = feesBehaviour === 'OVERRIDE'
  const canEditFees = !readOnly

  const isFreeTicket = useMemo(
    () =>
      (values.priceTiers?.length || 0) > 0
        ? some((pt) => (pt?.faceValue || 0) === 0, values.priceTiers)
        : (values.faceValue || 0) === 0,
    [values.faceValue, values.priceTiers]
  )

  const showAppendToContractFees =
    !isFreeTicket && feesBehaviour === 'APPEND_TO_CONTRACT' && hasPermission('read:balances')

  const hasFixedFeesEventLevel = useMemo(
    () => some((fee: IFee | null) => fee?.unit === 'fixed' && (fee.amount || 0) > 0, feeOverrides),
    [feeOverrides]
  )

  const handleSalesLimitChange = useCallback(
    (e: any) => {
      const value = parseInt(e.target.value, 10)
      setFieldValue('salesLimit', !isNaN(value) ? Math.abs(value) : null)
    },
    [setFieldValue]
  )

  const togglePriceHidden = useCallback(
    () => setFieldValue('priceHidden', !values.priceHidden),
    [setFieldValue, values.priceHidden]
  )

  const isOnSale = useMemo(
    () => isPast(parseISO(values.onSaleDate || eventOnSaleDate || '')),
    [eventOnSaleDate, values.onSaleDate]
  )

  const salesLimitFormField = (
    <FormField
      min={0}
      type="number"
      name="salesLimit"
      onBlur={handleBlur}
      value={values.salesLimit}
      onChange={handleSalesLimitChange}
      error={errors.salesLimit}
      placeholder={
        ticketPool?.maxAllocation
          ? intl.formatMessage(
            { id: 'new_event.tickets.ticket_type_edit.sales_limit.placeholder' },
            {
              allocation: ticketPool?.maxAllocation,
            }
          )
          : undefined
      }
      hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.sales_limit.hint' })}
      label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.sales_limit.label' })}
      disabled={!hasFeatureFlag('ticketPools')}
    />
  )

  return (
    <>
      {tiersAllowed && (
        <FormRow columnOnMobile>
          <TabMenu>
            <TabMenuItem
              disabled={!canChangePriceType}
              forceShowTooltip={!!eventForm?.freeEvent}
              data-pricing="simple"
              active={!values.priceTierType}
              onClick={onClickPricing}
              title={
                eventForm?.freeEvent &&
                intl.formatMessage({
                  id: 'new_event.tickets.ticket_type_edit.pricing.free_event.tooltip',
                })
              }
            >
              {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.pricing.options.no_tiers' })}
            </TabMenuItem>
            {(!eventSeatingChart || !values.reservedSeating || values.isStream) && (
              <TabMenuItem
                disabled={!canChangePriceType}
                forceShowTooltip={!!eventForm?.freeEvent}
                data-pricing="allocation"
                title={
                  eventForm?.freeEvent
                    ? intl.formatMessage({
                      id: 'new_event.tickets.ticket_type_edit.pricing.free_event.tooltip',
                    })
                    : intl.formatMessage({
                      id: 'new_event.tickets.ticket_type_edit.pricing.options.allocation_tiers.tooltip',
                    })
                }
                active={values.priceTierType === 'allocation'}
                onClick={onClickPricing}
              >
                {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.pricing.options.allocation_tiers' })}
              </TabMenuItem>
            )}
            <TabMenuItem
              disabled={!canChangePriceType}
              forceShowTooltip={!!eventForm?.freeEvent}
              data-pricing="time"
              title={
                eventForm?.freeEvent
                  ? intl.formatMessage({
                    id: 'new_event.tickets.ticket_type_edit.pricing.free_event.tooltip',
                  })
                  : intl.formatMessage({
                    id: 'new_event.tickets.ticket_type_edit.pricing.options.time_tiers.tooltip',
                  })
              }
              active={values.priceTierType === 'time'}
              onClick={onClickPricing}
            >
              {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.pricing.options.time_tiers' })}
            </TabMenuItem>
          </TabMenu>
        </FormRow>
      )}

      {children}

      {(!values.priceTierType || eventType === 'STREAM') && (
        <>
          <FormRow columnOnMobile>
            <FormField
              name="faceValue"
              currency={currency}
              label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price.label' })}
              value={values.faceValue}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.faceValue}
              disabled={!canChangePrice}
              required
              help={
                eventForm?.freeEvent &&
                intl.formatMessage(
                  { id: 'new_event.tickets.ticket_type_edit.price.free_event.help' },
                  {
                    amount: intl.formatNumber(0, {
                      ...CURRENCY(0, eventForm?.costCurrency),
                      minimumFractionDigits: 2,
                    }),
                  }
                )
              }
            />
            {ticketPool && salesLimitFormField}
            {(!eventSeatingChart || !values.reservedSeating || !values.reservedSeating || values.isStream) &&
              !values.ticketPoolId && (
              <FormField
                name="allocation"
                label={
                  ticketPool
                    ? intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.allocation_limit.label' })
                    : intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.allocation.label' })
                }
                control={LimitedInput}
                limit={ticketPool ? ticketPool.maxAllocation : undefined}
                type="tel"
                value={isNil(values.allocation) ? '' : values.allocation}
                onChange={handleChangeAllocation}
                onBlur={handleBlur}
                error={errors.allocation}
                hint={
                  !errors.allocation &&
                  !ticketPool &&
                  (!isOverallocated ? totalCapacityInfo : <Red>{totalCapacityInfo}</Red>)
                }
                disabled={
                  eventState !== 'DRAFT' &&
                  !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
                  !noRestrictions
                }
                required
              />
            )}
          </FormRow>
        </>
      )}

      {showAppendToContractFees &&
        (values.fees?.length || 0) > 0 &&
        chunk(3, values.fees || []).map((ch: Array<IFee | null>, cidx) => (
          <FormRow key={cidx} columnOnMobile>
            {ch.map((fee, index) => (
              <FormField
                name={`fees[${cidx * 3 + index}].amount`}
                key={fee?.type}
                currency={currency}
                label={intl.formatMessage({ id: `fees.${fee?.type}` })}
                value={get(`fees[${cidx * 3 + index}].amount`, values)}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={
                  eventState !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !noRestrictions
                }
                required
              />
            ))}
          </FormRow>
        ))}

      {!values.ticketPoolId &&
        values.priceTierType === 'time' &&
        (!eventSeatingChart || !values.reservedSeating || values.isStream) && (
        <FormRow columnOnMobile>
          <FormField
            name="allocation"
            label={
              ticketPool
                ? intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.allocation_limit.label' })
                : intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.allocation.label' })
            }
            control={LimitedInput}
            limit={ticketPool ? ticketPool.maxAllocation : undefined}
            type="tel"
            value={isNil(values.allocation) ? '' : values.allocation}
            onChange={handleChangeAllocation}
            onBlur={handleBlur}
            error={errors.allocation}
            hint={
              !errors.allocation &&
              !ticketPool &&
              (!isOverallocated ? totalCapacityInfo : <Red>{totalCapacityInfo}</Red>)
            }
            disabled={
              eventState !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !noRestrictions
            }
            required
          />
        </FormRow>
      )}

      {!!values.priceTierType && (
        <>
          <FormRow>
            {intl.formatMessage({
              id:
                values.priceTierType === 'allocation'
                  ? 'new_event.tickets.ticket_type_edit.allocation.hint'
                  : 'new_event.tickets.ticket_type_edit.time.hint',
            })}
          </FormRow>

          {ticketPool && <FormRow>{salesLimitFormField}</FormRow>}
          <EventTicketTypePriceTiers
            salesLimit={values.salesLimit}
            currency={currency || undefined}
            tiers={priceTiers}
            tiersSales={tiersSales}
            priceTierType={values.priceTierType}
            handleChange={handleChange}
            onRemove={removePriceTier}
            setFieldValue={setFieldValue}
            validateForm={validateForm}
            timezoneName={timezoneName || undefined}
            handleBlur={handleBlur}
            ticketPool={ticketPool as ITicketPool}
            errors={errors}
            touched={touched}
            canReorder={eventState === 'DRAFT' || noRestrictions}
            canRemove={eventState === 'DRAFT' || noRestrictions}
            canEditFirstTier={
              eventState === 'DRAFT' ||
              noRestrictions ||
              allowedEventAction(allowedLifecycleUpdates, 'forbidden') ||
              (values.priceTierType === 'time' && allowedEventAction(allowedLifecycleUpdates, 'onSaleDate'))
            }
            canEdit={
              eventState === 'DRAFT' ||
              noRestrictions ||
              allowedEventAction(allowedLifecycleUpdates, 'forbidden') ||
              (values.priceTierType === 'time' &&
                allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeTierNames'))
            }
            canEditAllocation={!eventIsOffSale}
            canEditPrice={canChangePrice}
            canRename={
              eventState === 'DRAFT' ||
              noRestrictions ||
              allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeTierNames')
            }
            lockAxis="y"
            useDragHandle
            onSortEnd={reorderPriceTiers}
            ttyOnSaleDate={values.onSaleDate || eventOnSaleDate}
            sortOnlyNew={
              eventState !== 'DRAFT' &&
              !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
              !noRestrictions &&
              canAddPriceTiers
            }
            isItalian={isItalian}
            isStream={values.isStream || eventType === 'STREAM'}
            showAppendToContractFees={showAppendToContractFees}
          >
            <TiersFooter>
              {values.priceTierType === 'allocation' && !values.ticketPoolId ? (
                <TotalAllocation hint={!isOverallocated ? totalCapacityInfo : <Danger>{totalCapacityInfo}</Danger>}>
                  <span>{intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.total_allocation' })}</span>
                  <strong>
                    {intl.formatNumber(values.allocation || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </strong>
                </TotalAllocation>
              ) : (
                <div />
              )}

              {canAdd ? (
                <StyledListAddButton
                  label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.add_price_tier_button' })}
                  onClick={addPriceTier}
                  data-id="addPriceTier"
                />
              ) : (
                <div />
              )}
            </TiersFooter>
          </EventTicketTypePriceTiers>
        </>
      )}

      {user.diceStaff && showOverrideFees && (
        <FormRow>
          <CollapsibleFees
            label={intl.formatMessage({ id: 'fees' })}
            initialCollapsed={!(isFreeTicket && hasFixedFeesEventLevel)}
            dataId="ticketsFees"
            dice
          >
            {isFreeTicket && hasFixedFeesEventLevel && (
              <FormRow data-test-id="freeTicketsFeeWarning">
                <SWarning fullWidth icon="warning" color={color.error}>
                  <strong>
                    {intl.formatMessage({
                      id: 'fee_overrides.free_tickets_error_tty.title',
                    })}
                  </strong>
                  {intl.formatMessage({
                    id: 'fee_overrides.free_tickets_error_tty.text',
                  })}
                </SWarning>
              </FormRow>
            )}

            {contracts && contracts.length > 0 && canEditFees && (
              <FormRow>
                <FormGroup label={intl.formatMessage({ id: 'new_event.tickets.contract_fees' })}>
                  {contracts.map(
                    (it, idx) =>
                      it && (
                        <EventFeeContractPreview key={idx} contract={it} currency={currency || 'GBP'}>
                          {contracts.length > 1 ? priceTiers[idx]?.name : null}
                        </EventFeeContractPreview>
                      )
                  )}
                </FormGroup>
              </FormRow>
            )}

            {((values.fees && values.fees.length > 0) || canEditFees) && contracts?.[0] && (
              <FormRow>
                <FormGroup label={`${intl.formatMessage({ id: 'ticket_type' })} ${intl.formatMessage({ id: 'fees' })}`}>
                  <EventOverrideFees
                    contract={contracts[0]}
                    currency={currency}
                    fees={values.fees}
                    name="fees"
                    readOnly={!canEditFees}
                    formikContext={formikContext}
                  />
                </FormGroup>
              </FormRow>
            )}
            {!!values.priceTierType &&
              priceTiers.length > 0 &&
              priceTiers.map(
                (tier, idx) =>
                  ((tier.fees && tier.fees.length > 0) || canEditFees) && contracts?.[idx] && (
                    <FormRow key={tier.id}>
                      <FormGroup
                        label={`${
                          tier.name ||
                          intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.unnamed_tier' }, { idx })
                        } ${intl.formatMessage({ id: 'fees' })}`}
                      >
                        <EventOverrideFees
                          contract={contracts[idx]}
                          currency={currency}
                          fees={tier.fees}
                          name={`priceTiers[${idx}].fees`}
                          readOnly={!canEditFees}
                          formikContext={formikContext}
                        />
                      </FormGroup>
                    </FormRow>
                  )
              )}
          </CollapsibleFees>
        </FormRow>
      )}

      {canShowPriceSuggestions && canChangePrice && priceSuggestions.length > 0 && (
        <FormRow>
          <Warning>
            {priceSuggestions.map((priceSuggestion) => (
              <div key={priceSuggestion.idx}>
                {intl.formatMessage(
                  { id: 'new_event.tickets.ticket_type_edit.round_price_warning' },
                  {
                    a: (str: string) => (
                      <a
                        data-facevalue={priceSuggestion.friendlyFaceValue}
                        data-idx={priceSuggestion.idx}
                        onClick={doRoundUp}
                      >
                        {str}
                      </a>
                    ),
                    price: intl.formatNumber(
                      priceSuggestion.friendlyTotal / 100,
                      CURRENCY(priceSuggestion.friendlyTotal, currency)
                    ),
                  }
                )}
                {values.priceTierType !== null && (
                  <>
                    <br />
                    <NoBreak>
                      {`(${
                        priceTiers[priceSuggestion.idx]?.name ||
                        intl.formatMessage(
                          { id: 'new_event.tickets.ticket_type_edit.unnamed_tier' },
                          { idx: priceSuggestion.idx }
                        )
                      })`}
                    </NoBreak>
                  </>
                )}
              </div>
            ))}
          </Warning>
        </FormRow>
      )}

      {canShowPriceSuggestions && (
        <>
          {values.priceTierType === null ? (
            take(1, priceBreakdowns).map((priceBreakdown, idx) => (
              <PriceInfo key={idx} isLoading={loading}>
                <PriceBreakdown>
                  <div>
                    {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_suggestion.keep' })}
                    {': '}
                    {intl.formatNumber(
                      (priceBreakdown?.faceValue || 0) / 100,
                      CURRENCY(priceBreakdown?.faceValue || 0, currency)
                    )}
                  </div>
                  <div>
                    {intl.formatMessage({ id: 'fees' })}
                    {': '}
                    <FeeBreakdownTooltip
                      trigger={intl.formatNumber(
                        ((priceBreakdown?.total || 0) - (priceBreakdown?.faceValue || 0)) / 100,
                        CURRENCY((priceBreakdown?.total || 0) - (priceBreakdown?.faceValue || 0), currency)
                      )}
                      currency={currency}
                      priceBreakdown={priceBreakdown}
                      hasRebate={hasRebate}
                    />
                  </div>
                  <div>
                    {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_suggestion.fan_price' })}
                    {': '}
                    <PriceBreakdownTooltip currency={currency} priceBreakdown={priceBreakdown} />
                  </div>
                </PriceBreakdown>
                {!values.ticketPoolId && (
                  <PotentialRevenue>
                    {intl.formatMessage({
                      id: 'new_event.tickets.ticket_type_edit.price_suggestion.potential_revenue',
                    })}
                    {':'}
                    <span>{intl.formatNumber(potentialRevenue / 100, CURRENCY(potentialRevenue, currency))}</span>
                  </PotentialRevenue>
                )}
              </PriceInfo>
            ))
          ) : (
            <PriceInfo isLoading={loading}>
              <StyledTable hasRebate={hasRebate}>
                <StyledTableHeader>
                  <Row>
                    <Cell>
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_tier.name.label' })}
                    </Cell>
                    <Cell textAlign="right">
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_suggestion.keep' })}
                    </Cell>
                    <Cell textAlign="right">{intl.formatMessage({ id: 'fees' })}</Cell>
                    <Cell textAlign="right">
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_suggestion.fan_price' })}
                    </Cell>
                  </Row>
                </StyledTableHeader>
                <StyledTableBody>
                  {priceBreakdowns.map((priceBreakdown, idx) => (
                    <Row key={idx}>
                      <Cell>
                        {priceTiers[idx]?.name ||
                          intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.unnamed_tier' }, { idx })}
                      </Cell>
                      <Cell textAlign="right">
                        {intl.formatNumber(
                          (priceBreakdown?.faceValue || 0) / 100,
                          CURRENCY(priceBreakdown?.faceValue || 0, currency)
                        )}
                      </Cell>
                      <Cell textAlign="right">
                        <FeeBreakdownTooltip
                          trigger={intl.formatNumber(
                            ((priceBreakdown?.total || 0) - (priceBreakdown?.faceValue || 0)) / 100,
                            CURRENCY((priceBreakdown?.total || 0) - (priceBreakdown?.faceValue || 0), currency)
                          )}
                          currency={currency}
                          priceBreakdown={priceBreakdown}
                          hasRebate={hasRebate}
                        />
                      </Cell>
                      <Cell textAlign="right">
                        <PriceBreakdownTooltip currency={currency} priceBreakdown={priceBreakdown} />
                      </Cell>
                    </Row>
                  ))}
                </StyledTableBody>
                {!values.ticketPoolId && (
                  <StyledTableFooter>
                    <Row>
                      <Cell textAlign="right" colSpan={hasRebate ? 5 : 4}>
                        <PotentialRevenue>
                          {intl.formatMessage({
                            id: 'new_event.tickets.ticket_type_edit.price_suggestion.potential_revenue',
                          })}
                          {':'}
                          <span>{intl.formatNumber(potentialRevenue / 100, CURRENCY(potentialRevenue, currency))}</span>
                        </PotentialRevenue>
                      </Cell>
                    </Row>
                  </StyledTableFooter>
                )}
              </StyledTable>
            </PriceInfo>
          )}
        </>
      )}

      {hasPermission('announce_without_prices:event') && (
        <FormRow>
          <div>
            {isOnSale && values.priceHidden && (
              <AlertBox icon="warning" color={color.error} fullWidth className="mb-md">
                {intl.formatMessage({
                  id: 'new_event.tickets.ticket_types.price_hidden.on_sale.warning',
                })}
              </AlertBox>
            )}
            <SwitchField
              label={intl.formatMessage({
                id: 'new_event.tickets.ticket_type_edit.price_hidden.label',
              })}
              hint={intl.formatMessage({
                id: 'new_event.tickets.ticket_type_edit.price_hidden.hint',
              })}
              title={
                isOnSale && !values.priceHidden
                  ? intl.formatMessage({
                    id: 'new_event.tickets.ticket_type_edit.price_hidden.title',
                  })
                  : null
              }
              name="priceHidden"
              checked={!!values.priceHidden}
              disabled={readOnly || (isOnSale && !values.priceHidden)}
              onChange={togglePriceHidden}
            />
          </div>
        </FormRow>
      )}
    </>
  )
}

export default memo(EventTicketTypePricing)
