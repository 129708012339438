import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { FormikContextType, useFormik, yupToFormErrors } from 'formik'
import { compact, compose, filter, find, get, getOr, isNil, map, reject, set, sumBy, toPairs } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { addSeconds, differenceInSeconds, formatISO, parseISO } from 'date-fns'

import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../components/Modal'
import { Divider, Form, FormRow, FrameContainer } from '../../../components/Form'
import FormField from '../../../components/FormField'
import FormGroup from '../../../components/FormGroup'
import { color, font } from '../../../utils/variables'
import { localeContext } from '../../../context/locale'
import { authContext } from '../../../context/auth'
import { allowedEventAction } from '../services/allowedEventAction'
import unwrapId from '../../../utils/unwrapId'
import { trackingContext } from '../../../context/tracking'
import MarkdownEditor from '../../../components/MarkdownEditor'
import { parseMarkdown, renderMarkdown } from '../../../utils/markdown'
import { TitleTooltip, TooltipHelpIcon } from '../../../components/Tooltip'
import Svg from '../../../components/Svg'
import { EventTicketTypeModal_event$key } from '../../../__generated__/EventTicketTypeModal_event.graphql'
import EventTicketTypeIconInput from './EventTicketTypeIconInput'
import EventTicketTypeIncrements from './EventTicketTypeIncrements'
import EventTicketTypePricing from './EventTicketTypePricing'
import EventTicketTypeDoorPricing from './EventTicketTypeDoorPricing'
import IEventFormTickets, { ITicketType } from '../types/Tickets'
import TagsInput from '../../../components/TagsInput'
import PermissionCheck from '../../../components/PermissionCheck'
import { isItalianEvent } from '../../../utils/isCountryEvent'
import { isNew, isSaved } from '../../../utils/entityStatus'
import SwitchField from '../../../components/SwitchField'
import { PHYSICAL_ICONS, VIRTUAL_ICONS } from '../../../constants/ticketTypeIcons'
import { countSeats } from '../../../utils/seats'
import Warning from '../../../components/Warning'
import { TicketTypeSchema } from '../validation/Tickets'
import useTicketTypeSeating from '../hooks/useTicketTypeSeating'
import EventTicketTypeTimeline from './EventTicketTypeTimeline'
import { QR_OFFSET_LIVE, QR_OFFSET_STREAM } from '../services/getDefaultEvent'
import DiceBadge from '../../../components/DiceBadge'
import EventTicketTypeNts from './EventTicketTypeNts'
import EventTicketTypeAdminSettings from './EventTicketTypeAdminSettings'
import AlertBox from '../../../components/AlertBox'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import EventTicketTypePaymentMethods from './EventTicketTypePaymentMethods'

interface IProps {
  event: EventTicketTypeModal_event$key | null
  eventForm?: IEventFormTickets
  ticketTypeId: string
  onClose: () => void
  onSave: (ticketType: ITicketType) => void
  readOnly?: boolean
}

const Icons = styled.div`
  margin-bottom: -8px;
`

const HelpIcon = styled(Svg)`
  margin: 3px 0 -3px 6px;
  background-color: ${color.grey};
  color: ${color.white};
  border-radius: 50%;
  cursor: help;
`

interface ICounterProps {
  count: number
  maxCount: number
}

const Counter = styled.div<ICounterProps>`
  position: absolute;
  top: 0;
  right: 0;
  white-space: nowrap;
  font-size: ${font.size.sm}px;
  ${({ count, maxCount }) => (count > maxCount ? `color: ${color.error}` : undefined)}
`

const MarginH3 = styled.h3`
  && {
    font-size: ${font.size.md}px;
    margin-bottom: 20px;
    margin-top: 52px;
  }
`

const HighlightBox = styled.div`
  background-color: ${color.palegrey};
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
`

const EventTicketTypeModal: FC<React.PropsWithChildren<IProps>> = ({
  event: eventKey,
  eventForm,
  ticketTypeId,
  onClose,
  onSave,
  readOnly: externalReadOnly,
}) => {
  const event = useFragment(
    graphql`
      fragment EventTicketTypeModal_event on Event {
        ...EventTicketTypeIncrements_event
        ...EventTicketTypePricing_event
        ...EventTicketTypeDoorPricing_event
        ...EventTicketTypeTimeline_event

        id
        eventIdLive
        eventType
        diceTvPlatform
        state
        costCurrency
        timezoneName
        date
        endDate
        announceDate
        onSaleDate
        offSaleDate
        maxTicketsLimit
        addressCountry
        countryCode

        allowedActions {
          minorUpdate
          managePromotions
          manageTickets
        }

        flags {
          seated
          shoppingCart
          unicorn
        }

        venueSchedules {
          id
          name
        }

        ticketPools {
          id
          name
          maxAllocation
        }

        ticketTypes(doorSalesOnly: false, includeArchived: true) {
          requiresOtherTypeIds
          id
          name
          archived
          hidden

          ticketPoolId
          salesLimit
          additionalPaymentMethods
          activateCodeDateOffset
          venueScheduleId
          codeLocked
          startDate
          endDate
          isStream
          description
          allocation
          faceValue
          requiresAddress
          presale
          icon
          increment
          maximumIncrements
          doorSalesPrice
          doorSalesEnabled
          doorSalesPriceTaxed
          doorSalesTax
          announceDate
          onSaleDate
          offSaleDate
          attractiveSeatingAreaType
          attractivePriceType
          seatmapUrl
          priceTierType
          streamLink
          externalSkus
          priceHidden

          allowSeatChange
          reservedSeating
          reservedSeatingType
          seatCategories {
            id
            name
            seatsIoKey
          }

          fees {
            amount
            type
            unit
            applicable

            split {
              amount
              destination
              unit
            }
          }

          priceBreakdown {
            breakdown {
              computed
              type
              applicable
              split {
                amount
                computed
                destination
              }
            }
            total
            totalWithPwl
            totalWithoutPwl
            faceValue
            split {
              computed
              destination
            }
            friendlyPrice
            friendlyFaceValue
          }

          priceTiers {
            id
            name
            doorSalesPrice
            doorSalesPriceTaxed
            faceValue
            allocation
            time
            attractivePriceType
            fees {
              amount
              type
              unit
              applicable

              split {
                amount
                destination
                unit
              }
            }
            priceBreakdown {
              breakdown {
                computed
                type
                applicable
                split {
                  amount
                  computed
                  destination
                }
              }
              total
              totalWithPwl
              totalWithoutPwl
              faceValue
              split {
                computed
                destination
              }
              friendlyPrice
              friendlyFaceValue
            }
          }
        }

        attractiveStatus {
          status
        }

        eventSeatingChart {
          id
          seatsIoEventReport(group: BY_CATEGORY_KEY)
        }

        venues {
          addressCountry
          countryCode
          capacity
        }

        attractiveFields {
          compatibilityAe
          siaeGenreType
          integrationDisabled

          taxFree
        }

        sales {
          ticketTypesBreakdown {
            ticketTypeId
            totalSold
            totalReserved
            totalAppSold
            totalPosSold
            totalTerminalSold
            ticketType {
              id
              ticketPoolId
            }
          }
        }

        allowedLifecycleUpdates {
          ticketTypes {
            canAdd
            canChangeAllocation
            canChangeDoorSalesEnabled
            canChangeIcon
            canChangeIncrements
            canChangeOffSaleDate
            canChangeOnSaleDate
            canChangeOrder
            canChangeSeatmap
            canChangeTierNames
            canDelete
            canHide
            canUpdate
            canUpdatePrice
            canChangeExternalSkus
          }
        }
      }
    `,
    eventKey
  )

  const {
    id: eventId,
    eventIdLive,
    eventType,
    allowedLifecycleUpdates: realAllowedLifecycleUpdates,
    state,
    diceTvPlatform,
    timezoneName: timezone,
    date: eventDate,
    endDate: eventEndDate,
    announceDate: eventAnnounceDate,
    onSaleDate: eventOnSaleDate,
    offSaleDate: eventOffSaleDate,
    maxTicketsLimit,
    attractiveFields,
    eventSeatingChart,
    ticketTypes,
    venueSchedules,
    attractiveStatus,
    ticketPools,
    flags,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = (eventForm || event)!

  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { user, account, hasPermission } = useContext(authContext)
  const { trackEvent } = useContext(trackingContext)

  const readOnly = isNil(externalReadOnly) ? !event?.allowedActions?.minorUpdate : externalReadOnly
  const isUnicornActive = get('unicorn.active', flags)
  const isShoppingCartActive = get('shoppingCart.active', flags)

  const isLiveAndCanAddTty =
    !readOnly && state !== 'DRAFT' && allowedEventAction(realAllowedLifecycleUpdates, 'ticketTypes', 'canAdd')

  const ticketType: ITicketType = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => find(['id', ticketTypeId], ticketTypes || [])! as ITicketType,
    [ticketTypeId, ticketTypes]
  )

  const noRestrictions = useMemo(() => !!isLiveAndCanAddTty && !isSaved(ticketType), [isLiveAndCanAddTty, ticketType])
  const allowedLifecycleUpdates = noRestrictions ? null : realAllowedLifecycleUpdates

  const streamingTicketsIntegrationDisabled = !!getOr(true, 'streamingTicketsIntegrationDisabled', attractiveFields)

  const isItalian = useMemo(() => isItalianEvent(eventForm || event, locale), [event, eventForm, locale])

  const isForbiddenCustomDates = useMemo(() => {
    const ttys = reject('archived', ticketTypes || [])

    return (
      state !== 'DRAFT' &&
      !isNil(realAllowedLifecycleUpdates) &&
      ttys.length < 2 &&
      !allowedEventAction(allowedLifecycleUpdates, 'forbidden')
    )
  }, [allowedLifecycleUpdates, realAllowedLifecycleUpdates, state, ticketTypes])

  const onSubmit = useCallback(
    (values: ITicketType) => {
      const trackData = {
        event_id: unwrapId(eventId),
        event_id_live: eventIdLive,
        ticket_type_id: unwrapId(ticketType.id),
      }

      trackEvent('ticket_type_saved', trackData)

      let result = values

      if (!!eventSeatingChart && !values.isStream && values.reservedSeating) {
        const catKeys = new Set(map('seatsIoKey', values.seatCategories))

        const report = eventSeatingChart.seatsIoEventReport
        const allocation =
          compose([
            sumBy(([catKey, seats]) => (catKeys.has(catKey) ? countSeats(seats) : 0)),
            reject(([_catKey, seats]) => seats.length === 0),
            toPairs,
          ])(report) || 0

        result = set('allocation', allocation, values)
      }

      onSave(result)
    },
    [eventId, eventIdLive, eventSeatingChart, onSave, ticketType.id, trackEvent]
  )

  const onCancel = useCallback(() => {
    onClose(/* no args! */)
  }, [onClose])

  const isExisting = !isNew(ticketType)

  const initialValues = useMemo(
    () => ({
      ...ticketType,
      descriptionDraft: parseMarkdown(ticketType.description),
      event: {
        sales: eventForm?.sales || event?.sales,
        ticketPools: eventForm?.ticketPools || event?.ticketPools,
      },
      requiresOtherTypeIdsEnabled:
        (ticketType.requiresOtherTypeIds && ticketType.requiresOtherTypeIds?.length > 0) || false,
    }),
    [event?.sales, event?.ticketPools, eventForm?.sales, eventForm?.ticketPools, ticketType]
  )

  const validate = useCallback(
    async (tt: any) => {
      try {
        await TicketTypeSchema.validate(tt, {
          abortEarly: false,
          context: { diceStaff: user.diceStaff, dicePartner: user.dicePartner, ...(eventForm || event) },
        })
      } catch (err) {
        return yupToFormErrors(err)
      }
    },
    [event, eventForm, user.dicePartner, user.diceStaff]
  )

  const formik = useFormik<typeof initialValues & { limit?: number; requiresOtherTypeIdsEnabled?: boolean }>({
    initialValues,
    onSubmit,
    validate,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: isExisting,
  })

  const {
    values,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleBlur,
    isValid,
    touched,
    errors,
    validateForm,
    setFieldTouched,
    setValues,
  } = formik

  const forceSubmit = useCallback(() => {
    onSubmit(values)
  }, [onSubmit, values])

  const setDescriptionDraft = useCallback(
    (draft: Draft.EditorState) => {
      setFieldValue('descriptionDraft', draft)
      setFieldValue('description', renderMarkdown(draft))
    },
    [setFieldValue]
  )

  const [customTicketsPerOrder, setCustomTicketsPerOrder] = useState(
    values.maximumIncrements !== maxTicketsLimit || values.increment !== 1
  )
  const changeCustomTicketsPerOrder = useCallback(
    (e: any) => {
      const isCustom = e.target.checked
      if (!isCustom) {
        setFieldValue('maximumIncrements', maxTicketsLimit)
        setFieldValue('increment', 1)
      }
      setCustomTicketsPerOrder(isCustom)
    },
    [maxTicketsLimit, setFieldValue]
  )

  const onChangeIcon = useCallback(
    (icon: string | null) => {
      setFieldValue('icon', icon)
      if (icon && (state === 'DRAFT' || noRestrictions)) {
        setFieldValue('name', intl.formatMessage({ id: `new_event.tickets.ticket_type_edit.icon.tooltip.${icon}` }))
      }
    },
    [intl, noRestrictions, setFieldValue, state]
  )

  const iconSet = useMemo(
    () => (eventType === 'STREAM' || values.isStream ? VIRTUAL_ICONS : PHYSICAL_ICONS),
    [eventType, values.isStream]
  )

  const handleChangeRequiresOtherTypeIds = useCallback(
    (e: any) => {
      const checked = e.target.checked
      setFieldValue('requiresOtherTypeIdsEnabled', checked)
      if (!checked) {
        setFieldValue('requiresOtherTypeIds', [])
      }
      setFieldTouched('requiresOtherTypeIds', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, validateForm, setFieldTouched]
  )

  const toggleStream = useCallback(
    (e: any) => {
      if (values.isStream) {
        setFieldValue('streamLink', null)
        setFieldValue('streamEmbedCode', null)
        if (eventSeatingChart?.id) {
          setFieldValue('reservedSeating', true)
        }
        setFieldValue('activateCodeDateOffset', QR_OFFSET_LIVE)
      } else {
        setFieldValue('attractiveSeatingAreaType', null)
        setFieldValue('attractivePriceType', null)
        setFieldValue('reservedSeating', false)
        setFieldValue('seatCategories', null)
        setFieldValue('activateCodeDateOffset', QR_OFFSET_STREAM)
      }

      const isStreamNow = eventType === 'STREAM' || !values.isStream

      const newIconSet = isStreamNow ? VIRTUAL_ICONS : PHYSICAL_ICONS
      if (newIconSet !== iconSet) {
        setFieldValue('icon', newIconSet[0])
      }

      if (
        (values.name === intl.formatMessage({ id: 'new_event.defaults.ticket_type.name' }) && isStreamNow) ||
        (values.name === intl.formatMessage({ id: 'new_event.defaults.ticket_type.name_streaming' }) && !isStreamNow)
      ) {
        setFieldValue(
          'name',
          isStreamNow
            ? intl.formatMessage({ id: 'new_event.defaults.ticket_type.name_streaming' })
            : intl.formatMessage({ id: 'new_event.defaults.ticket_type.name' })
        )
      }

      handleChange(e)
    },
    [eventSeatingChart?.id, eventType, handleChange, iconSet, intl, setFieldValue, values.isStream, values.name]
  )

  const setExternalSkus = useCallback(
    (skus: any) => {
      setFieldValue('externalSkus', skus)
    },
    [setFieldValue]
  )

  const {
    allSeatCategoryOptions,
    selectedSeatCategoryOptions,
    changeSeatCategories,

    handleChangeSeating,
  } = useTicketTypeSeating(formik, eventSeatingChart)

  const activateCodeDate = useMemo(
    () => (eventDate ? formatISO(addSeconds(parseISO(eventDate), values.activateCodeDateOffset || 0)) : null),
    [eventDate, values.activateCodeDateOffset]
  )

  const setOffsetDate = useCallback(
    (name: string, value: string | null) => {
      if (!value || !eventDate) {
        setFieldValue(name, null)
        return
      }
      const evDate = parseISO(eventDate)
      const newDate = parseISO(value)
      const newOffset = differenceInSeconds(newDate, evDate)

      setFieldValue(name, newOffset)
    },
    [eventDate, setFieldValue]
  )

  const ticketPoolOptions = useMemo(
    () => compact(map((pool) => pool && { label: pool.name, value: pool.id }, ticketPools)),
    [ticketPools]
  )

  const setTicketPool = useCallback(
    (value: any) => {
      setFieldValue('ticketPoolId', value)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, validateForm]
  )

  const isStreamTty = eventType === 'STREAM' || values.isStream

  const isNts =
    isItalian && !(isStreamTty && streamingTicketsIntegrationDisabled) && !attractiveFields?.integrationDisabled

  const [addEntryTime, setAddEntryTime] = useState<boolean>(!!(values.startDate || values.endDate))

  const handleAddEntryTimeChange = useCallback(
    (e: any) => {
      const checked = e.target.checked
      setAddEntryTime(checked)
      if (!checked) {
        setFieldValue('startDate', null)
        setFieldValue('endDate', null)
      }
    },
    [setFieldValue]
  )

  const formattedEventStartDate = useMemo(() => {
    return eventDate
      ? intl.formatDate(eventDate, {
        ...DATETIME_FORMATS.LONG(locale),
        timeZone: timezone || user.timezoneName,
      })
      : '??'
  }, [eventDate, intl, locale, timezone, user.timezoneName])

  const formattedEventEndDate = useMemo(() => {
    return eventEndDate
      ? intl.formatDate(eventEndDate, {
        ...DATETIME_FORMATS.LONG(locale),
        timeZone: timezone || user.timezoneName,
      })
      : '??'
  }, [eventEndDate, intl, locale, timezone, user.timezoneName])

  const [sameAsEventDates, setSameAsEventDates] = useState<boolean>(
    !(!!values.announceDate || !!values.onSaleDate || !!values.offSaleDate)
  )

  const toggleSameAsEventDates = useCallback(() => {
    if (!sameAsEventDates) {
      setValues({
        ...values,
        announceDate: null,
        onSaleDate: null,
        offSaleDate: null,
      })
    } else {
      setValues({
        ...values,
        announceDate: eventAnnounceDate ?? null,
        onSaleDate: eventOnSaleDate ?? null,
        offSaleDate: eventOffSaleDate ?? null,
      })
    }
    setSameAsEventDates(!sameAsEventDates)
  }, [eventAnnounceDate, eventOffSaleDate, eventOnSaleDate, sameAsEventDates, setValues, values])

  const ticketTypesOptions = useMemo(() => {
    const filteredTicketTypes = filter(
      (tty) => tty && typeof tty === 'object' && values.id !== tty.id && !tty.id.startsWith('new-'),
      ticketTypes || []
    )

    return [
      ...map(
        (tty) => tty && typeof tty === 'object' && { label: tty.name, value: tty.id, ...tty },
        filteredTicketTypes
      ),
      {
        label: (
          <TicketTypeOptionsHint>
            {intl.formatMessage({
              id: 'new_event.tickets.ticket_type_edit.required_ticket_types.options_hint',
            })}
          </TicketTypeOptionsHint>
        ),
        value: null,
        id: null,
      },
    ]
  }, [intl, values.id, ticketTypes])

  const requiresOtherTypeIdsValue = useMemo(
    () => filter((tty) => tty && values.requiresOtherTypeIds?.includes(tty.id), ticketTypesOptions),
    [values, ticketTypesOptions]
  )

  const handleRequiresOtherTypeIdsChange = useCallback(
    (_ids: any, selection: any) => {
      setFieldValue('requiresOtherTypeIds', selection?.filter((s: any) => s.id)?.map((s: any) => s.id) || [])
      setFieldTouched('requiresOtherTypeIds', true, true)

      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, validateForm, setFieldValue]
  )

  return (
    <Modal
      size="fullscreen"
      closeButton
      onClose={onCancel}
      modalTitle={intl.formatMessage({
        id: isExisting ? 'new_event.tickets.ticket_type_edit.title' : 'new_event.tickets.new_ticket_type.title',
      })}
    >
      <ModalBody>
        <form noValidate onSubmit={handleSubmit}>
          <Form>
            <FormRow columnOnMobile>
              <FormGroup
                error={
                  errors.icon === ' '
                    ? intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.icon.error_mandatory' })
                    : errors.icon
                }
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.icon.label' })}
                required
              >
                <Icons>
                  {iconSet.map((icon) => (
                    <EventTicketTypeIconInput
                      key={icon}
                      icon={icon}
                      active={values.icon === icon}
                      onChange={onChangeIcon}
                      disabled={
                        readOnly ||
                        (state !== 'DRAFT' &&
                          !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeIcon'))
                      }
                    />
                  ))}
                </Icons>
              </FormGroup>
            </FormRow>

            <FormRow columnOnMobile>
              <FormField
                name="name"
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.name.label' })}
                hint={
                  user.diceStaff ? (
                    <>
                      <DiceBadge />
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.name.dice_hint' })}
                    </>
                  ) : undefined
                }
                value={values.name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(touched.name || isExisting) && errors.name}
                disabled={
                  readOnly ||
                  (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canUpdate'))
                }
                required
              >
                <Counter maxCount={60} count={(values.name || '').length}>
                  {(values.name || '').length}/{60}
                </Counter>
              </FormField>
            </FormRow>

            {ticketPoolOptions.length > 0 && (
              <FormRow columnOnMobile>
                <FormField
                  name="ticketPoolId"
                  label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.ticket_pool_id.label' })}
                  control="select"
                  options={ticketPoolOptions}
                  value={find({ value: values.ticketPoolId }, ticketPoolOptions)}
                  onChange={setTicketPool}
                  onBlur={handleBlur}
                  disabled={
                    readOnly ||
                    (ticketPools?.length === 1 && !!values.ticketPoolId) ||
                    (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canUpdate')) ||
                    !!(event?.eventIdLive || eventForm?.eventIdLive)
                  }
                  error={(touched.ticketPoolId || isExisting) && errors.ticketPoolId}
                />
              </FormRow>
            )}

            {eventType === 'HYBRID' && (
              <FormRow columnOnMobile>
                <SwitchField
                  label={
                    <>
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.is_stream.label' })}
                      <TitleTooltip
                        title={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.is_stream.help' })}
                      >
                        <HelpIcon icon="help" width={16} height={16} />
                      </TitleTooltip>
                    </>
                  }
                  hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.is_stream.hint' })}
                  name="isStream"
                  checked={!!values.isStream}
                  disabled={
                    readOnly ||
                    (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !noRestrictions)
                  }
                  onChange={toggleStream}
                />
              </FormRow>
            )}

            {(eventType !== 'HYBRID' || values.isStream) && eventType !== 'LIVE' && diceTvPlatform === 'EXTERNAL' && (
              <FormRow columnOnMobile>
                <FormField
                  name="streamLink"
                  label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.stream_url.label' })}
                  value={values.streamLink || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(touched.streamLink || isExisting) && errors.streamLink}
                  disabled={
                    readOnly ||
                    (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canUpdate'))
                  }
                  placeholder="https://"
                />
              </FormRow>
            )}
            <FormRow columnOnMobile>
              <FormField
                name="description"
                control={MarkdownEditor}
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.description.label' })}
                value={values.descriptionDraft}
                onChange={setDescriptionDraft}
                onBlur={handleBlur}
                error={
                  (touched.description || isExisting) &&
                  (errors.description === 'validation.yup.string.max' ? ' ' : errors.description)
                }
                disabled={
                  readOnly ||
                  (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canUpdate'))
                }
              >
                <Counter maxCount={1500} count={(values.description || '').length}>
                  {(values.description || '').length}/{1500}
                </Counter>
              </FormField>
            </FormRow>

            {(hasPermission('manage_tickets:event') || !!event?.allowedActions?.manageTickets) && (
              <>
                <FormRow columnOnMobile>
                  <FormField
                    control="checkbox"
                    name="addEntryTime"
                    label={
                      <>
                        {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.valid_entry_time.label' })}
                        <TitleTooltip
                          title={intl.formatMessage({
                            id: 'new_event.tickets.ticket_type_edit.valid_entry_time.tooltip',
                          })}
                        >
                          <TooltipHelpIcon icon="help" width={16} height={16} />
                        </TitleTooltip>
                      </>
                    }
                    checked={addEntryTime}
                    onChange={handleAddEntryTimeChange}
                    onBlur={handleBlur}
                    disabled={readOnly}
                  />
                </FormRow>

                {addEntryTime && (
                  <>
                    {eventDate && (
                      <FormRow columnOnMobile>
                        <AlertBox icon="bulb" fullWidth>
                          {intl.formatMessage(
                            { id: 'new_event.tickets.ticket_type_edit.valid_entry_time.hint' },
                            {
                              eventStartDate: formattedEventStartDate,
                              eventEndDate: formattedEventEndDate,
                              b: (str: string) => <strong>{str}</strong>,
                            }
                          )}
                        </AlertBox>
                      </FormRow>
                    )}
                    <FormRow columnOnMobile>
                      <FormField
                        name="startDate"
                        timezone={timezone || undefined}
                        label={intl.formatMessage({
                          id: 'new_event.tickets.ticket_type_edit.valid_entry_time.start_label',
                        })}
                        placeholderDate={eventDate}
                        control="datetime"
                        value={values.startDate}
                        setFieldValue={setFieldValue}
                        locale={locale}
                        onBlur={handleBlur}
                        error={errors.startDate}
                        disabled={
                          readOnly ||
                          (state !== 'DRAFT' &&
                            !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeOnSaleDate'))
                        }
                      />
                    </FormRow>
                  </>
                )}
              </>
            )}

            <MarginH3>{intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.header.on_sale_date' })}</MarginH3>

            {!values.isStream && !!eventSeatingChart?.id && (
              <>
                <FormRow columnOnMobile>
                  <SwitchField
                    label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.reserved_seating.label' })}
                    hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.reserved_seating.hint' })}
                    name="reservedSeating"
                    checked={!!values.reservedSeating}
                    disabled={
                      readOnly ||
                      (state !== 'DRAFT' &&
                        !allowedEventAction(allowedLifecycleUpdates, 'forbidden') &&
                        !noRestrictions)
                    }
                    onChange={handleChangeSeating}
                    onBlur={handleBlur}
                  />
                </FormRow>
                {!!eventSeatingChart?.id && !values.reservedSeating && (
                  <FormRow>
                    <Warning data-id="seatedGaWarning">
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.seated_ga_warning' })}
                    </Warning>
                  </FormRow>
                )}
                {(values.reservedSeating || selectedSeatCategoryOptions.length > 0) && (
                  <FormRow columnOnMobile>
                    <FormField
                      name="seatCategories"
                      label={intl.formatMessage({
                        id: 'new_event.tickets.ticket_type_edit.seat_categories.label',
                      })}
                      control="select"
                      multiple
                      searchable
                      required
                      onBlur={handleBlur}
                      disabled={
                        !(state === 'DRAFT' || user.diceStaff || hasPermission('full_manage_access:event')) &&
                        !noRestrictions
                      }
                      options={allSeatCategoryOptions as any}
                      value={selectedSeatCategoryOptions}
                      onChange={changeSeatCategories}
                      error={(touched.seatCategories || isExisting) && errors.seatCategories}
                    />
                  </FormRow>
                )}
              </>
            )}

            <FormRow columnOnMobile>
              <FormField
                control="checkbox"
                name="sameAsEventDates"
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.same_as_event_dates' })}
                checked={sameAsEventDates}
                onChange={toggleSameAsEventDates}
                disabled={
                  readOnly ||
                  (state !== 'DRAFT' &&
                    !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeOnSaleDate')) ||
                  isForbiddenCustomDates
                }
                data-checked={sameAsEventDates}
              />
            </FormRow>

            <FormRow columnOnMobile>
              <FormField
                name="onSaleDate"
                timezone={timezone || undefined}
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.on_sale.label' })}
                control="datetime"
                value={sameAsEventDates ? eventOnSaleDate : values.onSaleDate}
                setFieldValue={setFieldValue}
                locale={locale}
                onBlur={handleBlur}
                error={errors.onSaleDate}
                hint={
                  sameAsEventDates || errors.onSaleDate || values.onSaleDate
                    ? undefined
                    : intl.formatMessage({ id: 'new_event.timeline.on_sale.hint' })
                }
                help={
                  isForbiddenCustomDates
                    ? intl.formatMessage({ id: 'new_event.timeline.on_sale.forbidden_help' })
                    : intl.formatMessage({ id: 'new_event.timeline.custom_dates.help' })
                }
                disabled={
                  readOnly ||
                  sameAsEventDates ||
                  (state !== 'DRAFT' &&
                    !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeOnSaleDate')) ||
                  isForbiddenCustomDates
                }
              />
              <FormField
                name="offSaleDate"
                timezone={timezone || undefined}
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.off_sale.label' })}
                control="datetime"
                value={sameAsEventDates ? eventOffSaleDate : values.offSaleDate}
                setFieldValue={setFieldValue}
                locale={locale}
                onBlur={handleBlur}
                error={errors.offSaleDate}
                hint={
                  sameAsEventDates || errors.offSaleDate || values.offSaleDate
                    ? undefined
                    : intl.formatMessage({ id: 'new_event.timeline.off_sale.hint' })
                }
                help={
                  isForbiddenCustomDates
                    ? intl.formatMessage({ id: 'new_event.timeline.off_sale.forbidden_help' })
                    : intl.formatMessage({ id: 'new_event.timeline.custom_dates.help' })
                }
                disabled={
                  readOnly ||
                  sameAsEventDates ||
                  (state !== 'DRAFT' &&
                    !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeOffSaleDate')) ||
                  isForbiddenCustomDates
                }
              />
            </FormRow>

            <FormRow columnOnMobile>
              <FormField
                name="announceDate"
                timezone={timezone || undefined}
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.announce.label' })}
                control="datetime"
                value={sameAsEventDates ? eventAnnounceDate : values.announceDate}
                setFieldValue={setFieldValue}
                locale={locale}
                onBlur={handleBlur}
                error={errors.announceDate}
                hint={
                  sameAsEventDates || errors.announceDate || values.announceDate
                    ? undefined
                    : intl.formatMessage({ id: 'new_event.timeline.on_sale.hint' })
                }
                help={
                  isForbiddenCustomDates
                    ? intl.formatMessage({ id: 'new_event.timeline.announce.forbidden_help' })
                    : intl.formatMessage({ id: 'new_event.timeline.custom_dates.help' })
                }
                disabled={
                  readOnly ||
                  sameAsEventDates ||
                  (state !== 'DRAFT' &&
                    !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeOnSaleDate')) ||
                  isForbiddenCustomDates
                }
              />
            </FormRow>

            {user.diceStaff && (
              <FormRow columnOnMobile>
                <FormField
                  name="activateCodeDateOffset"
                  timezone={timezone || undefined}
                  label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.activate_code_date.label' })}
                  hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.activate_code_date.hint' })}
                  control="datetime"
                  value={activateCodeDate}
                  setFieldValue={setOffsetDate}
                  locale={locale}
                  onBlur={handleBlur}
                  error={errors.activateCodeDateOffset}
                  disabled={readOnly}
                  required
                  dice
                />
              </FormRow>
            )}
            <FormRow>
              <EventTicketTypeTimeline event={event} eventForm={eventForm} values={values} />
            </FormRow>

            <MarginH3>{intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.header.pricing' })}</MarginH3>
            <EventTicketTypePricing
              formikContext={formik}
              readOnly={readOnly}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              validateForm={validateForm}
              event={event}
              eventForm={eventForm}
              noRestrictions={noRestrictions}
              canAddPriceTiers={!!isLiveAndCanAddTty}
            ></EventTicketTypePricing>

            <MarginH3>{intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.header.visibility' })}</MarginH3>

            <FormRow columnOnMobile>
              <FormField
                control="checkbox"
                name="hidden"
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.hidden.label' })}
                checked={getOr(false, 'hidden', values)}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(touched.hidden || isExisting) && errors.hidden}
                disabled={
                  readOnly ||
                  (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canHide'))
                }
              />
              {(hasPermission('manage_promotions:event') || !!event?.allowedActions?.managePromotions) && (
                <FormField
                  control="checkbox"
                  name="codeLocked"
                  label={
                    <>
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.code_locked.label' })}
                      <TitleTooltip
                        title={
                          isUnicornActive
                            ? intl.formatMessage({
                              id: 'new_event.tickets.ticket_type_edit.code_locked.not_compatible_with_cart',
                            })
                            : intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.code_locked.tooltip' })
                        }
                      >
                        <TooltipHelpIcon icon="help" width={16} height={16} />
                      </TitleTooltip>
                    </>
                  }
                  checked={getOr(false, 'codeLocked', values)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={readOnly || isUnicornActive}
                />
              )}
            </FormRow>
            {values.hidden && values.codeLocked && (
              <Warning className="mt-md">
                {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.warning.hidden_code_locked' })}
              </Warning>
            )}

            <MarginH3>
              {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.header.advanced_settings' })}
            </MarginH3>

            <PermissionCheck permission="manage_skus:event">
              <FormRow columnOnMobile>
                <FormField
                  name="externalSkus"
                  label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.external_skus.label' })}
                  control={TagsInput}
                  placeholder={intl.formatMessage({
                    id: 'new_event.tickets.ticket_type_edit.external_skus.placeholder',
                  })}
                  value={values.externalSkus}
                  onChange={setExternalSkus}
                  onBlur={handleBlur}
                  error={(touched.externalSkus || isExisting) && errors.externalSkus}
                  disabled={
                    readOnly ||
                    (state !== 'DRAFT' &&
                      !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeExternalSkus'))
                  }
                />
              </FormRow>
            </PermissionCheck>

            {isNts && (
              <EventTicketTypeNts
                isAlreadyIntegrated={!!attractiveStatus}
                noRestrictions={noRestrictions}
                state={state}
                allowedLifecycleUpdates={allowedLifecycleUpdates}
                venueSchedules={venueSchedules}
                readOnly={readOnly}
                formik={formik as unknown as FormikContextType<ITicketType>}
              />
            )}

            {eventType !== 'STREAM' && (
              <FrameContainer>
                {isShoppingCartActive && ticketTypes && ticketTypes.length > 1 && (
                  <>
                    <FormRow columnOnMobile>
                      <SwitchField
                        label={intl.formatMessage({
                          id: 'new_event.tickets.ticket_type_edit.required_ticket_types.label',
                        })}
                        hint={intl.formatMessage({
                          id: 'new_event.tickets.ticket_type_edit.required_ticket_types.hint',
                        })}
                        name="checkoutRestrictions"
                        checked={values.requiresOtherTypeIdsEnabled}
                        onChange={handleChangeRequiresOtherTypeIds}
                      />
                    </FormRow>

                    {values.requiresOtherTypeIdsEnabled && (
                      <SFormRowShort columnOnMobile>
                        <FormField
                          name="ticketTypes"
                          control="select"
                          placeholder={intl.formatMessage({ id: 'fan_survey.form.ticket_types.placeholder' })}
                          options={(ticketTypesOptions || []) as any[]}
                          multiple
                          value={requiresOtherTypeIdsValue || []}
                          onChange={handleRequiresOtherTypeIdsChange}
                          onBlur={handleBlur}
                          error={touched.requiresOtherTypeIds && errors.requiresOtherTypeIds}
                        />
                      </SFormRowShort>
                    )}
                  </>
                )}

                <FormRow columnOnMobile>
                  <SwitchField
                    label={intl.formatMessage({
                      id: 'new_event.tickets.ticket_type_edit.custom_tickets_per_order.label',
                    })}
                    hint={intl.formatMessage({
                      id: 'new_event.tickets.ticket_type_edit.custom_tickets_per_order.hint',
                    })}
                    name="customTicketsPerOrder"
                    disabled={
                      readOnly ||
                      (state !== 'DRAFT' &&
                        !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeIncrements'))
                    }
                    checked={customTicketsPerOrder}
                    onChange={changeCustomTicketsPerOrder}
                  />
                </FormRow>

                {customTicketsPerOrder && (
                  <HighlightBox>
                    <EventTicketTypeIncrements
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      validateForm={validateForm}
                      event={event}
                      eventForm={eventForm}
                    />
                  </HighlightBox>
                )}

                {user.diceStaff && (
                  <EventTicketTypePaymentMethods
                    event={eventForm || event}
                    values={values}
                    setFieldValue={setFieldValue}
                    readOnly={readOnly}
                    allowedLifecycleUpdates={allowedLifecycleUpdates}
                  />
                )}

                {user.diceStaff && <Divider />}

                {(user.diceStaff || account?.allowSkipReview || hasPermission('edit_all:door_sale')) && (
                  <>
                    <FormRow columnOnMobile>
                      <SwitchField
                        label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.door_sales.label' })}
                        hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.door_sales.hint' })}
                        name="doorSalesEnabled"
                        checked={!!values.doorSalesEnabled}
                        disabled={
                          readOnly ||
                          (state !== 'DRAFT' &&
                            !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeDoorSalesEnabled')) ||
                          isUnicornActive
                        }
                        onChange={handleChange}
                        title={
                          isUnicornActive
                            ? intl.formatMessage({
                              id:
                                  state === 'DRAFT'
                                    ? 'new_event.tickets.ticket_type_edit.door_sales.not_compatible_with_cart'
                                    : 'new_event.tickets.ticket_type_edit.door_sales.disabled_with_cart',
                            })
                            : undefined
                        }
                      />
                    </FormRow>

                    {values.doorSalesEnabled && (
                      <HighlightBox>
                        <EventTicketTypeDoorPricing
                          event={event}
                          eventForm={eventForm}
                          readOnly={readOnly}
                          touched={touched}
                          errors={errors}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          validateForm={validateForm}
                        />
                      </HighlightBox>
                    )}
                  </>
                )}
              </FrameContainer>
            )}

            {hasPermission('enable_requires_address_for_ticket_types:event') && (
              <FormRow columnOnMobile className="mt-lg">
                <SwitchField
                  label={intl.formatMessage({
                    id: 'new_event.tickets.ticket_type_edit.requires_address.label',
                  })}
                  hint={intl.formatMessage({
                    id: 'new_event.tickets.ticket_type_edit.requires_address.hint',
                  })}
                  name="requiresAddress"
                  checked={!!values.requiresAddress}
                  disabled={readOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormRow>
            )}

            {eventType !== 'STREAM' && (
              <EventTicketTypeAdminSettings
                formik={formik as any}
                readOnly={readOnly}
                allowedLifecycleUpdates={allowedLifecycleUpdates}
                noRestrictions={noRestrictions}
                state={state}
                eventSeatingChart={eventSeatingChart}
              />
            )}
          </Form>
        </form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl
          loading={isSubmitting}
          disabled={readOnly || isSubmitting || (!isValid && !user.diceStaff)}
          onClick={!isValid && user.diceStaff ? forceSubmit : handleSubmit}
          icon={!isValid && user.diceStaff ? 'dice-badge' : undefined}
          preset={!isValid && user.diceStaff ? 'outline' : undefined}
          color={!isValid && user.diceStaff ? 'tertiary' : undefined}
        >
          {intl.formatMessage({ id: !isValid && user.diceStaff ? 'save_changes_with_errors' : 'actions.save' })}
        </ModalFooterControl>

        <ModalFooterControl preset="secondary" loading={isSubmitting} disabled={isSubmitting} onClick={onCancel}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(EventTicketTypeModal)

const SFormRowShort = styled(FormRow)`
  max-width: 528px;
`

const TicketTypeOptionsHint = styled.span`
  color: ${color.darkgrey};
`
