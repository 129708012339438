import { useContext, useMemo } from 'react'
import { compose, filter, identity, map, reject, set } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { sortProfiles } from '../../flows/UsersAndPermissions/utils/structure'
import { authContext } from '../../context/auth'

export interface IProfile {
  readonly value: string
  readonly roleName: string
  readonly caption: string
  readonly diceStaff: boolean
}

function useProfileOptions<T extends IProfile>(
  permissionProfiles: ReadonlyArray<T | null> | null,
  clientOnly?: boolean
) {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const profileOptions: Array<T & { label: string }> = useMemo(
    () =>
      compose([
        map(
          (profile: { roleName: string; caption: string }) =>
            profile &&
            set(
              'label',
              // prettier-ignore
              profile.roleName === 'custom'
                ? profile.caption || ''
                : intl.formatMessage({
                  // @intl-meta project:Permissions
                  id: `permissions_role.${profile.roleName}`,
                  defaultMessage: profile.caption || '',
                }),
              profile
            )
        ),
        sortProfiles,
        user.diceStaff && !clientOnly ? identity : reject('diceStaff'),
      ])(permissionProfiles || []),
    [clientOnly, intl, permissionProfiles, user.diceStaff]
  )

  const groupedOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'permissions.title_custom' }),
        options: filter(['roleName', 'custom'], profileOptions) as typeof profileOptions,
      },
      {
        label: intl.formatMessage({ id: 'permissions.title_predefined' }),
        options: reject(['roleName', 'custom'], profileOptions) as typeof profileOptions,
      },
    ],
    [intl, profileOptions]
  )

  return { profileOptions, groupedOptions }
}

export default useProfileOptions
