import React, { FC, useState, useCallback, useEffect, ReactNode } from 'react'
import styled from 'styled-components/macro'
import cn from 'classnames'

import { useIntl } from 'react-intl'
import Svg from './Svg'
import { font, mediaQuery } from '../utils/variables'
import { TitleTooltip } from './Tooltip'
import { HelpIcon, FormGroupHint } from './FormGroup'
import DiceBadge from './DiceBadge'
import Badge from './Badge'

export const CollapseContainer = styled.div<{ collapsed: boolean }>`
  display: inline-block;
  position: relative;
  padding-right: 24px;

  margin: ${({ collapsed }) => (collapsed ? '32px 0 0 0' : '32px 0')};

  ${mediaQuery.lessThan<{ collapsed: boolean }>('tablet')`
    margin: ${({ collapsed }) => (collapsed ? '24px 0 0 0' : '24px 0')};
  `}
`

const StyledHelpIcon = styled(HelpIcon)`
  top: 4px;
`

export const CollapseButton = styled.button.attrs(() => ({ type: 'button' }))<{ collapsed: boolean }>`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;

  font-weight: bold;
  font-size: ${font.size.sm}px;
  text-align: left;

  & > svg {
    margin-bottom: -7px;
    margin-right: 8px;
    ${({ collapsed }) => (collapsed ? 'transform: rotate(-90deg);' : undefined)}
  }

  ${FormGroupHint} {
    font-weight: normal;
    margin-left: 32px;
  }
`

const NewBadge = styled(Badge).attrs({ color: 'primary' })`
  font-weight: bold;
  margin: 0 8px;
`

const ShiftedDiceBadge = styled(DiceBadge)`
  margin-left: -8px;
`

interface IProps {
  className?: string
  label: ReactNode
  hint?: ReactNode
  help?: ReactNode
  initialCollapsed?: boolean
  dataId?: string
  dice?: boolean
  reverse?: boolean
  isNew?: boolean
}

const Collapsible: FC<React.PropsWithChildren<IProps>> = ({
  className,
  label,
  hint,
  help,
  children,
  initialCollapsed = true,
  dataId,
  dice,
  reverse,
  isNew,
}) => {
  const intl = useIntl()
  const [collapsed, setCollapsed] = useState(initialCollapsed)

  useEffect(() => {
    if (!initialCollapsed) {
      setCollapsed(initialCollapsed)
    }
  }, [initialCollapsed])

  const toggleCollapsed = useCallback(() => {
    setCollapsed((c) => !c)
  }, [])

  return (
    <div className={className}>
      <CollapseContainer collapsed={collapsed}>
        <CollapseButton
          collapsed={collapsed}
          className={cn({ '-collapsed': collapsed })}
          onClick={toggleCollapsed}
          data-id={dataId || 'collapseButton'}
        >
          {!reverse && <Svg icon="collapsible" />}
          <span>
            {dice && <ShiftedDiceBadge />}
            {label}
            {isNew && <NewBadge>{intl.formatMessage({ id: 'new_event.step_badge.new' })}</NewBadge>}
            {hint && <FormGroupHint>{hint}</FormGroupHint>}
          </span>
          {reverse && <Svg icon="collapsible" />}
        </CollapseButton>
        {help && (
          <TitleTooltip title={help}>
            <StyledHelpIcon icon="help" width={16} height={16} />
          </TitleTooltip>
        )}
      </CollapseContainer>
      {!collapsed && children}
    </div>
  )
}

export default styled(Collapsible)``
