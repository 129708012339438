import React, { useMemo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import RelayLoader from '../../components/RelayLoader'
import InviteUser from './InviteUser'

import { PageHeader, PageTitle } from '../../components/Page'

function InviteUserPage() {
  const intl = useIntl()

  const DataLoader = useMemo(() => {
    return RelayLoader(InviteUser, {
      fetchPolicy: 'store-and-network',
      query: graphql`
        query InviteUserPageQuery {
          viewer {
            ...InviteUser_viewer
          }
        }
      `,
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'permissions.new_users.title' })} | DICE MIO</title>
      </Helmet>
      <PageHeader>
        <PageTitle>{intl.formatMessage({ id: 'permissions.new_users.title' })}</PageTitle>
      </PageHeader>
      <DataLoader />
    </>
  )
}

export default InviteUserPage
