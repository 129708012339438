import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import { array, object, string } from 'yup'
import { Link } from 'react-router-dom'
import { compact, compose, find, map } from 'lodash/fp'

import { useNavigate } from 'react-router'
import GenericError from '../../components/GenericError'
import { authContext } from '../../context/auth'
import { InviteUser_viewer$key } from '../../__generated__/InviteUser_viewer.graphql'
import { breakpoints, color, mediaQuery } from '../../utils/variables'
import AutoScroller, { scrollToSection, useAutoScroll } from '../../components/AutoScroller'
import Button from '../../components/Button'
import { localeContext } from '../../context/locale'
import { Form, FormRow } from '../../components/Form'
import FormField from '../../components/FormField'
import TagsInput from '../../components/TagsInput'
import PermissionView from '../UsersAndPermissions/components/PermissionView'
import { extractStructure, flattenPermissionStructure } from '../UsersAndPermissions/utils/structure'
import { notificationContext } from '../../context/notification'
import Collapsible from '../../components/Collapsible'
import PageTransitionBlocker from '../../components/PageTransitionBlocker'
import FormHeader from '../../components/FormHeader'
import { CancelButton, FormControls, FormControlsInner } from '../../components/FormControlsStyles'
import { NavItem, NavItems, NavPanel } from '../../components/NavBarStyles'
import useProfileOptions from '../../utils/hooks/useProfileOptions'
import useInviteUser from '../UsersAndPermissions/hooks/useInviteUser'
import { REGEX_VALIDATE_EMAIL } from '../../utils/regex'

const Layout = styled.div`
  display: flex;
  min-height: calc(100% - 72px);

  ${mediaQuery.lessThan('desktop')`
    flex-direction: column;
  `};
`

const BodyContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  width: 100%;
  ${FormControls} {
    margin-top: auto;
  }
`

const Section = styled(AutoScroller)`
  & > div {
    padding: 48px;
    margin: auto;
    max-width: calc(100vw - 74px);

    ${mediaQuery.lessThan('desktop')`
      padding: 16px;
      max-width: none;
    `}
    ${mediaQuery.greaterThan('desktop')`
      max-width: calc(100vw - 314px);
    `}
    ${mediaQuery.greaterThan('desktopLarge')`
      max-width: 800px;
    `}
  }

  &[data-slug='profile'] {
    min-height: 500px;
  }

  & + & {
    border-top: 2px solid ${color.text};
    padding-top: 16px;
  }
`

const ActiveLink = styled(Link)`
  color: ${color.primary};
`

const InviteUserSchema = object().shape({
  inviteEmails: array().of(string().nullable().email().required()),
  profileId: string().nullable().required(),
})

interface IFormState {
  inviteEmails: Array<string | null>
  profileId: string | null
}

interface IProps {
  viewer: InviteUser_viewer$key
}

const InviteUser: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { phraseEnabled } = useContext(localeContext)
  const { user, account, hasPermission } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)

  const viewer = useFragment(
    graphql`
      fragment InviteUser_viewer on Viewer {
        ...PermissionView_viewer
        permissionProfiles {
          value: id
          roleName
          caption
          diceStaff

          subjects {
            name
            actions {
              name
            }
          }
        }
      }
    `,
    viewerKey
  )

  const [activeStep, setActiveStep] = useState('invites')
  useAutoScroll(setActiveStep, [])
  const doScroll = useCallback((e: any) => {
    const slug = e.currentTarget.dataset['slug']
    scrollToSection(slug, false, 72)
  }, [])

  const { profileOptions, groupedOptions } = useProfileOptions(viewer.permissionProfiles || [])

  const inviteUser = useInviteUser()

  const usersPath = !user.diceStaff && user.mioRedesignV2 ? '/settings/users' : '/tools/users'

  const formik = useFormik<IFormState>({
    initialValues: {
      inviteEmails: [],
      profileId: null,
    },
    validationSchema: InviteUserSchema,
    onReset: () => {
      setTimeout(() => navigate(usersPath), 0)
    },
    onSubmit: async (values) => {
      const profileId = values.profileId
      if (values.inviteEmails.length > 0 && account && profileId) {
        const results: Array<null | any> = await Promise.all(
          map((email) => inviteUser(profileId, email), compact(values.inviteEmails))
        )

        const successCount = compact(results).length

        if (successCount > 0) {
          const profile = find(['value', values.profileId], profileOptions)
          addNotification(
            'success',
            intl.formatMessage(
              { id: 'permissions.bulk_invite_success' },
              { count: successCount, profileName: profile?.label || '' }
            )
          )
        }
      }

      navigate(usersPath)
    },
  })

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    isSubmitting,
    setFieldTouched,
    submitForm,
    handleReset,
    handleSubmit,
    validateForm,
    resetForm,
    dirty,
  } = formik

  const setInviteEmails = useCallback(
    (emails: any) => {
      setFieldValue('inviteEmails', emails || [])
      // Normally we touch on blur but this inout can be updated without blur due to its nature
      setFieldTouched('inviteEmails')
    },
    [setFieldTouched, setFieldValue]
  )

  const setProfile = useCallback(
    (id: any) => {
      setFieldValue('profileId', id)
      setFieldTouched('profileId')
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const profileOption = useMemo(
    () => find(['value', values.profileId], profileOptions),
    [profileOptions, values.profileId]
  )

  const enabledPermissions = useMemo(
    () =>
      compose([(profile) => flattenPermissionStructure(extractStructure(profile)), find(['value', values.profileId])])(
        viewer.permissionProfiles || []
      ),
    [values.profileId, viewer.permissionProfiles]
  )

  if (!account || !hasPermission('manage_users:account')) {
    return (
      <GenericError
        icon="access-denied"
        title={intl.formatMessage({ id: 'generic_error.access_denied_title' })}
        description={intl.formatMessage({ id: 'generic_error.access_denied_description' })}
        backText={intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
        backLink="/dashboard"
      />
    )
  }

  return (
    <Layout>
      <PageTransitionBlocker
        condition={dirty && values.inviteEmails.length > 0 && !isSubmitting}
        message={intl.formatMessage({ id: 'permissions.new_users.leave_page_warning' })}
      />
      <NavPanel>
        <NavItems>
          <NavItem active={activeStep === 'invites'} data-slug="invites" onClick={doScroll}>
            {intl.formatMessage({ id: 'permissions.new_users.steps.invite_users.name' })}
          </NavItem>
          <NavItem active={activeStep === 'profile'} data-slug="profile" onClick={doScroll}>
            {intl.formatMessage({ id: 'permissions.new_users.steps.assign_profile.name' })}
          </NavItem>
        </NavItems>
      </NavPanel>
      <BodyContainer>
        <Section slug="invites">
          <Form spacing={isMobile ? 'default' : 'extra'}>
            <FormHeader header={intl.formatMessage({ id: 'permissions.new_users.steps.invite_users.title' })} />

            <FormRow columnOnMobile>
              <FormField
                required
                name="inviteEmails"
                label={intl.formatMessage({ id: 'permissions.new_users.steps.invite_users.email.label' })}
                control={TagsInput}
                placeholder={intl.formatMessage({ id: 'permissions.new_users.steps.invite_users.email.placeholder' })}
                value={values.inviteEmails}
                onChange={setInviteEmails}
                onBlur={handleBlur}
                validationRegex={REGEX_VALIDATE_EMAIL}
                error={touched.inviteEmails && errors.inviteEmails}
              />
            </FormRow>
          </Form>
        </Section>
        <Section slug="profile">
          <Form spacing={isMobile ? 'default' : 'extra'}>
            <form noValidate onSubmit={handleSubmit} onReset={handleReset}>
              <FormHeader
                header={intl.formatMessage({ id: 'permissions.new_users.steps.assign_profile.title' })}
                subheader={
                  <>
                    {intl.formatMessage({ id: 'permissions.new_users.steps.assign_profile.description_new_part_one' })}
                    {
                      // prettier-ignore
                      ' '
                    }
                    {user.diceStaff || hasPermission('manage:permission_profile')
                      ? intl.formatMessage(
                        { id: 'permissions.new_users.steps.assign_profile.description_new_part_two' },
                        {
                          a: (str: string) => <ActiveLink to="/tools/permissions">{str}</ActiveLink>,
                        }
                      )
                      : null}
                  </>
                }
              />

              <FormRow columnOnMobile>
                <FormField
                  required
                  name="profileId"
                  label={intl.formatMessage({ id: 'permissions.new_users.steps.assign_profile.profile.label' })}
                  control="select"
                  searchable
                  options={groupedOptions}
                  value={profileOption}
                  onChange={setProfile}
                  onBlur={handleBlur}
                  error={touched.profileId && errors.profileId}
                />
              </FormRow>

              {values.profileId && (
                <Collapsible label={intl.formatMessage({ id: 'users.invite_modal.permission_structure.label' })}>
                  <PermissionView viewer={viewer} enabledPermissions={enabledPermissions} />
                </Collapsible>
              )}
            </form>
          </Form>
        </Section>

        <FormControls ICEenabled={phraseEnabled}>
          <FormControlsInner>
            <Button
              type="submit"
              data-id="inviteButton"
              loading={isSubmitting}
              disabled={!isValid || values.inviteEmails.length === 0}
              onClick={submitForm}
            >
              {intl.formatMessage({ id: 'users.add_action' })}
            </Button>

            <div />

            <CancelButton preset="secondary" onClick={resetForm}>
              {intl.formatMessage({ id: 'actions.cancel' })}
            </CancelButton>
          </FormControlsInner>
        </FormControls>
      </BodyContainer>
    </Layout>
  )
}

export default memo(InviteUser)
