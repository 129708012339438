import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'

import ProgressBar from '../../../../components/ProgressBar'
import Value from '../../../../components/Value'
import { CURRENCY } from '../../../../utils/formatters/number'
import { mediaQuery } from '../../../../utils/variables'
import { EventProgress_event$key } from '../../../../__generated__/EventProgress_event.graphql'

const ProgressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`

const ProgressFinance = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
`

const Progress = styled(ProgressBar)`
  width: 100%;
  margin-top: 16px;
  min-width: 350px;

  ${mediaQuery.lessThan('tablet')`
    min-width: unset;
  `}
`

interface IProps {
  event: EventProgress_event$key
}

const EventProgress: FC<IProps> = ({ event: eventKey }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment EventProgress_event on Event {
        id
        costCurrency
        allocation
        sales {
          totalSold
          totalFaceValue
          totalAppSold
          totalPosSold
        }
      }
    `,
    eventKey
  )

  const allocation = event.allocation
  const sales = event.sales || {
    totalSold: 0,
    totalFaceValue: 0,
  }

  const soldPercentage = parseFloat(
    intl.formatNumber((sales.totalSold * 100) / allocation || 0, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })
  )

  const formattedRevenue = useMemo(
    () => intl.formatNumber((sales.totalFaceValue || 0) / 100, CURRENCY(sales.totalFaceValue, event.costCurrency)),
    [intl, sales.totalFaceValue, event.costCurrency]
  )

  return (
    <ProgressWrapper>
      <ProgressFinance>
        <Value label={intl.formatMessage({ id: 'tickets_sold' })} value={sales.totalSold + '/' + allocation} />
        <Value
          label={intl.formatMessage({ id: 'event_overview.ticket_breakdown.total_face_value.label' })}
          value={formattedRevenue}
          textAlign="right"
        />
      </ProgressFinance>
      <Progress color="primary" gradient value={soldPercentage} />
    </ProgressWrapper>
  )
}

export default memo(EventProgress)
