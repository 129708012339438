import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'
import { isNil, keys, omit } from 'lodash/fp'
import Toggle, { IToggleProps } from './Toggle'
import FormGroup, { IFormGroupProps } from './FormGroup'
import { mediaQuery } from '../utils/variables'

const PaddedFormGroup = styled(FormGroup)`
  padding-right: 80px;

  .switch-label {
    margin-bottom: 4px;
  }
`

const CustomSwitch = styled(Toggle)<{ $hasHint?: boolean }>`
  position: absolute;
  right: 0;
  top: ${({ $hasHint }) => ($hasHint ? 8 : -5)}px;

  ${mediaQuery.lessThan<{ $hasHint?: boolean }>('tablet')`
    top: ${({ $hasHint }) => ($hasHint ? 4 : -5)}px;
  `}
`

type IProps = IFormGroupProps & Omit<IToggleProps, 'label'>

const SwitchField: FC<React.PropsWithChildren<IProps>> = (props) => {
  const formGroupProps: IFormGroupProps = {
    label: props.label,
    labelFor: props.id,
    error: props.error,
    hint: props.hint,
    help: props.help,
    required: props.required,
    disabled: props.disabled,
    className: props.className,
    size: props.size,
    timezone: props.timezone,
    dice: props.dice,
  }

  const otherProps = {
    ...omit(keys(formGroupProps), props),
    disabled: props.disabled,
    hasError: !isNil(props.error) && props.error !== false,
  }

  return (
    <PaddedFormGroup {...formGroupProps} labelClassName="switch-label">
      <CustomSwitch {...otherProps} $hasHint={!!props.hint} />
    </PaddedFormGroup>
  )
}

export default memo(SwitchField)
