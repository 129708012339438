import React, { ComponentProps, ErrorInfo, ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { IntlShape, useIntl } from 'react-intl'
import { Location, useLocation } from 'react-router'

import GenericError from './GenericError'
import { Loader, LoaderContainer } from './Loader'
import { ConfirmationModal } from './ConfirmationModal'

interface IProps {
  intl: IntlShape
  location: Location
  noAuth?: boolean
  children: ReactNode
}

interface IState {
  hasError: boolean
  chunkLoadFailed: boolean
}

const DEFAULT_STATE = { hasError: false, chunkLoadFailed: false }

const doReload = () => window.location.reload()
class ErrorBoundaryNoDeps extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = DEFAULT_STATE
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, chunkLoadFailed: error?.name === 'ChunkLoadError' }
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    if (error?.name === 'ChunkLoadError') {
      console.warn(error)
      return
    }

    console.error(error, errorInfo)
    Sentry.withScope((scope) => {
      scope.setExtras({ componentStack: JSON.stringify(errorInfo.componentStack) })
      Sentry.captureException(error)
    })
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.state.hasError && prevProps.location.key !== this.props.location.key) {
      this.setState(DEFAULT_STATE)
    }
  }

  render() {
    const { intl, noAuth } = this.props
    const { hasError, chunkLoadFailed } = this.state

    if (!hasError) return this.props.children

    if (chunkLoadFailed) {
      return (
        <>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>

          <ConfirmationModal
            title={intl.formatMessage({ id: 'app_outdated_error' })}
            cta={intl.formatMessage({ id: 'actions.refresh' })}
            onConfirm={doReload}
          />
        </>
      )
    } else {
      return (
        <GenericError
          title={intl.formatMessage({ id: 'generic_error.title' })}
          description={intl.formatMessage({ id: 'generic_error.description' })}
          backText={intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
          backLink={noAuth ? undefined : '/dashboard'}
        />
      )
    }
  }
}

const ErrorBoundary = (props: Omit<ComponentProps<typeof ErrorBoundaryNoDeps>, 'intl' | 'location'>) => {
  const intl = useIntl()
  const location = useLocation()
  return <ErrorBoundaryNoDeps intl={intl} location={location} {...props} />
}

export default ErrorBoundary
