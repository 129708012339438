import React, { FC, memo, useContext, useMemo, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { get, getOr } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import { Form, FormRow, FormSubsection } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { color, breakpoints, font } from '../../../utils/variables'
import { IFormStep } from '../services/getStepsConfig'
import { allowedEventAction } from '../services/allowedEventAction'
import IEventFormInformation from '../types/Information'
import { authContext } from '../../../context/auth'
import EventImageUploader from '../components/EventImageUploader'
import useInformationImages from '../hooks/useInformationImages'
import MarkdownEditor from '../../../components/MarkdownEditor'
import { renderMarkdown } from '../../../utils/markdown'
import Collapsible from '../../../components/Collapsible'
import EventAdditionalInfos from '../components/EventAdditionalInfos'
import FormHeader from '../../../components/FormHeader'
import SwitchField from '../../../components/SwitchField'
import EventCovidRestrictions from '../components/EventCovidRestrictions'
import DiceBadge from '../../../components/DiceBadge'
import EventFeatured from '../components/EventFeatured'
import EventMedia from '../components/EventMedia'
import { IRadioOptionValue } from '../../../components/RadioGroup'
import EventFaqs from '../components/EventFaqs'
import EventOnSaleReminder from '../components/EventOnSaleReminder'

const MAIN_IMAGE_TYPES = ['square', 'landscape', 'portrait']

interface ICounterProps {
  count: number
  maxCount: number
  minCount: number
}

const Counter = styled.div<ICounterProps>`
  position: absolute;
  top: 0;
  right: 0;
  white-space: nowrap;
  font-size: ${font.size.sm}px;
  ${({ count, maxCount, minCount }) => (count < minCount || count > maxCount ? `color: ${color.error}` : undefined)}
`

const ArtistBioHint = styled.div`
  margin-top: 8px;
  margin-right: 8px;

  & > a {
    color: ${color.primary};
  }
`

const InformationStep: FC<React.PropsWithChildren<IFormStep>> = ({ children, readOnly }) => {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)
  const { values, touched, errors, handleChange, handleBlur, setFieldValue, validateForm, setFieldTouched } =
    useFormikContext<IEventFormInformation>()

  const { images, setImages, rawImage, setRawImage, touchImages } = useInformationImages()

  const ageLimitSuggestions = useMemo(() => {
    return [
      intl.formatMessage({ id: 'new_event.information.age_limit.options.all_ages' }),
      intl.formatMessage({ id: 'new_event.information.age_limit.options.specific_age' }, { age: '14' }),
      intl.formatMessage({ id: 'new_event.information.age_limit.options.specific_age' }, { age: '16' }),
      intl.formatMessage({ id: 'new_event.information.age_limit.options.specific_age_an' }, { age: '18' }),
      intl.formatMessage({ id: 'new_event.information.age_limit.options.specific_age' }, { age: '21' }),
    ]
  }, [intl])

  const setFaqs = useCallback(
    (v: any) => {
      setFieldValue('faqs', v)
      setFieldTouched('faqs', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const descriptionCharLimit = 1500

  const minSizeLabel = useMemo(
    () =>
      intl.formatMessage({ id: 'new_event.basics.images.min_size' }, { b: (str: string) => <strong>{str}</strong> }),
    [intl]
  )

  const fileFormatLabel = useMemo(
    () =>
      intl.formatMessage({ id: 'new_event.basics.images.file_format' }, { b: (str: string) => <strong>{str}</strong> }),
    [intl]
  )

  const setDescriptionDraft = useCallback(
    (draft: Draft.EditorState) => {
      setFieldValue('descriptionDraft', draft)
      setFieldValue('description', renderMarkdown(draft))
    },
    [setFieldValue]
  )

  const setArtistBioDraft = useCallback(
    (draft: Draft.EditorState) => {
      setFieldValue('artistForBio.descriptionDraft', draft)
      setFieldValue('artistForBio.description', renderMarkdown(draft))
    },
    [setFieldValue]
  )

  const toggleDayOfEventComms = useCallback(
    () =>
      setFieldValue(
        'flags.disableDayOfEventComms.active',
        !getOr(false, 'disableDayOfEventComms.active', values.flags)
      ),
    [values.flags, setFieldValue]
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const setArtistBioMode = useCallback(
    (mode: IRadioOptionValue) => {
      setFieldValue('showArtistDescription', mode)
    },
    [setFieldValue]
  )

  const includeOnPurchaseEmailAdditionalInfo = values.additionalInfos?.find((ai) => ai?.includeOnPurchaseEmail)
  const includeOnReminderEmailAdditionalInfo = values.additionalInfos?.find((ai) => ai?.includeOnReminderEmail)

  return (
    <Form spacing={isMobile ? 'default' : 'extra'}>
      <FormHeader
        header={intl.formatMessage({ id: 'new_event.steps.information' })}
        subheader={intl.formatMessage({ id: 'new_event.information.description' })}
      />

      <FormRow columnOnMobile>
        <EventImageUploader
          label={intl.formatMessage({ id: 'new_event.basics.images.label' })}
          help={intl.formatMessage({ id: 'new_event.basics.images.help' })}
          minSizeLabel={minSizeLabel}
          fileFormatLabel={fileFormatLabel}
          existingFileLabel={intl.formatMessage({ id: 'new_event.basics.images.main_photo' })}
          fileFormatErrorSuggestion="JPEG"
          rawImage={rawImage || null}
          setRawImage={setRawImage}
          images={images}
          setImages={setImages}
          allowEdit={!readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'eventImages')}
          required
          error={
            touched.eventImages ||
            (!!values.id && !readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'eventImages'))
              ? errors.eventImages
              : undefined
          }
          allowedImageTypes={MAIN_IMAGE_TYPES}
          onTouch={touchImages}
        />
      </FormRow>

      <FormRow columnOnMobile>
        <FormField
          name="description"
          control={MarkdownEditor}
          label={intl.formatMessage({ id: 'new_event.information.description.label' })}
          placeholder={intl.formatMessage({ id: 'new_event.information.description.placeholder' })}
          hint={
            user.diceStaff ? (
              <>
                <DiceBadge />
                {intl.formatMessage({ id: 'new_event.information.description.dice_hint' })}
              </>
            ) : undefined
          }
          value={values.descriptionDraft}
          onChange={setDescriptionDraft}
          onBlur={handleBlur}
          error={touched.description && errors.description}
          disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'description')}
          required
        >
          <Counter minCount={1} maxCount={descriptionCharLimit} count={(values.description || '').length}>
            {(values.description || '').length}/{descriptionCharLimit}
          </Counter>
        </FormField>
      </FormRow>

      <FormRow columnOnMobile>
        <EventFaqs allowEdit={!readOnly} />
      </FormRow>

      {values.artistForBio ? (
        <FormRow columnOnMobile>
          <FormField
            name="showArtistDescription"
            control="radioGroup"
            label={intl.formatMessage(
              { id: 'new_event.information.artist_bio.title' },
              { artistName: values.artistForBio.artist?.label }
            )}
            value={values.showArtistDescription}
            options={[
              ...(values.artistForBio.artist?.description
                ? [
                  {
                    value: 'DICE',
                    label: intl.formatMessage({ id: 'new_event.information.artist_bio.dice.title' }),
                    children: (
                      <FormField
                        name="artistBio"
                        control="text"
                        multiline
                        value={values.artistForBio.artist?.description}
                        hint={
                          <ArtistBioHint>
                            {intl.formatMessage(
                              { id: 'new_event.information.artist_bio.dice.description' },
                              {
                                link: (str: string) => (
                                  <a
                                    // eslint-disable-next-line max-len
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSf0lo68HR_VcvVv5s4dBu260YWFl_5f6Z5rJMoRsilzyff5hQ/viewform"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {str}
                                  </a>
                                ),
                              }
                            )}
                          </ArtistBioHint>
                        }
                        disabled
                      />
                    ),
                  },
                ]
                : []),
              {
                value: 'CUSTOM',
                label: intl.formatMessage({ id: 'new_event.information.artist_bio.custom.title' }),
                children: (
                  <FormField
                    name="artistBio"
                    control={MarkdownEditor}
                    placeholder={intl.formatMessage({ id: 'new_event.information.artist_bio.custom.placeholder' })}
                    value={values.artistForBio.descriptionDraft}
                    onChange={setArtistBioDraft}
                    onBlur={handleBlur}
                    error={touched.artistForBio && errors.artistForBio}
                    disabled={readOnly}
                  />
                ),
              },
              {
                value: 'NONE',
                label: intl.formatMessage({ id: 'new_event.information.artist_bio.none.title' }),
              },
            ]}
            onChange={setArtistBioMode}
            error={touched.showArtistDescription && errors.showArtistDescription}
            disabled={readOnly}
          />
        </FormRow>
      ) : null}

      <EventMedia readOnly={readOnly} />

      <FormRow columnOnMobile>
        <FormField
          name="ageLimit"
          label={intl.formatMessage({ id: 'new_event.information.age_limit.label' })}
          placeholder={intl.formatMessage(
            { id: 'new_event.information.age_limit.options.specific_age' },
            { age: '16' }
          )}
          value={values.ageLimit}
          suggestions={ageLimitSuggestions}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          error={touched.ageLimit && errors.ageLimit}
          disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'ageLimit')}
          required
        />

        <FormField
          name="presentedBy"
          label={intl.formatMessage({ id: 'new_event.information.presented_by.label' })}
          value={values.presentedBy}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.presentedBy && errors.presentedBy}
          disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'presentedBy')}
        />
      </FormRow>

      {hasPermission('manage_auto_reminders:event') && (
        <FormSubsection header={intl.formatMessage({ id: 'new_event.information.on_sale_sms.header' })}>
          <EventOnSaleReminder readOnly={readOnly} />
        </FormSubsection>
      )}

      {includeOnPurchaseEmailAdditionalInfo && (
        <FormSubsection
          header={intl.formatMessage({ id: 'new_event.information.information_for_fans.on_purchase.header' })}
        >
          <EventAdditionalInfos ai={includeOnPurchaseEmailAdditionalInfo} readOnly={readOnly} />
        </FormSubsection>
      )}

      {(hasPermission('manage_auto_day_of_comms:event') || includeOnReminderEmailAdditionalInfo) && (
        <FormSubsection
          className="mb-md"
          header={intl.formatMessage({ id: 'new_event.information.information_for_fans.on_day_reminder.header' })}
        >
          {hasPermission('manage_auto_day_of_comms:event') && (
            <FormRow columnOnMobile>
              <SwitchField
                label={intl.formatMessage({ id: 'new_event.settings.flags.day_of_event_comms.label' })}
                error={
                  get('flags.disableDayOfEventComms.active', touched) &&
                  get('flags.disableDayOfEventComms.active', errors)
                }
                name="flags.disableDayOfEventComms.active"
                checked={!getOr(false, 'flags.disableDayOfEventComms.active', values)}
                onChange={toggleDayOfEventComms}
                onBlur={handleBlur}
                disabled={readOnly}
              />
            </FormRow>
          )}

          {includeOnReminderEmailAdditionalInfo && !getOr(false, 'flags.disableDayOfEventComms.active', values) && (
            <EventAdditionalInfos ai={includeOnReminderEmailAdditionalInfo} readOnly={readOnly} />
          )}
        </FormSubsection>
      )}

      {values.eventType !== 'STREAM' && <EventCovidRestrictions disabled={readOnly} />}

      {user.diceStaff && (
        <Collapsible
          label={intl.formatMessage({ id: 'admin_settings' })}
          initialCollapsed={(values.featuredAreas?.length || 0) === 0}
          dataId="informationAdminSettings"
          dice
        >
          <FormRow columnOnMobile>
            <FormField
              control="checkbox"
              name="flags.alcoholFree.active"
              label={intl.formatMessage({ id: 'new_event.information.flags.alcohol_free.label' })}
              checked={getOr(false, 'flags.alcoholFree.active', values)}
              onChange={handleChange}
              onBlur={handleBlur}
              error={get('flags.alcoholFree.active', touched) && get('flags.alcoholFree.active', errors)}
              disabled={readOnly}
            />
          </FormRow>

          {hasPermission('manage:featured_area') && <EventFeatured readOnly={readOnly} />}
        </Collapsible>
      )}

      {children}
    </Form>
  )
}

export default createFragmentContainer(memo(InformationStep), {
  event: graphql`
    fragment Information_event on Event {
      id
      eventType
      allowedLifecycleUpdates {
        description {
          canUpdate
        }
        media {
          canUpdate
        }
        presentedBy {
          canUpdate
        }
        ageLimit {
          canUpdate
        }
      }
      eventArtists {
        artist {
          media {
            id
            type
            values
          }
          description
        }
        description
      }
      featuredAreas {
        id
        startDate
        endDate
        mode
        locationString
        locationRadius
        locationUnits
        locationLat
        locationLng
        countryCode
        weight
        description
      }
      flags {
        disableDayOfEventComms
        alcoholFree
      }
      eventRules {
        covidPcr
        covidPcrValidHours
        covidRecovery
        covidVaccination
        maskRequired
        proofOfBeingHealthy
        socialDistancing
        covidPolicyUrl
      }
      eventImages {
        id
        type
        cdnUrl
        attachment {
          id
        }
        cropRegion {
          x
          y
          width
          height
        }
      }
      state
      description
      presentedBy
      ageLimit
      faqs {
        id
        title
        body
        order
      }
      media {
        id
        type
        values
      }
      additionalInfos {
        id
        content
        includeOnPurchaseEmail
        includeOnReminderEmail
        ctaLabel
        ctaLink
      }
      addressCountry
      countryCode
      addressLocality
      latitude
      longitude
      date
      endDate
      announceDate
      onSaleDate
      offSaleDate
      showArtistDescription
    }
  `,
})
