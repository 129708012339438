import { every, isArray } from 'lodash/fp'

function buildHasPermission(permissionSet: Set<string>) {
  const hasPermission = (p?: string | string[] | null) => {
    if (!p) return false
    if (!isArray(p)) return permissionSet.has(p)
    return every((it) => permissionSet.has(it), p)
  }

  return hasPermission
}

export default buildHasPermission
