/**
 * @generated SignedSource<<a9015dc252bd887fe680924f387608e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type FeesBehaviour = "APPEND_TO_CONTRACT" | "OVERRIDE" | "USE_CONTRACT" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventTicketTypePricing_event$data = {
  readonly addressCountry: string | null;
  readonly allowedLifecycleUpdates: {
    readonly onSaleDate: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly ticketTypes: {
      readonly canChangeTierNames: boolean | null;
      readonly canUpdatePrice: ReadonlyArray<string | null> | null;
    } | null;
  } | null;
  readonly basePriceFees: ReadonlyArray<string | null> | null;
  readonly billingPromoter: {
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly coolingOffPeriod: boolean | null;
    readonly countryCode: string | null;
    readonly disableUsTax: boolean | null;
    readonly label: string | null;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly value: string;
  } | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly countryCode: string | null;
  readonly disableUsTax: boolean | null;
  readonly eventIdLive: string | null;
  readonly eventSeatingChart: {
    readonly id: string;
  } | null;
  readonly eventType: EventType | null;
  readonly fees: ReadonlyArray<{
    readonly amount: number | null;
    readonly split: ReadonlyArray<{
      readonly amount: number | null;
      readonly destination: FeeDestination | null;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly type: FeeType;
    readonly unit: FeeUnit;
  } | null> | null;
  readonly feesBehaviour: FeesBehaviour | null;
  readonly freeEvent: boolean | null;
  readonly id: string;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly postFanPriceFees: ReadonlyArray<string | null> | null;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly priceTiersBreakdown: ReadonlyArray<{
        readonly appSold: number;
        readonly posSold: number;
        readonly priceTier: {
          readonly id: string;
        };
        readonly reserved: number;
        readonly sold: number;
        readonly terminalSold: number;
      } | null>;
      readonly ticketType: {
        readonly id: string;
        readonly ticketPoolId: string | null;
      };
      readonly ticketTypeId: string;
    } | null>;
  } | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly ticketPools: ReadonlyArray<{
    readonly id: string;
    readonly maxAllocation: number | null;
    readonly name: string | null;
  } | null> | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly allocation: number;
    readonly archived: boolean | null;
    readonly hidden: boolean;
    readonly id: string;
    readonly isStream: boolean;
    readonly priceHidden: boolean;
    readonly priceTierType: PriceTierTypes | null;
    readonly priceTiers: ReadonlyArray<{
      readonly allocation: number | null;
    } | null> | null;
    readonly salesLimit: number | null;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly capacity: number;
    readonly countryCode: string | null;
    readonly value: string;
  } | null> | null;
  readonly " $fragmentType": "EventTicketTypePricing_event";
};
export type EventTicketTypePricing_event$key = {
  readonly " $data"?: EventTicketTypePricing_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventTicketTypePricing_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disableUsTax",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v6 = [
  (v0/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v8 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./EventTicketTypePricingRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "EventTicketTypePricing_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feesBehaviour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basePriceFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postFanPriceFees",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Fee",
      "kind": "LinkedField",
      "name": "fees",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FeeSplit",
          "kind": "LinkedField",
          "name": "split",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "destination",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSeatingChart",
      "kind": "LinkedField",
      "name": "eventSeatingChart",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "doorSalesOnly",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "includeArchived",
          "value": true
        }
      ],
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archived",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStream",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceTierType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceHidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceTier",
          "kind": "LinkedField",
          "name": "priceTiers",
          "plural": true,
          "selections": [
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "onSaleDate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketTypes",
          "kind": "LinkedField",
          "name": "ticketTypes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeTierNames",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdatePrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        {
          "alias": "label",
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stripeAccountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platformAccountCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showPriceSuggestions",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSkipReview",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resoldEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coolingOffPeriod",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketPool",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketTypeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ticketPoolId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceTierBreakdown",
              "kind": "LinkedField",
              "name": "priceTiersBreakdown",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceTier",
                  "kind": "LinkedField",
                  "name": "priceTier",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reserved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "posSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terminalSold",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "075806dd00bfa740fdcce2360d9415bc";

export default node;
