import React, { useCallback, memo, FC, useContext, useMemo } from 'react'
import { get, getOr } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { isAfter, parseISO, startOfMinute } from 'date-fns'
import styled from 'styled-components/macro'
import { allowedEventAction } from '../services/allowedEventAction'
import { FormRow } from '../../../components/Form'
import SwitchField from '../../../components/SwitchField'
import { TitleTooltip, TooltipHelpIcon } from '../../../components/Tooltip'
import FormField from '../../../components/FormField'
import IEventFormTickets from '../types/Tickets'
import { localeContext } from '../../../context/locale'
import { autofillDate, generateDateFieldHint } from '../services/autofillDates'
import IEventForm from '../types'
import { authContext } from '../../../context/auth'
import AlertBox from '../../../components/AlertBox'
import { color, font, mediaQuery } from '../../../utils/variables'

interface IProps {
  readOnly?: boolean
}

const EventTicketTransfer: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const { values, touched, errors, setFieldValue, handleBlur } = useFormikContext<IEventFormTickets>()

  const onChangeTicketTransfer = useCallback(() => {
    const isActive = getOr(false, 'flags.ticketTransfer.active', values)
    if (isActive) {
      setFieldValue('flags.ticketTransfer.active', false)
      if (allowedEventAction(values.allowedLifecycleUpdates, 'endDate')) {
        setFieldValue('closeEventDate', null)
      }
    } else {
      setFieldValue('flags.ticketTransfer.active', true)
      if (values.date && allowedEventAction(values.allowedLifecycleUpdates, 'endDate')) {
        setFieldValue('closeEventDate', autofillDate(values as IEventForm, 'closeEventDate', user.diceStaff))
      }
    }
  }, [values, setFieldValue, user.diceStaff])

  const isAfterOffsaleDate = useMemo(
    () =>
      values.closeEventDate &&
      values.offSaleDate &&
      isAfter(parseISO(values.closeEventDate), parseISO(values.offSaleDate)),
    [values.closeEventDate, values.offSaleDate]
  )

  return (
    <>
      <FormRow columnOnMobile>
        <SwitchField
          label={
            <>
              {intl.formatMessage({ id: 'new_event.settings.flags.ticket_transfer.label' })}
              <TitleTooltip
                title={intl.formatMessage({
                  id: 'new_event.settings.flags.ticket_transfer.tooltip',
                })}
              >
                <TooltipHelpIcon icon="help" width={16} height={16} />
              </TitleTooltip>
            </>
          }
          hint={intl.formatMessage({ id: 'new_event.settings.flags.ticket_transfer.hint' })}
          error={touched.flags && get('flags.ticketTransfer.active', errors)}
          name="flags.ticketTransfer.active"
          checked={getOr(false, 'flags.ticketTransfer.active', values)}
          onChange={onChangeTicketTransfer}
          onBlur={handleBlur}
          disabled={readOnly}
        />
      </FormRow>
      {getOr(false, 'flags.ticketTransfer.active', values) && (
        <>
          <FormRow columnOnMobile>
            <FormField
              name="closeEventDate"
              timezone={values.timezoneName || undefined}
              label={intl.formatMessage({ id: 'new_event.tickets.transfer_deadline.label' })}
              control="datetime"
              value={values.closeEventDate}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              error={touched.closeEventDate && errors.closeEventDate}
              locale={locale}
              disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'endDate')}
              required
              hint={generateDateFieldHint(intl, values as IEventForm, 'TRANSFER_DEADLINE', values.closeEventDate)}
            />
          </FormRow>
          {isAfterOffsaleDate && (
            <FormRow columnOnMobile>
              <StyledAlertBox icon="info" color={color.warning} fullWidth>
                <div className="title">
                  {intl.formatMessage({ id: 'new_event.tickets.transfer_deadline.offsale_warning.title' })}
                </div>
                {intl.formatMessage({ id: 'new_event.tickets.transfer_deadline.offsale_warning.description' })}
              </StyledAlertBox>
            </FormRow>
          )}
        </>
      )}
    </>
  )
}

export default memo(EventTicketTransfer)

const StyledAlertBox = styled(AlertBox)`
  color: ${color.darkgrey};
  margin-top: -16px;

  ${mediaQuery.lessThan('tablet')`
    margin-top: -8px;
  `}

  .title {
    font-weight: ${font.weight.bold};
    color: ${color.text};
  }
`
