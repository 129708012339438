import React, { FC, memo, useMemo, useCallback, useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components/macro'
import { set } from 'lodash/fp'
import circle from '@turf/circle'
import { point } from '@turf/helpers'

import { MAPBOX_API_KEY } from '../env'
import { breakpoints, color } from '../utils/variables'
import Dots from './Dots'

const DEFAULT_ASPECT = 0.344

const Link = styled.a`
  display: inline-block;
`

const Map = styled.img`
  display: inline-block;
`

const Container = styled.div<{ isLoading: boolean; vertical: boolean; aspect: number }>`
  position: relative;
  background-color: ${color.palegrey};

  ${({ vertical }) => (vertical ? 'height: 100%;' : 'width: 100%;')}

  /* reserve proper space in newer browsers */
  aspect-ratio: ${({ aspect, vertical }) => (vertical ? aspect : `1 / ${aspect}`)};

  ${Map} {
    filter: ${({ isLoading }) => (isLoading ? 'blur(5px)' : 'none')};
    ${({ vertical }) => (vertical ? 'height: 100%;' : 'width: 100%;')}
  }
`

const CustomLoader = styled.div`
  position: absolute;

  top: 32px;
  left: 50%;
  margin-left: -35px;

  background-color: ${color.white};
  border-radius: 4px;
`

interface IProps {
  longitude: number
  latitude: number
  zoom: number

  radiusKm?: number

  bbox?: [number, number, number, number]

  className?: string

  vertical?: boolean
  aspect?: number

  withLink?: boolean

  apiKey?: string
}

const MapboxMap: FC<React.PropsWithChildren<IProps>> = ({
  longitude,
  latitude,
  zoom,
  bbox,
  radiusKm,
  className,
  vertical,
  aspect,
  withLink,
  apiKey,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const size = useMemo(() => {
    const maxSize = isMobile ? 320 : 640
    const ratio = aspect || DEFAULT_ASPECT

    const a = maxSize
    const b = Math.round(maxSize * ratio)

    return vertical ? `${b}x${a}` : `${a}x${b}`
  }, [aspect, isMobile, vertical])

  const googleMapsURL = useMemo(() => {
    const url = new URL('https://www.google.com/maps/search/')
    url.searchParams.append('api', '1')
    url.searchParams.append('query', [latitude, longitude].join(','))
    return url.toString()
  }, [latitude, longitude])

  const mapImageURL = useMemo(() => {
    const username = 'mapbox'
    const styleId = 'streets-v11'
    const lon = Math.round(longitude * 1000000) / 1000000
    const lat = Math.round(latitude * 1000000) / 1000000

    let overlay = null
    if (zoom > 0 && !bbox) {
      if (radiusKm) {
        overlay = set(
          'properties',
          { fill: '#cacafa', stroke: color.primary },
          circle([lon, lat], radiusKm, { steps: 50, units: 'kilometers' })
        )
      } else {
        overlay = set(
          'properties',
          { 'marker-size': 'small', 'marker-color': '#ea3b61', 'marker-symbol': 'circle' },
          point([lon, lat])
        )
      }
    }

    const overlayStr = overlay ? `/geojson(${encodeURIComponent(JSON.stringify(overlay))})` : ''

    const zoomLevel = isMobile && zoom > 2 ? zoom - 2 : zoom
    const bearing = 0
    const pitch = 0

    const place = bbox ? JSON.stringify(bbox) : `${lon},${lat},${zoomLevel},${bearing},${pitch}`

    const urlStr =
      // eslint-disable-next-line max-len
      `https://api.mapbox.com/styles/v1/${username}/${styleId}/static${overlayStr}/${place}/${size}@2x`

    const url = new URL(urlStr)
    url.searchParams.append('access_token', apiKey || MAPBOX_API_KEY)

    return url.toString()
  }, [apiKey, bbox, isMobile, latitude, longitude, radiusKm, size, zoom])

  const [loading, setLoading] = useState(false)
  const stopLoading = useCallback(() => setLoading(false), [])

  useEffect(() => {
    if (!mapImageURL) return
    setLoading(true)

    const timeout = setTimeout(stopLoading, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [mapImageURL, stopLoading])

  const mapEl = useMemo(() => <Map alt="Map" src={mapImageURL} onLoad={stopLoading} />, [mapImageURL, stopLoading])

  const mapBody = (
    <>
      {mapEl}
      {loading && (
        <CustomLoader>
          <Dots />
        </CustomLoader>
      )}
    </>
  )

  return (
    <Container vertical={!!vertical} aspect={aspect || DEFAULT_ASPECT} isLoading={loading}>
      {withLink ? (
        <Link className={className} href={googleMapsURL} target="_blank" rel="noopener noreferrer">
          {mapBody}
        </Link>
      ) : (
        mapBody
      )}
    </Container>
  )
}

export default styled(memo(MapboxMap))``
