import { useEffect, useState } from 'react'
import { map } from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'

import { useDebounce } from '../../../utils/hooks/useDebounce'
import getSalesTax from '../../../utils/getSalesTax'

interface IProps {
  doorPrices: {
    doorSalesPrice: number
    doorSalesTax: number
    doorSalesPriceTaxed: number
  }
  venueId: string | null
  eventId: string | null
}

function useGetSalesTax(doorPrices: IProps['doorPrices'][], venueId: IProps['venueId'], eventId: IProps['eventId']) {
  const debouncedInput = useDebounce(doorPrices, 1000)
  const [loading, setLoading] = useState(true)
  const [doorPriceBreakdown, setDoorPriceBreakdown] = useState(doorPrices)
  const environment = useRelayEnvironment()

  useEffect(() => {
    let stillMounted = true
    setLoading(true)
    const promises = map(
      (breakdown) => getSalesTax(environment, breakdown?.doorSalesPrice, venueId, eventId),
      debouncedInput
    )

    Promise.all(promises)
      .then((results) => {
        setLoading(false)
        if (stillMounted) {
          const breakdown = results.map((tax, idx) => ({
            doorSalesPrice: debouncedInput[idx].doorSalesPrice || 0,
            doorSalesTax: tax || 0,
            doorSalesPriceTaxed: (debouncedInput[idx].doorSalesPrice || 0) + (tax || 0),
          }))
          setDoorPriceBreakdown(breakdown)
        }
      })
      .finally(() => {
        if (stillMounted) {
          setLoading(false)
        }
      })

    return () => {
      stillMounted = false
    }
  }, [venueId, debouncedInput, eventId, environment])

  return {
    loading,
    doorPriceBreakdown,
  }
}

export default useGetSalesTax
