import React, { FC, Fragment, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import cn from 'classnames'
import { indexBy, isArray, sortBy } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import { EventCostCurrency } from '../../../enums.generated'
import { CURRENCY } from '../../../utils/formatters/number'
import { breakpoints, color, mediaQuery } from '../../../utils/variables'

import Table, { TableHeader, HeaderCell, TableBody, Row, Cell } from '../../../components/Table'

import { IFeeRange } from '../types/Fees'
import IconButton from '../../../components/IconButton'

const Wrapper = styled.div``
const Title = styled.div`
  flex: 1;
  max-width: 470px;
  margin-bottom: -8px;
`
const Controls = styled.div`
  margin: -10px 0 -10px auto;
  flex: 88px 0 0;
`
const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 24px;
`
const StyledTable = styled(Table)`
  ${HeaderCell}, ${Cell} {
    min-width: auto;
    border-color: ${color.lightgrey};
    padding-left: 0;
    &:last-child {
      padding-right: 0;
    }
    &.-large {
      width: 40%;
      min-width: 260px;
      ${mediaQuery.lessThan('tablet')`
        min-width: auto;
      `}
    }
  }
  ${HeaderCell} {
    padding-bottom: 12px;
  }
  ${TableBody} {
    ${Cell} {
      border-top: 1px solid ${color.lightgrey};
      &.-no-border {
        border-color: transparent;
      }
    }
    .-mobile-price-range {
      ${Cell} {
        padding-top: 16px;
        padding-bottom: 4px;
      }
      & + ${Row} {
        ${Cell} {
          border-top: 0;
        }
      }
    }
  }
`

const PlaceholderCell = styled(HeaderCell)`
  width: 60px;
`

const CustomBadge = styled.div`
  display: inline-block;
  padding: 3px 8px;
  background: ${color.lightgrey};
  margin: 0 8px 8px 0;
  border-radius: 4px;
`

interface IProps {
  isAdhoc?: boolean
  title?: string | Array<string>
  feeRanges: Array<IFeeRange | null> | null
  currency: EventCostCurrency | null
  ruleId?: string
  onEdit?: (id: string) => void
  onRemove?: (id: string) => void
}

const FeeRulePreview: FC<IProps> = ({ isAdhoc, title, feeRanges, currency, ruleId, onEdit, onRemove }) => {
  const intl = useIntl()
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const editRule = useCallback(() => {
    if (ruleId && onEdit) onEdit(ruleId)
  }, [onEdit, ruleId])

  const removeRule = useCallback(() => {
    if (ruleId && onRemove) onRemove(ruleId)
  }, [onRemove, ruleId])

  const formattedTitle = useMemo(
    () => 
      isArray(title) ? (
        title.map((t) => <CustomBadge key={t}>{t}</CustomBadge>)
      ) : (
        <CustomBadge>{title}</CustomBadge>
      ), 
    [title]
  )

  const sortedFeeRanges = useMemo(() => sortBy('fromBasePrice', feeRanges), [feeRanges])

  return (
    <Wrapper>
      {title && (
        <Header>
          <Title>{formattedTitle}</Title>
          {(onEdit || onRemove) && (
            <Controls>
              {onRemove && <IconButton icon="trash" onClick={removeRule} />}
              {onEdit && <IconButton icon="edit" onClick={editRule} />}
            </Controls>
          )}
        </Header>
      )}

      <StyledTable>
        <TableHeader>
          <Row>
            {!isMobile && (
              <HeaderCell>
                <strong>{intl.formatMessage({ id: 'create_contract_form.fees_preview.price_range' })}</strong>
              </HeaderCell>
            )}
            <HeaderCell className="-large">
              <strong>{intl.formatMessage({ id: 'create_contract_form.fees_preview.type' })}</strong>
            </HeaderCell>
            {!isAdhoc ? (
              <HeaderCell textAlign="right">
                <strong>{intl.formatMessage({ id: 'create_contract_form.fees_preview.fee' })}</strong>
              </HeaderCell>
            ) : (
              <PlaceholderCell>&nbsp;</PlaceholderCell>
            )}
            <HeaderCell textAlign="right">
              <strong>{intl.formatMessage({ id: 'create_contract_form.fees_preview.split.keep' })}</strong>
            </HeaderCell>
            <HeaderCell textAlign="right">
              <strong>{intl.formatMessage({ id: 'create_contract_form.fees_preview.split.billingPromoter' })}</strong>
            </HeaderCell>
          </Row>
        </TableHeader>
        <TableBody>
          {sortedFeeRanges?.map((range, idx) =>
            range?.fees?.map((fee, i) => {
              if (!range || !fee) return null
              const startOfRange = intl.formatNumber(
                (range.fromBasePrice || 0) / 100,
                CURRENCY(range.fromBasePrice || 0, currency)
              )
              const endOfRange = sortedFeeRanges?.[idx + 1]
                ? intl.formatNumber(
                  ((sortedFeeRanges?.[idx + 1]?.fromBasePrice || 0) - 1 || 0) / 100,
                  CURRENCY((sortedFeeRanges?.[idx + 1]?.fromBasePrice || 0) - 1 || 0, currency)
                )
                : '∞'
              const currentFeeRange =
                i === 0
                  ? sortedFeeRanges?.length < 2
                    ? intl.formatMessage({ id: 'fees.contract.fee_ranges.any_price_range' })
                    : `${startOfRange} - ${endOfRange}`
                  : null

              const splits: any = indexBy('destination', fee?.split || [])

              return (
                <Fragment key={`${range.id}-${i}`}>
                  {isMobile && i === 0 && (
                    <Row className="-mobile-price-range">
                      <Cell colSpan={4} data-id="feeRange">
                        <strong>{intl.formatMessage({ id: 'create_contract_form.fees_preview.price_range' })}</strong>
                        {': '}
                        {currentFeeRange}
                      </Cell>
                    </Row>
                  )}
                  <Row>
                    {!isMobile && (
                      <Cell className={cn({ '-no-border': i !== 0 })} data-id="feeRange">
                        {currentFeeRange}
                      </Cell>
                    )}
                    <Cell data-id="feeType">{intl.formatMessage({ id: `fees.${fee.type}` })}</Cell>
                    {!isAdhoc ? (
                      <Cell textAlign="right" data-id="amount">
                        {fee?.unit === 'percentage'
                          ? intl.formatNumber((fee?.amount || 0) / 100, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            style: 'percent',
                          })
                          : intl.formatNumber((fee?.amount || 0) / 100, CURRENCY(fee?.amount || 0, currency))}
                      </Cell>
                    ) : (
                      <Cell>&nbsp;</Cell>
                    )}
                    <Cell textAlign="right" data-id="toDice">
                      {splits.keep?.unit === 'percentage'
                        ? intl.formatNumber((splits.keep?.amount || 0) / 100, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: 'percent',
                        })
                        : intl.formatNumber(
                          (splits.keep?.amount || 0) / 100,
                          CURRENCY(splits.keep?.amount || 0, currency)
                        )}
                    </Cell>
                    <Cell textAlign="right" data-id="toPromoter">
                      {splits.billingPromoter?.unit === 'percentage'
                        ? intl.formatNumber((splits.billingPromoter?.amount || 0) / 100, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: 'percent',
                        })
                        : intl.formatNumber(
                          (splits.billingPromoter?.amount || 0) / 100,
                          CURRENCY(splits.billingPromoter?.amount || 0, currency)
                        )}
                    </Cell>
                  </Row>
                </Fragment>
              )
            })
          )}
        </TableBody>
      </StyledTable>
    </Wrapper>
  )
}

export default FeeRulePreview
