import React, { FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useFormikContext } from 'formik'
import { compact, reject, concat, findIndex, set, update, find, getOr, without, sum, isNumber, filter } from 'lodash/fp'
import { useIntl } from 'react-intl'
import arrayMove from 'array-move'
import { isPast, parseISO } from 'date-fns'

import { authContext } from '../../../context/auth'
import { localeContext } from '../../../context/locale'
import { featureFlagsContext } from '../../../context/featureFlags'
import { trackingContext } from '../../../context/tracking'

import { isNew, markAsClientOnly } from '../../../utils/entityStatus'
import { isItalianEvent } from '../../../utils/isCountryEvent'
import { color, font, mediaQuery, spacing } from '../../../utils/variables'
import unwrapId from '../../../utils/unwrapId'

import { AlertModal } from '../../../components/AlertModal'
import Button from '../../../components/Button'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import EmptyState from '../../../components/EmptyState'
import { FormRow } from '../../../components/Form'
import ListAddButton from '../../../components/ListAddButton'
import Svg from '../../../components/Svg'
import { Text } from '../../../components/Text'

import useAddTicketType from '../hooks/useAddTicketType'
import useRemoveTicketType from '../hooks/useRemoveTicketType'
import useSeatedTicketTypes from '../hooks/useSeatedTicketTypes'
import useSoldOutTickets from '../hooks/useSoldOutTickets'
import { allowedEventAction } from '../services/allowedEventAction'
import IEventFormTickets, { ITicketPool, ITicketType } from '../types/Tickets'

import EventTicketTypeModal from './EventTicketTypeModal'
import EventTicketTypeList from './EventTicketTypeList'
import { textStyle } from '../../../utils/typography'

const AddRow = styled(FormRow)<{ canAdd: boolean }>`
  border-top: ${({ canAdd }) => (canAdd ? `1px solid ${color.lightgrey}` : 'none')};
  padding-top: 16px;
`

const IconWrapper = styled.div`
  display: flex;
  flex: 48px 0 0;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${color.lightgrey};
`

const TextWrapper = styled.div`
  margin: 0 32px 0 16px;
`

const NoTicketTypes = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${spacing.md}px;
  padding-top: 24px;
  border-top: 1px solid ${color.lightgrey};

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
    justify-content: center;

    ${TextWrapper} {
      max-width: 320px;
      margin: 16px 32px 24px;
      text-align: center;
    }
  `}
`

const StyledEmptyState = styled(EmptyState)<{ hasError?: boolean }>`
  border: 2px solid ${({ hasError }) => (hasError ? color.error : color.lightgrey)};
  h2,
  p {
    max-width: 368px;
  }
  h2 {
    ${textStyle.interactive.lg}
    margin-bottom: 4px;
  }
  p {
    color: ${color.text};
    font-size: ${font.size.sm}px;
  }
`

const TicketPoolRemaining = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background: rgba(255, 168, 26, 0.1);
  border-radius: 4px;
  padding: 12px;
  font-size: ${font.size.sm}px;
  margin: 8px 0 16px;
  &:last-child {
    margin-bottom: 0;
  }
`

interface IEventTicketTypesProps {
  readOnly?: boolean
  archived?: boolean
  ticketTypes: ITicketType[]
  ticketPool?: ITicketPool
}

const EventTicketTypes: FC<PropsWithChildren<IEventTicketTypesProps>> = ({
  children,
  readOnly,
  archived,
  ticketTypes,
  ticketPool,
}) => {
  const intl = useIntl()

  const { hasFeatureFlag } = useContext(featureFlagsContext)
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const { trackEvent } = useContext(trackingContext)

  const { values, setFieldValue, setFieldTouched, errors, touched, validateForm } =
    useFormikContext<IEventFormTickets>()

  const allowedLifecycleUpdates = values.allowedLifecycleUpdates

  const canAddTicketPool =
    !readOnly &&
    hasFeatureFlag('ticketPools') &&
    (values.state === 'DRAFT' || allowedEventAction(allowedLifecycleUpdates, 'ticketPools', 'canAdd'))

  const canAddTicketType =
    !readOnly && (values.state === 'DRAFT' || allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canAdd'))

  const allTtys = useMemo(() => compact(values.ticketTypes || []) as ITicketType[], [values.ticketTypes])

  const [modalId, setModalId] = useState<string | null>(null)

  const showEditModal = useCallback(
    (id: string) => {
      const trackData = {
        event_id: unwrapId(values.id),
        event_id_live: values.eventIdLive,
        ticket_type_id: unwrapId(id),
      }

      trackEvent('ticket_type_edit_clicked', trackData)

      setModalId(id)
    },
    [trackEvent, values.eventIdLive, values.id]
  )

  const seated = getOr(false, 'flags.seated.active', values)

  const reorderTicketTypes = useCallback(
    ({ oldIndex, newIndex }: any) => {
      const restOfTicketTypes = without(ticketTypes, allTtys)
      setFieldValue('ticketTypes', concat(arrayMove(ticketTypes, oldIndex, newIndex), restOfTicketTypes))
    },
    [ticketTypes, allTtys, setFieldValue]
  )

  const closeEditModal = useCallback(() => {
    setModalId(null)
    setFieldValue('ticketTypes', reject(isNew, allTtys))
  }, [allTtys, setFieldValue])

  const saveTicketType = useCallback(
    (ticketType: any) => {
      setFieldValue(
        'ticketTypes',
        set(
          [findIndex(['id', ticketType.id], values.ticketTypes)],
          update('id', (id) => (isNew({ id }) ? markAsClientOnly({ id }).id : id), ticketType),
          allTtys
        )
      )
      setModalId(null)
      setFieldTouched('ticketTypes', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, values.ticketTypes, allTtys, setFieldTouched, validateForm]
  )

  const isOffSale = useMemo(
    () => (values.offSaleDate ? isPast(parseISO(values.offSaleDate)) : false),
    [values.offSaleDate]
  )

  const noTicketTypes = !ticketTypes || ticketTypes.length === 0
  const noTicketPools = (values.ticketPools?.length || 0) === 0

  const [generateSeatedTicketTypes, generating] = useSeatedTicketTypes()

  const soldOutMap = useSoldOutTickets(allTtys)

  const ticketTypeTotalSales = useMemo(() => {
    const ttySales: Record<string, number> = {}
    values.sales?.ticketTypesBreakdown.forEach((ttyb) => {
      const ttyId = ttyb?.ticketTypeId
      if (!ttyId) return
      const totalSold = ttyb.totalAppSold + ttyb.totalPosSold
      ttySales[ttyId] = totalSold
    })
    return ttySales
  }, [values.sales?.ticketTypesBreakdown])

  const ctx = { canAdd: canAddTicketType, ticketPool, seated, onAdd: setModalId, soldOutMap }
  const { addTicketType, copyTicketType } = useAddTicketType(allTtys, ctx)
  const {
    handleRemoveTicketType,
    restoreTicketType,
    problemAlert,
    closeProblemAlert,
    confirmRemoveAlert,
    closeConfirmRemoveAlert,
  } = useRemoveTicketType(allTtys, ctx)

  const isItalian = useMemo(() => isItalianEvent(values, locale), [locale, values])

  const addTicketPool = useCallback(() => {
    setFieldValue(
      'ticketPools',
      concat(
        values.ticketPools || [],
        markAsClientOnly<ITicketPool>({
          name: `${intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.ticket_pool_id.label' })} ${String(
            (values.ticketPools || []).length + 1
          ).padStart(2, '0')}`,
          maxAllocation: 0,
        })
      )
    )
  }, [setFieldValue, values.ticketPools, intl])

  const poolAllocation = ticketPool?.maxAllocation || 0

  const ticketPoolRemaining = useMemo(() => {
    if (!ticketPool || !allTtys.length) return 0
    const poolTtys = filter((tty) => tty.ticketPoolId === ticketPool.id, allTtys)
    if (!poolTtys.length) return 0

    const salesLimits = poolTtys.map((tty) => tty.salesLimit).filter(isNumber)
    const someTicketsHaveNoLimit = salesLimits.length < poolTtys.length

    return someTicketsHaveNoLimit ? 0 : poolAllocation - sum(salesLimits)
  }, [allTtys, poolAllocation, ticketPool])

  if (!archived && noTicketTypes && noTicketPools) {
    return (
      <StyledEmptyState
        icon="ticket"
        title={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.empty_title' })}
        description={intl.formatMessage({
          id: `new_event.tickets.ticket_types.list.empty_message${values.eventType !== 'LIVE' ? '_stream' : ''}`,
        })}
        hasError={touched.ticketTypes && !!errors.ticketTypes}
      >
        {canAddTicketPool && !seated && (
          <Button preset="secondary" disabled={generating} onClick={addTicketPool}>
            {intl.formatMessage({ id: 'new_event.tickets.ticket_pools.list.create_button' })}
          </Button>
        )}
        {canAddTicketType && !(!user.diceStaff && hasFeatureFlag('ticketPools') && !seated) && (
          <Button onClick={addTicketType} loading={generating} preset={seated ? 'secondary' : undefined}>
            {intl.formatMessage({
              id: 'new_event.tickets.ticket_types.list.empty_add_button',
            })}
          </Button>
        )}
        {canAddTicketType && !(!user.diceStaff && hasFeatureFlag('ticketPools') && !seated) && seated && (
          <Button onClick={generateSeatedTicketTypes} loading={generating}>
            {intl.formatMessage({
              id: 'new_event.tickets.ticket_types.list.empty_generate_button',
            })}
          </Button>
        )}
      </StyledEmptyState>
    )
  }

  return (
    <div data-name={archived ? 'archivedTicketTypes' : 'ticketTypes'} data-disabled={isOffSale || archived}>
      {ticketPool && !noTicketTypes && (
        <Text display="block" bold className="mt-lg">
          {intl.formatMessage({ id: 'ticket_type' })}
        </Text>
      )}
      <EventTicketTypeList
        ticketTypes={ticketTypes}
        soldOutMap={soldOutMap}
        ticketTypeTotalSales={ticketTypeTotalSales}
        currency={values.costCurrency || undefined}
        onSortEnd={reorderTicketTypes}
        removeTicketType={handleRemoveTicketType}
        restoreTicketType={restoreTicketType}
        copyTicketType={copyTicketType}
        showEditModal={showEditModal}
        lockAxis="y"
        useDragHandle
        errors={errors}
        modalId={modalId}
        sortingDisabled={
          !!ticketPool ||
          readOnly ||
          (values.state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeOrder'))
        }
        removeDisabled={
          readOnly ||
          (values.state !== 'DRAFT' &&
            ((!allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canHide') &&
              !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canDelete')) ||
              ticketTypes.length === 1))
        }
        copyDisabled={
          readOnly ||
          (values.state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canAdd'))
        }
        isTicketPool={!!ticketPool}
        isSeated={getOr(false, 'flags.seated.active', values) && values.eventType !== 'STREAM'}
        isItalian={isItalian}
        eventOnSaleDate={values.onSaleDate}
        isShoppingCartEnabled={getOr(false, 'flags.shoppingCart.active', values)}
      />
      {ticketPoolRemaining > 0 && (
        <TicketPoolRemaining>
          <Svg icon="info" className="color-warning mr-sm" />
          <span>
            {intl.formatMessage(
              { id: 'new_event.tickets.ticket_pool.sales_limit_non_purchasable_warn' },
              { b: (str: string) => <strong>{str}</strong>, count: ticketPoolRemaining }
            )}
          </span>
        </TicketPoolRemaining>
      )}
      {!archived && (canAddTicketType || children) && (
        <AddRow columnOnMobile canAdd={canAddTicketType && !noTicketTypes}>
          {canAddTicketType &&
            (noTicketTypes ? (
              ticketPool ? (
                <NoTicketTypes>
                  <IconWrapper>
                    <Svg icon="ticket" />
                  </IconWrapper>
                  <TextWrapper>
                    <Text bold>{intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.empty_title' })}</Text>
                    <Text fontSize="sm" color="darkgrey">
                      {intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.empty_message' })}
                    </Text>
                  </TextWrapper>
                  <Button onClick={addTicketType}>
                    {intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.empty_add_button' })}
                  </Button>
                </NoTicketTypes>
              ) : null
            ) : (
              <ListAddButton
                className="mt-zero"
                label={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.add_button' })}
                onClick={addTicketType}
              />
            ))}

          {children}
        </AddRow>
      )}

      {problemAlert && (
        <AlertModal title={problemAlert.title} description={problemAlert.subtitle} onClose={closeProblemAlert} />
      )}

      {confirmRemoveAlert && (
        <ConfirmationModal
          icon="alert"
          title={confirmRemoveAlert.title}
          description={confirmRemoveAlert.subtitle}
          cta={confirmRemoveAlert.cta}
          onConfirm={confirmRemoveAlert.onConfirm}
          onReject={closeConfirmRemoveAlert}
        >
          <Button preset="secondary" onClick={confirmRemoveAlert.onConfirm}>
            {intl.formatMessage({ id: 'confirm' })}
          </Button>
        </ConfirmationModal>
      )}

      {modalId !== null && find(['id', modalId], ticketTypes) ? (
        <EventTicketTypeModal
          event={null}
          eventForm={values}
          ticketTypeId={modalId}
          onClose={closeEditModal}
          onSave={saveTicketType}
          readOnly={readOnly}
        />
      ) : null}
    </div>
  )
}

export default EventTicketTypes
