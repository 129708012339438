import React, { FC, memo, useContext, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { useMediaQuery } from 'react-responsive'
import { find, map } from 'lodash/fp'

import { isPast, parseISO } from 'date-fns'
import { Form, FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'

import { localeContext } from '../../../context/locale'

import EventSchedule, { ILineup } from '../components/EventSchedule'
import { IFormStep } from '../services/getStepsConfig'
import { allowedEventAction } from '../services/allowedEventAction'
import IEventFormTimeline from '../types/Timeline'
import useTimelineDates from '../hooks/useTimelineDates'
import { breakpoints, color, mediaQuery } from '../../../utils/variables'
import FormHeader from '../../../components/FormHeader'
import { authContext } from '../../../context/auth'
import { ScheduleStatus } from '../../../enums.generated'
import { getTimezoneOption } from '../../../utils/calendar'
import IEventForm from '../types'
import AlertBox from '../../../components/AlertBox'
import AutoRescheduledEventRefundsFormControls from '../../../components/AutoRescheduledEventRefundsFormControls'
import { generateDateFieldHint } from '../services/autofillDates'
import TimezoneOptionLabel from '../../../components/Select/TimezoneOptionLabel'
import EventAbbonamento from '../components/EventAbbonamento/EventAbbonamento'
import { isItalianEvent } from '../../../utils/isCountryEvent'

const MarginTopFormRow = styled(FormRow)`
  margin-top: 32px;
`
const StyledAlertBox = styled(AlertBox)`
  margin-top: -16px;

  ${mediaQuery.lessThan('tablet')`
    margin-top: -8px;
  `}
`

const Frame = styled.div`
  border: 2px solid ${color.palegrey};
  border-radius: 6px;
  padding: 16px;
  margin: 32px 0;
`

const TimelineStep: FC<React.PropsWithChildren<IFormStep>> = ({ children, readOnly }) => {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const { values, touched, errors, setFieldValue, handleBlur, setFieldTouched, validateForm, initialValues } =
    useFormikContext<IEventForm>()

  const isItalianNts = useMemo(
    () => isItalianEvent(values, locale) && !values.attractiveFields?.integrationDisabled,
    [values, locale]
  )

  const { setDateValue, timeZoneValue, timeZoneOptions, handleTimezoneChange } = useTimelineDates(readOnly)

  const setLineup = useCallback(
    (v: any) => {
      setFieldValue('lineup', v)
      setFieldTouched('lineup', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  const scheduleStatusOptions = useMemo(
    () =>
      map(
        (k) => ({
          value: k,
          label: intl.formatMessage({
            id: `new_event.timeline.schedule_status.options.${(k || 'going_ahead')?.toLowerCase()}`,
          }),
        }),
        ['', 'POSTPONED', 'RESCHEDULED']
      ),
    [intl]
  )
  const scheduleStatusOption = useMemo(
    () => find(['value', values.scheduleStatus || ''], scheduleStatusOptions),
    [scheduleStatusOptions, values.scheduleStatus]
  )
  const setScheduleStatus = useCallback(
    (id: ScheduleStatus | null) => {
      setFieldValue('scheduleStatus', id)

      if (id === 'POSTPONED') {
        setFieldValue('flags.disableDayOfEventComms.active', true, true)
      } else {
        setFieldValue('flags.disableDayOfEventComms.active', false, true)
      }

      if (
        id === 'RESCHEDULED' &&
        values.billingPromoter?.autoRescheduledEventRefunds &&
        hasPermission('auto_rescheduled_event_refunds:event')
      ) {
        setFieldValue(
          'flags.autoRescheduledEventRefunds.active',
          values.billingPromoter.autoRescheduledEventRefunds?.active || false
        )
        setFieldTouched('flags.autoRescheduledEventRefunds.active', true, true)
        setFieldValue(
          'flags.autoRescheduledEventRefunds.cutoff_days',
          values.billingPromoter.autoRescheduledEventRefunds?.cutoffDays || null
        )
        setFieldTouched('flags.autoRescheduledEventRefunds.cutoff_days', true, true)
        setFieldValue(
          'flags.autoRescheduledEventRefunds.cutoffDaysCustom',
          !!values.billingPromoter.autoRescheduledEventRefunds?.cutoffDays || false
        )
        setFieldTouched('flags.autoRescheduledEventRefunds.cutoffDaysCustom', true, true)
      }
    },
    [hasPermission, setFieldTouched, setFieldValue, values.billingPromoter?.autoRescheduledEventRefunds]
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })
  const startDateIsPast = useMemo(() => isPast(parseISO(values.date || '')), [values.date])

  const allowedTimezoneUpdate = useMemo(
    () =>
      !values.timezoneName ||
      values.state === 'DRAFT' ||
      (allowedEventAction(values.allowedLifecycleUpdates, 'announceDate') &&
        allowedEventAction(values.allowedLifecycleUpdates, 'onSaleDate') &&
        allowedEventAction(values.allowedLifecycleUpdates, 'offSaleDate') &&
        allowedEventAction(values.allowedLifecycleUpdates, 'date') &&
        allowedEventAction(values.allowedLifecycleUpdates, 'endDate')),
    [values.allowedLifecycleUpdates, values.state, values.timezoneName]
  )

  const showError = useCallback(
    (field: keyof IEventFormTimeline) => (values.state !== 'DRAFT' ? true : touched[field]),
    [touched, values.state]
  )

  const isAttractiveLocked =
    !!values.attractiveStatus && (!!values.attractiveFields?.forceSubscription || !user.diceStaff)

  const timezoneNameHint = useMemo(() => {
    return (
      <>
        {values.timezoneName
          ? intl.formatMessage({ id: 'venue_form.information.timezone.hint' }, { code: timeZoneValue?.value })
          : null}
        {values.venues?.length && values.venues[0]?.timezoneName ? (
          <div>
            {intl.formatMessage(
              { id: 'venue_form.information.timezone.venue_hint' },
              { tz: getTimezoneOption(intl, values.venues[0].timezoneName)?.value }
            )}
          </div>
        ) : null}
      </>
    )
  }, [intl, timeZoneValue?.value, values.timezoneName, values.venues])

  const shouldShowOffsaleDateWarning = useMemo(
    () =>
      (values.doorlistRecipients?.length || 0) + (values.doorlistAdditionalRecipients?.length || 0) > 0 &&
      (values.doorlistSendStatus === 'delivered' || values.offSaleSentStatus === 'sent') &&
      values.offSaleDate !== initialValues.offSaleDate,
    [
      initialValues.offSaleDate,
      values.doorlistAdditionalRecipients?.length,
      values.doorlistRecipients?.length,
      values.doorlistSendStatus,
      values.offSaleDate,
      values.offSaleSentStatus,
    ]
  )

  return (
    <Form spacing={isMobile ? 'default' : 'extra'}>
      <FormHeader
        header={intl.formatMessage({ id: 'new_event.steps.timeline' })}
        subheader={intl.formatMessage({ id: 'new_event.timeline.description' })}
      />
      {user.diceStaff && (
        <>
          <FormRow columnOnMobile>
            <FormField
              name="scheduleStatus"
              label={intl.formatMessage({ id: 'new_event.timeline.schedule_status.label' })}
              control="select"
              options={scheduleStatusOptions}
              value={scheduleStatusOption}
              onChange={setScheduleStatus}
              required
              disabled={readOnly}
              dice
            />
          </FormRow>

          {scheduleStatusOption?.value === 'RESCHEDULED' && (
            <Frame>
              <AutoRescheduledEventRefundsFormControls
                disabled={!hasPermission('auto_rescheduled_event_refunds:event')}
                pathToAutoRescheduledEventRefunds="flags"
                snakeCase
                dice
                showCutoffDaysHint
                switchCopyOverride={intl.formatMessage({ id: 'new_event.timeline.rescheduled_events.label' })}
                switchHintCopyOverride={intl.formatMessage({ id: 'new_event.timeline.rescheduled_events.hint' })}
              />
            </Frame>
          )}
        </>
      )}
      <FormRow columnOnMobile>
        <FormField
          name="timezoneName"
          label={intl.formatMessage({ id: 'new_event.timeline.timezone.label' })}
          hint={timezoneNameHint}
          control="select"
          options={timeZoneOptions}
          value={timeZoneValue}
          onChange={handleTimezoneChange}
          onBlur={handleBlur}
          error={(touched.timezoneName || values.timezoneName) && errors.timezoneName}
          disabled={readOnly || !allowedTimezoneUpdate}
          help={intl.formatMessage({
            id: 'new_event.timeline.timezone.help',
          })}
          optionLabel={TimezoneOptionLabel}
          required
        />

        <FormField
          name="announceDate"
          timezone={values.timezoneName || undefined}
          label={intl.formatMessage({ id: 'new_event.timeline.announced.label' })}
          control="datetime"
          placement="top-start"
          value={values.announceDate}
          setFieldValue={setDateValue}
          onBlur={handleBlur}
          error={showError('announceDate') && errors.announceDate}
          locale={locale}
          disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'announceDate')}
          required
          hint={generateDateFieldHint(intl, values, 'ANNOUNCE_DATE', values.announceDate)}
        />
      </FormRow>
      <FormRow columnOnMobile>
        <FormField
          name="onSaleDate"
          timezone={values.timezoneName || undefined}
          label={intl.formatMessage({ id: 'new_event.timeline.on_sale.label' })}
          control="datetime"
          placement="top-start"
          value={values.onSaleDate}
          setFieldValue={setDateValue}
          onBlur={handleBlur}
          error={showError('onSaleDate') && errors.onSaleDate}
          locale={locale}
          disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'onSaleDate')}
          required
          hint={generateDateFieldHint(intl, values, 'ON_SALE_DATE', values.onSaleDate)}
        />
        <FormField
          name="offSaleDate"
          timezone={values.timezoneName || undefined}
          label={intl.formatMessage({ id: 'new_event.timeline.off_sale.label' })}
          control="datetime"
          placement="top-start"
          value={values.offSaleDate}
          setFieldValue={setDateValue}
          onBlur={handleBlur}
          error={showError('offSaleDate') && errors.offSaleDate}
          locale={locale}
          disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'offSaleDate')}
          required
          hint={generateDateFieldHint(intl, values, 'OFF_SALE_DATE', values.offSaleDate)}
        />
      </FormRow>
      {shouldShowOffsaleDateWarning && (
        <FormRow columnOnMobile>
          <StyledAlertBox
            icon="info"
            color={color.warning}
            fullWidth
            title={intl.formatMessage({ id: 'new_event.timeline.doorlist_already_sent_warning.title' })}
            body={intl.formatMessage({ id: 'new_event.timeline.doorlist_already_sent_warning.description' })}
          />
        </FormRow>
      )}
      <FormRow columnOnMobile>
        <FormField
          name="date"
          timezone={values.timezoneName || undefined}
          label={intl.formatMessage({ id: 'new_event.timeline.start.label' })}
          control="datetime"
          placement="top-start"
          value={values.date}
          setFieldValue={setDateValue}
          onBlur={handleBlur}
          error={showError('date') && errors.date}
          locale={locale}
          disabled={readOnly || !(allowedEventAction(values.allowedLifecycleUpdates, 'date') && !isAttractiveLocked)}
          hint={
            !readOnly && isAttractiveLocked && allowedEventAction(values.allowedLifecycleUpdates, 'endDate')
              ? intl.formatMessage({ id: 'nts100_field_locked' })
              : generateDateFieldHint(intl, values, 'DATE', values.date)
          }
          required
        />
        <FormField
          name="endDate"
          timezone={values.timezoneName || undefined}
          label={intl.formatMessage({ id: 'new_event.timeline.end.label' })}
          control="datetime"
          placement="top-start"
          value={values.endDate}
          setFieldValue={setDateValue}
          onBlur={handleBlur}
          error={showError('endDate') && errors.endDate}
          locale={locale}
          disabled={readOnly || !(allowedEventAction(values.allowedLifecycleUpdates, 'endDate') && !isAttractiveLocked)}
          hint={
            !readOnly && isAttractiveLocked && allowedEventAction(values.allowedLifecycleUpdates, 'endDate')
              ? intl.formatMessage({ id: 'nts100_field_locked' })
              : generateDateFieldHint(intl, values, 'END_DATE', values.endDate)
          }
          required
        />
      </FormRow>

      {startDateIsPast && (values.state === 'DRAFT' || values.state === 'SUBMITTED' || values.state === 'REVIEW') && (
        <FormRow>
          <StyledAlertBox icon="info" color={color.warning} fullWidth>
            {intl.formatMessage({ id: 'new_event.timeline.start.warning' })}
          </StyledAlertBox>
        </FormRow>
      )}

      <MarginTopFormRow columnOnMobile>
        <EventSchedule
          handleBlur={handleBlur}
          lineup={values.lineup as ILineup[] | null}
          setLineup={setLineup}
          event={values}
          errors={errors}
          touched={touched}
          allowEdit={!readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'lineUp')}
          timezoneName={values.timezoneName}
        />
      </MarginTopFormRow>

      {isItalianNts && <EventAbbonamento readOnly={readOnly} />}

      {children}
    </Form>
  )
}

export default createFragmentContainer(memo(TimelineStep), {
  event: graphql`
    fragment Timeline_event on Event {
      id
      eventType
      date
      endDate
      onSaleDate
      offSaleDate
      closeEventDate
      announceDate
      scheduleStatus
      scheduleStatusUpdatedAt
      timezoneName
      lineup
      defaultEventTimings {
        offset
        sourceField
        targetField
        type
      }
      billingPromoter {
        autoRescheduledEventRefunds {
          active
          cutoffDays
        }
        defaultEventTimings {
          offset
          sourceField
          targetField
          type
        }
      }
      attractiveStatus {
        status
      }
      attractiveFields {
        forceSubscription
        forceSubscriptionLimit
        subscriptionCode
        linkedEvents {
          id
          name
          date
          endDate
          timezoneName
          primaryVenue {
            name
          }
        }
      }
      flags {
        enabledPwl
        ticketTransfer
        autoRescheduledEventRefunds
      }
      recurrentEventsGroup {
        id
        date
      }
      primaryVenue {
        value: id
      }
      allowedLifecycleUpdates {
        announceDate {
          canUpdate
          maxValue
          minValue
        }
        onSaleDate {
          canUpdate
          maxValue
          minValue
        }
        offSaleDate {
          canUpdate
          maxValue
          minValue
        }
        date {
          canUpdate
          maxValue
          minValue
        }
        endDate {
          canUpdate
          maxValue
          minValue
        }
        lineUp {
          canUpdate
        }
      }
      state
      statusAsOfNow
      eventIdLive
      sales {
        totalAppSold
        totalPosSold
      }
    }
  `,
})
