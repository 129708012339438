import { useLayoutEffect, useMemo, useState } from 'react'

function useRendered() {
  const [isRendered, setRendered] = useState(false)
  const RenderSpy = useMemo(
    () =>
      // eslint-disable-next-line func-names
      function LoaderSpy() {
        useLayoutEffect(() => {
          setRendered(true)
          return () => {
            setRendered(false)
          }
        }, [])
        return null
      },
    []
  )

  return [isRendered, RenderSpy] as const
}

export default useRendered
