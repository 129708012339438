/**
 * @generated SignedSource<<9b87fbe69c1a680c61f4c64d00875091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type StripeAccountIntegrityState = "CHARGES_DISABLED" | "OK" | "PAYOUTS_DISABLED" | "%future added value";
export type EventHeaderBasicQuery$variables = {
  id: string;
};
export type EventHeaderBasicQuery$data = {
  readonly event: {
    readonly endDate?: string | null;
    readonly eventIdLive?: string | null;
    readonly eventType?: EventType | null;
    readonly id?: string;
    readonly isTest?: boolean | null;
    readonly name?: string | null;
    readonly state?: EventState | null;
    readonly " $fragmentSpreads": FragmentRefs<"useEventNavigation_event">;
  } | null;
  readonly viewer: {
    readonly account: {
      readonly stripeAccountState: StripeAccountIntegrityState;
    } | null;
  } | null;
};
export type EventHeaderBasicQuery = {
  response: EventHeaderBasicQuery$data;
  variables: EventHeaderBasicQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeAccountState",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTest",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventIdLive",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventHeaderBasicQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "event",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useEventNavigation_event"
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventHeaderBasicQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "event",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "statusAsOfNow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "diceTvPlatform",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventFlags",
                "kind": "LinkedField",
                "name": "flags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "seated",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventSeatingChart",
                "kind": "LinkedField",
                "name": "eventSeatingChart",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "hdrTtys",
                "args": null,
                "concreteType": "TicketType",
                "kind": "LinkedField",
                "name": "ticketTypes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doorSalesEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reservedSeating",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AllowedActions",
                "kind": "LinkedField",
                "name": "allowedActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readAnalytics",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readAdvancedStats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readDoorlist",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readDoorlistExtras",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readAllCustomerData",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readMarketingOptIns",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createSocialLinks",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readSocialLinks",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manageFacebook",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "manageBoxOffice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readDoorSalesActivities",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "managePromotions",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TokensList",
                "kind": "LinkedField",
                "name": "tokens",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allCustomerDataToken",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Marketeer",
                "kind": "LinkedField",
                "name": "marketeers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessToken",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fbAccessToken",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fbPixelId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gaTrackingId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "googleAdsConversionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "googleAdsPurchaseConversionLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privacyPolicyLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tiktokPixelId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "twitterCheckoutInitiatedPixelId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "twitterPixelId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "twitterPurchasePixelId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "webOptInEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appOptInEnabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Venue",
                "kind": "LinkedField",
                "name": "venues",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0b719ad9b045ea30a5890f1fbd9ef60",
    "id": null,
    "metadata": {},
    "name": "EventHeaderBasicQuery",
    "operationKind": "query",
    "text": "query EventHeaderBasicQuery(\n  $id: ID!\n) {\n  viewer {\n    account {\n      stripeAccountState\n      id\n    }\n    id\n  }\n  event: node(id: $id) {\n    __typename\n    ... on Event {\n      id\n      name\n      state\n      isTest\n      eventType\n      endDate\n      eventIdLive\n      ...useEventNavigation_event\n    }\n    id\n  }\n}\n\nfragment useEventNavigation_event on Event {\n  id\n  eventIdLive\n  state\n  statusAsOfNow\n  eventType\n  diceTvPlatform\n  flags {\n    seated\n  }\n  eventSeatingChart {\n    id\n  }\n  hdrTtys: ticketTypes {\n    id\n    doorSalesEnabled\n    reservedSeating\n  }\n  allowedActions {\n    readAnalytics\n    readAdvancedStats\n    readDoorlist\n    readDoorlistExtras\n    readAllCustomerData\n    readMarketingOptIns\n    createSocialLinks\n    readSocialLinks\n    manageFacebook\n    manageBoxOffice\n    readDoorSalesActivities\n    managePromotions\n  }\n  tokens {\n    allCustomerDataToken\n  }\n  marketeers {\n    id\n    accessToken\n    fbAccessToken\n    fbPixelId\n    gaTrackingId\n    googleAdsConversionId\n    googleAdsPurchaseConversionLabel\n    privacyPolicyLink\n    tiktokPixelId\n    twitterCheckoutInitiatedPixelId\n    twitterPixelId\n    twitterPurchasePixelId\n    webOptInEnabled\n    appOptInEnabled\n  }\n  countryCode\n  addressCountry\n  venues {\n    addressCountry\n    countryCode\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "917271e92dc40644e082136f9c953435";

export default node;
