import React, { FC, memo, useContext, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { authContext } from '../context/auth'
import { EventType } from '../enums.generated'
import Badge from './Badge'

const TagContainer = styled.div`
  display: flex;

  ${Badge} {
    display: inline-block;
    padding: 0;
    margin-left: 4px;
    min-width: 19px;
    width: 19px;
    height: 19px;
    & > svg {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const Clamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

interface IVenue {
  id: string
  name: string | null
}

interface IEvent {
  venueName: string | null
  eventType: EventType | null
  primaryVenue: IVenue | null
  venues: null | ReadonlyArray<IVenue | null>
}

const VenueBadge: FC<{ event: IEvent }> = ({ event }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const venue = useMemo(
    () => event.primaryVenue || (event.venueName && { id: null, name: event.venueName }) || event.venues?.[0] || null,
    [event.primaryVenue, event.venueName, event.venues]
  )

  const venueName = venue?.name || intl.formatMessage({ id: 'na' })
  const venueLink = user.diceStaff && venue?.id ? <Link to={`/venues/${venue.id}`}>{venue.name}</Link> : venueName

  if (event.eventType === 'LIVE') return <span title={venueName}>{venueLink}</span>

  const isPureStream = event.eventType === 'STREAM'

  return isPureStream ? (
    <Badge title={venueName} icon="live">
      {intl.formatMessage({ id: 'live_stream' })}
    </Badge>
  ) : (
    <TagContainer>
      <Clamp>{venueLink}</Clamp>

      <Badge title={intl.formatMessage({ id: 'live_stream' })} icon="live" />
    </TagContainer>
  )
}

export default memo(VenueBadge)
