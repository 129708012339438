import { useEffect, useState, useContext, useRef } from 'react'
import { isEqual, map, some } from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'

import getPriceBreakdown, {
  convertBreakdown,
  IPriceBreakdown,
  IPriceBreakdownRaw,
} from '../../../utils/getPriceBreakdown'
import { useDebounce } from '../../../utils/hooks/useDebounce'
import { IFee } from '../types/Tickets'
import { authContext } from '../../../context/auth'

export interface IBreakdownInput {
  initialBreakdown: IPriceBreakdownRaw | null
  faceValue: number
  fee: Array<IFee | null> | null
  forcePwlActive: boolean | null
}

export interface IBreakdownResult {
  loading: boolean
  priceBreakdowns: Array<IPriceBreakdown | null>
}

export interface IBreakdownContext {
  venueIds: string[]
  eventId: string | null
  billingPromoterId: string | null
  basePriceFees: ReadonlyArray<string | null> | null
  postFanPriceFees: ReadonlyArray<string | null> | null
  disableUsTax: boolean
}

function usePriceBreakdown(inputs: IBreakdownInput[], ctx: IBreakdownContext): IBreakdownResult {
  const { user } = useContext(authContext)
  const environment = useRelayEnvironment()

  const debouncedInputs = useDebounce(inputs, 500)

  const [loading, setLoading] = useState(() => some((inp) => !inp.initialBreakdown, inputs || []))

  const [priceBreakdowns, setPriceBreakdowns] = useState<Array<null | IPriceBreakdown>>(() =>
    map(({ initialBreakdown }) => initialBreakdown && convertBreakdown(initialBreakdown), inputs)
  )

  const firstTime = useRef(true)

  const prevData = useRef({ ctx, inputs })

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false

      const hasMissingData = some((inp) => !inp.initialBreakdown, inputs || [])

      // Skip first fetch if no missing data
      if (!hasMissingData) {
        return
      }
    } else {
      const data = { ctx, inputs: debouncedInputs }

      // Skip next requests if params are deep equal
      if (isEqual(prevData.current, data)) {
        return
      }

      prevData.current = data
    }

    let stillMounted = true

    setLoading(true)

    const { venueIds, eventId, billingPromoterId, basePriceFees, postFanPriceFees, disableUsTax } = ctx

    const promises = map(
      ({ faceValue, fee, forcePwlActive }) =>
        getPriceBreakdown(environment, {
          billingPromoterId,
          eventId: eventId || null,
          faceValue,
          venueIds,
          fees: fee,
          basePriceFees,
          postFanPriceFees,
          ignorePwlFee: !user.diceStaff,
          disableUsTax,
          forcePwlActive,
        }),
      debouncedInputs
    ) as any as Promise<IPriceBreakdown | null>[]

    Promise.all(promises)
      .then((results) => {
        if (stillMounted) {
          setPriceBreakdowns(results)
        }
      })
      .finally(() => {
        if (stillMounted) {
          setLoading(false)
        }
      })

    return () => {
      stillMounted = false
    }
  }, [user.diceStaff, inputs, debouncedInputs, ctx, environment])

  return {
    loading,
    priceBreakdowns,
  }
}

export default usePriceBreakdown
