/**
 * @generated SignedSource<<809f713662dd212976502461ed352475>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useSeatingStateSeatmapsQuery$variables = {
  venueIds?: ReadonlyArray<string> | null;
};
export type useSeatingStateSeatmapsQuery$data = {
  readonly viewer: {
    readonly options: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly label: string | null;
          readonly seatingCharts: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly disabled: boolean;
                readonly label: string;
                readonly value: string;
              } | null;
            } | null> | null;
          } | null;
          readonly value: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useSeatingStateSeatmapsQuery = {
  response: useSeatingStateSeatmapsQuery$data;
  variables: useSeatingStateSeatmapsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "venueIds"
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 50
},
v2 = [
  (v1/*: any*/),
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "in",
            "variableName": "venueIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v1/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disabled",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSeatingStateSeatmapsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v2/*: any*/),
            "concreteType": "VenuesConnection",
            "kind": "LinkedField",
            "name": "venues",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VenuesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Venue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": (v5/*: any*/),
                        "concreteType": "SeatingChartConnection",
                        "kind": "LinkedField",
                        "name": "seatingCharts",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SeatingChartEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SeatingChart",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "seatingCharts(first:50)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSeatingStateSeatmapsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v2/*: any*/),
            "concreteType": "VenuesConnection",
            "kind": "LinkedField",
            "name": "venues",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VenuesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Venue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": (v5/*: any*/),
                        "concreteType": "SeatingChartConnection",
                        "kind": "LinkedField",
                        "name": "seatingCharts",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SeatingChartEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SeatingChart",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "seatingCharts(first:50)"
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45d644932ca67634687cdde5c9a61af4",
    "id": null,
    "metadata": {},
    "name": "useSeatingStateSeatmapsQuery",
    "operationKind": "query",
    "text": "query useSeatingStateSeatmapsQuery(\n  $venueIds: [ID!]\n) {\n  viewer {\n    options: venues(first: 50, where: {id: {in: $venueIds}}) {\n      edges {\n        node {\n          value: id\n          label: name\n          seatingCharts(first: 50) {\n            edges {\n              node {\n                value: id\n                label: name\n                disabled\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "260897de015f5f401736b7c941326d83";

export default node;
