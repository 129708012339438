/**
 * @generated SignedSource<<956c95ce93c2f907fdf5fdd922e31dbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventVenueSchedulePreloadQuery$variables = {
  id: string;
};
export type EventVenueSchedulePreloadQuery$data = {
  readonly venue: {
    readonly configurations?: ReadonlyArray<{
      readonly attractiveRoomSiaeCode: string | null;
      readonly capacity: number;
      readonly label: string;
      readonly seatingAreaConfigs: ReadonlyArray<{
        readonly capacity: number;
        readonly seatingArea: string;
      } | null>;
      readonly value: string;
    } | null> | null;
    readonly label?: string | null;
    readonly value?: string;
  } | null;
};
export type EventVenueSchedulePreloadQuery = {
  response: EventVenueSchedulePreloadQuery$data;
  variables: EventVenueSchedulePreloadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attractiveRoomSiaeCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SeatingAreaConfig",
  "kind": "LinkedField",
  "name": "seatingAreaConfigs",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seatingArea",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventVenueSchedulePreloadQuery",
    "selections": [
      {
        "alias": "venue",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VenueConfiguration",
                "kind": "LinkedField",
                "name": "configurations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Venue",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventVenueSchedulePreloadQuery",
    "selections": [
      {
        "alias": "venue",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VenueConfiguration",
                "kind": "LinkedField",
                "name": "configurations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Venue",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee766cb8ac684bdab3a42bce90dd4153",
    "id": null,
    "metadata": {},
    "name": "EventVenueSchedulePreloadQuery",
    "operationKind": "query",
    "text": "query EventVenueSchedulePreloadQuery(\n  $id: ID!\n) {\n  venue: node(id: $id) {\n    __typename\n    ... on Venue {\n      value: id\n      label: name\n      configurations {\n        value: id\n        label: name\n        attractiveRoomSiaeCode\n        capacity\n        seatingAreaConfigs {\n          capacity\n          seatingArea\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd53343a0fef25d13581004273d6ecea";

export default node;
