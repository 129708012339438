
declare global {
  interface Window {
    RUNTIME_API_URL?: string

    RUNTIME_MAPBOX_TZ_TILESET_ID?: string
    RUNTIME_MAPBOX_API_KEY?: string

    RUNTIME_SENTRY_DSN?: string
    RUNTIME_COMMIT_HASH?: string
    RUNTIME_APM_ENV?: string

    RUNTIME_FACEBOOK_APP_ID?: string

    RUNTIME_SALESFORCE_URL?: string

    RUNTIME_RUDDERSTACK_DATA_PLANE_URL?: string
    RUNTIME_RUDDERSTACK_CONFIG_PLANE_URL?: string
    RUNTIME_RUDDERSTACK_WRITE_KEY?: string

    RUNTIME_HS_DOCS_API_KEY?: string

    RUNTIME_SEATS_IO_PUBLIC_KEY?: string

    RUNTIME_SOCIAL_LINKS_HOST?: string

    RUNTIME_MUX_DASHBOARD_PREFIX?: string

    PHRASEAPP_CONFIG?: any

    ENABLE_PERF_MIDDLEWARE?: boolean
  }
}

const API_PREFIX = 'p-api'

export const API_URL = (window.RUNTIME_API_URL || process.env.REACT_APP_API_URL || '').replace(/\/$/, '')

export const MAPBOX_TZ_TILESET_ID =
  window.RUNTIME_MAPBOX_TZ_TILESET_ID || process.env.REACT_APP_MAPBOX_TZ_TILESET_ID || ''
export const MAPBOX_API_KEY = window.RUNTIME_MAPBOX_API_KEY || process.env.REACT_APP_MAPBOX_API_KEY || ''

export const SENTRY_DSN = window.RUNTIME_SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN || ''
export const COMMIT_HASH = window.RUNTIME_COMMIT_HASH || process.env.REACT_APP_COMMIT_HASH || ''
export const APM_ENV = window.RUNTIME_APM_ENV || process.env.REACT_APP_APM_ENV || ''

export const SALESFORCE_URL = window.RUNTIME_SALESFORCE_URL || process.env.REACT_APP_SALESFORCE_URL || ''

export const RUDDERSTACK_DATA_PLANE_URL =
  window.RUNTIME_RUDDERSTACK_DATA_PLANE_URL || process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL || ''
export const RUDDERSTACK_CONFIG_PLANE_URL =
  window.RUNTIME_RUDDERSTACK_CONFIG_PLANE_URL || process.env.REACT_APP_RUDDERSTACK_CONFIG_PLANE_URL || ''
export const RUDDERSTACK_RUDDERSTACK_WRITE_KEY =
  window.RUNTIME_RUDDERSTACK_WRITE_KEY || process.env.REACT_APP_RUDDERSTACK_WRITE_KEY || ''

// ts-prune-ignore-next
export const FACEBOOK_APP_ID = window.RUNTIME_FACEBOOK_APP_ID || process.env.REACT_APP_FACEBOOK_APP_ID || ''
export const HS_DOCS_API_KEY = window.RUNTIME_HS_DOCS_API_KEY || process.env.REACT_APP_HS_DOCS_API_KEY || ''

export const DICE_FM_URL = API_URL && API_URL.replace(API_PREFIX, '').replace(/\/\/[.-]/, '//')
export const PONTARE_URL = API_URL && API_URL.replace(API_PREFIX, 'pontare')

export const SEATS_IO_PUBLIC_KEY = window.RUNTIME_SEATS_IO_PUBLIC_KEY || process.env.REACT_APP_SEATS_IO_PUBLIC_KEY || ''
export const SOCIAL_LINKS_HOST = (
  window.RUNTIME_SOCIAL_LINKS_HOST ||
  process.env.REACT_APP_SOCIAL_LINKS_HOST ||
  ''
).replace(/\/$/, '')
export const MUX_DASHBOARD_PREFIX = (
  window.RUNTIME_MUX_DASHBOARD_PREFIX ||
  process.env.REACT_APP_MUX_DASHBOARD_PREFIX ||
  ''
).replace(/\/$/, '')

export const ENABLE_PERF_MIDDLEWARE =
  window.ENABLE_PERF_MIDDLEWARE || process.env.REACT_APP_ENABLE_PERF_MIDDLEWARE === 'true'
