import React, { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import styled, { css } from 'styled-components/macro'
import { addDays, differenceInCalendarDays, subDays } from 'date-fns'
import { isString } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { color, mediaQuery } from '../../../../utils/variables'
import TimelineDays from './TimelineDays'
import { parseAtTimezone } from '../../../../utils/calendar'
import EventTimelineContext from '../../util/eventTimelineContext'
import TimelineBaseDates from './TimelineBaseDates'
import TimelineMonths from './TimelineMonths'
import TicketTypes from './TicketTypes'
import Button from '../../../../components/Button'
import useTimelineTodayScroll from '../../hooks/useTimelineTodayScroll'
import { CELL_WIDTH, TIMELINE_EXTRA_DAYS } from '../../util/eventTimelineVariables'
import useResizeObserver from '../../../../utils/hooks/useResizeObserver'
import { trackingContext } from '../../../../context/tracking'
import unwrapId from '../../../../utils/unwrapId'
import { EventTimelineQuery } from '../../../../__generated__/EventTimelineQuery.graphql'
import { EventTimeline_event$key } from '../../../../__generated__/EventTimeline_event.graphql'

interface IProps {
  eventId: string
}

const Wrapper = styled.div`
  position: relative;
`
const Container = styled.div`
  position: relative;
  overflow-x: auto;
  margin: 16px 32px 48px;
  padding-bottom: 8px;
  ${mediaQuery.lessThan('tablet')`
  margin: 16px 0 24px;
  `}
`

const ScrollWrapper = styled.div<{ fixed?: boolean }>`
  width: 100%;
  ${(props) =>
    props.fixed &&
    css`
      overflow: hidden;
    `}
`

const TodayLine = styled.div<{ left: number; hidden?: boolean }>`
  position: absolute;
  top: 64px;
  bottom: 0;
  width: 1px;
  background-color: ${color.black};
  left: ${(props) => props.left}px;
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`
const TodayButtonWrapper = styled.div`
  margin: 24px 32px 0;
  ${mediaQuery.lessThan('tablet')`
    margin: 16px 16px 0;
  `}
`
const TodayButton = styled(Button)`
  width: 100%;

  ${mediaQuery.greaterThan('tablet')`
    width: unset;
  `}
`

const today = new Date()

const EventTimeline: FC<React.PropsWithChildren<IProps>> = ({ eventId }) => {
  const intl = useIntl()
  const { trackEvent } = useContext(trackingContext)

  const { event: eventKey } = useLazyLoadQuery<EventTimelineQuery>(
    graphql`
      query EventTimelineQuery($eventId: ID!) {
        event: node(id: $eventId) {
          id
          ...EventTimeline_event
        }
      }
    `,
    { eventId },
    { fetchPolicy: 'store-and-network' }
  )

  const event = useFragment<EventTimeline_event$key>(
    graphql`
      fragment EventTimeline_event on Event {
        ...EventTicketTypeModal_event
        id
        eventIdLive
        eventType
        maxTicketsLimit
        lockVersion
        previewToken
        announceDate
        onSaleDate
        offSaleDate
        date
        endDate
        timezoneName
        costCurrency

        venueSchedules {
          id
        }

        sales {
          ticketTypesBreakdown {
            ticketTypeId
            priceTiersBreakdown {
              priceTier {
                id
                name
                price
                allocation
                order
              }
              sold
            }
          }
        }

        ticketTypes(doorSalesOnly: false, includeArchived: true) {
          id
          allocation
          name
          archived
          offSaleDate
          onSaleDate
          price
          priceTierType
          priceTiers {
            id
            name
            price
            time
          }
          eventPromotions {
            id
            startDate
            endDate
            name
            promotionType
          }
          products {
            id
            onSaleDate
            offSaleDate
            name
            category {
              type
              parentCategory {
                type
              }
            }
            ticketTypes {
              offSaleDate
            }
          }
        }
      }
    `,
    eventKey
  )

  const [timelineWidthRef, { contentRect: timelineRect }] = useResizeObserver()
  const fullScreenExtraDays = useMemo(() => {
    if (!timelineRect?.width) return 0

    const screenWidthInCells = timelineRect.width / CELL_WIDTH
    const timelineWidth = differenceInCalendarDays(
      parseAtTimezone(event?.endDate, event?.timezoneName) || new Date(),
      parseAtTimezone(event?.announceDate, event?.timezoneName) || new Date()
    )

    return Math.max(0, screenWidthInCells - timelineWidth - TIMELINE_EXTRA_DAYS * 2)
  }, [timelineRect?.width, event?.announceDate, event?.endDate, event?.timezoneName])

  const timelineStart = useMemo(
    () => subDays(parseAtTimezone(event?.announceDate, event?.timezoneName) || new Date(), TIMELINE_EXTRA_DAYS),
    [event?.announceDate, event?.timezoneName]
  )
  const timelineEnd = useMemo(
    () =>
      addDays(
        parseAtTimezone(event?.endDate, event?.timezoneName) || new Date(),
        TIMELINE_EXTRA_DAYS + fullScreenExtraDays
      ),
    [fullScreenExtraDays, event?.endDate, event?.timezoneName]
  )

  const dateOffset = useCallback(
    (date: Date | string | null) =>
      date
        ? differenceInCalendarDays(
          isString(date) ? parseAtTimezone(date, event?.timezoneName) || new Date() : date,
          timelineStart
        ) * CELL_WIDTH
        : 0,
    [timelineStart, event?.timezoneName]
  )

  const todayOffset = useMemo(() => dateOffset(today), [dateOffset])
  const pastTimeline = useMemo(() => todayOffset > dateOffset(timelineEnd), [dateOffset, todayOffset, timelineEnd])
  const containerRef = useRef<HTMLDivElement>(null)

  const { onScrollToday } = useTimelineTodayScroll(todayOffset, containerRef)

  useEffect(() => {
    onScrollToday()
  }, [onScrollToday])

  useEffect(() => {
    trackEvent('event_timeline_viewed', { event_id: unwrapId(event?.id), event_id_live: event?.eventIdLive })
  }, [trackEvent, event?.id, event?.eventIdLive])

  if (!event) return null

  return (
    <Wrapper>
      {!pastTimeline && fullScreenExtraDays === 0 && (
        <TodayButtonWrapper>
          <TodayButton onClick={onScrollToday} preset="outline">
            {intl.formatMessage({ id: 'dashboard.sales_plate.day' })}
          </TodayButton>
        </TodayButtonWrapper>
      )}
      <Container ref={containerRef}>
        <ScrollWrapper ref={timelineWidthRef} fixed={fullScreenExtraDays > 0}>
          <EventTimelineContext.Provider
            value={{
              dateOffset,
              timelineStart,
              timelineEnd,
              event,
            }}
          >
            <TimelineMonths />
            <TimelineDays />
            <TimelineBaseDates />
            <TicketTypes />
            <TodayLine left={todayOffset + CELL_WIDTH / 2 - 1} hidden={pastTimeline} />
          </EventTimelineContext.Provider>
        </ScrollWrapper>
      </Container>
    </Wrapper>
  )
}

export default EventTimeline
