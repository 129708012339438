import { get } from 'lodash/fp'

interface IVenue {
  venueImages: null | ReadonlyArray<null | { attachment: null | { cdnUrl: null | string } }>
  profileDetails?: null | {
    imageAttachment: null | {
      cdnUrl: null | string
    }
    imageCropRegion: null | {
      x: number | null
      y: number | null
      width: number | null
      height: number | null
    }
  }
}

const venueImage = (venue: IVenue, size?: number) => {
  const cropRegion = venue.profileDetails?.imageCropRegion
  const profileUrl = venue.profileDetails?.imageAttachment?.cdnUrl
  let url = profileUrl || get('venueImages.0.attachment.cdnUrl', venue)
  if (url) {
    try {
      const parsed = new URL(url)
      parsed.searchParams.set('w', String(size || 100))
      parsed.searchParams.set('h', String(size || 100))
      parsed.searchParams.set('auto', 'compress')
      parsed.searchParams.set('fit', 'crop')

      if (!parsed.searchParams.get('rect') && !!cropRegion && !!profileUrl) {
        parsed.searchParams.append(
          'rect',
          [cropRegion?.x || 0, cropRegion?.y || 0, cropRegion?.width || 0, cropRegion?.height || 0].join(',')
        )
      }

      url = parsed.toString()
    } catch (e) {
      console.error(e)
    }
  }
  return url
}

export default venueImage
