import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import graphql from 'babel-plugin-relay/macro'

import RelayLoader from '../../components/RelayLoader'
import EventDetails from './EventDetails'

function EventDetailsPage() {
  const { id } = useParams<{ id: string }>()

  const DataLoader = useMemo(
    () =>
      RelayLoader(EventDetails, {
        fetchPolicy: 'store-and-network',
        variables: {
          id,
        },
        query: graphql`
          query EventDetailsPageQuery($id: ID!) {
            event: node(id: $id) {
              ...EventDetails_event
            }
            viewer {
              ...EventDetails_viewer
            }
          }
        `,
      }),
    [id]
  )

  return <DataLoader />
}

export default EventDetailsPage
