import { css } from 'styled-components/macro'

export const globalStickyTop = (top = '0px') => css`
  top: ${top};

  .-impersonating & {
    top: calc(62px + ${top});
  }
`

export const pageStickyTop = (top = '0px') => css`
  top: ${top};

  .-test-page & {
    top: calc(34px + ${top});
  }

  .-impersonating & {
    top: calc(62px + ${top});
  }

  .-test-page.-impersonating & {
    top: calc(34px + 62px + ${top});
  }
`
