import { createGlobalStyle } from 'styled-components/macro'
import { mediaQuery } from '../utils/variables'
import { textStyle } from '../utils/typography'

export const TypeStyle = createGlobalStyle`
  h1, .-like-h1,
  h2, .-like-h2,
  h3, .-like-h3,
  h4, .-like-h4,
  h5, .-like-h5 {
    line-height: 1.2em;
    color: #000;
    margin: 32px 0;
    font-weight: 400;
    &:first-child {
      margin-top: 0;
    }
  }
  h1, .-like-h1 {
    ${textStyle.heading.xl}
    ${mediaQuery.lessThan('desktop')`
      ${textStyle.heading.lg}
    `}
    
  }
  h2, .-like-h2 {
    ${textStyle.heading.lg}
    
    ${mediaQuery.lessThan('desktop')`
      ${textStyle.heading.md}
    `}
  }
  h3, .-like-h3 {
    ${textStyle.heading.md}

    ${mediaQuery.lessThan('desktop')`
      ${textStyle.heading.sm}
    `}
  }
  h4, .-like-h4 {
    ${textStyle.heading.sm}

    ${mediaQuery.lessThan('desktop')`
      ${textStyle.heading.xs}
    `}
  }
  h5, .-like-h5 {
    ${textStyle.heading.xs}
  }
  p {
    margin: 8px 0;
  }
`
