import React, { Dispatch, FC, memo, SetStateAction, useMemo } from 'react'
import { filter, map, maxBy, set, some, update } from 'lodash/fp'
import styled, { css } from 'styled-components/macro'
import { Bar } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import useChartConfig from '../hooks/useChartConfig'
import useDataConfig from '../hooks/useDataConfig'
import { IOptions } from '../types/options'
import { breakpoints, mediaQuery, zIndex } from '../../../utils/variables'
import { ITooltipModel } from './SalesChartTooltip'
import SalesChartConfig from './SalesChartConfig'
import useChartLegend from '../hooks/useChartLegend'
import SalesChartLegend from './SalesChartLegend'
import useBounds from '../hooks/useBounds'
import useTimeNavigation from '../hooks/useTimeNavigation'
import { IEventStats } from '../hooks/useEventStats'
import { IAggregatedData } from '../hooks/useAggregatedData'
import { EventCostCurrency } from '../../../enums.generated'
import useResizeObserver from '../../../utils/hooks/useResizeObserver'

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: calc(100% + 24px);
  padding: 12px;
  margin: -12px;
`

const ChartDiv = styled.div`
  position: relative;

  height: 400px;
  margin: 0 auto;
  width: 100%;

  ${mediaQuery.lessThan('tablet')`
    width: 90vw;

    height: 212px;
    margin: 0 auto 16px auto;
    canvas {
      pointer-events: none;
    }
  `}
`

const EmptyState = styled.div<{ $interactive?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  padding-bottom: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  user-select: none;

  ${({ $interactive }) =>
    $interactive
      ? css`
          pointer-events: none;
        `
      : ''}
`

interface IProps {
  labels: string[]
  data: IAggregatedData
  startDate: Date
  endDate: Date

  costCurrency: EventCostCurrency | null
  eventStats: IEventStats

  options: IOptions
  setOptions: Dispatch<SetStateAction<IOptions>>

  setTooltipModel: Dispatch<SetStateAction<ITooltipModel | null>>

  showExtras: boolean
  showMerch: boolean
  showViews: boolean
  showRevenue: boolean
}

const SalesChart: FC<IProps> = ({
  eventStats,
  labels,
  data,
  startDate,
  endDate,
  costCurrency,
  options,
  setOptions,
  setTooltipModel,
  showExtras,
  showMerch,
  showViews,
  showRevenue,
}) => {
  const intl = useIntl()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const [allocMax, moneyMax] = useBounds({
    cumulative: options.groupBy === 'cumulative',
    ...eventStats,
  })

  const dataConfig = useDataConfig({ options, labels, data, showExtras, showMerch, showViews })

  const { timeSpan, setTimeSpan, minStartDate, maxStartDate } = useTimeNavigation(
    options.groupBy === 'week',
    startDate,
    endDate
  )

  const chartConfig = useChartConfig({
    options,
    costCurrency: costCurrency || 'GBP',
    startDate: timeSpan[0],
    endDate: timeSpan[1],
    maxAllocation: allocMax,
    maxMoney: moneyMax,
    tooltipCallback: setTooltipModel,
  })

  const { chartRef, checkboxes, toggleDataset } = useChartLegend(dataConfig)

  const [chartDivRef, { contentRect }] = useResizeObserver()

  const adjustedWidthDataConfig = useMemo(() => {
    let adjustedWidths
    if (contentRect && (options.groupBy === 'week' || isMobile)) {
      const padding = 100

      const visibleDatasetCount = filter((ds) => !ds.hidden && ds.id !== 'event:views', dataConfig.datasets).length
      const barPointCount = maxBy((ds) => ds.data.length, dataConfig.datasets)?.data.length || 0

      const flexBarWidth = (0.55 * (contentRect.width - padding)) / (visibleDatasetCount * barPointCount)
      const cappedWidth = Math.min(20, Math.floor(flexBarWidth))

      adjustedWidths = update('datasets', map(set('barThickness', cappedWidth)), dataConfig)
    } else {
      adjustedWidths = dataConfig
    }

    return adjustedWidths
  }, [contentRect, dataConfig, isMobile, options.groupBy])

  const memoizedChart = useMemo(
    () => <Bar ref={chartRef as any} data={adjustedWidthDataConfig} options={chartConfig} />,
    [adjustedWidthDataConfig, chartConfig, chartRef]
  )

  const hasData = useMemo(() => some((d) => d.data.length > 0, dataConfig.datasets), [dataConfig.datasets])

  return (
    <>
      <Container>
        {!isMobile && (
          <SalesChartConfig
            options={options}
            setOptions={setOptions}
            timeSpan={timeSpan}
            setTimeSpan={setTimeSpan}
            minStartDate={minStartDate}
            maxStartDate={maxStartDate}
            disabled={!hasData}
          />
        )}
        <ChartDiv ref={chartDivRef}>{memoizedChart}</ChartDiv>
        {!isMobile && (
          <SalesChartLegend
            options={options}
            setOptions={setOptions}
            datasets={dataConfig.datasets}
            enabled={checkboxes}
            toggleDataset={toggleDataset}
            disabled={!hasData}
            showRevenue={showRevenue}
          />
        )}
        {!hasData && <EmptyState>{intl.formatMessage({ id: 'event_overview.chart_empty.hint' })}</EmptyState>}
        {hasData && options.mode === 'revenue' && moneyMax <= 0 && (
          <EmptyState $interactive>
            {intl.formatMessage({
              id: `event_overview.revenue_empty_${showRevenue ? 'paid' : 'free'}_tickets.hint`,
            })}
          </EmptyState>
        )}
      </Container>
    </>
  )
}

export default memo(SalesChart)
