import React, { FC, useContext } from 'react'

import { useIntl } from 'react-intl'
import { parseISO } from 'date-fns'
import EventCardValue from '../../../components/Event/EventCardValue'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { localeContext } from '../../../context/locale'

interface IProps {
  date?: string | null
  endDate?: string | null
  timezoneName: string | null
  className?: string
}

const EventPartDateTime: FC<React.PropsWithChildren<IProps>> = ({ date, endDate, timezoneName, className }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const parsedDate = date ? parseISO(date) : undefined
  const parsedEndDate = endDate ? parseISO(endDate) : undefined

  const eventDate = parsedDate
    ? intl.formatDate(parsedDate, {
      ...DATETIME_FORMATS.MEDIUM,
      timeZone: timezoneName || undefined,
    })
    : intl.formatMessage({ id: 'na' })

  const startTime = parsedDate
    ? intl.formatDate(parsedDate, {
      ...DATETIME_FORMATS.TIME(locale),
      timeZone: timezoneName || undefined,
    })
    : ''

  const endTime = parsedEndDate
    ? intl.formatDate(parsedEndDate, {
      ...DATETIME_FORMATS.TIME(locale),
      timeZone: timezoneName || undefined,
    })
    : ''

  const formattedTimes = parsedDate && parsedEndDate ? `${startTime} - ${endTime}` : startTime

  return <EventCardValue primary={eventDate} secondary={formattedTimes} className={className} />
}

export default EventPartDateTime
