import styled from 'styled-components/macro'

import { mediaQuery } from '../../utils/variables'

const AuthSidebar = styled.div`
  background-color: grey;
  flex-grow: 0;
  flex-shrink: 2;
  flex-basis: 480px;
  background-image: url('https://dice-media.imgix.net/mio/auth_sidebar_sm.jpg?auto=compress&q=25');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  position: relative;

  ${mediaQuery.lessThan('tablet')`
    flex-basis: 257px;
  `}
`

export default styled(AuthSidebar)``
