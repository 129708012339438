/**
 * @generated SignedSource<<aabefb16ca8b0ed17c9781595a295105>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuickActionsDrafts_event$data = {
  readonly completedSteps: number | null;
  readonly eventType: EventType | null;
  readonly id: string;
  readonly statusAsOfNow: string | null;
  readonly tokens: {
    readonly doorlistExportToken: string | null;
  } | null;
  readonly " $fragmentType": "QuickActionsDrafts_event";
};
export type QuickActionsDrafts_event$key = {
  readonly " $data"?: QuickActionsDrafts_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickActionsDrafts_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickActionsDrafts_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedSteps",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TokensList",
      "kind": "LinkedField",
      "name": "tokens",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorlistExportToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "0ebf3fd0c4517364b094bca8f577b9e2";

export default node;
