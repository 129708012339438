import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import Svg from '../../../components/Svg'
import { mediaQuery, color, font } from '../../../utils/variables'
import { SalesStats_event$key } from '../../../__generated__/SalesStats_event.graphql'
import { SkeletonLine } from '../../../components/SkeletonLine'
import { SalesStatsQuery } from '../../../__generated__/SalesStatsQuery.graphql'

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column
  `}
`

const Info = styled.div`
  /* flex: 1; */
  margin-left: 16px;

  ${mediaQuery.lessThan('tablet')`
    min-width: 50%;
    display: flex;
    margin-top: 8px;
    margin-left: 0;
  `}
`

const Title = styled.div`
  text-transform: uppercase;
  font-size: ${font.size.xs}px;
  letter-spacing: 0.1em;
  white-space: nowrap;

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.base}px;
    text-transform: none;
    letter-spacing: inherit;
    color: ${color.darkgrey};

    &:after {
      content: ':'
    }
  `}
`

const Stats = styled.div`
  & > svg {
    margin-bottom: -4px;

    ${mediaQuery.lessThan('tablet')`
      display: none;
    `}
  }
`

const Big = styled.div`
  display: inline-block;
  font-size: ${font.size.lg}px;
  margin-left: 8px;

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.base}px;
    margin-left: 4px;
  `}
`

export const SalesStatsSkeleton = () => {
  const intl = useIntl()

  return (
    <Container>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.reminders' })}</Title>
        <Stats>
          <Svg icon="clock-alarm" />
          <Big><SkeletonLine width="24px" height="18px" animate /></Big>
        </Stats>
      </Info>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.saves' })}</Title>
        <Stats>
          <Svg icon="heart" />
          <Big><SkeletonLine width="24px" height="18px" animate /></Big>
        </Stats>
      </Info>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.wl_tickets' })}</Title>
        <Stats>
          <Svg icon="ticket-wl" />
          <Big><SkeletonLine width="24px" height="18px" animate /></Big>
        </Stats>
      </Info>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.wl_individuals' })}</Title>
        <Stats>
          <Svg icon="person" />
          <Big><SkeletonLine width="24px" height="18px" animate /></Big>
        </Stats>
      </Info>
    </Container>
  )
}

const SalesStats: FC<React.PropsWithChildren<{ eventId: string }>> = ({ eventId }) => {
  const intl = useIntl()
  const { event: eventKey } = useLazyLoadQuery<SalesStatsQuery>(
    graphql`
      query SalesStatsQuery($eventId: ID!) {
        event: node(id: $eventId) {
          ...SalesStats_event
        }
      }
    `,
    { eventId },
    { fetchPolicy: 'store-and-network' }
  )


  const event = useFragment<SalesStats_event$key>(
    graphql`
      fragment SalesStats_event on Event {
        savedCount
        onSaleSmsReminders
        sales {
          totalSold
          totalWlIndividuals
          totalWlRequests
        }
      }
    `,
    eventKey
  )

  return (
    <Container>
      <Info>
        <Title>{intl.formatMessage({ id: 'analytics.top_events.columns.tickets_sold' })}</Title>
        <Stats>
          <Svg icon="ticket" />
          <Big>{event?.sales?.totalSold || 0}</Big>
        </Stats>
      </Info>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.reminders' })}</Title>
        <Stats>
          <Svg icon="clock-alarm" />
          <Big>{event?.onSaleSmsReminders || 0}</Big>
        </Stats>
      </Info>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.saves' })}</Title>
        <Stats>
          <Svg icon="heart" />
          <Big>{event?.savedCount || 0}</Big>
        </Stats>
      </Info>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.wl_tickets' })}</Title>
        <Stats>
          <Svg icon="ticket-wl" />
          <Big>{event?.sales?.totalWlRequests || 0}</Big>
        </Stats>
      </Info>
      <Info>
        <Title>{intl.formatMessage({ id: 'event_overview.stats.wl_individuals' })}</Title>
        <Stats>
          <Svg icon="person" />
          <Big>{event?.sales?.totalWlIndividuals || 0}</Big>
        </Stats>
      </Info>
    </Container>
  )
}

export default memo(SalesStats)
