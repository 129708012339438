/**
 * @generated SignedSource<<cd437e071a75ff02ffaeeeb06c763b13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChannelType = "APP_SALE" | "BOX_OFFICE_SALE" | "HOLD" | "NON_DICE" | "OTHER" | "%future added value";
export type CompatibilityAe = "_0" | "_1000" | "_5000" | "%future added value";
export type CountryCode = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AN" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type FeesBehaviour = "APPEND_TO_CONTRACT" | "OVERRIDE" | "USE_CONTRACT" | "%future added value";
export type PaymentMethods = "AFTERPAY_CLEARPAY" | "DICE_SPLIT" | "GIROPAY" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type PrintedTicketFormat = "BOCA_55X2" | "BOCA_6X3_NO_LOGO" | "NO_PRINTER" | "STAR_RECEIPT" | "STAR_RECEIPT_ETICKET" | "%future added value";
export type ReservedSeatingTypes = "assignBestSeat" | "selectSeat" | "%future added value";
export type RestrictionKind = "ALLOW" | "DENY" | "%future added value";
export type TvPlatform = "DICE" | "EXTERNAL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Tickets_event$data = {
  readonly addressCountry: string | null;
  readonly allocation: number;
  readonly allowedActions: {
    readonly managePromotions: boolean | null;
    readonly manageTickets: boolean | null;
    readonly minorUpdate: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly endDate: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly maxTicketsLimit: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly onSaleDate: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly ticketPools: {
      readonly canAdd: boolean | null;
      readonly canChangeAllocation: boolean | null;
      readonly canRemove: boolean | null;
      readonly canUpdate: boolean | null;
    } | null;
    readonly ticketTypes: {
      readonly canAdd: boolean | null;
      readonly canChangeAllocation: boolean | null;
      readonly canChangeDoorSalesEnabled: boolean | null;
      readonly canChangeExternalSkus: boolean | null;
      readonly canChangeIcon: boolean | null;
      readonly canChangeIncrements: boolean | null;
      readonly canChangeOffSaleDate: boolean | null;
      readonly canChangeOnSaleDate: boolean | null;
      readonly canChangeOrder: boolean | null;
      readonly canChangeSeatmap: boolean | null;
      readonly canChangeTierNames: boolean | null;
      readonly canDelete: boolean | null;
      readonly canHide: boolean | null;
      readonly canUpdate: boolean | null;
      readonly canUpdatePrice: ReadonlyArray<string | null> | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly attractiveFields: {
    readonly compatibilityAe: CompatibilityAe | null;
    readonly integrationDisabled: boolean;
    readonly siaeGenreType: string | null;
    readonly taxFree: boolean | null;
  } | null;
  readonly attractiveStatus: {
    readonly status: string | null;
  } | null;
  readonly barcodeType: string | null;
  readonly basePriceFees: ReadonlyArray<string | null> | null;
  readonly closeEventDate: string | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly countryCode: string | null;
  readonly date: string | null;
  readonly diceStreamDuration: number | null;
  readonly diceStreamDvrEnabled: boolean | null;
  readonly diceStreamRewatchEnabledUntil: string | null;
  readonly diceTvPlatform: TvPlatform | null;
  readonly disableUsTax: boolean | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly eventSeatingChart: {
    readonly chartManagerCredentials: {
      readonly secretKey: string;
    };
    readonly id: string;
    readonly seatingChannels: ReadonlyArray<{
      readonly channelType: ChannelType;
      readonly name: string | null;
      readonly seatsIoChannel: string;
    } | null> | null;
    readonly seatsIoEventId: string;
    readonly seatsIoEventReport: IBackendMap | null;
    readonly venueChart: {
      readonly label: string;
      readonly value: string;
    };
  } | null;
  readonly eventType: EventType | null;
  readonly fees: ReadonlyArray<{
    readonly amount: number | null;
    readonly applicable: boolean | null;
    readonly split: ReadonlyArray<{
      readonly amount: number | null;
      readonly destination: FeeDestination | null;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly type: FeeType;
    readonly unit: FeeUnit;
  } | null> | null;
  readonly feesBehaviour: FeesBehaviour | null;
  readonly flags: {
    readonly codeLocked: IBackendMap | null;
    readonly competition: IBackendMap | null;
    readonly coolingOffPeriod: IBackendMap | null;
    readonly enabledPwl: IBackendMap | null;
    readonly fanPickSeat: IBackendMap | null;
    readonly generateNewCodeOnTransfer: IBackendMap | null;
    readonly paperTicket: IBackendMap | null;
    readonly seated: IBackendMap | null;
    readonly shoppingCart: IBackendMap | null;
    readonly ticketTransfer: IBackendMap | null;
    readonly unicorn: IBackendMap | null;
    readonly waitingList: IBackendMap | null;
  } | null;
  readonly freeEvent: boolean | null;
  readonly id: string;
  readonly isTicketAvailableAtDoor: boolean | null;
  readonly maxTicketsLimit: number | null;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly onSaleNotification: boolean | null;
  readonly onSaleNotificationAt: string | null;
  readonly onSaleNotificationStatus: boolean | null;
  readonly onSaleSmsReminders: number;
  readonly postFanPriceFees: ReadonlyArray<string | null> | null;
  readonly printedTicketFormat: PrintedTicketFormat | null;
  readonly restrictCountries: ReadonlyArray<CountryCode | null> | null;
  readonly restrictCountriesKind: RestrictionKind | null;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly priceTiersBreakdown: ReadonlyArray<{
        readonly appSold: number;
        readonly posSold: number;
        readonly priceTier: {
          readonly id: string;
        };
        readonly reserved: number;
        readonly sold: number;
        readonly terminalSold: number;
      } | null>;
      readonly ticketType: {
        readonly id: string;
        readonly ticketPoolId: string | null;
      };
      readonly ticketTypeId: string;
      readonly totalAppSold: number;
      readonly totalPosSold: number;
      readonly totalReserved: number;
      readonly totalSold: number;
      readonly totalTerminalSold: number;
    } | null>;
  } | null;
  readonly smsTtys: ReadonlyArray<{
    readonly archived: boolean | null;
    readonly id: string;
    readonly name: string | null;
    readonly onSaleDate: string | null;
    readonly onSaleNotificationSentAt: string | null;
  } | null> | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly taxSettings: {
    readonly clubNight: boolean | null;
    readonly franceMainstream: boolean | null;
  } | null;
  readonly ticketPools: ReadonlyArray<{
    readonly id: string;
    readonly maxAllocation: number | null;
    readonly name: string | null;
  } | null> | null;
  readonly ticketType: string | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly activateCodeDateOffset: number | null;
    readonly additionalPaymentMethods: ReadonlyArray<PaymentMethods | null> | null;
    readonly allocation: number;
    readonly allowSeatChange: boolean | null;
    readonly announceDate: string | null;
    readonly archived: boolean | null;
    readonly attractivePriceType: string | null;
    readonly attractiveSeatingAreaType: string | null;
    readonly codeLocked: boolean;
    readonly description: string | null;
    readonly doorSalesEnabled: boolean;
    readonly doorSalesPrice: number | null;
    readonly doorSalesPriceTaxed: number | null;
    readonly doorSalesTax: number | null;
    readonly endDate: string | null;
    readonly externalSkus: ReadonlyArray<string | null> | null;
    readonly faceValue: number;
    readonly fees: ReadonlyArray<{
      readonly amount: number | null;
      readonly applicable: boolean | null;
      readonly split: ReadonlyArray<{
        readonly amount: number | null;
        readonly destination: FeeDestination | null;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly type: FeeType;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly hidden: boolean;
    readonly icon: string | null;
    readonly id: string;
    readonly increment: number | null;
    readonly isStream: boolean;
    readonly maximumIncrements: number | null;
    readonly name: string | null;
    readonly offSaleDate: string | null;
    readonly onSaleDate: string | null;
    readonly presale: boolean;
    readonly priceBreakdown: {
      readonly breakdown: ReadonlyArray<{
        readonly applicable: boolean | null;
        readonly computed: number;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly computed: number;
          readonly destination: FeeDestination | null;
        } | null>;
        readonly type: FeeType;
      } | null>;
      readonly faceValue: number;
      readonly friendlyFaceValue: number | null;
      readonly friendlyPrice: number | null;
      readonly split: ReadonlyArray<{
        readonly computed: number;
        readonly destination: FeeDestination;
      } | null>;
      readonly total: number;
      readonly totalWithPwl: number | null;
      readonly totalWithoutPwl: number | null;
    } | null;
    readonly priceHidden: boolean;
    readonly priceTierType: PriceTierTypes | null;
    readonly priceTiers: ReadonlyArray<{
      readonly allocation: number | null;
      readonly attractivePriceType: string | null;
      readonly doorSalesPrice: number | null;
      readonly doorSalesPriceTaxed: number | null;
      readonly faceValue: number;
      readonly fees: ReadonlyArray<{
        readonly amount: number | null;
        readonly applicable: boolean | null;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null> | null;
        readonly type: FeeType;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly id: string;
      readonly name: string | null;
      readonly priceBreakdown: {
        readonly breakdown: ReadonlyArray<{
          readonly applicable: boolean | null;
          readonly computed: number;
          readonly split: ReadonlyArray<{
            readonly amount: number | null;
            readonly computed: number;
            readonly destination: FeeDestination | null;
          } | null>;
          readonly type: FeeType;
        } | null>;
        readonly faceValue: number;
        readonly friendlyFaceValue: number | null;
        readonly friendlyPrice: number | null;
        readonly split: ReadonlyArray<{
          readonly computed: number;
          readonly destination: FeeDestination;
        } | null>;
        readonly total: number;
        readonly totalWithPwl: number | null;
        readonly totalWithoutPwl: number | null;
      } | null;
      readonly time: string | null;
    } | null> | null;
    readonly requiresAddress: boolean | null;
    readonly requiresOtherTypeIds: ReadonlyArray<string | null> | null;
    readonly reservedSeating: boolean | null;
    readonly reservedSeatingType: ReservedSeatingTypes | null;
    readonly salesLimit: number | null;
    readonly seatCategories: ReadonlyArray<{
      readonly id: string | null;
      readonly name: string;
      readonly seatsIoKey: string | null;
    } | null> | null;
    readonly seatmapUrl: string | null;
    readonly startDate: string | null;
    readonly streamLink: string | null;
    readonly ticketPoolId: string | null;
    readonly venueScheduleId: string | null;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly venueSchedules: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly addressLocality: string | null;
    readonly addressRegion: string | null;
    readonly addressState: string | null;
    readonly atLeastOneSeatingChart: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null> | null;
    } | null;
    readonly capacity: number;
    readonly countryCode: string | null;
    readonly fullAddress: string | null;
    readonly label: string | null;
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly postalCode: string | null;
    readonly profileDetails: {
      readonly imageAttachment: {
        readonly cdnUrl: string;
      } | null;
      readonly imageCropRegion: {
        readonly height: number | null;
        readonly width: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
    } | null;
    readonly streetAddress: string | null;
    readonly timezoneName: string | null;
    readonly value: string;
    readonly venueImages: ReadonlyArray<{
      readonly attachment: {
        readonly cdnUrl: string;
      } | null;
    } | null> | null;
  } | null> | null;
  readonly waitingListExchangeWindows: ReadonlyArray<{
    readonly duration: number;
    readonly id: string | null;
    readonly offset: number | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventTicketTypeDoorPricing_event" | "EventTicketTypeIncrements_event" | "EventTicketTypePricing_event" | "EventTicketTypeTimeline_event" | "TaxRates_event">;
  readonly " $fragmentType": "Tickets_event";
};
export type Tickets_event$key = {
  readonly " $data"?: Tickets_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"Tickets_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezoneName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "announceDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onSaleDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offSaleDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codeLocked",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "doorSalesOnly",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "includeArchived",
    "value": true
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticketPoolId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doorSalesPrice",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doorSalesPriceTaxed",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attractivePriceType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applicable",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destination",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Fee",
  "kind": "LinkedField",
  "name": "fees",
  "plural": true,
  "selections": [
    (v18/*: any*/),
    (v19/*: any*/),
    (v20/*: any*/),
    (v21/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v18/*: any*/),
        (v22/*: any*/),
        (v20/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "computed",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "priceBreakdown",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeOutput",
      "kind": "LinkedField",
      "name": "breakdown",
      "plural": true,
      "selections": [
        (v24/*: any*/),
        (v19/*: any*/),
        (v21/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FeeSplitOutput",
          "kind": "LinkedField",
          "name": "split",
          "plural": true,
          "selections": [
            (v18/*: any*/),
            (v24/*: any*/),
            (v22/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithPwl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithoutPwl",
      "storageKey": null
    },
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v24/*: any*/),
        (v22/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyFaceValue",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v27 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v28 = [
  (v0/*: any*/)
],
v29 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cdnUrl",
    "storageKey": null
  }
],
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canAdd",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canChangeAllocation",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v33 = [
  (v32/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Tickets_event",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeIncrements_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypePricing_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeDoorPricing_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventTicketTypeTimeline_event"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceTvPlatform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTicketsLimit",
      "storageKey": null
    },
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managePromotions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageTickets",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shoppingCart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unicorn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "competition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketTransfer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waitingList",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paperTicket",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generateNewCodeOnTransfer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabledPwl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coolingOffPeriod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fanPickSeat",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VenueSchedule",
      "kind": "LinkedField",
      "name": "venueSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketPool",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v10/*: any*/),
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresOtherTypeIds",
          "storageKey": null
        },
        (v0/*: any*/),
        (v9/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        },
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalPaymentMethods",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activateCodeDateOffset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "venueScheduleId",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStream",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "presale",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "increment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maximumIncrements",
          "storageKey": null
        },
        (v15/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesEnabled",
          "storageKey": null
        },
        (v16/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesTax",
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attractiveSeatingAreaType",
          "storageKey": null
        },
        (v17/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seatmapUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceTierType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalSkus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceHidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSeatChange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedSeating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedSeatingType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatCategory",
          "kind": "LinkedField",
          "name": "seatCategories",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seatsIoKey",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v23/*: any*/),
        (v25/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceTier",
          "kind": "LinkedField",
          "name": "priceTiers",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v9/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v14/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            },
            (v17/*: any*/),
            (v23/*: any*/),
            (v25/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationStatus",
      "kind": "LinkedField",
      "name": "attractiveStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSeatingChart",
      "kind": "LinkedField",
      "name": "eventSeatingChart",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "group",
              "value": "BY_CATEGORY_KEY"
            }
          ],
          "kind": "ScalarField",
          "name": "seatsIoEventReport",
          "storageKey": "seatsIoEventReport(group:\"BY_CATEGORY_KEY\")"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seatsIoEventId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartManagerCredentials",
          "kind": "LinkedField",
          "name": "chartManagerCredentials",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "secretKey",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatingChannel",
          "kind": "LinkedField",
          "name": "seatingChannels",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seatsIoChannel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "channelType",
              "storageKey": null
            },
            (v9/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatingChart",
          "kind": "LinkedField",
          "name": "venueChart",
          "plural": false,
          "selections": [
            (v26/*: any*/),
            (v27/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLocality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressRegion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        },
        (v26/*: any*/),
        (v27/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullAddress",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        {
          "alias": "atLeastOneSeatingChart",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "SeatingChartConnection",
          "kind": "LinkedField",
          "name": "seatingCharts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SeatingChartEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SeatingChart",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v28/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "seatingCharts(first:1)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDetails",
          "kind": "LinkedField",
          "name": "profileDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "imageAttachment",
              "plural": false,
              "selections": (v29/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CropRegion",
              "kind": "LinkedField",
              "name": "imageCropRegion",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "x",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "y",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VenueImage",
          "kind": "LinkedField",
          "name": "venueImages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "attachment",
              "plural": false,
              "selections": (v29/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttractiveFields",
      "kind": "LinkedField",
      "name": "attractiveFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "compatibilityAe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "siaeGenreType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "integrationDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFree",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketTypeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalReserved",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalTerminalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceTierBreakdown",
              "kind": "LinkedField",
              "name": "priceTiersBreakdown",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceTier",
                  "kind": "LinkedField",
                  "name": "priceTier",
                  "plural": false,
                  "selections": (v28/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reserved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "posSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terminalSold",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketTypes",
          "kind": "LinkedField",
          "name": "ticketTypes",
          "plural": false,
          "selections": [
            (v30/*: any*/),
            (v31/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeDoorSalesEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeIcon",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeIncrements",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOffSaleDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOnSaleDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOrder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeSeatmap",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeTierNames",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canDelete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canHide",
              "storageKey": null
            },
            (v32/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdatePrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeExternalSkus",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketPools",
          "kind": "LinkedField",
          "name": "ticketPools",
          "plural": false,
          "selections": [
            (v30/*: any*/),
            (v32/*: any*/),
            (v31/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canRemove",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "maxTicketsLimit",
          "plural": false,
          "selections": (v33/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "endDate",
          "plural": false,
          "selections": (v33/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesDate",
          "kind": "LinkedField",
          "name": "onSaleDate",
          "plural": false,
          "selections": (v33/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feesBehaviour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basePriceFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postFanPriceFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTicketAvailableAtDoor",
      "storageKey": null
    },
    (v23/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableUsTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WaitingListExchangeWindow",
      "kind": "LinkedField",
      "name": "waitingListExchangeWindows",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offset",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "restrictCountries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "restrictCountriesKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStreamDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStreamDvrEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diceStreamRewatchEnabledUntil",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeEventDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "barcodeType",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printedTicketFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxSettings",
      "kind": "LinkedField",
      "name": "taxSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clubNight",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "franceMainstream",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxRates_event"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleNotificationAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleSmsReminders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleNotificationStatus",
      "storageKey": null
    },
    {
      "alias": "smsTtys",
      "args": (v10/*: any*/),
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v9/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onSaleNotificationSentAt",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "80b910a340082c97c3248fc686182c22";

export default node;
