import React, { FC, memo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'

import TextInput, { ITextInputProps } from './TextInput'
import CopyButton from './CopyButton'
import { breakpoints } from '../utils/variables'

const StyledCopyButton = styled(CopyButton)`
  width: 32px;
  height: 32px;
  margin: 4px;
`

const InputWithCopy: FC<React.PropsWithChildren<ITextInputProps>> = (props) => {
  const intl = useIntl()
  const { value } = props

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  return (
    <TextInput
      {...props}
      affix={
        <StyledCopyButton
          link={value}
          title={intl.formatMessage({ id: 'copy_prompt' })}
          titlePlacement={isMobile ? 'left' : 'top'}
        />
      }
    />
  )
}

export default memo(InputWithCopy)
