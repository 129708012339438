import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { filter, getOr, isEmpty } from 'lodash/fp'
import InfiniteScroll from 'react-infinite-scroller'

import { usePaginationFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import EventListItem from './EventListItem'

import { Loader } from '../../../components/Loader'
import Button from '../../../components/Button'
import { OnDesktop } from '../../../components/Breakpoints'
import CollaboratorsBanner from '../../../components/CollaboratorsBanner'

import { EventList as List } from './EventListItemGrid'
import EventListEmpty from './EventListEmpty'
import EventListHeader from './EventListHeader'

import { EventList_viewer$key } from '../../../__generated__/EventList_viewer.graphql'
import EventListTypeContext from '../util/eventListTypeContext'

const LoadingMore = styled.div`
  display: block;
  margin: 24px auto;
  text-align: center;
`

const EventsWrapper = styled.div<{ isLoading?: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    `
    opacity: .4;
    pointer-events: none;
  `};
`

interface IProps {
  viewer: EventList_viewer$key
  orderBy?: string
  setOrderBy?: (v: string) => void
  loading?: boolean
  embedded?: boolean
}

const EventList: FC<IProps> = ({ viewer: viewerKey, embedded, orderBy, setOrderBy, loading }) => {
  const {
    data: viewer,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    graphql`
      fragment EventList_viewer on Viewer
      @refetchable(queryName: "EventListPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 15 }
        cursor: { type: "String" }
        where: { type: "EventWhereInput", defaultValue: {} }
        scopes: { type: "EventScopesInput", defaultValue: {} }
        orderBy: { type: "[EventsConnectionOrder]", defaultValue: [dateASC] }
        needBalance: { type: "Boolean", defaultValue: false }
        isDraft: { type: "Boolean", defaultValue: true }
        noSelfPayouts: { type: "Boolean", defaultValue: false }
        searchTerm: { type: String }
      ) {
        account {
          stripeAccountState
        }
        events(
          first: $count
          after: $cursor
          where: $where
          searchTerm: $searchTerm
          scopes: $scopes
          orderBy: $orderBy
        ) @connection(key: "EventList_events") {
          edges {
            node {
              id
              ...EventListItem_event
                @arguments(needBalance: $needBalance, isDraft: $isDraft, noSelfPayouts: $noSelfPayouts)
            }
          }
        }
      }
    `,
    viewerKey
  )

  const edges = useMemo(() => viewer.events?.edges || [], [viewer.events?.edges])
  const hasNextPage = getOr(false, 'events.pageInfo.hasNextPage', viewer)

  const intl = useIntl()

  const [stickyHeader, setStickyHeader] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset
      const stickedHeader = position > 20
      if (stickyHeader !== stickedHeader) {
        setStickyHeader(stickedHeader)
      }
    }
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [stickyHeader, setStickyHeader])

  const listType = useContext(EventListTypeContext)

  const loadMoreEvents = useCallback(() => {
    if (!hasNext || isLoadingNext) return
    loadNext(15)
  }, [hasNext, isLoadingNext, loadNext])

  const validEdges = useMemo(() => filter('node', edges), [edges])

  const doLoadMore = useCallback(() => !isLoadingNext && loadMoreEvents(), [isLoadingNext, loadMoreEvents])

  const doOrderBy = useCallback(
    (e: any) => {
      const orderType = e.currentTarget.dataset.order
      if (setOrderBy) {
        setOrderBy(orderType)
      }
    },
    [setOrderBy]
  )

  const stripeIsBroken = !!viewer.account && viewer.account?.stripeAccountState !== 'OK'

  if (isEmpty(validEdges) && !loading) {
    return <EventListEmpty embedded={embedded} />
  }

  return (
    <>
      <CollaboratorsBanner />
      <List>
        <InfiniteScroll loadMore={doLoadMore} hasMore={hasNextPage}>
          <OnDesktop>
            <EventListHeader
              type={listType}
              currentOrder={orderBy}
              orderBy={setOrderBy && doOrderBy}
              bordered={stickyHeader}
            />
          </OnDesktop>
          <EventsWrapper isLoading={loading}>
            {validEdges.map(
              (e) => e?.node && <EventListItem key={e.node.id} event={e.node} stripeIsBroken={stripeIsBroken} />
            )}
          </EventsWrapper>
        </InfiniteScroll>
        {(isLoadingNext || (isEmpty(validEdges) && loading)) && (
          <LoadingMore>
            <Loader />
          </LoadingMore>
        )}
        {!isLoadingNext && hasNextPage && (
          <Button onClick={loadMoreEvents} className="block mt-lg mb-lg ml-auto mr-auto">
            {intl.formatMessage({ id: 'actions.load_more' })}
          </Button>
        )}
      </List>
    </>
  )
}

export default memo(EventList)
