import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { FormikProvider } from 'formik'
import { getYear } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import { breakpoints, color, font, mediaQuery, zIndex } from '../../utils/variables'
import { Form, FormRow } from '../../components/Form'
import Button from '../../components/Button'
import ProgressBar from './components/ProgressBar'
import useSteps from './hooks/useSteps'
import SignupComplete from './components/SignupComplete'
import useSignupForm from './hooks/useSignupForm'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import countrySpecificFields from './util/countrySpecificFields'
import MapsProvider from '../../context/maps'
import { TitleTooltip } from '../../components/Tooltip'
import { OnMobile } from '../../components/Breakpoints'
import AuthHeader from '../Auth/AuthHeader'
import AuthLayout from '../Auth/AuthLayout'
import { PageViewTracker } from '../../context/tracking'

const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  height: 72px;

  padding: 16px 66px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  background-color: ${color.white};
  z-index: ${zIndex.header};

  ${mediaQuery.lessThan('tablet')`
    position: fixed;
    bottom: unset;
    top: 100%;
    margin-top: -72px;
    padding: 16px;
    justify-content: space-between;
  `}
`

const Body = styled.form`
  margin: 85px -30px 0 -30px;

  height: calc(100vh - 72px - 85px);

  display: flex;
  align-items: center;
  overflow: auto;

  ${mediaQuery.lessThan('tablet')`
    display: block;
    height: unset;
    overflow: visible;
    margin: -4px -8px 118px -8px;
  `}

  input[type=submit] {
    visibility: hidden;
  }
`

const SForm = styled(Form)`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;

  min-height: 350px;
  padding-bottom: 32px;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: ${font.size.xlg}px;
    line-height: 120%;

    margin-bottom: 4px;
  }

  p {
    margin: 0 0 32px 0;
  }

  ${mediaQuery.lessThan('tablet')`
    height: unset;
    padding-bottom: 0;
  `}
`

const SDiv = styled.div<{ cursor?: string }>`
  cursor: ${({ cursor }) => cursor || 'auto'};
`

const BackButton = styled(Button)`
  &:focus-visible {
    color: ${color.primary};
  }
`

const NextButton = styled(Button)`
  &:focus-visible {
    background-color: ${color.primary};
    border-color: ${color.primary};
    color: ${color.white};
  }
`

const Copyright = styled.div`
  text-align: center;
  margin-top: 16px;
  opacity: 0.5;

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.sm}px;
  `}
`

const STEPS = 3
const YEAR = getYear(new Date())

const SignupPage: FC = () => {
  const intl = useIntl()
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { step, setStep, goBack, goNext } = useSteps(STEPS)

  const { formik, countryOptions } = useSignupForm(setStep)
  const { status, values, submitForm, isValid, isSubmitting, handleReset } = formik

  const [italianLegalRequirements, setItalianLegalRequirements] = useState(false)
  const [isAgreed, setIsAgreed] = useState(false)
  useEffect(() => {
    setIsAgreed(false)
    setItalianLegalRequirements(false)
  }, [values.country])

  const hasFieldsMissing = useMemo(() => {
    const italyOk = values.country?.alpha2 !== 'IT' || italianLegalRequirements

    if (step === 0) {
      return !values.companyName || !values.displayName
    } else if (step === 1) {
      const countrySchema = countrySpecificFields(values.country?.alpha2 || null)
      let countryValid = false
      if (countrySchema) {
        try {
          countrySchema.validateSync(values.countrySpecificFields, { strict: true, abortEarly: true })
          countryValid = true
        } catch (e) {
          // DO NOTHING, it's invalid
        }
      }

      return !countryValid || !values.city || !italyOk
    } else {
      // Most fields are Yup checked, here are only additional ones
      return !isAgreed || !italyOk
    }
  }, [isAgreed, italianLegalRequirements, step, values])

  const ref = useRef<HTMLDivElement | null>(null)

  const ctaDisabled = step === STEPS - 1 ? !isValid || hasFieldsMissing : hasFieldsMissing

  const ctaAction = ctaDisabled ? undefined : step === STEPS - 1 ? submitForm : goNext

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault()
      if (ctaAction) ctaAction()
    },
    [ctaAction]
  )

  if (status && status.success) {
    return <SignupComplete />
  }

  return (
    <>
      <PageViewTracker trackId="account_create" />
      <MapsProvider>
        <AuthHeader />
        <AuthLayout>
          <Body noValidate onSubmit={onFormSubmit} onReset={handleReset}>
            <SForm>
              <h1>{intl.formatMessage({ id: `signup.wizard.step${step + 1}.title` })}</h1>
              <p>{intl.formatMessage({ id: `signup.wizard.step${step + 1}.subtitle`, defaultMessage: ' ' })}</p>

              <FormikProvider value={formik}>
                {step === 0 && <Step1 />}
                {step === 1 && (
                  <Step2
                    countryOptions={countryOptions}
                    italianLegalRequirements={italianLegalRequirements}
                    setItalianLegalRequirements={setItalianLegalRequirements}
                  />
                )}
                {step === 2 && <Step3 isAgreed={isAgreed} setIsAgreed={setIsAgreed} />}
              </FormikProvider>

              <OnMobile>
                <FormRow>
                  <Copyright>{intl.formatMessage({ id: 'dice_copyright_with_year' }, { year: YEAR })}</Copyright>
                </FormRow>
              </OnMobile>

              <input type="submit" hidden tabIndex={-1} />
            </SForm>
          </Body>
          <Footer ref={ref}>
            <ProgressBar percentage={(100 * (step + 1)) / STEPS} />

            {step > 0 ? (
              <BackButton preset="secondary" onClick={goBack} disabled={isSubmitting}>
                {intl.formatMessage({ id: 'actions.back' })}
              </BackButton>
            ) : (
              <div />
            )}

            <TitleTooltip
              title={ctaDisabled ? intl.formatMessage({ id: 'fields_missing' }) : undefined}
              placement={isMobile ? 'left' : 'top'}
              key={step}
            >
              <SDiv cursor={ctaDisabled ? 'not-allowed' : undefined}>
                <NextButton onClick={ctaAction} loading={isSubmitting} disabled={ctaDisabled}>
                  {intl.formatMessage({ id: step === STEPS - 1 ? 'signup.cta' : 'actions.next' })}
                </NextButton>
              </SDiv>
            </TitleTooltip>
          </Footer>
        </AuthLayout>
      </MapsProvider>
    </>
  )
}

export default memo(SignupPage)
