/**
 * @generated SignedSource<<733c797309b1685486dff8f9b51abffc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type FeeInput = {
  active?: boolean | null;
  amount: number;
  split: ReadonlyArray<FeeSplitInput | null>;
  type: FeeType;
  unit: FeeUnit;
};
export type FeeSplitInput = {
  amount: number;
  destination: FeeDestination;
  unit: FeeUnit;
};
export type getPriceBreakdownQuery$variables = {
  basePriceFees?: ReadonlyArray<string | null> | null;
  billingPromoterId?: string | null;
  disableUsTax: boolean;
  eventId?: string | null;
  faceValue: number;
  fees?: ReadonlyArray<FeeInput | null> | null;
  forcePwlActive?: boolean | null;
  ignorePwlFee: boolean;
  postFanPriceFees?: ReadonlyArray<string | null> | null;
  venueIds?: ReadonlyArray<string | null> | null;
};
export type getPriceBreakdownQuery$data = {
  readonly viewer: {
    readonly priceCalculation: {
      readonly breakdown: ReadonlyArray<{
        readonly amount: number | null;
        readonly applicable: boolean | null;
        readonly computed: number;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly computed: number;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null>;
        readonly type: FeeType;
        readonly unit: FeeUnit;
      } | null>;
      readonly faceValue: number;
      readonly friendlyFaceValue: number | null;
      readonly friendlyPrice: number | null;
      readonly split: ReadonlyArray<{
        readonly computed: number;
        readonly destination: FeeDestination;
      } | null>;
      readonly total: number;
      readonly totalWithPwl: number | null;
      readonly totalWithoutPwl: number | null;
    } | null;
  } | null;
};
export type getPriceBreakdownQuery = {
  response: getPriceBreakdownQuery$data;
  variables: getPriceBreakdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "basePriceFees"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "billingPromoterId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "disableUsTax"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "faceValue"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fees"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forcePwlActive"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ignorePwlFee"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postFanPriceFees"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "venueIds"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "computed",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destination",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "basePriceFees",
      "variableName": "basePriceFees"
    },
    {
      "kind": "Variable",
      "name": "billingPromoterId",
      "variableName": "billingPromoterId"
    },
    {
      "kind": "Variable",
      "name": "disableUsTax",
      "variableName": "disableUsTax"
    },
    {
      "kind": "Variable",
      "name": "eventId",
      "variableName": "eventId"
    },
    {
      "kind": "Variable",
      "name": "faceValue",
      "variableName": "faceValue"
    },
    {
      "kind": "Variable",
      "name": "fees",
      "variableName": "fees"
    },
    {
      "kind": "Variable",
      "name": "forcePwlActive",
      "variableName": "forcePwlActive"
    },
    {
      "kind": "Variable",
      "name": "ignorePwlFee",
      "variableName": "ignorePwlFee"
    },
    {
      "kind": "Variable",
      "name": "postFanPriceFees",
      "variableName": "postFanPriceFees"
    },
    {
      "kind": "Variable",
      "name": "venueIds",
      "variableName": "venueIds"
    }
  ],
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "priceCalculation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithPwl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithoutPwl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "faceValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyFaceValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeOutput",
      "kind": "LinkedField",
      "name": "breakdown",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "applicable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FeeSplitOutput",
          "kind": "LinkedField",
          "name": "split",
          "plural": true,
          "selections": [
            (v11/*: any*/),
            (v12/*: any*/),
            (v10/*: any*/),
            (v13/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        (v13/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getPriceBreakdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v9/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "getPriceBreakdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea0734d03396ac316ca0c66b8aa6e59e",
    "id": null,
    "metadata": {},
    "name": "getPriceBreakdownQuery",
    "operationKind": "query",
    "text": "query getPriceBreakdownQuery(\n  $billingPromoterId: ID\n  $eventId: ID\n  $faceValue: Int!\n  $venueIds: [ID]\n  $fees: [FeeInput]\n  $basePriceFees: [String]\n  $postFanPriceFees: [String]\n  $ignorePwlFee: Boolean!\n  $disableUsTax: Boolean!\n  $forcePwlActive: Boolean\n) {\n  viewer {\n    priceCalculation(billingPromoterId: $billingPromoterId, eventId: $eventId, faceValue: $faceValue, venueIds: $venueIds, fees: $fees, ignorePwlFee: $ignorePwlFee, forcePwlActive: $forcePwlActive, disableUsTax: $disableUsTax, basePriceFees: $basePriceFees, postFanPriceFees: $postFanPriceFees) {\n      total\n      totalWithPwl\n      totalWithoutPwl\n      faceValue\n      friendlyPrice\n      friendlyFaceValue\n      breakdown {\n        computed\n        amount\n        unit\n        type\n        applicable\n        split {\n          amount\n          unit\n          computed\n          destination\n        }\n      }\n      split {\n        computed\n        destination\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e0517ebdec42dc092096ca5d0d9a7e6";

export default node;
