/**
 * @generated SignedSource<<ad4186ab8dc55e8750ab14184c9437a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemittanceModal_event$data = {
  readonly eventIdLive: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "RemittanceModal_event";
};
export type RemittanceModal_event$key = {
  readonly " $data"?: RemittanceModal_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemittanceModal_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemittanceModal_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "58e9772b75d4ea45b09db8259ccdae94";

export default node;
