import { isString } from 'lodash/fp'
import React, { FC, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import Button from '../../components/Button'
import { trackingContext } from '../../context/tracking'

import { font, mediaQuery } from '../../utils/variables'

interface IHeaderProps {
  title: string
  backLink?: boolean | string
  description?: string
}

export const AuthFormHeader: FC<React.PropsWithChildren<IHeaderProps>> = ({ title, backLink }) => {
  const intl = useIntl()
  const { trackEvent } = useContext(trackingContext)

  const trackLogin = useCallback(() => trackEvent('login_started', { source_name: 'auth_page_header' }), [trackEvent])

  const where = isString(backLink) ? backLink : '/auth/login'

  return (
    <AuthFormHeaderBlock>
      <h2>{title}</h2>
      {backLink && (
        <Button preset="link" color="primary" to={where} onClick={where === '/auth/login' ? trackLogin : undefined}>
          {intl.formatMessage({ id: 'auth.back_to_signin' })}
        </Button>
      )}
    </AuthFormHeaderBlock>
  )
}

export const AuthForm = styled.form`
  text-align: left;
`

const AuthFormHeaderBlock = styled.div`
  margin-bottom: 24px;
  h2 {
    font-weight: 300;
    margin: 0 0 8px;
  }
`

export const AuthFormSuccess = styled.div`
  margin-bottom: 70px;
  svg {
    display: inline-block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
  p {
    font-size: ${font.size.lg}px;
    line-height: 1.4em;
  }
`

export const AuthFormWrapper = styled.div`
  width: 360px;
  margin: 0 auto;

  ${mediaQuery.lessThan('tablet')`
    width: auto;
  `}
`
