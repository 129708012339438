import React, { FC, memo, PropsWithChildren, ReactNode } from 'react'
import { isNil } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

import { EventCostCurrency } from '../../enums.generated'
import { CURRENCY } from '../../utils/formatters/number'
import { TitleTooltip } from '../Tooltip'
import { Text } from '../Text'
import { breakpoints, color, font, mediaQuery } from '../../utils/variables'

const PriceBreakdown = styled.div`
  margin: -8px;

  min-width: 248px;

  font-size: ${font.size.sm}px;
  text-align: left;
  line-height: 125%;

  strong {
    display: block;
  }

  h1 {
    font-weight: bold;
    font-size: ${font.size.md}px;
    margin-bottom: 50px;
  }

  & > div + div {
    margin-top: 4px;
  }

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.base}px;
    padding-bottom: 32px;
    margin-top: 0;
  `}
`

const Item = styled.div<{ isBold?: boolean; isNonActive?: boolean }>`
  display: flex;
  justify-content: space-between;

  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  color: ${({ isNonActive }) => (isNonActive ? color.grey : color.text)};
  text-decoration: ${({ isNonActive }) => (isNonActive ? `line-through ${color.text}` : 'unset')};

  & > *:first-child {
    flex: 1;
  }

  & > *:last-child {
    text-align: right;
  }
`

const Separator = styled.div`
  && {
    margin: 12px auto;
  }

  height: 1px;
  background: ${color.lightgrey};

  ${mediaQuery.lessThan('tablet')`
    max-width: 248px;
  `}
`

interface IBreakdown {
  faceValue: number | null
  total: number | null
  totalWithPwl: number | null
  totalWithoutPwl: number | null
}

interface IProps {
  title: string
  priceBreakdown: IBreakdown | null
  currency: EventCostCurrency | null
  placement?: string
  faceValueLabel?: ReactNode
  fanPriceLabel?: ReactNode
}

const DetailedPriceBreakdownTooltip: FC<PropsWithChildren<IProps>> = ({
  title,
  priceBreakdown,
  currency,
  placement,
  faceValueLabel,
  fanPriceLabel,
  children,
}) => {
  const intl = useIntl()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const hasPwl =
    !isNil(priceBreakdown?.totalWithPwl) &&
    !isNil(priceBreakdown?.totalWithoutPwl) &&
    priceBreakdown?.totalWithPwl !== priceBreakdown?.totalWithoutPwl

  const faceValue = priceBreakdown?.faceValue || 0
  const priceWithoutPwl = priceBreakdown?.totalWithoutPwl || priceBreakdown?.total || 0
  const priceWithPwl = priceBreakdown?.totalWithPwl || priceBreakdown?.total || 0
  const feesWithoutPwl = priceWithoutPwl - faceValue
  const pwlAddedFees = priceWithPwl - priceWithoutPwl

  const breakdownTooltip = (
    <PriceBreakdown>
      {isMobile && <h1>{title}</h1>}
      <Item>
        <div>{faceValueLabel || intl.formatMessage({ id: 'face_value' })}</div>
        <div data-id="faceValue">{intl.formatNumber(faceValue / 100, CURRENCY(faceValue, currency))}</div>
      </Item>
      <Item>
        <div>{intl.formatMessage({ id: 'fees' })}</div>
        <div data-id="nonPwlFees">{intl.formatNumber(feesWithoutPwl / 100, CURRENCY(feesWithoutPwl, currency))}</div>
      </Item>
      <Item
        isBold
        isNonActive={
          hasPwl && !(Math.abs((priceBreakdown?.totalWithoutPwl || 0) - (priceBreakdown?.total || 0)) < 0.01)
        }
      >
        <div>{fanPriceLabel || intl.formatMessage({ id: 'price.without_pwl' })}</div>
        <div data-id="priceWithoutPwl">
          {intl.formatNumber(priceWithoutPwl / 100, CURRENCY(priceWithoutPwl, currency))}
        </div>
      </Item>
      {hasPwl && (
        <>
          <Separator />
          <Item>
            <div>{intl.formatMessage({ id: 'fees' })}</div>
            <div data-id="pwlAddedFees">{intl.formatNumber(pwlAddedFees / 100, CURRENCY(pwlAddedFees, currency))}</div>
          </Item>
          <Item
            isBold
            isNonActive={!(Math.abs((priceBreakdown?.totalWithPwl || 0) - (priceBreakdown?.total || 0)) < 0.01)}
          >
            <div>{intl.formatMessage({ id: 'price.with_pwl' })}</div>
            <div data-id="priceWithPwl">{intl.formatNumber(priceWithPwl / 100, CURRENCY(priceWithPwl, currency))}</div>
          </Item>
        </>
      )}
    </PriceBreakdown>
  )

  return children ? (
    <TitleTooltip
      overlayClassName={cn({ '-mobile': isMobile })}
      preset="underlined"
      title={breakdownTooltip}
      placement={isMobile ? 'bottom' : placement}
      prefixCls="rc-tooltip"
    >
      {children}
    </TitleTooltip>
  ) : (
    <Text fontSize="base" color="greyer" data-id="plusFees">
      {'+ '}
      <TitleTooltip
        overlayClassName={cn({ '-mobile': isMobile })}
        preset="underlined"
        title={breakdownTooltip}
        placement={isMobile ? 'bottom' : placement}
        prefixCls="rc-tooltip"
      >
        {intl.formatMessage({ id: 'fees' }).toLowerCase()}
      </TitleTooltip>
    </Text>
  )
}

export default memo(DetailedPriceBreakdownTooltip)
