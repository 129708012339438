import React, { FC, HTMLProps } from 'react'
import styled from 'styled-components/macro'
import { textStyle } from '../utils/typography'
import { input, mediaQuery } from '../utils/variables'

type Spacing = 'default' | 'small' | 'medium' | 'sticky' | 'extra' | undefined

const FormWrapper = styled.div<{ spacing?: Spacing; spacingSize?: number }>`
  display: block;
  position: relative;
  ${({ spacing }) => {
    switch (spacing) {
      case 'sticky':
        return `
          & + & {
            margin-top: -2px;
          }
          ${Row} + ${Row} {
            margin-top: -2px;
          }
          ${Col} + ${Col} {
            margin-left: -2px;
            margin-top: 0 !important;
          }
        `
      case 'small':
        return `
          & + & {
            margin-top: 8px;
          }
          ${Row} + ${Row} {
            margin-top: 8px;
          }
          ${Col} + ${Col} {
            margin-left: 8px;
          }
        `
      case 'medium':
        return `
          & + & {
            margin-top: 16px;
          }
          ${Row} + ${Row} {
            margin-top: 16px;
          }
          ${Col} + ${Col} {
            margin-left: 16px;
          }
        `
      case 'extra':
        return `
          & + & {
            margin-top: 32px;
          }
          ${Row} + ${Row} {
            margin-top: 32px;
          }
          ${Col} + ${Col} {
            margin-left: 32px;
          }
        `
      default:
        return `
          & + & {
            margin-top: 24px;
          }
          ${Row} + ${Row} {
            margin-top: 24px;
          }
          ${Col} + ${Col} {
            margin-left: 24px;
          }
        `
    }
  }}

  ${({ spacingSize }) => {
    if (spacingSize) {
      return `${Col} + ${Col} {
        margin-left: ${spacingSize}px;
      }`
    }
  }}
`

export const Row = styled.div<{ columnOnMobile?: boolean; connectedFields?: boolean; spacing?: Spacing }>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  ${mediaQuery.lessThan<{ columnOnMobile?: boolean; spacing?: Spacing }>('tablet')`
    ${({ columnOnMobile, spacing }) =>
    columnOnMobile &&
      `
      display: block;
      ${Col} + ${Col} {
        margin-left: 0 !important;
        margin-top: ${spacing === 'small' ? '8px' : '24px'};
      }
    `}
  `}
`

export const Col = styled.div`
  flex: 1 1 0;
  min-width: 1px;
  position: relative;
  & + & {
    margin-left: -2px;
  }
`

interface IFormProps {
  spacing?: Spacing
  spacingSize?: number
  className?: string
}

const RawForm: FC<React.PropsWithChildren<IFormProps>> = ({
  children,
  className,
  spacing = 'default',
  spacingSize,
}) => (
  <FormWrapper spacing={spacing} spacingSize={spacingSize} className={className}>
    {children}
  </FormWrapper>
)

export const Form = styled(RawForm)``

interface IFormRowProps {
  className?: string
  columnOnMobile?: boolean
  connectedFields?: boolean
  spacing?: Spacing
}

const RawFormRow: FC<React.PropsWithChildren<IFormRowProps & Omit<Omit<HTMLProps<HTMLDivElement>, 'as'>, 'ref'>>> = ({
  className,
  children,
  columnOnMobile,
  connectedFields,
  spacing,
  ...props
}) => (
  <Row
    className={className}
    columnOnMobile={columnOnMobile}
    connectedFields={connectedFields}
    spacing={spacing}
    {...props}
  >
    {React.Children.map(children, (child) => child && <Col>{child}</Col>)}
  </Row>
)

export const FormRow = styled(RawFormRow)``

export const FrameContainer = styled.div`
  border: 1px solid ${input.borderColor};
  border-radius: 4px;
  padding: 16px;
  margin-top: 24px;
`

const DIVIDER_SPACING = {
  md: 16,
  lg: 24,
  xl: 40,
}

export const Divider = styled.div<{ spacing?: keyof typeof DIVIDER_SPACING }>`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${input.borderColor};

  ${({ spacing }) => `
    margin: ${DIVIDER_SPACING[spacing || 'md']}px 0;
  `}
`

export const NoMarginFormRow = styled(FormRow)`
  margin-top: 16px !important;
`

export const FormSubsection = styled(({ className, header, children }) => (
  <div className={className}>
    <h3>{header}</h3>
    {children}
  </div>
))`
  margin: 48px 0;

  h3 {
    margin-bottom: 24px;
    ${textStyle.heading.sm};
  }
`
