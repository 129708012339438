import { useEffect, useState } from 'react'
import { compact, map } from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'
import getPriceBreakdown, { IPriceBreakdown } from '../../../utils/getPriceBreakdown'

export type IFeeContractPreview = NonNullable<IPriceBreakdown['breakdown'][number]>[]

interface IFeeContractPreviewResult {
  loading: boolean
  contracts: Array<IFeeContractPreview | null> | null
}

function useFeeContractPreview(
  eventId: string | null,
  billingPromoterId: string | null,
  disableUsTax: boolean | null,
  faceValues: number[]
): IFeeContractPreviewResult {
  const environment = useRelayEnvironment()

  const [loading, setLoading] = useState(false)

  const [contracts, setContracts] = useState<Array<IFeeContractPreview | null> | null>(null)

  useEffect(() => {
    let stillMounted = true
    setLoading(true)

    const promise = Promise.allSettled(
      map(
        (fv) =>
          getPriceBreakdown(environment, {
            billingPromoterId,
            eventId: eventId || null,
            faceValue: fv,
            venueIds: [],
            fees: null,
            basePriceFees: null,
            postFanPriceFees: null,
            ignorePwlFee: false,
            disableUsTax: !!disableUsTax,
            forcePwlActive: null,
          }),
        faceValues
      )
    )

    promise
      .then((results) => {
        if (stillMounted) {
          setContracts(
            map(
              (result) =>
                result.status === 'fulfilled' && result?.value?.breakdown ? compact(result?.value?.breakdown) : null,
              results
            )
          )
        }
      })
      .finally(() => {
        if (stillMounted) {
          setLoading(false)
        }
      })

    return () => {
      stillMounted = false
    }
  }, [billingPromoterId, disableUsTax, environment, eventId, faceValues])

  return {
    loading,
    contracts,
  }
}

export default useFeeContractPreview
