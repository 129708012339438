import styled from 'styled-components/macro'

import { color, mediaQuery, spacing } from '../utils/variables'
import Checkbox, { CheckboxSkeleton } from './Checkbox'

export const FilterGroup = styled.div<{ flex?: boolean; justifyContent?: string }>`
  & + & {
    margin-top: 24px;
  }
  ${({ flex, justifyContent }) =>
    flex &&
    `
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    justify-content: ${justifyContent ? justifyContent : 'flex-start'};
  `}
`

export const FilterGroupTitle = styled.strong<{ spacingBottom?: keyof typeof spacing }>`
  display: block;
  margin: 0 20px ${({ spacingBottom }) => (spacingBottom ? `${spacing[spacingBottom]}px` : '8px')} 0;
  small {
    display: block;
    font-weight: normal;
    color: ${color.darkgrey};
  }
`

export const FilterGroupInner = styled.div`
  display: block;
  position: relative;

  ${CheckboxSkeleton} {
    width: 33.33333%;
    margin: 10px 0;
    ${mediaQuery.lessThan('tablet')`
      width: 50%;
    `}
  }

  ${Checkbox} {
    width: 33.33333%;
    margin: 10px 0;
    ${mediaQuery.lessThan('tablet')`
      width: 50%;
    `}
  }
`
