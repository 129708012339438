import geocoderClient, { GeocodeRequest } from '@mapbox/mapbox-sdk/services/geocoding'
import tilequeryClient, { TileQueryRequest } from '@mapbox/mapbox-sdk/services/tilequery'

import { debounce, getOr, map, uniqBy } from 'lodash/fp'

import { MAPBOX_API_KEY, MAPBOX_TZ_TILESET_ID } from '../env'
import { ILocale } from '../intl'

export type IOptions = Array<{
  value: string
  label: string
  [key: string]: any
}>
type ICallback = (value: IOptions) => void

export const suggestCities = (countryCodeAlpha2?: string, locale?: ILocale, simpleLabel?: boolean) => {
  return debounce(2000, (str: string, callback: ICallback) => {
    const promise = suggestCitiesRaw(str, countryCodeAlpha2, locale, simpleLabel)
    promise
      .then((data) => callback(data))
      .catch((err) => {
        console.error(err)
        callback([])
      })
  })
}

async function suggestCitiesRaw(
  str: string,
  countryCodeAlpha2?: string,
  locale?: ILocale,
  simpleLabel?: boolean
): Promise<Array<{ value: string; label: string }>> {
  const geocoder = geocoderClient({
    accessToken: MAPBOX_API_KEY,
  })

  const req: GeocodeRequest = {
    query: (str || '').replaceAll(/;/g, ''),
    mode: 'mapbox.places',
    types: ['place', 'locality'],
    autocomplete: true,
    limit: 5,
    language: [locale || 'en'],
  }

  if (countryCodeAlpha2) {
    req.countries = [countryCodeAlpha2]
  }

  const promise: Promise<Array<{ value: string; label: string }>> = geocoder
    .forwardGeocode(req)
    .send()
    .then((res) => {
      const match = res.body
      const items = map(
        (f) => ({
          value: f.text,
          label: simpleLabel ? f.text : f.place_name,
          placeId: f.id,
          lat: f.center[1],
          lon: f.center[0],
        }),
        match.features || []
      )

      return simpleLabel ? uniqBy('label', items) : items
    })

  return promise
}

export const suggestAddresses = (isoCountryCode?: string, locale?: ILocale) => {
  return debounce(2000, (str: string, callback: ICallback) => {
    const promise = suggestAddressesRaw(str, isoCountryCode, locale)
    promise
      .then((data) => callback(data))
      .catch((err) => {
        console.error(err)
        callback([])
      })
  })
}

async function suggestAddressesRaw(
  str: string,
  isoCountryCode?: string,
  locale?: ILocale
): Promise<Array<{ value: string; label: string }>> {
  const geocoder = geocoderClient({
    accessToken: MAPBOX_API_KEY,
  })

  const req: GeocodeRequest = {
    query: (str || '').replaceAll(/;/g, ''),
    mode: 'mapbox.places',
    types: ['address', 'poi', 'postcode'],
    autocomplete: false,
    limit: 10,
    language: [locale || 'en'],
    countries: isoCountryCode && [isoCountryCode.toLowerCase()],
    proximity: 'ip' as any,
  } as GeocodeRequest

  const promise: Promise<Array<{ value: string; label: string }>> = geocoder
    .forwardGeocode(req)
    .send()
    .then((res) => {
      const match = res.body
      return map(
        (f) => ({
          ...f,
          value: f.text,
          label: f.place_name,
          text: f.text,
          placeId: f.id,
          lat: f.center[1],
          lon: f.center[0],
        }),
        match.features || []
      )
    })

  return promise
}

export const radiusToZoom = (value: number, units: 'kilometers' | 'miles') => {
  const km = units === 'kilometers' ? value : value * 1.60934

  if (km >= 7724) return 0
  if (km >= 3862) return 1
  if (km >= 1930) return 2
  if (km >= 965) return 3
  if (km >= 480) return 4
  if (km >= 100) return 6
  if (km >= 48) return 7
  if (km >= 19) return 8
  if (km >= 14) return 9
  if (km >= 9) return 10
  if (km >= 4) return 11
  if (km >= 1) return 12

  return 14
}

export async function getTimezoneFromLocation(lat: number, lng: number): Promise<string> {
  const tilequery = tilequeryClient({
    accessToken: MAPBOX_API_KEY,
  })

  const req: TileQueryRequest = {
    // NOTE: this variable should contain ID of mapbox tileset popuplated from
    // https://github.com/evansiroky/timezone-boundary-builder
    // using Mapbox Studio
    mapIds: [MAPBOX_TZ_TILESET_ID],
    coordinates: [lng, lat],
    radius: 500,
    limit: 1,
    dedupe: true,
  }

  return tilequery
    .listFeatures(req)
    .send()
    .then((res) => {
      const match = res.body
      const tz = getOr(null, ['features', 0, 'properties', 'tzid'], match)
      return tz
    })
}
