/**
 * @generated SignedSource<<1e88c2626ccb3c6c239c860243b7eb52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventHeaderData_event$data = {
  readonly eventIdLive: string | null;
  readonly id: string;
  readonly previewToken: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventControls_event" | "EventHeaderDataBasic_event" | "EventProgress_event">;
  readonly " $fragmentType": "EventHeaderData_event";
};
export type EventHeaderData_event$key = {
  readonly " $data"?: EventHeaderData_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventHeaderData_event">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isDraft",
    "variableName": "isDraft"
  },
  {
    "kind": "Variable",
    "name": "needBalance",
    "variableName": "needBalance"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDraft"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "needBalance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventHeaderData_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewToken",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "EventHeaderDataBasic_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventProgress_event"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "EventControls_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "70aa03132e861279b245a73788592b67";

export default node;
