import React, { FC, useCallback } from 'react'
import { useIntl } from 'react-intl'

import Checkbox from '../../../../../components/Checkbox'
import DiceBadge from '../../../../../components/DiceBadge'
import { IStatus } from '../../../../../constants/statuses'

interface IProps {
  status: IStatus
  checked: boolean
  onChange: (k: string, v: boolean) => void
}

const StatusCheckbox: FC<IProps> = ({ status, checked, onChange }) => {
  const intl = useIntl()
  const handleChange = useCallback(() => onChange(status.key, checked), [onChange, status.key, checked])
  return (
    <Checkbox
      key={status.key}
      name="status"
      checked={checked}
      label={
        <>
          {status.key.startsWith('dice') && <DiceBadge />}
          {intl.formatMessage({ id: status.i18n })}
        </>
      }
      onChange={handleChange}
    />
  )
}

export default StatusCheckbox
