import React, { FC, PropsWithChildren, Suspense, useContext } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { isPast, parseISO } from 'date-fns'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useLazyLoadQuery } from 'react-relay'
import Responsive from 'react-responsive'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { EventHeaderBasicQuery } from '../../__generated__/EventHeaderBasicQuery.graphql'
import { OnDesktop } from '../../components/Breakpoints'
import { Loader, LoaderContainer } from '../../components/Loader'
import { PageControls, PageHeader, PageTitle } from '../../components/Page'
import SubNavigation from '../../components/SubNavigation'
import Svg from '../../components/Svg'
import { TitleTooltip } from '../../components/Tooltip'
import { authContext } from '../../context/auth'
import useExposeHeightToCss from '../../utils/useExposeHeightToCss'
import useRendered from '../../utils/useRendered'
import { breakpoints, color } from '../../utils/variables'
import EventNotesPageWrapper from '../EventNotes/EventNotesPageWrapper'
import EventHeaderData from './components/EventHeaderData'
import EventHeaderSkeleton from './components/EventHeaderSkeleton'
import useSetEventListType from './hooks/useSetEventListType'
import useEventNavigation from './hooks/useEventNavigation'
import useRecentlyViewed from './hooks/useRecentlyViewed'
import TestPageBanner from '../../components/TestPageBanner'

export const EventKind = styled.div`
  border-radius: 50%;
  border: 2px solid ${color.text};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
`

const EventHeader: FC<PropsWithChildren<{}>> = ({ children }) => {
  const intl = useIntl()

  const { pathname } = useLocation()
  const { id } = useParams<{ id: string }>()
  const { user } = useContext(authContext)

  const { event, viewer } = useLazyLoadQuery<EventHeaderBasicQuery>(
    graphql`
      query EventHeaderBasicQuery($id: ID!) {
        viewer {
          account {
            stripeAccountState
          }
        }
        event: node(id: $id) {
          ... on Event {
            id
            name
            state
            isTest
            eventType
            endDate
            eventIdLive
            ...useEventNavigation_event
          }
        }
      }
    `,
    { id: id || 'oops' },
    { fetchPolicy: id ? 'store-and-network' : 'store-only' }
  )

  const stripeIsBroken = !!viewer?.account && viewer.account.stripeAccountState !== 'OK'

  useSetEventListType(event)
  useRecentlyViewed(event?.id || null)

  const navOptions = useEventNavigation(event)

  const headerRef = useExposeHeightToCss<HTMLDivElement>('--page-nav-height')

  const navbar = <SubNavigation ref={headerRef} options={navOptions} />

  const loader = (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )

  const [portalIsThere, PortalSpy] = useRendered()
  const [bodyLoading, LoaderSpy] = useRendered()

  const isEditPage = pathname.endsWith('/edit')

  const simpleHeader = (
    <PageHeader>
      <PageTitle>{event?.name}</PageTitle>
      <OnDesktop>
        <PageControls>
          <TitleTooltip
            placement="bottom"
            title={intl.formatMessage({
              id: `new_event.select_type.options.${(event?.eventType || 'LIVE').toLowerCase()}`,
            })}
          >
            <EventKind>
              <Svg icon={`event-type-${(event?.eventType || 'LIVE').toLowerCase()}`} />
            </EventKind>
          </TitleTooltip>
        </PageControls>
      </OnDesktop>
    </PageHeader>
  )

  const draftHeader = user.diceStaff ? (
    <>
      {(!isEditPage || bodyLoading) && simpleHeader}
      {isEditPage && (
        <>
          <div id="headerPortal" />
          <PortalSpy />
        </>
      )}
      <>{navbar}</>
    </>
  ) : null

  const needBalance = event?.endDate ? isPast(parseISO(event.endDate)) : false
  const isDraft = event?.state === 'DRAFT' || event?.state === 'SUBMITTED'
  const isSubmitted = (event?.state === 'SUBMITTED' || event?.state === 'REVIEW') && pathname.endsWith('/details')

  const nonDraftHeader = event?.id && (
    <Suspense fallback={<EventHeaderSkeleton />}>
      <EventHeaderData id={event.id} isDraft={isDraft} needBalance={needBalance} stripeIsBroken={stripeIsBroken} />
      {navbar}
    </Suspense>
  )

  const letsLoadDraftBody = portalIsThere || !isEditPage || !user.diceStaff

  return (
    <EventNotesPageWrapper eventId={event?.id} notesOpenByDefault={isSubmitted}>
      {event?.isTest && <TestPageBanner page="event" />}

      <Responsive maxWidth={breakpoints.tablet}>
        <PageHeader />
      </Responsive>

      {event?.id ? (
        <>
          <Helmet>
            <title>{event.name} | DICE MIO</title>
          </Helmet>
          {event.state === 'DRAFT' ? draftHeader : nonDraftHeader}
        </>
      ) : null}

      {event?.state === 'DRAFT' ? (
        letsLoadDraftBody && (
          <Suspense
            fallback={
              <>
                <LoaderSpy />
                {loader}
              </>
            }
          >
            {children}
          </Suspense>
        )
      ) : (
        <Suspense fallback={loader}>{children}</Suspense>
      )}
    </EventNotesPageWrapper>
  )
}

export default EventHeader
