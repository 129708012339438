import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components/macro'

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 100%;
`

const loaderRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const loaderDash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120;
  }
`

const LoaderControl = styled.span<{ size?: string }>`
  position: relative;
  display: inline-block;
  color: currentColor;
  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
    width: 20px;
    height: 20px;
    ${LoaderCircle} {
      stroke-width: 4;
    }
  `
      default:
        return `
    width: 24px;
    height: 24px;
  `
    }
  }}
`

const LoaderSvg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  animation: ${loaderRotate} 1.5s infinite linear;
`

const LoaderCircle = styled.circle`
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 4;
  stroke: currentColor;
  stroke-linecap: round;
  animation: ${loaderDash} 1.3s infinite ease-in-out;
`

const RawLoader: FC<React.PropsWithChildren<{ size?: string; className?: string }>> = ({
  className,
  size = 'medium',
}) => (
  <LoaderControl size={size} className={className}>
    <LoaderSvg width="50" height="50" viewBox="0 0 50 50">
      <LoaderCircle cx="25" cy="25" r="20" fill="none" stroke="currentColor" />
    </LoaderSvg>
  </LoaderControl>
)

const Loader = styled(RawLoader)``

export { Loader, LoaderContainer }
