import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { getOr } from 'lodash/fp'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import ProgressBar from '../../../../components/ProgressBar'

import { CURRENCY } from '../../../../utils/formatters/number'
import { font, mediaQuery } from '../../../../utils/variables'

// eslint-disable-next-line max-len
import { DashboardEventSalesProgress_event$key } from '../../../../__generated__/DashboardEventSalesProgress_event.graphql'

const Row = styled.div`
  display: flex;
  margin: -2px 0 0;
`

const Col = styled.div<{ textAlign?: string }>`
  flex: 1 1 0;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  ${mediaQuery.lessThan('desktopLarge')`
    font-size: ${font.size.sm}px;
  `}
`

interface IProps {
  event: DashboardEventSalesProgress_event$key
}

const DashboardEventSalesProgress: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment DashboardEventSalesProgress_event on Event {
        id
        allocation
        costCurrency
        sales {
          totalSold
          totalValue
        }
      }
    `,
    eventKey
  )

  const revenue = getOr(0, 'sales.totalValue', event)
  const formattedRevenue = useMemo(
    () => intl.formatNumber(revenue / 100, CURRENCY(revenue, event.costCurrency)),
    [event, intl, revenue]
  )

  const sold = getOr(0, 'sales.totalSold', event)
  const allocation = getOr(0, 'allocation', event)
  const soldPercentage = allocation
    ? parseFloat(
      intl.formatNumber((sold * 100) / allocation, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })
    )
    : 0

  return (
    <>
      <ProgressBar size="small" value={soldPercentage} gradient />
      <Row>
        <Col data-id="sales-progress">{sold + '/' + allocation}</Col>
        <Col data-id="revenue" textAlign="right">
          {formattedRevenue}
        </Col>
      </Row>
    </>
  )
}

export default memo(DashboardEventSalesProgress)
