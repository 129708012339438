import React, { Fragment, useContext } from 'react'
import styled from 'styled-components/macro'
import { addMonths, differenceInCalendarMonths, endOfMonth, format, startOfMonth } from 'date-fns'
import { font, mediaQuery } from '../../../../utils/variables'
import EventTimelineContext from '../../util/eventTimelineContext'
import { CELL_WIDTH } from '../../util/eventTimelineVariables'
import { localeContext } from '../../../../context/locale'
import { dateFnsLocales } from '../../../../intl'

const Container = styled.div`
  width: 100%;
  height: 22px;
  margin: 8px 0;
  font-size: ${font.size.md}px;
  display: flex;
`
const MonthWrapper = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  flex-shrink: 0;
`
const Month = styled.div`
  width: 90px;
  position: sticky;
  left: 0;
  text-transform: capitalize;
  ${mediaQuery.lessThan('tablet')`
  left: 16px;
  `}
`

const MonthSeparator = styled.div<{ offset: number }>`
  position: absolute;
  z-index: 1;
  left: ${(props) => props.offset}px;
  top: 74px;
  bottom: 0;
  width: 1px;
  background: rgba(0, 0, 0, 0.08);
`

const TimelineMonths = () => {
  const { timelineEnd, timelineStart, dateOffset } = useContext(EventTimelineContext)
  const { locale } = useContext(localeContext)

  const months = [...Array(differenceInCalendarMonths(timelineEnd, timelineStart) + 1)].map((_, shift) =>
    shift === 0 ? timelineStart : startOfMonth(addMonths(timelineStart, shift))
  )

  return (
    <Container>
      {months.map((month, pos) => {
        const width =
          dateOffset(pos === months.length - 1 ? timelineEnd : endOfMonth(month)) - dateOffset(month) + CELL_WIDTH

        return (
          <Fragment key={pos}>
            <MonthWrapper width={width}>
              {width > 60 && <Month>{format(month, 'MMMM', { locale: dateFnsLocales[locale] })}</Month>}
            </MonthWrapper>

            {pos > 0 && <MonthSeparator offset={dateOffset(month)} />}
          </Fragment>
        )
      })}
    </Container>
  )
}

export default TimelineMonths
