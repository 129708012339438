/**
 * @generated SignedSource<<f114f77489c2e2d9bd21e5bfa89cf9e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewed_viewer$data = {
  readonly recentlyViewed: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly eventIdLive: string | null;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"DashboardEventCardInfo_event" | "DashboardEventSalesProgress_event">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "RecentlyViewed_viewer";
};
export type RecentlyViewed_viewer$key = {
  readonly " $data"?: RecentlyViewed_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewed_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentlyViewed_viewer",
  "selections": [
    {
      "alias": "recentlyViewed",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "EventConnection",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Event",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eventIdLive",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DashboardEventCardInfo_event"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DashboardEventSalesProgress_event"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "bf5b3614a5d78295dd3674b92bc15d2c";

export default node;
