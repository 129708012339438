import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { every, map, some } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'

import { color } from '../../../utils/variables'
import { extractMaxPermissionStructure, IMaxPermissionStructure } from '../utils/structure'
import { PermissionView_viewer$key } from '../../../__generated__/PermissionView_viewer.graphql'
import ActionView from './ActionView'

const SubjectLabel = styled.div`
  padding: 16px;

  font-weight: bold;
  text-transform: uppercase;

  border-bottom: 1px solid ${color.lightgrey};
`

const CategoryLabel = styled.div`
  font-weight: 700;
  padding: 12px 0 0 16px;
`

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  min-height: 100px;
`

const SubjectActions = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding: 8px 16px;
`

const Subject = styled.div`
  & + & {
    border-top: 1px solid ${color.lightgrey};
  }
`

const Container = styled.div`
  border-radius: 10px;
  border: 1px solid ${color.lightgrey};
`

interface IProps {
  viewer: PermissionView_viewer$key
  enabledPermissions: Array<string>
}

const PermissionView: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey, enabledPermissions }) => {
  const intl = useIntl()

  const viewer = useFragment(
    graphql`
      fragment PermissionView_viewer on Viewer {
        permissionProfileStructure {
          subjects {
            name
            actions {
              name
              category
            }
          }
        }
      }
    `,
    viewerKey
  )

  const maxPermissionStructure: IMaxPermissionStructure = useMemo(
    () =>
      extractMaxPermissionStructure(intl)(viewer.permissionProfileStructure ? [viewer.permissionProfileStructure] : []),
    [intl, viewer.permissionProfileStructure]
  )

  const enabledPermissionsSet = useMemo(() => new Set(enabledPermissions), [enabledPermissions])

  const enabledSubjectsMap: Map<string, boolean | null> = useMemo(() => {
    const subjectMap = new Map()

    maxPermissionStructure.forEach((subject) => {
      const permissions = map('name', subject?.actions || [])
      const isEnabled = (str: string) => enabledPermissionsSet.has(`${str}:${subject.name}`)
      subjectMap.set(subject.name, some(isEnabled, permissions) ? (every(isEnabled, permissions) ? true : null) : false)
    })

    return subjectMap
  }, [enabledPermissionsSet, maxPermissionStructure])

  return enabledPermissionsSet.size === 0 ? (
    <Empty>{intl.formatMessage({ id: 'permissions.empty_profile' })}</Empty>
  ) : (
    <Container>
      {maxPermissionStructure.map(
        (subj) =>
          enabledSubjectsMap.get(subj.name) !== false && (
            <Subject key={subj.name}>
              <SubjectLabel>{subj.label}</SubjectLabel>
              {subj.categories.length > 0 ? (
                subj.categories.map((category) => (
                  <div key={category.name}>
                    <CategoryLabel>{category.label}</CategoryLabel>
                    <SubjectActions>
                      {category.actions.map(
                        (action) =>
                          enabledPermissionsSet.has(`${action.name}:${subj.name}`) && <ActionView action={action} />
                      )}
                    </SubjectActions>
                  </div>
                ))
              ) : (
                <SubjectActions>
                  {subj.actions.map(
                    (action) =>
                      enabledPermissionsSet.has(`${action.name}:${subj.name}`) && <ActionView action={action} />
                  )}
                </SubjectActions>
              )}
            </Subject>
          )
      )}
    </Container>
  )
}

export default memo(PermissionView)
