/**
 * @generated SignedSource<<dd8e65d381928599be648d900c6be9c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventVenueScheduleVenueQuery$variables = {
  searchTerm?: string | null;
};
export type EventVenueScheduleVenueQuery$data = {
  readonly viewer: {
    readonly options: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly configurations: ReadonlyArray<{
            readonly attractiveRoomSiaeCode: string | null;
            readonly capacity: number;
            readonly label: string;
            readonly seatingAreaConfigs: ReadonlyArray<{
              readonly capacity: number;
              readonly seatingArea: string;
            } | null>;
            readonly value: string;
          } | null> | null;
          readonly hint: string | null;
          readonly label: string | null;
          readonly value: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type EventVenueScheduleVenueQuery = {
  response: EventVenueScheduleVenueQuery$data;
  variables: EventVenueScheduleVenueQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  }
],
v2 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": "hint",
  "args": null,
  "kind": "ScalarField",
  "name": "fullAddress",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attractiveRoomSiaeCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SeatingAreaConfig",
  "kind": "LinkedField",
  "name": "seatingAreaConfigs",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seatingArea",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventVenueScheduleVenueQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "VenuesConnection",
            "kind": "LinkedField",
            "name": "venues",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VenuesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Venue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VenueConfiguration",
                        "kind": "LinkedField",
                        "name": "configurations",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventVenueScheduleVenueQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "VenuesConnection",
            "kind": "LinkedField",
            "name": "venues",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VenuesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Venue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VenueConfiguration",
                        "kind": "LinkedField",
                        "name": "configurations",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37e0d4827348e6feb580259abe59e394",
    "id": null,
    "metadata": {},
    "name": "EventVenueScheduleVenueQuery",
    "operationKind": "query",
    "text": "query EventVenueScheduleVenueQuery(\n  $searchTerm: String\n) {\n  viewer {\n    options: venues(searchTerm: $searchTerm, first: 50) {\n      edges {\n        node {\n          value: id\n          label: name\n          hint: fullAddress\n          configurations {\n            value: id\n            label: name\n            attractiveRoomSiaeCode\n            capacity\n            seatingAreaConfigs {\n              capacity\n              seatingArea\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "578171260f9c2a0686428df18fe1f46b";

export default node;
