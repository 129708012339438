import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { sumBy } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { createRefetchContainer, RelayRefetchProp } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { EventCostCurrency } from '../../../../enums.generated'
import DashboardSalesPlate from './DashboardSalesPlate'

import { DashboardSales_viewer$data } from '../../../../__generated__/DashboardSales_viewer.graphql'
import { notificationContext } from '../../../../context/notification'

interface IProps {
  viewer: DashboardSales_viewer$data
  relay: RelayRefetchProp
  initialCurrency: EventCostCurrency
  period: string
}

const DashboardSales: FC<React.PropsWithChildren<IProps>> = ({
  viewer,
  relay: { refetch },
  initialCurrency,
  period,
}) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const [currentCurrency, setCurrentCurrency] = useState(initialCurrency)

  const [loading, setLoading] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    setLoading(true)
    refetch(
      {
        currency: currentCurrency,
      },
      null,
      (err: any) => {
        setLoading(false)
        if (err) {
          addNotification('error', intl.formatMessage({ id: 'load_error' }))
        }
      },
      { force: true, fetchPolicy: 'network-only' }
    )
  }, [refetch, currentCurrency, addNotification, intl])

  const stats = useMemo(() => {
    const ticketsSold = sumBy('soldTickets', viewer.revenueReport || []) || 0
    const grossRevenue = sumBy('revenue', viewer.revenueReport || []) || 0
    const eventOnSale = viewer.totalEvents?.onSaleEventsCount || 0
    return {
      ticketsSold,
      grossRevenue,
      eventOnSale,
    }
  }, [viewer])

  return (
    <DashboardSalesPlate
      period={period}
      currentCurrency={currentCurrency}
      updateCurrency={setCurrentCurrency}
      stats={stats}
      isLoading={loading}
    />
  )
}

export default createRefetchContainer(
  DashboardSales,
  {
    viewer: graphql`
      fragment DashboardSales_viewer on Viewer
      @argumentDefinitions(
        startDate: { type: "Time", defaultValue: null }
        endDate: { type: "Time", defaultValue: null }
        currency: { type: "EventCostCurrency", defaultValue: null }
      ) {
        revenueReport(startDate: $startDate, endDate: $endDate, currency: $currency) {
          revenue
          soldTickets
        }

        totalEvents: aggregatedSales(startDate: $startDate, endDate: $endDate, currency: $currency) {
          onSaleEventsCount
        }
      }
    `,
  },
  graphql`
    query DashboardSalesRefetchQuery($startDate: Time, $endDate: Time, $currency: EventCostCurrency) {
      viewer {
        ...DashboardSales_viewer @arguments(startDate: $startDate, endDate: $endDate, currency: $currency)
      }
    }
  `
)
