import React, { FC } from 'react'
import styled from 'styled-components/macro'

import { font, color, mediaQuery } from '../utils/variables'

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 18px;
`

const StatusTitle = styled.span`
  color: ${color.darkgrey};
  font-size: ${font.size.sm}px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-content: center;
`

const StatusIndicator = styled.i<{ color: keyof typeof color }>`
  display: inline-block;
  min-width: 8px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 4px;
  background: ${(props) => (props.color ? color[props.color] : 'currentColor')};
`

interface IStatus {
  className?: string
  color: keyof typeof color
  title?: any
}

const Status: FC<React.PropsWithChildren<IStatus>> = ({ className, color, title }) => (
  <StatusWrapper className={className}>
    <StatusIndicator color={color} />
    <StatusTitle>{title}</StatusTitle>
  </StatusWrapper>
)

Status.defaultProps = {
  color: 'grey',
  title: null,
}

export default styled(Status)``
