import { compact, map } from 'lodash/fp'
import { Environment } from 'react-relay'

import { markAsServer } from '../../../utils/entityStatus'
import uploadAttachment from './uploadAttachment'

const uploadProductImages = async (environment: Environment, product: any) => {
  const promises = map(async (img) => {
    const cdnUrl = img?.cdnUrl
    const cropRegion = img?.cropRegion
    let attachmentId = img?.attachment?.id
    if (cdnUrl && !attachmentId && !cdnUrl.startsWith('http')) {
      attachmentId = (
        await uploadAttachment(
          environment,
          cdnUrl,
          img.format && ['jpeg', 'jpg', 'png'].includes(img.format) ? img.format : 'jpeg'
        )
      )[0]
    }

    // prettier-ignore
    return attachmentId
      ? markAsServer<{ id: string; attachmentId: string; cropRegion: any }>({
        id: img?.id || undefined,
        attachmentId,
        cropRegion,
      })
      : null
  }, product.productImages || [])
  return compact(await Promise.all(promises))
}

export default uploadProductImages
