import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { addDays, formatISO, parseISO } from 'date-fns'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { authContext } from '../../../../../context/auth'
import { localeContext } from '../../../../../context/locale'
import { DATETIME_FORMATS } from '../../../../../utils/formatters/datetime'
import { CURRENCY } from '../../../../../utils/formatters/number'

import AlertBox from '../../../../../components/AlertBox'
import { ModalFooter, ModalFooterControl } from '../../../../../components/Modal'

import { RemittanceModalFooter_event$key } from '../../../../../__generated__/RemittanceModalFooter_event.graphql'
import useEventPayout from '../../../../../utils/hooks/useEventPayout'
import usePerformPayout from '../../../../../utils/hooks/usePerformPayout'
import { notificationContext } from '../../../../../context/notification'

interface IProps {
  event: RemittanceModalFooter_event$key
  onClose: () => void
}

const RemittanceModalFooter: FC<IProps> = ({ event: eventKey, onClose }) => {
  const intl = useIntl()
  const { hasPermission } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const { addNotification } = useContext(notificationContext)

  const event = useFragment(
    graphql`
      fragment RemittanceModalFooter_event on Event {
        id
        name
        endDate
        timezoneName
        costCurrency
        eventIdLive
        sales {
          totalFaceValue
          totalRebate
          totalPromoterIncome
        }
        ...usePerformPayout_event
        ...useEventPayout_event
      }
    `,
    eventKey
  )

  const { doPerformPayout, payoutInProgress } = usePerformPayout(event)
  const { selfPayoutBlockers, selfPayoutDisabled, isPaidOut, payoutAmount } = useEventPayout(event)

  const doSelfPayout = useCallback(() => {
    try {
      doPerformPayout(payoutAmount)
    } catch (_) {
      addNotification('error', intl.formatMessage({ id: 'event_payouts.perform_payout.error' }))
      onClose()
    }
  }, [addNotification, doPerformPayout, intl, onClose, payoutAmount])

  const payoutRemittanceFooter = useMemo(() => {
    if (selfPayoutBlockers?.event_postponed || selfPayoutBlockers?.hold_payouts) {
      return <AlertBox>{intl.formatMessage({ id: 'event_status.paid-out.tooltip' })}</AlertBox>
    }
    if (isPaidOut) {
      return (
        <>
          <ModalFooterControl data-id="selfPayout-remittance" disabled>
            {intl.formatMessage({ id: 'event_status.paid-out' })}
          </ModalFooterControl>
          <ModalFooterControl preset="secondary" onClick={onClose}>
            {intl.formatMessage({ id: 'actions.cancel' })}
          </ModalFooterControl>
        </>
      )
    }
    if (selfPayoutDisabled) {
      const completedDate = event.endDate
        ? intl.formatDate(formatISO(addDays(parseISO(event.endDate), 2)), {
          ...DATETIME_FORMATS.DATETIME(locale),
          timeZone: event.timezoneName || undefined,
        })
        : intl.formatMessage({ id: 'na' })
      return (
        <AlertBox>
          {selfPayoutBlockers?.event_completed_recently
            ? intl.formatMessage({ id: 'payout.alert.come_back_later' }, { date: completedDate })
            : intl.formatMessage({ id: 'payout.alert.processing_by_dice' })}
        </AlertBox>
      )
    } else {
      return (
        <>
          <ModalFooterControl data-id="selfPayout-remittance" loading={payoutInProgress} onClick={doSelfPayout}>
            {intl.formatMessage(
              { id: 'actions.pay_out_sum' },
              {
                value: intl.formatNumber(payoutAmount / 100, CURRENCY(payoutAmount, event.costCurrency)),
              }
            )}
          </ModalFooterControl>
          <ModalFooterControl preset="secondary" onClick={onClose}>
            {intl.formatMessage({ id: 'actions.cancel' })}
          </ModalFooterControl>
        </>
      )
    }
  }, [
    selfPayoutBlockers?.event_postponed,
    selfPayoutBlockers?.hold_payouts,
    selfPayoutBlockers?.event_completed_recently,
    isPaidOut,
    selfPayoutDisabled,
    intl,
    onClose,
    event.endDate,
    event.timezoneName,
    event.costCurrency,
    locale,
    payoutInProgress,
    doSelfPayout,
    payoutAmount,
  ])

  return payoutAmount > 0 && hasPermission('perform_payout:balances') ? (
    <ModalFooter>{payoutRemittanceFooter}</ModalFooter>
  ) : null
}

export default memo(RemittanceModalFooter)
