import { useCallback, useState } from 'react'

function useSteps(stepCount: number) {
  const [step, setStep] = useState(0)
  const goBack = useCallback(() => {
    if (step > 0) {
      setStep(step - 1)
    }
  }, [step])
  const goNext = useCallback(() => {
    if (step < stepCount - 1) {
      setStep(step + 1)
    }
  }, [step, stepCount])

  return { step, goBack, goNext, setStep }
}

export default useSteps
