import { useCallback, useContext, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-relay'
import { nanoid } from 'nanoid'
import { useAssignEventReviewMutation } from '../../__generated__/useAssignEventReviewMutation.graphql'
import { notificationContext } from '../../context/notification'
import { authContext } from '../../context/auth'
import { convertViewerIdToUserId } from '../convertViewerIdToUserId'

function useAssignEventReview(eventId: string | null) {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { user } = useContext(authContext)

  const [loading, setLoading] = useState(false)

  const [commitVerifyPhone] = useMutation<useAssignEventReviewMutation>(graphql`
    mutation useAssignEventReviewMutation($input: UpdateEventReviewInput!) {
      updateEventReview(input: $input) {
        successful
        messages {
          message
        }
        result {
          assignee {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  `)

  const doAssign = useCallback(
    (assigneeId: string | null) => {
      if (!eventId) return
      setLoading(true)
      commitVerifyPhone({
        variables: {
          input: {
            clientMutationId: nanoid(),
            assigneeId,
            eventId: eventId!,
          },
        },
        onCompleted: (data, _errors) => {
          setLoading(false)
        },
        onError: (err) => {
          console.error(err)
          addNotification('error', intl.formatMessage({ id: 'event_list.assign.assign_error' }))
          setLoading(false)
        },
      })
    },
    [commitVerifyPhone, eventId, addNotification, intl]
  )

  const assignToMe = useCallback(() => doAssign(convertViewerIdToUserId(user.id)), [doAssign, user.id])
  const unassign = useCallback(() => doAssign(null), [doAssign])

  return { doAssign, assignToMe, unassign, loading }
}

export default useAssignEventReview
