import React, { FC, memo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { Modal, ModalBody } from '../../../components/Modal'

interface IProps {
  onClose: () => void
}

const DoorlistDisclaimerModal: FC<IProps> = ({ onClose }) => {
  const intl = useIntl()

  return (
    <Modal closeButton onClose={onClose} modalTitle={intl.formatMessage({ id: 'event_doorlist.disclaimer_title' })}>
      <StyledModalBody>
        {intl.formatMessage({ id: 'event_doorlist.disclaimer_1' })}
        <ul>
          {intl.formatMessage(
            { id: 'event_doorlist.disclaimer_2' },
            { li: (str: string | string[]) => <li>{str}</li> }
          )}
        </ul>
        {intl.formatMessage({ id: 'event_doorlist.disclaimer_3' })}
      </StyledModalBody>
    </Modal>
  )
}

export default memo(DoorlistDisclaimerModal)

const StyledModalBody = styled(ModalBody)`
  padding-bottom: 24px;

  ul {
    list-style: disc;
    margin-top: 4px;
    margin-bottom: 24px;

    li {
      margin-left: 24px;
      margin-top: 2px;
    }
  }
`
