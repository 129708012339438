/**
 * @generated SignedSource<<73c55d5f7832a3c220f0346c69aab9ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuickActionsPast_event$data = {
  readonly allowedActions: {
    readonly performPayout: boolean | null;
  } | null;
  readonly balance?: {
    readonly amountPromoterTotal: number;
    readonly payouts: ReadonlyArray<{
      readonly amount: number;
      readonly status: string;
    } | null> | null;
    readonly payoutsTotal: number;
  } | null;
  readonly detectNoSelfPayout?: string;
  readonly eventIdLive: string | null;
  readonly eventType: EventType | null;
  readonly id: string;
  readonly organicSocialLink: string | null;
  readonly tokens: {
    readonly doorlistExportToken: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartPayout_event" | "EventPartRemittance_event" | "useEventPayout_event">;
  readonly " $fragmentType": "QuickActionsPast_event";
};
export type QuickActionsPast_event$key = {
  readonly " $data"?: QuickActionsPast_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickActionsPast_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "noSelfPayouts"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickActionsPast_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organicSocialLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TokensList",
      "kind": "LinkedField",
      "name": "tokens",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorlistExportToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "noSelfPayouts",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventBalance",
          "kind": "LinkedField",
          "name": "balance",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amountPromoterTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payoutsTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Payout",
              "kind": "LinkedField",
              "name": "payouts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EventPartPayout_event"
        },
        {
          "alias": "detectNoSelfPayout",
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useEventPayout_event"
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performPayout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventPartRemittance_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "d07355f466083e8dfdbeaf29f99360c6";

export default node;
