import styled from 'styled-components/macro'
import React, { useEffect } from 'react'
import { color, mediaQuery, zIndex } from '../utils/variables'
import Button from './Button'

export const FormControls = styled(({ ICEenabled, ...props }) => {
  useEffect(() => {
    document.body.classList.add('-no-scroll-bounce')

    return () => {
      document.body.classList.remove('-no-scroll-bounce')
    }
  }, [])

  return <div {...props} />
})<{ ICEenabled?: boolean }>`
  display: block;
  position: sticky;
  bottom: ${({ ICEenabled }) => (ICEenabled ? '52px' : 0)};
  background: #fff;
  border-top: 2px solid ${color.text};
  z-index: ${zIndex.dropdown + 1};
  justify-content: space-between;

  margin-top: 16px;
`

export const FormControlsInner = styled.div<{ justifyContent?: 'space-between' | null }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  margin: 0 auto;
  padding: 16px 32px;

  .button + .button {
    margin-left: 16px;
  }

  ${mediaQuery.lessThan('tablet')`
    padding: 16px;
    flex-direction: column;

    .button {
      display: block;
      width: 100%;
    }

    .button + .button {
      margin-left: 0;
      margin-top: 16px;
    }
  `}
`

export const CancelButton = styled(Button)`
  &.button {
    margin-left: auto;
  }

  ${mediaQuery.lessThan('tablet')`
    &.button {
      margin-top: 8px;
    }
  `}
`
