import { object, string, array, number } from 'yup'
import { find, filter, get, getOr, isNil, isNumber, isInteger } from 'lodash/fp'
import { isItalianEvent } from '../../../utils/isCountryEvent'

const DictSchema = object().nullable().shape({
  value: string().required(),
  label: string(),
})

export const AdditionalArtistSchema = object()
  .nullable()
  .shape({
    name: string().nullable().trim().required(),
    description: string().nullable().trim().required(),
    hierarchicalTags: array().min(1),
  })

const BasicsSchema = object()
  .shape({
    name: string().required(),
    costCurrency: string().required(),
    venues: array().nullable().of(DictSchema),
    eventArtists: array()
      .nullable()
      .of(object().nullable().shape({ artist: DictSchema })),
    additionalArtists: array().nullable().of(AdditionalArtistSchema),
    hierarchicalTags: array().nullable().min(1).required(),
    primaryVenue: object().nullable().shape({ value: string().required() }),
    billingPromoter: DictSchema.clone().nullable().required(),
    stripeAccountId: string().nullable(),
    platformAccountCode: string().nullable(),
    promoters: array().nullable().required().min(1).of(DictSchema),
    attractiveFields: object()
      .nullable()
      .shape({
        entertainmentPercent: number().nullable().max(100).min(0),
        seatingAreaConfigs: array()
          .nullable()
          .of(
            object()
              .nullable()
              .shape({
                seatingArea: string().nullable().required(),
                capacity: number().integer().min(0).nullable().required(),
              })
          ),
      }),
  })
  .test('eventType', 'No type has been added to the event', function (values) {
    const eventType = find(['kind', 'type'], values.hierarchicalTags || [])
    if (!eventType) {
      return this.createError({ path: 'hierarchicalTags', message: ' ' })
    }

    return true
  })
  .test('genres', 'No genres has been added to the event', function (values) {
    const artistCount = (values.eventArtists || []).length + (values.additionalArtists || []).length
    const genres = filter(['kind', 'genre'], values.hierarchicalTags || [])

    if (genres.length === 0 && artistCount === 0) {
      return this.createError({ path: 'genres', message: 'new_event.basics.genre_missing_error' })
    }

    return true
  })
  .test('venueOrAddress', 'Event should have venue or venue address specified', function (values) {
    const hasCustomStreamLocation =
      values.eventType === 'STREAM' && !!values.venueName && (!!values.addressCountry || !!values.countryCode)

    const hasAddress = !!(
      values.venueName &&
      values.streetAddress &&
      values.addressLocality &&
      (values.addressCountry || values.countryCode) &&
      values.postalCode
    )

    const primaryVenueId =
      values.primaryVenue?.value || (values.state !== 'DRAFT' ? get(['venues', 0, 'value'], values) : null) || null
    const hasPrimaryVenue = !!find(['value', primaryVenueId], values.venues || [])

    const valid = hasPrimaryVenue || (values.state === 'DRAFT' && (hasAddress || hasCustomStreamLocation))

    if (!valid) {
      return this.createError({ path: 'primaryVenue', message: ' ' })
    }

    return true
  })
  .test('venueConfiguration', 'Italian event should have venueConfiguration (if has venue)', function (values) {
    const locale = (this.options.context as any).locale

    const isItalian = isItalianEvent(values, locale)
    const eventType = values.eventType

    const primaryVenueId =
      values.primaryVenue?.value || (values.state !== 'DRAFT' ? get(['venues', 0, 'value'], values) : null) || null
    const hasPrimaryVenue = !!find(['value', primaryVenueId], values.venues || [])

    const integrationDisabled = !!getOr(true, 'attractiveFields.integrationDisabled', values)

    const streamingTicketsIntegrationDisabled = !!getOr(
      true,
      'attractiveFields.streamingTicketsIntegrationDisabled',
      values
    )

    const isNts = isItalian && !(eventType === 'STREAM' && streamingTicketsIntegrationDisabled) && !integrationDisabled

    if (!isNts || !hasPrimaryVenue) return true

    if (!values.venueConfiguration) {
      return this.createError({ path: 'venueConfiguration', message: ' ' })
    }

    return true
  })
  .test('siaeGenreType', 'Italian event should have siaeGenreType', function (values) {
    const locale = (this.options.context as any).locale

    const isItalian = isItalianEvent(values, locale)
    const eventType = values.eventType

    const integrationDisabled = !!getOr(true, 'attractiveFields.integrationDisabled', values)

    const streamingTicketsIntegrationDisabled = !!getOr(
      true,
      'attractiveFields.streamingTicketsIntegrationDisabled',
      values
    )

    const isNts = isItalian && !(eventType === 'STREAM' && streamingTicketsIntegrationDisabled) && !integrationDisabled

    if (!isNts) return true

    if (!values.attractiveFields?.siaeGenreType) {
      return this.createError({ path: 'attractiveFields.siaeGenreType', message: ' ' })
    }

    return true
  })
  .test('entertainmentPercent', 'Italian event should have entertainmentPercent if required', function (values) {
    const locale = (this.options.context as any).locale

    //isEntertainment is synthetic field set by effect in genre selector
    if (!values.attractiveFields?.isEntertainment) return true

    const isItalian = isItalianEvent(values, locale)
    const eventType = values.eventType

    const integrationDisabled = !!getOr(true, 'attractiveFields.integrationDisabled', values)

    const streamingTicketsIntegrationDisabled = !!getOr(
      true,
      'attractiveFields.streamingTicketsIntegrationDisabled',
      values
    )

    const isNts = isItalian && !(eventType === 'STREAM' && streamingTicketsIntegrationDisabled) && !integrationDisabled

    if (!isNts) return true

    if (
      isNil(values.attractiveFields?.entertainmentPercent) ||
      !isNumber(values.attractiveFields?.entertainmentPercent)
    ) {
      return this.createError({ path: 'attractiveFields.entertainmentPercent', message: ' ' })
    }

    return true
  })

export default BasicsSchema
