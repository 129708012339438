import React, { FC, Fragment, useCallback, useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { get, flatMap, find, getOr } from 'lodash/fp'
import { formatISO, isBefore, set } from 'date-fns'

import IEventFormInformation from '../types/Information'
import { color, font } from '../../../utils/variables'
import { parseAtTimezone } from '../../../utils/calendar'

import FormField, { IStyledFormField } from '../../../components/FormField'
import { FormRow } from '../../../components/Form'
import { authContext } from '../../../context/auth'
import Note from '../../../components/Note'

const AdditionalInfos = styled(FormRow)`
  position: relative;
`

const SNote = styled(Note)`
  margin-top: 8px;
  align-items: center;

  & > svg {
    margin-top: 0;
  }
`

const Label = styled.span`
  display: block;
  color: ${color.black};
  font-size: ${font.size.base}px;
  font-weight: bold;
  margin-bottom: 8px;
`

const AdditionalInfosContent = styled.div``

const AdditionalInfosContentArea = styled(FormField)`
  textarea {
    min-height: 122px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
` as IStyledFormField

interface ICounterProps {
  count: number
  maxCount: number
}

const Counter = styled.div<ICounterProps>`
  position: absolute;
  top: -26px;
  right: 0;
  white-space: nowrap;
  font-size: ${font.size.sm}px;
  ${({ count, maxCount }) => count > maxCount && `color: ${color.error}`}
`

export const ON_PURCHASE = {
  content: '',
  includeOnPurchaseEmail: true,
  includeOnReminderEmail: false,
  ctaLabel: null,
  ctaLink: null,
}

export const ON_DAY_REMINDER = {
  content: '',
  includeOnPurchaseEmail: false,
  includeOnReminderEmail: true,
  ctaLabel: null,
  ctaLink: null,
}

export const DEFAULT_ADDITIONAL_INFO = [ON_PURCHASE, ON_DAY_REMINDER]

// Needed to override KIM UI logic
export const parseAdditionalInfo = (evt: any) => {
  const infos = flatMap(
    (i) =>
      i && i.includeOnPurchaseEmail && i.includeOnReminderEmail
        ? [
          { ...i, includeOnReminderEmail: false },
          { ...i, includeOnPurchaseEmail: false },
        ]
        : i,
    evt?.additionalInfos || DEFAULT_ADDITIONAL_INFO
  )
  const types = ['includeOnReminderEmail', 'includeOnPurchaseEmail']
  if (!find(types[0], infos)) infos.push(ON_DAY_REMINDER)
  if (!find(types[1], infos)) infos.unshift(ON_PURCHASE)
  return infos
}

interface IProps {
  ai: NonNullable<NonNullable<IEventFormInformation['additionalInfos']>[number]>
  readOnly: boolean
}

const EventAdditionalInfos: FC<React.PropsWithChildren<IProps>> = ({ ai, readOnly }) => {
  const intl = useIntl()
  const { hasPermission } = useContext(authContext)
  const { values, touched, errors, setFieldValue, handleBlur } = useFormikContext<IEventFormInformation>()

  const onDayReminderDisabled = useMemo(() => {
    if (values.date) {
      const now = parseAtTimezone(formatISO(new Date()), values.timezoneName || undefined)
      const eventDate = parseAtTimezone(values.date, values.timezoneName || undefined)
      const date = eventDate && set(eventDate, { hours: 9, minutes: 0, seconds: 0 })

      return date && now ? !isBefore(now, date) : false
    }
    return false
  }, [values.date, values.timezoneName])

  const onChange = useCallback(
    (e: any) => {
      setFieldValue(e.currentTarget.name, e.currentTarget.value || '')
    },
    [setFieldValue]
  )

  const anyFieldTouched = useCallback(
    (index: number) => {
      return (
        getOr(false, `additionalInfos[${index}].content`, touched) ||
        getOr(false, `additionalInfos[${index}].ctaLink`, touched) ||
        getOr(false, `additionalInfos[${index}].ctaLabel`, touched)
      )
    },
    [touched]
  )

  const idx = values.additionalInfos?.findIndex((_ai) => _ai === ai) || 0

  return (
    <>
      <AdditionalInfos>
        <AdditionalInfosContent>
          <Label>
            {ai?.includeOnPurchaseEmail
              ? intl.formatMessage({ id: 'new_event.information.information_for_fans.on_purchase.label' })
              : intl.formatMessage({ id: 'new_event.information.information_for_fans.on_day_reminder.label' })}
          </Label>
          <AdditionalInfosContentArea
            data-field={
              ai?.includeOnReminderEmail ? 'additionalInfos:dayOfEvent' : 'additionalInfos:purchaseConfirmation'
            }
            name={`additionalInfos[${idx}].content`}
            placeholder={
              ai?.includeOnPurchaseEmail
                ? intl.formatMessage({
                  id: 'new_event.information.information_for_fans.on_purchase.text_placeholder',
                })
                : intl.formatMessage({
                  id: 'new_event.information.information_for_fans.on_day_reminder.text_placeholder',
                })
            }
            hint={
              <SNote>
                {intl.formatMessage({
                  id: ai?.includeOnPurchaseEmail
                    ? 'new_event.information.information_for_fans.on_purchase.hint'
                    : 'new_event.information.information_for_fans.on_day_reminder.hint',
                })}
              </SNote>
            }
            value={ai?.content || ''}
            onChange={onChange}
            onBlur={handleBlur}
            multiline
            disabled={readOnly || (ai?.includeOnReminderEmail ? onDayReminderDisabled : false)}
            error={anyFieldTouched(idx) && get(`additionalInfos[${idx}].content`, errors)}
          >
            <Counter maxCount={2000} count={(ai?.content || '').length}>
              {(ai?.content || '').length}/{2000}
            </Counter>
          </AdditionalInfosContentArea>
        </AdditionalInfosContent>
      </AdditionalInfos>
      {hasPermission('manage_cta_purchase_confirmation_and_day_of_email:event') && (
        <>
          <FormRow>
            <FormField
              name={`additionalInfos[${idx}].ctaLabel`}
              label={
                ai?.includeOnPurchaseEmail
                  ? intl.formatMessage({ id: 'new_event.information.information_for_fans.on_purchase.cta_label' })
                  : intl.formatMessage({
                    id: 'new_event.information.information_for_fans.on_day_reminder.cta_label',
                  })
              }
              value={ai?.ctaLabel || ''}
              onChange={onChange}
              onBlur={handleBlur}
              error={anyFieldTouched(idx) && get(`additionalInfos[${idx}].ctaLabel`, errors)}
              disabled={readOnly || (ai?.includeOnReminderEmail ? onDayReminderDisabled : false)}
            />
          </FormRow>
          <FormRow>
            <FormField
              name={`additionalInfos[${idx}].ctaLink`}
              label={
                ai?.includeOnPurchaseEmail
                  ? intl.formatMessage({ id: 'new_event.information.information_for_fans.on_purchase.cta_link' })
                  : intl.formatMessage({
                    id: 'new_event.information.information_for_fans.on_day_reminder.cta_link',
                  })
              }
              value={ai?.ctaLink || ''}
              onChange={onChange}
              onBlur={handleBlur}
              error={anyFieldTouched(idx) && get(`additionalInfos[${idx}].ctaLink`, errors)}
              disabled={readOnly || (ai?.includeOnReminderEmail ? onDayReminderDisabled : false)}
              placeholder="https://"
            />
          </FormRow>
        </>
      )}
    </>
  )
}

export default EventAdditionalInfos
