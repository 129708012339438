import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { commitMutation, Environment } from 'react-relay'

import { validateDraftMutation } from '../../../__generated__/validateDraftMutation.graphql'

const validateDraft = async (environment: Environment, id: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    commitMutation<validateDraftMutation>(environment, {
      mutation: graphql`
        mutation validateDraftMutation($input: ValidateDraftEventInput!) {
          validateDraftEvent(input: $input) {
            successful
            messages {
              code
              field
              message
              options {
                key
                value
              }
              template
            }
          }
        }
      `,
      variables: {
        input: {
          id,
          clientMutationId: nanoid(),
        },
      },
      onCompleted: (data: any) => {
        const { successful, messages } = data.validateDraftEvent
        if (successful) {
          resolve()
        } else {
          reject({ messages })
        }
      },
      onError: reject,
    })
  })
}

export default validateDraft
