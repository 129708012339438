import React, { FC, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay'
import { compact, compose, filter, orderBy } from 'lodash/fp'
import { isPast, parseISO } from 'date-fns'
import AlertBox from '../../../components/AlertBox'
import { VenueFeesWarningQuery } from '../../../__generated__/VenueFeesWarningQuery.graphql'
import { color } from '../../../utils/variables'

interface IProps {
  venueId: string
  message: string
}

const VenueFeesWarning: FC<React.PropsWithChildren<IProps>> = ({ venueId, message }) => {
  const { venue } = useLazyLoadQuery<VenueFeesWarningQuery>(
    graphql`
      query VenueFeesWarningQuery($venueId: ID!) {
        venue: node(id: $venueId) {
          ... on Venue {
            contracts {
              id
              effectiveDate
            }
          }
        }
      }
    `,
    {
      venueId: venueId,
    },
    { fetchPolicy: 'store-or-network' }
  )

  const activeVenueContract = useMemo(() => {
    // According to backend docs:
    // `end_date` is only for information and does not affect any fee calculation

    const contracts = compose([orderBy(['effectiveDate', 'id'], ['desc', 'asc']), compact])(venue?.contracts || [])
    const currentContracts = filter((c) => c && c.effectiveDate && isPast(parseISO(c.effectiveDate)), contracts)

    return currentContracts?.length ? currentContracts[0] : null
  }, [venue?.contracts])

  if (!activeVenueContract) return null

  return (
    <AlertBox color={color.warning} icon="info" fullWidth>
      {message}
    </AlertBox>
  )
}

export default VenueFeesWarning
