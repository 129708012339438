import React, { ChangeEventHandler, Dispatch, FC, memo, SetStateAction, useCallback, useMemo } from 'react'
import { compose, orderBy, reject, set } from 'lodash/fp'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import Checkbox from '../../Checkbox'
import { COLOR_SCHEME } from '../utils/colors'
import { IOptions } from '../types/options'
import { TabMenu, TabMenuItem } from '../../TabMenu'

const Left = styled.div`
  margin-right: auto;
  display: flex;
  gap: 12px;
  align-items: center;
`

const Legend = styled.div`
  margin-top: 32px;

  display: flex;
  justify-content: flex-end;
  gap: 24px;
`

type IColorMode = keyof typeof COLOR_SCHEME

interface IDataset {
  id: string
  label?: string
  hidden?: boolean
}

interface IProps {
  disabled?: boolean

  datasets: Array<IDataset>
  enabled: string[]
  showRevenue: boolean

  options: IOptions
  setOptions: Dispatch<SetStateAction<IOptions>>
  toggleDataset: ChangeEventHandler<HTMLInputElement>
}

const SalesChartLegend: FC<IProps> = ({
  disabled,
  datasets,
  enabled,
  showRevenue,
  toggleDataset,
  options,
  setOptions,
}) => {
  const intl = useIntl()

  const sorted: typeof datasets = useMemo(
    () =>
      compose([
        reject((ds: IDataset) => !disabled && !!ds.hidden),
        orderBy(
          [
            (ds: IDataset) => {
              const [, b] = ds.id.split(':')
              return b === 'event' ? 100 : b === 'tickets' ? 1 : b === 'extras' ? 2 : 3
            },
          ],
          ['desc']
        ),
      ])(datasets),
    [datasets, disabled]
  )

  const changeMode = useCallback((e: any) => setOptions(set('mode', e.currentTarget.dataset['id'])), [setOptions])

  return (
    <Legend>
      <Left>
        <strong>{intl.formatMessage({ id: 'event_overview.chart_mode.label' })}</strong>

        <TabMenu>
          <TabMenuItem
            disabled={disabled}
            active={!disabled && options.mode === 'sold'}
            data-id="sold"
            onClick={changeMode}
          >
            {intl.formatMessage({ id: 'event_overview.chart_mode.options.sold' })}
          </TabMenuItem>
          <TabMenuItem
            disabled={disabled || !showRevenue}
            active={!disabled && options.mode === 'revenue'}
            data-id="revenue"
            title={
              !showRevenue ? intl.formatMessage({ id: 'event_overview.chart_mode.no_revenue_tooltip' }) : undefined
            }
            forceShowTooltip={!showRevenue}
            onClick={changeMode}
          >
            {intl.formatMessage({ id: 'event_overview.chart_mode.options.revenue' })}
          </TabMenuItem>
        </TabMenu>
      </Left>

      {sorted.map((ds) => {
        const [kind, value] = ds.id.split(':') as [IColorMode, string]
        const colors: any = COLOR_SCHEME[kind]
        const color = colors?.[value]

        return (
          <Checkbox
            key={ds.id}
            data-id={ds.id}
            label={ds.label}
            checked={disabled || enabled.indexOf(ds.id) >= 0}
            color="text"
            overrideColor={disabled ? undefined : color}
            overrideColorHover={disabled ? undefined : color}
            onChange={toggleDataset}
            disabled={disabled || sorted.length === 1}
          />
        )
      })}
    </Legend>
  )
}

export default memo(SalesChartLegend)
