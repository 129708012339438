/**
 * @generated SignedSource<<5ae6bee97cee9e90fe9104611f8da9fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type ProductOptionType = "CUSTOM" | "SIZE" | "%future added value";
export type ProductType = "ADDON" | "%future added value";
export type CreateProductsListInput = {
  clientMutationId: string;
  products?: ReadonlyArray<NewProductInput | null> | null;
};
export type NewProductInput = {
  allTicketTypes?: boolean | null;
  allocation?: number | null;
  archived?: boolean | null;
  categoryId?: string | null;
  customCover?: boolean | null;
  date?: string | null;
  description?: string | null;
  endDate?: string | null;
  faceValue?: number | null;
  fees?: ReadonlyArray<FeeInput | null> | null;
  fulfilledBy?: string | null;
  hasSeparateAccessBarcodes?: boolean | null;
  hasVariants?: boolean | null;
  locationNote?: string | null;
  name?: string | null;
  offSaleDate?: string | null;
  onSaleDate?: string | null;
  optionType?: ProductOptionType | null;
  productImages?: ReadonlyArray<ProductImageInput | null> | null;
  productType?: ProductType | null;
  purchaseConfirmationMessage?: string | null;
  sellingPoints?: ReadonlyArray<SellingPointInput | null> | null;
  sku?: string | null;
  variants?: ReadonlyArray<VariantInput | null> | null;
  venueId?: string | null;
};
export type FeeInput = {
  active?: boolean | null;
  amount: number;
  split: ReadonlyArray<FeeSplitInput | null>;
  type: FeeType;
  unit: FeeUnit;
};
export type FeeSplitInput = {
  amount: number;
  destination: FeeDestination;
  unit: FeeUnit;
};
export type ProductImageInput = {
  attachmentId?: string | null;
  cropRegion?: CropRegionInput | null;
  id?: string | null;
};
export type CropRegionInput = {
  height?: number | null;
  width?: number | null;
  x?: number | null;
  y?: number | null;
};
export type SellingPointInput = {
  name: string;
};
export type VariantInput = {
  allocation: number;
  id?: string | null;
  name: string;
  size?: string | null;
  sku?: string | null;
};
export type createOrUpdateProductsCreateListMutation$variables = {
  input: CreateProductsListInput;
};
export type createOrUpdateProductsCreateListMutation$data = {
  readonly createProductsList: {
    readonly clientMutationId: string;
    readonly messages: ReadonlyArray<{
      readonly code: string;
      readonly field: string | null;
      readonly message: string | null;
    } | null> | null;
    readonly result: {
      readonly products: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      } | null> | null;
    } | null;
    readonly successful: boolean;
  } | null;
};
export type createOrUpdateProductsCreateListMutation = {
  response: createOrUpdateProductsCreateListMutation$data;
  variables: createOrUpdateProductsCreateListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateProductsListPayload",
    "kind": "LinkedField",
    "name": "createProductsList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductsList",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "products",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createOrUpdateProductsCreateListMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createOrUpdateProductsCreateListMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d072fc31d4e434ef765c62263519a03c",
    "id": null,
    "metadata": {},
    "name": "createOrUpdateProductsCreateListMutation",
    "operationKind": "mutation",
    "text": "mutation createOrUpdateProductsCreateListMutation(\n  $input: CreateProductsListInput!\n) {\n  createProductsList(input: $input) {\n    successful\n    messages {\n      field\n      message\n      code\n    }\n    clientMutationId\n    result {\n      products {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "67b664b849a0dbaeba0a47251fc5dfec";

export default node;
