import React, { FC, memo, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { DiceBadgeOverlay } from '../../../../components/DiceBadge'

import PermissionCheck from '../../../../components/PermissionCheck'
import { authContext } from '../../../../context/auth'
import { IFactory, preload, SIDEBAR_ROUTER } from '../../services/sidebarNav'
import { SidebarList, SidebarMenu, SidebarMenuIcon, SidebarMenuItem } from './Styles'
import { featureFlagsContext } from '../../../../context/featureFlags'

interface IProps {
  onReload: () => void
}

const Top: FC<IProps> = ({ onReload }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user, hasPermission } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)
  const toBundlesList = useCallback(() => navigate(SIDEBAR_ROUTER.bundles[0] as string), [navigate])
  const toPromotersList = useCallback(() => navigate(SIDEBAR_ROUTER.promoters[0] as string), [navigate])
  const toVenueList = useCallback(() => navigate(SIDEBAR_ROUTER.venues[0] as string), [navigate])
  const toArtistsList = useCallback(() => navigate(SIDEBAR_ROUTER.artists[0] as string), [navigate])
  const toMarketeersList = useCallback(() => navigate(SIDEBAR_ROUTER.marketeers[0] as string), [navigate])

  const dashboardReload = useCallback(() => {
    onReload()
    navigate('/dashboard')
  }, [navigate, onReload])

  const reportsReload = useCallback(() => {
    onReload()
    navigate('/reporting')
  }, [navigate, onReload])

  return (
    <SidebarList>
      <SidebarMenu>
        <SidebarMenuItem
          active={pathname === SIDEBAR_ROUTER.dashboard[0]}
          to={SIDEBAR_ROUTER.dashboard[0]}
          onReload={dashboardReload}
        >
          <SidebarMenuIcon icon="venue" />
          <span>{intl.formatMessage({ id: 'sidebar.dashboard' })}</span>
        </SidebarMenuItem>
        {user.diceStaff && (
          <PermissionCheck permission="access_fan_profile:fan_support">
            <SidebarMenuItem active={pathname === SIDEBAR_ROUTER.fans[0]} to={SIDEBAR_ROUTER.fans[0]}>
              <DiceBadgeOverlay>
                <SidebarMenuIcon icon="fans" />
              </DiceBadgeOverlay>
              <span>{intl.formatMessage({ id: 'sidebar.fans' })}</span>
            </SidebarMenuItem>
          </PermissionCheck>
        )}
        <PermissionCheck permission="read:balances">
          <SidebarMenuItem
            active={pathname && pathname.startsWith(SIDEBAR_ROUTER.finances[0] as string)}
            to={SIDEBAR_ROUTER.finances[0]}
            data-id="sidebarFinances"
            onMouseEnter={preload(SIDEBAR_ROUTER.finances[1] as IFactory)}
          >
            <SidebarMenuIcon icon="diamond" />
            <span>{intl.formatMessage({ id: 'sidebar.finances' })}</span>
          </SidebarMenuItem>
        </PermissionCheck>

        {hasPermission('read_attractive_fiscal_info:event') && (
          <SidebarMenuItem
            active={pathname && pathname.startsWith(SIDEBAR_ROUTER.nts[0] as string)}
            to={SIDEBAR_ROUTER.nts[0]}
            data-id="sidebarNts"
            onMouseEnter={preload(SIDEBAR_ROUTER.nts[1] as IFactory)}
          >
            <SidebarMenuIcon icon="tick" />
            <span>{intl.formatMessage({ id: 'sidebar.nts_fiscal_info' })}</span>
          </SidebarMenuItem>
        )}

        <SidebarMenuItem
          active={pathname && pathname.startsWith(SIDEBAR_ROUTER.reports[0] as string)}
          to={SIDEBAR_ROUTER.reports[0]}
          data-id="sidebarReports"
          onMouseEnter={preload(SIDEBAR_ROUTER.reports[1] as IFactory)}
          onReload={reportsReload}
        >
          <SidebarMenuIcon icon="remittance" />
          <span>{intl.formatMessage({ id: 'sidebar.reports' })}</span>
        </SidebarMenuItem>

        <SidebarMenuItem
          active={pathname && pathname.startsWith(SIDEBAR_ROUTER.venues[0] as string)}
          to={SIDEBAR_ROUTER.venues[0]}
          data-id="sidebarVenues"
          onMouseEnter={preload(SIDEBAR_ROUTER.venues[1] as IFactory)}
          onReload={toVenueList}
        >
          <SidebarMenuIcon icon="map-marker" />
          <span>{intl.formatMessage({ id: 'sidebar.venues' })}</span>
        </SidebarMenuItem>

        {hasFeatureFlag('eventsCollection') && (
          <PermissionCheck permission="read:bundle">
            <SidebarMenuItem
              active={pathname && pathname.startsWith(SIDEBAR_ROUTER.bundles[0] as string)}
              to={SIDEBAR_ROUTER.bundles[0]}
              data-id="sidebarBundles"
              onMouseEnter={preload(SIDEBAR_ROUTER.bundles[1] as IFactory)}
              onReload={toBundlesList}
            >
              <SidebarMenuIcon icon="bundle" />
              <span>{intl.formatMessage({ id: 'sidebar.bundles' })}</span>
            </SidebarMenuItem>
          </PermissionCheck>
        )}

        {user.diceStaff && (
          <>
            <SidebarMenuItem
              active={pathname && pathname.startsWith(SIDEBAR_ROUTER.artists[0] as string)}
              to={SIDEBAR_ROUTER.artists[0]}
              data-id="sidebarArtists"
              onMouseEnter={preload(SIDEBAR_ROUTER.artists[1] as IFactory)}
              onReload={toArtistsList}
            >
              <DiceBadgeOverlay>
                <SidebarMenuIcon icon="artist" />
              </DiceBadgeOverlay>
              <span>{intl.formatMessage({ id: 'sidebar.artists' })}</span>
            </SidebarMenuItem>
            <SidebarMenuItem
              active={pathname && pathname.startsWith(SIDEBAR_ROUTER.promoters[0] as string)}
              to={SIDEBAR_ROUTER.promoters[0]}
              data-id="sidebarPromoters"
              onMouseEnter={preload(SIDEBAR_ROUTER.promoters[1] as IFactory)}
              onReload={toPromotersList}
            >
              <DiceBadgeOverlay>
                <SidebarMenuIcon icon="promoter" />
              </DiceBadgeOverlay>
              <span>{intl.formatMessage({ id: 'sidebar.promoters' })}</span>
            </SidebarMenuItem>
          </>
        )}

        {user.diceStaff && (
          <SidebarMenuItem
            active={pathname && pathname.startsWith(SIDEBAR_ROUTER.marketeers[0] as string)}
            to={SIDEBAR_ROUTER.marketeers[0]}
            data-id="sidebarMarketeers"
            onMouseEnter={preload(SIDEBAR_ROUTER.marketeers[1] as IFactory)}
            onReload={toMarketeersList}
          >
            <DiceBadgeOverlay>
              <SidebarMenuIcon icon="filter" />
            </DiceBadgeOverlay>
            <span>{intl.formatMessage({ id: 'sidebar.marketeers' })}</span>
          </SidebarMenuItem>
        )}
      </SidebarMenu>
    </SidebarList>
  )
}

export default memo(Top)
