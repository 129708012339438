import React, { FC } from 'react'
import styled from 'styled-components/macro'
import Svg from '../../components/Svg'

import { mediaQuery } from '../../utils/variables'

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://dice-media.imgix.net/mio/auth_success.jpg?auto=compress&q=25');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 0;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`

const LogoWrapper = styled.div`
  position: absolute;
  left: 32px;
  top: 24px;
  width: 64px;
  height: 64px;
  flex-grow: 0;
  svg {
    width: 87px;
    height: 56px;
  }

  ${mediaQuery.lessThan('tablet')`
    left: 24px;
    svg {
      width: 60px;
      height: 24px;
    }
  `}
`

const AuthSimpleLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <>
      <Background>
        <Overlay />
      </Background>
      <LogoWrapper>
        <Svg icon="MIO" color="white" />
      </LogoWrapper>
      {children}
    </>
  )
}

export default AuthSimpleLayout
