import React, { FC, memo, useCallback, useMemo } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components/macro'
import { compact, filter, flow, reject, some, sumBy } from 'lodash/fp'

import { color, mediaQuery } from '../../../../utils/variables'

import TicketBreakdown from './TicketBreakdown'
import { TicketTypeCol, TicketTypeName } from './TicketBreakdownStyles'
import TicketPoolSalesProgress from './TicketPoolSalesProgress'
import TicketPoolSales from './TicketPoolSales'

import TicketPoolActions from './TicketPoolActions'
import { TicketBreakdownPoolsQuery } from '../../../../__generated__/TicketBreakdownPoolsQuery.graphql'
import { TicketBreakdownPools_event$key } from '../../../../__generated__/TicketBreakdownPools_event.graphql'

const TicketPool = styled.div`
  border: 2px solid ${color.lightgrey};
  border-radius: 6px;
  margin: 24px;
`

const STicketBreakdown = styled(TicketBreakdown)<{ isSold: boolean }>`
  padding: 24px;

  opacity: ${(props) => (props.isSold ? 0.5 : 1)};
`

const Header = styled.div`
  position: relative;
  margin: 0 24px 8px;
  padding: 24px 0;
  border-bottom: 1px solid ${color.lightgrey};
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  ${mediaQuery.lessThan('desktop')`
    flex-wrap: wrap;
    ${TicketTypeCol} {
      padding-left: 0;
    }
  `}

  ${TicketTypeCol}:first-child {
    padding: 0 112px 0 0;
  }
`

interface IProps {
  eventId: string
}

const TicketBreakdownPools: FC<IProps> = ({ eventId }) => {
  const { event: eventKey } = useLazyLoadQuery<TicketBreakdownPoolsQuery>(
    graphql`
      query TicketBreakdownPoolsQuery($eventId: ID!) {
        event: node(id: $eventId) {
          id
          ...TicketBreakdownPools_event
        }
      }
    `,
    { eventId },
    { fetchPolicy: 'store-and-network' }
  )

  const event = useFragment<TicketBreakdownPools_event$key>(
    graphql`
      fragment TicketBreakdownPools_event on Event {
        id
        ...TicketBreakdown_event
        ...TicketPoolSalesProgress_event
        ...TicketPoolSales_event
        allowedActions {
          minorUpdate
        }

        doorTickets: ticketTypes(doorSalesOnly: true) {
          id
          doorSalesEnabled
        }

        allowedLifecycleUpdates {
          ticketPools {
            canChangeAllocation
            canUpdate
          }
        }

        ticketPools {
          id
          name
          maxAllocation
        }

        sales {
          ticketTypesBreakdown {
            totalSold
            totalPosSold
            ticketType {
              id
              archived
              ticketPoolId
            }
          }
        }
      }
    `,
    eventKey
  )

  const isReadOnly =
    !event?.allowedActions?.minorUpdate || !event.allowedLifecycleUpdates?.ticketPools?.canChangeAllocation

  const hasBoxOffice = useMemo(
    () => some((tty) => tty?.doorSalesEnabled, event?.doorTickets || []),
    [event?.doorTickets]
  )

  const poolIsSold = useCallback(
    (pool: any) => {
      const poolsTicketTypes = flow(
        compact,
        reject('ticketType.archived'),
        filter((tt: any) => tt.ticketType.ticketPoolId === pool.id)
      )(event?.sales?.ticketTypesBreakdown || [])

      return sumBy((t) => t.totalAppSold + t.totalPosSold, poolsTicketTypes) >= (pool.maxAllocation || 0)
    },
    [event]
  )

  if (!event) return null

  return (
    <>
      {(event.ticketPools || []).map(
        (pool: any) =>
          pool && (
            <TicketPool key={pool.id}>
              <Header>
                <TicketTypeCol flexDirection="column" justify="center" order={0}>
                  <TicketTypeName>{pool.name}</TicketTypeName>
                </TicketTypeCol>
                <TicketTypeCol order={1}>
                  <TicketPoolSalesProgress event={event} ticketPool={pool} />
                </TicketTypeCol>
                <TicketTypeCol order={2} shifted={hasBoxOffice} nonInteractive>
                  <TicketPoolSales event={event} ticketPool={pool} />
                </TicketTypeCol>
                {!isReadOnly && <TicketPoolActions event={event} ticketPool={pool} />}
              </Header>
              <STicketBreakdown event={event} ticketPoolId={pool.id} isSold={poolIsSold(pool)} />
            </TicketPool>
          )
      )}
      <TicketBreakdown event={event} ticketPoolId={null} />
    </>
  )
}

export default memo(TicketBreakdownPools)
