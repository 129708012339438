import React, { FC, ReactNode, useMemo, useContext } from 'react'
import cn from 'classnames'
import styled from 'styled-components/macro'
import { concat, isString, isObject, values, isNil, map, fromPairs, filter } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { parseISO } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import { breakpoints, color, font } from '../utils/variables'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'
import { localeContext } from '../context/locale'
import { ISO_DATE_REGEX } from '../utils/calendar'
import { TitleTooltip } from './Tooltip'
import Svg from './Svg'
import DiceBadge from './DiceBadge'

export interface IFormGroupProps {
  className?: string
  label?: any
  labelFor?: string
  labelClassName?: string
  error?: any
  errorClassName?: string
  hint?: ReactNode
  help?: ReactNode
  hintClassName?: string
  required?: boolean
  disabled?: boolean
  focus?: boolean
  size?: string
  timezone?: string | null
  name?: string | null
  dice?: boolean
}

const FormGroup: FC<React.PropsWithChildren<IFormGroupProps>> = ({
  className,
  children,
  label,
  labelFor,
  labelClassName,
  error,
  errorClassName,
  hint,
  hintClassName,
  help,
  required,
  disabled,
  focus,
  size,
  timezone,
  name,
  dice,
  ...rest
}) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const isSmall = useMediaQuery({ query: `(max-width: ${breakpoints.desktopLarge}px)` })

  const errorList = useMemo(() => {
    if (!error || error === ' ' || (!isObject(error) && !isString(error))) return null
    const arr = filter(isString, isObject(error) ? values(error) : concat(error, [])) as string[]

    return map((msg) => {
      if (msg.startsWith('raw:')) {
        return [msg, msg.substring(4)]
      }

      const [key, ...argValues] = msg.split('%')
      const args = fromPairs(
        argValues.map((s, idx) => [
          `arg${idx}`,
          ISO_DATE_REGEX.test(s)
            ? intl.formatDate(parseISO(s), {
              ...DATETIME_FORMATS.DATETIME(locale),
              ...(timezone ? { timeZone: timezone } : {}),
            })
            : s,
        ])
      )

      return [key, intl.formatMessage({ id: key }, args)]
    }, arr)
  }, [error, intl, locale, timezone])

  return (
    <FormGroupBlock
      className={cn('form-group', {
        [`-size-${size}`]: !!size,
        '-has-error': !isNil(error) && error !== false,
        '-disabled': disabled,
        '-required': required,
        '-focus': focus,
        [className || '']: !!className,
      })}
      {...rest}
    >
      {label && (
        <FormGroupLabel className={labelClassName} htmlFor={labelFor}>
          {dice && <DiceBadge />}
          {label}
          {required && (
            <TitleTooltip title={intl.formatMessage({ id: 'form.required' })}>
              <RequiredMark />
            </TitleTooltip>
          )}
          {help && (
            <TitleTooltip title={help} placement={isSmall ? 'left' : 'top'}>
              <HelpIcon icon="help" width={16} height={16} />
            </TitleTooltip>
          )}
        </FormGroupLabel>
      )}
      <FormGroupControl data-name={name}>{children}</FormGroupControl>
      {errorList &&
        errorList.map((item, idx) => (
          <FormGroupError className={errorClassName} key={idx} data-msg={item[0]}>
            {item[1]}
          </FormGroupError>
        ))}
      {hint && (
        <FormGroupHint className={hintClassName} hasDice={!!dice}>
          {hint}
        </FormGroupHint>
      )}
    </FormGroupBlock>
  )
}

export default styled(FormGroup)``

export const FormGroupControl = styled.div``

const FormGroupBlock = styled.div`
  display: block;
  position: relative;
  &.-checkbox {
    ${FormGroupControl} {
      line-height: 1;
    }
  }
`

export const FormGroupLabel = styled.label`
  display: block;
  color: ${color.text};
  font-size: ${font.size.base}px;
  font-weight: bold;
  margin-bottom: 8px;
  &:empty {
    display: none;
  }
`

export const FormGroupError = styled.div`
  display: block;
  margin-top: 4px;
  color: ${color.error};
  font-size: ${font.size.sm}px;
  line-height: 1.2em;
  &:empty {
    display: none;
  }
`

export const FormGroupHint = styled.div<{ hasDice?: boolean }>`
  display: block;
  margin-top: 4px;
  color: ${color.darkgrey};
  font-size: ${font.size.sm}px;
  line-height: 1.2em;
  ${({ hasDice }) => (hasDice ? 'padding-left: 24px;' : null)}

  &:empty {
    display: none;
  }
`

export const RequiredMark = styled.div`
  background-color: ${color.error};
  border-radius: 100%;
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 2px 4px;
`

export const HelpIcon = styled(Svg)`
  position: absolute;
  right: 0;
  top: 2px;
  background-color: ${color.grey};
  color: ${color.white};
  border-radius: 50%;
  cursor: help;
`
