import React, { useCallback, useContext, useEffect, useMemo, useState, memo, FC } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import {
  capitalize,
  concat,
  difference,
  filter,
  find,
  forEach,
  includes,
  isArray,
  map,
  noop,
  set,
  split,
  times,
  uniq,
} from 'lodash/fp'
import { useFormik, useFormikContext } from 'formik'
import { useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { endOfDay, formatISO, parseISO } from 'date-fns'
import { localeContext } from '../../../../context/locale'
import { authContext } from '../../../../context/auth'

import Checkbox, { CheckboxSkeleton } from '../../../../components/Checkbox'
import { Form, FormRow } from '../../../../components/Form'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'
import FormField, { FlexFormField } from '../../../../components/FormField'
import { TabMenu } from '../../../../components/TabMenu'

import { EVENT_STATUS } from '../../../../constants/statuses'
import { PROMOTER_TIERS } from '../../../../constants/promoterTiers'

import graphqlOptionsLoader, { IOptions } from '../../../../utils/graphqlOptionsLoader'

import StatusCheckbox from './components/StatusCheckbox'
import EventTypeCheckbox from './components/EventTypeCheckbox'
import DateTab from './components/DateTab'
import EventListTypeContext from '../../util/eventListTypeContext'
import SwitchField from '../../../../components/SwitchField'
import getCountries from '../../../../utils/countries'
import IconButton from '../../../../components/IconButton'
import { FilterGroup, FilterGroupInner, FilterGroupTitle } from '../../../../components/FilterModalStyles'
import useAccountOptions from '../../../../utils/hooks/useAccountOptions'
import useAccountOwnerOptions from '../../../../utils/hooks/useAccountOwnerOptions'
import useVenueOptions, { IVenue } from '../../hooks/useVenueOptions'
import { REVIEW_STATUSES } from '../../../../constants/reviewStatuses'
import useAssignEventReviewOptions from '../../../../utils/hooks/useAssignEventReviewOptions'
import { EnumTypeOfOrganizer } from '../../../../enums.generated'
import { TYPES_OF_ORGANIZER } from '../../../Promoter/types/Basics'
import { EVENT_STATES } from '../../../../constants/states'

const getStates = (listType: string) => {
  let set: Set<string>
  switch (listType) {
    case 'submission':
      set = new Set(['submitted', 'review'])
      break
    default:
      return []
  }

  return filter((state) => set.has(state.key), EVENT_STATES)
}

const getStatuses = (isAdmin: boolean, listType: string) => {
  let set: Set<string>
  switch (listType) {
    case 'draft':
      set = new Set(['draft', 'ready-to-submit', 'to-be-approved'])
      return filter((status) => set.has(status.key), EVENT_STATUS)
    case 'live':
      set = new Set(['announced', 'on-sale', 'running-low', 'sold-out', 'postponed'])
      return filter((status) => set.has(status.key), EVENT_STATUS)
    case 'past':
      set = new Set(['running-low', 'sold-out', 'archived', 'postponed'])
      if (isAdmin) {
        set.add('dice-ready-to-payout')
      }
      return filter((status) => set.has(status.key), EVENT_STATUS)
    default:
      return []
  }
}

interface IProps {
  onClose: () => void
}

const EventFilterModal: FC<IProps> = ({ onClose }) => {
  const intl = useIntl()
  const environment = useRelayEnvironment()
  const listType = useContext(EventListTypeContext)
  const { locale } = useContext(localeContext)
  const { user } = useContext(authContext)
  const statuses = getStatuses(user.diceStaff, listType)
  const states = getStates(listType)
  const { values, setValues, handleSubmit } = useFormikContext<any>()

  const [citiesOptions, setCitiesOptions] = useState<IOptions>([])
  const [eventTypes, setEventTypes] = useState<IOptions | null>(null)

  const { values: filterValues, setFieldValue } = useFormik<any>({ initialValues: values, onSubmit: noop })

  const trackData = useMemo(() => ({ event_category: listType }), [listType])

  const cityLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query EventFilterModalCitiesQuery($where: CitiesWhereInput) {
            viewer {
              options: cities(where: $where) {
                value: id
                label: name
              }
            }
          }
        `,
        { simple: true }
      ),
    [environment]
  )

  const eventTypesLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query EventFilterModalTypesQuery {
            viewer {
              options: hierarchicalTags(where: { kind: "type" }, first: 100) {
                edges {
                  node {
                    value: id
                    label: name
                    parent {
                      name
                    }
                  }
                }
              }
            }
          }
        `,
        { simple: true }
      ),
    [environment]
  )

  // Get eventTypes
  useEffect(() => {
    let stillMounted = true
    eventTypesLoader('', (result) => {
      if (stillMounted) setEventTypes(result)
    })
    return () => {
      stillMounted = false
    }
  }, [eventTypesLoader, setEventTypes])

  // Get cities
  useEffect(() => {
    let stillMounted = true
    cityLoader('', (result) => {
      if (stillMounted) setCitiesOptions(result)
    })
    return () => {
      stillMounted = false
    }
  }, [cityLoader, setCitiesOptions])

  // Status Filter
  const toggleStatus = useCallback(
    (key: any, checked: any) =>
      setFieldValue('status', checked ? difference(filterValues.status, [key]) : concat(key, filterValues.status)),
    [setFieldValue, filterValues]
  )

  const toggleState = useCallback(
    (key: any, checked: any) =>
      setFieldValue('state', checked ? difference(filterValues.state, [key]) : concat(key, filterValues.state)),
    [setFieldValue, filterValues]
  )

  const togglePromoterTier = useCallback(
    (key: any, checked: any) =>
      setFieldValue(
        'promoterTiers',
        checked ? difference(filterValues.promoterTiers, [key]) : concat(key, filterValues.promoterTiers)
      ),
    [setFieldValue, filterValues]
  )

  const togglePriority = useCallback(
    () => setFieldValue('priority', filterValues.priority === 'true' ? null : 'true'),
    [filterValues.priority, setFieldValue]
  )

  const toggleReviewStatus = useCallback(
    (key: any, checked: any) =>
      setFieldValue(
        'reviewStatuses',
        checked ? difference(filterValues.reviewStatuses, [key]) : concat(key, filterValues.reviewStatuses)
      ),
    [setFieldValue, filterValues]
  )

  // EventType Filter NEED BACKEND
  const toggleType = useCallback(
    (key: any, checked: any) =>
      setFieldValue(
        'eventType',
        checked ? difference(filterValues.eventType, [key]) : concat(key, filterValues.eventType)
      ),
    [setFieldValue, filterValues]
  )

  // Dates Filter
  const toggleDate = useCallback(
    (value: any, active: any) => {
      if (value !== 'custom_date_range') {
        setFieldValue('timeFrameValue', active ? undefined : value)
        setFieldValue('timeFrameMinDate', undefined)
        setFieldValue('timeFrameMaxDate', undefined)
      } else {
        setFieldValue('timeFrameValue', active ? undefined : value)
      }
    },
    [setFieldValue]
  )

  // Set time on max date to end of day to include events on the max day
  const setMaxDateValue = useCallback(
    (field: string, value: any, shouldValidate?: boolean | undefined) => {
      const newValue = formatISO(endOfDay(parseISO(value)))
      setFieldValue(field, newValue, shouldValidate)
    },
    [setFieldValue]
  )

  // Location Filter
  const locationIds = useMemo(() => split(',', filterValues.location), [filterValues])
  const locationValues = useMemo(() => {
    if (!locationIds) return citiesOptions
    const result: typeof citiesOptions = []
    forEach((cityId) => {
      const c = find((city) => city.value === cityId, citiesOptions)
      if (c) result.push(c)
    }, locationIds)
    return uniq(result)
  }, [citiesOptions, locationIds])
  const setLocation = useCallback((_ids: any) => setFieldValue('location', _ids), [setFieldValue])

  // Country filter
  const countryOptions = useMemo(
    () => map((c) => set('value', c.alpha2, c), getCountries(intl, locale)),
    [intl, locale]
  )
  const selectedCountries = useMemo(
    () =>
      map(
        (code) => find(['value', code], countryOptions),
        isArray(filterValues.countryCode) ? filterValues.countryCode : [filterValues.countryCode]
      ),
    [countryOptions, filterValues.countryCode]
  )
  const setCountry = useCallback(
    (codes: string[] | string) => {
      const countryCodes = isArray(codes) && codes.length === 1 ? codes[0] : codes
      setFieldValue('countryCode', countryCodes)
    },
    [setFieldValue]
  )

  const eventTypeOptions = useMemo(
    () =>
      map(
        (kind) => ({
          value: kind,
          label: intl.formatMessage({
            id: `new_event.select_type.options.${kind.toLowerCase()}`,
            defaultMessage: capitalize(kind),
          }),
        }),
        ['LIVE', 'STREAM', 'HYBRID']
      ),
    [intl]
  )

  const eventTypeOption = useMemo(
    () => filterValues.kind && find(['value', filterValues.kind], eventTypeOptions),
    [eventTypeOptions, filterValues.kind]
  )
  const setEventType = useCallback((kind: any) => setFieldValue('kind', kind), [setFieldValue])
  const clearEventType = useCallback(() => setEventType(null), [setEventType])

  const applyAdvancedFilters = useCallback(() => {
    setValues(filterValues)
    onClose()
    handleSubmit()
  }, [setValues, filterValues, onClose, handleSubmit])

  // Account Filter
  const { accountsOptions, accountLoader, allAccountsOption } = useAccountOptions(filterValues.account)
  const accountValue = useMemo(
    () =>
      !filterValues.account
        ? allAccountsOption
        : find((account) => account.value === filterValues.account, accountsOptions),
    [accountsOptions, allAccountsOption, filterValues.account]
  )
  const setAccount = useCallback(
    (_id: any, account: any) => {
      setFieldValue('account', account.value !== 'default_filter_value' ? account.value : '')
    },
    [setFieldValue]
  )

  const clearAccount = useCallback(() => {
    setFieldValue('account', null)
  }, [setFieldValue])

  // Account owner filter
  const { accountOwnersOptions, accountOwnerLoader, allAccountOwnersOption } = useAccountOwnerOptions(
    filterValues.accountOwner
  )
  const accountOwnerValue = useMemo(
    () =>
      !filterValues.accountOwner
        ? allAccountOwnersOption
        : find((owner) => owner.value === filterValues.accountOwner, accountOwnersOptions),
    [accountOwnersOptions, allAccountOwnersOption, filterValues.accountOwner]
  )
  const setAccountOwner = useCallback(
    (_id: any, account: any) =>
      setFieldValue('accountOwner', account.value !== 'default_filter_value' ? account.value : ''),
    [setFieldValue]
  )

  const [accountOwnerStatus, setAccountOwnerStatus] = useState<'me' | 'custom' | null>(null)

  useEffect(() => {
    if (accountOwnerValue?.label === user.email) {
      setAccountOwnerStatus('me')
    } else if (
      !!accountOwnerValue &&
      accountOwnerValue?.label !== user.email &&
      accountOwnerValue?.value !== allAccountOwnersOption.value
    ) {
      setAccountOwnerStatus('custom')
    }
  }, [accountOwnerValue, allAccountOwnersOption.value, user.email])

  const handleAccountOwnerCheckbox = useCallback(
    (e: any) => {
      const controlName = e.target.name
      const checked = e.target.checked

      if (!checked) {
        setAccountOwnerStatus(null)
        setFieldValue('accountOwner', '')
      } else if (checked && controlName === 'accountOwnerIsMe') {
        setAccountOwnerStatus('me')
        if (user.email) {
          // Fetch current user's ID (user.id is a Viewer ID, not a User ID)
          accountOwnerLoader(user.email, (v: any) => {
            const value = v?.[0]?.value
            if (value) {
              setFieldValue('accountOwner', value)
            }
          })
        }
      } else if (checked && controlName === 'accountOwnerCustom') {
        setAccountOwnerStatus('custom')
        setFieldValue('accountOwner', '')
      }
    },
    [accountOwnerLoader, setFieldValue, user.email]
  )

  const { selectedVenues, setSelectedVenues, venueLoader } = useVenueOptions(values.venues)
  const setVenues = useCallback(
    (ids: string[], venues: IVenue[]) => {
      // Removes 'hint' field otherwise it appears in the chip
      const newVenues = map(
        ({ value, label }) => ({
          value,
          label,
        }),
        venues
      )
      setSelectedVenues(newVenues)
      setFieldValue('venues', ids)
    },
    [setFieldValue, setSelectedVenues]
  )

  const { userLoader, selectedAssignee, setSelectedAssignee } = useAssignEventReviewOptions(values.assigneeId)

  const setAssignee = useCallback(
    (id: string, assignee: any) => {
      setSelectedAssignee(assignee)
      setFieldValue('assigneeId', id)
    },
    [setFieldValue, setSelectedAssignee]
  )

  const clearAssignee = useCallback(() => {
    setSelectedAssignee(undefined)
    setFieldValue('assigneeId', undefined)
  }, [setFieldValue, setSelectedAssignee])

  const setTypeOfOrganiser = useCallback((types: string[]) => setFieldValue('typeOfOrganiser', types), [setFieldValue])

  const typesOfOrganisers = useMemo(() => {
    const organisersArray = isArray(filterValues.typeOfOrganiser)
      ? filterValues.typeOfOrganiser
      : [filterValues.typeOfOrganiser]
    const set = new Set(organisersArray || [])
    return filter(({ value }) => set.has(value as EnumTypeOfOrganizer), TYPES_OF_ORGANIZER)
  }, [filterValues.typeOfOrganiser])

  return (
    <Modal
      trackId="events_filter"
      trackData={trackData}
      modalTitle={intl.formatMessage({ id: 'advanced_filters' })}
      closeButton
      onClose={onClose}
    >
      <ModalBody>
        <FilterGroup>
          <FilterGroupTitle spacingBottom="sm">{intl.formatMessage({ id: 'account.country' })}</FilterGroupTitle>
          <FlexFormField
            name="country"
            control="select"
            searchable
            multiple
            options={countryOptions}
            placeholder={intl.formatMessage({ id: 'event_filter_bar.location.country_placeholder' })}
            value={selectedCountries}
            onChange={setCountry}
          ></FlexFormField>
        </FilterGroup>

        {user.diceStaff && (
          <FilterGroup>
            <FilterGroupTitle spacingBottom="sm">{intl.formatMessage({ id: 'account' })}</FilterGroupTitle>
            <FlexFormField
              control="select"
              searchable={accountsOptions.length > 10}
              async
              defaultOptions={accountsOptions}
              loadOptions={accountLoader}
              placeholder={intl.formatMessage({ id: 'all_accounts' })}
              value={accountValue}
              onChange={setAccount}
            >
              {accountValue && (
                <IconButton icon="trash" title={intl.formatMessage({ id: 'clear' })} onClick={clearAccount} />
              )}
            </FlexFormField>
          </FilterGroup>
        )}

        {listType === 'submission' && (
          <FilterGroup>
            <FilterGroupTitle>{intl.formatMessage({ id: 'event_filter_bar.promoter_tier' })}</FilterGroupTitle>
            <FilterGroupInner>
              {Object.values(PROMOTER_TIERS).map((tier) => {
                const checked = includes(tier.key, filterValues.promoterTiers)
                return <StatusCheckbox key={tier.key} status={tier} checked={checked} onChange={togglePromoterTier} />
              })}
            </FilterGroupInner>
          </FilterGroup>
        )}

        {listType === 'submission' && (
          <>
            <FilterGroup>
              <FilterGroupTitle spacingBottom="sm">{intl.formatMessage({ id: 'venue' })}</FilterGroupTitle>
              <FormRow columnOnMobile>
                <FlexFormField
                  name="otherVenues"
                  control="select"
                  multiple
                  searchable
                  async
                  loadOptions={venueLoader}
                  placeholder={intl.formatMessage({ id: 'new_event.basics.additional_collapsible.venues.placeholder' })}
                  value={selectedVenues}
                  onChange={setVenues}
                />
              </FormRow>
            </FilterGroup>

            <FilterGroup>
              <FilterGroupTitle>{intl.formatMessage({ id: 'event_filter_bar.status' })}</FilterGroupTitle>
              <FilterGroupInner>
                {Object.values(REVIEW_STATUSES).map((status) => {
                  const checked = includes(status.key, filterValues.reviewStatuses)
                  return (
                    <StatusCheckbox
                      key={status.key}
                      status={{ ...status, color: 'grey' }}
                      checked={checked}
                      onChange={toggleReviewStatus}
                    />
                  )
                })}
              </FilterGroupInner>
            </FilterGroup>

            <FilterGroup>
              <FilterGroupTitle>{intl.formatMessage({ id: 'state' })}</FilterGroupTitle>
              <FilterGroupInner>
                {states.map((state) => {
                  const checked = includes(state.key, filterValues.state)
                  return <StatusCheckbox key={state.key} status={state} checked={checked} onChange={toggleState} />
                })}
              </FilterGroupInner>
            </FilterGroup>

            <FilterGroup>
              <Form>
                <FormRow columnOnMobile>
                  <FormField
                    name="submittedAtMinDate"
                    placeholder={intl.formatMessage({ id: 'date' })}
                    label={intl.formatMessage({ id: 'event_filter_bar.submitted_at.from' })}
                    control="datetime"
                    value={filterValues.submittedAtMinDate}
                    setFieldValue={setFieldValue}
                    locale={locale}
                    mode="date"
                  />
                  <FormField
                    name="submittedAtMaxDate"
                    placeholder={intl.formatMessage({ id: 'date' })}
                    label={intl.formatMessage({ id: 'event_filter_bar.submitted_at.to' })}
                    control="datetime"
                    value={filterValues.submittedAtMaxDate}
                    setFieldValue={setFieldValue}
                    locale={locale}
                    mode="date"
                  />
                </FormRow>
              </Form>
            </FilterGroup>
            <FilterGroup>
              <Form>
                <FormRow columnOnMobile>
                  <FormField
                    name="announceMinDate"
                    placeholder={intl.formatMessage({ id: 'date' })}
                    label={intl.formatMessage({ id: 'event_filter_bar.announce_date.from' })}
                    control="datetime"
                    value={filterValues.announceMinDate}
                    setFieldValue={setFieldValue}
                    locale={locale}
                    mode="date"
                  />
                  <FormField
                    name="announceMaxDate"
                    placeholder={intl.formatMessage({ id: 'date' })}
                    label={intl.formatMessage({ id: 'event_filter_bar.announce_date.to' })}
                    control="datetime"
                    value={filterValues.announceMaxDate}
                    setFieldValue={setFieldValue}
                    locale={locale}
                    mode="date"
                  />
                </FormRow>
              </Form>
            </FilterGroup>
            <FilterGroup>
              <Form>
                <FormRow columnOnMobile>
                  <FormField
                    name="onSaleDateMinDate"
                    placeholder={intl.formatMessage({ id: 'date' })}
                    label={intl.formatMessage({ id: 'event_filter_bar.on_sale_date.from' })}
                    control="datetime"
                    value={filterValues.onSaleDateMinDate}
                    setFieldValue={setFieldValue}
                    locale={locale}
                    mode="date"
                  />
                  <FormField
                    name="onSaleDateMaxDate"
                    placeholder={intl.formatMessage({ id: 'date' })}
                    label={intl.formatMessage({ id: 'event_filter_bar.on_sale_date.to' })}
                    control="datetime"
                    value={filterValues.onSaleDateMaxDate}
                    setFieldValue={setFieldValue}
                    locale={locale}
                    mode="date"
                  />
                </FormRow>
              </Form>
            </FilterGroup>
          </>
        )}

        {['live', 'draft', 'past', 'cancelled', 'payouts'].includes(listType) && (
          <>
            <FilterGroup>
              <FilterGroupTitle spacingBottom="sm">{intl.formatMessage({ id: 'location' })}</FilterGroupTitle>
              <FormField
                control="select"
                multiple
                searchable
                async
                defaultOptions={citiesOptions}
                loadOptions={cityLoader}
                placeholder={intl.formatMessage({ id: 'event_filter_bar.location.city_placeholder' })}
                value={locationValues}
                onChange={setLocation}
              />
            </FilterGroup>

            <FilterGroup>
              <FilterGroupTitle spacingBottom="sm">{intl.formatMessage({ id: 'venue' })}</FilterGroupTitle>
              <FormRow columnOnMobile>
                <FlexFormField
                  name="otherVenues"
                  control="select"
                  multiple
                  searchable
                  async
                  loadOptions={venueLoader}
                  placeholder={intl.formatMessage({ id: 'new_event.basics.additional_collapsible.venues.placeholder' })}
                  value={selectedVenues}
                  onChange={setVenues}
                />
              </FormRow>
            </FilterGroup>

            <FilterGroup>
              <FilterGroupTitle>{intl.formatMessage({ id: 'dates' })}</FilterGroupTitle>
              <TabMenu>
                {listType === 'past' || listType === 'payouts' ? (
                  <>
                    <DateTab
                      onClick={toggleDate}
                      value="previous_week"
                      active={filterValues.timeFrameValue === 'previous_week'}
                    >
                      {intl.formatMessage({ id: 'dates.previous_week' })}
                    </DateTab>
                    <DateTab
                      onClick={toggleDate}
                      value="previous_fortnight"
                      active={filterValues.timeFrameValue === 'previous_fortnight'}
                    >
                      {intl.formatMessage({ id: 'dates.previous_fortnight' })}
                    </DateTab>
                    <DateTab
                      onClick={toggleDate}
                      value="previous_month"
                      active={filterValues.timeFrameValue === 'previous_month'}
                    >
                      {intl.formatMessage({ id: 'dates.previous_month' })}
                    </DateTab>
                    <DateTab
                      onClick={toggleDate}
                      value="yesterday"
                      active={filterValues.timeFrameValue === 'yesterday'}
                    >
                      {intl.formatMessage({ id: 'dates.yesterday' })}
                    </DateTab>
                  </>
                ) : (
                  <>
                    <DateTab
                      onClick={toggleDate}
                      value="upcoming_week"
                      active={filterValues.timeFrameValue === 'upcoming_week'}
                    >
                      {intl.formatMessage({ id: 'dates.upcoming_week' })}
                    </DateTab>
                    <DateTab
                      onClick={toggleDate}
                      value="upcoming_fortnight"
                      active={filterValues.timeFrameValue === 'upcoming_fortnight'}
                    >
                      {intl.formatMessage({ id: 'dates.upcoming_fortnight' })}
                    </DateTab>
                    <DateTab
                      onClick={toggleDate}
                      value="upcoming_month"
                      active={filterValues.timeFrameValue === 'upcoming_month'}
                    >
                      {intl.formatMessage({ id: 'dates.upcoming_month' })}
                    </DateTab>
                    <DateTab
                      onClick={toggleDate}
                      value="on_sale_today"
                      active={filterValues.timeFrameValue === 'on_sale_today'}
                    >
                      {intl.formatMessage({ id: 'dates.on_sale_today' })}
                    </DateTab>
                  </>
                )}

                <DateTab
                  onClick={toggleDate}
                  value="custom_date_range"
                  active={filterValues.timeFrameValue === 'custom_date_range'}
                >
                  {intl.formatMessage({ id: 'dates.custom_date_range' })}
                </DateTab>
              </TabMenu>
            </FilterGroup>
            {filterValues.timeFrameValue === 'custom_date_range' && (
              <FilterGroup>
                <Form>
                  <FormRow columnOnMobile>
                    <FormField
                      name="timeFrameMinDate"
                      placeholder={intl.formatMessage({ id: 'date' })}
                      label={intl.formatMessage({ id: 'dates_from' })}
                      control="datetime"
                      value={filterValues.timeFrameMinDate}
                      setFieldValue={setFieldValue}
                      locale={locale}
                      mode="date"
                    />
                    <FormField
                      name="timeFrameMaxDate"
                      placeholder={intl.formatMessage({ id: 'date' })}
                      label={intl.formatMessage({ id: 'dates_to' })}
                      control="datetime"
                      value={filterValues.timeFrameMaxDate}
                      setFieldValue={setMaxDateValue}
                      locale={locale}
                      mode="date"
                    />
                  </FormRow>
                </Form>
              </FilterGroup>
            )}
          </>
        )}

        {user.diceStaff && (
          <FilterGroup>
            <FilterGroupTitle spacingBottom="sm">{intl.formatMessage({ id: 'account_owner' })}</FilterGroupTitle>
            <Form>
              <FormRow columnOnMobile>
                <Checkbox
                  name="accountOwnerIsMe"
                  label={intl.formatMessage({ id: 'event_filter_bar.account_owner_is_me' })}
                  checked={accountOwnerStatus === 'me'}
                  onChange={handleAccountOwnerCheckbox}
                />
                <Checkbox
                  name="accountOwnerCustom"
                  label={intl.formatMessage({ id: 'event_filter_bar.account_owner_someone_else' })}
                  checked={accountOwnerStatus === 'custom'}
                  onChange={handleAccountOwnerCheckbox}
                />
              </FormRow>
              {accountOwnerStatus === 'custom' && (
                <DropdownWrapper>
                  <FormField
                    control="select"
                    searchable={accountOwnersOptions.length > 10}
                    async
                    defaultOptions={accountOwnersOptions}
                    loadOptions={accountOwnerLoader}
                    placeholder={intl.formatMessage({ id: 'all_account_owners' })}
                    value={accountOwnerValue}
                    onChange={setAccountOwner}
                  />
                </DropdownWrapper>
              )}
            </Form>
          </FilterGroup>
        )}

        {user.diceStaff && listType === 'draft' && (
          <FilterGroup>
            <SwitchField
              name="priority"
              label={intl.formatMessage({ id: 'priority' })}
              hint={intl.formatMessage({ id: 'event_filter_bar.priority.hint' })}
              checked={!!filterValues.priority}
              onChange={togglePriority}
              dice
            />
          </FilterGroup>
        )}
        {['live', 'draft', 'past'].includes(listType) && (
          <FilterGroup>
            <FilterGroupTitle>{intl.formatMessage({ id: 'status' })}</FilterGroupTitle>
            <FilterGroupInner>
              {statuses.map((status) => {
                const checked = includes(status.key, filterValues.status)
                return <StatusCheckbox key={status.key} status={status} checked={checked} onChange={toggleStatus} />
              })}
            </FilterGroupInner>
          </FilterGroup>
        )}

        {['live', 'draft', 'past', 'cancelled'].includes(listType) && (
          <FilterGroup>
            <FilterGroupTitle>{intl.formatMessage({ id: 'event_type' })}</FilterGroupTitle>
            <FilterGroupInner>
              {eventTypes === null ? (
                times((idx) => <CheckboxSkeleton key={`checkbox-skeleton-${idx}`} />, 5)
              ) : eventTypes && eventTypes.length > 0 ? (
                eventTypes.map((type) => {
                  const checked = includes(type.label, filterValues.eventType)
                  return <EventTypeCheckbox key={type.value} type={type} checked={checked} onChange={toggleType} />
                })
              ) : (
                <EmptyFilterPlate>{intl.formatMessage({ id: 'select.no_results' })}</EmptyFilterPlate>
              )}
            </FilterGroupInner>
          </FilterGroup>
        )}

        {listType === 'submission' && (
          <>
            <FilterGroup>
              <FilterGroupTitle spacingBottom="sm">
                {intl.formatMessage({ id: 'account.type_of_organiser' })}
              </FilterGroupTitle>
              <FlexFormField
                name="typeOfOrganiser"
                control="select"
                multiple
                options={TYPES_OF_ORGANIZER}
                value={typesOfOrganisers}
                onChange={setTypeOfOrganiser}
              />
            </FilterGroup>

            <FilterGroup>
              <FilterGroupTitle spacingBottom="sm">
                {intl.formatMessage({ id: 'event_filter_bar.assignee.title' })}
              </FilterGroupTitle>
              <FlexFormField
                name="assignee"
                control="select"
                searchable
                async
                loadOptions={userLoader}
                placeholder={intl.formatMessage({ id: 'event_filter_bar.assignee.any_assignee' })}
                value={selectedAssignee}
                onChange={setAssignee}
              >
                {selectedAssignee && (
                  <IconButton icon="trash" title={intl.formatMessage({ id: 'clear' })} onClick={clearAssignee} />
                )}
              </FlexFormField>
            </FilterGroup>
          </>
        )}

        <FilterGroup>
          <FilterGroupTitle spacingBottom="sm">
            {intl.formatMessage({ id: 'event_filter_bar.event_type.title' })}
          </FilterGroupTitle>
          <FlexFormField
            name="kind"
            control="select"
            options={eventTypeOptions}
            placeholder={intl.formatMessage({ id: 'event_filter_bar.event_type.any_type' })}
            value={eventTypeOption}
            onChange={setEventType}
          >
            {eventTypeOption && (
              <IconButton icon="trash" title={intl.formatMessage({ id: 'clear' })} onClick={clearEventType} />
            )}
          </FlexFormField>
        </FilterGroup>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl onClick={applyAdvancedFilters}>
          {intl.formatMessage({ id: 'actions.apply_filters' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(EventFilterModal)

const EmptyFilterPlate = styled.div`
  margin: 10px 0;
`

const DropdownWrapper = styled.div`
  margin-top: 8px;
`
