import React, { useState, useCallback, useMemo, useContext } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { Location, useLocation } from 'react-router-dom'
import { useFormikContext } from 'formik'

import { last, filter, includes, isArray, concat, difference, find } from 'lodash/fp'

import { Dropdown, DropdownTrigger, DropdownContent } from '../../../../../../components/Dropdown'
import { Menu, MenuItem } from '../../../../../../components/Menu'
import Svg from '../../../../../../components/Svg'

import { EVENT_STATUS } from '../../../../../../constants/statuses'

import StatusCheckbox from '../StatusCheckbox'
import { FilterAdditionalItemDropdown } from '../../../../../../components/FilterStyles'
import { authContext } from '../../../../../../context/auth'
import DiceBadge from '../../../../../../components/DiceBadge'

const getStatuses = (isAdmin: boolean, location: Location) => {
  const status = last(location.pathname.split('/'))

  let set: Set<string>
  switch (status) {
    case 'drafts':
      set = new Set(['draft', 'ready-to-submit', 'to-be-approved'])
      break
    case 'live':
      set = new Set(['postponed', 'announced', 'on-sale', 'running-low', 'sold-out'])
      break
    case 'past':
      set = new Set(['postponed', 'running-low', 'sold-out', 'archived'])
      if (isAdmin) {
        set.add('dice-ready-to-payout')
      }
      break
    default:
      return []
  }

  return filter((status) => set.has(status.key), EVENT_STATUS)
}

function renderValue(value: string[] | string, intl: IntlShape) {
  const array = isArray(value) ? value : value.split(',')
  if (array.length === 0) return intl.formatMessage({ id: 'event_filter_bar.status.any_status' })
  if (array.length > 1) return intl.formatMessage({ id: 'event_filter_bar.status.options' }, { number: array.length })

  const status = find(['key', array[0]], EVENT_STATUS)

  return (
    <>
      {status?.key.startsWith('dice') && <DiceBadge />}
      {intl.formatMessage({ id: status?.i18n || `event_status.${array[0]}` })}
    </>
  )
}

function StatusFilter() {
  const intl = useIntl()
  const location = useLocation()
  const { user } = useContext(authContext)

  const { values, handleSubmit, setFieldValue } = useFormikContext<{
    status: string[] | string
  }>()
  const [open, setOpen] = useState(false)

  const statuses = useMemo(() => getStatuses(user.diceStaff, location) || [], [location, user.diceStaff])
  const currentValue = useMemo(() => renderValue(values.status, intl), [intl, values.status])
  const onClickOutside = useCallback(() => open && setOpen(false), [open, setOpen])
  const toggleDropdown = useCallback(() => setOpen(!open), [open, setOpen])

  const toggleStatus = useCallback(
    (key: any, checked: any) => {
      const array = isArray(values.status) ? values.status : values.status.split(',')
      setFieldValue('status', checked ? difference(array, [key]) : concat(key, array))
      handleSubmit()
    },
    [setFieldValue, values.status, handleSubmit]
  )

  return (
    <FilterAdditionalItemDropdown
      as={Dropdown}
      className={!!values.status.length && '-active'}
      active={open}
      onClickOutside={onClickOutside}
    >
      <DropdownTrigger onClick={toggleDropdown}>
        <Svg icon="status" />
        <strong>{currentValue}</strong>
      </DropdownTrigger>
      <DropdownContent active={open}>
        <Menu>
          {statuses.map((status) => {
            const checked = includes(status.key, values.status)
            return (
              <MenuItem key={status.key}>
                <StatusCheckbox status={status} checked={checked} onChange={toggleStatus} />
              </MenuItem>
            )
          })}
        </Menu>
      </DropdownContent>
    </FilterAdditionalItemDropdown>
  )
}

export default StatusFilter
