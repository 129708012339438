import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'
import { isNumber } from 'lodash/fp'
import TextInput, { ITextInputProps } from './TextInput'
import { color } from '../utils/variables'

export interface ILimitedInputProps extends ITextInputProps {
  limit?: number | null
  formatLimitText?: (props: ILimitedInputProps) => string
}

const Limit = styled.span`
  position: absolute;
  left: 16px;
  color: ${color.grey};
  pointer-events: none;
  span {
    &:first-child {
      opacity: 0;
    }
  }
`

const LimitedInput: FC<ILimitedInputProps> = (props) => {
  const { limit, formatLimitText = () => '', ...rest } = props

  if (!isNumber(limit)) return <TextInput {...props} />

  return (
    <TextInput
      {...rest}
      type="number"
      max={limit}
      affix={
        <Limit>
          {formatLimitText(props) || (
            <>
              <span>{props.value}</span>
              <span>{`${!String(props.value) ? '∞' : ''} / ${limit}`}</span>
            </>
          )}
        </Limit>
      }
    />
  )
}

export default memo(LimitedInput)
