import React, { FC, memo, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { color } from '../../../utils/variables'

const Container = styled.div<{ percentage?: number }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  width: 100%;
  height: 2px;

  background-color: ${color.lightgrey};

  overflow: hidden;

  & > div {
    position: absolute;
    left: 0;
    height: 100%;
    max-width: 100%;
    background-color: ${color.text};

    width: 100%;
    transform-origin: top left;
    transform: scaleX(0);

    transition: transform 1s;

    &:after {
      display: block;
      content: ' ';
      position: absolute;

      width: 2px;
      height: 2px;
      right: -2px;

      background-color: ${color.text};
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;

      transform: scaleX(0);
    }
  }

  &.-passive > div {
    transition: transform 0.3s;
  }

  &.-animating > div,
  &.-passive > div {
    transform: scaleX(${({ percentage }) => percentage || 0}%);

    &:after {
      transform: scaleX(${({ percentage }) => (percentage ? (100 / percentage) * 100 : 0)}%);
    }
  }
`

interface IProps {
  percentage: number
}

const ProgressBar: FC<IProps> = ({ percentage }) => {
  const [barState, setBarState] = useState<'initial' | 'animating' | 'passive'>('initial')
  useEffect(() => {
    setBarState('animating')
    const timeout = setTimeout(() => setBarState('passive'), 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Container className={`-${barState}`} percentage={percentage}>
      <div />
    </Container>
  )
}

export default memo(ProgressBar)
