import React, { FC, memo, useMemo } from 'react'
import { filter, sortBy, isEmpty, times } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { List, ListTitle } from '../../DashboardEvents'
import { DashboardEventCardList, DashboardEventCard, DashboardEventCardEmpty } from '../DashboardEvent/DashboardEvent'
import DashboardEventCardInfo from '../DashboardEvent/DashboardEventCardInfo'

import {
  RecentlyViewed_viewer$data,
  RecentlyViewed_viewer$key,
} from '../../../../__generated__/RecentlyViewed_viewer.graphql'
import DashboardEventSalesProgress from '../DashboardEvent/DashboardEventSalesProgress'


const ToBottom = styled.div`
  margin-top: auto;
`

type INode = NonNullable<
  NonNullable<NonNullable<RecentlyViewed_viewer$data['recentlyViewed']>['edges']>[number]
>['node']

interface IProps {
  viewer: RecentlyViewed_viewer$key
}

const RecentlyViewed: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const intl = useIntl()

  const viewer = useFragment(
    graphql`
      fragment RecentlyViewed_viewer on Viewer
      @argumentDefinitions(where: { type: "EventWhereInput", defaultValue: {} }) {
        recentlyViewed: events(first: 5, where: $where) {
          edges {
            node {
              id
              eventIdLive
              ...DashboardEventCardInfo_event
              ...DashboardEventSalesProgress_event
            }
          }
        }
      }
    `,
    viewerKey
  )

  const edges = useMemo(() => viewer.recentlyViewed?.edges || [], [viewer.recentlyViewed?.edges])

  const validEdges = useMemo(() => filter('node', edges), [edges])

  if (isEmpty(validEdges)) return null

  const eventsOrder = window.localStorage.getItem('recently_viewed')?.split(',') || []
  const orderedEvents = isEmpty(eventsOrder)
    ? validEdges
    : (sortBy(({ node }: { node: INode }) => eventsOrder.indexOf(node?.id as string), validEdges) as typeof validEdges)

  return (
    <List>
      <ListTitle>{intl.formatMessage({ id: 'dashboard.event_list.recently_viewed' })}</ListTitle>
      <DashboardEventCardList>
        {orderedEvents.map(
          (oe) =>
            oe?.node && (
              <DashboardEventCard key={oe.node.id}>
                <DashboardEventCardInfo event={oe.node} />
                {!!oe.node.eventIdLive && (
                  <ToBottom>
                    <DashboardEventSalesProgress event={oe.node} />
                  </ToBottom>
                )}
              </DashboardEventCard>
            )
        )}
        {times(
          (idx) => (
            <DashboardEventCardEmpty key={`event-viewed-${idx}`} />
          ),
          4
        )}
      </DashboardEventCardList>
    </List>
  )
}

export default memo(RecentlyViewed)
