import React, { ComponentProps, FC, HTMLProps, useCallback, useMemo } from 'react'
import cn from 'classnames'
import styled from 'styled-components/macro'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { getOr, isEmpty, isArray, reject, sumBy, findIndex } from 'lodash/fp'
import { FormikErrors } from 'formik'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'

import { CURRENCY } from '../../../utils/formatters/number'
import { breakpoints, color, font, mediaQuery } from '../../../utils/variables'

import IconButton from '../../../components/IconButton'
import IdTag from '../../../components/IdTag'
import Svg from '../../../components/Svg'
import { TitleTooltip } from '../../../components/Tooltip'

import IEventFormExtras, { IProduct } from '../types/Extras'
import { EventCostCurrency } from '../../../enums.generated'
import { textStyle } from '../../../utils/typography'
import { Text } from '../../../components/Text'

const LinkedTicketsWrapper = styled.div`
  margin: -8px;
  min-width: 248px;
`

const Flex = styled.div`
  display: flex;
  align-items: baseline;
  ${IdTag} {
    margin: -3px 0 -2px 4px;
  }
`

const Icon = styled.div`
  position: relative;
  border: 2px solid ${color.text};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 16px;
  margin-top: 3px;

  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }

  ${mediaQuery.lessThan('desktop')`
    display: none;
  `}
`

const Col = styled.div`
  font-size: ${font.size.base}px;
  line-height: 20px;
  text-align: right;
  & + & {
    margin-left: 32px;
  }

  & > div:first-child {
    font-size: ${font.size.sm}px;
    line-height: 17px;
    color: ${color.darkgrey};
    margin-bottom: 4px;
    white-space: nowrap;
  }
`

const WideCol = styled(Col)`
  flex: 1;
  ${textStyle.functional.lg}
  text-align: left;

  ${mediaQuery.lessThan('desktop')`
    max-width: calc(100% - 100px);
  `}
`

const Row = styled.li<{ isFirstItem?: boolean; isArchived?: boolean }>`
  position: relative;
  display: flex;
  padding: 16px 0 16px 48px;
  border-bottom: 1px solid ${color.lightgrey};

  ${({ isFirstItem }) => isFirstItem && 'margin-top: -8px;'}

  & > ${Col} {
    opacity: ${({ isArchived }) => (isArchived ? 0.5 : 1)};
  }

  ${mediaQuery.lessThan('tablet')`
    padding-right: 40px;
  `}

  ${mediaQuery.lessThan('desktopLarge')`
    & > ${Col} {
      display: none;
    }

    & > ${WideCol} {
      display: block;
    }
  `}
`

const EditButton = styled(IconButton)`
  margin-left: 16px;
  ${({ outlineColor }) =>
    `color: ${getOr(color.text, outlineColor === 'red' ? 'error' : outlineColor || 'text', color)};`};
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 18px;
  left: 0px;

  color: ${color.grey};

  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};

  user-select: none;

  ${mediaQuery.lessThan<{ disabled?: boolean }>('tablet')`
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
    margin: 0;
    position: absolute;
    left: 0px;
    top: 18px;
  `}

  svg {
    pointer-events: none;
  }
`

const Buttons = styled.div`
  display: flex;

  ${mediaQuery.lessThan('tablet')`
    margin: 0;
    position: absolute;
    right: 0px;
  `}
`

const Name = styled.div`
  font-weight: bold;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface IContainerProps<T> {
  allProducts?: ReadonlyArray<T>
  products: ReadonlyArray<T>
  currency?: EventCostCurrency
  remove: (id: string) => void
  restore: (id: string) => void
  copy: (id: string) => void
  showEditModal: (id: string) => void
  errors: FormikErrors<IEventFormExtras>
  modalId: null | string
  sortingDisabled?: boolean
  removeDisabled?: boolean
  copyDisabled?: boolean
}

interface IElementProps<T> {
  product: T
  currency?: EventCostCurrency
  idx: number
  currentProductIdx: number
  noMove: boolean
  noRemove?: boolean
  noCopy?: boolean
  remove: (id: string) => void
  restore: (id: string) => void
  copy: (id: string) => void
  showEditModal: (id: string) => void
  errors: FormikErrors<IEventFormExtras>
  modalOpen?: boolean
}

const SortableDragHandle = SortableHandle<HTMLProps<HTMLDivElement> & { disabled?: boolean }>(
  ({ disabled, ...props }: { disabled?: boolean }) => (
    <DragHandle disabled={disabled} {...props}>
      <Svg icon="hamburger" />
    </DragHandle>
  )
)

const SortableRow = SortableElement<IElementProps<IProduct>>(
  ({
    product,
    currency,
    idx,
    currentProductIdx,
    remove,
    restore,
    copy,
    showEditModal,
    noMove,
    noRemove,
    noCopy,
    errors,
    modalOpen,
  }: IElementProps<IProduct>) => {
    const intl = useIntl()
    const onRemove = useCallback(() => remove(product.id), [remove, product.id])
    const onRestore = useCallback(() => restore(product.id), [restore, product.id])
    const onEdit = useCallback(() => showEditModal(product.id), [showEditModal, product.id])
    const onCopy = useCallback(() => copy(product.id), [copy, product.id])

    const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

    const formattedPrice = useMemo(
      () =>
        product.faceValue && intl.formatNumber((product.faceValue || 0) / 100, CURRENCY(product.faceValue, currency)),
      [product.faceValue, intl, currency]
    )

    const hasErrors = useMemo(
      () =>
        !product.archived &&
        !modalOpen &&
        !isEmpty(errors.products) &&
        (!isArray(errors.products) || !!errors.products[currentProductIdx]),
      [currentProductIdx, errors.products, modalOpen, product.archived]
    )

    const linkedTickets = useMemo(() => {
      return reject(['archived', true], product.ticketTypes)
    }, [product.ticketTypes])

    const ticketsTooltip = useMemo(
      () => (
        <LinkedTicketsWrapper>
          <ul>{linkedTickets.map((t) => t && <li key={t.id}>{t.name}</li>)}</ul>
        </LinkedTicketsWrapper>
      ),
      [linkedTickets]
    )

    const productCategoryIcon = useMemo(() => {
      switch (product?.category?.parentCategory?.type) {
        case 'MERCH':
          return 'merch'
        case 'EXPERIENCE':
        default:
          return 'ticket'
      }
    }, [product.category])

    const productAllocation = useMemo(() => {
      switch (product?.category?.parentCategory?.type) {
        // case 'MERCH':
        //   return sumBy('allocation', product?.variants || [])
        case 'EXPERIENCE':
        default:
          return product.hasVariants ? sumBy('allocation', product.variants || []) : product.allocation || 0
      }
    }, [product])

    return (
      <Row
        className="draggable"
        isFirstItem={idx === 0}
        isArchived={!!product.archived}
        data-name={`products.${idx}.innerError`}
      >
        <SortableDragHandle
          data-type={product?.category?.parentCategory?.type || 'UNKNOWN'}
          disabled={noMove || !!product.archived}
        />
        <Icon>
          <Svg icon={productCategoryIcon} />
        </Icon>
        <WideCol>
          <Flex>
            <div>{intl.formatMessage({ id: 'new_event.extras.list.name' })}</div>
            <IdTag id={product.id} successMessage={intl.formatMessage({ id: 'action.copy_success' })} />
          </Flex>
          <Name data-id={`products[${idx}].name`}>
            <div>{product.name}</div>
          </Name>
          {product.hasVariants && (product.variants.length || 0) > 0 && (
            <Text fontSize="sm" color="darkgrey" as="span">
              {intl.formatMessage(
                { id: `new_event.extras.list.variants.${product.optionType === 'SIZE' ? 'sizes' : 'options'}` },
                { count: product.variants.length }
              )}
            </Text>
          )}
        </WideCol>
        <Col>
          <div>{intl.formatMessage({ id: 'new_event.extras.list.allocation' })}</div>
          <div data-id={`product[${idx}].allocation`}>{productAllocation}</div>
        </Col>
        <Col>
          <div>{intl.formatMessage({ id: 'new_event.extras.list.linked_tickets' })}</div>
          <div data-id={`product[${idx}].ticketTypes`}>
            {product.allTicketTypes ? (
              intl.formatMessage({ id: 'all_tickets' })
            ) : linkedTickets.length > 0 ? (
              <TitleTooltip
                overlayClassName={cn({ '-mobile': isMobile })}
                preset="underlined"
                title={ticketsTooltip}
                placement={'bottomRight'}
                prefixCls="rc-tooltip"
              >
                {linkedTickets.length}
              </TitleTooltip>
            ) : (
              '0'
            )}
          </div>
        </Col>

        <Col>
          <div>{intl.formatMessage({ id: 'new_event.extras.list.price' })}</div>
          <div data-id={`product[${idx}].price`}>{formattedPrice || intl.formatMessage({ id: 'free' })}</div>
        </Col>
        <Buttons>
          <EditButton
            disabled={!!product.archived}
            outlineColor={hasErrors ? 'red' : 'text'}
            icon="edit"
            title={intl.formatMessage({ id: 'actions.edit' })}
            onClick={onEdit}
            data-id={`editProduct[${idx}]`}
          />
          <IconButton
            title={intl.formatMessage({ id: 'actions.copy' })}
            icon="copy"
            onClick={onCopy}
            data-id={`copyProduct[${idx}]`}
            disabled={noCopy || !!product.archived}
          />
          {product.archived ? (
            <IconButton
              title={intl.formatMessage({ id: 'actions.restore' })}
              icon="reload"
              onClick={onRestore}
              data-id={`restoreProduct[${idx}]`}
            />
          ) : (
            <IconButton
              title={intl.formatMessage({ id: 'actions.remove' })}
              icon="trash"
              onClick={onRemove}
              data-id={`removeProduct[${idx}]`}
              disabled={noRemove}
            />
          )}
        </Buttons>
      </Row>
    )
  }
)

const EventProductList: FC<IContainerProps<IProduct>> = ({
  allProducts,
  products,
  currency,
  remove,
  restore,
  copy,
  showEditModal,
  errors,
  modalId,
  sortingDisabled,
  removeDisabled,
  copyDisabled,
}) => {
  return (
    <ul>
      {products.map((product: IProduct, idx: number) => {
        const currentProductIdx = findIndex(['id', product.id], reject('archived', allProducts))
        return (
          product && (
            <SortableRow
              key={product.id}
              index={idx}
              idx={idx}
              currentProductIdx={currentProductIdx || idx}
              product={product}
              currency={currency}
              remove={remove}
              restore={restore}
              copy={copy}
              showEditModal={showEditModal}
              disabled={sortingDisabled || products.length === 1}
              noMove={sortingDisabled || products.length === 1}
              noRemove={removeDisabled}
              noCopy={copyDisabled}
              errors={errors}
              modalOpen={modalId === product.id}
            />
          )
        )
      })}
    </ul>
  )
}

export default SortableContainer<ComponentProps<typeof EventProductList>>(EventProductList)
