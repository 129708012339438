import { useCallback, useContext, useMemo } from 'react'
import {
  map,
  keyBy,
  compose,
  without,
  compact,
  concat,
  find,
  filter,
  flatMap,
  uniqBy,
  reject,
  last,
  differenceBy,
  __,
  set,
  update,
  findIndex,
  debounce,
  take,
} from 'lodash/fp'
import { DeepNonNullable } from 'ts-essentials'
import graphql from 'babel-plugin-relay/macro'
import { useFormikContext } from 'formik'
import arrayMove from 'array-move'
import { useIntl } from 'react-intl'
import { Environment, useRelayEnvironment } from 'react-relay'

import { authContext } from '../../../context/auth'
import { allowedEventAction } from '../services/allowedEventAction'
import graphqlOptionsLoader, { IOptions } from '../../../utils/graphqlOptionsLoader'
import simplifyLabel from '../../../utils/simplifyLabel'
import IEventFormBasics from '../types/Basics'
import { useEventArtistsKimLoaderQuery } from '../../../__generated__/useEventArtistsKimLoaderQuery.graphql'
import { getDefaultLineup } from '../services/getDefaultEvent'
import IEventFormInformation from '../types/Information'
import { autofillLineup } from '../services/autofillDates'
import IEventForm from '../types'

interface ISearchResult {
  value: string
  label: string
}

type ISearchCallback = (value: Array<ISearchResult>) => void

const createCombinedLoader = (environment: Environment) =>
  debounce(500, (str: string, callback: ISearchCallback) => {
    const kimArtistLoader = graphqlOptionsLoader(
      environment,
      graphql`
        query useEventArtistsKimLoaderQuery($searchTerm: String) {
          viewer {
            options: artists(searchTerm: $searchTerm, first: 50) {
              edges {
                node {
                  value: id
                  label: name
                  hint: disambiguation
                  media {
                    id
                    type
                    values
                  }
                  description
                  hierarchicalTags {
                    value: id
                    label: name
                    kind
                    parent {
                      name
                    }
                  }
                  tags {
                    value: id
                    label: name
                  }
                  profileImageAttachment {
                    cdnUrl
                  }
                  profileImageCropRegion {
                    x
                    y
                    width
                    height
                  }
                }
              }
            }
          }
        }
      `,
      { fullText: true, immediate: true }
    )

    const musicbrainzArtistLoader = graphqlOptionsLoader(
      environment,
      graphql`
        query useEventArtistsMusicbrainzLoaderQuery($searchTerm: String!) {
          viewer {
            options: musicbrainzArtists(query: $searchTerm, limit: 50, filterKimArtists: true) {
              value: id
              label: name
              hint: disambiguation
            }
          }
        }
      `,
      { fullText: true, immediate: true }
    )

    const resultsPromise = Promise.all([
      new Promise<IOptions>((resolve) => kimArtistLoader(str, resolve)),
      new Promise<IOptions>((resolve) => musicbrainzArtistLoader(str, resolve)),
    ])

    resultsPromise.then(
      compose([
        callback,
        take(50),
        ([kim, musicbrainz]) => {
          const keyed = keyBy((k) => simplifyLabel(k.label), kim)
          return concat(
            kim,
            reject((mb) => !!keyed[simplifyLabel(mb.label)], musicbrainz)
          )
        },
      ])
    )
  })

type IArtist =
  | DeepNonNullable<useEventArtistsKimLoaderQuery['response']['viewer']>['options']['edges'][number]['node']
  | undefined

type IArtists = Array<NonNullable<NonNullable<IEventFormBasics['eventArtists']>[number]>['artist']>

interface IEventArtist {
  artist: IArtist | null
  headliner: boolean | null
}

function useEventArtists() {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)
  const environment = useRelayEnvironment()

  const { values, setFieldValue } = useFormikContext<IEventFormBasics & IEventFormInformation>()

  const allowEditLineup = useMemo(
    () => values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'lineUp'),
    [values.allowedLifecycleUpdates, values.state]
  )

  const allowEditMedia = useMemo(
    () => values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'media'),
    [values.allowedLifecycleUpdates, values.state]
  )

  const setArtists = useCallback(
    (eventArtists: Array<IEventArtist | null> | null) => {
      const selection = filter('artist', compact(eventArtists || []))

      const type = find(['kind', 'type'], values.hierarchicalTags)
      const genres = filter((tag) => tag?.kind === 'genre', values.hierarchicalTags || [])

      let newGenres = []

      if (genres.length === 0) {
        newGenres = compose([
          filter(['kind', 'genre']),
          flatMap('hierarchicalTags'),
          filter((artist: IArtist) => type?.value === find(['kind', 'type'], artist?.hierarchicalTags || [])?.value),
          map('artist'),
        ])(selection)
      } else {
        const artist = last(selection)?.artist
        const artistType = find(['kind', 'type'], artist?.hierarchicalTags || [])
        const artistGenres = filter((tag) => tag?.kind === 'genre', artist?.hierarchicalTags || [])

        if (artistType?.value === type?.value) {
          newGenres = artistGenres
        }
      }

      // TODO: Cover with specific permission in future if needed
      if (hasPermission('manage_links:event') || !!values.allowedActions?.manageLinks || user.diceStaff) {
        setFieldValue('tags', uniqBy('value', concat(values.tags || [], last(selection)?.artist?.tags || [])))
      }

      if (newGenres.length > 0) {
        setFieldValue('hierarchicalTags', uniqBy('value', concat(values.hierarchicalTags || [], newGenres)))
      }

      setFieldValue('eventArtists', selection)

      const headliners = filter((eventArtist) => !!eventArtist?.headliner, selection || [])
      const artistForBio =
        selection?.length === 1 && selection[0]
          ? selection[0]
          : headliners.length === 1 && headliners[0]
            ? headliners[0]
            : null

      if (values.artistForBio?.artist?.value !== artistForBio?.artist?.value) {
        if (!values.artistForBio && artistForBio?.artist?.description) {
          setFieldValue('showArtistDescription', 'DICE')
        } else if (!values.showArtistDescription) {
          setFieldValue('showArtistDescription', 'NONE')
        }

        setFieldValue('artistForBio', artistForBio)
      }

      const oldArtists = map('artist', values.eventArtists || []) as IArtists
      const newArtists = map('artist', selection || []) as IArtists

      const addedArtists: IArtists = differenceBy('value', newArtists, oldArtists)
      const removedArtists: IArtists = differenceBy('value', oldArtists, newArtists)

      if (allowEditLineup) {
        const normalizedLineup =
          !values.lineup || values.lineup.length === 0
            ? autofillLineup(values as IEventForm, getDefaultLineup(intl), values.timezoneName)
            : compact(values.lineup)

        const lineupMap = keyBy('details', normalizedLineup)

        const addedLineupItems = compose([
          map((a: IArtist) => a && { details: a.label, time: '' }),
          filter((a: IArtist) => !!a && !lineupMap[a.label]),
        ])(addedArtists)

        const removedLineupItems = compose([compact, map((a: IArtist) => a && lineupMap[a.label])])(removedArtists)

        if (addedLineupItems.length > 0 || removedLineupItems.length > 0) {
          setFieldValue(
            'lineup',
            compose([concat(__, addedLineupItems), without(removedLineupItems)])(normalizedLineup)
          )
        }
      }

      if (allowEditMedia) {
        let newMedia = [...(values.media || [])]

        if (!newMedia || newMedia.length === 0) {
          for (const artist of map('artist', selection)) {
            if (artist?.media && reject(['type', 'spotifyArtist'], artist?.media || []).length > 0) {
              newMedia = reject(['type', 'spotifyArtist'], artist?.media || [])
              break
            }
          }
        }

        const oldSpotifyIds = new Set(compact(map('values.artist_id', filter(['type', 'spotifyArtist'], newMedia))))

        for (const artist of addedArtists) {
          if (artist?.media) {
            for (const spotArt of filter(['type', 'spotifyArtist'], artist.media)) {
              if (spotArt?.values?.artist_id && !oldSpotifyIds.has(spotArt.values.artist_id)) {
                newMedia.push(spotArt)
              }
            }
          }
        }

        for (const artist of removedArtists) {
          if (artist?.media) {
            for (const spotArt of filter(['type', 'spotifyArtist'], artist.media)) {
              if (spotArt?.values?.artist_id && oldSpotifyIds.has(spotArt.values.artist_id)) {
                newMedia = reject(['values.artist_id', spotArt.values?.artist_id], newMedia)
              }
            }
          }
        }

        setFieldValue('media', newMedia)
      }
    },
    [values, hasPermission, user.diceStaff, setFieldValue, allowEditLineup, allowEditMedia, intl]
  )

  const toggleHeadliner = useCallback(
    (id: string) => {
      const idx = findIndex(['artist.value', id], values.eventArtists || [])
      const newArtists = compact(update([idx, 'headliner'], (v) => !v, values.eventArtists || [])) as any

      setArtists(newArtists)
    },
    [setArtists, values.eventArtists]
  )

  const removeArtist = useCallback(
    (id: string) => {
      const newArtists = compact(reject(['artist.value', id], values.eventArtists))

      setArtists(uniqBy('artist.value', newArtists) as any)
    },
    [setArtists, values.eventArtists]
  )

  const addArtist = useCallback(
    (_id: string | null, selected: IArtist | null) => {
      if (!selected) return

      const oldArtists: IEventArtist[] = filter('artist', compact(values.eventArtists || [])) as any
      const eventArtist: IEventArtist = { artist: selected, headliner: false }

      const newArtists = compact(concat(oldArtists, set('isNew', true, eventArtist)))

      setArtists(uniqBy('artist.value', newArtists) as any)
    },
    [setArtists, values.eventArtists]
  )

  const artists = useMemo(() => uniqBy('artist.value', compact(values.eventArtists || [])), [values.eventArtists])

  const reorderArtists = useCallback(
    ({ oldIndex, newIndex }: any) => {
      setArtists(arrayMove(artists || [], oldIndex, newIndex) as any)
    },
    [setArtists, artists]
  )

  const combinedLoader = useMemo(() => createCombinedLoader(environment), [environment])

  return {
    artists,
    addArtist,
    removeArtist,
    toggleHeadliner,
    artistLoader: combinedLoader,
    reorderArtists,
  }
}

export default useEventArtists
