/**
 * @generated SignedSource<<160bcc6929006af4b000cc6ae2b94c1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventReviewStatus = "ESCALATED" | "ON_HOLD" | "%future added value";
export type UpdateEventReviewInput = {
  assigneeId?: string | null;
  clientMutationId: string;
  eventId: string;
  priority?: boolean | null;
  status?: EventReviewStatus | null;
};
export type EventControlsPriorityMutation$variables = {
  input: UpdateEventReviewInput;
};
export type EventControlsPriorityMutation$data = {
  readonly updateEventReview: {
    readonly messages: ReadonlyArray<{
      readonly message: string | null;
    } | null> | null;
    readonly result: {
      readonly priority: boolean | null;
    } | null;
    readonly successful: boolean;
  } | null;
};
export type EventControlsPriorityMutation = {
  response: EventControlsPriorityMutation$data;
  variables: EventControlsPriorityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "successful",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationMessage",
  "kind": "LinkedField",
  "name": "messages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priority",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventControlsPriorityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventReviewPayload",
        "kind": "LinkedField",
        "name": "updateEventReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventReview",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventControlsPriorityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventReviewPayload",
        "kind": "LinkedField",
        "name": "updateEventReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventReview",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "148761b9ed0670f3e41c809506ecbfce",
    "id": null,
    "metadata": {},
    "name": "EventControlsPriorityMutation",
    "operationKind": "mutation",
    "text": "mutation EventControlsPriorityMutation(\n  $input: UpdateEventReviewInput!\n) {\n  updateEventReview(input: $input) {\n    successful\n    messages {\n      message\n    }\n    result {\n      priority\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b72fcbe1454d1243bf842097bb64a58";

export default node;
