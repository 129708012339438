import graphql from 'babel-plugin-relay/macro'
import { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useRelayEnvironment } from 'react-relay'
import graphqlOptionsLoader from '../graphqlOptionsLoader'

const useMiscTags = (options = { field: 'tags' }) => {
  const { setFieldValue } = useFormikContext()
  const environment = useRelayEnvironment()

  const tagsLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query useMiscTagsLoaderQuery($where: TagWhereInput) {
            viewer {
              options: tags(where: $where, first: 20) {
                edges {
                  node {
                    value: id
                    label: name
                  }
                }
              }
            }
          }
        `
      ),
    [environment]
  )

  const setTags = useCallback(
    (_ids: any, tags: any) => setFieldValue(options.field, tags || []),
    [options.field, setFieldValue]
  )

  return { setTags, tagsLoader }
}

export default useMiscTags
