/**
 * @generated SignedSource<<b74b40a0f42d997ff9dca5b340145256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChannelType = "APP_SALE" | "BOX_OFFICE_SALE" | "HOLD" | "NON_DICE" | "OTHER" | "%future added value";
export type CompatibilityAe = "_0" | "_1000" | "_5000" | "%future added value";
export type CountryCode = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AN" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type DistanceUnits = "kilometers" | "miles" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type FeesBehaviour = "APPEND_TO_CONTRACT" | "OVERRIDE" | "USE_CONTRACT" | "%future added value";
export type MediaItemTypes = "appleMusicTrack" | "spotifyArtist" | "spotifyTrack" | "trailer" | "%future added value";
export type PaymentMethods = "AFTERPAY_CLEARPAY" | "DICE_SPLIT" | "GIROPAY" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type PrintedTicketFormat = "BOCA_55X2" | "BOCA_6X3_NO_LOGO" | "NO_PRINTER" | "STAR_RECEIPT" | "STAR_RECEIPT_ETICKET" | "%future added value";
export type ProductOptionType = "CUSTOM" | "SIZE" | "%future added value";
export type ProductType = "ADDON" | "%future added value";
export type RepeatEnds = "OCCURRENCES" | "UNTIL" | "%future added value";
export type RepeatFrequency = "BI_WEEKLY" | "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type RepeatOn = "LAST_WEEK_DAY" | "SAME_DAY" | "SAME_WEEK_AND_DAY" | "%future added value";
export type ReservedSeatingTypes = "assignBestSeat" | "selectSeat" | "%future added value";
export type RestrictionKind = "ALLOW" | "DENY" | "%future added value";
export type ScheduleStatus = "POSTPONED" | "RESCHEDULED" | "%future added value";
export type ShowArtistDescription = "CUSTOM" | "DICE" | "NONE" | "%future added value";
export type TargetingMode = "country" | "location" | "worldwide" | "%future added value";
export type TvPlatform = "DICE" | "EXTERNAL" | "%future added value";
export type ValidateDraftEventInput = {
  additionalArtists?: ReadonlyArray<AdditionalArtistInput | null> | null;
  additionalInfos?: ReadonlyArray<EventAdditionalInfoInput | null> | null;
  addressCapacity?: number | null;
  addressCountry?: string | null;
  addressLocality?: string | null;
  addressRegion?: string | null;
  addressSiaeCode?: string | null;
  addressState?: string | null;
  ageLimit?: string | null;
  announceDate?: string | null;
  artistIds?: ReadonlyArray<string | null> | null;
  artists?: ReadonlyArray<EventArtistInput | null> | null;
  attractiveFields?: AttractiveFieldsInput | null;
  barcodeType?: string | null;
  basePriceFees?: ReadonlyArray<string | null> | null;
  billingNotes?: string | null;
  billingPromoterId?: string | null;
  bundleIds?: ReadonlyArray<string | null> | null;
  characteristicIds?: ReadonlyArray<string | null> | null;
  charityEvent?: boolean | null;
  charityId?: string | null;
  checklists?: ReadonlyArray<ChecklistInput | null> | null;
  cityIds?: ReadonlyArray<string | null> | null;
  clientMutationId: string;
  closeEventDate?: string | null;
  colour?: ColourInput | null;
  completedSteps?: number | null;
  costAmount?: number | null;
  costCurrency?: string | null;
  countryCode?: string | null;
  date?: string | null;
  description?: string | null;
  diceStatusNotes?: string | null;
  diceStreamDuration?: number | null;
  diceStreamDvrEnabled?: boolean | null;
  diceStreamRewatchEnabledUntil?: string | null;
  diceTv?: boolean | null;
  diceTvPlatform?: TvPlatform | null;
  disableUsTax?: boolean | null;
  doorlistAdditionalRecipients?: ReadonlyArray<string | null> | null;
  doorlistRecipientIds?: ReadonlyArray<string | null> | null;
  endDate?: string | null;
  eventIdLive?: string | null;
  eventImages?: ReadonlyArray<EventImageInput | null> | null;
  eventLoadPredictions?: ReadonlyArray<EventLoadPredictionInput | null> | null;
  eventPromoters?: ReadonlyArray<EventPromoter | null> | null;
  eventRules?: EventRulesInput | null;
  eventSeatingChartId?: string | null;
  eventSharingObjects?: ReadonlyArray<EventSharingObjectInput | null> | null;
  eventType?: EventType | null;
  eventVenues?: ReadonlyArray<EventVenues | null> | null;
  extraNotes?: string | null;
  fanSupportNotes?: FanSupportNotesInput | null;
  faqs?: ReadonlyArray<FaqInput | null> | null;
  featuredAreas?: ReadonlyArray<FeaturedAreaInput | null> | null;
  fees?: ReadonlyArray<FeeInput | null> | null;
  feesBehaviour?: FeesBehaviour | null;
  flags?: EventFlagsInput | null;
  freeEvent?: boolean | null;
  fullAddress?: string | null;
  hierarchicalTagIds?: ReadonlyArray<string | null> | null;
  id: string;
  isTest?: boolean | null;
  isTicketAvailableAtDoor?: boolean | null;
  labelIds?: ReadonlyArray<string | null> | null;
  latitude?: number | null;
  licenseNumber?: string | null;
  lineup?: ReadonlyArray<LineupInput | null> | null;
  links?: ReadonlyArray<LinkInput | null> | null;
  lockVersion?: number | null;
  longitude?: number | null;
  manualValidationEnabled?: boolean | null;
  marketeerIds?: ReadonlyArray<string | null> | null;
  maxTicketsLimit?: number | null;
  media?: ReadonlyArray<MediaItemInputObject | null> | null;
  musicbrainzArtists?: ReadonlyArray<MusicbrainzArtists | null> | null;
  name?: string | null;
  notes?: string | null;
  offSaleDate?: string | null;
  onSaleDate?: string | null;
  onSaleNotification?: boolean | null;
  onSaleNotificationAt?: string | null;
  onSaleNotificationSmsContent?: string | null;
  overrideFees?: boolean | null;
  permName?: string | null;
  platformAccountCode?: PlatformAccountCode | null;
  postFanPriceFees?: ReadonlyArray<string | null> | null;
  postOfficeBoxNumber?: string | null;
  postalCode?: string | null;
  presentedBy?: string | null;
  printedTicketFormat?: PrintedTicketFormat | null;
  products?: ReadonlyArray<ProductInput | null> | null;
  promoterIds?: ReadonlyArray<string | null> | null;
  promoterStatusNotes?: string | null;
  pwlWindow?: number | null;
  readAccessEmails?: ReadonlyArray<string | null> | null;
  recurrentEventSchedule?: RecurrentEventsScheduleInput | null;
  relatedEventIds?: ReadonlyArray<string | null> | null;
  requiresBoxOfficeTicketNomination?: boolean | null;
  requiresTicketNomination?: boolean | null;
  restrictCountries?: ReadonlyArray<CountryCode | null> | null;
  restrictCountriesKind?: RestrictionKind | null;
  salesforceContractId?: string | null;
  scheduleStatus?: ScheduleStatus | null;
  seatingChannels?: ReadonlyArray<SeatingChannelInput | null> | null;
  sendReceiptViaSms?: boolean | null;
  showArtistDescription?: ShowArtistDescription | null;
  socialDistancingRulesetKey?: string | null;
  stages?: ReadonlyArray<string | null> | null;
  streetAddress?: string | null;
  stripeAccountId?: string | null;
  tagIds?: ReadonlyArray<string | null> | null;
  taxSettings?: TaxSettingsInput | null;
  thirdPartySettingsId?: string | null;
  ticketPools?: ReadonlyArray<AttachTicketPoolInput | null> | null;
  ticketType?: string | null;
  ticketTypes?: ReadonlyArray<TicketTypesInput | null> | null;
  timezoneName?: string | null;
  totalTickets?: number | null;
  venue?: string | null;
  venueConfigurationId?: string | null;
  venueIds?: ReadonlyArray<string | null> | null;
  venueName?: string | null;
  venueSchedules?: ReadonlyArray<VenueScheduleInput | null> | null;
  venueSpaceId?: string | null;
  waitingListExchangeWindows?: ReadonlyArray<WaitingListExchangeWindowInput | null> | null;
};
export type AdditionalArtistInput = {
  description?: string | null;
  hierarchicalTagIds?: ReadonlyArray<string | null> | null;
  id?: string | null;
  name?: string | null;
};
export type EventAdditionalInfoInput = {
  content?: string | null;
  ctaLabel?: string | null;
  ctaLink?: string | null;
  id?: string | null;
  includeOnPurchaseEmail?: boolean | null;
  includeOnReminderEmail?: boolean | null;
};
export type EventArtistInput = {
  description?: string | null;
  headliner?: boolean | null;
  id?: string | null;
  musicbrainzArtistId?: string | null;
  name?: string | null;
};
export type AttractiveFieldsInput = {
  author?: string | null;
  compatibilityAe?: CompatibilityAe | null;
  distributor?: string | null;
  entertainmentPercent?: number | null;
  forceSubscription?: boolean | null;
  forceSubscriptionLimit?: number | null;
  id?: string | null;
  integrationDisabled?: boolean | null;
  linkedEventIds?: ReadonlyArray<string | null> | null;
  nationality?: string | null;
  performer?: string | null;
  producer?: string | null;
  seatingAreaConfigs?: ReadonlyArray<SeatingAreaConfigInput | null> | null;
  siaeGenreType?: string | null;
  streamingTicketsIntegrationDisabled?: boolean | null;
  subscriptionCode?: string | null;
  subscriptionEventsLimit?: number | null;
  taxFree?: boolean | null;
};
export type SeatingAreaConfigInput = {
  capacity: number;
  seatingArea: string;
};
export type ChecklistInput = {
  items: ReadonlyArray<ChecklistItemInput | null>;
  name: string;
  uuid: string;
};
export type ChecklistItemInput = {
  completed: boolean;
  name: string;
  uuid: string;
};
export type ColourInput = {
  alpha?: number | null;
  blue?: number | null;
  green?: number | null;
  red?: number | null;
};
export type EventImageInput = {
  attachmentId?: string | null;
  cropRegion?: CropRegionInput | null;
  id?: string | null;
  type?: string | null;
};
export type CropRegionInput = {
  height?: number | null;
  width?: number | null;
  x?: number | null;
  y?: number | null;
};
export type EventLoadPredictionInput = {
  expectedRequestsPerMinute: number;
  expectedStartTime: string;
  id?: string | null;
};
export type EventPromoter = {
  billingPromoter?: boolean | null;
  promoterId?: string | null;
};
export type EventRulesInput = {
  covidPcr?: boolean | null;
  covidPcrValidHours?: number | null;
  covidPolicyUrl?: string | null;
  covidRecovery?: boolean | null;
  covidVaccination?: boolean | null;
  maskRequired?: boolean | null;
  proofOfBeingHealthy?: boolean | null;
  socialDistancing?: boolean | null;
};
export type EventSharingObjectInput = {
  email: string;
  id?: string | null;
  permissionProfileId: string;
};
export type EventVenues = {
  primary?: boolean | null;
  venueId?: string | null;
};
export type FanSupportNotesInput = {
  body?: string | null;
};
export type FaqInput = {
  body: string;
  id?: string | null;
  order: number;
  title: string;
};
export type FeaturedAreaInput = {
  countryCode?: string | null;
  description?: string | null;
  endDate: string;
  id?: string | null;
  locationLat?: number | null;
  locationLng?: number | null;
  locationRadius?: number | null;
  locationString?: string | null;
  locationUnits?: DistanceUnits | null;
  mode: TargetingMode;
  startDate: string;
  weight: number;
};
export type FeeInput = {
  active?: boolean | null;
  amount: number;
  split: ReadonlyArray<FeeSplitInput | null>;
  type: FeeType;
  unit: FeeUnit;
};
export type FeeSplitInput = {
  amount: number;
  destination: FeeDestination;
  unit: FeeUnit;
};
export type EventFlagsInput = {
  alcoholFree?: FlagValue | null;
  autoRescheduledEventRefunds?: AutoRescheduledEventRefundsInput | null;
  branded?: FlagValue | null;
  claimTickets?: FlagValue | null;
  codeLocked?: FlagValue | null;
  competition?: FlagValue | null;
  coolingOffPeriod?: CoolingOffPeriodInput | null;
  disableDayOfEventComms?: FlagValue | null;
  enabledPwl?: EnabledPwlInput | null;
  fanPickSeat?: FanPickSeatInput | null;
  fanSurvey?: FlagValue | null;
  featured?: FlagValue | null;
  generateNewCodeOnTransfer?: FlagValue | null;
  grouped?: FlagValue | null;
  guestlist?: FlagValue | null;
  hidden?: FlagValue | null;
  hideFromDiscovery?: FlagValue | null;
  mbwayEnabled?: FlagValue | null;
  night?: FlagValue | null;
  paperTicket?: FlagValue | null;
  returns?: FlagValue | null;
  seated?: FlagValue | null;
  shoppingCart?: FlagValue | null;
  ticketTransfer?: FlagValue | null;
  ticketTypes?: FlagValue | null;
  unicorn?: FlagValue | null;
  waitingList?: FlagValue | null;
};
export type FlagValue = {
  active?: boolean | null;
};
export type AutoRescheduledEventRefundsInput = {
  active?: boolean | null;
  cutoffDays?: number | null;
};
export type CoolingOffPeriodInput = {
  active?: boolean | null;
  hours?: number | null;
};
export type EnabledPwlInput = {
  active?: boolean | null;
  deadline?: string | null;
  increaseAmount?: number | null;
};
export type FanPickSeatInput = {
  active?: boolean | null;
  hours?: number | null;
};
export type LineupInput = {
  details?: string | null;
  time?: string | null;
};
export type LinkInput = {
  name?: string | null;
  url?: string | null;
};
export type MediaItemInputObject = {
  id?: string | null;
  type: MediaItemTypes;
  values: MediaValuesInput;
};
export type MediaValuesInput = {
  artistId?: string | null;
  image?: string | null;
  mediaUri?: string | null;
  name?: string | null;
  openUrl?: string | null;
  previewUrl?: string | null;
  video?: string | null;
};
export type MusicbrainzArtists = {
  id?: string | null;
  name?: string | null;
};
export type ProductInput = {
  allTicketTypes?: boolean | null;
  allocation?: number | null;
  archived?: boolean | null;
  categoryId?: string | null;
  customCover?: boolean | null;
  date?: string | null;
  description?: string | null;
  endDate?: string | null;
  eventId?: string | null;
  faceValue?: number | null;
  fees?: ReadonlyArray<FeeInput | null> | null;
  fulfilledBy?: string | null;
  hasSeparateAccessBarcodes?: boolean | null;
  hasVariants?: boolean | null;
  id?: string | null;
  locationNote?: string | null;
  name?: string | null;
  offSaleDate?: string | null;
  onSaleDate?: string | null;
  optionType?: ProductOptionType | null;
  productImages?: ReadonlyArray<ProductImageInput | null> | null;
  productType?: ProductType | null;
  purchaseConfirmationMessage?: string | null;
  sellingPoints?: ReadonlyArray<SellingPointInput | null> | null;
  sku?: string | null;
  ticketTypeIds?: ReadonlyArray<string | null> | null;
  variants?: ReadonlyArray<VariantInput | null> | null;
  venueId?: string | null;
};
export type ProductImageInput = {
  attachmentId?: string | null;
  cropRegion?: CropRegionInput | null;
  id?: string | null;
};
export type SellingPointInput = {
  name: string;
};
export type VariantInput = {
  allocation: number;
  id?: string | null;
  name: string;
  size?: string | null;
  sku?: string | null;
};
export type RecurrentEventsScheduleInput = {
  frequency?: RepeatFrequency | null;
  occurrences?: number | null;
  repeatEnds?: RepeatEnds | null;
  repeatOn?: RepeatOn | null;
  timezoneName?: string | null;
  until?: string | null;
};
export type SeatingChannelInput = {
  channelType: ChannelType;
  id?: string | null;
  name?: string | null;
  seatsIoChannel: string;
};
export type TaxSettingsInput = {
  clubNight?: boolean | null;
  franceMainstream?: boolean | null;
};
export type AttachTicketPoolInput = {
  id?: string | null;
  maxAllocation?: number | null;
  name?: string | null;
};
export type TicketTypesInput = {
  activateCodeDateOffset?: number | null;
  additionalPaymentMethods?: ReadonlyArray<PaymentMethods | null> | null;
  allocation?: number | null;
  allowSeatChange?: boolean | null;
  announceDate?: string | null;
  archived?: boolean | null;
  area?: CreateOrUpdateVenueAreaInput | null;
  attractivePriceType?: string | null;
  attractiveSeatingAreaType?: string | null;
  attractiveTaxFree?: boolean | null;
  codeLocked?: boolean | null;
  description?: string | null;
  doorSalesEnabled?: boolean | null;
  doorSalesPrice?: number | null;
  endDate?: string | null;
  externalSkus?: ReadonlyArray<string | null> | null;
  faceValue?: number | null;
  fees?: ReadonlyArray<FeeInput | null> | null;
  hidden?: boolean | null;
  icon?: string | null;
  id?: string | null;
  increment?: number | null;
  isStream?: boolean | null;
  maximumIncrements?: number | null;
  name?: string | null;
  offSaleDate?: string | null;
  onSaleDate?: string | null;
  presale?: boolean | null;
  priceGrade?: string | null;
  priceHidden?: boolean | null;
  priceTierType?: PriceTierTypes | null;
  priceTiers?: ReadonlyArray<PriceTiersInput | null> | null;
  productIds?: ReadonlyArray<string | null> | null;
  requiresAddress?: boolean | null;
  requiresOtherTypeIds?: ReadonlyArray<string | null> | null;
  reservedSeating?: boolean | null;
  reservedSeatingType?: ReservedSeatingTypes | null;
  salesLimit?: number | null;
  seatCategories?: ReadonlyArray<SeatCategoriesInput | null> | null;
  seatmapUrl?: string | null;
  startDate?: string | null;
  streamEmbedCode?: string | null;
  streamLink?: string | null;
  ticketPoolId?: string | null;
  venueScheduleId?: string | null;
  venueScheduleIndex?: number | null;
};
export type CreateOrUpdateVenueAreaInput = {
  code?: string | null;
  id?: string | null;
  name?: string | null;
};
export type PriceTiersInput = {
  allocation?: number | null;
  attractivePriceType?: string | null;
  doorSalesPrice?: number | null;
  faceValue?: number | null;
  fees?: ReadonlyArray<FeeInput | null> | null;
  id?: string | null;
  name?: string | null;
  time?: string | null;
};
export type SeatCategoriesInput = {
  id?: string | null;
  name?: string | null;
  seatsIoKey?: string | null;
  value?: number | null;
};
export type VenueScheduleInput = {
  date?: string | null;
  endDate?: string | null;
  id?: string | null;
  name?: string | null;
  venueConfigurationId?: string | null;
  venueId?: string | null;
};
export type WaitingListExchangeWindowInput = {
  duration: number;
  id?: string | null;
  offset?: number | null;
};
export type validateDraftMutation$variables = {
  input: ValidateDraftEventInput;
};
export type validateDraftMutation$data = {
  readonly validateDraftEvent: {
    readonly messages: ReadonlyArray<{
      readonly code: string;
      readonly field: string | null;
      readonly message: string | null;
      readonly options: ReadonlyArray<{
        readonly key: string;
        readonly value: string;
      } | null> | null;
      readonly template: string | null;
    } | null> | null;
    readonly successful: boolean;
  } | null;
};
export type validateDraftMutation = {
  response: validateDraftMutation$data;
  variables: validateDraftMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidateDraftEventPayload",
    "kind": "LinkedField",
    "name": "validateDraftEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationOption",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "template",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "validateDraftMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "validateDraftMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "293494ade2028de14f001f81a2fe57ba",
    "id": null,
    "metadata": {},
    "name": "validateDraftMutation",
    "operationKind": "mutation",
    "text": "mutation validateDraftMutation(\n  $input: ValidateDraftEventInput!\n) {\n  validateDraftEvent(input: $input) {\n    successful\n    messages {\n      code\n      field\n      message\n      options {\n        key\n        value\n      }\n      template\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8c8cf6aa91e9abd94cbe34049a4cd66";

export default node;
