import React, { FC, HTMLProps, Suspense, memo, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { spacing } from '../../../../utils/variables'

import { OnDesktop, OnMobile } from '../../../../components/Breakpoints'
import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../components/Dropdown'
import IconButton from '../../../../components/IconButton'
import { Loader } from '../../../../components/Loader'
import { Menu, MenuItem } from '../../../../components/Menu'
import ChangePoolCapacityModal from '../../../EventList/components/Modals/ChangePoolCapacityModal'

import { TicketBreakdownPools_event$data } from '../../../../__generated__/TicketBreakdownPools_event.graphql'

export const Actions = styled.div<HTMLProps<HTMLSpanElement> & { disabled?: boolean }>`
  position: absolute;
  right: 0;
  margin-top: ${spacing.xs}px;

  && {
    opacity: 1;
  }

  flex: 1;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
    && {
      opacity: .25;
    }
  `}

  ${IconButton} {
    margin-left: ${spacing.sm}px;
  }

  ${Loader} {
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-left: ${spacing.sm}px;
    vertical-align: middle;
  }
`

interface IProps {
  event: TicketBreakdownPools_event$data
  ticketPool: {
    id: string
    maxAllocation: number | null
  }
}

const TicketPoolActions: FC<IProps> = ({ event, ticketPool }) => {
  const intl = useIntl()

  const [open, setOpen] = useState<boolean>(false)
  const toggleDropdown = useCallback(() => setOpen(!open), [open])
  const clickOutside = useCallback(() => open && setOpen(false), [open])

  const [poolCapacityModal, setPoolCapacityModal] = useState<boolean>(false)
  const togglePoolCapacityModal = useCallback(() => {
    setOpen(false)
    setPoolCapacityModal(!poolCapacityModal)
  }, [poolCapacityModal])

  return (
    <Actions>
      <Suspense fallback={<Loader />}>
        <Dropdown active={open} onClickOutside={clickOutside}>
          <DropdownTrigger role="button" onClick={toggleDropdown} data-id="ticketPool">
            <OnDesktop>
              <IconButton icon="more" />
            </OnDesktop>
            <OnMobile>
              <IconButton outlineColor="grey" icon="more" />
            </OnMobile>
          </DropdownTrigger>
          <DropdownContent active={open}>
            <Menu>
              <MenuItem onClick={togglePoolCapacityModal} data-id="changePoolCapacity">
                {intl.formatMessage({ id: 'change_pool_capacity' })}
              </MenuItem>
            </Menu>
          </DropdownContent>
        </Dropdown>
        {poolCapacityModal && (
          <ChangePoolCapacityModal
            eventId={event.id}
            preSelectedTPId={ticketPool.id}
            onClose={togglePoolCapacityModal}
          />
        )}
      </Suspense>
    </Actions>
  )
}

export default memo(TicketPoolActions)
