import { parseISO } from 'date-fns'
import { EventState } from '../enums.generated'
import unwrapId from './unwrapId'

type ITrackingState = 'draft' | 'announced' | 'onsale' | 'live' | 'ended'

const trackingState = (
  eventState: EventState | null,
  announceDate: Date | null,
  onSaleDate: Date | null,
  date: Date | null,
  endDate: Date | null
): ITrackingState | null => {
  if (!eventState) return null

  switch (eventState) {
    case 'DRAFT':
    case 'DECLINED':
    case 'REVIEW':
    case 'SUBMITTED':
      return 'draft'
    case 'ARCHIVED':
    case 'CANCELLED':
      return 'ended'
    case 'APPROVED':
      if (endDate && endDate < new Date()) return 'ended'
      if (date && date < new Date()) return 'live'
      if (onSaleDate && onSaleDate < new Date()) return 'onsale'
      if (announceDate && announceDate < new Date()) return 'announced'
      return 'draft'
    default:
      return null
  }
}

interface IEvent {
  id: string | null
  eventIdLive: string | null
  state: EventState | null
  announceDate: string | null
  onSaleDate: string | null
  date: string | null
  endDate: string | null
}

interface ITrackData {
  event_id: number | null
  event_id_live: string | null
  event_state: ITrackingState | null
}

export const collectTrackingData = (event: IEvent | null | undefined): ITrackData | null =>
  event
    ? {
      event_id: unwrapId(event.id || undefined),
      event_id_live: event.eventIdLive,
      event_state: trackingState(
        event.state,
        event.announceDate ? parseISO(event.announceDate) : null,
        event.onSaleDate ? parseISO(event.onSaleDate) : null,
        event.date ? parseISO(event.date) : null,
        event.endDate ? parseISO(event.endDate) : null
      ),
    }
    : null
