import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useFormikContext } from 'formik'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { compact, compose, concat, filter, find, get, map, reject } from 'lodash/fp'
import { Link } from 'react-router-dom'
import { useRelayEnvironment } from 'react-relay'
import { color, font, mediaQuery } from '../../../utils/variables'

import { allowedEventAction } from '../services/allowedEventAction'
import IEventFormBasics from '../types/Basics'
import Badge from '../../../components/Badge'
import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import IconButton from '../../../components/IconButton'
import useAdditionalArtists from '../hooks/useAdditionalArtists'
import useEventArtists from '../hooks/useEventArtists'
import Svg from '../../../components/Svg'
import { searchSpotifyArtistFn } from '../../../utils/trackSearch'
import { authContext } from '../../../context/auth'
import EventCustomArtist, { type FormValues as CustomArtistFormValues } from './EventCustomArtist'
import { Basics_viewer$data } from '../../../__generated__/Basics_viewer.graphql'
import artistImage from '../../Artists/utils/artistImage'
import { textStyle } from '../../../utils/typography'
import OpenUrl from '../../../components/OpenUrl'

const ArtistLabel = styled.div`
  flex: 1;
  margin-right: 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  &.-additional {
    flex-direction: column;
    align-items: flex-start;
  }

  & > a {
    display: block;
    height: 24px;
  }

  & > strong {
    display: block;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;

    .hint {
      ${textStyle.functional.sm}
      color: ${color.darkgrey}
    }
  }
`

const ArtistItem = styled.li`
  display: flex;
  align-items: center;

  padding: 16px 0;

  ${mediaQuery.lessThan('tablet')`
    padding: 8px 0;
  `}

  border-bottom: 1px solid ${color.lightgrey};
  background-color: ${color.white};
`

const HeadlinerLabel = styled.div`
  color: ${color.primary};

  font-size: ${font.size.sm}px;
  text-align: right;
  letter-spacing: 0.01em;

  margin-right: 4px;
`

const HeadlinerButton = styled(IconButton)<{ $isHeadliner: boolean }>`
  &,
  &:hover,
  &:focus,
  &:active {
    color: ${({ $isHeadliner }) => ($isHeadliner ? color.primary : color.text)};
  }
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  flex: none;
  max-width: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${color.grey};
  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};

  user-select: none;

  svg {
    pointer-events: none;
  }
`

const SOpenUrl = styled(OpenUrl)`
  pointer-events: auto;
  cursor: pointer;
`

const Option = styled.div<{ isCreateOption?: boolean }>`
  display: flex;
  align-items: center;
  & > * {
    word-break: break-word;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  ${({ isCreateOption }) =>
    isCreateOption &&
    `
    border-top: 1px solid ${color.lightgrey};
    margin: -8px -16px 0 -16px;
    padding: 4px 16px 0 16px;
  `}
`
export const ArtistImage = styled.div<{ large?: boolean }>`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  flex-grow: 0;

  margin-right: 8px;

  border-radius: 50%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${color.palegrey};
  color: ${color.darkgrey};

  & > img {
    width: 100%;
    height: 100%;
  }

  ${({ large }) =>
    large &&
    `
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    margin-right: 16px;
  `}
`

const ArtistName = styled.span`
  display: inline-block;
  margin: 0 16px 0 0;
`

const ArtistHint = styled.span`
  color: ${color.darkgrey};
`

type IEventArtist = NonNullable<NonNullable<IEventFormBasics['eventArtists']>[number]>
type IAdditionalArtist = NonNullable<NonNullable<IEventFormBasics['additionalArtists']>[number]>

interface IItemProps {
  eventArtist: IEventArtist
  canUpdate: boolean
  canRemove: boolean
  onRemove: (id: string) => void
  onToggleHeadliner: (id: string) => void
}

const SortableDragHandle = SortableHandle<{ disabled?: boolean }>(({ disabled }: { disabled?: boolean }) => (
  <DragHandle disabled={disabled} data-id="dragHandle">
    <Svg icon="hamburger" />
  </DragHandle>
))

const SortableArtist = SortableElement<IItemProps>(
  ({ eventArtist, canUpdate, canRemove, onToggleHeadliner, onRemove }: IItemProps) => {
    const intl = useIntl()

    const { user } = useContext(authContext)

    const toggleHeadliner = useCallback(() => {
      if (!eventArtist.artist?.value) return
      onToggleHeadliner(eventArtist.artist?.value)
    }, [eventArtist.artist?.value, onToggleHeadliner])

    const removeArtist = useCallback(() => {
      if (!eventArtist.artist?.value) return
      onRemove(eventArtist.artist?.value)
    }, [eventArtist.artist?.value, onRemove])

    const spotifyArtist = useMemo(
      () => find(['type', 'spotifyArtist'], eventArtist.artist?.media || [])?.values,
      [eventArtist.artist?.media]
    )

    return eventArtist.artist ? (
      <ArtistItem data-id={`eventArtist[${eventArtist.artist.value}]`}>
        <SortableDragHandle disabled={!canUpdate} />
        <ArtistImage large>
          {artistImage(eventArtist.artist) ? (
            <img src={artistImage(eventArtist.artist)} alt="Artist thumbnail" />
          ) : (
            <Svg icon="artist" />
          )}
        </ArtistImage>
        <ArtistLabel>
          <div>
            <strong data-id="artistLabel">
              {user.diceStaff ? (
                <Link to={`/artists/${eventArtist.artist.value}`} target="_blank" rel="noopener noreferrer">
                  {eventArtist.artist.label}
                </Link>
              ) : (
                eventArtist.artist.label
              )}
            </strong>
            {eventArtist.artist.hint && <div className="hint">{eventArtist.artist.hint}</div>}
          </div>
          {spotifyArtist && (
            <OpenUrl href={spotifyArtist.open_url as string}>
              <Svg icon="spotify" />
            </OpenUrl>
          )}
        </ArtistLabel>
        {eventArtist.headliner && (
          <HeadlinerLabel data-id="artistHeadlinerLabel">
            {intl.formatMessage({ id: 'new_event.basics.artists.headline.active_label' })}
          </HeadlinerLabel>
        )}
        <div>
          {(canUpdate || (eventArtist as any).isNew) && (
            <HeadlinerButton
              icon={eventArtist.headliner ? 'star-filled' : 'star'}
              title={intl.formatMessage({ id: 'new_event.basics.artists.headline.title' })}
              titlePlacement="left"
              data-id="headlinerToggleButton"
              onClick={toggleHeadliner}
              $isHeadliner={!!eventArtist.headliner}
            />
          )}
          {(canRemove || (eventArtist as any).isNew) && (
            <IconButton
              icon="trash"
              title={intl.formatMessage({ id: 'new_event.basics.artists.remove.title' })}
              data-id="removeButton"
              onClick={removeArtist}
            />
          )}
        </div>
      </ArtistItem>
    ) : null
  }
)

interface IContainerProps {
  artists: IEventArtist[]
  canUpdate: boolean
  canRemove: boolean
  onRemove: (id: string) => void
  onToggleHeadliner: (id: string) => void
}

const SortableArtistList = SortableContainer<IContainerProps>(
  ({ artists, canRemove, canUpdate, onRemove, onToggleHeadliner }: IContainerProps) => (
    <ul data-id="eventArtists">
      {artists.map(
        (eventArtist, idx) =>
          eventArtist && (
            <SortableArtist
              key={eventArtist?.artist?.value}
              index={idx}
              eventArtist={eventArtist}
              canRemove={canRemove}
              canUpdate={canUpdate}
              onRemove={onRemove}
              onToggleHeadliner={onToggleHeadliner}
            />
          )
      )}
    </ul>
  )
)

const EventArtists: FC<React.PropsWithChildren<{ readOnly: boolean; viewer: Basics_viewer$data }>> = ({
  readOnly,
  viewer,
}) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const environment = useRelayEnvironment()
  const [openArtist, setOpenArtist] = useState<Partial<IAdditionalArtist> | null>(null)

  const { values, touched, errors, handleBlur, setFieldValue } = useFormikContext<IEventFormBasics>()

  const createArtistLabelConstructor = useCallback(
    (input: string) =>
      intl.formatMessage(
        { id: 'new_event.basics.artists.create_artist' },
        { b: (str: string) => <strong key="new_event.basics.artists.create_artist">{str}</strong>, name: input }
      ),
    [intl]
  )

  const canAdd = useMemo(
    () => values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'artistIds', 'canAdd'),
    [values.allowedLifecycleUpdates, values.state]
  )
  const canRemove = useMemo(
    () => values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'artistIds', 'canRemove'),
    [values.allowedLifecycleUpdates, values.state]
  )
  const canUpdate = useMemo(
    () => values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'artistIds', 'canUpdate'),
    [values.allowedLifecycleUpdates, values.state]
  )

  const { artists, addArtist, removeArtist, toggleHeadliner, artistLoader, reorderArtists } = useEventArtists()

  const { additionalArtists, addAdditionalArtist, updateAdditionalArtist, removeAdditionalArtist } =
    useAdditionalArtists()

  const searchSpotifyArtist = useMemo(() => searchSpotifyArtistFn(environment), [environment])

  const spotifyArtists = useMemo(
    () =>
      compose([
        compact,
        map(
          (m: NonNullable<IEventFormBasics['media']>[number]) =>
            m?.values && {
              ...m.values,
              value: m.values.artist_id,
              label: <SOpenUrl href={m.values.open_url as string}>{m.values.name as string}</SOpenUrl>,
            }
        ),
        filter(['type', 'spotifyArtist']),
      ])(values.media || []),
    [values.media]
  )

  const setSpotifyArtists = useCallback(
    (_ids: any[], media: any[]) => {
      const everythingElse = reject(['type', 'spotifyArtist'], values.media || [])
      const newArtists = map((m: any) => ({ type: 'spotifyArtist', values: { ...m, value: m.artist_id } }), media || [])

      setFieldValue('media', concat(everythingElse, newArtists))
    },
    [setFieldValue, values.media]
  )

  const handleCustomArtistSubmit = useCallback(
    (values: CustomArtistFormValues) => {
      if (!openArtist) {
        return
      }

      if (find(['name', openArtist.name], additionalArtists)) {
        updateAdditionalArtist(openArtist, values)
      } else {
        addAdditionalArtist(values)
      }

      setOpenArtist(null)
    },
    [addAdditionalArtist, additionalArtists, openArtist, updateAdditionalArtist]
  )

  const handleCustomArtistClose = useCallback(() => setOpenArtist(null), [])

  const handleCreateArtist = useCallback((name: string) => setOpenArtist({ name }), [])

  const customOptionLabel = useCallback((artist: any) => {
    const { label, hint, __isNew__ } = artist
    return (
      <Option isCreateOption={__isNew__}>
        {!__isNew__ && (
          <ArtistImage>
            {artistImage(artist) ? <img src={artistImage(artist)} alt="Artist thumbnail" /> : <Svg icon="artist" />}
          </ArtistImage>
        )}
        <ArtistName>{label}</ArtistName>
        {hint && <ArtistHint>{hint}</ArtistHint>}
      </Option>
    )
  }, [])

  const canAddArtists = user.diceStaff || (values.billingPromoter?.eventDefaults?.addArtistsToEvent ?? true)

  return (
    <>
      {openArtist && (
        <EventCustomArtist
          viewer={viewer}
          artist={openArtist}
          onClose={handleCustomArtistClose}
          onSubmit={handleCustomArtistSubmit}
        />
      )}

      {canAddArtists && (
        <FormRow columnOnMobile>
          <div>
            <FormField
              name="artists"
              label={intl.formatMessage({ id: 'new_event.basics.artists.label' })}
              control="select"
              searchable
              async
              loadOptions={canAdd ? artistLoader : undefined}
              placeholder={intl.formatMessage({ id: 'new_event.basics.artists.placeholder' })}
              onBlur={handleBlur}
              onChange={addArtist}
              value={[]}
              disabled={readOnly || !canAdd || !canUpdate}
              error={touched.eventArtists && errors.eventArtists}
              allowCreate={canAdd}
              createOptionLabel={createArtistLabelConstructor}
              onCreateOption={handleCreateArtist}
              help={intl.formatMessage({ id: 'new_event.basics.artists.help' })}
              optionLabel={customOptionLabel}
              alwaysShowCreateOption
            />
            {additionalArtists.length > 0 && (
              <ul data-id="additionalArtists">
                {additionalArtists.map((artist, i) => (
                  <ArtistItem key={i}>
                    <ArtistLabel className="-additional">
                      <strong>{artist.name}</strong>
                      {user.diceStaff && <Badge>{intl.formatMessage({ id: 'new_event.basics.not_in_mio' })}</Badge>}
                    </ArtistLabel>
                    <div>
                      {(canUpdate || artist.isNew) && (
                        <IconButton
                          icon="edit"
                          disabled={readOnly}
                          data-id="editButton"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => setOpenArtist(artist)}
                          title={intl.formatMessage({ id: 'artists.edit_action' })}
                        />
                      )}
                      {(canRemove || artist.isNew) && (
                        <IconButton
                          icon="trash"
                          disabled={readOnly}
                          data-id="removeButton"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => removeAdditionalArtist(artist)}
                          title={intl.formatMessage({ id: 'new_event.basics.artists.remove.title' })}
                        />
                      )}
                    </div>
                  </ArtistItem>
                ))}
              </ul>
            )}
            {artists.length > 0 && (
              <SortableArtistList
                artists={artists}
                canRemove={canRemove}
                canUpdate={canUpdate}
                onRemove={removeArtist}
                onToggleHeadliner={toggleHeadliner}
                onSortEnd={reorderArtists}
                lockAxis="y"
                useDragHandle
              />
            )}
          </div>
        </FormRow>
      )}

      {user.diceStaff && (
        <FormRow columnOnMobile>
          <FormField
            name="spotifyArtists"
            label={intl.formatMessage({ id: 'new_event.basics.spotify_artists.label' })}
            placeholder={intl.formatMessage({ id: 'new_event.basics.artists.placeholder' })}
            control="select"
            multiple
            searchable
            async
            value={spotifyArtists}
            loadOptions={searchSpotifyArtist}
            onChange={setSpotifyArtists}
            onBlur={handleBlur}
            dice
            disabled={readOnly || !canAdd || !canUpdate}
            help={intl.formatMessage({ id: 'new_event.basics.spotify_artists.help' })}
          />
        </FormRow>
      )}
    </>
  )
}

export default memo(EventArtists)
