/**
 * @generated SignedSource<<77841ed745c6c96c25906485fdd9755f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type ScheduleStatus = "POSTPONED" | "RESCHEDULED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventPartStatus_event$data = {
  readonly allocation: number;
  readonly allowedActions: {
    readonly performPayout: boolean | null;
  } | null;
  readonly completedSteps?: number | null;
  readonly detectBalance?: string;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly flags: {
    readonly hidden: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly prices?: {
    readonly to: number;
  } | null;
  readonly sales?: {
    readonly totalAppSold: number;
    readonly totalPosSold: number;
  } | null;
  readonly scheduleStatus: ScheduleStatus | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"useEventPayout_event">;
  readonly " $fragmentType": "EventPartStatus_event";
};
export type EventPartStatus_event$key = {
  readonly " $data"?: EventPartStatus_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartStatus_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "hideFreeTag"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isDraft"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "needBalance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventPartStatus_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isDraft",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedSteps",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hideFreeTag",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceRange",
          "kind": "LinkedField",
          "name": "prices",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "to",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allocation",
      "storageKey": null
    },
    {
      "condition": "isDraft",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Sales",
          "kind": "LinkedField",
          "name": "sales",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performPayout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "needBalance",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "detectBalance",
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useEventPayout_event"
        }
      ]
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "f31a913fb3adb81c6d8edacb9408679e";

export default node;
