import React, { FC, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { has } from 'lodash/fp'

import { color as COLOR, font, gradient as GRADIENT } from '../utils/variables'

type IProgressSize = 'small' | 'smallish' | 'medium' | undefined

interface IProps {
  className?: string
  size?: IProgressSize
  color?: keyof typeof COLOR
  gradient?: boolean
  value: number
}

const DEFAULT_SCALE = [24, 12] // [height, radius(half of height)]

export const ProgressBarWrapper = styled.div<{ scale: Array<number>; size?: IProgressSize }>`
  position: relative;
  height: ${({ scale }) => scale[0]}px;
  border-radius: 12px;
  overflow: hidden;
  transform: translateZ(0);
  ${({ size }) => {
    if (size === 'smallish')
      return css`
        margin: 11px 0;
      `
    if (size === 'small')
      return css`
        margin: 8px 0;
      `
  }}
`

export const ProgressBarColor = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor || COLOR.primary};
  height: 100%;
  border-radius: 12px;
`

export const ProgressBarLine = styled.div<{ scale: Array<number> }>`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  color: ${COLOR.lightgrey};
  transition: width 200ms;

  ${({ scale }) => css`
    &:before {
      content: '';
      display: block;
      width: ${scale[0]}px;
      height: ${scale[0]}px;
      border-radius: ${scale[1]}px;
      margin-left: ${-scale[0]}px;
      background: transparent;
      box-shadow: ${10000 + scale[1]}px 0 0 10000px;
      color: currentColor;
    }
  `}
`

export const ProgressBarValue = styled.span<{ position: 'inside' | 'out' }>`
  display: inline-block;
  font-size: ${font.size.sm}px;
  line-height: 24px;
  position: absolute;
  top: 0;
  padding: 0 8px;
  width: 56px;
  text-align: left;
  ${({ position }) =>
    position === 'inside' ? `color: ${COLOR.white}; margin-left: -56px; text-align: right;` : `color: ${COLOR.text};`};
`

const calcParams = (size?: IProgressSize) => {
  switch (size) {
    case 'small':
      return [8, 4]
    case 'smallish':
      return [11, 6]
    case 'medium':
    default:
      return DEFAULT_SCALE
  }
}

const ProgressBar: FC<React.PropsWithChildren<IProps>> = ({ className, size, color, gradient, value }) => {
  const intl = useIntl()
  const progressColor = gradient
    ? has(color as string, GRADIENT)
      ? GRADIENT[color as keyof typeof GRADIENT]
      : GRADIENT['primary']
    : COLOR[color || 'primary']
  const scaleParams = useMemo(() => calcParams(size), [size])
  return (
    <ProgressBarWrapper className={className} scale={scaleParams} size={size}>
      {/* values 0 and 99% needs to avoid background artifacts */}
      <ProgressBarColor bgColor={progressColor} style={{ width: value ? (value === 100 ? '100%' : '99%') : 0 }} />
      <ProgressBarLine style={{ width: `${100 - value}%`, height: `${scaleParams[0]}px` }} scale={scaleParams} />
      {(!size || size === 'medium') && (
        <ProgressBarValue position={value > 49 ? 'inside' : 'out'} style={{ left: `${value}%` }}>
          {intl.formatNumber((value || 0) / 100, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </ProgressBarValue>
      )}
    </ProgressBarWrapper>
  )
}

export default ProgressBar
