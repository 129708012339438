import styled, { css } from 'styled-components/macro'

import { color } from '../utils/variables'

export const SkeletonLine = styled.div<{ block?: boolean; height?: string; width?: string; animate?: boolean }>`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  height: ${({ height }) => (height ? height : '16px')};
  background: ${color.disabled};
  width: ${({ width }) => (width ? width : '100%')};
  & + & {
    margin-top: 8px;
  }
  ${({ animate }) =>
    animate &&
    css`
      animation: opacityAnimation 1.5s infinite;
      @keyframes opacityAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
    `}
`
