import React, { useEffect, useCallback, memo, FC, RefObject, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
import { compose, findIndex, map, omit, reject, set } from 'lodash/fp'
import { SortEnd } from 'react-sortable-hoc'
import { FormikTouched, FormikValues } from 'formik'
import arrayMove from 'array-move'
import styled from 'styled-components/macro'

import { markAsClientOnly } from '../../../../utils/entityStatus'

import Svg from '../../../../components/Svg'
import { Text } from '../../../../components/Text'

import ProductImagesList from './ProductImagesList'
import { Dropzone, DropzoneDescription, DropzoneIcon } from './ProductImagesStyles'

const rejectWithIdx = (reject as any).convert({ cap: false })

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export interface ICropRegion {
  x: number
  y: number
  width: number
  height: number
}

export interface IImage {
  id: string
  cdnUrl: string
  croppedUrl: string | null
  format: string | null
  attachment: {
    id: string
  } | null
  cropRegion: ICropRegion
}

interface IProductImages {
  refContainer: RefObject<HTMLDivElement>
  staticCover?: { imgUrl: string; bgColor: string; hint?: string } | null
  productImages: IImage[]
  setProductImages: React.Dispatch<React.SetStateAction<IImage[]>>
  touched?: FormikTouched<FormikValues>
  hasError?: boolean
  allowEdit?: boolean
  noMove?: boolean
  noRemove?: boolean
}

export const loadImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = () => resolve(img)
    img.onerror = (err) => reject(new Error('Image failed to load'))
    img.src = url
  })

const getCroppedImage = async (
  url: string,
  crop: ICropRegion,
  mime = 'image/jpeg',
  quality = 1
): Promise<string | null> => {
  const image = await loadImage(url)
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width || 0
  canvas.height = crop.height || 0
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return Promise.reject(new Error('No canvas available'))
  }

  ctx.drawImage(
    image,
    (crop.x || 0) * scaleX,
    (crop.y || 0) * scaleY,
    (crop.width || 0) * scaleX,
    (crop.height || 0) * scaleY,
    0,
    0,
    crop.width || 0,
    crop.height || 0
  )

  return new Promise<string | null>((resolve) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const objectUrl = URL.createObjectURL(blob)
          resolve(objectUrl)
        } else {
          resolve(null)
        }
      },
      mime,
      quality
    )
  })
}

export const getCroppedUrl = (cdnUrl: string, crop?: ICropRegion) => {
  if (!cdnUrl || !cdnUrl.startsWith('http')) return cdnUrl

  const url = new URL(cdnUrl)
  url.searchParams.append('w', '340')
  url.searchParams.append('auto', 'compress')

  if (!url.searchParams.get('rect') && !!crop) {
    url.searchParams.append('rect', [crop?.x || 0, crop?.y || 0, crop?.width || 0, crop?.height || 0].join(','))
  }

  return url.toString()
}

export const getImageFormatFromUrl = (url: string) => {
  const segments = url.split('.')
  const extension = segments[segments.length - 1]
  return extension.toLowerCase() // Convert to lowercase for consistency
}

const ProductImages: FC<IProductImages> = ({
  refContainer,
  staticCover,
  productImages,
  setProductImages,
  hasError,
  allowEdit,
}) => {
  const intl = useIntl()
  const [dropzoneError, setDropzoneError] = useState<string | null>(null)

  useEffect(() => {
    if (!productImages || productImages.length === 0) return
    const imagesWithoutCroppedUrl = productImages.filter((image) => !image.croppedUrl)

    if (imagesWithoutCroppedUrl.length === 0) return
    setProductImages(
      map(
        (image: IImage) =>
          image && {
            ...image,
            croppedUrl: getCroppedUrl(image.cdnUrl, image.cropRegion),
            format: getImageFormatFromUrl(image.cdnUrl),
          },
        productImages || []
      )
    )
  }, [productImages, setProductImages])

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections && fileRejections.length > 0) {
        setDropzoneError(intl.formatMessage({ id: 'new_event.extras.form.product_images.instructions.error.format' }))
        return
      } else {
        setDropzoneError(null)
      }
      const newPreviewsPromises = acceptedFiles.map(async (file) => {
        const originalUrl = URL.createObjectURL(file)
        const img = await loadImage(originalUrl)

        const squareSize = Math.min(img.width, img.height)
        const cropRegion: ICropRegion = {
          x: Math.round((img.width - squareSize) / 2),
          y: Math.round((img.height - squareSize) / 2),
          width: squareSize,
          height: squareSize,
        }
        const croppedUrl = await getCroppedImage(originalUrl, cropRegion, file.type)

        const newPreview: IImage = markAsClientOnly<IImage>({
          cdnUrl: originalUrl,
          croppedUrl,
          format: file.type.split('/')[1],
          attachment: null,
          cropRegion,
        })

        return newPreview
      })

      Promise.all(newPreviewsPromises).then((newPreviews) => {
        setProductImages([...productImages, ...newPreviews])
      })
    },
    [intl, productImages, setProductImages]
  )

  const onCrop = useCallback(
    (id: any, crop: any) => {
      const idx = findIndex(['id', id], productImages)
      const img = productImages[idx]

      const realImg = new Image()
      realImg.crossOrigin = 'anonymous'
      realImg.onload = () => {
        getCroppedImage(img.cdnUrl, crop, `image/${img.format || 'jpeg'}`).then((preview) => {
          setProductImages(
            set(
              [idx],
              compose([set('croppedUrl', preview), set('cropRegion', omit(['aspect', 'unit'], crop))])(img),
              productImages
            )
          )
        })
      }
      realImg.src = img.cdnUrl
    },
    [productImages, setProductImages]
  )

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      setProductImages(arrayMove(productImages, oldIndex, newIndex))
    },
    [productImages, setProductImages]
  )

  const onRemove = useCallback(
    (removedIdx: any) => {
      setProductImages(rejectWithIdx((_: any, idx: number) => idx === removedIdx, productImages))
    },
    [setProductImages, productImages]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
  })

  return (
    <Container>
      {staticCover || (productImages && productImages.length > 0) ? (
        <>
          <ProductImagesList
            staticCover={staticCover}
            productImages={productImages}
            onDrop={onDrop}
            onRemove={onRemove}
            onCrop={onCrop}
            hasError={hasError || !!dropzoneError}
            allowEdit={allowEdit}
            helperClass="-grabbing"
            helperContainer={refContainer.current as any}
            onSortEnd={onSortEnd}
            axis="xy"
            useDragHandle
          />
          {dropzoneError ? (
            <Text fontSize="sm" color="error" as="span" className="mt-sm">
              {intl.formatMessage({ id: 'new_event.extras.form.product_images.instructions.error.format' })}
            </Text>
          ) : (
            <Text fontSize="sm" color="darkgrey" as="span" className="mt-sm">
              {intl.formatMessage({ id: 'new_event.extras.form.product_images.instructions.drag' })}
            </Text>
          )}
        </>
      ) : (
        <>
          <Dropzone {...getRootProps()} focus={isDragActive} hasError={dropzoneError}>
            <input {...getInputProps({ name: 'productImages' })} />
            <DropzoneIcon>
              <Svg icon="add-image" />
            </DropzoneIcon>
            <DropzoneDescription>
              <span>
                {intl.formatMessage(
                  { id: 'new_event.extras.form.product_images.instructions.main' },
                  {
                    a: (str: string) => <span className="linkLike">{str}</span>,
                  }
                )}
              </span>
              <small className="color-darkgrey">JPG, PNG</small>
            </DropzoneDescription>
          </Dropzone>
          {dropzoneError && (
            <Text fontSize="sm" color="error" as="span" className="mt-sm">
              {intl.formatMessage({ id: 'new_event.extras.form.product_images.instructions.error.format' })}
            </Text>
          )}
        </>
      )}
    </Container>
  )
}

export default memo(ProductImages)
