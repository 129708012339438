import React, { memo, useMemo, FC, useContext } from 'react'
import { useIntl } from 'react-intl'
import { getOr } from 'lodash/fp'
import { isFuture, parseISO } from 'date-fns'
import styled from 'styled-components/macro'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { authContext } from '../../../context/auth'
import { EVENT_STATUS } from '../../../constants/statuses'
import { color, font, mediaQuery } from '../../../utils/variables'

import Status from '../../../components/Status'
import { STEP_COUNT } from '../../EventForm/services/getStepsConfig'
import { EventPartStatus_event$key } from '../../../__generated__/EventPartStatus_event.graphql'
import useEventPayout from '../../../utils/hooks/useEventPayout'

const Tag = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 2px 4px;
  max-width: 117px;

  color: ${color.text};
  font-size: ${font.size.xs}px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  line-height: 120%;

  background-color: ${color.lightgrey};
  border-radius: 4px;

  ${mediaQuery.lessThan('desktop')`
    display: none
  `}
`

interface IProps {
  event: EventPartStatus_event$key
  noTitle?: boolean
  showHidden?: boolean
  extended?: boolean
}

const EventPartStatus: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, noTitle, showHidden, extended }) => {
  const intl = useIntl()
  const { hasPermission } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment EventPartStatus_event on Event
      @argumentDefinitions(
        needBalance: { type: "Boolean", defaultValue: false }
        isDraft: { type: "Boolean", defaultValue: true }
        hideFreeTag: { type: "Boolean", defaultValue: false }
      ) {
        id
        statusAsOfNow
        state
        endDate
        eventIdLive
        scheduleStatus

        flags {
          hidden
        }

        completedSteps @include(if: $isDraft)

        prices @skip(if: $hideFreeTag) {
          to
        }

        allocation
        sales @skip(if: $isDraft) {
          totalAppSold
          totalPosSold
        }

        allowedActions {
          performPayout
        }

        detectBalance: id @include(if: $needBalance)
        ...useEventPayout_event @include(if: $needBalance)
      }
    `,
    eventKey
  )

  const eventType = useMemo(() => {
    if (event.state === 'DRAFT' || !event.eventIdLive || (event.state === 'APPROVED' && !event.eventIdLive)) {
      return 'draft'
    }
    if (event.endDate && isFuture(parseISO(event.endDate))) return 'live'
    return 'past'
  }, [event])

  const { selfPayoutDisabled, selfPayoutBlockers, isPaidOut, payoutTotal, isProcessing, hasPendingPayouts } =
    useEventPayout(!!event.detectBalance ? event : null)

  const customStatus = useMemo(() => {
    // Cancelled and Archived override
    if (event.state === 'CANCELLED' || event.state === 'ARCHIVED') return

    // Postponed status override
    if (event.scheduleStatus === 'POSTPONED') return EVENT_STATUS['postponed']

    switch (eventType) {
      case 'draft':
        return event.completedSteps === STEP_COUNT && event.state === 'DRAFT'
          ? EVENT_STATUS['ready-to-submit']
          : event.state === 'APPROVED' && !event.eventIdLive
            ? EVENT_STATUS['to-be-approved']
            : ''

      case 'live':
        if (event?.statusAsOfNow === 'off-sale') return
        const sold = (event?.sales?.totalAppSold || 0) + (event?.sales?.totalPosSold || 0)
        const soldPercentage = event?.allocation ? (sold * 100) / event?.allocation : 0
        return soldPercentage === 100
          ? EVENT_STATUS['sold-out']
          : soldPercentage > 80
            ? EVENT_STATUS['running-low']
            : ''

      case 'past':
        if (hasPermission('read:balances')) {
          if (isPaidOut) {
            return payoutTotal === 0 ? EVENT_STATUS['no-payout'] : EVENT_STATUS['paid_out']
          }

          if (hasPermission('perform_payout:balances')) {
            if (selfPayoutBlockers?.event_completed_recently) return EVENT_STATUS['almost-ready']
          }

          if (event.allowedActions?.performPayout) {
            if (isProcessing || hasPendingPayouts) {
              return EVENT_STATUS['processing']
            }

            if (!selfPayoutDisabled) {
              return EVENT_STATUS['ready_to_pay_out']
            }
          } else if (!isPaidOut && !hasPermission('perform_payout:balances')) {
            return EVENT_STATUS['finalising-payout']
          }
        }

        return EVENT_STATUS['off-sale']

      default:
        return ''
    }
  }, [
    event?.allocation,
    event.allowedActions?.performPayout,
    event.completedSteps,
    event.eventIdLive,
    event?.sales?.totalAppSold,
    event?.sales?.totalPosSold,
    event.scheduleStatus,
    event.state,
    event?.statusAsOfNow,
    eventType,
    hasPendingPayouts,
    hasPermission,
    isPaidOut,
    isProcessing,
    payoutTotal,
    selfPayoutBlockers?.event_completed_recently,
    selfPayoutDisabled,
  ])

  const currentStatus = customStatus || EVENT_STATUS[event.statusAsOfNow as string]
  const eventStatus = currentStatus
    ? intl.formatMessage({ id: currentStatus.i18n, defaultMessage: currentStatus.defaultValue })
    : intl.formatMessage({ id: 'event_status.unknown', defaultMessage: 'Unknown' })
  const eventStatusColor = currentStatus ? currentStatus.color : 'grey'
  const hidden = showHidden && getOr(false, 'flags.hidden.active', event)
  const free = !!event?.prices && event?.prices?.to === 0

  return (
    <Status
      color={eventStatusColor}
      title={
        !noTitle && (
          <>
            <span>
              {extended && <strong>{intl.formatMessage({ id: `event_workflow.states.${eventType}` })}:&nbsp;</strong>}
              {eventStatus}
            </span>
            {hidden && <Tag>{intl.formatMessage({ id: 'private' })}</Tag>}
            {free && !hidden && <Tag>{intl.formatMessage({ id: 'free_event' })}</Tag>}
          </>
        )
      }
    />
  )
}

export default memo(EventPartStatus)
