import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react'
import { commitLocalUpdate, useLazyLoadQuery, useRelayEnvironment } from 'react-relay'
import { useCountryRestrictionsActiveQuery } from '../../../__generated__/useCountryRestrictionsActiveQuery.graphql'

const useCountryRestrictionsActive = (): [boolean, (value: boolean) => void] => {
  const environment = useRelayEnvironment()

  const data = useLazyLoadQuery<useCountryRestrictionsActiveQuery>(
    graphql`
      query useCountryRestrictionsActiveQuery {
        viewer {
          __typename
          countryRestrictionsActive
        }
      }
    `,
    {}
  )

  const setValue = useCallback(
    (value: boolean) => {
      commitLocalUpdate(environment, (store) => {
        const viewer = store.getRoot().getLinkedRecord('viewer')
        viewer?.setValue(value, 'countryRestrictionsActive')
      })
    },
    [environment]
  )

  return [data.viewer?.countryRestrictionsActive ?? false, setValue]
}

export default useCountryRestrictionsActive
