import React, { FC, memo, useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { map, set, findIndex, compose, omit, filter, find } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'
import { FileRejection, useDropzone } from 'react-dropzone'
import styled from 'styled-components/macro'

import FormGroup from '../../../components/FormGroup'
import Svg from '../../../components/Svg'
import { breakpoints, color } from '../../../utils/variables'

import EventCropPreviews from './EventCropPreviews'
import { Loader } from '../../../components/Loader'
import { notificationContext } from '../../../context/notification'
import IEventForm from '../types'
import { OnDesktop, OnMobile } from '../../../components/Breakpoints'
import useFileDragIncoming from '../../../utils/hooks/useFileDragIncoming'
import { markAsClientOnly } from '../../../utils/entityStatus'
import {
  ImagePreview,
  ImagePreviewWrapper,
  LoaderContainer,
  RemoveButton,
  UploadButton,
  Uploader,
  UploadFileName,
  UploadInfoPanel,
} from '../../../components/UploaderStyles'
import { TitleTooltip } from '../../../components/Tooltip'
import copyToClipboard from '../../../utils/copyToClipboard'
import { CropRegionInput } from '../../../__generated__/validateDraftMutation.graphql'

const CopyButtonOverlay = styled.div`
  display: none;
  cursor: pointer;

  opacity: 0.5;
  background-color: ${color.black};

  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
`

const CopyActiveArea = styled.div`
  display: none;

  color: ${color.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;

  justify-content: center;
  align-items: center;
`

const StyledImagePreviewWrapper = styled(ImagePreviewWrapper)`
  &:hover ${CopyButtonOverlay}, &:hover ${CopyActiveArea} {
    display: flex;
    cursor: pointer;
  }

  ${CopyActiveArea} {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
`

function getCroppedImage(image: HTMLImageElement, crop: CropRegionInput, mime = 'image/jpeg', quality = 1) {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width || 0
  canvas.height = crop.height || 0
  const ctx = canvas.getContext('2d')

  if (!ctx) return Promise.reject(new Error('No canvas available'))

  ctx.drawImage(
    image,
    (crop.x || 0) * scaleX,
    (crop.y || 0) * scaleY,
    (crop.width || 0) * scaleX,
    (crop.height || 0) * scaleY,
    0,
    0,
    crop.width || 0,
    crop.height || 0
  )

  return new Promise<string | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob && URL.createObjectURL(blob)), mime, quality)
  })
}

type IImage = NonNullable<NonNullable<IEventForm['eventImages']>[number]>

interface IProps {
  rawImage: IImage | null
  setRawImage: (image: IImage | null) => void
  images: IImage[]
  setImages: (images: IImage[]) => void
  mimeType?: string
  label?: string
  help?: string
  minSizeLabel?: any
  fileFormatLabel?: any
  existingFileLabel?: any
  disablePreviews?: boolean
  allowedImageTypes?: string[]
  previewType?: string
  fileFormatErrorSuggestion: string
  allowEdit?: boolean
  required?: boolean
  error?: any
  onTouch?: () => void
  noMask?: boolean
}

const EventImageUploader: FC<React.PropsWithChildren<IProps>> = ({
  label,
  help,
  images,
  setImages,
  rawImage,
  setRawImage,
  mimeType = 'image/jpeg',
  minSizeLabel,
  fileFormatLabel,
  existingFileLabel,
  disablePreviews,
  allowedImageTypes,
  fileFormatErrorSuggestion,
  required,
  error,
  onTouch,
  previewType,
  allowEdit = true,
  noMask,
}) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const [fileName, setFileName] = useState('')

  const emptyMessage = useMemo(
    () =>
      rawImage && !fileName ? existingFileLabel : intl.formatMessage({ id: 'new_event.basics.images.no_file_chosen' }),
    [intl, fileName, rawImage, existingFileLabel]
  )

  const incoming = useFileDragIncoming(mimeType)

  const removeImages = useCallback(() => {
    setImages([])
    setRawImage(null)
    if (onTouch) onTouch()
  }, [onTouch, setImages, setRawImage])

  const onUpload = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if ((!acceptedFiles || acceptedFiles.length === 0) && (rejectedFiles?.length || 0) > 0) {
        addNotification(
          'error',
          intl.formatMessage({ id: 'upload.single_file_error' }, { fmt: fileFormatErrorSuggestion })
        )
        return
      }

      const file = acceptedFiles[0]

      if (file.type !== mimeType) {
        addNotification('error', intl.formatMessage({ id: 'upload.bad_format' }, { fmt: fileFormatErrorSuggestion }))
        return
      }

      const url = URL.createObjectURL(file)

      setFileName(file.name)

      const realImg = new Image()
      realImg.crossOrigin = 'anonymous'
      realImg.onload = () => {
        const squareSize = Math.min(realImg.naturalWidth, realImg.naturalHeight)

        let landscapeWidth = realImg.naturalWidth
        let landscapeHeight = Math.round(realImg.naturalWidth * 0.6)
        if (landscapeHeight > realImg.naturalHeight) {
          landscapeWidth = Math.round(realImg.naturalHeight / 0.6)
          landscapeHeight = realImg.naturalHeight
        }

        let portraitWidth = Math.round(realImg.naturalHeight * 0.55)
        let portraitHeight = realImg.naturalHeight
        if (portraitWidth > realImg.naturalWidth) {
          portraitWidth = realImg.naturalWidth
          portraitHeight = Math.round(realImg.naturalWidth / 0.55)
        }

        setRawImage(
          markAsClientOnly<IImage>({
            type: 'original',
            cdnUrl: url,
            attachment: null,
            cropRegion: {
              x: 0,
              y: 0,
              width: realImg.naturalWidth,
              height: realImg.naturalHeight,
            },
          })
        )

        const promises = Promise.all(
          map(
            (img) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              getCroppedImage(realImg, img.cropRegion!, mimeType).then((croppedUrl) => set('cdnUrl', croppedUrl, img)),
            filter(
              (img) => !allowedImageTypes || (img.type && allowedImageTypes.indexOf(img.type) >= 0),
              [
                markAsClientOnly<IImage>({
                  type: 'square',
                  cdnUrl: url,
                  attachment: null,
                  cropRegion: {
                    x: Math.round((realImg.naturalWidth - squareSize) / 2),
                    y: Math.round((realImg.naturalHeight - squareSize) / 2),
                    width: squareSize,
                    height: squareSize,
                  },
                }),
                markAsClientOnly<IImage>({
                  type: 'landscape',
                  cdnUrl: url,
                  attachment: null,
                  cropRegion: {
                    x: Math.round((realImg.naturalWidth - landscapeWidth) / 2),
                    y: Math.round((realImg.naturalHeight - landscapeHeight) / 2),
                    width: landscapeWidth,
                    height: landscapeHeight,
                  },
                }),
                markAsClientOnly<IImage>({
                  type: 'portrait',
                  cdnUrl: url,
                  attachment: null,
                  cropRegion: {
                    x: Math.round((realImg.naturalWidth - portraitWidth) / 2),
                    y: Math.round((realImg.naturalHeight - portraitHeight) / 2),
                    width: portraitWidth,
                    height: portraitHeight,
                  },
                }),
                markAsClientOnly<IImage>({
                  type: 'brand',
                  cdnUrl: url,
                  attachment: null,
                  cropRegion: {
                    x: Math.round((realImg.naturalWidth - squareSize) / 2),
                    y: Math.round((realImg.naturalHeight - squareSize) / 2),
                    width: squareSize,
                    height: squareSize,
                  },
                }),
              ]
            ) as NonNullable<IImage[]>
          )
        )

        promises.then((images) => {
          setImages(images)
          if (onTouch) onTouch()
        })
      }
      realImg.src = url
    },
    [mimeType, onTouch, addNotification, intl, fileFormatErrorSuggestion, setRawImage, allowedImageTypes, setImages]
  )

  const onCrop = useCallback(
    (id: any, crop: any) => {
      if (!rawImage) return

      const idx = findIndex(['id', id], images)
      const img = images[idx]

      const realImg = new Image()
      realImg.crossOrigin = 'anonymous'
      realImg.onload = () => {
        getCroppedImage(realImg, crop).then((preview) => {
          if (!img.cdnUrl.startsWith('http')) URL.revokeObjectURL(img.cdnUrl)

          setImages(
            set(
              [idx],
              compose([set('cdnUrl', preview), set('cropRegion', omit(['aspect', 'unit'], crop))])(img),
              images
            )
          )

          if (onTouch) onTouch()
        })
      }
      realImg.src = rawImage.cdnUrl
    },
    [rawImage, images, onTouch, setImages]
  )

  const smartUrl = useMemo(() => {
    if (previewType) return find(['type', previewType], images || [])?.cdnUrl
    if (!rawImage?.cdnUrl || !rawImage?.cdnUrl?.startsWith('http')) return rawImage?.cdnUrl
    return `${rawImage.cdnUrl}?w=${isMobile ? breakpoints.tablet : 240}&auto=compress`
  }, [previewType, images, rawImage, isMobile])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onUpload,
    accept: mimeType,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    noDrag: !incoming,
    disabled: !allowEdit,
  })

  const [copying, setCopying] = useState(false)
  const copyFullImageUrl = useCallback(() => {
    if (!rawImage?.cdnUrl?.startsWith('http')) {
      return
    }

    setCopying(true)
    copyToClipboard(rawImage?.cdnUrl)
      .catch((err) => {
        console.error(err)
        addNotification('error', err.message)
      })
      .finally(() => {
        setTimeout(() => setCopying(false), 350)
      })
  }, [addNotification, rawImage?.cdnUrl])

  let uploadBody

  if (images.length > 0 && rawImage) {
    uploadBody = (
      <StyledImagePreviewWrapper onClick={copyFullImageUrl}>
        <ImagePreview src={smartUrl} alt="preview" />
        {rawImage?.cdnUrl?.startsWith('http') && (
          <>
            <CopyButtonOverlay />
            <TitleTooltip title={intl.formatMessage({ id: 'new_event.basics.images.copy_full_size' })}>
              <CopyActiveArea>
                {copying ? (
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                ) : (
                  <Svg icon="copy" />
                )}
              </CopyActiveArea>
            </TitleTooltip>
          </>
        )}
      </StyledImagePreviewWrapper>
    )
  } else if (images.length === 0) {
    uploadBody = allowEdit ? (
      <UploadButton data-id="uploadImage" onClick={open}>
        <Svg icon="add" />
        <OnMobile>{intl.formatMessage({ id: 'new_event.basics.images.upload_image' })}</OnMobile>
        <OnDesktop>{intl.formatMessage({ id: 'image_drag_drop.hint' })}</OnDesktop>
      </UploadButton>
    ) : (
      <ImagePreviewWrapper>
        <Svg icon="image-placeholder" />
      </ImagePreviewWrapper>
    )
  } else {
    uploadBody = (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  useEffect(() => {
    if (!images || images.length === 0) {
      setFileName('')
    }
  }, [images])

  return (
    <>
      <FormGroup label={label} help={help} required={required} error={error} focus={incoming || isDragActive}>
        <Uploader {...getRootProps()} error={!!error} focus={incoming || isDragActive}>
          {uploadBody}
          <input {...getInputProps({ name: 'eventImages' })} />
          <UploadInfoPanel error={!!error} focus={incoming || isDragActive}>
            <UploadFileName>{fileName || emptyMessage}</UploadFileName>
            {minSizeLabel && <div>{minSizeLabel}</div>}
            {fileFormatLabel && <div>{fileFormatLabel}</div>}

            {rawImage && allowEdit && <RemoveButton icon="trash" onClick={removeImages} data-id="removeImage" />}
          </UploadInfoPanel>
        </Uploader>
      </FormGroup>
      {!disablePreviews && images && images.length > 0 && (!allowEdit || !error) && (
        <EventCropPreviews noMask={noMask} readOnly={!allowEdit} rawImage={rawImage} images={images} onCrop={onCrop} />
      )}
    </>
  )
}

export default memo(EventImageUploader)
