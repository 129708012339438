import { omit, isEqual, map } from 'lodash/fp'

const omitIgnoredFields = <T extends object>(obj: T | null | undefined) =>
  obj && {
    ...omit(
      [
        'rawImage',
        'rawBrandLogo',
        'addressLocalityId',
        'addressLocalityLat',
        'addressLocalityLon',
        '__fragmentOwner',
        'completedSteps',
        'descriptionDraft' /* we use markdown field 'descruiption instead' */,
        'eventType',
        'attractiveFields',
      ],
      obj
    ),
    attractiveFields: (obj as any)?.attractiveFields && omit(['isEntertainment'], (obj as any)?.attractiveFields),
    ticketTypes: (obj as any)?.ticketTypes && map(omit(['descriptionDraft']), (obj as any).ticketTypes),
  }

export default function hasEventChanged<T extends object>(initialValues?: T | null, values?: T | null): boolean {
  const hasChanged = initialValues !== values && !isEqual(omitIgnoredFields(initialValues), omitIgnoredFields(values))
  // if (hasChanged)
  //   console.log(
  //     'EVENT CHANGED',
  //     JSON.stringify(omitIgnoredFields(initialValues)),
  //     JSON.stringify(omitIgnoredFields(values))
  //   )
  return hasChanged
}
