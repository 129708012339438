import { useCallback, useState } from 'react'
import { useFragment, useMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { usePerformPayoutMutation } from '../../__generated__/usePerformPayoutMutation.graphql'
import { usePerformPayout_event$key } from '../../__generated__/usePerformPayout_event.graphql'

function usePerformPayout(eventKey: usePerformPayout_event$key) {
  const event = useFragment(
    graphql`
      fragment usePerformPayout_event on Event {
        id
        costCurrency
      }
    `,
    eventKey
  )

  const [commitPerformPayout] = useMutation<usePerformPayoutMutation>(graphql`
    mutation usePerformPayoutMutation($input: CreatePayoutInput!) {
      createPayout(input: $input) {
        event {
          ...PayoutsTable_event
          ...EventHealth_event
          ...EventStats_event
          ...usePerformPayout_event
          ...useEventPayout_event
          balance {
            availability
            payouts {
              amount
              status
            }
            payoutsTotal
            amountPromoterOwed
            amountPromoterTotal
          }
        }
      }
    }
  `)

  const [payoutInProgress, setPayoutInProgress] = useState(false)

  const doPerformPayout = useCallback(
    async (amountCents: number) => {
      setPayoutInProgress(true)

      return new Promise<void>((resolve, reject) =>
        commitPerformPayout({
          variables: {
            input: {
              clientMutationId: nanoid(),
              amount: amountCents,
              currency: event.costCurrency || 'GBP',
              eventId: event.id,
              sendRemittanceEmail: true,
            },
          },
          onCompleted(_, errors) {
            setPayoutInProgress(false)

            if ((errors?.length || 0) > 0) {
              reject()
              return
            }

            resolve()
          },
          onError() {
            setPayoutInProgress(false)
            reject()
          },
        })
      )
    },
    [commitPerformPayout, event.costCurrency, event.id]
  )

  return { doPerformPayout, payoutInProgress }
}

export default usePerformPayout
