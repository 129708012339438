/**
 * @generated SignedSource<<13e92136c67f8a06b9aebc9dfa45ddee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StripeAccountIntegrityState = "CHARGES_DISABLED" | "OK" | "PAYOUTS_DISABLED" | "%future added value";
export type StripeProblemBannerQuery$variables = Record<PropertyKey, never>;
export type StripeProblemBannerQuery$data = {
  readonly viewer: {
    readonly account: {
      readonly billingAccountDueDate: string | null;
      readonly isDisabled: boolean | null;
      readonly stripeAccountId: string | null;
      readonly stripeAccountState: StripeAccountIntegrityState;
      readonly stripeAccountType: string | null;
    } | null;
  } | null;
};
export type StripeProblemBannerQuery = {
  response: StripeProblemBannerQuery$data;
  variables: StripeProblemBannerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisabled",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeAccountId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeAccountState",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeAccountType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingAccountDueDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StripeProblemBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StripeProblemBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac377baa12fefd37a0ad869d09ea374a",
    "id": null,
    "metadata": {},
    "name": "StripeProblemBannerQuery",
    "operationKind": "query",
    "text": "query StripeProblemBannerQuery {\n  viewer {\n    account {\n      isDisabled\n      stripeAccountId\n      stripeAccountState\n      stripeAccountType\n      billingAccountDueDate\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf51d6afc79a24911748b781eef72e5f";

export default node;
