import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { commitMutation, Environment } from 'react-relay'

import { ILocale } from '../../../intl'
import { assertServerCompatibleDeep } from '../../../utils/entityStatus'
import {
  MinorUpdateEventInput,
  minorUpdateEventMutation,
} from '../../../__generated__/minorUpdateEventMutation.graphql'
import IEventForm from '../../EventForm/types'
import convertEventMinor from './convertEventMinor'

const MINOR_UPDATE_EVENT_MUTATION = graphql`
  mutation minorUpdateEventMutation($input: MinorUpdateEventInput!) {
    minorUpdateEvent(input: $input) {
      event {
        id
        name
        completedSteps
        state
        statusAsOfNow

        ...EventDetails_event
      }
    }
  }
`

export const minorUpdateEvent = async (
  environment: Environment,
  values: Partial<IEventForm>,
  locale: ILocale
): Promise<void> => {
  if (!values.id) return

  const payload = await convertEventMinor(environment, values, locale)

  await new Promise<void>((resolve, reject) => {
    commitMutation<minorUpdateEventMutation>(environment, {
      mutation: MINOR_UPDATE_EVENT_MUTATION,
      variables: {
        input: assertServerCompatibleDeep({
          ...(payload as Omit<MinorUpdateEventInput, 'clientMutationId'>),
          clientMutationId: nanoid(),
        }),
      },
      onCompleted: (data: minorUpdateEventMutation['response']) => {
        if (data.minorUpdateEvent?.event) {
          resolve()
        } else {
          reject()
        }
      },
      onError: reject,
    })
  })
}
