import { useCallback, useContext } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { commitMutation, useRelayEnvironment } from 'react-relay'
import { useNavigate } from 'react-router'
import { useIntl } from 'react-intl'

import { trackingContext } from '../../../context/tracking'
import { notificationContext } from '../../../context/notification'
import { useCopyEventMutation } from '../../../__generated__/useCopyEventMutation.graphql'

function useCopyEvent(event: { id: string }) {
  const intl = useIntl()
  const navigate = useNavigate()
  const environment = useRelayEnvironment()

  const { trackEvent } = useContext(trackingContext)
  const { addNotification } = useContext(notificationContext)

  const copyEvent = useCallback(() => {
    trackEvent('copy_event_clicked')
    commitMutation<useCopyEventMutation>(environment, {
      mutation: graphql`
        mutation useCopyEventMutation($input: DuplicateEventInput!) {
          duplicateEvent(input: $input) {
            event {
              id
              name
            }
          }
        }
      `,
      variables: {
        input: {
          clientMutationId: event.id,
          id: event.id,
        },
      },
      onCompleted: (data, errors) => {
        if (errors && errors.length > 0) {
          // DO NOTHING, errors already reported
          return
        }
        const eventId = data?.duplicateEvent?.event?.id
        if (eventId) {
          navigate(`/events/${eventId}/edit`)
        }
      },
      onError: () => {
        addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
      },
    })
  }, [trackEvent, environment, event.id, navigate, addNotification, intl])

  return copyEvent
}

export default useCopyEvent
