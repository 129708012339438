/**
 * @generated SignedSource<<0c4eaedb58b01ef20f4475f70821f39e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProductOptionType = "CUSTOM" | "SIZE" | "%future added value";
export type ChangeProductAllocationModalQuery$variables = {
  id: string;
};
export type ChangeProductAllocationModalQuery$data = {
  readonly event: {
    readonly eventIdLive?: string | null;
    readonly id?: string;
    readonly productsSales?: {
      readonly productBreakdown: ReadonlyArray<{
        readonly product: {
          readonly allocation: number;
          readonly category: {
            readonly name: string;
          };
          readonly hasVariants: boolean;
          readonly id: string;
          readonly name: string;
          readonly optionType: ProductOptionType | null;
          readonly variants: ReadonlyArray<{
            readonly allocation: number;
            readonly id: string;
            readonly name: string;
          } | null>;
        };
        readonly productId: string;
        readonly totalAppSold: number;
        readonly totalSold: number;
        readonly variantBreakdown: ReadonlyArray<{
          readonly totalSold: number;
          readonly variant: {
            readonly allocation: number;
            readonly id: string;
            readonly name: string;
            readonly product: {
              readonly id: string;
              readonly name: string;
            };
          } | null;
          readonly variantId: string;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};
export type ChangeProductAllocationModalQuery = {
  response: ChangeProductAllocationModalQuery$data;
  variables: ChangeProductAllocationModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventIdLive",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVariants",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "optionType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Variant",
  "kind": "LinkedField",
  "name": "variants",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSold",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "VariantBreakdownItem",
  "kind": "LinkedField",
  "name": "variantBreakdown",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Variant",
      "kind": "LinkedField",
      "name": "variant",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAppSold",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeProductAllocationModalQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductsSales",
                "kind": "LinkedField",
                "name": "productsSales",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductBreakdownItem",
                    "kind": "LinkedField",
                    "name": "productBreakdown",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Category",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeProductAllocationModalQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductsSales",
                "kind": "LinkedField",
                "name": "productsSales",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductBreakdownItem",
                    "kind": "LinkedField",
                    "name": "productBreakdown",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Category",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08118fe0988fb3aa2abde75f84be1de0",
    "id": null,
    "metadata": {},
    "name": "ChangeProductAllocationModalQuery",
    "operationKind": "query",
    "text": "query ChangeProductAllocationModalQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ... on Event {\n      id\n      eventIdLive\n      productsSales {\n        productBreakdown {\n          productId\n          product {\n            id\n            name\n            category {\n              name\n              id\n            }\n            allocation\n            hasVariants\n            optionType\n            variants {\n              id\n              name\n              allocation\n            }\n          }\n          variantBreakdown {\n            variantId\n            variant {\n              id\n              name\n              allocation\n              product {\n                id\n                name\n              }\n            }\n            totalSold\n          }\n          totalAppSold\n          totalSold\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a4611b2c3b5827b68fcacb0e5b329ab";

export default node;
