import React, { FC, useMemo } from 'react'
import { toLower, isInteger } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import FormGroup from './FormGroup'
import EventCardValue from './Event/EventCardValue'
import ProgressBar from './ProgressBar'
import { textStyle } from '../utils/typography'
import { color } from '../utils/variables'

interface IProps {
  sold?: number
  limit?: number | null
  label?: string
}

const FormSalesProgress: FC<IProps> = ({ sold = 0, limit, label }) => {
  const intl = useIntl()

  const isLimit = !!limit && isInteger(limit)

  const soldPercentage = useMemo(() => {
    const percent = ((sold || 0) / (limit || 0)) * 100
    return percent > 100 ? 100 : percent
  }, [limit, sold])

  const gradientColor = soldPercentage === 100 ? 'secondary' : 'primary'

  return (
    <div>
      <FormGroup label={intl.formatMessage({ id: label ? label : 'current_status' })} />
      <EventCardValue
        primary={
          <HidingSpan hide={!isLimit}>
            <ProgressBar size="small" color={gradientColor} gradient value={soldPercentage ?? 0} />
          </HidingSpan>
        }
        secondary={
          <ProgressBarValues move={!isLimit}>
            <div>
              <SoldValue>{sold}</SoldValue>
              <AllocationValue secondary={!isLimit}>
                {isLimit ? ` / ${limit}` : ` ${toLower(intl.formatMessage({ id: 'sold' }))}`}
              </AllocationValue>
            </div>
            <HidingSpan hide={!isLimit}>
              {isLimit ? (
                <>
                  {(limit || 0) - sold} {intl.formatMessage({ id: 'remaining' })}
                </>
              ) : null}
            </HidingSpan>
          </ProgressBarValues>
        }
      />
    </div>
  )
}

export default FormSalesProgress

const ProgressBarValues = styled.div<{ move?: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${textStyle.functional.sm}
  margin-top: 14px;
  margin-bottom: -6px;

  top: 0px;
  transition: top 200ms;

  ${({ move }) =>
    move &&
    `
      top: -12px;
  `}
`

const SoldValue = styled.span`
  ${textStyle.numeric.md}
  line-height: inherit;
  color: ${color.text};
`

const AllocationValue = styled.span<{ secondary?: boolean }>`
  ${textStyle.functional.sm}
  color: ${({ secondary }) => (secondary ? color.darkgrey : color.text)};
  margin-left: 2px;
`

const HidingSpan = styled.span<{ hide?: boolean }>`
  opacity: 1;

  ${({ hide }) =>
    hide &&
    `
      opacity: 0;
  `}
`
