import { concat } from 'lodash/fp'
import { useEffect, useRef, useState } from 'react'

function useFileDragIncoming(mimeTypesRaw?: string | string[] | null) {
  const mimeTypes = mimeTypesRaw && concat(mimeTypesRaw, [])
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [incoming, setIncoming] = useState(false)
  useEffect(() => {
    const endLsnr = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      if (incoming) setIncoming(false)
    }
    document.addEventListener('drop', endLsnr, { passive: true })

    const lsnr = (e: DragEvent) => {
      const hasItems = (e.dataTransfer?.items?.length || 0) > 0
      const hasFiles = (e.dataTransfer?.files?.length || 0) > 0
      const hasTypes = (e.dataTransfer?.types?.length || 0) > 0
      const hasSomething = hasItems || hasFiles || hasTypes

      if (
        !hasSomething ||
        (e.dataTransfer?.items.length === 1 && (!mimeTypes || mimeTypes.indexOf(e.dataTransfer?.items[0].type) >= 0)) ||
        (e.dataTransfer?.types.length === 1 &&
          (!mimeTypes || mimeTypes.indexOf(e.dataTransfer?.types[0]) >= 0 || e.dataTransfer?.types[0] === 'Files')) ||
        (e.dataTransfer?.files.length === 1 && (!mimeTypes || mimeTypes.indexOf(e.dataTransfer?.files[0].type) >= 0))
      ) {
        if (!incoming) setIncoming(true)
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(endLsnr, 300)
      }
    }
    document.addEventListener('dragover', lsnr, { passive: true })

    return () => {
      document.removeEventListener('dragover', lsnr)
      document.removeEventListener('drop', endLsnr)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [incoming, mimeTypes])

  return incoming
}

export default useFileDragIncoming
