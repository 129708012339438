import React, { FC, memo, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { textStyle } from '../utils/typography'
import { color } from '../utils/variables'
import CopyButton from './CopyButton'
import IconButton from './IconButton'
import { Loader } from './Loader'

const Field = styled.div`
  ${textStyle.functional.md}

  & > small {
    display: block;
    ${textStyle.functional.sm}
    color: ${color.darkgrey};
    margin-bottom: 6px;
    word-break: break-word;
  }
`

const Framed = styled.div`
  border: 1px solid ${color.grey};
  border-radius: 4px;

  ${textStyle.label.regular}

  padding: 1px 4px 1px 8px;

  display: flex;
  justify-content: space-between;

  & > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    user-select: none;

    padding: 3px 0;
  }

  ${IconButton} {
    width: 20px;
    height: 20px;
  }

  ${Loader} {
    width: 12px;
    height: 12px;
  }

  .icon-button_container {
    color: #6d6d6d;
  }
`

interface IProps {
  label?: ReactNode
  value?: string | null
  fallback?: ReactNode
  forceFramed?: boolean
  className?: string
}

const InfoField: FC<IProps> = ({ label, value, className, fallback, forceFramed }) => {
  const intl = useIntl()

  return (
    <Field className={className}>
      <small>{label}</small>
      {value || forceFramed ? (
        <Framed>
          <span>{value || intl.formatMessage({ id: 'na' })}</span>
          {value && (
            <CopyButton
              icon="link"
              title={intl.formatMessage({ id: 'actions.copy' })}
              titleSuccess={intl.formatMessage({ id: 'action.copy_success' })}
              link={value}
            />
          )}
        </Framed>
      ) : (
        fallback || <div>{intl.formatMessage({ id: 'value_not_set' })}</div>
      )}
    </Field>
  )
}

export default styled(memo(InfoField))``
