import React, { FC, useCallback } from 'react'
import styled from 'styled-components/macro'

import { mediaQuery, color } from '../../../../utils/variables'

const QuickActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  right: 8px;
  top: 50%;
  margin-left: auto;
  color: ${color.text};
  > * {
    margin-left: 8px;
  }

  &.-floatOnMobile {
    ${mediaQuery.lessThan('desktop')`
      position: absolute;
      top: 16px;
      right: 16px;
      margin: 0;
    `}
  }
`

interface IProps {
  children?: any
  onClick?: (e: Event) => void
  mobileBehaviour?: 'none' | 'absolute'
}

export const EventListItemControlWrapper: FC<React.PropsWithChildren<IProps>> = ({
  children,
  onClick,
  mobileBehaviour = 'absolute',
}) => {
  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (onClick) onClick(e)
    },
    [onClick]
  )

  return (
    <QuickActionsWrapper
      onClick={handleClick}
      className={mobileBehaviour === 'absolute' ? '-floatOnMobile' : undefined}
    >
      {children}
    </QuickActionsWrapper>
  )
}
