import React, { FC, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import qs from 'qs'

import { notificationContext } from '../../../context/notification'

import FormField from '../../../components/FormField'
import Button from '../../../components/Button'
import Svg from '../../../components/Svg'
import { Form, FormRow } from '../../../components/Form'

import REQUESTS from '../../../utils/requests'

import { AuthFormHeader, AuthForm, AuthFormSuccess, AuthFormWrapper } from '../Auth'
import { trackingContext } from '../../../context/tracking'

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(2).required(),
})

const ResetPassword: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const { token } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)

  const formik = useFormik({
    initialValues: {
      password: '',
      submitted: false,
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async ({ password }, { setFieldValue }) => {
      if (!token) return

      const { status, description } = await REQUESTS.RESET_PASSWORD({ token: token as string, password })
      if (status && status === 201) {
        setFieldValue('submitted', true)
      } else if (description) {
        addNotification('error', description)
      }
    },
  })

  const trackLogin = useCallback(() => trackEvent('login_started', { source_name: 'forgot_password' }), [trackEvent])

  const { values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting } = formik

  return (
    <AuthFormWrapper>
      {values.submitted ? (
        <>
          <AuthFormSuccess>
            <Svg icon="check" />
            <p>{intl.formatMessage({ id: 'auth.password_set_success' })}</p>
          </AuthFormSuccess>
          <Form>
            <FormRow>
              <Button preset="link" color="primary" to="/auth/login" onClick={trackLogin}>
                {intl.formatMessage({ id: 'auth.proceed_to_signin' })}
              </Button>
            </FormRow>
          </Form>
        </>
      ) : (
        <>
          <AuthFormHeader
            title={intl.formatMessage({ id: 'auth.set_new_password' })}
            description={intl.formatMessage({ id: 'auth.description' })}
            backLink
          />
          <AuthForm onSubmit={handleSubmit}>
            <Form>
              <FormRow>
                <FormField
                  name="password"
                  control="newpassword"
                  label={intl.formatMessage({ id: 'auth.password' })}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && errors.password}
                  hint={intl.formatMessage({ id: 'account.password_hint' })}
                />
              </FormRow>
              <FormRow>
                <Button block type="submit" onClick={handleSubmit} loading={isSubmitting} disabled={isSubmitting}>
                  {intl.formatMessage({ id: 'auth.reset_password' })}
                </Button>
              </FormRow>
            </Form>
          </AuthForm>
        </>
      )}
    </AuthFormWrapper>
  )
}

export default ResetPassword
