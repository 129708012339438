import React, { FC, createContext, useMemo, useContext } from 'react'
import { set } from 'lodash/fp'
import { useNavigate } from 'react-router'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { clearAuthToken } from '../../utils/api'
import { IAuthContext } from './util/types'
import STUB_CONTEXT from './util/stub'
import useImpersonation from './hooks/useImpersonation'
import useSwitchAccount from './hooks/useSwitchAccount'
import usePermissions from './hooks/usePermissions'
import useUserInfo from './hooks/useUserInfo'
import { auth_viewer$key } from '../../__generated__/auth_viewer.graphql'

import buildHasPermission from './util/permissions'
import { toPermissions } from './util/convert'

export const authContext = createContext<IAuthContext>(STUB_CONTEXT)

interface IProps {
  viewer: auth_viewer$key
  onUserChange?: () => void
}

const AuthProvider: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey, children, onUserChange }) => {
  const navigate = useNavigate()

  const viewer = useFragment(
    graphql`
      fragment auth_viewer on Viewer {
        ...useUserInfo_viewer
        ...useSwitchAccount_viewer
        ...useImpersonation_viewer
        ...usePermissions_viewer
      }
    `,
    viewerKey
  )

  const { user, account } = useUserInfo(viewer)
  const hasPermission = usePermissions(viewer)

  const { impersonateAs, exitImpersonation, isImpersonated } = useImpersonation(viewer, onUserChange)
  const canImpersonate = user.diceStaff && hasPermission('impersonate:account')

  const { switchAccount, availableAccounts } = useSwitchAccount(viewer, onUserChange)
  const canSwitch = !user.diceStaff

  const ctxValue = useMemo(() => {
    const logOut = () => {
      clearAuthToken()
      navigate('/auth/login')
      if (onUserChange) onUserChange()
    }

    return {
      user,
      account,
      logOut,

      hasPermission,

      switchAccount: canSwitch ? switchAccount : undefined,
      availableAccounts,

      impersonateAs: canImpersonate ? impersonateAs : undefined,
      exitImpersonation,
      isImpersonated,
    }
  }, [
    account,
    availableAccounts,
    canImpersonate,
    canSwitch,
    exitImpersonation,
    hasPermission,
    impersonateAs,
    isImpersonated,
    navigate,
    onUserChange,
    switchAccount,
    user,
  ])

  return <authContext.Provider value={ctxValue}>{children}</authContext.Provider>
}

interface IPermissionOverrideProps {
  permissions: null | ReadonlyArray<string | null>
}

export const PermissionOverride: FC<React.PropsWithChildren<IPermissionOverrideProps>> = ({
  permissions,
  children,
}) => {
  const parentValue = useContext(authContext)

  const overridenValue = useMemo(
    () => set('hasPermission', buildHasPermission(toPermissions({ permissions })), parentValue),
    [parentValue, permissions]
  )

  return <authContext.Provider value={overridenValue}>{children}</authContext.Provider>
}

export default AuthProvider
