import React, { FC, memo, useCallback, useState, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay'
import { Link } from 'react-router-dom'

import { color, font, mediaQuery } from '../../utils/variables'
import Svg from '../../components/Svg'
import { Text, TextCenter } from '../../components/Text'
import { LoaderContainer } from '../../components/Loader'
import HugeButton from '../../components/HugeButton'
import { NewUserDashboardQuery } from '../../__generated__/NewUserDashboardQuery.graphql'
import Button from '../../components/Button'
import { PageHeader, PageTitle } from '../../components/Page'
import { localeContext } from '../../context/locale'

const SLink = styled(Link)`
  color: ${color.primary};
`

const SButton = styled(Button)`
  && {
    margin-top: -2px;
  }

  strong {
    color: ${color.primary};
  }
`

const Container = styled(LoaderContainer)`
  padding: 32px;
  ${mediaQuery.lessThan('tablet')`
    padding: 16px;
  `}

  ${TextCenter} {
    display: block;
    width: 100%;
    max-width: 900px;
  }
`

const Title = styled.div`
  font-size: ${font.size.xlg}px;
  font-weight: 300;
  line-height: 110%;
  text-align: center;
  margin: 0 0 8px;
`

const SubTitle = styled.div<{ fontSize?: keyof typeof font.size; spacing?: string }>`
  text-align: center;
  font-size: ${({ fontSize }) => (fontSize ? font.size[fontSize] : font.size.base)}px;
  margin: ${({ spacing }) => spacing || '0'};
  line-height: 1.4em;
`

const OnboardingIcon = styled(Svg)`
  width: 73px;
  height: 100px;
  margin-bottom: 32px;
  ${mediaQuery.lessThan('tablet')`
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
  `}
`

const Flow = styled.ul`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 40px;
  margin: 32px 0 64px;
  width: 100%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    top: 23px;
    background: ${color.lightgrey};
  }
  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
    align-items: center;
    margin: 16px 0 0;
    &:before {
      display: none;
    }
  `}
`

const FlowItem = styled.li`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  span {
    display: block;
    position: absolute;
    width: 120px;
    top: 100%;
  }
  ${mediaQuery.lessThan('tablet')`
    flex-direction: row;
    margin: 6px 0;
    span {
      position: relative;
      font-size: ${font.size.sm}px;
      top: auto;
      width: auto;
    }
  `}
`

const FlowItemIcon = styled.div`
  display: block;
  background: ${color.white};
  padding: 0 16px;
  svg {
    width: 48px;
    height: 48px;
  }
  ${mediaQuery.lessThan('tablet')`
    padding: 0;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  `}
`

const FixedButton = styled(HugeButton)`
  width: 340px;

  ${mediaQuery.lessThan('tablet')`
    width: 100%;
  `}
`

const NewUserDashboard: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const helpLink = useMemo(() => {
    switch (locale) {
      case 'fr':
        return '/support?article=619f9de3efc78d0553e5db6e'
      case 'it':
        return '/support?article=619f9e5d64e42a671b63a7ba'
      case 'es':
        return '/support?article=619f9d76d3efbe495c3b2b97'
      case 'pt':
        return '/support?article=619f9e9aefc78d0553e5db74'
      case 'de': // TODO: own link
      default:
        return '/support?article=619f9cac2b380503dfe08766'
    }
  }, [locale])

  const [skippedFinancialData, setSkippedFinancialData] = useState(false)
  const doSkipFinance = useCallback(() => setSkippedFinancialData(true), [])

  const { viewer } = useLazyLoadQuery<NewUserDashboardQuery>(
    graphql`
      query NewUserDashboardQuery {
        viewer {
          account {
            financialDataProvided
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' }
  )

  const financialDataProvided = !!viewer?.account?.financialDataProvided
  const step = !financialDataProvided && !skippedFinancialData ? 'financial' : 'event'

  const hintKey =
    step === 'financial'
      ? 'new_event.onboarding.skip_financial_info_hint'
      : 'new_event.onboarding.select_type_restricted_hint_new'

  const hintCTA = useMemo(
    () =>
      // prettier-ignore
      step === 'financial'
        ? (str: string) => (
          <SButton preset="link" onClick={doSkipFinance} data-id="skipFinance">
            <strong>{str}</strong>
          </SButton>
        )
        : (str: string) => (
          <SLink to={helpLink} data-id="streamingArticle">
            <strong>{str}</strong>
          </SLink>
        ),
    [doSkipFinance, helpLink, step]
  )

  return (
    <>
      <PageHeader>
        <PageTitle>{intl.formatMessage({ id: 'dashboard.title' })}</PageTitle>
      </PageHeader>
      <Container>
        <TextCenter>
          <OnboardingIcon icon="fan" />
          <Title>{intl.formatMessage({ id: 'new_event.onboarding.title' })}</Title>
          <SubTitle>{intl.formatMessage({ id: 'new_event.onboarding.subtitle' })}</SubTitle>
          <Flow>
            <FlowItem>
              <FlowItemIcon>
                <Svg icon="onboarding_card" />
              </FlowItemIcon>
              <span>{intl.formatMessage({ id: 'new_event.onboarding.step0' })}</span>
            </FlowItem>
            <FlowItem>
              <FlowItemIcon>
                <Svg icon="onboarding_calendar" />
              </FlowItemIcon>
              <span>{intl.formatMessage({ id: 'new_event.onboarding.step1' })}</span>
            </FlowItem>
            <FlowItem>
              <FlowItemIcon>
                <Svg icon="onboarding_badge" />
              </FlowItemIcon>
              <span>{intl.formatMessage({ id: 'new_event.onboarding.step2' })}</span>
            </FlowItem>
            <FlowItem>
              <FlowItemIcon>
                <Svg icon="onboarding_graph" />
              </FlowItemIcon>
              <span>{intl.formatMessage({ id: 'new_event.onboarding.step3' })}</span>
            </FlowItem>
            <FlowItem>
              <FlowItemIcon>
                <Svg icon="onboarding_cash" />
              </FlowItemIcon>
              <span>{intl.formatMessage({ id: 'new_event.onboarding.step4' })}</span>
            </FlowItem>
          </Flow>
        </TextCenter>

        <SubTitle fontSize="lg" spacing="0 0 8px">
          {intl.formatMessage({ id: 'new_event.onboarding.next_title' })}
        </SubTitle>

        {step === 'financial' ? (
          <FixedButton
            data-id="financialInfo"
            icon="onboarding_card"
            title={intl.formatMessage({ id: 'new_event.onboarding.financial_info.title' })}
            subtitle={intl.formatMessage({ id: 'new_event.onboarding.financial_info.subtitle' })}
            to="/account"
          />
        ) : (
          <FixedButton
            data-id="createEvent"
            icon="onboarding_calendar"
            title={intl.formatMessage({ id: 'new_event.onboarding.create_event.title' })}
            subtitle={intl.formatMessage({ id: 'new_event.onboarding.create_event.subtitle' })}
            to="/events/new?dashboard=yes"
          />
        )}

        <SubTitle spacing="8px 0 0 0">
          <Text fontSize="sm">{intl.formatMessage({ id: hintKey }, { a: hintCTA })}</Text>
        </SubTitle>
      </Container>
    </>
  )
}

export default memo(NewUserDashboard)
