import { compact, find } from 'lodash/fp'

import { EventCostCurrency, UserSource } from '../../../enums.generated'
import { ILocale } from '../../../intl'
import { getAlpha2ByName, getCurrencyByCountryCode } from '../../../utils/countries'
import { IAccount, IUser } from './types'

const getDefaultCurrency = (
  userInfo?: {
    account: null | { addressCountry: string | null; countryCode: string | null }
    eventsCurrencies: null | ReadonlyArray<null | EventCostCurrency>
  },
  locale?: ILocale
): EventCostCurrency => {
  const countryCode = userInfo?.account?.countryCode || getAlpha2ByName(userInfo?.account?.addressCountry, locale)

  let currency = countryCode ? getCurrencyByCountryCode(countryCode) : null

  const hasGBPEvents = !!find((c) => c === 'GBP', userInfo?.eventsCurrencies || [])

  if (!currency && !hasGBPEvents) {
    currency = (userInfo?.eventsCurrencies || [])[0] || null
  }

  return currency || 'GBP'
}

export const toUser = (
  userInfo?: {
    id: string
    name: string | null
    email: string | null
    timezoneName: string | null
    source: UserSource | null

    diceStaff: boolean | null
    dicePartner: boolean | null

    account: null | { addressCountry: string | null; countryCode: string | null }
    eventsCurrencies: null | ReadonlyArray<null | EventCostCurrency>
    events: null | { edges: null | ReadonlyArray<unknown> }

    mioRedesignV2: boolean | null
  },
  locale?: ILocale
): IUser => ({
  id: userInfo?.id,
  name: userInfo?.name || undefined,
  email: userInfo?.email || undefined,
  diceStaff: !!userInfo?.diceStaff,
  dicePartner: !!userInfo?.dicePartner,
  hasEvent: (userInfo?.events?.edges?.length || 0) > 0,
  timezoneName: userInfo?.timezoneName || null,

  canDoEvents: {
    live: !!userInfo?.diceStaff || userInfo?.source !== 'SELF_SERVICE',
    stream: !!userInfo?.diceStaff,
  },

  eventsCurrencies: [...compact(userInfo?.eventsCurrencies || [])].sort(),
  defaultCurrency: getDefaultCurrency(userInfo, locale),
  mioRedesignV2: !!userInfo?.mioRedesignV2,
})

export const toPermissions = (userInfo: { permissions: ReadonlyArray<string | null> | null }) =>
  new Set(compact(userInfo?.permissions || []))

export const toAccount = (
  userInfo?: {
    account: null | {
      id: string
      name: string | null

      countryCode: string | null
      addressCountry: string | null

      allowSkipReview: boolean | null
      extrasEnabled: boolean | null
      merchEnabled: boolean | null
      isDisabled: boolean | null
      automaticRollingPaymentsEnabled: boolean | null
      forbidSelfPayouts: boolean | null
    }
  },
  locale?: ILocale
): undefined | IAccount =>
  // prettier-ignore
  userInfo?.account
    ? {
      id: userInfo.account.id,
      name: userInfo.account.name || '',
      allowSkipReview: !!userInfo.account.allowSkipReview,
      extrasEnabled: !!userInfo.account.extrasEnabled,
      merchEnabled: !!userInfo.account.merchEnabled,
      isDisabled: !!userInfo.account.isDisabled,
      countryCode:
        userInfo.account.countryCode || getAlpha2ByName(userInfo.account.addressCountry || null, locale) || null,
      forbidSelfPayouts: !!userInfo.account.automaticRollingPaymentsEnabled || !!userInfo.account.forbidSelfPayouts,
    }
    : undefined
