import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import FormHeader from '../../../components/FormHeader'
import { breakpoints, color } from '../../../utils/variables'
import { Form, FormRow } from '../../../components/Form'
import Button from '../../../components/Button'
import usePreviewEventOnDiceCallback from '../../../utils/hooks/usePreviewEventOnDiceCallback'
import useLazyEventPreviewToken from '../../../utils/hooks/useLazyEventPreviewToken'


const SForm = styled(Form)`
  padding-top: 16px;
`

const Container = styled.div``

const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: ${color.text};
  left: 0;
  transform: translateY(-32px);
`

interface IProps {
  eventId: string
}

const EventPreview: FC<React.PropsWithChildren<IProps>> = ({ eventId }) => {
  const intl = useIntl()
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })
  const { previewToken, loadPreviewToken, loadingPreviewToken } = useLazyEventPreviewToken(eventId, true)
  const [isOpeningPreview, setIsOpeningPreview] = useState(false)

  const previewEventOnDice = usePreviewEventOnDiceCallback(previewToken)

  const fetchPreviewTokenAndOpenPreview = useCallback(() => {
    loadPreviewToken()
    setIsOpeningPreview(true)
  }, [loadPreviewToken])

  useEffect(() => {
    if(!loadingPreviewToken && isOpeningPreview) {
      previewEventOnDice()
      setIsOpeningPreview(false)
    }

  }, [loadingPreviewToken, isOpeningPreview, previewEventOnDice])

  return (
    <Container>
      <Line />
      <SForm spacing={isMobile ? 'default' : 'extra'}>
        <FormHeader
          header={intl.formatMessage({ id: 'new_event.preview_section.header' })}
          subheader={intl.formatMessage({ id: 'new_event.preview_section.sub_header' })}
        />
        <FormRow>
          <Button onClick={fetchPreviewTokenAndOpenPreview} 
            disabled={!previewToken || loadingPreviewToken} preset="secondary">
            {intl.formatMessage({ id: 'event_list.quick_actions.preview_event_on_dice' })}
          </Button>
        </FormRow>
      </SForm>
    </Container>
  )
}

export default EventPreview
