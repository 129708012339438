/**
 * @generated SignedSource<<e95c37134124ba7823ca3e96e078ad35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DistanceUnits = "kilometers" | "miles" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type MediaItemTypes = "appleMusicTrack" | "spotifyArtist" | "spotifyTrack" | "trailer" | "%future added value";
export type ShowArtistDescription = "CUSTOM" | "DICE" | "NONE" | "%future added value";
export type TargetingMode = "country" | "location" | "worldwide" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Information_event$data = {
  readonly additionalInfos: ReadonlyArray<{
    readonly content: string | null;
    readonly ctaLabel: string | null;
    readonly ctaLink: string | null;
    readonly id: string;
    readonly includeOnPurchaseEmail: boolean | null;
    readonly includeOnReminderEmail: boolean | null;
  } | null> | null;
  readonly addressCountry: string | null;
  readonly addressLocality: string | null;
  readonly ageLimit: string | null;
  readonly allowedLifecycleUpdates: {
    readonly ageLimit: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly description: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly media: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly presentedBy: {
      readonly canUpdate: boolean | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly countryCode: string | null;
  readonly date: string | null;
  readonly description: string | null;
  readonly endDate: string | null;
  readonly eventArtists: ReadonlyArray<{
    readonly artist: {
      readonly description: string | null;
      readonly media: ReadonlyArray<{
        readonly id: string;
        readonly type: MediaItemTypes;
        readonly values: IBackendMap | null;
      } | null>;
    } | null;
    readonly description: string | null;
  } | null> | null;
  readonly eventImages: ReadonlyArray<{
    readonly attachment: {
      readonly id: string;
    } | null;
    readonly cdnUrl: string;
    readonly cropRegion: {
      readonly height: number | null;
      readonly width: number | null;
      readonly x: number | null;
      readonly y: number | null;
    } | null;
    readonly id: string;
    readonly type: string | null;
  } | null> | null;
  readonly eventRules: {
    readonly covidPcr: boolean | null;
    readonly covidPcrValidHours: number | null;
    readonly covidPolicyUrl: string | null;
    readonly covidRecovery: boolean | null;
    readonly covidVaccination: boolean | null;
    readonly maskRequired: boolean | null;
    readonly proofOfBeingHealthy: boolean | null;
    readonly socialDistancing: boolean | null;
  } | null;
  readonly eventType: EventType | null;
  readonly faqs: ReadonlyArray<{
    readonly body: string | null;
    readonly id: string;
    readonly order: number;
    readonly title: string | null;
  } | null> | null;
  readonly featuredAreas: ReadonlyArray<{
    readonly countryCode: string | null;
    readonly description: string | null;
    readonly endDate: string;
    readonly id: string;
    readonly locationLat: number | null;
    readonly locationLng: number | null;
    readonly locationRadius: number | null;
    readonly locationString: string | null;
    readonly locationUnits: DistanceUnits | null;
    readonly mode: TargetingMode;
    readonly startDate: string;
    readonly weight: number;
  } | null> | null;
  readonly flags: {
    readonly alcoholFree: IBackendMap | null;
    readonly disableDayOfEventComms: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly media: ReadonlyArray<{
    readonly id: string;
    readonly type: MediaItemTypes;
    readonly values: IBackendMap | null;
  } | null> | null;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly presentedBy: string | null;
  readonly showArtistDescription: ShowArtistDescription | null;
  readonly state: EventState | null;
  readonly " $fragmentType": "Information_event";
};
export type Information_event$key = {
  readonly " $data"?: Information_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"Information_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canUpdate",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MediaItem",
  "kind": "LinkedField",
  "name": "media",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "values",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Information_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "media",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "presentedBy",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "ageLimit",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventArtist",
      "kind": "LinkedField",
      "name": "eventArtists",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Artist",
          "kind": "LinkedField",
          "name": "artist",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeaturedArea",
      "kind": "LinkedField",
      "name": "featuredAreas",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationString",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationRadius",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationUnits",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationLat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationLng",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weight",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableDayOfEventComms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alcoholFree",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventRules",
      "kind": "LinkedField",
      "name": "eventRules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidPcr",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidPcrValidHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidRecovery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidVaccination",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maskRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "proofOfBeingHealthy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "socialDistancing",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "covidPolicyUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventImage",
      "kind": "LinkedField",
      "name": "eventImages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cdnUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "attachment",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CropRegion",
          "kind": "LinkedField",
          "name": "cropRegion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "x",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "y",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presentedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ageLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Faq",
      "kind": "LinkedField",
      "name": "faqs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventAdditionalInfo",
      "kind": "LinkedField",
      "name": "additionalInfos",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeOnPurchaseEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeOnReminderEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaLink",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressCountry",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressLocality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showArtistDescription",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "5cbafa23bd4110812d67dffd9fc9ee23";

export default node;
