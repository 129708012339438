import { useCallback, useContext, useMemo } from 'react'
import {
  addHours,
  addSeconds,
  differenceInHours,
  formatDuration,
  formatISO,
  intervalToDuration,
  isAfter,
  parseISO,
} from 'date-fns'
import { useFormikContext } from 'formik'
import { find, rangeStep } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { dateFnsLocales } from '../../../intl'
import IEventFormTickets from '../types/Tickets'
import { localeContext } from '../../../context/locale'

const useDiceStream = () => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { values, setFieldValue } = useFormikContext<IEventFormTickets>()

  const onChangeStreamRewatchEnabled = useCallback(() => {
    if (!!values.diceStreamRewatchEnabledUntil) {
      setFieldValue('diceStreamRewatchEnabledUntil', null)
    } else {
      const date = values.date ? parseISO(values.date) : new Date()
      setFieldValue(
        'diceStreamRewatchEnabledUntil',
        formatISO(addHours(addSeconds(date, values.diceStreamDuration || 0), 24))
      )
    }
  }, [values, setFieldValue])

  const diceStreamDurationOptions: { value: string; label: string }[] = useMemo(
    () =>
      rangeStep(900, 900, 43201).map((i) => ({
        value: i,
        label: formatDuration(intervalToDuration({ start: 0, end: i * 1000 }), {
          format: ['hours', 'minutes'],
          locale: dateFnsLocales[locale],
        }),
      })) as any,
    [locale]
  )

  const diceStreamDurationOption = useMemo(
    () => find(['value', values.diceStreamDuration], diceStreamDurationOptions),
    [diceStreamDurationOptions, values.diceStreamDuration]
  )

  const diceStreamRewatchDurationHint: string = useMemo(() => {
    if (!values.date || !values.diceStreamRewatchEnabledUntil || !values.diceStreamDuration) {
      return ''
    }

    const date = parseISO(values.date)
    const diceStreamRewatchEnabledUntil = parseISO(values.diceStreamRewatchEnabledUntil)
    const diceStreamEnd = addSeconds(date, values.diceStreamDuration)

    if (!isAfter(diceStreamRewatchEnabledUntil, diceStreamEnd)) {
      return ''
    }

    const duration = intervalToDuration({ start: diceStreamEnd, end: diceStreamRewatchEnabledUntil })
    // Display values up to 3 days using hours
    if (differenceInHours(diceStreamRewatchEnabledUntil, diceStreamEnd) <= 72) {
      duration.hours = (duration.hours || 0) + (duration.days || 0) * 24
      duration.days = 0
    }
    return intl.formatMessage(
      { id: 'new_event.tickets.dice_stream_rewatch_enabled_until.hint' },
      { duration: formatDuration(duration, { locale: dateFnsLocales[locale] }) }
    )
  }, [intl, locale, values])

  const diceStreamDVRError = useMemo(
    () =>
      (values.diceStreamDuration || 0) > 4 * 3600 &&
      intl.formatMessage({ id: 'new_event.tickets.dice_stream_dvr_enabled.duration_error' }),
    [values.diceStreamDuration, intl]
  )

  const setDiceStreamDuration = useCallback(
    (value: any) => {
      if (values.diceStreamRewatchEnabledUntil) {
        const diceStreamRewatchEnabledUntil = parseISO(values.diceStreamRewatchEnabledUntil)
        setFieldValue(
          'diceStreamRewatchEnabledUntil',
          formatISO(addSeconds(diceStreamRewatchEnabledUntil, value - (values.diceStreamDuration || 0)))
        )
      }
      setFieldValue('diceStreamDuration', value)
    },
    [values, setFieldValue]
  )

  return {
    onChangeStreamRewatchEnabled,
    diceStreamDurationOptions,
    diceStreamDurationOption,
    setDiceStreamDuration,
    diceStreamDVRError,
    diceStreamRewatchDurationHint,
  }
}

export default useDiceStream
