import React, { FC, ComponentProps, memo } from 'react'
import styled from 'styled-components/macro'

import { zIndex } from '../utils/variables'

import NotificationMessage from './NotificationMessage'

const NotificationsWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: ${zIndex.notifications};
`

const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 26px 32px;
  &:empty {
    display: none;
  }
`

interface IProps {
  notifications: Array<Omit<ComponentProps<typeof NotificationMessage>, 'onRemove' | 'shouldRemove'>>
  removeNotification: (id: string) => void
}

const Notifications: FC<React.PropsWithChildren<IProps>> = ({ notifications, removeNotification }) => {
  return (
    <NotificationsWrapper>
      <NotificationList>
        {notifications.map((item) => (
          <NotificationMessage key={item.id} {...item} shouldRemove onRemove={removeNotification} />
        ))}
      </NotificationList>
    </NotificationsWrapper>
  )
}

export default memo(Notifications)
