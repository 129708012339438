/**
 * @generated SignedSource<<315a83712e3421f0ca8861450249b770>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventTicketTypeTimeline_event$data = {
  readonly addressCountry: string | null;
  readonly countryCode: string | null;
  readonly eventType: EventType | null;
  readonly id: string;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly archived: boolean | null;
    readonly hidden: boolean;
    readonly id: string;
    readonly name: string | null;
    readonly offSaleDate: string | null;
    readonly onSaleDate: string | null;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly " $fragmentType": "EventTicketTypeTimeline_event";
};
export type EventTicketTypeTimeline_event$key = {
  readonly " $data"?: EventTicketTypeTimeline_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventTicketTypeTimeline_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onSaleDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offSaleDate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventTicketTypeTimeline_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "doorSalesOnly",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "includeArchived",
          "value": true
        }
      ],
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archived",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "9446fa6f49f6988d11b15195b91d24a5";

export default node;
