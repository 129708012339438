import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { mediaQuery, color, zIndex, spacing } from '../utils/variables'

export const Page = styled.div`
  display: flex;
  position: relative;
  min-height: 100%;
  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
  `}
`

export const PageContainer = styled.div<{ ICEenabled?: boolean }>`
  flex-grow: 1;
  padding-left: 242px;
  width: 100%;
  transition-delay: 0.3s;
  transition: padding-left 0.3s cubic-bezier(0.8, 0, 0.3, 1);

  /* Phrase In-Context Editor */
  ${({ ICEenabled }) =>
    ICEenabled &&
    `
    padding-bottom: 52px;
  `}

  ${mediaQuery.greaterThan('tablet')`
    body.compact-sidebar & {
      padding-left: 74px;
    }
  `}
  ${mediaQuery.lessThan('desktopLarge')`
    padding-left: 74px;
  `}
  ${mediaQuery.lessThan('tablet')`
    padding: 58px 0 0;
  `}

  @media print {
    padding-left: 0;
  }
`

export const PageHeader = styled.div<{ withFilters?: boolean }>`
  display: flex;
  position: sticky;
  align-items: center;
  padding: 16px 32px;
  min-height: 58px;
  border-bottom: 2px solid ${color.text};
  background: #fff;
  top: 0;
  z-index: ${zIndex.header};

  .-impersonating & {
    top: 62px;
  }

  ${({ withFilters }) =>
    withFilters &&
    `
    flex-wrap: wrap;
    min-height: 116px;
    height: auto;
    & + ${PageBody} {
      min-height: calc(100vh - 162px);
    }
  `}
  ${mediaQuery.lessThan<{ withFilters?: boolean }>('tablet')`
    position: fixed;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    padding: 0px 58px;
    top: 0;
    ${({ withFilters }) =>
    withFilters &&
      `
      & + ${PageBody} {
        padding-top: 54px;
      }
    `}
  `}
`

export const PageHeaderBackLink = styled(Link)`
  color: ${color.greyer};
`

export const PageTitle = styled.h1`
  min-width: 100px;
  margin: 0;
  min-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${mediaQuery.lessThan('tablet')`
    && {
      margin: 12px 48px 0 24px;
    }
    min-height: 0;
  `}
`

export const PageControls = styled.div`
  margin-left: auto;
  ${mediaQuery.lessThan('tablet')`
    position: fixed;
    left: 16px;
    right: 16px;
    bottom: 16px;
    .button {
      width: 100%;
    }
  `}
`

export const PageBody = styled.div<{ extraSpacing?: boolean }>`
  padding: 24px 32px;
  margin: 0 auto;
  min-height: calc(100vh - 82px);
  height: auto;
  ${({ extraSpacing }) =>
    extraSpacing &&
    `
    padding-bottom: 92px;
  `}
  ${mediaQuery.lessThan<{ extraSpacing?: boolean }>('desktop')`
    padding: 16px;
    min-height: calc(100vh - 58px);
    height: auto;
    padding-bottom: ${({ extraSpacing }) => (extraSpacing ? '120px' : '72px')}
  `}
`

export const PageContent = styled.div`
  padding: 24px ${spacing.lg}px;
  ${mediaQuery.lessThan('desktop')`
    padding: ${spacing.md}px;
  `}
`

export const SettingsPageHeader = styled(PageHeader)`
  ${mediaQuery.lessThan('tablet')`
      position: fixed;
      top: 58px;
      display: flex;
      align-items: center;
      padding: 0 16px 8px;

      ${PageTitle} {
        margin: 0;
      }
  `}
`

export const SettingsPageContainer = styled(PageContainer)`
  ${mediaQuery.lessThan('tablet')`
    padding: 116px 0 0;
  `}

  ${PageBody} {
    ${mediaQuery.lessThan('desktop')`
      min-height: calc(100vh - 116px);
    `}
  }
`
