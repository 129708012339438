import { useCallback, useMemo } from 'react'
import { compose, find, keyBy, map, reject, snakeCase, toPairs } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { markAsClientOnly } from '../../../utils/entityStatus'
import { ITicketType } from '../types/Tickets'

interface IFormik {
  values: ITicketType
  setFieldValue: (name: string, value: any) => void
  setFieldTouched: (name: string, value: boolean, validate?: boolean) => void
  handleChange: (e: any) => void
  validateForm: () => void
}

export interface ISeatingChart {
  seatsIoEventReport: any
}

function useTicketTypeSeating(
  { values, setFieldValue, handleChange, setFieldTouched, validateForm }: IFormik,
  eventSeatingChart: ISeatingChart | null
) {
  const intl = useIntl()

  const reservedSeatingTypeOptions = useMemo(
    () =>
      map(
        (k: string) => ({
          label: intl.formatMessage({
            id: `new_event.tickets.ticket_type_edit.reserved_seating_type.options.${snakeCase(k)}`,
          }),
          value: k,
        }),
        ['assignBestSeat', 'selectSeat']
      ),
    [intl]
  )

  const reservedSeatingTypeOption = useMemo(
    () => find(['value', values.reservedSeatingType], reservedSeatingTypeOptions),
    [reservedSeatingTypeOptions, values.reservedSeatingType]
  )

  const changeReservedSeatingType = useCallback(
    (id: string) => {
      setFieldValue('reservedSeatingType', id)
      if (id === 'selectSeat') {
        setFieldValue('allowSeatChange', false)
      }
    },
    [setFieldValue]
  )

  const handleChangeSeating = useCallback(
    (e: any) => {
      const wasChecked = !!e.target.checked

      setFieldValue('allowSeatChange', false)
      setFieldValue('reservedSeatingType', wasChecked ? 'assignBestSeat' : null)
      setFieldValue('seatCategories', null)
      handleChange(e)
      setFieldTouched('seatCategories', true, true)
      setFieldTouched('reservedSeating', true, true)

      if (wasChecked) {
        setFieldValue('priceTierType', null)
        setFieldValue('priceTiers', null)
      }

      setTimeout(() => validateForm(), 0)
    },
    [handleChange, setFieldTouched, setFieldValue, validateForm]
  )

  const allSeatCategoryOptions = useMemo(() => {
    return compose([
      map((cat: { id: string; name: string; seatsIoKey: string | null }) => ({
        value: cat.id,
        label: cat.name,
        seatsIoKey: cat.seatsIoKey,
      })),
      map(([catKey, seats]) =>
        markAsClientOnly<{ id: string; name: string; seatsIoKey: string | null }>({
          name: seats[0]?.categoryLabel || catKey,
          seatsIoKey: catKey,
        })
      ),
      reject(([catKey]) => catKey === 'NO_CATEGORY'),
      toPairs,
    ])(eventSeatingChart?.seatsIoEventReport || {})
  }, [eventSeatingChart?.seatsIoEventReport])

  const selectedSeatCategoryOptions = useMemo(() => {
    const keyed = keyBy('seatsIoKey', allSeatCategoryOptions)
    return map(
      (cat: { id: string; name: string; seatsIoKey: string | null }) =>
        (cat.seatsIoKey ? keyed[cat.seatsIoKey] : null) ||
        markAsClientOnly({ value: cat.id, label: cat.name, seatsIoKey: cat.seatsIoKey }),
      values.seatCategories
    )
  }, [allSeatCategoryOptions, values.seatCategories])

  const changeSeatCategories = useCallback(
    (_ids: any, opts: any) => {
      setFieldValue(
        'seatCategories',
        map(
          (catOpt) =>
            catOpt && {
              id: catOpt.value,
              name: catOpt.label,
              seatsIoKey: catOpt.seatsIoKey,
            },
          opts
        )
      )
    },
    [setFieldValue]
  )

  return {
    reservedSeatingTypeOptions,
    reservedSeatingTypeOption,
    changeReservedSeatingType,

    allSeatCategoryOptions,
    selectedSeatCategoryOptions,
    changeSeatCategories,

    handleChangeSeating,
  }
}

export default useTicketTypeSeating
