import { memoize } from 'lodash/fp'

export type IFactory = () => Promise<any>

export const preload = memoize((factory: IFactory) => () => {
  factory().catch(console.warn)
})

export const SIDEBAR_ROUTER = {
  dashboard: ['/dashboard'],
  fans: ['/fans', () => import(/* webpackChunkName: "fans" */ '../../Fans/FansPage')],
  finances: ['/finances', () => import(/* webpackChunkName: "finances" */ '../../Finances/FinancesPage')],
  reports: ['/reporting', () => import(/* webpackChunkName: "reports" */ '../../Reports/ReportsPage')],
  promoters: ['/promoters', () => import(/* webpackChunkName: "promoters" */ '../../Promoters/PromotersPage')],
  bundles: ['/event-collections', () => import(/* webpackChunkName: "bundles" */ '../../Bundles/BundlesPage')],
  linkouts: ['/website-widget', () => import(/* webpackChunkName: "linkouts" */ '../../Linkouts/LinkoutsPage')],
  venues: ['/venues', () => import(/* webpackChunkName: "venues" */ '../../Venues/VenuesPage')],
  artists: ['/artists', () => import(/* webpackChunkName: "artists" */ '../../Artists/ArtistsPage')],
  marketeers: ['/marketeers', () => import(/* webpackChunkName: "marketeers" */ '../../Marketeers/MarketeersPage')],
  nts: ['/nts-fiscal-info', () => import(/* webpackChunkName: "nts" */ '../../NtsFiscalInfo/NtsFiscalInfoPage')],
  events: {
    live: ['/events/live'],
    drafts: ['/events/drafts'],
    past: ['/events/past'],
    submissions: ['/events/submissions'],
    cancelled: ['/events/cancelled'],
    payouts: ['/events/payouts'],
  },
  tools: {
    integrations: [
      '/tools/integrations',
      () => import(/* webpackChunkName: "integrations" */ '../../Integrations/IntegrationsPage'),
    ],

    featured: ['/tools/featured', () => import(/* webpackChunkName: "featured" */ '../../Featured/FeaturedPage')],

    fanConnect: ['/tools/connect', () => import(/* webpackChunkName: "connect" */ '../../Connect/FanConnectPage')],
    users: ['/tools/users', () => import(/* webpackChunkName: "access" */ '../../Users/UsersPage')],
    usersInviteUser: [
      '/tools/users/new',
      () => import(/* webpackChunkName: "access" */ '../../InviteUser/InviteUserPage'),
    ],
    permissions: [
      '/tools/permissions',
      () => import(/* webpackChunkName: "access" */ '../../Permissions/PermissionsPage'),
    ],
    permissionsNewProfile: [
      '/tools/permissions/new',
      () => import(/* webpackChunkName: "access" */ '../../CreatePermissionProfile/CreatePermissionProfilePage'),
    ],

    tags: ['/tools/tags', () => import(/* webpackChunkName: "tags" */ '../../Tags/TagsPage')],
    hierarchy: ['/tools/hierarchy', () => import(/* webpackChunkName: "tags" */ '../../Hierarchy/HierarchyPage')],

    qr: ['/tools/qr', () => import(/* webpackChunkName: "qr" */ '../../QRGenerator/QRGeneratorPage')],

    inspector: [
      '/tools/inspector',
      () => import(/* webpackChunkName: "pip" */ '../../PlatformInspector/PlatformInspectorPage'),
    ],
  },
  support: ['/support', () => import(/* webpackChunkName: "helpcentre" */ '../../HelpCentre/HelpCentrePage')],

  // NEW SECTOIONS BELOW
  // TODO - clean this object up once nav redesign is enabled for all users

  data: {
    salesAttribution: [
      '/reporting/attribution',
      () => import(/* webpackChunkName: "reports" */ '../../ReportSalesAttribution/ReportSalesAttributionPage'),
    ],
    reports: ['/reporting', () => import(/* webpackChunkName: "reports" */ '../../Reports/ReportsPage')],
  },
  finance: {
    finances: ['/finances', () => import(/* webpackChunkName: "finances" */ '../../Finances/FinancesPage')],
    payouts: ['/finances/payouts'],
    managePayouts: ['/events/payouts'],
    nts: ['/nts-fiscal-info', () => import(/* webpackChunkName: "nts" */ '../../NtsFiscalInfo/NtsFiscalInfoPage')],
  },
  marketing: {
    bundles: ['/event-collections', () => import(/* webpackChunkName: "bundles" */ '../../Bundles/BundlesPage')],
    marketeers: ['/marketeers', () => import(/* webpackChunkName: "marketeers" */ '../../Marketeers/MarketeersPage')],
    linkouts: ['/website-widget', () => import(/* webpackChunkName: "linkouts" */ '../../Linkouts/LinkoutsPage')],
    featured: ['/tools/featured', () => import(/* webpackChunkName: "featured" */ '../../Featured/FeaturedPage')],
    qr: ['/tools/qr', () => import(/* webpackChunkName: "qr" */ '../../QRGenerator/QRGeneratorPage')],
  },
  fanSupport: {
    fanConnect: ['/tools/connect', () => import(/* webpackChunkName: "connect" */ '../../Connect/FanConnectPage')],
    fans: ['/fans', () => import(/* webpackChunkName: "fans" */ '../../Fans/FansPage')],
  },
  internal: {
    promoters: ['/promoters', () => import(/* webpackChunkName: "promoters" */ '../../Promoters/PromotersPage')],
    venues: ['/venues', () => import(/* webpackChunkName: "venues" */ '../../Venues/VenuesPage')],
    artists: ['/artists', () => import(/* webpackChunkName: "artists" */ '../../Artists/ArtistsPage')],
    users: ['/tools/users', () => import(/* webpackChunkName: "access" */ '../../Users/UsersPage')],
    usersInviteUser: [
      '/tools/users/new',
      () => import(/* webpackChunkName: "access" */ '../../InviteUser/InviteUserPage'),
    ],
    permissions: [
      '/tools/permissions',
      () => import(/* webpackChunkName: "access" */ '../../Permissions/PermissionsPage'),
    ],
    permissionsNewProfile: [
      '/tools/permissions/new',
      () => import(/* webpackChunkName: "access" */ '../../CreatePermissionProfile/CreatePermissionProfilePage'),
    ],
    tags: ['/tools/tags', () => import(/* webpackChunkName: "tags" */ '../../Tags/TagsPage')],
    hierarchy: ['/tools/hierarchy', () => import(/* webpackChunkName: "tags" */ '../../Hierarchy/HierarchyPage')],
    inspector: [
      '/tools/inspector',
      () => import(/* webpackChunkName: "pip" */ '../../PlatformInspector/PlatformInspectorPage'),
    ],
  },
}
