import React, { useEffect, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import graphqlOptionsLoader from '../graphqlOptionsLoader'

const QUERY_LOAD_USERS = graphql`
  query useAssignEventReviewOptionsUserSearchQuery($where: UsersWhereInput!) {
    viewer {
      options: users(first: 20, where: $where) {
        edges {
          node {
            id
            email
            name
            firstName
            lastName
          }
        }
      }
    }
  }
`

const QUERY_SELECTED_USER = graphql`
  query useAssignEventReviewOptionsSelectedUserQuery($id: ID!) {
    node(id: $id) {
      ... on User {
        id
        email
        name
        firstName
        lastName
      }
    }
  }
`

interface IUser {
  id: string
  email: string
  name: string
  firstName: string
  lastName: string
}

function useAssignEventReviewOptions(initialAssigneeId?: string | null) {
  const environment = useRelayEnvironment()
  const [selectedAssignee, setSelectedAssignee] = useState<IUser | null>()

  // Get preselected assignee
  useEffect(() => {
    let stillMounted = true
    if (initialAssigneeId) {
      fetchQuery_DEPRECATED(environment, QUERY_SELECTED_USER, { id: initialAssigneeId }).then((data) => {
        const user = data.node && {
          ...data.node,
          value: data.node.id,
          label: data.node.name,
        }
        if (stillMounted && user) {
          setSelectedAssignee(user)
        }
      })
    }
    return () => {
      stillMounted = false
    }
  }, [environment, initialAssigneeId])

  const userLoader = graphqlOptionsLoader(environment, QUERY_LOAD_USERS)

  return { userLoader, selectedAssignee, setSelectedAssignee }
}

export default useAssignEventReviewOptions
