import React, { FC, memo, ReactNode, useCallback, useMemo } from 'react'
import { find, sumBy } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { TitleTooltip, TooltipHelpIcon } from '../Tooltip'
import { EventCostCurrency, FeeType } from '../../enums.generated'
import { CURRENCY } from '../../utils/formatters/number'
import { color, font } from '../../utils/variables'

const FeesBreakdown = styled.div`
  text-align: left;
  & > div + div {
    padding-top: 8px;
  }
`

const Fee = styled.div<{ isApplicable?: boolean }>`
  color: ${({ isApplicable }) => (!isApplicable ? color.disabled : 'unset')};
  text-decoration: ${({ isApplicable }) => (!isApplicable ? `line-through ${color.white}` : 'unset')};
`

const Totals = styled.div`
  margin: 12px 0 0;
  padding: 8px 0 2px;
  border-top: 1px solid currentColor;
  span {
    display: inline-block;
    margin-left: 4px;
    vertical-align: bottom;
    font-size: ${font.size.base}px;
    font-weight: ${font.weight.bold};
  }
`

export interface IBreakdown {
  faceValue: number | null
  total: number | null
  rebate: number | null
  breakdown: null | ReadonlyArray<null | {
    type: FeeType
    applicable: boolean | null
    computed: number | null
  }>
}

interface IProps {
  priceBreakdown: IBreakdown | null
  currency: EventCostCurrency | null
  hasRebate: boolean
  placement?: string
  showFullPrice?: boolean
  trigger?: ReactNode
}

const FeeBreakdownTooltip: FC<IProps> = ({
  priceBreakdown,
  currency,
  hasRebate,
  placement,
  showFullPrice,
  trigger,
}) => {
  const intl = useIntl()

  const rebateForPromoter = useCallback(
    (feeType: any) => {
      const rebate = find(
        (v: { destination: string; computed: number }) => v.destination === 'billingPromoter' && !!v.computed,
        feeType?.split
      )

      if (!rebate) return null

      return {
        label: intl.formatMessage({ id: `fees.${feeType.type}.rebate`.replace(':', '.') }),
        value: currency && intl.formatNumber((rebate?.computed || 0) / 100, CURRENCY(rebate?.computed || 0, currency)),
      }
    },
    [currency, intl]
  )

  const breakdownTooltip = useMemo(() => {
    if (
      (priceBreakdown?.breakdown?.length || 0) === 0 ||
      sumBy('computed', priceBreakdown?.breakdown || []) === 0 ||
      !currency
    ) {
      return null
    }

    return (
      <FeesBreakdown data-id="feeBreakdown">
        {showFullPrice && (
          <div data-id="faceValue">
            {intl.formatMessage({ id: 'face_value' })}
            {': '}
            {intl.formatNumber(
              (priceBreakdown?.faceValue || 0) / 100,
              CURRENCY(priceBreakdown?.faceValue || 0, currency)
            )}
          </div>
        )}
        {priceBreakdown?.breakdown?.map(
          (feeType, fIdx) =>
            feeType &&
            (feeType.computed || 0) > 0 && (
              <Fee key={fIdx} isApplicable={!!feeType.applicable}>
                <div data-id={`fee:${feeType.type}`}>
                  {intl.formatMessage({ id: `fees.${feeType.type}`.replace(':', '.') })}
                  {': '}
                  {!showFullPrice && '('}
                  {intl.formatNumber((feeType?.computed || 0) / 100, CURRENCY(feeType?.computed || 0, currency))}
                  {!showFullPrice && ')'}
                </div>
                {hasRebate && rebateForPromoter(feeType) && (
                  <div data-id={`rebate:${feeType.type}`}>
                    {rebateForPromoter(feeType)?.label}
                    {': '}
                    {rebateForPromoter(feeType)?.value}
                  </div>
                )}
              </Fee>
            )
        )}
        {(hasRebate || showFullPrice) && (
          <Totals data-id="totals">
            {hasRebate && (
              <div data-id="rebate">
                {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_suggestion.total_rebate' })}
                {':'}
                <span>
                  {intl.formatNumber(
                    (priceBreakdown?.rebate || 0) / 100,
                    CURRENCY(priceBreakdown?.rebate || 0, currency)
                  )}
                </span>
              </div>
            )}
            {showFullPrice && (
              <div data-id="totalPrice">
                {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_suggestion.total_price' })}
                {':'}
                <span>
                  {intl.formatNumber(
                    (priceBreakdown?.total || 0) / 100,
                    CURRENCY(priceBreakdown?.total || 0, currency)
                  )}
                </span>
              </div>
            )}
          </Totals>
        )}
      </FeesBreakdown>
    )
  }, [
    currency,
    hasRebate,
    intl,
    priceBreakdown?.breakdown,
    priceBreakdown?.faceValue,
    priceBreakdown?.rebate,
    priceBreakdown?.total,
    rebateForPromoter,
    showFullPrice,
  ])

  const defaultTrigger = breakdownTooltip && <TooltipHelpIcon icon="help" width={16} height={16} />

  return (
    <TitleTooltip preset={trigger ? 'underlined' : 'undecorated'} placement={placement} title={breakdownTooltip}>
      {trigger || defaultTrigger}
    </TitleTooltip>
  )
}

export default memo(FeeBreakdownTooltip)
