import React, { forwardRef, PropsWithChildren } from 'react'
import styled from 'styled-components/macro'
import { textStyle } from '../utils/typography'

import { color } from '../utils/variables'
import Svg from './Svg'
import { TitleTooltip } from './Tooltip'

const Tag = styled.div<{ hasIcon?: boolean; colorStyle?: keyof typeof color }>`
  display: inline-block;
  vertical-align: middle;
  padding: 2px 4px 2px ${({ hasIcon }) => (hasIcon ? 2 : 4)}px;

  max-width: 125px;
  height: 20px;

  text-transform: uppercase;

  ${textStyle.button.lg}

  && {
    font-weight: normal;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${color.lightgrey};

  border-radius: 4px;

  ${({ colorStyle }) =>
    colorStyle &&
    `
    color: ${color[colorStyle]};
      background-color:  ${color[colorStyle]}20;
    `}}

  & > svg {
    display: inline-block;
    margin: -2px 2px 0 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
`

interface IProps {
  title?: string
  className?: string
  icon?: string
  color?: keyof typeof color
}

const Badge = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  ({ icon, title, className, color: colorStyle, children }, ref) => {
    const tag = (
      <Tag className={className} hasIcon={!!icon} ref={ref} colorStyle={colorStyle}>
        {icon && <Svg icon={icon} />}
        {children}
      </Tag>
    )

    return title ? <TitleTooltip title={title}>{tag}</TitleTooltip> : tag
  }
)

export default styled(Badge)``
