import React, { FC, memo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { STYLEGUIDE_SECTIONS, IKind, IStyleguideItem } from '../../../utils/styleguide'
import { color } from '../../../utils/variables'

const TwoColumns = styled.div`
  display: flex;
  line-height: 150%;

  & > div:first-child {
    margin-right: 34px;
  }
`

const StyleguideRule = styled.li<{ error: boolean }>`
  color: ${({ error }) => (error ? color.error : color.text)};
`

export type IData = {
  [key in IKind]: Array<IStyleguideItem>
}

const EventStyleguide: FC<React.PropsWithChildren<{ data: IData }>> = ({ data }) => {
  const intl = useIntl()
  return (
    <TwoColumns>
      {STYLEGUIDE_SECTIONS.map((section) => (
        <div key={section}>
          <strong>{intl.formatMessage({ id: `new_event.validation.styleguide.header.${section}` })}</strong>
          <ul>
            {data[section].map((rule) => (
              <StyleguideRule key={rule.key} error={!rule.valid}>
                {rule.message}
              </StyleguideRule>
            ))}
          </ul>
        </div>
      ))}
    </TwoColumns>
  )
}

export default memo(EventStyleguide)
