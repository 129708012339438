/**
 * @generated SignedSource<<103f326bdb727f968597ff343a9d1ebc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImpersonateUserInput = {
  accountId?: string | null;
  clientMutationId: string;
  id?: string | null;
  reason: string;
};
export type useImpersonationMutation$variables = {
  input: ImpersonateUserInput;
};
export type useImpersonationMutation$data = {
  readonly impersonateUser: {
    readonly token: string | null;
  } | null;
};
export type useImpersonationMutation = {
  response: useImpersonationMutation$data;
  variables: useImpersonationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ImpersonateUserPayload",
    "kind": "LinkedField",
    "name": "impersonateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useImpersonationMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useImpersonationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b22b74e6d4da277a3468bdd3db6d4c3a",
    "id": null,
    "metadata": {},
    "name": "useImpersonationMutation",
    "operationKind": "mutation",
    "text": "mutation useImpersonationMutation(\n  $input: ImpersonateUserInput!\n) {\n  impersonateUser(input: $input) {\n    token\n  }\n}\n"
  }
};
})();

(node as any).hash = "a517053f9e5da855024aba9da325841d";

export default node;
