/**
 * @generated SignedSource<<2fe79962435b04860b7e186019418bf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateEventChangedNotificationInput = {
  changes?: ReadonlyArray<string | null> | null;
  clientMutationId: string;
  eventId: string;
  message?: string | null;
  sendMeACopy?: boolean | null;
};
export type EventDetailsSendEventChangedNotificationMutation$variables = {
  input: CreateEventChangedNotificationInput;
};
export type EventDetailsSendEventChangedNotificationMutation$data = {
  readonly createEventChangedNotification: {
    readonly successful: boolean;
  } | null;
};
export type EventDetailsSendEventChangedNotificationMutation = {
  response: EventDetailsSendEventChangedNotificationMutation$data;
  variables: EventDetailsSendEventChangedNotificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateEventChangedNotificationPayload",
    "kind": "LinkedField",
    "name": "createEventChangedNotification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventDetailsSendEventChangedNotificationMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventDetailsSendEventChangedNotificationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8392b16677e9732b38f5273356937718",
    "id": null,
    "metadata": {},
    "name": "EventDetailsSendEventChangedNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation EventDetailsSendEventChangedNotificationMutation(\n  $input: CreateEventChangedNotificationInput!\n) {\n  createEventChangedNotification(input: $input) {\n    successful\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e7a1ce6891b8ea3d023fe0b9e607b8a";

export default node;
