import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import graphql from 'babel-plugin-relay/macro'

import RelayLoader from '../../components/RelayLoader'
import EventSuccess from './EventSuccess'

function EventSuccessPage() {
  const { id } = useParams<{ id: string }>()

  const eventId = useMemo(() => (id === 'new' ? null : id), [id])

  const DataLoader = useMemo(
    () =>
      RelayLoader(EventSuccess, {
        fetchPolicy: 'store-and-network',
        variables: {
          id: eventId,
        },
        query: graphql`
          query EventSuccessPageQuery($id: ID!) {
            event: node(id: $id) {
              ...EventSuccess_event
            }
          }
        `,
      }),
    [eventId]
  )

  return <DataLoader />
}

export default EventSuccessPage
