/**
 * @generated SignedSource<<c446b8e5b60bd7e49b63063e8b6eef09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type UpdateEventStateInput = {
  clientMutationId: string;
  id: string;
  notify?: boolean | null;
  notifyOn?: string | null;
  state: EventState;
};
export type submitDraftMutation$variables = {
  input: UpdateEventStateInput;
};
export type submitDraftMutation$data = {
  readonly updateEventState: {
    readonly event: {
      readonly state: EventState | null;
    } | null;
  } | null;
};
export type submitDraftMutation = {
  response: submitDraftMutation$data;
  variables: submitDraftMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "submitDraftMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventStatePayload",
        "kind": "LinkedField",
        "name": "updateEventState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "submitDraftMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventStatePayload",
        "kind": "LinkedField",
        "name": "updateEventState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04664aef2e489c94f39f2744e3e6dce2",
    "id": null,
    "metadata": {},
    "name": "submitDraftMutation",
    "operationKind": "mutation",
    "text": "mutation submitDraftMutation(\n  $input: UpdateEventStateInput!\n) {\n  updateEventState(input: $input) {\n    event {\n      state\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "410ea22dca81e8e532cff601f4abe49c";

export default node;
