import React, { FC, MouseEvent } from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'

import { HeaderRow, HeaderCol, Col, MainCol, ExtraCol } from './EventListItemGrid'
import { OnDesktopLarge } from '../../../components/Breakpoints'

interface IProps {
  type: string
  currentOrder?: string
  orderBy?: (e: MouseEvent<HTMLDivElement>) => void
  bordered?: boolean
}

const EventListHeader: FC<React.PropsWithChildren<IProps>> = ({ type, currentOrder, orderBy, bordered }) => {
  const intl = useIntl()
  return (
    <HeaderRow className={cn({ '-bordered': bordered })}>
      <MainCol>
        <Col grow>
          <HeaderCol
            data-order={currentOrder === 'nameASC' ? 'nameDESC' : 'nameASC'}
            className={cn(
              { '-can-order': !!orderBy },
              {
                '-desc': currentOrder === 'nameDESC',
                '-asc': currentOrder === 'nameASC',
              }
            )}
            onClick={orderBy}
          >
            {intl.formatMessage({ id: 'event' })}
          </HeaderCol>
        </Col>
        <Col>
          <HeaderCol
            data-order={currentOrder === 'dateASC' ? 'dateDESC' : 'dateASC'}
            className={cn(
              { '-can-order': !!orderBy },
              {
                '-desc': currentOrder === 'dateDESC',
                '-asc': !currentOrder || currentOrder === 'dateASC',
              }
            )}
            onClick={orderBy}
          >
            {intl.formatMessage({ id: 'date' })}
          </HeaderCol>
        </Col>
      </MainCol>
      <ExtraCol>
        <OnDesktopLarge>
          <Col>
            <HeaderCol
              data-order={currentOrder === 'venueASC' ? 'venueDESC' : 'venueASC'}
              className={cn(
                { '-can-order': !!orderBy },
                {
                  '-desc': currentOrder === 'venueDESC',
                  '-asc': !currentOrder || currentOrder === 'venueASC',
                }
              )}
              onClick={orderBy}
            >
              {intl.formatMessage({ id: 'venue' })}
            </HeaderCol>
          </Col>
        </OnDesktopLarge>
        {type === 'draft' ? (
          <Col grow>
            <HeaderCol className="-controls -progress">{intl.formatMessage({ id: 'progress' })}</HeaderCol>
          </Col>
        ) : (
          <Col grow>
            <Col grow>
              <HeaderCol
                data-order={currentOrder === 'ticketsSoldDESC' ? 'ticketsSoldASC' : 'ticketsSoldDESC'}
                className={cn(
                  { '-can-order': !!orderBy },
                  {
                    '-desc': currentOrder === 'ticketsSoldDESC',
                    '-asc': !currentOrder || currentOrder === 'ticketsSoldASC',
                  }
                )}
                onClick={orderBy}
              >
                {intl.formatMessage({ id: 'tickets' })}
              </HeaderCol>
            </Col>
            <Col grow className={cn('-text-right', type !== 'cancelled' && '-controls')}>
              <HeaderCol
                data-order={currentOrder === 'totalFaceValueASC' ? 'totalFaceValueDESC' : 'totalFaceValueASC'}
                className={cn(
                  '-text-right',
                  { '-can-order': !!orderBy },
                  {
                    '-desc': currentOrder === 'totalFaceValueDESC',
                    '-asc': !currentOrder || currentOrder === 'totalFaceValueASC',
                  }
                )}
                onClick={orderBy}
              >
                {intl.formatMessage({ id: 'total_face_value' })}
              </HeaderCol>
            </Col>
          </Col>
        )}
      </ExtraCol>
    </HeaderRow>
  )
}

export default EventListHeader
