import React, { FC } from 'react'
import { Outlet } from 'react-router'

import ErrorBoundary from '../components/ErrorBoundary'
import TrackingProvider from '../context/tracking'

const AnonymousLayout: FC = () => {
  return (
    <ErrorBoundary noAuth>
      <TrackingProvider readyForTracking>
        <Outlet />
      </TrackingProvider>
    </ErrorBoundary>
  )
}

export default AnonymousLayout
