import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import localforage from 'localforage'
import { authContext } from '../../../context/auth'

import Events from './Events'
import Data from './Data'
import Finances from './Finances'
import Marketing from './Marketing'
import FanSupport from './FanSupport'
import Internal from './Internal'
import { SortableCollapsibleSidebarList } from './CollapsibleSidebarList'

const DEFAULT_SIDEBAR_ORDER = [
  'sidebar.events',
  'sidebar.data',
  'sidebar.finances',
  'sidebar.marketing',
  'sidebar.fan_support',
  'sidebar.internal',
]

interface SectionInfo {
  component: any
  shouldShow?: (diceStaff: boolean, hasPermission: (perm: string) => boolean) => boolean
}

const SECTIONS_DICTIONARY: { [key: string]: SectionInfo } = {
  'sidebar.events': { component: Events },
  'sidebar.data': { component: Data },
  'sidebar.finances': { component: Finances },
  'sidebar.marketing': {
    component: Marketing,
    shouldShow: (diceStaff, hasPermission) =>
      diceStaff || hasPermission('read:bundle') || hasPermission('read:linkout') || hasPermission('read:featured_area'),
  },
  'sidebar.fan_support': {
    component: FanSupport,
    shouldShow: (_, hasPermission) =>
      hasPermission('connect_via_email:fan') || hasPermission('access_fan_profile:fan_support'),
  },
  'sidebar.internal': { component: Internal, shouldShow: (diceStaff) => diceStaff },
}

interface IListProps {
  sections: Array<string>
  draggingIndex: number | null
  onReload?: () => void
}

const SortableList = SortableContainer<IListProps>(({ sections, draggingIndex, onReload }: IListProps) => {
  const { user, hasPermission } = useContext(authContext)

  return (
    <ul data-id="navigationSections">
      {sections.map((section, idx) => {
        const info = SECTIONS_DICTIONARY[section]
        const Element = info.component as FC<{ onReload?: any }>
        const shouldShow = info.shouldShow ? info.shouldShow(user.diceStaff, hasPermission) : true
        return shouldShow ? (
          <SortableCollapsibleSidebarList key={section} index={idx} title={section} isDragging={draggingIndex === idx}>
            <Element onReload={onReload} />
          </SortableCollapsibleSidebarList>
        ) : null
      })}
    </ul>
  )
})

interface IProps {
  onReload: () => void
}

const SortableSidebarList: FC<IProps> = ({ onReload }) => {
  const { user } = useContext(authContext)
  const [sections, setSections] = useState(DEFAULT_SIDEBAR_ORDER)
  const store = useMemo(() => localforage.createInstance({ name: `sidebar_order_${user.id}` }), [user.id])

  useEffect(() => {
    store.getItem<Array<string>>('nav-sections-order').then((arr) => setSections(arr ?? DEFAULT_SIDEBAR_ORDER))
  }, [store])

  const [draggingIndex, setDraggingIndex] = useState<number | null>(null)

  // Passing drag index down before sort start allows us to collapse the section before
  //  the drag animation starts
  const beforeSortStart = useCallback(({ index }: { index: number }) => {
    setDraggingIndex(index)
  }, [])

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const newOrder = arrayMove(sections || [], oldIndex, newIndex)
      setSections(newOrder)
      store.setItem('nav-sections-order', newOrder)
      setDraggingIndex(null)
    },
    [sections, store]
  )

  return (
    <SortableList
      sections={sections}
      updateBeforeSortStart={beforeSortStart}
      onSortEnd={onSortEnd}
      draggingIndex={draggingIndex}
      useDragHandle={true}
      onReload={onReload}
    />
  )
}

export default SortableSidebarList
