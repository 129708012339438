import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { compact, compose, concat, find, map } from 'lodash/fp'
import { FormikContextType } from 'formik'

import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { authContext } from '../../../context/auth'
import { allowedEventAction } from '../services/allowedEventAction'
import EventTicketTypePriceTypeSelect from './EventTicketTypePriceTypeSelect'
import EventTicketTypeSeatingAreaTypeSelect from './EventTicketTypeSeatingAreaTypeSelect'
import IEventFormTickets, { ITicketType } from '../types/Tickets'
import { isNew } from '../../../utils/entityStatus'

interface IProps {
  isAlreadyIntegrated?: boolean
  readOnly?: boolean
  noRestrictions?: boolean
  formik: FormikContextType<ITicketType>
  venueSchedules: null | ReadonlyArray<NonNullable<IEventFormTickets['venueSchedules']>[number]>
  allowedLifecycleUpdates: unknown | null
  state: IEventFormTickets['state']
}

const EventTicketTypeNts: FC<IProps> = ({
  isAlreadyIntegrated,
  noRestrictions,
  readOnly,
  formik,
  venueSchedules,
  state,
  allowedLifecycleUpdates,
}) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const { values, setFieldValue, touched, errors, handleBlur, validateForm } = formik

  const venueScheduleOptions = useMemo(
    () =>
      compose([
        concat([{ value: '$FESTIVAL$', label: intl.formatMessage({ id: 'festival' }) }]),
        compact,
        map((vs: NonNullable<IProps['venueSchedules']>[number]) => vs && { value: vs.id, label: vs.name }),
      ])(venueSchedules || []),
    [intl, venueSchedules]
  )

  const venueScheduleOption = useMemo(() => {
    if (!values.venueScheduleId) {
      return {
        value: '$FESTIVAL$',
        label: intl.formatMessage({ id: 'festival' }),
      }
    }

    const found = find(['value', values.venueScheduleId], venueScheduleOptions || [])

    return (
      found || {
        value: values.venueScheduleId,
        label: `??? [${values.venueScheduleId}]`,
      }
    )
  }, [intl, values.venueScheduleId, venueScheduleOptions])

  const changeVenueSchedule = useCallback(
    (id: string) => setFieldValue('venueScheduleId', id === '$FESTIVAL$' ? null : id),
    [setFieldValue]
  )

  const isExisting = !isNew(values)

  return (
    <>
      {user.diceStaff && venueScheduleOptions.length > 0 && (
        <FormRow columnOnMobile>
          <FormField
            dice
            name="venueScheduleId"
            label={intl.formatMessage({ id: 'new_event.basics.venue_schedule.label' })}
            control="select"
            required
            onBlur={handleBlur}
            options={venueScheduleOptions}
            value={venueScheduleOption}
            onChange={changeVenueSchedule}
            error={(touched.venueScheduleId || isExisting) && errors.venueScheduleId}
            disabled={
              readOnly ||
              (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !noRestrictions)
            }
          />
        </FormRow>
      )}
      <FormRow columnOnMobile>
        <EventTicketTypeSeatingAreaTypeSelect
          touched={touched}
          errors={errors}
          handleBlur={handleBlur}
          values={values}
          setFieldValue={setFieldValue}
          validateForm={validateForm}
          disabled={
            readOnly ||
            (state !== 'DRAFT' &&
              !(allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !isAlreadyIntegrated) &&
              !noRestrictions)
          }
        />
        {!values.priceTierType && (
          <EventTicketTypePriceTypeSelect
            name="attractivePriceType"
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            values={values}
            setFieldValue={setFieldValue}
            validateForm={validateForm}
            disabled={
              readOnly ||
              (state !== 'DRAFT' && !allowedEventAction(allowedLifecycleUpdates, 'forbidden') && !noRestrictions)
            }
          />
        )}
      </FormRow>
    </>
  )
}

export default memo(EventTicketTypeNts)
