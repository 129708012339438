import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { compose, find, map, reject, filter } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import {
  useTierSelector_event$data,
  useTierSelector_event$key,
} from '../../../__generated__/useTierSelector_event.graphql'
import { authContext } from '../../../context/auth'
import DiceBadge from '../../../components/DiceBadge'

const filterWithIdx = (filter as any).convert({ cap: false })
const mapWithIdx = (map as any).convert({ cap: false })

const closedABPriceTier = (pt: { priceTier: { allocation: number | null }; appSold: number; posSold: number }) =>
  pt.priceTier.allocation && pt.appSold + pt.posSold >= pt.priceTier.allocation

type IBreakdownElement = NonNullable<NonNullable<useTierSelector_event$data['sales']>['ticketTypesBreakdown']>[number]
type IPtBreakdownElement = NonNullable<NonNullable<IBreakdownElement>['priceTiersBreakdown']>[number]

interface IPriceTierOption {
  value: string
  label: ReactNode | null
  allocation: number
  sold: number
  remaining: number
  reserved: number
}

interface ITicketTypeOption extends IPriceTierOption {
  isABTiers: boolean
  isStream: boolean
}

function useTierSelector(
  eventKey: useTierSelector_event$key | null,
  preSelectTtyId?: string | null,
  preSelectTierId?: string | null
) {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment useTierSelector_event on Event {
        sales {
          ticketTypesBreakdown {
            ticketType {
              id
              name
              allocation
              priceTierType
              archived
              isStream
            }
            totalAppSold
            totalPosSold
            totalReserved

            priceTiersBreakdown {
              appSold
              posSold
              reserved
              priceTier {
                id
                name
                allocation
              }
            }
          }
        }
      }
    `,
    eventKey
  )

  const ticketTypesOptions: ITicketTypeOption[] = useMemo(
    () =>
      compose([
        map(
          (tty: NonNullable<IBreakdownElement>) =>
            ({
              value: tty.ticketType.id,
              label: (
                <>
                  {!!tty.ticketType.archived && <DiceBadge />} {tty.ticketType.name}
                </>
              ),
              hint: !!tty.ticketType.archived ? intl.formatMessage({ id: 'event_status.archived' }) : undefined,
              allocation: tty.ticketType.allocation,
              sold: tty.totalAppSold + tty.totalPosSold,
              remaining: tty.ticketType.allocation - (tty.totalAppSold + tty.totalPosSold),
              reserved: tty.totalReserved,
              isABTiers: tty.ticketType.priceTierType === 'allocation',
              isStream: !!tty.ticketType.isStream,
              isArchived: !!tty.ticketType.archived,
            } as ITicketTypeOption)
        ),
        reject((tty: IBreakdownElement) => !tty || (!!tty.ticketType?.archived && !user.diceStaff)),
      ])(event?.sales?.ticketTypesBreakdown || []),
    [event?.sales?.ticketTypesBreakdown, intl, user.diceStaff]
  )

  const defaultTTy = useMemo(
    () => (preSelectTtyId ? find(['value', preSelectTtyId], ticketTypesOptions) : ticketTypesOptions[0]),
    [preSelectTtyId, ticketTypesOptions]
  )

  const getPriceTierOptions = useCallback(
    (ttyId: string | null): IPriceTierOption[] => {
      const currentTTy = find((t) => t?.ticketType.id === ttyId, event?.sales?.ticketTypesBreakdown || [])

      if (!currentTTy) return []
      if (currentTTy.ticketType.priceTierType !== 'allocation') return []

      return mapWithIdx(
        (pt: IPtBreakdownElement, idx: number) =>
          pt &&
          ({
            value: pt.priceTier.id,
            label:
              pt.priceTier.name ||
              intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.unnamed_tier' }, { idx: idx + 1 }),
            allocation: pt.priceTier.allocation,
            sold: pt.appSold + pt.posSold,
            remaining: (pt.priceTier.allocation || 0) - (pt.appSold + pt.posSold),
            reserved: pt.reserved,
          } as IPriceTierOption),
        filterWithIdx(
          (pt: IPtBreakdownElement, idx: number) =>
            pt &&
            (!closedABPriceTier(pt) ||
              pt.priceTier.id === preSelectTierId ||
              idx === currentTTy?.priceTiersBreakdown?.length - 1),
          currentTTy.priceTiersBreakdown || []
        )
      )
    },
    [event?.sales?.ticketTypesBreakdown, intl, preSelectTierId]
  )

  const defaultPriceTierOptions = useMemo(
    () => getPriceTierOptions(defaultTTy?.value || null),
    [defaultTTy?.value, getPriceTierOptions]
  )

  const defaultTier = useMemo(
    () => (preSelectTierId ? find(['value', preSelectTierId], defaultPriceTierOptions) : defaultPriceTierOptions[0]),
    [preSelectTierId, defaultPriceTierOptions]
  )

  return {
    ticketTypesOptions,
    defaultTTy,
    getPriceTierOptions,
    defaultTier,
  }
}

export default useTierSelector
