import { always } from 'lodash/fp'
import { clearAuthToken } from '../../../utils/api'
import { toUser } from './convert'
import { IAuthContext } from './types'

const ANONYMOUS = toUser(undefined, undefined)

const STUB_CONTEXT: IAuthContext = {
  logOut() {
    clearAuthToken()
    window.location.href = '/auth/login'
  },
  hasPermission: always(false),
  user: ANONYMOUS,
  availableAccounts: [],
  isImpersonated: false,
}

export default STUB_CONTEXT
