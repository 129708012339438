import React, { FC, memo, forwardRef, useMemo, useCallback, useState } from 'react'
import RealTagsInput, { ReactTagsInputProps } from 'react-tagsinput'
import styled from 'styled-components/macro'
import { concat, without } from 'lodash/fp'
import cn from 'classnames'

import 'react-tagsinput/react-tagsinput.css'
import { color, font, mediaQuery } from '../utils/variables'

const Container = styled.div`
  position: relative;
  pointer-events: all;
  display: flex;
  flex-wrap: wrap;
`

const Placeholder = styled.div`
  position: absolute;
  color: ${color.grey};
  left: 10px;
  top: 6px;
  pointer-events: none;
  user-select: none;
`

const StyledTagsInput = styled(RealTagsInput)<
  { error?: boolean; focus?: boolean; disabled: boolean } & ReactTagsInputProps<any>
>`
  border: none;
  padding: 4px 6px;
  pointer-events: none;
  background-color: ${({ disabled }) => (disabled ? color.palegrey : color.white)};

  ${mediaQuery.lessThan('tablet')`
    min-height: 58px;
  `}

  &.text-input:hover:before {
    border: 2px solid ${({ error, focus }) => (focus ? color.primary : error ? color.error : color.lightgrey)};
  }

  &:before {
    border: 2px solid ${({ error, focus }) => (focus ? color.primary : error ? color.error : color.lightgrey)};
  }

  .react-tagsinput-input {
    flex: 1;
    font-family: ${font.family.base};
    font-size: ${font.size.base}px;
    color: ${color.text};
    padding: 5px 0;
    margin: 2px 0 1px 10px;

    :not(:first-child) {
      margin-left: 0;
    }
  }

  .react-tagsinput-tag {
    font-size: ${font.size.base}px;
    border-radius: 4px;
    color: ${color.text};
    background-color: ${color.lightgrey};
    border: none;
    outline: none;
    margin: 0;
    padding: 0 8px;
    margin: 2px 10px 2px 0;
    word-break: break-all;
    line-height: 28px;
  }

  .react-tagsinput-remove {
    font-weight: normal;
    margin-left: 5px;
    color: ${color.darkgrey};
  }
`

interface IProps {
  name?: string
  className?: string
  value?: Array<string | null> | null
  onChange?: (val: string[]) => void
  onBlur?: (e: Event) => void
  placeholder?: string
  validationRegex?: RegExp
  readOnlyValues?: string[]
  disabled?: boolean
  hasError?: boolean
  addKeys?: number[] | string[]
}

const pasteSplit = (data: string) => {
  const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r']
  return data.split(new RegExp(separators.join('|'))).map((d) => d.trim())
}

const TagsInput: FC<IProps> = forwardRef(
  (
    {
      hasError,
      name,
      disabled,
      className,
      value,
      onChange,
      placeholder,
      validationRegex,
      readOnlyValues,
      onBlur: onBlurProp,
      addKeys,
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)
    const [text, setText] = useState('')

    const onFocus = useCallback(() => setFocused(true), [setFocused])
    const onBlur = useCallback(
      (e: Event) => {
        setFocused(false)
        setText('')
        if (onBlurProp) {
          onBlurProp(e)
        }
      },
      [onBlurProp]
    )

    const inputProps = useMemo(() => ({ placeholder: null, ref, onFocus, onBlur, name }), [ref, onFocus, onBlur, name])

    const renderLayout = useCallback(
      (tagComponents: any, inputComponent: any) => (
        <Container>
          {tagComponents}
          {inputComponent}
          <Placeholder>
            {(!value || value.length === 0) &&
            (!readOnlyValues || readOnlyValues.length === 0) &&
            !text &&
            !focused &&
            placeholder
              ? placeholder
              : null}
          </Placeholder>
        </Container>
      ),
      [placeholder, value, focused, text, readOnlyValues]
    )

    const renderTag = useCallback(
      ({ tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other }: any) => {
        const isDisabled = disabled || (readOnlyValues || []).indexOf(tag) >= 0
        return (
          <span key={key} {...other}>
            {getTagDisplayValue(tag)}
            {/* THIS IS NOT COMPONENT - JUST FN! */}
            {/* eslint-disable-next-line react/jsx-no-bind */}
            {!isDisabled && <a className={classNameRemove} onClick={() => onRemove(key)} />}
          </span>
        )
      },
      [readOnlyValues]
    )

    const allValues = useMemo(() => concat(readOnlyValues || [], value || []), [readOnlyValues, value])

    const doChange = useCallback(
      (values: any) => (onChange ? onChange(without(readOnlyValues || [], values || [])) : null),
      [onChange, readOnlyValues]
    )

    return (
      <StyledTagsInput
        className={cn('react-tagsinput', 'text-input', className)}
        focus={focused}
        error={hasError}
        value={allValues}
        onChange={doChange}
        onlyUnique
        addOnBlur
        addOnPaste
        addKeys={addKeys}
        validationRegex={validationRegex}
        pasteSplit={pasteSplit}
        inputProps={inputProps}
        renderLayout={renderLayout}
        renderTag={renderTag}
        inputValue={text}
        onChangeInput={setText}
        disabled={!!disabled}
      />
    )
  }
)

export default memo(TagsInput)
