import { some } from 'lodash/fp'
import { getAlpha2ByName } from './countries'
import { ILocale } from '../intl'

export type ICountrySpecificEvent = {
  countryCode?: string | null
  addressCountry?: string | null
  venues?: ReadonlyArray<{ addressCountry?: string | null; countryCode?: string | null } | null | undefined> | null
}

const hasCountryCode = (event: ICountrySpecificEvent, locale: ILocale, code: string): boolean =>
  event.countryCode === code ||
  getAlpha2ByName(event?.addressCountry, locale) === code ||
  some((v) => (v?.countryCode || getAlpha2ByName(v?.addressCountry, locale)) === code, event.venues || [])

export const isItalianEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'IT') ||
    event.addressCountry === 'Italy' ||
    event.addressCountry === 'Italia' ||
    some(['addressCountry', 'Italy'], event.venues || []) ||
    some(['addressCountry', 'Italia'], event.venues || []))

export const isSpanishEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'ES') ||
    event.addressCountry === 'Spain' ||
    event.addressCountry === 'España' ||
    some(['addressCountry', 'Spain'], event.venues || []) ||
    some(['addressCountry', 'España'], event.venues || []))

export const isFrenchEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'FR') ||
    event.addressCountry === 'France' ||
    some(['addressCountry', 'France'], event.venues || []))

export const isGermanEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'DE') ||
    event.addressCountry === 'Germany' ||
    some(['addressCountry', 'Germany'], event.venues || []))

export const isAustriaEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'AT') ||
    event.addressCountry === 'Austria' ||
    some(['addressCountry', 'Austria'], event.venues || []))

export const isUSEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'US') ||
    event.addressCountry === 'United States' ||
    event.addressCountry === 'United States of America' ||
    some(['addressCountry', 'United States'], event.venues || []) ||
    some(['addressCountry', 'United States of America'], event.venues || []))

export const isCanadaEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'CA') ||
    event.addressCountry === 'Canada' ||
    some(['addressCountry', 'Canada'], event.venues || []))

export const isPortugueseEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'PT') ||
    event.addressCountry === 'Portugal' ||
    some(['addressCountry', 'Portugal'], event.venues || []))

export const isIrishEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'IE') ||
    event.addressCountry === 'Ireland' ||
    some(['addressCountry', 'Ireland'], event.venues || []))

export const isUKEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  !!event &&
  (hasCountryCode(event, locale, 'GB') ||
    event.addressCountry === 'United Kingdom' ||
    some(['addressCountry', 'United Kingdom'], event.venues || []))

export const isEUEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  isItalianEvent(event, locale) ||
  isFrenchEvent(event, locale) ||
  isGermanEvent(event, locale) ||
  isAustriaEvent(event, locale) ||
  isSpanishEvent(event, locale) ||
  isPortugueseEvent(event, locale) ||
  isIrishEvent(event, locale)

export const isUKorEUEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  isUKEvent(event, locale) || isEUEvent(event, locale)

export const isRemittanceCountryEvent = (event: ICountrySpecificEvent | null | undefined, locale: ILocale): boolean =>
  isFrenchEvent(event, locale) || isItalianEvent(event, locale)
