import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { find, get } from 'lodash/fp'
import { addDays, formatISO, isAfter, subDays } from 'date-fns'
import { localeContext } from '../context/locale'
import { color, font } from '../utils/variables'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'

import { FormRow } from './Form'
import SwitchField from './SwitchField'
import Radio from './Radio'
import FormField from './FormField'

const CUTOFF_DAYS_OPTIONS = [
  { value: '14', i18n: 'promoter_form.refund.rescheduled_events.cutoff_options.14_days' },
  { value: '30', i18n: 'promoter_form.refund.rescheduled_events.cutoff_options.30_days' },
  { value: '90', i18n: 'promoter_form.refund.rescheduled_events.cutoff_options.90_days' },
]

interface IAutoRescheduledEventRefundsFormControlsProps {
  disabled?: boolean
  pathToAutoRescheduledEventRefunds?: string
  snakeCase?: boolean
  dice?: boolean
  switchCopyOverride?: string
  switchHintCopyOverride?: string
  showCutoffDaysHint?: boolean
}

const AutoRescheduledEventRefundsFormControls: FC<IAutoRescheduledEventRefundsFormControlsProps> = ({
  disabled = false,
  pathToAutoRescheduledEventRefunds,
  snakeCase = false,
  dice = false,
  switchCopyOverride,
  switchHintCopyOverride,
  showCutoffDaysHint = false,
}) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const path = pathToAutoRescheduledEventRefunds ? pathToAutoRescheduledEventRefunds + '.' : ''
  const cutoffDaysPath = path + 'autoRescheduledEventRefunds.' + (snakeCase ? 'cutoff_days' : 'cutoffDays')
  const cutoffDaysCustomPath = path + 'autoRescheduledEventRefunds.cutoffDaysCustom'

  const { values, touched, errors, handleBlur, setFieldValue, setFieldTouched } = useFormikContext()

  const active = get(path + 'autoRescheduledEventRefunds.active', values)
  const cutoffDays = get(cutoffDaysPath, values)
  const cutoffDaysCustom: boolean = get(cutoffDaysCustomPath, values) || !!cutoffDays || false

  const onChangeAutoRescheduledEventRefundsActive = useCallback(() => {
    if (active) {
      setFieldValue(cutoffDaysPath, null)
      setFieldValue(cutoffDaysCustomPath, false)
    }
    setFieldValue(path + 'autoRescheduledEventRefunds.active', !active)
  }, [active, cutoffDaysCustomPath, cutoffDaysPath, path, setFieldValue])

  const toggleCutoffDaysRadio = useCallback(() => {
    if (cutoffDaysCustom) {
      setFieldValue(cutoffDaysPath, null)
      setFieldTouched(cutoffDaysPath, false)
    }
    setFieldValue(cutoffDaysCustomPath, !cutoffDaysCustom)
  }, [cutoffDaysCustom, cutoffDaysCustomPath, cutoffDaysPath, setFieldTouched, setFieldValue])

  const onChangeCutoffDays = useCallback(
    (value: string) => {
      setFieldValue(cutoffDaysPath, value)
    },
    [cutoffDaysPath, setFieldValue]
  )

  const selectedCutoffDaysOption = useMemo(
    () => find((o) => o.value === cutoffDays + '', CUTOFF_DAYS_OPTIONS) || null,
    [cutoffDays]
  )

  const cutoffDaysHint = useMemo(() => {
    if (!cutoffDays || !showCutoffDaysHint) return null

    const scheduleStatusUpdatedAt = get('scheduleStatusUpdatedAt', values)
    const referenceDate = scheduleStatusUpdatedAt ? new Date(scheduleStatusUpdatedAt) : new Date()
    const twentyFourHoursBeforeEvent = subDays(new Date(get('date', values)), 1)

    const cutoffDate = addDays(referenceDate, cutoffDays)
    const formattedCutoffDate = formatISO(
      isAfter(twentyFourHoursBeforeEvent, cutoffDate) ? cutoffDate : twentyFourHoursBeforeEvent
    )

    return intl.formatMessage(
      { id: 'promoter_form.refund.rescheduled_events.cutoff_days.hint' },
      {
        date: intl.formatDate(formattedCutoffDate, {
          ...DATETIME_FORMATS.LONG(locale),
          timeZone: get('timezoneName', values) || undefined,
        }),
        b: (str: string) => <span className="highlight">{str}</span>,
      }
    )
  }, [cutoffDays, intl, locale, showCutoffDaysHint, values])

  return (
    <>
      <FormRow>
        <SwitchField
          dice={dice}
          label={switchCopyOverride || intl.formatMessage({ id: 'promoter_form.refund.rescheduled_events.label' })}
          hint={switchHintCopyOverride || intl.formatMessage({ id: 'promoter_form.refund.rescheduled_events.hint' })}
          name={path + 'autoRescheduledEventRefunds.active'}
          checked={!!active}
          onChange={onChangeAutoRescheduledEventRefundsActive}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </FormRow>

      {!!active && (
        <FormRow>
          <Fieldset dice={dice}>
            <Radio
              name={cutoffDaysCustomPath}
              label={intl.formatMessage({
                id: 'promoter_form.refund.rescheduled_events.cutoff_radio.up_to_24_hours',
              })}
              value="default"
              checked={!cutoffDaysCustom}
              onChange={toggleCutoffDaysRadio}
              onBlur={handleBlur}
              disabled={disabled}
            />
            <Radio
              name={cutoffDaysCustomPath}
              label={intl.formatMessage({
                id: 'promoter_form.refund.rescheduled_events.cutoff_radio.custom',
              })}
              value="custom"
              checked={cutoffDaysCustom}
              onChange={toggleCutoffDaysRadio}
              onBlur={handleBlur}
              disabled={disabled}
            />

            {!!cutoffDaysCustom && (
              <FormField
                className="cuttoffDaysSelect"
                name={cutoffDaysPath}
                control="select"
                options={CUTOFF_DAYS_OPTIONS}
                value={selectedCutoffDaysOption}
                onBlur={handleBlur}
                onChange={onChangeCutoffDays}
                error={get(cutoffDaysPath, touched) && get(cutoffDaysPath, errors)}
                placeholder={intl.formatMessage({ id: 'please_select' })}
                required={!!cutoffDaysCustom}
                disabled={disabled}
                hint={cutoffDaysHint}
              />
            )}
          </Fieldset>
        </FormRow>
      )}
    </>
  )
}

export default memo(AutoRescheduledEventRefundsFormControls)

const Fieldset = styled.fieldset<{ dice?: boolean }>`
  position: relative;
  border: none;
  margin: -24px 0 0 0;
  padding: 0 ${({ dice }) => (dice ? '20' : 0)}px 0;
  display: flex;
  flex-direction: column;

  ${Radio} {
    margin-top: 16px;

    &:last-of-type {
      margin-top: 12px;
    }

    .radio-box {
      background: ${color.white};
    }
  }

  .cuttoffDaysSelect {
    margin: 16px 0 0 32px;
    max-width: 310px;

    .react-select {
      background: ${color.white};
    }

    .highlight {
      color: ${color.primary};
    }
  }
`
