import React, { FC, useCallback, useMemo, HTMLProps, Suspense, useState } from 'react'
import { isPast, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components/macro'

import { breakpoints, spacing } from '../../../../utils/variables'

import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../components/Dropdown'
import IconButton from '../../../../components/IconButton'
import { Menu, MenuItem } from '../../../../components/Menu'
import PermissionCheck from '../../../../components/PermissionCheck'

import { Loader } from '../../../../components/Loader'
import DiceBadge from '../../../../components/DiceBadge'
import ChangeProductAllocationModal from './ChangeProductAllocationModal'

import { ProductsBreakdown_event$data } from '../../../../__generated__/ProductsBreakdown_event.graphql'

export const Actions = styled.div<HTMLProps<HTMLSpanElement> & { disabled: boolean }>`
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  right: 0;
  top: ${spacing.xs}px;

  && {
    opacity: 1;
  }

  flex: 1;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
    && {
      opacity: .25;
    }
  `}

  ${IconButton} {
    margin-left: ${spacing.sm}px;
  }
`

interface IProps {
  event: ProductsBreakdown_event$data
  products: NonNullable<
    NonNullable<NonNullable<ProductsBreakdown_event$data['productsSales']>['productBreakdown']>[number]
  >
}

const ProductActions: FC<React.PropsWithChildren<IProps>> = ({ event, products }) => {
  const intl = useIntl()

  const [dropdownId, setDropdownId] = useState<string | null>(null)
  const closeDropdown = useCallback(() => setDropdownId(null), [setDropdownId])
  const toggleDropdown = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      if (id === dropdownId) {
        closeDropdown()
      } else {
        setDropdownId(id)
      }
    },
    [closeDropdown, dropdownId, setDropdownId]
  )

  const [allocationId, setAllocationId] = useState<string | null>(null)
  const closeAllocation = useCallback(() => setAllocationId(null), [])
  const doChangeAllocation = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      if (!id) return

      closeDropdown()
      setAllocationId(id)
    },
    [closeDropdown]
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const isOffSale = useMemo(
    () => (event.offSaleDate ? isPast(parseISO(event.offSaleDate)) : false),
    [event.offSaleDate]
  )

  return (
    <Actions disabled={isOffSale}>
      <Suspense fallback={<Loader />}>
        {allocationId === products.product.id && (
          <ChangeProductAllocationModal
            eventId={event.id}
            onClose={closeAllocation}
            preSelectProductId={allocationId}
          />
        )}
      </Suspense>
      <Dropdown active={dropdownId === products.product.id} onClickOutside={closeDropdown}>
        <DropdownTrigger data-id={products.product.id} onClick={toggleDropdown}>
          <IconButton icon="more" color={isMobile && 'lightgrey'} />
        </DropdownTrigger>
        <DropdownContent active={dropdownId === products.product.id}>
          <Menu>
            <PermissionCheck permission="manage_extras_allocation:event">
              <MenuItem data-id={products.product.id} onClick={doChangeAllocation}>
                {products.product.archived && <DiceBadge />}
                {intl.formatMessage({ id: 'change_quantity' })}
              </MenuItem>
            </PermissionCheck>
          </Menu>
        </DropdownContent>
      </Dropdown>
    </Actions>
  )
}

export default ProductActions
