import React, { useMemo, FC, useContext } from 'react'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Button from '../../../components/Button'
import IconButton from '../../../components/IconButton'
import { MenuItem } from '../../../components/Menu'

import { EventPartPayout_event$key } from '../../../__generated__/EventPartPayout_event.graphql'
import { authContext } from '../../../context/auth'
import useEventPayout from '../../../utils/hooks/useEventPayout'

interface IProps {
  event: EventPartPayout_event$key
  type: string
  title?: string
  color?: string
  outlineColor?: string
  loading?: boolean
  onClick: () => void
}

const EventPartPayout: FC<IProps> = ({ event: eventKey, type, title, color, outlineColor, loading, onClick }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment EventPartPayout_event on Event {
        id
        costCurrency
        ...useEventPayout_event
      }
    `,
    eventKey
  )

  const { selfPayoutDisabled, adminPayoutDisabled, isPaidOut, selfPayoutBlockers } = useEventPayout(event)

  const payoutDisabled = user.diceStaff ? adminPayoutDisabled : selfPayoutDisabled

  const btnTitle = useMemo(() => (title ? title : intl.formatMessage({ id: 'payout.modal.title' })), [intl, title])

  const disabled = !user.diceStaff && !!selfPayoutBlockers?.hold_payouts

  const renderActionItem = useMemo(() => {
    switch (type) {
      case 'button':
        return (
          <Button data-id={`selfPayout-${type}`} color={color} onClick={onClick} disabled={disabled} loading={loading}>
            {isPaidOut ? intl.formatMessage({ id: 'event_status.paid-out' }) : btnTitle}
          </Button>
        )
      case 'menu':
        return (
          <MenuItem data-id={`selfPayout-${type}`} color={color} onClick={onClick} disabled={disabled} active={loading}>
            {isPaidOut ? intl.formatMessage({ id: 'event_status.paid-out' }) : btnTitle}
          </MenuItem>
        )
      case 'icon':
      default:
        return (
          <IconButton
            data-id={`selfPayout-${type}`}
            icon="coins"
            color={color}
            outlineColor={!payoutDisabled && outlineColor}
            onClick={onClick}
            title={btnTitle}
            disabled={disabled}
            loading={loading}
          />
        )
    }
  }, [type, color, onClick, disabled, loading, isPaidOut, intl, btnTitle, payoutDisabled, outlineColor])

  return renderActionItem
}

export default EventPartPayout
