import React, { FC, memo } from 'react'
import { isNil } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { EventCostCurrency } from '../../enums.generated'
import { CURRENCY } from '../../utils/formatters/number'
import { TitleTooltip } from '../Tooltip'
import { color } from '../../utils/variables'

const PriceBreakdown = styled.div`
  text-align: left;
  & > div + div {
    padding-top: 8px;
  }
`

const Price = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (!isActive ? color.disabled : 'unset')};
  text-decoration: ${({ isActive }) => (!isActive ? `line-through ${color.white}` : 'unset')};
`

interface IBreakdown {
  total: number | null
  totalWithPwl: number | null
  totalWithoutPwl: number | null
}

interface IProps {
  priceBreakdown: IBreakdown | null
  currency: EventCostCurrency | null
  placement?: string
}

const PriceBreakdownTooltip: FC<IProps> = ({ priceBreakdown, currency, placement }) => {
  const intl = useIntl()

  const breakdownTooltip =
    // prettier-ignore
    !isNil(priceBreakdown?.totalWithPwl) &&
    !isNil(priceBreakdown?.totalWithoutPwl) &&
    priceBreakdown?.totalWithPwl !== priceBreakdown?.totalWithoutPwl ? (
        <PriceBreakdown>
          <Price
            data-id="priceWithoutPwl"
            isActive={Math.abs((priceBreakdown?.totalWithoutPwl || 0) - (priceBreakdown?.total || 0)) < 0.01}
          >
            {intl.formatMessage({ id: 'price.without_pwl' })}
            {': '}
            {intl.formatNumber(
              (priceBreakdown?.totalWithoutPwl || 0) / 100,
              CURRENCY(priceBreakdown?.totalWithoutPwl || 0, currency)
            )}
          </Price>
          <Price
            data-id="priceWithPwl"
            isActive={Math.abs((priceBreakdown?.totalWithPwl || 0) - (priceBreakdown?.total || 0)) < 0.01}
          >
            {intl.formatMessage({ id: 'price.with_pwl' })}
            {': '}
            {intl.formatNumber(
              (priceBreakdown?.totalWithPwl || 0) / 100,
              CURRENCY(priceBreakdown?.totalWithPwl || 0, currency)
            )}
          </Price>
        </PriceBreakdown>
      ) : null

  return (
    <TitleTooltip preset="underlined" title={breakdownTooltip} placement={placement}>
      {intl.formatNumber((priceBreakdown?.total || 0) / 100, CURRENCY(priceBreakdown?.total || 0, currency))}
    </TitleTooltip>
  )
}

export default memo(PriceBreakdownTooltip)
