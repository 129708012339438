import React, { useMemo, useCallback, FC, useEffect, useState, useRef, useContext, memo } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components/macro'
import { compose, filter, groupBy, intersection, set, map, fromPairs, isEmpty, compact, reject } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { SchemaFieldDescription } from 'yup'

import Badge from '../../components/Badge'
import Button from '../../components/Button'
import STEPS, { IFormStepConfig, STEP_SLUGS } from './services/getStepsConfig'
import Svg from '../../components/Svg'
import hasEventChanged from './services/hasEventChanged'
import IEventForm from './types'
import scrollToTopError from '../../utils/scrollToTopError'
import errorsToTouched from '../../utils/errorsToTouched'
import { color, font } from '../../utils/variables'
import { localeContext } from '../../context/locale'
import { authContext } from '../../context/auth'
import EventLaxSchema from './validation/EventLax'
import PageTransitionBlocker from '../../components/PageTransitionBlocker'
import { CancelButton, FormControls, FormControlsInner } from '../../components/FormControlsStyles'
import EventWorkflow from './components/EventWorkflow'
import getDeepKeys from '../../utils/getDeepKeys'
import Wizard, { IStep } from '../../components/Wizard'
import { Dirty } from '../../components/WizardFormFooter'
import NotesTrigger from '../../components/NotesTrigger'
import useEventNotesContext from '../EventNotes/hooks/useEventNotesContext'

const StyledBadge = styled(Badge)<{ badgeColor?: keyof typeof color; outline?: boolean }>`
  position: relative;
  font-weight: ${font.weight.bold} !important;
  background: none;
  color: ${(props) => (props.badgeColor ? color[props.badgeColor] : color.primary)};
  letter-spacing: normal;
  line-height: 15px;
  margin-left: 4px;

  ${(props) =>
    props.outline
      ? css`
          border: 1px solid ${props.badgeColor ? color[props.badgeColor] : color.primary};
        `
      : css`
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: ${props.badgeColor ? color[props.badgeColor] : color.primary};
            opacity: 0.08;
          }
        `}
`

function getDeepValidations(obj: Record<string, SchemaFieldDescription>): string[] {
  let keys: string[] = []
  for (const key in obj) {
    if ((obj[key] as any).fields) {
      const subkeys = getDeepValidations((obj[key] as any).fields)
      keys = keys.concat(
        subkeys.map(function (subkey) {
          return key + '.' + subkey
        })
      )
    } else {
      keys.push(key)
    }
  }
  return keys
}

interface IWizardProps {
  submissionFlow?: boolean
  readOnly?: boolean
  viewer: any
  onActivateStep?: (step: string) => void
  onCancel?: () => void
  onSaveDraft?: (values: IEventForm, helpers: any, isDraft: boolean) => Promise<any>
}

interface IActivatedSteps {
  [k: string]: boolean | undefined
}

const EventWizard: FC<React.PropsWithChildren<IWizardProps>> = ({
  readOnly,
  submissionFlow,
  viewer,
  onActivateStep,
  onSaveDraft = null,
}) => {
  const intl = useIntl()
  const { user, account, hasPermission } = useContext(authContext)
  const { phraseEnabled } = useContext(localeContext)

  const formik = useFormikContext<IEventForm>()
  const { setFieldValue, isValid, errors, isSubmitting, values, resetForm, initialValues, setTouched } = formik

  // Keep event notes context updated
  useEventNotesContext(values)

  const isMinimallyValid = useMemo(() => EventLaxSchema.isValidSync(values), [values])

  const validSteps = useMemo(
    () =>
      compose([
        groupBy('slug'),
        filter((step: IFormStepConfig[number]) => {
          const fields = getDeepValidations(step.schema.describe().fields)
          const errFields = getDeepKeys(errors)
          return intersection(fields, errFields).length === 0
        }),
      ])(STEPS),
    [errors]
  )

  const [activatedSteps, setActivatedSteps] = useState<IActivatedSteps>(
    values.id ? fromPairs(map((k) => [k, true], STEP_SLUGS)) : {}
  )

  useEffect(() => {
    const comletedSteps = filter((step: IFormStepConfig[number]) => {
      return step.schema.isValidSync(values, { context: { initialValues, viewer } })
    }, STEPS)
    setFieldValue('completedSteps', comletedSteps.length)
  }, [initialValues, setFieldValue, values, viewer])

  const onStepChange = useCallback(
    (slug: string) => {
      setActivatedSteps(set(slug, true))
      if (onActivateStep) onActivateStep(slug)
    },
    [onActivateStep]
  )

  const getStepValidationState = useCallback(
    (step: any) => {
      if (!values.id) return 'unknown'
      if (!activatedSteps[step]) return 'unknown'
      return validSteps[step] ? 'valid' : 'invalid'
    },
    [activatedSteps, validSteps, values.id]
  )

  const isChanged = useMemo(() => hasEventChanged(initialValues, values), [initialValues, values])

  const [isSavingDraft, setSavingDraft] = useState(false)
  const doSaveDraft = useCallback(
    async (e: Event) => {
      e.preventDefault()

      if (!onSaveDraft) return

      setSavingDraft(true)
      try {
        await onSaveDraft(values, formik, true)
      } finally {
        setSavingDraft(false)
      }
    },
    [formik, onSaveDraft, values]
  )

  const forceSubmit = useCallback(
    async (e: Event) => {
      e.preventDefault()

      if (!onSaveDraft) return

      setSavingDraft(true)
      try {
        await onSaveDraft(values, formik, false)
      } finally {
        setSavingDraft(false)
      }
    },
    [formik, onSaveDraft, values]
  )

  const ctaKey = useMemo(() => {
    if (!submissionFlow || values.state !== 'DRAFT') return 'save_changes'
    if (isChanged && isValid) return 'new_event.save_and_continue'
    if (isValid && !isChanged) return 'actions.continue'
    return 'new_event.save_draft'
  }, [isChanged, isValid, submissionFlow, values.state])

  const ctaEnabled = useMemo(() => {
    if (submissionFlow) {
      // For drafts - "save draft" / "continue"
      return isChanged || isValid
    } else {
      // For non-drafts - "save changes"
      return user.diceStaff ? isChanged : isChanged && isValid
    }
  }, [isChanged, isValid, submissionFlow, user.diceStaff])

  const hasErrors = useRef(isEmpty(errors))
  const errTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (submissionFlow || !errors || isEmpty(errors) === hasErrors.current) return

    hasErrors.current = isEmpty(errors)

    if (errTimeoutRef.current) clearTimeout(errTimeoutRef.current)
    errTimeoutRef.current = setTimeout(() => {
      if (errors && !isEmpty(errors)) {
        setTouched(errorsToTouched(errors))
        scrollToTopError(errors)
      }
    }, 100)
  }, [errors, setTouched, submissionFlow])

  const extrasVisibility = useMemo(() => {
    if (user.diceStaff || account?.extrasEnabled || values.allowedActions?.readExtras) {
      const extras = !user.diceStaff ? reject('archived', values.products || []) : values.products || []
      return extras.length > 0 || hasPermission('add_extras:event') || values?.allowedActions?.addProducts
    }
    return false
  }, [
    account?.extrasEnabled,
    hasPermission,
    user.diceStaff,
    values.allowedActions?.addProducts,
    values.allowedActions?.readExtras,
    values.products,
  ])

  const merchVisibility = useMemo(() => {
    if (user.diceStaff || account?.merchEnabled || values.allowedActions?.readMerch) {
      const extras = !user.diceStaff ? reject('archived', values.products || []) : values.products || []
      return extras.length > 0 || hasPermission('add_extras:event') || values?.allowedActions?.addProducts
    }
    return false
  }, [
    account?.merchEnabled,
    hasPermission,
    user.diceStaff,
    values.allowedActions?.addProducts,
    values.allowedActions?.readMerch,
    values.products,
  ])

  const wizardSteps = useMemo(
    () =>
      compact(
        map(
          (s) =>
            (s.slug !== 'extras' || extrasVisibility) &&
            (s.slug !== 'merch' || merchVisibility) &&
            ({
              slug: s.slug,
              label: intl.formatMessage({ id: `new_event.steps.${s.slug}` }),
              badge: s.badge && (
                <StyledBadge badgeColor={s.slug === 'merch' ? 'tertiary' : 'primary'} outline={s.slug === 'merch'}>
                  {intl.formatMessage({ id: `new_event.step_badge.${s.badge}` })}
                </StyledBadge>
              ),
              component: s.component,
            } as IStep),
          STEPS
        )
      ),
    [extrasVisibility, merchVisibility, intl]
  )

  const [forceEdit, setForceEdit] = useState(false)
  const onForceEdit = useCallback(() => {
    setForceEdit(true)
    setFieldValue('allowedLifecycleUpdates', null)
  }, [setFieldValue])

  const doCancel = useCallback(() => {
    setForceEdit(false)
    resetForm({ values: !values.id ? set('eventType', null, initialValues) : initialValues })
  }, [initialValues, resetForm, values.id])

  useEffect(() => {
    if (!values.lockVersion) return
    setForceEdit(false)
  }, [values.lockVersion])

  const effectiveReadOnly = readOnly && !forceEdit
  // DICE staff can edit some fields in cancelled events, so need save button still
  const formControlsReadOnly = effectiveReadOnly && !(user.diceStaff && values.state === 'CANCELLED')

  return (
    <>
      <PageTransitionBlocker condition={isChanged && !isSubmitting && !isSavingDraft} />
      <Wizard
        isEmbedded={!submissionFlow || user.diceStaff}
        steps={wizardSteps}
        navFooter={user.diceStaff && <NotesTrigger />}
        hideSteps={!submissionFlow}
        onStepChange={onStepChange}
        validateStep={getStepValidationState}
        readOnly={effectiveReadOnly}
        remountKey={initialValues.id}
        event={null}
        viewer={viewer}
      >
        {!formControlsReadOnly ? (
          <FormControls ICEenabled={phraseEnabled}>
            <FormControlsInner>
              {!submissionFlow && user.diceStaff && !isValid ? (
                <Button
                  data-id="saveAnyway"
                  type="submit"
                  onClick={submissionFlow && !isValid ? doSaveDraft : null}
                  loading={isSubmitting || isSavingDraft}
                  disabled={!ctaEnabled}
                  preset="outline"
                  color="tertiary"
                  icon="dice-badge"
                >
                  {intl.formatMessage({ id: 'save_changes_with_errors' })}
                </Button>
              ) : (
                <Button
                  loading={isSubmitting || isSavingDraft}
                  type="submit"
                  disabled={!ctaEnabled}
                  data-id="saveButton"
                  onClick={submissionFlow && !isValid ? doSaveDraft : null}
                >
                  {intl.formatMessage({ id: ctaKey })}
                </Button>
              )}

              {submissionFlow && user.diceStaff && !isValid && isMinimallyValid && (
                <Button
                  data-id="saveAnyway"
                  onClick={forceSubmit}
                  loading={isSubmitting || isSavingDraft}
                  preset="outline"
                  color="tertiary"
                  icon="dice-badge"
                >
                  {intl.formatMessage({ id: 'submit_with_errors' })}
                </Button>
              )}

              {!submissionFlow && user.diceStaff && !isChanged && (
                <EventWorkflow
                  disabled={isSubmitting || isSavingDraft}
                  forceEdit={forceEdit}
                  onForceEdit={onForceEdit}
                />
              )}

              <Dirty>
                {isChanged && !isSubmitting && (
                  <div>
                    <Svg icon="circle" width={8} height={8} />
                    {intl.formatMessage({ id: 'unsaved_changes' })}
                  </div>
                )}
              </Dirty>

              <CancelButton
                disabled={(!isChanged && !!values.id) || isSubmitting || isSavingDraft}
                preset="secondary"
                data-id="cancel"
                onClick={doCancel}
              >
                {intl.formatMessage({ id: 'actions.cancel' })}
              </CancelButton>
            </FormControlsInner>
          </FormControls>
        ) : (
          user.diceStaff &&
          !submissionFlow &&
          !isChanged && (
            <FormControls ICEenabled={phraseEnabled}>
              <FormControlsInner>
                <EventWorkflow
                  disabled={isSubmitting || isSavingDraft}
                  forceEdit={forceEdit}
                  onForceEdit={onForceEdit}
                />
              </FormControlsInner>
            </FormControls>
          )
        )}
      </Wizard>
    </>
  )
}

export default memo(EventWizard)
