import React, { FC, memo } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { capitalize, entries, isObject, uniqBy } from 'lodash/fp'
import styled from 'styled-components/macro'
import { FormikErrors } from 'formik'

import IEventForm from '../types'
import { EventType } from '../../../enums.generated'
import useEventFormErrors from '../hooks/useEventFormErrors'
import Warning from '../../../components/Warning'

const FieldList = styled.dl`
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;

  dt {
    width: 144px;
    min-height: 17px;

    margin: 0;
    padding: 0;

    font-weight: bold;
  }

  dd {
    min-height: 17px;

    flex: 1;
    flex-basis: calc(100% - 250px);

    margin: 0 0 16px 32px;
    padding: 0;

    overflow: hidden;
    text-overflow: ellipsis;

    & > div {
      margin-bottom: 16px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }

    &:last-child {
      margin: 0 0 0 32px;
    }
  }
`

export const renderErrorValue = (intl: IntlShape, f: { key: string; label: string; value: any }) => {
  if (f.value === ' ') {
    return intl.formatMessage({ id: 'form.required' })
  } else if (isObject(f.value)) {
    return intl.formatMessage({ id: 'form.has_errors' })
  }
  return intl.formatMessage({ id: f.value, defaultMessage: `_${f.value}_` })
}

interface IProps {
  eventType: EventType | undefined | null
  errors: FormikErrors<Partial<IEventForm>>
}

const EventFormErrors: FC<React.PropsWithChildren<IProps>> = ({ eventType, errors }) => {
  const intl = useIntl()

  const { fields, remainingErrors } = useEventFormErrors(eventType, errors)

  return (
    <Warning>
      <FieldList>
        {uniqBy('label', fields).map((f) => (
          <React.Fragment key={f.key}>
            <dt data-id={f.key}>{f.label}</dt>
            <dd data-id={f.key}>{renderErrorValue(intl, f)}</dd>
          </React.Fragment>
        ))}
        {remainingErrors &&
          entries(remainingErrors).map(([k, v]) => (
            <React.Fragment key={k}>
              <dt data-id={`unknown:${k}`}>_{k}_</dt>
              <dd data-id={`unknown:${k}`}>{renderErrorValue(intl, { key: k, label: capitalize(k), value: v })}</dd>
            </React.Fragment>
          ))}
      </FieldList>
    </Warning>
  )
}

export default memo(EventFormErrors)
