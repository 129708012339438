import React, { FC, useMemo, memo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { find } from 'lodash/fp'
import { font, color } from '../../../utils/variables'

import Svg from '../../../components/Svg'
import RelayLoader from '../../../components/RelayLoader'
import CircleProgress from '../../../components/CircleProgress'
import EventCardValue from '../../../components/Event/EventCardValue'

import { EventPartSalesBreakdown_event$key } from '../../../__generated__/EventPartSalesBreakdown_event.graphql'

const TicketTypeSalesBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px 16px;
  width: 320px;
  min-height: 58px;
  align-items: center;
  justify-content: center;
`

const TicketTypeSalesBreakdown = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 -16px;
  padding: 8px 16px;
  width: 320px;
  flex: 40px 0 0;
  &:first-child {
    margin-top: -8px;
  }
  &:last-child {
    margin-bottom: -8px;
  }
  &:not(:first-child) {
    border-top: 1px solid ${color.lightgrey};
  }
`

const Remaining = styled.span`
  display: block;
  position: absolute;
  right: 16px;
  bottom: 9px;
  padding-left: 8px;
  margin-left: auto;
  margin-top: auto;
  font-size: ${font.size.sm}px;
  color: ${color.darkgrey};
`

interface IProps {
  event: EventPartSalesBreakdown_event$key
}

const EventPartSalesBreakdownRender: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment EventPartSalesBreakdown_event on Event {
        id
        sales {
          ticketTypesBreakdown {
            ticketType {
              id
              name
              allocation
              ticketPoolId
            }
            totalAppSold
            totalPosSold
            totalWlRequests
          }
        }
        ticketPools {
          id
          maxAllocation
        }
      }
    `,
    eventKey
  )

  return (
    <>
      {event?.sales?.ticketTypesBreakdown.map((tty) => {
        const poolAllocation =
          tty?.ticketType.ticketPoolId &&
          find((p) => p?.id === tty?.ticketType.ticketPoolId, event.ticketPools)?.maxAllocation
        const allocation = tty?.ticketType.allocation || poolAllocation || 0
        const sold = (tty?.totalAppSold || 0) + (tty?.totalPosSold || 0)
        const soldPercentage = (sold * 100) / (allocation || 0)
        const wlRequests = tty?.totalWlRequests || 0
        const showWL = soldPercentage === 100 && wlRequests > 0

        return (
          <TicketTypeSalesBreakdown key={tty?.ticketType.id}>
            <CircleProgress
              percentage={soldPercentage}
              value={showWL ? wlRequests : undefined}
              title={showWL ? intl.formatMessage({ id: 'on_waiting_list' }, { num: wlRequests }) : undefined}
            />
            <EventCardValue
              primary={tty?.ticketType.name || 'Unknown'}
              secondary={sold + '/' + (allocation || 0)}
              overflow="hidden"
            />
            <Remaining>{(allocation || 0) - sold} remaining</Remaining>
          </TicketTypeSalesBreakdown>
        )
      })}
    </>
  )
}

export default memo(({ eventId }: { eventId: string }) => {
  const DataLoader = useMemo(
    () =>
      RelayLoader(EventPartSalesBreakdownRender, {
        fetchPolicy: 'store-and-network',
        variables: {
          id: eventId,
        },
        query: graphql`
          query EventPartSalesBreakdownQuery($id: ID!) {
            event: node(id: $id) {
              ...EventPartSalesBreakdown_event
            }
          }
        `,
      }),
    [eventId]
  )

  return (
    <TicketTypeSalesBreakdownWrapper>
      {eventId ? <DataLoader /> : <Svg icon="face-sad" />}
    </TicketTypeSalesBreakdownWrapper>
  )
})
