import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import qs from 'qs'

import graphql from 'babel-plugin-relay/macro'

import { mediaQuery } from '../../utils/variables'
import { useDebounce } from '../../utils/hooks/useDebounce'

import InputAutosize from '../../components/InputAutosize'
import SearchResults, { SearchResultsLoader } from './components/DashboardSearch/SearchResults'
import RelayLoader from '../../components/RelayLoader'
import { trackingContext, PageViewTracker } from '../../context/tracking'
import { ClearIcon, FilterSearch, FilterSearchInputHuge, SearchIcon } from '../../components/FilterStyles'

const DashboardSearchBar = styled.div`
  position: relative;
  display: flex;
  min-width: 100%;
  flex-grow: 1;
  margin: 16px -32px -16px;
  border-top: 2px solid #000;
  height: 88px;
  ${mediaQuery.lessThan('tablet')`
    height: 58px;
    margin: 12px -58px -16px -58px;
  `}
`

const FilterSearchDashboard = styled(FilterSearch)`
  justify-content: center;
  padding: 23px 24px 23px 32px;
  overflow: hidden;
  & > div {
    overflow: hidden;
  }
`

const DashboardSearch = () => {
  const intl = useIntl()
  const { search } = useLocation()
  const navigate = useNavigate()
  const { trackEvent } = useContext(trackingContext)

  const [searchStr, setSearchStr] = useState('')
  const debounceSearch = useDebounce(searchStr, 500)

  useEffect(() => {
    const searchString = qs.parse(search, { ignoreQueryPrefix: true })
    setSearchStr((searchString.search as string) || '')
  }, [search])

  const Results = useMemo(() => {
    if (searchStr !== debounceSearch) return SearchResultsLoader
    return RelayLoader(SearchResults, {
      customLoader: <SearchResultsLoader />,
      variables: {
        searchTerm: debounceSearch,
      },
      query: graphql`
        query DashboardSearchQuery($searchTerm: String) {
          viewer {
            ...SearchResults_viewer @arguments(searchTerm: $searchTerm)
          }
        }
      `,
    })
  }, [searchStr, debounceSearch])

  const clearSearch = useCallback(() => {
    navigate({})
    setSearchStr('')
  }, [setSearchStr, navigate])

  const doSearch = useCallback(
    (e: any) => {
      if (e.target.value) {
        navigate({ search: qs.stringify({ search: e.target.value }, { addQueryPrefix: true }) })
        setSearchStr(e.target.value)
      } else {
        clearSearch()
      }
    },
    [setSearchStr, navigate, clearSearch]
  )

  const trackData = useMemo(() => (debounceSearch ? { search_query: debounceSearch } : null), [debounceSearch])

  useEffect(() => {
    if (!trackData) return

    trackEvent('search_submitted', trackData)
  }, [trackData, trackEvent])

  return (
    <>
      {!trackData && <PageViewTracker trackId="dashboard" />}
      <DashboardSearchBar>
        <FilterSearchDashboard as="label" htmlFor="dashboard-search">
          {!searchStr && <SearchIcon $huge icon="search" />}
          <FilterSearchInputHuge
            as={InputAutosize}
            id="dashboard-search"
            name="dashboard-search"
            placeholder={intl.formatMessage({ id: 'dashboard.search_events' })}
            onChange={doSearch}
            value={searchStr}
            autoComplete="off"
          />
          {searchStr && <ClearIcon icon="close-view" onClick={clearSearch} />}
        </FilterSearchDashboard>
      </DashboardSearchBar>
      {searchStr && <Results />}
    </>
  )
}

export default DashboardSearch
