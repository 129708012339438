import { createGlobalStyle } from 'styled-components/macro'
import { color, font, input, zIndex } from '../../utils/variables'

export const ReactSelectStyles = createGlobalStyle`
  .react-select {
    display: block;
    font-size: ${input.fontSize}px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: ${input.borderWidth}px solid ${input.borderColor};
      border-radius: 6px;
      pointer-events: none;
      transition: border-color .1s cubic-bezier(0.4, 0.0, 0.6, 1);
      z-index: 1;
    }
    &.-disabled {
      color: ${color.darkgrey};

      .react-select__control {
        pointer-events: none;
        cursor: not-allowed;
        color: ${color.darkgrey};
      }
    }
    &.-has-error {
      &:before {
        border-color: ${input.errorColor};
      }
    }

    &:hover {
      &:before {
        border-color: ${input.borderColorHover};
      }
    }
  }

  .react-select__control {
    display: flex;
    position: relative;
    padding: ${input.borderWidth}px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: ${input.height}px;
    &--menu-is-open {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: ${input.borderWidth}px solid ${input.borderColorActive};
        border-radius: 6px;
        pointer-events: none;
        transition: border-color .1s cubic-bezier(0.4, 0.0, 0.6, 1);
        z-index: 1;
      }
    }
    .react-select.-disabled & {
      background: ${color.palegrey};
    }
  }
  .react-select__value-container {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    .react-select__single-value {
      padding: ${input.paddingVertical}px 0 ${input.paddingVertical}px ${input.paddingHorizontal}px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      right: 1px;
      left: 1px;
      bottom: 1px;
      top: 1px;
      line-height: 28px;
    }
    .react-select.-disabled & {
      background: ${color.palegrey};
      .react-select__single-value {
        padding: ${input.paddingVertical}px ${input.paddingHorizontal}px;
      }
    }
  }
  .react-select__value-container--is-multi {
    &.react-select__value-container--has-value {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .react-select__placeholder {
    font-size: inherit;
    font-weight: inherit;
    color: ${color.grey};
  }

  .react-select__placeholder {
    padding: 4px 0 4px 16px;
    line-height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    right: 1px;
    left: 1px;
    bottom: 1px;
    top: 1px;
  }

  .react-select__input {
    & > input {
      display: block;
      overflow: hidden;
      background: ${color.white} !important;
      border: 0 none;
      box-shadow: none;
      cursor: default;
      font-family: inherit;
      margin: 0;
      outline: none;
      padding: 6px ${input.paddingHorizontal}px !important;
      min-height: 24px;
      &::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
    }

    .react-select.-disabled & {
      display: none !important
    }
  }

  .react-select__indicators {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    padding: 0 6px;
  }

  .react-select__indicator {}
  .react-select__clear-indicator {
    font-size: 0;
    line-height: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .react-select__control--menu-is-open & {
      transform: rotate(180deg);
    }
  }

  .react-select__menu {
    background-color: ${color.white};
    position: absolute;
    top: 100%;
    width: 100%;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    z-index: ${zIndex.dropdown};
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
  }

  .react-select__menu.-top {
    top: unset;
    bottom: 100%;
  }

  .react-select__menu-list {
    max-height: 240px;
    overflow-y: auto;
  }

  .react-select__option {
    background-color: ${color.white};
    color: ${color.text};
    cursor: pointer;
    display: block;
    padding: 8px 16px;
    &--is-focused {
      background-color: ${color.palegrey};
      color: ${color.text};
    }
    &--is-selected {
      padding-right: 40px;
      position: relative;
      &:before {
        content: '';
        display: block;
        height: 5px;
        width: 10px;
        border-left: 2px solid ${color.primary};
        border-bottom: 2px solid ${color.primary};
        position: absolute;
        top: 50%;
        right: 16px;
        transform: rotate(-45deg);
        margin-top: -5px;
      }
    }
  }

  .react-select__menu-notice {
    box-sizing: border-box;
    color: ${color.darkgrey};
    cursor: default;
    display: block;
    padding: 16px;
  }

  .react-select__single-value {}
  .react-select__multi-value {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    margin: 4px 0px 4px 4px;
    padding: 4px 8px;
    min-height: 28px;
    background: ${color.lightgrey};
    border-radius: 6px;
    & + div > .react-select__input > input {
      padding: 6px !important;
    }
  }

  .react-select__multi-value__label {}
  .react-select__multi-value__remove {
    display: flex;
    width: 16px;
    margin: 0 -4px 0 4px;
    align-items: center;
    cursor: pointer;
    .react-select.-disabled & {
      display: none;
    }
  }

  .react-select__group-heading {
    color: ${color.greyer};
    font-size: ${font.size.xs}px;
    line-height: 120%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    padding: 16px 16px 8px 16px;
  }
`
