import React, { FC, memo, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import IconButton from '../../../components/IconButton'
import { color } from '../../../utils/variables'

const BigIconButton = styled(IconButton)`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 4px;

  margin-bottom: 8px;

  &.icon-button + .icon-button {
    margin-left: 0;
  }

  &.icon-button.-color-primary {
    color: ${color.primary};
    background-color: ${color.white};
    border: 2px solid ${color.primary};
  }
`

const EventTicketTypeIconInput: FC<
  React.PropsWithChildren<{
    active: boolean
    disabled: boolean
    icon: string
    onChange: (icon: string | null) => void
  }>
> = ({ active, disabled, icon, onChange }) => {
  const intl = useIntl()
  const onClick = useCallback(() => onChange(icon), [onChange, icon])
  return (
    <BigIconButton
      icon={`ticket-type-${icon}`}
      outlineColor={active ? undefined : 'lightgrey'}
      color={active ? 'primary' : undefined}
      width={32}
      height={32}
      onClick={onClick}
      data-id={`iconButton[${icon}]`}
      disabled={disabled}
      title={intl.formatMessage({ id: `new_event.tickets.ticket_type_edit.icon.tooltip.${icon}` })}
    />
  )
}

export default memo(EventTicketTypeIconInput)
