import React, { FC, memo, useCallback } from 'react'
import { get, getOr, snakeCase } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useFormikContext } from 'formik'

import { FormRow } from '../../../components/Form'
import FormGroup from '../../../components/FormGroup'
import { mediaQuery } from '../../../utils/variables'
import IEventFormInformation from '../types/Information'
import Checkbox from '../../../components/Checkbox'
import FormField from '../../../components/FormField'
import Collapsible from '../../../components/Collapsible'

const CovidItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 16px;
  }

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const Title = styled.div`
  flex: 1;
  margin-right: 8px;

  ${mediaQuery.lessThan('tablet')`
    margin-bottom: 8px;
  `}
`

const Options = styled.div`
  ${Checkbox} + ${Checkbox} {
    margin-left: 32px;
  }
`

const ProofList = styled.ul`
  display: flex;
  align-items: center;

  margin: 0 -16px -16px -16px;

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
    align-items: flex-start;
  `}

  & > li {
    margin: 16px;
  }
`

const ProofHint = styled.div`
  margin-top: 16px;
`

const RESTRICTIONS = ['maskRequired', 'socialDistancing', 'proofOfBeingHealthy']
const PROOFS = ['covidPcr', 'covidRecovery', 'covidVaccination']

interface IProps {
  disabled?: boolean
}

const EventCovidRestrictions: FC<React.PropsWithChildren<IProps>> = ({ disabled }) => {
  const { values, setFieldValue, setFieldTouched, touched, errors, handleBlur } =
    useFormikContext<IEventFormInformation>()

  const handleChange = useCallback(
    (e: any) => {
      const field = e.target.name

      if (!field?.startsWith('eventRules')) return

      let value = null
      if (field === 'eventRules.covidPolicyUrl') {
        value = e.target.value
      } else if (field === 'eventRules.covidPcrValidHours') {
        value = Number(e.target.value)
      } else {
        value = e.target.value === 'yes'
      }

      setFieldValue(field, value, field !== 'eventRules.covidPcrValidHours')
      setFieldTouched(field, true, field !== 'eventRules.covidPcrValidHours')
    },
    [setFieldTouched, setFieldValue]
  )

  const handleRestrictionChange = useCallback(
    (e: any) => {
      const field = e.target.name
      if (!field?.startsWith('eventRules')) return

      setFieldValue(field, e.target.value && e.target.checked ? e.target.value === 'yes' : null)
      setFieldTouched(field, true)

      if (field === 'eventRules.proofOfBeingHealthy') {
        setFieldValue('eventRules.covidPcrValidHours', null, true)

        PROOFS.forEach((p) => {
          setFieldValue(`eventRules.${p}`, null, true)
        })
      }
    },
    [setFieldTouched, setFieldValue]
  )

  const handleProofChange = useCallback(
    (e: any) => {
      const field = e.target.name

      if (!field?.startsWith('eventRules')) return

      const value = e.target.checked

      setFieldValue(field, value, true)
      setFieldTouched(field, true, true)

      if (field === 'eventRules.covidPcr') {
        setFieldValue('eventRules.covidPcrValidHours', null, true)
      }
    },
    [setFieldTouched, setFieldValue]
  )

  const intl = useIntl()
  return (
    <Collapsible
      dataId="informationCovid19Settings"
      label={intl.formatMessage({ id: 'new_event.information.covid.label' })}
    >
      <FormRow columnOnMobile>
        <FormGroup
          // required
          error={getOr(false, ['eventRules'], touched) && getOr(false, ['eventRules', 'proofOfBeingHealthy'], errors)}
        >
          <ul>
            {RESTRICTIONS.map((r) => (
              <CovidItem key={r}>
                <Title>{intl.formatMessage({ id: `new_event.information.covid.options.${snakeCase(r)}.label` })}</Title>
                <Options>
                  <Checkbox
                    name={`eventRules.${r}`}
                    label={intl.formatMessage({ id: 'required' })}
                    value="yes"
                    checked={get(['eventRules', r], values) === true}
                    disabled={disabled}
                    onChange={handleRestrictionChange}
                    onBlur={handleBlur}
                    hasError={touched.eventRules && getOr(false, ['eventRules', r], errors)}
                  />
                  <Checkbox
                    name={`eventRules.${r}`}
                    label={intl.formatMessage({ id: 'not_required' })}
                    value="no"
                    checked={get(['eventRules', r], values) === false}
                    disabled={disabled}
                    onChange={handleRestrictionChange}
                    onBlur={handleBlur}
                    hasError={touched.eventRules && getOr(false, ['eventRules', r], errors)}
                  />
                </Options>
              </CovidItem>
            ))}
          </ul>
          {values.eventRules?.proofOfBeingHealthy && (
            <div>
              <ProofHint>{intl.formatMessage({ id: 'new_event.information.covid.select_proof_hint' })}</ProofHint>

              <ProofList>
                {PROOFS.map((p) => (
                  <li key={p}>
                    <Checkbox
                      name={`eventRules.${p}`}
                      label={intl.formatMessage({ id: `new_event.information.covid.options.${snakeCase(p)}.label` })}
                      checked={get(['eventRules', p], values)}
                      onChange={handleProofChange}
                      onBlur={handleBlur}
                      disabled={disabled}
                      hasError={getOr(false, ['eventRules', p], touched) && getOr(false, ['eventRules', p], errors)}
                    />
                  </li>
                ))}
              </ProofList>
            </div>
          )}
        </FormGroup>
      </FormRow>
      {values.eventRules?.covidPcr && (
        <FormRow columnOnMobile>
          <FormField
            name="eventRules.covidPcrValidHours"
            label={intl.formatMessage({ id: 'new_event.information.covid.pcr_valid_hours.label' })}
            value={values.eventRules?.covidPcrValidHours || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            error={getOr(false, ['eventRules'], touched) && getOr(false, ['eventRules', 'covidPcrValidHours'], errors)}
            required
            type="number"
            min={1}
            step={1}
            max={99}
          />
        </FormRow>
      )}
      <FormRow columnOnMobile>
        <FormField
          name="eventRules.covidPolicyUrl"
          label={intl.formatMessage({ id: 'new_event.information.covid.policy_url.label' })}
          hint={intl.formatMessage({ id: 'new_event.information.covid.policy_url.hint' })}
          value={values.eventRules?.covidPolicyUrl || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          error={getOr(false, ['eventRules'], touched) && getOr(false, ['eventRules', 'covidPolicyUrl'], errors)}
        />
      </FormRow>
    </Collapsible>
  )
}

export default memo(EventCovidRestrictions)
