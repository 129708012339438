import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { every, isEmpty } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { color } from '../utils/variables'
import { INote, INoteProps, NoteComponent } from './NotesCollapsible'

type NotesTab = 'notes' | 'internalNotes'

export interface INotesViewerProps {
  loading?: boolean
  notes: ReadonlyArray<{
    type: INoteProps['type']
    notes: ReadonlyArray<INote>
  }>
  internalNotes?: string | null
  border?: boolean
}

const NotesViewer: FC<INotesViewerProps> = ({ loading, notes, internalNotes, border = false }) => {
  const intl = useIntl()

  const noNotes = useMemo(() => every((n) => isEmpty(n.notes), notes), [notes])
  const [currentTab, setCurrentTab] = useState<NotesTab>('notes')

  const toggleNotes = useCallback((e: React.SyntheticEvent<HTMLButtonElement>) => {
    const tab = e.currentTarget.dataset['tab'] || 'notes'
    setCurrentTab(tab as NotesTab)
  }, [])

  return (
    <>
      <NotesSubnav>
        <NavItem onClick={toggleNotes} data-tab="notes" className={currentTab === 'notes' ? '-active' : undefined}>
          {intl.formatMessage({ id: 'new_event.settings.notes.label' })}
        </NavItem>
        <NavItem
          onClick={toggleNotes}
          data-tab="internalNotes"
          className={currentTab === 'internalNotes' ? '-active' : undefined}
        >
          {intl.formatMessage({ id: 'new_event.settings.extra_notes.label' })}
        </NavItem>
      </NotesSubnav>
      <InnerContainer border={border}>
        <Content isLoading={!!loading}>
          {currentTab === 'notes' ? (
            noNotes ? (
              <p>{intl.formatMessage({ id: 'new_event.pv_notes.empty' })}</p>
            ) : (
              notes.map((n, idx) => n.notes.length > 0 && <NoteComponent key={idx} type={n.type} section={n.notes} />)
            )
          ) : (
            <>
              {!!internalNotes ? internalNotes : <p>{intl.formatMessage({ id: 'new_event.internal_notes.empty' })}</p>}
            </>
          )}
        </Content>
      </InnerContainer>
    </>
  )
}

export default memo(NotesViewer)

const NotesSubnav = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
`

const NavItem = styled.button`
  background: none;
  border: none;
  padding: 12px 0px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;

  &.-active {
    color: ${color.primary};
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      margin-top: 8px;
      margin-bottom: -10px;
      background: currentColor;
    }
  }
`

const InnerContainer = styled.div<{ border: boolean }>`
  flex-grow: 1;
  display: block;
  background: ${color.white};
  overflow: auto;
  height: 100%;

  ${({ border }) =>
    border &&
    `
      border: 2px solid ${color.lightgrey};
      border-radius: 4px;
      padding: 12px 16px;  
  `}
`

const Content = styled.div<{ isLoading: boolean }>`
  line-height: 1.14em;
  overflow-y: auto;
  white-space: pre-wrap;
  ${({ isLoading }) => isLoading && 'opacity: 0.5;'}
`
