/**
 * @generated SignedSource<<442b50cff0b588ef9e38d43ce26bc4e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeAllocationInput = {
  allocation: number;
  clientMutationId: string;
  id: string;
};
export type ChangeProductAllocationModalMutation$variables = {
  input: ChangeAllocationInput;
};
export type ChangeProductAllocationModalMutation$data = {
  readonly changeAllocation: {
    readonly object: {
      readonly allocation: number;
      readonly id: string;
      readonly lockVersion: number;
      readonly productsSales: {
        readonly productBreakdown: ReadonlyArray<{
          readonly product: {
            readonly allocation: number;
            readonly id: string;
            readonly name: string;
            readonly variants: ReadonlyArray<{
              readonly allocation: number;
              readonly id: string;
              readonly name: string;
            } | null>;
          };
          readonly totalAppSold: number;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type ChangeProductAllocationModalMutation = {
  response: ChangeProductAllocationModalMutation$data;
  variables: ChangeProductAllocationModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeAllocationPayload",
    "kind": "LinkedField",
    "name": "changeAllocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "object",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductsSales",
            "kind": "LinkedField",
            "name": "productsSales",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductBreakdownItem",
                "kind": "LinkedField",
                "name": "productBreakdown",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Variant",
                        "kind": "LinkedField",
                        "name": "variants",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalAppSold",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeProductAllocationModalMutation",
    "selections": (v4/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeProductAllocationModalMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "76d759c62cf61d251e460cfc62d8012c",
    "id": null,
    "metadata": {},
    "name": "ChangeProductAllocationModalMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeProductAllocationModalMutation(\n  $input: ChangeAllocationInput!\n) {\n  changeAllocation(input: $input) {\n    object {\n      id\n      allocation\n      lockVersion\n      productsSales {\n        productBreakdown {\n          product {\n            id\n            name\n            allocation\n            variants {\n              id\n              name\n              allocation\n            }\n          }\n          totalAppSold\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "711c9025234bfba0ef09fd395b1c908c";

export default node;
