import React, { ComponentProps, FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Outlet } from 'react-router'
import { MarkOptional } from 'ts-essentials'

import GenericError, { GenericErrorSub } from '../../components/GenericError'
import PermissionCheck from '../../components/PermissionCheck'

type IProps = MarkOptional<ComponentProps<typeof PermissionCheck> & { sub?: boolean }, 'children'>

const PermissionGate: FC<IProps> = ({ children, sub, ...props }) => {
  const intl = useIntl()

  const genericFallback = useMemo(() => {
    const ErrorComponent = sub ? GenericErrorSub : GenericError
    return (
      <ErrorComponent
        icon="access-denied"
        title={intl.formatMessage({ id: 'generic_error.access_denied_title' })}
        description={intl.formatMessage({ id: 'generic_error.access_denied_description' })}
        backText={sub ? undefined : intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
        backLink={sub ? undefined : '/dashboard'}
      />
    )
  }, [intl, sub])

  return (
    <PermissionCheck {...props} fallback={props.fallback || genericFallback}>
      {children || <Outlet />}
    </PermissionCheck>
  )
}

export default PermissionGate
