/**
 * @generated SignedSource<<21793cb3ee82f99e4aad7ebe9c55c63e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FinishStripeOnboardingInput = {
  clientMutationId: string;
  onboardingCode: string;
  state: string;
};
export type StripeOnboardingPageMutation$variables = {
  input: FinishStripeOnboardingInput;
};
export type StripeOnboardingPageMutation$data = {
  readonly finishStripeOnboarding: {
    readonly promoter: {
      readonly id: string;
    } | null;
  } | null;
};
export type StripeOnboardingPageMutation = {
  response: StripeOnboardingPageMutation$data;
  variables: StripeOnboardingPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FinishStripeOnboardingPayload",
    "kind": "LinkedField",
    "name": "finishStripeOnboarding",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Promoter",
        "kind": "LinkedField",
        "name": "promoter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StripeOnboardingPageMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StripeOnboardingPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ad3dec92fe754c8065e5b0c12655a40d",
    "id": null,
    "metadata": {},
    "name": "StripeOnboardingPageMutation",
    "operationKind": "mutation",
    "text": "mutation StripeOnboardingPageMutation(\n  $input: FinishStripeOnboardingInput!\n) {\n  finishStripeOnboarding(input: $input) {\n    promoter {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6db5238892b6d609a2c0f783865f5e31";

export default node;
