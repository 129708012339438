/**
 * @generated SignedSource<<817e323a89e5f9f02fb69ba46925f7f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventSuccess_event$data = {
  readonly date: string | null;
  readonly eventIdLive: string | null;
  readonly id: string;
  readonly organicSocialLink: string | null;
  readonly recurrentEventsGroup: ReadonlyArray<{
    readonly date: string | null;
    readonly id: string;
    readonly state: EventState | null;
    readonly " $fragmentSpreads": FragmentRefs<"EventSuccessRow_attrs">;
  } | null> | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventSuccessRow_attrs">;
  readonly " $fragmentType": "EventSuccess_event";
};
export type EventSuccess_event$key = {
  readonly " $data"?: EventSuccess_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventSuccess_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "EventSuccessRow_attrs"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventSuccess_event",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organicSocialLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "recurrentEventsGroup",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "64fa5d8b938d81bbad5f536025da8891";

export default node;
