import { compact, find, map, reject } from 'lodash/fp'
import { markAsClientOnly } from '../../../utils/entityStatus'
import { CreateContractPageNodeQuery } from '../../../__generated__/CreateContractPageNodeQuery.graphql'
import ICreateContractForm from '../types'
import { IFeeRange } from '../types/Fees'
import { IProductRule } from '../types/ProductFees'

export const convertContract = (
  contract: CreateContractPageNodeQuery['response']['template'],
  makeCopy?: boolean
): ICreateContractForm | null => {
  const ticketsFee = find(['feeTarget', 'tickets'], contract?.feesApplicationRules || [])
  const productsFee = reject(['feeTarget', 'tickets'], contract?.feesApplicationRules || [])
  return contract
    ? {
      id: makeCopy ? null : contract.id || null,
      effectiveDate: makeCopy ? null : contract.effectiveDate || null,
      endDate: makeCopy ? null : contract.endDate || null,

      taxExempt: !!contract.taxExempt,
      taxId: contract.taxId || null,

      boxOfficeFee: contract.boxOfficeFee || null,

      basePriceFees: contract.basePriceFees ? [...contract.basePriceFees] : null,
      postFanPriceFees: contract.postFanPriceFees ? [...contract.postFanPriceFees] : null,

      ticketFeeRanges: ticketsFee?.feeRanges
        ? compact(
          ticketsFee.feeRanges.map(
            (fr, idx) =>
              fr &&
                  markAsClientOnly<IFeeRange>(
                    {
                      fromBasePrice: fr.fromBasePrice,
                      _preconfigured: true,
                      fees: fr.fees
                        ? compact(
                          map(
                            (fee) =>
                              fee && {
                                amount: fee.amount,
                                type: fee.type,
                                unit: fee.unit,
                                split: fee.split ? [...fee.split] : null,
                              },
                            fr.fees
                          )
                        )
                        : [],
                    },
                    `copy${idx}`
                  )
          )
        )
        : null,
      products: productsFee
        ? compact(
          productsFee.map((feeRule) =>
            markAsClientOnly<IProductRule>({
              feeTarget: feeRule?.feeTarget || null,
              parentCategory: feeRule?.feeTargetCategories?.[0]?.parentCategory || null,
              feeTargetCategories: feeRule?.feeTargetCategories ? compact(feeRule?.feeTargetCategories) : null,
              feeRanges: feeRule?.feeRanges
                ? compact(
                  feeRule?.feeRanges.map(
                    (fr, idx) =>
                      fr &&
                            markAsClientOnly<IFeeRange>(
                              {
                                fromBasePrice: fr.fromBasePrice,
                                _preconfigured: true,
                                fees: fr.fees
                                  ? compact(
                                    map(
                                      (fee) =>
                                        fee && {
                                          amount: fee.amount,
                                          type: fee.type,
                                          unit: fee.unit,
                                          split: fee.split ? [...fee.split] : null,
                                        },
                                      fr.fees
                                    )
                                  )
                                  : [],
                              },
                              `copy${idx}`
                            )
                  )
                )
                : null,
            })
          )
        )
        : null,
      allowedAdhocFeeTypes: contract.allowedAdhocFeeTypes
        ? compact(
          map(
            (ahf) =>
              ahf && {
                amount: 0,
                type: ahf.type,
                unit: ahf.unit,
                split: ahf.split ? [...ahf.split] : null,
              },
            contract.allowedAdhocFeeTypes
          )
        )
        : null,
    }
    : null
}
