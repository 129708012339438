import React, { memo, useCallback, useContext, FC, ChangeEvent } from 'react'
import styled from 'styled-components/macro'
import { FormikTouched, FormikValues, FormikErrors, useFormikContext } from 'formik'
import { reject, get, set } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { mediaQuery, color } from '../../../utils/variables'
import IconButton from '../../../components/IconButton'
import FormGroup from '../../../components/FormGroup'
import { FlexFormField } from '../../../components/FormField'
import { localeContext } from '../../../context/locale'
import { ILocale } from '../../../intl'

import { FormRow } from '../../../components/Form'
import IEventFormSettings from '../types/Settings'

const rejectWithIdx = (reject as any).convert({ cap: false })

const AddPredictionButton = styled(IconButton)`
  margin-right: 16px;
`

const AddPredictionLabel = styled.label`
  display: block;
  margin-top: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 250px;
`

const PredictionItem = styled.div`
  display: flex;

  & > div {
    flex: 3;
    margin-left: 8px;
  }
  & > div:first-child {
    flex: 5;
    margin-left: 0;
  }

  ${mediaQuery.lessThan('desktop')`
    flex-wrap: wrap;
    & > div {
      min-width: 100%;
      margin-left: 0;
      margin-top: 8px;
    }
  `}
`

const PredictionRow = styled.div`
  position: relative;
  background-color: ${color.white};
  margin-bottom: 16px;

  ${mediaQuery.lessThan('desktop')`
    padding: 0 40px 0 0;

    &:first-child {
      padding: 0 40px 0 0;
    }
  `}
`

const RemoveButton = styled(IconButton)`
  ${mediaQuery.lessThan('desktop')`
    margin: 0;
    position: absolute;
    right: -40px;
    top: -24px;
  `}
`

interface IItemProps {
  idx: number
  prediction: ILoadPrediction
  timezone?: string
  locale: ILocale
  setLoadPrediction: (idx: number, value: ILoadPrediction) => void
  handleBlur?: (...args: any[]) => any
  removePrediction: (idx: number) => void
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
}

const LoadPredictionRow: FC<IItemProps> = ({
  idx,
  prediction,
  handleBlur,
  setLoadPrediction,
  removePrediction,
  touched,
  errors,
  locale,
  timezone,
}: IItemProps) => {
  const { expectedStartTime, expectedRequestsPerMinute } = prediction

  const intl = useIntl()
  const onRemove = useCallback(() => removePrediction(idx), [removePrediction, idx])

  const handleChangeRpm = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value || ''
      setLoadPrediction(idx, set('expectedRequestsPerMinute', parseInt(v), prediction))
    },
    [setLoadPrediction, idx, prediction]
  )

  const handleChangeTime = useCallback(
    (nm: string, date: string) => {
      setLoadPrediction(idx, set('expectedStartTime', date, prediction))
    },
    [idx, prediction, setLoadPrediction]
  )

  return (
    <PredictionRow>
      <PredictionItem>
        <FlexFormField
          name={`eventLoadPredictions[${idx}].expectedStartTime`}
          label={idx === 0 && intl.formatMessage({ id: 'new_event.settings.load_predictions.expectedStartTime.label' })}
          value={expectedStartTime || ''}
          timezone={timezone || undefined}
          placement="top-start"
          placeholder={intl.formatMessage({ id: 'new_event.settings.load_predictions.expectedStartTime.placeholder' })}
          control="datetime"
          onBlur={handleBlur}
          setFieldValue={handleChangeTime}
          error={
            get(`eventLoadPredictions[${idx}].expectedStartTime`, touched) &&
            get(`eventLoadPredictions[${idx}].expectedStartTime`, errors)
          }
          locale={locale}
        ></FlexFormField>

        <FlexFormField
          name={`eventLoadPredictions[${idx}].expectedRequestsPerMinute`}
          label={
            idx === 0 &&
            intl.formatMessage({ id: 'new_event.settings.load_predictions.expectedRequestsPerMinute.label' })
          }
          type="number"
          max={10000}
          onChange={handleChangeRpm}
          value={expectedRequestsPerMinute || ''}
          onBlur={handleBlur}
          placeholder={intl.formatMessage({
            id: 'new_event.settings.load_predictions.expectedRequestsPerMinute.placeholder',
          })}
          error={
            get(`eventLoadPredictions[${idx}].expectedRequestsPerMinute`, touched) &&
            get(`eventLoadPredictions[${idx}].expectedRequestsPerMinute`, errors)
          }
        >
          <RemoveButton icon="trash" onClick={onRemove} ddata-id={`removePrediction[${idx}]`} />
        </FlexFormField>
      </PredictionItem>
    </PredictionRow>
  )
}

export interface ILoadPrediction {
  id: string | null
  expectedRequestsPerMinute: number | null
  expectedStartTime: string | null
}

const EventLoadPredictions: FC<React.PropsWithChildren> = ({}) => {
  const intl = useIntl()

  const { values, setFieldValue, errors, touched, handleBlur } = useFormikContext<IEventFormSettings>()
  const predictions = values.eventLoadPredictions

  const setLoadPrediction = useCallback(
    (idx: number, value: ILoadPrediction) => {
      const newPredictions = [...(predictions || [])]
      newPredictions.splice(idx, 1, value)
      setFieldValue('eventLoadPredictions', newPredictions)
    },
    [predictions, setFieldValue]
  )

  const addPrediction = useCallback(() => {
    setFieldValue('eventLoadPredictions', [
      ...(predictions || []),
      { id: null, expectedStartTime: null, expectedRequestsPerMinute: null } as ILoadPrediction,
    ])
  }, [predictions, setFieldValue])

  const removePrediction = useCallback(
    (removedIdx: any) => {
      setFieldValue(
        'eventLoadPredictions',
        rejectWithIdx((_: any, idx: number) => idx === removedIdx, predictions)
      )
    },
    [predictions, setFieldValue]
  )

  const { locale } = useContext(localeContext)

  return (
    <div className="mt-lg mb-lg">
      <FormRow columnOnMobile>
        <FormGroup
          dice
          label={intl.formatMessage({ id: 'new_event.settings.load_predictions.label' })}
          hint={intl.formatMessage({ id: 'new_event.settings.load_predictions.hint' })}
        />
      </FormRow>

      <div className="mt-lg">
        {predictions?.map(
          (p, idx) =>
            p && (
              <LoadPredictionRow
                key={p.id || idx}
                idx={idx}
                prediction={p}
                locale={locale}
                setLoadPrediction={setLoadPrediction}
                removePrediction={removePrediction}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
              />
            )
        )}
      </div>

      <AddPredictionLabel>
        <AddPredictionButton icon="add" color="black" onClick={addPrediction} data-id="addPrediction" />
        {intl.formatMessage({ id: 'new_event.settings.load_predictions.add_prediction' })}
      </AddPredictionLabel>
    </div>
  )
}

export default memo(EventLoadPredictions)
