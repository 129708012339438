import { set, pick, map, isNil, compose, update, reject as rejectFn, compact, reject, unset, sortBy } from 'lodash/fp'
import { DeepReadonly } from 'ts-essentials'
import { Environment } from 'react-relay'

import { convertProduct, convertTty as convertTtyFull } from '../../EventForm/services/convertEvent'
import IEventForm from '../../EventForm/types'
import { ITicketType } from '../../EventForm/types/Tickets'
import { markAsServer } from '../../../utils/entityStatus'
import { splitId } from '../../../utils/unwrapId'
import uploadEventImages from '../../EventForm/services/uploadEventImages'
import convertMedia from '../../EventForm/services/convertMedia'
import { MinorTicketTypeInput } from '../../../__generated__/minorUpdateEventMutation.graphql'
import createOrUpdateTicketPools from '../../EventForm/services/createOrUpdateTicketPools'
import { getAlpha2ByName } from '../../../utils/countries'
import { ILocale } from '../../../intl'
import createOrUpdateProducts from '../../EventForm/services/createOrUpdateProducts'
import { IProduct } from '../../EventForm/types/Extras'
import { renderMarkdown } from '../../../utils/markdown'
import { EXTRAS_TYPES_ORDER } from '../../EventForm/steps/Extras'

export const convertTty: (...args: Parameters<typeof convertTtyFull>) => Promise<MinorTicketTypeInput | null> = (
  ...args
) =>
  convertTtyFull(...args).then(
    compose([
      (tt) =>
        tt &&
        update(
          'priceTiers',
          (arr) =>
            arr &&
            compose([
              map(
                pick(['allocation', 'attractivePriceType', 'faceValue', 'doorSalesPrice', 'id', 'name', 'time', 'fees'])
              ),
              (pt) => pt && set('fees', pt.id ? undefined : pt.fees, pt),
              (pt) => pt && set('allocation', pt.id ? undefined : pt.allocation, pt),
            ])(arr),
          tt
        ),
      (tt) =>
        tt &&
        pick(
          [
            'allocation',
            'announceDate',
            'archived',
            'attractivePriceType',
            'attractiveSeatingAreaType',
            'description',
            'doorSalesEnabled',
            'doorSalesPrice',
            'externalSkus',
            'faceValue',
            'hidden',
            'codeLocked',
            'icon',
            'id',
            'increment',
            'isStream',
            'maximumIncrements',
            'name',
            'offSaleDate',
            'onSaleDate',
            'order',
            'productIds',
            'priceGrade',
            'priceTierType',
            'priceTiers',
            'requiresAddress',
            'seatmapUrl',
            'streamEmbedCode',
            'streamLink',
            'venueScheduleId',
            'venueScheduleIndex',
            'fees',
            'allowSeatChange',
            'reservedSeating',
            'reservedSeatingType',
            'seatCategories',
            'ticketPoolId',
            'salesLimit',
            'startDate',
            'endDate',
            'priceHidden',
          ],
          tt
        ),
      (tt) => tt && set('fees', tt.id ? undefined : tt.fees, tt),
      (tt) => tt && set('allocation', tt.id ? undefined : tt.allocation, tt),
    ])
  )

const convertEventMinor = async (environment: Environment, values: Partial<IEventForm>, locale: ILocale) => {
  const eventImages = await uploadEventImages(environment, values)
  const ticketPool = await createOrUpdateTicketPools(environment, values.ticketPools)
  const ticketPoolIds = ticketPool?.ids || []
  const ticketPoolMap = ticketPool?.poolsMap

  const product = await createOrUpdateProducts(environment, values.products)
  const productList = sortBy((product: IProduct) => {
    const idx = EXTRAS_TYPES_ORDER.indexOf(product?.category?.parentCategory?.type || '')
    return idx >= 0 ? idx : 99999999
  }, product?.products)
  const products = await Promise.all(map(async (p) => await convertProduct(environment, p as IProduct), productList))
  const productsMap = product?.productsMap

  const media = convertMedia(values)

  let ticketTypes = await Promise.all(
    map(
      (tt: ITicketType): Promise<MinorTicketTypeInput | null> =>
        convertTty(
          environment,
          tt as DeepReadonly<ITicketType>,
          values.announceDate,
          values.onSaleDate,
          values.offSaleDate,
          values.eventType || null,
          values.venueSchedules || null,
          values.products || null,
          false
        ),
      reject(
        (tt: ITicketType & { __justArchived?: boolean }) => tt.archived && !tt.__justArchived,
        values.ticketTypes || []
      )
    ) as any as Promise<MinorTicketTypeInput | null>[]
  )

  if (values.ticketPools && ticketPoolMap) {
    ticketTypes = map(
      compose([
        (t) => (t && t.ticketPoolId ? set('ticketPoolId', ticketPoolMap.get(t.ticketPoolId) || null, t) : t),
        set('allocation', 0),
      ]),
      ticketTypes
    )
  }

  if (values.products && productsMap) {
    ticketTypes = map(
      (t) =>
        t && t.productIds
          ? set(
            'productIds',
            map((productId) => productId && productsMap.get(productId), t.productIds || []),
            t
          )
          : t,
      ticketTypes
    )
  }

  if (values.eventType === 'STREAM' && !isNil(values.maxTicketsLimit)) {
    // These fields are absent in UI for stream events, so setting to event-level default
    ticketTypes = map(
      compose([set('increment', 1), set('maximumIncrements', values.maxTicketsLimit || 0)]),
      ticketTypes
    )
  }

  // Unset allocation fields when we're updating live seats.io (seating v2) events,
  // So we don't overwrite proper seatmap capacity,
  // As this is forbidden by backend.
  const isUpdateOfLiveSeatedEvent = !!values.eventSeatingChart?.id
  if (isUpdateOfLiveSeatedEvent) {
    ticketTypes = map(
      (tty) =>
        // prettier-ignore
        !!tty?.reservedSeating
          ? (compose([
            update('priceTiers', (arr) => arr && map(unset('allocation'), arr)),
            unset('allocation'),
          ])(tty) as typeof tty)
          : tty,
      ticketTypes
    )
  }

  const payload = {
    ...pick(
      [
        'id',
        'name',
        'date',
        'endDate',
        'announceDate',
        'onSaleDate',
        'offSaleDate',
        'closeEventDate',
        'extraNotes',
        'description',
        'lineup',
        'doorlistAdditionalRecipients',
        'ageLimit',
        'presentedBy',
        'maxTicketsLimit',
        'restrictCountries',
        'restrictCountriesKind',
        'diceStreamDuration',
        'diceStreamDvrEnabled',
        'diceStreamRewatchEnabledUntil',
        'lockVersion',
        'requiresTicketNomination',
        'requiresBoxOfficeTicketNomination',
        'manualValidationEnabled',
        'sendReceiptViaSms',
        'timezoneName',
        'addressCountry',
        'addressLocality',
        'addressRegion',
        'addressState',
        'addressCapacity',
        'addressSiaeCode',
        'streetAddress',
        'postalCode',
        'fullAddress',
        'latitude',
        'longitude',
        'isTicketAvailableAtDoor',
        'priceHidden',
        'onSaleNotification',
        'onSaleNotificationAt',
      ],
      values
    ),
    flags: values.flags
      ? compose(
        set('autoRescheduledEventRefunds', {
          active: values.flags?.autoRescheduledEventRefunds?.active || false,
          cutoff_days: Number(values.flags?.autoRescheduledEventRefunds?.cutoff_days) || null,
        }),
        set('coolingOffPeriod', {
          active: values.flags?.coolingOffPeriod?.active || false,
          hours: Number(values.flags?.coolingOffPeriod?.hours) || 24,
        })
      )(values.flags)
      : null,
    faqs: values.faqs
      ?.map((faq: any, index) => ({
        id: faq.id,
        order: index,
        title: faq.title,
        body: faq.body || '',
      }))
      .filter((faq) => faq.title || faq.body),
    countryCode: values.countryCode && (values.countryCode || getAlpha2ByName(values.addressCountry, locale)),
    showArtistDescription: values.showArtistDescription,
    artists:
      values.eventArtists &&
      compact(
        map((ea) => {
          if (!ea) return null

          try {
            const split = splitId(ea.artist?.value)

            if (!split) return null

            const [kind] = split

            return {
              headliner: !!ea.headliner,
              id: kind === 'Artist' ? ea.artist?.value : null,
              musicbrainzArtistId: kind === 'MusicbrainzArtist' ? ea.artist?.value : null,
              name: ea.artist?.label,
              description:
                values.artistForBio?.artist?.value === ea.artist?.value
                  ? values.artistForBio?.description
                  : ea.description,
            }
          } catch (e) {
            console.error(`Error processing artist (event) ${JSON.stringify(ea)}`, e)
            return null
          }
        }, values.eventArtists || [])
      ),
    hierarchicalTagIds: map('value', values.hierarchicalTags),
    bundleIds: map('value', values.bundles),
    marketeerIds: map('value', values.marketeers),
    additionalInfos:
      values.additionalInfos && rejectFn((v) => !(v?.content || (v?.ctaLink && v?.ctaLabel)), values.additionalInfos),
    additionalArtists:
      values.additionalArtists &&
      map(
        (artist) => ({
          ...pick(['id', 'name', 'description'])(artist),
          hierarchicalTagIds: map((ht) => ht?.value, artist?.hierarchicalTags),
        }),
        values.additionalArtists
      ),
    eventImages,
    media,
    ticketTypes,
    ticketPools: ticketPoolIds,
    products: products,
    eventRules:
      values.eventRules &&
      pick(
        [
          'maskRequired',
          'socialDistancing',
          'proofOfBeingHealthy',
          'covidPcr',
          'covidPcrValidHours',
          'covidRecovery',
          'covidVaccination',
          'covidPolicyUrl',
        ],
        values.eventRules || {}
      ),
    links: values.links && map(pick(['name', 'url']), values.links),
    tagIds: values.tags && map('value', values.tags),
    eventVenues: map(
      (p) => ({
        primary: p.value === values.primaryVenue?.value,
        venueId: p.value,
      }),
      compact(values.venues)
    ),
    venueConfigurationId: values.venueConfiguration?.value,
    venueSpaceId: values.venueSpace?.value || null,
    seatingChannels:
      values.eventSeatingChart?.seatingChannels &&
      compose([map(pick(['channelType', 'name', 'seatsIoChannel'])), compact])(
        values.eventSeatingChart?.seatingChannels || []
      ),
    featuredAreas:
      values.featuredAreas &&
      map(
        compose([
          markAsServer,
          pick([
            'countryCode',
            'description',
            'endDate',
            'id',
            'locationLat',
            'locationLng',
            'locationRadius',
            'locationString',
            'locationUnits',
            'mode',
            'startDate',
            'weight',
          ]),
        ]),
        values.featuredAreas
      ),
    eventSharingObjects:
      values.eventSharingObjects &&
      map(
        compose([
          markAsServer,
          (so) => ({
            id: so.id,
            email: so.email,
            permissionProfileId: so.permissionProfile?.id,
          }),
        ]),
        compact(values.eventSharingObjects || [])
      ),
    waitingListExchangeWindows:
      values.waitingListExchangeWindows &&
      map(
        compose([
          markAsServer,
          (wnd) => ({
            id: wnd.id,
            offset: wnd.offset,
            duration: wnd.duration || 0,
          }),
        ]),
        compact(values.waitingListExchangeWindows || [])
      ),
    thirdPartySettingsId: values.thirdPartySettings?.value,
    eventLoadPredictions: values.eventLoadPredictions?.filter(
      (p) => p?.expectedStartTime && p.expectedRequestsPerMinute
    ),
  }

  return payload
}

export default convertEventMinor
