import { useCallback } from 'react'
import { dicefmPreview } from '../dicefm'

function usePreviewEventOnDiceCallback(previewToken: string | null) {
  return useCallback(() => {
    const link = previewToken && dicefmPreview(previewToken)
    if (link) {
      window.open(link, '_blank')
    }
  }, [previewToken])
}

export default usePreviewEventOnDiceCallback
