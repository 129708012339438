import { useFormikContext } from 'formik'
import React, { Dispatch, FC, memo, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { PageViewTracker } from '../../../context/tracking'
import TermsAndConditions from '../components/TermsAndConditions'
import { ISignupForm } from '../hooks/useSignupForm'

interface IProps {
  isAgreed: boolean
  setIsAgreed: Dispatch<SetStateAction<boolean>>
}

const Step3: FC<IProps> = ({ isAgreed, setIsAgreed }) => {
  const intl = useIntl()

  const { values, handleChange, handleBlur, touched, errors } = useFormikContext<ISignupForm>()

  return (
    <>
      <PageViewTracker trackId="account_create_personal_details" />
      <FormRow columnOnMobile>
        <FormField
          name="firstName"
          label={intl.formatMessage({ id: 'account.first_name' })}
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && errors.firstName}
          autoComplete="given-name"
        />
        <FormField
          name="lastName"
          label={intl.formatMessage({ id: 'account.last_name' })}
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lastName && errors.lastName}
          autoComplete="family-name"
        />
      </FormRow>
      <FormRow columnOnMobile>
        <FormField
          name="email"
          label={intl.formatMessage({ id: 'auth.email_address' })}
          placeholder={intl.formatMessage({ id: 'auth.email_address' })}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email}
          autoComplete="email"
        />

        <FormField
          name="password"
          label={intl.formatMessage({ id: 'auth.password' })}
          placeholder={intl.formatMessage({ id: 'auth.password' })}
          hint={intl.formatMessage({ id: 'account.password_hint' })}
          control="newpassword"
          autoComplete="new-password"
          value={values.password || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && errors.password}
        />
      </FormRow>
      <FormRow columnOnMobile>
        <TermsAndConditions
          countryCode={values.country?.alpha2 || 'GB'}
          isAgreed={isAgreed}
          setIsAgreed={setIsAgreed}
        />
      </FormRow>
    </>
  )
}

export default memo(Step3)
