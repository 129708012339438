import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import AlertBox, { AlertBoxIcon } from '../../../components/AlertBox'
import Collapsible from '../../../components/Collapsible'
import RelayLoader from '../../../components/RelayLoader'
import SalesforceContract from '../../../components/SalesforceContract'
import { authContext } from '../../../context/auth'
import IEventFormBasics from '../types/Basics'
import { SalesforceDetailsQuery$data } from '../../../__generated__/SalesforceDetailsQuery.graphql'
import { color } from '../../../utils/variables'
import { IContract } from '../../../components/SalesforceContractsModal'

const Panel = styled.div`
  position: relative;

  background-color: ${color.palegrey};
  border-radius: 8px;

  padding: 24px;
`

const Frame = styled.div`
  border: 1px solid ${color.grey};
  border-radius: 8px;
`

const Warning = styled(AlertBox)`
  ${AlertBoxIcon} {
    align-self: center;
  }
`

const SalesforceDetails: FC = () => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const { values, setFieldValue } = useFormikContext<IEventFormBasics>()

  const selectSFContract = useCallback(
    (contract: IContract | null) => setFieldValue('salesforceContract', contract),
    [setFieldValue]
  )

  const eventPromoterId = values.billingPromoter?.value
  const eventPromoterContractId = values.billingPromoter?.salesforceFields?.defaultContract?.id

  const eventContract: IContract | null = values.salesforceContract

  const canView = user.diceStaff && !!eventPromoterId

  const SalesforceContractLoader = useMemo(
    () =>
      RelayLoader<SalesforceDetailsQuery$data>(
        ({ promoter }) =>
          promoter?.salesforceFields?.salesforceId || eventContract ? (
            eventContract ? (
              <Panel>
                <SalesforceContract
                  accountId={eventContract?.sfAccountId || promoter?.salesforceFields?.salesforceId || null}
                  parentAccountId={
                    eventContract?.sfAccountId !== promoter?.salesforceFields?.salesforceId
                      ? null
                      : promoter?.salesforceFields?.parentSalesforceId || null
                  }
                  contracts={promoter?.salesforceContracts || null}
                  contract={eventContract}
                  onSelect={selectSFContract}
                  showExpired
                  disabled={eventContract && eventContract?.sfAccountId !== promoter?.salesforceFields?.salesforceId}
                />
              </Panel>
            ) : (
              <Frame>
                <SalesforceContract
                  accountId={promoter?.salesforceFields?.salesforceId || null}
                  parentAccountId={promoter?.salesforceFields?.parentSalesforceId || null}
                  contracts={promoter?.salesforceContracts || null}
                  contract={null}
                  onSelect={selectSFContract}
                  showExpired
                />
              </Frame>
            )
          ) : (
            <Warning color={color.warning} data-id="noSalesforceAccontWarning">
              {intl.formatMessage({ id: 'promoter_form.salesforce_integration.no_account_warning' })}
            </Warning>
          ),
        {
          fetchPolicy: canView ? 'store-and-network' : 'store-only',
          variables: {
            promoterId: eventPromoterId || 'oops',
          },
          query: graphql`
            query SalesforceDetailsQuery($promoterId: ID!) {
              promoter: node(id: $promoterId) {
                ... on Promoter {
                  salesforceContracts {
                    id
                    name
                    num
                    opportunityName
                    sfAccountId
                    sfId
                    startDate
                    status
                  }
                  salesforceFields {
                    salesforceId
                    parentSalesforceId
                    defaultContract {
                      id
                      name
                      num
                      opportunityName
                      sfAccountId
                      sfId
                      startDate
                      status
                    }
                  }
                }
              }
            }
          `,
        }
      ),
    [canView, eventPromoterId, eventContract, selectSFContract, intl]
  )

  return canView ? (
    <Collapsible
      dice
      initialCollapsed={!!eventPromoterContractId && !!eventContract && eventPromoterContractId === eventContract?.id}
      label={intl.formatMessage({ id: 'promoter_form.salesforce_integration.label' })}
      dataId="salesforceIntegration"
    >
      <SalesforceContractLoader />
    </Collapsible>
  ) : null
}

export default memo(SalesforceDetails)
