import { memoize } from 'lodash/fp'
import { object, string, boolean, ObjectSchema } from 'yup'
import { isFiscalCodeValid, isVatValid } from '../../../utils/italyTaxCode'

const USSchema = object().shape({
  hasEUEvents: boolean().nullable().default(false),
  postalCode: string().nullable().required(),
  addressState: string().nullable().required(),
})

const ItalianSchema = object().shape({
  vatNumber: string()
    .nullable()
    .test('Is valid', 'Has valid structure and checksum', function (value) {
      return !isVatValid(value)
        ? this.createError({ path: 'vatNumber', message: 'signup.errors.tax_code_invalid_format_italy' })
        : true
    }),
  taxCode: string()
    .nullable()
    .required()
    .test('Is valid', 'Has valid structure and checksum', function (value) {
      // SIC! Fiscal code can have EITHER alphanumeric format OR format similar to VAT number
      // Despite being semantically different from VAT number
      return !isFiscalCodeValid(value) && !isVatValid(value)
        ? this.createError({ path: 'taxCode', message: 'signup.errors.tax_code_invalid_format_italy' })
        : true
    }),
})

const FrenchSchema = object().shape({
  licenseNumber: string().nullable(),
})

const NonUSSchema = object().shape({
  hasUSEvents: boolean().nullable().default(false),
})

const append = (schema: null | ObjectSchema, addition: ObjectSchema): ObjectSchema =>
  schema ? schema.concat(addition) : addition

const countrySpecificFields = memoize((countryCode: string | null): null | ObjectSchema => {
  let combinedSchema = null

  if (countryCode?.toUpperCase() !== 'US' && countryCode?.toUpperCase() !== 'CA') {
    combinedSchema = append(combinedSchema, NonUSSchema)
  } else {
    combinedSchema = append(combinedSchema, USSchema)
  }

  if (countryCode?.toUpperCase() === 'FR') {
    combinedSchema = append(combinedSchema, FrenchSchema)
  }

  if (countryCode?.toUpperCase() === 'IT') {
    combinedSchema = append(combinedSchema, ItalianSchema)
  }

  return combinedSchema
})

export default countrySpecificFields
