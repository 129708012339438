/**
 * @generated SignedSource<<007ea27e4e9c4b49454abefd07f7828d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventPartVenue_event$data = {
  readonly billingPromoter: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly eventType: EventType | null;
  readonly id: string;
  readonly primaryVenue: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly streamLink: string | null;
  } | null> | null;
  readonly venueName: string | null;
  readonly venues: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  } | null> | null;
  readonly " $fragmentType": "EventPartVenue_event";
};
export type EventPartVenue_event$key = {
  readonly " $data"?: EventPartVenue_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartVenue_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventPartVenue_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "venueName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "primaryVenue",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "doorSalesOnly",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "includeArchived",
          "value": false
        }
      ],
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamLink",
          "storageKey": null
        }
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:false)"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "4e8d085d4c109518625234c07db944ab";

export default node;
