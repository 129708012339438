import React, { FC, memo, useMemo, useContext, useCallback, useState } from 'react'
import styled from 'styled-components//macro'
import graphql from 'babel-plugin-relay/macro'
import { useFragment, useRelayEnvironment } from 'react-relay'
import { map, concat, uniqBy, sortBy, compact, compose, some, identity, every } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'

import { EventSuccess_event$data, EventSuccess_event$key } from '../../__generated__/EventSuccess_event.graphql'

import { authContext } from '../../context/auth'
import Button from '../../components/Button'

import { font, mediaQuery } from '../../utils/variables'
import Svg from '../../components/Svg'

import submitDraft from '../EventForm/services/submitDraft'
import { notificationContext } from '../../context/notification'
import EventSuccessRow from './EventSuccessRow'
import { dicefmEvent } from '../../utils/dicefm'
import { textStyle } from '../../utils/typography'

const BigTick = styled(Svg)`
  width: 95px;
  height: 70px;
  margin-bottom: 48px;

  ${mediaQuery.lessThan('tablet')`
    width: 64px;
    height: 48px;
    margin-bottom: 16px;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 64px 128px;

  ${mediaQuery.lessThan('tablet')`
    padding: 24px 16px;
  `}
`

const HeaderText = styled.div`
  margin-bottom: 32px;
  text-align: center;
  ${textStyle.heading.lg}

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.md}px;
  `}
`

const SubmitButton = styled(Button)`
  margin-bottom: 48px;
  min-width: 120px;

  ${mediaQuery.lessThan('tablet')`
    width: 100%;
  `}
`

const EventList = styled.ul`
  width: 100%;
`

const LinkMessage = styled.div`
  line-height: 150%;
  text-align: center;

  a {
    text-decoration: underline;
  }
`

const SingleButtons = styled.div`
  margin-top: 48px;

  ${Button}:first-child {
    margin-right: 34px;
  }

  ${mediaQuery.lessThan('tablet')`
    width: 100%;
    margin-top: 103px;

    ${Button} {
      margin: 0;
      width: 100%;
    }

    ${Button}:first-child {
      margin-bottom: 16px;
    }
  `}
`

const MultiButtons = styled.div`
  margin-bottom: 48px;

  ${Button}:first-child {
    margin-right: 34px;
  }

  ${mediaQuery.lessThan('tablet')`
    width: 100%;

    ${Button} {
      margin-right: 0;
      width: 100%;
    }
  `}
`

interface IProps {
  event: EventSuccess_event$key | null
}

const EventSuccess: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { user } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)
  const environment = useRelayEnvironment()

  const event = useFragment(
    graphql`
      fragment EventSuccess_event on Event {
        id
        date
        statusAsOfNow
        state
        organicSocialLink
        eventIdLive
        ...EventSuccessRow_attrs
        recurrentEventsGroup {
          id
          date
          state
          ...EventSuccessRow_attrs
        }
      }
    `,
    eventKey
  )

  const eventList = useMemo(
    () =>
      compose([sortBy(['date', 'id']), compact, uniqBy('id'), concat(event)])(
        event?.recurrentEventsGroup || []
      ) as EventSuccess_event$data[],
    [event]
  )

  const isSingle = eventList.length === 1
  const isApproved = event?.state === 'APPROVED'

  const goToNewEvent = useCallback(() => navigate('/events/new'), [navigate])
  const goToDashboard = useCallback(() => navigate('/dashboard'), [navigate])
  const goToEvent = useCallback(() => {
    if (!event) return

    navigate(`/events/${event.id}/overview`)
  }, [navigate, event])

  const [submitting, setSubmitting] = useState<string | null>(null)

  const doSubmit = useCallback(
    (id: string) => {
      setSubmitting(id)
      submitDraft(environment, id)
        .catch((err) => {
          console.error(err)
          addNotification('error', intl.formatMessage({ id: 'event_success.submit_error' }))
        })
        .finally(() => {
          setSubmitting(null)
        })
    },
    [environment, addNotification, intl]
  )

  const doSubmitAll = useCallback(() => {
    setSubmitting('all')

    const promises = map(
      (e) =>
        // prettier-ignore
        e.state === 'DRAFT'
          ? submitDraft(environment, e.id)
            .then(() => false)
            .catch((err) => {
              console.error(err)
              return true
            })
          : false,
      eventList
    )

    Promise.all(promises)
      .then((results) => {
        const hasErrors = some(identity, results || [])
        if (hasErrors) addNotification('error', intl.formatMessage({ id: 'event_success.submit_error' }))
      })
      .finally(() => setSubmitting(null))
  }, [addNotification, environment, eventList, intl])

  const allSubmitted = useMemo(() => every((e) => e.state !== 'DRAFT', eventList), [eventList])

  let headerId,
    linkMessage = 'event_success.single.link_message'

  if (isApproved) {
    headerId = 'event_success.single.header_approved'
    linkMessage =
      event.statusAsOfNow === 'pending'
        ? 'event_success.single.link_message_approved'
        : 'event_success.single.link_message_approved_announced'
  } else if (isSingle) {
    headerId = 'event_success.single.header'
  } else {
    headerId = 'event_success.recurrent.header'
  }

  const goToDashboardButton = (
    <Button preset="secondary" onClick={goToDashboard} data-id="toDashboard">
      {intl.formatMessage({ id: 'event_success.single.to_dashboard' })}
    </Button>
  )
  const goToEventButton = (
    <Button preset="secondary" onClick={goToEvent} data-id="goToEvent">
      {intl.formatMessage({ id: 'event_success.manage_event' })}
    </Button>
  )

  const link = event ? event.organicSocialLink || (event.eventIdLive ? dicefmEvent(event.eventIdLive) : null) : null

  return !event ? null : (
    <Container>
      <BigTick icon="tick-big" />
      <HeaderText>
        <div>
          {intl.formatMessage(
            {
              id: headerId,
            },
            { userName: user.name, count: eventList.length }
          )}
        </div>
        <div>
          {!isApproved &&
            intl.formatMessage({
              id: isSingle ? 'event_success.single.subheader' : 'event_success.recurrent.subheader',
            })}
        </div>
      </HeaderText>
      {!isSingle ? (
        <>
          {allSubmitted ? (
            <MultiButtons>
              <Button onClick={goToNewEvent} data-id="newEvent">
                {intl.formatMessage({ id: 'event_success.single.create_another' })}
              </Button>
              {isApproved ? goToEventButton : goToDashboardButton}
            </MultiButtons>
          ) : (
            <SubmitButton onClick={doSubmitAll} loading={submitting === 'all'} data-id="submitAll">
              {intl.formatMessage({ id: 'event_success.submit_all' })}
            </SubmitButton>
          )}

          <EventList>
            {eventList.map((evt) => (
              <EventSuccessRow key={evt.id} attrs={evt as any} submitting={submitting === evt.id} doSubmit={doSubmit} />
            ))}
          </EventList>
        </>
      ) : (
        <>
          {link && (
            <LinkMessage>
              <div>{intl.formatMessage({ id: linkMessage })}</div>
              <div>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </div>
            </LinkMessage>
          )}

          <SingleButtons>
            <Button onClick={goToNewEvent} data-id="newEvent">
              {intl.formatMessage({ id: 'event_success.single.create_another' })}
            </Button>
            {isApproved ? goToEventButton : goToDashboardButton}
          </SingleButtons>
        </>
      )}
    </Container>
  )
}

export default memo(EventSuccess)
