/**
 * @generated SignedSource<<b5666504c12a49637f4df5b5f7f2a5bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EnumAccountDisabledReason = "INACTIVITY" | "INACTIVITY_AD_LEAD" | "TERMINATION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountDeactivatedBanner_viewer$data = {
  readonly account: {
    readonly disabledReason: EnumAccountDisabledReason | null;
    readonly isDisabled: boolean | null;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "AccountDeactivatedBanner_viewer";
};
export type AccountDeactivatedBanner_viewer$key = {
  readonly " $data"?: AccountDeactivatedBanner_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountDeactivatedBanner_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountDeactivatedBanner_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disabledReason",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "08e1c80d6d744b284e2374d724e1d0e8";

export default node;
