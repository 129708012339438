import { useMemo } from 'react'
import { orderBy, filter, map, compose, getOr } from 'lodash/fp'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { INote } from '../../components/NotesCollapsible'
import { useEventNotesQuery } from '../../__generated__/useEventNotesQuery.graphql'
import { INotesEvent } from '../../flows/EventNotes/context/eventNotes'

/**
 * Fetches event, promoter, and venue notes for an event, and organises them to be displayed
 *
 * @param eventId - Event ID
 * @param promoterIds - Array of promter IDs
 * @param venueIds - Array of venue IDs
 * @param eventValues - Optional - live formik values from the event form to overwrite values from the API
 * @returns
 */
function useEventNotes(
  eventId: string | null | undefined,
  promoterIds: Array<string | null> | null,
  venueIds: Array<string | null> | null,
  eventValues?: INotesEvent | null
) {
  const { viewer, event } = useLazyLoadQuery<useEventNotesQuery>(
    graphql`
      query useEventNotesQuery($eventId: ID!, $promoterIds: [ID], $venueIds: [ID], $newEvent: Boolean!) {
        viewer {
          id
          promotersNote: promoters(first: 50, where: { id: { in: $promoterIds } }) {
            edges {
              node {
                id
                name
                notes
                tier
              }
            }
          }
          venuesNote: venues(first: 50, where: { id: { in: $venueIds } }) {
            edges {
              node {
                id
                name
                notes
              }
            }
          }
        }
        event: node(id: $eventId) @skip(if: $newEvent) {
          ... on Event {
            id
            name
            notes
            extraNotes
            eventIdLive
            state
            submittedAt
            createdBy {
              firstName
              lastName
              email
            }
            billingPromoter {
              name
              tier
            }
            primaryVenue {
              name
            }
          }
        }
      }
    `,
    {
      promoterIds,
      venueIds,
      eventId: eventId || '',
      newEvent: !eventId,
    },
    { fetchPolicy: 'store-and-network' }
  )

  const internalNotes = eventValues?.extraNotes || event?.extraNotes

  const eventNote = useMemo(() => {
    if (!(event?.notes || eventValues?.notes)) return []

    return [
      {
        id: null,
        name: eventValues?.name || event?.name,
        notes: eventValues?.notes || event?.notes,
      },
    ]
  }, [event?.name, event?.notes, eventValues?.name, eventValues?.notes])

  const promotersNote: typeof eventNote = useMemo(
    () =>
      compose([
        orderBy([(n: any) => n.id === (eventValues?.billingPromoter?.value || event?.billingPromoter?.name)], ['desc']),
        filter('notes'),
        map('node'),
        getOr([], 'promotersNote.edges'),
      ])(viewer),
    [viewer, eventValues?.billingPromoter?.value, event?.billingPromoter?.name]
  )

  const venuesNote: typeof eventNote = useMemo(
    () =>
      compose([
        orderBy([(n: any) => n.id === (eventValues?.primaryVenue?.value || event?.primaryVenue?.name)], ['desc']),
        filter('notes'),
        map('node'),
        getOr([], 'venuesNote.edges'),
      ])(viewer),
    [viewer, eventValues?.primaryVenue?.value, event?.primaryVenue?.name]
  )

  const allNotes = useMemo(
    () =>
      [
        { type: 'event', notes: eventNote as INote[] },
        { type: 'promoter', notes: promotersNote as INote[] },
        { type: 'venue', notes: venuesNote as INote[] },
      ] as const,
    [eventNote, promotersNote, venuesNote]
  )

  return {
    notes: allNotes,
    internalNotes,
    event,
  }
}

export default useEventNotes
