/**
 * @generated SignedSource<<22e6408efc6a336620c6f3ad0d26bed4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventAbbonamentoPickerTableRow_event$data = {
  readonly attractiveFields: {
    readonly forceSubscription: boolean | null;
  } | null;
  readonly date: string | null;
  readonly endDate: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly primaryVenue: {
    readonly name: string | null;
  } | null;
  readonly timezoneName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartImage_event" | "EventPartStatus_event">;
  readonly " $fragmentType": "EventAbbonamentoPickerTableRow_event";
};
export type EventAbbonamentoPickerTableRow_event$key = {
  readonly " $data"?: EventAbbonamentoPickerTableRow_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventAbbonamentoPickerTableRow_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventAbbonamentoPickerTableRow_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "primaryVenue",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttractiveFields",
      "kind": "LinkedField",
      "name": "attractiveFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forceSubscription",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventPartImage_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventPartStatus_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "4e28792d3d9bbed6076ba90b6d14c78e";

export default node;
