import React, { ReactNode, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import Button from '../../../components/Button'
import { ILoader } from '../../../utils/graphqlOptionsLoader'
import { color } from '../../../utils/variables'
import { EventType } from '../../../enums.generated'

const LookupButton = styled(Button)`
  && {
    font-weight: bold;
    color: ${color.primary};
    margin-top: -16px;
  }
`

interface IVenue {
  value: string
  label: string | null
  hint?: string | null
}

interface IProps<V extends IVenue> {
  allowCreate: boolean
  eventType: EventType | null
  disabled: boolean
  venue: V | null
  error?: any
  setVenue: (id: null | string, venue: null | V) => void
  venueLoader: ILoader
  onBlur?: (e: Event) => void
  onCustom?: (venueName: string) => void
  required?: boolean
  placeholder?: string
}

const EventPrimaryVenue = <V extends IVenue>({
  allowCreate,
  eventType,
  disabled,
  venue,
  error,
  setVenue,
  venueLoader,
  onBlur,
  onCustom,
  children,
  required,
  placeholder,
}: IProps<V> & { children?: ReactNode }) => {
  const intl = useIntl()
  const isPureStream = eventType === 'STREAM'

  const createLabel = useCallback(
    (name: string) =>
      intl.formatMessage(
        { id: 'new_event.basics.venue.add_address_button' },
        {
          name,
          b: (children: string) => <b>{children}</b>,
        }
      ),
    [intl]
  )

  return (
    <>
      <FormRow columnOnMobile data-name="primaryVenue" data-disabled={disabled}>
        <div>
          <FormField
            name="primaryVenue"
            label={intl.formatMessage({
              id: isPureStream ? 'new_event.basics.streaming_from.label' : 'new_event.basics.venue.label',
            })}
            control="select"
            allowCreate={allowCreate && !isPureStream}
            createOptionLabel={createLabel}
            searchable
            async
            loadOptions={venueLoader}
            placeholder={placeholder || intl.formatMessage({ id: 'new_event.basics.venue.placeholder' })}
            value={venue}
            onChange={setVenue}
            onBlur={onBlur}
            onCreateOption={onCustom}
            error={error}
            disabled={disabled}
            required={required}
          />

          {children}
        </div>
      </FormRow>
      {isPureStream && (
        <FormRow columnOnMobile>
          <div>
            <div>
              <LookupButton preset="link" color="primary" type="button" onClick={onCustom} data-id="customLocation">
                {intl.formatMessage({ id: 'new_event.basics.streaming_from.location_button' })}
              </LookupButton>
            </div>
          </div>
        </FormRow>
      )}
    </>
  )
}

export default EventPrimaryVenue
