import React, { FC, memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { compact } from 'lodash/fp'

import { FormRow } from '../../../components/Form'
import IEventFormSettings from '../types/Settings'
import FormGroup from '../../../components/FormGroup'
import { allowedEventAction } from '../services/allowedEventAction'
import PermissionCheck from '../../../components/PermissionCheck'
import LinksForm from '../../../components/LinksForm'
import { authContext } from '../../../context/auth'

const EventLinks: FC<React.PropsWithChildren<{ readOnly: boolean }>> = ({ readOnly }) => {
  const intl = useIntl()
  const { hasPermission } = useContext(authContext)

  const { values } = useFormikContext<IEventFormSettings>()

  const links = useMemo(() => compact(values.links || []), [values.links])

  const canUpdate = useMemo(
    () => values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'links'),
    [values.allowedLifecycleUpdates, values.state]
  )

  const noLinks = !links || links.length === 0

  if ((readOnly || !canUpdate) && noLinks) {
    return null
  }

  return hasPermission('manage_links:event') || !!values.allowedActions?.manageLinks ? (
    <FormRow columnOnMobile>
      <FormGroup label={intl.formatMessage({ id: 'new_event.settings.links.label' })}>
        {!noLinks && <FormGroup hint={intl.formatMessage({ id: 'new_event.settings.links.hint' })} />}
        <LinksForm
          readOnly={readOnly}
          canUpdate={canUpdate}
          emptyMessage={intl.formatMessage({ id: 'new_event.settings.links.empty_message' })}
        />
      </FormGroup>
    </FormRow>
  ) : null
}

export default memo(EventLinks)
