import React, { FC, memo, useMemo, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFragment } from 'react-relay'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { map } from 'lodash/fp'
import { useNavigate } from 'react-router'

import { color, font, mediaQuery } from '../utils/variables'
import Banner from './Banner'
import Button from './Button'
import Svg from './Svg'
import { ReadyForPayoutBanner_viewer$key } from '../__generated__/ReadyForPayoutBanner_viewer.graphql'
import PayoutModal from '../flows/EventList/components/Modals/PayoutModal'

const StyledBanner = styled(Banner)`
  padding: 8px 28px 8px 10px;
  font-size: ${font.size.sm}px;
  border-radius: 4px;

  margin-bottom: 40px;

  & + ${Banner} {
    margin-top: -8px;
  }

  & > div {
    color: ${color.text};
    max-width: 80%;
  }

  & > svg {
    margin-right: 8px;
  }

  ${Button} {
    margin-left: auto;
  }

  ${mediaQuery.lessThan('tablet')`
    margin-bottom: 0;

    & + ${Banner} {
      margin-top: 16px;
    }
  `}
`

interface IProps {
  viewer: ReadyForPayoutBanner_viewer$key
}

const ReadyForPayoutBanner: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const viewer = useFragment(
    graphql`
      fragment ReadyForPayoutBanner_viewer on Viewer {
        account {
          availableEventsForPayout {
            id
            name
          }
        }
      }
    `,
    viewerKey
  )

  const names = useMemo(
    () => map('name', viewer.account?.availableEventsForPayout || []),
    [viewer.account?.availableEventsForPayout]
  )

  const [payoutId, setPayoutId] = useState<string | null>(null)
  const closePayout = useCallback(() => setPayoutId(null), [])

  const toPayout = useCallback(() => {
    const events = viewer.account?.availableEventsForPayout || []
    if (events.length === 0) {
      return
    } else if (events.length === 1) {
      const id = events[0]?.id
      setPayoutId(id || null)
    } else {
      navigate('/events/past')
    }
  }, [navigate, viewer.account?.availableEventsForPayout])

  let variant
  if (names.length === 1) {
    variant = 'single'
  } else if (names.length === 2) {
    variant = 'pair'
  } else {
    variant = 'many'
  }

  return names.length > 0 ? (
    <>
      <StyledBanner>
        <Svg icon="coins" />
        <div>
          {intl.formatMessage(
            { id: `dashboard.ready_for_payout_banner.text_${variant}` },
            {
              name: names[0],
              nameA: names[0],
              nameB: names[1],
              count: names.length - 2,
              b: (str: string) => <strong>{str}</strong>,
            }
          )}
        </div>
        <Button preset="link" onClick={toPayout} loading={!!payoutId}>
          <strong>{intl.formatMessage({ id: 'dashboard.ready_for_payout_banner.cta' })}</strong>
        </Button>
      </StyledBanner>
      {payoutId && <PayoutModal eventId={payoutId} onClose={closePayout} />}
    </>
  ) : null
}

export default memo(ReadyForPayoutBanner)
