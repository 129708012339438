/**
 * @generated SignedSource<<7a94336863618d1bdea2882da398d2b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type trackSearchAppleMusicQuery$variables = {
  q: string;
};
export type trackSearchAppleMusicQuery$data = {
  readonly appleMusic: {
    readonly search: {
      readonly results: ReadonlyArray<{
        readonly image: string | null;
        readonly label: string | null;
        readonly open_url: string | null;
        readonly preview_url: string | null;
        readonly value: string | null;
      } | null>;
    } | null;
  } | null;
};
export type trackSearchAppleMusicQuery = {
  response: trackSearchAppleMusicQuery$data;
  variables: trackSearchAppleMusicQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AppleMusicViewer",
    "kind": "LinkedField",
    "name": "appleMusic",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "q",
            "variableName": "q"
          }
        ],
        "concreteType": "AppleMusicSearchResponse",
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AppleMusicTrack",
            "kind": "LinkedField",
            "name": "results",
            "plural": true,
            "selections": [
              {
                "alias": "value",
                "args": null,
                "kind": "ScalarField",
                "name": "openUrl",
                "storageKey": null
              },
              {
                "alias": "label",
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": "preview_url",
                "args": null,
                "kind": "ScalarField",
                "name": "previewUrl",
                "storageKey": null
              },
              {
                "alias": "open_url",
                "args": null,
                "kind": "ScalarField",
                "name": "openUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackSearchAppleMusicQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackSearchAppleMusicQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "66224599b39921988e496013e6b186f9",
    "id": null,
    "metadata": {},
    "name": "trackSearchAppleMusicQuery",
    "operationKind": "query",
    "text": "query trackSearchAppleMusicQuery(\n  $q: String!\n) {\n  appleMusic {\n    search(q: $q) {\n      results {\n        value: openUrl\n        label: name\n        image\n        preview_url: previewUrl\n        open_url: openUrl\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "39ca7d6eeccaa0b20231c4fc0a268fdf";

export default node;
