import React, { FC, memo, useCallback, useState, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { useMediaQuery } from 'react-responsive'
import {
  compact,
  groupBy,
  concat,
  find,
  findIndex,
  reject,
  set,
  update,
  without,
  compose,
  mapValues,
  keys,
  isEmpty,
  sortBy,
} from 'lodash/fp'
import arrayMove from 'array-move'

import { authContext } from '../../../context/auth'
import { allowedEventAction } from '../services/allowedEventAction'
import { IFormStep } from '../services/getStepsConfig'
import { isNew, markAsClientOnly } from '../../../utils/entityStatus'
import { breakpoints, color, font, mediaQuery } from '../../../utils/variables'

import Badge from '../../../components/Badge'
import Button from '../../../components/Button'
import FormHeader from '../../../components/FormHeader'
import FormGroup from '../../../components/FormGroup'
import { Form, FormRow } from '../../../components/Form'
import ListAddButton from '../../../components/ListAddButton'
import Svg from '../../../components/Svg'

import IEventFormExtras, { IProduct } from '../types/Extras'

import useEventProduct from '../hooks/useEventProduct'
import EventProductSummary from '../components/EventProductSummary'
import EventProductModal from '../components/EventProductModal'
import EventProductList from '../components/EventProductList'

const EmptyListWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  background: rgba(0, 0, 254, 0.08);
  border-radius: 8px;

  ${Badge} {
    font-weight: ${font.weight.bold};
    background-color: rgba(0, 0, 254, 0.08);
    color: ${color.primary};
    margin-bottom: 8px;
  }

  & > svg {
    flex: 152px 0 0;
    width: 152px;
    height: 152px;

    ${mediaQuery.lessThan('tablet')`
      display: none;
    `}
  }
`

const EmptyListText = styled.div`
  max-width: 450px;
`

export const EXTRAS_TYPES_ORDER = ['EXPERIENCE', 'TRAVEL_AND_ACCOMMODATION', 'FOOD_AND_DRINK', 'MERCH']

const ExtrasStep: FC<React.PropsWithChildren<IFormStep>> = ({ viewer, children, readOnly }) => {
  const intl = useIntl()
  const { user, account, hasPermission } = useContext(authContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { values, setFieldValue, setFieldTouched, errors, validateForm } = useFormikContext<IEventFormExtras>()

  const groupedProducts = useMemo(
    () =>
      compose([
        mapValues(
          compose([
            groupBy((product: IProduct) => product?.category?.parentCategory?.type || 'UNKNOWN'),
            sortBy((product: IProduct) => {
              const idx = EXTRAS_TYPES_ORDER.indexOf(product?.category?.parentCategory?.type || '')
              return idx >= 0 ? idx : 99999999
            }),
          ])
        ),
        groupBy((product: IProduct) => (product.archived ? 'archived' : 'live')),
        reject((product: IProduct) => product?.category?.parentCategory?.type === 'MERCH'),
      ])(values.products || []) || {},
    [values.products]
  )

  const allProducts = useMemo(() => compact(values.products || []) as any, [values.products])

  const [modalId, setModalId] = useState<string | null>(null)

  const ctx = { onAdd: setModalId }
  const { addProduct, copyProduct, removeProduct, restoreProduct } = useEventProduct(allProducts, ctx)

  const reorderProducts = useCallback(
    ({ oldIndex, newIndex }: any, e: any) => {
      const productType = e.target.dataset['type'] || 'UNKNOWN'
      const restOfProducts = without(groupedProducts['live'][productType], allProducts)
      setFieldValue(
        'products',
        concat(arrayMove(groupedProducts['live'][productType], oldIndex, newIndex), restOfProducts)
      )
      setFieldTouched('products', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [allProducts, groupedProducts, setFieldTouched, setFieldValue, validateForm]
  )

  const openEditModal = useCallback((productId: string) => {
    setModalId(productId)
  }, [])

  const closeEditModal = useCallback(() => {
    setModalId(null)
    setFieldValue('products', reject(isNew, allProducts))
  }, [allProducts, setFieldValue])

  const saveProduct = useCallback(
    (product: IProduct) => {
      setFieldValue(
        'products',
        set(
          [findIndex(['id', product.id], values.products)],
          update('id', (id) => (isNew({ id }) ? markAsClientOnly({ id }).id : id), product),
          allProducts
        )
      )
      setModalId(null)
      setFieldTouched('products', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldValue, values.products, allProducts, setFieldTouched, validateForm]
  )

  const canAdd = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canAdd')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )
  const canChangeOrder = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canChangeOrder')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )
  const canRemove = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canDelete')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )
  const canUpdate = useMemo(
    () =>
      hasPermission('add_extras:event') &&
      (values.state === 'DRAFT' || allowedEventAction(values.allowedLifecycleUpdates, 'products', 'canUpdate')),
    [hasPermission, values.allowedLifecycleUpdates, values.state]
  )

  const canAddExtras = useMemo(
    () => hasPermission('add_extras:event') && (values.state === 'DRAFT' || values?.allowedActions?.addProducts),
    [hasPermission, values?.allowedActions?.addProducts, values.state]
  )

  return user.diceStaff || account?.extrasEnabled || values?.allowedActions?.readExtras ? (
    <Form spacing={isMobile ? 'default' : 'extra'} className="mb-md">
      {!isEmpty(groupedProducts['live']) ? (
        <>
          <FormHeader
            header={intl.formatMessage({ id: 'new_event.steps.extras' })}
            subheader={intl.formatMessage({ id: 'new_event.extras.description' })}
          />
          {/* LIVE */}
          {keys(groupedProducts['live']).map(
            (type) =>
              type && (
                <FormRow columnOnMobile key={type}>
                  <FormGroup
                    label={intl.formatMessage({ id: `new_event.extras.list.type.${type.toLowerCase()}` })}
                    labelClassName="color-darkgrey"
                  >
                    <FormRow>
                      <div>
                        <EventProductList
                          allProducts={allProducts}
                          products={groupedProducts['live'][type] || []}
                          currency={values.costCurrency || undefined}
                          onSortEnd={reorderProducts}
                          remove={removeProduct}
                          restore={restoreProduct}
                          copy={copyProduct}
                          showEditModal={openEditModal}
                          lockAxis="y"
                          useDragHandle
                          errors={errors}
                          modalId={modalId}
                          sortingDisabled={!canAddExtras || readOnly || !canChangeOrder}
                          removeDisabled={!canAddExtras || readOnly || !canRemove}
                          copyDisabled={!canAddExtras || readOnly || !canAdd}
                        />
                      </div>
                    </FormRow>
                  </FormGroup>
                </FormRow>
              )
          )}
          {!readOnly && canAdd && (
            <FormRow className="mt-zero pt-md">
              <ListAddButton
                className="mt-zero"
                label={intl.formatMessage({ id: 'new_event.extras.list.add_button' })}
                onClick={addProduct}
              />
            </FormRow>
          )}
        </>
      ) : (
        <FormRow>
          <EmptyListWrapper>
            <EmptyListText>
              <Badge>{intl.formatMessage({ id: 'new_event.step_badge.new' })}</Badge>
              <FormHeader
                header={intl.formatMessage({ id: 'new_event.steps.extras' })}
                subheader={intl.formatMessage({ id: 'new_event.extras.empty_placeholder.description' })}
              />
              {!readOnly && canAdd && (
                <FormRow>
                  <Button icon="add" size="small" onClick={addProduct}>
                    {intl.formatMessage({ id: 'new_event.extras.list.empty_add_button' })}
                  </Button>
                </FormRow>
              )}
            </EmptyListText>
            <Svg icon="extras_generic" />
          </EmptyListWrapper>
        </FormRow>
      )}

      {/* ARCHIVED */}
      {user.diceStaff &&
        !isEmpty(groupedProducts['archived']) &&
        keys(groupedProducts['archived']).map(
          (type) =>
            type && (
              <FormRow columnOnMobile key={type}>
                <FormGroup
                  dice
                  label={`[${intl.formatMessage({
                    id: 'new_event.tickets.ticket_types.archived_label',
                  })}] ${intl.formatMessage({ id: `new_event.extras.list.type.${type.toLowerCase()}` })}`}
                >
                  <FormRow>
                    <div>
                      <EventProductList
                        allProducts={allProducts}
                        products={groupedProducts['archived'][type] || []}
                        currency={values.costCurrency || undefined}
                        onSortEnd={reorderProducts}
                        remove={removeProduct}
                        restore={restoreProduct}
                        copy={copyProduct}
                        showEditModal={openEditModal}
                        lockAxis="y"
                        useDragHandle
                        errors={errors}
                        modalId={modalId}
                        sortingDisabled
                        removeDisabled
                        copyDisabled
                      />
                    </div>
                  </FormRow>
                </FormGroup>
              </FormRow>
            )
        )}

      {/* SUMMARY */}
      {!isEmpty(groupedProducts['live']) && (
        <FormRow>
          <EventProductSummary type="extras" />
        </FormRow>
      )}

      {modalId !== null && find(['id', modalId], values.products) ? (
        <EventProductModal
          event={null}
          eventForm={values}
          productId={modalId}
          productType="extras"
          onClose={closeEditModal}
          onSave={saveProduct}
          readOnly={readOnly || !canUpdate}
        />
      ) : null}
    </Form>
  ) : null
}

export default createFragmentContainer(memo(ExtrasStep), {
  viewer: graphql`
    fragment Extras_viewer on Viewer {
      id
    }
  `,
  event: graphql`
    fragment Extras_event on Event {
      ...EventProductModal_event @relay(mask: false)

      id
      eventType
      date
      endDate
      onSaleDate
      offSaleDate
      closeEventDate
      announceDate
      scheduleStatus
      timezoneName
      state
      statusAsOfNow
      eventIdLive
      allowedActions {
        addProducts
        readExtras
        readMerch
        manageProductsAllocation
        minorUpdate
      }
      allowedLifecycleUpdates {
        products {
          canAdd
          canChangeOrder
          canDelete
          canUpdate
          canUpdatePrice
        }
      }
      feesBehaviour
      fees {
        amount
        type
        unit
        applicable

        split {
          amount
          destination
          unit
        }
      }
      disableUsTax
      products {
        id
        name
        archived
        productType
        rootType
        event {
          id
        }
        category {
          value: id
          label: name
          type
          parentCategory {
            value: id
            label: name
            type
          }
        }
        optionType
        hasVariants
        variants {
          id
          allocation
          sku
          name
        }
        fulfilledBy
        sellingPoints {
          name
        }
        hasSeparateAccessBarcodes
        customCover
        venue {
          value: id
          label: name
        }
        locationNote
        date
        endDate
        description
        faceValue
        allocation
        onSaleDate
        offSaleDate
        allTicketTypes
        ticketTypes {
          id
          name
        }
        priceBreakdown {
          total
          faceValue
          salesTax
          fees
          vatAmount
          vatRate
          breakdown {
            type
            applicable
            computed
            split {
              amount
              computed
              destination
              unit
            }
          }
          split {
            computed
            destination
          }
        }
        purchaseConfirmationMessage
      }
    }
  `,
})
