import { map } from 'lodash/fp'
import { useContext, useEffect } from 'react'
import eventNotesContext, { INotesEvent } from '../context/eventNotes'
import IEventForm from '../../EventForm/types'

export default function useEventNotesContext(values: IEventForm) {
  const { setPromoterIds, setVenueIds, setEvent } = useContext(eventNotesContext)

  useEffect(() => {
    const promoterIds = map('value', values.promoters || [])
    const venueIds = map('value', values.venues || [])
    const event: INotesEvent = {
      id: values.id,
      name: values.name,
      notes: values.notes,
      extraNotes: values.extraNotes,
      billingPromoter: values.billingPromoter,
      primaryVenue: values.primaryVenue,
    }
    setPromoterIds(promoterIds)
    setVenueIds(venueIds)
    setEvent(event)
  }, [
    setEvent,
    setPromoterIds,
    setVenueIds,
    values.billingPromoter,
    values.extraNotes,
    values.id,
    values.name,
    values.notes,
    values.primaryVenue,
    values.promoters,
    values.venues,
  ])
}
