import React from 'react'

import { GlobalStyle } from './GlobalStyle'
import { TypeStyle } from './TypeStyle'
import { UtilityStyle } from './UtilityStyle'
import { DateTimePickerStyles } from '../components/DateTimePicker'
import { GeneralModalStyles } from '../components/Modal'
import { ReactSelectStyles } from '../components/Select/SelectStyles'
import { SuggestionsStyles } from '../components/SuggestionsInput'
import { TooltipStyles } from '../components/Tooltip'
import { ButtonStyle, IconButtonStyle } from './components/Button'
import { TextInputStyle } from './components/TextInput'
import { MobileDurationPickerStyles } from '../components/MobileDurationPicker'
import ChartJsMobileHack from './ChartJsMobileHack'

const StyleSheets = () => {
  return (
    <>
      <GlobalStyle />
      <TypeStyle />
      <UtilityStyle />
      <MobileDurationPickerStyles />
      <DateTimePickerStyles />
      <TooltipStyles />
      <ReactSelectStyles />
      <SuggestionsStyles />
      <GeneralModalStyles />
      <ButtonStyle />
      <IconButtonStyle />
      <TextInputStyle />
      <ChartJsMobileHack />
    </>
  )
}

export default StyleSheets
