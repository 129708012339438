import React, { FC, useContext, useMemo } from 'react'
import { find, getOr, map, filter, concat, uniq } from 'lodash/fp'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { startOfDay, subDays } from 'date-fns'

import { color, font, mediaQuery } from '../../../../utils/variables'
import { getCurrencySymbol } from '../../../../utils/currency'
import { CURRENCY } from '../../../../utils/formatters/number'

import { DATETIME_FORMATS } from '../../../../utils/formatters/datetime'

import { authContext } from '../../../../context/auth'

import Value from '../../../../components/Value'
import FormField from '../../../../components/FormField'
import PermissionCheck from '../../../../components/PermissionCheck'
import { SkeletonLine } from '../../../../components/SkeletonLine'
import { EventCostCurrency } from '../../../../enums.generated'

const Plate = styled.div`
  display: flex;
  position: relative;
  padding: 16px;
  min-height: 160px;

  ${mediaQuery.lessThan('tablet')`
    padding: 16px 8px;
  `}
`
const PlateCol = styled.div<{ grow?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 16px;
  flex: 35% 0 0;
  ${({ grow }) => grow && 'flex-grow: 1; overflow: hidden;'}
  ${mediaQuery.lessThan('tablet')`
    padding: 16px 8px;
  `}
`

const Title = styled.h2`
  display: block;
  font-weight: normal;
  font-size: ${font.size.md}px;
  line-height: 24px;
  margin: 0;
`
const Period = styled.span`
  display: block;
  color: ${color.darkgrey};
  font-size: ${font.size.sm}px;
`

const Currency = styled.div`
  width: 120px;
  margin-top: 8px;
`

const List = styled.div`
  display: flex;
  margin: 6px 0;
  strong {
    flex: 35% 0 0;
    min-width: 80px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  span {
    flex-grow: 1;
    padding-left: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${mediaQuery.lessThan('desktop')`
      padding-left: 8px;
    `}
  }
`

interface IProps {
  period: string
  currentCurrency: EventCostCurrency
  updateCurrency?: any
  stats: any
  isLoading?: boolean
}

const DashboardSalesPlate: FC<React.PropsWithChildren<IProps>> = ({
  period,
  currentCurrency,
  updateCurrency,
  stats,
  isLoading,
}) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const eventsCurrencies = uniq(concat([user.defaultCurrency], getOr([], ['eventsCurrencies'], user)))
  const currencyOptions: { value: string; label: string }[] = useMemo(
    () =>
      filter(
        'value',
        map((c) => {
          const currencySymbol = getCurrencySymbol(intl, c)
          return { value: c, label: `${currencySymbol} ${c}` }
        }, eventsCurrencies)
      ) as any,
    [intl, eventsCurrencies]
  )
  const title = useMemo(() => {
    switch (period) {
      case 'day':
        return intl.formatMessage({ id: 'dashboard.sales_plate.day' })
      case 'week':
        return intl.formatMessage({ id: 'dashboard.sales_plate.week' })
      default:
        return intl.formatMessage({ id: 'dashboard.sales_plate.day' })
    }
  }, [period, intl])

  const date = useMemo(() => {
    switch (period) {
      case 'day':
        return intl.formatDate(startOfDay(new Date()), { ...DATETIME_FORMATS.SHORT })
      case 'week':
        return `
          ${intl.formatDate(startOfDay(subDays(new Date(), 7)), { ...DATETIME_FORMATS.SHORTEST })}
          -
          ${intl.formatDate(new Date(), { ...DATETIME_FORMATS.SHORTEST })}
        `
      default:
        return intl.formatDate(startOfDay(new Date()), { ...DATETIME_FORMATS.SHORT })
    }
  }, [intl, period])

  return (
    <Plate data-id={`dashboard-sales-plate-${period}`}>
      <PlateCol>
        <Title>{title}</Title>
        <Period>{date}</Period>
        {currencyOptions.length > 1 && (
          <Value
            label={' '}
            value={
              <Currency>
                <FormField
                  name={`currency-${period}`}
                  control="select"
                  options={currencyOptions}
                  value={find(['value', currentCurrency], currencyOptions)}
                  onChange={updateCurrency}
                />
              </Currency>
            }
          />
        )}
      </PlateCol>
      <PlateCol grow>
        <List data-id={`dashboard-sales-plate-${period}-onsale`}>
          <strong>{isLoading ? <SkeletonLine width="40%" height="12px" /> : stats.eventOnSale}</strong>
          <span>{intl.formatMessage({ id: 'dashboard.sales_plate.stats.events_on_sale' })}</span>
        </List>
        <List data-id={`dashboard-sales-plate-${period}-sold`}>
          <strong>{isLoading ? <SkeletonLine width="35%" height="12px" /> : stats.ticketsSold}</strong>
          <span>{intl.formatMessage({ id: 'dashboard.sales_plate.stats.tickets_sold' })}</span>
        </List>
        <PermissionCheck permission="read:balances">
          <List data-id={`dashboard-sales-plate-${period}-revenue`}>
            <strong>
              {isLoading ? (
                <SkeletonLine width="62%" height="12px" />
              ) : (
                intl.formatNumber(stats.grossRevenue / 100, CURRENCY(stats.grossRevenue, currentCurrency))
              )}
            </strong>
            <span>{intl.formatMessage({ id: 'dashboard.sales_plate.stats.revenue' })}</span>
          </List>
        </PermissionCheck>
      </PlateCol>
    </Plate>
  )
}

export default DashboardSalesPlate
