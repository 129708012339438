import styled from 'styled-components/macro'
import { color, spacing } from '../../utils/variables'

export const AgreementCheckbox = styled.div`
  display: flex;
  margin-top: 10px;
`

export const AgreementCheckboxItem = styled.div`
  align-self: center;
  margin-right: ${spacing.sm}px;
  line-height: 14px;
`

export const TermsLink = styled.a`
  font-weight: bold;
  white-space: nowrap;
  &:hover {
    color: ${color.primary};
  }
`
