import React, { FC, useCallback, useState, ChangeEvent, memo } from 'react'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'

import { allowedEventAction } from '../services/allowedEventAction'
import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { EventTicketTypeIncrements_event$key } from '../../../__generated__/EventTicketTypeIncrements_event.graphql'
import IEventFormTickets, { ITicketType } from '../types/Tickets'

interface IProps {
  event: EventTicketTypeIncrements_event$key | null | undefined
  eventForm?: IEventFormTickets
  values: ITicketType
  handleChange: (e: string | ChangeEvent) => void
  handleBlur: (e: Event) => void
  setFieldValue: (name: string, value: any) => void
  setFieldTouched: (name: string, p1: boolean, p2: boolean) => void
  validateForm: () => void
  touched: FormikTouched<FormikValues>
  errors: FormikErrors<FormikValues>
}

const EventTicketTypeIncrements: FC<React.PropsWithChildren<IProps>> = ({
  event: eventKey,
  eventForm,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
}) => {
  const event = useFragment(
    graphql`
      fragment EventTicketTypeIncrements_event on Event {
        allowedLifecycleUpdates {
          ticketTypes {
            canChangeIncrements
          }
        }
      }
    `,
    eventKey
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const allowedLifecycleUpdates = (eventForm || event)!.allowedLifecycleUpdates

  const intl = useIntl()

  const [limit, setLimit] = useState((values.maximumIncrements || 1) * (values.increment || 1))
  const onLimitChange = useCallback((e: any) => setLimit(Number(e.target.value)), [setLimit])
  const onLimitBlur = useCallback(
    (e: any, increment?: any) => {
      if (e) handleBlur(e)

      const realIncrement = increment || values.increment || 1
      const maxVal = Math.floor(limit / realIncrement) || 1
      setFieldValue('maximumIncrements', maxVal)
      setLimit(maxVal * realIncrement)
    },
    [setFieldValue, limit, values.increment, setLimit, handleBlur]
  )

  const onIncrementChange = useCallback(
    (e: any) => {
      handleChange(e)
      onLimitBlur(e, Number(e.target.value))
    },
    [handleChange, onLimitBlur]
  )

  return (
    <FormRow columnOnMobile>
      <FormField
        name="increment"
        label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.increments.label' })}
        hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.increments.hint' })}
        type="number"
        min={1}
        step={1}
        value={values.increment}
        onChange={onIncrementChange}
        onBlur={handleBlur}
        error={errors.increment}
        disabled={!allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeIncrements')}
        required
      />
      <FormField
        name="limit"
        label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.limit.label' })}
        hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.limit.hint' })}
        type="number"
        min={values.increment || 1}
        step={values.increment || 1}
        value={limit}
        onChange={onLimitChange}
        onBlur={onLimitBlur}
        error={errors.limit}
        disabled={!allowedEventAction(allowedLifecycleUpdates, 'ticketTypes', 'canChangeIncrements')}
        required
      />
    </FormRow>
  )
}

export default memo(EventTicketTypeIncrements)
