/**
 * @generated SignedSource<<96febbb868dac6fa7a279153a402a63a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BundleWhereInput = {
  _or?: ReadonlyArray<BundleWhereInput | null> | null;
  active?: OperatorsBooleanEqInput | null;
  id?: OperatorsIdEqInput | null;
  name?: OperatorsString | null;
};
export type OperatorsBooleanEqInput = {
  eq?: boolean | null;
  ne?: boolean | null;
  neOrNull?: boolean | null;
};
export type OperatorsIdEqInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
};
export type OperatorsString = {
  eq?: string | null;
  iLike?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notLike?: string | null;
  null?: boolean | null;
};
export type useSettingsFieldsBundleQuery$variables = {
  where?: BundleWhereInput | null;
};
export type useSettingsFieldsBundleQuery$data = {
  readonly viewer: {
    readonly options: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly label: string;
          readonly value: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useSettingsFieldsBundleQuery = {
  response: useSettingsFieldsBundleQuery$data;
  variables: useSettingsFieldsBundleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSettingsFieldsBundleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "BundleConnection",
            "kind": "LinkedField",
            "name": "bundles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BundleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Bundle",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSettingsFieldsBundleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "BundleConnection",
            "kind": "LinkedField",
            "name": "bundles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BundleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Bundle",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "370a280e7a31fab2af14c50b525d9b4c",
    "id": null,
    "metadata": {},
    "name": "useSettingsFieldsBundleQuery",
    "operationKind": "query",
    "text": "query useSettingsFieldsBundleQuery(\n  $where: BundleWhereInput\n) {\n  viewer {\n    options: bundles(where: $where, first: 50) {\n      edges {\n        node {\n          value: id\n          label: name\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "063dda7ec5bbb27ad71f9be73f71d609";

export default node;
