import React, { memo, useState, useEffect, useCallback, FC, useContext, useMemo } from 'react'
import styled from 'styled-components/macro'
import cn from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import { useIntl } from 'react-intl'
import { OnMobile } from '../../components/Breakpoints'
import { authContext } from '../../context/auth'
import {
  SettingsMobileControls,
  SidebarBackDrop,
  SidebarMenu,
  SidebarMenuIcon,
  SidebarMenuItem,
  SidebarScrollable,
  SidebarToogler,
  SidebarWrapper,
} from '../Sidebar/components/legacy/Styles'
import AccountSettings from './components/AccountSettings'
import { PROMOTER_SETTINGS_SIDEBAR_TOGGLER_TRIGGER_ID } from './services/promoterSettingsSidebarNav'
import PromoterSettings from './components/PromoterSettings'
import Footer from './components/Footer'

interface IProps {
  ICEenabled?: boolean
}

const PromoterSettingsSidebar: FC<IProps> = ({ ICEenabled }) => {
  const intl = useIntl()
  const { isImpersonated } = useContext(authContext)
  const { pathname } = useLocation()
  const [expandOnMobile, setExpandOnMobile] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const goBackLocation = useMemo(() => {
    const lastLocation = sessionStorage.getItem('lastLocationBeforePromoterSettings')
    return lastLocation ?? '/'
  }, [])

  const cleanupBackStorage = useCallback(() => sessionStorage.removeItem('lastLocationBeforePromoterSettings'), [])

  useEffect(() => {
    if (pathname) {
      setExpandOnMobile(false)
      setExpanded(false)
    }
  }, [pathname])

  const toggleMobileExapnded = useCallback(() => setExpandOnMobile((e) => !e), [setExpandOnMobile])
  const expandSidebar = useCallback(() => setExpanded(true), [setExpanded])
  const collapseSidebar = useCallback(() => setExpanded(false), [setExpanded])

  return (
    <>
      <OnMobile>
        <SettingsMobileControls>
          <MobileBackButton to={goBackLocation} data-id="sidebarBackToMio">
            <RotatedSidebarMenuIcon icon="chevron-right" />
            <span>{intl.formatMessage({ id: 'actions.back' })}</span>
          </MobileBackButton>
          <SidebarToogler
            id={PROMOTER_SETTINGS_SIDEBAR_TOGGLER_TRIGGER_ID}
            onClick={toggleMobileExapnded}
            icon="hamburger"
          />
        </SettingsMobileControls>
      </OnMobile>
      <SidebarWrapper
        className={cn('sidebar', expanded && 'expanded', expandOnMobile && 'mobile-expanded')}
        expandOnMobile={expandOnMobile}
        onMouseEnter={expandSidebar}
        onMouseLeave={collapseSidebar}
        ICEenabled={ICEenabled}
        isImpersonated={isImpersonated}
        v2
      >
        <OnMobile>
          <SidebarBackDrop expandOnMobile={expandOnMobile} onClick={toggleMobileExapnded} />
        </OnMobile>

        <SidebarScrollable>
          <div style={{ margin: '0 0 12px' }}>
            <SidebarMenu>
              <SidebarMenuItem to={goBackLocation} onClick={cleanupBackStorage} data-id="sidebarBackToMio">
                <RotatedSidebarMenuIcon icon="chevron-right" />
                <span>{intl.formatMessage({ id: 'actions.back' })}</span>
              </SidebarMenuItem>
            </SidebarMenu>
          </div>

          <AccountSettings />
          <PromoterSettings />
        </SidebarScrollable>
        <Footer expanded={expanded || expandOnMobile} />
      </SidebarWrapper>
    </>
  )
}

export default memo(PromoterSettingsSidebar)

const RotatedSidebarMenuIcon = styled(SidebarMenuIcon)`
  transform: rotate(180deg);
`

const MobileBackButton = styled(Link)`
  position: absolute;
  left: 0;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 8px;
`
