import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'

import { color, font, mediaQuery } from '../utils/variables'
import Button from './Button'
import Svg from './Svg'

const IconWrapper = styled.div``
const TextWrapper = styled.div``

const NoItems = styled.div<{ hasError?: boolean; compact?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid ${({ hasError }) => (hasError ? color.error : color.lightgrey)};
  border-radius: 8px;
  font-size: ${font.size.sm}px;
  letter-spacing: 0.01em;
  padding: 24px 32px;

  h6 {
    margin: 4px 0;
    font-size: ${font.size.md}px;
    font-weight: bold;
    line-height: 120%;
  }

  & > div {
    max-width: 50%;

    ${mediaQuery.lessThan('desktop')`
      padding: 0 32px;
      max-width: unset;
    `}
  }

  ${Button} {
    margin-top: 16px;
    min-width: 100px;

    ${mediaQuery.lessThan('tablet')`
      width: 100%;
    `}
  }
`

interface IProps {
  icon: string
  title: string
  cta: string
  onAdd: () => void

  className?: string
  subTitle?: string
  hasError?: boolean
  disabled?: boolean
  loading?: boolean
}

const EmptyList: FC<React.PropsWithChildren<IProps>> = ({
  icon,
  cta,
  title,
  subTitle,
  hasError,
  onAdd,
  disabled,
  loading,
  className,
}) => {
  return (
    <NoItems className={className} hasError={!!hasError}>
      <IconWrapper>
        <Svg display="block" icon={icon} width={48} height={48} />
      </IconWrapper>
      <TextWrapper>
        <h6>{title}</h6>
        {subTitle && <div>{subTitle}</div>}
      </TextWrapper>
      <Button disabled={disabled} loading={loading} onClick={onAdd}>
        {cta}
      </Button>
    </NoItems>
  )
}

export default styled(memo(EmptyList))``
