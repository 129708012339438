import React, { FC, PropsWithChildren, useCallback } from 'react'
import { TabMenuItem } from '../../../../../components/TabMenu'

interface IProps {
  active: boolean
  value: string
  onClick: (nm: string, v: boolean) => void
}

const DateTab: FC<PropsWithChildren<IProps>> = ({ children, active, value, onClick }) => {
  const toggleValue = useCallback((e: any) => onClick(e.currentTarget.name, active), [onClick, active])

  return (
    <TabMenuItem name={value} active={active} onClick={toggleValue}>
      {children}
    </TabMenuItem>
  )
}

export default DateTab
