import { map } from 'lodash/fp'

const CANADA_STATES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Northwest Territories',
  'Nunavut',
  'Yukon',
]

export const CANADA_STATES_OPTIONS = map((state) => ({ label: state, value: state }), CANADA_STATES)

export default CANADA_STATES
