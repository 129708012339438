import React, { FC, memo, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { useMediaQuery } from 'react-responsive'

import Button from '../../components/Button'
import { authContext } from '../../context/auth'
import { breakpoints, color, mediaQuery, zIndex } from '../../utils/variables'

const ImpersonationBannerContainer = styled.div`
  background-color: ${color.primary};
  color: ${color.white};

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 62px;
  z-index: ${zIndex.max};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .button_content {
    color: ${color.white};

    border-bottom: 1px solid ${color.white};
    margin-bottom: -1px;
  }

  ${mediaQuery.lessThan('tablet')`
    display: flex;
    bottom: 0;
    height: unset;
  `}
`

const ImpersonationBanner: FC = () => {
  const intl = useIntl()
  const { user, account, exitImpersonation } = useContext(authContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  return (
    <ImpersonationBannerContainer>
      <div>
        {
          // prettier-ignore
          isMobile
            ? intl.formatMessage({ id: 'impersonation_banner.mobile_message' })
            : intl.formatMessage(
              { id: 'impersonation_banner.message' },
              { name: user.name, account: account?.name || intl.formatMessage({ id: 'na' }) }
            )
        }
      </div>
      {!isMobile && exitImpersonation && (
        <Button preset="link" onClick={exitImpersonation}>
          {intl.formatMessage({ id: 'impersonation_banner.exit_button' })}
        </Button>
      )}
    </ImpersonationBannerContainer>
  )
}

export default memo(ImpersonationBanner)
