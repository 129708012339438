import { FeeType } from '../enums.generated'

// SIC! order is important - it defines table columns order
const FEE_TYPES: FeeType[] = [
  'booking',
  'processing',
  'paidWaitingList',
  'postal',
  'tierDiff',
  'extraCharge',
  'additionalPromoterFee',
  'facilityFee',
  'venueFee',
  'charityDonation',
  'venueLevy',
  'presale',
  'boxOfficeFee',
  'salesTax',
  'deposit',
  'vendor',
]

export default FEE_TYPES
