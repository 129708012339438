/**
 * @generated SignedSource<<8f0fcced166ccdb21dc4b5582914f675>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useDoorlistRecipientsQuery$variables = {
  id: string;
};
export type useDoorlistRecipientsQuery$data = {
  readonly node: {
    readonly contacts?: ReadonlyArray<{
      readonly doorlistRecipient: boolean;
      readonly email: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type useDoorlistRecipientsQuery = {
  response: useDoorlistRecipientsQuery$data;
  variables: useDoorlistRecipientsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "contacts",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorlistRecipient",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "Venue",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "Promoter",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDoorlistRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDoorlistRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b9833be3ea0ce522e87a946496c61c7",
    "id": null,
    "metadata": {},
    "name": "useDoorlistRecipientsQuery",
    "operationKind": "query",
    "text": "query useDoorlistRecipientsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Venue {\n      contacts {\n        id\n        email\n        doorlistRecipient\n      }\n    }\n    ... on Promoter {\n      contacts {\n        id\n        email\n        doorlistRecipient\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea979f6f01e799e9646ebf347015d053";

export default node;
