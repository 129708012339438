import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'

import { API_URL } from '../env'

const PRE_LOGIN_PAGE_URL_KEY = 'preLoginPageUrl'

export function disableICE() {
  window.localStorage.removeItem('translatorMode')
  window.location.reload()
}

export function setAuthToken(token: string) {
  window.localStorage.setItem('token', token)
}

export function clearAuthToken() {
  const token = window.localStorage.getItem('token')
  Sentry.configureScope(function (scope) {
    scope.setUser(null)
  })
  window.localStorage.removeItem('token')
  window.sessionStorage.clear()
  if (window.localStorage.getItem('translatorMode')) {
    disableICE()
  }
  return token
}

function getHeaders() {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${window.localStorage.getItem('token')}`)
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')
  return headers
}

export const persistLocation = () => {
  if (!window.location.pathname.startsWith('/auth')) {
    window.sessionStorage.setItem(PRE_LOGIN_PAGE_URL_KEY, window.location.href.replace(window.location.origin, ''))
  }
}

export const restoreLocation = (): string | null => {
  const goToUrl = window.sessionStorage.getItem(PRE_LOGIN_PAGE_URL_KEY)
  window.sessionStorage.removeItem(PRE_LOGIN_PAGE_URL_KEY)
  return goToUrl
}

export const stashAuth = () => {
  const token = window.localStorage.getItem('token')
  if (token) {
    Cookies.set(
      'kim-auth',
      token,
      // expire cookie after 1 min (needed for Chrome)
      { path: '/', domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.dice.fm', expires: 1 / 1440 }
    )
  }
}

type IResponseData = any & { status: number; ok: boolean }

const responseHandler = async (response?: Response): Promise<IResponseData> => {
  let data: IResponseData = {}
  if (response) {
    try {
      data = await response.json()
    } catch (e) {
      data = {}
    }
    // add to request status to data for checking it
    data.status = response.status
    data.ok = response.ok
  }
  return data
}

export const POST = (partialUrl: string, data: any) =>
  async function () {
    const request = new Request(`${API_URL}${partialUrl}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    })

    let response

    try {
      response = await fetch(request)

      if (!response.ok) {
        const err: any = new Error(`API error ${response.status} ${partialUrl}`)
        err.data = data
        Sentry.captureException(err)
      }
    } catch (error) {
      Sentry.captureException(error)
      if (!error.response) {
        alert('Something went wrong. Please try again or contact support if the error persists.')
      }
    }

    return await responseHandler(response)
  }
