import { useCallback, useMemo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { filter } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { useRelayEnvironment } from 'react-relay'

import graphqlOptionsLoader from '../graphqlOptionsLoader'

function useMarketeers(opts = { field: 'marketeers' }) {
  const { setFieldValue } = useFormikContext()
  const environment = useRelayEnvironment()

  const marketeerLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query useMarketeersQuery($where: MarketeerWhereInput) {
            viewer {
              options: marketeers(where: $where, first: 50) {
                edges {
                  node {
                    id
                    name
                    accessToken
                    fbAccessToken
                    fbPixelId
                    gaTrackingId
                    googleAdsConversionId
                    googleAdsPurchaseConversionLabel
                    privacyPolicyLink
                    tiktokPixelId
                    twitterCheckoutInitiatedPixelId
                    twitterPixelId
                    twitterPurchasePixelId
                    webOptInEnabled
                    appOptInEnabled
                  }
                }
              }
            }
          }
        `,
        { postProcess: filter((m) => !!m.accessToken) }
      ),
    [environment]
  )

  const setMarketeers = useCallback(
    (_ids: any, selection: any) => setFieldValue(opts.field, selection),
    [opts, setFieldValue]
  )

  return { setMarketeers, marketeerLoader }
}

export default useMarketeers
