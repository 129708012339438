import React, { FC, memo, useCallback, useMemo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { filter, find, getOr, isEmpty } from 'lodash/fp'
import InfiniteScroll from 'react-infinite-scroller'
import { useIntl } from 'react-intl'
import { usePaginationFragment } from 'react-relay'

import Button from '../../../../components/Button'
import EventAbbonamentoPickerTableHeader from './EventAbbonamentoPickerTableHeader'
import { EventAbbonamentoPickerTable_viewer$key } from '../../../../__generated__/EventAbbonamentoPickerTable_viewer.graphql'
import EventAbbonamentoPickerTableRow from './EventAbbonamentoPickerTableRow'
import { IAbbonamentoEvent } from '../../types/Timeline'
import { EmptyTableState, LoadMoreFooter, StyledTable, TableWrapper } from './EventAbbonamentoTableStyles'

interface IProps {
  viewer: EventAbbonamentoPickerTable_viewer$key
  orderBy?: string
  setOrderBy?: (v: string) => void
  loading?: boolean
  selectedEvents: IAbbonamentoEvent[]
  setSelectedEvents: (events: IAbbonamentoEvent[]) => void
  maxEventsSelected: boolean
}

const EventAbbonamentoPickerTable: FC<IProps> = ({
  viewer: viewerKey,
  orderBy,
  setOrderBy,
  loading,
  selectedEvents,
  setSelectedEvents,
  maxEventsSelected,
}) => {
  const {
    data: viewer,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    graphql`
      fragment EventAbbonamentoPickerTable_viewer on Viewer
      @refetchable(queryName: "EventAbbonamentoListPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
        where: { type: "EventWhereInput", defaultValue: {} }
        orderBy: { type: "[EventsConnectionOrder]", defaultValue: [dateASC] }
        scopes: { type: "EventScopesInput", defaultValue: {} }
        searchTerm: { type: String }
      ) {
        events(
          first: $count
          after: $cursor
          where: $where
          scopes: $scopes
          searchTerm: $searchTerm
          orderBy: $orderBy
        ) @connection(key: "EventAbbonamentoPickerTable_events") {
          edges {
            node {
              id
              ...EventAbbonamentoPickerTableRow_event
            }
          }
        }
      }
    `,
    viewerKey
  )

  const edges = useMemo(() => viewer.events?.edges || [], [viewer.events?.edges])
  const hasNextPage = getOr(false, 'events.pageInfo.hasNextPage', viewer)

  const intl = useIntl()

  const loadMoreEvents = useCallback(() => {
    if (!hasNext || isLoadingNext) return
    loadNext(20)
  }, [hasNext, isLoadingNext, loadNext])

  const validEdges = useMemo(() => filter('node', edges), [edges])

  const doLoadMore = useCallback(() => !isLoadingNext && loadMoreEvents(), [isLoadingNext, loadMoreEvents])

  const doOrderBy = useCallback(
    (e: any) => {
      const orderType = e.currentTarget.dataset.order
      if (setOrderBy) {
        setOrderBy(orderType)
      }
    },
    [setOrderBy]
  )

  const toggleEventSelected = useCallback(
    (event: IAbbonamentoEvent) => {
      if (find((e) => e.id === event.id, selectedEvents)) {
        setSelectedEvents(filter((e: IAbbonamentoEvent) => e.id !== event.id, selectedEvents))
      } else {
        setSelectedEvents([...selectedEvents, event])
      }
    },
    [selectedEvents, setSelectedEvents]
  )

  if (isEmpty(validEdges) && !loading) {
    return <EmptyTableState>{intl.formatMessage({ id: 'select.no_results' })}</EmptyTableState>
  }

  return (
    <TableWrapper>
      <StyledTable isLoading={loading}>
        <EventAbbonamentoPickerTableHeader currentOrder={orderBy} orderBy={setOrderBy && doOrderBy} />
        <InfiniteScroll loadMore={doLoadMore} hasMore={hasNextPage} element="tbody" threshold={5}>
          {validEdges.map((e, i) => {
            if (!e?.node) return
            const isSelected = !!find((ev) => ev.id === e.node?.id, selectedEvents)

            return (
              <EventAbbonamentoPickerTableRow
                key={e.node.id}
                event={e.node}
                selected={isSelected}
                onSelect={toggleEventSelected}
                disabled={maxEventsSelected && !isSelected}
              />
            )
          })}
        </InfiniteScroll>
        {!isLoadingNext && hasNextPage && (
          <LoadMoreFooter>
            <tr>
              <td colSpan={4}>
                <Button onClick={loadMoreEvents} className="mt-lg mb-lg">
                  {intl.formatMessage({ id: 'actions.load_more' })}
                </Button>
              </td>
            </tr>
          </LoadMoreFooter>
        )}
      </StyledTable>
    </TableWrapper>
  )
}

export default memo(EventAbbonamentoPickerTable)
