import * as Sentry from '@sentry/react'
import localforage from 'localforage'
import { fromPairs, isString } from 'lodash/fp'
// eslint-disable-next-line lodash-fp/use-fp
import { memoize as memoizeMultiArg } from 'lodash'
import { COMMIT_HASH } from '../../../env'

const deepMapObj = (mapper: (v: any, k: string) => any, obj: any) => {
  const data = JSON.stringify(obj)
  const values = JSON.parse(data, (k, v) => mapper(v, k))
  return values
}

const stashBlob = async (store: LocalForage, blobUrl: string): Promise<void> => {
  try {
    const existing = await store.getItem(blobUrl)
    if (existing) return

    Sentry.addBreadcrumb({
      category: 'formBackup',
      message: 'Stash blob',
      data: { url: blobUrl },
      level: 'info',
    })

    const rs = await fetch(blobUrl)
    const blob = await rs.blob()
    await store.setItem(blobUrl, blob)
  } catch (e) {
    Sentry.captureException(e)
  }
}

const unstashBlob = async (store: LocalForage, blobUrl: string): Promise<[string, string | null]> => {
  try {
    Sentry.addBreadcrumb({
      category: 'formBackup',
      message: 'Unstash blob',
      data: { url: blobUrl },
      level: 'info',
    })

    const blob: any = await store.getItem(blobUrl)
    const url = blob ? URL.createObjectURL(blob) : null
    return [blobUrl, url]
  } catch (e) {
    Sentry.captureException(e)
    return [blobUrl, null]
  }
}

// eslint-disable-next-line lodash-fp/no-extraneous-args
export const kvStore = memoizeMultiArg(
  (theKey: string, userId: string | null) =>
    localforage.createInstance({ name: `formBackup_${theKey}_${userId || 'anonymous'}` }),
  (theKey: string, userId: string | null) => `formBackup_${theKey}_${userId || 'anonymous'}`
)

export const canRestore = (theKey: string, userId: string | null) => () => {
  const hasBackup = localStorage.getItem(`hasBackup:${theKey}@${userId || 'anonymous'}`) === (COMMIT_HASH || 'local')
  return hasBackup
}

export const clearBackup = async (theKey: string, userId: string | null): Promise<void> => {
  const store = kvStore(theKey, userId)

  Sentry.addBreadcrumb({
    category: 'formBackup',
    message: 'Clear backup',
    data: { theKey, userId },
    level: 'info',
  })

  const hasBackupKey = `hasBackup:${theKey}@${userId || 'anonymous'}`
  localStorage.removeItem(hasBackupKey)
  await store.clear()
}

export const saveBackup = async (theKey: string, userId: string | null, values: any): Promise<void> => {
  const store = kvStore(theKey, userId)

  Sentry.addBreadcrumb({
    category: 'formBackup',
    message: 'Save backup',
    data: { theKey, userId },
    level: 'info',
  })

  try {
    await clearBackup(theKey, userId)
  } catch (e) {
    Sentry.captureException(e)
  }

  const hasBackupKey = `hasBackup:${theKey}@${userId || 'anonymous'}`

  const promises: Promise<void>[] = []

  const form = deepMapObj((value) => {
    if (value && isString(value) && value.startsWith('blob:')) {
      promises.push(stashBlob(store, value))
    }
    return value
  }, values)

  await Promise.all(promises)

  await store.setItem('backup', { [COMMIT_HASH || 'local']: form })
  localStorage.setItem(hasBackupKey, COMMIT_HASH || 'local')
}

export const restoreBackup = async (theKey: string, userId: string | null): Promise<any> => {
  const store = kvStore(theKey, userId)

  Sentry.addBreadcrumb({
    category: 'formBackup',
    message: 'Restore backup',
    data: { theKey, userId },
    level: 'info',
  })

  const backup: any = await store.getItem('backup')

  try {
    if (!backup) {
      const err = new Error(`No backup found for ${theKey}`)
      Sentry.captureException(err)
      throw err
    }

    const form = backup[COMMIT_HASH || 'local']

    if (!form) {
      const err = new Error(`Backup version don't match app version ${theKey}`)
      Sentry.captureException(err)
      throw err
    }

    const promises: Promise<[string, string | null]>[] = []

    const values = deepMapObj((value) => {
      if (value && isString(value) && value.startsWith('blob:')) {
        promises.push(unstashBlob(store, value))
      }
      return value
    }, form)

    const blobMap = fromPairs(await Promise.all(promises))

    const mappedValues = deepMapObj((value) => {
      if (value && isString(value) && value.startsWith('blob:')) {
        return blobMap[value] || value
      }
      return value
    }, values)

    return mappedValues
  } finally {
    try {
      await clearBackup(theKey, userId)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}
