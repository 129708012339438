/* eslint-disable max-lines */
import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { differenceInCalendarDays } from 'date-fns'
import { find, map, reject } from 'lodash/fp'

import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'

import { parseAtTimezone } from '../../../utils/calendar'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { color, font, input, spacing } from '../../../utils/variables'

import { FormRow } from '../../../components/Form'
import Collapsible, { CollapseButton, CollapseContainer } from '../../../components/Collapsible'
import { Text } from '../../../components/Text'

import IEventFormTickets, { ITicketType } from '../types/Tickets'

const TimelineCollapse = styled(Collapsible)`
  border: 1px solid ${input.borderColor};
  border-radius: ${input.borderRadius}px;

  ${CollapseContainer} {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
  }

  ${CollapseButton} {
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    & > *:first-child {
      margin-right: 0;
      margin-top: -1px;
    }
  }
`
const TimelineWrapper = styled.div`
  padding: ${spacing.md}px;
`
const TimelineBase = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`
const TimelineBaseDot = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  white-space: nowrap;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${color.black};
  &:last-child {
    align-items: flex-end;
    &:after {
      border-left: unset;
      border-right: 5px solid ${color.black};
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid ${color.black};
  }
`
const Timeline = styled.div`
  padding: 0 4px;
`
const TimelineTTName = styled.span`
  display: block;
  font-size: ${font.size.xs}px;
  margin: 0 -4px;
  white-space: nowrap;
  display: block;
  min-width: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`
const TimelineTTLine = styled.div`
  height: 8px;
  display: block;
  width: auto;
  position: relative;
  margin: 0 -4px;
  left: 0;
  min-width: 8px;
  &:before {
    content: '';
    display: block;
    width: 100%;
    min-width: 8px;
    height: 8px;
    background-color: ${color.lightgrey};
    border-radius: 4px;
  }
`
const TimelineTTInner = styled.div`
  position: relative;
  &:hover {
    ${TimelineTTName} {
      width: auto;
    }
  }
`
const TimelineTT = styled.div<{ current?: boolean }>`
  position: relative;
  & + & {
    margin-top: 8px;
  }
  ${({ current }) =>
    current &&
    `
    ${TimelineTTLine}:before {
      background-color: ${color.primary};
    }
  `}
`

interface IProps {
  event: any | null
  eventForm?: IEventFormTickets
  values: ITicketType
}

const EventTicketTypeTimeline: FC<React.PropsWithChildren<IProps>> = (props) => {
  const intl = useIntl()
  const event = useFragment<any>(
    graphql`
      fragment EventTicketTypeTimeline_event on Event {
        id
        state
        statusAsOfNow
        eventType
        onSaleDate
        offSaleDate
        timezoneName
        addressCountry
        countryCode
        ticketTypes(doorSalesOnly: false, includeArchived: true) {
          id
          name
          hidden
          archived
          onSaleDate
          offSaleDate
        }
      }
    `,
    props.eventForm?.id ? props.eventForm : props.event
  )

  const { values, eventForm } = props

  const {
    onSaleDate: eventOnSaleDate,
    offSaleDate: eventOffSaleDate,
    timezoneName,
    ticketTypes,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = (eventForm || event)!

  const onSale = useMemo(
    () =>
      intl.formatDate(eventOnSaleDate, {
        ...DATETIME_FORMATS.SHORT,
        timeZone: timezoneName || undefined,
      }),
    [eventOnSaleDate, intl, timezoneName]
  )

  const offSale = useMemo(
    () =>
      intl.formatDate(eventOffSaleDate, {
        ...DATETIME_FORMATS.SHORT,
        timeZone: timezoneName || undefined,
      }),
    [eventOffSaleDate, intl, timezoneName]
  )

  const timelineStart = useMemo(
    () => parseAtTimezone(eventOnSaleDate, timezoneName) || new Date(),
    [eventOnSaleDate, timezoneName]
  )
  const timelineEnd = useMemo(
    () => parseAtTimezone(eventOffSaleDate, timezoneName) || new Date(),
    [eventOffSaleDate, timezoneName]
  )
  const timelineDays = useMemo(() => differenceInCalendarDays(timelineEnd, timelineStart), [timelineEnd, timelineStart])

  const timelineCollection = useMemo(() => {
    const ttys = reject('archived', ticketTypes)
    return find((t) => t.id === values.id, ttys)
      ? map((tt) => (tt.id === values.id ? values : tt), ttys)
      : [...ttys, values]
  }, [ticketTypes, values])

  return (
    <TimelineCollapse
      label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.timeline.title' })}
      initialCollapsed
      dataId="ticketTypeTimeline"
    >
      <FormRow columnOnMobile>
        <TimelineWrapper>
          <TimelineBase>
            <TimelineBaseDot>
              <Text fontSize="xs" color="darkgrey">
                {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.timeline.event_sales_start' })}
              </Text>
              <Text fontSize="sm">{onSale}</Text>
            </TimelineBaseDot>
            <TimelineBaseDot>
              <Text fontSize="xs" color="darkgrey">
                {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.timeline.event_sales_end' })}
              </Text>
              <Text fontSize="sm">{offSale}</Text>
            </TimelineBaseDot>
          </TimelineBase>
          <Timeline>
            {timelineCollection.map((tt: ITicketType) => {
              const ticketTypeSalesStart = parseAtTimezone(tt.onSaleDate, timezoneName) || timelineStart
              const ticketTypeSalesEnd = parseAtTimezone(tt.offSaleDate, timezoneName) || timelineEnd
              const offsetLeft = (differenceInCalendarDays(ticketTypeSalesStart, timelineStart) * 100) / timelineDays
              const offsetRight = (differenceInCalendarDays(timelineEnd, ticketTypeSalesEnd) * 100) / timelineDays
              return (
                <TimelineTT key={`ticketType[${tt.id}]`} current={values.id === tt.id}>
                  <TimelineTTInner>
                    <TimelineTTName
                      style={{
                        // 98% - hack to show at least first letter with elipsis of ticketType name
                        paddingLeft: `${offsetLeft > 0 ? (offsetLeft > 98 ? 98 : offsetLeft) : 0}%`,
                      }}
                    >
                      {tt.name ||
                        intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.timeline.unnamed_ticket_type' })}
                    </TimelineTTName>
                    <TimelineTTLine
                      style={{
                        paddingLeft: `${offsetLeft > 0 ? offsetLeft : 0}%`,
                        paddingRight: `${offsetRight > 0 ? offsetRight : 0}%`,
                      }}
                    />
                  </TimelineTTInner>
                </TimelineTT>
              )
            })}
          </Timeline>
        </TimelineWrapper>
      </FormRow>
    </TimelineCollapse>
  )
}

export default memo(EventTicketTypeTimeline)
