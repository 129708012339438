import React, { FC, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { color, zIndex, mediaQuery } from '../utils/variables'
import { textStyle } from '../utils/typography'

interface IProps {
  page: 'event' | 'venue' | 'promoter'
}

const Banner = styled.div`
  top: 0;
  height: 34px;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.header};
  background-color: ${color.lightyellow};

  ${textStyle.functional.sm}

  .-impersonating & {
    top: 62px;
  }

  ${mediaQuery.lessThan('tablet')`
    top: 58px;
  `}
`

const TestPageBanner: FC<IProps> = ({ page }) => {
  const intl = useIntl()

  useEffect(() => {
    document.body.classList.add('-test-page')

    return () => {
      document.body.classList.remove('-test-page')
    }
  }, [])

  return (
    <Banner>
      <span>
        {intl.formatMessage(
          { id: `test_page.banner.${page}` },
          {
            b: (str: string) => <strong>{str}</strong>,
          }
        )}
      </span>
    </Banner>
  )
}

export default TestPageBanner
