import React, { memo, useState, useEffect, useCallback, FC, useContext } from 'react'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'

import { OnMobile } from '../../components/Breakpoints'
import ToolsLegacy from './components/legacy/Tools'
import FooterLegacy from './components/legacy/Footer'
import EventsLegacy from './components/legacy/Events'
import TopLegacy from './components/legacy/Top'
import { SIDEBAR_TOGGLER_TRIGGER_ID } from './services/sidebarTrigger'
import { authContext } from '../../context/auth'
import Logo from './components/Logo'
import Top from './components/Top'
import SortableSidebarList from './components/SortableSidebarList'
import Footer from './components/Footer'
import {
  MobileControls,
  SidebarBackDrop,
  SidebarScrollable,
  SidebarToogler,
  SidebarWrapper,
} from './components/legacy/Styles'

interface IProps {
  ICEenabled?: boolean
}

const Sidebar: FC<IProps> = ({ ICEenabled }) => {
  const { isImpersonated, user } = useContext(authContext)
  const { pathname } = useLocation()
  const [expandOnMobile, setExpandOnMobile] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (pathname) {
      setExpandOnMobile(false)
      setExpanded(false)
    }
  }, [pathname])

  const toggleMobileExapnded = useCallback(() => setExpandOnMobile((e) => !e), [setExpandOnMobile])
  const expandSidebar = useCallback(() => setExpanded(true), [setExpanded])
  const collapseSidebar = useCallback(() => setExpanded(false), [setExpanded])
  const onReload = useCallback(() => {
    setExpandOnMobile(false)
    setExpanded(false)
  }, [])

  return (
    <>
      <OnMobile>
        <MobileControls>
          <Logo mobile />
          <SidebarToogler id={SIDEBAR_TOGGLER_TRIGGER_ID} onClick={toggleMobileExapnded} icon="hamburger" />
        </MobileControls>
      </OnMobile>
      <SidebarWrapper
        className={cn('sidebar', expanded && 'expanded', expandOnMobile && 'mobile-expanded')}
        expandOnMobile={expandOnMobile}
        onMouseEnter={expandSidebar}
        onMouseLeave={collapseSidebar}
        ICEenabled={ICEenabled}
        isImpersonated={isImpersonated}
        v2={user.mioRedesignV2}
      >
        <OnMobile>
          <SidebarBackDrop expandOnMobile={expandOnMobile} onClick={toggleMobileExapnded} />
        </OnMobile>
        {user.mioRedesignV2 ? (
          <>
            <SidebarScrollable>
              <Logo v2 />
              <Top onReload={onReload} />
              <SortableSidebarList onReload={onReload} />
            </SidebarScrollable>
            <Footer expandOnMobile={expandOnMobile} />
          </>
        ) : (
          <>
            <Logo />
            <TopLegacy onReload={onReload} />
            <EventsLegacy />
            <ToolsLegacy />
            <FooterLegacy expandOnMobile={expandOnMobile} />
          </>
        )}
      </SidebarWrapper>
    </>
  )
}

export default memo(Sidebar)
