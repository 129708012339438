import React, { FC, memo, useCallback, useContext, useMemo, useRef } from 'react'
import { useFormikContext } from 'formik'
import { concat, filter, without, get, find, update, set } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useMediaQuery } from 'react-responsive'

import { Form, FormRow, Col } from '../../../components/Form'
import FormField, { FlexFormField } from '../../../components/FormField'
import FormGroup from '../../../components/FormGroup'
import IEventFormSettings from '../types/Settings'
import IconButton from '../../../components/IconButton'
import { markAsClientOnly } from '../../../utils/entityStatus'
import { breakpoints, mediaQuery } from '../../../utils/variables'
import { EventGuests_viewer$key } from '../../../__generated__/EventGuests_viewer.graphql'
import useProfileOptions from '../../../utils/hooks/useProfileOptions'
import EmptyList from '../../../components/EmptyList'
import ListAddButton from '../../../components/ListAddButton'
import { authContext } from '../../../context/auth'
import { EventGuestsAccountQuery } from '../../../__generated__/EventGuestsAccountQuery.graphql'

const StyledForm = styled(Form)`
  ${mediaQuery.lessThan('tablet')`
    ${FormRow} + ${FormRow} {
      margin-top: 32px;
    }

    ${Col} + ${Col} {
      margin-top: 8px;
    }
  `}
`

type IGuest = { id: string } & Omit<NonNullable<NonNullable<IEventFormSettings['eventSharingObjects']>[number]>, 'id'>

interface IProps {
  readOnly?: boolean
  viewer: EventGuests_viewer$key
}

interface IGuestRow {
  guest: IGuest
  idx: number
  readOnly?: boolean
  permissionProfileOptions: any
  onChangeProfile: (idx: number, id: string) => void
  onRemove: (idx: number) => void
}

const EventGuest: FC<React.PropsWithChildren<IGuestRow>> = ({
  guest,
  idx,
  readOnly,
  permissionProfileOptions,
  onChangeProfile,
  onRemove,
}) => {
  const intl = useIntl()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { touched, errors, handleChange, handleBlur } = useFormikContext<IEventFormSettings>()

  const handleProfileChange = useCallback(
    (id: string) => {
      onChangeProfile(idx, id)
    },
    [idx, onChangeProfile]
  )

  const removeGuest = useCallback(() => {
    onRemove(idx)
  }, [idx, onRemove])

  return (
    <FormRow columnOnMobile data-id={`guest[${guest.id}]`}>
      <FormField
        label={
          idx > 0 || isMobile ? undefined : intl.formatMessage({ id: 'new_event.settings.guest_access.email.label' })
        }
        placeholder={!isMobile ? undefined : intl.formatMessage({ id: 'new_event.settings.guest_access.email.label' })}
        required
        name={`eventSharingObjects[${idx}].email`}
        value={guest.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={get(`eventSharingObjects[${idx}].email`, touched) && get(`eventSharingObjects[${idx}].email`, errors)}
        disabled={readOnly}
      />
      <FlexFormField
        label={
          idx > 0 || isMobile
            ? undefined
            : intl.formatMessage({ id: 'new_event.settings.guest_access.permission_profile.label' })
        }
        placeholder={
          !isMobile ? undefined : intl.formatMessage({ id: 'new_event.settings.guest_access.permission_profile.label' })
        }
        control="select"
        options={permissionProfileOptions}
        name={`eventSharingObjects[${idx}].permissionProfile`}
        value={find(['value', guest.permissionProfile?.id], permissionProfileOptions)}
        required
        searchable
        onChange={handleProfileChange}
        onBlur={handleBlur}
        disabled={readOnly}
        error={
          get(`eventSharingObjects[${idx}].permissionProfile`, touched) &&
          get(`eventSharingObjects[${idx}].permissionProfile`, errors)
        }
      >
        <IconButton data-id="removeGuestButton" icon="trash" onClick={removeGuest} disabled={readOnly} />
      </FlexFormField>
    </FormRow>
  )
}

const EventGuests: FC<React.PropsWithChildren<IProps>> = ({ readOnly, viewer: viewerKey }) => {
  const intl = useIntl()
  const { user, hasPermission } = useContext(authContext)

  const { values, touched, errors, setFieldValue, setFieldTouched, validateForm } =
    useFormikContext<IEventFormSettings>()

  const { permissionProfiles } = useFragment(
    graphql`
      fragment EventGuests_viewer on Viewer {
        permissionProfiles {
          value: id
          caption
          roleName
          diceStaff
        }
      }
    `,
    viewerKey
  )

  const { account } = useLazyLoadQuery<EventGuestsAccountQuery>(
    graphql`
      query EventGuestsAccountQuery($id: ID!) {
        account: node(id: $id) {
          ... on Promoter {
            customPermissionProfiles: accountPermissionProfiles {
              value: id
              roleName
              caption
              diceStaff
            }
          }
        }
      }
    `,
    {
      id: values.billingPromoter?.value || 'oops',
    },
    {
      fetchPolicy: !!values.billingPromoter?.value && user.diceStaff ? 'store-and-network' : 'store-only',
    }
  )

  const readOnlyProfileId = useMemo(
    () => find(['roleName', 'client_read_only'], permissionProfiles)?.value || null,
    [permissionProfiles]
  )

  const ref = useRef<HTMLDivElement>(null)

  const guests: IGuest[] = useMemo(
    () => filter('id', values.eventSharingObjects || []) as any,
    [values.eventSharingObjects]
  )

  const addGuest = useCallback(() => {
    const idx = (guests || []).length
    const newGuest = markAsClientOnly<IGuest>({
      email: null,
      permissionProfile: readOnlyProfileId !== null ? { id: readOnlyProfileId } : null,
    })

    setFieldValue('eventSharingObjects', concat(guests, newGuest), true)

    setFieldTouched('eventSharingObjects', true, true)
    setTimeout(() => validateForm(), 0)

    setTimeout(() => {
      if (ref.current) {
        const node = ref.current.querySelector(`input[name="eventSharingObjects[${idx}].email"]`) as HTMLInputElement
        if (node) node.focus()
      }
    }, 500)
  }, [guests, readOnlyProfileId, setFieldTouched, setFieldValue, validateForm])

  const removeGuest = useCallback(
    (idx: number) => {
      const guest = guests[idx]
      setFieldValue('eventSharingObjects', without([guest], guests))

      setFieldTouched('eventSharingObjects', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [guests, setFieldTouched, setFieldValue, validateForm]
  )

  const changeGuestProfile = useCallback(
    (idx: number, id: string) => {
      setFieldValue('eventSharingObjects', update([idx], set('permissionProfile.id', id), guests))

      setFieldTouched('eventSharingObjects', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [guests, setFieldTouched, setFieldValue, validateForm]
  )

  const allProfiles = useMemo(
    () => concat(account?.customPermissionProfiles || [], permissionProfiles || []),
    [account?.customPermissionProfiles, permissionProfiles]
  )

  const { profileOptions } = useProfileOptions(allProfiles, !user.diceStaff)

  return hasPermission('invite_external_guest:event') || !!values.allowedActions?.inviteExternalGuest ? (
    <FormRow columnOnMobile>
      <FormGroup
        data-name="guestAccess"
        label={intl.formatMessage({ id: 'new_event.settings.guest_access.label' })}
        help={intl.formatMessage({ id: 'new_event.settings.guest_access.help' })}
        error={touched.eventSharingObjects && errors.eventSharingObjects}
        disabled={readOnly}
      >
        <div ref={ref}>
          <StyledForm spacing="small">
            {guests.map((guest, idx) => (
              <EventGuest
                key={guest.id}
                guest={guest}
                idx={idx}
                readOnly={readOnly}
                onRemove={removeGuest}
                onChangeProfile={changeGuestProfile}
                permissionProfileOptions={profileOptions}
              />
            ))}
            <FormRow columnOnMobile>
              {guests.length === 0 ? (
                <EmptyList
                  icon="letter"
                  cta={intl.formatMessage({ id: 'new_event.settings.guest_access.add_button.label' })}
                  title={intl.formatMessage({ id: 'new_event.settings.guest_access.empty_title' })}
                  subTitle={intl.formatMessage({ id: 'new_event.settings.guest_access.empty_message' })}
                  hasError={touched.eventSharingObjects && !!errors.eventSharingObjects}
                  onAdd={addGuest}
                  disabled={readOnly}
                />
              ) : (
                <>
                  {!readOnly && (
                    <ListAddButton
                      label={intl.formatMessage({ id: 'new_event.settings.guest_access.add_button.label' })}
                      onClick={addGuest}
                    />
                  )}
                  <div />
                </>
              )}
            </FormRow>
          </StyledForm>
        </div>
      </FormGroup>
    </FormRow>
  ) : null
}

export default memo(EventGuests)
