import { useLayoutEffect, useRef, useState, useEffect } from 'react'
import { concat, throttle } from 'lodash/fp'

function persistProp<T extends HTMLElement>(propName: string, el: T, prevValue: number) {
  const height = el.offsetHeight
  if (prevValue !== height) {
    document.documentElement.style.setProperty(propName, `${el.offsetHeight}px`)
    return () => {
      document.documentElement.style.removeProperty(propName)
    }
  }
}

const throttledPersist = throttle(300, persistProp)

export const getExposedHeight = (propName: string) =>
  Number(document.documentElement.style.getPropertyValue(propName).replace(/px$/, '')) || 0

export default function useExposeHeightToCss<T extends HTMLElement>(propName: string, deps: any | any[] = []) {
  const ref = useRef<T>(null)
  const [size, setSize] = useState<string | null>(null)
  const prevValue = useRef(0)

  useEffect(() => {
    const lsnr = () => setSize(`${window.innerWidth}x${window.innerHeight}`)

    const throttled = throttle(300, lsnr)

    window.addEventListener('resize', throttled, false)
    return () => {
      window.removeEventListener('resize', throttled)
    }
  }, [setSize])

  useLayoutEffect(() => {
    const el = ref.current
    if (el) {
      throttledPersist(propName, el, prevValue.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, concat(deps, [propName, size, prevValue, ref]))

  return ref
}
