import React, { FC, memo, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import cn from 'classnames'
import { compose, get, find, getOr } from 'lodash/fp'
import { Link } from 'react-router-dom'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Svg from '../../../components/Svg'

import { color, mediaQuery } from '../../../utils/variables'

import { EventPartImage_event$key } from '../../../__generated__/EventPartImage_event.graphql'

export const EventListCardImage = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  flex: 72px 0 0;
  width: 72px;
  height: 72px;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
  margin: 0 16px 0 0;
  border-radius: 8px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    pointer-events: none;
    z-index: 1;
  }
  &.-no-image {
    background: ${color.white};
    color: ${color.text};
  }
  &.-hidden {
    opacity: 0.5;
  }
  ${(props) =>
    !!props.onClick &&
    `
    cursor: pointer;
  `}
`

const EventListCardImageTag = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  ${mediaQuery.lessThan('tablet')`
    width: 100%;
    height: auto;
  `}
`

const EventListCardImageMissed = styled(Svg)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

interface IProps {
  event: EventPartImage_event$key
  onClick?: (e: Event) => void
  showHidden?: boolean
  className?: string
}

const EventPartImage: FC<IProps> = ({ event: eventKey, onClick, showHidden, className }) => {
  const event = useFragment(
    graphql`
      fragment EventPartImage_event on Event {
        id
        state
        flags {
          hidden
        }
        eventImages {
          type
          cdnUrl
        }
      }
    `,
    eventKey
  )

  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (onClick) onClick(e)
    },
    [onClick]
  )

  const imgUrl = useMemo(
    () =>
      compose(
        (url) => (url ? url + '&h=200&w=200&auto=compress' : undefined),
        get('cdnUrl'),
        find(['type', 'square'])
      )(event.eventImages),
    [event.eventImages]
  )

  const eventPath = event.state === 'DRAFT' ? `/events/${event.id}/edit` : `/events/${event.id}/overview`

  const hidden = showHidden && getOr(false, 'flags.hidden.active', event)

  return (
    <EventListCardImage className={cn({ '-no-image': !imgUrl, '-hidden': hidden }, className)} onClick={handleClick}>
      <Link to={eventPath}>
        {imgUrl ? (
          <EventListCardImageTag loading="lazy" src={imgUrl} alt="" />
        ) : (
          <EventListCardImageMissed icon="image-placeholder" />
        )}
      </Link>
    </EventListCardImage>
  )
}

export default memo(EventPartImage)
