/**
 * @generated SignedSource<<44e54729e2b66976c495f2347e4851c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChannelType = "APP_SALE" | "BOX_OFFICE_SALE" | "HOLD" | "NON_DICE" | "OTHER" | "%future added value";
export type CompatibilityAe = "_0" | "_1000" | "_5000" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type RepeatEnds = "OCCURRENCES" | "UNTIL" | "%future added value";
export type RepeatFrequency = "BI_WEEKLY" | "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type RepeatOn = "LAST_WEEK_DAY" | "SAME_DAY" | "SAME_WEEK_AND_DAY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Settings_event$data = {
  readonly addressCountry: string | null;
  readonly allowedActions: {
    readonly inviteExternalGuest: boolean | null;
    readonly manageLinks: boolean | null;
    readonly manageTickets: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly extraNotes: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly links: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly manualValidationEnabled: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly recurringEvents: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly requiresBoxOfficeTicketNomination: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly requiresTicketNomination: {
      readonly canUpdate: boolean | null;
    } | null;
    readonly sendReceiptViaSms: {
      readonly canUpdate: boolean | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly attractiveFields: {
    readonly compatibilityAe: CompatibilityAe | null;
  } | null;
  readonly billingNotes: string | null;
  readonly billingPromoter: {
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly associatedMarketeers: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly billingNotes: string | null;
    readonly coolingOffPeriod: boolean | null;
    readonly countryCode: string | null;
    readonly disableUsTax: boolean | null;
    readonly holdPayouts: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly sendReceiptViaSms: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly stripeFallbackAccountId: string | null;
    readonly stripeFallbackPlatformCode: PlatformAccountCode | null;
    readonly tags: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly value: string;
  } | null;
  readonly bundles: ReadonlyArray<{
    readonly label: string;
    readonly value: string;
  } | null> | null;
  readonly cities: ReadonlyArray<{
    readonly label: string;
    readonly value: string;
  } | null> | null;
  readonly countryCode: string | null;
  readonly date: string | null;
  readonly doorlistAdditionalRecipients: ReadonlyArray<string | null> | null;
  readonly doorlistRecipients: ReadonlyArray<{
    readonly email: string | null;
    readonly id: string;
  } | null> | null;
  readonly doorlistSendAt: string | null;
  readonly doorlistSendStatus: string | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly eventImages: ReadonlyArray<{
    readonly attachment: {
      readonly id: string;
    } | null;
    readonly cdnUrl: string;
    readonly cropRegion: {
      readonly height: number | null;
      readonly width: number | null;
      readonly x: number | null;
      readonly y: number | null;
    } | null;
    readonly id: string;
    readonly type: string | null;
  } | null> | null;
  readonly eventLoadPredictions: ReadonlyArray<{
    readonly expectedRequestsPerMinute: number | null;
    readonly expectedStartTime: string | null;
    readonly id: string;
  } | null> | null;
  readonly eventSeatingChart: {
    readonly chartManagerCredentials: {
      readonly secretKey: string;
    };
    readonly id: string;
    readonly seatingChannels: ReadonlyArray<{
      readonly channelType: ChannelType;
      readonly name: string | null;
      readonly seatsIoChannel: string;
    } | null> | null;
    readonly seatsIoEventId: string;
    readonly venueChart: {
      readonly label: string;
      readonly value: string;
    };
  } | null;
  readonly eventSharingObjects: ReadonlyArray<{
    readonly email: string;
    readonly id: string | null;
    readonly permissionProfile: {
      readonly id: string;
    };
  } | null> | null;
  readonly extraNotes: string | null;
  readonly fanSupportNotes: {
    readonly body: string | null;
  } | null;
  readonly flags: {
    readonly claimTickets: IBackendMap | null;
    readonly coolingOffPeriod: IBackendMap | null;
    readonly hidden: IBackendMap | null;
    readonly hideFromDiscovery: IBackendMap | null;
    readonly shoppingCart: IBackendMap | null;
    readonly unicorn: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly isTest: boolean | null;
  readonly labels: ReadonlyArray<{
    readonly label: string;
    readonly value: string;
  } | null> | null;
  readonly links: ReadonlyArray<{
    readonly name: string | null;
    readonly url: string | null;
  } | null>;
  readonly manualValidationEnabled: boolean | null;
  readonly marketeers: ReadonlyArray<{
    readonly appOptInEnabled: boolean | null;
    readonly fbAccessToken: string | null;
    readonly fbPixelId: string | null;
    readonly gaTrackingId: string | null;
    readonly googleAdsConversionId: string | null;
    readonly googleAdsPurchaseConversionLabel: string | null;
    readonly label: string | null;
    readonly privacyPolicyLink: string | null;
    readonly tiktokPixelId: string | null;
    readonly twitterCheckoutInitiatedPixelId: string | null;
    readonly twitterPixelId: string | null;
    readonly twitterPurchasePixelId: string | null;
    readonly value: string;
    readonly webOptInEnabled: boolean | null;
  } | null> | null;
  readonly notes: string | null;
  readonly offSaleDate: string | null;
  readonly offSaleSentAt: string | null;
  readonly offSaleSentStatus: string | null;
  readonly onSaleDate: string | null;
  readonly previewToken: string | null;
  readonly promoters: ReadonlyArray<{
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly associatedMarketeers: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly billingNotes: string | null;
    readonly coolingOffPeriod: boolean | null;
    readonly countryCode: string | null;
    readonly disableUsTax: boolean | null;
    readonly holdPayouts: boolean | null;
    readonly label: string | null;
    readonly labels: ReadonlyArray<{
      readonly label: string;
      readonly value: string;
    } | null> | null;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly sendReceiptViaSms: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly stripeFallbackAccountId: string | null;
    readonly stripeFallbackPlatformCode: PlatformAccountCode | null;
    readonly tags: ReadonlyArray<{
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
    readonly value: string;
  } | null> | null;
  readonly recurrentEventSchedule: {
    readonly frequency: RepeatFrequency | null;
    readonly occurrences: number | null;
    readonly repeatEnds: RepeatEnds | null;
    readonly repeatOn: RepeatOn | null;
    readonly until: string | null;
  } | null;
  readonly recurrentEventsGroup: ReadonlyArray<{
    readonly date: string | null;
    readonly id: string;
  } | null> | null;
  readonly requiresBoxOfficeTicketNomination: boolean | null;
  readonly requiresTicketNomination: boolean | null;
  readonly sendReceiptViaSms: boolean | null;
  readonly state: EventState | null;
  readonly tags: ReadonlyArray<{
    readonly label: string | null;
    readonly value: string;
  } | null> | null;
  readonly thirdPartySettings: {
    readonly label: string;
    readonly value: string;
  } | null;
  readonly ticketType: string | null;
  readonly timezoneName: string | null;
  readonly venues: ReadonlyArray<{
    readonly addressCountry: string | null;
    readonly countryCode: string | null;
    readonly value: string;
  } | null> | null;
  readonly " $fragmentType": "Settings_event";
};
export type Settings_event$key = {
  readonly " $data"?: Settings_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"Settings_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingNotes",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canUpdate",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendReceiptViaSms",
  "storageKey": null
},
v7 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v7/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coolingOffPeriod",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Label",
  "kind": "LinkedField",
  "name": "labels",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v14 = [
  (v7/*: any*/),
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stripeAccountId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stripeFallbackAccountId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stripeFallbackPlatformCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "platformAccountCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceSuggestions",
    "storageKey": null
  },
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "allowSkipReview",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "resoldEnabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "disableUsTax",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "holdPayouts",
    "storageKey": null
  },
  (v11/*: any*/),
  (v6/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Marketeer",
    "kind": "LinkedField",
    "name": "associatedMarketeers",
    "plural": true,
    "selections": (v10/*: any*/),
    "storageKey": null
  },
  (v2/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v16 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Settings_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FanSupportNotes",
      "kind": "LinkedField",
      "name": "fanSupportNotes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiresTicketNomination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiresBoxOfficeTicketNomination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualValidationEnabled",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doorlistSendStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleSentStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doorlistSendAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleSentAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inviteExternalGuest",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageLinks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageTickets",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "extraNotes",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "links",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "recurringEvents",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "requiresTicketNomination",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "requiresBoxOfficeTicketNomination",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "manualValidationEnabled",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdateBase",
          "kind": "LinkedField",
          "name": "sendReceiptViaSms",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AttractiveFields",
      "kind": "LinkedField",
      "name": "attractiveFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "compatibilityAe",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ThirdPartySettings",
      "kind": "LinkedField",
      "name": "thirdPartySettings",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": "label",
          "args": null,
          "kind": "ScalarField",
          "name": "appName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSeatingChart",
      "kind": "LinkedField",
      "name": "eventSeatingChart",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seatsIoEventId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartManagerCredentials",
          "kind": "LinkedField",
          "name": "chartManagerCredentials",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "secretKey",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatingChannel",
          "kind": "LinkedField",
          "name": "seatingChannels",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seatsIoChannel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "channelType",
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SeatingChart",
          "kind": "LinkedField",
          "name": "venueChart",
          "plural": false,
          "selections": (v10/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": (v14/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "promoters",
      "plural": true,
      "selections": (v14/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Marketeer",
      "kind": "LinkedField",
      "name": "marketeers",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fbAccessToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fbPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gaTrackingId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleAdsConversionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleAdsPurchaseConversionLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "privacyPolicyLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterCheckoutInitiatedPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterPixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "twitterPurchasePixelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webOptInEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appOptInEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventSharingObject",
      "kind": "LinkedField",
      "name": "eventSharingObjects",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v15/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PermissionProfile",
          "kind": "LinkedField",
          "name": "permissionProfile",
          "plural": false,
          "selections": (v16/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "doorlistRecipients",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v15/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "doorlistAdditionalRecipients",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        },
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "claimTickets",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unicorn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shoppingCart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideFromDiscovery",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "City",
      "kind": "LinkedField",
      "name": "cities",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Bundle",
      "kind": "LinkedField",
      "name": "bundles",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventImage",
      "kind": "LinkedField",
      "name": "eventImages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cdnUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "attachment",
          "plural": false,
          "selections": (v16/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CropRegion",
          "kind": "LinkedField",
          "name": "cropRegion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "x",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "y",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "recurrentEventsGroup",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecurrentEventsScheduleConfig",
      "kind": "LinkedField",
      "name": "recurrentEventSchedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frequency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "occurrences",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatEnds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeatOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "until",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EventLoadPrediction",
      "kind": "LinkedField",
      "name": "eventLoadPredictions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expectedRequestsPerMinute",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expectedStartTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "d46c6da33777c89ee97632ea1b21b630";

export default node;
