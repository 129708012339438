import { HTMLProps } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { mediaQuery, color, zIndex } from '../../../utils/variables'
import { textStyle } from '../../../utils/typography'

export const Col = styled.div<HTMLProps<HTMLDivElement> & { grow?: boolean; hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  flex: ${({ grow }) => (grow ? '2 1 0' : '1 1 0')};
  padding: 0 8px;
  &.-text-right {
    justify-content: flex-end;
  }
  &.-controls {
    flex: 3 1 0;
    display: grid;
    grid-template-columns: 1fr 48px;

    ${mediaQuery.greaterThan('desktopLarge')`
      grid-template-columns: 1fr 48px 48px;
    `}
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`

export const Row = styled.div<{ isHidden?: boolean }>`
  display: flex;
  position: relative;
  margin: 0 -8px;
  padding: 8px;
  border-radius: 4px;

  color: ${({ isHidden }) => (isHidden ? color.darkgrey : color.text)};
`

export const RowItem = styled(Row)`
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${color.palegrey};
  }
`

export const MainCol = styled(Col)`
  ${mediaQuery.lessThan('desktopLarge')`
    flex: 3 1 0;
  `}
  ${Col} {
    overflow: hidden;
  }
`

export const ExtraCol = styled(Col)`
  ${mediaQuery.lessThan('desktopLarge')`
    flex: 2 1 0;
  `}
  ${mediaQuery.lessThan('tablet')`
    padding-left: 0;
  `}
`

export const HeaderRow = styled(Row)`
  position: sticky;
  top: 132px;
  background: ${color.white};
  z-index: ${zIndex.dropdown};
  border-radius: 0;
  margin: -8px -8px 0;
  overflow: unset;

  .-impersonating & {
    top: 194px;
  }

  ${Col} {
    z-index: 1;
  }
  &.-bordered {
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 56px;
      bottom: 0;
      background: #fff;
      box-shadow: 1000px 0 ${color.white}, -1000px 0 ${color.white};
      ${mediaQuery.lessThan('1300px')`
        box-shadow: 30px 0 ${color.white}, -30px 0 ${color.white};
      `}
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      border-bottom: 1px solid ${color.lightgrey};
      box-shadow: 1000px 0 ${color.lightgrey}, -1000px 0 ${color.lightgrey};
      ${mediaQuery.lessThan('1300px')`
        box-shadow: 30px 0 ${color.lightgrey}, -30px 0 ${color.lightgrey};
      `}
    }
  }
`

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  ${textStyle.functional.sm}
  color: ${color.darkgrey};
  height: 40px;
  white-space: nowrap;
  &.-text-right {
    justify-content: flex-end;
  }
  &.-can-order {
    font-weight: bold;
    cursor: pointer;
    &.-asc,
    &.-desc {
      color: ${color.primary};
      font-weight: bold;
      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        margin-top: -2px;
        width: 0px;
        height: 0px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid currentColor;
      }
    }
    &.-desc {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`

export const EventName = styled(Link)`
  margin: 0;
  ${textStyle.interactive.lg}
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  margin-bottom: 2px;
  ${mediaQuery.lessThan('tablet')`
    margin-left: 4px;
    padding-right: 48px;
  `}
`

export const PartnerTier = styled.span`
  opacity: 0.5;
`

export const EventList = styled.div`
  position: relative;
  &.-with-filters {
    padding-bottom: 72px;
  }
  ${mediaQuery.lessThan('desktop')`
    margin: 0 -16px;
  `}
`

export const EventListCard = styled.div<{ isHidden?: boolean }>`
  position: relative;
  padding: 16px;
  border-top: 1px solid ${color.lightgrey};

  color: ${({ isHidden }) => (isHidden ? color.darkgrey : color.text)};

  &:first-child {
    border: 0;
  }
`
export const EventListCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  overflow: hidden;
`

export const EventListCardTitleWrapper = styled.div`
  display: flex;
`

export const EventListRow = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  ${mediaQuery.lessThan('desktop')`
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  `}
`
