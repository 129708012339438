import { useMemo } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { countSeats } from '../../../utils/seats'
import { useMaxSeatsCapacityQuery } from '../../../__generated__/useMaxSeatsCapacityQuery.graphql'

function useMaxSeatsCapacity(seatingChartId: string | null) {
  const maxCapacityData = useLazyLoadQuery<useMaxSeatsCapacityQuery>(
    graphql`
      query useMaxSeatsCapacityQuery($id: ID!) {
        seatingChart: node(id: $id) {
          ... on EventSeatingChart {
            seatsIoEventReport(group: BY_SELECTABILITY)
          }
        }
      }
    `,
    {
      id: seatingChartId || 'NO_ID',
    },
    {
      fetchPolicy: seatingChartId ? 'network-only' : 'store-only',
    }
  )

  const selectableSeatsReport = maxCapacityData.seatingChart?.seatsIoEventReport as any

  const maxSeatsCapacity = useMemo(
    () => countSeats(selectableSeatsReport?.selectable),
    [selectableSeatsReport?.selectable]
  )

  return maxSeatsCapacity
}

export default useMaxSeatsCapacity
