import { useCallback, useContext } from 'react'
import { compose, find, set, update, concat, map } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'

import unwrapId from '../../../utils/unwrapId'
import useAllowedAdhocFees from './useAllowedAdhocFees'
import { getDefaultTicketType } from '../services/getDefaultEvent'
import { markAsClientOnlyNewId, markAsNew } from '../../../utils/entityStatus'
import IEventFormTickets, { ITicketType, ITicketPool } from '../types/Tickets'
import { authContext } from '../../../context/auth'
import { localeContext } from '../../../context/locale'
import { trackingContext } from '../../../context/tracking'

interface ICtx {
  canAdd: boolean
  ticketPool?: ITicketPool
  seated: boolean
  onAdd: (id: string) => void
}

function useAddTicketType(allTtys: ITicketType[], ctx: ICtx) {
  const intl = useIntl()

  const { values, setFieldValue } = useFormikContext<IEventFormTickets>()

  const { account } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const { trackEvent } = useContext(trackingContext)

  const { canAdd, ticketPool, seated, onAdd } = ctx

  const adhocFeesAccountId = canAdd ? values.billingPromoter?.accountId || account?.id : null
  const allowedAdhocFees = useAllowedAdhocFees(adhocFeesAccountId, values.id)

  const addTicketType = useCallback(() => {
    let newTTY =
      allTtys.length === 0
        ? getDefaultTicketType(values as any, intl, allowedAdhocFees, locale)
        : set('name', '', getDefaultTicketType(values as any, intl, allowedAdhocFees, locale))

    if (ticketPool?.id) {
      newTTY = compose([set('ticketPoolId', ticketPool.id), set('allocation', 0)])(newTTY)
    }

    if (seated && values.eventType !== 'STREAM') {
      newTTY = compose(
        [set('reservedSeating', true)],
        set('reservedSeatingType', 'assignBestSeat'),
        set('allowSeatChange', true)
      )(newTTY)
    }

    const trackData = {
      event_id: unwrapId(values.id),
      event_id_live: values.eventIdLive,
      ticket_type_id: unwrapId(newTTY.id),
    }

    trackEvent('ticket_type_create_clicked', trackData)

    setFieldValue('ticketTypes', concat(allTtys, newTTY))

    setTimeout(() => onAdd(newTTY.id), 0)
  }, [allTtys, values, intl, allowedAdhocFees, locale, ticketPool?.id, seated, trackEvent, setFieldValue, onAdd])

  const copyTicketType = useCallback(
    (copiedId: any) => {
      const tty = find(['id', copiedId], allTtys)
      if (tty) {
        const newTTY = compose([
          set('name', ''),
          markAsNew,
          // eslint-disable-next-line lodash-fp/no-extraneous-function-wrapping
          update('priceTiers', (ptArr) => ptArr && map(markAsClientOnlyNewId, ptArr)),
        ])(tty)

        const trackData = {
          event_id: unwrapId(values.id),
          event_id_live: values.eventIdLive,
          ticket_type_id: unwrapId(newTTY.id),
        }

        trackEvent('ticket_type_create_clicked', trackData)

        setFieldValue('ticketTypes', concat(allTtys, newTTY))
        setTimeout(() => onAdd(newTTY.id), 0)
      }
    },
    [allTtys, values.id, values.eventIdLive, trackEvent, setFieldValue, onAdd]
  )

  return { addTicketType, copyTicketType }
}

export default useAddTicketType
