import React, { FC, PropsWithChildren } from 'react'
import { Outlet } from 'react-router'
import AuthHeader from '../flows/Auth/AuthHeader'
import AuthLayout from '../flows/Auth/AuthLayout'

const AuthenticationLayout: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <>
    <AuthHeader />
    <AuthLayout withBuffer>{children || <Outlet />}</AuthLayout>
  </>
)

export default AuthenticationLayout
