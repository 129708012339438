import React, { memo, useContext } from 'react'
import styled, { css } from 'styled-components/macro'
import { addDays, differenceInCalendarDays, format, isSameDay } from 'date-fns'

import { color, font } from '../../../../utils/variables'
import EventTimelineContext from '../../util/eventTimelineContext'
import { CELL_WIDTH } from '../../util/eventTimelineVariables'

const Container = styled.div`
  display: flex;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  width: fit-content;
`

const Day = styled.span<{ today?: boolean }>`
  font-size: ${font.size.sm}px;
  font-weight: ${font.weight.bold};
  color: ${color.black};
  width: ${CELL_WIDTH}px;
  height: ${CELL_WIDTH}px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.today &&
    css`
      color: ${color.white};
      background-color: ${color.black};
      border-radius: 30px;
    `}
`

const TimelineDays = () => {
  const today = new Date()
  const { timelineEnd, timelineStart } = useContext(EventTimelineContext)

  const days = [...Array(differenceInCalendarDays(timelineEnd, timelineStart) + 1)].map((_, shift) =>
    addDays(timelineStart, shift)
  )

  return (
    <Container>
      {days.map((day, pos) => (
        <Day key={pos} today={isSameDay(today, day)}>
          {format(day, 'd')}
        </Day>
      ))}
    </Container>
  )
}

export default memo(TimelineDays)
