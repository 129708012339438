import { omit } from 'lodash/fp'
import { Dictionary } from 'ts-essentials'
import { POST } from './api'

export type IPreferredLanguage = 'en_gb' | 'en_us' | 'fr' | 'es' | 'ca' | 'it' | 'pt' | 'de'

const REQUESTS = {
  OBTAIN_TOKEN: (credentials: {
    email: string
    password: string
  }): Promise<{ access_token?: string; status?: number }> => POST('/api/session', credentials)(),

  FORGOT_PASSWORD: (email: string): Promise<{ status?: number }> =>
    POST('/api/password_reset/send_instructions?use_mio_url=true', { email })(),

  RESET_PASSWORD: ({
    token,
    password,
  }: {
    token: string
    password: string
  }): Promise<{ status?: number; description?: string }> =>
    POST('/api/password_reset/set_new_password', { token, new_password: password })(),

  ACCEPT_INVITE: ({
    token,
    firstName,
    lastName,
    password,
  }: {
    token: string
    firstName: string
    lastName: string
    password: string
  }): Promise<{ description?: string; errors?: Array<Dictionary<string>>; ok?: boolean }> =>
    POST('/api/accounts/accept_invitation', { token, first_name: firstName, last_name: lastName, password })(),

  CREATE_ACCOUNT: ({
    firstName,
    lastName,
    country,
    countryCode,
    addressLocality,
    companyName,
    displayName,
    typeOfOrganizer,
    email,
    password,
    preferredLanguage,
    timezoneName,
    ...countrySpecificFields
  }: {
    firstName: string
    lastName: string
    country: string
    countryCode: string
    addressLocality: string
    companyName: string
    displayName: string
    typeOfOrganizer: string
    email: string
    password: string
    preferredLanguage: IPreferredLanguage
    timezoneName: string
  }): Promise<{ status?: number; account?: { id: string }; errors?: any }> =>
    POST('/api/accounts?use_mio_url=true', {
      user: { firstName, lastName, email, password, preferredLanguage, timezoneName },
      account: {
        ...omit(['hasUSEvents', 'hasEUEvents', 'vatNumber'], countrySpecificFields),
        hasUsEvents: (countrySpecificFields as any)?.hasUSEvents,
        hasEuEvents: (countrySpecificFields as any)?.hasEuEvents,
        accountVatNumber: (countrySpecificFields as any)?.vatNumber,
        name: companyName,
        displayName,
        addressCountry: country,
        addressLocality,
        countryCode,
        typeOfOrganizer,
      },
    })(),

  CONFIRM_ACCOUNT: (
    token: string
  ): Promise<{ status?: number; user?: { id: string; firstName?: string }; errors?: any }> =>
    POST('/api/accounts/confirm', {
      token: token,
    })(),
}

export default REQUESTS
