/**
 * @generated SignedSource<<68555777e1ef35960692ad8e69492aeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimelineChart_event$data = {
  readonly announceDate: string | null;
  readonly closeEventDate: string | null;
  readonly date: string | null;
  readonly endDate: string | null;
  readonly flags: {
    readonly ticketTransfer: IBackendMap | null;
  } | null;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly timezoneName: string | null;
  readonly " $fragmentType": "TimelineChart_event";
};
export type TimelineChart_event$key = {
  readonly " $data"?: TimelineChart_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimelineChart_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimelineChart_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeEventDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketTransfer",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "395c0ce8aa47ece8454271a9552bb841";

export default node;
