import React, { FC, Fragment, memo, useMemo } from 'react'
import styled from 'styled-components/macro'
import cn from 'classnames'
import { flow, groupBy, keys, some, sortBy, sumBy } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { isPast, parseISO } from 'date-fns'

import { useFragment, useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { CURRENCY } from '../../../../utils/formatters/number'
import { breakpoints } from '../../../../utils/variables'

import { allowedEventAction } from '../../../EventForm/services/allowedEventAction'
import Badge from '../../../../components/Badge'
import DetailedPriceBreakdownTooltip from '../../../../components/Event/DetailedPriceBreakdownTooltip'
import IdTag from '../../../../components/IdTag'
import Svg from '../../../../components/Svg'
import {
  AllocationValue,
  CustomProgressBar,
  Progress,
  ProgressBarValues,
  Relative,
  RemainingValue,
  SoldValue,
  TicketType,
  TicketTypeCol,
  TicketTypeIcon,
  TicketTypeName,
  TicketTypeNameWithBadge,
  TicketTypePrice,
  TicketTypeRow,
  TicketTypes,
} from './TicketBreakdownStyles'
import { TitleTooltip } from '../../../../components/Tooltip'
import Value from '../../../../components/Value'

import ProductActions from './ProductActions'
import ProductVariantsBreakdown from './ProductVariantsBreakdown'

import { EXTRAS_TYPES_ORDER } from '../../../EventForm/steps/Extras'
import {
  ProductsBreakdown_event$data,
  ProductsBreakdown_event$key,
} from '../../../../__generated__/ProductsBreakdown_event.graphql'
import { ProductsBreakdownQuery } from '../../../../__generated__/ProductsBreakdownQuery.graphql'

const LinkedTicketsTooltip = styled.div`
  margin: -8px;
  min-width: 240px;
`

type IProductsBreakdown = NonNullable<NonNullable<ProductsBreakdown_event$data['productsSales']>['productBreakdown']>
type IProductBreakdown = NonNullable<IProductsBreakdown[number]>

interface IProps {
  eventId: string
  productType: 'EXTRAS' | 'MERCH'
  className?: string
}

const isOffSale = (products: IProductBreakdown) =>
  !!products.product.offSaleDate && isPast(parseISO(products.product.offSaleDate))
const isSold = (products: IProductBreakdown) =>
  (products.totalSold || 0) ===
  (products.product.hasVariants ? sumBy('allocation', products.product.variants) : products.product.allocation || 0)

const ProductsBreakdown: FC<React.PropsWithChildren<IProps>> = ({ eventId, productType, className }) => {
  const intl = useIntl()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { event: eventKey } = useLazyLoadQuery<ProductsBreakdownQuery>(
    graphql`
      query ProductsBreakdownQuery($eventId: ID!, $rootType: ProductRootType) {
        event: node(id: $eventId) {
          id
          ...ProductsBreakdown_event @arguments(rootType: $rootType)
        }
      }
    `,
    { eventId, rootType: productType },
    { fetchPolicy: 'store-and-network' }
  )

  const event = useFragment<ProductsBreakdown_event$key>(
    graphql`
      fragment ProductsBreakdown_event on Event @argumentDefinitions(rootType: { type: "ProductRootType" }) {
        id
        eventIdLive
        allowedActions {
          minorUpdate
        }
        allowedLifecycleUpdates {
          products {
            canAdd
            canUpdate
          }
        }
        costCurrency
        timezoneName
        date
        announceDate
        onSaleDate
        offSaleDate
        ticketTypes {
          id
          doorSalesEnabled
        }

        productsSales {
          productBreakdown(rootType: $rootType) {
            variantBreakdown {
              variantId
              totalDigitalValue
              totalFaceValue
              totalSold
              variant {
                id
                name
                allocation
                product {
                  id
                  name
                  allocation
                }
              }
            }
            product {
              id
              rootType
              category {
                parentCategory {
                  name
                  type
                }
              }
              name
              archived
              onSaleDate
              offSaleDate
              allocation
              faceValue
              price
              ticketTypes {
                id
                name
              }
              hasVariants
              optionType
              variants {
                id
                name
                allocation
              }
              priceBreakdown {
                total
                totalWithPwl
                totalWithoutPwl
                faceValue
              }
            }
            totalFaceValue
            totalDigitalValue
            totalSold
          }
        }
      }
    `,
    eventKey
  )

  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.desktop}px)` })

  const productsByCategories = useMemo(
    () =>
      flow(
        sortBy((item: IProductBreakdown) => {
          const idx = EXTRAS_TYPES_ORDER.indexOf(item?.product?.category?.parentCategory?.type || '')
          return idx >= 0 ? idx : Infinity
        }),
        groupBy((item: IProductBreakdown) => item?.product?.category?.parentCategory?.type || 'UNKNOWN')
      )(event?.productsSales?.productBreakdown || []),
    [event?.productsSales?.productBreakdown]
  )

  const hasBoxOfficeTickets = useMemo(
    () => some((tty) => tty && tty.doorSalesEnabled, event?.ticketTypes || []),
    [event?.ticketTypes]
  )

  if (!event) return null

  const isReadOnly =
    !event?.allowedActions?.minorUpdate || !allowedEventAction(event.allowedLifecycleUpdates, 'products', 'canUpdate')

  return (
    <>
      <TicketTypes className={className}>
        {keys(productsByCategories).map((category, idx) => (
          <Fragment key={category}>
            {productType !== 'MERCH' && (
              <strong className={cn('block color-darkgrey mb-md', { 'mt-lg': idx > 0 })}>
                {intl.formatMessage({ id: `new_event.extras.list.type.${category.toLowerCase()}` })}
              </strong>
            )}
            {productsByCategories[category].map((products) => (
              <TicketTypeRow
                key={products.product.id}
                data-id={products.product.id}
                isArchived={!!products.product.archived}
              >
                <TicketType>
                  <TicketTypeCol flexDirection="column" justify="center" order={0}>
                    <TicketTypeIcon>
                      <Svg icon={products.product.archived ? 'trash' : productType === 'MERCH' ? 'merch' : 'plus'} />
                    </TicketTypeIcon>
                    <TicketTypeNameWithBadge>
                      <TicketTypeName>
                        <strong>{products.product.name}</strong>
                      </TicketTypeName>
                      <IdTag
                        id={products.product.id}
                        successMessage={intl.formatMessage({ id: 'action.ticket_id_copied' })}
                      />
                      {(isOffSale(products) || isSold(products)) && (
                        <div>
                          <Badge>
                            {intl.formatMessage({
                              id: isSold(products) ? 'event_status.sold-out' : 'event_status.off-sale',
                            })}
                          </Badge>
                        </div>
                      )}
                    </TicketTypeNameWithBadge>
                    <TicketTypePrice>
                      <span>
                        {/* eslint-disable-next-line */}
                        {intl.formatNumber(
                          products.product.faceValue / 100,
                          CURRENCY(products.product.faceValue, event?.costCurrency)
                        )}{' '}
                        <DetailedPriceBreakdownTooltip
                          title={intl.formatMessage(
                            { id: 'price.fees_title' },
                            {
                              name:
                                products.product.name ||
                                intl.formatMessage({ id: 'new_event.basics.name.placeholder' }),
                            }
                          )}
                          currency={event?.costCurrency || null}
                          priceBreakdown={products.product.priceBreakdown || null}
                          placement="right"
                        />
                      </span>
                    </TicketTypePrice>
                  </TicketTypeCol>

                  <TicketTypeCol order={isTablet ? 3 : 1}>
                    <Progress
                      primary={
                        <Relative>
                          <CustomProgressBar
                            size="small"
                            gradient
                            color={isSold(products) ? 'secondary' : 'primary'}
                            value={
                              products.product.hasVariants
                                ? (100 * (products.totalSold || 0)) / sumBy('allocation', products.product.variants)
                                : (100 * (products.totalSold || 0)) / products.product.allocation
                            }
                          />
                        </Relative>
                      }
                      secondary={
                        <ProgressBarValues>
                          <>
                            <div>
                              <SoldValue>
                                {intl.formatNumber(products.totalSold || 0, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </SoldValue>
                              {' / '}
                              <AllocationValue>
                                {products.product.hasVariants
                                  ? intl.formatNumber(sumBy('allocation', products.product.variants), {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })
                                  : intl.formatNumber(products.product.allocation || 0, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                              </AllocationValue>
                            </div>
                            <RemainingValue>
                              <span>
                                {products.product.ticketTypes.length > 0 ? (
                                  <TitleTooltip
                                    overlayClassName={cn({ '-mobile': isMobile })}
                                    preset="underlined"
                                    title={
                                      <LinkedTicketsTooltip>
                                        <ul>
                                          {products.product.ticketTypes.map((t) => t && <li key={t.id}>{t.name}</li>)}
                                        </ul>
                                      </LinkedTicketsTooltip>
                                    }
                                    placement={'bottomLeft'}
                                    prefixCls="rc-tooltip"
                                  >
                                    {products.product.ticketTypes.length}
                                  </TitleTooltip>
                                ) : (
                                  '0'
                                )}{' '}
                                {intl.formatMessage({ id: 'event_overview.extras_breakdown.tickets_linked' })}
                              </span>
                            </RemainingValue>
                          </>
                        </ProgressBarValues>
                      }
                    />
                  </TicketTypeCol>

                  <TicketTypeCol shifted={hasBoxOfficeTickets} order={2} nonInteractive>
                    <Value
                      compact={isTablet}
                      label={intl.formatMessage({ id: 'event_overview.ticket_breakdown.total_face_value.label' })}
                      descriptionFontSize="base"
                      value={intl.formatNumber(
                        (products.totalDigitalValue || 0) / 100,
                        CURRENCY(products.totalDigitalValue, event?.costCurrency)
                      )}
                    />
                  </TicketTypeCol>

                  {!isReadOnly && <ProductActions event={event} products={products} />}
                </TicketType>
                <ProductVariantsBreakdown
                  event={event}
                  variantsBreakdown={products.variantBreakdown}
                  isReadOnly={isReadOnly}
                />
              </TicketTypeRow>
            ))}
          </Fragment>
        ))}
      </TicketTypes>
    </>
  )
}

export default memo(ProductsBreakdown)
