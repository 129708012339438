/**
 * @generated SignedSource<<6266a5fc260d5f17bd31c552acf38830>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useImpersonation_viewer$data = {
  readonly account: {
    readonly id: string;
  } | null;
  readonly impersonator: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "useImpersonation_viewer";
};
export type useImpersonation_viewer$key = {
  readonly " $data"?: useImpersonation_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useImpersonation_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useImpersonation_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "impersonator",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "2d2abd10ea9ef47f5cfa87036928fe7d";

export default node;
