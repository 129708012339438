/**
 * @generated SignedSource<<85fcb5e65dc6dccc7be2a2f7510199d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventAttractiveGenreSelectQuery$variables = Record<PropertyKey, never>;
export type EventAttractiveGenreSelectQuery$data = {
  readonly viewer: {
    readonly externalEntities: ReadonlyArray<{
      readonly isEntertainment: boolean;
      readonly label: string | null;
      readonly value: string | null;
    } | null> | null;
  } | null;
};
export type EventAttractiveGenreSelectQuery = {
  response: EventAttractiveGenreSelectQuery$data;
  variables: EventAttractiveGenreSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "entityType",
      "value": "event_genre"
    },
    {
      "kind": "Literal",
      "name": "integrationType",
      "value": "attractive"
    }
  ],
  "concreteType": "ExternalEntity",
  "kind": "LinkedField",
  "name": "externalEntities",
  "plural": true,
  "selections": [
    {
      "alias": "label",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEntertainment",
      "storageKey": null
    }
  ],
  "storageKey": "externalEntities(entityType:\"event_genre\",integrationType:\"attractive\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventAttractiveGenreSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventAttractiveGenreSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f75fdcc36ff3d5d360a05d45907da80c",
    "id": null,
    "metadata": {},
    "name": "EventAttractiveGenreSelectQuery",
    "operationKind": "query",
    "text": "query EventAttractiveGenreSelectQuery {\n  viewer {\n    externalEntities(entityType: \"event_genre\", integrationType: \"attractive\") {\n      label: name\n      value\n      isEntertainment\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef30b1637ade9274877d738ca8fb1785";

export default node;
