import { FormikContextType } from 'formik'
import React, { useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { localeContext } from '../context/locale'

import { color, mediaQuery, zIndex } from '../utils/variables'
import Button from './Button'
import PageTransitionBlocker from './PageTransitionBlocker'
import Svg from './Svg'
import { TitleTooltip } from './Tooltip'

export const Dirty = styled.div`
  position: relative;
  display: inline-flex;
  color: ${color.darkgrey};
  margin-left: 16px;
  align-items: center;

  min-height: 8px;

  & > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
    }
  }

  ${mediaQuery.lessThan('tablet')`
    margin-left: 0;

    & > div {
      margin-top: 8px;
      padding: 18px 0;
    }
  `}
`

const WizardFormControls = styled.div<{ ICEenabled?: boolean; needGap?: boolean }>`
  display: block;
  position: sticky;
  bottom: ${({ ICEenabled }) => (ICEenabled ? '52px' : 0)};
  background: #fff;
  border-top: 2px solid ${color.text};
  z-index: ${zIndex.dropdown + 1};
  justify-content: space-between;

  ${mediaQuery.lessThan<{ needGap?: boolean }>('tablet')`
    padding-bottom: ${({ needGap }) => (needGap ? 48 : 0)}px;
  `}
`

const WizardFormControlsInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 16px 32px;

  .button + .button {
    margin-left: 16px;
  }

  ${mediaQuery.lessThan('tablet')`
    padding: 16px;
    flex-direction: column;
    button {
      display: block;
      width: 100%;
    }
  `}
`

const HoverableButton = styled(Button)`
  &.button.-disabled {
    pointer-events: all;

    &:hover {
      background-color: ${color.text};
      border-color: ${color.text};
      color: ${color.greyer};
    }
  }
`

const CancelButton = styled(Button)`
  &.button {
    margin-left: auto;
  }
`

interface IEntity {
  id?: string | null
}

interface IProps<T> {
  formik: FormikContextType<T>
  checkDirty?: (initial: T | null, current: T | null) => boolean
  onBack?: () => void
  cta?: string
  needGap?: boolean
}

const WizardFormFooter = <T extends IEntity>({ cta, checkDirty, formik, onBack, needGap }: IProps<T>) => {
  const intl = useIntl()
  const { phraseEnabled } = useContext(localeContext)

  const { isValid, initialStatus, isSubmitting, values, resetForm, initialValues, dirty } = formik

  const doCancel = useCallback(() => {
    if (values.id) {
      resetForm({ values: initialValues })
    } else if (onBack) {
      onBack()
    }
  }, [initialValues, onBack, resetForm, values.id])

  const isChanged = useMemo(
    () => (checkDirty ? checkDirty(initialValues, values) : dirty),
    [checkDirty, dirty, initialValues, values]
  )

  const ctaEnabled = isValid && (initialStatus === 'ok' || isChanged)

  return (
    <>
      <PageTransitionBlocker condition={isChanged && !isSubmitting} />
      <WizardFormControls ICEenabled={phraseEnabled} needGap={needGap ?? !!values.id}>
        <WizardFormControlsInner>
          <TitleTooltip title={!isValid && isChanged ? intl.formatMessage({ id: 'invalid_form' }) : undefined}>
            <HoverableButton loading={isSubmitting} type="submit" disabled={!ctaEnabled} data-id="saveButton">
              {cta || intl.formatMessage({ id: 'save_changes' })}
            </HoverableButton>
          </TitleTooltip>
          <Dirty>
            {isChanged && !isSubmitting && (
              <div>
                <Svg icon="circle" width={8} height={8} />
                {intl.formatMessage({ id: 'unsaved_changes' })}
              </div>
            )}
          </Dirty>
          <CancelButton
            disabled={(!isChanged && !!values.id) || isSubmitting}
            preset="secondary"
            data-id="cancel"
            onClick={doCancel}
          >
            {intl.formatMessage({ id: 'actions.cancel' })}
          </CancelButton>
        </WizardFormControlsInner>
      </WizardFormControls>
    </>
  )
}

export default WizardFormFooter
