import React, { FC, memo, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { find } from 'lodash/fp'
import { addDays, isSameHour } from 'date-fns'

import { color, zIndex } from '../../../utils/variables'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { textStyle } from '../../../utils/typography'
import { CURRENCY } from '../../../utils/formatters/number'
import { EventCostCurrency } from '../../../enums.generated'
import { IAggregatedData } from '../hooks/useAggregatedData'

const Tooltip = styled.div<{
  hidden?: boolean
  x: number
  y: number
  height: number
  mirrorX?: boolean
  mirrorY?: boolean
}>`
  width: 280px;
  padding: 16px 16px 8px 16px;
  z-index: ${zIndex.tooltip};
  border-radius: 8px;
  background-color: ${color.white};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  transition: top 0.2s, bottom 0.2s, left 0.2s, right 0.2s;
  position: absolute;
  ${({ x, mirrorX }) => (mirrorX ? `right: calc(100% - ${x - 36}px);` : `left: ${x + 16}px;`)}
  ${({ y, mirrorY, height }) => (mirrorY ? `bottom: calc(100% - ${y + height / 2}px);` : `top: ${y + height / 2}px;`)}
`

const TooltipHeader = styled.div`
  text-transform: uppercase;
  text-align: center;
  ${textStyle.label.regular}

  margin-top: -4px;
  padding: 0 0 8px 0;
  border-bottom: 1px solid ${color.lightgrey};
`

const STable = styled.table`
  width: 100%;

  td {
    padding: 8px 0;
  }

  tr + tr td {
    border-top: 1px solid ${color.lightgrey};
  }

  td:last-child {
    font-weight: bold;
    text-align: right;

    strong {
      color: ${color.darkgrey};
      margin-left: 8px;
    }
  }
`

export interface ITooltipModel {
  t: Date
  caretX: number
  caretY: number
  xAlign: string
  yAlign: string
}

interface IProps {
  tooltipModel: ITooltipModel
  costCurrency: EventCostCurrency | null
  data: IAggregatedData

  byWeeks: boolean
  showExtras: boolean
  showMerch: boolean
  showViews: boolean
  showRevenue: boolean
}

const SalesChartTooltip: FC<IProps> = ({
  costCurrency,
  data,
  tooltipModel,
  byWeeks,
  showExtras,
  showMerch,
  showViews,
  showRevenue,
}) => {
  const intl = useIntl()

  const formattedTooltipDate = useMemo(() => {
    if (!tooltipModel.t) return null

    if (!byWeeks) return intl.formatDate(tooltipModel.t, { ...DATETIME_FORMATS.MEDIUM })

    return (
      <>
        {intl.formatDate(tooltipModel.t, { ...DATETIME_FORMATS.SHORT })}
        &nbsp;&mdash;&nbsp;
        {intl.formatDate(addDays(tooltipModel.t, 6), { ...DATETIME_FORMATS.SHORT })}
      </>
    )
  }, [byWeeks, intl, tooltipModel.t])

  const item = useMemo(() => {
    if (!tooltipModel.t) return null

    const views = find((p) => isSameHour(p.t, tooltipModel.t), data.views.views)?.y || 0

    const ticketsSold = find((p) => isSameHour(p.t, tooltipModel.t), data.tickets.sold)?.y || 0
    const ticketsFV = find((p) => isSameHour(p.t, tooltipModel.t), data.tickets.faceValue)?.y || 0

    const extrasSold = find((p) => isSameHour(p.t, tooltipModel.t), data.extras.sold)?.y || 0
    const extrasFV = find((p) => isSameHour(p.t, tooltipModel.t), data.extras.faceValue)?.y || 0

    const merchSold = find((p) => isSameHour(p.t, tooltipModel.t), data.merch.sold)?.y || 0
    const merchFV = find((p) => isSameHour(p.t, tooltipModel.t), data.merch.faceValue)?.y || 0

    return {
      views,
      ticketsSold,
      ticketsFV,
      extrasSold,
      extrasFV,
      merchSold,
      merchFV,
    }
  }, [data.extras, data.merch, data.tickets, data.views, tooltipModel.t])

  return (
    <Tooltip
      x={tooltipModel.caretX}
      y={tooltipModel.caretY}
      height={300}
      mirrorX={tooltipModel.xAlign === 'right'}
      mirrorY={tooltipModel.yAlign === 'top'}
    >
      <TooltipHeader>{formattedTooltipDate}</TooltipHeader>
      <STable>
        <tbody>
          <tr>
            <td>{intl.formatMessage({ id: 'event_finances.table.th.tickets_sold' })}</td>
            <td>
              {intl.formatNumber(item?.ticketsSold || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              {showRevenue && (
                <strong>
                  {intl.formatNumber((item?.ticketsFV || 0) / 100, CURRENCY(item?.ticketsFV || 0, costCurrency))}
                </strong>
              )}
            </td>
          </tr>

          {showExtras && (
            <tr>
              <td>{intl.formatMessage({ id: 'event_finances.table.th.extras_sold' })}</td>
              <td>
                {intl.formatNumber(item?.extrasSold || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                {showRevenue && (
                  <strong>
                    {intl.formatNumber((item?.extrasFV || 0) / 100, CURRENCY(item?.extrasFV || 0, costCurrency))}
                  </strong>
                )}
              </td>
            </tr>
          )}

          {showMerch && (
            <tr>
              <td>{intl.formatMessage({ id: 'event_finances.table.th.merch_sold' })}</td>
              <td>
                {intl.formatNumber(item?.merchSold || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                {showRevenue && (
                  <strong>
                    {intl.formatNumber((item?.merchFV || 0) / 100, CURRENCY(item?.merchFV || 0, costCurrency))}
                  </strong>
                )}
              </td>
            </tr>
          )}

          {showViews && (
            <tr>
              <td>{intl.formatMessage({ id: 'event_views' })}</td>
              <td>{intl.formatNumber(item?.views || 0, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
            </tr>
          )}
        </tbody>
      </STable>
    </Tooltip>
  )
}

export const TooltipContainer = styled.div`
  position: relative;
  width: 100%;
`

export default memo(SalesChartTooltip)
