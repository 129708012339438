import React, { memo, FC, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { differenceInMinutes, startOfDay } from 'date-fns'
import MobileDatePicker from 'react-mobile-datepicker'
import { createGlobalStyle } from 'styled-components/macro'
import { color, font } from '../utils/variables'

interface IProps {
  onChange?: (minutes: null | number) => void
}

const MobileDurationPicker: FC<React.PropsWithChildren<IProps>> = ({ onChange }) => {
  const intl = useIntl()

  const dateConfig = useMemo(
    () => ({
      hour: {
        format: 'hh',
        step: 1,
      },
      minute: {
        format: 'mm',
        step: 5,
      },
    }),
    []
  )

  const onSelect = useCallback(
    (d: Date) => {
      const duration = differenceInMinutes(d, startOfDay(d))
      if (onChange) onChange(duration)
    },
    [onChange]
  )

  const onClose = useCallback(() => {
    if (onChange) onChange(null)
  }, [onChange])

  const zero = useMemo(() => startOfDay(new Date()), [])

  return (
    <MobileDatePicker
      isOpen
      theme="ios"
      value={zero}
      onSelect={onSelect}
      onCancel={onClose}
      dateConfig={dateConfig}
      showHeader
      showFooter
      confirmText={intl.formatMessage({ id: 'actions.done' })}
      cancelText={intl.formatMessage({ id: 'actions.cancel' })}
    />
  )
}

export default memo(MobileDurationPicker)

export const MobileDurationPickerStyles = createGlobalStyle`
  .Modal-Portal {
    .datepicker-modal {
      position: fixed;

      .datepicker {
        font-family: ${font.family.base};
        background-color: ${color.white};

        .datepicker-header {
          opacity: 0;
          min-height: unset;
          line-height: 125%;
          font-size: ${font.size.sm}px;
          padding: 0;
          height: 40px;
        }

        .datepicker-content {
          padding: 0;
        }

        .datepicker-viewport::after {
          background: linear-gradient(
            ${color.white}, rgba(255, 255, 255, 0) 52%, rgba(255, 255, 255, 0) 48%, ${color.white}
          );
        }

        .datepicker-wheel {
          border-color: ${color.lightgrey};
        }

        .datepicker-scroll {
          li:nth-child(6) {
            font-weight: bold;
          }
        }

        .datepicker-navbar {
          padding: 11px 16px 8px 16px;
          border-bottom: 1px solid ${color.lightgrey};

          .datepicker-navbar-btn {
            color: ${color.text};
            line-height: 125%;
            height: unset;
          }

          .datepicker-navbar-btn:first-child {
            color: ${color.primary};
            font-weight: bold;
            font-size: ${font.size.sm}px;
            letter-spacing: 0.01em;
          }
        }
      }
    }
  }
`
