import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useLocation } from 'react-router'
import Svg from '../../../../components/Svg'
import { color, font, mediaQuery, transition, zIndex } from '../../../../utils/variables'
import Logo from '../Logo'
import IconButton from '../../../../components/IconButton'

const doReload = () => {
  window.location.reload()
}

interface ISidebarWrapperProps {
  expandOnMobile?: boolean
  ICEenabled?: boolean
  isImpersonated?: boolean
  v2?: boolean
}

export const SidebarWrapper = styled.div<ISidebarWrapperProps>`
  @media print {
    display: none;
  }

  display: flex;
  width: 242px;
  flex: 242px 0 0;
  height: 100%;
  position: fixed;
  top: 0;
  padding: ${({ v2 }) => (v2 ? '0' : '24px 16px')};
  flex-direction: column;
  border-right: 2px solid ${color.text};
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  transition-delay: ${transition.sidebar.transitionDelay};
  transition: ${transition.sidebar.transition('width')};
  z-index: ${zIndex.sidebar};

  /* Impersonation banner */
  ${({ isImpersonated }) =>
    isImpersonated &&
    `
    padding-top: 80px;
  `}

  /* Phrase In-Context Editor */
  ${({ ICEenabled }) =>
    ICEenabled &&
    `
    padding-bottom: 72px;
  `}

  body.compact-sidebar &:not(.expanded) {
    ${mediaQuery.greaterThan('tablet')`
      width: 74px;
      flex: 74px 0 0;
    `}
  }
  ${mediaQuery.between('769px', '1200px')`
    &:not(.expanded) {
      width: 74px;
      flex: 74px 0 0;
    }
  `}
  ${mediaQuery.lessThan<ISidebarWrapperProps>('tablet')`
    position: fixed;
    right: ${({ expandOnMobile }) => (expandOnMobile ? 'auto' : '100%')};
    left: ${({ expandOnMobile }) => (expandOnMobile ? '0' : 'auto')};
    width: 242px;
    flex: 242px 0 0;
    justify-content: flex-start;

    ${({ expandOnMobile }) =>
    expandOnMobile &&
      `
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
      }
    `}
  `}
`

export const SidebarScrollable = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 16px 16px;
  scrollbar-color: ${color.lightgrey} ${color.white};

  ${mediaQuery.lessThan('tablet')`
    padding-top: 44px;
  `}
`

export const SidebarBackDrop = styled.div<{ expandOnMobile?: boolean }>`
  display: ${({ expandOnMobile }) => (expandOnMobile ? 'block' : 'none')};
  position: fixed;
  left: 242px;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 2030;
`

export const MobileControls = styled.div`
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 16px;
  z-index: 2030;

  body.black-sidebar & {
    background-color: ${color.text};
    color: ${color.white};
  }

  ${Logo} {
    position: absolute;
    left: 16px;
    top: 10px;
    svg {
      flex: 56px 0 0;
      margin-left: 0;
    }
  }

  ${mediaQuery.lessThan('tablet')`
    display: block;
  `}
`

export const SettingsMobileControls = styled(MobileControls)`
  height: 58px;
  background-color: ${color.white};
`

export const SidebarToogler = styled(IconButton)`
  position: absolute;
  right: 16px;
  top: 8px;

  body.black-sidebar &.icon-button:hover,
  body.black-sidebar &.icon-button:active,
  body.black-sidebar &.icon-button:focus {
    background-color: ${color.darkgrey};
  }
`

interface ISidebarMenuItemProps {
  dice?: boolean
  active?: boolean
  disabled?: boolean
  to?: string
  onReload?: () => void
}

export const SidebarMenu = styled.nav`
  list-style: none;
  margin: 0 -16px;
  padding: 0;
`

export const SidebarMenuIcon = styled(Svg)`
  flex: 24px 0 0;
  margin-right: 8px;
`

export const SidebarMenuItem = styled(({ active, disabled, to, onReload, shouldReload, ...rest }) => {
  const { pathname } = useLocation()
  return shouldReload ?? pathname === to ? <div {...rest} onClick={onReload || doReload} /> : <Link to={to} {...rest} />
})<ISidebarMenuItemProps>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 6px 24px;
  margin: 4px 0;
  cursor: pointer;
  user-select: none;

  ${({ dice }) => dice && `color: ${color.dice};`}

  * {
    z-index: 1;
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.8, 0, 0.3, 1);
    opacity: 1;
  }
  .sidebar.expanded & {
    > span {
      overflow: visible;
    }
  }

  &:hover {
    color: ${({ dice }) => (dice ? color.dice : 'inherit')};
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      left: 16px;
      right: 16px;
      background: ${color.palegrey};
      border-radius: 4px;
    }
  }
  ${({ active }) =>
    active &&
    `
    color: ${color.primary};
    &:hover {
      color: ${color.primary};
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      height: 24px;
      width: 2px;
      background: currentColor;
    }
  `};
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  `};
  body.compact-sidebar .sidebar:not(.expanded) & {
    ${mediaQuery.greaterThan('tablet')`
      > span {
        opacity: 0;
      }
    `}
  }
  ${mediaQuery.lessThan('tablet')`
    > span {
      opacity: 1;
    }
    text-indent: 0;
  `}
`

export const CollapsibleSidebarMenu = styled(SidebarMenu)<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 2px;

  ${SidebarMenuItem} {
    margin: 0;
    border-radius: 8px;
  }

  ${({ collapsed }) => collapsed && ' display: none;'}
`

export const SidebarList = styled.div`
  margin: 0 0 22px;
`

export const SidebarListName = styled.span`
  position: relative;
  margin: 0 0 24px;
  > span {
    font-size: ${font.size.xs}px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${color.darkgrey};
    transition: opacity 0.2s cubic-bezier(0.8, 0, 0.3, 1);
    transition-delay: 0.15s;
    text-indent: 8px;
    white-space: nowrap;
    overflow: hidden;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    font-size: 0;
    background: ${color.lightgrey};
    height: 2px;
    width: 42px;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.8, 0, 0.3, 1);
    transition-delay: 0.15s;
  }
  body.compact-sidebar .sidebar:not(.expanded) & {
    ${mediaQuery.greaterThan('tablet')`
      > span {
        opacity: 0;
      }
      &:before {
        opacity: 1;
      }
    `}
  }
  ${mediaQuery.between('769px', '1200px')`
    .sidebar:not(.expanded) & {
      > span {
        opacity: 0;
      }
      &:before {
        opacity: 1;
      }
    }
  `}
`

export const SidebarListNameToggle = styled(SidebarListName)<{ collapsed: boolean; isDragging?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  min-width: 100px;
  cursor: pointer;
  user-select: none;
  background: ${({ isDragging }) => (isDragging ? color.palegrey : color.white)};

  & > span {
    text-indent: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow-indicator {
    transition: opacity 0.2s cubic-bezier(0.8, 0, 0.3, 1);
    opacity: 1;
    ${({ collapsed }) => collapsed && 'transform: rotate(-90deg)'};
  }

  ${mediaQuery.between('769px', '1200px')`
    .sidebar:not(.expanded) & {
        ${Svg} {
          opacity: 0;
        }
      }
  `}

  &:before {
    top: 20px;
  }

  &:hover {
    background: ${color.palegrey};
  }
`
