import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { addHours, formatISO, isPast, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { compose, get, map, reject } from 'lodash/fp'

import { useAutoPinnedEventQuery } from '../../../__generated__/useAutoPinnedEventQuery.graphql'
import { getPinnedEventId, isUnpinned, loadUnpinned, pinEvent, saveUnpinned, unpinEvent } from '../../../utils/pinning'
import { notificationContext } from '../../../context/notification'
import { authContext } from '../../../context/auth'

function useAutoPinnedEvent(enableAutoPin: boolean): [string | null, () => void] {
  const intl = useIntl()
  const { account } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)

  const vars = useMemo(() => {
    const now = new Date()
    const then = addHours(now, 48)
    const unpinnedIds = [...loadUnpinned(account?.id)]
    const pinnedId = getPinnedEventId(account?.id)

    return { d1: formatISO(now), d2: formatISO(then), unpinnedIds, id: pinnedId || 'oops', hasPin: !!pinnedId }
  }, [account?.id])

  const { viewer, currentPin } = useLazyLoadQuery<useAutoPinnedEventQuery>(
    graphql`
      query useAutoPinnedEventQuery($d1: Time!, $d2: Time!, $unpinnedIds: [ID!], $id: ID!, $hasPin: Boolean!) {
        currentPin: node(id: $id) @include(if: $hasPin) {
          ... on Event {
            id
            endDate
          }
        }

        viewer {
          pinCandidates: events(
            first: 20
            orderBy: [dateASC]
            scopes: { eventState: [ON_SALE], lifeCycleState: [LIVE] }
            where: { date: { between: [$d1, $d2] } }
          ) {
            edges {
              node {
                id
                name
              }
            }
          }

          werePinned: events(first: 100, orderBy: [dateASC], where: { id: { in: $unpinnedIds } }) {
            edges {
              node {
                id
                endDate
              }
            }
          }
        }
      }
    `,
    vars,
    { fetchPolicy: enableAutoPin ? 'network-only' : 'store-only' }
  )

  useEffect(() => {
    if (!enableAutoPin) return

    // Cleanup pinned and unpinned events if needed

    if (vars.hasPin) {
      const needUnpin = !currentPin || (currentPin.endDate && isPast(parseISO(currentPin.endDate)))
      if (needUnpin) {
        unpinEvent(account?.id)
      }
    }

    const previouslyPinned = map('node', viewer?.werePinned?.edges || [])

    const remainingIds = reject((e) => e.endDate && isPast(parseISO(e.endDate)), previouslyPinned)

    saveUnpinned(account?.id, new Set(map('id', remainingIds)))
  }, [account?.id, currentPin, enableAutoPin, vars.hasPin, viewer?.werePinned?.edges])

  const [pinnedEventId, setPinnedEventId] = useState<string | null>(() => getPinnedEventId(account?.id))

  useEffect(() => {
    if (!enableAutoPin || !!pinnedEventId) return

    // Auto pin first non-unpinned event candidate

    const pinnableEvent = compose([get([0]), reject((e: any) => isUnpinned(account?.id, e.id)), map('node')])(
      viewer?.pinCandidates?.edges || []
    )

    if (pinnableEvent) {
      pinEvent(account?.id, pinnableEvent.id)

      setPinnedEventId(pinnableEvent.id)

      addNotification(
        'success',
        intl.formatMessage({ id: 'notification.event_auto_pinned' }, { name: pinnableEvent.name })
      )
    }
  }, [account?.id, addNotification, enableAutoPin, intl, pinnedEventId, viewer?.pinCandidates?.edges])

  const unpin = useCallback(() => {
    if (!pinnedEventId) return
    unpinEvent(account?.id)

    setPinnedEventId(null)

    addNotification('success', intl.formatMessage({ id: 'notification.event_unpinned' }))
  }, [account?.id, addNotification, intl, pinnedEventId])

  return [pinnedEventId, unpin]
}

export default useAutoPinnedEvent
