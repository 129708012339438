/**
 * @generated SignedSource<<72328b0c21b815641976581541d734cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventReviewStatus = "ESCALATED" | "ON_HOLD" | "%future added value";
export type EventWorkflowEventReviewQuery$variables = {
  id: string;
};
export type EventWorkflowEventReviewQuery$data = {
  readonly viewer: {
    readonly eventReviews: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly event: {
            readonly id: string;
          } | null;
          readonly id: string;
          readonly priority: boolean | null;
          readonly status: EventReviewStatus | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type EventWorkflowEventReviewQuery = {
  response: EventWorkflowEventReviewQuery$data;
  variables: EventWorkflowEventReviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "fields": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "eq",
                  "variableName": "id"
                }
              ],
              "kind": "ObjectValue",
              "name": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "event"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "EventReviewConnection",
  "kind": "LinkedField",
  "name": "eventReviews",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventReviewEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventReview",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priority",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Event",
              "kind": "LinkedField",
              "name": "event",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventWorkflowEventReviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventWorkflowEventReviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50f56db418de109fa68acf967162124c",
    "id": null,
    "metadata": {},
    "name": "EventWorkflowEventReviewQuery",
    "operationKind": "query",
    "text": "query EventWorkflowEventReviewQuery(\n  $id: ID!\n) {\n  viewer {\n    eventReviews(first: 1, where: {event: {id: {eq: $id}}}) {\n      edges {\n        node {\n          id\n          priority\n          status\n          event {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1aedcc56413e3abbfc551c50a7f4c496";

export default node;
