import graphql from 'babel-plugin-relay/macro'
import { useCallback, useEffect } from 'react'
import { commitLocalUpdate, useLazyLoadQuery, useRelayEnvironment } from 'react-relay'
import { useLocation } from 'react-router'
import { useEventListTypeQuery } from '../../../__generated__/useEventListTypeQuery.graphql'
import { SIDEBAR_ROUTER } from '../services/sidebarNav'

const useEventListType = () => {
  const { pathname } = useLocation()
  const environment = useRelayEnvironment()

  const data = useLazyLoadQuery<useEventListTypeQuery>(
    graphql`
      query useEventListTypeQuery {
        viewer {
          __typename
          eventListType
        }
      }
    `,
    {}
  )

  const setEventListType = useCallback(
    (value: string | null) => {
      commitLocalUpdate(environment, (store) => {
        const viewer = store.getRoot().getLinkedRecord('viewer')
        viewer?.setValue(value, 'eventListType')
      })
    },
    [environment]
  )

  useEffect(() => {
    if (!pathname.startsWith('/events')) {
      return setEventListType(null)
    }

    switch (pathname) {
      case SIDEBAR_ROUTER.events.live[0]:
        setEventListType('live')
        break
      case SIDEBAR_ROUTER.events.drafts[0]:
        setEventListType('draft')
        break
      case SIDEBAR_ROUTER.events.past[0]:
        setEventListType('past')
        break
      case SIDEBAR_ROUTER.events.cancelled[0]:
        setEventListType('cancelled')
        break
      case SIDEBAR_ROUTER.events.submissions[0]:
        setEventListType('submission')
        break
      case SIDEBAR_ROUTER.events.payouts[0]:
        setEventListType('payouts')
        break
    }
  }, [pathname, setEventListType])

  return data.viewer?.eventListType
}

export default useEventListType
