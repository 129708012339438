/**
 * @generated SignedSource<<127c4abede32bd7df347f73ffa86bee0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventType = "HYBRID" | "LIVE" | "STREAM" | "%future added value";
export type FeesBehaviour = "APPEND_TO_CONTRACT" | "OVERRIDE" | "USE_CONTRACT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventControls_event$data = {
  readonly allowedActions: {
    readonly performPayout: boolean | null;
  } | null;
  readonly announceDate: string | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly date: string | null;
  readonly detectBalance?: string;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly eventType: EventType | null;
  readonly feesBehaviour: FeesBehaviour | null;
  readonly id: string;
  readonly name: string | null;
  readonly onSaleDate: string | null;
  readonly organicSocialLink?: string | null;
  readonly previewToken: string | null;
  readonly state: EventState | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartPayout_event" | "useEventPayout_event">;
  readonly " $fragmentType": "EventControls_event";
};
export type EventControls_event$key = {
  readonly " $data"?: EventControls_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventControls_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDraft"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "needBalance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventControls_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onSaleDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "condition": "isDraft",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organicSocialLink",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "performPayout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feesBehaviour",
      "storageKey": null
    },
    {
      "condition": "needBalance",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EventPartPayout_event"
        },
        {
          "alias": "detectBalance",
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useEventPayout_event"
        }
      ]
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "45e8b15c08295624035c2b562c3a4fbc";

export default node;
