import React, { FC, useState, useContext, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { nanoid } from 'nanoid'

import { commitMutation, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { notificationContext } from '../../../../context/notification'
import { color } from '../../../../utils/variables'

import Checkbox from '../../../../components/Checkbox'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'
import { Paragraph } from '../../../../components/Text'

const AgreementCheckbox = styled(Checkbox)`
  margin-top: 24px;
`

const TermsLink = styled.a`
  font-weight: bold;
  &:hover {
    color: ${color.primary};
  }
`

interface IProps {
  tierId: string
  onClose: () => void
}

export const CloseTierModal: FC<React.PropsWithChildren<IProps>> = ({ tierId, onClose }) => {
  const intl = useIntl()
  const [userAgreed, setUserAgreed] = useState(false)
  const { addNotification } = useContext(notificationContext)
  const environment = useRelayEnvironment()

  const handleSubmit = useCallback(() => {
    if (!userAgreed || !tierId) return
    return commitMutation(environment, {
      mutation: graphql`
        mutation CloseTierModalMutation($input: MarkBackAllocationsInput!) {
          markBackAllocations(input: $input) {
            event {
              id
              sales {
                ticketTypesBreakdown {
                  ticketType {
                    id
                    name
                    allocation
                  }
                  totalAppSold
                  totalPosSold
                  totalReserved
                  priceTiersBreakdown {
                    appSold
                    posSold
                    reserved
                    priceTier {
                      id
                      name
                      allocation
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          clientMutationId: nanoid(),
          id: tierId,
        },
      },
      onCompleted: () => {
        addNotification('success', intl.formatMessage({ id: 'close_tier_modal.success_message' }))
        onClose()
      },
      onError: () => {
        addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
        onClose()
      },
    })
  }, [addNotification, environment, intl, onClose, tierId, userAgreed])

  const toggleAgree = useCallback(() => setUserAgreed((ua) => !ua), [setUserAgreed])

  return (
    <Modal modalTitle={intl.formatMessage({ id: 'close_tier_modal.title' })} closeButton onClose={onClose}>
      <ModalBody>
        <Paragraph>{intl.formatMessage({ id: 'close_tier_modal.description' })}</Paragraph>
        <br />
        <AgreementCheckbox
          name="userAgreed"
          label={
            <>
              {intl.formatMessage({ id: 'i_accept_the' })}{' '}
              <TermsLink href="https://dice.fm/terms_and_conditions.html" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'terms_and_conditions' })}
              </TermsLink>
            </>
          }
          checked={userAgreed}
          onChange={toggleAgree}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl disabled={!userAgreed} onClick={handleSubmit}>
          {intl.formatMessage({ id: 'actions.confirm' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

CloseTierModal.defaultProps = {
  onClose: () => {},
}

export default CloseTierModal
