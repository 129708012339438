import React, { memo, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Crop } from 'react-image-crop'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components/macro'

import { breakpoints } from '../../../../utils/variables'
import { markAsClientOnly } from '../../../../utils/entityStatus'

import Button from '../../../../components/Button'
import Cropper from '../../../../components/Cropper'
import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../components/Dropdown'
import IconButton from '../../../../components/IconButton'
import { Loader } from '../../../../components/Loader'
import { Menu, MenuItem } from '../../../../components/Menu'
import Svg from '../../../../components/Svg'

import { ICropRegion, IImage, getImageFormatFromUrl, loadImage } from './ProductImages'
import ProductImageStaticCover from './ProductImageStaticCover'
import {
  ProductImage,
  ProductImageControls,
  RemoveButton,
  ProductImagesListWrapper,
  CoverBadge,
  ProductImageWrapper,
  SmallDropzone,
  DropzoneIcon,
  DropzoneDescription,
  DropzoneContent,
  ProductImagePreview,
} from './ProductImagesStyles'

import { CropRegionInput } from '../../../../__generated__/validateDraftMutation.graphql'

const Separator = styled.div`
  margin: -6px 0;
`

const MAX_IMAGES_NUM = 8

const toPercentCrop = (crop: CropRegionInput, img: CropRegionInput): Crop => ({
  unit: '%',
  aspect: crop.width && crop.height ? crop.width / crop.height : 1,
  x: ((crop.x || 0) / (img.width || 0)) * 100,
  y: ((crop.y || 0) / (img.height || 0)) * 100,
  width: ((crop.width || 0) / (img.width || 0)) * 100,
  height: ((crop.height || 0) / (img.height || 0)) * 100,
})

const toAbsoluteCrop = (crop: CropRegionInput, img: CropRegionInput): Crop => ({
  unit: 'px',
  aspect: crop.width && crop.height ? crop.width / crop.height : 1,
  x: Math.round(((crop.x || 0) * (img.width || 0)) / 100),
  y: Math.round(((crop.y || 0) * (img.height || 0)) / 100),
  width: Math.round(((crop.width || 0) * (img.width || 0)) / 100),
  height: Math.round(((crop.height || 0) * (img.height || 0)) / 100),
})

interface ISortableProductImageItem {
  idx: number
  preview: IImage
  allowEdit?: boolean
  onRemove?: (idx: number) => void
  onCrop?: any
}

interface ISortableProductImagesList {
  productImages: IImage[]
  staticCover?: { imgUrl: string; bgColor: string; hint?: string } | null
  allowEdit?: boolean
  hasError?: boolean
  onDrop: (v: File[], r: FileRejection[]) => void
  onRemove: (idx: number) => void
  onCrop: any
}

const SortableProductImagePreview = SortableHandle<{ imageUrl: string; disabled?: boolean }>(
  memo(({ imageUrl, disabled }: { imageUrl: string; disabled?: boolean }) => (
    <ProductImagePreview disabled={disabled}>
      <img src={imageUrl} alt="preview" />
    </ProductImagePreview>
  ))
)

const SortableProductImage = SortableElement<ISortableProductImageItem>(
  memo(({ idx, preview, allowEdit, onRemove, onCrop }: ISortableProductImageItem) => {
    const intl = useIntl()

    const [cropping, setCropping] = useState(false)
    const [loading, setLoading] = useState(false)
    const [originalImage, setOriginalImage] = useState<IImage | null>(null)

    const [dropdownActive, setDropdownActive] = useState(false)
    const clickOutside = useCallback(() => dropdownActive && setDropdownActive(false), [dropdownActive])
    const toggleDropdown = useCallback(() => setDropdownActive((v) => !v), [])

    const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

    const startCropping = useCallback(async () => {
      setLoading(true)
      const img = await loadImage(preview.cdnUrl)
      const cropRegion: ICropRegion = {
        x: 0,
        y: 0,
        width: img.width,
        height: img.height,
      }
      const originalImage: IImage = markAsClientOnly<IImage>({
        cdnUrl: preview.cdnUrl,
        croppedUrl: preview.cdnUrl,
        format: getImageFormatFromUrl(preview.cdnUrl),
        attachment: null,
        cropRegion,
      })
      setOriginalImage(originalImage)
      setLoading(false)
      setDropdownActive(false)
      setCropping(true)
    }, [preview.cdnUrl])

    const doCrop = useCallback(
      (crop: any) => {
        const absoluteCrop = crop && originalImage?.cropRegion && toAbsoluteCrop(crop, originalImage?.cropRegion)
        if (onCrop && absoluteCrop) onCrop(preview.id, absoluteCrop)
        setCropping(false)
        setOriginalImage(null)
      },
      [originalImage?.cropRegion, onCrop, preview.id]
    )

    const percentCrop = useMemo(
      () =>
        originalImage?.cropRegion && preview.cropRegion
          ? toPercentCrop(preview.cropRegion, originalImage.cropRegion)
          : null,
      [originalImage?.cropRegion, preview.cropRegion]
    )

    const removeItem = useCallback(() => {
      if (onRemove) return onRemove(idx)
    }, [onRemove, idx])

    return (
      <ProductImage disabled={!allowEdit}>
        <SortableProductImagePreview imageUrl={preview.croppedUrl || preview.cdnUrl} disabled={!allowEdit} />
        {allowEdit && (
          <ProductImageControls>
            {!isMobile ? (
              <>
                <RemoveButton onClick={removeItem}>
                  <Svg icon="trash" />
                </RemoveButton>
                <Button icon="crop" size="small" loading={loading} onClick={startCropping}>
                  {intl.formatMessage({ id: 'actions.crop' })}
                </Button>
              </>
            ) : (
              <Dropdown data-id="azazaza" active={dropdownActive} onClickOutside={clickOutside}>
                <DropdownTrigger as="span" onClick={toggleDropdown}>
                  <IconButton icon="more" />
                </DropdownTrigger>
                <DropdownContent active={dropdownActive}>
                  <Menu>
                    <MenuItem onClick={startCropping} disabled={loading}>
                      <span>{intl.formatMessage({ id: 'actions.crop' })}</span>
                      {loading && <Loader size="small" className="ml-sm" />}
                    </MenuItem>
                    <Separator />
                    <MenuItem onClick={removeItem}>
                      <span className="color-error">{intl.formatMessage({ id: 'actions.delete' })}</span>
                    </MenuItem>
                  </Menu>
                </DropdownContent>
              </Dropdown>
            )}
            {cropping && <Cropper src={preview.cdnUrl} crop={percentCrop} setCrop={doCrop} ruleOfThirds />}
          </ProductImageControls>
        )}
      </ProductImage>
    )
  })
)

const ProductImagesList = SortableContainer<ISortableProductImagesList>(
  memo(({ staticCover, productImages, hasError, allowEdit, onDrop, onCrop, onRemove }: ISortableProductImagesList) => {
    const intl = useIntl()

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: 'image/jpeg, image/png',
      onDrop,
    })

    return (
      <ProductImagesListWrapper>
        {staticCover && <ProductImageStaticCover cover={staticCover} />}
        {productImages.map((image, index) => (
          <ProductImageWrapper key={image.cdnUrl} disabled={!allowEdit}>
            {index === 0 && !staticCover && (
              <CoverBadge>
                <Svg icon="star-filled" />
                <span>{intl.formatMessage({ id: 'new_event.extras.form.product_images.badge.cover' })}</span>
              </CoverBadge>
            )}
            <SortableProductImage
              index={index}
              idx={index}
              preview={image}
              allowEdit={allowEdit}
              disabled={!allowEdit}
              onRemove={onRemove}
              onCrop={onCrop}
            />
          </ProductImageWrapper>
        ))}
        {allowEdit && productImages.length < (staticCover ? MAX_IMAGES_NUM - 1 : MAX_IMAGES_NUM) && (
          <SmallDropzone {...getRootProps()} focus={isDragActive} hasError={hasError}>
            <DropzoneContent>
              <input {...getInputProps({ name: 'productImages' })} />
              <DropzoneIcon>
                <Svg icon="add-image" />
              </DropzoneIcon>
              <DropzoneDescription>
                {staticCover ? (
                  <>
                    <p className="color-black">{intl.formatMessage({ id: 'actions.add_images' })}</p>
                    <span>{intl.formatMessage({ id: 'optional' })}</span>
                  </>
                ) : (
                  <p>{intl.formatMessage({ id: 'actions.add_images' })}</p>
                )}
              </DropzoneDescription>
            </DropzoneContent>
          </SmallDropzone>
        )}
      </ProductImagesListWrapper>
    )
  })
)

export default memo(ProductImagesList)
