import { object, number, string, array, boolean, InferType, ValidationError } from 'yup'
import { isNil, getOr, takeRight, every, maxBy, isNumber, reject, sortBy, reduce, find, filter, sumBy } from 'lodash/fp'
import { parseISO, isAfter, startOfMinute, subHours, addSeconds, formatISO } from 'date-fns'

import { ISO_DATE_REGEX } from '../../../utils/calendar'
import { EventType } from '../../../enums.generated'
import { isFrenchEvent, isItalianEvent, isSpanishEvent } from '../../../utils/isCountryEvent'

const FlagSchema = object().nullable().optional().shape({
  active: boolean().nullable().optional(),
})

const FlagsSchema = object()
  .nullable()
  .optional()
  .shape({
    codeLocked: FlagSchema,
    competition: FlagSchema,
    hidden: FlagSchema,
    ticketTransfer: FlagSchema,
    waitingList: FlagSchema,
    paperTicket: FlagSchema,
    generateNewCodeOnTransfer: FlagSchema,
    enabledPwl: object().optional().shape({
      active: boolean().nullable().optional(),
      deadline: string().nullable().optional(),
    }),
  })

const PriceTierSchema = object().shape({
  id: string().required(),
  name: string().required(),
  faceValue: number().integer().required().min(0),
  doorSalesPrice: number().nullable().integer().min(0),
  allocation: number().nullable().integer().min(0),
  time: string().nullable().matches(ISO_DATE_REGEX),
  attractivePriceType: string().nullable(),
})

type IPriceTier = InferType<typeof PriceTierSchema>

export const TicketTypeSchema = object()
  .shape({
    id: string().required(),
    name: string().required(),
    description: string().nullable().max(1500),
    allocation: number().integer().required().min(0),
    faceValue: number().integer().required().min(0),
    icon: string().nullable().required(),
    increment: number().integer().required().min(1),
    maximumIncrements: number().integer().required().min(1),
    doorSalesPrice: number().integer().min(0),
    doorSalesEnabled: boolean(),
    announceDate: string().nullable().matches(ISO_DATE_REGEX),
    onSaleDate: string().nullable().matches(ISO_DATE_REGEX),
    offSaleDate: string().nullable().matches(ISO_DATE_REGEX),
    startDate: string().nullable().matches(ISO_DATE_REGEX),
    endDate: string().nullable().matches(ISO_DATE_REGEX),
    attractiveSeatingAreaType: string().nullable(),
    attractivePriceType: string().nullable(),
    seatmapUrl: string().nullable(),
    priceTierType: string().nullable(),
    priceTiers: array()
      .nullable()
      .of(PriceTierSchema)
      .when('priceTierType', {
        is: (val: any) => val === 'time' || val === 'allocation',
        then: (schema: any) => schema.min(1),
      }),
    activateCodeDateOffset: number().nullable(),
    streamLink: string()
      .nullable()
      .matches(/^https?:\/\/[^/]+/, { excludeEmptyString: true }),
    requiresOtherTypeIdsEnabled: boolean(),
    requiresOtherTypeIds: array()
      .of(string())
      .when('requiresOtherTypeIdsEnabled', {
        is: true, // when requiresOtherTypeIdsEnabled is true
        then: array().of(string()).required('new_event.tickets.ticket_type_edit.required_ticket_types.error_mandatory'),
        otherwise: array().of(string()).notRequired(), // otherwise, it's not required
      }),
  })
  .test('activateCodeDateOffset', 'Must be negative', function (values) {
    const { diceStaff } = (this.options.context as any) || {}

    if (!diceStaff) return true

    const valid = (values.activateCodeDateOffset || 0) < 0
    return valid || this.createError({ path: 'activateCodeDateOffset', message: 'event_errors.tty.qr_activation' })
  })
  .test('limitName', 'Name must not exceed the char limit', function (values) {
    const { diceStaff, dicePartner } = (this.options.context as any) || {}

    const charLimit = diceStaff || dicePartner ? null : 60

    const valid = !charLimit || (values.name || '').length <= charLimit
    return valid || this.createError({ path: 'name', message: 'validation.yup.string.max' })
  })
  .test('reservedSeating', 'Ticket types with reserved seating should have seat categories', function (values) {
    const valid = !values.reservedSeating || (values.seatCategories || []).length > 0
    return valid || this.createError({ path: 'seatCategories', message: ' ' })
  })
  .test('timeBasedTiers', 'Time based tiers should have time', function (values) {
    const eventType: EventType | null = (this.options.context as any)?.eventType

    // Streams have no price tiers in UI
    if (
      eventType === 'STREAM' ||
      !values.priceTiers ||
      values.priceTiers.length === 0 ||
      values.priceTierType !== 'time'
    ) {
      return true
    }

    const error = new ValidationError(' ', values, 'timeBasedTiers')
    error.inner = []

    let valid = true
    values.priceTiers.forEach((pt: IPriceTier, idx: number) => {
      if (!pt.time && idx !== 0) {
        error.inner.push(this.createError({ path: `priceTiers[${idx}].time`, message: ' ' }))
        valid = false
      }
    })

    return valid || error
  })
  .test('allocationBasedTiers', 'Allocation based tiers should have allocation', function (values) {
    const eventType: EventType | null = (this.options.context as any)?.eventType

    // Streams have no price tiers in UI
    if (
      eventType === 'STREAM' ||
      !values.priceTiers ||
      values.priceTiers.length === 0 ||
      values.priceTierType !== 'allocation'
    ) {
      return true
    }

    const error = new ValidationError(' ', values, 'allocationBasedTiers')
    error.inner = []

    let valid = true
    if (values.ticketPoolId) {
      values.priceTiers.forEach((pt: IPriceTier, idx: number) => {
        if ((isNil(pt.allocation) || !isNumber(pt.allocation)) && idx !== values.priceTiers.length - 1) {
          error.inner.push(this.createError({ path: `priceTiers[${idx}].allocation`, message: ' ' }))
          valid = false
        }
      })
    } else {
      values.priceTiers.forEach((pt: IPriceTier, idx: number) => {
        if ((isNil(pt.allocation) || !isNumber(pt.allocation)) && idx !== 0) {
          error.inner.push(this.createError({ path: `priceTiers[${idx}].allocation`, message: ' ' }))
          valid = false
        }
      })
    }

    return valid || error
  })
  .test('incrementMaxLimit', 'Increment should not exceed max tickets limit', function (values) {
    const eventType: EventType | null = (this.options.context as any)?.eventType

    if (eventType === 'STREAM') return true // Those fields are absent in UI for streams

    const maxLimit: number | undefined = (this.options.context as any)?.maxTicketsLimit
    const valid = isNil(maxLimit) || (values.increment || 1) <= maxLimit
    return valid || this.createError({ path: 'limit', message: 'event_errors.tty.ticket_limit' })
  })
  .test('limitMaxLimit', 'Ticket type limit should not exceed max tickets limit', function (values) {
    const eventType: EventType | null = (this.options.context as any)?.eventType

    if (eventType === 'STREAM') return true // Those fields are absent in UI for streams

    const maxLimit: number | undefined = (this.options.context as any)?.maxTicketsLimit
    const valid = isNil(maxLimit) || (values.maximumIncrements || 1) * (values.increments || 1) <= maxLimit
    return valid || this.createError({ path: 'limit', message: 'event_errors.tty.ticket_limit' })
  })
  .test('customDates', 'Custom dates should be either all be present or all missing', function (values) {
    const hasOnSale = !isNil(values.onSaleDate)
    const hasOffSale = !isNil(values.offSaleDate)
    const hasAnnounce = !isNil(values.announceDate)

    if (hasOnSale === hasOffSale && hasOnSale === hasAnnounce) return true

    const error = new ValidationError(' ', values, 'customDates')
    error.inner = []

    if (!hasAnnounce)
      error.inner.push(this.createError({ path: 'announceDate', message: 'event_errors.custom_tty_dates_dice' }))
    if (!hasOnSale)
      error.inner.push(this.createError({ path: 'onSaleDate', message: 'event_errors.custom_tty_dates_dice' }))
    if (!hasOffSale)
      error.inner.push(this.createError({ path: 'offSaleDate', message: 'event_errors.custom_tty_dates_dice' }))

    return error
  })
  .test('onSaleBeforeOffSale', 'Custom on-sale date should be before off-sale date', function (values) {
    const eventOnSaleDate: string | undefined = (this.options.context as any)?.onSaleDate
    const eventOffSaleDate: string | undefined = (this.options.context as any)?.offSaleDate

    const valid =
      isNil(values.onSaleDate || eventOnSaleDate) ||
      isNil(values.offSaleDate || eventOffSaleDate) ||
      startOfMinute(parseISO(values.onSaleDate || eventOnSaleDate)) <
        startOfMinute(parseISO(values.offSaleDate || eventOffSaleDate))

    return valid || this.createError({ path: 'onSaleDate', message: 'event_errors.tty.on_sale_before_off_sale' })
  })
  .test('onSaleAfterEventOnSale', 'Custom on-sale date should be after event off-sale date', function (values) {
    const onSaleDate: string | undefined = (this.options.context as any)?.onSaleDate
    const valid =
      isNil(values.onSaleDate) ||
      isNil(onSaleDate) ||
      startOfMinute(parseISO(values.onSaleDate)) >= startOfMinute(parseISO(onSaleDate))
    return (
      valid ||
      this.createError({ path: 'onSaleDate', message: `event_errors.tty.on_sale_before_event_on_sale%${onSaleDate}` })
    )
  })
  .test('offSaleBeforeEventOffSale', 'Custom off-sale date should be before event off-sale date', function (values) {
    const offSaleDate: string | undefined = (this.options.context as any)?.offSaleDate
    const valid =
      isNil(values.offSaleDate) ||
      isNil(offSaleDate) ||
      startOfMinute(parseISO(values.offSaleDate)) <= startOfMinute(parseISO(offSaleDate))
    return (
      valid ||
      this.createError({
        path: 'offSaleDate',
        message: `event_errors.tty.off_sale_after_event_off_sale%${offSaleDate}`,
      })
    )
  })
  .test('announceAfterEventAnnounce', 'Custom announce date should be after event announce date', function (values) {
    const announceDate: string | undefined = (this.options.context as any)?.announceDate
    const valid =
      isNil(values.announceDate) ||
      isNil(announceDate) ||
      startOfMinute(parseISO(values.announceDate)) >= startOfMinute(parseISO(announceDate))
    return (
      valid ||
      this.createError({
        path: 'announceDate',
        message: `event_errors.tty.announce_after_event%${announceDate}`,
      })
    )
  })
  .test('announceBeforeEventOffSale', 'Custom announce date should be before event off sale date', function (values) {
    const eventOffSale: string | undefined = (this.options.context as any)?.offSaleDate
    const valid =
      isNil(values.announceDate) ||
      isNil(eventOffSale) ||
      startOfMinute(parseISO(values.announceDate)) <= startOfMinute(parseISO(eventOffSale))
    return (
      valid ||
      this.createError({
        path: 'announceDate',
        message: `event_errors.tty.announce_after_event_off_sale%${eventOffSale}`,
      })
    )
  })
  .test(
    'offSalePriceTierConflict',
    'Ticket type level off sale should be after last time-based price tier off sale',
    function (values) {
      const eventType: EventType | null = (this.options.context as any)?.eventType
      const eventOffSaleDate: string | undefined = (this.options.context as any)?.offSaleDate

      const valid =
        eventType === 'STREAM' ||
        !(values.offSaleDate || eventOffSaleDate) ||
        values.priceTierType !== 'time' ||
        !values.priceTiers ||
        values.priceTiers.length === 0 ||
        ((tt) => {
          const lastTier: any = maxBy('time', tt.priceTiers || [])
          return (
            !lastTier?.time ||
            startOfMinute(parseISO(lastTier?.time)) < startOfMinute(parseISO(values.offSaleDate || eventOffSaleDate))
          )
        })(values)
      return (
        valid ||
        this.createError({ path: 'offSaleDate', message: 'new_event.timeline.off_sale.price_tier_conflict_error' })
      )
    }
  )
  .test('tbTiersOrder', 'Time-based price tiers should have consistent timeline', function (values) {
    const eventType: EventType | null = (this.options.context as any)?.eventType

    if (
      eventType === 'STREAM' ||
      !values.priceTiers ||
      values.priceTiers.length === 0 ||
      values.priceTierType !== 'time' ||
      !every('time', takeRight(values.priceTiers.length - 1, values.priceTiers || []))
    ) {
      return true
    }

    const eventOnSaleDate: string | undefined = (this.options.context as any)?.onSaleDate
    const eventOffSaleDate: string | undefined = (this.options.context as any)?.offSaleDate
    const ttyOnSaleDate: string | undefined = values.onSaleDate
    const ttyOffSaleDate: string | undefined = values.offSaleDate

    if (!ttyOnSaleDate && !eventOnSaleDate) return true
    if (!ttyOffSaleDate && !eventOffSaleDate) return true

    const minDate = ttyOnSaleDate || eventOnSaleDate || ''
    const maxDate = ttyOffSaleDate || eventOffSaleDate || ''

    const error = new ValidationError(' ', values, 'timeBasedTiers')
    error.inner = []

    let valid = true
    let lastTime: Date | null = null
    values.priceTiers.forEach((pt: IPriceTier, idx: number) => {
      const timeStr = idx === 0 ? minDate : pt.time
      if (timeStr) {
        const time = startOfMinute(parseISO(timeStr))

        if (idx > 0) {
          if (lastTime && !isAfter(time, lastTime)) {
            error.inner.push(this.createError({ path: `priceTiers[${idx}].time`, message: 'validation.yup.date.min' }))
            valid = false
          } else if (!isAfter(startOfMinute(parseISO(maxDate)), time)) {
            error.inner.push(this.createError({ path: `priceTiers[${idx}].time`, message: 'validation.yup.date.max' }))
            valid = false
          } else if (!isAfter(time, startOfMinute(parseISO(minDate)))) {
            error.inner.push(this.createError({ path: `priceTiers[${idx}].time`, message: 'validation.yup.date.min' }))
            valid = false
          }
        }

        lastTime = time
      }
    })

    return valid || error
  })
  .test('attractiveSeatingAreaType', 'Italian event tickets should have attractiveSeatingAreaType', function (values) {
    const locale = (this.options.context as any).locale
    const isItalian = isItalianEvent(this.options.context as any, locale)
    const eventType = (this.options.context as any)?.eventType

    const integrationDisabled = !!getOr(true, 'attractiveFields.integrationDisabled', this.options.context)

    const streamingTicketsIntegrationDisabled = !!getOr(
      true,
      'attractiveFields.streamingTicketsIntegrationDisabled',
      this.options.context
    )

    const isNts =
      isItalian &&
      !((eventType === 'STREAM' || values.isStream) && streamingTicketsIntegrationDisabled) &&
      !integrationDisabled

    if (!isNts) return true

    if (!values.attractiveSeatingAreaType) {
      return this.createError({ path: 'attractiveSeatingAreaType', message: ' ' })
    }

    return true
  })
  .test('attractivePriceType', 'Italian event tickets should have attractivePriceType', function (values) {
    const locale = (this.options.context as any).locale
    const isItalian = isItalianEvent(this.options.context as any, locale)
    const eventType = (this.options.context as any)?.eventType

    const integrationDisabled = !!getOr(true, 'attractiveFields.integrationDisabled', this.options.context)

    const streamingTicketsIntegrationDisabled = !!getOr(
      true,
      'attractiveFields.streamingTicketsIntegrationDisabled',
      this.options.context
    )

    const isNts =
      isItalian &&
      !((eventType === 'STREAM' || values.isStream) && streamingTicketsIntegrationDisabled) &&
      !integrationDisabled

    if (!isNts || values.priceTierType) return true

    if (!values.attractivePriceType) {
      return this.createError({ path: 'attractivePriceType', message: ' ' })
    }

    return true
  })
  .test(
    'attractivePriceTypeTiers',
    'Italian event tickets should have attractivePriceType at tier level',
    function (values) {
      const locale = (this.options.context as any).locale
      const isItalian = isItalianEvent(this.options.context as any, locale)
      const eventType = (this.options.context as any)?.eventType

      const integrationDisabled = !!getOr(true, 'attractiveFields.integrationDisabled', this.options.context)

      const streamingTicketsIntegrationDisabled = !!getOr(
        true,
        'attractiveFields.streamingTicketsIntegrationDisabled',
        this.options.context
      )

      const isNts =
        isItalian &&
        !((eventType === 'STREAM' || values.isStream) && streamingTicketsIntegrationDisabled) &&
        !integrationDisabled

      if (!isNts || !values.priceTierType || !values.priceTiers || values.priceTiers.length === 0) {
        return true
      }

      const error = new ValidationError(' ', values, 'italianTiers')
      error.inner = []

      let valid = true
      values.priceTiers.forEach((pt: IPriceTier, idx: number) => {
        if (!pt.attractivePriceType) {
          error.inner.push(this.createError({ path: `priceTiers[${idx}].attractivePriceType`, message: ' ' }))
          valid = false
        }
      })

      return valid || error
    }
  )
  .test(
    'doorSalesPrice',
    'If doorSales enabled for ticketType with priceTiers than doorSalePrice should be set on price tier level',
    function (values) {
      if (!values.priceTierType || !values.priceTiers || values.priceTiers.length === 0 || !values.doorSalesEnabled) {
        return true
      }

      const error = new ValidationError(' ', values, 'doorSalesPriceTiers')
      error.inner = []

      let valid = true
      values.priceTiers.forEach((pt: IPriceTier, idx: number) => {
        if (isNil(pt.doorSalesPrice)) {
          error.inner.push(this.createError({ path: `priceTiers[${idx}].doorSalesPrice`, message: ' ' }))
          valid = false
        }
      })

      return valid || error
    }
  )
  .test(
    'ticketStartDate',
    'Ticket start date & time must be within event start and end date & times',
    function (values) {
      const eventStartDate = (this.options.context as any).date
      const eventEndDate = (this.options.context as any).endDate
      const isAfterEventStart =
        isNil(values.startDate) ||
        isNil(eventStartDate) ||
        startOfMinute(parseISO(values.startDate)) >= startOfMinute(parseISO(eventStartDate))
      const isBeforeEventEnd =
        isNil(values.startDate) ||
        isNil(eventEndDate) ||
        startOfMinute(parseISO(values.startDate)) <= startOfMinute(parseISO(eventEndDate))

      return (
        (isAfterEventStart && isBeforeEventEnd) ||
        this.createError({
          path: 'startDate',
          message: `new_event.tickets.ticket_type_edit.valid_entry_time.${
            !isAfterEventStart ? 'start_before_event_start_error' : 'start_after_event_end_error'
          }`,
        })
      )
    }
  )
  .test(
    'ticketPoolAllocationLimit',
    'Ticket sales cant exceed ticket pool allocation when moving to another ticket pool',
    function (values) {
      if (!values.ticketPoolId) return true
      const { event } = values
      const { sales, ticketPools } = event || {}
      const destinationPool = find((pool) => pool?.id === values.ticketPoolId, ticketPools || [])

      if (((sales && sales?.ticketTypesBreakdown?.length) || 0 > 0) && destinationPool) {
        const destinationPoolMaxAllocation = destinationPool.maxAllocation || 0

        const ticketTypeSalesBreakdown = find((bkdn) => bkdn.ticketTypeId === values.id, sales.ticketTypesBreakdown)
        const ticketTypeSales = ticketTypeSalesBreakdown?.totalSold || 0 + ticketTypeSalesBreakdown?.totalReserved || 0

        const ticketPoolOtherTicketTypes = filter(
          (bkdn) => bkdn.ticketType.ticketPoolId === destinationPool.id && bkdn.ticketType.id !== values.id,
          sales.ticketTypesBreakdown
        )
        const ticketPoolOtherTicketsSales =
          sumBy('totalSold', ticketPoolOtherTicketTypes) + sumBy('totalReserved', ticketPoolOtherTicketTypes)

        if (ticketTypeSales + ticketPoolOtherTicketsSales > destinationPoolMaxAllocation) {
          return this.createError({
            path: 'ticketPoolId',
            message: 'new_event.tickets.ticket_type_edit.ticket_pool_id.pool_allocation_exceeded',
          })
        }
      }
      return true
    }
  )
  .test('salesLimitSafety', 'Sales limit cannot bet set below current sales on that ticket', function (values) {
    if (!values.ticketPoolId) return true
    const sales = getOr({}, 'event.sales', values)

    const ticketSales = find((tb) => tb.ticketTypeId === values.id, sales.ticketTypesBreakdown || [])
    const { totalAppSold = 0, totalPosSold = 0, totalTerminalSold = 0, totalReserved = 0 } = ticketSales || {}
    const totalSold = totalAppSold + totalPosSold + totalTerminalSold + totalReserved

    const valid = !isNil(values.salesLimit) ? values.salesLimit >= totalSold : true

    return (
      valid ||
      this.createError({
        path: 'salesLimit',
        message: `new_event.tickets.ticket_type_edit.sales_limit.min_sales_error%${totalSold}`,
      })
    )
  })

type ITicketType = InferType<typeof TicketTypeSchema>

const TicketsSchema = object()
  .shape({
    state: string().required(),
    announceDate: string().nullable(),
    onSaleDate: string(),
    offSaleDate: string(),
    closeEventDate: string().nullable(),
    maxTicketsLimit: number().integer().required().min(1),
    ticketTypes: array().min(1).required(),
    charityEvent: boolean().nullable(),
    flags: FlagsSchema,
    charityId: string().nullable(),
    diceStreamRewatchEnabledUntil: string().nullable().matches(ISO_DATE_REGEX),
    ticketPools: array()
      .nullable()
      .of(object().shape({ name: string().required(), maxAllocation: number().integer().required() })),
  })
  .test('minAllocation', 'Pool allocation must be higher than # tickets already sold', function (values) {
    if (!values.ticketPools || values.ticketPools?.length === 0 || !values.sales || !values.sales?.ticketTypesBreakdown)
      return true
    const poolSales = reduce((sales: any, tt: any) => {
      if (!tt || tt.ticketType.archived) return
      const poolId = tt.ticketType.ticketPoolId
      sales[poolId] = (sales[poolId] || 0) + (tt.totalAppSold || 0) + (tt.totalPosSold || 0) + (tt.totalReserved || 0)
      return sales
    }, {})(values.sales?.ticketTypesBreakdown || [])

    const pools = values.ticketPools
    const errs: ValidationError[] = []
    let valid = true
    pools.forEach((pool: any, index: number) => {
      if (pool.maxAllocation < poolSales[pool.id] || 0) {
        errs.push(this.createError({ path: `ticketPools[${index}].maxAllocation`, message: 'minAllocation' }))
        valid = false
      }
    })

    if (valid) return true

    const error = new ValidationError(' ', values, 'ticketPools')
    error.inner = errs
    return error
  })
  .test('pwlEnabled', 'PWL is not allowed when WL is disabled', function (values) {
    const enabledPwl: boolean = getOr(false, 'flags.enabledPwl.active', values)
    const enabledWl: boolean = getOr(false, 'flags.waitingList.active', values)

    if (enabledPwl && !enabledWl) {
      return this.createError({ path: 'flags.enabledPwl.active', message: 'new_event.tickets.enabled_pwl.wl_missing' })
    }

    return true
  })
  .test('pwlWindows', 'PWL window should have offset except first one', function (values) {
    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff } = ctx

    const enabledPwl: boolean = getOr(false, 'flags.enabledPwl.active', values)

    if (
      !diceStaff ||
      !enabledPwl ||
      !values.waitingListExchangeWindows ||
      values.waitingListExchangeWindows.length === 0
    ) {
      return true
    }

    const windows = sortBy((w) => {
      if (isNil(w?.offset) && !(w as any)?.isNew) return -Infinity
      return w?.offset || 0
    }, values.waitingListExchangeWindows || [])

    let valid = true
    const errs: ValidationError[] = []
    windows.forEach((w: any, idx: number) => {
      if (idx > 0 && isNil(w?.offset)) {
        errs.push(this.createError({ path: `waitingListExchangeWindows[${idx}].offset`, message: ' ' }))
        valid = false
      }
    })

    if (valid) return true

    const error = new ValidationError(' ', values, 'waitingListExchangeWindows')
    error.inner = errs
    return error
  })
  .test('pwlWindowsUniq', 'PWL windows should have different offsets', function (values) {
    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff } = ctx

    const enabledPwl: boolean = getOr(false, 'flags.enabledPwl.active', values)

    if (
      !diceStaff ||
      !enabledPwl ||
      !values.waitingListExchangeWindows ||
      values.waitingListExchangeWindows.length === 0
    ) {
      return true
    }

    const windows = sortBy((w) => {
      if (isNil(w?.offset) && !(w as any)?.isNew) return -Infinity
      return w?.offset || 0
    }, values.waitingListExchangeWindows || [])
    const offsets = new Set<number>()

    let valid = true
    const errs: ValidationError[] = []
    windows.forEach((w: any, idx: number) => {
      if (idx > 0 && !isNil(w?.offset) && offsets.has(w.offset)) {
        errs.push(
          this.createError({
            path: `waitingListExchangeWindows[${idx}].offset`,
            message: 'new_event.tickets.pwl_windows.validation.uniq',
          })
        )
        valid = false
      } else if (!isNil(w?.offset)) {
        offsets.add(w.offset)
      }
    })

    if (valid) return true

    const error = new ValidationError(' ', values, 'waitingListExchangeWindows')
    error.inner = errs
    return error
  })
  .test('pwlWindowsBounds', 'PWL windows should have properly bounded offsets', function (values) {
    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff } = ctx

    const enabledPwl: boolean = getOr(false, 'flags.enabledPwl.active', values)

    if (
      !diceStaff ||
      !enabledPwl ||
      !values.waitingListExchangeWindows ||
      values.waitingListExchangeWindows.length === 0
    ) {
      return true
    }

    const windows = sortBy((w) => {
      if (isNil(w?.offset) && !(w as any)?.isNew) return -Infinity
      return w?.offset || 0
    }, values.waitingListExchangeWindows || [])

    const offSaleDate = parseISO(values.offSaleDate)
    const onSaleDate = parseISO(values.onSaleDate)

    let valid = true
    const errs: ValidationError[] = []
    windows.forEach((w: any, idx: number) => {
      if (idx > 0 && !isNil(w?.offset) && values.onSaleDate && values.offSaleDate) {
        const startDate = addSeconds(offSaleDate, w.offset)

        if (startDate < onSaleDate || w.offset > 0) {
          errs.push(
            this.createError({
              path: `waitingListExchangeWindows[${idx}].offset`,
              message: 'new_event.tickets.pwl_windows.validation.boundaries',
            })
          )
          valid = false
        }
      }
    })

    if (valid) return true

    const error = new ValidationError(' ', values, 'waitingListExchangeWindows')
    error.inner = errs
    return error
  })
  .test('closeEventDate', 'Ticket transfer deadline check', function (values) {
    const ticketTransfer: boolean = getOr(false, 'flags.ticketTransfer.active', values)
    if (!ticketTransfer) {
      return true
    }
    if (isNil(values.closeEventDate)) {
      return this.createError({ path: 'closeEventDate', message: ' ' })
    }
    if (values.date && startOfMinute(parseISO(values.endDate)) < startOfMinute(parseISO(values.closeEventDate))) {
      return this.createError({
        path: 'closeEventDate',
        message: 'new_event.tickets.transfer_deadline.validation.before_close',
      })
    }
    return true
  })
  .test('onSaleNotificationAt', 'On sale notification date check', function (values) {
    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff } = ctx

    const onSaleNotification = diceStaff && !!values.onSaleNotification && !values.onSaleNotificationStatus
    if (!onSaleNotification) {
      return true
    }
    if (isNil(values.onSaleNotificationAt)) {
      return this.createError({ path: 'onSaleNotificationAt', message: ' ' })
    }
    if (
      values.date &&
      startOfMinute(subHours(parseISO(values.date), 1)) < startOfMinute(parseISO(values.onSaleNotificationAt))
    ) {
      return this.createError({
        path: 'onSaleNotificationAt',
        message: 'new_event.tickets.transfer_deadline.validation.before_doors_open',
      })
    }
    return true
  })
  .test('flags.enabledPwl.deadline', 'PWL deadline check', function (values) {
    const enabledPwl: boolean = getOr(false, 'flags.enabledPwl.active', values)
    if (!enabledPwl) {
      return true
    }
    if (isNil(getOr(null, 'flags.enabledPwl.deadline', values))) {
      return this.createError({ path: 'flags.enabledPwl.deadline', message: ' ' })
    }
    if (
      values.offSaleDate &&
      startOfMinute(subHours(parseISO(values.offSaleDate), 1)) <
        startOfMinute(parseISO(getOr(null, 'flags.enabledPwl.deadline', values)))
    ) {
      return this.createError({
        path: 'flags.enabledPwl.deadline',
        message: 'new_event.tickets.pwl_deadline.validation.hour_before_off_sale',
      })
    }
    return true
  })
  .test('innerTicketTypesErrors', 'Lets revalidate inner tickets', function (values) {
    const locale = (this.options.context as any).locale

    const ttys = values.ticketTypes && reject('archived', values.ticketTypes)

    if (!ttys || ttys.length === 0) return true

    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff, dicePartner } = ctx

    let timeConflict = false
    let valid = true
    const errs: ValidationError[] = []
    ttys.forEach((tt: ITicketType, idx: number) => {
      try {
        TicketTypeSchema.validateSync(tt, { context: { locale, diceStaff, dicePartner, ...values } })
      } catch (e) {
        const timeConflictSet = new Set(['timeBasedTiers', 'onSaleDate', 'offSaleDate'])
        if (timeConflictSet.has(e.path)) {
          timeConflict = true
        }
        errs.push(this.createError({ path: `ticketTypes[${idx}].innerError`, message: ' ' }))
        valid = false
      }
    })

    if (valid) return true

    if (timeConflict) {
      errs.push(
        this.createError({ path: 'innerTicketTypesErrors', message: 'new_event.timeline.tickets_time_conflict' })
      )
    }

    const error = new ValidationError(' ', values, 'innerTicketTypesErrors')
    error.inner = errs
    return error
  })
  .test('charityId', 'Charity id is required if its charity event', function (values) {
    const valid = !values.charityEvent || !!values.charityId
    return valid || this.createError({ path: 'charityId', message: ' ' })
  })
  .test('clubNight', 'Club night is only in Spain', function (values) {
    const locale = (this.options.context as any).locale
    const valid = !values.taxSettings?.clubNight || isSpanishEvent(values, locale)
    return valid || this.createError({ path: 'taxSettings.clubNight', message: ' ' })
  })
  .test('franceMainstream', 'France mainstream is only in France', function (values) {
    const locale = (this.options.context as any).locale
    const valid = !values.taxSettings?.franceMainstream || isFrenchEvent(values, locale)
    return valid || this.createError({ path: 'taxSettings.franceMainstream', message: ' ' })
  })
  .test('diceStreamRewatchEnabledUntil', 'Enabled rewatch should be after planned stream end', function (values) {
    const valid =
      isNil(values.date) ||
      isNil(values.diceStreamDuration) ||
      isNil(values.diceStreamRewatchEnabledUntil) ||
      startOfMinute(addSeconds(parseISO(values.date), values.diceStreamDuration)) <
        startOfMinute(parseISO(values.diceStreamRewatchEnabledUntil))
    return valid || this.createError({ path: 'diceStreamRewatchEnabledUntil', message: 'validation.yup.date.min' })
  })

// Commented according to decision in https://dicemusic.atlassian.net/browse/PROD-9280
// .test('salesMustFlow', 'At any point in time at least one tty should be on sale', function (values) {
//   if (
//     !values.ticketTypes ||
//     values.ticketTypes.length === 0 ||
//     isNil(values.onSaleDate) ||
//     isNil(values.offSaleDate)
//   ) {
//     return true
//   }

//   const bounds = [startOfMinute(parseISO(values.onSaleDate)), startOfMinute(parseISO(values.offSaleDate))]

//   const periods = map(
//     (tt) => [
//       startOfMinute(parseISO(tt.onSaleDate || values.onSaleDate)),
//       startOfMinute(parseISO(tt.offSaleDate || values.offSaleDate)),
//     ],
//     values.ticketTypes
//   )

//   const gaps: Date[][] = map(
//     map((dt: Date) => utcToZonedTime(dt, values.timezoneName || clientTimezone)),
//     calculatePeriodGaps(bounds, periods)
//   )

//   const error = new ValidationError(' ', values, 'ticketTypes')
//   error.inner = gaps.map((gap, idx) =>
//     this.createError({
//       path: `ticketTypesGap[${idx}]`,
//       message: `event_errors.tty.no_gaps%${formatISO(gap[0])}%${formatISO(gap[1])}`,
//     })
//   )

//   error.inner.push(this.createError({ path: 'ticketTypes', message: ' ' }))

//   return gaps.length === 0 || error
// })

export default TicketsSchema
