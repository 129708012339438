/**
 * @generated SignedSource<<530afe3588117a11ae0c23219fb54ab2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CategoryType = "ACCESS_PASS" | "AFTER_PARTY_PASS" | "ARTIST_MEET_AND_GREET" | "CAMPING" | "COACH_BUS" | "DRINKS_ALCOHOLIC" | "DRINKS_NON_ALCOHOLIC" | "EARLY_ENTRY_PASS" | "EXPERIENCE" | "FOOD" | "FOOD_AND_DRINK" | "JUMPER" | "MERCH" | "OTHER" | "PARKING" | "QUEUE_JUMP" | "TRAVEL_AND_ACCOMMODATION" | "T_SHIRT" | "VIP_UPGRADE" | "%future added value";
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type FeesBehaviour = "APPEND_TO_CONTRACT" | "OVERRIDE" | "USE_CONTRACT" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type ProductOptionType = "CUSTOM" | "SIZE" | "%future added value";
export type ProductRootType = "EXTRAS" | "MERCH" | "%future added value";
export type ProductType = "ADDON" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventProductModal_event$data = {
  readonly allowedActions: {
    readonly addProducts: boolean | null;
    readonly manageProductsAllocation: boolean | null;
    readonly minorUpdate: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly products: {
      readonly canAdd: boolean | null;
      readonly canChangeOrder: boolean | null;
      readonly canDelete: boolean | null;
      readonly canUpdate: boolean | null;
      readonly canUpdateImages: ReadonlyArray<string | null> | null;
      readonly canUpdatePrice: ReadonlyArray<string | null> | null;
    } | null;
  } | null;
  readonly basePriceFees: ReadonlyArray<string | null> | null;
  readonly billingPromoter: {
    readonly accountId: string;
    readonly addressCountry: string | null;
    readonly allowSkipReview: boolean | null;
    readonly coolingOffPeriod: boolean | null;
    readonly countryCode: string | null;
    readonly disableUsTax: boolean | null;
    readonly label: string | null;
    readonly platformAccountCode: PlatformAccountCode | null;
    readonly resoldEnabled: boolean | null;
    readonly showPriceSuggestions: boolean;
    readonly stripeAccountId: string | null;
    readonly value: string;
  } | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly date: string | null;
  readonly disableUsTax: boolean | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly fees: ReadonlyArray<{
    readonly amount: number | null;
    readonly applicable: boolean | null;
    readonly split: ReadonlyArray<{
      readonly amount: number | null;
      readonly destination: FeeDestination | null;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly type: FeeType;
    readonly unit: FeeUnit;
  } | null> | null;
  readonly feesBehaviour: FeesBehaviour | null;
  readonly id: string;
  readonly postFanPriceFees: ReadonlyArray<string | null> | null;
  readonly products: ReadonlyArray<{
    readonly allTicketTypes: boolean;
    readonly allocation: number;
    readonly category: {
      readonly coverBackgroundColor: string | null;
      readonly coverImageUrl: string | null;
      readonly label: string;
      readonly parentCategory: {
        readonly label: string;
        readonly type: CategoryType | null;
        readonly value: string;
      } | null;
      readonly rootType: ProductRootType | null;
      readonly type: CategoryType | null;
      readonly value: string;
    };
    readonly customCover: boolean | null;
    readonly date: string | null;
    readonly description: string;
    readonly endDate: string | null;
    readonly faceValue: number;
    readonly fees: ReadonlyArray<{
      readonly amount: number | null;
      readonly applicable: boolean | null;
      readonly split: ReadonlyArray<{
        readonly amount: number | null;
        readonly destination: FeeDestination | null;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly type: FeeType;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly fulfilledBy: string | null;
    readonly hasSeparateAccessBarcodes: boolean;
    readonly hasVariants: boolean;
    readonly id: string;
    readonly locationNote: string | null;
    readonly name: string;
    readonly offSaleDate: string | null;
    readonly onSaleDate: string | null;
    readonly optionType: ProductOptionType | null;
    readonly productImages: ReadonlyArray<{
      readonly attachment: {
        readonly id: string;
      } | null;
      readonly cdnUrl: string;
      readonly cropRegion: {
        readonly height: number | null;
        readonly width: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
      readonly id: string;
    } | null> | null;
    readonly productType: ProductType;
    readonly purchaseConfirmationMessage: string | null;
    readonly rootType: ProductRootType;
    readonly sellingPoints: ReadonlyArray<{
      readonly name: string;
    } | null>;
    readonly sku: string | null;
    readonly ticketTypes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
    } | null>;
    readonly variants: ReadonlyArray<{
      readonly allocation: number;
      readonly id: string;
      readonly name: string;
      readonly sku: string | null;
    } | null>;
    readonly venue: {
      readonly fullAddress: string | null;
      readonly label: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly profileDetails: {
        readonly imageAttachment: {
          readonly cdnUrl: string;
        } | null;
        readonly imageCropRegion: {
          readonly height: number | null;
          readonly width: number | null;
          readonly x: number | null;
          readonly y: number | null;
        } | null;
      } | null;
      readonly value: string;
      readonly venueImages: ReadonlyArray<{
        readonly attachment: {
          readonly cdnUrl: string;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
  readonly state: EventState | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly archived: boolean | null;
    readonly faceValue: number;
    readonly fees: ReadonlyArray<{
      readonly amount: number | null;
      readonly applicable: boolean | null;
      readonly split: ReadonlyArray<{
        readonly amount: number | null;
        readonly destination: FeeDestination | null;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly type: FeeType;
      readonly unit: FeeUnit;
    } | null> | null;
    readonly hidden: boolean;
    readonly id: string;
    readonly name: string | null;
    readonly priceBreakdown: {
      readonly breakdown: ReadonlyArray<{
        readonly applicable: boolean | null;
        readonly computed: number;
        readonly type: FeeType;
      } | null>;
      readonly faceValue: number;
      readonly friendlyFaceValue: number | null;
      readonly friendlyPrice: number | null;
      readonly split: ReadonlyArray<{
        readonly computed: number;
        readonly destination: FeeDestination;
      } | null>;
      readonly total: number;
      readonly totalWithPwl: number | null;
      readonly totalWithoutPwl: number | null;
    } | null;
    readonly priceTierType: PriceTierTypes | null;
    readonly priceTiers: ReadonlyArray<{
      readonly faceValue: number;
      readonly fees: ReadonlyArray<{
        readonly amount: number | null;
        readonly applicable: boolean | null;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null> | null;
        readonly type: FeeType;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly id: string;
      readonly name: string | null;
      readonly priceBreakdown: {
        readonly breakdown: ReadonlyArray<{
          readonly applicable: boolean | null;
          readonly computed: number;
          readonly split: ReadonlyArray<{
            readonly amount: number | null;
            readonly computed: number;
            readonly destination: FeeDestination | null;
          } | null>;
          readonly type: FeeType;
        } | null>;
        readonly faceValue: number;
        readonly friendlyFaceValue: number | null;
        readonly friendlyPrice: number | null;
        readonly split: ReadonlyArray<{
          readonly computed: number;
          readonly destination: FeeDestination;
        } | null>;
        readonly total: number;
        readonly totalWithPwl: number | null;
        readonly totalWithoutPwl: number | null;
      } | null;
    } | null> | null;
    readonly ticketPoolId: string | null;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly venues: ReadonlyArray<{
    readonly value: string;
  } | null> | null;
  readonly " $fragmentType": "EventProductModal_event";
};
export type EventProductModal_event$key = {
  readonly " $data"?: EventProductModal_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventProductModal_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disableUsTax",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applicable",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destination",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Fee",
  "kind": "LinkedField",
  "name": "fees",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v8/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithPwl",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithoutPwl",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "computed",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceSplit",
  "kind": "LinkedField",
  "name": "split",
  "plural": true,
  "selections": [
    (v17/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyFaceValue",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyPrice",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootType",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cdnUrl",
  "storageKey": null
},
v25 = [
  (v24/*: any*/)
],
v26 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "x",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "y",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventProductModal_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feesBehaviour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basePriceFees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postFanPriceFees",
      "storageKey": null
    },
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Promoter",
      "kind": "LinkedField",
      "name": "billingPromoter",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stripeAccountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platformAccountCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showPriceSuggestions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressCountry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowSkipReview",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resoldEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coolingOffPeriod",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addProducts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manageProductsAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesProducts",
          "kind": "LinkedField",
          "name": "products",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canAdd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeOrder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canDelete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdatePrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdateImages",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Venue",
      "kind": "LinkedField",
      "name": "venues",
      "plural": true,
      "selections": [
        (v10/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "doorSalesOnly",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "includeArchived",
          "value": true
        }
      ],
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketPoolId",
          "storageKey": null
        },
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archived",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        },
        (v13/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "priceBreakdown",
          "plural": false,
          "selections": [
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FeeOutput",
              "kind": "LinkedField",
              "name": "breakdown",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                (v7/*: any*/),
                (v17/*: any*/)
              ],
              "storageKey": null
            },
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceTierType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceTier",
          "kind": "LinkedField",
          "name": "priceTiers",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "priceBreakdown",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FeeOutput",
                  "kind": "LinkedField",
                  "name": "breakdown",
                  "plural": true,
                  "selections": [
                    (v17/*: any*/),
                    (v5/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FeeSplitOutput",
                      "kind": "LinkedField",
                      "name": "split",
                      "plural": true,
                      "selections": [
                        (v4/*: any*/),
                        (v17/*: any*/),
                        (v8/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v13/*: any*/),
                (v18/*: any*/),
                (v20/*: any*/),
                (v19/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "products",
      "plural": true,
      "selections": [
        (v21/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allTicketTypes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            (v11/*: any*/),
            (v5/*: any*/),
            (v21/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverBackgroundColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "parentCategory",
              "plural": false,
              "selections": [
                (v10/*: any*/),
                (v11/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasVariants",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optionType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Variant",
          "kind": "LinkedField",
          "name": "variants",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v12/*: any*/),
            (v22/*: any*/),
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fulfilledBy",
          "storageKey": null
        },
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasSeparateAccessBarcodes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customCover",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Venue",
          "kind": "LinkedField",
          "name": "venue",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileDetails",
              "kind": "LinkedField",
              "name": "profileDetails",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Attachment",
                  "kind": "LinkedField",
                  "name": "imageAttachment",
                  "plural": false,
                  "selections": (v25/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CropRegion",
                  "kind": "LinkedField",
                  "name": "imageCropRegion",
                  "plural": false,
                  "selections": (v26/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VenueImage",
              "kind": "LinkedField",
              "name": "venueImages",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Attachment",
                  "kind": "LinkedField",
                  "name": "attachment",
                  "plural": false,
                  "selections": (v25/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationNote",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v0/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onSaleDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offSaleDate",
          "storageKey": null
        },
        (v12/*: any*/),
        (v13/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "purchaseConfirmationMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductImage",
          "kind": "LinkedField",
          "name": "productImages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v24/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CropRegion",
              "kind": "LinkedField",
              "name": "cropRegion",
              "plural": false,
              "selections": (v26/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "attachment",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SellingPoint",
          "kind": "LinkedField",
          "name": "sellingPoints",
          "plural": true,
          "selections": [
            (v12/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketType",
          "kind": "LinkedField",
          "name": "ticketTypes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v12/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "2a2eaf61ef9f1d4ce1dee8ca63d8157c";

export default node;
