import React, { FC, memo, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import graphql from 'babel-plugin-relay/macro'
import qs from 'qs'
import { useLazyLoadQuery } from 'react-relay'

import EventSubmission from './EventSubmission'
import { EventSubmissionPageQuery } from '../../__generated__/EventSubmissionPageQuery.graphql'

const QUERY_NEW = graphql`
  query EventSubmissionPageNewQuery {
    viewer {
      ...EventSubmission_viewer
    }
  }
`

const QUERY = graphql`
  query EventSubmissionPageQuery($id: ID!) {
    event: node(id: $id) {
      ...EventSubmission_event
    }
    viewer {
      ...EventSubmission_viewer
    }
  }
`

const EventSubmissionPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<{ id: string }>()

  const { viewer, event } = useLazyLoadQuery<EventSubmissionPageQuery>(
    id ? QUERY : QUERY_NEW,
    (id ? { id } : {}) as any,
    {
      fetchPolicy: 'store-and-network',
    }
  )

  return viewer && <EventSubmission viewer={viewer} event={(event || null) as any} />
}

export default memo(EventSubmissionPage)
