import React, { FC, memo, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router'
import styled from 'styled-components/macro'

import AccountSwitcher from '../../../../components/AccountSwitcher'
import SidebarAccount from '../../../../components/SidebarAccount'
import Tooltip from '../../../../components/Tooltip'
import { authContext } from '../../../../context/auth'
import { breakpoints, mediaQuery } from '../../../../utils/variables'
import { USER_ACCOUNT_TRIGGER_ID } from '../../services/sidebarTrigger'

const TRIGGER = ['click']
const OFFSET_MOBILE = { offset: [0, -21] }

const SidebarFooter = styled.div`
  margin-top: auto;
`

export const SidebarUser = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  body.compact-sidebar & {
    ${mediaQuery.greaterThan('tablet')`
      display: block;
    `}
  }
  ${mediaQuery.between('tablet', 'desktop')`
    display: block;
  `}
`

interface IProps {
  expandOnMobile: boolean
}

const Footer: FC<IProps> = ({ expandOnMobile }) => {
  const { key } = useLocation()
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })
  const { user, account } = useContext(authContext)
  const accountName = user.diceStaff ? 'DICE' : account?.name

  return (
    <SidebarFooter>
      <Tooltip
        key={`${key}:${expandOnMobile}`}
        trigger={TRIGGER}
        overlayClassName="-fixed"
        placement={isMobile ? 'top' : 'rightBottom'}
        align={isMobile ? OFFSET_MOBILE : undefined}
        handle={
          <SidebarUser id={USER_ACCOUNT_TRIGGER_ID}>
            <SidebarAccount name={user.name || ''} accountName={accountName} data-id="account" />
          </SidebarUser>
        }
      >
        <AccountSwitcher />
      </Tooltip>
    </SidebarFooter>
  )
}

export default memo(Footer)
