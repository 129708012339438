import React, { FC, memo, ComponentProps } from 'react'
import styled from 'styled-components/macro'

import IconButton from './IconButton'

const AddButton = styled(IconButton)`
  margin-right: 16px;
`

const AddLabel = styled.label`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
`

const ListAddButton: FC<React.PropsWithChildren<ComponentProps<typeof IconButton>>> = ({
  className,
  label,
  ...props
}) => {
  return (
    <AddLabel className={className}>
      <AddButton icon="add" color="black" {...props} />
      {label}
    </AddLabel>
  )
}

export default styled(memo(ListAddButton))``
