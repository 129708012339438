import React, { FC, memo, useState, useCallback, useMemo, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components/macro'
import { compact, getOr, includes, map, find, drop, get } from 'lodash/fp'
import { nanoid } from 'nanoid'
import { addDays, formatISO, isAfter, parseISO, subDays } from 'date-fns'

import { useMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { localeContext } from '../../context/locale'
import { notificationContext } from '../../context/notification'
import { DATETIME_FORMATS } from '../../utils/formatters/datetime'
import { color, mediaQuery } from '../../utils/variables'

import Badge from '../../components/Badge'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Collapsible from '../../components/Collapsible'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import EmptyState from '../../components/EmptyState'
import FormField, { IStyledFormField } from '../../components/FormField'
import { Form, FormRow } from '../../components/Form'
import { Modal, ModalBody, ModalFooterControl, ModalFooter, ModalContent } from '../../components/Modal'
import Svg from '../../components/Svg'

import IEventForm from '../EventForm/types'

import { EventDetails_event$data } from '../../__generated__/EventDetails_event.graphql'
import { textStyle } from '../../utils/typography'

const Divider = styled.hr`
  margin: 32px 0;
  border-top: 1px solid ${color.lightgrey};
  ${mediaQuery.lessThan('tablet')`
    margin: 24px 0;
  `}
`

const Plate = styled(EmptyState)<{ successIcon?: boolean }>`
  svg {
    position: relative;
    width: 72px;
    height: 72px;
    border-radius: 100%;
    border: 1px solid currentColor;
    margin-bottom: 24px;
  }
  ${({ successIcon }) =>
    successIcon &&
    css`
      svg {
        color: ${color.success};
      }
    `}
`

const StyledModal = styled(Modal)<{ centered?: boolean }>`
  ${ModalFooter} {
    ${Form} {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      max-width: 700px;
      margin: 0 auto;
    }
  }
  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      justify-content: center;
      ${ModalContent} {
        display: flex;
        min-height: 100%;
        flex: 1;
      }
    `}
`

const ChangeList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: rgba(0, 216, 175, 0.1);
  border: 1px solid ${color.success};
  border-radius: 4px;
  ${Badge} {
    max-width: 30%;
    background-color: ${color.success};
    color: white;
    margin-right: 32px;
  }
  li + li {
    margin-top: 8px;
  }
  li {
    display: flex;
    align-items: center;
    s {
      color: ${color.greyer};
      &:empty {
        display: none;
      }
      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${color.success};
        margin: 0 8px;
      }
    }
  }
`

const TextArea = styled(FormField)`
  textarea {
    min-height: 122px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
` as IStyledFormField

interface ICounterProps {
  count: number
  maxCount: number
}

const Counter = styled.div<ICounterProps>`
  position: absolute;
  top: -24px;
  right: 0;
  white-space: nowrap;
  ${textStyle.functional.sm}
  color: ${color.darkgrey};
  ${({ count, maxCount }) => count > maxCount && `color: ${color.error}`}
`

const SendTest = styled.div`
  margin-top: 32px;
  padding: 10px 12px;
  background: rgba(0, 0, 255, 0.05);
  ${textStyle.functional.sm}
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: flex-start;

  ${mediaQuery.greaterThan('tablet')`
    align-items: center;
  `}
`

const SendTestDescription = styled.div`
  flex: 1;
  display: flex;
  margin-right: 24px;
  font-weight: normal;
  align-items: flex-start;

  ${mediaQuery.greaterThan('tablet')`
    align-items: center;
  `}

  svg {
    flex: 24px 0 0;
    color: ${color.primary};
    margin-right: 8px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .button + .button {
    margin-left: 16px;
  }
`

interface IProps {
  event: EventDetails_event$data
  values: IEventForm
  changes: Array<string | null>
  loading?: boolean
  completed?: boolean
  onSave: (
    values: IEventForm,
    notification?: { changes: Array<string | null> | null; message: string | null; sendMeACopy: boolean }
  ) => void
  onClose: () => void
}

// STEPS = 'INIT' | 'FORM' | 'COMPLETE' | 'REJECT'

const ChangeEventNotificationModal: FC<React.PropsWithChildren<IProps>> = ({
  event,
  values,
  changes,
  loading,
  completed,
  onClose,
  onSave,
}) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { addNotification } = useContext(notificationContext)
  const [currentStep, setCurrentStep] = useState('INIT')
  const [formattedChanges] = useState(() => {
    const resultChanges = [] as Array<{ name: string; oldValue: string | null; newValue: string | null } | null>
    if (includes('schedule_status', changes)) {
      const eventScheduleStatus = intl.formatMessage({
        id: `new_event.timeline.schedule_status.options.${(event?.scheduleStatus || 'going_ahead')?.toLowerCase()}`,
      })
      const currentScheduleStatus = intl.formatMessage({
        id: `new_event.timeline.schedule_status.options.${(values.scheduleStatus || 'going_ahead')?.toLowerCase()}`,
      })
      resultChanges.push({ name: 'schedule_status', oldValue: eventScheduleStatus, newValue: currentScheduleStatus })
      if (values?.scheduleStatus === 'RESCHEDULED') {
        resultChanges.push({
          name: 'date',
          oldValue: includes('date', changes)
            ? event?.date
              ? intl.formatDate(parseISO(event.date), {
                ...DATETIME_FORMATS.DATETIME(locale),
                timeZone: event.timezoneName || undefined,
              })
              : intl.formatMessage({ id: 'na' })
            : eventScheduleStatus,
          newValue: values.date
            ? intl.formatDate(parseISO(values.date), {
              ...DATETIME_FORMATS.DATETIME(locale),
              timeZone: event.timezoneName || undefined,
            })
            : intl.formatMessage({ id: 'na' }),
        })
      } else if (values.scheduleStatus === 'POSTPONED') {
        resultChanges.push({
          name: 'date',
          oldValue: event?.date
            ? intl.formatDate(parseISO(event.date), {
              ...DATETIME_FORMATS.DATETIME(locale),
              timeZone: event.timezoneName || undefined,
            })
            : intl.formatMessage({ id: 'na' }),
          newValue: intl.formatMessage({
            id: 'change_event_notification.type.postponed.date',
          }),
        })
      }
    } else if (includes('date', changes)) {
      resultChanges.push({
        name: 'date',
        oldValue: event.date
          ? intl.formatDate(parseISO(event.date), {
            ...DATETIME_FORMATS.DATETIME(locale),
            timeZone: event.timezoneName || undefined,
          })
          : intl.formatMessage({ id: 'na' }),
        newValue: values.date
          ? intl.formatDate(parseISO(values.date), {
            ...DATETIME_FORMATS.DATETIME(locale),
            timeZone: event.timezoneName || undefined,
          })
          : intl.formatMessage({ id: 'na' }),
      })
    }
    if (includes('venue', changes))
      resultChanges.push({
        name: 'venue',
        oldValue: event?.primaryVenue?.label || '',
        newValue: find(['value', values.primaryVenue?.value], values.venues)?.label || intl.formatMessage({ id: 'na' }),
      })
    if (includes('lineup', changes))
      resultChanges.push({
        name: 'lineup',
        oldValue: null,
        newValue: map((l: { details: string } | null) => l && l.details, drop(1, values.lineup)).join(', '),
      })

    return resultChanges
  })

  const [message, setMessage] = useState('')
  const handleChange = useCallback((e: any) => {
    setMessage(e.target.value)
  }, [])

  const [agreementSigned, setAgreementSigned] = useState<boolean>(false)
  const [sendMeACopy, setSendMeACopy] = useState(false)
  const handleSendMeACopyChange = useCallback(() => setSendMeACopy((v) => !v), [])
  const handleSignAgreement = useCallback(() => setAgreementSigned((v) => !v), [])

  const showForm = useCallback(() => setCurrentStep('FORM'), [])
  const reject = useCallback(() => setCurrentStep('REJECT'), [])
  const saveAndNotify = useCallback(() => {
    let highlighChanges = changes
    if (includes('schedule_status', changes) && values.scheduleStatus === 'POSTPONED') {
      highlighChanges = ['schedule_status']
    }
    const notification = { changes: highlighChanges, message: message, sendMeACopy }
    onSave(values, notification)
  }, [changes, message, onSave, values, sendMeACopy])
  const saveWithoutNotification = useCallback(() => onSave(values), [onSave, values])

  useEffect(() => {
    if (completed) {
      setCurrentStep('COMPLETE')
    }
  }, [completed])

  const [send, sending] = useMutation(graphql`
    mutation ChangeEventNotificationModalSendTestMutation($input: TestEventChangedNotificationInput!) {
      testEventChangedNotification(input: $input) {
        successful
      }
    }
  `)

  const sendTest = useCallback(() => {
    let highlighChanges = changes
    if (includes('schedule_status', changes) && values.scheduleStatus === 'POSTPONED') {
      highlighChanges = ['schedule_status']
    }
    send({
      variables: {
        input: {
          clientMutationId: nanoid(),
          eventId: event?.id,
          message: message,
          changes: highlighChanges,
          changeset: {
            date: values.date,
            scheduleStatus: values.scheduleStatus,
            flags: {
              autoRescheduledEventRefunds: {
                active: values.flags?.autoRescheduledEventRefunds?.active,
                cutoffDays: parseInt(values.flags?.autoRescheduledEventRefunds?.cutoff_days as string) || null,
              },
            },
            lineup: values.lineup,
            eventVenues: map(
              (p) => ({
                primary: p.value === values.primaryVenue?.value,
                venueId: p.value,
              }),
              compact(values.venues)
            ),
          },
        },
      },
      onCompleted: (data) => {
        const success = getOr(false, 'testEventChangedNotification.successful', data)
        if (success) {
          addNotification('success', intl.formatMessage({ id: 'fan_connect.notification.create.success' }))
        } else {
          addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
        }
      },
      onError: () => addNotification('error', intl.formatMessage({ id: 'notification.general_error' })),
    })
  }, [addNotification, changes, event?.id, values, intl, message, send])

  const initStep = useMemo(
    () => (
      <ModalBody>
        <Form>
          <FormRow>
            <Plate
              icon="empty-state_email"
              title={intl.formatMessage({ id: 'change_event_notification.start.header' })}
              description={intl.formatMessage({ id: 'change_event_notification.start.description' })}
            />
          </FormRow>
          <ButtonWrapper>
            <Button preset="secondary" onClick={reject}>
              {intl.formatMessage({ id: 'change_event_notification.actions.save_and_exit' })}
            </Button>
            <Button onClick={showForm}>
              {intl.formatMessage({ id: 'change_event_notification.actions.draft_email' })}
            </Button>
          </ButtonWrapper>
        </Form>
      </ModalBody>
    ),
    [intl, reject, showForm]
  )

  const completeStep = useMemo(
    () => (
      <ModalBody>
        <Form>
          <FormRow>
            <Plate
              icon="tick"
              successIcon
              title={intl.formatMessage({ id: 'change_event_notification.finish.header' }, { eventName: event.name })}
              description={intl.formatMessage({ id: 'change_event_notification.finish.description' })}
            />
          </FormRow>
          <ButtonWrapper>
            <Button onClick={onClose}>{intl.formatMessage({ id: 'actions.close' })}</Button>
          </ButtonWrapper>
        </Form>
      </ModalBody>
    ),
    [event.name, intl, onClose]
  )

  const formattedInfo = useMemo(() => {
    const isRescheduled = includes('schedule_status', changes)
    const autoRefundsEnabled = get('flags.autoRescheduledEventRefunds.active', values)
    let cutoffDate = null
    if (isRescheduled && autoRefundsEnabled) {
      const scheduleStatusUpdatedAt = get('scheduleStatusUpdatedAt', values)
      const cutoffDays = get('flags.autoRescheduledEventRefunds.cutoff_days', values)
      const referenceDate = scheduleStatusUpdatedAt ? new Date(scheduleStatusUpdatedAt) : new Date()
      const twentyFourHoursBeforeEvent = subDays(new Date(values.date!), 1)

      if (cutoffDays) {
        cutoffDate = addDays(referenceDate, Number(cutoffDays))
      }
      if (!cutoffDate || isAfter(cutoffDate, twentyFourHoursBeforeEvent)) {
        cutoffDate = twentyFourHoursBeforeEvent
      }

      cutoffDate = formatISO(cutoffDate)
    }

    const scheduleStatusMessageKey = (() => {
      if (isRescheduled && !!values.scheduleStatus) {
        if (values.scheduleStatus === 'RESCHEDULED') {
          return values.scheduleStatus.toLowerCase().concat(autoRefundsEnabled ? '_with_auto_refund' : '')
        } else {
          return values.scheduleStatus.toLowerCase()
        }
      }
      return 'general'
    })()

    return {
      subject: intl.formatMessage({
        id: `change_event_notification.type.${
          isRescheduled && !!values.scheduleStatus ? values.scheduleStatus?.toLowerCase() : 'general'
        }.subject`,
      }),
      message: intl.formatMessage(
        {
          id: `change_event_notification.type.${scheduleStatusMessageKey}.message`,
        },
        {
          eventName: values.name,
          eventDate: intl.formatDate(parseISO(values.date || ''), {
            ...DATETIME_FORMATS.LONG(locale),
            timeZone: event.timezoneName || undefined,
          }),
          cutoffDate: intl.formatDate(parseISO(cutoffDate || ''), {
            ...DATETIME_FORMATS.LONG(locale),
            timeZone: event.timezoneName || undefined,
          }),
        }
      ),
    }
  }, [changes, event.timezoneName, intl, locale, values])

  if (currentStep === 'REJECT') {
    return (
      <ConfirmationModal
        icon="alert"
        title={intl.formatMessage({ id: 'change_event_notification.confirmation.header' })}
        description={intl.formatMessage({ id: 'change_event_notification.confirmation.description' })}
        cta={intl.formatMessage({ id: 'change_event_notification.actions.confirm_save_and_exit' })}
        rejectCta={intl.formatMessage({ id: 'change_event_notification.actions.draft_email' })}
        onConfirm={saveWithoutNotification}
        onReject={showForm}
      />
    )
  }

  return (
    <StyledModal
      centered={includes(currentStep, ['INIT', 'COMPLETE'])}
      size="fullscreen"
      closeButton
      onClose={currentStep === 'COMPLETE' ? onClose : reject}
      modalTitle={intl.formatMessage({ id: 'change_event_notification.modal_header' })}
    >
      {currentStep === 'INIT' && initStep}
      {currentStep === 'COMPLETE' && completeStep}
      {currentStep === 'FORM' && (
        <>
          <ModalBody>
            <Form>
              <FormRow>
                <h4 className="mb-zero">
                  {intl.formatMessage({ id: 'change_event_notification.whats_changed' }, { eventName: event.name })}
                </h4>
              </FormRow>
              <FormRow>
                <ChangeList>
                  {formattedChanges.map(
                    (item) =>
                      item && (
                        <li key={item.name}>
                          <Badge>
                            {intl.formatMessage({ id: `change_event_notification.changed_field.${item?.name}` })}
                          </Badge>
                          <div>
                            <s>{item.oldValue}</s>
                            <span>{item.newValue}</span>
                          </div>
                        </li>
                      )
                  )}
                </ChangeList>
              </FormRow>
              <FormRow className="mt-xlg">
                <h4 className="mb-zero">{intl.formatMessage({ id: 'change_event_notification.email_details' })}</h4>
              </FormRow>
              <FormRow>
                <FormField
                  label={intl.formatMessage({ id: 'change_event_notification.form.subject' })}
                  value={formattedInfo.subject}
                  disabled
                />
              </FormRow>
              <FormRow>
                <TextArea
                  label={intl.formatMessage({ id: 'change_event_notification.form.message' })}
                  value={formattedInfo.message}
                  disabled
                  multiline
                />
              </FormRow>
              <Collapsible label={intl.formatMessage({ id: 'change_event_notification.form.addition_comment' })}>
                <FormRow>
                  <TextArea
                    name="message"
                    placeholder={intl.formatMessage({
                      id: 'change_event_notification.form.addition_comment.placeholder',
                    })}
                    value={message}
                    onChange={handleChange}
                    multiline
                  >
                    <Counter maxCount={160} count={(message || '').length}>
                      {(message || '').length}/{160}
                    </Counter>
                  </TextArea>
                </FormRow>
              </Collapsible>
              <SendTest>
                <SendTestDescription>
                  <Svg icon="bulb" />
                  <span>{intl.formatMessage({ id: 'change_event_notification.form.send_test.description' })}</span>
                </SendTestDescription>
                <Button loading={sending} preset="link" onClick={sendTest} color="primary">
                  {intl.formatMessage({ id: 'change_event_notification.form.send_test.action_send' })}
                </Button>
              </SendTest>
              <Divider />
              <FormRow>
                <Checkbox
                  name="sendMeACopy"
                  checked={sendMeACopy}
                  onChange={handleSendMeACopyChange}
                  label={intl.formatMessage({ id: 'change_event_notification.form.send_me_a_copy' })}
                />
              </FormRow>
              <FormRow>
                <Checkbox
                  name="agreementSigned"
                  label={intl.formatMessage(
                    { id: 'change_event_notification.agreement' },
                    { b: (str: string) => <strong>{str}</strong> }
                  )}
                  checked={agreementSigned}
                  onChange={handleSignAgreement}
                />
              </FormRow>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Form>
              <ModalFooterControl
                data-id="save"
                onClick={saveAndNotify}
                loading={loading}
                disabled={!agreementSigned || message.length > 160}
              >
                {intl.formatMessage({ id: 'change_event_notification.actions.save_and_send' })}
              </ModalFooterControl>
            </Form>
          </ModalFooter>
        </>
      )}
    </StyledModal>
  )
}

export default memo(ChangeEventNotificationModal)
