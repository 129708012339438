import { useCallback, useContext, useState } from 'react'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { DeepPartial } from 'ts-essentials'
import { compose, get, map, reject, set, toPairs, uniqBy } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'

import IEventFormTickets, { IFee, ITicketType } from '../types/Tickets'
import { useSeatedTicketTypesGenerateQuery } from '../../../__generated__/useSeatedTicketTypesGenerateQuery.graphql'
import { notificationContext } from '../../../context/notification'
import { getDefaultTicketType } from '../services/getDefaultEvent'
import IEventForm from '../types'
import { markAsClientOnly } from '../../../utils/entityStatus'
import { countSeats, iconForSeats } from '../../../utils/seats'
import { localeContext } from '../../../context/locale'

function useSeatedTicketTypes(): [(eventSeatingChartId?: string) => void, boolean] {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const { values, setFieldValue } = useFormikContext<IEventFormTickets>()

  const [generating, setGenerating] = useState(false)
  const generateSeatedTicketTypes = useCallback(
    (eventSeatingChartId?: string) => {
      const id =
        eventSeatingChartId && typeof eventSeatingChartId === 'string'
          ? eventSeatingChartId
          : values.eventSeatingChart?.id

      if (!id) return

      setGenerating(true)

      const promise: Promise<useSeatedTicketTypesGenerateQuery['response']> =
        fetchQuery_DEPRECATED<useSeatedTicketTypesGenerateQuery>(
          environment,
          graphql`
            query useSeatedTicketTypesGenerateQuery($id: ID!, $eventId: ID) {
              viewer {
                account {
                  allowedAdhocFees(eventId: $eventId) {
                    type
                    unit
                    amount
                    split {
                      destination
                      amount
                      unit
                    }
                  }
                }
              }
              seatingChart: node(id: $id) {
                ... on EventSeatingChart {
                  seatsIoEventReport(group: BY_CATEGORY_KEY)
                }
              }
            }
          `,
          {
            id,
            eventId: values.id,
          }
        )

      promise
        .then((data) => {
          const allowedAdhocFees = (get('viewer.account.allowedAdhocFees', data) || []) as IFee[]
          const report: any = data.seatingChart?.seatsIoEventReport
          if (!report) return

          const newTTYs: ITicketType[] = compose([
            map(markAsClientOnly),
            map(([catKey, seats]) =>
              compose([
                set('name', seats[0]?.categoryLabel || catKey),
                set('allocation', countSeats(seats)),
                set('icon', iconForSeats(seats)),
                set('allowSeatChange', values.flags?.seated?.active || false),
                set('reservedSeating', true),
                set('reservedSeatingType', 'assignBestSeat'),
                set(
                  'seatCategories',
                  compose([
                    map(markAsClientOnly),
                    map((s: any) => ({
                      name: s.categoryLabel,
                      seatsIoKey: s.categoryKey,
                    })),
                    uniqBy('category'),
                  ])(seats || [])
                ),
              ])(getDefaultTicketType(values as DeepPartial<IEventForm>, intl, allowedAdhocFees, locale))
            ),
            reject(([catKey, seats]) => catKey === 'NO_CATEGORY' || seats.length === 0),
            toPairs,
          ])(report)

          setFieldValue('ticketTypes', newTTYs)
          setFieldValue('ticketPools', [])

          if (newTTYs.length === 0) {
            addNotification('error', intl.formatMessage({ id: 'seated_ticket_types.generate_empty_warn' }))
          } else {
            addNotification('success', intl.formatMessage({ id: 'seated_ticket_types.generate_ok' }))
          }
        })
        .catch((e) => {
          console.error(e)
          addNotification('error', e.message)
        })
        .finally(() => {
          setGenerating(false)
        })
    },
    [addNotification, environment, intl, locale, setFieldValue, values]
  )

  return [generateSeatedTicketTypes, generating]
}

export default useSeatedTicketTypes
