import { isInteger } from 'lodash/fp'
import { FormatNumberOptions } from 'react-intl'
import { Dictionary } from 'ts-essentials'
import { EventCostCurrency } from '../../enums.generated'
import { ILocale } from '../../intl'

const WIDE_BY_DEAFULT = new Set(['CAD', 'AUD'])

const LOCALE_NARROW_SYMBOLS: Partial<Dictionary<EventCostCurrency, ILocale>> = {
  'en-CA': 'CAD',
}

const LOCALE_WIDE_SYMBOLS: Partial<Dictionary<EventCostCurrency, ILocale>> = {
  'en-CA': 'USD',
}

export const CURRENCY = (
  amountCents: number | null | undefined,
  currency: EventCostCurrency | '_ANY_' | null | undefined,
  locale?: ILocale
): FormatNumberOptions => {
  const effectiveLocale = locale || window.__currentLocale

  let currencyDisplay: FormatNumberOptions['currencyDisplay'] =
    currency && WIDE_BY_DEAFULT.has(currency) ? 'symbol' : 'narrowSymbol'

  if (effectiveLocale && currency && LOCALE_NARROW_SYMBOLS[effectiveLocale] === currency) {
    currencyDisplay = 'narrowSymbol'
  }

  if (effectiveLocale && currency && LOCALE_WIDE_SYMBOLS[effectiveLocale] === currency) {
    currencyDisplay = 'symbol'
  }

  const digits = isInteger((amountCents || 0) / 100) ? 0 : 2
  const sign = Math.abs(amountCents || 0) === 0 ? 'exceptZero' : 'auto'

  // prettier-ignore
  const currencyOptions: FormatNumberOptions =
    currency === '_ANY_'
      ? {}
      : {
        style: 'currency',
        currency: currency || 'GBP',
        currencyDisplay,
      }

  return {
    minimumFractionDigits: digits,
    maximumFractionDigits: 2,
    signDisplay: sign,
    ...currencyOptions,
  }
}

export const PERCENT_FRACTION = (percents: number | null | undefined): FormatNumberOptions => {
  const digits = isInteger(percents) ? 0 : 2
  const sign = Math.abs(percents || 0) === 0 ? 'exceptZero' : 'auto'

  return {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: 2,
    signDisplay: sign,
  }
}

export const SHORT: FormatNumberOptions = {
  notation: 'compact',
  maximumFractionDigits: 1,
  compactDisplay: 'short',
}
