import React, { useState, useCallback, FC, memo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { Loader } from '../../../components/Loader'
import CircleProgress from '../../../components/CircleProgress'
import EventCardValue from '../../../components/Event/EventCardValue'
import { Dropdown, DropdownTrigger, DropdownContent } from '../../../components/Dropdown'

import EventPartSalesBreakdown from './EventPartSalesBreakdown'
import { EventPartSales_event$key } from '../../../__generated__/EventPartSales_event.graphql'

const SalesWrapper = styled.div`
  display: inline-block;
  margin: 0;
`

const DropdownContentBreakdown = styled(DropdownContent)`
  left: -16px;
  right: auto;
`

interface IProps {
  event: EventPartSales_event$key | null
}

const EventPartSales: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const event = useFragment(
    graphql`
      fragment EventPartSales_event on Event {
        id
        allocation
        soldToday
        sales {
          totalAppSold
          totalPosSold
          totalWlRequests
        }
      }
    `,
    eventKey
  )

  const clickOutside = useCallback(() => open && setOpen(false), [open])
  const toggleOpen = useCallback(() => setOpen(!open), [open])
  const preventClick = useCallback((e: any) => e.stopPropagation(), [])

  if (!event) {
    return <Loader />
  }

  const sold = (event?.sales?.totalAppSold || 0) + (event?.sales?.totalPosSold || 0)
  const allocation = event?.allocation

  const soldPercentage = allocation === 0 ? 0 : (sold * 100) / allocation
  const wlRequests = event.sales?.totalWlRequests || 0
  const showWL = soldPercentage === 100 && wlRequests > 0

  return (
    <SalesWrapper onClick={preventClick}>
      <Dropdown active={open} onClickOutside={clickOutside}>
        <DropdownTrigger onClick={toggleOpen}>
          <div className="flex flex-align-center">
            <CircleProgress
              percentage={soldPercentage}
              value={showWL ? wlRequests : undefined}
              title={showWL ? intl.formatMessage({ id: 'on_waiting_list' }, { num: wlRequests }) : undefined}
            />
            <EventCardValue
              primary={sold + '/' + allocation}
              secondary={
                event.soldToday > 0 ? intl.formatMessage({ id: 'sales.sold_today' }, { amount: event.soldToday }) : null
              }
            />
          </div>
        </DropdownTrigger>
        {open && (
          <DropdownContentBreakdown active>
            <EventPartSalesBreakdown eventId={event.id} />
          </DropdownContentBreakdown>
        )}
      </Dropdown>
    </SalesWrapper>
  )
}

export default memo(EventPartSales)
