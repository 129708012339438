/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  isEqual,
  addDays,
  subDays,
  differenceInDays,
  startOfDay,
  max,
  compareAsc,
  parseISO,
  formatISO,
  subMilliseconds,
  addMilliseconds,
  differenceInSeconds,
  startOfWeek,
  startOfMinute,
  isValid,
  set,
  differenceInMinutes,
  addMinutes,
  startOfHour,
} from 'date-fns'
import { getTimezoneOffset, utcToZonedTime, format as formatTZ } from 'date-fns-tz'
import {
  keys,
  map,
  sortBy,
  compose,
  reduce,
  toPairs,
  groupBy,
  unset,
  isArray,
  unzip,
  zipAll,
  minBy,
  concat,
  find,
  fromPairs,
  compact,
  values,
  filter,
  orderBy,
  reject,
} from 'lodash/fp'
import { IntlShape } from 'react-intl'
import * as chrono from 'chrono-node'
import { Dictionary } from 'ts-essentials'
import timezones, { Timezone } from 'countries-and-timezones'

import { chronoLocales, DEFAULT_LOCALE, ILocale } from '../intl'

export const ISO_DATE_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3,10})?(Z|[+-]\d{2}:\d{2})/

export const TIME_REGEX = /^$|\d{1,2}:\d{2}( am| pm)?/i

export const truncate = (d: string) => d.substring(0, d.indexOf('T'))

export const normalize = <T extends { t: Date; y: number; fake?: boolean }>(
  dataset: Array<T | T[]>,
  startDate: Date,
  endDate: Date,
  dontTruncatePeriod?: boolean
) => {
  if (dataset.length === 0) return []

  const results: Array<T[]> = []
  let subsets: Array<T[]>
  if (isArray(dataset[0])) {
    subsets = unzip(dataset as Array<T[]>)
  } else {
    subsets = [dataset as Array<T>]
  }

  subsets.forEach((subset, sidx) => {
    let prevValue = subDays(startDate, 1)
    let prevObj = {}

    sortBy('t', subset).forEach((it) => {
      if (!results[sidx]) {
        results[sidx] = []
      }

      if (dontTruncatePeriod || (it.t >= startDate && it.t <= endDate)) {
        const diff = differenceInDays(it.t, startOfDay(prevValue))

        for (let i = 1; i < diff; i++) {
          results[sidx].push({
            t: addDays(startOfDay(prevValue), i),
            y: 0,
          } as T)
        }

        results[sidx].push(it)
        prevValue = it.t
        prevObj = it
      }
    })

    const diff = differenceInDays(endDate, prevValue)

    for (let i = 1; i <= diff; i++) {
      results[sidx].push({
        t: startOfDay(addDays(prevValue, i)),
        y: 0,
        fake: true,
      } as T)
    }

    if (differenceInSeconds(endDate, prevValue) > 0) {
      results[sidx].push({
        ...(diff === 0 ? prevObj : {}),
        t: endDate,
        y: 0,
        fake: true,
      } as T)
    }

    if (results[sidx].length > 0 && !isEqual(results[sidx][0].t, startOfDay(results[sidx][0].t))) {
      results[sidx].unshift({ t: startOfDay(results[sidx][0].t), y: 0, fake: true } as T)
    }
  })

  return isArray(dataset[0]) ? zipAll(results) : results[0]
}

// ts-prune-ignore-next
export const calculatePeriodGaps = (bounds: Date[], rawPeriods: Array<Date | null>[]): Date[][] => {
  const periods = map((p) => [p[0] || bounds[0], p[1] || bounds[1]], rawPeriods)

  const mergePeriod = (ac: Date[][], x: Date[]) => {
    if (!ac.length || ac[ac.length - 1][1] < x[0]) {
      ac.push([...x])
    } else {
      ac[ac.length - 1][1] = max([ac[ac.length - 1][1], x[1]])
    }
    return ac
  }

  const merged = compose([sortBy([0]), reduce(mergePeriod, [] as Date[][]), sortBy([0])])(periods)

  const gaps = []

  if (merged.length > 0) {
    if (!isEqual(merged[0][0], bounds[0]) && bounds[0] < merged[0][0]) {
      gaps.push([merged[0][0], bounds[0]].sort(compareAsc))
    }

    for (let i = 1; i < merged.length; i++) {
      const a = merged[i][0]
      const b = merged[i - 1][1]
      if (!(isEqual(a, b) || b > a)) {
        gaps.push([a, b].sort(compareAsc))
      }
    }

    if (!isEqual(merged[merged.length - 1][1], bounds[1]) && bounds[1] > merged[merged.length - 1][1]) {
      gaps.push([merged[merged.length - 1][1], bounds[1]].sort(compareAsc))
    }
  }

  return gaps
}

export const isValidDate = (date: Date | null) => {
  if (!date) return true
  try {
    return (
      isValid(date) &&
      isValid(parseISO(formatISO(date))) &&
      date.getUTCFullYear() >= 1900 &&
      date.getUTCFullYear() <= 3000
    )
  } catch (e) {
    console.error(e)
    return false
  }
}

export const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const parseAtTimezone = (isoStr?: string | null, timezone?: string | null): Date | null => {
  if (!isoStr) return null

  const fakeDate = parseISO(isoStr)

  if (!timezone) return fakeDate

  if (!isValidDate(fakeDate)) return null

  /* we need to do % 24hour here because of weird Chrome behavior which is not taken into account */
  /* by date-fns-tz resulting in timezone offsets of f by 24 hours */
  /* https://bugs.chromium.org/p/chromium/issues/detail?id=1025564&can=2&q=%2224%3A00%22%20datetimeformat */
  /* Example of same fix in Luxon (https://github.com/moment/luxon/issues/619) */
  /* ideally this need to be appllied to date-fns-tz */

  const myOffsetMilliseconds = -getTimezoneOffset(currentTimezone, fakeDate) % 86400000
  const offsetMilliseconds = timezone ? -getTimezoneOffset(timezone, fakeDate) % 86400000 : myOffsetMilliseconds

  const resultDate = addMilliseconds(fakeDate, myOffsetMilliseconds - offsetMilliseconds)

  if (!isValidDate(resultDate)) return null

  return resultDate
}

export const formatAtTimezone = (fakeDate?: Date | null, timezone?: string | null): string | null => {
  if (!fakeDate || !isValidDate(fakeDate)) return null
  if (!timezone) return formatISO(fakeDate)

  const myOffsetMilliseconds = -getTimezoneOffset(currentTimezone, fakeDate) % 86400000
  const offsetMilliseconds = timezone ? -getTimezoneOffset(timezone, fakeDate) % 86400000 : myOffsetMilliseconds

  const resultDate = subMilliseconds(fakeDate, myOffsetMilliseconds - offsetMilliseconds)

  if (!isValidDate(resultDate)) return null

  const isoStr = resultDate.toISOString()

  if (!isValidDate(parseISO(isoStr))) return null

  return isoStr
}

const sumAllFields = (arr: any[]): any => {
  const acc = {} as any
  arr.forEach((el) => {
    keys(el).forEach((k) => {
      acc[k] = (acc[k] ? acc[k] + el[k] : el[k]) || 0
    })
  })
  return acc
}

export const aggregateByWeeks =
  (weekStartsOn: 0 | 1) =>
  <T extends { t: Date; fake?: boolean }, E extends T | T[]>(arr: Array<E>): Array<E> => {
    const res = compose([
      map(([t, vals]: [string, E[]]) => {
        const arrVals = unzip(map(concat([] as E[]), vals))
        const mapped = map(
          (arr) => ({
            ...sumAllFields(map(unset('t'), reject('fake', arr))),
            t: parseISO(t),
          }),
          arrVals
        )
        return mapped.length === 1 ? mapped[0] : mapped
      }),
      toPairs,
      groupBy((it: E) => {
        const minT = minBy('t', concat([], it))?.t
        return minT && formatISO(startOfWeek(minT, { weekStartsOn }))
      }),
    ])(arr)

    return res
  }

export function prepareDatePair(
  localDate: Date | null,
  serverDate: string | null | undefined,
  timezone: string | undefined | null,
  mode: 'date' | 'time'
) {
  if (!localDate || !serverDate) return null

  const parseFn = timezone ? (dt: string) => parseAtTimezone(dt, timezone)! : parseISO

  const A = mode === 'time' ? localDate : startOfDay(localDate)
  const B = mode === 'time' ? parseFn(serverDate) : startOfDay(parseFn(serverDate))

  return [A, B]
}

export type IDatePredicate = (localDate: Date | null, mode: 'date' | 'time') => boolean

// prettier-ignore
export const gtDate =
  (serverDate: string | null | undefined, timezone: string | undefined | null): IDatePredicate =>
    (localDate: Date | null, mode: 'date' | 'time'): boolean => {
      const pair = prepareDatePair(localDate, serverDate, timezone, mode)

      return pair ? startOfMinute(pair[0]) > startOfMinute(pair[1]) : false
    }

// prettier-ignore
export const ltDate =
  (serverDate: string | null | undefined, timezone: string | undefined | null): IDatePredicate =>
    (localDate: Date | null, mode: 'date' | 'time'): boolean => {
      const pair = prepareDatePair(localDate, serverDate, timezone, mode)

      return pair ? startOfMinute(pair[0]) < startOfMinute(pair[1]) : false
    }

export const futureDate: IDatePredicate = (localDate: Date | null, mode: 'date' | 'time'): boolean => {
  if (!localDate) return false
  const now = new Date()
  const A = mode === 'time' ? localDate : startOfDay(localDate)
  const B = mode === 'time' ? now : startOfDay(now)
  return A > B
}

export const pastDate: IDatePredicate = (localDate: Date | null, mode: 'date' | 'time'): boolean => {
  if (!localDate) return false
  const now = new Date()
  const A = mode === 'time' ? localDate : startOfDay(localDate)
  const B = mode === 'time' ? now : startOfDay(now)
  return A < B
}

export const getOffsetString = (intl: IntlShape, offset: number) => {
  const hoursOffset = offset / 3_600_000

  const hh = intl.formatNumber(Math.floor(hoursOffset), {
    minimumIntegerDigits: 2,
  })

  const mm = intl.formatNumber(60 * (hoursOffset - Math.floor(hoursOffset)), { minimumIntegerDigits: 2 })

  return `${offset >= 0 ? '+' : ''}${hh}:${mm}`
}

export const getTimezoneOption = (intl: IntlShape, timezoneName: string | null, refDate?: Date | null) => {
  if (!timezoneName) return null

  try {
    const refDateSafe = refDate && isValidDate(refDate) ? refDate : new Date()

    const tzName = find(
      ['type', 'timeZoneName'],
      intl.formatDateToParts(refDateSafe, { timeZone: timezoneName, timeZoneName: 'long' })
    )?.value

    const offset = 0 - (-getTimezoneOffset(timezoneName, refDateSafe) % 86400000)

    const offsetStr = getOffsetString(intl, offset)

    return {
      value: timezoneName,
      label: `(GMT${offsetStr}) ${tzName}`,
      offset,
      offsetStr,
    }
  } catch (e) {
    console.error(e)

    return {
      value: timezoneName,
      label: `(????) ${timezoneName}`,
      offset: 0,
    }
  }
}

const UNIQUE_TZ_LIST: Timezone[] = compose([
  orderBy(
    [
      (tz: Timezone) => tz.utcOffset,
      (tz: Timezone) => {
        if (tz.name.indexOf('UTC') >= 0) return -Infinity
        if (tz.name.startsWith('Europe')) return 0
        if (tz.name.startsWith('America')) return 1
        if (tz.name.startsWith('Asia')) return 2
        if (tz.name.startsWith('Australia')) return 3
        if (tz.name.startsWith('Pacific')) return 4
        if (tz.name.startsWith('Africa')) return 5
        if (tz.name.startsWith('Etc')) return 6
        return Infinity
      },
    ],
    ['asc', 'asc']
  ),
  sortBy((tz: Timezone) => tz.name.split('/')[1]),
  filter(
    (tz: Timezone) =>
      !tz.aliasOf &&
      !(tz.name.indexOf('Antarctica') >= 0) &&
      !(tz.name.indexOf('Etc') >= 0) &&
      tz.name.indexOf('/') >= 0 &&
      tz.name.indexOf('/') === tz.name.lastIndexOf('/')
  ),
  values,
])(timezones.getAllTimezones())

export const getTimezoneOptions = (intl: IntlShape, refDate?: Date | null) => {
  const options = compose([
    sortBy('offset'),
    compact,
    map((tz: Timezone) => getTimezoneOption(intl, tz.name, refDate)),
  ])(UNIQUE_TZ_LIST)

  return options
}

const FREEFORM_COMPONENTS: chrono.Component[] = ['year', 'month', 'day', 'hour', 'minute', 'second', 'millisecond']

export const parseFreeformDate = (str: string, locale?: ILocale | null | undefined): Date | null => {
  const localChrono = chronoLocales[locale || DEFAULT_LOCALE]
  const defaultChrono = chronoLocales[DEFAULT_LOCALE]

  const ref = new Date()
  const opts = { forwardDate: true }

  let parsed = localChrono.parse(str, ref, opts)
  if (parsed.length === 0) {
    parsed = defaultChrono.parse(str, ref, opts)
  }

  let dt: Date | null = null

  if (parsed.length > 0) {
    const r = parsed[0]
    const d = r.refDate ? new Date(r.refDate) : new Date()

    const patch = fromPairs(
      FREEFORM_COMPONENTS.map((p, idx) => {
        const implied = !r.start.isCertain(p)
        const defaultTime = p === 'hour' ? 12 : 0
        const v = !implied || idx < 3 ? r.start.get(p) : defaultTime
        return [p, v]
      })
    ) as Dictionary<number, chrono.Component>

    dt = set(d, {
      year: patch.year,
      month: patch.month - 1,
      date: patch.day,
      hours: patch.hour,
      minutes: patch.minute,
      seconds: patch.second,
      milliseconds: patch.millisecond,
    })
  }

  if (dt && isValidDate(dt) && Math.abs(differenceInMinutes(new Date(), dt)) <= 5) {
    const mins = differenceInMinutes(dt, startOfHour(dt))
    const minsRounded = Math.ceil(mins / 15) * 15
    dt = addMinutes(startOfHour(dt), minsRounded)
  }

  return dt && isValidDate(dt) ? dt : null
}

export const getTimezoneAbbreviation = (date: string, timeZone: string) =>
  formatTZ(utcToZonedTime(date, timeZone), 'zzz', { timeZone })
