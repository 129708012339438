import { useEffect, useState } from 'react'
import { compact } from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'
import getProductPriceBreakdown from '../../../utils/getProductPriceBreakdown'
import { IPriceBreakdown } from '../../../utils/getPriceBreakdown'

export type IFeeContractPreview = NonNullable<IPriceBreakdown['breakdown'][number]>[]

interface IFeeContractPreviewResult {
  loading: boolean
  contract: IFeeContractPreview | null | undefined
}

function useProductFeeContractPreview(
  billingPromoterId: string | null,
  eventId: string | null,
  disableUsTax: boolean,
  categoryId: string | null,
  faceValue: number | null,
  venueId: string | null
): IFeeContractPreviewResult {
  const environment = useRelayEnvironment()

  const [loading, setLoading] = useState(false)

  const [contract, setContract] = useState(null)

  useEffect(() => {
    let stillMounted = true
    setLoading(true)

    getProductPriceBreakdown(environment, {
      billingPromoterId,
      eventId: eventId || null,
      fees: null,
      basePriceFees: null,
      postFanPriceFees: null,
      disableUsTax: !!disableUsTax,
      categoryId,
      faceValue,
      venueId,
    })
      .then((result: any) => {
        if (stillMounted) {
          setContract(result?.breakdown && compact(result?.breakdown))
        }
      })
      .finally(() => {
        if (stillMounted) {
          setLoading(false)
        }
      })

    return () => {
      stillMounted = false
    }
  }, [billingPromoterId, categoryId, disableUsTax, environment, eventId, faceValue, venueId])

  return {
    loading,
    contract,
  }
}

export default useProductFeeContractPreview
