import React, { FC, Fragment, memo, useCallback, useMemo, useState, Suspense } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { isPast, parseISO } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import { breakpoints } from '../../../../utils/variables'
import { CURRENCY } from '../../../../utils/formatters/number'

import { Text as RealText } from '../../../../components/Text'
import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../components/Dropdown'
import IconButton from '../../../../components/IconButton'
import { Menu, MenuItem } from '../../../../components/Menu'
import { PriceTier, PriceTierCol, PriceTiers, PriceTiersBreakdown, PriceTierName } from './TicketBreakdownStyles'

import { Loader } from '../../../../components/Loader'
import ChangeProductAllocationModal from './ChangeProductAllocationModal'
import { ProductsBreakdown_event$data } from '../../../../__generated__/ProductsBreakdown_event.graphql'

const PTName = styled(PriceTierName)`
  display: flex;
`

const Additional = styled.div`
  position: absolute;
  right: 0;
`

interface IProps {
  event: ProductsBreakdown_event$data
  variantsBreakdown: NonNullable<
    NonNullable<NonNullable<ProductsBreakdown_event$data['productsSales']>['productBreakdown']>[number]
  >['variantBreakdown']
  isReadOnly: boolean
}

const ProductVariantsBreakdown: FC<React.PropsWithChildren<IProps>> = ({ event, variantsBreakdown, isReadOnly }) => {
  const intl = useIntl()

  const [dropdownId, setDropdownId] = useState<string | null>(null)
  const closeDropdown = useCallback(() => setDropdownId(null), [setDropdownId])
  const toggleDropdown = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      if (id === dropdownId) {
        closeDropdown()
      } else {
        setDropdownId(id)
      }
    },
    [closeDropdown, dropdownId, setDropdownId]
  )

  const [allocationId, setAllocationId] = useState<string | null>(null)
  const closeAllocation = useCallback(() => setAllocationId(null), [])
  const doChangeAllocation = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      if (!id) return

      closeDropdown()
      setAllocationId(id)
    },
    [closeDropdown]
  )

  const isOffSale = useMemo(
    () => (event.offSaleDate ? isPast(parseISO(event.offSaleDate)) : false),
    [event.offSaleDate]
  )

  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.desktop}px)` })
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const Actions: FC<React.PropsWithChildren<{ breakdown: any }>> = useMemo(
    () =>
      ({ breakdown }) => {
        const shouldShowChangeAllocationItem = !isReadOnly

        if (!shouldShowChangeAllocationItem) return null

        return (
          <Additional>
            <Dropdown active={dropdownId === breakdown.variant.id} onClickOutside={closeDropdown}>
              <DropdownTrigger data-id={breakdown.variant.id} data-name="openMenuPriceTier" onClick={toggleDropdown}>
                <IconButton icon="more" color={isMobile && 'transparent'} />
              </DropdownTrigger>
              <DropdownContent active={dropdownId === breakdown.variant.id}>
                <Menu>
                  {shouldShowChangeAllocationItem && (
                    <MenuItem
                      data-id={breakdown.variant.id}
                      data-name="menuChangeAllocationPriceTier"
                      onClick={doChangeAllocation}
                      disabled={isOffSale}
                    >
                      {intl.formatMessage({
                        id: 'event_overview.ticket_breakdown.actions.change_allocation',
                      })}
                    </MenuItem>
                  )}
                </Menu>
              </DropdownContent>
            </Dropdown>
          </Additional>
        )
      },
    [isReadOnly, dropdownId, closeDropdown, toggleDropdown, isMobile, doChangeAllocation, isOffSale, intl]
  )

  return (
    <>
      {variantsBreakdown && variantsBreakdown.length > 0 && (
        <PriceTiersBreakdown>
          <PriceTiers>
            {variantsBreakdown.map(
              (breakdown) =>
                breakdown && (
                  <Fragment key={breakdown.variant?.id}>
                    <PriceTier minHeight>
                      <PriceTierCol>
                        <PTName data-variant={breakdown.variant?.id}>
                          <span className={isMobile ? 'bold' : ''}>{breakdown.variant?.name}</span>
                        </PTName>
                      </PriceTierCol>
                      <PriceTierCol justify="space-between" nowrap>
                        <span>
                          {intl.formatNumber(breakdown.totalSold || 0, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                          {' / '}
                          {intl.formatNumber(breakdown.variant?.allocation || 0, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                        {!isTablet && (
                          <>
                            &nbsp;
                            <RealText fontSize="sm" color="darkgrey">
                              {(breakdown.variant?.allocation || 0) - (breakdown.totalSold || 0)}{' '}
                              {intl.formatMessage({ id: 'event_overview.ticket_breakdown.tickets_remaining' })}
                            </RealText>
                          </>
                        )}
                      </PriceTierCol>
                      <PriceTierCol>
                        {intl.formatNumber(
                          (breakdown.totalDigitalValue || 0) / 100,
                          CURRENCY(breakdown.totalDigitalValue || 0, event.costCurrency)
                        )}

                        <Actions breakdown={breakdown} />

                        <Suspense fallback={!isMobile ? <Loader className="ml-auto mr-lg" /> : null}>
                          {allocationId === breakdown.variant?.id && (
                            <ChangeProductAllocationModal
                              eventId={event.id}
                              onClose={closeAllocation}
                              preSelectProductId={breakdown.variant?.product.id}
                              preSelectProductVariantId={allocationId}
                            />
                          )}
                        </Suspense>
                      </PriceTierCol>
                    </PriceTier>
                  </Fragment>
                )
            )}
          </PriceTiers>
        </PriceTiersBreakdown>
      )}
    </>
  )
}

export default memo(ProductVariantsBreakdown)
