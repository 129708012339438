interface IArtist {
  profileImageAttachment: {
    cdnUrl: string
  } | null
  profileImageCropRegion: null | {
    x: number | null
    y: number | null
    width: number | null
    height: number | null
  }
}

const artistImage = (artist: IArtist, size?: number) => {
  let url = artist.profileImageAttachment?.cdnUrl
  const cropRegion = artist.profileImageCropRegion

  if (url) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const parsed = new URL(url!)
      parsed.searchParams.set('w', String(size || 100))
      parsed.searchParams.set('h', String(size || 100))
      parsed.searchParams.set('auto', 'compress')
      parsed.searchParams.set('fit', 'crop')

      if (!parsed.searchParams.get('rect') && !!cropRegion && !!url) {
        parsed.searchParams.append(
          'rect',
          [cropRegion?.x || 0, cropRegion?.y || 0, cropRegion?.width || 0, cropRegion?.height || 0].join(',')
        )
      }

      url = parsed.toString()
    } catch (e) {
      console.error(e)
    }
  }
  return url
}

export default artistImage
