import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormik, yupToFormErrors } from 'formik'
import { object, number } from 'yup'
import { find, map, get, isNil, filter, flow, compact, reject, reduce } from 'lodash/fp'
import { nanoid } from 'nanoid'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { notificationContext } from '../../../../context/notification'

import { REGEX_INTEGER } from '../../../../utils/regex'

import { Form, FormRow } from '../../../../components/Form'
import FormField from '../../../../components/FormField'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'

import { allowedEventAction } from '../../../EventForm/services/allowedEventAction'

import {
  ChangePoolCapacityModalQuery,
  ChangePoolCapacityModalQuery$data,
} from '../../../../__generated__/ChangePoolCapacityModalQuery.graphql'
import FormSalesProgress from '../../../../components/FormSalesProgress'

type TicketTypesBreakdown = NonNullable<
  NonNullable<ChangePoolCapacityModalQuery$data['event']>['sales']
>['ticketTypesBreakdown']

function getPoolMinAllocation(ticketTypesBreakdown: any, poolId: string) {
  const poolsTicketTypes = flow(
    compact,
    reject('ticketType.archived'),
    filter((tt: any) => tt.ticketType.ticketPoolId === poolId)
  )(ticketTypesBreakdown || [])

  const totalSales = reduce((acc, ttyb: TicketTypesBreakdown[number]) => {
    const { totalAppSold = 0, totalPosSold = 0, totalTerminalSold = 0, totalReserved = 0 } = ttyb || {}
    return acc + totalAppSold + totalPosSold + totalTerminalSold + totalReserved
  }, 0)(poolsTicketTypes)

  return totalSales
}

const TicketPoolSchema = object().shape({
  ticketPool: object()
    .shape({
      maxAllocation: number().integer().required(),
    })
    .test('minAllocation', 'Allocation must be greater than ticket sales', function (values) {
      const { event } = this.options.context as any
      const totalPoolSales = getPoolMinAllocation(event.sales?.ticketTypesBreakdown, values.id)
      return totalPoolSales <= values.maxAllocation
        ? true
        : this.createError({
          path: 'ticketPool.maxAllocation',
          message: `change_pool_capacity_modal.max_allocation.min_allocation_error%${totalPoolSales}`,
        })
    }),
})

const CHANGE_POOL_CAPACITY_MUTATION = graphql`
  mutation ChangePoolCapacityModalMutation($input: UpdateTicketPoolInput!) {
    updateTicketPool(input: $input) {
      result {
        id
        name
        maxAllocation
      }
    }
  }
`

interface IProps {
  eventId: string
  preSelectedTPId?: string
  onClose: () => void
}

const ChangePoolCapacityModal: FC<IProps> = ({ eventId, preSelectedTPId, onClose }) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)

  const { event } = useLazyLoadQuery<ChangePoolCapacityModalQuery>(
    graphql`
      query ChangePoolCapacityModalQuery($id: ID!) {
        event: node(id: $id) {
          ... on Event {
            id
            ticketPools {
              id
              name
              maxAllocation
            }
            allowedLifecycleUpdates {
              ticketPools {
                canUpdate
                canChangeAllocation
              }
            }
            eventType
            sales {
              ticketTypesBreakdown {
                totalAppSold
                totalPosSold
                totalTerminalSold
                totalReserved
                totalDigitalValue
                ticketType {
                  id
                  archived
                  ticketPoolId
                }
              }
            }
          }
        }
      }
    `,
    { id: eventId },
    { fetchPolicy: 'network-only' }
  )

  const [updatePoolCapacity, updatingPoolCapacity] = useMutation(CHANGE_POOL_CAPACITY_MUTATION)

  const ticketPoolsOptions = useMemo(
    () => map((tp) => ({ value: tp?.id, label: tp?.name, ...tp }), event?.ticketPools || []),
    [event]
  )

  const defaultTicketPool = preSelectedTPId
    ? find(['value', preSelectedTPId], ticketPoolsOptions)
    : ticketPoolsOptions[0]

  const validateSchema = useCallback(
    async (evt: any) => {
      try {
        await TicketPoolSchema.validate(evt, { context: { event }, abortEarly: false })
      } catch (err) {
        return yupToFormErrors(err)
      }
    },
    [event]
  )

  const formik = useFormik({
    initialValues: {
      ticketPool: defaultTicketPool,
    },
    validate: validateSchema,
    onSubmit: async (values) => {
      updatePoolCapacity({
        variables: {
          input: {
            clientMutationId: nanoid(),
            id: values.ticketPool?.id,
            maxAllocation: values.ticketPool?.maxAllocation,
          },
        },
        onCompleted: () => {
          addNotification('success', intl.formatMessage({ id: 'saved' }))
          onClose()
        },
        onError: () => {
          addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
        },
      })
    },
  })

  const { values, touched, errors, setFieldValue, handleSubmit, handleBlur, isValid } = formik

  const changePoolCapacity = useCallback(
    (e: any) => {
      const fieldName = e.target.name
      const value = e.target.value
      if (REGEX_INTEGER.test(value) && value.length < 15) {
        setFieldValue(fieldName, parseInt(value, 10))
      }
      if (value === '') {
        setFieldValue(fieldName, null)
      }
      return
    },
    [setFieldValue]
  )

  const setTicketPool = useCallback((_id: any, tp: any) => setFieldValue('ticketPool', tp), [setFieldValue])

  const poolMinAllocation = getPoolMinAllocation(event?.sales?.ticketTypesBreakdown, values.ticketPool?.id)

  return (
    <Modal modalTitle={intl.formatMessage({ id: 'change_allocation' })} closeButton onClose={onClose}>
      <ModalBody>
        <Form>
          <FormRow>
            <FormField
              label={intl.formatMessage({ id: 'new_event.tickets.ticket_pool.name.label' })}
              control="select"
              searchable
              value={values.ticketPool}
              onChange={setTicketPool}
              options={ticketPoolsOptions}
              disabled={!!preSelectedTPId}
            />
          </FormRow>

          <FormRow>
            <FormField
              name="ticketPool.maxAllocation"
              type="tel"
              label={intl.formatMessage({ id: 'new_event.tickets.ticket_pool.max_allocation.label' })}
              value={isNil(values.ticketPool?.maxAllocation) ? '' : values.ticketPool?.maxAllocation}
              onChange={changePoolCapacity}
              onBlur={handleBlur}
              error={get('ticketPool.maxAllocation', touched) && get('ticketPool.maxAllocation', errors)}
              disabled={
                !allowedEventAction(event?.allowedLifecycleUpdates || null, 'ticketPools', 'canChangeAllocation')
              }
              required
            />

            <FormSalesProgress sold={poolMinAllocation} limit={values.ticketPool.maxAllocation} />
          </FormRow>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl disabled={!isValid} loading={updatingPoolCapacity} onClick={handleSubmit}>
          {intl.formatMessage({ id: 'actions.save_changes' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ChangePoolCapacityModal)
