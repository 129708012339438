import React, { useCallback, useContext, useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { differenceInCalendarDays, max } from 'date-fns'
import { compact, find, map, reject, sortBy } from 'lodash/fp'
import { DeepReadonly } from 'ts-essentials'
import { useRelayEnvironment } from 'react-relay'

import EventTimelineContext from '../../util/eventTimelineContext'
import { color, font } from '../../../../utils/variables'
import { CURRENCY } from '../../../../utils/formatters/number'
import { parseAtTimezone } from '../../../../utils/calendar'
import Svg from '../../../../components/Svg'
import EventTicketTypeModal from '../../../EventForm/components/EventTicketTypeModal'
import { ITicketType } from '../../../EventForm/types/Tickets'
import saveTicketType from '../../services/saveTicketType'
import { notificationContext } from '../../../../context/notification'
import { CELL_WIDTH } from '../../util/eventTimelineVariables'
import { TitleTooltip } from '../../../../components/Tooltip'
import { trackingContext } from '../../../../context/tracking'
import unwrapId from '../../../../utils/unwrapId'
import { authContext } from '../../../../context/auth'

const Container = styled.div`
  margin: 8px 0;
`

const TicketTypeWrapper = styled.div<{ offset: number; width: number }>`
  transform: translateX(${(props) => props.offset}px);
  width: ${(props) => props.width}px;
  font-size: ${font.size.sm}px;
  line-height: 18px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`
const TicketType = styled.div`
  background: #e6e6ff;
  border: 1px solid #0000fe;
  border-radius: 30px;
  margin: 0 3px;
  cursor: pointer;
  color: #0000fe;
`

const BarName = styled.span<{ condensed?: boolean; sticky?: number; icon?: boolean; maxWidth?: number }>`
  display: flex;
  padding: 2px 8px 2px;
  min-height: 22px;

  ${(props) =>
    props.sticky &&
    css`
      max-width: fit-content;
      position: sticky;
      left: ${props.sticky}px;
    `}

  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`};

  svg {
    margin: 0 5px 0 -4px;
    flex-shrink: 0;
    align-self: center;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  strong:last-child {
    font-weight: normal;
    flex-shrink: 0;
    &:before {
      content: ' · ';
    }
  }

  ${(props) =>
    props.condensed &&
    (props.icon
      ? css`
          span,
          strong {
            display: none;
          }
        `
      : css`
          padding: 2px 5px 2px;
          span,
          strong {
            display: none;
          }
          &:after {
            content: '...';
            display: block;
            width: 100%;
            text-align: center;
          }
        `)}
`

const TicketTier = styled.div<{ width?: number }>`
  color: ${color.black};
  border: 1px solid ${color.black};
  border-radius: 30px;
  margin: 8px 0 0;
  &:not(:last-child) {
    margin-right: 6px;
  }
  ${(props) => props.width && `width: ${props.width - 6}px`};
`
const TicketTires = styled.div`
  display: flex;
  margin: 0 3px;
`

const Promotion = styled.div<{ offset: number; width: number; sticky?: number }>`
  transform: translateX(${(props) => props.offset}px);
  width: ${(props) => props.width - 6}px;
  border-radius: 30px;
  background: ${color.palegrey};
  border: 1px solid ${color.palegrey};
  margin: 8px 0 0 3px;
  display: flex;
  align-items: center;
`
const Product = styled.div<{ offset: number; width: number; sticky?: number }>`
  transform: translateX(${(props) => props.offset}px);
  width: ${(props) => props.width - 6}px;
  border-radius: 30px;
  background: rgba(0, 216, 175, 0.1);
  border: 1px solid #00987b;
  color: #00987b;
  margin: 8px 0 0 3px;
  display: flex;
  align-items: center;
`

const TicketTypes = () => {
  const intl = useIntl()
  const { trackEvent } = useContext(trackingContext)
  const { user } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)
  const { dateOffset, event } = useContext(EventTimelineContext)
  const environment = useRelayEnvironment()

  const ttys = useMemo(() => reject('archived', event.ticketTypes), [event.ticketTypes])

  const [editId, setEditId] = useState<string | undefined>(undefined)
  const editedTicketType = useMemo(() => find({ id: editId }, ttys), [editId, ttys])

  const onEdit = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      trackEvent('event_timeline_ticket_type_clicked', {
        ticket_type_id: unwrapId(id),
        event_id: unwrapId(event.id),
        event_id_live: event.eventIdLive,
      })
      setEditId(id)
    },
    [trackEvent, event.id, event.eventIdLive]
  )

  const closeEdit = useCallback(() => setEditId(undefined), [])

  const saveTicketTypeCallback = useCallback(
    async (ticketType: ITicketType) => {
      try {
        await saveTicketType(environment, event, ticketType as DeepReadonly<ITicketType>, user.diceStaff)

        closeEdit()
      } catch (e) {
        console.error(e)
        closeEdit()
        addNotification('error', intl.formatMessage({ id: 'event_overview.ticket_breakdown.tty_save_error' }))
      }
    },
    [addNotification, closeEdit, environment, event, intl, user.diceStaff]
  )

  return (
    <Container>
      {editedTicketType?.id && (
        <EventTicketTypeModal
          event={event}
          ticketTypeId={editedTicketType.id}
          onClose={closeEdit}
          onSave={saveTicketTypeCallback}
        />
      )}

      {ttys &&
        ttys.map((ticketType) => {
          if (!ticketType) return null

          const onSaleDate =
            parseAtTimezone(ticketType.onSaleDate || event.onSaleDate, event.timezoneName) || new Date()
          const offSaleDate =
            parseAtTimezone(ticketType.offSaleDate || event.offSaleDate, event.timezoneName) || new Date()
          const ticketTypeOffset = dateOffset(onSaleDate)
          const ticketTypeWidth = dateOffset(offSaleDate) - dateOffset(onSaleDate) + CELL_WIDTH
          const tiered = ticketType.priceTiers && ticketType.priceTiers.length > 0

          const allocationTierSales =
            tiered &&
            ticketType.priceTierType === 'allocation' &&
            find({ ticketTypeId: ticketType.id }, event.sales?.ticketTypesBreakdown)
          const allocationTier =
            allocationTierSales &&
            (
              find(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (br: any) => br && br.priceTier!.allocation! > br.sold,
                sortBy('priceTier.order', allocationTierSales.priceTiersBreakdown)
              ) as any
            )?.priceTier

          const ticketTypePrice = intl.formatNumber(
            (ticketType.price || 0) / 100,
            CURRENCY(ticketType.price || 0, event.costCurrency)
          )

          return (
            <TicketTypeWrapper key={ticketType.id} offset={ticketTypeOffset} width={ticketTypeWidth}>
              <TicketType onClick={onEdit} data-id={ticketType.id}>
                <TitleTooltip title={`${ticketType.name} · ${ticketTypePrice}`} placement="top">
                  <BarName condensed={ticketTypeWidth <= CELL_WIDTH} sticky={-ticketTypeOffset}>
                    <span>{ticketType.name}</span>
                    {!tiered && <strong>{ticketTypePrice}</strong>}
                  </BarName>
                </TitleTooltip>
              </TicketType>

              {tiered && (
                <TicketTires>
                  {(ticketType.priceTierType === 'time' ? ticketType.priceTiers : [allocationTier]).map((tier, pos) => {
                    if (!tier) return null

                    let tierWidth
                    if (ticketType.priceTierType === 'time') {
                      const tierStart = parseAtTimezone(tier.time, event.timezoneName) || new Date()
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      if (pos === ticketType.priceTiers!.length - 1) {
                        tierWidth = (differenceInCalendarDays(offSaleDate, tierStart) + 1) * CELL_WIDTH
                      } else {
                        const tierEnd =
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          parseAtTimezone(ticketType.priceTiers![pos + 1]?.time, event.timezoneName) || new Date()
                        tierWidth = differenceInCalendarDays(tierEnd, tierStart) * CELL_WIDTH
                      }
                    } else {
                      tierWidth = ticketTypeWidth
                    }

                    const tierPrice = intl.formatNumber(
                      (tier.price || 0) / 100,
                      CURRENCY(tier.price || 0, event.costCurrency)
                    )

                    return (
                      <TicketTier key={tier.id} width={tierWidth}>
                        <TitleTooltip title={`${tier.name} · ${tierPrice}`} placement="top">
                          <BarName condensed={tierWidth <= CELL_WIDTH} sticky={-ticketTypeOffset}>
                            <span>{tier.name}</span>
                            <strong>{tierPrice}</strong>
                          </BarName>
                        </TitleTooltip>
                      </TicketTier>
                    )
                  })}
                </TicketTires>
              )}

              {ticketType.eventPromotions &&
                ticketType.eventPromotions.length > 0 &&
                ticketType.eventPromotions.map((promotion) => {
                  if (!promotion) return null
                  const promotionStart =
                    parseAtTimezone(
                      promotion.startDate || ticketType.onSaleDate || event.onSaleDate,
                      event.timezoneName
                    ) || new Date()
                  const promotionEnd =
                    parseAtTimezone(
                      promotion.endDate || ticketType.offSaleDate || event.offSaleDate,
                      event.timezoneName
                    ) || new Date()

                  const width = dateOffset(promotionEnd) - dateOffset(promotionStart) + CELL_WIDTH

                  return (
                    <Promotion key={promotion.id} offset={dateOffset(promotionStart) - ticketTypeOffset} width={width}>
                      <TitleTooltip title={promotion.name} placement="top">
                        <BarName icon maxWidth={width} sticky={-dateOffset(promotionStart)} condensed={width <= 30}>
                          {promotion.promotionType === 'CODE_LOCK' ? (
                            <Svg icon="lock" width={13} height={13} />
                          ) : (
                            <Svg icon="tag" width={13} height={13} />
                          )}
                          <span>{promotion.name}</span>
                        </BarName>
                      </TitleTooltip>
                    </Promotion>
                  )
                })}

              {ticketType.products &&
                ticketType.products.length > 0 &&
                ticketType.products.map((product) => {
                  if (!product) return null
                  const productTicketsOffSaleDates = compact(
                    map(
                      (t) => t && t.offSaleDate && parseAtTimezone(t.offSaleDate, event.timezoneName),
                      product.ticketTypes
                    )
                  )
                  const productOffSaleDate =
                    product.ticketTypes.length > productTicketsOffSaleDates.length
                      ? event.offSaleDate
                      : max(productTicketsOffSaleDates)

                  const start =
                    parseAtTimezone(
                      product.onSaleDate || ticketType.onSaleDate || event.onSaleDate,
                      event.timezoneName
                    ) || new Date()
                  const end = product.offSaleDate
                    ? parseAtTimezone(product.offSaleDate, event.timezoneName)
                    : productOffSaleDate || new Date()

                  const width = dateOffset(end) - dateOffset(start) + CELL_WIDTH

                  return (
                    <Product key={product.id} offset={dateOffset(start) - ticketTypeOffset} width={width}>
                      <TitleTooltip title={product.name} placement="top">
                        <BarName icon maxWidth={width} sticky={-dateOffset(start)} condensed={width <= 30}>
                          {product.category?.parentCategory?.type === 'MERCH' ? (
                            <Svg icon="merch" width={13} height={13} />
                          ) : (
                            <Svg icon="plus" width={13} height={13} />
                          )}
                          <span>{product.name}</span>
                        </BarName>
                      </TitleTooltip>
                    </Product>
                  )
                })}
            </TicketTypeWrapper>
          )
        })}
    </Container>
  )
}

export default TicketTypes
