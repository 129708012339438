import React, { FC, useRef, useLayoutEffect, HTMLProps } from 'react'
import cn from 'classnames'
import { takeWhile, map, find } from 'lodash/fp'
import { waitForImagesPromise } from '../utils/waitForImages'

const AUTO_SCROLLER_CLASS = 'js-auto-scroller'

const AutoScroller: FC<
  React.PropsWithChildren<
    {
      slug: string
      className?: string
      dataIdPrefix?: string
    } & HTMLProps<HTMLDivElement>
  >
> = ({ slug, className, children, dataIdPrefix = 'autoScroller', ...props }) => {
  return (
    <div
      {...props}
      className={cn(AUTO_SCROLLER_CLASS, className)}
      data-slug={slug}
      data-id={`${dataIdPrefix}[${slug}]`}
    >
      {children}
    </div>
  )
}

export const scrollToSection = async (slug: string, notSmooth?: boolean, offsetTop?: number) => {
  const sections = document.querySelectorAll<HTMLElement>(`.${AUTO_SCROLLER_CLASS}[data-slug]`)
  if (sections.length === 0) return

  const arr: HTMLElement[] = []
  sections.forEach((el) => arr.push(el))
  arr.sort((a, b) => a.offsetTop - b.offsetTop)

  const targetSection = find((el) => el.dataset['slug'] === slug, arr)

  if (!targetSection) return

  const sectionsAbove = takeWhile((el) => el.dataset['slug'] !== slug, arr)

  // To prevent layout shift and scroll misalign after image load
  await Promise.all(map((el) => waitForImagesPromise(el, 500), sectionsAbove))

  const offsetPosition = targetSection.offsetTop - (offsetTop || 0) + (targetSection.parentElement?.offsetTop || 0) + 2

  window.scrollTo({
    top: offsetPosition,
    behavior: notSmooth ? undefined : 'smooth',
  })
}

export function useAutoScroll(setActiveStep: (slug: string) => void, deps: any[]) {
  const lastValue = useRef<string | undefined>(undefined)
  useLayoutEffect(() => {
    const sections: NodeListOf<HTMLElement> = document.querySelectorAll(`.${AUTO_SCROLLER_CLASS}`)

    const lsnr = () => {
      sections.forEach((section) => {
        const slug = section.dataset.slug
        const top = section.getBoundingClientRect().top
        const height = section.getBoundingClientRect().height
        const bottom = top + height

        const isCurrent = bottom >= window.innerHeight / 2 && top <= window.innerHeight / 2

        if (slug && isCurrent && lastValue.current !== slug) {
          lastValue.current = slug
          setActiveStep(slug)
        }
      })
    }

    lsnr()
    window.addEventListener('scroll', lsnr, { passive: true })
    window.addEventListener('resize', lsnr, { passive: true })

    return () => {
      window.removeEventListener('scroll', lsnr)
      window.removeEventListener('resize', lsnr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveStep, ...deps])
}

export default AutoScroller
