import { some } from 'lodash/fp'
import { EventCostCurrency, EventType } from '../enums.generated'
import { ILocale } from '../intl'
import { getAlpha2ByName, getCurrencyByCountryCode } from './countries'

interface ITicketType {
  isStream: boolean | null
  archived: boolean | null
}

interface IEvent {
  eventType: EventType | null
  costCurrency: EventCostCurrency | null
  addressCountry: string | null
  countryCode: string | null
  primaryVenue: { addressCountry: string | null; countryCode: string | null } | null
  ticketTypes: ReadonlyArray<ITicketType | null> | null
}

const checkCurrencyMismatch = (event: IEvent, locale: ILocale): boolean => {
  const hasLiveTtys =
    event.eventType === 'LIVE' ||
    (event.eventType === 'HYBRID' && some((tt: ITicketType) => !tt.isStream, event.ticketTypes || []))

  if (!!event.costCurrency && !!(event.primaryVenue?.addressCountry || event.addressCountry) && hasLiveTtys) {
    const countryCode =
      event.countryCode ||
      event.primaryVenue?.countryCode ||
      getAlpha2ByName(event.primaryVenue?.addressCountry || event.addressCountry, locale)

    if (countryCode) {
      const venueCurrency = getCurrencyByCountryCode(countryCode)

      return venueCurrency !== event.costCurrency
    }
  }

  return false
}

export default checkCurrencyMismatch
