import React, { FC, useState, useCallback, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { Modal, ModalBody, ModalDialog, ModalFooter, ModalFooterControl } from './Modal'
import { color, mediaQuery } from '../utils/variables'

const StyledModal = styled(Modal)`
  ${ModalDialog} {
    background-color: ${color.text};
    padding: 16px;
    width: calc(max(50vh, 550px) + 32px);
    height: calc(max(50vh, 550px) + 54px);

    ${mediaQuery.lessThan('tablet')`
      width: 100vw;
      height: calc(100vw + 125px);
      margin: auto;
    `}
  }
`

const StyledFooter = styled(ModalFooter)`
  margin: 0;
  padding: 0;
  background: none;
  ${mediaQuery.lessThan('tablet')`
    margin: 0;
    padding: 0;
    bottom: auto;
  `}
`

const ImgContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: max(50vh, 550px);
  margin-top: -16px;

  ${mediaQuery.lessThan('tablet')`
    height: 100vw;
  `}

  .ReactCrop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    img {
      max-width: max(50vh, 550px);
      max-height: calc(max(50vh, 550px) - 32px);

      ${mediaQuery.lessThan('tablet')`
        max-width: calc(100vw - 32px);
        max-height: calc(100vw - 32px);
      `}
    }

    .ReactCrop__selection-addon {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }

    .ReactCrop__rule-of-thirds-vt {
      &:before, &:after {
        top: 0;
      }
    } 
  }
`

const StyledButton = styled(ModalFooterControl)`
  &:first-child {
    margin-right: 16px;

    ${mediaQuery.lessThan('tablet')`
      margin-right: 0;
    `}
  }
`

interface IProps {
  src?: string
  crop?: Crop | null
  setCrop: (crop: Crop | null) => void
  mask?: JSX.Element
  cta?: ReactNode
  ruleOfThirds?: boolean
}

const Cropper: FC<React.PropsWithChildren<IProps>> = ({ src, crop, setCrop, mask, cta, ruleOfThirds }) => {
  const intl = useIntl()
  const [myCrop, setMyCrop] = useState(crop)

  const onClose = useCallback(() => setCrop(null), [setCrop])
  const onSave = useCallback(() => setCrop(myCrop || null), [setCrop, myCrop])

  const onChange = useCallback((_: any, percentCrop: any) => setMyCrop(percentCrop), [setMyCrop])

  const renderSelection = useCallback(() => mask, [mask])

  return (
    <StyledModal onClose={onClose}>
      <ModalBody>
        <ImgContainer>
          {src && (
            <ReactCrop
              src={src}
              crop={myCrop || undefined}
              onChange={onChange}
              keepSelection
              crossorigin="anonymous"
              renderSelectionAddon={renderSelection}
              ruleOfThirds={!!ruleOfThirds}
            />
          )}
        </ImgContainer>
      </ModalBody>
      <StyledFooter>
        <StyledButton color="white" onClick={onSave} data-id="save">
          {cta || intl.formatMessage({ id: 'actions.save' })}
        </StyledButton>
        <StyledButton color="white" preset="outline" onClick={onClose} data-id="cancel">
          {intl.formatMessage({ id: 'actions.cancel' })}
        </StyledButton>
      </StyledFooter>
    </StyledModal>
  )
}

export default Cropper
