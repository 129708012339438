import React, { useMemo, FC, memo } from 'react'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { CURRENCY } from '../../../utils/formatters/number'

import EventCardValue from '../../../components/Event/EventCardValue'

import { EventPartRevenue_event$key } from '../../../__generated__/EventPartRevenue_event.graphql'

interface IProps {
  grow?: boolean
  event: EventPartRevenue_event$key
  textAlign?: 'left' | 'center' | 'right'
}

const EventPartRevenue: FC<React.PropsWithChildren<IProps>> = ({ grow, event: eventKey, textAlign }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment EventPartRevenue_event on Event
      @argumentDefinitions(hideFreeTag: { type: "Boolean", defaultValue: false }) {
        id
        costCurrency
        sales {
          totalValue
        }
        prices @skip(if: $hideFreeTag) {
          to
        }
      }
    `,
    eventKey
  )

  const formattedRevenue = useMemo(() => {
    if (!!event.prices && event?.prices?.to === 0) return '-'
    return intl.formatNumber(
      (event?.sales?.totalValue || 0) / 100,
      CURRENCY(event?.sales?.totalValue, event.costCurrency)
    )
  }, [event, intl])

  return <EventCardValue grow={grow} textAlign={textAlign} primary={formattedRevenue} />
}

export default memo(EventPartRevenue)
