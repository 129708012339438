import React, { ComponentType, FC, ReactNode, Suspense, useCallback, useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled, { css } from 'styled-components/macro'

import { getExposedHeight } from '../utils/useExposeHeightToCss'
import { breakpoints, color, mediaQuery, zIndex } from '../utils/variables'
import AutoScroller, { scrollToSection, useAutoScroll } from './AutoScroller'
import { Loader, LoaderContainer } from './Loader'
import { pageStickyTop } from '../utils/sticky'

interface IWizardStepProps {
  active: boolean
  showStep: boolean
  validationState: 'valid' | 'invalid' | 'unknown'
}

export interface IStep {
  slug: string
  label: ReactNode
  badge?: ReactNode
  component: ComponentType<React.PropsWithChildren<{ readOnly: boolean } & { componentRef?: (ref: any) => void } & any>>
}

const STEP_BG_COLORS = {
  active: color.primary,
  valid: color.success,
  invalid: color.error,
  unknown: color.white,
}

const STEP_FG_COLORS = {
  active: color.primary,
  valid: color.success,
  invalid: color.error,
  unknown: color.text,
}

const WizardLayout = styled.div`
  display: flex;

  ${mediaQuery.lessThan('desktop')`
    flex-direction: column;
  `}
`

const WizardSteps = styled.ol`
  padding: 22px 0 0 32px;
  counter-reset: wizard-step-number;
  position: fixed;

  ${mediaQuery.lessThan('desktop')`
    position: relative;
    padding: 0 8px;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: space-between;
  `}
`

const NavPanel = styled.div<{ isEmbedded?: boolean }>`
  position: relative;
  background: ${color.white};
  border-right: 2px solid ${color.text};
  min-width: 240px;

  ${({ isEmbedded }) =>
    !isEmbedded
      ? css`
          min-height: calc(100vh - var(--page-header-height));
          ${mediaQuery.lessThan('desktop')`
            width: auto;
            padding: 0 25%;
            min-height: auto;
            position: sticky;
            top: var(--page-header-height);
            border-right: none;
            border-bottom: 2px solid ${color.text};
            z-index: ${zIndex.header};
          `}
        `
      : css`
          ${WizardSteps} {
            position: sticky;
            ${pageStickyTop('69px')}
          }

          ${mediaQuery.lessThan('desktop')`
            display: none;
          `}
        `}
`

const WizardStep = styled.li<IWizardStepProps>`
  margin-bottom: 12px;
  ${({ showStep }) => showStep && 'padding-left: 40px;'}
  line-height: 52px;
  overflow: hidden;
  font-weight: ${({ showStep }) => (showStep ? 'normal' : 'bold')};

  counter-increment: wizard-step-number;

  cursor: pointer;

  ${mediaQuery.lessThan('desktop')`
    display: inline-block;
    margin-bottom: 0;

    &:not(:first-child) {
      margin-left: 8px;
    }
  `}

  ${({ active }) => (active ? `color: ${color.primary};` : undefined)}

  &::before {
    content: counter(wizard-step-number);
    pointer-events: none;

    ${({ showStep }) => (showStep ? 'display: block;' : 'display: none;')}

    position: absolute;
    width: 32px;
    height: 32px;
    margin: 10px 0 0 -40px;

    line-height: 28px;
    font-weight: bold;
    text-align: center;

    border-radius: 100%;
    border: 2px solid ${color.text};

    color: ${({ validationState, active }) => (validationState === 'unknown' && !active ? color.text : color.white)};

    background-color: ${({ validationState, active }) => STEP_BG_COLORS[active ? 'active' : validationState]};

    border: ${({ validationState, active }) => `2px solid ${STEP_FG_COLORS[active ? 'active' : validationState]}`};

    ${mediaQuery.lessThan('desktop')`
      margin: 10px 0 0 -32px;
    `}
  }

  & > span {
    display: block;

    width: calc(100% + 40px);
    height: 100%;

    padding-left: 40px;
    margin-left: -40px;

    ${mediaQuery.lessThan('desktop')`
       font-size: 0;
       padding-right: 8px;
    `}
  }
`

const StepBodyContainer = styled.div`
  position: relative;
  width: 100%;
`

const Section = styled(AutoScroller)`
  padding: 0 48px;

  & > div {
    padding: 32px 0;
    margin: auto;

    max-width: 700px;
  }

  & + & {
    border-top: 2px solid ${color.text};
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &:last-child {
    min-height: calc(100vh - 140px);
  }

  ${mediaQuery.lessThan('desktop')`
    padding: 0 16px;

    &:last-child {
      min-height: calc(100vh - 200px);
    }
  `}
`

const WizardMenuItem: FC<
  React.PropsWithChildren<{
    step: string
    label: ReactNode
    badge?: ReactNode
    activeStep: string
    showStep: boolean
    validationState?: 'valid' | 'invalid' | 'unknown'
    onClick: (step: string) => void
  }>
> = ({ step, label, badge, activeStep, showStep, onClick, validationState }) => {
  const handleClick = useCallback(() => onClick(step), [onClick, step])

  return (
    <WizardStep
      active={step === activeStep}
      onClick={handleClick}
      data-id={`stepIndicator[${step}]`}
      showStep={showStep}
      validationState={validationState || 'unknown'}
    >
      <span>
        {label}
        {badge && badge}
      </span>
    </WizardStep>
  )
}

interface IProps {
  remountKey?: string | null
  steps: IStep[]
  hideSteps?: boolean
  isEmbedded?: boolean
  navFooter?: ReactNode
  onStepChange?: (slug: string) => void
  validateStep?: (slug: string) => IWizardStepProps['validationState']
  readOnly?: boolean
  [k: string]: any
}

const Wizard: FC<React.PropsWithChildren<IProps>> = ({
  steps,
  navFooter,
  remountKey,
  isEmbedded,
  hideSteps,
  readOnly,
  onStepChange,
  validateStep,
  children,
  ...stepProps
}) => {
  const scrollingToStep = useRef('')
  const [activeStep, setActiveStep] = useState(steps[0].slug)
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  useAutoScroll(
    (step) => {
      if (!scrollingToStep.current || scrollingToStep.current === step) {
        setActiveStep(step)
        scrollingToStep.current = ''
      }
    },
    [remountKey]
  )

  useEffect(() => {
    if (onStepChange) onStepChange(activeStep)
  }, [activeStep, onStepChange])

  const handleMenuItemClick = useCallback(
    (step: string) => {
      const offsetTopNav = getExposedHeight('--page-nav-height')
      const offsetTopHdr = !hideSteps ? getExposedHeight('--page-header-height') : 0
      const mobileOffset = isMobile && !hideSteps ? 52 : 0

      scrollingToStep.current = step
      scrollToSection(step, false, offsetTopHdr + offsetTopNav + mobileOffset)
    },
    [hideSteps, isMobile]
  )

  return (
    <WizardLayout>
      <NavPanel isEmbedded={isEmbedded}>
        <WizardSteps>
          {steps.map(({ slug, label, badge }) => (
            <WizardMenuItem
              key={slug}
              step={slug}
              label={label}
              badge={badge}
              activeStep={activeStep}
              showStep={!hideSteps}
              onClick={handleMenuItemClick}
              validationState={validateStep ? validateStep(slug) : 'unknown'}
            />
          ))}
        </WizardSteps>
        {navFooter}
      </NavPanel>
      <StepBodyContainer>
        <div>
          {steps.map((step) => {
            return (
              <Section key={step.slug} slug={step.slug} dataIdPrefix="wizardStep">
                <Suspense
                  fallback={
                    <LoaderContainer>
                      <Loader />
                    </LoaderContainer>
                  }
                >
                  <step.component readOnly={readOnly || false} {...stepProps} />
                </Suspense>
              </Section>
            )
          })}
        </div>

        {children}
      </StepBodyContainer>
    </WizardLayout>
  )
}

export default Wizard
