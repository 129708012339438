import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { ConfirmationModal } from '../../components/ConfirmationModal'

import versionCheck from '../../utils/versionCheck'

const doReload = () => window.location.reload()

const VersionChecker: FC = () => {
  const intl = useIntl()

  const [showModal, setShowModal] = useState(false)
  const doHideModal = useCallback(() => setShowModal(false), [])

  const lastCheck = useRef<Date | null>(null)
  const checking = useRef<boolean>(false)

  useEffect(() => {
    const lsnr = () => {
      if (checking.current) return
      // Check every 5 mins or more
      if (lastCheck.current && new Date().getTime() - lastCheck.current.getTime() < 300000) return

      checking.current = true
      versionCheck()
        .then((isOk) => {
          if (!isOk) {
            setShowModal(true)
          }
        })
        .catch(console.error)
        .finally(() => {
          lastCheck.current = new Date()
          checking.current = false
        })
    }

    window.addEventListener('focus', lsnr)
    return () => {
      window.removeEventListener('focus', lsnr)
    }
  }, [intl])

  return showModal ? (
    <ConfirmationModal
      title={intl.formatMessage({ id: 'app_outdated_error' })}
      cta={intl.formatMessage({ id: 'actions.refresh' })}
      onConfirm={doReload}
      onReject={doHideModal}
    />
  ) : null
}

export default VersionChecker
