import { filter, isArray, isEmpty, without } from 'lodash/fp'
import { addMonths, addWeeks, endOfDay, formatISO, startOfDay, subDays, subMonths, subWeeks } from 'date-fns/fp'
import { EventScopesInput, EventWhereInput } from '../../../../__generated__/EventListPageQuery.graphql'
import { EventLifeCycleState, EventsConnectionOrder } from '../../../../enums.generated'
import { EventReviewWhereInput } from '../../../../__generated__/EventSubmissionsListPageQuery.graphql'

function mappedStatuses(statuses: string | string[]) {
  const statusArray = isArray(statuses) ? statuses : statuses.split(',')
  return statusArray.join().toUpperCase().replace(/-/g, '_').replace('DRAFT', 'NOT_COMPLETE').split(',')
}

export function defaultOrder(listType: string): EventsConnectionOrder {
  switch (listType) {
    case 'cancelled':
      return 'cancelledAtDESC'
    case 'submission':
      return 'submittedAtASC'
    case 'past':
      return 'dateDESC'
    case 'draft':
    case 'live':
    default:
      return 'dateASC'
  }
}

export function defaultScopes(listType: string): EventScopesInput {
  switch (listType) {
    case 'submission':
      return { eventState: ['TO_BE_APPROVED'] }
    case 'past':
    case 'draft':
    case 'live':
      return { lifeCycleState: [listType.toUpperCase() as EventLifeCycleState] }
    case 'cancelled':
    default:
      return { lifeCycleState: undefined }
  }
}

export const initFilters = (listType: string) => {
  const eventFilters = {
    search: '',
    status: [],
    state: [],
    kind: undefined,
    eventType: [],
    submittedAtMinDate: undefined,
    submittedAtMaxDate: undefined,
    announcedMinDate: undefined,
    announcedMaxDate: undefined,
    timeFrameValue: undefined,
    timeFrameMinDate: undefined,
    timeFrameMaxDate: undefined,
    location: [],
    venues: [],
    countryCode: undefined,
    account: undefined,
    orderBy: defaultOrder(listType),
    promoterTiers: [],
    typeOfOrganiser: [],
  }

  return listType === 'submission'
    ? {
      ...eventFilters,
      assigneeId: undefined,
      reviewStatuses: null,
    }
    : eventFilters
}

export const getEventVariables = (appliedFilters: any, listType = 'live') => {
  const scopes: EventScopesInput = defaultScopes(listType)
  const where: EventWhereInput = listType === 'cancelled' ? { state: { eq: 'cancelled' } } : {}
  let submissionWhere: EventReviewWhereInput = {}
  let searchTerm
  const orderBy = appliedFilters.orderBy || defaultOrder(listType)
  const needBalance = listType === 'past'
  const isDraft = listType === 'draft'

  // Scopes mapper
  if (!isEmpty(appliedFilters.status)) {
    const statuses = mappedStatuses(appliedFilters.status)

    const states = without(['POSTPONED', 'DICE_READY_TO_PAYOUT'], statuses)
    const hasPostponed = statuses.indexOf('POSTPONED') >= 0
    const hasDiceToBePaid = statuses.indexOf('DICE_READY_TO_PAYOUT') >= 0

    if (states.length > 0) scopes.eventState = states as any

    if (hasPostponed) {
      where.scheduleStatus = { eq: 'postponed' }
    } else {
      where.scheduleStatus = { neOrNull: 'postponed' }
    }

    if (hasDiceToBePaid) {
      where.special = 'toBePaid'
    }
  }

  // Where mapper
  if (appliedFilters.search) {
    searchTerm = appliedFilters.search
  }

  if (!isEmpty(appliedFilters.promoterTiers)) {
    const tiersArray = isArray(appliedFilters.promoterTiers)
      ? appliedFilters.promoterTiers
      : [appliedFilters.promoterTiers]
    const promoterTiers = tiersArray.map((tier: string) => tier.toUpperCase())
    where.promoterTier = { in: promoterTiers }
  }

  if (!isEmpty(appliedFilters.state)) {
    where.state = { in: appliedFilters.state }
  }

  if (appliedFilters.kind) {
    where.eventType = { eq: appliedFilters.kind.toLowerCase() }
  }

  if (!isEmpty(appliedFilters.eventType)) {
    where.hierarchicalTagNames = {
      contains: isArray(appliedFilters.eventType) ? appliedFilters.eventType : appliedFilters.eventType.split(','),
    }
  }

  if (!isEmpty(appliedFilters.location)) {
    where.cityIds = {
      contains: isArray(appliedFilters.location) ? appliedFilters.location : appliedFilters.location.split(','),
    }
  }

  if (!isEmpty(appliedFilters.venues)) {
    where.venueIds = { in: appliedFilters.venues }
  }

  if (appliedFilters.account) {
    where.accountId = { eq: `${appliedFilters.account}` }
  }

  if (appliedFilters.accountOwner) {
    where.accountManagerId = { eq: `${appliedFilters.accountOwner}` }
  }

  if (appliedFilters.priority) {
    where.priority = { eq: appliedFilters.priority === 'true' }
  }

  if (appliedFilters.countryCode) {
    where.countryCode = isArray(appliedFilters.countryCode)
      ? { in: appliedFilters.countryCode }
      : { eq: appliedFilters.countryCode }
  }

  if (appliedFilters.typeOfOrganiser?.length) {
    where.promoterTypeOfOrganizer = isArray(appliedFilters.typeOfOrganiser)
      ? { in: appliedFilters.typeOfOrganiser }
      : { eq: appliedFilters.typeOfOrganiser }
  }

  if (appliedFilters.announceMinDate || appliedFilters.announceMaxDate) {
    where.announceDate = {
      gt: appliedFilters.announceMinDate ? appliedFilters.announceMinDate : '',
      lt: appliedFilters.announceMaxDate ? appliedFilters.announceMaxDate : '',
    }
  }

  if (appliedFilters.submittedAtMinDate || appliedFilters.submittedAtMaxDate) {
    where.submittedAt = {
      gt: appliedFilters.submittedAtMinDate ? appliedFilters.submittedAtMinDate : '',
      lt: appliedFilters.submittedAtMaxDate ? appliedFilters.submittedAtMaxDate : '',
    }
  }

  if (appliedFilters.onSaleDateMinDate || appliedFilters.onSaleDateMaxDate) {
    where.onSaleDate = {
      gt: appliedFilters.onSaleDateMinDate ? appliedFilters.onSaleDateMinDate : '',
      lt: appliedFilters.onSaleDateMaxDate ? appliedFilters.onSaleDateMaxDate : '',
    }
  }

  if (appliedFilters.timeFrameValue === 'custom_date_range') {
    where.date = {
      gt: appliedFilters.timeFrameMinDate ? appliedFilters.timeFrameMinDate : '',
      lt: appliedFilters.timeFrameMaxDate ? appliedFilters.timeFrameMaxDate : '',
    }
  } else if (appliedFilters.timeFrameValue === 'on_sale_today') {
    where.special = 'onSaleToday'
  } else if (appliedFilters.timeFrameValue) {
    let minDate = startOfDay(new Date())
    let maxDate = minDate

    switch (appliedFilters.timeFrameValue) {
      case 'upcoming_week':
        maxDate = endOfDay(addWeeks(1, maxDate))
        break
      case 'upcoming_fortnight':
        maxDate = endOfDay(addWeeks(2, maxDate))
        break
      case 'upcoming_month':
        maxDate = endOfDay(addMonths(1, maxDate))
        break
      case 'yesterday':
        minDate = subDays(1, minDate)
        break
      case 'previous_week':
        minDate = subWeeks(1, minDate)
        break
      case 'previous_fortnight':
        minDate = subWeeks(2, minDate)
        break
      case 'previous_month':
        minDate = subMonths(1, minDate)
        break
    }

    where.date = {
      gte: formatISO(minDate),
      lte: formatISO(maxDate),
    }
  }

  if (listType === 'submission') {
    let assigneeFilter = undefined
    switch (appliedFilters.assigneeId) {
      case 'UNASSIGNED':
        assigneeFilter = { eq: null }
        break
      case undefined:
        assigneeFilter = undefined
        break
      default:
        assigneeFilter = { eq: appliedFilters.assigneeId }
    }
    submissionWhere = {
      status: appliedFilters.reviewStatuses ? { in: filter((v) => !!v, appliedFilters.reviewStatuses) } : undefined,
      assigneeId: assigneeFilter,
      event: where,
    } as EventReviewWhereInput
  }

  return {
    where: listType === 'submission' ? submissionWhere : where,
    searchTerm,
    scopes,
    orderBy,
    needBalance,
    isDraft,
  }
}
