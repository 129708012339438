import { compact, compose, isNil, map, reject, set } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'
import { commitMutation, Environment } from 'react-relay'
import { nanoid } from 'nanoid'
import { DeepReadonly } from 'ts-essentials'

import { ITicketType } from '../../EventForm/types/Tickets'
import { EventType } from '../../../enums.generated'

import { convertTty as converTtyMinor } from '../../EventDetails/services/convertEventMinor'
import { convertTty as convertTtyFull } from '../../EventForm/services/convertEvent'

import { assertServerCompatibleDeep } from '../../../utils/entityStatus'
import {
  saveTicketTypeAdminMutation,
  saveTicketTypeAdminMutation$data,
} from '../../../__generated__/saveTicketTypeAdminMutation.graphql'
import {
  saveTicketTypePromoterMutation,
  saveTicketTypePromoterMutation$data,
} from '../../../__generated__/saveTicketTypePromoterMutation.graphql'

interface IPartialTicketType {
  id: string
  archived: boolean | null
}

interface IPartialEvent {
  id: string
  announceDate: string | null
  onSaleDate: string | null
  offSaleDate: string | null
  eventType: EventType | null
  maxTicketsLimit: number | null
  lockVersion: number
  ticketTypes: ReadonlyArray<IPartialTicketType | null> | null
  venueSchedules: ReadonlyArray<{ id: string } | null> | null
}

const saveTicketType = async (
  environment: Environment,
  event: IPartialEvent,
  ticketType: DeepReadonly<ITicketType>,
  isAdmin: boolean
) => {
  const convertTty = isAdmin ? convertTtyFull : converTtyMinor

  const realTty = await convertTty(
    environment,
    ticketType,
    event.announceDate,
    event.onSaleDate,
    event.offSaleDate,
    event.eventType || null,
    event.venueSchedules,
    null,
    isAdmin
  )

  if (!realTty) return

  let ttys: Array<{ id: string } | typeof realTty> = isAdmin
    ? compose([map((tty: IPartialTicketType) => (tty.id === realTty.id ? realTty : { id: tty.id })), compact])(
      event.ticketTypes
    )
    : compose([
      map((tty: IPartialTicketType) => (tty.id === realTty.id ? realTty : { id: tty.id })),
      compact,
      reject((tty: IPartialTicketType | null) => !!tty?.archived && realTty.id !== tty?.id),
    ])(event.ticketTypes)

  if (event.eventType === 'STREAM' && !isNil(event.maxTicketsLimit)) {
    // These fields are absent in UI for stream events, so setting to event-level default
    ttys = map(compose([set('increment', 1), set('maximumIncrements', event.maxTicketsLimit || 0)]), ttys)
  }

  await new Promise<void>((resolve, rejectFn) => {
    if (isAdmin) {
      commitMutation<saveTicketTypeAdminMutation>(environment, {
        mutation: graphql`
          mutation saveTicketTypeAdminMutation($input: UpdateEventInput!) {
            updateEvent(input: $input) {
              warnings
              event {
                ...TicketBreakdown_event
              }
            }
          }
        `,
        variables: {
          input: assertServerCompatibleDeep({
            clientMutationId: nanoid(),
            id: event.id,
            lockVersion: event.lockVersion,
            ticketTypes: ttys,
          }),
        },
        onCompleted: (data: saveTicketTypeAdminMutation$data) => {
          const payload = data.updateEvent
          if (!payload) {
            rejectFn()
            return
          }

          if (payload.event) {
            resolve()
          } else {
            rejectFn()
          }
        },
        onError: reject,
      })
    } else {
      commitMutation<saveTicketTypePromoterMutation>(environment, {
        mutation: graphql`
          mutation saveTicketTypePromoterMutation($input: MinorUpdateEventInput!) {
            minorUpdateEvent(input: $input) {
              event {
                ...TicketBreakdown_event
              }
            }
          }
        `,
        variables: {
          input: assertServerCompatibleDeep({
            clientMutationId: nanoid(),
            id: event.id,
            lockVersion: event.lockVersion,
            ticketTypes: ttys,
          }),
        },
        onCompleted: (data: saveTicketTypePromoterMutation$data) => {
          const payload = data.minorUpdateEvent
          if (!payload) {
            rejectFn()
            return
          }

          if (payload.event) {
            resolve()
          } else {
            rejectFn()
          }
        },
        onError: reject,
      })
    }
  })
}

export default saveTicketType
