import styled from 'styled-components/macro'
import { color, font, mediaQuery } from '../utils/variables'
import Button from './Button'
import IconButton from './IconButton'

const Banner = styled.div`
  background-color: ${color.palepurple};
  color: ${color.primary};
  padding: 24px 64px 24px 24px;
  border-radius: 10px;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 100vw;

  & > svg {
    margin-right: 16px;
    min-width: 24px;
  }

  ${Button} {
    color: ${color.primary};
  }

  ${IconButton} {
    position: absolute;
    right: 16px;
  }

  ${mediaQuery.lessThan('tablet')`
    padding: 14px 64px 14px 14px;
    font-size: ${font.size.sm}px;
    letter-spacing: 0.01em;

    & > svg {
      display: none;
    }
  `}
`

export default Banner
