import React, { FC, memo, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer, useRelayEnvironment } from 'react-relay'
import { useMediaQuery } from 'react-responsive'
import { useFormikContext } from 'formik'
import { get, getOr, map } from 'lodash/fp'

import { Form, FormRow, NoMarginFormRow } from '../../../components/Form'
import FormField, { IStyledFormField, SmallFormField } from '../../../components/FormField'
import Collapsible from '../../../components/Collapsible'
import EventImageUploader from '../components/EventImageUploader'
import { IFormStep } from '../services/getStepsConfig'
import { authContext } from '../../../context/auth'
import IEventFormSettings from '../types/Settings'
import IEventFormTickets from '../types/Tickets'
import { PageViewTracker } from '../../../context/tracking'
import unwrapId from '../../../utils/unwrapId'
import useSettingsBrandLogo from '../hooks/useSettingsBrandLogo'
import useSettingsFields from '../hooks/useSettingsFields'
import { allowedEventAction } from '../services/allowedEventAction'
import FormGroup from '../../../components/FormGroup'
import EventRecurring from '../components/EventRecurring'
import { breakpoints, color, font } from '../../../utils/variables'
import EventLinks from '../components/EventLinks'
import FormHeader from '../../../components/FormHeader'
import SwitchField from '../../../components/SwitchField'
import usePromoters, { getPromoterLoader } from '../hooks/usePromoters'
import EventGuests from '../components/EventGuests'
import { isItalianEvent } from '../../../utils/isCountryEvent'
import EventPreview from '../components/EventPreview'
import useMiscTags from '../../../utils/hooks/useMiscTags'
import { TitleTooltip, TooltipHelpIcon } from '../../../components/Tooltip'
import useMarketeers from '../../../utils/hooks/useMarketeers'
import EventThirdPartyAccess from '../components/EventThirdPartyAccess'
import { Loader, LoaderContainer } from '../../../components/Loader'
import { localeContext } from '../../../context/locale'
import { featureFlagsContext } from '../../../context/featureFlags'
import EventDoorlist from '../components/EventDoorlist'
import Labels from '../../../components/Labels'
import EventLoadPredictions from '../components/EventLoadPredictions'
import DoorlistDisclaimerModal from '../../EventDoorlist/components/DoorlistDisclaimerModal'
import EventMarketingProfilesModal from '../components/EventMarketingProfilesModal'
import { useMarketeersQuery, useMarketeersQuery$data } from '../../../__generated__/useMarketeersQuery.graphql'
import Badge from '../../../components/Badge'
import { COOLING_OFF_PERIOD_HOURS_LIMITS } from '../../../constants/settings'

const LOGO_IMAGE_TYPES = ['brand']

const Placeholder = styled.div`
  margin-bottom: 80px;
`

const HintButton = styled.button`
  appearance: none;
  border: 0;
  background: none;
  display: inline;
  color: ${color.primary};
  padding: 0;
  font-weight: ${font.weight.bold};
  cursor: pointer;
`

const Notes = styled(FormField)`
  textarea {
    min-height: 122px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
` as IStyledFormField

const SettingsStep: FC<React.PropsWithChildren<IFormStep>> = ({ children, readOnly, viewer }) => {
  const intl = useIntl()
  const { user, account, hasPermission } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const [showingMarketingProfilesModal, setShowingMarketingProfilesModal] = useState(false)

  const showMarketingProfilesModal = useCallback(() => {
    setShowingMarketingProfilesModal(true)
  }, [])

  const closeMarketingProfilesModal = useCallback(() => {
    setShowingMarketingProfilesModal(false)
  }, [])

  const { values, initialValues, handleChange, handleBlur, errors, touched, setFieldValue } =
    useFormikContext<IEventFormSettings>()

  const { values: ticketValues } = useFormikContext<IEventFormTickets>()

  const trackData = useMemo(
    () => ({
      event_id: unwrapId(values.id),
      event_id_live: values.eventIdLive,
    }),
    [values.eventIdLive, values.id]
  )

  const {
    setCities,
    cityLoader,
    setBundles,
    bundleLoader,
    onToggleCoolingOffPeriod,
    onChangeCoolingOffPeriodHours,
    onChangeHideFromDiscovery,
  } = useSettingsFields()

  const { marketeerLoader, setMarketeers } = useMarketeers({ field: 'marketeers' })

  const { rawImage, setRawImage, brandLogo, setBrandLogo } = useSettingsBrandLogo()

  const toggleTicketNomination = useCallback(
    () => setFieldValue('requiresTicketNomination', !values.requiresTicketNomination),
    [setFieldValue, values.requiresTicketNomination]
  )

  const toggleBoxOfficeTicketNomination = useCallback(
    () => setFieldValue('requiresBoxOfficeTicketNomination', !values.requiresBoxOfficeTicketNomination),
    [setFieldValue, values.requiresBoxOfficeTicketNomination]
  )

  const toggleManualValidation = useCallback(
    () => setFieldValue('manualValidationEnabled', !values.manualValidationEnabled),
    [setFieldValue, values.manualValidationEnabled]
  )

  const toggleSendReceiptViaSms = useCallback(
    () => setFieldValue('sendReceiptViaSms', !values.sendReceiptViaSms),
    [setFieldValue, values.sendReceiptViaSms]
  )

  const toggleIsTest = useCallback(() => setFieldValue('isTest', !values.isTest), [setFieldValue, values.isTest])

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const { billingPromoter, nonBillingPromoters, setNonBillingPromoters } = usePromoters(viewer)

  const billingPromoterArr = useMemo(() => (billingPromoter ? [billingPromoter] : null), [billingPromoter])

  const { setTags, tagsLoader } = useMiscTags()

  const isItalian = useMemo(() => isItalianEvent(values, locale), [locale, values])

  const isNts =
    isItalian &&
    !(values.eventType === 'STREAM' && values.attractiveFields?.streamingTicketsIntegrationDisabled) &&
    !values.attractiveFields?.integrationDisabled

  const manualValidationReadOnly =
    readOnly ||
    (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'manualValidationEnabled'))

  const sendReceiptViaSmsReadOnly =
    readOnly || (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'sendReceiptViaSms'))

  useEffect(() => {
    if (manualValidationReadOnly || !isItalian) return

    if (values.manualValidationEnabled) {
      setFieldValue('manualValidationEnabled', false, true)
    }
  }, [isItalian, manualValidationReadOnly, setFieldValue, values.manualValidationEnabled])

  useEffect(() => {
    const canChange = !(
      readOnly ||
      (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
    )

    if (
      !isItalian ||
      !canChange ||
      !values.addressLocality ||
      values.eventType === 'STREAM' ||
      (values.cities && values.cities.length > 0)
    ) {
      return
    }

    let stillMounted = true

    cityLoader(values.addressLocality, (options) => {
      if (stillMounted && options.length > 0) {
        setCities(map('value', options[0].value), [options[0]])
      }
    })

    return () => {
      stillMounted = false
    }
  }, [
    cityLoader,
    isItalian,
    readOnly,
    setCities,
    values.addressLocality,
    values.allowedLifecycleUpdates,
    values.cities,
    values.eventType,
    values.state,
  ])

  const setLabels = useCallback(
    (
      v: Array<{
        value: string
        label: string | null
      } | null> | null
    ) => setFieldValue('labels', v),
    [setFieldValue]
  )

  const promoterLoader = useMemo(() => getPromoterLoader(environment), [environment])

  const diceCancelledEventReadonlyOverride = values.state === 'CANCELLED' && user.diceStaff
  const hasDeveloperSettings = user.diceStaff && hasFeatureFlag('devSettings')

  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const toggleTicketNominationDisclaimer = useCallback(() => setShowDisclaimer(!showDisclaimer), [showDisclaimer])

  const isPwlEnabled = getOr(false, 'flags.enabledPwl.active', values)
  const isPromotionsEnabled = getOr(false, 'flags.promotions.active', values)
  const isCodeLocked =
    getOr(false, 'flags.codeLocked.active', values) ||
    ticketValues.ticketTypes?.some((ticketType) => ticketType?.codeLocked)
  const isDoorSalesEnabled = ticketValues.ticketTypes?.some((ticketType) => ticketType?.doorSalesEnabled)
  const isSeatingEnabled = getOr(false, 'flags.seated.active', values)

  const isUnicornDisabled =
    isPwlEnabled || isPromotionsEnabled || isCodeLocked || isDoorSalesEnabled || isSeatingEnabled

  const unicornDisabledMessage = useMemo(() => {
    if (isPwlEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.unicorn.not_compatible_with_pwl' })
    }
    if (isPromotionsEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.unicorn.not_compatible_with_promotions' })
    }
    if (isCodeLocked) {
      return intl.formatMessage({ id: 'new_event.settings.unicorn.not_compatible_with_code_lock' })
    }
    if (isDoorSalesEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.unicorn.not_compatible_with_door_sales' })
    }
    if (isSeatingEnabled) {
      return intl.formatMessage({ id: 'new_event.settings.unicorn.not_compatible_with_seated' })
    }
    return ''
  }, [intl, isCodeLocked, isDoorSalesEnabled, isSeatingEnabled, isPwlEnabled, isPromotionsEnabled])

  return (
    <>
      <Form spacing={isMobile ? 'default' : 'extra'}>
        <FormHeader
          header={intl.formatMessage({ id: 'new_event.steps.settings' })}
          subheader={intl.formatMessage({
            id: !!values.eventSeatingChart
              ? 'new_event.settings.description_with_seating'
              : 'new_event.settings.description',
          })}
        />

        {hasPermission('read:bundle') && (
          <FormRow columnOnMobile>
            <FormField
              name="bundles"
              label={
                <>
                  <span>{intl.formatMessage({ id: 'new_event.settings.bundles.label' })}</span>
                  <Badge color="primary" className="ml-sm">
                    {intl.formatMessage({ id: 'new_event.step_badge.new' })}
                  </Badge>
                </>
              }
              hint={intl.formatMessage({ id: 'new_event.settings.bundles.hint' })}
              placeholder={intl.formatMessage({ id: 'new_event.settings.bundles.placeholder' })}
              control="select"
              async
              multiple
              searchable
              value={values.bundles || []}
              onChange={setBundles}
              onBlur={handleBlur}
              loadOptions={bundleLoader}
              error={touched.bundles && errors.bundles}
              disabled={readOnly}
            />
          </FormRow>
        )}

        <FormRow columnOnMobile>
          <FormField
            name="marketeers"
            label={intl.formatMessage({ id: 'new_event.settings.marketing.label' })}
            placeholder={intl.formatMessage({ id: 'new_event.settings.marketing.placeholder' })}
            hint={intl.formatMessage(
              {
                id: values?.marketeers?.length
                  ? 'new_event.settings.marketing.modal_hint'
                  : 'new_event.settings.marketing.without_modal_hint',
              },
              {
                a: (str: string) => (
                  <HintButton type="button" onClick={showMarketingProfilesModal}>
                    {str}
                  </HintButton>
                ),
              }
            )}
            control="select"
            async
            multiple
            searchable
            value={values.marketeers || []}
            onChange={setMarketeers}
            onBlur={handleBlur}
            loadOptions={marketeerLoader}
            error={touched.marketeers && errors.marketeers}
            disabled={
              values.state !== 'DRAFT' &&
              !hasPermission('full_manage_access:event') &&
              !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden')
            }
          />
        </FormRow>

        {showingMarketingProfilesModal ? (
          <EventMarketingProfilesModal
            marketeers={(values.marketeers as any) || []}
            onClose={closeMarketingProfilesModal}
          />
        ) : null}

        <EventDoorlist readOnly={readOnly} />

        <Suspense
          fallback={
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          }
        >
          <EventGuests readOnly={readOnly} viewer={viewer} />
        </Suspense>

        {user.diceStaff && (
          <>
            <FormRow columnOnMobile>
              <Notes
                name="billingNotes"
                label={intl.formatMessage({ id: 'new_event.settings.billing_notes.label' })}
                placeholder={intl.formatMessage({ id: 'new_event.settings.billing_notes.placeholder' })}
                value={values.billingNotes || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                disabled={readOnly && !diceCancelledEventReadonlyOverride}
                error={touched.billingNotes && errors.billingNotes}
                dice
              />
            </FormRow>
            <FormRow columnOnMobile>
              <Notes
                name="fanSupportNotes.body"
                label={intl.formatMessage({ id: 'new_event.settings.fan_support_notes.label' })}
                placeholder={intl.formatMessage({ id: 'new_event.settings.fan_support_notes.placeholder' })}
                value={values.fanSupportNotes?.body || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                disabled={readOnly && !diceCancelledEventReadonlyOverride}
                error={touched.fanSupportNotes && errors.fanSupportNotes}
                dice
              />
            </FormRow>
            <FormRow columnOnMobile>
              <Notes
                name="notes"
                label={intl.formatMessage({ id: 'new_event.settings.notes.label' })}
                placeholder={intl.formatMessage({ id: 'new_event.settings.notes.placeholder' })}
                value={values.notes || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                disabled={readOnly && !diceCancelledEventReadonlyOverride}
                error={touched.notes && errors.notes}
                dice
              />
            </FormRow>
          </>
        )}

        <FormRow columnOnMobile>
          <Notes
            name="extraNotes"
            label={intl.formatMessage({ id: 'new_event.settings.extra_notes.label' })}
            placeholder={intl.formatMessage({ id: 'new_event.settings.extra_notes.placeholder' })}
            help={intl.formatMessage({ id: 'new_event.settings.extra_notes.help' })}
            value={values.extraNotes || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            disabled={
              readOnly || (!user.diceStaff && !allowedEventAction(values.allowedLifecycleUpdates, 'extraNotes'))
            }
            error={touched.extraNotes && errors.extraNotes}
          />
        </FormRow>

        <EventLinks readOnly={readOnly} />

        {(hasPermission('manage_links:event') || !!values.allowedActions?.manageLinks || user.diceStaff) && (
          <FormRow columnOnMobile>
            <FormField
              name="tags"
              label={intl.formatMessage({ id: 'new_event.basics.misc_tags.label' })}
              control="select"
              multiple
              defaultOptions
              searchable
              async
              value={values.tags || []}
              loadOptions={tagsLoader}
              onBlur={handleBlur}
              onChange={setTags}
              disabled={readOnly}
              placeholder={intl.formatMessage({ id: 'new_event.basics.misc_tags.placeholder' })}
            />
          </FormRow>
        )}

        {(user.diceStaff ||
          hasPermission('full_manage_access:event') ||
          hasPermission('manage_tickets:event') ||
          !!values.allowedActions?.manageTickets) && (
          <FormRow columnOnMobile>
            {(initialValues.requiresTicketNomination || isItalian) && (
              <SwitchField
                label={intl.formatMessage({ id: 'new_event.settings.requires_ticket_nomination.label' })}
                hint={intl.formatMessage({ id: 'new_event.settings.requires_ticket_nomination.hint' })}
                name="requiresTicketNomination"
                checked={!!values.requiresTicketNomination}
                disabled={
                  (isNts && !user.diceStaff) ||
                  (isNts && values.attractiveFields?.compatibilityAe === '_5000') ||
                  readOnly ||
                  (values.state !== 'DRAFT' &&
                    !allowedEventAction(values.allowedLifecycleUpdates, 'requiresTicketNomination'))
                }
                onChange={toggleTicketNomination}
              />
            )}

            <SwitchField
              label={intl.formatMessage({ id: 'new_event.settings.requires_box_office_ticket_nomination.label' })}
              hint={intl.formatMessage(
                { id: 'new_event.settings.requires_box_office_ticket_nomination.hint' },
                {
                  a: (str: string | string[]) => (
                    <strong className="color-error cursor-pointer" onClick={toggleTicketNominationDisclaimer}>
                      {str}
                    </strong>
                  ),
                }
              )}
              name="requiresBoxOfficeTicketNomination"
              checked={!!values.requiresBoxOfficeTicketNomination}
              disabled={
                (isNts && !user.diceStaff) ||
                (isNts && values.attractiveFields?.compatibilityAe === '_5000') ||
                readOnly ||
                (values.state !== 'DRAFT' &&
                  !allowedEventAction(values.allowedLifecycleUpdates, 'requiresBoxOfficeTicketNomination'))
              }
              onChange={toggleBoxOfficeTicketNomination}
            />
            {showDisclaimer && <DoorlistDisclaimerModal onClose={toggleTicketNominationDisclaimer} />}
          </FormRow>
        )}
        {values.eventType !== 'STREAM' &&
          (user.diceStaff ||
            hasPermission('full_manage_access:event') ||
            hasPermission('manage_tickets:event') ||
            !!values.allowedActions?.manageTickets) && (
          <>
            <FormRow>
              <SwitchField
                label={intl.formatMessage({ id: 'new_event.settings.manual_validation_enabled.label' })}
                hint={intl.formatMessage({ id: 'new_event.settings.manual_validation_enabled.hint' })}
                name="manualValidationEnabled"
                checked={!!values.manualValidationEnabled}
                disabled={manualValidationReadOnly || isItalian}
                onChange={toggleManualValidation}
              />
            </FormRow>

            {user.diceStaff && (
              <FormRow>
                <SwitchField
                  label={intl.formatMessage({ id: 'new_event.settings.sms_receipt.label' })}
                  hint={intl.formatMessage({ id: 'new_event.settings.sms_receipt.hint' })}
                  name="sendReceiptViaSms"
                  checked={!!values.sendReceiptViaSms}
                  disabled={sendReceiptViaSmsReadOnly}
                  onChange={toggleSendReceiptViaSms}
                />
              </FormRow>
            )}

            <FormRow>
              <SwitchField
                label={
                  <>
                    {intl.formatMessage({ id: 'new_event.settings.cooling_off_period.label' })}
                    <TitleTooltip
                      title={intl.formatMessage({
                        id: 'new_event.settings.flags.cooling_off_period.tooltip',
                      })}
                    >
                      <TooltipHelpIcon icon="help" width={16} height={16} />
                    </TitleTooltip>
                  </>
                }
                hint={intl.formatMessage({ id: 'new_event.settings.cooling_off_period.hint' })}
                name="flags.coolingOffPeriod.active"
                checked={getOr(false, 'flags.coolingOffPeriod.active', values)}
                disabled={readOnly}
                onChange={onToggleCoolingOffPeriod}
                onBlur={handleBlur}
                error={get('flags.coolingOffPeriod.active', touched) && get('flags.coolingOffPeriod.active', errors)}
              />
            </FormRow>

            {getOr(false, 'flags.coolingOffPeriod.active', values) && (
              <NoMarginFormRow>
                <SmallFormField
                  name="flags.coolingOffPeriod.hours"
                  type="number"
                  min={COOLING_OFF_PERIOD_HOURS_LIMITS.min}
                  max={COOLING_OFF_PERIOD_HOURS_LIMITS.max}
                  step={1}
                  suffix="hours"
                  value={getOr(24, 'flags.coolingOffPeriod.hours', values) + ''}
                  hint={
                    !(get('flags.coolingOffPeriod.hours', touched) && get('flags.coolingOffPeriod.hours', errors)) &&
                      intl.formatMessage({ id: 'new_event.settings.flags.cooling_off_period.hours.hint' })
                  }
                  onChange={onChangeCoolingOffPeriodHours}
                  onBlur={handleBlur}
                  error={get('flags.coolingOffPeriod.hours', touched) && get('flags.coolingOffPeriod.hours', errors)}
                  disabled={readOnly}
                />
              </NoMarginFormRow>
            )}
          </>
        )}

        {user.diceStaff && (
          <FormRow>
            <SwitchField
              name="isTest"
              label={intl.formatMessage({ id: 'new_event.settings.is_test.label' })}
              hint={intl.formatMessage({ id: 'new_event.settings.is_test.hint' })}
              checked={!!values.isTest}
              onChange={toggleIsTest}
              onBlur={handleBlur}
              disabled={readOnly}
              dice
            />
          </FormRow>
        )}

        <Suspense
          fallback={
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          }
        >
          <EventThirdPartyAccess readOnly={readOnly} />
        </Suspense>

        {hasPermission('hide_from_discovery:event') && (
          <FormRow>
            <SwitchField
              name="hideFromDiscovery"
              label={intl.formatMessage({ id: 'new_event.settings.hide_from_discovery.label' })}
              hint={intl.formatMessage({ id: 'new_event.settings.hide_from_discovery.hint' })}
              checked={getOr(false, 'flags.hideFromDiscovery.active', values)}
              onChange={onChangeHideFromDiscovery}
              onBlur={handleBlur}
              disabled={readOnly}
            />
          </FormRow>
        )}

        {!values.eventSeatingChart && (
          <>
            <FormRow columnOnMobile>
              <FormGroup
                label={intl.formatMessage({ id: 'new_event.timeline.recurring.label' })}
                hint={intl.formatMessage({ id: 'new_event.timeline.recurring.hint' })}
                help={
                  values.recurrentEventSchedule?.frequency === 'MONTHLY'
                    ? intl.formatMessage({ id: 'new_event.settings.recurring_info' })
                    : undefined
                }
              />
            </FormRow>

            <FormRow columnOnMobile>
              <EventRecurring
                disabled={readOnly || !allowedEventAction(values.allowedLifecycleUpdates, 'recurringEvents')}
              />
            </FormRow>
          </>
        )}

        {(user.diceStaff || hasPermission('full_manage_access:event') || account?.allowSkipReview) && (
          <FormRow>
            <SwitchField
              name="flags.hidden.active"
              label={intl.formatMessage({ id: 'new_event.settings.flags.hidden.label' })}
              hint={intl.formatMessage({ id: 'new_event.settings.flags.hidden.hint' })}
              checked={getOr(false, 'flags.hidden.active', values)}
              onChange={handleChange}
              onBlur={handleBlur}
              error={get('flags.hidden.active', touched) && get('flags.hidden.active', errors)}
              disabled={readOnly && !diceCancelledEventReadonlyOverride}
              dice
            />
          </FormRow>
        )}

        {hasPermission('manage_load_predictions:event') && <EventLoadPredictions />}

        {user.diceStaff ? (
          <>
            <Labels
              dice
              readOnly={readOnly}
              promoterName={values.billingPromoter?.label}
              labels={values.labels}
              setLabels={setLabels}
            />

            <Collapsible label={intl.formatMessage({ id: 'admin_settings' })} dataId="settingsAdminSettings" dice>
              <PageViewTracker trackId="create_event_advanced_settings_displayed" trackData={trackData} />

              <FormRow columnOnMobile>
                <FormField
                  name="promoters"
                  label={intl.formatMessage({ id: 'new_event.settings.promoters.label' })}
                  placeholder={intl.formatMessage({ id: 'new_event.settings.promoters.placeholder' })}
                  control="select"
                  async
                  multiple
                  searchable
                  readOnlyValues={billingPromoterArr}
                  value={nonBillingPromoters}
                  onChange={setNonBillingPromoters}
                  onBlur={handleBlur}
                  loadOptions={promoterLoader}
                  error={touched.promoters && errors.promoters}
                  required
                  disabled={readOnly}
                />
              </FormRow>

              <FormRow columnOnMobile>
                <EventImageUploader
                  mimeType="image/png"
                  label={intl.formatMessage({ id: 'new_event.settings.brand_logo.label' })}
                  minSizeLabel={intl.formatMessage(
                    { id: 'new_event.tickets.ticket_type_edit.brand_logo.min_size' },
                    { b: (str: string) => <strong>{str}</strong> }
                  )}
                  fileFormatLabel={intl.formatMessage(
                    { id: 'new_event.tickets.ticket_type_edit.brand_logo.file_format' },
                    { b: (str: string) => <strong>{str}</strong> }
                  )}
                  existingFileLabel={intl.formatMessage({
                    id: 'new_event.tickets.ticket_type_edit.brand_logo.existing_image',
                  })}
                  fileFormatErrorSuggestion="PNG"
                  rawImage={rawImage || null}
                  setRawImage={setRawImage}
                  images={brandLogo}
                  setImages={setBrandLogo}
                  disablePreviews
                  previewType="brand"
                  allowedImageTypes={LOGO_IMAGE_TYPES}
                  error={errors.rawBrandLogo}
                  allowEdit={
                    !readOnly && (user.diceStaff || allowedEventAction(values.allowedLifecycleUpdates, 'eventImages'))
                  }
                />
              </FormRow>

              <FormRow columnOnMobile>
                <FormField
                  name="cities"
                  label={intl.formatMessage({ id: 'new_event.settings.cities.label' })}
                  placeholder={intl.formatMessage({ id: 'new_event.settings.cities.placeholder' })}
                  control="select"
                  async
                  multiple
                  searchable
                  value={values.cities || []}
                  onChange={setCities}
                  onBlur={handleBlur}
                  loadOptions={cityLoader}
                  error={touched.cities && errors.cities}
                  disabled={readOnly}
                />
              </FormRow>

              <FormRow>
                <Placeholder />
              </FormRow>
            </Collapsible>
          </>
        ) : (
          <Placeholder />
        )}

        {hasDeveloperSettings && (
          <Collapsible
            label={intl.formatMessage({ id: 'new_event.settings.developer_settings' })}
            dataId="settingsDeveloperSettings"
            dice
          >
            <FormRow columnOnMobile>
              <SwitchField
                label={intl.formatMessage({ id: 'new_event.settings.developer_settings.unicorn.label' })}
                hint={intl.formatMessage({ id: 'new_event.settings.developer_settings.unicorn.hint' })}
                name="flags.unicorn.active"
                checked={getOr(false, 'flags.unicorn.active', values)}
                onChange={handleChange}
                disabled={isUnicornDisabled || values.state !== 'DRAFT'}
                title={unicornDisabledMessage}
              />
            </FormRow>
          </Collapsible>
        )}

        {children}
      </Form>
      {values.state === 'DRAFT' && <EventPreview eventId={values.id} />}
    </>
  )
}

export default createFragmentContainer(memo(SettingsStep), {
  event: graphql`
    fragment Settings_event on Event {
      id
      isTest
      eventIdLive
      previewToken
      state
      date
      endDate
      onSaleDate
      offSaleDate
      announceDate
      timezoneName
      notes
      billingNotes
      fanSupportNotes {
        body
      }
      extraNotes
      requiresTicketNomination
      requiresBoxOfficeTicketNomination
      manualValidationEnabled
      addressCountry
      countryCode

      doorlistSendStatus
      offSaleSentStatus
      doorlistSendAt
      offSaleSentAt

      allowedActions {
        inviteExternalGuest
        manageLinks
        manageTickets
      }

      allowedLifecycleUpdates {
        extraNotes {
          canUpdate
        }
        links {
          canUpdate
        }
        recurringEvents {
          canUpdate
        }
        requiresTicketNomination {
          canUpdate
        }
        requiresBoxOfficeTicketNomination {
          canUpdate
        }
        manualValidationEnabled {
          canUpdate
        }
        sendReceiptViaSms {
          canUpdate
        }
      }
      sendReceiptViaSms
      attractiveFields {
        compatibilityAe
      }
      thirdPartySettings {
        value: id
        label: appName
      }
      eventSeatingChart {
        id
        seatsIoEventId
        chartManagerCredentials {
          secretKey
        }
        seatingChannels {
          seatsIoChannel
          channelType
          name
        }
        venueChart {
          value: id
          label: name
        }
      }

      billingPromoter {
        value: id
        label: name
        stripeAccountId
        stripeFallbackAccountId
        stripeFallbackPlatformCode
        platformAccountCode
        showPriceSuggestions
        addressCountry
        countryCode
        accountId
        allowSkipReview
        resoldEnabled
        disableUsTax
        holdPayouts
        coolingOffPeriod
        sendReceiptViaSms
        tags {
          value: id
          label: name
        }
        labels {
          value: id
          label: name
        }
        associatedMarketeers {
          value: id
          label: name
        }
        billingNotes
      }
      promoters {
        value: id
        label: name
        stripeAccountId
        stripeFallbackAccountId
        stripeFallbackPlatformCode
        platformAccountCode
        showPriceSuggestions
        addressCountry
        countryCode
        accountId
        allowSkipReview
        resoldEnabled
        disableUsTax
        holdPayouts
        coolingOffPeriod
        sendReceiptViaSms
        tags {
          value: id
          label: name
        }
        labels {
          value: id
          label: name
        }
        associatedMarketeers {
          value: id
          label: name
        }
        billingNotes
      }
      venues {
        value: id
        addressCountry
        countryCode
      }
      marketeers {
        value: id
        label: name
        fbAccessToken
        fbPixelId
        gaTrackingId
        googleAdsConversionId
        googleAdsPurchaseConversionLabel
        privacyPolicyLink
        tiktokPixelId
        twitterCheckoutInitiatedPixelId
        twitterPixelId
        twitterPurchasePixelId
        webOptInEnabled
        appOptInEnabled
      }
      labels {
        value: id
        label: name
      }
      ticketType
      eventSharingObjects {
        id
        email
        permissionProfile {
          id
        }
      }
      doorlistRecipients {
        id
        email
      }
      doorlistAdditionalRecipients
      flags {
        hidden
        coolingOffPeriod
        claimTickets
        unicorn
        shoppingCart
        hideFromDiscovery
      }
      cities {
        value: id
        label: name
      }
      bundles {
        value: id
        label: name
      }
      eventImages {
        id
        type
        cdnUrl
        attachment {
          id
        }
        cropRegion {
          x
          y
          width
          height
        }
      }
      recurrentEventsGroup {
        id
        date
      }
      recurrentEventSchedule {
        frequency
        occurrences
        repeatEnds
        repeatOn
        until
      }
      links {
        name
        url
      }
      tags {
        value: id
        label: name
      }
      eventLoadPredictions {
        id
        expectedRequestsPerMinute
        expectedStartTime
      }
    }
  `,
  viewer: graphql`
    fragment Settings_viewer on Viewer {
      ...usePromoters_viewer
      ...EventGuests_viewer
    }
  `,
})
