import React, { FC, memo, useContext, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useFormikContext } from 'formik'
import { compact, compose, concat, groupBy, isNil, map, reject, some, values as lodashValues } from 'lodash/fp'
import { useIntl } from 'react-intl'

import IEventForm from '../types'
import Table, { Cell, Row, TableBody, TableFooter, TableHeader } from '../../../components/Table'
import { color } from '../../../utils/variables'
import { CURRENCY } from '../../../utils/formatters/number'
import FeeBreakdownTooltip from '../../../components/Event/FeeBreakdownTooltip'
import { IFee, IPriceTier, ITicketType } from '../types/Tickets'
import usePriceBreakdown, { IBreakdownInput } from '../hooks/usePriceBreakdown'
import { EventCostCurrency, FeesBehaviour } from '../../../enums.generated'
import checkOverallocation from '../../../utils/checkOverallocation'
import { localeContext } from '../../../context/locale'
import PriceBreakdownTooltip from '../../../components/Event/PriceBreakdownTooltip'

const Container = styled.div<{ isLoading?: boolean }>`
  padding: 6px 16px;
  background-color: ${color.palegrey};
  border-radius: 8px;
`

const TTRow = styled(Row)<{ isLoading?: boolean }>`
  ${Row} + & {
    border-top: 1px solid ${color.lightgrey};
  }
  ${({ isLoading }) =>
    isLoading &&
    `
    filter: blur(3px);
  `}
`

const StyledTable = styled(Table)`
  table-layout: initial;
  width: 100%;
  word-break: break-word;

  tbody + tbody {
    border-top: 1px solid ${color.text};
  }

  td {
    width: 17%;
    padding: 10px 0;
    white-space: initial;
  }

  td:first-child {
    width: 32%;
  }

  td + td {
    padding-left: 16px;
  }
`

const StyledTableHeader = styled(TableHeader)`
  border-bottom: 1px solid ${color.lightgrey};
  color: ${color.darkgrey};
`

const StyledTableFooter = styled(TableFooter)`
  border-top: 2px solid ${color.text};
`

const Clamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TicketSummaryRow: FC<
  React.PropsWithChildren<{
    tt: ITicketType
    ttIdx: number
    eventFees: Array<IFee | null> | null
    basePriceFees: Array<string | null> | null
    postFanPriceFees: Array<string | null> | null
    feesBehaviour: FeesBehaviour | null
    currency: EventCostCurrency | null
    venueIds: string[]
    eventId: string | null
    promoterId: string | null
    showPriceBreakdown: boolean
    disableUsTax: boolean
  }>
> = ({
  tt,
  ttIdx,
  eventFees,
  basePriceFees,
  postFanPriceFees,
  feesBehaviour,
  currency,
  venueIds,
  eventId,
  promoterId,
  showPriceBreakdown,
  disableUsTax,
}) => {
  const intl = useIntl()

  const breakdownInputs: IBreakdownInput[] = useMemo(() => {
    if (tt.priceTierType === null) {
      return [
        {
          initialBreakdown: tt.priceBreakdown,
          faceValue: tt.faceValue || 0,
          fee: concat(eventFees || [], tt.fees || []) || [],
          forcePwlActive: tt.priceBreakdown
            ? !isNil(tt.priceBreakdown.totalWithPwl) && tt.priceBreakdown.totalWithPwl === tt.priceBreakdown.total
            : null,
        } as IBreakdownInput,
      ]
    }

    return map(
      (pt: IPriceTier | null) =>
        ({
          initialBreakdown: pt?.priceBreakdown || null,
          faceValue: pt?.faceValue || 0,
          fee: concat(eventFees || [], concat(tt.fees || [], pt?.fees || [])),
          forcePwlActive: pt?.priceBreakdown
            ? !isNil(pt.priceBreakdown.totalWithPwl) && pt.priceBreakdown.totalWithPwl === pt.priceBreakdown.total
            : null,
        } as IBreakdownInput),
      tt.priceTiers || []
    )
  }, [eventFees, tt.faceValue, tt.fees, tt.priceBreakdown, tt.priceTierType, tt.priceTiers])

  const breakdownCtx = useMemo(
    () => ({
      venueIds,
      eventId: feesBehaviour === 'OVERRIDE' ? null : eventId,
      billingPromoterId: feesBehaviour === 'OVERRIDE' ? null : promoterId,
      basePriceFees: feesBehaviour === 'OVERRIDE' ? basePriceFees : null,
      postFanPriceFees: feesBehaviour === 'OVERRIDE' ? postFanPriceFees : null,
      disableUsTax,
    }),
    [basePriceFees, disableUsTax, eventId, feesBehaviour, postFanPriceFees, promoterId, venueIds]
  )

  const { loading, priceBreakdowns } = usePriceBreakdown(breakdownInputs, breakdownCtx)

  const hasRebate = useMemo(() => some('rebate', priceBreakdowns || []), [priceBreakdowns])

  return (
    <>
      <TTRow isLoading={loading} data-id={`ticketSummary.ticketType[${ttIdx}]`}>
        <Cell bold data-id={`ticketSummary.ticketType[${ttIdx}].name`}>
          <Clamp>{tt.name}</Clamp>
        </Cell>

        {(tt.priceTiers?.length || 0) > 0 ? (
          <>
            {!tt.ticketPoolId && <Cell />}
            <Cell />
            {showPriceBreakdown && (
              <>
                <Cell />
                <Cell />
              </>
            )}
          </>
        ) : (
          <>
            {!tt.ticketPoolId && (
              <Cell textAlign="right" data-id={`ticketSummary.ticketType[${ttIdx}].allocation`}>
                {intl.formatNumber(tt.allocation || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Cell>
            )}

            <Cell textAlign="right" data-id={`ticketSummary.ticketType[${ttIdx}].price`}>
              {intl.formatNumber((tt.faceValue || 0) / 100, CURRENCY(tt.faceValue, currency))}
            </Cell>

            {showPriceBreakdown && (
              <>
                <Cell textAlign="right" data-id={`ticketSummary.ticketType[${ttIdx}].fees`}>
                  <FeeBreakdownTooltip
                    trigger={intl.formatNumber(
                      ((priceBreakdowns[0]?.total || 0) - (tt.faceValue || 0)) / 100,
                      CURRENCY((priceBreakdowns[0]?.total || 0) - (tt.faceValue || 0), currency)
                    )}
                    currency={currency}
                    priceBreakdown={priceBreakdowns[0]}
                    hasRebate={hasRebate}
                  />
                </Cell>

                <Cell textAlign="right" data-id={`ticketSummary.ticketType[${ttIdx}].total`}>
                  <PriceBreakdownTooltip currency={currency} priceBreakdown={priceBreakdowns[0]} />
                </Cell>
              </>
            )}
          </>
        )}
      </TTRow>
      {tt.priceTiers?.map(
        (pt, idx) =>
          pt && (
            <Row key={pt.id} data-id={`ticketSummary.ticketType[${ttIdx}].priceTier[${idx}]`}>
              <Cell data-id={`ticketSummary.ticketType[${ttIdx}].priceTier[${idx}].name`}>{pt.name}</Cell>

              {tt.ticketPoolId || (tt.priceTierType === 'time' && idx > 0) ? null : (
                <Cell
                  textAlign="right"
                  rowSpan={tt.priceTierType === 'time' ? tt.priceTiers?.length || 0 : undefined}
                  verticalAlign={tt.priceTierType === 'time' ? 'middle' : undefined}
                  data-id={`ticketSummary.ticketType[${ttIdx}].priceTier[${idx}].allocation`}
                >
                  {intl.formatNumber((tt.priceTierType === 'time' ? tt.allocation : pt.allocation) || 0, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Cell>
              )}

              <Cell textAlign="right" data-id={`ticketSummary.ticketType[${ttIdx}].priceTier[${idx}].price`}>
                {intl.formatNumber((pt.faceValue || 0) / 100, CURRENCY(pt.faceValue, currency))}
              </Cell>

              {showPriceBreakdown && (
                <>
                  <Cell textAlign="right" data-id={`ticketSummary.ticketType[${ttIdx}].priceTier[${idx}].fees`}>
                    <FeeBreakdownTooltip
                      trigger={intl.formatNumber(
                        ((priceBreakdowns[idx]?.total || 0) - (pt.faceValue || 0)) / 100,
                        CURRENCY((priceBreakdowns[idx]?.total || 0) - (pt.faceValue || 0), currency)
                      )}
                      currency={currency}
                      priceBreakdown={priceBreakdowns[idx]}
                      hasRebate={hasRebate}
                    />
                  </Cell>

                  <Cell textAlign="right" data-id={`ticketSummary.ticketType[${ttIdx}].priceTier[${idx}].total`}>
                    <PriceBreakdownTooltip currency={currency} priceBreakdown={priceBreakdowns[idx]} />
                  </Cell>
                </>
              )}
            </Row>
          )
      )}
    </>
  )
}

interface IProps {
  maxSeatsCapacity: number | null
}

const EventTicketSummary: FC<React.PropsWithChildren<IProps>> = ({ maxSeatsCapacity }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const { values } = useFormikContext<IEventForm>()

  const ticketPools = useMemo(
    () =>
      compose([lodashValues, groupBy('ticketPoolId'), reject((tt: any) => tt.archived), compact])(
        values.ticketTypes || []
      ),
    [values.ticketTypes]
  )

  const venueIds: string[] = useMemo(() => compact(map('value', values.venues || [])), [values.venues])

  const showPriceBreakdown = !!values.billingPromoter?.showPriceSuggestions

  const { overallocation, venueCapacity, totalAllocationLive, totalAllocationStream } = useMemo(
    () => checkOverallocation(values, locale, maxSeatsCapacity || undefined),
    [locale, maxSeatsCapacity, values]
  )

  const hasTicketPools = (values.ticketPools?.length || 0) > 0

  return (
    <Container>
      <StyledTable>
        <StyledTableHeader>
          <Row>
            <Cell>{intl.formatMessage({ id: 'tickets' })}</Cell>
            {!hasTicketPools && <Cell textAlign="right">{intl.formatMessage({ id: 'allocation' })}</Cell>}
            <Cell textAlign="right">{intl.formatMessage({ id: 'price' })}</Cell>
            {showPriceBreakdown && (
              <>
                <Cell textAlign="right">{intl.formatMessage({ id: 'fees' })}</Cell>
                <Cell textAlign="right">
                  {intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.price_suggestion.fan_price' })}
                </Cell>
              </>
            )}
          </Row>
        </StyledTableHeader>
        {ticketPools.map((pool: ITicketType[], poolIdx: number) => (
          <TableBody key={`ticketPool[${poolIdx}]`}>
            {pool.map((tt: ITicketType, ttIdx: number) => (
              <TicketSummaryRow
                key={tt.id}
                tt={tt}
                ttIdx={ttIdx}
                currency={values.costCurrency}
                venueIds={venueIds}
                eventId={values.id}
                promoterId={values.billingPromoter?.value || null}
                feesBehaviour={values.feesBehaviour}
                basePriceFees={values.basePriceFees}
                postFanPriceFees={values.postFanPriceFees}
                eventFees={values.fees}
                showPriceBreakdown={showPriceBreakdown}
                disableUsTax={!!values.disableUsTax}
              />
            ))}
          </TableBody>
        ))}
        {!hasTicketPools && (
          <StyledTableFooter>
            <Row>
              <Cell>{intl.formatMessage({ id: 'new_event.tickets.ticket_summary.total_allocation' })}</Cell>
              <Cell textAlign="right" data-id="ticketSummary.totalAllocation">
                {overallocation > 0 ? (
                  <>
                    <strong className="color-error">
                      {intl.formatNumber(totalAllocationLive, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </strong>
                    <span className="color-greyer">
                      {'/'}
                      {intl.formatNumber(venueCapacity, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                    {totalAllocationStream > 0 && (
                      <>
                        {' + '}
                        {intl.formatNumber(totalAllocationStream, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {intl.formatNumber(totalAllocationLive + totalAllocationStream, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </>
                )}
              </Cell>
              <Cell />
              {showPriceBreakdown && (
                <>
                  <Cell />
                  <Cell />
                </>
              )}
            </Row>
          </StyledTableFooter>
        )}
      </StyledTable>
    </Container>
  )
}

export default memo(EventTicketSummary)
