import { ChartDataset } from 'chart.js'
import { compact, concat, every, flatMap, map, snakeCase, uniqBy } from 'lodash/fp'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { IOptions } from '../types/options'
import { COLOR_SCHEME } from '../utils/colors'
import { IAggregatedData } from './useAggregatedData'

interface IProps {
  options: IOptions
  data: IAggregatedData
  labels: string[]

  showExtras: boolean
  showMerch: boolean
  showViews: boolean
}

function useDataConfig({ options, data, labels, showExtras, showMerch, showViews }: IProps) {
  const intl = useIntl()

  const datasetTypes: Array<'tickets' | 'extras' | 'merch'> = useMemo(
    () => compact(['tickets', showExtras ? 'extras' : null, showMerch ? 'merch' : null]),
    [showExtras, showMerch]
  )

  const dataConfig = useMemo(() => {
    const values = concat(options.mode === 'revenue' ? ['faceValue'] : ['sold'], showViews ? ['views'] : []) as Array<
      'sold' | 'faceValue' | 'views'
    >

    return {
      datasets: flatMap((v) => {
        const datasets = map((ds) => {
          const color = v === 'views' ? COLOR_SCHEME.event[v] : COLOR_SCHEME[ds][v]

          return {
            id: v === 'views' ? `event:${v}` : `${ds}:${v}`,
            type: v === 'views' ? 'line' : 'bar',
            order: v === 'views' ? 0 : 100,
            label:
              // prettier-ignore
              v === 'views'
                ? intl.formatMessage({ id: 'event_views' })
                : datasetTypes.length > 1
                  ? [intl.formatMessage({ id: ds }), intl.formatMessage({ id: snakeCase(v) }).toLowerCase()]
                    .join(' ')
                    .trim()
                  : intl.formatMessage({
                    id: snakeCase(v),
                  }),

            data: data[ds][v] as any,
            labels,

            hidden: every(['y', 0], data[ds][v]),

            yAxisID: v === 'sold' || v === 'views' ? v : 'money',
            cubicInterpolationMode: 'monotone',
            spanGaps: true,
            pointRadius: 0,
            borderCapStyle: 'round',
            borderWidth: v !== 'views' ? 1 : 2,
            borderRadius: 2,
            minBarLength: 0,
            barThickness: 20,
            borderColor: color,
            backgroundColor: color,
            hoverColor: color,
          } as ChartDataset<'line' | 'bar'> & { id: string }
        }, datasetTypes)

        return uniqBy('id', datasets)
      }, values),
    } as const
  }, [data, datasetTypes, intl, labels, options.mode, showViews])

  return dataConfig
}

export default useDataConfig
