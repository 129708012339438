import { map, reject } from 'lodash/fp'
import { nanoid } from 'nanoid'
import graphql from 'babel-plugin-relay/macro'
import { commitMutation, Environment } from 'react-relay'
import { DeepReadonly } from 'ts-essentials'

import { hideUnhideTicketTypeMutation } from '../../../__generated__/hideUnhideTicketTypeMutation.graphql'
import { assertServerCompatibleDeep } from '../../../utils/entityStatus'
import { PriceTierTypes } from '../../../enums.generated'

interface ITTY {
  id: string
  archived: boolean | null
  hidden: boolean | null
  onSaleDate: string | null
  offSaleDate: string | null
  priceTierType: null | PriceTierTypes
}

const hideUnhideTicketType = async (
  environment: Environment,
  eventId: string,
  ttyId: string,
  hidden: boolean,
  ticketTypes: DeepReadonly<ITTY[]>,
  lockVersion: number
) => {
  await new Promise((resolve, rejectFn) =>
    commitMutation<hideUnhideTicketTypeMutation>(environment, {
      mutation: graphql`
        mutation hideUnhideTicketTypeMutation($input: MinorUpdateEventInput!) {
          minorUpdateEvent(input: $input) {
            event {
              ...TicketBreakdown_event
            }
          }
        }
      `,
      variables: {
        input: assertServerCompatibleDeep({
          clientMutationId: nanoid(),
          id: eventId,
          lockVersion,
          ticketTypes: map(
            (tt: ITTY) => (ttyId === tt.id ? { id: tt.id, hidden: !hidden } : { id: tt.id }),
            reject('archived', ticketTypes)
          ),
        }),
      },
      onCompleted: resolve,
      onError: rejectFn,
    })
  )
}

export default hideUnhideTicketType
