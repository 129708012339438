/**
 * @generated SignedSource<<8f3e2b379643c215c89a198a351528ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnumAccountUserInvitaionStatus = "ACCEPTED" | "DECLINED" | "PENDING" | "%future added value";
export type InviteUserInput = {
  accountId: string;
  clientMutationId: string;
  email: string;
  permissionProfileId: string;
};
export type useInviteUserMutation$variables = {
  input: InviteUserInput;
};
export type useInviteUserMutation$data = {
  readonly inviteUser: {
    readonly messages: ReadonlyArray<{
      readonly code: string;
      readonly field: string | null;
      readonly message: string | null;
    } | null> | null;
    readonly result: {
      readonly email: string;
      readonly id: string;
      readonly permissionProfile: {
        readonly usedBy: number;
      } | null;
      readonly status: EnumAccountUserInvitaionStatus | null;
      readonly user: {
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null;
    readonly successful: boolean;
  } | null;
};
export type useInviteUserMutation = {
  response: useInviteUserMutation$data;
  variables: useInviteUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationMessage",
  "kind": "LinkedField",
  "name": "messages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usedBy",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "successful",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useInviteUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUserPayload",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountUserInvitation",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PermissionProfile",
                "kind": "LinkedField",
                "name": "permissionProfile",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useInviteUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUserPayload",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountUserInvitation",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PermissionProfile",
                "kind": "LinkedField",
                "name": "permissionProfile",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6699c671357c11f1b39323f96718217",
    "id": null,
    "metadata": {},
    "name": "useInviteUserMutation",
    "operationKind": "mutation",
    "text": "mutation useInviteUserMutation(\n  $input: InviteUserInput!\n) {\n  inviteUser(input: $input) {\n    messages {\n      code\n      field\n      message\n    }\n    result {\n      id\n      email\n      status\n      user {\n        id\n        name\n      }\n      permissionProfile {\n        usedBy\n        id\n      }\n    }\n    successful\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d55ab3cdb0207791537fd31a2ff31ae";

export default node;
