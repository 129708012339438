/**
 * @generated SignedSource<<859a291459017c5a891a61b2506bee54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteNodeInput = {
  clientMutationId: string;
  id: string;
};
export type useDeleteEventMutation$variables = {
  input: DeleteNodeInput;
};
export type useDeleteEventMutation$data = {
  readonly deleteNode: {
    readonly id: string;
  } | null;
};
export type useDeleteEventMutation = {
  response: useDeleteEventMutation$data;
  variables: useDeleteEventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteNodePayload",
    "kind": "LinkedField",
    "name": "deleteNode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteEventMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e067ce82b2eaa408a7e482456e14247f",
    "id": null,
    "metadata": {},
    "name": "useDeleteEventMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteEventMutation(\n  $input: DeleteNodeInput!\n) {\n  deleteNode(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ffe21bc809e72fc9064514fd66e643b";

export default node;
