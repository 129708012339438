// eslint-disable-next-line lodash-fp/use-fp
import { random } from 'lodash'
import { times } from 'lodash/fp'

const subscriptionCode = () =>
  times(() => random(35).toString(36), 4)
    .join('')
    .toUpperCase()

export default subscriptionCode
