/**
 * @generated SignedSource<<b9791a982196cf8d05a9c94b825a0e01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardEvents_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountDeactivatedBanner_viewer" | "OnSaleToday_viewer" | "Priority_viewer" | "ReadyForPayoutBanner_viewer" | "RecentlyOnSale_viewer">;
  readonly " $fragmentType": "DashboardEvents_viewer";
};
export type DashboardEvents_viewer$key = {
  readonly " $data"?: DashboardEvents_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardEvents_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "onSaleWhere"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardEvents_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnSaleToday_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Priority_viewer"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "onSaleWhere"
        }
      ],
      "kind": "FragmentSpread",
      "name": "RecentlyOnSale_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReadyForPayoutBanner_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountDeactivatedBanner_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "37a9cb10db142b175e5a32d800ff2ce3";

export default node;
