import { isAfter, parseISO } from 'date-fns'
import { PromotionAccessType } from '../enums.generated'

export const getPromotionStatusColor = (status: string) => {
  switch (status) {
    case 'upcoming':
      return 'primary'
    case 'ongoing':
    case 'complete':
      return 'secondary'
    default:
      return 'grey'
  }
}

export const getPromotionStatus = (
  event: { onSaleDate: string | null; offSaleDate: string | null },
  promotion: {
    startDate: string | null
    isEnded: boolean
    endDate: string | null
    accessType: PromotionAccessType
    timesUsedUnique: number
    codeLocks: {
      count: number
    } | null
  } | null
) => {
  const now = new Date()
  let status = 'upcoming'

  if (!event.onSaleDate || !event.offSaleDate) {
    return status
  }

  if (promotion?.startDate ? isAfter(now, parseISO(promotion.startDate)) : isAfter(now, parseISO(event.onSaleDate))) {
    status = 'ongoing'
  }
  if (
    promotion?.accessType === 'UNIQUE_CODE' &&
    promotion?.timesUsedUnique &&
    promotion.timesUsedUnique >= (promotion.codeLocks?.count || 0)
  ) {
    status = 'complete'
  }
  if (
    promotion?.isEnded ||
    (promotion?.endDate ? isAfter(now, parseISO(promotion.endDate)) : isAfter(now, parseISO(event.offSaleDate)))
  ) {
    status = 'ended'
  }
  return status
}
