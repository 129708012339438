import React, { useCallback, memo, FC } from 'react'
import { get } from 'lodash/fp'
import { useFormikContext } from 'formik'

import { useIntl } from 'react-intl'
import { FormRow } from '../../../components/Form'
import SwitchField from '../../../components/SwitchField'
import IEventFormTickets from '../types/Tickets'

interface IProps {
  readOnly?: boolean
}

const EventHasTicketsOnDoor: FC<IProps> = ({ readOnly }) => {
  const intl = useIntl()

  const { values, touched, errors, setFieldValue, handleBlur } = useFormikContext<IEventFormTickets>()

  const onChangeTicketTransfer = useCallback(() => {
    setFieldValue('isTicketAvailableAtDoor', !values.isTicketAvailableAtDoor)
  }, [values, setFieldValue])

  return (
    <>
      <FormRow columnOnMobile>
        <SwitchField
          label={intl.formatMessage({ id: 'new_event.settings.tickets_available_on_door.label' })}
          hint={intl.formatMessage({ id: 'new_event.settings.tickets_available_on_door.hint' })}
          error={touched.isTicketAvailableAtDoor && get('isTicketAvailableAtDoor', errors)}
          name="isTicketAvailableAtDoor"
          checked={!!values.isTicketAvailableAtDoor}
          onChange={onChangeTicketTransfer}
          onBlur={handleBlur}
          disabled={readOnly}
        />
      </FormRow>
    </>
  )
}

export default memo(EventHasTicketsOnDoor)
