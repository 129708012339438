import React, { FC, memo, useMemo } from 'react'
import cn from 'classnames'
import styled from 'styled-components/macro'
import { get, find, compose } from 'lodash/fp'

import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'

import { color, mediaQuery } from '../../../../utils/variables'

import Svg from '../../../../components/Svg'
import { EventImage_event$key } from '../../../../__generated__/EventImage_event.graphql'

const EventImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 16px;
  position: relative;
  flex: 160px 0 0;
  width: 160px;
  height: 160px;
  overflow: hidden;
  margin-bottom: auto;
  &.-no-image {
    background: ${color.white};
    color: ${color.text};
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:before {
      display: none;
    }
  }
  &:before {
    content: '';
    display: block;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  ${mediaQuery.lessThan('tablet')`
    margin-top: 16px;
  `}
`
const EventImageTag = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
`

const NoImage = styled(Svg)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

interface IProps {
  event: EventImage_event$key
}

const EventImage: FC<IProps> = ({ event: eventKey }) => {
  const event = useFragment(
    graphql`
      fragment EventImage_event on Event {
        eventImages {
          type
          cdnUrl
        }
      }
    `,
    eventKey
  )

  const imgUrl = useMemo(
    () =>
      compose(
        (url) => (url ? url + '&h=200&w=200&auto=compress' : undefined),
        get('cdnUrl'),
        find(['type', 'square'])
      )(event.eventImages),
    [event.eventImages]
  )

  return (
    <EventImageWrapper className={cn({ '-no-image': !imgUrl })}>
      {imgUrl ? <EventImageTag src={imgUrl} /> : <NoImage icon="image-placeholder" />}
    </EventImageWrapper>
  )
}

export default memo(EventImage)
