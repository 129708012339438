/**
 * @generated SignedSource<<aa0be9cd3b43198682722c6c970c2206>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CategoryType = "ACCESS_PASS" | "AFTER_PARTY_PASS" | "ARTIST_MEET_AND_GREET" | "CAMPING" | "COACH_BUS" | "DRINKS_ALCOHOLIC" | "DRINKS_NON_ALCOHOLIC" | "EARLY_ENTRY_PASS" | "EXPERIENCE" | "FOOD" | "FOOD_AND_DRINK" | "JUMPER" | "MERCH" | "OTHER" | "PARKING" | "QUEUE_JUMP" | "TRAVEL_AND_ACCOMMODATION" | "T_SHIRT" | "VIP_UPGRADE" | "%future added value";
export type ProductRootType = "EXTRAS" | "MERCH" | "%future added value";
export type EventProductModalProductCategoriesQuery$variables = Record<PropertyKey, never>;
export type EventProductModalProductCategoriesQuery$data = {
  readonly viewer: {
    readonly options: ReadonlyArray<{
      readonly categories: ReadonlyArray<{
        readonly coverBackgroundColor: string | null;
        readonly coverImageUrl: string | null;
        readonly label: string;
        readonly rootType: ProductRootType | null;
        readonly type: CategoryType | null;
        readonly value: string;
      } | null>;
      readonly label: string;
      readonly type: CategoryType | null;
      readonly value: string;
    } | null> | null;
  } | null;
};
export type EventProductModalProductCategoriesQuery = {
  response: EventProductModalProductCategoriesQuery$data;
  variables: EventProductModalProductCategoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverBackgroundColor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventProductModalProductCategoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "productCategories",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventProductModalProductCategoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "productCategories",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84947ef6c83afa9a2703f2badcd473c0",
    "id": null,
    "metadata": {},
    "name": "EventProductModalProductCategoriesQuery",
    "operationKind": "query",
    "text": "query EventProductModalProductCategoriesQuery {\n  viewer {\n    options: productCategories {\n      value: id\n      label: name\n      type\n      categories {\n        value: id\n        label: name\n        rootType\n        type\n        coverBackgroundColor\n        coverImageUrl\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfb275a2ab88444736c343c8223f0139";

export default node;
