import React, { FC } from 'react'
import styled from 'styled-components/macro'

import { IMaxPermissionAction } from '../utils/structure'
import Checkbox from '../../../components/Checkbox'
import { TitleTooltip, TooltipHelpIcon } from '../../../components/Tooltip'
import { mediaQuery, color } from '../../../utils/variables'

const ActionContainer = styled.div`
  display: flex;
  align-items: center;

  min-width: 50%;

  padding: 8px 12px;

  ${TooltipHelpIcon} {
    margin: 0 0 0 8px;
  }

  ${mediaQuery.lessThan('tablet')`
    min-width: 100%;
  `}
`

const ActionComment = styled.div`
  color: ${color.greyer};
`

interface IProps {
  action: IMaxPermissionAction
  subj: {
    name: string
    label: string
    actions: Array<IMaxPermissionAction>
    categories: Array<{ name: string; label: string; actions: Array<IMaxPermissionAction> }>
  }
  enabledPermissionsSet: Set<any>
  disabledPermissionsSet: Set<any>
  onChange: (e: any) => void
}

const ActionConfig: FC<IProps> = ({ action, subj, enabledPermissionsSet, disabledPermissionsSet, onChange }) => {
  return (
    <ActionContainer>
      <Checkbox
        name={action.name}
        data-id={`${action.name}:${subj.name}`}
        label={
          <div>
            <span dangerouslySetInnerHTML={{ __html: action.label }}></span>
            {action.comment ? <ActionComment>{action.comment}</ActionComment> : null}
          </div>
        }
        checked={
          enabledPermissionsSet.has(`${action.name}:${subj.name}`) ||
          disabledPermissionsSet.has(`${action.name}:${subj.name}`)
        }
        disabled={disabledPermissionsSet.has(`${action.name}:${subj.name}`)}
        onChange={onChange}
      />
      {action.tooltip && (
        <TitleTooltip title={action.tooltip}>
          <TooltipHelpIcon icon="help" width={16} height={16} />
        </TitleTooltip>
      )}
    </ActionContainer>
  )
}

export default ActionConfig
