import React, { FC, useMemo, createContext, useContext, useRef, useCallback } from 'react'
import { keys, isEqual, without, flatten } from 'lodash/fp'
import * as Sentry from '@sentry/react'
import { Dictionary } from 'ts-essentials'
import { getTrackingActions, IAction, IGNORED_TRACKING_PROPS } from '../../tracking/trackingActions'
import { APM_ENV, COMMIT_HASH } from '../../env'
import { localeContext } from '../locale'
import { authContext } from '../auth'
import unwrapId from '../../utils/unwrapId'
import { ILocale } from '../../intl'
import { PageViewTracker as PVT } from './components/PageViewTracker'
import { DicePageViewTracker as DPVT } from './components/DicePageViewTracker'

export const PageViewTracker = PVT
export const DicePageViewTracker = DPVT

interface ITrackingContext {
  trackEvent: (name: string, data?: any) => void
  trackPageView: (name: string, data?: any) => void

  trackDiceEvent: (param: string) => void
  trackDicePageView: () => void
}

const COMMAND_BUFFER: Array<['page' | 'event', string, any]> = []

export const trackingContext = createContext<ITrackingContext>({
  trackEvent: (name: string, data?: any) => COMMAND_BUFFER.push(['event', name, data]),
  trackPageView: (name: string, data?: any) => COMMAND_BUFFER.push(['page', name, data]),

  trackDiceEvent: (param: string) => COMMAND_BUFFER.push(['event', 'dice_action', { generic_prop: param }]),
  trackDicePageView: () => COMMAND_BUFFER.push(['page', 'dice_page_view', {}]),
})

const checkMissingProps = (action: IAction, data: any) => {
  const props = without(IGNORED_TRACKING_PROPS, flatten((action.properties || []) as string[][])).sort()

  const dataKeys = without(IGNORED_TRACKING_PROPS, data ? keys(data).sort() : [])
  if (!isEqual(dataKeys, props)) {
    throw new Error(
      `Tracking data mismatch [${action.key}]: expected ${JSON.stringify(props)}, got: ${JSON.stringify(dataKeys)}`
    )
  }
}

export const hash = async (value: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(value)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

const captureContext = (locale: ILocale) => {
  const conn = (window.navigator as any).connection

  return {
    app: {
      build: APM_ENV || 'dev',
      name: 'DICE MIO',
      namespace: 'fm.dice.mio',
      version: COMMIT_HASH || 'latest',
    },
    network: {
      effectiveType: conn?.effectiveType,
      downlink: conn?.downlink,
      rtt: conn?.rtt,
    },
    screen: {
      width: window.screen?.width,
      height: window.screen?.height,
      colorDepth: window.screen?.colorDepth,
      orientation: window.screen?.orientation?.type,
      density: window.devicePixelRatio || 1,
    },
    os: {
      platform: window.navigator.platform,
    },
    locale,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
}

let rudderanalytics: typeof import('../../utils/rudderanalytics')['default'] = null
let trackingActions: null | Dictionary<IAction> = null
let globalOrderPromise = Promise.resolve()

const TrackingProvider: FC<React.PropsWithChildren<{ readyForTracking: boolean }>> = ({
  readyForTracking,
  children,
}) => {
  const { locale } = useContext(localeContext)
  const { user, account } = useContext(authContext)
  const identified = useRef(false)

  const trackIt = useMemo(
    () => async (kind: 'event' | 'page', name: string, data?: any) => {
      try {
        if (!readyForTracking) return
        if (!rudderanalytics) {
          const val = await import(/* webpackChunkName: "tracking" */ '../../utils/rudderanalytics')
          rudderanalytics = val.default
        }

        if (!trackingActions) {
          trackingActions = await getTrackingActions()
        }

        const context = captureContext(locale)

        if (!identified.current && user.id) {
          identified.current = true
          const uid = await hash(user.id)

          await new Promise<void>((resolve) => {
            if (!rudderanalytics) {
              resolve()
            } else {
              rudderanalytics.identify(uid, undefined, { context }, resolve)

              Sentry.addBreadcrumb({
                category: 'tracking',
                message: 'Identified user',
                data: { context, uid },
                level: 'info',
              })
            }
          })
        } else if (identified.current && !user.id) {
          identified.current = false
          if (rudderanalytics) {
            rudderanalytics.reset()
          }
        }

        const action = trackingActions[name as keyof typeof trackingActions]

        if (!action) {
          throw new Error(`Missing tracking action for event: ${name}`)
        }

        if (action.kind !== kind) {
          throw new Error(`Wrong tracking type for event: ${name} - got ${kind} but expected ${action.kind}`)
        }

        if (action.key.startsWith('dice') && !user.diceStaff) {
          throw new Error('Attempt to track DICE only action as non-DICE user')
        }

        checkMissingProps(action, data)

        await new Promise<void>((resolve) => {
          if (!rudderanalytics) {
            resolve()
          } else if (kind === 'event') {
            rudderanalytics.track(name, { ...data, promoter_id: unwrapId(account?.id) }, { context }, resolve)
          } else {
            rudderanalytics.page('mio', name, { ...data, promoter_id: unwrapId(account?.id) }, { context }, resolve)
          }
        })

        Sentry.addBreadcrumb({
          category: 'tracking',
          message: 'Tracking event submitted',
          data: { name, data, context, action },
          level: 'info',
        })
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      }
    },
    [account?.id, locale, readyForTracking, user.diceStaff, user.id]
  )

  const drainBuffer = useCallback(() => {
    let el = null
    // eslint-disable-next-line no-cond-assign
    while ((el = COMMAND_BUFFER.shift())) {
      const currentEl = el
      globalOrderPromise = globalOrderPromise.then(() => trackIt(...currentEl))
    }
  }, [trackIt])

  const ctxValue = useMemo(
    () => ({
      trackEvent(name: string, data?: any) {
        COMMAND_BUFFER.push(['event', name, data])
        drainBuffer()
      },
      trackPageView(name: string, data?: any) {
        COMMAND_BUFFER.push(['page', name, data])
        drainBuffer()
      },
      trackDiceEvent: (param: string) => {
        COMMAND_BUFFER.push(['event', 'dice_action', { generic_prop: param }])
        drainBuffer()
      },
      trackDicePageView: () => {
        COMMAND_BUFFER.push(['page', 'dice_page_view', {}])
        drainBuffer()
      },
    }),
    [drainBuffer]
  )

  return <trackingContext.Provider value={ctxValue}>{children}</trackingContext.Provider>
}

export default TrackingProvider
