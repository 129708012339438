import { Dictionary } from 'ts-essentials'
import { FeeType } from '../enums.generated'

export const PROMOTER_ONLY_FEES: Set<FeeType> = new Set(['additionalPromoterFee', 'vendor'])

export const VENUE_ONLY_FEES: Set<FeeType> = new Set(['venueLevy'])

export const PROMOTER_DEFAULT_FEES: Set<FeeType> = new Set(['presale', 'additionalPromoterFee', 'venueLevy', 'vendor'])

export const PERCENTAGE_DEFAULT_FEES: Set<FeeType> = new Set(['booking', 'processing', 'salesTax'])

export const DEFAULT_FEE_AMOUNTS: Partial<Dictionary<number, FeeType>> = {
  paidWaitingList: 200,
}
