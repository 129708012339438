/**
 * @generated SignedSource<<3af1b8d3ce0f8422b4861de63016bfc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Notes_event$data = {
  readonly extraNotes: string | null;
  readonly " $fragmentType": "Notes_event";
};
export type Notes_event$key = {
  readonly " $data"?: Notes_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"Notes_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Notes_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraNotes",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "519a309795804c19b482c80c0d7e09db";

export default node;
