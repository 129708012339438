import React, { useMemo, useContext, useCallback, FC } from 'react'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { formatISO, subDays, startOfDay, endOfDay, parseISO, isPast, addHours } from 'date-fns'
import { useIntl, type IntlShape } from 'react-intl'
import { Helmet } from 'react-helmet'

import { Link } from 'react-router-dom'
import { mediaQuery, color } from '../../utils/variables'
import { trackingContext } from '../../context/tracking'
import { authContext } from '../../context/auth'

import PermissionCheck from '../../components/PermissionCheck'
import RelayLoader from '../../components/RelayLoader'
import Button from '../../components/Button'
import { LoaderContainer, Loader } from '../../components/Loader'
import { PageHeader, PageBody, PageTitle, PageControls } from '../../components/Page'

import DashboardSearch from './DashboardSearch'
import DashboardSales from './components/DashboardSales/DashboardSales'
import DashboardEvents from './DashboardEvents'
import PinnedEvent from './components/PinnedEvent/PinnedEvent'
import PinnedEventPlaceholder from './components/PinnedEvent/PinnedEventPlaceholder'
import { DATETIME_FORMATS } from '../../utils/formatters/datetime'
import { localeContext } from '../../context/locale'
import DismissableBanner from '../../components/DismissableBanner'

// SIC! Update when maintenance is scheduled
const MAINTENANCE_DATE_ISO = '2024-08-06T06:00:00Z'
const MAINTENANCE_INTERVAL_HR = 1

const MAINTENANCE_DATE = parseISO(MAINTENANCE_DATE_ISO)

const MaintenanceBanner = styled(DismissableBanner)`
  white-space: pre-wrap;
`

const MaintenancePadding = styled.div`
  padding: 24px 32px;
  margin-bottom: -32px;

  ${mediaQuery.lessThan('desktop')`
    padding: 16px;
    margin-bottom: -16px;
  `}
`

export const DashboardWrapper = styled(PageBody)`
  padding: 0;
  max-width: none;
  display: flex;
  flex-direction: column;
  ${mediaQuery.lessThan('desktop')`
    padding: 0;
  `}
`

const Section = styled.div`
  display: flex;
  min-height: 162px;
  border-bottom: 2px solid ${color.text};
  &:last-child {
    border: 0;
  }
  & > div {
    flex: 1;
  }
  ${mediaQuery.lessThan('desktop')`
    flex-direction: column;
  `}
`

const SectionRow = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  & > div {
    flex: 1;
  }
  ${mediaQuery.lessThan('desktop')`
    flex-direction: column;
    margin: 0;
  `}
`

const SectionCol = styled.div`
  position: relative;
  & + & {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      width: 2px;
      background: ${color.black};
    }
  }
  ${mediaQuery.lessThan('desktop')`
    & + & {
      border-top: 2px solid ${color.black};
      &:before {
        display: none;
      }
    }
  `}
`

const DashboardLoader = () => (
  <Section>
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  </Section>
)

interface IProps {
  pinnedEventId?: string | null
  onSwitchView?: () => void
  onUnpin?: () => void
}

const Dashboard: FC<React.PropsWithChildren<IProps>> = ({ pinnedEventId, onSwitchView, onUnpin }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const { trackEvent } = useContext(trackingContext)
  const trackNewEvent = useCallback(() => trackEvent('create_event_clicked'), [trackEvent])

  const DailySales = useMemo(() => {
    if (user.diceStaff) return () => <div />

    const now = new Date()

    return RelayLoader(DashboardSales, {
      customLoader: <DashboardLoader />,
      fetchPolicy: 'store-and-network',
      variables: {
        startDate: formatISO(startOfDay(now)),
        endDate: formatISO(endOfDay(now)),
        initialCurrency: user.defaultCurrency,
        period: 'day',
      },
      query: graphql`
        query DashboardDailyQuery($startDate: Time, $endDate: Time, $initialCurrency: EventCostCurrency) {
          viewer {
            ...DashboardSales_viewer @arguments(startDate: $startDate, endDate: $endDate, currency: $initialCurrency)
          }
        }
      `,
    })
  }, [user])

  const WeeklySales = useMemo(() => {
    if (user.diceStaff) return () => <div />

    const now = new Date()

    return RelayLoader(DashboardSales, {
      customLoader: <DashboardLoader />,
      fetchPolicy: 'store-and-network',
      variables: {
        startDate: formatISO(startOfDay(subDays(now, 7))),
        endDate: formatISO(endOfDay(now)),
        initialCurrency: user.defaultCurrency,
        period: 'week',
      },
      query: graphql`
        query DashboardWeeklyQuery($startDate: Time, $endDate: Time, $initialCurrency: EventCostCurrency) {
          viewer {
            ...DashboardSales_viewer @arguments(startDate: $startDate, endDate: $endDate, currency: $initialCurrency)
          }
        }
      `,
    })
  }, [user])

  const Events = useMemo(() => {
    const now = new Date()
    const startOfOnSale = formatISO(subDays(now, 7))
    return RelayLoader(DashboardEvents, {
      customLoader: <DashboardLoader />,
      variables: {
        onSaleWhere: { onSaleDate: { gte: startOfOnSale, lte: 'NOW' } },
      },
      query: graphql`
        query DashboardEventsQuery($onSaleWhere: EventWhereInput!) {
          viewer {
            ...DashboardEvents_viewer @arguments(onSaleWhere: $onSaleWhere)
          }
        }
      `,
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'dashboard.title' })} | DICE MIO</title>
      </Helmet>
      <PageHeader withFilters>
        <PageTitle>{intl.formatMessage({ id: 'dashboard.title' })}</PageTitle>
        <PageControls>
          <PermissionCheck permission="create:event">
            <Button to="/events/new" onClick={trackNewEvent} disabled={!user.canDoEvents.live}>
              {intl.formatMessage({ id: 'actions.new_event' })}
            </Button>
          </PermissionCheck>
        </PageControls>
        <DashboardSearch />
      </PageHeader>
      <DashboardWrapper>
        {!user.diceStaff && (
          <Section>
            <SectionRow data-id="dashboard-sales-section">
              <SectionCol>
                <DailySales />
              </SectionCol>
              <SectionCol>
                <WeeklySales />
              </SectionCol>
            </SectionRow>
          </Section>
        )}

        {onSwitchView && onUnpin && (
          <Section>
            <SectionRow>
              {pinnedEventId ? (
                <PinnedEvent eventId={pinnedEventId} onSwitchView={onSwitchView} onUnpin={onUnpin} />
              ) : (
                <PinnedEventPlaceholder />
              )}
            </SectionRow>
          </Section>
        )}

        <Section>
          <SectionRow>
            <div>
              {MAINTENANCE_DATE && !isPast(MAINTENANCE_DATE) && (
                <MaintenanceBanner
                  wrapper={MaintenancePadding}
                  icon="warning"
                  dismissKey={`maintenance:${MAINTENANCE_DATE_ISO}`}
                >
                  {/* {intl.formatMessage(
                    { id: 'warning.scheduled_maintenance_pools' },
                    {
                      a: (str: string) => (
                        <strong>
                          <Link to="/support?category=65af8ee0ffe4f977717baf78">{str}</Link>
                        </strong>
                      ),
                    }
                  )} */}
                  {intl.formatMessage(
                    { id: 'warning.scheduled_maintenance_alt' },
                    {
                      time: intl.formatDate(MAINTENANCE_DATE, {
                        ...DATETIME_FORMATS.TIME_FULL(locale),
                        timeZone: user.timezoneName || 'Europe/London',
                      }),
                      dates: intl.formatList(
                        [
                          intl.formatDate(MAINTENANCE_DATE, {
                            ...DATETIME_FORMATS.MEDIUM,
                            timeZone: user.timezoneName || 'Europe/London',
                          }),
                          // intl.formatDate(addHours(MAINTENANCE_DATE, MAINTENANCE_INTERVAL_HR), {
                          //   ...DATETIME_FORMATS.MEDIUM,
                          //   timeZone: user.timezoneName || 'Europe/London',
                          // }),
                        ],
                        {
                          style: 'long',
                          type: 'conjunction',
                        }
                      ),
                      endTime: intl.formatDate(addHours(MAINTENANCE_DATE, MAINTENANCE_INTERVAL_HR), {
                        ...DATETIME_FORMATS.TIME_FULL(locale),
                        timeZone: user.timezoneName || 'Europe/London',
                      }),
                    }
                  )}
                </MaintenanceBanner>
              )}
              <Events />
            </div>
          </SectionRow>
        </Section>
      </DashboardWrapper>
    </>
  )
}

export default Dashboard
