/**
 * @generated SignedSource<<85541b2f203c75f124eff97e5727f74e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventTicketTypePricingRefetchQuery$variables = {
  id: string;
};
export type EventTicketTypePricingRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"EventTicketTypePricing_event">;
  } | null;
};
export type EventTicketTypePricingRefetchQuery = {
  response: EventTicketTypePricingRefetchQuery$data;
  variables: EventTicketTypePricingRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disableUsTax",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v8 = [
  (v2/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v10 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventTicketTypePricingRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventTicketTypePricing_event"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventTicketTypePricingRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventIdLive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "statusAsOfNow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onSaleDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "offSaleDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "costCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezoneName",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feesBehaviour",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "basePriceFees",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postFanPriceFees",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freeEvent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Fee",
                "kind": "LinkedField",
                "name": "fees",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeeSplit",
                    "kind": "LinkedField",
                    "name": "split",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "destination",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventSeatingChart",
                "kind": "LinkedField",
                "name": "eventSeatingChart",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "doorSalesOnly",
                    "value": false
                  },
                  {
                    "kind": "Literal",
                    "name": "includeArchived",
                    "value": true
                  }
                ],
                "concreteType": "TicketType",
                "kind": "LinkedField",
                "name": "ticketTypes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hidden",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isStream",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceTierType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salesLimit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceHidden",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceTier",
                    "kind": "LinkedField",
                    "name": "priceTiers",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ticketTypes(doorSalesOnly:false,includeArchived:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Venue",
                "kind": "LinkedField",
                "name": "venues",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "capacity",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AllowedLifecycleUpdates",
                "kind": "LinkedField",
                "name": "allowedLifecycleUpdates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllowedLifecycleUpdatesDate",
                    "kind": "LinkedField",
                    "name": "onSaleDate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canUpdate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllowedLifecycleUpdatesTicketTypes",
                    "kind": "LinkedField",
                    "name": "ticketTypes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canChangeTierNames",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canUpdatePrice",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Promoter",
                "kind": "LinkedField",
                "name": "billingPromoter",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": "label",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stripeAccountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platformAccountCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showPriceSuggestions",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowSkipReview",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resoldEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coolingOffPeriod",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketPool",
                "kind": "LinkedField",
                "name": "ticketPools",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxAllocation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Sales",
                "kind": "LinkedField",
                "name": "sales",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketTypeBreakdown",
                    "kind": "LinkedField",
                    "name": "ticketTypesBreakdown",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ticketTypeId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketType",
                        "kind": "LinkedField",
                        "name": "ticketType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ticketPoolId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceTierBreakdown",
                        "kind": "LinkedField",
                        "name": "priceTiersBreakdown",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PriceTier",
                            "kind": "LinkedField",
                            "name": "priceTier",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reserved",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appSold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "posSold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "terminalSold",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4d85504a88f638baecf7d532a1965a1",
    "id": null,
    "metadata": {},
    "name": "EventTicketTypePricingRefetchQuery",
    "operationKind": "query",
    "text": "query EventTicketTypePricingRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...EventTicketTypePricing_event\n    id\n  }\n}\n\nfragment EventTicketTypePricing_event on Event {\n  id\n  eventIdLive\n  state\n  statusAsOfNow\n  eventType\n  onSaleDate\n  offSaleDate\n  costCurrency\n  timezoneName\n  addressCountry\n  countryCode\n  feesBehaviour\n  basePriceFees\n  postFanPriceFees\n  disableUsTax\n  freeEvent\n  fees {\n    amount\n    type\n    unit\n    split {\n      amount\n      destination\n      unit\n    }\n  }\n  eventSeatingChart {\n    id\n  }\n  ticketTypes(doorSalesOnly: false, includeArchived: true) {\n    id\n    hidden\n    archived\n    allocation\n    isStream\n    priceTierType\n    salesLimit\n    priceHidden\n    priceTiers {\n      allocation\n      id\n    }\n  }\n  venues {\n    value: id\n    addressCountry\n    countryCode\n    capacity\n    id\n  }\n  allowedLifecycleUpdates {\n    onSaleDate {\n      canUpdate\n    }\n    ticketTypes {\n      canChangeTierNames\n      canUpdatePrice\n    }\n  }\n  billingPromoter {\n    value: id\n    label: name\n    stripeAccountId\n    platformAccountCode\n    showPriceSuggestions\n    addressCountry\n    countryCode\n    accountId\n    allowSkipReview\n    resoldEnabled\n    coolingOffPeriod\n    disableUsTax\n    id\n  }\n  ticketPools {\n    id\n    name\n    maxAllocation\n  }\n  sales {\n    ticketTypesBreakdown {\n      ticketTypeId\n      ticketType {\n        id\n        ticketPoolId\n      }\n      priceTiersBreakdown {\n        priceTier {\n          id\n        }\n        reserved\n        sold\n        appSold\n        posSold\n        terminalSold\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "075806dd00bfa740fdcce2360d9415bc";

export default node;
