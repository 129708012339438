/**
 * @generated SignedSource<<f50a9e8b34068d0a42b59db884c90350>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventTimingField = "ANNOUNCE_DATE" | "DATE" | "END_DATE" | "LINE_UP_DOORS_OPEN" | "OFF_SALE_DATE" | "ON_SALE_DATE" | "ON_SALE_REMINDER_TIME" | "RETURN_DEADLINE" | "TICKET_TYPE_ANNOUNCE_DATE" | "TICKET_TYPE_OFF_SALE_DATE" | "TICKET_TYPE_ON_SALE_DATE" | "TRANSFER_DEADLINE" | "%future added value";
export type EventTimingType = "DEFAULT" | "PROMOTER" | "%future added value";
export type PlatformAccountCode = "AU" | "CA" | "DE" | "ES" | "FR" | "GB" | "IN" | "IT" | "PT" | "US" | "%future added value";
export type SalesforceContractStatus = "ACTIVATED" | "CANCELLED" | "EXPIRED" | "SIGNED" | "SUPERSEDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePromoters_viewer$data = {
  readonly destinationPromoters: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly addressCountry: string | null;
        readonly allowSkipReview: boolean | null;
        readonly associatedMarketeers: ReadonlyArray<{
          readonly label: string | null;
          readonly value: string;
        } | null> | null;
        readonly autoRescheduledEventRefunds: {
          readonly active: boolean | null;
          readonly cutoffDays: number | null;
        } | null;
        readonly billingNotes: string | null;
        readonly coolingOffPeriod: boolean | null;
        readonly coolingOffPeriodHours: number | null;
        readonly countryCode: string | null;
        readonly defaultEventTimings: ReadonlyArray<{
          readonly offset: number | null;
          readonly sourceField: EventTimingField | null;
          readonly targetField: EventTimingField | null;
          readonly type: EventTimingType | null;
        } | null> | null;
        readonly disableUsTax: boolean | null;
        readonly displayName: string | null;
        readonly fanSupportNotes: {
          readonly body: string | null;
        } | null;
        readonly holdPayouts: boolean | null;
        readonly isTest: boolean | null;
        readonly label: string | null;
        readonly platformAccountCode: PlatformAccountCode | null;
        readonly resoldEnabled: boolean | null;
        readonly salesforceFields: {
          readonly defaultContract: {
            readonly id: string;
            readonly name: string | null;
            readonly num: string | null;
            readonly opportunityName: string | null;
            readonly sfAccountId: string | null;
            readonly sfId: string | null;
            readonly startDate: string | null;
            readonly status: SalesforceContractStatus | null;
          } | null;
        } | null;
        readonly sendReceiptViaSms: boolean | null;
        readonly stripeAccountId: string | null;
        readonly stripeFallbackAccountId: string | null;
        readonly stripeFallbackPlatformCode: PlatformAccountCode | null;
        readonly tags: ReadonlyArray<{
          readonly label: string | null;
          readonly value: string;
        } | null> | null;
        readonly tier: string | null;
        readonly value: string;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "usePromoters_viewer";
};
export type usePromoters_viewer$key = {
  readonly " $data"?: usePromoters_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePromoters_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "eq": true
},
v1 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePromoters_viewer",
  "selections": [
    {
      "alias": "destinationPromoters",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "chargesEnabled": (v0/*: any*/),
            "destinationFallback": (v0/*: any*/),
            "payoutsEnabled": (v0/*: any*/)
          }
        }
      ],
      "concreteType": "PromotersConnection",
      "kind": "LinkedField",
      "name": "promoters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PromotersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Promoter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isTest",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripeAccountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripeFallbackAccountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripeFallbackPlatformCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "platformAccountCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "addressCountry",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "countryCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowSkipReview",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "resoldEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coolingOffPeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coolingOffPeriodHours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sendReceiptViaSms",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disableUsTax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutoRescheduledEventRefunds",
                  "kind": "LinkedField",
                  "name": "autoRescheduledEventRefunds",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "active",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cutoffDays",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EventTiming",
                  "kind": "LinkedField",
                  "name": "defaultEventTimings",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "offset",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sourceField",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "targetField",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Marketeer",
                  "kind": "LinkedField",
                  "name": "associatedMarketeers",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "holdPayouts",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tag",
                  "kind": "LinkedField",
                  "name": "tags",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billingNotes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FanSupportNotes",
                  "kind": "LinkedField",
                  "name": "fanSupportNotes",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "body",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tier",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesforcePromoterFields",
                  "kind": "LinkedField",
                  "name": "salesforceFields",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesforceContract",
                      "kind": "LinkedField",
                      "name": "defaultContract",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "num",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "opportunityName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sfAccountId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sfId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "promoters(first:100,where:{\"chargesEnabled\":{\"eq\":true},\"destinationFallback\":{\"eq\":true},\"payoutsEnabled\":{\"eq\":true}})"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "75f630df1981a55ea042b8a6ed1cfc59";

export default node;
