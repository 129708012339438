import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useDeleteEventMutation } from '../../../__generated__/useDeleteEventMutation.graphql'
import { notificationContext } from '../../../context/notification'

function useDeleteEvent(eventId: string) {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState(false)

  const [commit, deleting] = useMutation<useDeleteEventMutation>(graphql`
    mutation useDeleteEventMutation($input: DeleteNodeInput!) {
      deleteNode(input: $input) {
        id
      }
    }
  `)

  const deleteEvent = useCallback(() => {
    setRemoveConfirmationModal(false)

    commit({
      variables: {
        input: {
          clientMutationId: eventId,
          id: eventId,
        },
      },
      onCompleted(_data, errors) {
        if (errors && errors.length > 0) {
          // DO NOTHING, errors already reported
          return
        }
        addNotification('success', intl.formatMessage({ id: 'event_list.quick_actions.notification.draft_removed' }))
      },
      onError: () => {
        addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
      },
      updater: (store, data) => {
        const eventId = data.deleteNode?.id
        if (eventId) {
          store.delete(eventId)
        }
      },
    })
  }, [commit, eventId, addNotification, intl])

  return { removeConfirmationModal, setRemoveConfirmationModal, deleteEvent, deleting }
}

export default useDeleteEvent
