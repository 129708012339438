import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import Button from './Button'
import eventNotesContext from '../flows/EventNotes/context/eventNotes'
import { OnDesktop } from './Breakpoints'

const NotesTrigger: FC = () => {
  const intl = useIntl()
  const { toggleNotesOpen } = useContext(eventNotesContext)

  return (
    <OnDesktop>
      <Wrapper>
        <Button block preset="outline" onClick={toggleNotesOpen}>
          {intl.formatMessage({ id: 'new_event.pv_notes.toggle' })}
        </Button>
      </Wrapper>
    </OnDesktop>
  )
}

export default NotesTrigger

const Wrapper = styled.div`
  position: sticky;
  top: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 24px;
`
