import React, { FC, useContext, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import qs from 'qs'
import { map, compose, toPairs, join, capitalize, isEmpty, concat } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import { notificationContext } from '../../../context/notification'
import FormField from '../../../components/FormField'
import Button from '../../../components/Button'
import Svg from '../../../components/Svg'
import { Form, FormRow } from '../../../components/Form'
import REQUESTS from '../../../utils/requests'
import { AuthFormHeader, AuthForm, AuthFormSuccess, AuthFormWrapper } from '../Auth'
import { breakpoints } from '../../../utils/variables'
import Checkbox from '../../../components/Checkbox'
import { AgreementCheckbox, AgreementCheckboxItem, TermsLink } from '../AuthStyles'
import { trackingContext } from '../../../context/tracking'

const AcceptInviteSchema = object().shape({
  firstName: string().nullable().required(),
  lastName: string().nullable().required(),
  password: string().nullable().min(2).required(),
})

const AcceptInvite: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const { token, account, existing_user } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      submitted: false,
    },
    validationSchema: existing_user ? null : AcceptInviteSchema,
    onSubmit: async ({ firstName, lastName, password }, { setFieldValue }) => {
      if (!token) return

      const { ok, errors, description } = await REQUESTS.ACCEPT_INVITE({
        token: token as string,
        firstName,
        lastName,
        password,
      })
      const hasErrors = (errors && !isEmpty(errors)) || !!description

      if (ok && !hasErrors) {
        setFieldValue('submitted', true)
      } else if (errors && !isEmpty(errors)) {
        const errText = compose([
          join('\n'),
          map(([k, v]) => map((e: string) => capitalize(`${k} ${e}`), concat([], v))),
          toPairs,
        ])(errors)
        addNotification('error', `${intl.formatMessage({ id: 'auth.accept_invite_error' })}:\n${errText}`)
      } else if (description) {
        addNotification('error', description)
      } else {
        addNotification('error', intl.formatMessage({ id: 'auth.accept_invite_error' }))
      }
    },
  })

  const { values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting, isValid } = formik

  const [isAgreed, setIsAgreed] = useState(false)
  const toggleIsAgreed = useCallback(() => setIsAgreed((v) => !v), [])

  const trackLogin = useCallback(() => trackEvent('login_started', { source_name: 'accept_invite' }), [trackEvent])

  return (
    <AuthFormWrapper>
      {values.submitted ? (
        <>
          <AuthFormSuccess>
            <Svg icon="check" />
            <p>{intl.formatMessage({ id: 'auth.accept_invite_success' })}</p>
          </AuthFormSuccess>
          <Form>
            <FormRow>
              <Button preset="link" color="primary" to="/auth/login" onClick={trackLogin}>
                {intl.formatMessage({ id: 'auth.proceed_to_signin' })}
              </Button>
            </FormRow>
          </Form>
        </>
      ) : (
        <>
          <AuthFormHeader
            title={intl.formatMessage({ id: 'auth.accept_invite_title' })}
            description={intl.formatMessage({ id: 'auth.accept_invite_description' })}
            backLink
          />
          <AuthForm onSubmit={handleSubmit}>
            <Form spacingSize={48}>
              <FormRow>
                <FormField
                  name="account"
                  label={intl.formatMessage({ id: 'account' })}
                  value={account || ''}
                  disabled
                />
              </FormRow>
              <FormRow>
                {!existing_user && (
                  <Form spacing="small">
                    <FormRow spacing="small" columnOnMobile>
                      <FormField
                        name="firstName"
                        label={intl.formatMessage({ id: 'account.full_name' })}
                        placeholder={intl.formatMessage({ id: 'account.first_name' })}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.firstName && errors.firstName}
                        autoFocus
                      />
                      <FormField
                        name="lastName"
                        label={!isMobile && '\xa0'} // non-breaking space to keep interface gaps consistent
                        placeholder={intl.formatMessage({ id: 'account.last_name' })}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lastName && errors.lastName}
                      />
                    </FormRow>
                  </Form>
                )}
              </FormRow>
              {!existing_user && (
                <FormRow>
                  <FormField
                    name="password"
                    control="newpassword"
                    label={intl.formatMessage({ id: 'auth.password' })}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && errors.password}
                    hint={intl.formatMessage({ id: 'account.password_hint' })}
                  />
                </FormRow>
              )}

              <FormRow>
                <AgreementCheckbox>
                  <AgreementCheckboxItem>
                    <Checkbox
                      checked={isAgreed}
                      onChange={toggleIsAgreed}
                      label={
                        <>
                          {intl.formatMessage({ id: 'account.i_agree' })}{' '}
                          <TermsLink
                            href="https://dice.fm/terms_and_conditions.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {intl.formatMessage({ id: 'terms_and_conditions' })}
                          </TermsLink>
                        </>
                      }
                    />
                  </AgreementCheckboxItem>
                </AgreementCheckbox>
              </FormRow>
              <FormRow>
                <Button
                  block
                  type="submit"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={isSubmitting || !isAgreed || !isValid}
                >
                  {intl.formatMessage({ id: 'auth.accept_invite' })}
                </Button>
              </FormRow>
            </Form>
          </AuthForm>
        </>
      )}
    </AuthFormWrapper>
  )
}

export default AcceptInvite
