import { compose, map, reduce } from 'lodash/fp'

const RE_DIGIT = /[0-9]/
const RE_VAT = /^[0-9]{11}$/
const RE_FISCAL_CODE = /^[A-Z]{6}[0-9]{2}[ABCDEHLMPRST]{1}[0-9]{2}[A-Z]{1}[0-9]{3}[A-Z]{1}$/

const vatChecksum: (digits: string[]) => string = compose([
  ([_, checksum]: [number, number]) => String((10 - (checksum % 10)) % 10),
  reduce(
    ([idx, acc], digit: number) => {
      const n = Number(digit)
      const v = idx % 2 === 0 ? n : n < 5 ? 2 * n : 2 * n - 9
      return [idx + 1, acc + v]
    },
    [0, 0]
  ),
])

export const isVatValid = (taxCode: string | null | undefined): boolean => {
  if (!taxCode) return true
  if (!RE_VAT.test(taxCode)) return false

  const checksum = taxCode[taxCode.length - 1]
  const digits = taxCode.substring(0, taxCode.length - 1).split('')
  return checksum === vatChecksum(digits)
}

const ENCODER = new Map([
  ['A', 1],
  ['B', 0],
  ['C', 5],
  ['D', 7],
  ['E', 9],
  ['F', 13],
  ['G', 15],
  ['H', 17],
  ['I', 19],
  ['J', 21],
  ['K', 2],
  ['L', 4],
  ['M', 18],
  ['N', 20],
  ['O', 11],
  ['P', 3],
  ['Q', 6],
  ['R', 8],
  ['S', 12],
  ['T', 14],
  ['U', 16],
  ['V', 10],
  ['W', 22],
  ['X', 25],
  ['Y', 24],
  ['Z', 23],
])

const fiscalCodeChecksum: (digits: string[]) => string = compose([
  ([_, checksum]: [number, number]) => String.fromCharCode(65 + (checksum % 26)),
  reduce(
    ([idx, acc], s: string) => {
      const v = idx % 2 === 0 ? ENCODER.get(s) || 0 : s.charCodeAt(0) - 65
      return [idx + 1, acc + v]
    },
    [0, 0]
  ),
  map((d: string) => (RE_DIGIT.test(d) ? String.fromCharCode(d.charCodeAt(0) + 17) : d)),
])

export const isFiscalCodeValid = (taxCode: string | null | undefined): boolean => {
  if (!taxCode) return true
  if (!RE_FISCAL_CODE.test(taxCode)) return false

  const checksum = taxCode[taxCode.length - 1]
  const digits = taxCode.substring(0, taxCode.length - 1).split('')
  return checksum === fiscalCodeChecksum(digits)
}
