import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { color, font, mediaQuery } from '../../../../utils/variables'

import Svg from '../../../../components/Svg'

import { TicketBreakdown_event$data } from '../../../../__generated__/TicketBreakdown_event.graphql'

const AddPromotionPlate = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  background: ${color.warningBg};
  font-size: ${font.size.sm}px;
  border-radius: 4px;
  font-weight: bold;
  ${mediaQuery.lessThan('desktop')`
    flex-direction: column;
    a {
      padding-left: 32px;
      margin-top: 16px;
    }
  `}
`

const AddPromotionPlateDescription = styled.div`
  display: flex;
  margin-right: auto;
  font-weight: normal;
  svg {
    flex: 24px 0 0;
    color: ${color.warning};
    margin: -2px 8px 0 0;
  }
`

interface IProps {
  event: TicketBreakdown_event$data
  ticketType: NonNullable<NonNullable<NonNullable<TicketBreakdown_event$data['sales']>['ticketTypesBreakdown']>[number]>
}

const AddPromotion: FC<React.PropsWithChildren<IProps>> = ({ event, ticketType: tt }) => {
  const intl = useIntl()

  return (
    <AddPromotionPlate className="mt-md">
      <AddPromotionPlateDescription>
        <Svg icon="info" />
        <span>{intl.formatMessage({ id: 'event_overview.ticket_breakdown.add_promotion.description' })}</span>
      </AddPromotionPlateDescription>
      <Link to={`/events/${event.id}/promotions/new?tty=${tt.ticketType.id}`}>
        +&nbsp;{intl.formatMessage({ id: 'event_overview.ticket_breakdown.add_promotion.link' })}
      </Link>
    </AddPromotionPlate>
  )
}

export default AddPromotion
