/**
 * @generated SignedSource<<c43b2ecabde584b5c666cf12f5a71a72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScheduleStatus = "POSTPONED" | "RESCHEDULED" | "%future added value";
export type TestEventChangedNotificationInput = {
  changes?: ReadonlyArray<string | null> | null;
  changeset: EventChangedNotificationChangeset;
  clientMutationId: string;
  eventId: string;
  message?: string | null;
  sendMeACopy?: boolean | null;
};
export type EventChangedNotificationChangeset = {
  date?: string | null;
  eventVenues?: ReadonlyArray<EventVenues | null> | null;
  flags?: EventFlagsInput | null;
  lineup?: ReadonlyArray<LineupInput | null> | null;
  scheduleStatus?: ScheduleStatus | null;
};
export type EventVenues = {
  primary?: boolean | null;
  venueId?: string | null;
};
export type EventFlagsInput = {
  alcoholFree?: FlagValue | null;
  autoRescheduledEventRefunds?: AutoRescheduledEventRefundsInput | null;
  branded?: FlagValue | null;
  claimTickets?: FlagValue | null;
  codeLocked?: FlagValue | null;
  competition?: FlagValue | null;
  coolingOffPeriod?: CoolingOffPeriodInput | null;
  disableDayOfEventComms?: FlagValue | null;
  enabledPwl?: EnabledPwlInput | null;
  fanPickSeat?: FanPickSeatInput | null;
  fanSurvey?: FlagValue | null;
  featured?: FlagValue | null;
  generateNewCodeOnTransfer?: FlagValue | null;
  grouped?: FlagValue | null;
  guestlist?: FlagValue | null;
  hidden?: FlagValue | null;
  hideFromDiscovery?: FlagValue | null;
  mbwayEnabled?: FlagValue | null;
  night?: FlagValue | null;
  paperTicket?: FlagValue | null;
  returns?: FlagValue | null;
  seated?: FlagValue | null;
  shoppingCart?: FlagValue | null;
  ticketTransfer?: FlagValue | null;
  ticketTypes?: FlagValue | null;
  unicorn?: FlagValue | null;
  waitingList?: FlagValue | null;
};
export type FlagValue = {
  active?: boolean | null;
};
export type AutoRescheduledEventRefundsInput = {
  active?: boolean | null;
  cutoffDays?: number | null;
};
export type CoolingOffPeriodInput = {
  active?: boolean | null;
  hours?: number | null;
};
export type EnabledPwlInput = {
  active?: boolean | null;
  deadline?: string | null;
  increaseAmount?: number | null;
};
export type FanPickSeatInput = {
  active?: boolean | null;
  hours?: number | null;
};
export type LineupInput = {
  details?: string | null;
  time?: string | null;
};
export type ChangeEventNotificationModalSendTestMutation$variables = {
  input: TestEventChangedNotificationInput;
};
export type ChangeEventNotificationModalSendTestMutation$data = {
  readonly testEventChangedNotification: {
    readonly successful: boolean;
  } | null;
};
export type ChangeEventNotificationModalSendTestMutation = {
  response: ChangeEventNotificationModalSendTestMutation$data;
  variables: ChangeEventNotificationModalSendTestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TestEventChangedNotificationPayload",
    "kind": "LinkedField",
    "name": "testEventChangedNotification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeEventNotificationModalSendTestMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeEventNotificationModalSendTestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fe395b956aa05279ffdc460741ff7680",
    "id": null,
    "metadata": {},
    "name": "ChangeEventNotificationModalSendTestMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeEventNotificationModalSendTestMutation(\n  $input: TestEventChangedNotificationInput!\n) {\n  testEventChangedNotification(input: $input) {\n    successful\n  }\n}\n"
  }
};
})();

(node as any).hash = "8051b5a6be98d16b5b771b9155e0bef6";

export default node;
