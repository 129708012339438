import { useMemo } from 'react'
import { compact, filter, flatMap, map, uniq } from 'lodash/fp'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { useDoorlistRecipientsQuery } from '../../../__generated__/useDoorlistRecipientsQuery.graphql'

function useDoorlistRecipients(
  setRecipients: (recipients: null | Array<{ id: string; email: string | null }>) => void
) {
  const environment = useRelayEnvironment()

  const fetchDefaultDoorlistRecipients = useMemo(
    () => (objectIdsRaw: Array<string | null> | null) => {
      const objectIds = uniq(compact(objectIdsRaw || []))

      if (objectIds.length === 0) {
        setRecipients(null)
      } else {
        const promises = map(
          (id) =>
            fetchQuery_DEPRECATED<useDoorlistRecipientsQuery>(
              environment,
              graphql`
                query useDoorlistRecipientsQuery($id: ID!) {
                  node(id: $id) {
                    ... on Venue {
                      contacts {
                        id
                        email
                        doorlistRecipient
                      }
                    }
                    ... on Promoter {
                      contacts {
                        id
                        email
                        doorlistRecipient
                      }
                    }
                  }
                }
              `,
              { id }
            ).catch((e) => {
              console.error(e)
              return null
            }),
          objectIds
        )

        Promise.all(promises).then((responses) => {
          const allContacts = flatMap((rs) => compact(rs.node?.contacts || []), compact(responses))
          setRecipients(filter('doorlistRecipient', allContacts))
        })
      }
    },
    [environment, setRecipients]
  )

  return fetchDefaultDoorlistRecipients
}

export default useDoorlistRecipients
