import React, { FC, memo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'

import { SIDEBAR_ROUTER } from '../services/sidebarNav'
import { SidebarMenu, SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'

interface IProps {
  onReload: () => void
}

const Top: FC<IProps> = ({ onReload }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const dashboardReload = useCallback(() => {
    onReload()
    navigate('/dashboard')
  }, [navigate, onReload])

  return (
    <div style={{ margin: '0 0 12px' }}>
      <SidebarMenu>
        <SidebarMenuItem
          active={pathname === SIDEBAR_ROUTER.dashboard[0]}
          to={SIDEBAR_ROUTER.dashboard[0]}
          onReload={dashboardReload}
        >
          <SidebarMenuIcon icon="venue" />
          <span>{intl.formatMessage({ id: 'sidebar.dashboard' })}</span>
        </SidebarMenuItem>
      </SidebarMenu>
    </div>
  )
}

export default memo(Top)
