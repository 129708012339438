import { useState, useCallback, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useBlocker } from 'react-router-dom'

function usePageTransitionBlock(condition: boolean) {
  const navigate = useNavigate()

  const [hasModal, setModal] = useState(false)
  const showModal = useCallback(() => {
    setModal(true)
  }, [])
  const closeModal = useCallback(() => {
    setModal(false)
  }, [])

  const savedLocation = useRef<null | any>(null)

  const routeUnblocker = useRef<null | (() => void)>(null)
  const windowUnblocker = useRef<null | (() => void)>(null)

  const blocker = useBlocker(({ nextLocation }) => {
    if (condition) {
      savedLocation.current = nextLocation.pathname
    }
    return condition
  })

  useEffect(() => {
    if (blocker.state !== 'blocked') return
    showModal()
    routeUnblocker.current = blocker.proceed.bind(blocker)
  }, [blocker, showModal])

  useEffect(() => {
    if (!condition) return

    const lsnr = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }

    windowUnblocker.current = () => {
      window.removeEventListener('beforeunload', lsnr)
    }

    window.addEventListener('beforeunload', lsnr)

    return () => {
      windowUnblocker.current = null
      window.removeEventListener('beforeunload', lsnr)
    }
  }, [condition, showModal])

  const doUnblock = useCallback(() => {
    closeModal()
    if (windowUnblocker.current) {
      windowUnblocker.current()
    }
    if (routeUnblocker.current) {
      routeUnblocker.current()
    }
    if (savedLocation.current) {
      navigate(savedLocation.current)
    }
  }, [closeModal, navigate])

  return { hasModal, doUnblock, closeModal }
}

export default usePageTransitionBlock
