/**
 * @generated SignedSource<<e3eaa038549ec29f5085332586c356ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type FeeInput = {
  active?: boolean | null;
  amount: number;
  split: ReadonlyArray<FeeSplitInput | null>;
  type: FeeType;
  unit: FeeUnit;
};
export type FeeSplitInput = {
  amount: number;
  destination: FeeDestination;
  unit: FeeUnit;
};
export type getProductPriceBreakdownQuery$variables = {
  basePriceFees?: ReadonlyArray<string | null> | null;
  billingPromoterId?: string | null;
  categoryId: string;
  disableUsTax: boolean;
  eventId?: string | null;
  faceValue: number;
  fees?: ReadonlyArray<FeeInput | null> | null;
  postFanPriceFees?: ReadonlyArray<string | null> | null;
  venueId: string;
};
export type getProductPriceBreakdownQuery$data = {
  readonly viewer: {
    readonly productPriceCalculation: {
      readonly breakdown: ReadonlyArray<{
        readonly amount: number | null;
        readonly applicable: boolean | null;
        readonly computed: number;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly computed: number;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null>;
        readonly type: FeeType;
        readonly unit: FeeUnit;
      } | null>;
      readonly faceValue: number;
      readonly fees: number;
      readonly salesTax: number;
      readonly split: ReadonlyArray<{
        readonly computed: number;
        readonly destination: FeeDestination;
      } | null>;
      readonly total: number;
      readonly vatAmount: number;
      readonly vatRate: number;
    } | null;
  } | null;
};
export type getProductPriceBreakdownQuery = {
  response: getProductPriceBreakdownQuery$data;
  variables: getProductPriceBreakdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "basePriceFees"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "billingPromoterId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "disableUsTax"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "faceValue"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fees"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postFanPriceFees"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "venueId"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "computed",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destination",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "basePriceFees",
      "variableName": "basePriceFees"
    },
    {
      "kind": "Variable",
      "name": "billingPromoterId",
      "variableName": "billingPromoterId"
    },
    {
      "kind": "Variable",
      "name": "categoryId",
      "variableName": "categoryId"
    },
    {
      "kind": "Variable",
      "name": "disableUsTax",
      "variableName": "disableUsTax"
    },
    {
      "kind": "Variable",
      "name": "eventId",
      "variableName": "eventId"
    },
    {
      "kind": "Variable",
      "name": "faceValue",
      "variableName": "faceValue"
    },
    {
      "kind": "Variable",
      "name": "fees",
      "variableName": "fees"
    },
    {
      "kind": "Variable",
      "name": "postFanPriceFees",
      "variableName": "postFanPriceFees"
    },
    {
      "kind": "Variable",
      "name": "venueId",
      "variableName": "venueId"
    }
  ],
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "productPriceCalculation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "faceValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeOutput",
      "kind": "LinkedField",
      "name": "breakdown",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "applicable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FeeSplitOutput",
          "kind": "LinkedField",
          "name": "split",
          "plural": true,
          "selections": [
            (v10/*: any*/),
            (v11/*: any*/),
            (v9/*: any*/),
            (v12/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        (v12/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getProductPriceBreakdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "getProductPriceBreakdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ace1edaab34cebcda71afc0fccb1f25e",
    "id": null,
    "metadata": {},
    "name": "getProductPriceBreakdownQuery",
    "operationKind": "query",
    "text": "query getProductPriceBreakdownQuery(\n  $billingPromoterId: ID\n  $eventId: ID\n  $fees: [FeeInput]\n  $basePriceFees: [String]\n  $postFanPriceFees: [String]\n  $disableUsTax: Boolean!\n  $categoryId: ID!\n  $faceValue: Int!\n  $venueId: ID!\n) {\n  viewer {\n    productPriceCalculation(billingPromoterId: $billingPromoterId, eventId: $eventId, fees: $fees, basePriceFees: $basePriceFees, postFanPriceFees: $postFanPriceFees, disableUsTax: $disableUsTax, categoryId: $categoryId, faceValue: $faceValue, venueId: $venueId) {\n      total\n      faceValue\n      salesTax\n      fees\n      vatAmount\n      vatRate\n      breakdown {\n        computed\n        amount\n        unit\n        type\n        applicable\n        split {\n          amount\n          unit\n          computed\n          destination\n        }\n      }\n      split {\n        computed\n        destination\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c91dbe4cd7447cb104831f6cf2be48bc";

export default node;
