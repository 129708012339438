/**
 * @generated SignedSource<<d0114d30225a6cdf871001fa2d76875b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventSuccessPageQuery$variables = {
  id: string;
};
export type EventSuccessPageQuery$data = {
  readonly event: {
    readonly " $fragmentSpreads": FragmentRefs<"EventSuccess_event">;
  } | null;
};
export type EventSuccessPageQuery = {
  response: EventSuccessPageQuery$data;
  variables: EventSuccessPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lockVersion",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previewToken",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onSaleDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCurrency",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezoneName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lineup",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "venueName",
  "storageKey": null
},
v15 = [
  (v9/*: any*/),
  (v2/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Venue",
  "kind": "LinkedField",
  "name": "primaryVenue",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Venue",
  "kind": "LinkedField",
  "name": "venues",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Promoter",
  "kind": "LinkedField",
  "name": "promoters",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Promoter",
  "kind": "LinkedField",
  "name": "billingPromoter",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceRange",
  "kind": "LinkedField",
  "name": "prices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventSuccessPageQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventSuccess_event"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventSuccessPageQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "statusAsOfNow",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organicSocialLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventIdLive",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "recurrentEventsGroup",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f813f3b6f6fa098b2f604b1cd022010a",
    "id": null,
    "metadata": {},
    "name": "EventSuccessPageQuery",
    "operationKind": "query",
    "text": "query EventSuccessPageQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ...EventSuccess_event\n    id\n  }\n}\n\nfragment EventSuccessRow_attrs on Event {\n  id\n  lockVersion\n  previewToken\n  date\n  endDate\n  onSaleDate\n  name\n  costCurrency\n  timezoneName\n  state\n  description\n  lineup\n  venueName\n  primaryVenue {\n    name\n    id\n  }\n  venues {\n    name\n    id\n  }\n  promoters {\n    name\n    id\n  }\n  billingPromoter {\n    name\n    id\n  }\n  prices {\n    from\n    to\n  }\n}\n\nfragment EventSuccess_event on Event {\n  id\n  date\n  statusAsOfNow\n  state\n  organicSocialLink\n  eventIdLive\n  ...EventSuccessRow_attrs\n  recurrentEventsGroup {\n    id\n    date\n    state\n    ...EventSuccessRow_attrs\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d2c3098e0f4273ceae52e9367d1d8ff";

export default node;
