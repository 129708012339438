import { useContext, useEffect, useMemo } from 'react'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import * as Sentry from '@sentry/react'
import { pick } from 'lodash/fp'

import { useUserInfo_viewer$key } from '../../../__generated__/useUserInfo_viewer.graphql'
import { toAccount, toUser } from '../util/convert'
import { localeContext } from '../../locale'

function useUserInfo(viewerKey: useUserInfo_viewer$key) {
  const { locale } = useContext(localeContext)

  const viewer = useFragment(
    graphql`
      fragment useUserInfo_viewer on Viewer {
        id
        name
        email
        diceStaff
        dicePartner
        source
        timezoneName

        eventsCurrencies

        mioRedesignV2

        account {
          id
          name
          allowSkipReview
          extrasEnabled
          merchEnabled
          countryCode
          addressCountry
          isDisabled
          forbidSelfPayouts
          automaticRollingPaymentsEnabled
        }

        events(first: 1) {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    viewerKey
  )

  const data = useMemo(() => ({ user: toUser(viewer, locale), account: toAccount(viewer, locale) }), [locale, viewer])

  useEffect(() => {
    const { user, account } = data
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: user.id,
        username: user.name,
        accountId: account?.id,
        accountName: account?.name,
        countryCode: account?.countryCode,
        ...pick(['diceStaff', 'hasEvent', 'eventCurrencies', 'defaultCurrency', 'mioRedesignV2'], user),
      })
    })
  }, [data, locale, viewer])

  return data
}

export default useUserInfo
