import React, { Dispatch, FC, memo, SetStateAction, useCallback, useContext, useMemo, useTransition } from 'react'
import { map, set } from 'lodash/fp'
import { IntlShape, useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { formatISO } from 'date-fns'
import { IOptions } from '../types/options'
import { TabMenu, TabMenuItem } from '../../TabMenu'
import FormField, { IStyledFormField } from '../../FormField'
import { localeContext } from '../../../context/locale'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { getWeekCalendar } from '../utils/dates'

const Flex = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 16px;
`

const SFormField = styled(FormField)`
  min-width: 280px;
  margin-top: -3px;

  .react-select:before {
    bottom: -1px;
  }
` as IStyledFormField<'select'>

const toWeekOption = (intl: IntlShape, [d1, d2]: readonly [Date, Date]) => ({
  value: formatISO(d1),
  span: [d1, d2] as const,
  label: [intl.formatDate(d1, DATETIME_FORMATS.SHORT), intl.formatDate(d2, DATETIME_FORMATS.SHORT)].join(' — '),
})

interface IProps {
  disabled?: boolean

  options: IOptions
  setOptions: Dispatch<SetStateAction<IOptions>>

  timeSpan: [Date, Date]
  setTimeSpan: (span: [Date, Date]) => void
  maxStartDate: Date
  minStartDate: Date
}

const SalesChartConfig: FC<IProps> = ({
  disabled,
  options,
  setOptions,
  timeSpan,
  setTimeSpan,
  minStartDate,
  maxStartDate,
}) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const [, startTransition] = useTransition()

  const changeGroupBy = useCallback(
    (e: any) => {
      startTransition(() => {
        setOptions(set('groupBy', e.currentTarget.dataset['id']))
      })
    },
    [setOptions]
  )

  const weekOptions = useMemo(
    () => map((span) => toWeekOption(intl, span), getWeekCalendar(locale, minStartDate, maxStartDate)),
    [intl, locale, maxStartDate, minStartDate]
  )

  const currentWeek = useMemo(() => toWeekOption(intl, timeSpan), [intl, timeSpan])

  const changeWeek = useCallback(
    (_: string, opt: any) => {
      startTransition(() => {
        setTimeSpan(opt.span)
      })
    },
    [setTimeSpan]
  )

  return (
    <Flex>
      <TabMenu>
        <TabMenuItem
          disabled={disabled}
          active={!disabled && options.groupBy === 'day'}
          data-id="day"
          onClick={changeGroupBy}
        >
          {intl.formatMessage({ id: 'event_overview.chart_switch.simple' })}
        </TabMenuItem>
        <TabMenuItem
          disabled={disabled}
          active={!disabled && options.groupBy === 'week'}
          data-id="week"
          onClick={changeGroupBy}
        >
          {intl.formatMessage({ id: 'event_overview.chart_switch.simple_weekly' })}
        </TabMenuItem>
        <TabMenuItem
          disabled={disabled}
          active={!disabled && options.groupBy === 'cumulative'}
          data-id="cumulative"
          onClick={changeGroupBy}
        >
          {intl.formatMessage({ id: 'event_overview.chart_switch.cumulative_new' })}
        </TabMenuItem>
      </TabMenu>

      {options.groupBy !== 'week' && (
        <SFormField
          name="mode"
          control="select"
          searchable
          options={weekOptions}
          value={currentWeek}
          onChange={changeWeek}
          disabled={disabled}
        />
      )}
    </Flex>
  )
}

export default memo(SalesChartConfig)
