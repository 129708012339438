import React, { FC, ReactNode, useCallback } from 'react'
import styled from 'styled-components/macro'
import { isString } from 'lodash/fp'

import { font, color, spacing } from '../../utils/variables'

export const Value = styled.div<{ textAlign: string; grow?: boolean; overflow: string }>`
  display: block;
  text-align: ${({ textAlign }) => textAlign};
  ${({ grow }) => grow && 'width: 100%'};
  overflow: ${({ overflow }) => overflow};
  cursor: initial;
`

const ValuePrimary = styled.span<{ lineLimit: 'none' | '1' | '2'; primaryBold?: boolean }>`
  display: block;
  font-size: ${font.size.base}px;
  margin-bottom: ${spacing.xs}px;
  font-weight: ${(props) => (props.primaryBold ? 'bold' : null)};

  ${({ lineLimit }) =>
    lineLimit !== 'none' &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${lineLimit};
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  `}
`

const ValueSecondary = styled.span<{ lineLimit: 'none' | '1' | '2' }>`
  display: block;
  font-size: ${font.size.sm}px;
  color: ${color.darkgrey};
  ${({ lineLimit }) =>
    lineLimit !== 'none' &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${lineLimit};
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  `}
`

interface IProps {
  className?: string
  primary: number | ReactNode
  secondary?: number | ReactNode
  primaryLimit?: 'none' | '1' | '2'
  secondaryLimit?: 'none' | '1' | '2'
  grow?: boolean
  textAlign?: 'left' | 'center' | 'right'
  overflow?: 'auto' | 'visible' | 'hidden'
  onClick?: (e: any) => {}
  passive?: boolean
  primaryBold?: boolean
}

const EventCardValue: FC<IProps> = ({
  className,
  primary,
  secondary,
  primaryLimit = 'none',
  secondaryLimit = 'none',
  grow,
  textAlign = 'left',
  overflow = 'visible',
  onClick,
  passive,
  primaryBold,
}) => {
  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (onClick) onClick(e)
    },
    [onClick]
  )

  return (
    <Value
      className={className}
      grow={grow}
      textAlign={textAlign}
      overflow={overflow}
      onClick={passive ? undefined : handleClick}
    >
      {primary && (
        <ValuePrimary
          lineLimit={primaryLimit}
          title={isString(primary) ? primary : undefined}
          primaryBold={primaryBold}
        >
          {primary}
        </ValuePrimary>
      )}
      {secondary && (
        <ValueSecondary lineLimit={secondaryLimit} title={isString(secondary) ? secondary : undefined}>
          {secondary}
        </ValueSecondary>
      )}
    </Value>
  )
}

export default EventCardValue
