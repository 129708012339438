import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { omit } from 'lodash/fp'
import * as Sentry from '@sentry/react'

import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { Loader, LoaderContainer } from '../../../components/Loader'
import { notificationContext } from '../../../context/notification'
import IEventForm from '../../EventForm/types'
import { parseMarkdown } from '../../../utils/markdown'
import { canRestore, clearBackup, restoreBackup, saveBackup } from '../util/backupStore'
import { authContext } from '../../../context/auth'

const FormBackupSupport: FC<React.PropsWithChildren<{ theKey: string; disabled?: boolean }>> = ({
  theKey,
  disabled,
  children,
}) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { user } = useContext(authContext)
  const userId = user.id || null

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const [restoring, setRestoring] = useState(false)
  const [modal, setModal] = useState(canRestore(theKey, userId))

  useEffect(() => {
    setModal(canRestore(theKey, userId))
  }, [theKey, userId])

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'formBackup',
      message: 'Restore prompt visibility change',
      data: { visible: modal },
      level: 'info',
    })
  }, [modal])

  const doHideModal = useCallback(() => {
    clearBackup(theKey, userId).catch((e) => {
      console.error(e)
    })

    setModal(false)
  }, [theKey, userId])

  const { setValues, validateForm } = useFormikContext<IEventForm>()

  const doRestore = useCallback(() => {
    setRestoring(true)

    const promise = restoreBackup(theKey, userId)

    promise
      .then((values) => {
        const form = { ...values, descriptionDraft: parseMarkdown(values.description) }

        setValues(form, true)

        setTimeout(() => validateForm(), 0)

        addNotification('success', intl.formatMessage({ id: 'new_event.backup.restore_done' }))
        if (mounted.current) {
          setRestoring(false)
          setModal(false)
        }
      })
      .catch(() => {
        addNotification('error', intl.formatMessage({ id: 'new_event.backup.restore_error' }))
        if (mounted.current) {
          setRestoring(false)
          setModal(false)
        }
      })
  }, [addNotification, intl, setValues, theKey, userId, validateForm])

  if (disabled) return <>{children}</>

  return (
    <>
      {restoring ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>{children}</>
      )}
      {modal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'new_event.backup.can_restore' })}
          cta={intl.formatMessage({ id: 'new_event.backup.do_restore' })}
          onConfirm={doRestore}
          onReject={doHideModal}
        />
      )}
    </>
  )
}

export const FormBackuper: FC<React.PropsWithChildren<{ delay?: number; theKey: string }>> = ({
  theKey,
  delay = 500,
}) => {
  const { values } = useFormikContext<IEventForm>()

  const { user } = useContext(authContext)
  const userId = user.id || null

  const [letsBackup, setLetsBackup] = useState(false)
  const promiseRef = useRef(Promise.resolve())

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setLetsBackup(true)
    }, delay)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [delay, values])

  useEffect(() => {
    if (!letsBackup) return

    setLetsBackup(false)

    const promise = saveBackup(theKey, userId, values && omit(['descriptionDraft'], values))

    promiseRef.current = promiseRef.current.then(() =>
      promise.catch((e) => {
        console.error(e)
      })
    )
  }, [letsBackup, theKey, userId, values])

  useEffect(() => {
    return () => {
      clearBackup(theKey, userId).catch((e) => {
        console.error(e)
      })
    }
  }, [theKey, userId])

  return null
}

export default FormBackupSupport
