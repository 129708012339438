/**
 * @generated SignedSource<<d32ed4c209d56adbc75dd1e9a4360f5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnumTypeOfOrganizer = "AGENT" | "ARTIST" | "ARTIST_MANAGEMENT" | "BRAND" | "LABEL" | "PROMOTER" | "VENUE" | "%future added value";
export type EventLifeCycleState = "DRAFT" | "LIVE" | "PAST" | "%future added value";
export type EventSpecialFilters = "onSaleToday" | "runningLow" | "soldOut" | "toBePaid" | "today" | "%future added value";
export type EventStates = "ANNOUNCED" | "ARCHIVED" | "NOT_COMPLETE" | "ON_SALE" | "READY_TO_SUBMIT" | "RUNNING_LOW" | "SOLD_OUT" | "TO_BE_APPROVED" | "%future added value";
export type EventTypes = "BOX_OFFICE" | "RECURRING_EVENT" | "REGULAR_EVENT" | "STREAM_EVENT" | "%future added value";
export type EventsConnectionOrder = "announceDateASC" | "announceDateDESC" | "cancelledAtASC" | "cancelledAtDESC" | "createdAtASC" | "createdAtDESC" | "dateASC" | "dateDESC" | "endDateASC" | "endDateDESC" | "nameASC" | "nameDESC" | "offSaleDateASC" | "offSaleDateDESC" | "onSaleDateASC" | "onSaleDateDESC" | "submittedAtASC" | "submittedAtDESC" | "ticketsSoldASC" | "ticketsSoldDESC" | "totalFaceValueASC" | "totalFaceValueDESC" | "venueASC" | "venueDESC" | "%future added value";
export type PromoterTier = "CORE" | "MAX" | "PLUS" | "PRO" | "WHALE" | "%future added value";
export type StreamingUrlState = "STREAM_EMBED_CODE_BLANK" | "STREAM_URL_BLANK" | "STREAM_URL_READY" | "%future added value";
export type EventWhereInput = {
  _or?: ReadonlyArray<EventWhereInput | null> | null;
  accountId?: OperatorsIdEqInput | null;
  accountManagerId?: OperatorsIdEqInput | null;
  addressCountry?: OperatorsString | null;
  addressRegion?: OperatorsString | null;
  addressState?: OperatorsString | null;
  announceDate?: OperatorsDateInput | null;
  artistIds?: OperatorsIdEqInput | null;
  artistName?: OperatorsString | null;
  billingPromoter?: OperatorsJsonInput | null;
  billingPromoterName?: OperatorsString | null;
  bundleIds?: OperatorsIdEqInput | null;
  cityIds?: OperatorsListOfStringInput | null;
  closeEventDate?: OperatorsDateInput | null;
  countryCode?: OperatorsString | null;
  createdAt?: OperatorsDateInput | null;
  date?: OperatorsDateInput | null;
  description?: OperatorsString | null;
  endDate?: OperatorsDateInput | null;
  endSaleDate?: OperatorsDateInput | null;
  eventIdLive?: OperatorsString | null;
  eventIdPreview?: OperatorsString | null;
  eventStatus?: OperatorsString | null;
  eventType?: OperatorsString | null;
  flagNames?: OperatorsListOfStringInput | null;
  free?: OperatorsBooleanEqInput | null;
  fullAddress?: OperatorsString | null;
  hierarchicalTagNames?: OperatorsListOfStringInput | null;
  id?: OperatorsIdEqInput | null;
  labelNames?: OperatorsListOfStringInput | null;
  name?: OperatorsString | null;
  offSaleDate?: OperatorsDateInput | null;
  onSaleDate?: OperatorsDateInput | null;
  permName?: OperatorsString | null;
  priority?: OperatorsBooleanEqInput | null;
  promoterName?: OperatorsString | null;
  promoterPayoutsEnabled?: OperatorsBooleanEqInput | null;
  promoterTier?: OperatorsEnumPromoterTier | null;
  promoterTypeOfOrganizer?: OperatorsEnumTypeOfOrganizer | null;
  recurring?: OperatorsBooleanEqInput | null;
  scheduleStatus?: OperatorsString | null;
  special?: EventSpecialFilters | null;
  stages?: OperatorsListOfStringInput | null;
  state?: OperatorsString | null;
  stripeAccountId?: OperatorsString | null;
  stripeVerified?: OperatorsBooleanEqInput | null;
  submittedAt?: OperatorsDateInput | null;
  tagNames?: OperatorsListOfStringInput | null;
  venue?: OperatorsString | null;
  venueIds?: OperatorsIdEqInput | null;
  venueSeatingChartIds?: OperatorsIdEqInput | null;
  venueTier?: OperatorsString | null;
};
export type OperatorsIdEqInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
};
export type OperatorsString = {
  eq?: string | null;
  iLike?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notLike?: string | null;
  null?: boolean | null;
};
export type OperatorsDateInput = {
  between?: ReadonlyArray<string | null> | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  notBetween?: ReadonlyArray<string | null> | null;
  null?: boolean | null;
};
export type OperatorsJsonInput = {
  eq?: IBackendMap | null;
  ne?: IBackendMap | null;
  neOrNull?: IBackendMap | null;
};
export type OperatorsListOfStringInput = {
  contains?: ReadonlyArray<string | null> | null;
  eq?: ReadonlyArray<string | null> | null;
  ne?: ReadonlyArray<string | null> | null;
  notContains?: ReadonlyArray<string | null> | null;
};
export type OperatorsBooleanEqInput = {
  eq?: boolean | null;
  ne?: boolean | null;
  neOrNull?: boolean | null;
};
export type OperatorsEnumPromoterTier = {
  eq?: PromoterTier | null;
  in?: ReadonlyArray<PromoterTier | null> | null;
};
export type OperatorsEnumTypeOfOrganizer = {
  eq?: EnumTypeOfOrganizer | null;
  in?: ReadonlyArray<EnumTypeOfOrganizer | null> | null;
};
export type EventScopesInput = {
  eventState?: ReadonlyArray<EventStates | null> | null;
  eventType?: ReadonlyArray<EventTypes | null> | null;
  lifeCycleState?: ReadonlyArray<EventLifeCycleState | null> | null;
  streamingUrlState?: ReadonlyArray<StreamingUrlState | null> | null;
};
export type EventAbbonamentoPickerModalQuery$variables = {
  orderBy?: ReadonlyArray<EventsConnectionOrder | null> | null;
  scopes?: EventScopesInput | null;
  searchTerm?: string | null;
  where?: EventWhereInput | null;
};
export type EventAbbonamentoPickerModalQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"EventAbbonamentoPickerTable_viewer">;
  } | null;
};
export type EventAbbonamentoPickerModalQuery = {
  response: EventAbbonamentoPickerModalQuery$data;
  variables: EventAbbonamentoPickerModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scopes"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v5 = {
  "kind": "Variable",
  "name": "scopes",
  "variableName": "scopes"
},
v6 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v7 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventAbbonamentoPickerModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "EventAbbonamentoPickerTable_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventAbbonamentoPickerModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "EventConnection",
            "kind": "LinkedField",
            "name": "events",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timezoneName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Venue",
                        "kind": "LinkedField",
                        "name": "primaryVenue",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AttractiveFields",
                        "kind": "LinkedField",
                        "name": "attractiveFields",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "forceSubscription",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventFlags",
                        "kind": "LinkedField",
                        "name": "flags",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hidden",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventImage",
                        "kind": "LinkedField",
                        "name": "eventImages",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cdnUrl",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusAsOfNow",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eventIdLive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduleStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedSteps",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceRange",
                        "kind": "LinkedField",
                        "name": "prices",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "to",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allocation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AllowedActions",
                        "kind": "LinkedField",
                        "name": "allowedActions",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "performPayout",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "where",
              "scopes",
              "searchTerm",
              "orderBy"
            ],
            "handle": "connection",
            "key": "EventAbbonamentoPickerTable_events",
            "kind": "LinkedHandle",
            "name": "events"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29592528cac3b0705b89fe187bbd11d5",
    "id": null,
    "metadata": {},
    "name": "EventAbbonamentoPickerModalQuery",
    "operationKind": "query",
    "text": "query EventAbbonamentoPickerModalQuery(\n  $where: EventWhereInput\n  $orderBy: [EventsConnectionOrder]\n  $scopes: EventScopesInput\n  $searchTerm: String\n) {\n  viewer {\n    ...EventAbbonamentoPickerTable_viewer_z9ZJZ\n    id\n  }\n}\n\nfragment EventAbbonamentoPickerTableRow_event on Event {\n  id\n  name\n  date\n  endDate\n  timezoneName\n  primaryVenue {\n    name\n    id\n  }\n  attractiveFields {\n    forceSubscription\n  }\n  ...EventPartImage_event\n  ...EventPartStatus_event\n}\n\nfragment EventAbbonamentoPickerTable_viewer_z9ZJZ on Viewer {\n  events(first: 20, where: $where, scopes: $scopes, searchTerm: $searchTerm, orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        ...EventAbbonamentoPickerTableRow_event\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EventPartImage_event on Event {\n  id\n  state\n  flags {\n    hidden\n  }\n  eventImages {\n    type\n    cdnUrl\n    id\n  }\n}\n\nfragment EventPartStatus_event on Event {\n  id\n  statusAsOfNow\n  state\n  endDate\n  eventIdLive\n  scheduleStatus\n  flags {\n    hidden\n  }\n  completedSteps\n  prices {\n    to\n  }\n  allocation\n  allowedActions {\n    performPayout\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cb3fa6ff42a85769ad65e42b117863e";

export default node;
