import React, { FC } from 'react'
import { indexBy } from 'lodash/fp'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { IFeeContractPreview } from '../hooks/useFeeContractPreview'
import Table, { Cell, Row, TableBody, TableHeader } from '../../../components/Table'
import { color, font } from '../../../utils/variables'
import { CURRENCY } from '../../../utils/formatters/number'
import { EventCostCurrency } from '../../../enums.generated'

interface IProps {
  currency: EventCostCurrency
  contract: IFeeContractPreview
}

const Container = styled.div`
  padding: 16px;
  border-radius: 8px;
  font-size: ${font.size.sm}px;
  background: ${color.palegrey};

  & + & {
    margin-top: 8px;
  }
`

const StyledTableHeader = styled(TableHeader)`
  border-bottom: 1px solid ${color.lightgrey};
  color: ${color.darkgrey};
`

const StyledTableRow = styled(Row)`
  &:not(:last-child) {
    border-bottom: 1px solid ${color.lightgrey};
  }
  ${Cell} {
    &:first-child {
      width: 40%;
    }
    width: 20%;
  }
`

const EventFeeContractPreview: FC<React.PropsWithChildren<IProps>> = ({ contract, currency, children }) => {
  const intl = useIntl()

  return (
    <Container>
      {children}
      <Table>
        <StyledTableHeader>
          <Row>
            <Cell>{intl.formatMessage({ id: 'new_event.tickets.fee_type' })}</Cell>
            <Cell textAlign="right">{intl.formatMessage({ id: 'fees.fee_unit.fixed' })}</Cell>
            <Cell textAlign="right">{intl.formatMessage({ id: 'new_event.tickets.fee_split_keep.hint' })}</Cell>
            <Cell textAlign="right">
              {intl.formatMessage({ id: 'new_event.tickets.fee_split_billingPromoter.hint' })}
            </Cell>
          </Row>
        </StyledTableHeader>
        <TableBody>
          {contract.map((row) => {
            const splits: any = indexBy('destination', row.split)

            return (
              <StyledTableRow key={row.type}>
                <Cell>{intl.formatMessage({ id: `fees.${row.type}` })}</Cell>
                <Cell textAlign="right">
                  {row.unit === 'percentage'
                    ? intl.formatNumber((row.amount || 0) / 100, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                      style: 'percent',
                    })
                    : intl.formatNumber((row.amount || 0) / 100, CURRENCY(row.amount || 0, currency))}
                </Cell>
                <Cell textAlign="right">
                  {splits.keep?.unit === 'percentage'
                    ? intl.formatNumber((splits.keep?.amount || 0) / 100, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                      style: 'percent',
                    })
                    : intl.formatNumber((splits.keep?.amount || 0) / 100, CURRENCY(splits.keep?.amount || 0, currency))}
                </Cell>
                <Cell textAlign="right">
                  {splits.billingPromoter?.unit === 'percentage'
                    ? intl.formatNumber((splits.billingPromoter?.amount || 0) / 100, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                      style: 'percent',
                    })
                    : intl.formatNumber(
                      (splits.billingPromoter?.amount || 0) / 100,
                      CURRENCY(splits.billingPromoter?.amount || 0, currency)
                    )}
                </Cell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </Container>
  )
}

export default EventFeeContractPreview
