import graphql from 'babel-plugin-relay/macro'
import { Environment, fetchQuery_DEPRECATED } from 'react-relay'

// Can be used to calculate US & Canada sales tax
// FOR US & CA VENUES only
const getSalesTax = async (
  environment: Environment,
  amount: number | null,
  venueId: string | null,
  eventId: string | null
): Promise<null | number> => {
  if (!amount || !venueId || !eventId) return null
  const result = await fetchQuery_DEPRECATED(
    environment,
    graphql`
      query getSalesTaxQuery($amount: Int!, $venueId: ID!, $eventId: ID!) {
        viewer {
          salesTaxCalculation(amount: $amount, venueId: $venueId, eventId: $eventId)
        }
      }
    `,
    {
      amount: amount,
      venueId: venueId,
      eventId: eventId,
    }
  )

  const tax = result.viewer?.salesTaxCalculation

  return tax ? tax : null
}

export default getSalesTax
