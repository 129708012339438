import { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import graphql from 'babel-plugin-relay/macro'
import { getOr } from 'lodash/fp'
import { useRelayEnvironment } from 'react-relay'

import IEventFormSettings from '../types/Settings'
import graphqlOptionsLoader from '../../../utils/graphqlOptionsLoader'
import { COOLING_OFF_PERIOD_HOURS_LIMITS } from '../../../constants/settings'

export default function useSettingsFields() {
  const environment = useRelayEnvironment()
  const { values, setFieldValue } = useFormikContext<IEventFormSettings>()

  const setCities = useCallback((_ids: any, selection: any) => setFieldValue('cities', selection), [setFieldValue])
  const setBundles = useCallback((_ids: any, selection: any) => setFieldValue('bundles', selection), [setFieldValue])

  const onToggleCoolingOffPeriod = useCallback(() => {
    const isActive = getOr(false, 'flags.coolingOffPeriod.active', values)
    const hours = getOr(null, 'flags.coolingOffPeriod.hours', values)
    setFieldValue('flags.coolingOffPeriod.active', !isActive)
    if (
      isActive &&
      hours &&
      (hours < COOLING_OFF_PERIOD_HOURS_LIMITS.min || hours > COOLING_OFF_PERIOD_HOURS_LIMITS.max)
    ) {
      setFieldValue('flags.coolingOffPeriod.hours', 24)
    }
  }, [values, setFieldValue])

  const onChangeCoolingOffPeriodHours = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value
      let num = parseInt(newVal) || 0
      num = num > 168 ? 168 : num
      setFieldValue('flags.coolingOffPeriod.hours', num)
    },
    [setFieldValue]
  )

  const onChangeHideFromDiscovery = useCallback(() => {
    const isActive = getOr(false, 'flags.hideFromDiscovery.active', values)
    setFieldValue('flags.hideFromDiscovery.active', !isActive)
  }, [setFieldValue, values])

  const cityLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query useSettingsFieldsCityQuery($where: CitiesWhereInput) {
            viewer {
              options: cities(where: $where) {
                value: id
                label: name
              }
            }
          }
        `,
        { simple: true }
      ),
    [environment]
  )

  const bundleLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query useSettingsFieldsBundleQuery($where: BundleWhereInput) {
            viewer {
              options: bundles(where: $where, first: 50) {
                edges {
                  node {
                    value: id
                    label: name
                  }
                }
              }
            }
          }
        `,
        { where: { active: { eq: true } } }
      ),
    [environment]
  )

  return {
    setCities,
    cityLoader,
    setBundles,
    bundleLoader,
    onToggleCoolingOffPeriod,
    onChangeCoolingOffPeriodHours,
    onChangeHideFromDiscovery,
  }
}
