import React, { FC, memo, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { compose, concat, every, find, getOr, map, some, uniq, without } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'

import Checkbox from '../../../components/Checkbox'
import { color } from '../../../utils/variables'
import { extractMaxPermissionStructure, IMaxPermissionStructure } from '../utils/structure'
import { PermissionConfig_viewer$key } from '../../../__generated__/PermissionConfig_viewer.graphql'
import ActionConfig from './ActionConfg'

const SubjectLabel = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`

const SubjectActions = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding: 8px 12px 8px 22px;
`

const CategoryLabel = styled.div`
  font-weight: 700;
  margin-bottom: -4px;
  padding: 8px 0 0 32px;
`

const Subject = styled.div`
  padding: 32px 0 16px 0;

  &:first-child {
    padding-top: 8px;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: -16px;
  }

  & + & {
    border-top: 1px solid ${color.lightgrey};
  }

  ${Checkbox} {
    display: flex;
  }
`

const SubjectBlock = styled.div`
  margin-bottom: 16px;
`

interface IProps {
  viewer: PermissionConfig_viewer$key
  enabledPermissions: Array<string>
  disabledPermissions?: Array<string>
  setEnabledPermissions: (arr: Array<string>) => void
}

const PermissionConfig: FC<React.PropsWithChildren<IProps>> = ({
  viewer: viewerKey,
  enabledPermissions,
  disabledPermissions,
  setEnabledPermissions,
}) => {
  const intl = useIntl()

  const viewer = useFragment(
    graphql`
      fragment PermissionConfig_viewer on Viewer {
        permissionProfileStructure {
          subjects {
            name
            actions {
              name
              category
            }
          }
        }
      }
    `,
    viewerKey
  )

  const maxPermissionStructure: IMaxPermissionStructure = useMemo(
    () =>
      extractMaxPermissionStructure(intl)(viewer.permissionProfileStructure ? [viewer.permissionProfileStructure] : []),
    [intl, viewer.permissionProfileStructure]
  )

  const enabledPermissionsSet = useMemo(() => new Set(enabledPermissions), [enabledPermissions])
  const disabledPermissionsSet = useMemo(() => new Set(disabledPermissions || []), [disabledPermissions])

  const changePermission = useCallback(
    (e: any) => {
      const permission = e.currentTarget.dataset['id']
      const enabled = enabledPermissions.indexOf(permission) >= 0
      setEnabledPermissions(
        enabled ? without([permission], enabledPermissions) : uniq(concat(enabledPermissions, permission))
      )
    },
    [setEnabledPermissions, enabledPermissions]
  )

  const enabledSubjectsMap: Map<string, boolean | null> = useMemo(() => {
    const subjectMap = new Map()

    maxPermissionStructure.forEach((subject) => {
      const permissions = map('name', subject?.actions || [])
      const isEnabled = (str: string) => enabledPermissionsSet.has(`${str}:${subject.name}`)
      subjectMap.set(subject.name, some(isEnabled, permissions) ? (every(isEnabled, permissions) ? true : null) : false)
    })

    return subjectMap
  }, [enabledPermissionsSet, maxPermissionStructure])

  const changeSubject = useCallback(
    (e: any) => {
      const subject = e.currentTarget.dataset['id']
      const enabled = !!enabledSubjectsMap.get(subject)

      const permissions: string[] = compose([
        map((a: { name: string }) => `${a.name}:${subject}`),
        (obj) => getOr([], 'actions', obj),
        find(['name', subject]),
      ])(maxPermissionStructure || [])

      setEnabledPermissions(
        enabled ? without(permissions, enabledPermissions) : uniq(concat(enabledPermissions, permissions))
      )
    },
    [enabledSubjectsMap, maxPermissionStructure, setEnabledPermissions, enabledPermissions]
  )

  return (
    <div>
      {maxPermissionStructure.map((subj) => (
        <Subject key={subj.name}>
          <SubjectBlock>
            <Checkbox
              name={subj.name}
              data-id={subj.name}
              label={<SubjectLabel>{subj.label}</SubjectLabel>}
              checked={enabledSubjectsMap.get(subj.name)}
              onChange={changeSubject}
              triState
            />
          </SubjectBlock>
          {subj.categories.length > 0 ? (
            subj.categories.map((category) => (
              <div key={category.name}>
                <CategoryLabel>{category.label}</CategoryLabel>
                <SubjectActions>
                  {category.actions.map((action) => (
                    <ActionConfig
                      key={action.name}
                      action={action}
                      subj={subj}
                      enabledPermissionsSet={enabledPermissionsSet}
                      disabledPermissionsSet={disabledPermissionsSet}
                      onChange={changePermission}
                    />
                  ))}
                </SubjectActions>
              </div>
            ))
          ) : (
            <SubjectActions>
              {subj.actions.map((action) => (
                <ActionConfig
                  key={action.name}
                  action={action}
                  subj={subj}
                  enabledPermissionsSet={enabledPermissionsSet}
                  disabledPermissionsSet={disabledPermissionsSet}
                  onChange={changePermission}
                />
              ))}
            </SubjectActions>
          )}
        </Subject>
      ))}
    </div>
  )
}

export default memo(PermissionConfig)
