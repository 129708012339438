import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import IEventFormBasics from '../types/Basics'

const useBasicsFields = () => {
  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormBasics>()

  const toggleCharity = useCallback(() => {
    setFieldValue('charityEvent', !values.charityEvent)
    setFieldTouched('charityEvent', true, true)

    if (values.charityEvent) {
      setFieldValue('charityId', null)
      setFieldTouched('charityId', true, true)
    }

    setTimeout(() => validateForm(), 0)
  }, [setFieldTouched, setFieldValue, validateForm, values.charityEvent])

  return {
    toggleCharity,
  }
}

export default useBasicsFields
