/**
 * @generated SignedSource<<1604f3a1877e484999259553f6394b9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventDoorlistRecipientsQuery$variables = {
  venueIds?: ReadonlyArray<string> | null;
};
export type EventDoorlistRecipientsQuery$data = {
  readonly viewer: {
    readonly defaultDoorlistRecipients: ReadonlyArray<string | null> | null;
  } | null;
};
export type EventDoorlistRecipientsQuery = {
  response: EventDoorlistRecipientsQuery$data;
  variables: EventDoorlistRecipientsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "venueIds"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "venueIds",
      "variableName": "venueIds"
    }
  ],
  "kind": "ScalarField",
  "name": "defaultDoorlistRecipients",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventDoorlistRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventDoorlistRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c0e000638b98c53b2bf070521e3391f",
    "id": null,
    "metadata": {},
    "name": "EventDoorlistRecipientsQuery",
    "operationKind": "query",
    "text": "query EventDoorlistRecipientsQuery(\n  $venueIds: [ID!]\n) {\n  viewer {\n    defaultDoorlistRecipients(venueIds: $venueIds)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "26c516f6837210315d435b2e44e4414d";

export default node;
