import { isString } from 'lodash/fp'
import React, { useEffect, useCallback, useState, useMemo, FC, memo, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { color, font } from '../utils/variables'

import Svg from './Svg'

export type INotificationType = 'success' | 'error' | 'alert'

const NotificationMessageWrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${font.size.sm}px;
  min-height: 48px;
  max-width: 620px;
  background: ${color.white};
  color: ${color.text};
  padding: 14px 16px 15px 64px;
  margin: 8px 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
`

const NotificationMessageIconContainer = styled.div<{ type: INotificationType }>`
  display: flex;
  height: 100%;
  width: 48px;
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background: ${color.grey};
  color: ${color.white};
  ${({ type }) => {
    switch (type) {
      case 'error':
        return `background: ${color.error};`
      case 'alert':
        return `background: ${color.warning};`
      case 'success':
        return `background: ${color.success};`
      default:
        return `background: ${color.white};`
    }
  }}
  & > svg {
    width: auto;
    height: auto;
  }
`

const NotificationMessageText = styled.div`
  font-size: ${font.size.sm}px;
  line-height: 1.25em;
  font-weight: normal;
`

const getIconType = (type: INotificationType) => {
  switch (type) {
    case 'success':
      return 'face-happy'
    case 'alert':
      return 'face-nothing'
    case 'error':
      return 'face-sad'
    default:
      return 'face-nothing'
  }
}

interface IProps {
  id: string
  type: INotificationType
  message: string | ReactNode
  onRemove: (id: string) => void
  className?: string
  shouldRemove?: boolean
}

const NotificationMessage: FC<React.PropsWithChildren<IProps>> = ({
  className,
  id,
  type,
  message,
  shouldRemove,
  onRemove,
}) => {
  const [hovered, setHovered] = useState(false)

  const removeNotification = useCallback(() => onRemove(id), [id, onRemove])
  const onHover = useCallback(() => setHovered(true), [setHovered])
  const onUnhover = useCallback(() => setHovered(false), [setHovered])

  useEffect(() => {
    if (shouldRemove && !hovered) {
      const timeoutDuration = isString(message) && (message.length || 0) > 80 ? 9000 : 7000
      const timeout = setTimeout(() => onRemove(id), timeoutDuration)
      return () => {
        if (timeout) clearTimeout(timeout)
      }
    }
  }, [onRemove, id, shouldRemove, hovered, message])

  const icon = useMemo(() => getIconType(type), [type])

  const messageLines = useMemo(() => {
    if (!isString(message)) {
      return <div>{message}</div>
    }

    return (message || '').split('\n').map((line, idx) => <div key={idx}>{line}</div>)
  }, [message])

  return (
    <NotificationMessageWrapper
      className={className}
      onMouseEnter={onHover}
      onMouseLeave={onUnhover}
      onClick={removeNotification}
    >
      <NotificationMessageIconContainer type={type}>
        {type && <Svg icon={icon} className="notification-message_icon" />}
      </NotificationMessageIconContainer>
      <NotificationMessageText>{messageLines}</NotificationMessageText>
    </NotificationMessageWrapper>
  )
}

export default memo(NotificationMessage)
