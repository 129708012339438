import styled from 'styled-components/macro'
import { Loader } from '../../../components/Loader'

import { mediaQuery } from '../../../utils/variables'

export const EventListLoader = styled(Loader)`
  position: fixed;
  left: calc(50% + 242px);
  transform: translateX(-50%);
  margin-left: -121px;
  top: calc(50% + 48px);
  z-index: 1;

  ${mediaQuery.lessThan('desktopLarge')`    
    left: calc(50% + 74px);
    margin-left: -37px;
  `}

  ${mediaQuery.lessThan('tablet')`    
    left: 50%;
    margin-left: 0;
  `}
`
