/**
 * @generated SignedSource<<87e236179736f02d9732c91d3209b509>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HierarchicalTagWhereInput = {
  _or?: ReadonlyArray<HierarchicalTagWhereInput | null> | null;
  deprecated?: OperatorsBooleanEqInput | null;
  kind?: string | null;
  name?: OperatorsString | null;
  parentId?: OperatorsIdEqInput | null;
};
export type OperatorsBooleanEqInput = {
  eq?: boolean | null;
  ne?: boolean | null;
  neOrNull?: boolean | null;
};
export type OperatorsString = {
  eq?: string | null;
  iLike?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notLike?: string | null;
  null?: boolean | null;
};
export type OperatorsIdEqInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
};
export type useHierarchicalTagsGenresQuery$variables = {
  where?: HierarchicalTagWhereInput | null;
};
export type useHierarchicalTagsGenresQuery$data = {
  readonly viewer: {
    readonly options: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly kind: string | null;
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useHierarchicalTagsGenresQuery = {
  response: useHierarchicalTagsGenresQuery$data;
  variables: useHierarchicalTagsGenresQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "options",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100
    },
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "where"
    }
  ],
  "concreteType": "HierarchicalTagsConnection",
  "kind": "LinkedField",
  "name": "hierarchicalTags",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HierarchicalTagsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HierarchicalTag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHierarchicalTagsGenresQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHierarchicalTagsGenresQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb6498801fe8d690077825696ee96c3e",
    "id": null,
    "metadata": {},
    "name": "useHierarchicalTagsGenresQuery",
    "operationKind": "query",
    "text": "query useHierarchicalTagsGenresQuery(\n  $where: HierarchicalTagWhereInput\n) {\n  viewer {\n    options: hierarchicalTags(where: $where, first: 100) {\n      edges {\n        node {\n          id\n          name\n          kind\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e66e971370af4f2194ea2b730873344a";

export default node;
