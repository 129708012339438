import { useRef, useLayoutEffect, useState, useCallback } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

type IResizeObserverHook = () => [(node: Element | null) => void, ResizeObserverEntry]

const useResizeObserver: IResizeObserverHook = () => {
  const [entry, setEntry] = useState<ResizeObserverEntry>({} as ResizeObserverEntry)
  const [node, setNode] = useState<Element | null>(null)
  const observer = useRef<ResizeObserver | null>(null)

  const disconnect = useCallback(() => {
    const { current } = observer
    if (current) {
      current.disconnect()
    }
  }, [])

  const observe = useCallback(() => {
    observer.current = new ResizeObserver(([entry]) => setEntry(entry))
    if (node) {
      observer.current.observe(node)
    }
  }, [node])

  useLayoutEffect(() => {
    observe()
    return () => disconnect()
  }, [disconnect, observe])

  return [setNode, entry]
}

export default useResizeObserver
