/**
 * @generated SignedSource<<9698d354740e95a248fe2c3eccab251e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestMailchimpCredentialsInput = {
  clientMutationId: string;
  code: string;
};
export type MailchimpConfirmationMutation$variables = {
  input: RequestMailchimpCredentialsInput;
};
export type MailchimpConfirmationMutation$data = {
  readonly requestMailchimpCredentials: {
    readonly clientMutationId: string;
  } | null;
};
export type MailchimpConfirmationMutation = {
  response: MailchimpConfirmationMutation$data;
  variables: MailchimpConfirmationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestMailchimpCredentialsPayload",
    "kind": "LinkedField",
    "name": "requestMailchimpCredentials",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MailchimpConfirmationMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MailchimpConfirmationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "28e6f1475475ca94cb2dff48f0a1380f",
    "id": null,
    "metadata": {},
    "name": "MailchimpConfirmationMutation",
    "operationKind": "mutation",
    "text": "mutation MailchimpConfirmationMutation(\n  $input: RequestMailchimpCredentialsInput!\n) {\n  requestMailchimpCredentials(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ff4207ce7b555c63abe43152570a2a9";

export default node;
