import React from 'react'
import { StoryFn, Meta } from '@storybook/react'

import Svg from '../../../components/Svg'
import iconNames from '../../util/iconNames'

export default {
  component: Svg,
  argTypes: {
    icon: { type: { name: 'string', required: true }, control: 'select', options: iconNames },
    width: { type: { name: 'number', required: false } },
    height: { type: { name: 'number', required: false } },
  },
  args: {
    width: 24,
    height: 24,
  },
} as Meta<typeof Svg>

const Template: StoryFn<typeof Svg> = (args) => <Svg {...args} />

export const Icon = Template.bind({})
Icon.args = {
  icon: 'qr',
}
