export const PROMOTER_SETTINGS_SIDEBAR_TOGGLER_TRIGGER_ID = 'globalPromoterSettingsSidebarTogglerElement'

export const PROMOTER_SETTINGS_SIDEBAR_ROUTER = {
  account: {
    personalDetails: [
      '/settings/personal-details',
      () => import(/* webpackChunkName: "account" */ '../../PromoterSettings/flows/PersonalDetails'),
    ],
  },
  promoter: {
    promoterDetails: [
      '/settings/promoter-details',
      () => import(/* webpackChunkName: "account" */ '../../PromoterSettings/flows/PromoterDetails'),
    ],
    bankDetails: [
      '/settings/bank-details',
      () => import(/* webpackChunkName: "account" */ '../../PromoterSettings/flows/PromoterDetails'),
    ],
    nts: [
      '/settings/nts-fiscal-info',
      () => import(/* webpackChunkName: "nts" */ '../../NtsFiscalInfo/NtsFiscalInfoPage'),
    ],
    users: ['/settings/users', () => import(/* webpackChunkName: "account" */ '../../PromoterSettings/flows/Users')],
    permissions: [
      '/settings/permissions',
      () => import(/* webpackChunkName: "account" */ '../../PromoterSettings/flows/Permissions'),
    ],
    integrations: [
      '/settings/integrations',
      () => import(/* webpackChunkName: "integrations" */ '../../Integrations/IntegrationsPage'),
    ],
    venues: ['/settings/venues', () => import(/* webpackChunkName: "venues" */ '../../Venues/SmartVenuePage')],
  },
}
