import { useCallback, useMemo, useState } from 'react'
import { compose, filter, flatMap, getOr, map, reject, some, update } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'
import { useFormikContext } from 'formik'
import { useRelayEnvironment } from 'react-relay'

import graphqlOptionsLoader from '../../../utils/graphqlOptionsLoader'
import IEventFormTickets from '../types/Tickets'

const QUERY_LOAD_SEATMAPS = graphql`
  query useSeatingStateSeatmapsQuery($venueIds: [ID!]) {
    viewer {
      options: venues(first: 50, where: { id: { in: $venueIds } }) {
        edges {
          node {
            value: id
            label: name
            seatingCharts(first: 50) {
              edges {
                node {
                  value: id
                  label: name
                  disabled
                }
              }
            }
          }
        }
      }
    }
  }
`

const useSeatingState = () => {
  const environment = useRelayEnvironment()
  const { values, setFieldValue } = useFormikContext<IEventFormTickets>()

  const hasVenueSeatmaps = useMemo(
    () => some((venue) => (venue?.atLeastOneSeatingChart?.edges?.length || 0) > 0, values.venues || []),
    [values.venues]
  )

  const [seatingWarn, setSeatingWarn] = useState(false)
  const confirmSeatingWarn = useCallback(() => {
    setFieldValue('ticketTypes', filter('isStream', values.ticketTypes))
    setFieldValue('ticketPools', [])
    setFieldValue('eventSeatingChart', null)
    setFieldValue('flags.seated.active', false)
    setSeatingWarn(false)
  }, [setFieldValue, values.ticketTypes])

  const cancelSeatingWarn = useCallback(() => setSeatingWarn(false), [])

  const onChangeSeated = useCallback(() => {
    if (!hasVenueSeatmaps) return
    const isActive = getOr(false, 'flags.seated.active', values)

    if (isActive) {
      setSeatingWarn(true)
    } else {
      setFieldValue('flags.seated.active', !isActive)
    }
  }, [hasVenueSeatmaps, values, setFieldValue])

  const seatmapLoader = useMemo(() => {
    const venueIds = map('value', values.venues || [])

    const postProcess = flatMap((option: any) =>
      compose([
        map(update('label', (label) => (venueIds.length > 1 ? `${label} / ${option.label}` : label))),
        reject('disabled'),
        map('node'),
      ])(option?.seatingCharts?.edges || [])
    )

    return graphqlOptionsLoader(environment, QUERY_LOAD_SEATMAPS, { fullText: true, postProcess }, { venueIds })
  }, [environment, values.venues])

  const onChangeVenueChart = useCallback(
    (_id: any, venueChart: any) => {
      setFieldValue('eventSeatingChart.venueChart', venueChart)
    },
    [setFieldValue]
  )

  return {
    hasVenueSeatmaps,
    onChangeSeated,
    seatmapLoader,
    seatingWarn,
    confirmSeatingWarn,
    cancelSeatingWarn,
    onChangeVenueChart,
  }
}

export default useSeatingState
