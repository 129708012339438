import React, { FC, memo, useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { omit } from 'lodash/fp'

import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { ISignupForm, organizerTypes } from '../hooks/useSignupForm'
import { IOptions } from '../../../utils/graphqlOptionsLoader'
import { color, font } from '../../../utils/variables'

const Option = styled.div`
  font-size: ${font.size.base}px;
  line-height: 140%;
`
const Hint = styled.div`
  font-size: ${font.size.sm}px;
  line-height: 125%;
  letter-spacing: 0.01em;
  color: ${color.darkgrey};
`

const Step1: FC = () => {
  const intl = useIntl()

  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = useFormikContext<ISignupForm>()

  const handleTypeChange = useCallback(
    (_value: any, obj: any) => setFieldValue('typeOfOrganizer', obj),
    [setFieldValue]
  )

  const formatOptionLabel = useCallback(
    ({ i18n, hint }: IOptions[number]) =>
      hint ? (
        <Option>
          <div>{intl.formatMessage({ id: i18n })}</div>
          <Hint>{intl.formatMessage({ id: hint })}</Hint>
        </Option>
      ) : (
        intl.formatMessage({ id: i18n })
      ),
    [intl]
  )

  const typeOfOrganizer = useMemo(
    () => values.typeOfOrganizer && omit(['hint'], values.typeOfOrganizer),
    [values.typeOfOrganizer]
  )

  return (
    <>
      <FormRow columnOnMobile>
        <FormField
          name="typeOfOrganizer"
          label={intl.formatMessage({ id: 'account.type_of_organiser' })}
          placeholder={intl.formatMessage({ id: 'placeholder.pick_one' })}
          control="select"
          options={organizerTypes}
          optionLabel={formatOptionLabel}
          value={typeOfOrganizer}
          onChange={handleTypeChange}
        />
      </FormRow>

      <FormRow columnOnMobile>
        <FormField
          name="companyName"
          label={intl.formatMessage({ id: 'account.company_name' })}
          hint={intl.formatMessage({ id: 'signup.company_name.hint' })}
          value={values.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.companyName && errors.companyName}
          autoComplete="organization"
        />
        <FormField
          name="displayName"
          label={intl.formatMessage({ id: 'account.brand_name' })}
          hint={intl.formatMessage({ id: 'signup.brand_name.hint' })}
          value={values.displayName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.displayName && errors.displayName}
          autoComplete="organization"
        />
      </FormRow>
    </>
  )
}

export default memo(Step1)
