import React, { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import DiceGate from '../gates/DiceGate'

const ArtistsPage = lazy(() => import(/* webpackChunkName: "artists" */ '../../flows/Artists/ArtistsPage'))
const ArtistHeader = lazy(() => import(/* webpackChunkName: "artists" */ '../../flows/Artist/components/ArtistHeader'))
const ArtistPage = lazy(() => import(/* webpackChunkName: "artists" */ '../../flows/Artist/ArtistPage'))
const ArtistActivityPage = lazy(() => import(/* webpackChunkName: "artists" */ '../../flows/Activity/ActivityPage'))
const ArtistEventsPage = lazy(
  () => import(/* webpackChunkName: "artists" */ '../../flows/EventList/EmbeddedEventListPage')
)

const ArtistsSection = () => (
  <Routes>
    <Route path="*" element={<DiceGate />}>
      <Route index element={<ArtistsPage />} />

      <Route
        path="new"
        element={
          <>
            <ArtistHeader />
            <ArtistPage />
          </>
        }
      />

      <Route
        path=":id"
        element={
          <>
            <ArtistHeader />
            <Outlet />
          </>
        }
      >
        <Route index element={<ArtistPage />} />
        <Route path="activity" element={<ArtistActivityPage />} />
        <Route path="events" element={<ArtistEventsPage />} />
      </Route>
    </Route>
  </Routes>
)

export default ArtistsSection
