import React, { FC, createContext, useMemo, useContext } from 'react'
import { always, find } from 'lodash/fp'
import { localeContext } from './locale'
import { getTimezoneFromLocation, suggestAddresses, suggestCities } from '../utils/mapbox'
import { getAlpha2ByName } from '../utils/countries'

type IOptions = Array<{
  value: string
  label: string
  [key: string]: any
}>

type ICallback = (value: IOptions) => void

export interface IStructuredAddress {
  latitude: number | null
  longitude: number | null
  addressCountry: string | null
  countryCode: string | null
  addressRegion: string | null
  addressLocality: string | null
  addressState: string | null
  streetAddress: string | null
  postalCode: string | null
  timezoneName: string | null
}

interface IMapsContext {
  suggestCities: (isoCountryCode?: string, simpleLabel?: boolean) => (str: string, callback: ICallback) => void
  suggestAddresses: (isoCountryCode?: string) => (str: string, callback: ICallback) => void

  getPlaceTimezone: (lat: number, lng: number) => Promise<string | null>
  getStructuredAddress: (placeId: string) => Promise<IStructuredAddress | null>
}

export const mapsContext = createContext<IMapsContext>({
  suggestCities: () => (_, callback) => callback([]),
  suggestAddresses: () => (_, callback) => callback([]),

  getPlaceTimezone: always(Promise.resolve(null)),
  getStructuredAddress: always(Promise.resolve(null)),
})

const MapsProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { locale } = useContext(localeContext)

  const ctxValue = useMemo(
    () => ({
      suggestCities: (isoCountryCode?: string, simpleLabel?: boolean) =>
        suggestCities(isoCountryCode, locale, !!simpleLabel),
      suggestAddresses: (isoCountryCode?: string) => suggestAddresses(isoCountryCode, locale),

      getPlaceTimezone: (lat: number, lng: number) =>
        getTimezoneFromLocation(lat, lng)
          .then((tz) => {
            return tz || null
          })
          .catch((e) => {
            console.error(e)
            return null
          }),

      getStructuredAddress: async (place: any) => {
        if (!place) return null

        const lat = place.center?.[1]
        const lng = place.center?.[0]

        const tz = lat && lng && (await getTimezoneFromLocation(lat, lng))

        const m = (kind: string) => (it: any) => it.id.startsWith(`${kind}.`)

        const isPostcode = m('postcode')(place)
        const isAddress = m('address')(place)
        const isPOI = m('poi')(place)

        const addressCountry = find(m('country'), place.context)?.text || null
        const addressRegion = find(m('district'), place.context)?.text || null
        const addressState = find(m('region'), place.context)?.text || null
        const addressLocality = find(m('place'), place.context)?.text || null

        let postalCode = null
        if (isPostcode) {
          postalCode = place.text || null
        } else {
          postalCode = find(m('postcode'), place.context)?.text || null
        }

        let streetAddress = null
        if (isAddress) {
          streetAddress = [place.address || '', place.text || ''].join(' ').trim() || null
        } else if (isPOI) {
          streetAddress = (place.label || '').replace(place.text || '', '')
          streetAddress = streetAddress
            .replace(addressCountry, '')
            .replace(addressRegion, '')
            .replace(addressState, '')
            .replace(addressLocality, '')
            .replace(postalCode, '')
            .replace(/\s+/g, ' ')
            .replace(/, ,/g, ',') // Country
            .replace(/, ,/g, ',') // Region
            .replace(/, ,/g, ',') // State
            .replace(/, ,/g, ',') // Locality
            .replace(/, ,/g, ',') // Post code
            .replace(/^,\s+/, '') // POI name
            .replace(/,\s+$/, '') // Trailing
            .trim()
        }

        const address = {
          timezoneName: tz || null,
          latitude: lat || null,
          longitude: lng || null,

          addressCountry,
          countryCode:
            find(m('country'), place.context)?.short_code?.toUpperCase() ||
            getAlpha2ByName(addressCountry, locale) ||
            null,

          addressRegion,
          addressState,
          addressLocality,

          streetAddress,
          postalCode,
        }

        // console.log('MAPS DEBUG', place, address)
        return address
      },
    }),
    [locale]
  )

  return <mapsContext.Provider value={ctxValue}>{children}</mapsContext.Provider>
}

export default MapsProvider
