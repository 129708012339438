import React from 'react'
import styled from 'styled-components/macro'

import { color } from '../utils/variables'

const DotsContainer = styled.div`
  position: relative;
  overflow: hidden;

  width: 70px;
  height: 40px;
`

const Dots = styled.div`
  position: relative;

  top: 50%;
  margin-top: -4px;
  margin-left: 31px;

  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${color.primary};
  color: ${color.primary};
  box-shadow: 9984px 0 0 0 ${color.primary}, 9999px 0 0 0 ${color.primary}, 10014px 0 0 0 ${color.primary};
  animation: dotTyping 1.5s infinite linear;

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 ${color.primary}, 9999px 0 0 0 ${color.primary}, 10014px 0 0 0 ${color.primary};
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 ${color.primary}, 9999px 0 0 0 ${color.primary}, 10014px 0 0 0 ${color.primary};
    }
    33.333% {
      box-shadow: 9984px 0 0 0 ${color.primary}, 9999px 0 0 0 ${color.primary}, 10014px 0 0 0 ${color.primary};
    }
    50% {
      box-shadow: 9984px 0 0 0 ${color.primary}, 9999px -10px 0 0 ${color.primary}, 10014px 0 0 0 ${color.primary};
    }
    66.667% {
      box-shadow: 9984px 0 0 0 ${color.primary}, 9999px 0 0 0 ${color.primary}, 10014px 0 0 0 ${color.primary};
    }
    83.333% {
      box-shadow: 9984px 0 0 0 ${color.primary}, 9999px 0 0 0 ${color.primary}, 10014px -10px 0 0 ${color.primary};
    }
    100% {
      box-shadow: 9984px 0 0 0 ${color.primary}, 9999px 0 0 0 ${color.primary}, 10014px 0 0 0 ${color.primary};
    }
  }
`

const DotsWithContainer = () => (
  <DotsContainer>
    <Dots />
  </DotsContainer>
)

export default DotsWithContainer
