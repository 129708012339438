import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { textStyle } from '../utils/typography'
import { mediaQuery } from '../utils/variables'

import { Modal, ModalBody, ModalDialog, ModalFooter, ModalFooterControl } from './Modal'
import Svg from './Svg'

export interface IConfirmationModalProps {
  title: string
  description?: string
  cta?: string
  ctaIcon?: string
  rejectCta?: string
  loading?: boolean
  icon?: string
  onConfirm?: () => void
  onReject?: () => void
}

const StyledModal = styled(Modal)`
  ${ModalDialog} {
    width: 426px;
    text-align: center;
    padding: 48px 32px 16px;
    ${mediaQuery.lessThan('tablet')`
      width: 100%;
      padding: 32px 20px;
    `}
    h3 {
      ${textStyle.heading.md}
    }
  }
  ${ModalFooter} {
    padding: 16px;
    border-top-width: 0;
    margin-top: 16px;
    ${mediaQuery.lessThan('tablet')`
      display: block;
      border-top-width: 2px;
      bottom: -32px;
      margin: 20px -20px -32px;
      padding: 20px;
    `}
  }

  ${ModalFooterControl} {
    min-width: 33%;
  }
`

const Description = styled.p`
  margin: 16px 0;
  word-break: break-word;
`

const Title = styled.p`
  ${textStyle.heading.md}
`

export const ConfirmationModal: FC<React.PropsWithChildren<IConfirmationModalProps>> = ({
  title,
  description,
  icon,
  cta,
  ctaIcon,
  rejectCta,
  loading,
  onConfirm,
  onReject,
}) => {
  const intl = useIntl()

  return (
    <StyledModal modalTitle={icon ? null : title} onClose={onReject}>
      <ModalBody>
        {icon && (
          <>
            <Svg icon={icon} width={64} height={64} />
            <Title>{title}</Title>
          </>
        )}
        {description && <Description>{description}</Description>}
      </ModalBody>
      <ModalFooter>
        {onConfirm && (
          <ModalFooterControl icon={ctaIcon} loading={!!loading} block={!onReject} onClick={onConfirm}>
            {cta || intl.formatMessage({ id: 'actions.confirm' })}
          </ModalFooterControl>
        )}
        {onReject && (
          <ModalFooterControl block={!onConfirm} preset="secondary" onClick={onReject}>
            {rejectCta || intl.formatMessage({ id: 'actions.cancel' })}
          </ModalFooterControl>
        )}
      </ModalFooter>
    </StyledModal>
  )
}
