import { useMemo } from 'react'
import { Dictionary } from 'ts-essentials'
import { find, keyBy, mapValues, sumBy } from 'lodash/fp'
import { useFormikContext } from 'formik'

import IEventFormTickets, { ITicketType } from '../types/Tickets'

function useSoldOutTickets(allTtys: ITicketType[]) {
  const { values } = useFormikContext<IEventFormTickets>()

  const soldOutMap: Dictionary<boolean> = useMemo(
    () =>
      mapValues((it: { totalReserved: number; totalSold: number; ticketTypeId: string }) => {
        if (!it) return null

        const tty = find(['id', it.ticketTypeId], allTtys)

        const allocation =
          (tty?.priceTierType === 'allocation' ? sumBy('allocation', tty.priceTiers || []) : tty?.allocation) || 0

        const remainingTickets = allocation - (it.totalReserved + it.totalSold)

        return remainingTickets <= 0
      }, keyBy('ticketTypeId', values.sales?.ticketTypesBreakdown || [])),
    [allTtys, values.sales?.ticketTypesBreakdown]
  )

  return soldOutMap
}

export default useSoldOutTickets
