import { compose, filter, keys, map, mergeAll } from 'lodash/fp'
import { setLocale } from 'yup'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import countries from 'i18n-iso-countries'
import enGB from 'date-fns/locale/en-GB'
import { Locale } from 'date-fns'
import enCnt from 'i18n-iso-countries/langs/en.json'
import { casual as enChrono } from 'chrono-node/dist/locales/en'

import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill'

import { Dictionary } from 'ts-essentials'
import PHRASEAPP_YUP_LOCALE from './yupLocale'

import enTranslationMessages from './translations/en.json'
import enTagsTranslationMessages from './translations/tags/en.json'
import enPermissionsTranslationMessages from './translations/permissions/en.json'
import { Language } from './enums.generated'

export async function intlPolyfills() {
  if (shouldPolyfillCanonicalLocales()) {
    await import(/* webpackChunkName: "pfgcl" */ '@formatjs/intl-getcanonicallocales/polyfill')
  }

  if (shouldPolyfillLocale()) {
    await import(/* webpackChunkName: "pfloc" */ '@formatjs/intl-locale/polyfill')
  }

  if (shouldPolyfillNumberFormat()) {
    await import(/* webpackChunkName: "pfnf" */ '@formatjs/intl-numberformat/polyfill')
  }

  if (shouldPolyfillPluralRules()) {
    await import(/* webpackChunkName: "pfpr" */ '@formatjs/intl-pluralrules/polyfill')
  }

  if (shouldPolyfillRelativeTimeFormat()) {
    await import(/* webpackChunkName: "pfrtf" */ '@formatjs/intl-relativetimeformat/polyfill')
  }

  if (shouldPolyfillListFormat()) {
    await import(/* webpackChunkName: "pflf" */ '@formatjs/intl-listformat/polyfill')
  }
}

export type ILocale = 'en-GB' | 'en-US' | 'en-IN' | 'en-CA' | 'es' | 'fr' | 'it' | 'pt' | 'de'

export const DEFAULT_LOCALE: ILocale = 'en-GB'

export const LOCALE_MAPPING: Partial<Dictionary<ILocale, Language | 'EN'>> = {
  EN: 'en-GB',
  EN_GB: 'en-GB',
  EN_US: 'en-US',
  EN_IN: 'en-IN',
  EN_CA: 'en-CA',
  ES: 'es',
  FR: 'fr',
  IT: 'it',
  PT: 'pt',
  DE: 'de',
}

interface ILocalizationMessages {
  [key: string]: string
}

setLocale(PHRASEAPP_YUP_LOCALE)

registerLocale('en', enGB)
registerLocale('en-GB', enGB)
setDefaultLocale('en')

export const dateFnsLocales: {
  [key in ILocale]: Locale
} = {
  'en-GB': enGB,
  'en-US': enGB,
  'en-IN': enGB,
  'en-CA': enGB,
  'es': enGB,
  'fr': enGB,
  'it': enGB,
  'pt': enGB,
  'de': enGB,
}

export const chronoLocales: {
  [key in ILocale]: typeof enChrono
} = {
  'en-GB': enChrono,
  'en-US': enChrono,
  'en-IN': enChrono,
  'en-CA': enChrono,
  'es': enChrono,
  'fr': enChrono,
  'it': enChrono,
  'pt': enChrono,
  'de': enChrono,
}

const ordinalSuffixesEn = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
}

type IPluralRule = 'zero' | 'one' | 'two' | 'few' | 'many' | 'other'

export const ordinalSuffixes: Partial<Dictionary<Partial<Dictionary<string, IPluralRule>>, ILocale>> = {
  'en-GB': ordinalSuffixesEn,
  'en-US': ordinalSuffixesEn,
}

countries.registerLocale(enCnt)

const formatTranslationMessages = (locale: ILocale | null, messages: ILocalizationMessages): ILocalizationMessages => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }, {})
}

const enTranslationMessagesFull = mergeAll([
  enTranslationMessages,
  enTagsTranslationMessages,
  enPermissionsTranslationMessages,
])

export const fallbackMessages = formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessagesFull)

export const PHONE_LOCALES: Partial<Dictionary<any, ILocale>> = {}

export async function loadLocale(locale: ILocale): Promise<ILocalizationMessages> {
  if (locale.startsWith('en')) {
    const promises = []

    if ((Intl.NumberFormat as any).polyfilled) {
      if (locale === 'en-GB') {
        promises.push(import(/* webpackChunkName: "pfnf_gb" */ '@formatjs/intl-numberformat/locale-data/en-GB'))
      } else if (locale === 'en-US') {
        promises.push(import(/* webpackChunkName: "pfnf_us" */ '@formatjs/intl-numberformat/locale-data/en'))
      } else if (locale === 'en-IN') {
        promises.push(import(/* webpackChunkName: "pfnf_in" */ '@formatjs/intl-numberformat/locale-data/en-IN'))
      } else if (locale === 'en-CA') {
        promises.push(import(/* webpackChunkName: "pfnf_ca" */ '@formatjs/intl-numberformat/locale-data/en-CA'))
      }
    }
    if ((Intl.PluralRules as any).polyfilled) {
      promises.push(import(/* webpackChunkName: "pfpr_en" */ '@formatjs/intl-pluralrules/locale-data/en'))
    }
    if ((Intl.RelativeTimeFormat as any).polyfilled) {
      if (locale === 'en-GB') {
        promises.push(import(/* webpackChunkName: "pfrtf_gb" */ '@formatjs/intl-relativetimeformat/locale-data/en-GB'))
      } else if (locale === 'en-US') {
        promises.push(import(/* webpackChunkName: "pfrtf_us" */ '@formatjs/intl-relativetimeformat/locale-data/en'))
      } else if (locale === 'en-IN') {
        promises.push(import(/* webpackChunkName: "pfrtf_in" */ '@formatjs/intl-relativetimeformat/locale-data/en-IN'))
      } else if (locale === 'en-CA') {
        promises.push(import(/* webpackChunkName: "pfrtf_ca" */ '@formatjs/intl-relativetimeformat/locale-data/en-CA'))
      }
    }
    if ((Intl.ListFormat as any).polyfilled) {
      if (locale === 'en-GB') {
        promises.push(import(/* webpackChunkName: "pflf_gb" */ '@formatjs/intl-listformat/locale-data/en-GB'))
      } else if (locale === 'en-US') {
        promises.push(import(/* webpackChunkName: "pflf_us" */ '@formatjs/intl-listformat/locale-data/en'))
      } else if (locale === 'en-IN') {
        promises.push(import(/* webpackChunkName: "pflf_in" */ '@formatjs/intl-listformat/locale-data/en-IN'))
      } else if (locale === 'en-CA') {
        promises.push(import(/* webpackChunkName: "pflf_ca" */ '@formatjs/intl-listformat/locale-data/en-CA'))
      }
    }
    if (locale === 'en-US') {
      const enUS = await import(/* webpackChunkName: "en_US" */ 'date-fns/locale/en-US')
      registerLocale('en-US', enUS.default)
      dateFnsLocales['en-US'] = enUS.default
    } else if (locale === 'en-IN') {
      const enIN = await import(/* webpackChunkName: "en_IN" */ 'date-fns/locale/en-IN')
      registerLocale('en-IN', enIN.default)
      dateFnsLocales['en-IN'] = enIN.default
    } else if (locale === 'en-CA') {
      const enCA = await import(/* webpackChunkName: "en_CA" */ 'date-fns/locale/en-CA')
      registerLocale('en-CA', enCA.default)
      dateFnsLocales['en-CA'] = enCA.default
    }

    await Promise.all(promises)

    return fallbackMessages
  }

  const promises = []

  switch (locale) {
    case 'fr':
      if ((Intl.NumberFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfnf_fr" */ '@formatjs/intl-numberformat/locale-data/fr'))
      }
      if ((Intl.PluralRules as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfpr_fr" */ '@formatjs/intl-pluralrules/locale-data/fr'))
      }
      if ((Intl.RelativeTimeFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfrtf_fr" */ '@formatjs/intl-relativetimeformat/locale-data/fr'))
      }
      if ((Intl.ListFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pflf_fr" */ '@formatjs/intl-listformat/locale-data/fr'))
      }

      const fr = await import(/* webpackChunkName: "fr" */ 'date-fns/locale/fr')
      registerLocale('fr', fr.default)
      dateFnsLocales['fr'] = fr.default

      const frChrono = await import(/* webpackChunkName: "fr" */ 'chrono-node/dist/locales/fr')
      chronoLocales['fr'] = frChrono.casual

      const frCnt = await import(/* webpackChunkName: "fr" */ 'i18n-iso-countries/langs/fr.json')
      countries.registerLocale(frCnt.default)

      const frPhones = await import(/* webpackChunkName: "fr" */ 'react-phone-input-2/lang/fr.json')
      PHONE_LOCALES['fr'] = frPhones.default

      await Promise.all(promises)

      const [frTranslationMessages, frTagsTranslationMessages, frPermissionsTranslationMessages] = map(
        'default',
        await Promise.all([
          import(/* webpackChunkName: "fr" */ './translations/fr.json'),
          import(/* webpackChunkName: "fr" */ './translations/tags/fr.json'),
          import(/* webpackChunkName: "fr" */ './translations/permissions/fr.json'),
        ])
      )

      return formatTranslationMessages(
        'fr',
        mergeAll([
          enTranslationMessagesFull,
          frTranslationMessages,
          frTagsTranslationMessages,
          frPermissionsTranslationMessages,
        ])
      )
    case 'es':
      if ((Intl.NumberFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfnf_es" */ '@formatjs/intl-numberformat/locale-data/es'))
      }
      if ((Intl.PluralRules as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfpr_es" */ '@formatjs/intl-pluralrules/locale-data/es'))
      }
      if ((Intl.RelativeTimeFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfrtf_es" */ '@formatjs/intl-relativetimeformat/locale-data/es'))
      }
      if ((Intl.ListFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pflf_es" */ '@formatjs/intl-listformat/locale-data/es'))
      }

      const es = await import(/* webpackChunkName: "es" */ 'date-fns/locale/es')
      registerLocale('es', es.default)
      dateFnsLocales['es'] = es.default

      const esChrono = await import(/* webpackChunkName: "es" */ './utils/chrono/es')
      chronoLocales['es'] = esChrono.casual

      const esCnt = await import(/* webpackChunkName: "es" */ 'i18n-iso-countries/langs/es.json')
      countries.registerLocale(esCnt.default)

      const esPhones = await import(/* webpackChunkName: "es" */ 'react-phone-input-2/lang/es.json')
      PHONE_LOCALES['es'] = esPhones.default

      await Promise.all(promises)

      const [esTranslationMessages, esTagsTranslationMessages, esPermissionsTranslationMessages] = map(
        'default',
        await Promise.all([
          import(/* webpackChunkName: "es" */ './translations/es.json'),
          import(/* webpackChunkName: "es" */ './translations/tags/es.json'),
          import(/* webpackChunkName: "es" */ './translations/permissions/es.json'),
        ])
      )

      return formatTranslationMessages(
        'es',
        mergeAll([
          enTranslationMessagesFull,
          esTranslationMessages,
          esTagsTranslationMessages,
          esPermissionsTranslationMessages,
        ])
      )
    case 'it':
      if ((Intl.NumberFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfnf_it" */ '@formatjs/intl-numberformat/locale-data/it'))
      }
      if ((Intl.PluralRules as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfpr_it" */ '@formatjs/intl-pluralrules/locale-data/it'))
      }
      if ((Intl.RelativeTimeFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfrtf_it" */ '@formatjs/intl-relativetimeformat/locale-data/it'))
      }
      if ((Intl.ListFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pflf_it" */ '@formatjs/intl-listformat/locale-data/it'))
      }

      const it = await import(/* webpackChunkName: "it" */ 'date-fns/locale/it')
      registerLocale('it', it.default)
      dateFnsLocales['it'] = it.default

      const itChrono = await import(/* webpackChunkName: "it" */ './utils/chrono/it')
      chronoLocales['it'] = itChrono.casual

      const itCnt = await import(/* webpackChunkName: "it" */ 'i18n-iso-countries/langs/it.json')
      countries.registerLocale(itCnt.default)

      const itPhones = await import(/* webpackChunkName: "it" */ 'react-phone-input-2/lang/it.json')
      PHONE_LOCALES['it'] = itPhones.default

      await Promise.all(promises)

      const [itTranslationMessages, itTagsTranslationMessages, itPermissionsTranslationMessages] = map(
        'default',
        await Promise.all([
          import(/* webpackChunkName: "it" */ './translations/it.json'),
          import(/* webpackChunkName: "it" */ './translations/tags/it.json'),
          import(/* webpackChunkName: "it" */ './translations/permissions/it.json'),
        ])
      )

      return formatTranslationMessages(
        'it',
        mergeAll([
          enTranslationMessagesFull,
          itTranslationMessages,
          itTagsTranslationMessages,
          itPermissionsTranslationMessages,
        ])
      )
    case 'pt':
      if ((Intl.NumberFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfnf_pt" */ '@formatjs/intl-numberformat/locale-data/pt'))
      }
      if ((Intl.PluralRules as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfpr_pt" */ '@formatjs/intl-pluralrules/locale-data/pt'))
      }
      if ((Intl.RelativeTimeFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfrtf_pt" */ '@formatjs/intl-relativetimeformat/locale-data/pt'))
      }
      if ((Intl.ListFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pflf_pt" */ '@formatjs/intl-listformat/locale-data/pt'))
      }

      const pt = await import(/* webpackChunkName: "pt" */ 'date-fns/locale/pt')
      registerLocale('pt', pt.default)
      dateFnsLocales['pt'] = pt.default

      const ptChrono = await import(/* webpackChunkName: "pt" */ 'chrono-node/dist/locales/pt')
      chronoLocales['pt'] = ptChrono.casual

      const ptCnt = await import(/* webpackChunkName: "pt" */ 'i18n-iso-countries/langs/pt.json')
      countries.registerLocale(ptCnt.default)

      const ptPhones = await import(/* webpackChunkName: "pt" */ 'react-phone-input-2/lang/pt.json')
      PHONE_LOCALES['pt'] = ptPhones.default

      await Promise.all(promises)

      const [ptTranslationMessages, ptTagsTranslationMessages, ptPermissionsTranslationMessages] = map(
        'default',
        await Promise.all([
          import(/* webpackChunkName: "pt" */ './translations/pt.json'),
          import(/* webpackChunkName: "pt" */ './translations/tags/pt.json'),
          import(/* webpackChunkName: "pt" */ './translations/permissions/pt.json'),
        ])
      )

      return formatTranslationMessages(
        'pt',
        mergeAll([
          enTranslationMessagesFull,
          ptTranslationMessages,
          ptTagsTranslationMessages,
          ptPermissionsTranslationMessages,
        ])
      )
    case 'de':
      if ((Intl.NumberFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfnf_de" */ '@formatjs/intl-numberformat/locale-data/de'))
      }
      if ((Intl.PluralRules as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfpr_de" */ '@formatjs/intl-pluralrules/locale-data/de'))
      }
      if ((Intl.RelativeTimeFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pfrtf_de" */ '@formatjs/intl-relativetimeformat/locale-data/de'))
      }
      if ((Intl.ListFormat as any).polyfilled) {
        promises.push(import(/* webpackChunkName: "pflf_de" */ '@formatjs/intl-listformat/locale-data/de'))
      }

      const de = await import(/* webpackChunkName: "de" */ 'date-fns/locale/de')
      registerLocale('de', de.default)
      dateFnsLocales['de'] = de.default

      const deChrono = await import(/* webpackChunkName: "de" */ 'chrono-node/dist/locales/de')
      chronoLocales['de'] = deChrono.casual

      const deCnt = await import(/* webpackChunkName: "de" */ 'i18n-iso-countries/langs/de.json')
      countries.registerLocale(deCnt.default)

      const dePhones = await import(/* webpackChunkName: "de" */ 'react-phone-input-2/lang/de.json')
      PHONE_LOCALES['de'] = dePhones.default

      await Promise.all(promises)

      const [deTranslationMessages, deTagsTranslationMessages, dePermissionsTranslationMessages] = map(
        'default',
        await Promise.all([
          import(/* webpackChunkName: "de" */ './translations/de.json'),
          import(/* webpackChunkName: "de" */ './translations/tags/de.json'),
          import(/* webpackChunkName: "de" */ './translations/permissions/de.json'),
        ])
      )

      return formatTranslationMessages(
        'de',
        mergeAll([
          enTranslationMessagesFull,
          deTranslationMessages,
          deTagsTranslationMessages,
          dePermissionsTranslationMessages,
        ])
      )
    default:
      throw new Error(`Unknown locale ${locale}`)
  }
}

export const RELEASED_LOCALES = new Set<ILocale | null>([
  'en-US',
  'en-GB',
  'en-IN',
  'en-CA',
  'it',
  'pt',
  'es',
  'fr',
  'de',
])

export const RELEASED_KIM_LOCALES: Set<keyof typeof LOCALE_MAPPING | null> = compose([
  (arr) => new Set<keyof typeof LOCALE_MAPPING>(arr),
  filter((k: keyof typeof LOCALE_MAPPING) => RELEASED_LOCALES.has(LOCALE_MAPPING[k] || null)),
  keys,
])(LOCALE_MAPPING)
