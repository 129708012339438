import React, { useCallback, useState, useMemo, FC, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import { compact, omit, pickBy, merge, isEmpty, last, set, isArray } from 'lodash/fp'
import qs from 'qs'
import { parseISO } from 'date-fns'
import graphql from 'babel-plugin-relay/macro'
import { Helmet } from 'react-helmet'
import { useLazyLoadQuery } from 'react-relay'

import Button from '../../components/Button'
import { PageHeader, PageTitle, PageControls, PageBody } from '../../components/Page'

import EventList from './components/EventList'
import EventListFilterBar from './components/Filters/EventFilterBar'
import { EventListLoader } from './components/EventListLoader'

import { initFilters, getEventVariables } from './components/Filters/EventFilterConfig'
import PermissionCheck from '../../components/PermissionCheck'
import { authContext } from '../../context/auth'
import EventListTypeContext, { IEventListType } from './util/eventListTypeContext'
import StaleSuspense from '../../components/StaleSuspense'

import { EventListPageQuery, EventListPageQuery$variables } from '../../__generated__/EventListPageQuery.graphql'
import SmartViewTracker from './components/SmartViewTracker'
import { trackingContext } from '../../context/tracking'
import { parseQueryParams } from '../../utils/useQuerySync'
interface IWrapperProps extends EventListPageQuery$variables {
  loading?: boolean
  orderByRaw: string
  setOrderBy: (v: string) => void
}

const ListWrapper: FC<IWrapperProps> = ({ orderByRaw, setOrderBy, loading, ...vars }) => {
  const { viewer } = useLazyLoadQuery<EventListPageQuery>(
    graphql`
      query EventListPageQuery(
        $where: EventWhereInput
        $scopes: EventScopesInput
        $orderBy: [EventsConnectionOrder]
        $needBalance: Boolean!
        $isDraft: Boolean!
        $searchTerm: String
        $noSelfPayouts: Boolean!
      ) {
        viewer {
          ...EventList_viewer
            @arguments(
              where: $where
              scopes: $scopes
              orderBy: $orderBy
              needBalance: $needBalance
              isDraft: $isDraft
              searchTerm: $searchTerm
              noSelfPayouts: $noSelfPayouts
            )
        }
      }
    `,
    vars
  )
  return viewer && <EventList viewer={viewer} loading={loading} orderBy={orderByRaw} setOrderBy={setOrderBy} />
}

const fixQueryParams = (p: any) => ({ ...p, search: p.search && (isArray(p.search) ? p.search.join(',') : p.search) })

function EventListPage() {
  const intl = useIntl()
  const { user, account } = useContext(authContext)
  const { trackEvent } = useContext(trackingContext)

  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  const listType: IEventListType = useMemo(() => {
    const type = last(pathname.split('/'))
    switch (type) {
      case 'drafts':
        return 'draft'
      case 'live':
        return 'live'
      case 'past':
        return 'past'
      case 'cancelled':
        return 'cancelled'
      default:
        return 'live'
    }
  }, [pathname])

  const initialFilters = useMemo(() => initFilters(listType) as any, [listType])

  const [filterValues, setFilterValues] = useState(() => {
    if (search) {
      return merge(initialFilters, parseQueryParams(search, fixQueryParams))
    }

    return initialFilters
  })

  const initialQueryString = useMemo(() => {
    const params = pickBy((v) => v !== initialFilters[v] && !isEmpty(v), initialFilters)
    return qs.stringify(params, { arrayFormat: 'comma', encodeValuesOnly: true })
  }, [initialFilters])

  const applyFilters = useCallback(
    (values: any) => {
      setFilterValues(values)

      const params = pickBy((v) => v !== initialFilters[v] && !isEmpty(v), values)

      const trackingFilters = omit(['search', 'orderBy'], params)
      if (!isEmpty(trackingFilters)) {
        const vars = getEventVariables(values, listType) as any
        const serializedFilters = compact([
          vars?.scopes?.eventState
            ? `event_state:${vars.scopes.eventState.join(',').toLowerCase().replace(/_/g, '')}`
            : null,
          vars?.where?.date
            ? `dates:${parseISO(vars.where.date.gt).getTime()},${parseISO(vars.where.date.lt).getTime()}`
            : null,
          vars?.where?.cityIds?.contains ? `location:${vars.where.cityIds.contains.join(',')}` : null,
        ]).join(' | ')
        trackEvent('events_filter_applied', { event_category: listType, filters_selected: serializedFilters })
      }

      const newSearch = qs.stringify(params, { arrayFormat: 'comma', encodeValuesOnly: true })

      /* Query string is changed and we're not trying to replace initial empty query string to default non-empty one */
      if (newSearch !== search && !(newSearch === initialQueryString && !search)) {
        navigate({ search: newSearch })
      }
    },
    [search, initialQueryString, initialFilters, listType, trackEvent, navigate]
  )

  const doOrderBy = useCallback(
    (v: string) => applyFilters(set('orderBy', v, filterValues)),
    [applyFilters, filterValues]
  )

  const trackNewEvent = useCallback(() => trackEvent('create_event_clicked'), [trackEvent])

  const listProps = useMemo(
    () => ({
      ...getEventVariables(filterValues, listType),
      noSelfPayouts: listType === 'past' && !!account?.forbidSelfPayouts,
      orderByRaw: filterValues.orderBy,
      setOrderBy: doOrderBy,
    }),
    [account?.forbidSelfPayouts, doOrderBy, filterValues, listType]
  )

  return (
    <EventListTypeContext.Provider value={listType}>
      <Helmet>
        <title>{intl.formatMessage({ id: `event_list.heading.${listType}` })} | DICE MIO</title>
      </Helmet>
      <PageHeader withFilters>
        <PageTitle>{intl.formatMessage({ id: `event_list.heading.${listType}` })}</PageTitle>
        <PageControls>
          <PermissionCheck permission="create:event">
            <Button to="/events/new" onClick={trackNewEvent} disabled={!user.canDoEvents.live}>
              {intl.formatMessage({ id: 'actions.new_event' })}
            </Button>
          </PermissionCheck>
        </PageControls>
        <Formik enableReinitialize initialValues={filterValues} onSubmit={applyFilters}>
          <>
            <EventListFilterBar />
            <SmartViewTracker listType={listType} />
          </>
        </Formik>
      </PageHeader>
      <PageBody extraSpacing={!isEmpty(search)}>
        <StaleSuspense component={ListWrapper} props={listProps} fallback={<EventListLoader />} />
      </PageBody>
    </EventListTypeContext.Provider>
  )
}

export default EventListPage
