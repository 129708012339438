import { color } from '../utils/variables'

export const PROMOTER_TIERS: {
  [key: string]: { key: string; defaultValue: string; i18n: string; color: keyof typeof color }
} = {
  core: {
    key: 'core',
    defaultValue: 'Core',
    i18n: 'promoter_tier.core',
    color: 'grey',
  },
  max: {
    key: 'max',
    defaultValue: 'Max',
    i18n: 'promoter_tier.max',
    color: 'grey',
  },
  plus: {
    key: 'plus',
    defaultValue: 'Plus',
    i18n: 'promoter_tier.plus',
    color: 'grey',
  },
  pro: {
    key: 'pro',
    defaultValue: 'Pro',
    i18n: 'promoter_tier.pro',
    color: 'grey',
  },
  whale: {
    key: 'whale',
    defaultValue: 'Whale',
    i18n: 'promoter_tier.whale',
    color: 'grey',
  },
}
