/**
 * @generated SignedSource<<a25aee2df79ad862594a32db567a3d32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteUser_viewer$data = {
  readonly permissionProfiles: ReadonlyArray<{
    readonly caption: string;
    readonly diceStaff: boolean;
    readonly roleName: string;
    readonly subjects: ReadonlyArray<{
      readonly actions: ReadonlyArray<{
        readonly name: string | null;
      } | null> | null;
      readonly name: string | null;
    } | null> | null;
    readonly value: string;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"PermissionView_viewer">;
  readonly " $fragmentType": "InviteUser_viewer";
};
export type InviteUser_viewer$key = {
  readonly " $data"?: InviteUser_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteUser_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteUser_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PermissionView_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PermissionProfile",
      "kind": "LinkedField",
      "name": "permissionProfiles",
      "plural": true,
      "selections": [
        {
          "alias": "value",
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "caption",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "diceStaff",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Subject",
          "kind": "LinkedField",
          "name": "subjects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Action",
              "kind": "LinkedField",
              "name": "actions",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "8c84074d307749f6e55f5ccdab2a1089";

export default node;
