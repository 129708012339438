import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { commitMutation, Environment } from 'react-relay'

import { compact, map } from 'lodash/fp'
// eslint-disable-next-line max-len
import {
  createOrUpdateTicketPoolsCreateMutation,
  createOrUpdateTicketPoolsCreateMutation$data,
} from '../../../__generated__/createOrUpdateTicketPoolsCreateMutation.graphql'
// eslint-disable-next-line max-len
import {
  createOrUpdateTicketPoolsUpdateMutation,
  createOrUpdateTicketPoolsUpdateMutation$data,
} from '../../../__generated__/createOrUpdateTicketPoolsUpdateMutation.graphql'
import IEventFormTickets from '../types/Tickets'

const CREATE_MUTATION = graphql`
  mutation createOrUpdateTicketPoolsCreateMutation($input: CreateTicketPoolInput!) {
    createTicketPool(input: $input) {
      result {
        id
        name
      }
    }
  }
`
const UPDATE_MUTATION = graphql`
  mutation createOrUpdateTicketPoolsUpdateMutation($input: UpdateTicketPoolInput!) {
    updateTicketPool(input: $input) {
      successful
      messages {
        message
      }
      result {
        id
      }
    }
  }
`

const createOrUpdateTicketPools = async (
  environment: Environment,
  ticketPools: IEventFormTickets['ticketPools'] | undefined
) => {
  if (!ticketPools || ticketPools.length === 0) return undefined

  const ids: string[] = []
  const poolsMap = new Map()
  for await (const [idx, pool] of compact(ticketPools).entries()) {
    const input = {
      clientMutationId: nanoid(),
      name: pool?.name || `Ticket pool ${String(idx + 1).padStart(2, '0')}`,
      maxAllocation: pool?.maxAllocation || 0,
    }

    if (pool?.id.startsWith('new')) {
      const { createTicketPool: response } = await new Promise<createOrUpdateTicketPoolsCreateMutation$data>(
        (resolve, reject) =>
          commitMutation<createOrUpdateTicketPoolsCreateMutation>(environment, {
            mutation: CREATE_MUTATION,
            variables: {
              input,
            },
            onCompleted: resolve,
            onError: reject,
          })
      )

      if (response && response.result?.id) {
        poolsMap.set(pool.id, response.result.id)
        ids.push(response.result.id)
      }
    } else {
      const { updateTicketPool: response } = await new Promise<createOrUpdateTicketPoolsUpdateMutation$data>(
        (resolve, reject) =>
          commitMutation<createOrUpdateTicketPoolsUpdateMutation>(environment, {
            mutation: UPDATE_MUTATION,
            variables: {
              input: {
                id: pool.id,
                ...input,
              },
            },
            onCompleted: resolve,
            onError: reject,
          })
      )

      if (response && response.successful && response.result?.id) {
        poolsMap.set(pool.id, response.result.id)
        ids.push(response.result.id)
      } else {
        poolsMap.set(pool.id, pool.id)
        ids.push(pool.id)
      }
    }
  }

  if (ids.length === 0) return undefined

  return {
    ids: map((id) => ({ id }), ids),
    poolsMap,
  }
}

export default createOrUpdateTicketPools
