import { useCallback, useMemo, useContext, useState, useLayoutEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import graphql from 'babel-plugin-relay/macro'
import { commitMutation, fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import { FormikHelpers, FormikErrors } from 'formik'
import { defaults, isArray, mapValues, groupBy, compact, map, isString, some, reject } from 'lodash/fp'
import { nanoid } from 'nanoid'
import { isItalianEvent } from '../../../utils/isCountryEvent'
import IEventForm from '../../EventForm/types'
import { notificationContext } from '../../../context/notification'
import getDefaultEvent, { ISinglePromoter } from '../../EventForm/services/getDefaultEvent'
import submitDraft from '../../EventForm/services/submitDraft'
import hasEventChanged from '../../EventForm/services/hasEventChanged'
// eslint-disable-next-line max-len
import { useEventSubmissionCloneDraftsMutation } from '../../../__generated__/useEventSubmissionCloneDraftsMutation.graphql'
import { authContext } from '../../../context/auth'
import errorsToTouched from '../../../utils/errorsToTouched'
import scrollToTopError from '../../../utils/scrollToTopError'
import { IDestinationPromoter } from '../../EventForm/hooks/usePromoters'
import validateDraft from '../../EventForm/services/validateDraft'
import { validateDraftMutation } from '../../../__generated__/validateDraftMutation.graphql'
import createOrUpdateEvent from '../../EventForm/services/createOrUpdateEvent'
import useAllowedAdhocFees from '../../EventForm/hooks/useAllowedAdhocFees'
import { EventType } from '../../../enums.generated'
import { localeContext } from '../../../context/locale'

const EVENTS_COUNT_QUERY = graphql`
  query useEventSubmissionQuery {
    viewer {
      events(first: 1) {
        count
      }
    }
  }
`

type IMsg = NonNullable<NonNullable<validateDraftMutation['response']['validateDraftEvent']>['messages']>[number]

export default function useEventSubmission(
  event: IEventForm | undefined,
  hierarchicalTypes: IEventForm['hierarchicalTags'] | null,
  singlePromoter: ISinglePromoter | null,
  destinationPromoters: null | IDestinationPromoter[]
) {
  const intl = useIntl()
  const navigate = useNavigate()
  const environment = useRelayEnvironment()

  const { state: locationState } = useLocation()
  const { addNotification } = useContext(notificationContext)
  const { account, user } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const [reviewModalId, setReviewModalId] = useState<string | null>(null)
  const [showIsFreeModal, setShowIsFreeModal] = useState(false)
  const closeReviewModal = useCallback(() => setReviewModalId(null), [setReviewModalId])

  const adhocFeesAccountId = singlePromoter?.accountId || account?.id || null
  const allowedAdhocFees = useAllowedAdhocFees(adhocFeesAccountId, null)

  const initEvent: (eventType: EventType | null) => Partial<IEventForm> = useCallback(
    (eventType) =>
      getDefaultEvent(
        intl,
        account || null,
        hierarchicalTypes,
        singlePromoter,
        destinationPromoters,
        user.defaultCurrency,
        eventType,
        allowedAdhocFees
      ),
    [account, allowedAdhocFees, destinationPromoters, hierarchicalTypes, intl, singlePromoter, user.defaultCurrency]
  )

  const initialValues: IEventForm = useMemo(() => defaults(initEvent('LIVE'), event), [initEvent, event])

  const onReviewOk = useCallback(
    async (id: string, values: any) => {
      let eventsForSubmission = [values as IEventForm]

      const isRecurringEventPartAlready = (values.recurrentEventsGroup?.length || 0) > 1

      if (
        !isRecurringEventPartAlready &&
        values.recurrentEventSchedule &&
        values.recurrentEventSchedule.frequency &&
        values.recurrentEventSchedule.repeatEnds
      ) {
        const input = {
          clientMutationId: nanoid(),
          id: id || values.id,
          ...(values.recurrentEventSchedule as any),
        }

        eventsForSubmission = await new Promise((resolve, reject) =>
          commitMutation<useEventSubmissionCloneDraftsMutation>(environment, {
            mutation: graphql`
              mutation useEventSubmissionCloneDraftsMutation($input: CloneEventInput!) {
                cloneEvent(input: $input) {
                  events {
                    id
                    state
                    ...EventSubmission_event
                  }
                }
              }
            `,
            variables: {
              input,
            },
            onCompleted: (data) => {
              resolve(((data.cloneEvent?.events as any[]) || []) as Array<IEventForm>)
            },
            onError: reject,
          })
        )
      }

      if (eventsForSubmission.length === 1) {
        await submitDraft(environment, id)
      }

      navigate(`/events/${id}/success`)
    },
    [environment, navigate]
  )

  const doValidateAndReview = useCallback(
    async (id: string, setErrors?: (errs: any) => void) => {
      try {
        await validateDraft(environment, id)
        setReviewModalId(id)
      } catch (err) {
        if (err && err.messages) {
          err.messages.forEach((msg: IMsg) => {
            if (msg?.message) addNotification('error', msg.message)
          })
          const errors: FormikErrors<IEventForm> = mapValues(
            map((m: NonNullable<IMsg>) => `raw:${m.message}`),
            groupBy('field', compact(err.messages))
          )
          if (setErrors) {
            setErrors(errors)
          }
          setTimeout(() => scrollToTopError(errors), 300)
        } else {
          addNotification('error', intl.formatMessage({ id: 'new_event.notification.draft_save_error' }))
        }
      }
    },
    [addNotification, environment, intl]
  )

  const saveAndContinue = useCallback(
    async (values: Partial<IEventForm>, helpers?: FormikHelpers<IEventForm>, saveDraft = false): Promise<void> => {
      let id = values.id

      let wasSaved = false
      try {
        const isChanged = !id || hasEventChanged(initialValues, values)
        if (isChanged) {
          const saved = await createOrUpdateEvent(environment, values, locale, false, user.diceStaff)
          id = id || saved.id
        }

        wasSaved = true

        if (saveDraft) {
          addNotification('success', intl.formatMessage({ id: 'new_event.notification.draft_saved' }))
        }

        if (!user.hasEvent) fetchQuery_DEPRECATED(environment, EVENTS_COUNT_QUERY, {})
      } catch (err) {
        if (err && (err.messages || isArray(err))) {
          const msgArr = isArray(err) ? err : err.messages
          msgArr.forEach((msg: IMsg) => {
            if (isString(msg)) addNotification('error', msg)
            if (msg?.message) addNotification('error', msg.message)
          })

          const errors: FormikErrors<IEventForm> = mapValues(
            map((m: NonNullable<IMsg>) => `raw:${m.message}`),
            groupBy('field', compact(err.messages))
          )
          if (helpers?.setErrors) {
            helpers.setErrors(errors)
          }
          setTimeout(() => scrollToTopError(errors), 300)
        } else {
          console.error(err)
          addNotification('error', intl.formatMessage({ id: 'new_event.notification.draft_save_error' }))
        }
      }

      if (!wasSaved) return

      if (saveDraft && helpers) {
        setTimeout(
          () =>
            helpers.validateForm().then((errs) => {
              helpers.setTouched(errorsToTouched(errs))
              helpers.setErrors(errs)
              setTimeout(() => scrollToTopError(errs), 300)
            }),
          100
        )
      }

      const shouldReview = !saveDraft && values.state === 'DRAFT'

      const nonArchivedTtys = values.ticketTypes ? reject('archived', values.ticketTypes) : []

      const shouldPromptFreeEvent =
        !user.diceStaff &&
        shouldReview &&
        !values.freeEvent &&
        isItalianEvent(values, locale) &&
        !some((tt) => tt?.priceTierType || (tt?.faceValue || 0) > 0, nonArchivedTtys)

      if (!values.id) {
        navigate(`/events/${id}/edit`, {
          state: {
            shouldReview,
            shouldPromptFreeEvent,
            scrollOffset: window.pageYOffset,
          },
        })
        return
      }

      if (shouldPromptFreeEvent) {
        return setShowIsFreeModal(true)
      }

      if (shouldReview && id) {
        await doValidateAndReview(id, (errs) => {
          if (helpers) {
            helpers.setTouched(errorsToTouched(errs))
            helpers.setErrors(errs)
          }
        })
      }
    },
    [
      initialValues,
      user.hasEvent,
      user.diceStaff,
      environment,
      locale,
      addNotification,
      intl,
      navigate,
      doValidateAndReview,
    ]
  )

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useLayoutEffect(() => {
    const state = locationState as any

    if (!initialValues.id || (!state?.shouldReview && !state?.scrollOffset)) return

    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      if (state.scrollOffset) {
        window.scrollBy(0, state.scrollOffset)
      }

      if (state.shouldPromptFreeEvent) {
        setShowIsFreeModal(true)
      } else if (state.shouldReview) {
        doValidateAndReview(initialValues.id)
      }
    }, 300)
  }, [doValidateAndReview, initialValues.id, locationState])

  return {
    initEvent,
    initialValues,
    saveAndContinue,
    reviewModalId,
    onReviewOk,
    closeReviewModal,
    showIsFreeModal,
    closeIsFreeModal: () => setShowIsFreeModal(false),
  }
}
