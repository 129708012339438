import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { isNumber } from 'lodash/fp'
import { closedPriceTier } from '../../../../utils/tiers'

import useTicketTypeCalculations from '../../hooks/useTicketTypeCalculations'

import {
  Progress,
  Relative,
  CustomProgressBar,
  PriceTierDot,
  ProgressBarValues,
  SoldValue,
  AllocationValue,
  RemainingValue,
  ProgressValues,
} from './TicketBreakdownStyles'

import { TicketBreakdown_event$data } from '../../../../__generated__/TicketBreakdown_event.graphql'

interface IProps {
  event: TicketBreakdown_event$data
  pooled?: boolean
  ticketType: NonNullable<NonNullable<NonNullable<TicketBreakdown_event$data['sales']>['ticketTypesBreakdown']>[number]>
}

const TicketSalesProgress: FC<React.PropsWithChildren<IProps>> = ({ event, pooled, ticketType: tt }) => {
  const intl = useIntl()
  const { isSold, priceTierDotPercentage } = useTicketTypeCalculations(event.costCurrency || undefined)
  const totalSold = (tt.totalAppSold || 0) + (tt.totalPosSold || 0)
  const salesLimit = tt.ticketType.salesLimit || 0
  const salesLimitRemaining = Math.max(salesLimit - totalSold, 0)

  return (
    <Progress
      primary={
        !pooled && (
          <Relative>
            <CustomProgressBar
              size="small"
              gradient
              color={isSold(tt) ? 'secondary' : 'primary'}
              value={tt.ticketType.allocation ? (100 * totalSold) / tt.ticketType.allocation : 0}
            />
            {tt.priceTiersBreakdown &&
              (tt.ticketType.allocation || 0) > 0 &&
              tt.priceTiersBreakdown.map(
                (pt) =>
                  pt &&
                  pt.priceTier &&
                  (tt.ticketType.priceTierType !== 'time' ||
                    (closedPriceTier(tt.ticketType.priceTierType, tt.priceTiersBreakdown, pt) &&
                      (pt.appSold || 0) + (pt.posSold || 0) > 0)) && (
                    <PriceTierDot
                      data-id={pt.priceTier.id}
                      percentage={priceTierDotPercentage(tt, pt)}
                      key={pt.priceTier.id}
                      icon="circle"
                    />
                  )
              )}
          </Relative>
        )
      }
      secondary={
        !pooled ? (
          <ProgressBarValues>
            <div>
              <SoldValue>
                {intl.formatNumber(totalSold, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </SoldValue>
              {' / '}
              <AllocationValue>
                {intl.formatNumber(tt.ticketType.allocation || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </AllocationValue>
            </div>
            <RemainingValue>
              <span>
                {intl.formatNumber(
                  isSold(tt)
                    ? tt.totalWlRequests || 0
                    : (tt.ticketType.allocation || 0) - (tt.totalAppSold || 0) - (tt.totalPosSold || 0),
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}{' '}
                {intl.formatMessage({
                  id: isSold(tt)
                    ? 'event_overview.ticket_breakdown.tickets_wl'
                    : 'event_overview.ticket_breakdown.tickets_remaining',
                })}
              </span>
              {!isSold(tt) && tt.totalWlRequests > 0 && (
                <span>
                  {intl.formatNumber(tt.totalWlRequests || 0, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{' '}
                  {intl.formatMessage({ id: 'event_overview.ticket_breakdown.tickets_wl' })}
                </span>
              )}
            </RemainingValue>
          </ProgressBarValues>
        ) : (
          <ProgressValues>
            <div>
              <SoldValue>
                {intl.formatNumber(totalSold, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </SoldValue>
              {salesLimit > 0 && (
                <>
                  {' / '}
                  <AllocationValue>
                    {intl.formatNumber(salesLimit, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </AllocationValue>
                </>
              )}
            </div>
            {(salesLimit > 0 || !!event.flags?.waitingList?.active) && (
              <RemainingValue>
                {salesLimit > 0 && (
                  <span>
                    {salesLimitRemaining > 0 &&
                      salesLimitRemaining +
                        ' ' +
                        intl.formatMessage({
                          id: 'event_overview.ticket_breakdown.tickets_remaining',
                        })}
                    {salesLimitRemaining <= 0 &&
                      tt.totalWlRequests <= 0 &&
                      intl.formatMessage({
                        id: 'event_overview.ticket_breakdown.tickets_sold_out',
                      })}
                  </span>
                )}
                <span>
                  {intl.formatNumber(tt.totalWlRequests || 0, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{' '}
                  {intl.formatMessage({ id: 'event_overview.ticket_breakdown.tickets_wl' })}
                </span>
              </RemainingValue>
            )}
          </ProgressValues>
        )
      }
    />
  )
}

export default TicketSalesProgress
