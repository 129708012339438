/**
 * @generated SignedSource<<57c5b0a717169fb97a98dabc4d1ffd1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayoutBlockers_event$data = {
  readonly endDate: string | null;
  readonly timezoneName: string | null;
  readonly " $fragmentType": "PayoutBlockers_event";
};
export type PayoutBlockers_event$key = {
  readonly " $data"?: PayoutBlockers_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"PayoutBlockers_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PayoutBlockers_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "c2fe0abb197937e6135d861e65c94b0b";

export default node;
