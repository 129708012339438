import { startOfDay, subDays } from 'date-fns'
import { useContext, useEffect, useMemo, useState } from 'react'
import { localeContext } from '../../../context/locale'
import { alignDateEnd, alignDateStart } from '../utils/dates'

function useTimeNavigation(byWeek: boolean, startDate: Date, endDate: Date) {
  const { locale } = useContext(localeContext)

  const [timeSpan, setTimeSpan] = useState<[Date, Date]>(() =>
    byWeek
      ? [alignDateStart(startDate, locale), alignDateStart(endDate, locale)]
      : [startOfDay(subDays(endDate, 6)), startOfDay(endDate)]
  )

  useEffect(() => {
    if (byWeek) {
      setTimeSpan([alignDateStart(startDate, locale), alignDateStart(endDate, locale)])
    } else {
      setTimeSpan([startOfDay(subDays(endDate, 6)), startOfDay(endDate)])
    }
  }, [byWeek, endDate, locale, startDate])

  const [minStartDate, maxStartDate] = useMemo(
    () => [alignDateStart(startDate, locale), alignDateEnd(endDate, locale)],
    [endDate, locale, startDate]
  )

  return { timeSpan, setTimeSpan, minStartDate, maxStartDate } as const
}

export default useTimeNavigation
