import React, { useCallback, useMemo, memo, forwardRef, useRef, useImperativeHandle, useState } from 'react'
import cn from 'classnames'
import InputCurrency from 'react-currency-masked-input'
import { find, isNil, get, reject, map, escapeRegExp } from 'lodash/fp'
import styled from 'styled-components/macro'

import { useIntl } from 'react-intl'
import { CURRENCY } from '../utils/formatters/number'
import { getCurrencySymbol } from '../utils/currency'
import { EventCostCurrency } from '../enums.generated'
import Svg from './Svg'

const StyledSvg = styled(Svg)`
  position: absolute;
  top: 8px;
  left: 8px;
`

const Container = styled.div<{ isWideSymbol?: boolean }>`
  display: flex;
  align-items: baseline;
  min-width: 0;
  width: 100%;

  .text-input_input {
    margin-left: ${({ isWideSymbol }) => (isWideSymbol ? '16px' : '0')};
  }
`
export interface ICurrencyProps {
  name: string
  className?: string
  value?: number
  disabled?: boolean
  onFocus?: (e: Event) => void
  onChange: (e: Event, v?: any) => void
  currency: EventCostCurrency | '_ANY_'
  placeholder?: string
  allowNull?: boolean
  allowNegative?: boolean
  forceNegative?: boolean
}

const CurrencyInput = forwardRef<HTMLInputElement, ICurrencyProps>((props, ref) => {
  const {
    name,
    className,
    value,
    disabled,
    onFocus,
    onChange,
    currency,
    placeholder,
    allowNull,
    allowNegative,
    forceNegative,
    ...rest
  } = props

  const intl = useIntl()

  const formatNumber = useCallback(
    (str: number | string) => {
      const cents = parseInt(`${str || '0'}`)
      const formatted = intl.formatNumberToParts(cents / 100, {
        ...CURRENCY(cents, 'GBP' /* SIC! it's fake currency to force proper formatting! */),
        useGrouping: false,
        minimumFractionDigits: 2,
      })
      return map('value', reject(['type', 'currency'], formatted))
        .join('')
        .trim()
    },
    [intl]
  )

  const separator = useMemo(() => get('value', find(['type', 'decimal'], intl.formatNumberToParts(1000.01))), [intl])
  const thePlaceholder = useMemo(
    () => (isNil(placeholder) ? formatNumber('0') : placeholder),
    [formatNumber, placeholder]
  )

  const [negative, setNegative] = useState(forceNegative || (!isNil(value) && value < 0))

  const strValue = useMemo(() => {
    if (isNil(value)) return ''

    const str = formatNumber(value).replace(/\s*/g, '')

    return allowNegative ? `${negative && value === 0 ? '-' : ''}${str}` : str
  }, [allowNegative, formatNumber, negative, value])

  const handleChange = useCallback(
    (e: any, v: string) => {
      let val = v ? Math.round(parseFloat(v.replace(separator, '.').replace(/\s*/g, '')) * 100) : 0

      if (allowNegative) {
        // Pressing minus key at any time switches number to be negative
        const wantNegative = forceNegative || e.target.value.indexOf('-') >= 0

        // Pressing plus key at any time switches number to be positive
        const wantPositive = !forceNegative && e.target.value.indexOf('+') >= 0

        // Negative state is sticky, so typing "-200" will result "$-2.00"
        let nextNegative = negative

        if (!negative && wantNegative) {
          nextNegative = true
        } else if (negative && wantPositive) {
          nextNegative = false
        }

        if (negative !== nextNegative) {
          setNegative(nextNegative)
        }

        if ((nextNegative && val > 0) || (!nextNegative && val < 0)) {
          val = val * -1
        }
      }

      const fakeE = {
        target: {
          id: e.target.id,
          name: e.target.name,
          value: allowNull && e.target.value === '' ? null : String(val),
          type: 'number',
        },
      } as any

      if (onChange) onChange(fakeE, fakeE.target.value)
    },
    [allowNegative, allowNull, forceNegative, negative, onChange, separator]
  )

  const handleFocus = useCallback(
    (e: any) => {
      if (!value && !allowNull) {
        handleChange(e, '0')
      }
      if (onFocus) {
        onFocus(e)
      }
    },
    [value, allowNull, onFocus, handleChange]
  )

  const currencySymbol = useMemo(() => {
    return currency === '_ANY_' ? <StyledSvg icon="coins" /> : getCurrencySymbol(intl, currency)
  }, [intl, currency])

  const isWideSymbol = useMemo(() => {
    return currency !== '_ANY_' && getCurrencySymbol(intl, currency).length > 1
  }, [intl, currency])

  const divRef = useRef<HTMLDivElement | null>(null)

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => {
    return divRef.current?.getElementsByTagName('input')?.[0] || null
  })

  return (
    <Container ref={divRef} isWideSymbol={isWideSymbol}>
      {currencySymbol ? <span className="text-input_currency">{currencySymbol}</span> : null}

      <InputCurrency
        name={name}
        className={cn('text-input_input', className)}
        value={strValue}
        type="tel"
        disabled={disabled}
        separator={separator}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={thePlaceholder}
        pattern={`[${allowNegative ? '-' : ''}0-9${escapeRegExp(separator)}]*`}
        {...rest}
      />
    </Container>
  )
})

export default memo(CurrencyInput)
