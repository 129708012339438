import styled, { css } from 'styled-components/macro'
import { textStyle } from '../../../../utils/typography'
import { color, mediaQuery } from '../../../../utils/variables'

import Badge from '../../../../components/Badge'
import Button from '../../../../components/Button'
import IconButton from '../../../../components/IconButton'
import { Dropdown } from '../../../../components/Dropdown'

export const DropzoneContent = styled.div``

export const DropzoneIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 56px 0 0;
  width: 56px;
  height: 56px;
  background-color: #ebebff;
  border-radius: 8px;
`

export const DropzoneDescription = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  .linkLike {
    color: ${color.primary};
    cursor: pointer;
  }
  & > p {
    margin: 0;
  }
  & > span {
    color: ${color.darkgrey};
  }
`

export const Dropzone = styled.div<{ focus?: boolean; hasError: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
  border: 2px dashed ${color.grey};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  cursor: default;

  ${(props) =>
    props.focus &&
    css`
      border-color: ${color.primary};
      ${DropzoneIcon} > svg {
        color: ${color.primary};
      }
    `}

  &:hover {
    cursor: pointer;
    border-color: ${color.primary};
    ${DropzoneIcon} > svg {
      color: ${color.primary};
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${color.error};
      ${DropzoneIcon} {
        background-color: #fff1f5;
      }
    `}
`

export const SmallDropzone = styled(Dropzone)`
  position: relative;
  flex: none !important;
  background-color: #ebebff;
  padding: 0;
  flex: calc(25% - 6px) 0 0;
  width: calc(25% - 6px);
  padding: 0;
  padding-bottom: calc(25% - 8px);
  min-width: 92px;
  border-width: 1px !important;
  gap: 4px;

  ${DropzoneDescription} {
    gap: 4px;
  }

  ${mediaQuery.lessThan('tablet')`
    flex: calc(50% - 4px) 0 0;
    width: calc(50% - 4px);
    padding-bottom: calc(50% - 6px);
  `}

  ${DropzoneContent} {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &:hover {
    cursor: pointer;
    border-color: ${color.primary};
    ${DropzoneIcon} > svg, ${DropzoneDescription} {
      color: ${color.primary};
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      background-color: #fff1f5;
      &:hover {
        background-color: #ebebff;
        cursor: disabled;
      }
    `}

  ${DropzoneIcon} {
    flex: 24px 0 0;
    margin-bottom: 4px;
    background: none;
  }
  ${DropzoneDescription} {
    ${textStyle.functional.sm};
    color: ${color.darkgrey};
    p {
      margin: 0;
    }
  }
`

export const ProductImagesListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  overflow: auto;
`

export const ProductImageTopControl = styled.div<{ disabled?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
  width: 24px;
  height: 24px;
  background: ${color.black};
  color: ${color.white};
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    user-select: none;
  }
`

export const RemoveButton = styled(ProductImageTopControl)`
  right: 8px;
  &:hover {
    background: ${color.black};
  }
`

export const CoverInfo = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 1;
`

export const CoverInfoHint = styled.span`
  display: none;
  color: ${color.white};
  text-align: center;
  ${textStyle.functional.sm};
`

export const CoverBadge = styled(Badge)`
  position: absolute;
  left: 8px;
  bottom: 8px;
  background-color: #ebebff;
  color: ${color.primary};
  z-index: 1;
`

export const CoverInfoButton = styled(ProductImageTopControl)`
  right: 8px;
  &:hover {
    & + ${CoverInfo} {
      background: rgba(0, 0, 0, 0.8);
      ${CoverInfoHint} {
        display: inline-block;
      }
      ${CoverBadge} {
        display: none;
      }
    }
  }
`

export const ProductImageControls = styled.div`
  display: none;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
  ${Button} {
    position: absolute;
    left: 50%;
    bottom: 8px;
    transform: translateX(-50%);
    z-index: 2;
    &:hover {
      background: ${color.black};
      border-color: ${color.black};
    }
  }
  ${Dropdown} {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
  }
  ${IconButton} {
    width: 24px;
    height: 24px;
    z-index: 2;
    background: ${color.black};
    border-color: ${color.black};
    color: ${color.white};
    svg {
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: ${color.black};
      border-color: ${color.black};
    }
  }

  ${mediaQuery.lessThan('tablet')`
    display: flex;
    &:before {
      display: none;
    }
  `}
`

export const ProductImageWrapper = styled.div<{ bgColor?: string; disabled?: boolean }>`
  position: relative;
  flex: calc(25% - 6px) 0 0;
  width: calc(25% - 6px);
  padding-bottom: calc(25% - 6px);
  min-width: 92px;
  transform: translateZ(0);
  ${({ bgColor }) =>
    bgColor &&
    css`
      border-radius: 8px;
      overflow: hidden;
      img {
        background-color: ${bgColor};
        user-select: none;
      }
    `}
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        ${CoverBadge} {
          display: none;
        }
        ${ProductImageControls} {
          display: block;
        }
      }
    `}

  ${mediaQuery.lessThan('tablet')`
    flex: calc(50% - 4px) 0 0;
    width: calc(50% - 4px);
    padding-bottom: calc(50% - 4px)
  `}
`

export const ProductImagePreview = styled.div<{ disabled?: boolean }>`
  img {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid ${color.grey};
    border-radius: 8px;
    user-select: none;
    pointer-events: none;
  }
`

export const ProductImage = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  user-select: none;
  transform: translateZ(0);
  pointer-events: all !important;
  &.-grabbing {
    cursor: grabbing;
  }
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
    `}
`
