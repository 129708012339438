import { SalesforceContractStatus } from '../enums.generated'
import { SALESFORCE_URL } from '../env'
import { color } from './variables'

export const getSalesforceStatusColor = (status: SalesforceContractStatus | null | undefined): keyof typeof color => {
  switch (status) {
    case 'ACTIVATED':
    case 'SUPERSEDED':
      return 'success'
    case 'SIGNED':
      return 'primary'
    case 'CANCELLED':
    case 'EXPIRED':
    default:
      return 'text'
  }
}

export const getSalesforceContractUrl = (sfId: string | null | undefined) =>
  sfId ? `${SALESFORCE_URL}/lightning/r/Contact/${encodeURIComponent(sfId)}/view` : undefined
