function getDeepKeys(obj: any): string[] {
  let keys: string[] = []
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      const subkeys = getDeepKeys(obj[key])
      keys = keys.concat(
        subkeys.map(function (subkey) {
          return key + '.' + subkey
        })
      )
    } else {
      keys.push(key)
    }
  }
  return keys
}

export default getDeepKeys
