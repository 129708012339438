import React, { FC, Suspense, useContext } from 'react'
import { Outlet } from 'react-router'

import ErrorBoundary from '../components/ErrorBoundary'
import { Loader, LoaderContainer } from '../components/Loader'
import { Page, PageContainer } from '../components/Page'
import { localeContext } from '../context/locale'
import Sidebar from '../flows/Sidebar/Sidebar'
import StripeProblemBanner from '../components/StripeProblemBanner'
import { authContext } from '../context/auth'

const SidebarLayout: FC = () => {
  const { phraseEnabled } = useContext(localeContext)
  const { user } = useContext(authContext)

  return (
    <Page>
      <Sidebar ICEenabled={phraseEnabled} />
      <PageContainer ICEenabled={phraseEnabled}>
        <ErrorBoundary>
          <Suspense
            fallback={
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            }
          >
            {!user.diceStaff && <StripeProblemBanner />}
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </PageContainer>
    </Page>
  )
}

export default SidebarLayout
