export function waitForImages(el: HTMLElement | null, callback: () => void) {
  const images = (el || document).querySelectorAll('img')
  const imageCount = images.length

  if (imageCount === 0) {
    callback()
    return
  }

  let loadedImages = 0
  images.forEach((img) => {
    if (img.complete) {
      loadedImages += 1

      if (loadedImages >= imageCount) {
        callback()
      }
    } else {
      const lsnr = () => {
        loadedImages += 1

        if (loadedImages >= imageCount) {
          callback()
        }
      }
      img.addEventListener('load', lsnr)
      img.addEventListener('error', lsnr)
    }
  })
}

export const waitForImagesPromise = (el: HTMLElement | null, timeout?: number) =>
  Promise.race([
    new Promise<void>((resolve) => waitForImages(el, resolve)),
    ...(timeout ? [new Promise((resolve) => setTimeout(resolve, timeout))] : []),
  ])
