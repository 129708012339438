import React, { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { concat, find } from 'lodash/fp'

import { authContext } from '../context/auth'
import { mediaQuery } from '../utils/variables'
import Button from './Button'
import DismissableBanner from './DismissableBanner'
import { showAccountSwitcher } from '../flows/Sidebar/services/sidebarTrigger'

const StyledBanner = styled(DismissableBanner)`
  margin-bottom: 32px;

  strong {
    white-space: nowrap;
  }

  ${Button} {
    margin-top: -4px;
  }

  ${mediaQuery.lessThan('desktop')`
    margin: 16px 16px 8px 16px;

    ${Button} {
      margin-top: -2px;
    }
  `}
`

const CollaboratorsBanner = () => {
  const intl = useIntl()
  const { availableAccounts } = useContext(authContext)

  const hasCollaborators = useMemo(
    () => !!find(['membershipType', 'COLLABORATORS'], availableAccounts),
    [availableAccounts]
  )

  return !hasCollaborators ? null : (
    <StyledBanner dismissKey="dashboardCollaborators">
      {intl.formatMessage(
        { id: 'dashboard.banner.collaborator_reminder' },
        {
          a: (str: string | string[]) => (
            <strong>
              <Button preset="link" onClick={showAccountSwitcher}>
                {concat([], str).map((s) => s.trim())}
              </Button>
            </strong>
          ),
        }
      )}
    </StyledBanner>
  )
}

export default CollaboratorsBanner
