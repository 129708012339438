/**
 * @generated SignedSource<<8a49f8750080254af8dbb6d4636cb732>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayoutModalLazyQuery$variables = {
  id: string;
};
export type PayoutModalLazyQuery$data = {
  readonly event: {
    readonly " $fragmentSpreads": FragmentRefs<"PayoutModal_event">;
  } | null;
};
export type PayoutModalLazyQuery = {
  response: PayoutModalLazyQuery$data;
  variables: PayoutModalLazyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutModalLazyQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PayoutModal_event"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayoutModalLazyQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezoneName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventIdLive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "costCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AllowedActions",
                "kind": "LinkedField",
                "name": "allowedActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performPayout",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdjustmentsStats",
                "kind": "LinkedField",
                "name": "adjustmentsStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "failed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notProcessed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Venue",
                "kind": "LinkedField",
                "name": "venues",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "selfPayoutBlockers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Promoter",
                "kind": "LinkedField",
                "name": "billingPromoter",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remittanceRecipients",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payoutsEnabled",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EventBalance",
                "kind": "LinkedField",
                "name": "balance",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isProcessing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountPromoterOwed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountPromoterTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Payout",
                    "kind": "LinkedField",
                    "name": "payouts",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "534fe893bc58f45d17680ff1f3e94380",
    "id": null,
    "metadata": {},
    "name": "PayoutModalLazyQuery",
    "operationKind": "query",
    "text": "query PayoutModalLazyQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ...PayoutModal_event\n    id\n  }\n}\n\nfragment PayoutBlockers_event on Event {\n  endDate\n  timezoneName\n}\n\nfragment PayoutModal_event on Event {\n  id\n  name\n  endDate\n  timezoneName\n  eventIdLive\n  costCurrency\n  state\n  ...useEventPayout_event\n  ...usePerformPayout_event\n  ...PayoutBlockers_event\n  billingPromoter {\n    name\n    remittanceRecipients\n    id\n  }\n}\n\nfragment useEventPayout_event on Event {\n  endDate\n  allowedActions {\n    performPayout\n  }\n  adjustmentsStats {\n    failed\n    notProcessed\n  }\n  countryCode\n  addressCountry\n  venues {\n    countryCode\n    addressCountry\n    id\n  }\n  selfPayoutBlockers\n  billingPromoter {\n    remittanceRecipients\n    payoutsEnabled\n    id\n  }\n  balance {\n    availability\n    isProcessing\n    amountPromoterOwed\n    amountPromoterTotal\n    payouts {\n      status\n      id\n    }\n  }\n}\n\nfragment usePerformPayout_event on Event {\n  id\n  costCurrency\n}\n"
  }
};
})();

(node as any).hash = "ab8bbc3fd564a3434617e1c15a2d1681";

export default node;
