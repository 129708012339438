import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from '../../../components/Modal'
import { color } from '../../../utils/variables'
import Collapsible, { CollapseContainer } from '../../../components/Collapsible'
import { useMarketeersQuery$data } from '../../../__generated__/useMarketeersQuery.graphql'
import { textStyle } from '../../../utils/typography'
import Svg from '../../../components/Svg'

type MarketingProfileSearchData = NonNullable<
  NonNullable<NonNullable<NonNullable<NonNullable<useMarketeersQuery$data['viewer']>['options']>['edges']>[0]>['node']
>

interface MarketingProfileData extends MarketingProfileSearchData {
  value: string
  label: string
}

interface IProps {
  marketeers: Array<MarketingProfileData | null>
  onClose?: () => void
}

const StyledModalBody = styled(ModalBody)`
  min-height: calc(100vh - 300px);
`

const Profile = styled.div`
  padding: 16px;
  background-color: ${color.palegrey};
  border-radius: 4px;
  margin-bottom: 16px;

  ${CollapseContainer} {
    margin: 0;
  }
`

interface ProfileRowProps {
  profile: MarketingProfileData
}

const SectionHeading = styled.div`
  ${textStyle.heading.xs}
`

const CollapsibleContent = styled.div`
  margin-top: 20px;
`

const TagsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
  margin-top: 16px;
`

const Tag = styled.div`
  display: inline-block;
  background: ${color.lightgrey};
  ${textStyle.label.regular};
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 4px;
`

const LinkRow = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`

const ProfileLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  color: ${color.primary};

  svg {
    width: 18px;
    height: 18px;
  }
`

const FadedSvg = styled(Svg)`
  color: ${color.grey};
`

const OptInsTable = styled.table`
  margin-top: 16px;
  width: 100%;

  svg {
    color: ${color.darkgrey};
  }

  thead {
    color: ${color.darkgrey};
    text-align: left;
  }

  th {
    padding-bottom: 12px;
    ${textStyle.interactive.sm};
    text-align: center;
    text-wrap: nowrap;
    padding-left: 32px;

    &:first-child {
      text-align: left;
      width: 100%;
      padding-left: 0;
    }
  }

  tbody {
    td {
      border-top: 1px solid ${color.lightgrey};
    }
  }

  td {
    ${textStyle.functional.sm}
    text-align: center;
    vertical-align: middle;
    padding-left: 32px;

    &:first-child {
      text-align: left;
      padding: 15px 0;
      padding-left: 0;
    }
  }
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

function ProfileRow({ profile }: ProfileRowProps) {
  const intl = useIntl()

  const trackingTags = useMemo(() => {
    const tags = []

    if (profile.fbPixelId) {
      tags.push(intl.formatMessage({ id: 'marketeers.facebook_pixel' }))
    }

    if (profile.fbAccessToken) {
      tags.push(intl.formatMessage({ id: 'marketeers.fb_conversion' }))
    }

    if (profile.gaTrackingId) {
      tags.push(intl.formatMessage({ id: 'marketeers.ga_tracking' }))
    }

    if (profile.googleAdsConversionId || profile.googleAdsPurchaseConversionLabel) {
      tags.push(intl.formatMessage({ id: 'marketeers.google_ads_tracking' }))
    }

    if (profile.tiktokPixelId) {
      tags.push(intl.formatMessage({ id: 'marketeers.tiktok_pixel' }))
    }

    if (profile.twitterPixelId || profile.twitterCheckoutInitiatedPixelId || profile.twitterPurchasePixelId) {
      tags.push(intl.formatMessage({ id: 'marketeers.twitter_pixel' }))
    }

    return tags
  }, [intl, profile])

  return (
    <Profile key={profile.id}>
      <Collapsible label={profile.label}>
        <CollapsibleContent>
          <Sections>
            <div>
              <SectionHeading>{intl.formatMessage({ id: 'marketeer_form.steps.optins' })}</SectionHeading>
              <OptInsTable cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: 'new_event.settings.marketing.opt_in_when' })}</th>
                    <th>{intl.formatMessage({ id: 'marketeer.opt_ins_platforms_label_web' })}</th>
                    <th>{intl.formatMessage({ id: 'marketeer.opt_ins_platforms_label_app' })}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{intl.formatMessage({ id: 'new_event.settings.marketing.buying_tickets' })}</td>
                    <td>{profile.webOptInEnabled ? <Svg icon="tick" /> : <FadedSvg icon="cross-bold" />}</td>
                    <td>{profile.appOptInEnabled ? <Svg icon="tick" /> : <FadedSvg icon="cross-bold" />}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: 'new_event.settings.marketing.joining_waiting_list' })}</td>
                    <td>{profile.webOptInEnabled ? <Svg icon="tick" /> : <FadedSvg icon="cross-bold" />}</td>
                    <td>{profile.appOptInEnabled ? <Svg icon="tick" /> : <FadedSvg icon="cross-bold" />}</td>
                  </tr>
                  <tr>
                    <td>{intl.formatMessage({ id: 'new_event.settings.marketing.setting_reminder' })}</td>
                    <td>{profile.webOptInEnabled ? <Svg icon="tick" /> : <FadedSvg icon="cross-bold" />}</td>
                    <td>{profile.appOptInEnabled ? <Svg icon="tick" /> : <FadedSvg icon="cross-bold" />}</td>
                  </tr>
                </tbody>
              </OptInsTable>
            </div>
            {trackingTags.length ? (
              <div>
                <SectionHeading>{intl.formatMessage({ id: 'marketeer_form.steps.tracking' })}</SectionHeading>
                <TagsRow>
                  {trackingTags.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))}
                </TagsRow>
              </div>
            ) : null}
          </Sections>
          <LinkRow>
            <ProfileLink to={`/marketeers/${profile.value}`} target="_blank">
              {intl.formatMessage({ id: 'new_event.settings.marketing.go_to_profile' })}
              <Svg icon="external-link" />
            </ProfileLink>
          </LinkRow>
        </CollapsibleContent>
      </Collapsible>
    </Profile>
  )
}

const EventMarketingProfilesModal: FC<IProps> = ({ marketeers, onClose }) => {
  const intl = useIntl()

  return (
    <Modal modalTitle={intl.formatMessage({ id: 'new_event.settings.marketing.label' })} closeButton onClose={onClose}>
      <StyledModalBody>
        {marketeers.map((profile) => (profile ? <ProfileRow key={profile.id} profile={profile} /> : null))}
      </StyledModalBody>
    </Modal>
  )
}

export default memo(EventMarketingProfilesModal)
