import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import ProgressBar from '../../../components/ProgressBar'
import EventCardValue from '../../../components/Event/EventCardValue'

import { STEP_COUNT } from '../../EventForm/services/getStepsConfig'
// eslint-disable-next-line max-len
import { EventPartDraftProgress_event$key } from '../../../__generated__/EventPartDraftProgress_event.graphql'

interface IProps {
  event: EventPartDraftProgress_event$key
}

const EventPartDraftProgress: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment EventPartDraftProgress_event on Event {
        id
        state
        statusAsOfNow
        completedSteps
      }
    `,
    eventKey
  )

  const progress =
    (event.completedSteps || 0) > STEP_COUNT || event.state === 'APPROVED'
      ? 100
      : ((event.completedSteps || 0) * 100) / STEP_COUNT
  const progressColor = event.state !== 'DRAFT' ? 'secondary' : 'primary'

  const progressStatus = useMemo(() => {
    if (event.statusAsOfNow === 'draft') {
      return progress < 100
        ? intl.formatMessage(
          { id: 'event.draft_progress.steps_to_complete' },
          { currentStep: event.completedSteps, totalSteps: STEP_COUNT }
        )
        : intl.formatMessage({ id: 'event_state.completed' })
    } else {
      return event.state === 'SUBMITTED'
        ? intl.formatMessage({ id: 'event_state.submitted' })
        : intl.formatMessage({ id: 'event_state.in_review' })
    }
  }, [event, progress, intl])

  return (
    <EventCardValue
      primary={<ProgressBar size="small" color={progressColor} gradient value={progress} />}
      secondary={progressStatus}
      grow
    />
  )
}

export default memo(EventPartDraftProgress)
