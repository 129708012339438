import { Dictionary } from 'ts-essentials'
import { color } from '../utils/variables'

export interface IStatus {
  key: string
  defaultValue: string
  i18n: string
  color: keyof typeof color
}

export const EVENT_STATUS: Dictionary<IStatus> = {
  // End override
  'draft': {
    key: 'draft',
    defaultValue: 'Draft',
    i18n: 'event_status.draft',
    color: 'grey',
  },
  'ready-to-submit': {
    key: 'ready-to-submit',
    defaultValue: 'Submit for review',
    i18n: 'event_status.submit_for_review',
    color: 'secondary',
  },
  'to-be-approved': {
    key: 'to-be-approved',
    defaultValue: 'In Review',
    i18n: 'event_status.in_review',
    color: 'warning',
  },
  'pending': {
    key: 'pending',
    defaultValue: 'Unannounced',
    i18n: 'event_status.unannounced',
    color: 'grey',
  },
  'announced': {
    key: 'announced',
    defaultValue: 'Announced',
    i18n: 'event_status.announced',
    color: 'yellow',
  },
  'on-sale': {
    key: 'on-sale',
    defaultValue: 'On sale',
    i18n: 'event_status.on-sale',
    color: 'secondary',
  },
  'running-low': {
    key: 'running-low',
    defaultValue: 'Running low',
    i18n: 'event_status.running-low',
    color: 'primary',
  },
  'sold-out': {
    key: 'sold-out',
    defaultValue: 'Sold out',
    i18n: 'event_status.sold-out',
    color: 'tertiary',
  },
  'off-sale': {
    key: 'off-sale',
    defaultValue: 'Off sale',
    i18n: 'event_status.off-sale',
    color: 'grey',
  },
  'archived': {
    key: 'archived',
    defaultValue: 'Archived',
    i18n: 'event_status.archived',
    color: 'grey',
  },
  'cancelled': {
    key: 'cancelled',
    defaultValue: 'CANCELLED',
    i18n: 'event_status.cancelled',
    color: 'error',
  },
  // DRAFT CUSTOM STATUSES
  'ready_to_submit': {
    key: 'ready_to_submit',
    defaultValue: 'Ready to submit',
    i18n: 'event_status.ready_to_submit',
    color: 'secondary',
  },
  // PAST CUSTOM STATUSES
  'no-payout': {
    key: 'no-payout',
    defaultValue: 'No payout',
    i18n: 'event_status.no_payout',
    color: 'grey',
  },
  'finalising-payout': {
    key: 'finalising-payout',
    defaultValue: 'Finalising payout',
    i18n: 'event_status.finalising_payout',
    color: 'warning',
  },
  'ready_to_pay_out': {
    key: 'ready_to_pay_out',
    defaultValue: 'Payout available',
    i18n: 'event_status.payout_available',
    color: 'secondary',
  },
  'almost-ready': {
    key: 'almost_ready',
    defaultValue: 'Payout almost ready',
    i18n: 'event_status.almost_ready',
    color: 'yellow',
  },
  'pay_out_blocked': {
    key: 'pay_out_blocked',
    defaultValue: 'Pay out blocked',
    i18n: 'event_status.payout_blocked',
    color: 'warning',
  },
  'processing': {
    key: 'processing',
    defaultValue: 'Payout processing',
    i18n: 'event_status.payout_processing',
    color: 'primary',
  },
  'paid_out': {
    key: 'paid_out',
    defaultValue: 'Paid out',
    i18n: 'event_status.paid-out',
    color: 'grey',
  },
  'ended': {
    key: 'ended',
    defaultValue: 'Ended',
    i18n: 'event_status.ended',
    color: 'grey',
  },
  // Lifecycle status override
  'postponed': {
    key: 'postponed',
    defaultValue: 'Postponed',
    i18n: 'event_status.postponed',
    color: 'error',
  },
  'dice-ready-to-payout': {
    key: 'dice-ready-to-payout',
    defaultValue: 'To be paid',
    i18n: 'event_status.dice-ready-to-payout',
    color: 'yellow',
  },
}

export const CODE_LOCK_STATUS: Dictionary<IStatus> = {
  USED: {
    key: 'USED',
    defaultValue: 'Used',
    i18n: 'event_promotions.code_locks.status.used',
    color: 'secondary',
  },
  NOT_USED: {
    key: 'NOT_USED',
    defaultValue: 'Not used',
    i18n: 'event_promotions.code_locks.status.not_used',
    color: 'primary',
  },
  DISABLED: {
    key: 'DISABLED',
    defaultValue: 'Disabled',
    i18n: 'event_promotions.code_locks.status.disabled',
    color: 'grey',
  },
}
