import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { commitMutation, Environment } from 'react-relay'

import { uploadAttachmentMutation } from '../../../__generated__/uploadAttachmentMutation.graphql'

const uploadAttachment = async (environment: Environment, url: string, format: string) => {
  try {
    const blob = await fetch(url).then((r) => r.blob())

    const { createAttachment: response } = await new Promise<uploadAttachmentMutation['response']>((resolve, reject) =>
      commitMutation<uploadAttachmentMutation>(environment, {
        mutation: graphql`
          mutation uploadAttachmentMutation($input: CreateAttachmentInput!) {
            createAttachment(input: $input) {
              presignedUploadUrl
              attachment {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            clientMutationId: nanoid(),
            format,
          },
        },
        onCompleted: resolve,
        onError: reject,
      })
    )

    if (!response) return [null, null]

    const { presignedUploadUrl, attachment } = response

    await fetch(presignedUploadUrl, {
      method: 'PUT',
      body: blob,
    })

    return [attachment?.id, presignedUploadUrl.substring(0, presignedUploadUrl.indexOf('?'))]
  } catch (err) {
    console.error(err)
    return [null, null]
  }
}

export default uploadAttachment
