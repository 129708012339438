import { MarkOptional, Opaque } from 'ts-essentials'
import { set } from 'lodash/fp'
import { nanoid } from 'nanoid'

// Represents entities which are already saved on server and has real database id
type IServerId = Opaque<string, 'ServerEntityId'>

// Represents entities which are not yet saved on server and has fake client-only id, but no real database id
type IClientId = Opaque<string, 'ClientEntityId'>

/*
  Represents entities which are not yet saved on server,
  has fake client-only id, no real database id and are "transient" and
  will be destroyed when their editing is cancelled
*/
type INewId = Opaque<string, 'NewEntityId'>

// Entity is any object which CAN be saved on server; identifyable by "id" field
interface IEntity<ID> {
  id: ID
}

/*  Checks: use those methods to ensure that entity is in desired persistence state */

type ICheck<T extends string> = (it: IEntity<string>) => it is IEntity<T>

export const isSaved: ICheck<IServerId> = (it): it is IEntity<IServerId> => !isClientOnly(it) && !isNew(it)

export const isClientOnly: ICheck<IClientId> = (it): it is IEntity<IClientId> =>
  it.id ? it.id.startsWith('new') : false

export const isNew: ICheck<INewId> = (it): it is IEntity<INewId> => it.id === 'tmp'

/*  Conversions: use those methods to change entity persistence state (it MAY change entity id!!!) */

export const markAsNew = <T extends IEntity<string>>(it: MarkOptional<T, 'id'>): T & IEntity<INewId> =>
  set('id', 'tmp', it) as any

export const markAsClientOnlyNewId = <T extends IEntity<string>>(
  it: MarkOptional<T, 'id'>,
  forceId?: string
): T & IEntity<IClientId> => set('id', `new-${forceId || nanoid()}`, it) as any

export const markAsClientOnly = <T extends IEntity<string>>(
  it: MarkOptional<T, 'id'>,
  forceId?: string
): T & IEntity<IClientId> => (it.id && isClientOnly(it as any) ? it : markAsClientOnlyNewId(it, forceId)) as any

export const markAsServer = <T extends IEntity<string>>(it: MarkOptional<T, 'id'>): T & IEntity<IClientId> =>
  set('id', it.id && isSaved(it as IEntity<string>) ? it.id : null, it) as any

/*  Assertions: use those methods to ensure server won't accidentally get any client ids */

export const assertServerCompatible = <T extends IEntity<string | null>>(it: T): T => {
  if (it.id === undefined) throw new Error('Type assertion violation: expected  entity, but found plain object')
  if (it.id === null || isSaved(it as IEntity<string>)) return it
  throw new Error('Type assertion violation: expected server compatible entity, but found client one')
}

export const assertServerCompatibleDeep = <T>(it: T): T => {
  const str = JSON.stringify(it)
  return JSON.parse(str, (k, v) => (k === 'id' ? assertServerCompatible({ id: v }).id : v))
}
