/**
 * @generated SignedSource<<71e378b325ee59e4f19c7b174e5eb4a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type PromotionType = "CODE_LOCK" | "DISCOUNT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PriceTiersBreakdownTable_ticketType$data = {
  readonly priceTiersBreakdown: ReadonlyArray<{
    readonly appSold: number;
    readonly digitalValue: number;
    readonly posSold: number;
    readonly priceTier: {
      readonly allocation: number | null;
      readonly faceValue: number;
      readonly id: string;
      readonly name: string | null;
      readonly order: number;
      readonly price: number | null;
      readonly priceBreakdown: {
        readonly faceValue: number;
        readonly total: number;
        readonly totalWithPwl: number | null;
        readonly totalWithoutPwl: number | null;
      } | null;
      readonly time: string | null;
    };
    readonly promotionsBreakdown: ReadonlyArray<{
      readonly appSold: number;
      readonly eventPromotion: {
        readonly name: string;
        readonly promotionType: PromotionType;
      } | null;
      readonly faceValue: number;
      readonly fees: ReadonlyArray<{
        readonly computed: number;
        readonly rebate: number;
        readonly type: FeeType;
      } | null>;
      readonly payoutValue: number;
      readonly posSold: number;
      readonly price: number;
      readonly priceWithPwl: number | null;
      readonly priceWithoutPwl: number | null;
      readonly rebate: number;
      readonly sold: number;
      readonly value: number;
    } | null>;
    readonly reserved: number;
  } | null>;
  readonly ticketType: {
    readonly allocation: number;
    readonly id: string;
    readonly priceHidden: boolean;
    readonly priceTierType: PriceTierTypes | null;
    readonly salesLimit: number | null;
    readonly ticketPoolId: string | null;
  };
  readonly " $fragmentType": "PriceTiersBreakdownTable_ticketType";
};
export type PriceTiersBreakdownTable_ticketType$key = {
  readonly " $data"?: PriceTiersBreakdownTable_ticketType$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceTiersBreakdownTable_ticketType">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appSold",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "posSold",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rebate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceTiersBreakdownTable_ticketType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceTierType",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketPoolId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceHidden",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceTierBreakdown",
      "kind": "LinkedField",
      "name": "priceTiersBreakdown",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceTier",
          "kind": "LinkedField",
          "name": "priceTier",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "order",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "priceBreakdown",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalWithPwl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalWithoutPwl",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "digitalValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reserved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PromotionBreakdown",
          "kind": "LinkedField",
          "name": "promotionsBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EventPromotion",
              "kind": "LinkedField",
              "name": "eventPromotion",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "promotionType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceWithPwl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceWithoutPwl",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payoutValue",
              "storageKey": null
            },
            (v5/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesFees",
              "kind": "LinkedField",
              "name": "fees",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "computed",
                  "storageKey": null
                },
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketTypeBreakdown",
  "abstractKey": null
};
})();

(node as any).hash = "f06efa01d8901a05b1916f9b9fc5ce37";

export default node;
