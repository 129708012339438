import React from 'react'
import * as Sentry from '@sentry/react'
import { extraErrorDataIntegration, captureConsoleIntegration } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/browser'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import { SENTRY_DSN, COMMIT_HASH, APM_ENV } from './env'

function initSentry() {
  if (SENTRY_DSN && process.env.NODE_ENV !== 'test') {
    const opts: Sentry.BrowserOptions = {
      dsn: SENTRY_DSN,
      debug: false,
      environment: APM_ENV,
      release: COMMIT_HASH || 'latest',
      tracesSampleRate: APM_ENV === 'prod' ? 0.001 : 0,
      ignoreErrors: [
        'The user aborted a request',
        'Fetch is aborted',
        'Request signal is aborted',
        'The operation was aborted',
        'Empty token',
        'cancelled',
        'cancelado',
        'annullato',
        'The network connection was lost',
        'Unable to query data from server',
        'Not found',
        'Sentry Logger',
        'The above error occurred in the <ReactRelayQueryRenderer>',
        'NetworkError',
        'Failed to fetch',
        '401',
        'timed out',
        'timeout',
        'loginToAccount',
        'Load failed',
        'NotAllowedError: Write permission denied.',
        '_AutofillCallbackHandler',
        'ResizeObserver loop',
        'At least one Ticket Type should remain visible',
        'Link with this URL is already taken',
      ],
      maxValueLength: 3000,
      normalizeDepth: 5,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
        extraErrorDataIntegration({ depth: 5 }),
        captureConsoleIntegration({ levels: ['error'] }),
      ],
      beforeSend(event: any, _hint: any) {
        if (event.logger === 'console' && /RRNLRequestError/.test(event.message || '')) {
          return null
        }
        return event
      },
    }

    Sentry.init(opts)
  } else {
    console.warn('No Sentry DSN configured')
  }
}

export default initSentry
