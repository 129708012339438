import React, { FC, createContext, useMemo, PropsWithChildren } from 'react'
import { always } from 'lodash/fp'

import { MainLayoutQuery$data } from '../__generated__/MainLayoutQuery.graphql'

export type IFeatureFlag = keyof NonNullable<MainLayoutQuery$data['featureFlags']>

interface IProps {
  flags: MainLayoutQuery$data['featureFlags']
}
interface IContext {
  hasFeatureFlag: (flagName: IFeatureFlag) => boolean
}

export const featureFlagsContext = createContext<IContext>({ hasFeatureFlag: always(false) })

const FeatureFlagsProvider: FC<PropsWithChildren<IProps>> = ({ flags, children }) => {
  const ctxValue = useMemo(
    () => ({
      hasFeatureFlag: (flagName: IFeatureFlag) => !!flags?.[flagName],
    }),
    [flags]
  )

  return <featureFlagsContext.Provider value={ctxValue}>{children}</featureFlagsContext.Provider>
}

export default FeatureFlagsProvider
