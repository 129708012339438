import React, { FC, memo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Value from '../../../../../components/Value'

import { font } from '../../../../../utils/variables'
import { CURRENCY } from '../../../../../utils/formatters/number'

// eslint-disable-next-line max-len
import { RemittanceModalSalesBreakdown_event$key } from '../../../../../__generated__/RemittanceModalSalesBreakdown_event.graphql'

const SalesBreakdown = styled.div`
  margin-top: 32px;
  padding-top: 30px;
  border-top: 2px solid #000;
  h3 {
    margin-bottom: 16px;
  }
`
const SalesBreakdownRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const SalesBreakdownCol = styled.div`
  flex: 33.3333% 0 0;
`

const SalesBreakdownHint = styled.p`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 24px 0 16px;
  font-size: ${font.size.sm}px;
`

const RemittanceModalSalesBreakdown: FC<React.PropsWithChildren<{ event: RemittanceModalSalesBreakdown_event$key }>> = (
  props
) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment RemittanceModalSalesBreakdown_event on Event {
        id
        name
        costCurrency
        sales {
          totalFaceValue
          totalRebate
          totalPromoterIncome
        }
      }
    `,
    props.event
  )

  return event?.sales ? (
    <SalesBreakdown>
      <h3>{intl.formatMessage({ id: 'remittance.sales_breakdown' })}</h3>
      <SalesBreakdownRow>
        <SalesBreakdownCol>
          <Value
            label={intl.formatMessage({ id: 'remittance.col.face_value' })}
            value={intl.formatNumber(
              (event.sales.totalFaceValue || 0) / 100,
              CURRENCY(event.sales.totalFaceValue, event.costCurrency)
            )}
          />
        </SalesBreakdownCol>
        <SalesBreakdownCol>
          <Value
            label={intl.formatMessage({ id: 'remittance.col.rebate' })}
            value={intl.formatNumber(
              (event.sales.totalRebate || 0) / 100,
              CURRENCY(event.sales.totalRebate, event.costCurrency)
            )}
          />
        </SalesBreakdownCol>
        <SalesBreakdownCol>
          <Value
            label={intl.formatMessage({ id: 'remittance.col.total' })}
            value={intl.formatNumber(
              (event.sales.totalPromoterIncome || 0) / 100,
              CURRENCY(event.sales.totalPromoterIncome, event.costCurrency)
            )}
          />
        </SalesBreakdownCol>
        <SalesBreakdownHint>
          {intl.formatMessage({ id: 'remittance.sales_breakdown.hint' })}&nbsp;
          <a href={`mailto:finance@dice.fm?subject=${event.name}`} target="_blank" rel="noopener noreferrer">
            <strong>{intl.formatMessage({ id: 'contact_finance' })}</strong>
          </a>
        </SalesBreakdownHint>
      </SalesBreakdownRow>
    </SalesBreakdown>
  ) : null
}

export default memo(RemittanceModalSalesBreakdown)
