import { useFormikContext } from 'formik'
import { find, without, concat } from 'lodash/fp'
import arrayMove from 'array-move'
import React, { FC, useMemo, useCallback } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import Collapsible from '../../../components/Collapsible'
import Svg from '../../../components/Svg'
import { textStyle } from '../../../utils/typography'
import { color, mediaQuery } from '../../../utils/variables'
import IEventForm from '../types'
import { ITicketType } from '../types/Tickets'

const Collapse = styled(Collapsible)`
  & > div {
    margin-top: 0;
  }
`

const DragHandle = styled.div`
  width: 24px;
  height: 24px;
  cursor: grab;
  color: ${color.grey};

  &:hover {
    color: ${color.text};
  }

  svg {
    pointer-events: none;
  }
`

const ListItem = styled.div`
  gap: 8px;
  display: grid;
  padding: 8px 0;
  user-select: none;
  align-items: center;
  background-color: #fff;
  grid-template-columns: 24px 2fr 1fr;
`

const TtyName = styled.div`
  ${textStyle.functional.md}
`

const PoolName = styled.div`
  ${textStyle.functional.sm}

  ${mediaQuery.lessThan('tablet')`
    text-align: right;
  `}
`

const ListHead = styled(ListItem)`
  padding-top: 0;
  color: ${color.darkgrey};
  ${textStyle.functional.sm}
`

const ListContainer = styled.div`
  padding: 8px 0;
  border-top: 1px solid ${color.grey};
  border-bottom: 1px solid ${color.grey};
`

const SortableDragHandle = SortableHandle(() => (
  <DragHandle>
    <Svg icon="hamburger" />
  </DragHandle>
))

interface ITicketTypeProps {
  tty: ITicketType
}

const SortableListItem = SortableElement<ITicketTypeProps>(({ tty }: ITicketTypeProps) => {
  const { values } = useFormikContext<IEventForm>()

  const ticketPool = useMemo(
    () => find(['id', tty.ticketPoolId], values.ticketPools || []),
    [tty.ticketPoolId, values.ticketPools]
  )

  return (
    <ListItem className="draggable">
      <SortableDragHandle />
      <TtyName>{tty.name}</TtyName>
      <PoolName>{ticketPool?.name}</PoolName>
    </ListItem>
  )
})

interface IProps {
  ticketTypes: ITicketType[]
}

const SortableList = SortableContainer<IProps>(({ ticketTypes }: IProps) => (
  <div>
    {ticketTypes.map((tty, i) => (
      <SortableListItem index={i} key={tty.id} tty={tty} />
    ))}
  </div>
))

const EventTicketPoolsTicketTypesSortable: FC<IProps> = ({ ticketTypes }) => {
  const intl = useIntl()
  const { values, setFieldValue } = useFormikContext<IEventForm>()

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: any) => {
      const rest = without(ticketTypes, values.ticketTypes)
      setFieldValue('ticketTypes', concat(arrayMove(ticketTypes, oldIndex, newIndex), rest))
    },
    [setFieldValue, ticketTypes, values.ticketTypes]
  )

  return (
    <Collapse
      isNew
      dataId="eventTicketPoolsTicketTypesSortable"
      hint={intl.formatMessage({ id: 'new_event.tickets.ticket_pools.sortable.hint' })}
      label={intl.formatMessage({ id: 'new_event.tickets.ticket_pools.sortable.label' })}
    >
      <ListHead>
        <div />
        <div>{intl.formatMessage({ id: 'new_event.tickets.ticket_pools.sortable.head.ticket_name' })}</div>
        <PoolName>{intl.formatMessage({ id: 'new_event.tickets.ticket_pools.sortable.head.pool_name' })}</PoolName>
      </ListHead>
      <ListContainer>
        <SortableList
          lockAxis="y"
          useDragHandle
          lockOffset="20%"
          lockToContainerEdges
          ticketTypes={ticketTypes}
          onSortEnd={handleSortEnd}
        />
      </ListContainer>
    </Collapse>
  )
}

export default EventTicketPoolsTicketTypesSortable
