import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'
import { commitMutation, Environment } from 'react-relay'
import { map, filter, compose, pickBy, keys, indexOf, omit, set } from 'lodash/fp'

import IEventFormExtras, { IProduct } from '../types/Extras'

import { convertProduct } from './convertEvent'

import {
  createOrUpdateProductsCreateListMutation,
  createOrUpdateProductsCreateListMutation$data,
} from '../../../__generated__/createOrUpdateProductsCreateListMutation.graphql'

const CREATE_MUTATION = graphql`
  mutation createOrUpdateProductsCreateListMutation($input: CreateProductsListInput!) {
    createProductsList(input: $input) {
      successful
      messages {
        field
        message
        code
      }
      clientMutationId
      result {
        products {
          id
          name
        }
      }
    }
  }
`

const createOrUpdateProducts = async (environment: Environment, products: IEventFormExtras['products'] | undefined) => {
  if (!products || products.length === 0) return undefined

  const productsMap = new Map()
  const newProducts: Array<{ id: string; name: string } | null> = []

  const draftProducts = filter((p) => p && p.id.startsWith('new'), products)
  const draftProductsIds = compose([map(Number), keys, pickBy((p: IProduct) => p.id.startsWith('new'))])(products)

  const convertedDraftProducts = await Promise.all(map(
    async (product) =>
      omit(['id', 'ticketTypeIds', 'eventId'], await convertProduct(environment, product as IProduct)),
    draftProducts
  ))

  if (draftProducts.length > 0) {
    const input = {
      clientMutationId: nanoid(),
      products: convertedDraftProducts,
    }
    const { createProductsList: response } = await new Promise<createOrUpdateProductsCreateListMutation$data>(
      (resolve, reject) =>
        commitMutation<createOrUpdateProductsCreateListMutation>(environment, {
          mutation: CREATE_MUTATION,
          variables: {
            input,
          },
          onCompleted: resolve,
          onError: reject,
        })
    )

    if (response && (response.result?.products?.length || 0) > 0) {
      response?.result?.products?.forEach((product) => product && newProducts.push(product as IProduct))
    }
  }

  const resultProducts = products.map((product, idx) =>
    indexOf(idx, draftProductsIds) > -1
      ? set('id', newProducts[indexOf(idx, draftProductsIds)]?.id, product as IProduct)
      : product
  )
  products.map((product, idx) => productsMap.set(product?.id, resultProducts[idx]?.id))

  return { products: resultProducts, productsMap }
}

export default createOrUpdateProducts
