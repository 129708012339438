import React, { FC, lazy, useCallback, useLayoutEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import { Navigate, Outlet, Route, RouterProvider, Routes } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import { wrapCreateBrowserRouter, withProfiler } from '@sentry/react'

import ErrorBoundary from '../components/ErrorBoundary'

import AnonymousLayout from '../layout/AnonymousLayout'
import AppLayout from '../layout/AppLayout'
import MainLayout from '../layout/MainLayout'

import AccountConfirmation from '../flows/Signup/AccountConfirmation'

import SidebarLayout from '../layout/SidebarLayout'
import { persistLocation } from '../utils/api'

import AuthSection from './sections/AuthSection'
import ArtistsSection from './sections/ArtistsSection'
import BundlesSection from './sections/BundlesSection'
import LinkoutsSection from './sections/LinkoutsSection'
import MarketeersSection from './sections/MarketeersSection'
import PromotersSection from './sections/PromotersSection'
import ReportingSection from './sections/ReportingSection'
import VenuesSection from './sections/VenuesSection'
import ToolsSection from './sections/ToolsSection'
import EventsSection from './sections/EventsSection'
import FinancesSection from './sections/FinancesSection'
import FansSection from './sections/FansSection'
import GenericSection from './sections/GenericSection'
import MailchimpConfirmation from '../flows/Integrations/components/Mailchimp/MailchimpConfirmation'
import StripeOnboardingPage from '../flows/StripeOnboarding/StripeOnboardingPage'
import StripeRefreshPage from '../flows/StripeRefresh/StripeRefreshPage'
import PromoterSettingsLayout from '../layout/PromoterSettingsLayout'
import PromoterSettingsSection from './sections/PromoterSettingsSection'
import PartnerGate from './gates/PartnerGate'
import RedirectGate from './gates/RedirectGate'

const MyGraphiQL =
  process.env.NODE_ENV === 'development'
    ? lazy(() => import(/* webpackChunkName: "graphiql" */ '../graphiql/Page'))
    : () => <h1>Dev mode only!</h1>

const RequireAuth: FC = () => {
  const token = window.localStorage.getItem('token')

  useLayoutEffect(() => {
    if (token) return

    persistLocation()
  }, [token])

  return token ? (
    <ErrorBoundary noAuth>
      <Outlet />
    </ErrorBoundary>
  ) : (
    <Navigate to="/auth/login" replace />
  )
}

const Root: FC = () => {
  const [envKey, setEnvKey] = useState('initial')
  const onUserChange = useCallback(() => setEnvKey(nanoid()), [])

  return (
    <Routes>
      <Route path="/" element={<AppLayout key={envKey} />}>
        <Route path="accounts" element={<AnonymousLayout />}>
          <Route path="confirm" element={<AccountConfirmation />} />
        </Route>

        <Route path="auth/*" element={<AuthSection />} />

        <Route index element={<Navigate replace to="/dashboard" />} />

        <Route path="*" element={<RequireAuth />}>
          <Route path="graphiql" element={<MyGraphiQL />} />

          <Route path="*" element={<MainLayout onUserChange={onUserChange} />}>
            <Route path="oauth/mailchimp_connect" element={<MailchimpConfirmation />} />
            <Route path="oauth/stripe_onboarding" element={<StripeOnboardingPage />} />
            <Route path="account/stripe/refresh" element={<StripeRefreshPage />} />

            <Route path="settings/*" element={<PartnerGate requiresV2 />}>
              <Route path="*" element={<PromoterSettingsLayout />}>
                <Route path="*" element={<PromoterSettingsSection />} />
              </Route>
            </Route>

            <Route path="*" element={<SidebarLayout />}>
              <Route path="*" element={<GenericSection />} />

              <Route path="artists/*" element={<ArtistsSection />} />
              <Route path="event-collections/*" element={<BundlesSection />} />
              <Route path="events/*" element={<EventsSection />} />
              <Route path="fans/*" element={<FansSection />} />
              <Route path="finances/*" element={<FinancesSection />} />
              <Route path="website-widget/*" element={<LinkoutsSection />} />
              <Route path="marketeers/*" element={<MarketeersSection />} />
              <Route path="promoters/*" element={<PromotersSection />} />
              <Route path="reporting/*" element={<ReportingSection />} />
              <Route path="tools/*" element={<ToolsSection />} />
              <Route
                path="venues/*"
                element={
                  <>
                    <PartnerGate requiresV2>
                      <RedirectGate find="/venues" replace="/settings/venues" />
                    </PartnerGate>
                    <VenuesSection />
                  </>
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter([{ path: '*', element: <Root /> }])

export default withProfiler(
  // eslint-disable-next-line func-names
  function Router() {
    return <RouterProvider router={router} />
  },
  { name: 'MIO', includeUpdates: false }
)
