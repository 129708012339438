/**
 * @generated SignedSource<<7c9c49a2e37b85ac062b2616036cfbca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type SyncEventInput = {
  clientMutationId: string;
  id: string;
};
export type EventWorkflowSyncMutation$variables = {
  input: SyncEventInput;
};
export type EventWorkflowSyncMutation$data = {
  readonly syncEvent: {
    readonly event: {
      readonly eventIdLive: string | null;
      readonly state: EventState | null;
      readonly statusAsOfNow: string | null;
    } | null;
  } | null;
};
export type EventWorkflowSyncMutation = {
  response: EventWorkflowSyncMutation$data;
  variables: EventWorkflowSyncMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusAsOfNow",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventIdLive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventWorkflowSyncMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncEventPayload",
        "kind": "LinkedField",
        "name": "syncEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventWorkflowSyncMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncEventPayload",
        "kind": "LinkedField",
        "name": "syncEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab4db33a1027da69683337a848dcbe06",
    "id": null,
    "metadata": {},
    "name": "EventWorkflowSyncMutation",
    "operationKind": "mutation",
    "text": "mutation EventWorkflowSyncMutation(\n  $input: SyncEventInput!\n) {\n  syncEvent(input: $input) {\n    event {\n      state\n      statusAsOfNow\n      eventIdLive\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "88d9392c502319dcdcec190de0e061db";

export default node;
