import React, { FC, useState, useCallback, useEffect, Ref, FormEvent, useRef } from 'react'
import Autosuggest from 'react-autosuggest'
import { createGlobalStyle } from 'styled-components/macro'
import { always, escapeRegExp, identity, isEqual } from 'lodash/fp'

import { color } from '../utils/variables'

const getSuggestions = (suggestions: Array<string>, value: string) => {
  const escapedValue = escapeRegExp(value.trim())
  const regex = new RegExp('^' + escapedValue, 'i')
  return suggestions.filter((suggestion) => regex.test(suggestion))
}

const shouldRenderSuggestions = always(true)

const renderSuggestion = (suggestion: string) => <span>{suggestion}</span>

export interface ISuggestionsProps {
  suggestions: Array<string>
  fetchSuggestions?: (str: string) => void
  handleSuggestionSelect?: (e: any, { suggestionIndex }: { suggestionIndex: number }) => void
  value?: string
  name: string
  inputRef?: Ref<HTMLInputElement>
  setFieldValue: (name: string, value: string) => void
}

const SuggestionsInput: FC<React.PropsWithChildren<ISuggestionsProps>> = ({
  suggestions,
  fetchSuggestions,
  name,
  value,
  setFieldValue,
  inputRef,
  handleSuggestionSelect,
  ...restProps
}) => {
  const [collection, setCollection] = useState(suggestions)
  const [inputValue, setInputValue] = useState(value || '')

  const prevSuggestions = useRef(suggestions)
  useEffect(() => {
    if (isEqual(prevSuggestions.current, suggestions)) {
      return
    }

    prevSuggestions.current = suggestions
    setCollection(suggestions)
  }, [suggestions])

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value || '')
    }
  }, [value, inputValue])

  const onSuggestionsFetchRequested = useCallback(
    ({ value }: { value: string }) => {
      if (fetchSuggestions) {
        fetchSuggestions(value)
      }

      setCollection(getSuggestions(suggestions, value))
    },
    [fetchSuggestions, suggestions]
  )

  const onSuggestionsClearRequested = useCallback(() => setCollection([]), [])

  const onChangeValue = useCallback(
    (event: FormEvent, { newValue }: { newValue: string }) => {
      setInputValue(newValue)
      if (setFieldValue && name) {
        setFieldValue(name, newValue)
      }
    },
    [setInputValue, setFieldValue, name]
  )

  const onChange = useCallback((e: FormEvent, value: any) => onChangeValue(e, value), [onChangeValue])

  const inputProps = {
    value: inputValue,
    name: name,
    ref: inputRef,
    ...restProps,
    onChange,
  }

  return (
    <Autosuggest
      suggestions={collection}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={identity}
      shouldRenderSuggestions={shouldRenderSuggestions}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={handleSuggestionSelect}
      inputProps={inputProps}
    />
  )
}

export default SuggestionsInput

export const SuggestionsStyles = createGlobalStyle`
  .react-autosuggest__container {
    width: 100%;
  }
  .react-autosuggest__suggestions-container {
    position: absolute;
    width: 100%;
    top: 100%;
    margin-top: 8px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .react-autosuggest__suggestion {
    display: flex;
    align-items: flex-start;
    padding: 10px 16px;
    margin: 6px 0;
    cursor: pointer;
    &:hover, &--highlighted {
      background: ${color.palegrey};
      a:hover {
        color: inherit;
      }
    }
  }
`
