/* eslint-disable max-len, max-lines */
import React, { FC, Suspense, memo, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { compact, get, getOr, groupBy, isArray, sumBy, compose, filter, reject as _reject, some } from 'lodash/fp'
import graphql from 'babel-plugin-relay/macro'
import { useFormikContext } from 'formik'
import { useMutation } from 'react-relay'
import { nanoid } from 'nanoid'
import { isNew } from '../../../utils/entityStatus'
import Button from '../../../components/Button'
import Danger from '../../../components/Danger'
import { FormRow } from '../../../components/Form'
import FormGroup from '../../../components/FormGroup'
import Warning from '../../../components/Warning'
import checkOverallocation from '../../../utils/checkOverallocation'
import IEventFormTickets, { ITicketType } from '../types/Tickets'
import IEventFormSettings from '../types/Settings'
import EventTicketSummary from './EventTicketSummary'
import EventTicketTypes from './EventTicketTypes'
import { color, font, mediaQuery } from '../../../utils/variables'
// eslint-disable-next-line max-len
import { EventTicketTypesConfigSeatmapMutation } from '../../../__generated__/EventTicketTypesConfigSeatmapMutation.graphql'
import { notificationContext } from '../../../context/notification'
import useSeatedTicketTypes from '../hooks/useSeatedTicketTypes'
import FormField, { FlexFormField } from '../../../components/FormField'
import useSeatingState from '../hooks/useSeatingState'
import { TitleTooltip } from '../../../components/Tooltip'
import Toggle from '../../../components/Toggle'
import useMaxSeatsCapacity from '../hooks/useMaxSeatsCapacity'
import { authContext } from '../../../context/auth'
import { allowedEventAction } from '../services/allowedEventAction'
import EventTicketPools from './EventTicketPools'
import { featureFlagsContext } from '../../../context/featureFlags'
import { localeContext } from '../../../context/locale'
import AlertBox, { AlertBoxContent } from '../../../components/AlertBox'
import EventTicketPoolsTicketTypesSortable from './EventTicketPoolsTicketTypesSortable'
import useTicketsFields from '../hooks/useTicketsFields'
import OtherCurrenciesHint from '../../Promoter/components/OtherCurrenciesHint'
import RadioGroup from '../../../components/RadioGroup'
import { textStyle } from '../../../utils/typography'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { CURRENCY } from '../../../utils/formatters/number'
import { isItalianEvent } from '../../../utils/isCountryEvent'

// import ChannelsModal from './ChannelsModal'
// import ChannelMappingModal from '../../../components/Event/ChannelMappingModal'

const SeatedFlow = styled.ul`
  counter-reset: seated-step-number;
`

const SeatedFlowStep = styled.li<{ isActive?: boolean }>`
  counter-increment: seated-step-number;

  position: relative;

  display: flex;
  align-items: center;

  border: 2px solid ${({ isActive }) => (isActive ? color.primary : color.lightgrey)};
  border-radius: 4px;

  padding: 30px 32px;
  min-height: 110px;

  & + & {
    margin-top: 32px;
  }

  &:before {
    content: counter(seated-step-number);

    width: 48px;
    height: 48px;
    max-width: 48px;

    border: 2px solid ${({ isActive }) => (isActive ? color.primary : color.text)};
    border-radius: 50%;

    color: ${({ isActive }) => (isActive ? color.primary : color.text)};

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: ${font.size.md}px;
    line-height: 120%;
    text-align: center;

    margin-right: 32px;
  }

  &:after {
    content: ' ';
    display: block;

    position: absolute;
    height: 32px;
    width: 2px;

    bottom: -34px;
    left: 61px;

    border-left: 2px dashed ${({ isActive }) => (isActive ? color.primary : color.text)};
  }

  &:last-child:after {
    display: none;
  }

  ${mediaQuery.lessThan('tablet')`
      padding: 24px 16px;
      flex-direction: column;

      & + & {
        margin-top: 24px;
      }

      &:before {
        margin-right: 0;
        margin-bottom: 16px;
      }

      &:after {
        position: absolute;
        height: 24px;
        bottom: -26px;
        left: calc(50% - 1px);
      }
  `}
`

const SeatedFlowDescription = styled.div`
  flex: 1;

  ${textStyle.functional.md}

  color: ${color.greyer};

  & > strong {
    display: block;
    margin-bottom: 8px;
    color: ${color.text};

    ${textStyle.interactive.lg}
  }

  ${mediaQuery.lessThan('tablet')`
    text-align: center;
    font-size: ${font.size.sm}px;
    letter-spacing: 0.01em;

    & > strong {
      font-size: ${font.size.base}px;
      line-height: 125%;
      letter-spacing: normal;
    }
  `}
`

const SeatedFlowActions = styled.div`
  width: 104px;
  max-width: 104px;
  margin-left: 32px;

  ${mediaQuery.lessThan('tablet')`
    margin-left: 0;
    margin-top: 16px;
  `}
`

const RightDanger = styled(Danger)`
  float: right;
`

const BoxedFormGroup = styled(FormGroup)`
  border: 2px solid rgba(229, 229, 229, 1);
  border-radius: 4px;
  padding: 16px;
`

const BoxedInnerFormGroup = styled(FormGroup)`
  background: rgba(242, 242, 242, 1);
  padding: 16px;
  border-radius: 4px;
  margin-top: 16px;
`

const Switch = styled(Toggle)`
  position: absolute;
  right: 0;
  top: 8px;

  ${mediaQuery.lessThan('tablet')`
    top: 4px;
  `}
`

const BoxedSwitch = styled(Toggle)`
  position: absolute;
  right: 0;
  top: 0;

  ${mediaQuery.lessThan('tablet')`
  top: -8px;
  right: -4px;
  `}
`

const TooltipTarget = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
  width: 64px;
  height: 32px;

  ${mediaQuery.lessThan('tablet')`
    top: 4px;
  `}

  ${Switch} {
    pointer-events: all;
    position: relative;
    top: 0;
  }
`

const SeatSelectionConfigBox = styled.div`
  border-top: 1px solid rgba(191, 191, 191, 1);
  padding-top: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const RadioGroupTitle = styled.div`
  font-weight: 700;
  margin-bottom: 6px;
`

const RadioGroupLabel = styled.div`
  color: ${color.darkgrey};

  ${textStyle.functional.sm}
`

const HoursSelectRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  > div:first-child {
    min-width: 176px;
  }

  > span {
    font-size: ${font.size.sm}px;
    line-height: 18px;
  }

  ${mediaQuery.lessThan('tablet')`
  flex-direction: column;
  align-items: flex-start;
  `}
`

const Alert = styled(AlertBox)`
  margin-top: 16px;

  ${AlertBoxContent} {
    display: inline-flex;
    align-items: center;
  }
`

interface IProps {
  readOnly: boolean
  viewer: {
    account: null | {
      taxFormProvided: null | boolean
      financialDataProvided: null | boolean
    }
  }
}

const EventTicketTypesConfig: FC<React.PropsWithChildren<IProps>> = ({ viewer, readOnly }) => {
  const intl = useIntl()
  const { user, account } = useContext(authContext)
  const { hasFeatureFlag } = useContext(featureFlagsContext)
  const { addNotification } = useContext(notificationContext)
  const { locale } = useContext(localeContext)

  const fanPickSeatHoursOptions = useMemo(
    () => [
      { label: intl.formatMessage({ id: 'new_event.tickets.seating.fan_pick_seat.delay.zero_hours' }), value: 0 },
      { label: intl.formatMessage({ id: 'new_event.tickets.seating.fan_pick_seat.delay.1_hour' }), value: 1 },
      { label: intl.formatMessage({ id: 'new_event.tickets.seating.fan_pick_seat.delay.6_hours' }), value: 6 },
      { label: intl.formatMessage({ id: 'new_event.tickets.seating.fan_pick_seat.delay.12_hours' }), value: 12 },
      { label: intl.formatMessage({ id: 'new_event.tickets.seating.fan_pick_seat.delay.24_hours' }), value: 24 },
    ],
    [intl]
  )

  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext<IEventFormTickets>()
  const { values: settingsValues } = useFormikContext<IEventFormSettings>()
  const isUnicornActive = get('flags.unicorn.active', settingsValues)

  const freeEventOptions = useMemo(
    () => ({
      amount: intl.formatNumber(0, {
        ...CURRENCY(0, values.costCurrency),
        minimumFractionDigits: 2,
      }),
    }),
    [intl, values.costCurrency]
  )

  const isItalian = useMemo(() => isItalianEvent(values, locale), [locale, values])

  const {
    hasVenueSeatmaps,
    onChangeSeated,
    seatmapLoader,
    seatingWarn,
    confirmSeatingWarn,
    cancelSeatingWarn,
    onChangeVenueChart,
  } = useSeatingState()

  const notUS = values.costCurrency !== 'USD'
  const taxFormOk = notUS || !!viewer?.account?.taxFormProvided
  const paidEventAllowed = !viewer?.account || (!!viewer?.account?.financialDataProvided && taxFormOk)

  const onChangeFanPickSeat = useCallback(
    (val: boolean | string) => {
      setFieldValue('flags.fanPickSeat.active', val)
    },
    [setFieldValue]
  )

  const setFanPickSeatHours = useCallback(
    (val: number) => {
      setFieldValue('flags.fanPickSeat.hours', val)
    },
    [setFieldValue]
  )

  const toggleIsFree = useCallback(() => {
    setFieldValue('freeEvent', !values.freeEvent)
  }, [setFieldValue, values.freeEvent])

  const fanPickSeatHoursSelectedOption = useMemo(() => {
    return fanPickSeatHoursOptions.find((opt) => opt.value === getOr(0, 'flags.fanPickSeat.hours', values))
  }, [fanPickSeatHoursOptions, values])

  const nonArchivedTtys = useMemo(
    () => (values.ticketTypes ? _reject('archived', values.ticketTypes) : []),
    [values.ticketTypes]
  )

  const isPaid = useMemo(
    () =>
      (sumBy((tt: ITicketType | null) => {
        if (!tt) return 0
        if (!tt.priceTierType) return tt.faceValue || 0
        return sumBy((pt) => pt?.faceValue || 0, tt.priceTiers || [])
      }, nonArchivedTtys || []) || 0) > 0,
    [nonArchivedTtys]
  )

  const showPaidEventWarning = isPaid && !paidEventAllowed

  const isSeated = getOr(false, 'flags.seated.active', values)

  // TODO: remove this when backend is ready
  const needSeatsConfiguration = isSeated && !values.eventSeatingChart?.id

  // If non-free tickets have already been synced to NTS100, the event cannot
  //  be marked as a free event (NTS sync happens when event is approved).
  const freeEventDisabled = useMemo(
    () =>
      (sumBy((tt: ITicketType | null) => {
        if (!tt) return 0
        if (!tt.priceTierType) return tt.faceValue || 0
        return sumBy((pt) => pt?.faceValue || 0, tt.priceTiers || [])
      }, (values.state === 'APPROVED' ? values.ticketTypes : nonArchivedTtys) || []) || 0) > 0,
    [nonArchivedTtys, values.state, values.ticketTypes]
  )

  // TODO: uncomment channels when backend is ready
  // const needSeatsConfiguration =
  //   isSeated && (!values.eventSeatingChart?.id || (values.eventSeatingChart?.seatingChannels || []).length === 0)

  const [commitCreateSeatmap] = useMutation<EventTicketTypesConfigSeatmapMutation>(graphql`
    mutation EventTicketTypesConfigSeatmapMutation($input: CreateEventSeatingChartInput!) {
      createEventSeatingChart(input: $input) {
        messages {
          code
          field
          message
        }
        result {
          id
          seatsIoEventId
          seatsIoEventReport(group: BY_CATEGORY_KEY)
          chartManagerCredentials {
            secretKey
          }
          seatingChannels {
            seatsIoChannel
            channelType
            name
          }
        }
        successful
      }
    }
  `)

  const [generateSeatedTicketTypes, generating] = useSeatedTicketTypes()

  // TODO: uncomment channels when backend is ready
  // const [channelsMappingModal, setChannelsMappingModal] = useState(false)
  // const closeChannelMappingsModal = useCallback(() => {
  //   setChannelsMappingModal(false)
  // }, [])

  // const onSaveMapping = useCallback(
  //   (mapping) => {
  //     setFieldValue('eventSeatingChart.seatingChannels', mapping)
  //     closeChannelMappingsModal()
  //     if (values.ticketTypes?.length === 0) {
  //       setTimeout(() => generateSeatedTicketTypes(), 0)
  //     }
  //   },
  //   [closeChannelMappingsModal, generateSeatedTicketTypes, setFieldValue, values.ticketTypes?.length]
  // )
  //
  // const [channelsModal, setChannelsModal] = useState(false)
  // const closeChannelsModal = useCallback(() => {
  //   setChannelsModal(false)
  //   setChannelsMappingModal(true)
  // }, [])
  //
  // const editEventSeatmap = useCallback(() => {
  //   setChannelsModal(true)
  // }, [])
  //
  // const chartManagerCredentials = useMemo(
  //   () =>
  //     values.eventSeatingChart?.chartManagerCredentials?.secretKey && values.eventSeatingChart?.seatsIoEventId
  //       ? {
  //           secretKey: values.eventSeatingChart?.chartManagerCredentials?.secretKey,
  //           eventId: values.eventSeatingChart?.seatsIoEventId,
  //         }
  //       : null,
  //   [values.eventSeatingChart?.chartManagerCredentials?.secretKey, values.eventSeatingChart?.seatsIoEventId]
  // )

  const [loading, setLoading] = useState(false)

  const createEventSeatmap = useCallback(() => {
    const venueChartId = values.eventSeatingChart?.venueChart.value
    if (!venueChartId) return

    setLoading(true)
    new Promise<string>((resolve, reject) =>
      commitCreateSeatmap({
        variables: {
          input: {
            clientMutationId: nanoid(),
            venueChartId,
          },
        },
        onCompleted(data, errors) {
          const payload = data.createEventSeatingChart
          if (errors || (payload?.messages && payload.messages.length > 0) || !payload?.successful || !payload.result) {
            reject(errors || payload?.messages || [])
            return
          }

          setFieldValue(
            'eventSeatingChart.chartManagerCredentials.secretKey',
            payload.result.chartManagerCredentials.secretKey
          )
          setFieldValue('eventSeatingChart.seatsIoEventId', payload.result.seatsIoEventId)
          setFieldValue('eventSeatingChart.seatsIoEventReport', payload.result.seatsIoEventReport)

          resolve(payload.result.id)
        },
        onError: reject,
      })
    )
      .catch((err) => {
        console.error('Error creating event seatmap', err)
        if (isArray(err) && err.length > 0) {
          err.forEach((e) => addNotification('error', e.message))
        } else {
          addNotification('error', intl.formatMessage({ id: 'new_event.tickets.seated_flow.create_seatmap_error' }))
        }
      })
      .then((id) => {
        setFieldValue('eventSeatingChart.id', id)

        // TODO: uncomment channels when backend is ready
        // setChannelsModal(true)

        // TODO: remove this when channels backend is ready
        if (id) {
          generateSeatedTicketTypes(id)
        }
      })
      .finally(() => setLoading(false))
  }, [
    addNotification,
    commitCreateSeatmap,
    generateSeatedTicketTypes,
    intl,
    setFieldValue,
    values.eventSeatingChart?.venueChart.value,
  ])

  const maxSeatsCapacity = useMaxSeatsCapacity(values.eventSeatingChart?.id || null)

  const { overallocation, venueCapacity } = useMemo(
    () => checkOverallocation(values, locale, maxSeatsCapacity),
    [locale, maxSeatsCapacity, values]
  )

  const totalPoolsCapacity = compose([
    sumBy('maxAllocation'),
    filter((item: { maxAllocation: number | null }) => (item?.maxAllocation || 0) > 0),
  ])(values.ticketPools)

  const groupedTtys = useMemo(
    () =>
      groupBy(
        (tty) => (tty.archived ? 'archived' : tty.ticketPoolId || 'standalone'),
        compact(values.ticketTypes || [])
      ),
    [values.ticketTypes]
  )

  const poolTtys: ITicketType[] = useMemo(
    () => compose([filter('ticketPoolId'), _reject('archived'), compact])(values.ticketTypes || []),
    [values.ticketTypes]
  )

  const { costCurrencyOptions, costCurrencyOption, setCostCurrency } = useTicketsFields()

  const shouldShowOtherCurrenciesHint = useMemo(
    // Promoters can't query info about other promoters
    () => user.diceStaff || account?.id === values.billingPromoter?.accountId,
    [account?.id, user.diceStaff, values.billingPromoter?.accountId]
  )

  const formik = useFormikContext<IEventFormTickets>()

  const { handleChange } = formik

  const { hasPermission } = useContext(authContext)

  const disabledMessage = useMemo(() => {
    if (isUnicornActive) {
      return intl.formatMessage({ id: 'new_event.tickets.flags.seated.not_compatible_with_cart' })
    }
    if (!hasVenueSeatmaps) {
      return intl.formatMessage({ id: 'new_event.tickets.flags.seated.tooltip' })
    }
    return null
  }, [hasVenueSeatmaps, intl, isUnicornActive])

  return (
    <>
      {(values.eventType === 'LIVE' || values.eventType === 'HYBRID') && (
        <>
          <FormRow columnOnMobile>
            <BoxedFormGroup>
              <FormGroup
                label={intl.formatMessage({ id: 'new_event.tickets.flags.seated.label' })}
                hint={intl.formatMessage({ id: 'new_event.tickets.flags.seated.hint' })}
                error={get('flags.seated.active', touched) && get('flags.seated.active', errors)}
              >
                <TitleTooltip title={disabledMessage}>
                  <TooltipTarget>
                    <BoxedSwitch
                      name="flags.seated.active"
                      checked={getOr(false, 'flags.seated.active', values)}
                      onChange={onChangeSeated}
                      onBlur={handleBlur}
                      disabled={
                        readOnly ||
                        isUnicornActive ||
                        !hasVenueSeatmaps ||
                        (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
                      }
                    />
                  </TooltipTarget>
                </TitleTooltip>
              </FormGroup>
              {getOr(false, 'flags.seated.active', values) && (
                <BoxedInnerFormGroup>
                  <FormGroup>
                    <FormRow columnOnMobile>
                      <FlexFormField
                        name="seatmap"
                        label={intl.formatMessage({ id: 'new_event.tickets.seatmap.label' })}
                        control="select"
                        async
                        searchable
                        defaultOptions
                        loadOptions={seatmapLoader}
                        required
                        allowCreate
                        createOptionLabel={intl.formatMessage({ id: 'new_event.tickets.seatmap.not_found' })}
                        disabled={readOnly || !!values.eventSeatingChart?.id}
                        hint={
                          maxSeatsCapacity > 0
                            ? intl.formatMessage(
                              { id: 'new_event.tickets.seated_max_capacity' },
                              { number: maxSeatsCapacity }
                            )
                            : undefined
                        }
                        value={values.eventSeatingChart?.venueChart}
                        onChange={onChangeVenueChart}
                      >
                        {/* TODO: uncomment this when channels backend is ready
                {!needSeatsConfiguration && (
                  <IconButton
                    icon="edit"
                    onClick={editEventSeatmap}
                    title={intl.formatMessage({ id: 'new_event.tickets.seatmap.edit_channels' })}
                  />
                )} */}
                      </FlexFormField>
                    </FormRow>
                    {values.eventSeatingChart?.venueChart && hasPermission('toggle_fan_seat_selection:event') ? (
                      <SeatSelectionConfigBox>
                        <FormGroup label="Seat selection">
                          <RadioGroup
                            layout="boxed"
                            onChange={onChangeFanPickSeat}
                            name="flags.fanPickSeat.active"
                            value={getOr(true, 'flags.fanPickSeat.active', values)}
                            options={[
                              {
                                value: true,
                                label: (
                                  <>
                                    <div>
                                      <RadioGroupTitle>
                                        {intl.formatMessage({ id: 'new_event.tickets.seating.fan_pick_seat.label' })}
                                      </RadioGroupTitle>
                                      <RadioGroupLabel>
                                        {intl.formatMessage({ id: 'new_event.tickets.seating.fan_pick_seat.hint' })}
                                      </RadioGroupLabel>
                                    </div>
                                  </>
                                ),
                                selectedSuffix: (
                                  <HoursSelectRow>
                                    <FormField
                                      name="flags.fanPickSeat.hours"
                                      control="select"
                                      onBlur={handleBlur}
                                      required
                                      options={fanPickSeatHoursOptions}
                                      value={fanPickSeatHoursSelectedOption}
                                      onChange={setFanPickSeatHours}
                                    />
                                    <span>
                                      {intl.formatMessage({
                                        id: 'new_event.tickets.seating.fan_pick_seat.delay.suffix',
                                      })}
                                    </span>
                                  </HoursSelectRow>
                                ),
                              },
                              {
                                value: false,
                                label: (
                                  <>
                                    <div>
                                      <RadioGroupTitle>
                                        {intl.formatMessage({
                                          id: 'new_event.tickets.seating.assign_best_available.label',
                                        })}
                                      </RadioGroupTitle>
                                      <RadioGroupLabel>
                                        {intl.formatMessage({
                                          id: 'new_event.tickets.seating.assign_best_available.hint',
                                        })}
                                      </RadioGroupLabel>
                                    </div>
                                  </>
                                ),
                              },
                            ]}
                          />
                        </FormGroup>
                        {/* <FormGroup label="Post-purchase seat changes">
                            <FormRow>
                              <FlexFormField
                                name="allowSeatChanges"
                                control="checkbox"
                                label="Let fans change their seats after they’ve bought tickets."
                              />
                            </FormRow>
                          </FormGroup> */}
                      </SeatSelectionConfigBox>
                    ) : null}
                  </FormGroup>
                </BoxedInnerFormGroup>
              )}
            </BoxedFormGroup>
          </FormRow>
        </>
      )}

      <FormRow columnOnMobile>
        <FormField
          name="costCurrency"
          label={intl.formatMessage({ id: 'new_event.tickets.cost_currency.label' })}
          control="select"
          onBlur={handleBlur}
          disabled={
            readOnly || (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'forbidden'))
          }
          error={touched.costCurrency && errors.costCurrency}
          required
          options={costCurrencyOptions}
          value={costCurrencyOption}
          onChange={setCostCurrency}
          hint={
            shouldShowOtherCurrenciesHint &&
            values.billingPromoter?.value && (
              <Suspense fallback={null}>
                <OtherCurrenciesHint promoterId={values.billingPromoter?.value} refCurrency={values.costCurrency} />
              </Suspense>
            )
          }
        />
        <FormField
          name="maxTicketsLimit"
          label={intl.formatMessage({ id: 'new_event.tickets.limit_per_person.label' })}
          type="number"
          min={1}
          step={1}
          value={values.maxTicketsLimit}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            readOnly ||
            (values.state !== 'DRAFT' && !allowedEventAction(values.allowedLifecycleUpdates, 'maxTicketsLimit'))
          }
          error={touched.maxTicketsLimit && errors.maxTicketsLimit}
          required
        />
      </FormRow>

      {isItalian && (
        <FormRow>
          <div>
            <FormGroup
              label={intl.formatMessage({ id: 'new_event.tickets.free_event.label' })}
              hint={intl.formatMessage({ id: 'new_event.tickets.free_event.hint' }, freeEventOptions)}
            >
              <BoxedSwitch
                title={
                  isPaid ? intl.formatMessage({ id: 'new_event.tickets.free_event.help' }, freeEventOptions) : null
                }
                name="freeEvent"
                checked={!!values.freeEvent}
                onChange={toggleIsFree}
                onBlur={handleBlur}
                disabled={
                  readOnly ||
                  freeEventDisabled ||
                  (user.diceStaff ? values.state === 'APPROVED' : values.state !== 'DRAFT')
                }
              />
            </FormGroup>
            {values.freeEvent && (
              <Alert fullWidth icon="info-msg">
                <span>
                  {intl.formatMessage(
                    { id: 'new_event.tickets.free_event.fiscal_reporting.alert' },
                    {
                      a: (str: string) => (
                        <a
                          className="color-primary"
                          href={`mailto:clientsuccess@dice.fm?subject=[${account?.name || 'DICE'}] C1 document`}
                        >
                          {' '}
                          {str}
                        </a>
                      ),
                    }
                  )}
                </span>
              </Alert>
            )}
            {!values.freeEvent && !freeEventDisabled && _reject(isNew, values.ticketTypes).length > 0 && (
              <Alert fullWidth color={color.warning}>
                {intl.formatMessage({ id: 'new_event.tickets.free_event.alert' }, freeEventOptions)}
              </Alert>
            )}
          </div>
        </FormRow>
      )}

      {needSeatsConfiguration || generating ? (
        <FormRow columnOnMobile>
          <SeatedFlow>
            <SeatedFlowStep isActive>
              <SeatedFlowDescription>
                <strong>{intl.formatMessage({ id: 'new_event.tickets.seated_flow.step_one.title' })}</strong>
                <div>{intl.formatMessage({ id: 'new_event.tickets.seated_flow.step_one.description' })}</div>
              </SeatedFlowDescription>
              <SeatedFlowActions>
                <Button
                  block
                  color="primary"
                  loading={loading || generating}
                  // TODO: uncomment this when channels backend is ready
                  // onClick={values.eventSeatingChart?.id ? editEventSeatmap : createEventSeatmap}
                  // disabled={readOnly || !values.eventSeatingChart?.venueChart}
                  onClick={createEventSeatmap}
                  disabled={readOnly || !values.eventSeatingChart?.venueChart || !!values.eventSeatingChart?.id}
                >
                  {intl.formatMessage({ id: values.eventSeatingChart?.id ? 'actions.edit' : 'actions.add' })}
                </Button>
              </SeatedFlowActions>
            </SeatedFlowStep>
            <SeatedFlowStep>
              <SeatedFlowDescription>
                <strong>{intl.formatMessage({ id: 'new_event.tickets.seated_flow.step_two.title' })}</strong>
                <div>{intl.formatMessage({ id: 'new_event.tickets.seated_flow.step_two.description' })}</div>
              </SeatedFlowDescription>
              <SeatedFlowActions />
            </SeatedFlowStep>
          </SeatedFlow>
        </FormRow>
      ) : (
        <>
          {showPaidEventWarning && (
            <FormRow columnOnMobile>
              <Warning>
                {intl.formatMessage(
                  { id: 'new_event.tickets.missing_financial_info_warning' },
                  {
                    a: (str: string) => (
                      <Button preset="link" to="/account">
                        {str}
                      </Button>
                    ),
                  }
                )}
              </Warning>
            </FormRow>
          )}

          <FormRow columnOnMobile>
            <FormGroup
              data-name="innerTicketTypesErrors"
              label={intl.formatMessage({
                id:
                  (groupedTtys['standalone']?.length || 0) > 0
                    ? 'new_event.tickets.ticket_types.label'
                    : (values.ticketPools?.length || 0) > 0
                      ? 'new_event.tickets.ticket_pools.label'
                      : 'new_event.tickets.ticket_creation.label',
              })}
              hint={
                (values.ticketPools?.length || 0) > 0
                  ? intl.formatMessage({ id: 'new_event.tickets.ticket_pools.hint' })
                  : null
              }
              required
            />
          </FormRow>
          <FormRow columnOnMobile className="mt-md">
            {(values.ticketPools?.length || 0) > 0 ? (
              <FormRow>
                <div>
                  {!readOnly && !hasFeatureFlag('ticketPools') && (
                    <AlertBox icon="info-msg" className="w-100 mb-sm">
                      <span>{intl.formatMessage({ id: 'new_event.tickets.ticket_pools.alert.no_permission' })}</span>
                    </AlertBox>
                  )}
                  <EventTicketPools readOnly={readOnly || !hasFeatureFlag('ticketPools')}>
                    {values.eventType !== 'STREAM' &&
                      totalPoolsCapacity - venueCapacity > 0 &&
                      !values.eventSeatingChart &&
                      venueCapacity !== 0 && (
                      <RightDanger>
                        {intl.formatMessage(
                          { id: 'venue_capacity_allocation.warning' },
                          {
                            number: totalPoolsCapacity - venueCapacity,
                            capacity: venueCapacity,
                            b: (str: string) => <strong>{str}</strong>,
                          }
                        )}
                      </RightDanger>
                    )}
                  </EventTicketPools>
                </div>
              </FormRow>
            ) : (
              <FormRow>
                <>
                  <FormGroup
                    data-name="ticketTypesGap"
                    error={(errors as any).innerTicketTypesErrors || (errors as any).ticketTypesGap}
                  />
                  <EventTicketTypes readOnly={readOnly} ticketTypes={groupedTtys['standalone'] || []}>
                    {overallocation > 0 && !values.eventSeatingChart && (
                      <RightDanger>
                        {intl.formatMessage(
                          { id: 'venue_capacity_allocation.warning' },
                          {
                            number: overallocation,
                            capacity: venueCapacity,
                            b: (str: string) => <strong>{str}</strong>,
                          }
                        )}
                      </RightDanger>
                    )}
                  </EventTicketTypes>
                </>
              </FormRow>
            )}
          </FormRow>

          {user.diceStaff && groupedTtys['archived'] && (
            <FormRow columnOnMobile>
              <FormGroup dice label={intl.formatMessage({ id: 'new_event.tickets.ticket_types.archived_label' })}>
                <EventTicketTypes archived readOnly={readOnly} ticketTypes={groupedTtys['archived'] || []} />
              </FormGroup>
            </FormRow>
          )}

          {values.ticketTypes && values.ticketTypes.length > 0 && !values.eventSeatingChart && (
            <FormRow columnOnMobile>
              <EventTicketSummary maxSeatsCapacity={maxSeatsCapacity} />
            </FormRow>
          )}

          {poolTtys.length > 0 && (
            <FormRow columnOnMobile>
              <EventTicketPoolsTicketTypesSortable ticketTypes={poolTtys} />
            </FormRow>
          )}
        </>
      )}
      {/* TODO: uncomment this when channels backend is ready */}
      {/* {channelsModal && chartManagerCredentials && (
        <ChannelsModal
          title={values.eventSeatingChart?.venueChart.label || ''}
          chartManagerCredentials={chartManagerCredentials}
          onClose={closeChannelsModal}
        />
      )}
      {channelsMappingModal && values.eventSeatingChart?.id && (
        <ChannelMappingModal
          title={values.eventSeatingChart.venueChart.label || ''}
          eventSeatingChartId={values.eventSeatingChart.id}
          currentMapping={values.eventSeatingChart.seatingChannels}
          onClose={closeChannelMappingsModal}
          onSave={onSaveMapping}
        />
      )} */}

      {seatingWarn && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'new_event.tickets.disable_seatmap.title' })}
          description={intl.formatMessage({ id: 'new_event.tickets.disable_seatmap.description' })}
          onConfirm={confirmSeatingWarn}
          onReject={cancelSeatingWarn}
        />
      )}
    </>
  )
}

export default memo(EventTicketTypesConfig)
