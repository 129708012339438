import { FC, useContext, useEffect } from 'react'
import { trackingContext } from '..'

export const PageViewTracker: FC<{ trackId: string; trackData?: any }> = ({ trackId, trackData }) => {
  const { trackPageView } = useContext(trackingContext)

  useEffect(() => {
    trackPageView(trackId, trackData)
  }, [trackId, trackData, trackPageView])

  return null
}
