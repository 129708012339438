import * as Sentry from '@sentry/react'
import { formatISO, parseISO, format, subMinutes, addMinutes } from 'date-fns'
import { compact, every, filter, find, forEach, get, getOr, includes, reduce, set } from 'lodash/fp'

import { IntlShape } from 'react-intl'
import IEventForm from '../types'
import { ILineup } from '../components/EventSchedule'
import { dateFnsLocales } from '../../../intl'
import { isValidDate, parseAtTimezone } from '../../../utils/calendar'
import { allowedEventAction } from '../services/allowedEventAction'
import { DEFAULTS_FIELDS_MAP, EventDefaultTiming, TICKET_TYPE_DATES } from '../types/promoterDefaultTimings'
import { EventTimingField } from '../../../enums.generated'

const logAutofill = (msg: string, newValue: string) => {
  // eslint-disable-next-line no-console
  console.info(msg, newValue)

  Sentry.addBreadcrumb({
    category: 'event_timeline',
    message: msg,
    data: { newValue },
    level: 'info',
  })
}

const getDefaultEventTimings = (event: IEventForm) => {
  return compact(
    event?.billingPromoter?.defaultEventTimings || event?.defaultEventTimings || []
  ) as EventDefaultTiming[]
}

/**
 * Finds first EventDefaultTiming with target field matching input field name
 */
const findDefaultEventTimingByTargetField = (
  event: IEventForm,
  targetField: string,
  location: 'event' | 'ticketType' = 'event'
) => {
  const defaultEventTimings = getDefaultEventTimings(event)

  const defaultsFieldAlias = find((key) => {
    return DEFAULTS_FIELDS_MAP[key]?.fieldName === targetField && DEFAULTS_FIELDS_MAP[key]?.location === location
  }, Object.keys(DEFAULTS_FIELDS_MAP))

  if (defaultsFieldAlias) {
    return find((d) => d?.targetField === defaultsFieldAlias, defaultEventTimings) || null
  }
  return null
}

const calculateOffsetDate = (sourceDateStr: string, offset: number | null | undefined) => {
  const sourceDate = parseISO(sourceDateStr)

  if (!isValidDate(sourceDate)) return null
  if (typeof offset !== 'number') return null

  const isAdding = offset > 0
  const absOffset = Math.abs(offset)

  let newTargetValue

  if (isAdding) {
    newTargetValue = formatISO(addMinutes(sourceDate, absOffset))
  } else {
    newTargetValue = formatISO(subMinutes(sourceDate, absOffset))
  }

  return newTargetValue
}

/**
 * Loop through and apply all default timings that rely on the last changed date field
 */
export const autofillDefaultTimings = (
  event: IEventForm,
  lastUpdatedDate: string,
  setFieldValue: (field: string, value: any) => void,
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void,
  readOnly = false,
  diceStaff = false
) => {
  const newSourceValue = getOr(null, lastUpdatedDate, event)
  if (!lastUpdatedDate || !newSourceValue) return
  const defaultEventTimings = getDefaultEventTimings(event)

  const necessaryUpdates = filter((d: EventDefaultTiming) => {
    if (!d || !d.sourceField || !d.targetField) return false
    const sourceFieldName = get(d.sourceField, DEFAULTS_FIELDS_MAP)?.fieldName || null
    return sourceFieldName === lastUpdatedDate
  }, defaultEventTimings)

  forEach((d) => {
    if (!d.targetField || !d.sourceField) return
    const targetFieldMeta = get(d.targetField, DEFAULTS_FIELDS_MAP)
    if (targetFieldMeta) {
      const targetField = targetFieldMeta.fieldName
      if (targetFieldMeta?.location === 'event') {
        if (canUpdateTargetField(event, d)) {
          const newVal = calculateOffsetDate(newSourceValue, d.offset)
          logAutofill(`Autofill ${targetField}`, newVal || 'null')
          setFieldValue(targetField, newVal)
          setFieldTouched(targetField)
        }
      }
    } else {
      // Special cases
      if (
        d.targetField === 'LINE_UP_DOORS_OPEN' &&
        event.timezoneName &&
        event.lineup &&
        event.lineup.length > 0 &&
        allowedEventAction(event.allowedLifecycleUpdates, 'lineUp')
      ) {
        setFieldValue('lineup', autofillLineup(event, compact(event.lineup), event.timezoneName))
      }
    }
  }, necessaryUpdates)
}

export const autofillLineup = (event: IEventForm, lineup: ILineup[], timezoneName: string | null): ILineup[] => {
  const defaultEventTimings = getDefaultEventTimings(event)
  if (!timezoneName) return lineup

  const [firstItem, ...rest] = lineup

  const defaultTiming = find((d) => d?.targetField === 'LINE_UP_DOORS_OPEN', defaultEventTimings)
  const sourceField = get(defaultTiming?.sourceField || '', DEFAULTS_FIELDS_MAP)?.fieldName || null
  const sourceDateStr = get(sourceField || '', event)

  let newTime = null
  if (sourceDateStr) {
    newTime = calculateOffsetDate(sourceDateStr, defaultTiming?.offset)
  }

  const newTimeStr =
    newTime && isValidDate(parseISO(newTime))
      ? format(parseAtTimezone(newTime, timezoneName)!, 'h:mm aaa', { locale: dateFnsLocales['en-GB'] }).toUpperCase()
      : ''

  logAutofill('Autofill lineup start time', newTimeStr)

  return [
    {
      ...firstItem,
      time: newTimeStr,
    },
    ...rest,
  ]
}

/**
 * Checks whether target field can be updated by checking any dependencies (other fields that need values)
 */
const canUpdateTargetField = (
  event: IEventForm,
  defaultTimingSetting: EventDefaultTiming,
  skipDependencies = false
): boolean => {
  const targetFieldMeta = get(defaultTimingSetting.targetField || '', DEFAULTS_FIELDS_MAP)
  const dependenciesPassed =
    skipDependencies ||
    (targetFieldMeta && every((dep) => get(dep.fieldName, event) === dep.value, targetFieldMeta.dependencies || []))
  return dependenciesPassed
}

export const autofillDate = (event: IEventForm, targetField: string, diceStaff = false, skipDependencies = false) => {
  const targetFieldLastValue = get(targetField, event)

  const defaultTimingSetting = findDefaultEventTimingByTargetField(event, targetField)
  if (!defaultTimingSetting || !defaultTimingSetting.sourceField || !defaultTimingSetting.offset)
    return targetFieldLastValue

  const sourceField = get(defaultTimingSetting.sourceField, DEFAULTS_FIELDS_MAP)?.fieldName
  const sourceDateStr = get(sourceField, event)

  if (!sourceField || !sourceDateStr || !canUpdateTargetField(event, defaultTimingSetting, skipDependencies))
    return targetFieldLastValue

  const newValue = calculateOffsetDate(sourceDateStr, defaultTimingSetting.offset)

  logAutofill(`Autofill ${targetField}`, newValue || '')

  return newValue || targetFieldLastValue
}

export const generateDateFieldHint = (
  intl: IntlShape,
  event: IEventForm,
  dateFieldKey: EventTimingField,
  currentFieldValue?: string | null
) => {
  const defaultEventTimings = getDefaultEventTimings(event)
  const defaultFieldTiming = find((d) => d?.targetField === dateFieldKey, defaultEventTimings)

  if (
    !defaultFieldTiming ||
    !defaultFieldTiming.sourceField ||
    !defaultFieldTiming.targetField ||
    typeof defaultFieldTiming.offset !== 'number'
  ) {
    return null
  }

  const sourceFieldLocalised = intl
    .formatMessage({ id: `promoter_timings.${defaultFieldTiming.sourceField}` })
    .toLowerCase()
  const targetFieldLocalised = intl
    .formatMessage({ id: `promoter_timings.${defaultFieldTiming.targetField}` })
    .toLowerCase()

  // Custom timing message displays if field date doesn't match promoter or global default
  if (defaultFieldTiming && currentFieldValue) {
    const referenceValue = get(DEFAULTS_FIELDS_MAP[defaultFieldTiming.sourceField].fieldName, event)
    const expectedOffsetDate = calculateOffsetDate(referenceValue, defaultFieldTiming.offset)

    if (currentFieldValue !== expectedOffsetDate) {
      return intl.formatMessage(
        { id: 'promoter_timings.custom_value' },
        {
          sourceField: sourceFieldLocalised,
          targetField: targetFieldLocalised,
        }
      )
    }
  }

  const offset = defaultFieldTiming.offset
  let copyKey = ''

  const absOffset = Math.abs(offset)
  const hours = Math.floor(absOffset / 60)
  const minutes = absOffset % 60
  const offsetCopy = compact([
    hours > 0 ? `${hours} ${intl.formatMessage({ id: 'fmt.hours' }, { h: hours })}` : '',
    minutes > 0 ? `${minutes} ${intl.formatMessage({ id: 'fmt.min' }, { m: minutes })}` : '',
  ]).join(', ')

  if (offset === 0) {
    copyKey = 'same_as'
  } else if (offset > 0) {
    copyKey = 'after'
  } else if (offset < 0) {
    copyKey = 'before'
  }

  return intl.formatMessage(
    { id: `promoter_timings.event_field_hint.${copyKey}` },
    {
      fieldName: sourceFieldLocalised,
      offset: offsetCopy,
    }
  )
}
