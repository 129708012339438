import React, { FC, PropsWithChildren, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Outlet } from 'react-router'
import GenericError from '../../components/GenericError'

import { authContext } from '../../context/auth'

const PartnerGate: FC<PropsWithChildren<{ requiresV2?: boolean | null }>> = ({ children, requiresV2 }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const pageMode = !children

  const fallback = (
    <GenericError
      title={intl.formatMessage({ id: 'generic_error.title' })}
      description={intl.formatMessage({ id: 'generic_error.not_found_description' })}
      backText={intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
      backLink="/dashboard"
    />
  )

  if (user.diceStaff || (requiresV2 && !user.mioRedesignV2)) return pageMode ? fallback : null

  return <>{pageMode ? <Outlet /> : children}</>
}

export default PartnerGate
