import React, { useEffect, useCallback, useState, useRef, Ref, FC, ComponentType, HTMLProps, ReactNode } from 'react'
import cn from 'classnames'
import { isNil, merge, omit } from 'lodash/fp'
import Textarea from 'react-textarea-autosize'
import { MergeN } from 'ts-essentials'

import styled from 'styled-components/macro'
import CurrencyInput, { ICurrencyProps } from './CurrencyInput'
import SuggestionsInput, { ISuggestionsProps } from './SuggestionsInput'
import { EventCostCurrency } from '../enums.generated'

// TODO: improve this somehow :(
export type ITextInputProps = MergeN<
  [
    Partial<ICurrencyProps>,
    Partial<ISuggestionsProps>,
    {
      id?: string
      className?: string
      inputClassName?: string
      type?: string
      name?: string
      size?: string
      value?: any
      currency?: EventCostCurrency | '_ANY_' | null
      suggestions?: string[]
      disabled?: boolean
      required?: boolean
      hasError?: boolean
      onChange?: (e: any) => void
      onPressEnter?: (e: any) => void
      onFocus?: (e: any) => void
      onBlur?: (e: any) => void
      onKeyDown?: (e: any) => void
      prefix?: any
      affix?: any
      suffix?: string | ReactNode | null
      multiline?: boolean
      inputRef?: Ref<HTMLInputElement>
      autoFocus?: boolean
      autoComplete?: string
    } & Omit<HTMLProps<HTMLInputElement>, 'ref' | 'as' | 'label' | 'onBlur' | 'onChange'>
  ]
>

const TextInput: FC<ITextInputProps> = ({
  className,
  inputClassName,
  type = 'text',
  value,
  currency,
  suggestions,
  disabled,
  required,
  hasError,
  onFocus,
  onBlur,
  onKeyDown,
  onPressEnter,
  prefix,
  affix,
  suffix,
  size,
  multiline,
  autoFocus,
  ...rest
}) => {
  const [focused, setFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFocus = useCallback(
    (e: any) => {
      setFocused(true)
      if (onFocus) {
        onFocus(e)
      }
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    (e: any) => {
      setFocused(false)
      if (onBlur) {
        onBlur(e)
      }
    },
    [onBlur]
  )

  const handleKeyDown = useCallback(
    (e: any) => {
      if (onPressEnter && e.keyCode === 13) {
        onPressEnter(e)
      }
      if (onKeyDown) {
        onKeyDown(e)
      }
    },
    [onKeyDown, onPressEnter]
  )

  const [suffixOffset, setSuffixOffset] = useState(0)
  const [inputFontSize, setInputFontSize] = useState(16)
  const hiddenRender = useRef<HTMLDivElement>(null)

  const updateSuffix = useCallback(() => {
    if (suffix && type === 'number' && !multiline && inputRef.current && window) {
      const style = window.getComputedStyle(inputRef.current)
      setInputFontSize(parseInt(style.getPropertyValue('font-size')))

      setTimeout(() => {
        const contentWidth = hiddenRender?.current?.offsetWidth || 0
        const inputPadding = parseInt(style.getPropertyValue('padding-left')) || 0
        const space = Math.floor(inputFontSize / 3)
        setSuffixOffset(contentWidth + inputPadding + space)
      }, 0)
    }
  }, [inputFontSize, multiline, suffix, type])

  useEffect(() => {
    if (inputRef.current) {
      updateSuffix()
    }
  }, [inputRef, updateSuffix])

  useEffect(() => {
    updateSuffix()
  }, [updateSuffix, value])

  useEffect(() => {
    if (autoFocus) {
      if (inputRef.current) {
        inputRef.current.focus()
      }
      setFocused(true)
    }
  }, [autoFocus])

  const inputProps: any = merge(
    omit(
      [
        'affix',
        'autoCompleteProps',
        'autoFocus',
        'className',
        'hasError',
        'inputClassName',
        'inputRef',
        'multiline',
        'onBlur',
        'ref',
        'onFocus',
        'onKeyDown',
        'onPressEnter',
        'prefix',
        'size',
        'currency',
        'defaultValue',
        'required',
      ],
      rest
    ),
    {
      className: cn('text-input_input', {
        [inputClassName || '']: !!inputClassName,
      }),
      suggestions,
      currency,
      value,
      disabled,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onKeyDown: handleKeyDown,
      type,
    }
  )

  let InputElement: ComponentType<any> | string = 'input'

  if (multiline) {
    InputElement = Textarea
    delete inputProps.type
    delete inputProps.ref
    inputProps.ref = inputRef
    inputProps.maxRows = 5
  } else {
    inputProps.ref = inputRef
  }

  if (currency) {
    InputElement = CurrencyInput
    delete inputProps.type
    inputProps.type = 'tel'
  }

  if (!isNil(suggestions)) {
    InputElement = SuggestionsInput
    delete inputProps.ref
    inputProps.inputRef = inputRef
  }

  return (
    <div
      className={cn('text-input', {
        '-disabled': disabled,
        '-required': required,
        '-has-error': hasError && !focused,
        '-focus': focused,
        [`-size-${size}`]: !!size,
        [className || '']: !!className,
      })}
    >
      <div className="text-input_container">
        {prefix}
        <InputElement {...inputProps} />
        {suffix && !multiline && !isNil(value) && (
          <>
            <HiddenRender ref={hiddenRender} fontSize={inputFontSize}>
              {value}
            </HiddenRender>
            <Suffix textWidth={suffixOffset} fontSize={inputFontSize}>
              {suffix}
            </Suffix>
          </>
        )}
        {affix}
      </div>
    </div>
  )
}

export default TextInput

const HiddenRender = styled.div<{ fontSize?: number }>`
  position: absolute;
  left: 0;
  visibility: hidden;
  font-size: ${({ fontSize }) => fontSize ?? 16}px;
`

const Suffix = styled.div<{ textWidth?: number; fontSize?: number }>`
  position: absolute;
  left: ${({ textWidth }) => textWidth ?? 0}px;
  font-size: ${({ fontSize }) => fontSize ?? 16}px;
`
