import styled from 'styled-components/macro'

import { color as COLOR } from '../utils/variables'

const OverviewPlate = styled.div<{ color?: keyof typeof COLOR }>`
  border-radius: 4px;
  background: ${({ color }) => (color ? COLOR[color] : COLOR.palegrey)};
  padding: 24px;
`

export default OverviewPlate
