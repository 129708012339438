import { useCallback, useContext, useState } from 'react'
import { compose, findIndex, set, unset, update, reject } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { Dictionary } from 'ts-essentials'

import { isSaved } from '../../../utils/entityStatus'
import IEventFormTickets, { ITicketType } from '../types/Tickets'
import { authContext } from '../../../context/auth'

interface ICtx {
  seated: boolean
  soldOutMap: Dictionary<boolean>
}

interface IAlert {
  title: string
  subtitle: string
}

interface IConfirmRemoveAlert {
  title: string
  subtitle: string
  cta: string
  onConfirm?: () => void
}

function useRemoveTicketType(allTtys: ITicketType[], ctx: ICtx) {
  const intl = useIntl()

  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormTickets>()

  const { user } = useContext(authContext)

  const { seated, soldOutMap } = ctx

  const [problemAlert, setProblemAlert] = useState<IAlert | null>(null)
  const [confirmRemoveAlert, setConfirmRemoveAlert] = useState<IConfirmRemoveAlert | null>(null)
  const closeProblemAlert = useCallback(() => setProblemAlert(null), [])
  const closeConfirmRemoveAlert = useCallback(() => setConfirmRemoveAlert(null), [])

  const removeTicketType = useCallback(
    (removedId: any) => {
      const removedIdx = findIndex(['id', removedId], allTtys)
      const updatedTicketTypes = update(
        [removedIdx],
        compose([set('archived', true), set('hidden', true), set('__justArchived', true)]),
        allTtys
      )

      setFieldValue('ticketTypes', updatedTicketTypes)
      setFieldTouched('ticketTypes', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [allTtys, setFieldTouched, setFieldValue, validateForm]
  )

  const handleRemoveTicketType = useCallback(
    (removedId: any) => {
      // check if the ticket is required by other tickets (requiresOtherTypeIds)
      const requiresOtherTypeIds = allTtys
        .filter((tty) => tty?.requiresOtherTypeIds?.includes(removedId))
        .map((tty) => tty.id)

      console.log('requiresOtherTypeIds', requiresOtherTypeIds)

      if (requiresOtherTypeIds.length) {
        setConfirmRemoveAlert({
          title: intl.formatMessage(
            {
              id: 'ticket_type_requires_other_alert.title',
            },
            { count: requiresOtherTypeIds.length }
          ),
          subtitle: intl.formatMessage(
            { id: 'ticket_type_requires_other_alert.description' },
            { requiresOtherTypeIds: requiresOtherTypeIds.join(', ') }
          ),
          cta: intl.formatMessage({ id: 'ticket_type_requires_other_alert.cta' }),
          onConfirm: () => {
            removeTicketType(removedId)

            closeConfirmRemoveAlert()
          },
        })
        return
      }

      if (values.state === 'DRAFT' || !isSaved({ id: removedId })) {
        setFieldValue('ticketTypes', reject(['id', removedId], allTtys))
        setFieldTouched('ticketTypes', true, true)
        setTimeout(() => validateForm(), 0)
      } else {
        const isSoldOut = !!soldOutMap[removedId]
        if (!isSoldOut && !seated && !user.diceStaff) {
          setProblemAlert({
            title: intl.formatMessage({ id: 'archive_with_allocation_ticket_error.title' }),
            subtitle: intl.formatMessage({ id: 'archive_with_allocation_ticket_error.description' }),
          })
          return
        }

        removeTicketType(removedId)
      }
    },
    [
      allTtys,
      values.state,
      intl,
      removeTicketType,
      closeConfirmRemoveAlert,
      setFieldValue,
      setFieldTouched,
      validateForm,
      soldOutMap,
      seated,
      user.diceStaff,
    ]
  )

  const restoreTicketType = useCallback(
    (restoredId: any) => {
      const restoredIdx = findIndex(['id', restoredId], allTtys)
      const updatedTicketTypes = update(
        [restoredIdx],
        compose([set('archived', false), set('hidden', false), unset('__justArchived')]),
        allTtys
      )

      setFieldValue('ticketTypes', updatedTicketTypes)
      setFieldTouched('ticketTypes', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [allTtys, setFieldValue, setFieldTouched, validateForm]
  )

  return {
    handleRemoveTicketType,
    restoreTicketType,
    problemAlert,
    closeProblemAlert,
    confirmRemoveAlert,
    closeConfirmRemoveAlert,
  }
}

export default useRemoveTicketType
