/**
 * @generated SignedSource<<3bef19db476ea743ef813b6ef9157cbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RemittanceModalSalesBreakdown_event$data = {
  readonly costCurrency: EventCostCurrency | null;
  readonly id: string;
  readonly name: string | null;
  readonly sales: {
    readonly totalFaceValue: number;
    readonly totalPromoterIncome: number;
    readonly totalRebate: number;
  } | null;
  readonly " $fragmentType": "RemittanceModalSalesBreakdown_event";
};
export type RemittanceModalSalesBreakdown_event$key = {
  readonly " $data"?: RemittanceModalSalesBreakdown_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemittanceModalSalesBreakdown_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemittanceModalSalesBreakdown_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalFaceValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalRebate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPromoterIncome",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "51fd7cc7ba7a2757c293e18ebd23c1cb";

export default node;
