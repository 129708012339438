import React, { lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router'
import { GenericErrorWithHeader } from '../../components/GenericError'
import IntlConsumer from '../../components/IntlConsumer'
import { Loader, LoaderContainer } from '../../components/Loader'
import DiceGate from '../gates/DiceGate'
import PartnerGate from '../gates/PartnerGate'
import PermissionGate from '../gates/PermissionGate'

const VenueHeader = lazy(() => import(/* webpackChunkName: "venues" */ '../../flows/Venues/components/VenueHeader'))
const VenuesPage = lazy(() => import(/* webpackChunkName: "venues" */ '../../flows/Venues/VenuesPage'))
const SmartVenuePage = lazy(() => import(/* webpackChunkName: "venues" */ '../../flows/Venues/SmartVenuePage'))
const VenueSeatmapsPage = lazy(
  () => import(/* webpackChunkName: "venues" */ '../../flows/VenueSeatmaps/VenueSeatmapsPage')
)
const VenueSeatmapPage = lazy(
  () => import(/* webpackChunkName: "venues" */ '../../flows/VenueSeatmaps/VenueSeatmapPage')
)
const VenueDetailsPage = lazy(
  () => import(/* webpackChunkName: "venues" */ '../../flows/VenueDetails/VenueDetailsPage')
)
const VenueContactsPage = lazy(() => import(/* webpackChunkName: "venues" */ '../../flows/Contacts/ContactsPage'))
const VenueFeesPage = lazy(() => import(/* webpackChunkName: "venues" */ '../../flows/Fees/FeesPage'))
const VenueCreateContactPage = lazy(
  () => import(/* webpackChunkName: "venues" */ '../../flows/CreateContact/CreateContactPage')
)
const VenueActivityPage = lazy(() => import(/* webpackChunkName: "venues" */ '../../flows/Activity/ActivityPage'))
const VenueCreateContractPage = lazy(
  () => import(/* webpackChunkName: "venues" */ '../../flows/CreateContract/CreateContractPage')
)
const VenueEventsPage = lazy(
  () => import(/* webpackChunkName: "venues" */ '../../flows/EventList/EmbeddedEventListPage')
)

const VenuesSection = () => (
  <Routes>
    <Route path="*">
      <Route
        index
        element={
          <>
            <DiceGate>
              <VenuesPage />
            </DiceGate>
            <PartnerGate>
              <SmartVenuePage />
            </PartnerGate>
          </>
        }
      />

      <Route
        path="new"
        element={
          <>
            <VenueHeader />
            <PermissionGate permission="create:venue">
              <VenueDetailsPage />
            </PermissionGate>
          </>
        }
      />
    </Route>

    <Route path=":id/contacts/new" element={<VenueCreateContactPage />} />
    <Route path=":id/fees/new" element={<VenueCreateContractPage />} />

    <Route
      path=":id"
      element={
        <>
          <DiceGate>
            <VenueHeader />
          </DiceGate>
          <Suspense
            fallback={
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            }
          >
            <Outlet />
          </Suspense>
        </>
      }
    >
      <Route
        index
        element={
          <>
            <PermissionGate permission="manage_seating_templates:venue" fallback={<Navigate to="details" replace />}>
              <Navigate to="seatmaps" replace />
            </PermissionGate>
          </>
        }
      />

      <Route
        path="details"
        element={
          <>
            <PartnerGate>
              <VenueHeader />
            </PartnerGate>
            <PermissionGate permission="update:venue">
              <VenueDetailsPage />
            </PermissionGate>
          </>
        }
      />

      <Route path="seatmaps" element={<VenueSeatmapsPage />} />
      <Route path="seatmaps/:seatmapId" element={<VenueSeatmapPage />} />

      <Route path="fees" element={<VenueFeesPage />} />
      <Route path="contacts" element={<VenueContactsPage />} />
      <Route path="activity" element={<VenueActivityPage />} />
      <Route path="events" element={<VenueEventsPage />} />

      <Route
        path="*"
        element={
          <IntlConsumer>
            {(intl) => (
              <GenericErrorWithHeader
                title={intl.formatMessage({ id: 'generic_error.title' })}
                description={intl.formatMessage({ id: 'generic_error.not_found_description' })}
              />
            )}
          </IntlConsumer>
        }
      />
    </Route>
  </Routes>
)

export default VenuesSection
