import { createContext } from 'react'
import { EventTimeline_event$data } from '../../../__generated__/EventTimeline_event.graphql'

interface IEventTimelineContext {
  dateOffset: (date: Date | string | null) => number
  timelineStart: Date
  timelineEnd: Date
  event: EventTimeline_event$data
}

const EventTimelineContext = createContext<IEventTimelineContext>({} as any)

export default EventTimelineContext
