/**
 * @generated SignedSource<<01bfd897d1606cfc44e74cb170d4d8f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventFilterModalTypesQuery$variables = Record<PropertyKey, never>;
export type EventFilterModalTypesQuery$data = {
  readonly viewer: {
    readonly options: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly label: string | null;
          readonly parent: {
            readonly name: string | null;
          } | null;
          readonly value: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type EventFilterModalTypesQuery = {
  response: EventFilterModalTypesQuery$data;
  variables: EventFilterModalTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "kind": "type"
    }
  }
],
v1 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventFilterModalTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v0/*: any*/),
            "concreteType": "HierarchicalTagsConnection",
            "kind": "LinkedField",
            "name": "hierarchicalTags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HierarchicalTagsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HierarchicalTag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HierarchicalTag",
                        "kind": "LinkedField",
                        "name": "parent",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "hierarchicalTags(first:100,where:{\"kind\":\"type\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventFilterModalTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v0/*: any*/),
            "concreteType": "HierarchicalTagsConnection",
            "kind": "LinkedField",
            "name": "hierarchicalTags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HierarchicalTagsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HierarchicalTag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HierarchicalTag",
                        "kind": "LinkedField",
                        "name": "parent",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "hierarchicalTags(first:100,where:{\"kind\":\"type\"})"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae9faef7d947a8e7bdf6b31a27a92758",
    "id": null,
    "metadata": {},
    "name": "EventFilterModalTypesQuery",
    "operationKind": "query",
    "text": "query EventFilterModalTypesQuery {\n  viewer {\n    options: hierarchicalTags(where: {kind: \"type\"}, first: 100) {\n      edges {\n        node {\n          value: id\n          label: name\n          parent {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "115cac740a328ef3b4ff35d1be56bed0";

export default node;
