import React, { memo, useCallback, useRef, FC, ChangeEvent } from 'react'
import styled from 'styled-components/macro'
import { FormikTouched, FormikValues, FormikErrors } from 'formik'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { concat, reject, get, set, isString, compact } from 'lodash/fp'
import { useIntl } from 'react-intl'

import { mediaQuery, color, font } from '../../../utils/variables'

import Button from '../../../components/Button'
import FormGroup from '../../../components/FormGroup'
import { ReverseFlexFormField } from '../../../components/FormField'
import IconButton from '../../../components/IconButton'
import Svg from '../../../components/Svg'

import { ISellingPoint } from '../types/Extras'

import { CategoryType } from '../../../enums.generated'

const rejectWithIdx = (reject as any).convert({ cap: false })

const Item = styled.div`
  display: flex;
  & > div {
    flex: 0.5;
    margin-left: 8px;
  }
  & > div:first-child {
    flex: 1;
    margin-left: 0;
  }

  ${mediaQuery.lessThan('desktop')`
    min-width: 100%;
    margin-left: 0;
    margin-top: 8px;
  `}
`

const SellingPoint = styled.div<{ compact?: boolean }>`
  margin-top: 16px;
  padding: ${({ compact }) => (compact ? '0 30px' : '0')};
  ${mediaQuery.lessThan('desktop')`
    padding: 0;
  `}
`

const Placeholder = styled.div`
  max-width: 40px;
  width: 40px;
  height: 40px;

  ${mediaQuery.lessThan('desktop')`
    display: none;
  `}
`

const Row = styled.div`
  position: relative;
  background-color: ${color.white};
  margin-bottom: 8px;

  ${mediaQuery.lessThan('desktop')`
    padding: 0 40px;
  `}
`

const DragHandle = styled.div<{ disabled?: boolean }>`
  width: 40px;
  height: 40px;
  flex: none;
  max-width: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.grey};
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};
  &:hover {
    color: ${({ disabled }) => (disabled ? color.grey : color.text)};
  }

  ${mediaQuery.lessThan<{ disabled?: boolean }>('desktop')`
    margin: 0;
    position: absolute;
    left: -40px;
    top: 50%;
    margin-top: -20px;
  `}

  svg {
    pointer-events: none;
  }
`

const RemoveButton = styled(IconButton)`
  ${mediaQuery.lessThan<{ disabled?: boolean }>('desktop')`
    margin: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -20px;
  `}
`

const Counter = styled.span<{ count: number; maxCount: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin-left: 8px;
  height: 40px;
  font-size: ${font.size.sm}px;
  overflow: hidden;
  white-space: nowrap;
  ${({ count, maxCount }) => (count > maxCount ? `color: ${color.error}` : `color: ${color.darkgrey}`)}
`

const SortableDragHandle = SortableHandle<{ disabled?: boolean }>(({ disabled }: { disabled?: boolean }) => (
  <DragHandle disabled={disabled}>
    <Svg icon="hamburger" />
  </DragHandle>
))

interface IItemProps {
  idx: number
  item: ISellingPoint
  timezone?: string
  setItem: (idx: number, value: ISellingPoint) => void
  handleBlur?: (...args: any[]) => any
  remove: (idx: number) => void
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
  allowEdit?: boolean
  noMove?: boolean
  noRemove?: boolean
}

const SortableItem = SortableElement<IItemProps>(
  ({ idx, item, handleBlur, remove, setItem, touched, errors, allowEdit, noMove, noRemove }: IItemProps) => {
    const { name } = item

    const onRemove = useCallback(() => remove(idx), [remove, idx])

    const handleChangeDetails = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value || ''

        if (!isString(v)) {
          console.error(new Error(`Attempt to set non-string name ${v}`))
          return
        }

        setItem(idx, set('name', v, item))
      },
      [setItem, idx, item]
    )

    return (
      <Row className="draggable">
        <Item>
          <ReverseFlexFormField
            name={`sellingPoints[${idx}].name`}
            onChange={handleChangeDetails}
            value={name || ''}
            onBlur={handleBlur}
            error={get(`sellingPoints[${idx}].name`, touched) && get(`sellingPoints[${idx}].name`, errors)}
            disabled={!allowEdit}
          >
            <SortableDragHandle disabled={!allowEdit || noMove} />
          </ReverseFlexFormField>
          <Counter maxCount={38} count={(name || '').length}>
            {(name || '').length}/{38}
          </Counter>
          {allowEdit && !noRemove ? (
            <RemoveButton icon="trash" className="ml-sm" onClick={onRemove} />
          ) : (
            <Placeholder />
          )}
        </Item>
      </Row>
    )
  }
)

interface IContainerProps {
  sellingPoints: Array<ISellingPoint>
  setSellingPoints: (v: ISellingPoint[]) => void
  handleBlur?: (...args: any[]) => any
  remove: (idx: number) => void
  touched?: FormikTouched<FormikValues>
  errors?: FormikErrors<FormikValues>
  compact?: boolean
  allowEdit?: boolean
  noMove?: boolean
  noRemove?: boolean
}

const SortableSellingPoints = SortableContainer<IContainerProps>(
  ({
    compact,
    sellingPoints = [],
    handleBlur,
    remove,
    setSellingPoints,
    touched,
    errors,
    allowEdit,
    noMove,
    noRemove,
  }: IContainerProps) => {
    const setItem = useCallback(
      (idx: number, value: ISellingPoint) => {
        setSellingPoints(set(`${idx}`, value, sellingPoints || []))
      },
      [setSellingPoints, sellingPoints]
    )

    return (
      <SellingPoint compact={compact}>
        {sellingPoints.map((item, idx) => (
          <SortableItem
            key={idx}
            idx={idx}
            index={idx}
            item={item}
            handleBlur={handleBlur}
            remove={remove}
            setItem={setItem}
            touched={touched}
            errors={errors}
            disabled={!allowEdit}
            allowEdit={allowEdit}
            noMove={noMove}
            noRemove={noRemove}
          />
        ))}
      </SellingPoint>
    )
  }
)

interface IProps {
  productCategoryType: CategoryType
  handleBlur: (e: Event) => void
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  sellingPoints?: ReadonlyArray<ISellingPoint> | null
  setSellingPoints: (v: Array<ISellingPoint>) => void
  compact?: boolean
  allowEdit?: boolean
  noMove?: boolean
  noRemove?: boolean
}

const EventProductSellingPonts: FC<React.PropsWithChildren<IProps>> = ({
  productCategoryType,
  compact: isCompact,
  sellingPoints,
  setSellingPoints,
  handleBlur,
  errors,
  touched,
  allowEdit,
  noMove,
  noRemove,
}) => {
  const intl = useIntl()

  const ref = useRef<HTMLDivElement>(null)

  const addPoint = useCallback(() => {
    const normalizedPoints = compact(sellingPoints)

    const idx = normalizedPoints.length

    setSellingPoints(concat(normalizedPoints, { name: '' }))

    setTimeout(() => {
      if (ref.current) {
        const node = ref.current.querySelector(`input[name="sellingPoints[${idx}].name"]`) as HTMLInputElement
        if (node) node.focus()
      }
    }, 500)
  }, [sellingPoints, setSellingPoints])

  const remove = useCallback(
    (removedIdx: any) => {
      setSellingPoints(rejectWithIdx((_: any, idx: number) => idx === removedIdx, sellingPoints))
    },
    [setSellingPoints, sellingPoints]
  )

  const reorder = useCallback(
    ({ oldIndex, newIndex }: any) => {
      setSellingPoints(arrayMove(sellingPoints || [], oldIndex, newIndex))
    },
    [setSellingPoints, sellingPoints]
  )

  return (
    <div ref={ref}>
      <FormGroup
        label={intl.formatMessage({
          id: `new_event.extras.form.selling_points${
            productCategoryType
              ? '.' + (productCategoryType === 'MERCH' ? productCategoryType : 'EXPERIENCE').toLowerCase()
              : ''
          }.label`,
        })}
        hint={intl.formatMessage({
          id: `new_event.extras.form.selling_points${
            productCategoryType
              ? '.' + (productCategoryType === 'MERCH' ? productCategoryType : 'EXPERIENCE').toLowerCase()
              : ''
          }.hint`,
        })}
        required
      />

      <SortableSellingPoints
        sellingPoints={(sellingPoints || []) as ISellingPoint[]}
        handleBlur={handleBlur}
        setSellingPoints={setSellingPoints}
        remove={remove}
        onSortEnd={reorder}
        lockAxis="y"
        useDragHandle
        touched={touched}
        errors={errors}
        compact={isCompact}
        allowEdit={allowEdit}
        noMove={noMove}
        noRemove={noRemove}
      />
      {allowEdit && (sellingPoints?.length || 0) < 6 && (
        <Button icon="plus" size="small" className="mt-sm" preset="secondary" onClick={addPoint}>
          {intl.formatMessage({ id: 'new_event.extras.form.selling_points.actions.add' })}
        </Button>
      )}
    </div>
  )
}

export default memo(EventProductSellingPonts)
