import styled from 'styled-components/macro'

import { mediaQuery } from '../../../../utils/variables'

export const DashboardEventCardList = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  margin: 0px -16px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  ${mediaQuery.lessThan('desktopLarge')`
    margin: 0 -8px;
  `}
  ${mediaQuery.lessThan('desktop')`
    margin: 0 8px;
  `}
`

export const DashboardEventCard = styled.div<{ withExtraInfo?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  flex-grow: 1;
  max-width: 232px;
  min-width: 155px;
  width: 20%;
  ${({ withExtraInfo }) => withExtraInfo && 'min-height: 380px;'}
  ${mediaQuery.lessThan('desktopLarge')`
    padding: 8px;
  `}
  ${mediaQuery.lessThan('desktop')`
    min-width: 220px;
    max-width: 50%;
  `}
  ${mediaQuery.lessThan('tablet')`
    max-width: none;
    min-height: unset;
  `}
`

export const DashboardEventCardEmpty = styled(DashboardEventCard)`
  padding: 0;
  min-height: 0;
`
