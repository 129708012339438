/**
 * @generated SignedSource<<d819d2d4adf59e01793468f48e62ebf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeAllocationModalQuery$variables = {
  id: string;
};
export type ChangeAllocationModalQuery$data = {
  readonly event: {
    readonly eventIdLive?: string | null;
    readonly id?: string;
    readonly ticketPools?: ReadonlyArray<{
      readonly id: string;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"useTierSelector_event" | "useVenueAllocationCheck_event">;
  } | null;
};
export type ChangeAllocationModalQuery = {
  response: ChangeAllocationModalQuery$data;
  variables: ChangeAllocationModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventIdLive",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketPool",
  "kind": "LinkedField",
  "name": "ticketPools",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressCountry",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v8 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStream",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceTierType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeAllocationModalQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useVenueAllocationCheck_event"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useTierSelector_event"
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeAllocationModalQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventType",
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventSeatingChart",
                "kind": "LinkedField",
                "name": "eventSeatingChart",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Venue",
                "kind": "LinkedField",
                "name": "venues",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "capacity",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Venue",
                "kind": "LinkedField",
                "name": "primaryVenue",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketType",
                "kind": "LinkedField",
                "name": "ticketTypes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hidden",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceTier",
                    "kind": "LinkedField",
                    "name": "priceTiers",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Sales",
                "kind": "LinkedField",
                "name": "sales",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketTypeBreakdown",
                    "kind": "LinkedField",
                    "name": "ticketTypesBreakdown",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketType",
                        "kind": "LinkedField",
                        "name": "ticketType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v13/*: any*/),
                          (v9/*: any*/),
                          (v12/*: any*/),
                          (v11/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalAppSold",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalPosSold",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalReserved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceTierBreakdown",
                        "kind": "LinkedField",
                        "name": "priceTiersBreakdown",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appSold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "posSold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reserved",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PriceTier",
                            "kind": "LinkedField",
                            "name": "priceTier",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v13/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3af3c12526afd42b97bbd3b162f884cc",
    "id": null,
    "metadata": {},
    "name": "ChangeAllocationModalQuery",
    "operationKind": "query",
    "text": "query ChangeAllocationModalQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ... on Event {\n      id\n      eventIdLive\n      ticketPools {\n        id\n      }\n      ...useVenueAllocationCheck_event\n      ...useTierSelector_event\n    }\n    id\n  }\n}\n\nfragment useTierSelector_event on Event {\n  sales {\n    ticketTypesBreakdown {\n      ticketType {\n        id\n        name\n        allocation\n        priceTierType\n        archived\n        isStream\n      }\n      totalAppSold\n      totalPosSold\n      totalReserved\n      priceTiersBreakdown {\n        appSold\n        posSold\n        reserved\n        priceTier {\n          id\n          name\n          allocation\n        }\n      }\n    }\n  }\n}\n\nfragment useVenueAllocationCheck_event on Event {\n  eventType\n  addressCountry\n  countryCode\n  eventSeatingChart {\n    id\n  }\n  venues {\n    value: id\n    capacity\n    addressCountry\n    countryCode\n    id\n  }\n  primaryVenue {\n    value: id\n    id\n  }\n  ticketTypes {\n    id\n    hidden\n    allocation\n    isStream\n    archived\n    priceTierType\n    priceTiers {\n      id\n      allocation\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "148198de21406a37de4d4cc54ca9a051";

export default node;
