import React, { FC, memo, useCallback } from 'react'
import styled from 'styled-components/macro'
import { forEach } from 'lodash/fp'

import Svg from '../../../components/Svg'
import { color } from '../../../utils/variables'
import { ITrack } from '../../../utils/trackSearch'
import IconButton from '../../../components/IconButton'
import OpenUrl from '../../../components/OpenUrl'
import { textStyle } from '../../../utils/typography'

interface ITrackEditorProps {
  track: ITrack
  type: 'appleMusicTrack' | 'spotifyTrack' | 'combinedTrack'
  onRemove?: () => void
  allowEdit?: boolean
}

const ICONS = {
  appleMusicTrack: 'apple-music',
  spotifyTrack: 'spotify',
  combinedTrack: 'apple-music-spotify',
}

const Placeholder = styled.div`
  position: relative;
  background-color: ${color.white};
  color: ${color.black};
  width: 40px;
  height: 40px;

  border-radius: 4px;
  border: 2px solid ${color.lightgrey};

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    pointer-events: none;
  }
`

const TrackEditorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > a {
    display: block;
  }

  & > audio {
    width: 100%;
    margin-top: 8px;
    outline: none;
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;

    border-radius: 4px;
  }
`

const TrackInfo = styled.div`
  position: relative;
  ${textStyle.heading.sm}
  flex: 1;

  margin-left: 16px;

  & > div {
    max-width: calc(100% - 60px);
    overflow: hidden;
  }
`

const RemoveButton = styled(IconButton)`
  position: absolute;
  top: calc(50% - 17px);
  right: 6px;
`

const EventTrackPreview: FC<React.PropsWithChildren<ITrackEditorProps>> = ({
  track,
  type,
  onRemove,
  allowEdit = true,
}) => {
  const onPlay = useCallback((e: any) => {
    const audioTags = document.getElementsByTagName('audio')
    forEach((audio) => {
      if (audio !== e?.target && !audio.paused) {
        audio.pause()
      }
    }, audioTags)
  }, [])

  return (
    <TrackEditorContainer>
      <OpenUrl href={track.open_url}>
        {track.image ? (
          <img src={track.image} alt={track.name} />
        ) : (
          <Placeholder>
            <Svg icon={ICONS[type]} />
          </Placeholder>
        )}
      </OpenUrl>
      <TrackInfo>
        <div>{track.name}</div>
        {allowEdit && <RemoveButton icon="trash" onClick={onRemove} data-id={`removeTrack[${type}]`} />}
      </TrackInfo>
      {track.preview_url && (
        <audio
          src={track.preview_url}
          controls
          preload="metadata"
          crossOrigin="anonymous"
          controlsList="nodownload noremoteplayback"
          onPlay={onPlay}
        />
      )}
    </TrackEditorContainer>
  )
}

export default memo(EventTrackPreview)
