import React, { FC, HTMLProps } from 'react'
import styled from 'styled-components/macro'
import { color as colors, font, mediaQuery } from '../utils/variables'
import Svg from './Svg'

const Container = styled.div<{ color?: keyof typeof colors }>`
  display: inline-flex;

  color: ${({ color }) => color || colors.greyer};
  font-size: ${font.size.sm}px;
  line-height: 125%;
  letter-spacing: 0.01em;

  & > svg {
    min-width: 24px;
    margin: -4px 8px 0 0;
  }

  & + & {
    margin-left: 16px;
  }

  ${mediaQuery.lessThan('tablet')`
     & + & {
      margin-top: 8px;
      margin-left: 0;
    }
  `}
`

interface IProps {
  icon?: string
  color?: keyof typeof colors
}

const Note: FC<React.PropsWithChildren<IProps & Omit<Omit<HTMLProps<HTMLDivElement>, 'ref'>, 'as'>>> = ({
  icon,
  color,
  children,
  ...props
}) => (
  <Container color={color} {...props}>
    <Svg icon={icon || 'info'} />
    <span>{children}</span>
  </Container>
)

export default styled(Note)``
