/**
 * @generated SignedSource<<30291940c0da2057b77e254336f04e3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useEventArtistsMusicbrainzLoaderQuery$variables = {
  searchTerm: string;
};
export type useEventArtistsMusicbrainzLoaderQuery$data = {
  readonly viewer: {
    readonly options: ReadonlyArray<{
      readonly hint: string | null;
      readonly label: string | null;
      readonly value: string;
    } | null> | null;
  } | null;
};
export type useEventArtistsMusicbrainzLoaderQuery = {
  response: useEventArtistsMusicbrainzLoaderQuery$data;
  variables: useEventArtistsMusicbrainzLoaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "filterKimArtists",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "limit",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "searchTerm"
  }
],
v2 = {
  "alias": "value",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "label",
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": "hint",
  "args": null,
  "kind": "ScalarField",
  "name": "disambiguation",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEventArtistsMusicbrainzLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "MusicbrainzArtist",
            "kind": "LinkedField",
            "name": "musicbrainzArtists",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEventArtistsMusicbrainzLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "options",
            "args": (v1/*: any*/),
            "concreteType": "MusicbrainzArtist",
            "kind": "LinkedField",
            "name": "musicbrainzArtists",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "51fb9c2f6458de7ddc99107aab2c1954",
    "id": null,
    "metadata": {},
    "name": "useEventArtistsMusicbrainzLoaderQuery",
    "operationKind": "query",
    "text": "query useEventArtistsMusicbrainzLoaderQuery(\n  $searchTerm: String!\n) {\n  viewer {\n    options: musicbrainzArtists(query: $searchTerm, limit: 50, filterKimArtists: true) {\n      value: id\n      label: name\n      hint: disambiguation\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "63e6d0db605f979f8462c3ccb1cee48f";

export default node;
