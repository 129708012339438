import graphql from 'babel-plugin-relay/macro'
import { isFuture, parseISO } from 'date-fns'
import { compose, filter, fromPairs, identity, some, toPairs, values } from 'lodash/fp'
import { useContext, useMemo } from 'react'
import { useFragment } from 'react-relay'
import { authContext } from '../../context/auth'
import { localeContext } from '../../context/locale'
import { useEventPayout_event$key } from '../../__generated__/useEventPayout_event.graphql'
import { isRemittanceCountryEvent } from '../isCountryEvent'
import { IBlockers, ISeriousBlockers } from '../payoutBlockers'

function useEventPayout(eventKey: useEventPayout_event$key | null) {
  const { user, hasPermission } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const event = useFragment(
    graphql`
      fragment useEventPayout_event on Event {
        endDate

        allowedActions {
          performPayout
        }

        adjustmentsStats {
          failed
          notProcessed
        }

        countryCode
        addressCountry
        venues {
          countryCode
          addressCountry
        }

        selfPayoutBlockers

        billingPromoter {
          remittanceRecipients
          payoutsEnabled
        }

        balance {
          availability
          isProcessing
          amountPromoterOwed
          amountPromoterTotal
          payouts {
            status
          }
        }
      }
    `,
    eventKey
  )

  const hasPendingPayouts = some(
    (p) => p?.status === 'pending' || p?.status === 'in_transit',
    event?.balance?.payouts || []
  )

  const selfPayoutBlockers = useMemo(() => {
    if (!event?.selfPayoutBlockers) return null
    const hasNonEmpty = compose([some(identity), values])(event?.selfPayoutBlockers)

    return hasNonEmpty
      ? (compose([fromPairs, filter(([_, v]) => !!v), toPairs])(event.selfPayoutBlockers) as IBlockers)
      : null
  }, [event?.selfPayoutBlockers])

  const payoutAmount = event?.balance?.amountPromoterOwed || 0
  const payoutTotal = event?.balance?.amountPromoterTotal || 0

  const isProcessing = !!event?.balance?.isProcessing
  const isOverPaid = !!event && payoutAmount < 0
  const isPaidOut = !!event && payoutAmount <= 0

  const [noPayoutPossible, seriousPayoutBlockers] = useMemo(() => {
    const adjustmentsFailed = (event?.adjustmentsStats?.failed || 0) > 0
    const adjustmentsInProgress =
      (event?.adjustmentsStats?.failed || 0) === 0 && (event?.adjustmentsStats?.notProcessed || 0) > 0

    const isOngoing = !event?.endDate || isFuture(parseISO(event.endDate))
    const promoterAccountIsBlocked = !event?.billingPromoter?.payoutsEnabled
    const noMoneyInAccount = !!event?.balance?.availability && event.balance.availability !== 'AVAILABLE'

    const isRemittanceCountry = isRemittanceCountryEvent(event, locale)
    const nowhereToSendRemittanceReport =
      isRemittanceCountry && (event?.billingPromoter?.remittanceRecipients?.length || 0) === 0

    const weirdThingsAreInProgress =
      adjustmentsInProgress || (!!selfPayoutBlockers?.stripe_account_inconsistent && !adjustmentsFailed)

    const noPayoutPossible =
      isProcessing ||
      isOngoing ||
      isPaidOut ||
      noMoneyInAccount ||
      promoterAccountIsBlocked ||
      nowhereToSendRemittanceReport ||
      weirdThingsAreInProgress ||
      !!selfPayoutBlockers?.hold_payouts ||
      !!selfPayoutBlockers?.event_postponed ||
      !!selfPayoutBlockers?.rolling_payments

    // prettier-ignore
    const seriousPayoutBlockers = noPayoutPossible
      ? compose([fromPairs, filter(([_, v]) => !!v), toPairs])({
        noMoneyInAccount,
        promoterAccountIsBlocked,
        nowhereToSendRemittanceReport,
        adjustmentsInProgress,
      }) as ISeriousBlockers
      : null

    return [noPayoutPossible, seriousPayoutBlockers] as const
  }, [
    event,
    isPaidOut,
    isProcessing,
    locale,
    selfPayoutBlockers?.event_postponed,
    selfPayoutBlockers?.hold_payouts,
    selfPayoutBlockers?.rolling_payments,
    selfPayoutBlockers?.stripe_account_inconsistent,
  ])

  const canDoAdminPayout = user.diceStaff && hasPermission('perform_payout:balances')
  const adminPayoutDisabled = noPayoutPossible || !canDoAdminPayout

  const canDoSelfPayout = hasPermission('read:balances') && event?.allowedActions?.performPayout
  const selfPayoutDisabled = noPayoutPossible || !canDoSelfPayout || !!selfPayoutBlockers

  return {
    adminPayoutDisabled,
    seriousPayoutBlockers,

    selfPayoutDisabled,
    selfPayoutBlockers,

    isPaidOut,
    isOverPaid,
    isProcessing,
    hasPendingPayouts,

    payoutAmount,
    payoutTotal,
  }
}

export default useEventPayout
