import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import Svg from '../../../components/Svg'
import { color, mediaQuery, zIndex } from '../../../utils/variables'

const Wrapper = styled.div<{ v2?: boolean }>`
  ${({ v2 }) =>
    v2 &&
    `
    position: sticky; 
    margin: -24px -16px 8px;
    top: -24px;
    background: ${color.white};
    z-index: ${zIndex.dropdown};
    padding: 24px 16px;
    box-shadow: 0px 10px 12px #ffffff;
  `}

  ${mediaQuery.lessThan<{ v2?: boolean }>('tablet')`
      ${({ v2 }) =>
    v2 &&
        `
        padding-top: 44px;
        margin-top: -44px;
        top: -44px;
      `}
    `}
`

const SidebarLogo = styled(Link)<{ v2?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ v2 }) =>
    !v2 &&
    `
    margin: 0 0 28px;
  `}

  &:hover {
    color: inherit;
  }
`

const SidebarLogoIconFan = styled(Svg)`
  position: absolute;
  width: 38px;
  flex: 38px 0 0;
  height: 38px;
  transition: all 0.15s ease-in-out;
  opacity: 0;
  ${mediaQuery.greaterThan('tablet')`
    body.compact-sidebar .sidebar:not(.expanded) & {
      opacity: 1;
      transition-delay: .2s;
    }
  `}
  ${mediaQuery.between('769px', '1200px')`
    .sidebar:not(.expanded) & {
      opacity: 1;
      transition-delay: .2s;
    }
  `}
`

const SidebarLogoIcon = styled(Svg)`
  width: 62px;
  flex: 62px 0 0;
  height: 36px;
  transition: all 0.2s ease-in;
  transition-delay: 0.15s;
  margin-left: 8px;
  opacity: 1;
  ${mediaQuery.greaterThan('tablet')`
    body.compact-sidebar .sidebar:not(.expanded) & {
      opacity: 0;
      transition-delay: 0.2s;
      transition: all 0.2s ease-out;
    }
  `}
  ${mediaQuery.between('769px', '1200px')`
    .sidebar:not(.expanded) & {
      opacity: 0;
      transition-delay: 0.2s;
      transition: all 0.2s ease-out;
    }
  `}
`

interface IProps {
  mobile?: boolean
  className?: string
  v2?: boolean
}

const Logo: FC<IProps> = ({ mobile, className, v2 }) => {
  return (
    <Wrapper v2={v2}>
      <SidebarLogo v2={v2} className={className} to="/">
        {!mobile && <SidebarLogoIconFan icon="fan" />}
        <SidebarLogoIcon icon="MIO" />
      </SidebarLogo>
    </Wrapper>
  )
}

export default styled(memo(Logo))``
