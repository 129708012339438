/**
 * @generated SignedSource<<41bec666b05c91d5e5ab0814c9a9848a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MarketeerWhereInput = {
  _or?: ReadonlyArray<MarketeerWhereInput | null> | null;
  id?: OperatorsIdEqInput | null;
  name?: OperatorsString | null;
};
export type OperatorsIdEqInput = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notIn?: ReadonlyArray<string | null> | null;
};
export type OperatorsString = {
  eq?: string | null;
  iLike?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
  neOrNull?: string | null;
  notLike?: string | null;
  null?: boolean | null;
};
export type useMarketeersQuery$variables = {
  where?: MarketeerWhereInput | null;
};
export type useMarketeersQuery$data = {
  readonly viewer: {
    readonly options: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly accessToken: string | null;
          readonly appOptInEnabled: boolean | null;
          readonly fbAccessToken: string | null;
          readonly fbPixelId: string | null;
          readonly gaTrackingId: string | null;
          readonly googleAdsConversionId: string | null;
          readonly googleAdsPurchaseConversionLabel: string | null;
          readonly id: string;
          readonly name: string | null;
          readonly privacyPolicyLink: string | null;
          readonly tiktokPixelId: string | null;
          readonly twitterCheckoutInitiatedPixelId: string | null;
          readonly twitterPixelId: string | null;
          readonly twitterPurchasePixelId: string | null;
          readonly webOptInEnabled: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useMarketeersQuery = {
  response: useMarketeersQuery$data;
  variables: useMarketeersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "options",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 50
    },
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "where"
    }
  ],
  "concreteType": "MarketeersConnection",
  "kind": "LinkedField",
  "name": "marketeers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MarketeersEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Marketeer",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessToken",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fbAccessToken",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fbPixelId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gaTrackingId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "googleAdsConversionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "googleAdsPurchaseConversionLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "privacyPolicyLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tiktokPixelId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitterCheckoutInitiatedPixelId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitterPixelId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitterPurchasePixelId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webOptInEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appOptInEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMarketeersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMarketeersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4b45888c520f885e6eff864c32e0c09",
    "id": null,
    "metadata": {},
    "name": "useMarketeersQuery",
    "operationKind": "query",
    "text": "query useMarketeersQuery(\n  $where: MarketeerWhereInput\n) {\n  viewer {\n    options: marketeers(where: $where, first: 50) {\n      edges {\n        node {\n          id\n          name\n          accessToken\n          fbAccessToken\n          fbPixelId\n          gaTrackingId\n          googleAdsConversionId\n          googleAdsPurchaseConversionLabel\n          privacyPolicyLink\n          tiktokPixelId\n          twitterCheckoutInitiatedPixelId\n          twitterPixelId\n          twitterPurchasePixelId\n          webOptInEnabled\n          appOptInEnabled\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfe8d710773b853d4806ef3dc37e9127";

export default node;
