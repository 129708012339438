/**
 * @generated SignedSource<<335a2bf1cc0e8f7911e8ce4bf8f4f302>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventPartSalesBreakdownQuery$variables = {
  id: string;
};
export type EventPartSalesBreakdownQuery$data = {
  readonly event: {
    readonly " $fragmentSpreads": FragmentRefs<"EventPartSalesBreakdown_event">;
  } | null;
};
export type EventPartSalesBreakdownQuery = {
  response: EventPartSalesBreakdownQuery$data;
  variables: EventPartSalesBreakdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventPartSalesBreakdownQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventPartSalesBreakdown_event"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventPartSalesBreakdownQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Sales",
                "kind": "LinkedField",
                "name": "sales",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TicketTypeBreakdown",
                    "kind": "LinkedField",
                    "name": "ticketTypesBreakdown",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TicketType",
                        "kind": "LinkedField",
                        "name": "ticketType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allocation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ticketPoolId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalAppSold",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalPosSold",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalWlRequests",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketPool",
                "kind": "LinkedField",
                "name": "ticketPools",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxAllocation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55add527e52d7f84eeedcf856945b6d2",
    "id": null,
    "metadata": {},
    "name": "EventPartSalesBreakdownQuery",
    "operationKind": "query",
    "text": "query EventPartSalesBreakdownQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ...EventPartSalesBreakdown_event\n    id\n  }\n}\n\nfragment EventPartSalesBreakdown_event on Event {\n  id\n  sales {\n    ticketTypesBreakdown {\n      ticketType {\n        id\n        name\n        allocation\n        ticketPoolId\n      }\n      totalAppSold\n      totalPosSold\n      totalWlRequests\n    }\n  }\n  ticketPools {\n    id\n    maxAllocation\n  }\n}\n"
  }
};
})();

(node as any).hash = "53b740b12eadfd6be3931481efe6f7ac";

export default node;
