import { createContext } from 'react'

// Although some of this could be fetched in EventNotesViewQuery,
//  This way it keeps up with live updates in the form
//  (ie. if CS agent changes the promoter or venue)
export interface INotesEvent {
  id?: string | null
  name?: string | null
  notes?: string | null
  extraNotes?: string | null
  billingPromoter?: {
    value: string
    tier?: string | null
  } | null
  primaryVenue?: {
    value: string
  } | null
}

const eventNotesContext = createContext<{
  notesOpen: boolean
  toggleNotesOpen: any
  promoterIds: string[]
  venueIds: string[]
  event: INotesEvent
  setPromoterIds: any
  setVenueIds: any
  setEvent: any
}>({
  notesOpen: false,
  toggleNotesOpen: () => {},
  promoterIds: [],
  venueIds: [],
  event: {},
  setPromoterIds: () => {},
  setVenueIds: () => {},
  setEvent: () => {},
})

export default eventNotesContext
