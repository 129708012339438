/**
 * @generated SignedSource<<eee2a7c6bdf1ad513ef3013393bacb17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VenueFeesWarningQuery$variables = {
  venueId: string;
};
export type VenueFeesWarningQuery$data = {
  readonly venue: {
    readonly contracts?: ReadonlyArray<{
      readonly effectiveDate: string | null;
      readonly id: string;
    } | null> | null;
  } | null;
};
export type VenueFeesWarningQuery = {
  response: VenueFeesWarningQuery$data;
  variables: VenueFeesWarningQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "venueId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "venueId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FeesConfiguration",
      "kind": "LinkedField",
      "name": "contracts",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "effectiveDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Venue",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VenueFeesWarningQuery",
    "selections": [
      {
        "alias": "venue",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VenueFeesWarningQuery",
    "selections": [
      {
        "alias": "venue",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67d984254c4e5e761f4761e7df7eedf6",
    "id": null,
    "metadata": {},
    "name": "VenueFeesWarningQuery",
    "operationKind": "query",
    "text": "query VenueFeesWarningQuery(\n  $venueId: ID!\n) {\n  venue: node(id: $venueId) {\n    __typename\n    ... on Venue {\n      contracts {\n        id\n        effectiveDate\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a496572bfdc2ee8e027f538645319a16";

export default node;
