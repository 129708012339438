import { useEffect } from 'react'
import { compact, take, uniq } from 'lodash/fp'

function addRecentlyViewed(id: string) {
  if (!id) return
  const recentlyViewedList = (window.localStorage.getItem('recently_viewed') || '').split(',')
  recentlyViewedList.unshift(id)
  const updatedList = take(5, uniq(compact(recentlyViewedList)))
  window.localStorage.setItem('recently_viewed', updatedList.join(','))
}

function useRecentlyViewed(eventId: string | null) {
  useEffect(() => {
    if (eventId) addRecentlyViewed(eventId)
  }, [eventId])
}

export default useRecentlyViewed
