/**
 * @generated SignedSource<<423c2ceefe3d787ea619df81303078bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type EventWorkflowForceRefetchQuery$variables = {
  id: string;
};
export type EventWorkflowForceRefetchQuery$data = {
  readonly event: {
    readonly billingNotes?: string | null;
    readonly lockVersion?: number;
    readonly state?: EventState | null;
    readonly statusAsOfNow?: string | null;
  } | null;
};
export type EventWorkflowForceRefetchQuery = {
  response: EventWorkflowForceRefetchQuery$data;
  variables: EventWorkflowForceRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingNotes",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventWorkflowForceRefetchQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventWorkflowForceRefetchQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e9cda70cb6ef6fd062b951fc1f34453",
    "id": null,
    "metadata": {},
    "name": "EventWorkflowForceRefetchQuery",
    "operationKind": "query",
    "text": "query EventWorkflowForceRefetchQuery(\n  $id: ID!\n) {\n  event: node(id: $id) {\n    __typename\n    ... on Event {\n      state\n      statusAsOfNow\n      lockVersion\n      billingNotes\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5d07922480ec0cce0c61e9aeb60bd3c";

export default node;
