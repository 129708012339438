import React, { memo, useCallback, useRef, FC, useState } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'

import { EditorState } from 'react-draft-wysiwyg'
import { useFormikContext } from 'formik'
import { compact, concat } from 'lodash/fp'
import IconButton from '../../../components/IconButton'
import EventFAQsModal from './EventFAQsModal'
import { textStyle } from '../../../utils/typography'
import Svg from '../../../components/Svg'
import Button from '../../../components/Button'
import IEventFormInformation from '../types/Information'
import { markdownToHtml, renderMarkdown } from '../../../utils/markdown'

const AddFaqButton = styled(IconButton)`
  margin-right: 16px;
`

const AddFaqLabel = styled.label`
  display: block;
  margin-top: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 250px;
`

export interface IFaq {
  title: string
  body: string
  bodyDraft: EditorState
}

interface IProps {
  faqs?: ReadonlyArray<IFaq> | null
  compact?: boolean
  allowEdit?: boolean
}

const Label = styled.div`
  ${textStyle.heading.xs}
`

const EmptyHint = styled.div`
  ${textStyle.functional.md}
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
`

const EmptyStateCard = styled.div`
  border: 2px solid #e6e6e6;
  border-radius: 4px;
  padding: 24px 32px;
  display: flex;
  flex-direction: row;
  gap: 27px;
  margin-top: 8px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const EmptyIcon = styled.div`
  background: #ccccff;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Summary = styled.summary`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &::marker,
  &::-webkit-details-marker {
    content: none;
    display: none;
  }
`

const StyledChevron = styled(Svg)`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transform-origin: 50% 50%;
  transform: rotate(0deg);
  transition: transform 100ms ease-out;
`

const ChevronWrapper = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Details = styled.details`
  padding: 26px 0;
  border-bottom: 1px solid rgba(229, 229, 229, 1);

  &[open] ${StyledChevron} {
    transform: rotate(-90deg);
  }
`

const AnswerBody = styled.div`
  padding-right: 60px;
  ${textStyle.functional.sm};
  margin-top: 8px;
`

const EventFaqs: FC<React.PropsWithChildren<IProps>> = ({ compact: isCompact, allowEdit }) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)

  const [modalOpen, setModalOpen] = useState(false)

  const {
    values: { faqs },
    setFieldValue,
  } = useFormikContext<IEventFormInformation>()

  const openModal = useCallback(() => {
    if (!faqs?.length) {
      const normalizedFaqs = compact(faqs)

      setFieldValue(
        'faqs',
        concat(normalizedFaqs, {
          title: '',
          body: '',
        })
      )
    }

    setModalOpen(true)
  }, [faqs, setFieldValue])

  const onClose = useCallback(() => setModalOpen(false), [])

  return (
    <div ref={ref}>
      <LabelRow>
        <Label>{intl.formatMessage({ id: 'new_event.information.faqs.label' })}</Label>
        {faqs?.length && allowEdit ? (
          <div>
            <Button onClick={openModal}>
              {intl.formatMessage({ id: 'new_event.information.faqs.edit_button.label' })}
            </Button>
          </div>
        ) : null}
      </LabelRow>
      {faqs?.length ? (
        <div>
          {faqs.map((faq) =>
            faq ? (
              <Details key={faq.id as string}>
                <Summary>
                  <span>{faq.title as string}</span>
                  <ChevronWrapper>
                    <StyledChevron width={16} height={16} icon="chevron-right" />
                  </ChevronWrapper>
                </Summary>
                <AnswerBody
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(
                      faq.bodyDraft
                        ? renderMarkdown(faq.bodyDraft as Draft.DraftModel.ImmutableData.EditorState)
                        : (faq.body as string)
                    ),
                  }}
                />
              </Details>
            ) : null
          )}
        </div>
      ) : allowEdit ? (
        <EmptyStateCard>
          <div>
            <EmptyIcon>
              <Svg icon="chat-information" />
            </EmptyIcon>
          </div>
          <div>
            <Label>{intl.formatMessage({ id: 'new_event.information.faqs.empty_state.label' })}</Label>
            <EmptyHint>{intl.formatMessage({ id: 'new_event.information.faqs.empty_state.hint' })}</EmptyHint>
          </div>
          <div>
            <Button onClick={openModal}>
              {intl.formatMessage({ id: 'new_event.information.faqs.empty_state.button.label' })}
            </Button>
          </div>
        </EmptyStateCard>
      ) : null}

      {modalOpen ? <EventFAQsModal onClose={onClose} onSave={onClose} readOnly={!allowEdit} /> : null}
    </div>
  )
}

export default memo(EventFaqs)
