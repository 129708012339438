/**
 * @generated SignedSource<<7456952e34a47cfc1ac62d19412b4cc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
export type UpdateEventStateInput = {
  clientMutationId: string;
  id: string;
  notify?: boolean | null;
  notifyOn?: string | null;
  state: EventState;
};
export type EventWorkflowMutation$variables = {
  input: UpdateEventStateInput;
};
export type EventWorkflowMutation$data = {
  readonly updateEventState: {
    readonly event: {
      readonly allowedLifecycleUpdates: {
        readonly venues: {
          readonly canUpdate: boolean | null;
        } | null;
      } | null;
      readonly state: EventState | null;
      readonly statusAsOfNow: string | null;
    } | null;
  } | null;
};
export type EventWorkflowMutation = {
  response: EventWorkflowMutation$data;
  variables: EventWorkflowMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusAsOfNow",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AllowedLifecycleUpdates",
  "kind": "LinkedField",
  "name": "allowedLifecycleUpdates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdateBase",
      "kind": "LinkedField",
      "name": "venues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventWorkflowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventStatePayload",
        "kind": "LinkedField",
        "name": "updateEventState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventWorkflowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventStatePayload",
        "kind": "LinkedField",
        "name": "updateEventState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40bd3ba66fd78b350827d671b6c329e3",
    "id": null,
    "metadata": {},
    "name": "EventWorkflowMutation",
    "operationKind": "mutation",
    "text": "mutation EventWorkflowMutation(\n  $input: UpdateEventStateInput!\n) {\n  updateEventState(input: $input) {\n    event {\n      state\n      statusAsOfNow\n      allowedLifecycleUpdates {\n        venues {\n          canUpdate\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6f0beb0a8444057536be7600cde2c5a";

export default node;
