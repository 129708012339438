import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { compact, getOr } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import SwitchField from '../../../components/SwitchField'
import { authContext } from '../../../context/auth'
import { font, color } from '../../../utils/variables'
import IEventFormSettings from '../types/Settings'
import { EventThirdPartyAccessQuery } from '../../../__generated__/EventThirdPartyAccessQuery.graphql'

const StyledFormRow = styled(FormRow)`
  ${FormRow} + && {
    margin-top: 16px;
  }
`

const Notice = styled.div`
  margin-top: 8px;
  font-size: ${font.size.sm}px;

  & > a {
    color: ${color.primary};
  }
`

interface IProps {
  readOnly?: boolean
}

const EventThirdPartyAccess: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()
  const { hasPermission } = useContext(authContext)

  const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IEventFormSettings>()

  const { viewer } = useLazyLoadQuery<EventThirdPartyAccessQuery>(
    graphql`
      query EventThirdPartyAccessQuery($promoterId: ID!) {
        viewer {
          thirdPartySettings(promoterId: $promoterId) {
            value: id
            label: appName
          }
        }
      }
    `,
    { promoterId: values.billingPromoter?.value || 'oops' },
    { fetchPolicy: values.billingPromoter?.value ? 'store-and-network' : 'store-only' }
  )

  const platforms = useMemo(() => compact(viewer?.thirdPartySettings || []), [viewer?.thirdPartySettings])

  const onChangePlatform = useCallback(
    (_id: any, value: any) => setFieldValue('thirdPartySettings', value),
    [setFieldValue]
  )

  const [claimTicketsAlert, setClaimTicketsAlert] = useState(false)

  const toggleClaimTickets = useCallback(() => {
    const isActive = getOr(false, 'flags.claimTickets.active', values)

    if (values.state !== 'DRAFT' && isActive) {
      setClaimTicketsAlert(true)
      return
    }

    setFieldValue('flags.claimTickets.active', !isActive)
  }, [values, setFieldValue])

  const confirmClaimTicketsAlert = useCallback(() => {
    setFieldValue('flags.claimTickets.active', false)
    setClaimTicketsAlert(false)
  }, [setFieldValue])

  const closeClaimTicketsAlert = useCallback(() => setClaimTicketsAlert(false), [])

  if (!hasPermission('allow_third_party_access:account')) return null

  const hasPlatforms = platforms.length > 0

  const accessEnabled = hasPlatforms && getOr(false, 'flags.claimTickets.active', values)

  return (
    <>
      <FormRow columnOnMobile>
        <div>
          <SwitchField
            label={intl.formatMessage({ id: 'new_event.settings.flags.claim_tickets.label' })}
            hint={intl.formatMessage({ id: 'new_event.settings.flags.claim_tickets.hint' })}
            name="flags.claimTickets.active"
            checked={accessEnabled}
            onChange={toggleClaimTickets}
            onBlur={handleBlur}
            disabled={readOnly || !hasPlatforms}
          />
          {!hasPlatforms && (
            <Notice>
              {intl.formatMessage(
                { id: 'new_event.settings.no_third_party_platforms_notice' },
                {
                  a: (str: string) => (
                    <Link to="/tools/integrations">
                      <strong>{str}</strong>
                    </Link>
                  ),
                }
              )}
            </Notice>
          )}
        </div>
      </FormRow>

      {accessEnabled && (
        <>
          <StyledFormRow columnOnMobile>
            <FormField
              name="thirdPartySettings"
              control="select"
              value={values.thirdPartySettings}
              options={platforms}
              disabled={readOnly || !hasPlatforms}
              onChange={onChangePlatform}
              onBlur={handleBlur}
              error={touched.thirdPartySettings && errors.thirdPartySettings}
            />
          </StyledFormRow>
        </>
      )}

      {claimTicketsAlert && (
        <ConfirmationModal
          icon="warning"
          title={intl.formatMessage({ id: 'confirmation.claim_tickets.title' })}
          description={intl.formatMessage({ id: 'confirmation.claim_tickets.description' })}
          onConfirm={confirmClaimTicketsAlert}
          onReject={closeClaimTicketsAlert}
        />
      )}
    </>
  )
}

export default memo(EventThirdPartyAccess)
