import React, { FC, HTMLProps, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { color } from '../utils/variables'
import Svg from './Svg'
import { TitleTooltip } from './Tooltip'

const ToggleTrack = styled.span`
  position: relative;
  display: block;
  width: 56px;
  height: 32px;
  border-radius: 32px;
  background: ${color.grey};
`
const ToggleHandle = styled.span`
  display: block;
  position: absolute;
  left: 4px;
  top: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${color.white};
  transition: all 0.2s ease-out;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px;
  }
`

const ToggleLabel = styled.label<{
  checked?: boolean
  color: keyof typeof color
  disabled?: boolean
  hasError?: boolean
}>`
  position: relative;
  display: inline-block;
  color: ${(props) => (props.hasError ? color.error : props.color ? color[props.color] : color.grey)};
  cursor: pointer;
  &:hover {
    ${ToggleTrack} {
      background: ${({ checked }) => (checked ? 'currentColor' : '#b2b2b2')};
    }
  }
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.4;
      cursor: not-allowed;
  `}
  ${({ checked }) =>
    checked &&
    `
    ${ToggleTrack} {
      background: currentColor;
      ${(props: { color: keyof typeof color }) => props.color === 'grey' && `background: ${color.primary};`}
    }
    ${ToggleHandle} {
      left: 100%;
      margin-left: -28px;
    }
  `}
`

const ToggleInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
`

const ToggleInner = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
`

export type IToggleProps = {
  className?: string
  color?: keyof typeof color
  disabled?: boolean
  hasError?: boolean
  title?: ReactNode
} & Omit<HTMLProps<HTMLInputElement>, 'ref' | 'as' | 'title'>

const Toggle: FC<React.PropsWithChildren<IToggleProps>> = ({
  className,
  checked,
  color = 'primary',
  disabled,
  hasError,
  title,
  ...inputProps
}) => {
  const visual = (
    <ToggleInner>
      <ToggleTrack>
        <ToggleHandle>{checked && <Svg icon="tick" width={16} height={16} />}</ToggleHandle>
      </ToggleTrack>
    </ToggleInner>
  )

  return (
    <ToggleLabel
      data-testid="toggleLabel"
      className={className}
      color={color}
      checked={!!checked}
      disabled={disabled}
      hasError={hasError}
    >
      <ToggleInput
        type="checkbox"
        checked={!!checked}
        disabled={disabled}
        data-checked={!!checked ? 'true' : 'false'}
        {...inputProps}
      />

      {title ? <TitleTooltip title={title}>{visual}</TitleTooltip> : visual}
    </ToggleLabel>
  )
}

export default Toggle
