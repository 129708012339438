import React, { FC, HTMLProps, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { font, color } from '../utils/variables'

type Wrapper = {
  valueFontSize?: string
  descriptionFontSize?: keyof typeof font.size
  textAlign?: string
  direction?: string
} & HTMLProps<HTMLDivElement>

const ValueWrapper = styled.div<Wrapper>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  text-align: ${({ textAlign }) => textAlign};
  & > small {
    white-space: nowrap;
    font-size: ${font.size.xs}px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  & > span {
    font-size: ${({ valueFontSize }) => (valueFontSize === 'small' ? '24px' : '48px')};
    margin-top: 6px;
  }
`

const CompactValueWrapper = styled.div<Wrapper>`
  display: block;
  text-align: ${({ textAlign }) => textAlign};
  & > small {
    font-size: ${({ descriptionFontSize }) => font.size[descriptionFontSize || 'sm']}px;
    color: ${color.darkgrey};
    margin-right: 4px;
    &:after {
      content: ':';
      display: inline;
    }
  }
`

interface IProps {
  className?: string
  valueFontSize?: string
  descriptionFontSize?: keyof typeof font.size
  label?: ReactNode
  value: ReactNode
  hint?: ReactNode
  compact?: boolean
  textAlign?: string
  direction?: 'column' | 'row'
}

const Hint = styled.div`
  color: ${color.darkgrey};
  font-size: ${font.size.sm}px;
  line-height: 125%;
  letter-spacing: 0.01em;
  margin-top: 4px;
`

const Value: FC<
  React.PropsWithChildren<Omit<HTMLProps<HTMLDivElement>, 'children' | 'ref' | 'as' | keyof IProps> & IProps>
> = ({ className, descriptionFontSize, valueFontSize, label, value, hint, compact, textAlign, ...props }) =>
  compact ? (
    <CompactValueWrapper
      {...props}
      className={className}
      textAlign={textAlign}
      descriptionFontSize={descriptionFontSize}
    >
      {label && <small>{label}</small>}
      <span>{value}</span>
      {hint && <Hint>{hint}</Hint>}
    </CompactValueWrapper>
  ) : (
    <ValueWrapper
      {...props}
      className={className}
      valueFontSize={valueFontSize}
      descriptionFontSize={descriptionFontSize}
      textAlign={textAlign}
    >
      {label && <small>{label}</small>}
      <span>{value}</span>
      {hint && <Hint>{hint}</Hint>}
    </ValueWrapper>
  )

Value.defaultProps = {
  valueFontSize: 'small',
  descriptionFontSize: 'sm',
  textAlign: 'left',
  compact: false,
  direction: 'column',
}

export default Value
