import React, { lazy } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import DiceGate from '../gates/DiceGate'

const PromotersPage = lazy(() => import(/* webpackChunkName: "promoters" */ '../../flows/Promoters/PromotersPage'))
const PromotersRestrictedPage = lazy(
  () => import(/* webpackChunkName: "promoters" */ '../../flows/PromotersRestricted/PromotersRestrictedPage')
)
const PromoterHeader = lazy(
  () => import(/* webpackChunkName: "promoters" */ '../../flows/Promoter/components/PromoterHeader')
)
const PromoterPage = lazy(() => import(/* webpackChunkName: "promoters" */ '../../flows/Promoter/PromoterPage'))
const PromoterActivityPage = lazy(() => import(/* webpackChunkName: "promoters" */ '../../flows/Activity/ActivityPage'))
const PromoterTimingsPage = lazy(() => import(/* webpackChunkName: "promoters" */ '../../flows/Timings/TimingsPage'))
const PromoterContactsPage = lazy(() => import(/* webpackChunkName: "promoters" */ '../../flows/Contacts/ContactsPage'))
const PromoterFeesPage = lazy(() => import(/* webpackChunkName: "promoters" */ '../../flows/Fees/FeesPage'))
const PromoterCreateContactPage = lazy(
  () => import(/* webpackChunkName: "promoters" */ '../../flows/CreateContact/CreateContactPage')
)
const PromoterCreateContractPage = lazy(
  () => import(/* webpackChunkName: "promoters" */ '../../flows/CreateContract/CreateContractPage')
)
const PromoterEventsPage = lazy(
  () => import(/* webpackChunkName: "promoters" */ '../../flows/EventList/EmbeddedEventListPage')
)
const PromoterAnalyticsPage = lazy(
  () => import(/* webpackChunkName: "promoters" */ '../../flows/PromoterAnalytics/PromoterAnalyticsPage')
)

const PromotersSection = () => (
  <Routes>
    <Route path="*" element={<DiceGate />}>
      <Route index element={<PromotersPage />} />
      <Route path="restricted" element={<PromotersRestrictedPage />} />

      <Route
        path="new"
        element={
          <>
            <PromoterHeader />
            <PromoterPage />
          </>
        }
      />

      <Route path=":id/contacts/new" element={<PromoterCreateContactPage />} />
      <Route path=":id/fees/new" element={<PromoterCreateContractPage />} />

      <Route
        path=":id"
        element={
          <>
            <PromoterHeader />
            <Outlet />
          </>
        }
      >
        <Route index element={<PromoterPage />} />
        <Route path="fees" element={<PromoterFeesPage />} />
        <Route path="contacts" element={<PromoterContactsPage />} />
        <Route path="activity" element={<PromoterActivityPage showEventBanner />} />
        <Route path="timings" element={<PromoterTimingsPage />} />
        <Route path="events" element={<PromoterEventsPage />} />
        <Route path="analytics" element={<PromoterAnalyticsPage />} />
      </Route>
    </Route>
  </Routes>
)

export default PromotersSection
