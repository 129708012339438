import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'
import PhoneInputLib, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import cn from 'classnames'
import styled from 'styled-components/macro'
import { map, uniq } from 'lodash/fp'

import { ITextInputProps } from './TextInput'
import { IFormGroupProps } from './FormGroup'
import { color, font, zIndex } from '../utils/variables'
import { OPERATING_COUNTRIES } from '../constants/operatingCountries'
import { localeContext } from '../context/locale'
import { PHONE_LOCALES } from '../intl'

export interface IPhoneInputProps extends Omit<PhoneInputProps, 'onChange'>, IFormGroupProps {
  id?: ITextInputProps['id']
  name?: ITextInputProps['name']
  onChange?: ITextInputProps['onChange']
  hasError?: boolean
  defaultCountry?: string
}

const StyledPhoneInput = styled.div`
  .react-tel-input {
    font-family: ${font.family.base};
    font-size: ${font.size.base}px;

    &:before {
      z-index: 1;
    }

    .flag-dropdown {
      border: none;
      background: none;
    }

    .flag-dropdown.open {
      width: 100%;

      .country-list {
        width: 100%;
        margin: 0;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
        z-index: ${zIndex.dropdown};
        border-radius: 8px;

        .country {
          padding: 8px 16px;

          .flag {
            margin-right: 16px;
          }
        }

        .country:hover {
          background-color: ${color.palegrey};
        }

        .dial-code.dial-code {
          color: ${color.darkgrey};
          float: right;
        }
      }
    }

    .selected-flag {
      padding-left: 16px;
    }

    .flag-dropdown.open .selected-flag,
    .selected-flag:hover,
    .selected-flag:focus {
      background: none;
    }

    .form-control {
      width: 100%;
      height: 40px;
      border: none;
      line-height: 1.4;
      letter-spacing: normal;
      font-size: ${font.size.base}px;
      background: none;
      border-radius: 0;
      padding-left: 56px;
    }
  }
`

const NewPhoneInput: FC<React.PropsWithChildren<IPhoneInputProps>> = ({
  id,
  className,
  name,
  value,
  placeholder,
  onBlur,
  onFocus,
  disabled,
  required,
  hasError,
  defaultCountry,
  onChange,
}) => {
  const { locale } = useContext(localeContext)
  const [focused, setFocused] = useState(false)

  const handleFocus = useCallback(
    (e: any) => {
      setFocused(true)
      if (onFocus) {
        onFocus(e, {})
      }
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    (e: any) => {
      setFocused(false)
      if (onBlur) {
        onBlur(e, {})
      }
    },
    [onBlur]
  )

  const inputProps = useMemo(
    () => ({
      id,
      name,
    }),
    [id, name]
  )

  const handleChange = useCallback(
    (v: any) => {
      const fakeE = {
        target: {
          id: id,
          name: name,
          value: v,
        },
      } as any

      if (onChange) onChange(fakeE)
    },
    [id, name, onChange]
  )

  const preferredCountries = useMemo(
    () =>
      uniq([
        defaultCountry?.toLowerCase() || 'gb',
        ...map((c: { alpha2: string }) => c.alpha2.toLowerCase(), OPERATING_COUNTRIES),
      ]),
    [defaultCountry]
  )

  return (
    <StyledPhoneInput>
      <PhoneInputLib
        country={defaultCountry?.toLowerCase()}
        inputProps={inputProps}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        localization={PHONE_LOCALES[locale]}
        preserveOrder={['preferredCountries']}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        inputClass={cn(className, 'text-input_input')}
        preferredCountries={preferredCountries}
        containerClass={cn('text-input', {
          '-focus': focused,
          '-disabled': disabled,
          '-required': required,
          '-has-error': hasError,
        })}
      />
    </StyledPhoneInput>
  )
}

export default memo(NewPhoneInput)
