import React, { useCallback, useContext, useMemo, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { concat, difference, includes, isArray } from 'lodash/fp'

import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../../../components/Dropdown'
import { Menu, MenuItem } from '../../../../../../components/Menu'
import Svg from '../../../../../../components/Svg'

import StatusCheckbox from '../StatusCheckbox'
import { FilterAdditionalItemDropdown } from '../../../../../../components/FilterStyles'
import { PROMOTER_TIERS } from '../../../../../../constants/promoterTiers'

function renderValue(value: string[] | string, intl: IntlShape) {
  const array = (isArray(value) ? value : value.split(',')) as Array<keyof typeof PROMOTER_TIERS>
  if (array.length === 0) return intl.formatMessage({ id: 'event_filter_bar.partner_tier' })
  if (array.length > 1) {
    return intl.formatMessage({ id: 'event_filter_bar.status.options' }, { number: array.length })
  }
  return intl.formatMessage({ id: PROMOTER_TIERS[array[0]]?.i18n })
}

const TierFilter = () => {
  const intl = useIntl()
  const { values, handleSubmit, setFieldValue } = useFormikContext<{
    promoterTiers: string[] | string
  }>()
  const [open, setOpen] = useState(false)

  const currentValue = useMemo(() => renderValue(values.promoterTiers, intl), [intl, values.promoterTiers])
  const onClickOutside = useCallback(() => open && setOpen(false), [open, setOpen])
  const toggleDropdown = useCallback(() => setOpen(!open), [open, setOpen])

  const toggleTier = useCallback(
    (key: any, checked: any) => {
      const array = isArray(values.promoterTiers) ? values.promoterTiers : values.promoterTiers.split(',')
      setFieldValue('promoterTiers', checked ? difference(array, [key]) : concat(key, array))
      handleSubmit()
    },
    [setFieldValue, values.promoterTiers, handleSubmit]
  )

  return (
    <FilterAdditionalItemDropdown
      as={Dropdown}
      className={!!values.promoterTiers?.length && '-active'}
      active={open}
      onClickOutside={onClickOutside}
    >
      <DropdownTrigger onClick={toggleDropdown}>
        <Svg icon="promoter" />
        <strong>{currentValue}</strong>
      </DropdownTrigger>
      <DropdownContent active={open}>
        <Menu>
          {Object.values(PROMOTER_TIERS).map((tier) => {
            const checked = includes(tier.key, values.promoterTiers)
            return (
              <MenuItem key={tier.key}>
                <StatusCheckbox status={tier} checked={checked} onChange={toggleTier} />
              </MenuItem>
            )
          })}
        </Menu>
      </DropdownContent>
    </FilterAdditionalItemDropdown>
  )
}

export default TierFilter
