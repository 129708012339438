import React, { FC, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { ConfirmationModal, IConfirmationModalProps } from './ConfirmationModal'
import usePageTransitionBlock from '../utils/hooks/usePageTransitionBlock'

interface IProps extends Omit<IConfirmationModalProps, 'onConfirm' | 'onReject' | 'title' | 'description'> {
  condition: boolean
  title?: string
  message?: string
}

const PageTransitionBlocker: FC<IProps> = ({ condition, icon, title, message, cta, rejectCta }) => {
  const intl = useIntl()
  const { hasModal, doUnblock, closeModal } = usePageTransitionBlock(condition)

  const onlyReject = !cta && rejectCta
  const bothCtas = !cta && !rejectCta

  // If only a reject CTA is provided, this will use the "confirm" button style
  //  (eg. the primary style), but do the onReject action
  const onConfirm = useMemo(() => {
    if (onlyReject) {
      return closeModal
    } else if (cta || bothCtas) {
      return doUnblock
    } else {
      return undefined
    }
  }, [bothCtas, closeModal, cta, doUnblock, onlyReject])

  const onReject = useMemo(() => {
    if (onlyReject) return undefined
    return rejectCta || bothCtas ? closeModal : undefined
  }, [bothCtas, closeModal, onlyReject, rejectCta])

  return !hasModal ? null : (
    <ConfirmationModal
      icon={icon}
      title={title || intl.formatMessage({ id: 'confirmation.leave_page.title' })}
      description={message || intl.formatMessage({ id: 'confirmation.leave_page.description' })}
      cta={onlyReject ? rejectCta : cta}
      rejectCta={rejectCta}
      onConfirm={onConfirm}
      onReject={onReject}
    />
  )
}

export default memo(PageTransitionBlocker)
