import { useCallback, useMemo, useContext } from 'react'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { concat, compact, compose, getOr, map, find, filter, reject, uniqBy, last, identity, flatMap } from 'lodash/fp'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { Environment } from 'relay-runtime'
import { usePromoters_viewer$data, usePromoters_viewer$key } from '../../../__generated__/usePromoters_viewer.graphql'
import IEventPromoters, { IPromoter } from '../types/Promoters'
import { getAlpha2ByName } from '../../../utils/countries'
import graphqlOptionsLoader from '../../../utils/graphqlOptionsLoader'
import { authContext } from '../../../context/auth'
import { allowedEventAction } from '../services/allowedEventAction'
import useDoorlistRecipients from './useDoorlistRecipients'
import IEventFormBasics from '../types/Basics'
import useCountryRestrictionsActive from './useCountryRestrictionsActive'

export const getPromoterLoader = (environment: Environment) =>
  graphqlOptionsLoader(
    environment,
    graphql`
      query usePromotersLoaderQuery($where: PromoterWhereInput) {
        viewer {
          options: promoters(where: $where, first: 50) {
            edges {
              node {
                id
                name
                isTest
                displayName
                stripeAccountId
                stripeFallbackAccountId
                stripeFallbackPlatformCode
                platformAccountCode
                showPriceSuggestions
                addressCountry
                countryCode
                accountId
                allowSkipReview
                resoldEnabled
                coolingOffPeriod
                coolingOffPeriodHours
                sendReceiptViaSms
                disableUsTax
                licenseNumber
                autoRescheduledEventRefunds {
                  active
                  cutoffDays
                }
                associatedMarketeers {
                  value: id
                  label: name
                }
                holdPayouts
                tags {
                  value: id
                  label: name
                }
                labels {
                  value: id
                  label: name
                }
                billingNotes
                fanSupportNotes {
                  body
                }
                tier

                defaultEventTimings {
                  offset
                  sourceField
                  targetField
                  type
                }

                salesforceFields {
                  defaultContract {
                    id
                    name
                    num
                    opportunityName
                    sfAccountId
                    sfId
                    startDate
                    status
                  }
                }
                eventDefaults {
                  taxFree
                  waitingList
                  ticketTransfer
                  restrictCountries
                  addArtistsToEvent
                  printedTicketFormat
                  restrictCountriesKind
                  disableDayOfEventComms
                  manualValidationEnabled
                  requiresBoxOfficeTicketNomination
                  disableAttractiveIntegration
                  hideFromDiscovery
                }
              }
            }
          }
        }
      }
    `
  )

export type IDestinationPromoter = NonNullable<
  NonNullable<NonNullable<NonNullable<usePromoters_viewer$data['destinationPromoters']>['edges']>[number]>['node']
>

export const useDestinationPromoters = (viewerKey: usePromoters_viewer$key) => {
  const { destinationPromoters: conn } = useFragment(
    graphql`
      fragment usePromoters_viewer on Viewer {
        destinationPromoters: promoters(
          first: 100
          where: { destinationFallback: { eq: true }, chargesEnabled: { eq: true }, payoutsEnabled: { eq: true } }
        ) {
          edges {
            node {
              isTest
              value: id
              label: name
              displayName
              stripeAccountId
              stripeFallbackAccountId
              stripeFallbackPlatformCode
              platformAccountCode
              addressCountry
              countryCode
              allowSkipReview
              resoldEnabled
              coolingOffPeriod
              coolingOffPeriodHours
              sendReceiptViaSms
              disableUsTax
              autoRescheduledEventRefunds {
                active
                cutoffDays
              }
              defaultEventTimings {
                offset
                sourceField
                targetField
                type
              }
              associatedMarketeers {
                value: id
                label: name
              }
              holdPayouts
              tags {
                value: id
                label: name
              }
              billingNotes
              fanSupportNotes {
                body
              }
              tier
              salesforceFields {
                defaultContract {
                  id
                  name
                  num
                  opportunityName
                  sfAccountId
                  sfId
                  startDate
                  status
                }
              }
            }
          }
        }
      }
    `,
    viewerKey
  )

  const destinationPromoters: IDestinationPromoter[] = useMemo(
    () => compose([filter('stripeAccountId'), compact, map('node'), getOr([], 'edges')])(conn),
    [conn]
  )

  return destinationPromoters
}

export const getFallbackPromoter = (
  destinationPromoters: IDestinationPromoter[],
  addressCountry: string | null,
  countryCode: string | null,
  forceId: string | null
) => {
  let fallbackPromoter: IDestinationPromoter | null = null
  if (addressCountry || countryCode || forceId) {
    fallbackPromoter =
      (find((dp) => {
        if (forceId) return dp.stripeAccountId === forceId

        if (dp.addressCountry === addressCountry || dp.countryCode === countryCode) return true

        const codeA = dp.countryCode || getAlpha2ByName(dp.addressCountry)
        const codeB = countryCode || getAlpha2ByName(addressCountry)
        return codeA && codeB && codeA === codeB
      }, destinationPromoters) as IDestinationPromoter) || null
  }
  if (!fallbackPromoter && destinationPromoters.length > 0) {
    fallbackPromoter = destinationPromoters[0]
  }
  return fallbackPromoter || null
}

const usePromoters = (viewerKey: usePromoters_viewer$key) => {
  const intl = useIntl()

  const destinationPromoters = useDestinationPromoters(viewerKey)
  const { user, hasPermission } = useContext(authContext)
  const [, setCountryRestrictionsActive] = useCountryRestrictionsActive()

  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<
    IEventPromoters & IEventFormBasics
  >()

  const setRecipients = useCallback((recList: any) => setFieldValue('doorlistRecipients', recList), [setFieldValue])
  const fetchDefaultDoorlistRecipients = useDoorlistRecipients(setRecipients)

  const billingPromoter = values.billingPromoter

  const isUnicornActive = getOr(false, 'flags.unicorn.active', values)

  const setBillingPromoter = useCallback(
    (_id: string, promoter: IPromoter) => {
      setFieldValue('billingPromoter', promoter)

      const oldBillingPromoterId = values.billingPromoter?.value
      const newPromoters = compose([
        compact,
        uniqBy('value'),
        concat([promoter]),
        oldBillingPromoterId ? reject(['value', oldBillingPromoterId]) : identity,
      ])(values.promoters || [])

      setFieldValue('promoters', newPromoters)

      setFieldTouched('billingPromoter', true, true)
      setFieldTouched('promoters', true, true)

      if (promoter.stripeAccountId) {
        setFieldValue('platformAccountCode', promoter.platformAccountCode || null)
        setFieldValue('stripeAccountId', promoter.stripeAccountId)
        setFieldTouched('stripeAccountId', true, true)
      } else {
        const fallbackPromoter = getFallbackPromoter(
          destinationPromoters,
          promoter.addressCountry,
          promoter.countryCode,
          promoter.stripeFallbackAccountId
        )
        if (fallbackPromoter?.stripeAccountId) {
          setFieldValue('platformAccountCode', fallbackPromoter.platformAccountCode || null)
          setFieldValue('stripeAccountId', fallbackPromoter.stripeAccountId)
          setFieldTouched('stripeAccountId', true, true)
        } else {
          setFieldValue('platformAccountCode', promoter.stripeFallbackPlatformCode || null)
          setFieldValue('stripeAccountId', promoter.stripeFallbackAccountId || null)
          setFieldTouched('stripeAccountId', true, true)
        }
      }

      setFieldValue('isTest', promoter.isTest ?? false)
      setFieldTouched('isTest', true, true)

      setFieldValue('disableUsTax', promoter.disableUsTax, true)

      setFieldValue(
        'flags.enabledPwl.active',
        isUnicornActive ? false : promoter.resoldEnabled || promoter.allowSkipReview || false
      )
      setFieldTouched('flags.enabledPwl.active', true, true)

      setFieldValue('flags.waitingList.active', promoter.eventDefaults?.waitingList ?? true)
      setFieldTouched('flags.waitingList.active', true, true)

      setFieldValue('flags.ticketTransfer.active', promoter.eventDefaults?.ticketTransfer ?? true)
      setFieldTouched('flags.ticketTransfer.active', true, true)

      setFieldValue('flags.disableDayOfEventComms.active', promoter.eventDefaults?.disableDayOfEventComms ?? false)
      setFieldTouched('flags.disableDayOfEventComms.active', true, true)

      setFieldValue('flags.hideFromDiscovery.active', promoter.eventDefaults?.hideFromDiscovery ?? false)
      setFieldTouched('flags.hideFromDiscovery.active', true, true)

      setFieldValue('manualValidationEnabled', promoter.eventDefaults?.manualValidationEnabled ?? false)
      setFieldTouched('manualValidationEnabled', true, true)

      setFieldValue(
        'requiresBoxOfficeTicketNomination',
        promoter.eventDefaults?.requiresBoxOfficeTicketNomination ?? false
      )
      setFieldTouched('requiresBoxOfficeTicketNomination', true, true)

      setFieldValue('attractiveFields.taxFree', promoter.eventDefaults?.taxFree ?? false)
      setFieldTouched('attractiveFields.taxFree', true, true)

      setFieldValue(
        'attractiveFields.integrationDisabled',
        promoter.eventDefaults?.disableAttractiveIntegration ?? false
      )
      setFieldTouched('attractiveFields.integrationDisabled', true, true)

      setFieldValue('printedTicketFormat', promoter.eventDefaults?.printedTicketFormat || 'BOCA_55X2')
      setFieldTouched('printedTicketFormat', true, true)

      const restrictCountries = promoter.eventDefaults?.restrictCountries || []

      setFieldValue('restrictCountries', restrictCountries)
      setFieldTouched('restrictCountries', true, true)

      setCountryRestrictionsActive(restrictCountries.length > 0)

      setFieldValue('restrictCountriesKind', promoter.eventDefaults?.restrictCountriesKind || 'ALLOW')
      setFieldTouched('restrictCountriesKind', true, true)

      setFieldValue('flags.coolingOffPeriod.active', promoter.coolingOffPeriod || false)
      setFieldTouched('flags.coolingOffPeriod.active', true, true)

      setFieldValue('flags.coolingOffPeriod.hours', promoter.coolingOffPeriodHours || 24)
      setFieldTouched('flags.coolingOffPeriod.hours', true, true)

      setFieldValue('flags.autoRescheduledEventRefunds.active', promoter.autoRescheduledEventRefunds?.active || false)
      setFieldTouched('flags.autoRescheduledEventRefunds.active', true, true)
      setFieldValue(
        'flags.autoRescheduledEventRefunds.cutoff_days',
        promoter.autoRescheduledEventRefunds?.cutoffDays || null
      )
      setFieldTouched('flags.autoRescheduledEventRefunds.cutoff_days', true, true)
      setFieldValue(
        'flags.autoRescheduledEventRefunds.cutoffDaysCustom',
        !!promoter.autoRescheduledEventRefunds?.cutoffDays || false
      )
      setFieldTouched('flags.autoRescheduledEventRefunds.cutoffDaysCustom', true, true)

      setFieldValue('sendReceiptViaSms', promoter.sendReceiptViaSms || false)

      // TODO: Cover with specific permission in future if needed
      if (hasPermission('manage_links:event') || !!values.allowedActions?.manageLinks || user.diceStaff) {
        setFieldValue('tags', uniqBy('value', concat(values.tags || [], promoter?.tags || [])))
      }

      setFieldValue('labels', uniqBy('value', concat(values.labels || [], promoter?.labels || [])))

      setFieldValue(
        'marketeers',
        compact(uniqBy('value', concat(values.marketeers || [], flatMap('associatedMarketeers', newPromoters))))
      )
      setFieldTouched('marketeers', true, true)

      setFieldValue('billingNotes', promoter.billingNotes || null)

      setFieldValue('salesforceContract', promoter.salesforceFields?.defaultContract || null)

      if (
        (promoter?.displayName || promoter?.label) &&
        allowedEventAction(values.allowedLifecycleUpdates, 'presentedBy')
      ) {
        setFieldValue(
          'presentedBy',
          intl
            .formatMessage(
              { id: 'event_defaults.presented_by' },
              { name: promoter?.displayName || promoter?.label || '' }
            )
            .replace(/ \.$/, '')
        )
        setFieldTouched('presentedBy', true, true)
      }

      if (user.diceStaff) {
        fetchDefaultDoorlistRecipients([...map('value', newPromoters), ...map('value', values.venues)])
      }

      setTimeout(() => validateForm(), 0)
    },
    [
      destinationPromoters,
      fetchDefaultDoorlistRecipients,
      hasPermission,
      intl,
      setCountryRestrictionsActive,
      setFieldTouched,
      setFieldValue,
      user.diceStaff,
      validateForm,
      values.allowedActions?.manageLinks,
      values.allowedLifecycleUpdates,
      values.billingPromoter?.value,
      values.labels,
      values.promoters,
      values.tags,
      values.venues,
      values.marketeers,
      isUnicornActive,
    ]
  )

  const stripeAccountOptions = useMemo(
    () =>
      compose([
        map((dp: IDestinationPromoter) => ({
          value: dp.stripeAccountId,
          label: dp.label,
          platformAccountCode: dp.platformAccountCode,
        })),
        filter('stripeAccountId'),
        concat([billingPromoter]),
      ])(destinationPromoters),
    [billingPromoter, destinationPromoters]
  )

  const stripeAccountValue = useMemo(
    () =>
      values.stripeAccountId && {
        value: values.stripeAccountId,
        label: find(['value', values.stripeAccountId], stripeAccountOptions)?.label || `??? ${values.stripeAccountId}`,
        platformAccountCode: values.platformAccountCode,
      },
    [stripeAccountOptions, values.platformAccountCode, values.stripeAccountId]
  )

  const setStripeAccount = useCallback(
    (id: any, option: any) => {
      setFieldValue('stripeAccountId', id)
      if (option.platformAccountCode) {
        setFieldValue('platformAccountCode', option.platformAccountCode)
      }
    },
    [setFieldValue]
  )

  const nonBillingPromoters = useMemo(
    () => reject(['value', billingPromoter?.value], values.promoters || []),
    [billingPromoter?.value, values.promoters]
  )

  const setNonBillingPromoters = useCallback(
    (_ids: string[] | null, selection: Array<IPromoter> | null) => {
      const newPromoters = compact(uniqBy('value', concat([billingPromoter], selection || [])))

      setFieldValue('promoters', newPromoters)
      setFieldTouched('promoters', true, true)

      setFieldValue(
        'marketeers',
        compact(uniqBy('value', concat(values.marketeers || [], flatMap('associatedMarketeers', newPromoters))))
      )
      setFieldTouched('marketeers', true, true)

      // TODO: Cover with specific permission in future if needed
      if (hasPermission('manage_links:event') || !!values.allowedActions?.manageLinks || user.diceStaff) {
        setFieldValue('tags', uniqBy('value', concat(values.tags || [], last(selection)?.tags || [])))
      }

      if (user.diceStaff) {
        fetchDefaultDoorlistRecipients([...map('value', newPromoters), ...map('value', values.venues)])
      }

      setTimeout(() => validateForm(), 0)
    },
    [
      billingPromoter,
      fetchDefaultDoorlistRecipients,
      hasPermission,
      setFieldTouched,
      setFieldValue,
      user.diceStaff,
      validateForm,
      values.allowedActions?.manageLinks,
      values.tags,
      values.venues,
      values.marketeers,
    ]
  )

  return {
    billingPromoter,
    setBillingPromoter,
    stripeAccountValue,
    stripeAccountOptions,
    setStripeAccount,
    nonBillingPromoters,
    setNonBillingPromoters,
  }
}

export default usePromoters
