import { HTMLProps } from 'react'
import styled from 'styled-components/macro'
import { color, font, input } from '../utils/variables'

const MIN_WIDTHS = {
  wide: 280,
  normal: 140,
  narrow: 5,
  auto: 0,
}

export const ScrollableTable = styled.div`
  position: relative;
  overflow-x: auto;
`

export const ScrollableTableInner = styled.div`
  position: relative;
  /* should be inline-block for right spacing */
  display: inline-block;
  min-width: 100%;
`

const Table = styled.table`
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse;
  font-size: ${font.size.sm}px;
  line-height: 17px;
`

export const TableHeader = styled.thead``
export const TableBody = styled.tbody``
export const TableFooter = styled.tfoot``

export const Row = styled.tr``

interface ICellProps {
  wrapText?: boolean
  textAlign?: string
  verticalAlign?: string
  bold?: boolean
  width?: keyof typeof MIN_WIDTHS
}

// eslint-disable-next-line
export const Cell = styled.td<ICellProps & HTMLProps<HTMLTableCellElement>>`
  vertical-align: ${({ verticalAlign }) => verticalAlign || 'top'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  ${({ wrapText }) => (!wrapText ? 'white-space: nowrap;' : 'white-space: normal;')}
  padding: 12px 8px 12px 8px;
`

interface IHeaderCellProps {
  width?: keyof typeof MIN_WIDTHS
  wrapText?: boolean
  textAlign?: string
}

export const HeaderCell = styled.th<IHeaderCellProps & HTMLProps<HTMLTableHeaderCellElement>>`
  vertical-align: top;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  border-bottom: 1px solid ${color.grey};
  color: ${color.darkgrey};
  font-weight: normal;
  ${({ wrapText }) => (!wrapText ? 'white-space: nowrap;' : 'white-space: normal;')}
  padding: 8px;
  ${({ width }) => width === 'narrow' && 'width: 5px;'}
  min-width: ${({ width }) => MIN_WIDTHS[width || 'normal']}px;
`

export const ColumnExpanderButton = styled.div<{ isExpanded: boolean }>`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 21px;
  height: 21px;
  background: ${color.palegrey};
  border-radius: 4px;
  text-align: center;
  margin: -2px -8px 0 4px;
  cursor: pointer;
  z-index: 2;
  svg {
    color: ${color.black};
    opacity: 0.5;
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-top: 2px;
    ${({ isExpanded }) => isExpanded && 'transform: rotate(-180deg);'}
  }
  &:hover {
    background-color: ${color.lightgrey};
  }
`

export const ExpandedHeader = styled.span<{ isLast?: boolean }>`
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 2px 0;
  margin: 10px 0;
  background: ${color.palegrey};
  ${({ isLast }) =>
    isLast &&
    `
    border-top-right-radius: ${input.borderRadius}px;
    border-bottom-right-radius: ${input.borderRadius}px;
  `}
`

export const Faint = styled.span`
  opacity: 0.5;
  color: ${color.black};
`

export default Table
