import { useCallback, useContext, useMemo, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { compact, concat, findIndex, map, sortBy, find, compose, filter } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { nanoid } from 'nanoid'

import { useLabelsQuery } from '../../__generated__/useLabelsQuery.graphql'
import { isClientOnly, markAsClientOnly } from '../entityStatus'
import { useLabelsCreateMutation } from '../../__generated__/useLabelsCreateMutation.graphql'
import { notificationContext } from '../../context/notification'

export type ILabelKind = 'promoter' | 'venue' | 'event'

interface ILabelConfigItem {
  name: string
  description: string
  kind: ILabelKind
}

// Labels are legacy mechanism of setting arbitrary flags on event / venue / promoter label
// We're supporting very limited set as of now, and should not add any in the future
// Descriptions are in English as those are fallback values, proper i18n descriptions should be in Phrase
export const SUPPORTED_LABELS: ReadonlyArray<ILabelConfigItem> = [
  { kind: 'promoter', name: 'AEG PIMS', description: 'AEG PIMS integration' },
  { kind: 'venue', name: 'COBO', description: 'Tickets to be collected at box office' },
  {
    kind: 'venue',
    name: 'Barcode Upload',
    description: 'Venue to upload DICE barcodes for a per transaction / per ticket fee',
  },
  { kind: 'venue', name: 'Postal Fulfilment', description: '' },
  { kind: 'event', name: 'EXCLUSIVE', description: 'Tickets are only available via DICE for this event.' },

  {
    kind: 'event',
    name: 'Post Live Checkpoint',
    description: 'Any events put live in order to send a link to a partner need to be checked over by PS',
  },
  {
    kind: 'venue',
    name: 'DICE Venue',
    description:
      // eslint-disable-next-line max-len
      'This label is used for revenue reporting by source and also to indicate whether the event partner is DICE Venue / an event is at DICE venue.',
  },
  {
    kind: 'promoter',
    name: 'ALLOCATION',
    description:
      // eslint-disable-next-line max-len
      'Used for revenue source reporting for when the event partner or event is general allocation (not primary, festival, artist, DICE venue or brand).',
  },
  {
    kind: 'promoter',
    name: 'BRAND',
    description: 'Used for revenue source reporting for when the event partner is brand.',
  },
  {
    kind: 'promoter',
    name: 'ARTIST',
    description:
      'Used for revenue source reporting for when the event partner or event is sourced directly from the artist.',
  },
  {
    kind: 'promoter',
    name: 'PRIMARY',
    description: 'Used for revenue source reporting for when the event partner is primary.',
  },
]

const KIND_MAP = new Map<string, ILabelKind>(map((sl) => [sl.name, sl.kind], SUPPORTED_LABELS))
const DESCRIPTION_MAP = new Map<string, string>(map((sl) => [sl.name, sl.description], SUPPORTED_LABELS))

interface ILabel {
  id: string
  name: string
  kind: ILabelKind
  description?: string | null
}

function useLabels(kind: ILabelKind | undefined, promoterName?: string | null) {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)

  const [fetchKey, setFetchKey] = useState('initial')

  const { viewer } = useLazyLoadQuery<useLabelsQuery>(
    graphql`
      query useLabelsQuery($count: Int!, $names: [String]!) {
        viewer {
          labels(first: $count, where: { name: { in: $names } }) {
            edges {
              node {
                id
                name
                description
              }
            }
          }
        }
      }
    `,
    {
      count: SUPPORTED_LABELS.length,
      names: map('name', SUPPORTED_LABELS),
    },
    { fetchPolicy: 'store-and-network', fetchKey }
  )

  const foundLabelOptions: Array<ILabel> = useMemo(
    () =>
      compact(
        map(
          (edge) =>
            edge?.node && {
              id: edge.node.id,
              name: edge.node.name,
              description: edge.node.description?.trim() || DESCRIPTION_MAP.get(edge.node.name),
              kind: KIND_MAP.get(edge.node.name) || 'event',
            },
          viewer?.labels?.edges || []
        )
      ),
    [viewer?.labels?.edges]
  )

  const missingLabelOptions: Array<ILabel> = useMemo(() => {
    const foundSet = new Set(map('name', foundLabelOptions))

    return compact(
      map(
        ({ name }) => {
          if (foundSet.has(name)) return null

          return markAsClientOnly<ILabel>({
            name,
            description: DESCRIPTION_MAP.get(name),
            kind: KIND_MAP.get(name) || 'event',
          })
        },

        SUPPORTED_LABELS
      )
    )
  }, [foundLabelOptions])

  const labelOptions: Array<ILabel> = useMemo(
    () =>
      compose([
        filter((opt: ILabel) => opt.name !== 'AEG PIMS' || promoterName === 'AEG Presents France'),
        filter((opt: ILabel) => !kind || opt.kind === kind),
        sortBy([isClientOnly, (opt: ILabel) => findIndex(({ name }) => name === opt.name, SUPPORTED_LABELS)]),
      ])(concat(foundLabelOptions, missingLabelOptions)),
    [foundLabelOptions, kind, missingLabelOptions, promoterName]
  )

  const [commitCreateLabel, creating] = useMutation<useLabelsCreateMutation>(graphql`
    mutation useLabelsCreateMutation($input: CreateLabelInput!) {
      createLabel(input: $input) {
        label {
          id
          name
          description
        }
      }
    }
  `)

  const doCreateLabel = useCallback(
    (name: string, descriptionRaw: string | null) => {
      const description = descriptionRaw || find(({ name: nm }) => nm === name, SUPPORTED_LABELS)?.description

      return new Promise<string>((resolve, reject) => {
        commitCreateLabel({
          variables: {
            input: {
              clientMutationId: nanoid(),
              name,
              description,
            },
          },
          onCompleted(data, errs) {
            const id = data.createLabel?.label?.id
            if (errs && errs.length > 0) {
              errs.forEach((e) => addNotification('error', e.message))
              reject()
              return
            }

            if (!id) {
              addNotification('error', intl.formatMessage({ id: 'new_event.settings.labels.create_error' }))
              reject()
              return
            }

            addNotification('success', intl.formatMessage({ id: 'new_event.settings.labels.create_success' }))
            resolve(id)

            setFetchKey(nanoid())
          },
          onError(e) {
            addNotification('error', e.message || intl.formatMessage({ id: 'new_event.settings.labels.create_error' }))
            reject(e)
          },
        })
      })
    },
    [commitCreateLabel, addNotification, intl]
  )

  return [labelOptions, doCreateLabel, creating] as const
}

export default useLabels
