import { object, string, array, boolean, number, ValidationError } from 'yup'

import { getOr } from 'lodash/fp'
import { ISO_DATE_REGEX } from '../../../utils/calendar'
import { COOLING_OFF_PERIOD_HOURS_LIMITS } from '../../../constants/settings'

const MIN_LOGO_WIDTH = 128
const MIN_LOGO_HEIGHT = 128

const DictSchema = object().shape({
  value: string().required(),
  label: string(),
})

const FlagSchema = object().nullable().optional().shape({
  active: boolean().nullable().optional(),
})

const LoadPredictionsSchema = object()
  .nullable()
  .shape({
    id: string().nullable(),
    expectedRequestsPerMinute: number().required().nullable(false).min(1).max(10000),
    expectedStartTime: string().required().nullable(false),
  })

const FlagsSchema = object()
  .nullable()
  .optional()
  .shape({
    hidden: FlagSchema,
    coolingOffPeriod: object().nullable().optional().shape({
      active: boolean().nullable().optional(),
      hours: number().integer().nullable().optional(),
    }),
  })

const SettingsSchema = object()
  .shape({
    marketeers: array().nullable().of(DictSchema),
    labels: array().nullable().of(DictSchema),
    links: array()
      .nullable()
      .of(object().shape({ url: string().required(), name: string().required() })),
    eventSharingObjects: array()
      .nullable()
      .of(
        object()
          .nullable()
          .required()
          .shape({
            id: string().nullable(),
            email: string().nullable().required().email(),
            permissionProfile: object().nullable().required().shape({
              id: string().nullable().required(),
            }),
          })
      ),
    doorlistAdditionalRecipients: array().nullable().of(string()),
    printedTicketFormat: string(),
    flags: FlagsSchema,
    recurrentEventSchedule: object()
      .nullable()
      .shape({
        frequency: string().nullable(),
        repeatOn: string().nullable(),
        repeatEnds: string().nullable(),
        until: string().nullable().matches(ISO_DATE_REGEX),
        occurrences: number().min(2).nullable(),
      }),
    eventLoadPredictions: array().nullable().of(LoadPredictionsSchema),
  })
  .test('rawBrandLogo', 'Event should have properly sized image', function (values) {
    const imgCrop = values.rawBrandLogo?.cropRegion

    if (imgCrop) {
      if (imgCrop.width >= MIN_LOGO_WIDTH && imgCrop.height >= MIN_LOGO_HEIGHT) {
        return true
      } else if (imgCrop.width < MIN_LOGO_WIDTH && imgCrop.height < MIN_LOGO_HEIGHT) {
        return this.createError({ path: 'rawBrandLogo.size', message: 'event_images.too_small.both' })
      }

      const error = new ValidationError(' ', values, 'rawBrandLogo')
      error.inner = []
      if (imgCrop.width < MIN_LOGO_WIDTH) {
        error.inner.push(this.createError({ path: 'rawBrandLogo.width', message: 'event_images.too_small.width' }))
      }
      if (imgCrop.height < MIN_LOGO_HEIGHT) {
        error.inner.push(this.createError({ path: 'rawBrandLogo.height', message: 'event_images.too_small.height' }))
      }

      return error
    }

    return true
  })
  .test('coolingOffPeriodHours', 'Cooling off period must be between 1 and 168 hours', function (values) {
    const active = getOr(false, 'flags.coolingOffPeriod.active', values)
    const hours = getOr(0, 'flags.coolingOffPeriod.hours', values)

    if (!active) return true

    if (hours >= COOLING_OFF_PERIOD_HOURS_LIMITS.min && hours <= COOLING_OFF_PERIOD_HOURS_LIMITS.max) return true

    return this.createError({
      path: 'flags.coolingOffPeriod.hours',
      message: 'new_event.settings.flags.cooling_off_period.hours.hint',
    })
  })

export default SettingsSchema
