/**
 * @generated SignedSource<<bfefd3475e6e533ca8060d08bff19ab9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CategoryType = "ACCESS_PASS" | "AFTER_PARTY_PASS" | "ARTIST_MEET_AND_GREET" | "CAMPING" | "COACH_BUS" | "DRINKS_ALCOHOLIC" | "DRINKS_NON_ALCOHOLIC" | "EARLY_ENTRY_PASS" | "EXPERIENCE" | "FOOD" | "FOOD_AND_DRINK" | "JUMPER" | "MERCH" | "OTHER" | "PARKING" | "QUEUE_JUMP" | "TRAVEL_AND_ACCOMMODATION" | "T_SHIRT" | "VIP_UPGRADE" | "%future added value";
export type EventCostCurrency = "AED" | "AFN" | "ALL" | "AMD" | "AOA" | "ARS" | "AUD" | "AWG" | "AZN" | "BAM" | "BBD" | "BDT" | "BGN" | "BHD" | "BIF" | "BMD" | "BND" | "BOB" | "BRL" | "BWP" | "BYR" | "BZD" | "CAD" | "CDF" | "CHF" | "CLP" | "CNY" | "COP" | "CRC" | "CVE" | "CZK" | "DJF" | "DKK" | "DOP" | "DZD" | "EGP" | "ERN" | "ETB" | "EUR" | "GBP" | "GEL" | "GHS" | "GNF" | "GTQ" | "GYD" | "HKD" | "HNL" | "HRK" | "HUF" | "IDR" | "ILS" | "INR" | "IQD" | "IRR" | "ISK" | "JMD" | "JOD" | "JPY" | "KES" | "KHR" | "KMF" | "KRW" | "KWD" | "KZT" | "LBP" | "LKR" | "LRD" | "LTL" | "LVL" | "LYD" | "MAD" | "MDL" | "MGA" | "MKD" | "MMK" | "MOP" | "MUR" | "MXN" | "MYR" | "MZN" | "NAD" | "NGN" | "NIO" | "NOK" | "NPR" | "NZD" | "OMR" | "PAB" | "PEN" | "PHP" | "PKR" | "PLN" | "PYG" | "QAR" | "RON" | "RSD" | "RUB" | "RWF" | "SAR" | "SDG" | "SEK" | "SGD" | "SOS" | "STD" | "SYP" | "THB" | "TND" | "TOP" | "TRY" | "TTD" | "TWD" | "TZS" | "UAH" | "UGX" | "USD" | "UYU" | "UZS" | "VEF" | "VND" | "XAF" | "XOF" | "YER" | "ZAR" | "ZMK" | "%future added value";
export type ProductOptionType = "CUSTOM" | "SIZE" | "%future added value";
export type ProductRootType = "EXTRAS" | "MERCH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductsBreakdown_event$data = {
  readonly allowedActions: {
    readonly minorUpdate: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly products: {
      readonly canAdd: boolean | null;
      readonly canUpdate: boolean | null;
    } | null;
  } | null;
  readonly announceDate: string | null;
  readonly costCurrency: EventCostCurrency | null;
  readonly date: string | null;
  readonly eventIdLive: string | null;
  readonly id: string;
  readonly offSaleDate: string | null;
  readonly onSaleDate: string | null;
  readonly productsSales: {
    readonly productBreakdown: ReadonlyArray<{
      readonly product: {
        readonly allocation: number;
        readonly archived: boolean;
        readonly category: {
          readonly parentCategory: {
            readonly name: string;
            readonly type: CategoryType | null;
          } | null;
        };
        readonly faceValue: number;
        readonly hasVariants: boolean;
        readonly id: string;
        readonly name: string;
        readonly offSaleDate: string | null;
        readonly onSaleDate: string | null;
        readonly optionType: ProductOptionType | null;
        readonly price: number | null;
        readonly priceBreakdown: {
          readonly faceValue: number;
          readonly total: number;
          readonly totalWithPwl: number | null;
          readonly totalWithoutPwl: number | null;
        } | null;
        readonly rootType: ProductRootType;
        readonly ticketTypes: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null;
        } | null>;
        readonly variants: ReadonlyArray<{
          readonly allocation: number;
          readonly id: string;
          readonly name: string;
        } | null>;
      };
      readonly totalDigitalValue: number;
      readonly totalFaceValue: number;
      readonly totalSold: number;
      readonly variantBreakdown: ReadonlyArray<{
        readonly totalDigitalValue: number;
        readonly totalFaceValue: number;
        readonly totalSold: number;
        readonly variant: {
          readonly allocation: number;
          readonly id: string;
          readonly name: string;
          readonly product: {
            readonly allocation: number;
            readonly id: string;
            readonly name: string;
          };
        } | null;
        readonly variantId: string;
      } | null> | null;
    } | null> | null;
  } | null;
  readonly ticketTypes: ReadonlyArray<{
    readonly doorSalesEnabled: boolean;
    readonly id: string;
  } | null> | null;
  readonly timezoneName: string | null;
  readonly " $fragmentType": "ProductsBreakdown_event";
};
export type ProductsBreakdown_event$key = {
  readonly " $data"?: ProductsBreakdown_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductsBreakdown_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onSaleDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offSaleDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalDigitalValue",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalFaceValue",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSold",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "rootType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductsBreakdown_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesProducts",
          "kind": "LinkedField",
          "name": "products",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canAdd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "announceDate",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductsSales",
      "kind": "LinkedField",
      "name": "productsSales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "rootType",
              "variableName": "rootType"
            }
          ],
          "concreteType": "ProductBreakdownItem",
          "kind": "LinkedField",
          "name": "productBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VariantBreakdownItem",
              "kind": "LinkedField",
              "name": "variantBreakdown",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "variantId",
                  "storageKey": null
                },
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Variant",
                  "kind": "LinkedField",
                  "name": "variant",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Product",
                      "kind": "LinkedField",
                      "name": "product",
                      "plural": false,
                      "selections": (v8/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rootType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Category",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Category",
                      "kind": "LinkedField",
                      "name": "parentCategory",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                (v7/*: any*/),
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TicketType",
                  "kind": "LinkedField",
                  "name": "ticketTypes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasVariants",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "optionType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Variant",
                  "kind": "LinkedField",
                  "name": "variants",
                  "plural": true,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "priceBreakdown",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalWithPwl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalWithoutPwl",
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v4/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "21ef84181c14d24716c91409a68973d9";

export default node;
