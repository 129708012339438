import { useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { find, set, concat, reject, compose, findIndex, update, unset } from 'lodash/fp'
import { useFormikContext } from 'formik'

import { getDefaultProduct } from '../services/getDefaultEvent'
import { localeContext } from '../../../context/locale'
import { isSaved, markAsNew } from '../../../utils/entityStatus'
import IEventFormExtras from '../types/Extras'
import { IProduct } from './../types/Extras'

interface ICtx {
  onAdd: (id: string) => void
}

function useEventProduct(allProducts: IProduct[], ctx: ICtx) {
  const intl = useIntl()

  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormExtras>()

  const { locale } = useContext(localeContext)

  const { onAdd } = ctx

  const addProduct = useCallback(() => {
    const newProduct =
      allProducts.length === 0
        ? getDefaultProduct(values as any, intl, locale)
        : set('name', '', getDefaultProduct(values as any, intl, locale))

    setFieldValue('products', concat(allProducts, newProduct))

    setTimeout(() => onAdd(newProduct.id), 0)
  }, [allProducts, values, intl, locale, setFieldValue, onAdd])

  const copyProduct = useCallback(
    (copiedId: any) => {
      const product = find(['id', copiedId], allProducts)
      if (product) {
        const newProduct = compose([set('name', ''), markAsNew])(product)

        setFieldValue('products', concat(allProducts, newProduct))
        setTimeout(() => onAdd(newProduct.id), 0)
      }
    },
    [allProducts, setFieldValue, onAdd]
  )

  const removeProduct = useCallback(
    (removedId: any) => {
      if (values.state === 'DRAFT' || !isSaved({ id: removedId })) {
        setFieldValue('products', reject(['id', removedId], allProducts))
        setFieldTouched('products', true, true)
        setTimeout(() => validateForm(), 0)
      } else {
        const removedIdx = findIndex(['id', removedId], allProducts)
        const updatedProducts = update(
          [removedIdx],
          compose([set('archived', true), set('__justArchived', true)]),
          allProducts
        )

        setFieldValue('products', updatedProducts)
        setFieldTouched('products', true, true)
        setTimeout(() => validateForm(), 0)
      }
    },
    [values.state, setFieldValue, allProducts, setFieldTouched, validateForm]
  )

  const restoreProduct = useCallback(
    (restoredId: any) => {
      const restoredIdx = findIndex(['id', restoredId], allProducts)
      const updatedProducts = update(
        [restoredIdx],
        compose([set('archived', false), unset('__justArchived')]),
        allProducts
      )

      setFieldValue('products', updatedProducts)
      setFieldTouched('products', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [allProducts, setFieldValue, setFieldTouched, validateForm]
  )

  return { addProduct, copyProduct, removeProduct, restoreProduct }
}

export default useEventProduct
