/**
 * @generated SignedSource<<6bde77e6c3c8c9fa109878d5028a7797>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EventReviewStatus = "ESCALATED" | "ON_HOLD" | "%future added value";
export type UpdateEventReviewInput = {
  assigneeId?: string | null;
  clientMutationId: string;
  eventId: string;
  priority?: boolean | null;
  status?: EventReviewStatus | null;
};
export type useAssignEventReviewMutation$variables = {
  input: UpdateEventReviewInput;
};
export type useAssignEventReviewMutation$data = {
  readonly updateEventReview: {
    readonly messages: ReadonlyArray<{
      readonly message: string | null;
    } | null> | null;
    readonly result: {
      readonly assignee: {
        readonly email: string | null;
        readonly firstName: string | null;
        readonly id: string;
        readonly lastName: string | null;
      } | null;
    } | null;
    readonly successful: boolean;
  } | null;
};
export type useAssignEventReviewMutation = {
  response: useAssignEventReviewMutation$data;
  variables: useAssignEventReviewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "successful",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationMessage",
  "kind": "LinkedField",
  "name": "messages",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "assignee",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAssignEventReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventReviewPayload",
        "kind": "LinkedField",
        "name": "updateEventReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventReview",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAssignEventReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEventReviewPayload",
        "kind": "LinkedField",
        "name": "updateEventReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventReview",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c942a8164f9e25f20481e6f4abb5d93",
    "id": null,
    "metadata": {},
    "name": "useAssignEventReviewMutation",
    "operationKind": "mutation",
    "text": "mutation useAssignEventReviewMutation(\n  $input: UpdateEventReviewInput!\n) {\n  updateEventReview(input: $input) {\n    successful\n    messages {\n      message\n    }\n    result {\n      assignee {\n        id\n        firstName\n        lastName\n        email\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4638f8a226474fc2201d40dd8cca804e";

export default node;
