import React, { forwardRef, memo, useCallback, useMemo } from 'react'
import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useLocation } from 'react-router'
import { find, map, memoize, update } from 'lodash/fp'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

import { breakpoints, color, mediaQuery, zIndex } from '../utils/variables'
import { pageStickyTop } from '../utils/sticky'
import DiceBadge from './DiceBadge'
import FormField, { IStyledFormField } from './FormField'

const SubnavWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: center;
  position: sticky;
  padding: 12px 0;
  border-bottom: 2px solid ${color.text};
  background: #fff;
  z-index: ${zIndex.dropdown};
  ${pageStickyTop()}

  ${mediaQuery.lessThan('tablet')`
    overflow: visible;
    ${pageStickyTop('58px')}
  `}
`

const SubnavDropdown = styled(FormField)`
  flex: 1;
  margin: 4px 16px;
` as IStyledFormField

export const Subnav = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 32px;
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
  &:after {
    left: auto;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
`

export const SubnavItem = styled(NavLink)`
  display: block;
  text-align: center;
  padding: 12px 24px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;

  &:first-child {
    padding-left: 32px;
    ${mediaQuery.lessThan('tablet')`
      padding-left: 16px;
    `}
  }
  &:last-child {
    padding-right: 32px;
    ${mediaQuery.lessThan('tablet')`
      padding-right: 16px;
    `}
  }
  &.-active {
    color: ${color.primary};
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      margin-top: 8px;
      margin-bottom: -10px;
      background: currentColor;
    }
  }
`

const preload = memoize((factory: () => Promise<any>) => () => {
  factory().catch(console.warn)
})

export interface INavItem {
  value: string
  label: string
  permission?: string | string[]
  dice?: boolean
  strict?: boolean
  preloader?: Parameters<typeof preload>[0]
}

interface IProps {
  className?: string
  options: Array<INavItem>
}

const SubNavigation = forwardRef<HTMLDivElement, IProps>(({ className, options }, ref) => {
  const navigate = useNavigate()
  const location = useLocation()

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const doNavigate = useCallback((page: any) => navigate(page), [navigate])

  const decoratedOptions = useMemo(() => {
    return map(
      // eslint-disable-next-line lodash-fp/no-extraneous-function-wrapping
      (it) =>
        update(
          'label',
          (l) => (
            <>
              {it.dice && <DiceBadge />}
              {l}
            </>
          ),
          it
        ),
      options
    )
  }, [options])

  const decoratedCurrentOption = useMemo(() => {
    const currentOption = find(
      (o) => (o.strict ? location.pathname === o.value : location.pathname.startsWith(o.value)),
      options
    )

    if (!currentOption) return currentOption

    return update(
      'label',
      (l) => (
        <>
          {currentOption.dice && <DiceBadge />}
          {l}
        </>
      ),
      currentOption
    )
  }, [location.pathname, options])

  return (
    <SubnavWrapper className={className} ref={ref}>
      {isMobile ? (
        <SubnavDropdown
          name="navigation"
          control="select"
          options={decoratedOptions}
          value={decoratedCurrentOption}
          onChange={doNavigate}
        />
      ) : (
        <Subnav>
          {decoratedOptions.map((nav) => (
            <SubnavItem
              key={nav.value}
              to={nav.value}
              data-id={nav.value}
              className={cn({ '-active': decoratedCurrentOption && decoratedCurrentOption.value === nav.value })}
              onMouseEnter={nav.preloader && preload(nav.preloader)}
            >
              {nav.label}
            </SubnavItem>
          ))}
        </Subnav>
      )}
    </SubnavWrapper>
  )
})

export default styled(memo(SubNavigation))``
