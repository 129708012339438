import { generateMedia } from 'styled-media-query'
import { mapValues } from 'lodash/fp'

export const breakpoints = {
  desktopLarge: 1200,
  desktop: 900,
  tablet: 768,
  mobile: 0,
}

export const mediaQuery = generateMedia(mapValues((v) => `${v}px`, breakpoints))

export const spacing = {
  auto: 'auto',
  zero: 0,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 32,
  xlg: 48,
}

export const font = {
  family: {
    base: '"ABC Favorit", Helvetica, Arial, sans-serif',
  },

  /**
   * @deprecated use typography.ts presets instead
   */
  size: {
    xs: 12,
    sm: 14,
    base: 16,
    md: 20,
    lg: 24,
    xlg: 32,
    xl: 40,
  },

  weight: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
  },
}

export const color = {
  primary: '#0000ff',
  secondary: '#00d8af',
  tertiary: '#c900ff',
  success: '#00d8af',
  warning: '#ffa81a',
  warningBg: '#ffeed1',
  error: '#ff1c5e',
  text: '#000000',
  black: '#000000',
  white: '#ffffff',
  darkgrey: '#666666',
  grey: '#bfbfbf',
  greyer: '#808080',
  lightgrey: '#e5e5e5',
  palegrey: '#f2f2f2',
  disabled: '#dbdbdb',
  palepurple: '#e5e5fe',
  lightblue: '#ccccff',
  yellow: '#dade00',
  lightyellow: '#f2f06f',
  dice: '#7400CC',
}

export const gradient = {
  primary: 'linear-gradient(90deg, #00D8AF 0%, #0000FF 100%)',
  secondary: 'linear-gradient(90deg, #FFA81A 0%, #C900FF 100%)',
}

export const input = {
  fontSize: font.size.base,
  lineHeight: 1.4,
  borderWidth: 2,
  borderRadius: 4,
  paddingHorizontal: 16,
  paddingVertical: 4,
  height: 40,
  innerHeight: 36,
  borderColor: color.lightgrey,
  borderColorHover: '#bfbfbf',
  borderColorActive: color.primary,
  errorColor: color.error,
  placeholderColor: color.grey,
}

export const zIndex = {
  sidebar: 2030,
  header: 2020,
  loadingContainer: 900,
  modal: 5000,
  modalPrimary: 99999,
  notifications: 999999,
  tooltip: 2040,
  dropdown: 1100,
  max: 9999,
  formField: 100,
}

export const transition = {
  sidebar: {
    transitionDelay: '0.3s',
    transition: (property: string | string[]): string => {
      const props = typeof property === 'string' ? [property] : property
      return props.reduce((str, p) => {
        const newStr = `${p} 0.3s cubic-bezier(0.8, 0, 0.3, 1)`
        return str === '' ? newStr : str + ', ' + newStr
      }, '')
    },
  },
}
