/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useCallback } from 'react'

function useTimelineTodayScroll(todayOffset: number, containerRef: RefObject<HTMLDivElement>) {
  const onScrollToday = useCallback(() => {
    if (!containerRef.current) return null

    const scrollLeft = Math.max(0, todayOffset - containerRef.current.offsetWidth / 2 + 15)
    containerRef.current && containerRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' })
  }, [])

  return { onScrollToday }
}

export default useTimelineTodayScroll
