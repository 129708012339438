import React, { FC, memo, Suspense, useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { some } from 'lodash/fp'
import AlertBox, { AlertBoxIcon } from '../../../components/AlertBox'
import Collapsible from '../../../components/Collapsible'
import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { Loader } from '../../../components/Loader'
import Note from '../../../components/Note'
import { authContext } from '../../../context/auth'
import { color } from '../../../utils/variables'
import useFeeAdminFields from '../hooks/useFeeAdminFields'
import IEventFormTickets from '../types/Tickets'
import EventFeeConfigurationPreview from './EventFeeConfigurationPreview'
import EventOverrideFees from './EventOverrideFees'
import VenueFeesWarning from './VenueFeesWarning'

const SWarning = styled(AlertBox)`
  max-width: 530px;
  margin: -16px 0;

  line-height: 18px;
  padding: 11px 12px;

  strong {
    display: block;
    margin-bottom: 2px;
  }

  ${AlertBoxIcon} {
    align-self: start;
  }
`

interface IProps {
  readOnly?: boolean
}

const EventFees: FC<IProps> = ({ readOnly }) => {
  const intl = useIntl()
  const { account } = useContext(authContext)

  const formik = useFormikContext<IEventFormTickets>()
  const { values, handleBlur } = formik

  const {
    feeModeOptions,
    feeModeOption,
    setFeeMode,
    feesOptions,
    basePriceFees,
    setBasePriceFees,
    fanPriceFees,
    setFanPriceFees,
  } = useFeeAdminFields({ readOnly: !!readOnly, accountId: values.billingPromoter?.accountId || account?.id || null })

  const hasFreeTickets = useMemo(
    () =>
      some(
        (tt) =>
          (tt?.priceTiers?.length || 0) > 0
            ? some((pt) => (pt?.faceValue || 0) === 0, tt?.priceTiers)
            : (tt?.faceValue || 0) === 0,
        values.ticketTypes
      ),
    [values.ticketTypes]
  )

  const hasFixedFees = useMemo(
    () => some((fee) => fee?.unit === 'fixed' && (fee.amount || 0) > 0, values.fees),
    [values.fees]
  )

  return (
    <Collapsible
      label={intl.formatMessage({ id: 'fees' })}
      initialCollapsed={!(hasFreeTickets && hasFixedFees)}
      dataId="ticketsFees"
      dice
    >
      <FormRow columnOnMobile data-test-id="feesBehaviour">
        <FormField
          name="feesBehaviour"
          label={intl.formatMessage({ id: 'new_event.tickets.fees_mode.label' })}
          control="select"
          options={feeModeOptions}
          value={feeModeOption}
          onChange={setFeeMode}
          onBlur={handleBlur}
          disabled={readOnly}
          hint={
            (values.billingPromoter?.value || values.primaryVenue?.value) && (
              <Suspense fallback={<Loader />}>
                <EventFeeConfigurationPreview
                  currency={values.costCurrency || 'GBP'}
                  timezone={values.timezoneName}
                  eventId={values.id || null}
                  promoterId={values.billingPromoter?.value}
                  venueId={values.primaryVenue?.value}
                />
              </Suspense>
            )
          }
        />
      </FormRow>

      {values.feesBehaviour === 'OVERRIDE' && (
        <>
          {values.primaryVenue?.value && (
            <FormRow className="mt-md">
              <VenueFeesWarning
                venueId={values.primaryVenue?.value}
                message={intl.formatMessage({ id: 'new_event.tickets.venue_fees_override_warning' })}
              />
            </FormRow>
          )}

          {hasFreeTickets && (
            <FormRow data-test-id="freeTicketsFeeWarning">
              <SWarning icon={hasFixedFees ? 'warning' : 'info'} color={hasFixedFees ? color.error : color.warning}>
                <strong>
                  {intl.formatMessage({
                    id: hasFixedFees
                      ? 'fee_overrides.free_tickets_error.title'
                      : 'fee_overrides.free_tickets_warning.title',
                  })}
                </strong>
                {intl.formatMessage({
                  id: hasFixedFees
                    ? 'fee_overrides.free_tickets_error.text'
                    : 'fee_overrides.free_tickets_warning.text',
                })}
              </SWarning>
            </FormRow>
          )}

          <FormRow columnOnMobile data-test-id="basePriceFees">
            <FormField
              name="basePriceFees"
              label={intl.formatMessage({ id: 'new_event.tickets.base_price_fees.label' })}
              control="select"
              multiple
              value={basePriceFees}
              options={feesOptions}
              onBlur={handleBlur}
              onChange={setBasePriceFees}
              disabled={readOnly}
            />
          </FormRow>

          <FormRow columnOnMobile data-test-id="postFanPriceFees">
            <FormField
              name="postFanPriceFees"
              label={intl.formatMessage({ id: 'new_event.tickets.fan_price_fees.label' })}
              control="select"
              multiple
              value={fanPriceFees}
              options={feesOptions}
              onBlur={handleBlur}
              onChange={setFanPriceFees}
              disabled={readOnly}
            />
          </FormRow>

          <FormRow>
            <div>
              <Note>{intl.formatMessage({ id: 'event_override_fee_note' })}</Note>

              <EventOverrideFees
                formikContext={formik}
                currency={values.costCurrency}
                fees={values.fees}
                name="fees"
                readOnly={readOnly}
              />
            </div>
          </FormRow>
        </>
      )}
    </Collapsible>
  )
}

export default memo(EventFees)
