import { filter, reject, sumBy } from 'lodash/fp'
import { useMemo } from 'react'
import { useRevenueReportQuery_event$data } from '../../../__generated__/useRevenueReportQuery_event.graphql'

const MERCH_PCAT = 'MERCH'

function useEventStats(event: null | useRevenueReportQuery_event$data) {
  const eventStats = useMemo(
    () => ({
      ticketsAllocation: event?.allocation || 0,
      ticketsSold: event?.sales?.totalSold || 0,
      ticketsMoney: event?.sales?.totalPayoutValue || 0,

      extrasAllocation: sumBy('allocation', reject(['rootType', MERCH_PCAT], event?.products || [])) || 0,
      extrasSold:
        sumBy('totalSold', reject(['product.rootType', MERCH_PCAT], event?.productsSales?.productBreakdown || [])) || 0,
      extrasMoney:
        sumBy(
          'totalFaceValue',
          reject(['product.rootType', MERCH_PCAT], event?.productsSales?.productBreakdown || [])
        ) || 0,

      merchAllocation: sumBy('allocation', filter(['rootType', MERCH_PCAT], event?.products || [])) || 0,
      merchSold:
        sumBy('totalSold', filter(['product.rootType', MERCH_PCAT], event?.productsSales?.productBreakdown || [])) || 0,
      merchMoney:
        sumBy(
          'totalFaceValue',
          filter(['product.rootType', MERCH_PCAT], event?.productsSales?.productBreakdown || [])
        ) || 0,
    }),
    [
      event?.allocation,
      event?.products,
      event?.productsSales?.productBreakdown,
      event?.sales?.totalPayoutValue,
      event?.sales?.totalSold,
    ]
  )

  return eventStats
}

export type IEventStats = ReturnType<typeof useEventStats>

export default useEventStats
