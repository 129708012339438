/* eslint-disable react-hooks/rules-of-hooks */
import React, { ComponentType, useMemo, useCallback } from 'react'
import { QueryRenderer, GraphQLTaggedNode, FetchPolicy, useRelayEnvironment } from 'react-relay'
import { useNavigate } from 'react-router'
import { persistLocation } from '../utils/api'

import { LoaderContainer, Loader } from './Loader'

interface IOpts {
  query: GraphQLTaggedNode
  fetchPolicy?: FetchPolicy
  variables?: any
  customLoader?: JSX.Element
}

function RelayLoader<T>(component: ComponentType<React.PropsWithChildren<T>>, opts: IOpts) {
  return (routeProps: any) => {
    const navigate = useNavigate()

    const vars = useMemo(
      () =>
        !opts.variables ? {} : typeof opts.variables.call !== 'undefined' ? opts.variables(routeProps) : opts.variables,
      [routeProps]
    )

    const doRender = useCallback(
      (response: any) => {
        const { error, props } = response
        if (error) {
          if (error.res?.status === 401) {
            persistLocation()
            navigate('/auth/login')
            return null
          }

          // SIC! the original error is reported at Environment level
          throw new Error('Unable to query data from server')
        } else if (props) {
          const myProps = {
            ...vars,
            ...routeProps,
            ...(props as Record<string, any>),
          }
          return React.createElement(component, myProps)
        }
        return opts.customLoader ? (
          opts.customLoader
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
      },
      [navigate, routeProps, vars]
    )

    const environment = useRelayEnvironment()

    return (
      <QueryRenderer
        environment={environment}
        query={opts.query}
        fetchPolicy={opts.fetchPolicy || 'store-and-network'}
        variables={vars}
        render={doRender}
      />
    )
  }
}

export default RelayLoader
