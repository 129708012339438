import { useCallback, useState, useContext, useMemo } from 'react'
import { pick } from 'lodash/fp'

import { authContext } from '../../../context/auth'
import { localeContext } from '../../../context/locale'
import { suggestCities } from '../../../utils/mapbox'

const LOCAL_STORAGE_KEY = 'featuredLocationSearch'

type IRadius = ['miles' | 'kilometers', number]

const remember = (what: string) => {
  try {
    const savedStr = localStorage.getItem(LOCAL_STORAGE_KEY)
    const json = savedStr && JSON.parse(savedStr)
    return json?.[what] || null
  } catch (e) {
    console.error(e)
    return null
  }
}

function useFeatured() {
  const { account } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const loadCities = useMemo(() => suggestCities(undefined, locale), [locale])

  const [coordinates, setCoordinates] = useState<null | { lat: number; lon: number }>(() => remember('coordinates'))

  const [location, setLocation] = useState<string | null>(() => remember('location'))

  const [radiusSelector, setRadiusSelector] = useState(false)
  const showRadiusSelector = useCallback(() => setRadiusSelector(true), [])
  const closeRadiusSelector = useCallback(() => setRadiusSelector(false), [])

  const defaultRadius = useMemo<IRadius>(() => {
    const defaultUnits = account?.countryCode === 'US' ? 'miles' : 'kilometers'

    return [defaultUnits, defaultUnits === 'kilometers' ? 5 : 3]
  }, [account?.countryCode])

  const [radius, setRadiusMandatory] = useState<IRadius>(() => remember('radius') || defaultRadius)

  const setRadius = useCallback(
    (radius: IRadius | null) => {
      const v = radius || defaultRadius
      setRadiusMandatory(v)

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ location, radius: v, coordinates }))
    },
    [coordinates, defaultRadius, location]
  )

  const changeLocation = useCallback(
    (_: any, v: any) => {
      const c = v && pick(['lat', 'lon'], v)

      setLocation(v?.value)
      setCoordinates(c)

      if (v) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ location: v.value, radius, coordinates: c }))
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY)
      }
    },
    [radius]
  )

  return {
    location,
    coordinates,
    changeLocation,
    showRadiusSelector,
    closeRadiusSelector,
    radiusSelector,
    radius,
    setRadius,
    loadCities,
  }
}

export default useFeatured
