import React, { FC } from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'

import { HeaderCell, Row, TableHeader } from '../../../../components/Table'
import { HeaderCol } from '../../../EventList/components/EventListItemGrid'

interface IProps {
  currentOrder?: string
  orderBy?: any
}

const EventAbbonamentoPickerTableHeader: FC<React.PropsWithChildren<IProps>> = ({ currentOrder, orderBy }) => {
  const intl = useIntl()
  return (
    <TableHeader>
      <Row>
        <HeaderCell className="-narrow"></HeaderCell>
        <HeaderCell className="-wide">
          <HeaderCol
            data-order={currentOrder === 'nameASC' ? 'nameDESC' : 'nameASC'}
            className={cn('-can-order', {
              '-desc': currentOrder === 'nameDESC',
              '-asc': currentOrder === 'nameASC',
            })}
            onClick={orderBy}
          >
            {intl.formatMessage({ id: 'event' })}
          </HeaderCol>
        </HeaderCell>
        <HeaderCell>
          <HeaderCol
            data-order={currentOrder === 'dateASC' ? 'dateDESC' : 'dateASC'}
            className={cn('-can-order', {
              '-desc': currentOrder === 'dateDESC',
              '-asc': !currentOrder || currentOrder === 'dateASC',
            })}
            onClick={orderBy}
          >
            {intl.formatMessage({ id: 'date' })}
          </HeaderCol>
        </HeaderCell>
        <HeaderCell>
          <HeaderCol>{intl.formatMessage({ id: 'venue' })}</HeaderCol>
        </HeaderCell>
      </Row>
    </TableHeader>
  )
}

export default EventAbbonamentoPickerTableHeader
