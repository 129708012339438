export default [
  { dial: '93', iso: 'AF' },
  { dial: '355', iso: 'AL' },
  { dial: '213', iso: 'DZ' },
  { dial: '1', iso: 'AS' },
  { dial: '376', iso: 'AD' },
  { dial: '244', iso: 'AO' },
  { dial: '1', iso: 'AI' },
  { dial: '672', iso: 'AQ' },
  { dial: '1', iso: 'AG' },
  { dial: '54', iso: 'AR' },
  { dial: '374', iso: 'AM' },
  { dial: '297', iso: 'AW' },
  { dial: '61', iso: 'AU' },
  { dial: '43', iso: 'AT' },
  { dial: '994', iso: 'AZ' },
  { dial: '1', iso: 'BS' },
  { dial: '973', iso: 'BH' },
  { dial: '880', iso: 'BD' },
  { dial: '1', iso: 'BB' },
  { dial: '375', iso: 'BY' },
  { dial: '32', iso: 'BE' },
  { dial: '501', iso: 'BZ' },
  { dial: '229', iso: 'BJ' },
  { dial: '1', iso: 'BM' },
  { dial: '975', iso: 'BT' },
  { dial: '591', iso: 'BO' },
  { dial: '387', iso: 'BA' },
  { dial: '267', iso: 'BW' },
  { dial: '47', iso: 'BV' },
  { dial: '55', iso: 'BR' },
  { dial: '246', iso: 'IO' },
  { dial: '1', iso: 'VG' },
  { dial: '673', iso: 'BN' },
  { dial: '359', iso: 'BG' },
  { dial: '226', iso: 'BF' },
  { dial: '257', iso: 'BI' },
  { dial: '855', iso: 'KH' },
  { dial: '237', iso: 'CM' },
  { dial: '1', iso: 'CA' },
  { dial: '238', iso: 'CV' },
  { dial: '599', iso: 'BQ' },
  { dial: '1', iso: 'KY' },
  { dial: '236', iso: 'CF' },
  { dial: '235', iso: 'TD' },
  { dial: '56', iso: 'CL' },
  { dial: '86', iso: 'CN' },
  { dial: '61', iso: 'CX' },
  { dial: '61', iso: 'CC' },
  { dial: '57', iso: 'CO' },
  { dial: '269', iso: 'KM' },
  { dial: '242', iso: 'CG' },
  { dial: '243', iso: 'CD' },
  { dial: '682', iso: 'CK' },
  { dial: '506', iso: 'CR' },
  { dial: '385', iso: 'HR' },
  { dial: '53', iso: 'CU' },
  { dial: '599', iso: 'CW' },
  { dial: '357', iso: 'CY' },
  { dial: '420', iso: 'CZ' },
  { dial: '225', iso: 'CI' },
  { dial: '45', iso: 'DK' },
  { dial: '253', iso: 'DJ' },
  { dial: '1', iso: 'DM' },
  { dial: '1', iso: 'DO' },
  { dial: '593', iso: 'EC' },
  { dial: '20', iso: 'EG' },
  { dial: '503', iso: 'SV' },
  { dial: '240', iso: 'GQ' },
  { dial: '291', iso: 'ER' },
  { dial: '372', iso: 'EE' },
  { dial: '251', iso: 'ET' },
  { dial: '500', iso: 'FK' },
  { dial: '298', iso: 'FO' },
  { dial: '679', iso: 'FJ' },
  { dial: '358', iso: 'FI' },
  { dial: '33', iso: 'FR' },
  { dial: '594', iso: 'GF' },
  { dial: '689', iso: 'PF' },
  { dial: '262', iso: 'TF' },
  { dial: '241', iso: 'GA' },
  { dial: '220', iso: 'GM' },
  { dial: '995', iso: 'GE' },
  { dial: '49', iso: 'DE' },
  { dial: '233', iso: 'GH' },
  { dial: '350', iso: 'GI' },
  { dial: '30', iso: 'GR' },
  { dial: '299', iso: 'GL' },
  { dial: '1', iso: 'GD' },
  { dial: '590', iso: 'GP' },
  { dial: '1', iso: 'GU' },
  { dial: '502', iso: 'GT' },
  { dial: '44', iso: 'GG' },
  { dial: '224', iso: 'GN' },
  { dial: '245', iso: 'GW' },
  { dial: '592', iso: 'GY' },
  { dial: '509', iso: 'HT' },
  { dial: '672', iso: 'HM' },
  { dial: '504', iso: 'HN' },
  { dial: '852', iso: 'HK' },
  { dial: '36', iso: 'HU' },
  { dial: '354', iso: 'IS' },
  { dial: '91', iso: 'IN' },
  { dial: '62', iso: 'ID' },
  { dial: '98', iso: 'IR' },
  { dial: '964', iso: 'IQ' },
  { dial: '353', iso: 'IE' },
  { dial: '44', iso: 'IM' },
  { dial: '972', iso: 'IL' },
  { dial: '39', iso: 'IT' },
  { dial: '1', iso: 'JM' },
  { dial: '81', iso: 'JP' },
  { dial: '44', iso: 'JE' },
  { dial: '962', iso: 'JO' },
  { dial: '7', iso: 'KZ' },
  { dial: '254', iso: 'KE' },
  { dial: '686', iso: 'KI' },
  { dial: '965', iso: 'KW' },
  { dial: '996', iso: 'KG' },
  { dial: '856', iso: 'LA' },
  { dial: '371', iso: 'LV' },
  { dial: '961', iso: 'LB' },
  { dial: '266', iso: 'LS' },
  { dial: '231', iso: 'LR' },
  { dial: '218', iso: 'LY' },
  { dial: '423', iso: 'LI' },
  { dial: '370', iso: 'LT' },
  { dial: '352', iso: 'LU' },
  { dial: '853', iso: 'MO' },
  { dial: '389', iso: 'MK' },
  { dial: '261', iso: 'MG' },
  { dial: '265', iso: 'MW' },
  { dial: '60', iso: 'MY' },
  { dial: '960', iso: 'MV' },
  { dial: '223', iso: 'ML' },
  { dial: '356', iso: 'MT' },
  { dial: '692', iso: 'MH' },
  { dial: '596', iso: 'MQ' },
  { dial: '222', iso: 'MR' },
  { dial: '230', iso: 'MU' },
  { dial: '262', iso: 'YT' },
  { dial: '52', iso: 'MX' },
  { dial: '691', iso: 'FM' },
  { dial: '373', iso: 'MD' },
  { dial: '377', iso: 'MC' },
  { dial: '976', iso: 'MN' },
  { dial: '382', iso: 'ME' },
  { dial: '1', iso: 'MS' },
  { dial: '212', iso: 'MA' },
  { dial: '258', iso: 'MZ' },
  { dial: '95', iso: 'MM' },
  { dial: '264', iso: 'NA' },
  { dial: '674', iso: 'NR' },
  { dial: '977', iso: 'NP' },
  { dial: '31', iso: 'NL' },
  { dial: '687', iso: 'NC' },
  { dial: '64', iso: 'NZ' },
  { dial: '505', iso: 'NI' },
  { dial: '227', iso: 'NE' },
  { dial: '234', iso: 'NG' },
  { dial: '683', iso: 'NU' },
  { dial: '672', iso: 'NF' },
  { dial: '850', iso: 'KP' },
  { dial: '1', iso: 'MP' },
  { dial: '47', iso: 'NO' },
  { dial: '968', iso: 'OM' },
  { dial: '92', iso: 'PK' },
  { dial: '680', iso: 'PW' },
  { dial: '970', iso: 'PS' },
  { dial: '507', iso: 'PA' },
  { dial: '675', iso: 'PG' },
  { dial: '595', iso: 'PY' },
  { dial: '51', iso: 'PE' },
  { dial: '63', iso: 'PH' },
  { dial: '870', iso: 'PN' },
  { dial: '48', iso: 'PL' },
  { dial: '351', iso: 'PT' },
  { dial: '1', iso: 'PR' },
  { dial: '974', iso: 'QA' },
  { dial: '40', iso: 'RO' },
  { dial: '7', iso: 'RU' },
  { dial: '250', iso: 'RW' },
  { dial: '262', iso: 'RE' },
  { dial: '685', iso: 'WS' },
  { dial: '378', iso: 'SM' },
  { dial: '966', iso: 'SA' },
  { dial: '221', iso: 'SN' },
  { dial: '381', iso: 'RS' },
  { dial: '248', iso: 'SC' },
  { dial: '232', iso: 'SL' },
  { dial: '65', iso: 'SG' },
  { dial: '1', iso: 'SX' },
  { dial: '421', iso: 'SK' },
  { dial: '386', iso: 'SI' },
  { dial: '677', iso: 'SB' },
  { dial: '252', iso: 'SO' },
  { dial: '27', iso: 'ZA' },
  { dial: '500', iso: 'GS' },
  { dial: '82', iso: 'KR' },
  { dial: '211', iso: 'SS' },
  { dial: '34', iso: 'ES' },
  { dial: '94', iso: 'LK' },
  { dial: '590', iso: 'BL' },
  { dial: '290', iso: 'SH' },
  { dial: '1', iso: 'KN' },
  { dial: '1', iso: 'LC' },
  { dial: '590', iso: 'MF' },
  { dial: '508', iso: 'PM' },
  { dial: '1', iso: 'VC' },
  { dial: '249', iso: 'SD' },
  { dial: '597', iso: 'SR' },
  { dial: '47', iso: 'SJ' },
  { dial: '268', iso: 'SZ' },
  { dial: '46', iso: 'SE' },
  { dial: '41', iso: 'CH' },
  { dial: '963', iso: 'SY' },
  { dial: '239', iso: 'ST' },
  { dial: '886', iso: 'TW' },
  { dial: '992', iso: 'TJ' },
  { dial: '255', iso: 'TZ' },
  { dial: '66', iso: 'TH' },
  { dial: '670', iso: 'TL' },
  { dial: '228', iso: 'TG' },
  { dial: '690', iso: 'TK' },
  { dial: '676', iso: 'TO' },
  { dial: '1', iso: 'TT' },
  { dial: '216', iso: 'TN' },
  { dial: '90', iso: 'TR' },
  { dial: '993', iso: 'TM' },
  { dial: '1', iso: 'TC' },
  { dial: '688', iso: 'TV' },
  { dial: '246', iso: 'UM' },
  { dial: '1', iso: 'VI' },
  { dial: '44', iso: 'GB' },
  { dial: '1', iso: 'US' },
  { dial: '256', iso: 'UG' },
  { dial: '380', iso: 'UA' },
  { dial: '971', iso: 'AE' },
  { dial: '598', iso: 'UY' },
  { dial: '998', iso: 'UZ' },
  { dial: '678', iso: 'VU' },
  { dial: '39', iso: 'VA' },
  { dial: '58', iso: 'VE' },
  { dial: '84', iso: 'VN' },
  { dial: '681', iso: 'WF' },
  { dial: '212', iso: 'EH' },
  { dial: '967', iso: 'YE' },
  { dial: '260', iso: 'ZM' },
  { dial: '263', iso: 'ZW' },
  { dial: '358', iso: 'AX' },
]
