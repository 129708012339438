import React, { FC, memo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'

import { IFactory, SIDEBAR_ROUTER, preload } from '../services/sidebarNav'
import { SidebarMenuIcon, SidebarMenuItem } from './legacy/Styles'

interface IProps {
  onReload: () => void
}

const Data: FC<IProps> = ({ onReload }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const salesAttributionReload = useCallback(() => {
    onReload()
    navigate('/reporting/attribution')
  }, [navigate, onReload])

  const reportsReload = useCallback(() => {
    onReload()
    navigate('/reporting')
  }, [navigate, onReload])

  return (
    <>
      <SidebarMenuItem
        active={pathname && pathname.startsWith(SIDEBAR_ROUTER.data.salesAttribution[0] as string)}
        to={SIDEBAR_ROUTER.data.salesAttribution[0]}
        data-id="sidebarSalesAttribution"
        onMouseEnter={preload(SIDEBAR_ROUTER.data.salesAttribution[1] as IFactory)}
        onReload={salesAttributionReload}
      >
        <SidebarMenuIcon icon="bar-graph" />
        <span>{intl.formatMessage({ id: 'sidebar.sales_attribution' })}</span>
      </SidebarMenuItem>
      <SidebarMenuItem
        active={
          pathname &&
          pathname.startsWith(SIDEBAR_ROUTER.data.reports[0] as string) &&
          !pathname.endsWith(SIDEBAR_ROUTER.data.salesAttribution[0] as string)
        }
        to={SIDEBAR_ROUTER.data.reports[0]}
        data-id="sidebarReports"
        onMouseEnter={preload(SIDEBAR_ROUTER.data.reports[1] as IFactory)}
        onReload={reportsReload}
      >
        <SidebarMenuIcon icon="remittance" />
        <span>{intl.formatMessage({ id: 'sidebar.reports' })}</span>
      </SidebarMenuItem>
    </>
  )
}

export default memo(Data)
