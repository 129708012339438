import { compact, compose, isNil, reduce } from 'lodash/fp'
import { ObjectSchema, Schema } from 'yup'

type IFormStepConfig = Array<{
  schema: Schema<object>
}>

type S = ObjectSchema

const reducer = (acc: S | null, curr: S): S => (acc ? acc.concat(curr) : curr)

const combineWizardSchemas =
  // prettier-ignore
  (steps: IFormStepConfig) =>
    (maxStepIdx?: number): Schema<any> =>
      compose([
        reduce(reducer, null),
        compact,
        (steps) =>
          steps.map((step: IFormStepConfig[number], idx: number) =>
            isNil(maxStepIdx) || idx <= maxStepIdx ? step.schema : null
          ),
      ])(steps)

export default combineWizardSchemas
