import { useCallback, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { compact, find, reject, concat, filter } from 'lodash/fp'
import IEventFormInformation, { IImage } from '../types/Information'
import { markAsClientOnly } from '../../../utils/entityStatus'

export default function useInformationImages() {
  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<IEventFormInformation>()

  const images = useMemo(() => reject(['type', 'brand'], compact(values.eventImages || [])), [values.eventImages])
  const setImages = useCallback(
    (newImages: any) =>
      setFieldValue('eventImages', concat(filter(['type', 'brand'], values.eventImages || []), newImages)),
    [setFieldValue, values.eventImages]
  )

  const rawImage = values.rawImage
  const setRawImage = useCallback((rawImage: any) => setFieldValue('rawImage', rawImage), [setFieldValue])

  useEffect(() => {
    if (rawImage) return

    const squareImage = find(['type', 'square'], images)

    if (squareImage && squareImage.cdnUrl.startsWith('http')) {
      let stillActive = true

      let url = squareImage.cdnUrl
      url = url.indexOf('?') >= 0 ? url.substring(0, url.indexOf('?')) : url

      const realImg = new Image()
      realImg.crossOrigin = 'anonymous'
      realImg.onload = () => {
        if (stillActive) {
          setRawImage(
            markAsClientOnly<IImage>({
              type: 'original',
              cdnUrl: url,
              attachment: null,
              cropRegion: {
                x: 0,
                y: 0,
                width: realImg.naturalWidth,
                height: realImg.naturalHeight,
              },
            })
          )
        }
      }
      realImg.src = url

      return () => {
        stillActive = false
      }
    }
  }, [images, rawImage, setRawImage])

  const touchImages = useCallback(() => {
    setFieldTouched('eventImages', true, true)
    setTimeout(() => validateForm(), 0)
  }, [setFieldTouched, validateForm])

  return {
    images,
    setImages,
    rawImage,
    setRawImage,
    touchImages,
  }
}
