import graphql from 'babel-plugin-relay/macro'
import { Environment, fetchQuery_DEPRECATED } from 'react-relay'
import { compose, map, pick, compact } from 'lodash/fp'
import { DeepWritable } from 'ts-essentials'

import { IFee } from '../flows/EventForm/types/Tickets'
import { convertBreakdown } from '../flows/EventForm/hooks/useProductPriceBreakdown'

interface IProps {
  billingPromoterId: string | null
  eventId: string | null
  fees: ReadonlyArray<IFee | null> | null | undefined
  basePriceFees: ReadonlyArray<string | null> | null | undefined
  postFanPriceFees: ReadonlyArray<string | null> | null | undefined
  disableUsTax: boolean
  categoryId: string | null
  faceValue: number | null
  venueId: string | null
}

const getProductPriceBreakdown = async (
  environment: Environment,
  {
    billingPromoterId,
    eventId,
    fees,
    basePriceFees,
    postFanPriceFees,
    disableUsTax,
    categoryId,
    faceValue,
    venueId,
  }: IProps
): Promise<null | number> => {
  if (!categoryId || !faceValue) return null

  const preparedFees = compose([map(pick(['active', 'amount', 'split', 'type', 'unit'])), compact])(
    fees || []
  )

  const result = await fetchQuery_DEPRECATED(
    environment,
    graphql`
      query getProductPriceBreakdownQuery(
        $billingPromoterId: ID
        $eventId: ID
        $fees: [FeeInput]
        $basePriceFees: [String]
        $postFanPriceFees: [String]
        $disableUsTax: Boolean!
        $categoryId: ID!
        $faceValue: Int!
        $venueId: ID!
      ) {
        viewer {
          productPriceCalculation(
            billingPromoterId: $billingPromoterId
            eventId: $eventId
            fees: $fees
            basePriceFees: $basePriceFees
            postFanPriceFees: $postFanPriceFees
            disableUsTax: $disableUsTax
            categoryId: $categoryId,
            faceValue: $faceValue,
            venueId: $venueId,
          ) {
            total
            faceValue
            salesTax
            fees
            vatAmount
            vatRate
            breakdown {
              computed
              amount
              unit
              type
              applicable
              split {
                amount
                unit
                computed
                destination
              }
            }
            split {
              computed
              destination
            }
          }
        }
      }
    `,
    {
      billingPromoterId,
      eventId,
      fees: preparedFees,
      basePriceFees: (basePriceFees || []) as DeepWritable<typeof basePriceFees>,
      postFanPriceFees: (postFanPriceFees || []) as DeepWritable<typeof postFanPriceFees>,
      disableUsTax,
      categoryId,
      faceValue,
      venueId,
    }
  )

  const breakdown = result.viewer?.productPriceCalculation

  return breakdown ? convertBreakdown(breakdown) : null
}

export default getProductPriceBreakdown
