import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { cloneDeep, compact, filter, find, includes } from 'lodash/fp'
import IEventFormTickets, { IFee } from '../types/Tickets'
import useAllowedAdhocFees from './useAllowedAdhocFees'
import { authContext } from '../../../context/auth'
import useFeeTypeOptions from './useFeeTypeOptions'

const useFeeAdminFields = ({ accountId, readOnly }: { accountId: string | null; readOnly: boolean }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { values, setFieldValue, setFieldTouched } = useFormikContext<IEventFormTickets>()

  const allowedAdhocFees = useAllowedAdhocFees(accountId, values.id)

  useEffect(() => {
    if (values.feesBehaviour === 'OVERRIDE' || readOnly || !user.diceStaff) return

    if (allowedAdhocFees.length > 0) setFieldValue('feesBehaviour', 'APPEND_TO_CONTRACT')
    else setFieldValue('feesBehaviour', 'USE_CONTRACT')
  }, [readOnly, values.feesBehaviour, allowedAdhocFees, setFieldValue, user.diceStaff])

  const feeModeOptions = useMemo<{ value: string; label: string }[]>(
    () =>
      compact([
        {
          value: 'OVERRIDE',
          label: intl.formatMessage({ id: 'new_event.tickets.fees_mode.options.override' }),
        },
        allowedAdhocFees.length === 0 && {
          value: 'USE_CONTRACT',
          label: intl.formatMessage({ id: 'new_event.tickets.fees_mode.options.use_contract' }),
        },
        allowedAdhocFees.length > 0 && {
          value: 'APPEND_TO_CONTRACT',
          label: intl.formatMessage({ id: 'new_event.tickets.fees_mode.options.append_to_contract' }),
        },
      ]),
    [intl, allowedAdhocFees]
  )
  const feeModeOption = useMemo(
    () => find(['value', values.feesBehaviour], feeModeOptions),
    [feeModeOptions, values.feesBehaviour]
  )
  const setFeeMode = useCallback(
    (id: any) => {
      setFieldValue('feesBehaviour', id)
      setFieldValue('basePriceFees', [])
      setFieldValue('postFanPriceFees', [])
      setFieldValue('fees', [])

      values.ticketTypes?.forEach((tty, idx) => {
        setFieldValue(`ticketTypes[${idx}].fees`, (cloneDeep(allowedAdhocFees) as IFee[]) || [])
        tty?.priceTiers?.forEach((pt, ptidx) => {
          setFieldValue(`ticketTypes[${idx}].priceTiers[${ptidx}].fees`, [])
        })
      })
    },
    [allowedAdhocFees, setFieldValue, values.ticketTypes]
  )

  const feesOptions = useFeeTypeOptions()

  const basePriceFees: typeof feesOptions = useMemo(
    () => filter((opt) => includes(opt.value, values.basePriceFees), feesOptions),
    [feesOptions, values.basePriceFees]
  )
  const setBasePriceFees = useCallback(
    (ids: any) => {
      setFieldValue('basePriceFees', ids, true)
      setFieldTouched('basePriceFees', true, true)
    },
    [setFieldTouched, setFieldValue]
  )

  const fanPriceFees: typeof feesOptions = useMemo(
    () => filter((opt) => includes(opt.value, values.postFanPriceFees), feesOptions),
    [feesOptions, values.postFanPriceFees]
  )
  const setFanPriceFees = useCallback(
    (ids: any) => {
      setFieldValue('postFanPriceFees', ids, true)
      setFieldTouched('postFanPriceFees', true, true)
    },
    [setFieldTouched, setFieldValue]
  )

  return {
    feeModeOptions,
    feeModeOption,
    setFeeMode,
    feesOptions,
    basePriceFees,
    setBasePriceFees,
    fanPriceFees,
    setFanPriceFees,
  }
}

export default useFeeAdminFields
