import { ChartOptions, LinearScaleOptions, TooltipXAlignment, TooltipYAlignment } from 'chart.js'
import { addDays, formatISO, isSameDay, startOfWeek, subDays } from 'date-fns'
import { concat, isInteger, isNil, some } from 'lodash/fp'
import { useContext, useMemo } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { DeepPartial } from 'ts-essentials'
import { localeContext } from '../../../context/locale'
import { EventCostCurrency } from '../../../enums.generated'
import { dateFnsLocales } from '../../../intl'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { CURRENCY } from '../../../utils/formatters/number'
import { breakpoints, color } from '../../../utils/variables'
import { ITooltipModel } from '../components/SalesChartTooltip'
import { IOptions } from '../types/options'

const shorten = (intl: IntlShape, value: number) =>
  // prettier-ignore
  isNil(value) || value < 1000
    ? intl.formatNumber(value, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
    : intl.formatNumber(value, {
      maximumFractionDigits: 0,
      maximumSignificantDigits: 2,
      notation: 'compact',
    })

const shortenMoney = (intl: IntlShape, value: number, costCurrency: EventCostCurrency) =>
  // prettier-ignore
  isNil(value) || value < 100_000
    ? intl.formatNumber(value / 100, CURRENCY(value, costCurrency))
    : intl.formatNumber(value / 100, {
      ...CURRENCY(value, costCurrency),
      maximumSignificantDigits: 2,
      notation: 'compact',
    })

interface IProps {
  options: IOptions
  costCurrency: EventCostCurrency
  startDate: Date
  endDate: Date
  maxAllocation: number
  maxMoney: number
  tooltipCallback?: (mdl: null | ITooltipModel) => void
}

function useChartConfig({
  options,
  costCurrency,
  maxAllocation,
  maxMoney,
  startDate,
  endDate,
  tooltipCallback,
}: IProps) {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const weekStartOnMonday = !(locale === 'en-US' || locale === 'en-CA')

  const chartConfig = useMemo(() => {
    const axes = concat(options.mode === 'revenue' ? ['money'] : ['sold'], ['views'])

    const xMin = formatISO(
      isSameDay(startDate, endDate) ? subDays(startDate, options.groupBy === 'week' ? 7 : 1) : startDate
    )

    const xMax = formatISO(
      isSameDay(startDate, endDate) ? addDays(endDate, options.groupBy === 'week' ? 7 : 1) : endDate
    )

    return {
      locale,
      maintainAspectRatio: false,
      parsing: {
        xAxisKey: 't',
      },
      animation: {
        duration: 0,
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: options.groupBy === 'cumulative' ? 'day' : options.groupBy,
            isoWeekday: weekStartOnMonday,
          },
          adapters: {
            date: {
              locale: dateFnsLocales[locale],
            },
          },
          grid: {
            display: false,
            offset: false,
          },
          min: xMin,
          max: xMax,
          ticks: {
            color: color.text,
            maxRotation: 0,
            maxTicksLimit: 10,
            callback: function (v, ...rest) {
              const d = new Date(v)

              if (
                options.groupBy === 'week' &&
                !isSameDay(
                  d,
                  startOfWeek(d, {
                    locale: dateFnsLocales[locale],
                    weekStartsOn: weekStartOnMonday ? 1 : 0,
                  })
                )
              ) {
                return
              }

              return intl.formatDate(d, DATETIME_FORMATS.SHORTEST)
            },
          },
        },
        ...Object.fromEntries(
          axes.map((k, idx) => [
            k,
            {
              type: 'linear',
              display: k === 'views' ? 'auto' : true,
              title: {
                display: !isMobile,
                color: color.text,
                text: concat(idx === 0 ? [] : [' ', ' '], [
                  // prettier-ignore
                  k === 'sold'
                    ? intl.formatMessage({ id: 'event_payouts.event_info.table_header.amount' })
                    : k === 'views'
                      ? intl.formatMessage({ id: 'views' })
                      : intl.formatMessage({ id: 'value_sold' }),
                ]),
              },
              weight: k === 'sold' || k === 'money' ? 0 : 1,
              position: idx === 0 ? 'left' : 'right',
              suggestedMax:
                // prettier-ignore
                k === 'views'
                  ? 1000
                  : k === 'sold'
                    ? options.groupBy === 'cumulative'
                      ? maxAllocation
                      : Math.max(10, Math.round(maxAllocation / 100))
                    : options.groupBy === 'cumulative'
                      ? (maxMoney > 0 ? maxMoney : 10000)
                      : Math.max(10000, Math.round(maxMoney / 100)),
              min: 0,
              grid: {
                display: k !== 'views',
              },
              ticks: {
                color: color.text,
                maxTicksLimit: isMobile ? 3 : 6,
                callback: function (v) {
                  const n = Number(v)

                  if (!isInteger(n)) return undefined

                  if (k !== 'sold' && k !== 'views') {
                    return shortenMoney(intl, n, costCurrency)
                  } else {
                    return shorten(intl, n)
                  }
                },
              },
            } as DeepPartial<LinearScaleOptions>,
          ])
        ),
      },
      interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },

        title: {
          display: true,
          text: intl.formatMessage({ id: 'reports_wizard.reports.sales_report.title' }),
          color: color.text,
          font: { weight: 'normal' },
          padding: { top: 0, bottom: 16 },
        },

        tooltip: {
          axis: 'x',
          enabled: false,
          // prettier-ignore
          external: tooltipCallback
            ? (mdl) => {
              const points = mdl.tooltip?.dataPoints

              if (!points || points.length === 0 || isMobile || mdl.tooltip.opacity === 0) {
                tooltipCallback(null)
                return
              }

              let xAlign: TooltipXAlignment =
                (mdl.tooltip.caretX || 0) < (mdl.chart.width || 0) / 2 ? 'left' : 'right'
              let yAlign: TooltipYAlignment =
                (mdl.tooltip.caretY || 0) < (mdl.chart.height || 0) / 2 ? 'bottom' : 'top'

              if (some((dataset) => dataset.data.length === 1, mdl.chart.data.datasets)) {
                xAlign = 'right'
                yAlign = 'top'
              }

              const t = (points[0]?.raw as any)?.t

              tooltipCallback({ ...mdl.tooltip, costCurrency, t, xAlign, yAlign } as ITooltipModel)
            }
            : undefined,
        },
      },
    } as ChartOptions<'line' | 'bar'>
  }, [
    options.mode,
    options.groupBy,
    locale,
    weekStartOnMonday,
    startDate,
    endDate,
    intl,
    tooltipCallback,
    maxAllocation,
    maxMoney,
    isMobile,
    costCurrency,
  ])

  return chartConfig
}

export default useChartConfig
