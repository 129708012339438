import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { isFuture } from 'date-fns'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { compact, concat, map } from 'lodash/fp'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { nanoid } from 'nanoid'

import useDoorlistStatus from '../hooks/useDoorlistStatus'
import Button from '../../../components/Button'
import { authContext } from '../../../context/auth'
import { localeContext } from '../../../context/locale'
import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import TagsInput from '../../../components/TagsInput'
import IEventFormSettings from '../types/Settings'
import { EventDoorlistRecipientsQuery } from '../../../__generated__/EventDoorlistRecipientsQuery.graphql'
import useDoorlistRecipients from '../hooks/useDoorlistRecipients'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { EventDoorlistResetMutation } from '../../../__generated__/EventDoorlistResetMutation.graphql'
import { notificationContext } from '../../../context/notification'
import { REGEX_VALIDATE_EMAIL } from '../../../utils/regex'
import { TZ_DEFAULT } from '../services/getDefaultEvent'

const MarginButton = styled(Button)`
  margin-top: -16px;
`

interface IProps {
  readOnly?: boolean
}

const EventDoorlist: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const { addNotification } = useContext(notificationContext)

  const { values, handleBlur, errors, touched, setFieldValue } = useFormikContext<IEventFormSettings>()

  const { doorlistStatus, doorlistDate } = useDoorlistStatus()

  const venueIds = useMemo(() => compact(map('value', values.venues || [])), [values.venues])

  const hasAccountRecipients = !values.id && !user.diceStaff

  const defaultDoorlistRecipients = useLazyLoadQuery<EventDoorlistRecipientsQuery>(
    graphql`
      query EventDoorlistRecipientsQuery($venueIds: [ID!]) {
        viewer {
          defaultDoorlistRecipients(venueIds: $venueIds)
        }
      }
    `,
    {
      venueIds,
    },
    {
      fetchPolicy: hasAccountRecipients ? 'store-or-network' : 'store-only',
    }
  )

  const doorlistRecipients: string[] = useMemo(
    () =>
      compact(
        !hasAccountRecipients
          ? map('email', values.doorlistRecipients || [])
          : defaultDoorlistRecipients.viewer?.defaultDoorlistRecipients || []
      ),
    [defaultDoorlistRecipients.viewer?.defaultDoorlistRecipients, hasAccountRecipients, values.doorlistRecipients]
  )

  const doorlistAdditionalRecipients: string[] = useMemo(
    () => compact(values.doorlistAdditionalRecipients || []),
    [values.doorlistAdditionalRecipients]
  )
  const setDoorlistAdditionalRecipients = useCallback(
    (emails: any) => setFieldValue('doorlistAdditionalRecipients', emails),
    [setFieldValue]
  )

  const doorlistRecipientsValue = useMemo(
    () => map((dr) => ({ value: dr?.id, label: dr?.email }), values.doorlistRecipients || []),
    [values.doorlistRecipients]
  )

  const setDoorlistRecipientsValue = useCallback(
    (_: any, recList: any) =>
      setFieldValue('doorlistRecipients', recList && map((opt) => ({ id: opt.value, email: opt.label }), recList)),
    [setFieldValue]
  )

  const [doorlistRecipientOptionsRaw, setDoorlistRecipientOptionsRaw] = useState<null | Array<null | {
    id: string
    email: string | null
  }>>(null)

  const fetchDefaultDoorlistRecipients = useDoorlistRecipients(setDoorlistRecipientOptionsRaw)

  useEffect(() => {
    if (user.diceStaff) {
      fetchDefaultDoorlistRecipients(concat(map('value', values.promoters), map('value', values.venues)))
    }
  }, [fetchDefaultDoorlistRecipients, user.diceStaff, values.promoters, values.venues])

  const doorlistRecipientOptions = useMemo(
    () => map((dr) => ({ value: dr?.id, label: dr?.email }), doorlistRecipientOptionsRaw || []),
    [doorlistRecipientOptionsRaw]
  )

  const [resetModal, setResetModal] = useState(false)
  const confirmReset = useCallback(() => setResetModal(true), [])
  const closeReset = useCallback(() => setResetModal(false), [])

  const [commitReset, loading] = useMutation<EventDoorlistResetMutation>(graphql`
    mutation EventDoorlistResetMutation($input: ResetDoorlistStatusInput!) {
      resetDoorlistStatus(input: $input) {
        event {
          id
          doorlistSendStatus
          offSaleSentStatus
          doorlistSendAt
          offSaleSentAt
        }
      }
    }
  `)

  const doReset = useCallback(() => {
    if (!values.id) return

    commitReset({
      variables: {
        input: {
          clientMutationId: nanoid(),
          id: values.id,
        },
      },
      onCompleted(payload, errs) {
        if (errs && errs.length > 0) {
          errs.forEach((e) => addNotification('error', e.message))
          return
        }

        if (!payload.resetDoorlistStatus?.event) {
          addNotification('error', intl.formatMessage({ id: 'new_event.settings.doorlist_status.reset_error' }))
          return
        }

        addNotification('success', intl.formatMessage({ id: 'new_event.settings.doorlist_status.reset_success' }))
        closeReset()
      },
      onError(e) {
        addNotification(
          'error',
          e.message || intl.formatMessage({ id: 'new_event.settings.doorlist_status.reset_error' })
        )
      },
    })
  }, [addNotification, closeReset, commitReset, intl, values.id])

  return (
    <>
      {user.diceStaff && (
        <>
          {values.state !== 'DRAFT' && doorlistStatus && (
            <>
              <FormRow>
                <FormField
                  name="doorlistStatus"
                  label={intl.formatMessage({ id: 'new_event.settings.doorlist_status.label' })}
                  value={doorlistStatus}
                  disabled
                  dice
                />
              </FormRow>
              {(values.offSaleSentAt || values.doorlistSendAt) && (
                <FormRow>
                  <MarginButton
                    onClick={confirmReset}
                    loading={loading}
                    block
                    icon="dice-badge"
                    preset="outline"
                    color="tertiary"
                  >
                    {
                      //prettier-ignore
                      doorlistDate && isFuture(doorlistDate)
                        ? intl.formatMessage(
                          { id: 'new_event.settings.doorlist_status.reset_at_date' },
                          {
                            date: intl.formatDate(doorlistDate, {
                              ...DATETIME_FORMATS.DATETIME(locale),
                              timeZone: values.timezoneName || undefined,
                            }),
                          }
                        )
                        : intl.formatMessage({ id: 'new_event.settings.doorlist_status.reset_now' })
                    }
                  </MarginButton>
                </FormRow>
              )}
            </>
          )}
          <FormRow>
            <FormField
              name="doorlistRecipients"
              label={intl.formatMessage({ id: 'new_event.settings.ticket_holder_list_contacts.label' })}
              hint={intl.formatMessage({ id: 'new_event.settings.ticket_holder_list_contacts.hint' })}
              control="select"
              multiple
              searchable
              value={doorlistRecipientsValue}
              onChange={setDoorlistRecipientsValue}
              onBlur={handleBlur}
              options={doorlistRecipientOptions}
              error={touched.doorlistRecipients && errors.doorlistRecipients}
              disabled={readOnly}
              dice
            />
          </FormRow>
        </>
      )}

      <FormRow columnOnMobile>
        <FormField
          name="doorlistAdditionalRecipients"
          label={intl.formatMessage({ id: 'new_event.settings.ticket_holder_list.label' })}
          hint={user.diceStaff ? intl.formatMessage({ id: 'new_event.settings.ticket_holder_list.hint' }) : undefined}
          control={TagsInput}
          placeholder={intl.formatMessage({ id: 'new_event.settings.doorlist.placeholder' })}
          value={doorlistAdditionalRecipients}
          readOnlyValues={user.diceStaff ? undefined : doorlistRecipients}
          onChange={setDoorlistAdditionalRecipients}
          onBlur={handleBlur}
          validationRegex={REGEX_VALIDATE_EMAIL}
          error={touched.doorlistAdditionalRecipients && errors.doorlistAdditionalRecipients}
          disabled={readOnly}
          help={intl.formatMessage({ id: 'new_event.settings.doorlist.help' })}
        />
      </FormRow>

      {resetModal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'new_event.settings.doorlist_reset.title' })}
          description={
            // prettier-ignore
            doorlistDate && isFuture(doorlistDate)
              ? intl.formatMessage(
                { id: 'new_event.settings.doorlist_reset.description_at_date' },
                {
                  date: intl.formatDate(doorlistDate, {
                    ...DATETIME_FORMATS.DATETIME(locale),
                    timeZone: values.timezoneName || undefined,
                  }),
                }
              )
              : intl.formatMessage({ id: 'new_event.settings.doorlist_reset.description_now' })
          }
          onConfirm={doReset}
          onReject={closeReset}
        />
      )}
    </>
  )
}

export default memo(EventDoorlist)
