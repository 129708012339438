/**
 * @generated SignedSource<<271724862c1598f2012de50726739a4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SalesforceContractStatus = "ACTIVATED" | "CANCELLED" | "EXPIRED" | "SIGNED" | "SUPERSEDED" | "%future added value";
export type SalesforceDetailsQuery$variables = {
  promoterId: string;
};
export type SalesforceDetailsQuery$data = {
  readonly promoter: {
    readonly salesforceContracts?: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly num: string | null;
      readonly opportunityName: string | null;
      readonly sfAccountId: string | null;
      readonly sfId: string | null;
      readonly startDate: string | null;
      readonly status: SalesforceContractStatus | null;
    } | null> | null;
    readonly salesforceFields?: {
      readonly defaultContract: {
        readonly id: string;
        readonly name: string | null;
        readonly num: string | null;
        readonly opportunityName: string | null;
        readonly sfAccountId: string | null;
        readonly sfId: string | null;
        readonly startDate: string | null;
        readonly status: SalesforceContractStatus | null;
      } | null;
      readonly parentSalesforceId: string | null;
      readonly salesforceId: string | null;
    } | null;
  } | null;
};
export type SalesforceDetailsQuery = {
  response: SalesforceDetailsQuery$data;
  variables: SalesforceDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "promoterId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "promoterId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "num",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "opportunityName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sfAccountId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sfId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesforceContract",
      "kind": "LinkedField",
      "name": "salesforceContracts",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesforcePromoterFields",
      "kind": "LinkedField",
      "name": "salesforceFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salesforceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentSalesforceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesforceContract",
          "kind": "LinkedField",
          "name": "defaultContract",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Promoter",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SalesforceDetailsQuery",
    "selections": [
      {
        "alias": "promoter",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SalesforceDetailsQuery",
    "selections": [
      {
        "alias": "promoter",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d22b93ab1ba8f235643400ca6910091",
    "id": null,
    "metadata": {},
    "name": "SalesforceDetailsQuery",
    "operationKind": "query",
    "text": "query SalesforceDetailsQuery(\n  $promoterId: ID!\n) {\n  promoter: node(id: $promoterId) {\n    __typename\n    ... on Promoter {\n      salesforceContracts {\n        id\n        name\n        num\n        opportunityName\n        sfAccountId\n        sfId\n        startDate\n        status\n      }\n      salesforceFields {\n        salesforceId\n        parentSalesforceId\n        defaultContract {\n          id\n          name\n          num\n          opportunityName\n          sfAccountId\n          sfId\n          startDate\n          status\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "155c96d3aebec4947f325999e3e13e01";

export default node;
