import React, { FC, memo, Suspense, useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { API_URL } from '../../../../env'

import IconButton from '../../../../components/IconButton'
import { Menu, MenuItem } from '../../../../components/Menu'
import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../components/Dropdown'
import { OnDesktop, OnDesktopLarge, OnMobile } from '../../../../components/Breakpoints'
import EventPartPayout from '../EventPartPayout'
import EventPartRemittance from '../EventPartRemittance'
import PermissionCheck from '../../../../components/PermissionCheck'
import { EventListItemControlWrapper } from './QuickActions'
import PayoutModal from '../Modals/PayoutModal'

import { QuickActionsPast_event$key } from '../../../../__generated__/QuickActionsPast_event.graphql'
import { ConfirmationModal } from '../../../../components/ConfirmationModal'
import useCopyEvent from '../../hooks/useCopyEvent'
import { authContext } from '../../../../context/auth'
import useRendered from '../../../../utils/useRendered'
import ListTypeProtector from './ListTypeProtector'
import useDeleteEvent from '../../hooks/useDeleteEvent'
import DiceBadge from '../../../../components/DiceBadge'
import useEventPayout from '../../../../utils/hooks/useEventPayout'

interface IProps {
  event: QuickActionsPast_event$key
  stripeIsBroken: boolean
}

const QuickActionsPast: FC<IProps> = ({ event: eventKey, stripeIsBroken }) => {
  const intl = useIntl()
  const { user, account } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment QuickActionsPast_event on Event
      @argumentDefinitions(noSelfPayouts: { type: "Boolean", defaultValue: false }) {
        id
        eventIdLive
        eventType
        organicSocialLink
        tokens {
          doorlistExportToken
        }
        balance @skip(if: $noSelfPayouts) {
          amountPromoterTotal
          payoutsTotal
          payouts {
            status
            amount
          }
        }
        allowedActions {
          performPayout
        }
        ...EventPartPayout_event @skip(if: $noSelfPayouts)
        ...EventPartRemittance_event

        detectNoSelfPayout: id @skip(if: $noSelfPayouts)
        ...useEventPayout_event @skip(if: $noSelfPayouts)
      }
    `,
    eventKey
  )

  const [open, setOpen] = useState(false)
  const [duplicateConfirmationModal, setDuplicateConfirmationModal] = useState(false)

  const { removeConfirmationModal, setRemoveConfirmationModal, deleteEvent, deleting } = useDeleteEvent(event.id)

  const clickOutside = useCallback(() => open && setOpen(false), [open])
  const toggleDropdown = useCallback(() => setOpen(!open), [open])

  const toggleDuplicateModal = useCallback(() => {
    setOpen(false)
    setDuplicateConfirmationModal((v) => !v)
  }, [])

  const toggleRemoveModal = useCallback(() => {
    setOpen(false)
    setRemoveConfirmationModal(!removeConfirmationModal)
  }, [removeConfirmationModal, setRemoveConfirmationModal])

  const downloadDoorlist = useCallback(async () => {
    setOpen(false)
    window.open(`${API_URL}/export?token=${event?.tokens?.doorlistExportToken}`, '_blank')
  }, [event])

  const copyEvent = useCopyEvent(event)

  const [payoutModalLoading, PayoutLoaderSpy] = useRendered()
  const [payoutModal, setPayoutModal] = useState(false)
  const togglePayoutModal = useCallback(() => {
    setOpen(false)
    setPayoutModal((v) => !v)
  }, [])

  const { isPaidOut, selfPayoutBlockers, payoutAmount } = useEventPayout(!!event.detectNoSelfPayout ? event : null)

  const showPayoutControl = event.allowedActions?.performPayout || !(isPaidOut || !!selfPayoutBlockers?.event_postponed)

  const canSeePayouts = !account || !account.forbidSelfPayouts

  return (
    <EventListItemControlWrapper>
      <OnDesktopLarge>
        {isPaidOut || payoutAmount === 0 ? (
          <PermissionCheck permission="read:balances">
            <EventPartRemittance
              event={event}
              iconTitle={intl.formatMessage({ id: 'event_list.quick_actions.view_remittance' })}
            />
          </PermissionCheck>
        ) : (
          <>
            {showPayoutControl && canSeePayouts && (
              <EventPartPayout
                type="icon"
                outlineColor="secondary"
                event={event}
                title={intl.formatMessage({ id: 'payout.modal.title' })}
                loading={payoutModalLoading}
                onClick={togglePayoutModal}
              />
            )}
          </>
        )}
      </OnDesktopLarge>
      <Dropdown active={open} onClickOutside={clickOutside}>
        <DropdownTrigger role="button" onClick={toggleDropdown} data-id="quickActions">
          <OnDesktop>
            <IconButton icon="more" />
          </OnDesktop>
          <OnMobile>
            <IconButton outlineColor="grey" icon="more" />
          </OnMobile>
        </DropdownTrigger>
        <DropdownContent active={open}>
          <Menu>
            {!isPaidOut && payoutAmount > 0 && event.allowedActions?.performPayout && canSeePayouts && (
              <EventPartPayout
                event={event}
                type="menu"
                title={intl.formatMessage({ id: 'payout.modal.title' })}
                loading={payoutModalLoading}
                onClick={togglePayoutModal}
              />
            )}
            <PermissionCheck permission="read:balances">
              <MenuItem data-id="remittance">
                <EventPartRemittance
                  event={event}
                  simpleTitle={intl.formatMessage({ id: 'event_list.quick_actions.view_remittance' })}
                  onOpen={toggleDropdown}
                />
              </MenuItem>
            </PermissionCheck>
            {(event.eventType === 'LIVE' || user.diceStaff) && (
              <PermissionCheck permission="create:event">
                <MenuItem onClick={toggleDuplicateModal} data-id="duplicateEvent" disabled={stripeIsBroken}>
                  {intl.formatMessage({ id: 'event_list.quick_actions.duplicate_event' })}
                </MenuItem>
              </PermissionCheck>
            )}
            <PermissionCheck permission="read_doorlist:event">
              <MenuItem onClick={downloadDoorlist} data-id="downloadDoorlist">
                {intl.formatMessage({ id: 'event_list.quick_actions.download_doorlist' })}
              </MenuItem>
            </PermissionCheck>
            {user.diceStaff && !event.eventIdLive && (
              <PermissionCheck permission="delete:event">
                <MenuItem onClick={toggleRemoveModal} data-id="deleteEvent">
                  <DiceBadge />
                  {intl.formatMessage({ id: 'event_list.quick_actions.delete_event' })}
                </MenuItem>
              </PermissionCheck>
            )}
          </Menu>
        </DropdownContent>
      </Dropdown>
      {duplicateConfirmationModal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'confirmation.duplicate_event.title' })}
          description={intl.formatMessage({ id: 'confirmation.duplicate_event.description' })}
          onConfirm={copyEvent}
          onReject={toggleDuplicateModal}
        />
      )}
      {removeConfirmationModal && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'confirmation.delete_event.title' })}
          description={intl.formatMessage({ id: 'confirmation.delete_event.description' })}
          onConfirm={deleteEvent}
          onReject={toggleRemoveModal}
          loading={deleting}
        />
      )}
      {payoutModal && (
        <Suspense fallback={<PayoutLoaderSpy />}>
          <PayoutModal eventId={event.id} onClose={togglePayoutModal} />
        </Suspense>
      )}
    </EventListItemControlWrapper>
  )
}

export default memo(ListTypeProtector(QuickActionsPast, 'past'))
