/**
 * @generated SignedSource<<60698f666fc0c37cda40da78481275d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRevenueReportQuery_viewer$data = {
  readonly extras: ReadonlyArray<{
    readonly faceValue: number;
    readonly sold: number;
    readonly time: string;
  } | null> | null;
  readonly merch: ReadonlyArray<{
    readonly faceValue: number;
    readonly sold: number;
    readonly time: string;
  } | null> | null;
  readonly tickets: ReadonlyArray<{
    readonly faceValue: number;
    readonly sold: number;
    readonly time: string;
  } | null> | null;
  readonly " $fragmentType": "useRevenueReportQuery_viewer";
};
export type useRevenueReportQuery_viewer$key = {
  readonly " $data"?: useRevenueReportQuery_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRevenueReportQuery_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "eventId",
  "variableName": "eventId"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": "sold",
    "args": null,
    "kind": "ScalarField",
    "name": "soldItems",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "eventId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useRevenueReportQuery_viewer",
  "selections": [
    {
      "alias": "tickets",
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "RevenueReportItem",
      "kind": "LinkedField",
      "name": "revenueReport",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": "sold",
          "args": null,
          "kind": "ScalarField",
          "name": "soldTickets",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "extras",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "rootType",
          "value": "EXTRAS"
        }
      ],
      "concreteType": "ProductsRevenueReportItem",
      "kind": "LinkedField",
      "name": "productsRevenueReport",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "merch",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "rootType",
          "value": "MERCH"
        }
      ],
      "concreteType": "ProductsRevenueReportItem",
      "kind": "LinkedField",
      "name": "productsRevenueReport",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "1c82813832ad956c48247ed94d5810ab";

export default node;
