/**
 * @generated SignedSource<<f03108e6c5fb5066a5ca8161b2313d89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventImage_event$data = {
  readonly eventImages: ReadonlyArray<{
    readonly cdnUrl: string;
    readonly type: string | null;
  } | null> | null;
  readonly " $fragmentType": "EventImage_event";
};
export type EventImage_event$key = {
  readonly " $data"?: EventImage_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventImage_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventImage_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventImage",
      "kind": "LinkedField",
      "name": "eventImages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cdnUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "c85531992d1fb54b5885b111cb6d1738";

export default node;
