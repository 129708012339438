import React, { FC, memo, useState, useCallback, useMemo, useContext } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { Crop } from 'react-image-crop'
import { find, snakeCase } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import { color, mediaQuery, breakpoints, font } from '../../../utils/variables'
import Cropper from '../../../components/Cropper'
import Svg from '../../../components/Svg'

import IEventForm from '../types'
import { TitleTooltip } from '../../../components/Tooltip'
import { CropRegionInput } from '../../../__generated__/validateDraftMutation.graphql'
import { authContext } from '../../../context/auth'
import DiceBadge from '../../../components/DiceBadge'

const MASKS = {
  squareA: (
    <svg viewBox="0 0 150 150" fill={color.white} xmlns="http://www.w3.org/2000/svg">
      <circle cx="130" cy="130" r="10" />
      <circle cx="102" cy="130" r="10" />
    </svg>
  ),
  squareB: (
    <svg viewBox="0 0 150 150" fill={color.white} xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="103" width="72" height="8" />
      <rect x="10" y="117" width="36" height="5" />
      <rect x="10" y="95" width="36" height="4" />
      <rect x="10" y="126" width="50" height="5" />
      <rect x="10" y="135" width="36" height="5" />
      <circle cx="130" cy="130" r="10" />
    </svg>
  ),
  squareC: (
    <svg viewBox="0 0 150 150" fill={color.white} xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="112" width="76" height="8" />
      <rect x="10" y="126" width="36" height="5" />
      <rect x="10" y="135" width="36" height="5" />
      <circle cx="130" cy="130" r="10" />
    </svg>
  ),
  squareD: (
    <svg viewBox="0 0 150 150" fill={color.white} xmlns="http://www.w3.org/2000/svg">
      <circle cx="136" cy="130" r="10" />
      <circle cx="14" cy="130" r="10" />
    </svg>
  ),
  landscapeNew: (
    <svg viewBox="0 0 150 150" fill={color.white} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 48)">
        <circle cx="136" cy="58" r="8" />
        <circle cx="14" cy="58" r="8" />
      </g>
    </svg>
  ),
}

const SELECTOR_MASKS = {
  squareA: MASKS.squareA,
  squareB: MASKS.squareB,
  squareC: MASKS.squareC,
  squareD: MASKS.squareD,
  landscapeNew: (
    <svg viewBox="0 0 150 150" fill={color.white} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 16)">
        <circle cx="136" cy="58" r="8" />
        <circle cx="14" cy="58" r="8" />
      </g>
    </svg>
  ),
}

const CropImages = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  list-style: none;
  margin: 32px -8px 0 -8px;
  padding: 0;
`

const CropImage = styled.li`
  display: flex;
  flex-direction: column;

  width: 150px;
  padding-bottom: 24px;

  margin: 0 8px;

  ${mediaQuery.lessThan('tablet')`
    min-width: 100%;
    margin: 24px 0 0;
  `}
`

const CropButtonOverlay = styled.div`
  display: none;
  cursor: pointer;

  opacity: 0.5;
  background-color: ${color.black};

  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
`

const CropActiveArea = styled.div`
  display: none;

  color: ${color.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;

  justify-content: center;
  align-items: center;
`

const ImgContainer = styled.div<{ isReadOnly: boolean }>`
  position: relative;
  overflow: hidden;
  background-color: ${color.lightgrey};
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'unset' : 'pointer')};
  border-radius: 4px;

  width: 150px;
  height: 150px;
  margin-bottom: 8px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  & > img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:hover ${CropButtonOverlay}, &:hover ${CropActiveArea} {
    display: flex;
  }

  ${CropActiveArea} {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  ${mediaQuery.lessThan('tablet')`
    margin: 0 auto 16px auto;
    width: 100%;
    padding-bottom: 100%;
  `}
`

const CropContainer = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const UsedFor = styled.div`
  font-size: ${font.size.sm}px;

  & > div:first-child {
    color: ${color.darkgrey};
  }
`

type IImage = NonNullable<NonNullable<IEventForm['eventImages']>[number]>

interface IProps {
  rawImage: IImage | null
  images: IImage[]
  onCrop?: (key: string, crop: Crop) => void
  readOnly?: boolean
  noMask?: boolean
}

interface IPreviewProps {
  rawImage: IImage | null
  img: IImage
  isCropping: boolean
  setCropping: (croppingId: string | null) => void
  onCrop?: (key: string, crop: Crop) => void
  readOnly?: boolean
  mask?: keyof typeof MASKS
  dice?: boolean
}

const toPercentCrop = (crop: CropRegionInput, img: CropRegionInput): Crop => ({
  unit: '%',
  aspect: crop.width && crop.height ? crop.width / crop.height : 1,
  x: ((crop.x || 0) / (img.width || 0)) * 100,
  y: ((crop.y || 0) / (img.height || 0)) * 100,
  width: ((crop.width || 0) / (img.width || 0)) * 100,
  height: ((crop.height || 0) / (img.height || 0)) * 100,
})

const toAbsoluteCrop = (crop: CropRegionInput, img: CropRegionInput): Crop => ({
  unit: 'px',
  aspect: crop.width && crop.height ? crop.width / crop.height : 1,
  x: Math.round(((crop.x || 0) * (img.width || 0)) / 100),
  y: Math.round(((crop.y || 0) * (img.height || 0)) / 100),
  width: Math.round(((crop.width || 0) * (img.width || 0)) / 100),
  height: Math.round(((crop.height || 0) * (img.height || 0)) / 100),
})

const CropPreview: FC<React.PropsWithChildren<IPreviewProps>> = ({
  readOnly,
  rawImage,
  img,
  mask,
  isCropping,
  setCropping,
  onCrop,
  dice,
}) => {
  const intl = useIntl()
  const startCropping = useCallback(() => setCropping(mask || '_NO_MASK_'), [setCropping, mask])

  const doCrop = useCallback(
    (crop: any) => {
      const absoluteCrop = crop && rawImage?.cropRegion && toAbsoluteCrop(crop, rawImage.cropRegion)
      if (onCrop && absoluteCrop) onCrop(img.id, absoluteCrop)
      setCropping(null)
    },
    [rawImage, onCrop, img.id, setCropping]
  )

  const percentCrop = useMemo(
    () => img.cropRegion && rawImage?.cropRegion && toPercentCrop(img.cropRegion, rawImage.cropRegion),
    [img.cropRegion, rawImage]
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const smartUrl = useMemo(() => {
    if (!img.cdnUrl || !img.cdnUrl.startsWith('http')) return img.cdnUrl

    const url = new URL(img.cdnUrl)
    url.searchParams.append('w', isMobile ? String(breakpoints.tablet) : '300')
    url.searchParams.append('auto', 'compress')

    if (!url.searchParams.get('rect') && !!img.cropRegion) {
      url.searchParams.append(
        'rect',
        [img.cropRegion?.x || 0, img.cropRegion?.y || 0, img.cropRegion?.width || 0, img.cropRegion?.height || 0].join(
          ','
        )
      )
    }

    return url.toString()
  }, [img.cdnUrl, img.cropRegion, isMobile])

  return (
    <CropImage>
      <ImgContainer
        isReadOnly={readOnly || false}
        onClick={isCropping || readOnly ? undefined : startCropping}
        data-id={`adjustCrop[${img.type}]`}
      >
        <img src={smartUrl} alt={img.type || ''} />
        {mask && MASKS[mask]}
        {!readOnly && !!rawImage && (
          <>
            <CropButtonOverlay />
            <TitleTooltip title={intl.formatMessage({ id: 'new_event.basics.images.adjust_crop' })}>
              <CropActiveArea>
                <Svg icon="crop" />
              </CropActiveArea>
            </TitleTooltip>
          </>
        )}
      </ImgContainer>
      <CropContainer>
        <div>
          {isCropping && rawImage && (
            <Cropper
              cta={intl.formatMessage({ id: 'new_event.basics.images.cropper_cta' })}
              src={rawImage.cdnUrl}
              mask={mask && SELECTOR_MASKS[mask]}
              crop={percentCrop}
              setCrop={doCrop}
            />
          )}
        </div>
        {mask && (
          <UsedFor>
            <div>{intl.formatMessage({ id: 'new_event.basics.images.used_for' })}</div>
            <div>
              {dice && <DiceBadge />}
              {intl.formatMessage({ id: `new_event.basics.images.used_for.${snakeCase(mask)}` })}
            </div>
          </UsedFor>
        )}
      </CropContainer>
    </CropImage>
  )
}

const EventCropPreviews: FC<React.PropsWithChildren<IProps>> = ({ noMask, readOnly, rawImage, images, onCrop }) => {
  const { user } = useContext(authContext)

  const [visibleCropper, setVisibleCropper] = useState<string | null>(null)

  const squareImg = useMemo(() => find(['type', 'square'], images), [images])

  if (noMask && squareImg) {
    return (
      <CropImages>
        <CropPreview
          rawImage={rawImage}
          img={squareImg}
          isCropping={visibleCropper === '_NO_MASK_'}
          setCropping={setVisibleCropper}
          onCrop={onCrop}
          readOnly={readOnly}
        />
      </CropImages>
    )
  }

  return (
    <CropImages>
      {squareImg && (
        <>
          {user.diceStaff && (
            <CropPreview
              rawImage={rawImage}
              img={squareImg}
              isCropping={visibleCropper === 'squareD'}
              setCropping={setVisibleCropper}
              onCrop={onCrop}
              readOnly={readOnly}
              mask="squareD"
              dice
            />
          )}
          <CropPreview
            rawImage={rawImage}
            img={squareImg}
            isCropping={visibleCropper === 'squareB'}
            setCropping={setVisibleCropper}
            onCrop={onCrop}
            readOnly={readOnly}
            mask="squareB"
          />
          <CropPreview
            rawImage={rawImage}
            img={squareImg}
            isCropping={visibleCropper === 'squareC'}
            setCropping={setVisibleCropper}
            onCrop={onCrop}
            readOnly={readOnly}
            mask="squareC"
          />
          <CropPreview
            rawImage={rawImage}
            img={squareImg}
            isCropping={visibleCropper === 'squareA'}
            setCropping={setVisibleCropper}
            onCrop={onCrop}
            readOnly={readOnly}
            mask="squareA"
          />
        </>
      )}
    </CropImages>
  )
}

export default memo(EventCropPreviews)
