/**
 * @generated SignedSource<<facc58d7c02ddfe0cd25b9381836e910>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useCountryRestrictionsActiveQuery$variables = Record<PropertyKey, never>;
export type useCountryRestrictionsActiveQuery$data = {
  readonly viewer: {
    readonly __typename: "Viewer";
    readonly countryRestrictionsActive: boolean | null;
  } | null;
};
export type useCountryRestrictionsActiveQuery = {
  response: useCountryRestrictionsActiveQuery$data;
  variables: useCountryRestrictionsActiveQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryRestrictionsActive",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCountryRestrictionsActiveQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useCountryRestrictionsActiveQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e8f8a3c8b66fbdd5e7e0f52066474f6",
    "id": null,
    "metadata": {},
    "name": "useCountryRestrictionsActiveQuery",
    "operationKind": "query",
    "text": "query useCountryRestrictionsActiveQuery {\n  viewer {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "24be81930a0ed573762842b78d7fa34e";

export default node;
