import React, { FC, memo, useContext, useMemo, useState } from 'react'
import { set, some } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../../utils/variables'

import SalesChartTooltip, { ITooltipModel, TooltipContainer } from './components/SalesChartTooltip'
import SalesChart from './components/SalesChart'
import useOptions from './hooks/useOptions'
import useRevenueReport from './hooks/useRevenueReport'
import { authContext } from '../../context/auth'
import useEventStats from './hooks/useEventStats'
import useAggregatedData from './hooks/useAggregatedData'
import { parseAtTimezone } from '../../utils/calendar'

interface IProps {
  eventId: string
}

const EventSalesChart: FC<IProps> = ({ eventId }) => {
  const { user } = useContext(authContext)
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const [tooltipModel, setTooltipModel] = useState<ITooltipModel | null>(null)

  const { event, data } = useRevenueReport(eventId)

  const stats = useEventStats(event || null)

  const showRevenue = useMemo(
    () =>
      some((p) => p && p.faceValue > 0, event?.products || []) ||
      some(
        (t) => t && (t.faceValue > 0 || some((pt) => pt && pt.faceValue > 0, t.priceTiers || [])),
        event?.ticketTypes || []
      ),
    [event?.products, event?.ticketTypes]
  )

  const [rawOptions, setOptions] = useOptions()

  const options = useMemo(
    () => (showRevenue || rawOptions.mode === 'sold' ? rawOptions : set('mode', 'sold', rawOptions)),
    [rawOptions, showRevenue]
  )

  const [evStartDate, evEndDate] = useMemo(
    () => [
      parseAtTimezone(event?.onSaleDate, event?.timezoneName),
      parseAtTimezone(event?.offSaleDate, event?.timezoneName),
    ],
    [event?.offSaleDate, event?.onSaleDate, event?.timezoneName]
  )
  const { labels, data: aggData, startDate, endDate } = useAggregatedData(options.groupBy, data, evStartDate, evEndDate)

  const showExtras = (user.diceStaff || !!event?.billingPromoter?.extrasEnabled) && stats.extrasAllocation > 0
  const showMerch = (user.diceStaff || !!event?.billingPromoter?.merchEnabled) && stats.merchAllocation > 0

  // TODO: enable when backend ready
  const showViews = false

  return (
    <TooltipContainer>
      <SalesChart
        costCurrency={event?.costCurrency || null}
        labels={labels}
        data={aggData}
        startDate={startDate}
        endDate={endDate}
        eventStats={stats}
        options={options}
        setOptions={setOptions}
        setTooltipModel={setTooltipModel}
        showExtras={showExtras}
        showMerch={showMerch}
        showViews={showViews}
        showRevenue={showRevenue}
      />

      {!isMobile && tooltipModel && (
        <SalesChartTooltip
          costCurrency={event?.costCurrency || null}
          data={aggData}
          tooltipModel={tooltipModel}
          byWeeks={options.groupBy === 'week'}
          showExtras={showExtras}
          showMerch={showMerch}
          showViews={showViews}
          showRevenue={showRevenue}
        />
      )}
    </TooltipContainer>
  )
}

export default memo(EventSalesChart)
