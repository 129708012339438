import React, { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { color, mediaQuery } from '../utils/variables'
import Banner from './Banner'
import { AccountDeactivatedBanner_viewer$key } from '../__generated__/AccountDeactivatedBanner_viewer.graphql'
import Svg from './Svg'

const StyledBanner = styled(Banner)`
  margin-bottom: 32px;
  color: ${color.text};
  background-color: #ff1c5e0f;

  a {
    white-space: nowrap;
    font-weight: bold;
  }

  ${mediaQuery.lessThan('desktop')`
    margin: 16px 16px 8px 16px;
  `}
`

interface IProps {
  viewer: AccountDeactivatedBanner_viewer$key
}

const AccountDeactivatedBanner: FC<React.PropsWithChildren<IProps>> = ({ viewer: viewerKey }) => {
  const intl = useIntl()

  const viewer = useFragment(
    graphql`
      fragment AccountDeactivatedBanner_viewer on Viewer {
        account {
          name
          isDisabled
          disabledReason
        }
      }
    `,
    viewerKey
  )

  const mailto = useMemo(
    () => `mailto:clientsuccess@dice.fm?subject=[${viewer.account?.name}] Reactivate DICE Account`,
    [viewer.account?.name]
  )

  if (!viewer.account?.isDisabled) return null

  const message =
    viewer.account?.disabledReason === 'TERMINATION'
      ? intl.formatMessage({ id: 'dashboard.banner.deactivated_account.termination' })
      : intl.formatMessage(
        { id: 'dashboard.banner.deactivated_account.inactivity' },
        { a: (str: string) => <a href={mailto}>{str}</a> }
      )

  return (
    <StyledBanner>
      <Svg icon="warning" className="color-error" />
      <span>{message}</span>
    </StyledBanner>
  )
}

export default AccountDeactivatedBanner
