/**
 * @generated SignedSource<<354678174f7aa1be1cddfc8133591092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type useAllowedAdhocFeesQuery$variables = {
  accountId: string;
  eventId?: string | null;
};
export type useAllowedAdhocFeesQuery$data = {
  readonly account: {
    readonly allowedAdhocFees?: ReadonlyArray<{
      readonly amount: number | null;
      readonly split: ReadonlyArray<{
        readonly amount: number | null;
        readonly destination: FeeDestination | null;
        readonly unit: FeeUnit;
      } | null> | null;
      readonly type: FeeType;
      readonly unit: FeeUnit;
    } | null> | null;
  } | null;
};
export type useAllowedAdhocFeesQuery = {
  response: useAllowedAdhocFeesQuery$data;
  variables: useAllowedAdhocFeesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eventId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "accountId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "eventId",
          "variableName": "eventId"
        }
      ],
      "concreteType": "Fee",
      "kind": "LinkedField",
      "name": "allowedAdhocFees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FeeSplit",
          "kind": "LinkedField",
          "name": "split",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "destination",
              "storageKey": null
            },
            (v3/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAllowedAdhocFeesQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAllowedAdhocFeesQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c40df68daf535f406d6aba9d45b97ed",
    "id": null,
    "metadata": {},
    "name": "useAllowedAdhocFeesQuery",
    "operationKind": "query",
    "text": "query useAllowedAdhocFeesQuery(\n  $accountId: ID!\n  $eventId: ID\n) {\n  account: node(id: $accountId) {\n    __typename\n    ... on Account {\n      allowedAdhocFees(eventId: $eventId) {\n        type\n        unit\n        amount\n        split {\n          destination\n          amount\n          unit\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "67a7f325f7feed2dcd017b0f9abe6c19";

export default node;
