import React, { FC, PropsWithChildren } from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import RcTooltip from 'rc-tooltip'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'

import { color, font, zIndex } from '../utils/variables'
import Svg from './Svg'

const Underlined = styled.div`
  display: inline-block;
  border-bottom: 1px dotted ${color.greyer};
  cursor: help;

  &:hover {
    color: ${color.primary};
    border-color: ${color.primary};
  }
`

const UnderlinedInline = styled(Underlined)`
  border: none;
  text-decoration: underline dotted ${color.grey};

  &:hover {
    text-decoration-color: ${color.primary};
  }
`

const TITLE_OFFSET = {
  top: { offset: [0, -6] },
  left: { offset: [-6, 0] },
  right: { offset: [6, 0] },
  bottom: { offset: [0, 6] },
}

interface IProps {
  handle: any
}

const Tooltip: FC<PropsWithChildren<IProps & Omit<Omit<TooltipProps, 'overlay'>, 'children'>>> = ({
  handle,
  children,
  ...props
}) => {
  return (
    <RcTooltip destroyTooltipOnHide overlay={children} {...props}>
      {handle}
    </RcTooltip>
  )
}

interface ITooltipProps {
  title: TooltipProps['overlay']
  preset?: 'undecorated' | 'underlined' | 'underlined-inline'
  prefixCls?: string
}

export const TitleTooltip: FC<PropsWithChildren<ITooltipProps & Omit<Omit<TooltipProps, 'overlay'>, 'children'>>> = ({
  title,
  placement,
  prefixCls,
  preset = 'undecorated',
  children,
  ...props
}) => {
  const trigger =
    title && preset === 'underlined' ? (
      <Underlined>{children}</Underlined>
    ) : title && preset === 'underlined-inline' ? (
      <UnderlinedInline>{children}</UnderlinedInline>
    ) : (
      (children as any)
    )

  return title ? (
    <RcTooltip
      prefixCls={prefixCls || 'title-tooltip'}
      destroyTooltipOnHide
      placement={placement || 'top'}
      overlay={title}
      align={TITLE_OFFSET[(placement || 'top') as keyof typeof TITLE_OFFSET]}
      {...props}
    >
      {trigger}
    </RcTooltip>
  ) : (
    trigger
  )
}

export const TooltipHelpIcon = styled(Svg)`
  margin: 0 0 -3px 6px;
  background-color: ${color.grey};
  color: ${color.white};
  border-radius: 50%;
  cursor: help;
`

export const TooltipStyles = createGlobalStyle`
.rc-tooltip {
  position: absolute;
  z-index: ${zIndex.tooltip};

  &.-fixed {
    position: fixed;
  }

  &.-mobile {
    position: fixed;
    top: unset !important;
    bottom: 0;
    left: 0;
    right: 0;

    .rc-tooltip-inner {
      box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.2);
    }
  }
}

.modal-open .rc-tooltip {
  z-index: ${zIndex.max};
}

.title-tooltip {
  position: absolute;
  z-index: ${zIndex.max};
  max-width: 228px;
}

.rc-tooltip-inner {
  padding: 24px;
  background-color: ${color.white};
  font-size: ${font.size.sm}px;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.title-tooltip-inner {
  padding: 8px 15px;
  background-color: ${color.text};
  color: ${color.white};
  font-size: ${font.size.sm}px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.rc-tooltip-hidden, .title-tooltip-hidden {
  display: none;
}

.title-tooltip-arrow,
.title-tooltip-arrow-inner{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.title-tooltip {
  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow{
    bottom: -4px;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: ${color.text};
  }

  &-placement-top &-arrow-inner,
  &-placement-topLeft &-arrow-inner,
  &-placement-topRight &-arrow-inner{
    bottom: 1px;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: ${color.text};
  }

  &-placement-top &-arrow {
    left: 50%;
  }

  &-placement-topLeft &-arrow {
    left: 15%;
  }

  &-placement-topRight &-arrow {
    right: 15%;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: -4px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: ${color.text};
  }

  &-placement-right &-arrow-inner,
  &-placement-rightTop &-arrow-inner,
  &-placement-rightBottom &-arrow-inner {
    left: 1px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: ${color.text};
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: -4px;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: ${color.text};
  }

  &-placement-left &-arrow-inner,
  &-placement-leftTop &-arrow-inner,
  &-placement-leftBottom &-arrow-inner {
    right: 1px;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: ${color.text};
  }

  &-placement-left &-arrow {
    top: 50%;
  }

  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: -4px;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: ${color.text};
  }

  &-placement-bottom &-arrow-inner,
  &-placement-bottomLeft &-arrow-inner,
  &-placement-bottomRight &-arrow-inner {
    top: 1px;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: ${color.text};
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }

  &-placement-bottomLeft &-arrow {
    left: 15%;
  }

  &-placement-bottomRight &-arrow {
    right: 15%;
  }
}
`

export default Tooltip
