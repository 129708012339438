import React, { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { color, mediaQuery, spacing } from '../utils/variables'

import Svg from './Svg'
import { textStyle } from '../utils/typography'

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${spacing.lg}px;
  min-width: 0;
  min-height: 230px;
  border-radius: 4px;
  ${mediaQuery.lessThan('tablet')`
    min-height: 198px;
    padding: ${spacing.lg}px ${spacing.md}px;
  `}
  & > svg {
    width: 60px;
    height: 60px;
    margin-bottom: 4px;
  }
`

const Content = styled.div``

const Header = styled.h2`
  ${textStyle.heading.lg}
  margin: 0 0 ${spacing.md}px;
  &:last-child {
    margin-bottom: 0;
  }
  & + ${Content} {
    margin-top: ${spacing.md}px;
  }
`

const Text = styled.p`
  ${textStyle.functional.md}
  color: ${color.darkgrey};
  margin: 0 0 24px;
  max-width: 500px;
  &:last-child {
    margin-bottom: 0;
  }
`

interface IProps {
  className?: string
  icon?: string
  title: string
  description?: string
  children?: ReactNode
}

const EmptyState: FC<React.PropsWithChildren<IProps>> = ({ className, icon, title, description, children }) => (
  <Wrapper className={className}>
    <Svg icon={icon || 'face-sad'} />
    <Header>{title}</Header>
    {description && <Text>{description}</Text>}
    {children && <Content>{children}</Content>}
  </Wrapper>
)

export default styled(EmptyState)``
