import React, { FC } from 'react'
import styled from 'styled-components/macro'

import { color as COLOR, color, font, input, spacing } from '../utils/variables'

import Svg from './Svg'

// Custom background opacities per color to optimize readability/match designs
const OPACITY_OVERRIDES = {
  [COLOR.warning]: 0.1,
}

const AlertBoxWrapper = styled.div<{ color?: string; fullWidth?: boolean }>`
  position: relative;
  display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'inline-flex')};
  padding: ${spacing.sm}px ${2 * spacing.sm}px ${spacing.sm}px ${spacing.sm}px;
  font-size: ${font.size.sm}px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ color }) => (color ? color : COLOR.primary)};
    border-radius: ${input.borderRadius}px;
    opacity: ${({ color }) => OPACITY_OVERRIDES[color || ''] || '0.05'};
  }
`
export const AlertBoxIcon = styled.div<{ color?: string }>`
  position: relative;
  display: flex;
  flex: 24px 0 0;
  margin: 0 6px 0 2px;
  color: ${({ color }) => (color ? color : COLOR.primary)};
  align-self: flex-start;
`
export const AlertBoxContent = styled.div`
  position: relative;
  padding: 2px 0;
`

const Title = styled.div`
  font-weight: ${font.weight.bold};
`

const Body = styled.div`
  color: ${color.darkgrey};
`

interface IAlertBox {
  color?: string
  fullWidth?: boolean
  className?: string
  children?: any
  icon?: string
  title?: string
  body?: string
}

const AlertBox: FC<React.PropsWithChildren<IAlertBox>> = ({
  color,
  fullWidth,
  className,
  children,
  icon = 'info',
  title,
  body,
}) => {
  return (
    <AlertBoxWrapper color={color} fullWidth={fullWidth} className={className}>
      <AlertBoxIcon color={color}>
        <Svg icon={icon} />
      </AlertBoxIcon>
      <AlertBoxContent>
        {title && <Title>{title}</Title>}
        {body && <Body>{body}</Body>}
        {children}
      </AlertBoxContent>
    </AlertBoxWrapper>
  )
}

export default styled(AlertBox)``
