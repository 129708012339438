import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { compact, concat, get, getOr, isNil, map, set, without, sortBy, find, findIndex } from 'lodash/fp'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { addSeconds, differenceInSeconds, formatDuration, formatISO, parseISO } from 'date-fns'
import DismissableBanner from '../../../components/DismissableBanner'
import { Form, FormRow } from '../../../components/Form'
import FormField, { FlexFormField, IStyledFormField } from '../../../components/FormField'
import SwitchField from '../../../components/SwitchField'
import { TitleTooltip, TooltipHelpIcon } from '../../../components/Tooltip'
import { color, font, mediaQuery } from '../../../utils/variables'
import IEventFormTickets from '../types/Tickets'
import IEventFormSettings from '../types/Settings'
import { allowedEventAction } from '../services/allowedEventAction'
import { localeContext } from '../../../context/locale'
import IconButton from '../../../components/IconButton'
import Banner from '../../../components/Banner'
import Svg from '../../../components/Svg'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { dateFnsLocales } from '../../../intl'
import { markAsClientOnly } from '../../../utils/entityStatus'
import { gtDate, ltDate } from '../../../utils/calendar'
import { Text } from '../../../components/Text'
import ListAddButton from '../../../components/ListAddButton'
import Collapsible from '../../../components/Collapsible'
import { autofillDate, generateDateFieldHint } from '../services/autofillDates'
import IEventForm from '../types'
import { authContext } from '../../../context/auth'

const PwlBanner = styled(DismissableBanner)`
  margin: 20px 0;
  max-width: 500px;

  font-size: ${font.size.sm}px;

  ${mediaQuery.lessThan('tablet')`
    max-width: unset;
  `}
`

const WindowsBanner = styled(Banner)`
  padding: 11px 14px 11px 10px;
  font-size: ${font.size.sm}px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  display: inline-flex;

  & > svg {
    margin-right: 6px;
  }

  ${mediaQuery.lessThan('tablet')`
    display: flex;
  `}
`

const Windows = styled(Form)`
  margin-top: 16px;
  padding: 0;
`

const Placeholder = styled.span`
  width: 48px;
`

const OnSale = styled.div`
  display: inline-block;
  white-space: nowrap;
  padding-left: 14px;
  margin-right: -10px;

  ${Text} {
    margin: 0 4px;
  }
`

const StyledCollapsible = styled(Collapsible)`
  margin-bottom: -24px;

  & > div {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .-collapsed ${PwlBanner} {
    display: flex;
  }

  ${PwlBanner} {
    margin: 8px 0 0 0;
    padding: 14px;
    display: none;
  }
`

const GreyFormField = styled(FormField)`
  & input {
    color: ${color.greyer};
  }
` as IStyledFormField

const StyledListAddButton = styled(ListAddButton)`
  margin-top: 8px;
`

interface IProps {
  readOnly?: boolean
}

const EventWaitingList: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { locale } = useContext(localeContext)

  const { values, touched, errors, setFieldValue, handleBlur } = useFormikContext<IEventFormTickets>()

  const { values: settingsValues } = useFormikContext<IEventFormSettings>()
  const isUnicornActive = get('flags.unicorn.active', settingsValues)

  const pwlWindows = useMemo(
    () =>
      sortBy((w) => {
        if (isNil(w?.offset) && !(w as any)?.isNew) return -Infinity
        return w?.offset || 0
      }, values.waitingListExchangeWindows || []),
    [values.waitingListExchangeWindows]
  )

  const toDurationOption = useCallback(
    (mins: number) => ({
      value: String(mins),
      label: formatDuration({ minutes: mins }, { locale: dateFnsLocales[locale] }),
    }),
    [locale]
  )

  const durationOptions = useMemo(() => map(toDurationOption, [20, 30, 45, 60, 90, 120]), [toDurationOption])

  const onChangeEnabledPwl = useCallback(() => {
    const isActive = getOr(false, 'flags.enabledPwl.active', values)
    if (isActive) {
      setFieldValue('flags.enabledPwl.active', false)
      if (allowedEventAction(values.allowedLifecycleUpdates, 'endDate')) {
        setFieldValue('flags.enabledPwl.deadline', null)
      }
    } else {
      setFieldValue('flags.enabledPwl.active', true)
      setFieldValue(
        'flags.enabledPwl.deadline',
        autofillDate(values as IEventForm, 'flags.enabledPwl.deadline', user.diceStaff, true),
        true
      )

      if (
        values.offSaleDate &&
        allowedEventAction(values.allowedLifecycleUpdates, 'endDate') &&
        pwlWindows.length === 0
      ) {
        const defaultWindow = markAsClientOnly<NonNullable<typeof pwlWindows[number]>>({
          duration: 60 * 60,
          offset: null,
        })

        setFieldValue('waitingListExchangeWindows', [defaultWindow])
      }
    }
  }, [values, setFieldValue, user.diceStaff, pwlWindows.length])

  const onChangeEnabledWl = useCallback(() => {
    const wasActive = getOr(false, 'flags.waitingList.active', values)
    setFieldValue('flags.waitingList.active', !wasActive)

    const isPwlActive = getOr(false, 'flags.enabledPwl.active', values)
    if (isPwlActive && wasActive) {
      onChangeEnabledPwl()
    }
  }, [values, setFieldValue, onChangeEnabledPwl])

  const handleChangePwlWindowOffset = useCallback(
    (id: string) => (_: string, value: string | null) => {
      const idx = findIndex(['id', id], pwlWindows)

      if (!value || !values.offSaleDate) {
        setFieldValue('waitingListExchangeWindows', set([idx, 'offset'], null, pwlWindows))
        return
      }
      const evDate = parseISO(values.offSaleDate)
      const newDate = parseISO(value)
      const newOffset = differenceInSeconds(newDate, evDate)

      setFieldValue('waitingListExchangeWindows', set([idx, 'offset'], newOffset, pwlWindows))
    },
    [pwlWindows, setFieldValue, values.offSaleDate]
  )

  const handleChangePwlWindowDuration = useCallback(
    (id: string) => (str: string) => {
      const value = str === null ? null : Number(str)
      const newDuration = value ? Math.round(value * 60) : null

      const idx = findIndex(['id', id], pwlWindows)
      setFieldValue('waitingListExchangeWindows', set([idx, 'duration'], newDuration, pwlWindows))
    },
    [setFieldValue, pwlWindows]
  )

  const firstWindowLabel = useMemo(
    () =>
      // prettier-ignore
      values.onSaleDate
        ? `${intl.formatDate(values.onSaleDate, {
          ...DATETIME_FORMATS.MEDIUM,
          timeZone: values.timezoneName || undefined,
        })} (${intl.formatDate(values.onSaleDate, {
          ...DATETIME_FORMATS.TIME(locale),
          timeZone: values.timezoneName || undefined,
        })})`
        : '',
    [intl, locale, values.onSaleDate, values.timezoneName]
  )

  const addWindow = useCallback(() => {
    const isFirst = pwlWindows.length === 0

    const newWindow = markAsClientOnly<NonNullable<typeof pwlWindows[number]>>({
      duration: 60 * 60,
      offset: null,
    })

    setFieldValue('waitingListExchangeWindows', compact(concat(pwlWindows, [set('isNew', !isFirst, newWindow)])))
  }, [setFieldValue, pwlWindows])

  const removeWindow = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset?.['rowid']
      setFieldValue('waitingListExchangeWindows', without([find(['id', id], pwlWindows)], pwlWindows || []))
    },
    [setFieldValue, pwlWindows]
  )

  const isDisabledDate = useMemo(
    () => [gtDate(values.offSaleDate, values.timezoneName), ltDate(values.onSaleDate, values.timezoneName)],
    [values]
  )

  const canEditPwl = !readOnly && allowedEventAction(values.allowedLifecycleUpdates, 'endDate')

  return (
    <>
      <FormRow columnOnMobile>
        <SwitchField
          label={
            <>
              {intl.formatMessage({ id: 'new_event.settings.flags.waiting_list.label' })}
              <TitleTooltip
                title={intl.formatMessage({
                  id: 'new_event.settings.flags.waiting_list.tooltip',
                })}
              >
                <TooltipHelpIcon icon="help" width={16} height={16} />
              </TitleTooltip>
            </>
          }
          hint={intl.formatMessage({ id: 'new_event.settings.flags.waiting_list.hint' })}
          error={touched.flags && get('flags.waitingList.active', errors)}
          name="flags.waitingList.active"
          checked={getOr(false, 'flags.waitingList.active', values)}
          onChange={onChangeEnabledWl}
          onBlur={handleBlur}
          disabled={readOnly}
        />
      </FormRow>

      {getOr(false, 'flags.waitingList.active', values) ? (
        <>
          <FormRow columnOnMobile>
            <SwitchField
              label={
                <>
                  {intl.formatMessage({ id: 'new_event.tickets.flags.pwl_enabled.label' })}
                  <TitleTooltip
                    title={intl.formatMessage({
                      id: 'new_event.settings.flags.pwl_enabled.tooltip',
                    })}
                  >
                    <TooltipHelpIcon icon="help" width={16} height={16} />
                  </TitleTooltip>
                </>
              }
              hint={intl.formatMessage({ id: 'new_event.settings.flags.pwl_enabled.hint' })}
              error={touched.flags && get('flags.enabledPwl.active', errors)}
              name="flags.enabledPwl.active"
              checked={getOr(false, 'flags.enabledPwl.active', values)}
              onChange={onChangeEnabledPwl}
              disabled={!canEditPwl || isUnicornActive}
              title={
                isUnicornActive
                  ? intl.formatMessage({ id: 'new_event.settings.flags.pwl_enabled.not_compatible_with_cart' })
                  : undefined
              }
            />
          </FormRow>

          {getOr(false, 'flags.enabledPwl.active', values) && (
            <FormRow columnOnMobile>
              <FormField
                name="flags.enabledPwl.deadline"
                timezone={values.timezoneName || undefined}
                label={intl.formatMessage({ id: 'new_event.tickets.pwl_deadline.label' })}
                control="datetime"
                value={getOr(null, 'flags.enabledPwl.deadline', values)}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                error={get('flags.enabledPwl.deadline', touched) && get('flags.enabledPwl.deadline', errors)}
                locale={locale}
                disabled={!canEditPwl}
                required
                hint={generateDateFieldHint(
                  intl,
                  values as IEventForm,
                  'RETURN_DEADLINE',
                  getOr(null, 'flags.enabledPwl.deadline', values)
                )}
              />
            </FormRow>
          )}

          {getOr(false, 'flags.waitingList.active', values) && (
            <FormRow>
              <StyledCollapsible
                label={intl.formatMessage({ id: 'new_event.tickets.pwl_windows.label' })}
                hint={
                  <>
                    {intl.formatMessage({ id: 'new_event.tickets.pwl_windows.hint' })}
                    <PwlBanner icon="bulb" dismissKey="pwlWindowsHint">
                      {intl.formatMessage({ id: 'new_event.tickets.pwl_windows_time_banner' })}
                    </PwlBanner>
                  </>
                }
                data-name="waitingListExchangeWindows"
                initialCollapsed={!(pwlWindows.length > 1 || pwlWindows[0]?.duration !== 60 * 60)}
              >
                <Windows spacing="small">
                  {canEditPwl && (
                    <FormRow>
                      <WindowsBanner>
                        <Svg icon="info-msg" />
                        <div>{intl.formatMessage({ id: 'new_event.tickets.pwl_windows_banner' })}</div>
                      </WindowsBanner>
                    </FormRow>
                  )}
                  {pwlWindows?.map((pwlWindow, idx) => {
                    if (!pwlWindow) return null

                    return (
                      <FormRow columnOnMobile key={pwlWindow.id || `window-${idx}`} spacing="small">
                        {idx === 0 ? (
                          <GreyFormField
                            key="first"
                            name="waitingListExchangeWindows[0].offset"
                            value={firstWindowLabel}
                            error={get('waitingListExchangeWindows[0].offset', errors)}
                            prefix={
                              <OnSale>
                                {intl.formatMessage({ id: 'new_event.tickets.pwl_windows.on_sale_label' })}
                                {values.onSaleDate && <Text color="greyer">&nbsp;&ndash;</Text>}
                              </OnSale>
                            }
                            disabled
                          />
                        ) : (
                          <FormField
                            key={`offset-${idx}`}
                            name={`waitingListExchangeWindows[${idx}].offset`}
                            timezone={values.timezoneName || undefined}
                            control="datetime"
                            value={
                              !isNil(pwlWindow.offset) && values.offSaleDate
                                ? formatISO(addSeconds(parseISO(values.offSaleDate), pwlWindow.offset || 0))
                                : null
                            }
                            setFieldValue={handleChangePwlWindowOffset(pwlWindow.id)}
                            onBlur={handleBlur}
                            error={get(`waitingListExchangeWindows[${idx}].offset`, errors)}
                            locale={locale}
                            disabled={!values.offSaleDate || !canEditPwl}
                            disabledDates={isDisabledDate}
                          />
                        )}

                        <FlexFormField
                          key={`duration-${idx}`}
                          name={`waitingListExchangeWindows[${idx}].duration`}
                          control="select"
                          value={
                            pwlWindow.duration ? toDurationOption(Math.round((pwlWindow.duration || 0) / 60)) : null
                          }
                          onChange={handleChangePwlWindowDuration(pwlWindow.id)}
                          onBlur={handleBlur}
                          disabled={!canEditPwl}
                          error={
                            get(`waitingListExchangeWindows[${idx}].duration`, touched) &&
                            get(`waitingListExchangeWindows[${idx}].duration`, errors)
                          }
                          options={durationOptions}
                        >
                          {idx > 0 && canEditPwl ? (
                            <IconButton
                              data-id="removePwlWindowButton"
                              data-rowid={pwlWindow.id}
                              icon="trash"
                              onClick={removeWindow}
                            />
                          ) : (
                            <Placeholder />
                          )}
                        </FlexFormField>
                      </FormRow>
                    )
                  })}
                  {canEditPwl && (pwlWindows?.length || 0) < 3 && (
                    <FormRow>
                      <StyledListAddButton
                        data-id="addPwlWindowButton"
                        label={intl.formatMessage({ id: 'new_event.tickets.pwl_windows.add_button' })}
                        onClick={addWindow}
                      />
                    </FormRow>
                  )}
                </Windows>
              </StyledCollapsible>
            </FormRow>
          )}
        </>
      ) : (
        <PwlBanner icon="bulb" dismissKey="pwlCapabilities">
          {intl.formatMessage({ id: 'new_event.tickets.pwl_capabilities_banner' })}
        </PwlBanner>
      )}
    </>
  )
}

export default memo(EventWaitingList)
