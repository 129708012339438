/**
 * @generated SignedSource<<45afa326c654d4d824fd7a37cbb15636>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChannelType = "APP_SALE" | "BOX_OFFICE_SALE" | "HOLD" | "NON_DICE" | "OTHER" | "%future added value";
export type CreateEventSeatingChartInput = {
  clientMutationId: string;
  socialDistancingRulesetKey?: string | null;
  venueChartId: string;
};
export type EventTicketTypesConfigSeatmapMutation$variables = {
  input: CreateEventSeatingChartInput;
};
export type EventTicketTypesConfigSeatmapMutation$data = {
  readonly createEventSeatingChart: {
    readonly messages: ReadonlyArray<{
      readonly code: string;
      readonly field: string | null;
      readonly message: string | null;
    } | null> | null;
    readonly result: {
      readonly chartManagerCredentials: {
        readonly secretKey: string;
      };
      readonly id: string;
      readonly seatingChannels: ReadonlyArray<{
        readonly channelType: ChannelType;
        readonly name: string | null;
        readonly seatsIoChannel: string;
      } | null> | null;
      readonly seatsIoEventId: string;
      readonly seatsIoEventReport: IBackendMap | null;
    } | null;
    readonly successful: boolean;
  } | null;
};
export type EventTicketTypesConfigSeatmapMutation = {
  response: EventTicketTypesConfigSeatmapMutation$data;
  variables: EventTicketTypesConfigSeatmapMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateEventSeatingChartPayload",
    "kind": "LinkedField",
    "name": "createEventSeatingChart",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationMessage",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EventSeatingChart",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "seatsIoEventId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "group",
                "value": "BY_CATEGORY_KEY"
              }
            ],
            "kind": "ScalarField",
            "name": "seatsIoEventReport",
            "storageKey": "seatsIoEventReport(group:\"BY_CATEGORY_KEY\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChartManagerCredentials",
            "kind": "LinkedField",
            "name": "chartManagerCredentials",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secretKey",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SeatingChannel",
            "kind": "LinkedField",
            "name": "seatingChannels",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "seatsIoChannel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "channelType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventTicketTypesConfigSeatmapMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventTicketTypesConfigSeatmapMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "728d4e250eff331c8623bfea420778b7",
    "id": null,
    "metadata": {},
    "name": "EventTicketTypesConfigSeatmapMutation",
    "operationKind": "mutation",
    "text": "mutation EventTicketTypesConfigSeatmapMutation(\n  $input: CreateEventSeatingChartInput!\n) {\n  createEventSeatingChart(input: $input) {\n    messages {\n      code\n      field\n      message\n    }\n    result {\n      id\n      seatsIoEventId\n      seatsIoEventReport(group: BY_CATEGORY_KEY)\n      chartManagerCredentials {\n        secretKey\n      }\n      seatingChannels {\n        seatsIoChannel\n        channelType\n        name\n      }\n    }\n    successful\n  }\n}\n"
  }
};
})();

(node as any).hash = "e98fffa69ba41b433c844d9275dd95a7";

export default node;
