import React, { ComponentProps } from 'react'
import Responsive from 'react-responsive'
import { breakpoints } from '../utils/variables'

type IProps = ComponentProps<typeof Responsive>

export const OnMobile = (props: IProps) => <Responsive {...props} maxWidth={breakpoints.desktop - 1} />
export const OnDesktop = (props: IProps) => <Responsive {...props} minWidth={breakpoints.desktop} />
export const OnDesktopLarge = (props: IProps) => <Responsive {...props} minWidth={breakpoints.desktopLarge} />

export const LessThanDesktopLarge = (props: IProps) => <Responsive {...props} maxWidth={breakpoints.desktopLarge - 1} />
