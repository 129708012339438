/**
 * @generated SignedSource<<00dd38e522172e620eed909a9def8aed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type trackSearchArtistSpotifyQuery$variables = {
  q: string;
};
export type trackSearchArtistSpotifyQuery$data = {
  readonly spotify: {
    readonly search: {
      readonly artists: {
        readonly items: ReadonlyArray<{
          readonly externalUrls: {
            readonly spotify: string;
          } | null;
          readonly images: ReadonlyArray<{
            readonly url: string;
          } | null> | null;
          readonly name: string;
          readonly value: string;
        } | null>;
      } | null;
    } | null;
  } | null;
};
export type trackSearchArtistSpotifyQuery = {
  response: trackSearchArtistSpotifyQuery$data;
  variables: trackSearchArtistSpotifyQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SpotifyViewer",
    "kind": "LinkedField",
    "name": "spotify",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "limit",
            "value": 10
          },
          {
            "kind": "Variable",
            "name": "q",
            "variableName": "q"
          },
          {
            "kind": "Literal",
            "name": "type",
            "value": [
              "artist"
            ]
          }
        ],
        "concreteType": "SpotifySearchResponse",
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SpotifyArtistsResponse",
            "kind": "LinkedField",
            "name": "artists",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SpotifyArtistObject",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": "value",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpotifyExternalUrlObject",
                    "kind": "LinkedField",
                    "name": "externalUrls",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spotify",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpotifyImagesObject",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackSearchArtistSpotifyQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackSearchArtistSpotifyQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a3bd7a8329b2b43373283bd4b7ac0d9",
    "id": null,
    "metadata": {},
    "name": "trackSearchArtistSpotifyQuery",
    "operationKind": "query",
    "text": "query trackSearchArtistSpotifyQuery(\n  $q: String!\n) {\n  spotify {\n    search(limit: 10, q: $q, type: [artist]) {\n      artists {\n        items {\n          value: id\n          name\n          externalUrls {\n            spotify\n          }\n          images {\n            url\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "52ecd3c616f3da6c0e89c65e7bb7cb48";

export default node;
