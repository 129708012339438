import React, { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { findIndex, set } from 'lodash/fp'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import checkOverallocation from '../../../utils/checkOverallocation'
import useMaxSeatsCapacity from '../../EventForm/hooks/useMaxSeatsCapacity'
import { useVenueAllocationCheck_event$key } from '../../../__generated__/useVenueAllocationCheck_event.graphql'
import { localeContext } from '../../../context/locale'

interface ITty {
  value: string
  isStream: boolean | null
}

interface IPt {
  value: string
}

function useVenueAllocationCheck(
  eventKey: useVenueAllocationCheck_event$key | null,
  tty: ITty | null,
  pt: IPt | null,
  allocation: number | null
) {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const event = useFragment(
    graphql`
      fragment useVenueAllocationCheck_event on Event {
        eventType
        addressCountry
        countryCode

        eventSeatingChart {
          id
        }

        venues {
          value: id
          capacity
          addressCountry
          countryCode
        }

        primaryVenue {
          value: id
        }

        ticketTypes {
          id
          hidden
          allocation
          isStream
          archived
          priceTierType
          priceTiers {
            id
            allocation
          }
        }
      }
    `,
    eventKey
  )

  const maxSeatsCapacity = useMaxSeatsCapacity(event?.eventSeatingChart?.id || null)

  const [isOverallocated, totalCapacityInfo] = useMemo(() => {
    if (event?.eventType === 'STREAM' || tty?.isStream) return [false, null]

    let patchedEvent = event

    if (pt) {
      const ttIdx = findIndex(['id', tty?.value], event?.ticketTypes)
      if (ttIdx >= 0) {
        const ptIdx = findIndex(['id', pt.value], event?.ticketTypes?.[ttIdx]?.priceTiers)
        if (ptIdx >= 0) {
          patchedEvent = event && set(['ticketTypes', ttIdx, 'priceTiers', ptIdx, 'allocation'], allocation || 0, event)
        }
      }
    } else if (tty) {
      const idx = findIndex(['id', tty.value], event?.ticketTypes)
      if (idx >= 0) {
        patchedEvent = event && set(['ticketTypes', idx, 'allocation'], allocation || 0, event)
      }
    }

    const { overallocation, venueCapacity, totalAllocationLive } = checkOverallocation(
      patchedEvent,
      locale,
      maxSeatsCapacity
    )

    const isOverallocated = overallocation > 0

    let totalCapacityInfo = null

    if (venueCapacity > 0) {
      // prettier-ignore
      totalCapacityInfo = isOverallocated
        ? intl.formatMessage(
          { id: 'venue_capacity_allocation.warning' },
          { number: overallocation, capacity: venueCapacity, b: (str: string) => <strong>{str}</strong> }
        )
        : intl.formatMessage(
          { id: 'venue_capacity_allocation' },
          {
            capacity: venueCapacity,
            allocation: totalAllocationLive,
            number: venueCapacity - totalAllocationLive,
            b: (str: string) => <strong>{str}</strong>,
          }
        )
    }

    return [isOverallocated, totalCapacityInfo]
  }, [allocation, event, intl, locale, maxSeatsCapacity, pt, tty])

  return [isOverallocated, totalCapacityInfo]
}

export default useVenueAllocationCheck
