import {
  compose,
  filter,
  map,
  update,
  uniqBy,
  orderBy,
  getOr,
  mapValues,
  keyBy,
  toPairs,
  values,
  sortBy,
  compact,
  set,
  mergeAll,
  lowerCase,
  capitalize,
  flatMap,
  groupBy,
} from 'lodash/fp'
import { IntlShape } from 'react-intl'
import { Dictionary } from 'ts-essentials'

export const PREDEFINED_ROLE_NAMES = [
  'admin',
  'product',
  'finance',
  'account_manager',
  'partner_services',
  'event_services',
  'fan_support',
  'editorial',

  'client_admin',
  'client_finance',
  'client_create_and_manage',
  'client_manage_only',
  'client_junior_manage',
  'client_read_only',

  'box_office_manager',
  'box_office_agent',
  'marketing',
  'bar_manager',
]

const CATEGORIES_ORDER = [
  'event_creation',
  'event_allocation',
  'event_marketing',
  'event_ops_and_finance',
  'event_dice_only',
]

export type IPermissionStructure = Array<{ name: string; actions: Array<{ name: string }> }>

export interface IPermissionProfile {
  id: string
  roleName?: string
  caption: string

  subjects: null | ReadonlyArray<{
    name: string | null
    actions: ReadonlyArray<{
      name: string | null
    } | null> | null
  } | null>
}

export const permissionMap: (struct: IPermissionStructure) => Dictionary<Dictionary<{ name: string }>> = compose([
  mapValues((v: IPermissionStructure[number]) => keyBy('name', v.actions || [])),
  keyBy('name'),
])

const permissionStructure: (map: Dictionary<Dictionary<{ name: string }>>) => IPermissionStructure = compose([
  orderBy(['actions.length', 'name'], ['desc', 'asc']),
  filter((s: { name: string; actions: Array<{ name: string }> }) => (s.actions?.length || 0) > 0),
  map(([k, v]) => ({ name: k, actions: values(v) })),
  toPairs,
])

export const extractStructure: (pp: Pick<IPermissionProfile, 'subjects'> | null) => IPermissionStructure = compose([
  filter((s: IPermissionStructure[number]) => (s.actions?.length || 0) > 0),
  map(update('actions', compose([uniqBy('name'), filter('name')]))),
  uniqBy('name'),
  filter('name'),
  getOr([], 'subjects'),
])

export const flattenPermissionStructure = (struct: IPermissionStructure): string[] =>
  flatMap((subj: IPermissionStructure[number]) => map((a) => `${a.name}:${subj.name}`, subj.actions), struct)

export const unflattenPermissionList = (perms: string[]): IPermissionStructure => {
  const result = new Map<string, Array<{ name: string }>>()

  perms.forEach((p) => {
    const [action, subject] = p.split(':')
    result.set(subject, result.get(subject) || [])
    result.get(subject)?.push({ name: action })
  })

  const arr = []

  for (const k of result.keys()) {
    arr.push({ name: k, actions: result.get(k) || [] })
  }

  return arr
}

export const sortProfiles: (arr: ReadonlyArray<IPermissionProfile | null> | null) => IPermissionProfile[] = compose([
  sortBy((pp: IPermissionProfile) => {
    const idx = PREDEFINED_ROLE_NAMES.indexOf(pp.roleName || '')
    return idx >= 0 ? idx : 99999999
  }),
  compact,
])

export type IMaxPermissionAction = { name: string; label: string; comment: string; tooltip: string }
export type IMaxPermissionCategory = {
  name: string
  label: string
  actions: Array<IMaxPermissionAction>
}

export type IMaxPermissionStructure = Array<{
  name: string
  label: string
  actions: Array<IMaxPermissionAction>
  categories: Array<IMaxPermissionCategory>
}>

export const extractMaxPermissionStructure: (
  intl: IntlShape
) => (profiles: ReadonlyArray<Pick<IPermissionProfile, 'subjects'> | null>) => IMaxPermissionStructure = (intl) =>
  compose([
    map(
      compose([
        (subj) =>
          set(
            'categories',
            compose([
              sortBy((category: IMaxPermissionCategory) => CATEGORIES_ORDER.indexOf(category.name)),
              map(([name, actions]) => ({
                name,
                actions,
                label: intl.formatMessage({
                  // @intl-meta project:Permissions
                  id: `permissions_action_category.${subj.name}.${name}`,
                  defaultMessage: '_not_available_',
                }),
              })),
              toPairs,
              groupBy('category'),
              (actions) => filter((item) => item.category, actions),
            ])(subj.actions),
            subj
          ),
        (subj) =>
          update(
            'actions',
            compose([
              map((action: { name: string }) =>
                compose([
                  set(
                    'label',
                    intl.formatMessage({
                      // @intl-meta project:Permissions
                      id: `permissions_action.${subj.name}.${action.name}`,
                      defaultMessage: capitalize(lowerCase(action.name)),
                    })
                  ),
                  update('tooltip', (text) => (text === '_not_available_' ? null : text)),
                  set(
                    'tooltip',
                    intl.formatMessage({
                      // @intl-meta project:Permissions
                      id: `permissions_action_description.${subj.name}.${action.name}`,
                      defaultMessage: '_not_available_',
                    })
                  ),
                  update('comment', (text) => (text === '_not_available_' ? null : text)),
                  set(
                    'comment',
                    intl.formatMessage({
                      // @intl-meta project:Permissions
                      id: `permissions_action_comment.${subj.name}.${action.name}`,
                      defaultMessage: '_not_available_',
                    })
                  ),
                ])(action)
              ),
              // filter event actions with no category, as this indicates the promoter doesn't have that permission
              filter((action: { category: string }) => (subj.name === 'event' ? !!action.category : true)),
            ]),
            subj
          ),
        (subj) =>
          set(
            'label',
            intl.formatMessage({
              // @intl-meta project:Permissions
              id: `permissions_subject.${subj.name}`,
              defaultMessage: capitalize(lowerCase(subj.name)),
            }),
            subj
          ),
      ])
    ),
    permissionStructure,
    mergeAll,
    map(compose([permissionMap, extractStructure])),
  ])
