import React, { FC, useCallback, useState, memo, useMemo } from 'react'
import { filter, size, map } from 'lodash/fp'
import styled from 'styled-components/macro'
import { color } from '../utils/variables'
import Svg from './Svg'
import TextInput, { ITextInputProps } from './TextInput'

const AdjustedTextInput = styled(TextInput)`
  padding-right: 64px;
`

const ControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
`

const EyeButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 8px 8px 8px 4px;
`

const DotsWrapper = styled.div`
  display: inline-block;
  align-self: center;
  margin-bottom: 4px;
`

const SECURITY_STAGES = [0, 4, 9]
const DOTS = [
  [[color.grey, '•••']],
  [
    [color.error, '•'],
    [color.grey, '••'],
  ],
  [
    [color.warning, '••'],
    [color.grey, '•'],
  ],
  [[color.secondary, '•••']],
]

interface IDotsProps {
  stage: number
}

const Dots: FC<React.PropsWithChildren<IDotsProps>> = ({ stage }) => {
  const dotsSchema = DOTS[stage]

  const dotsHtml = map((item) => {
    const [color, text] = item
    return (
      <span key={color} style={{ color }}>
        {text}
      </span>
    )
  }, dotsSchema)

  return <DotsWrapper>{dotsHtml}</DotsWrapper>
}

interface IControlsProps {
  stage: number
  toggleShowPassword: () => void
}

const Controls: FC<React.PropsWithChildren<IControlsProps>> = ({ toggleShowPassword, stage }) => {
  return (
    <ControlsWrapper>
      <Dots stage={stage} />
      <EyeButton>
        <Svg icon="eye" onClick={toggleShowPassword} />
      </EyeButton>
    </ControlsWrapper>
  )
}

export type INewPasswordProps = {
  className?: string
  inputClassName?: string
  name?: string
  value?: string
  disabled?: boolean
  required?: boolean
  hasError?: boolean
  onChange?: (e: Event) => void
  onPressEnter?: (e: Event) => void
  onFocus?: (e: Event) => void
  onBlur?: (e: Event) => void
  onKeyDown?: (e: Event) => void
  autoFocus?: boolean
} & ITextInputProps

const NewPasswordInput: FC<React.PropsWithChildren<INewPasswordProps>> = ({ value, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = useCallback(() => setShowPassword((v) => !v), [])

  const stage = useMemo(() => size(filter((i) => (value?.length || 0) > i, SECURITY_STAGES)), [value?.length])

  return (
    <AdjustedTextInput
      type={showPassword ? 'text' : 'password'}
      affix={<Controls stage={stage} toggleShowPassword={toggleShowPassword} />}
      autoComplete="new-password"
      value={value}
      {...rest}
    />
  )
}

export default memo(NewPasswordInput)
