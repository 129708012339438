import React, { useContext } from 'react'
import styled, { css } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { isSameDay } from 'date-fns'
import EventTimelineContext from '../../util/eventTimelineContext'
import Svg from '../../../../components/Svg'
import { CELL_WIDTH } from '../../util/eventTimelineVariables'
import { TitleTooltip } from '../../../../components/Tooltip'
import { parseAtTimezone } from '../../../../utils/calendar'

const Container = styled.div`
  width: 100%;
  padding: 8px 0;
  height: 40px;
  position: relative;
`

const SaleLine = styled.div<{ offset: number; width: number }>`
  position: absolute;
  background: #0000fe;
  height: 3px;
  top: 19px;
  ${(props) => css`
    left: ${props.offset + CELL_WIDTH / 2}px;
    width: ${props.width}px;
  `}
`

const EventLine = styled.div<{ offset: number; width: number }>`
  position: absolute;
  background: rgba(230, 230, 255, 0.45);
  height: 26px;
  top: 8px;
  ${(props) => css`
    left: ${props.offset + CELL_WIDTH / 2}px;
    width: ${props.width}px;
  `}
`

const Day = styled.div<{ offset: number; joined?: boolean }>`
  position: absolute;
  top: 8px;
  left: ${(props) => props.offset + 2}px;
  ${(props) =>
    props.joined &&
    css`
      &:last-child {
        filter: drop-shadow(-2px 0px 2px rgba(0, 0, 0, 0.1));
        transform: translateX(8px);
      }
      &:first-child {
        transform: translateX(-8px);
      }
    `};
  color: #0000fe;
  background-color: #e6e6ff;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  padding: 4px;
  svg {
    width: 18px;
    height: 18px;
  }
`

const TimelineBaseDates = () => {
  const intl = useIntl()
  const { dateOffset, event } = useContext(EventTimelineContext)

  const onSaleOffset = dateOffset(event.onSaleDate)
  const offSaleOffset = dateOffset(event.offSaleDate)
  const announceOffset = dateOffset(event.announceDate)
  const eventStartOffset = dateOffset(event.date)
  const eventEndOffset = dateOffset(event.endDate)

  const isOneDay = isSameDay(
    parseAtTimezone(event.date, event.timezoneName) || new Date(),
    parseAtTimezone(event.endDate, event.timezoneName) || new Date()
  )

  return (
    <Container>
      <SaleLine offset={onSaleOffset} width={offSaleOffset - onSaleOffset} />

      <div>
        <TitleTooltip title={intl.formatMessage({ id: 'new_event.timeline.announced.label' })} placement="top">
          <Day offset={announceOffset} joined={announceOffset === onSaleOffset}>
            <Svg icon="megaphone" />
          </Day>
        </TitleTooltip>
        <TitleTooltip title={intl.formatMessage({ id: 'new_event.timeline.on_sale.label' })} placement="top">
          <Day offset={onSaleOffset} joined={announceOffset === onSaleOffset}>
            <Svg icon="ticket" />
          </Day>
        </TitleTooltip>
      </div>

      {!isOneDay && <EventLine offset={eventStartOffset} width={eventEndOffset - eventStartOffset} />}

      <div>
        <TitleTooltip title={intl.formatMessage({ id: 'new_event.timeline.off_sale.label' })} placement="top">
          <Day offset={offSaleOffset} joined={offSaleOffset === eventStartOffset || offSaleOffset === eventEndOffset}>
            <Svg icon="ticket-cancelled" />
          </Day>
        </TitleTooltip>

        <TitleTooltip
          title={intl.formatMessage({ id: isOneDay ? 'event' : 'new_event.timeline.start.label' })}
          placement="top"
        >
          <Day offset={eventStartOffset} joined={offSaleOffset === eventStartOffset}>
            <Svg icon="celebrate" />
          </Day>
        </TitleTooltip>

        {!isOneDay && (
          <TitleTooltip title={intl.formatMessage({ id: 'new_event.timeline.end.label' })} placement="top">
            <Day offset={eventEndOffset} joined={offSaleOffset === eventEndOffset || onSaleOffset === eventEndOffset}>
              <Svg icon="celebrate" />
            </Day>
          </TitleTooltip>
        )}
      </div>
    </Container>
  )
}

export default TimelineBaseDates
