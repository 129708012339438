/**
 * @generated SignedSource<<c58c83cb3827186aa08a4e72ff1f91fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useEventSubmissionQuery$variables = Record<PropertyKey, never>;
export type useEventSubmissionQuery$data = {
  readonly viewer: {
    readonly events: {
      readonly count: number;
    } | null;
  } | null;
};
export type useEventSubmissionQuery = {
  response: useEventSubmissionQuery$data;
  variables: useEventSubmissionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "EventConnection",
  "kind": "LinkedField",
  "name": "events",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "storageKey": "events(first:1)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useEventSubmissionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useEventSubmissionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fda720c5f43ede87b0775a3ddab47d41",
    "id": null,
    "metadata": {},
    "name": "useEventSubmissionQuery",
    "operationKind": "query",
    "text": "query useEventSubmissionQuery {\n  viewer {\n    events(first: 1) {\n      count\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c25c407eb103e8287c8732657b4fabf9";

export default node;
