import { EditorState } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import { marked } from 'marked'
import TurndownService from 'turndown'
import { sanitize } from 'dompurify'

/*
  NOTE: We don't directly convert Draft.js state to markdown
  There are libraries that support such a conversion,
  but unfortunately all of them don't escape markdown special symbols well
  so markup is "leaking" and messing end result.

  To avoid this we do Draft.js -> HTML -> Markdown conversion flow
  As HTML to Markdown converters are much more sophisticated
  and allow to escape user input properly
  so when user is typing `2 * 2 * 3 = 12` this don't make middle 2 italic
*/

class MyTurndown extends TurndownService {
  HOLDER = '%mio!LINK!mio%'

  // Use original escape but preserve links untouched
  escape(str: string): string {
    const links = str.match(/(https?:\/\/\S+)/g)

    const safe = str.replace(/(https?:\/\/\S+)/g, this.HOLDER)

    let esc = super.escape(safe)
    links?.forEach((l) => {
      esc = esc.replace(this.HOLDER, l)
    })

    return esc
  }
}

const turndownService = new MyTurndown({
  bulletListMarker: '-',
  hr: '---',
  codeBlockStyle: 'fenced',
  emDelimiter: '*',
  strongDelimiter: '**',
})

export const parseMarkdown = (md: string | null | undefined) => {
  if (!md) return EditorState.createEmpty()

  const html = markdownToHtml(md)
  const content = convertFromHTML(html)

  return EditorState.createWithContent(content)
}

export const renderMarkdown = (draft: EditorState | null) => {
  if (!draft || !draft.getCurrentContent().hasText()) return null

  const content = draft.getCurrentContent()
  const html = convertToHTML(content)

  return htmlToMarkdown(html)
}

export const htmlToMarkdown = (html: string | null | undefined): string => {
  if (!html) return ''

  const clean = sanitize(html, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] })
  const md = turndownService.turndown(clean).replace(/-\s{2}/g, '-').replace(/(\d+\.)\s{2}/g, '$1 ')

  return md
}

export const markdownToHtml = (md: string | null | undefined): string => {
  const renderer = new marked.Renderer({})

  renderer.link = (href, title, text) =>
    `<a target="_blank" rel="noopener noreferrer" href="${href}" title="${title || ''}">${text}</a>`

  const html = md && marked(md, { renderer })

  const clean = html && sanitize(html, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] })

  return clean || ''
}
