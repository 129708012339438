import React, { memo, useMemo, FC, useCallback, useState, useContext } from 'react'
import styled from 'styled-components//macro'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { compact, compose, reject, keyBy, mapValues, get } from 'lodash/fp'
import { useIntl } from 'react-intl'
import Responsive from 'react-responsive'
import { parseISO } from 'date-fns'

import { color, mediaQuery, breakpoints, font } from '../../utils/variables'
import { EventSuccessRow_attrs$key } from '../../__generated__/EventSuccessRow_attrs.graphql'
import { DATETIME_FORMATS } from '../../utils/formatters/datetime'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import Svg from '../../components/Svg'
import QuickEditModal from './components/QuickEditModal'
import { localeContext } from '../../context/locale'
import { CURRENCY } from '../../utils/formatters/number'

const Faint = styled.div`
  font-size: ${font.size.sm}px;
  line-height: 17px;
  color: ${color.darkgrey};
  margin-top: 4px;
`

const Buttons = styled.div`
  & > * {
    width: 100%;
    min-width: 180px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
  }

  & > div > ${Button} {
    min-width: 118px;
  }

  ${mediaQuery.lessThan('tablet')`
    & > * {
      width: 100%;
      min-width: 90px;
    }

    & > div {
      justify-content: flex-end;
    }

    & > div > ${IconButton} {
      margin: 0;
    }

    & > div > ${IconButton}:last-child {
      margin-left: 8px;
    }
  `}
`

const EventRowData = styled.li`
  display: flex;
  margin-bottom: 32px;
  border: 2px solid ${color.text};
`

const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 95px;
  min-width: 95px;
  min-height: 95px;

  background-color: ${color.text};
  color: ${color.white};
  font-size: ${font.size.base}px;

  & > div {
    font-size: ${font.size.xl}px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  ${mediaQuery.lessThan('tablet')`
    display: none;
  `}
`

const EventDetails = styled.div`
  padding: 27px 24px;
  display: flex;
  width: 100%;

  & > div {
    flex: 1;
    margin-left: 16px;
    font-size: ${font.size.base}px;
    line-height: 20px;
  }

  & > div:first-child {
    flex: 1.5;
    margin-left: 0;
  }

  ${mediaQuery.lessThan('tablet')`
    & > div {
      display: none;
    }

    & > div:first-child {
      display: block;
    }

    & > div:last-child {
      display: block;
      flex: none;
    }
  `}
`

interface IProps {
  attrs: EventSuccessRow_attrs$key
  submitting: boolean
  doSubmit: (id: string) => void
}

const EventRow: FC<React.PropsWithChildren<IProps>> = ({ attrs: attrsKey, submitting, doSubmit }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const attrs = useFragment(
    graphql`
      fragment EventSuccessRow_attrs on Event {
        id
        lockVersion
        previewToken
        date
        endDate
        onSaleDate
        name
        costCurrency
        timezoneName
        state
        description
        lineup
        venueName
        primaryVenue {
          name
        }
        venues {
          name
        }
        promoters {
          name
        }
        billingPromoter {
          name
        }
        prices {
          from
          to
        }
      }
    `,
    attrsKey
  )

  const formattedDate = useMemo(
    () =>
      attrs.date &&
      compose([mapValues(get('value')), keyBy('type'), reject(['type', 'literal'])])(
        intl.formatDateToParts(parseISO(attrs.date), {
          ...DATETIME_FORMATS.SHORT,
          timeZone: attrs.timezoneName || undefined,
        })
      ),
    [intl, attrs.date, attrs.timezoneName]
  )

  const formattedOnSaleDate = useMemo(
    () =>
      attrs.onSaleDate &&
      intl.formatDate(parseISO(attrs.onSaleDate), {
        ...DATETIME_FORMATS.SHORT,
        timeZone: attrs.timezoneName || undefined,
      }),
    [intl, attrs.onSaleDate, attrs.timezoneName]
  )

  const venueName = useMemo(
    () => get('primaryVenue.name', attrs) || attrs.venueName || compose([get('0.name'), compact])(attrs.venues || []),
    [attrs]
  )
  const promoterName = useMemo(
    () => get('name', attrs.billingPromoter) || compose([get('0.name'), compact])(attrs.promoters || []),
    [attrs.billingPromoter, attrs.promoters]
  )

  const formattedMinPrice = useMemo(
    () =>
      attrs.prices &&
      intl.formatNumber((attrs.prices.from || 0) / 100, CURRENCY(attrs.prices.from, attrs.costCurrency || 'GBP')),
    [intl, attrs.costCurrency, attrs.prices]
  )

  const onSubmitClick = useCallback(() => doSubmit(attrs.id), [attrs.id, doSubmit])

  const [showQuickEdit, setShowQuickEdit] = useState(false)
  const onQuickEditShow = useCallback(() => setShowQuickEdit(true), [setShowQuickEdit])
  const onQuickEditClose = useCallback(() => setShowQuickEdit(false), [setShowQuickEdit])

  return (
    <EventRowData>
      <Calendar>
        <div>{formattedDate.day}</div>
        {formattedDate.month} {formattedDate.year}
      </Calendar>
      <EventDetails>
        <div>
          <strong>{attrs.name}</strong>
          <Faint>
            {attrs.date &&
              intl.formatDate(parseISO(attrs.date), {
                ...DATETIME_FORMATS.TIME(locale),
                timeZone: attrs.timezoneName || undefined,
              })}
            &nbsp;&mdash;&nbsp;
            {attrs.endDate &&
              intl.formatDate(parseISO(attrs.endDate), {
                ...DATETIME_FORMATS.TIME(locale),
                timeZone: attrs.timezoneName || undefined,
              })}
          </Faint>
        </div>
        <div>
          <strong>{venueName}</strong>
          <Faint>{promoterName}</Faint>
        </div>
        <div>
          <strong>
            {attrs.prices?.to === attrs.prices?.from
              ? formattedMinPrice
              : intl.formatMessage({ id: 'event_success.price_from' }, { price: formattedMinPrice })}
          </strong>
          <Faint>{intl.formatMessage({ id: 'event_success.on_sale' }, { date: formattedOnSaleDate })}</Faint>
        </div>
        <Buttons>
          <Responsive minWidth={breakpoints.tablet + 1}>
            {attrs.state === 'DRAFT' ? (
              <div>
                <IconButton
                  icon="edit"
                  outlineColor="black"
                  onClick={onQuickEditShow}
                  data-id={`quickEdit[${attrs.id}]`}
                />
                <Button onClick={onSubmitClick} data-id={`submit[${attrs.id}]`}>
                  {intl.formatMessage({ id: 'actions.submit' })}
                </Button>
              </div>
            ) : (
              <Button color="secondary" loading={submitting} data-id={`submit[${attrs.id}]`}>
                <Svg icon="tick" />
              </Button>
            )}
          </Responsive>
          <Responsive maxWidth={breakpoints.tablet}>
            <div>
              {attrs.state === 'DRAFT' ? (
                <>
                  <IconButton
                    icon="edit"
                    outlineColor="black"
                    onClick={onQuickEditShow}
                    data-id={`quickEditMobile[${attrs.id}]`}
                  />
                  <IconButton
                    icon="arrow-right"
                    outlineColor="black"
                    onClick={onSubmitClick}
                    data-id={`submitMobile[${attrs.id}]`}
                  />
                </>
              ) : (
                <IconButton color="secondary" loading={submitting} icon="tick" data-id={`submitMobile[${attrs.id}]`} />
              )}
            </div>
          </Responsive>
        </Buttons>
      </EventDetails>
      {showQuickEdit && <QuickEditModal event={attrs} onClose={onQuickEditClose} />}
    </EventRowData>
  )
}

export default memo(EventRow)
