import React, { FC, useEffect } from 'react'

const AndroidScrollHack: FC = () => {
  useEffect(() => {
    if (!/android/i.test(navigator.userAgent || '')) return

    // This is needed for some ugly android browsers which don't do this automatically
    const hack = () => {
      if (document.activeElement && document.activeElement.tagName.toLowerCase() === 'input') {
        document.activeElement.scrollIntoView({ inline: 'center', block: 'center' })
      }
    }

    window.addEventListener('resize', hack)

    return () => {
      window.removeEventListener('resize', hack)
    }
  }, [])
  return null
}

export default AndroidScrollHack
