import React, { FC, useState, useEffect, ReactNode, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { concat } from 'lodash/fp'

import { font, mediaQuery } from '../utils/variables'
import { Loader } from './Loader'
import Svg from './Svg'
import AuthSimpleLayout from '../flows/Auth/AuthSimpleLayout'
import { authContext } from '../context/auth'
import Button from './Button'
import { textStyle } from '../utils/typography'

export const STATUSES = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
} as const

const Layout = styled.div`
  min-height: 100%;
  display: flex;
  z-index: 20;
  position: relative;
`

const Buffer = styled.div`
  flex-grow: 1;
`

const TextBlock = styled.div`
  align-self: center;
  flex-basis: 620px;
  flex-grow: 0;
  text-align: center;
  color: #ffffff;
  margin: 0 32px;

  ${mediaQuery.lessThan('tablet')`
    flex-basis: 350px;
  `}
`

const Headline = styled.div`
  margin-bottom: 16px;
  ${textStyle.heading.xxxl}

  ${mediaQuery.lessThan('tablet')`
    ${textStyle.heading.xxl}
  `}
`

const DescriptionSmall = styled.div`
  /* TODO: light? not in AMP */
  ${textStyle.heading.md}

  ${mediaQuery.lessThan('tablet')`
    font-size: ${font.size.lg}px;
    padding: 0;
  `}
`

const Description = styled.div`
  ${textStyle.heading.xl}
  margin-bottom: 16px;
`

interface IContentProps {
  prefix: string
  status: string
  successHeaderKey?: string
  cta: ReactNode
  extra?: any
}

const Content: FC<React.PropsWithChildren<IContentProps>> = ({ successHeaderKey, prefix, status, extra, cta }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  if (status === STATUSES.LOADING) {
    return (
      <>
        <Loader />
        <Description>{intl.formatMessage({ id: `${prefix}.loading` })}</Description>
      </>
    )
  } else if (status === STATUSES.SUCCESS) {
    return (
      <>
        {!successHeaderKey && <Svg icon="face-happy" width={48} height={48} />}

        {successHeaderKey ? (
          <>
            <Headline>{intl.formatMessage({ id: successHeaderKey }, extra)}</Headline>
            <DescriptionSmall>{intl.formatMessage({ id: `${prefix}.success` })}</DescriptionSmall>
          </>
        ) : (
          <Description>{intl.formatMessage({ id: `${prefix}.success` })}</Description>
        )}

        {cta}
      </>
    )
  }

  return (
    <>
      <Svg icon="face-sad" width={48} height={48} />
      <Description>{intl.formatMessage({ id: `${prefix}.failure` })}</Description>
      {user.id && (
        <Button className="mt-md" preset="secondary" to="/">
          {intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
        </Button>
      )}
    </>
  )
}

interface IProps {
  prefix: string
  successHeaderKey?: string
  extractParams: () => null | Array<any>
  fetchData: (...args: any[]) => Promise<'success' | 'failure' | ['success' | 'failure', any]>
}

const ConfirmationPage: FC<React.PropsWithChildren<IProps>> = ({
  successHeaderKey,
  prefix,
  children,
  extractParams,
  fetchData,
}) => {
  const [status, setStatus] = useState<string>(STATUSES.LOADING)
  const [extra, setExtra] = useState<any>(null)

  useEffect(() => {
    let mounted = true

    const params = extractParams()
    if (params) {
      fetchData(...params)
        .then((rs) => {
          const [status, extraData] = concat([], rs)

          setStatus(status)
          if (extraData) {
            setExtra(extraData)
          }
        })
        .catch((e) => {
          console.error(e)
          if (mounted) {
            setStatus(STATUSES.FAILURE)
          }
        })
    } else {
      setStatus(STATUSES.FAILURE)
    }

    return () => {
      mounted = false
    }
  }, [extractParams, fetchData])

  return (
    <AuthSimpleLayout>
      <Layout>
        <Buffer />
        <TextBlock>
          <Content successHeaderKey={successHeaderKey} prefix={prefix} status={status} extra={extra} cta={children} />
        </TextBlock>
        <Buffer />
      </Layout>
    </AuthSimpleLayout>
  )
}

export default ConfirmationPage
