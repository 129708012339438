import React, { FC, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import { authContext } from '../../../context/auth'
import { trackingContext } from '../../../context/tracking'
import { mediaQuery } from '../../../utils/variables'

import CollaboratorsBanner from '../../../components/CollaboratorsBanner'
import EmptyState from '../../../components/EmptyState'
import Button from '../../../components/Button'
import EventListTypeContext from '../util/eventListTypeContext'

const ButtonWrapper = styled.div`
  ${mediaQuery.lessThan('tablet')`
    .button + .button {
      margin: 8px 0 0;
    }
    ${Button} {
      width: 100%;
    }
  `}
`

interface IProps {
  embedded?: boolean
}

const EventListEmpty: FC<IProps> = ({ embedded }) => {
  const intl = useIntl()
  const listType = useContext(EventListTypeContext)
  const { search } = useLocation()
  const { hasPermission } = useContext(authContext)
  const { trackEvent } = useContext(trackingContext)
  const trackNewEvent = useCallback(() => trackEvent('create_event_clicked'), [trackEvent])

  return (
    <>
      <CollaboratorsBanner />
      {!!search ? (
        <EmptyState icon="search" title={intl.formatMessage({ id: 'event_list.empty.no_filter_match' })} />
      ) : (
        <EmptyState
          icon="empty-state_events"
          title={intl.formatMessage({ id: `event_list.empty.empty_list.${listType}` })}
        >
          {hasPermission('create:event') && !embedded && (
            <ButtonWrapper>
              <Button icon="add" to="/events/new" onClick={trackNewEvent}>
                {intl.formatMessage({ id: 'event_list.empty.new_event' })}
              </Button>
              {listType === 'live' ? (
                <Button to="/events/drafts" preset="secondary">
                  {intl.formatMessage({ id: 'event_list.empty.view_drafts' })}
                </Button>
              ) : (
                <Button to="/events/live" preset="secondary">
                  {intl.formatMessage({ id: 'event_list.empty.view_live' })}
                </Button>
              )}
            </ButtonWrapper>
          )}
        </EmptyState>
      )}
    </>
  )
}

export default EventListEmpty
