import React, { ComponentProps, FC, forwardRef, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { textStyle } from '../utils/typography'

import { font, color } from '../utils/variables'

import ButtonBase from './ButtonBase'
import FormGroup from './FormGroup'
import { TitleTooltip } from './Tooltip'

const SpecialLabel = styled.span`
  font-weight: normal;
  ${textStyle.label.regular}
  text-transform: uppercase;
`

const TabMenuInner = styled.ul<{ fullWidth?: boolean; wrap?: boolean }>`
  list-style: none;
  padding: 0 2px;
  margin: 0;

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;

    display: flex;
    align-items: baseline;

    ${TabMenuItem} {
      display: block;
      flex: 1;
      margin-top: 0;

      button {
        width: 100%;
      }
    }
  `}

  ${({ wrap }) =>
    wrap &&
    `
    display: flex;
    flex-wrap: wrap;
`}
`

const TabMenuWithLabel: FC<{ label?: ReactNode } & ComponentProps<typeof TabMenuInner>> = ({ label, ...props }) =>
  label ? (
    <FormGroup label={<SpecialLabel>{label}</SpecialLabel>}>
      <TabMenuInner {...props} />
    </FormGroup>
  ) : (
    <TabMenuInner {...props} />
  )

export const TabMenu = styled(TabMenuWithLabel)``

export const TabMenuItem = styled(
  forwardRef<HTMLLIElement, any>(({ className, forceShowTooltip, ...props }, ref) => (
    <TabMenuListItem ref={ref} className={className}>
      <TitleTooltip title={props.title} placement={props.titlePlacement || 'top'}>
        {/* wrap in a div so tooltip can be triggered when button is disabled */}
        {forceShowTooltip ? (
          <div>
            <TabMenuButton {...props} />
          </div>
        ) : (
          <TabMenuButton {...props} />
        )}
      </TitleTooltip>
    </TabMenuListItem>
  ))
)``

const TabMenuListItem = styled.li`
  display: inline-block;
  vertical-align: baseline;

  &:first-child {
    margin-left: -2px;
    margin-top: -2px;
  }

  & + & {
    margin-left: -2px;
    margin-top: -2px;
  }
`

export const TabMenuButton = styled(ButtonBase)`
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  background: ${({ disabled }) => (disabled ? color.palegrey : 'none')};
  border: 2px solid ${color.lightgrey};
  font-size: ${font.size.sm}px;
  padding: 9px 12px 8px;
  margin: 0;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${color.text};
  user-select: none;
  &:hover {
    background: ${color.palegrey};
  }
  ${({ active }) =>
    active &&
    `
    border-color: ${color.text};
    z-index: 1;
  `};
`
