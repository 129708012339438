import { useContext, useMemo } from 'react'
import { parseISO, subHours } from 'date-fns'
import { capitalize } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { localeContext } from '../../../context/locale'
import IEventFormSettings from '../types/Settings'
import { TZ_DEFAULT } from '../services/getDefaultEvent'

function useDoorlistStatus() {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const { values } = useFormikContext<IEventFormSettings>()

  const doorlistStatus = useMemo(() => {
    if (values.offSaleDate && values.date) {
      let currentDoorlistStatus = null
      const timezoneName = values.timezoneName ?? TZ_DEFAULT
      if (values.doorlistSendAt) {
        const formattedDate = intl.formatDate(parseISO(values.doorlistSendAt), {
          ...DATETIME_FORMATS.DATETIME(locale),
          timeZone: timezoneName,
        })
        const friendlyStatus = values.doorlistSendStatus && capitalize(values.doorlistSendStatus)

        currentDoorlistStatus = intl.formatMessage(
          { id: 'new_event.settings.doorlist_status.current_past' },
          { date: formattedDate, status: friendlyStatus }
        )
      } else {
        const estimatedDate = subHours(parseISO(values.date), 1)
        const formattedEstimate = intl.formatDate(estimatedDate, {
          ...DATETIME_FORMATS.DATETIME(locale),
          timeZone: timezoneName,
        })

        currentDoorlistStatus = intl.formatMessage(
          { id: 'new_event.settings.doorlist_status.current_future' },
          { date: formattedEstimate }
        )
      }

      let finalDoorlistStatus = null
      if (values.offSaleSentAt) {
        const formattedDate = intl.formatDate(parseISO(values.offSaleSentAt), {
          ...DATETIME_FORMATS.DATETIME(locale),
          timeZone: timezoneName,
        })
        const friendlyStatus = values.offSaleSentStatus && capitalize(values.offSaleSentStatus)

        finalDoorlistStatus = intl.formatMessage(
          { id: 'new_event.settings.doorlist_status.final_past' },
          { date: formattedDate, status: friendlyStatus }
        )
      } else {
        const estimatedDate = parseISO(values.offSaleDate)
        const formattedEstimate = intl.formatDate(estimatedDate, {
          ...DATETIME_FORMATS.DATETIME(locale),
          timeZone: timezoneName,
        })

        finalDoorlistStatus = intl.formatMessage(
          { id: 'new_event.settings.doorlist_status.final_future' },
          { date: formattedEstimate }
        )
      }

      return parseISO(values.offSaleDate) > parseISO(values.date)
        ? `${currentDoorlistStatus}; ${finalDoorlistStatus}`
        : finalDoorlistStatus
    }

    return null
  }, [
    intl,
    locale,
    values.date,
    values.doorlistSendAt,
    values.doorlistSendStatus,
    values.offSaleDate,
    values.offSaleSentAt,
    values.offSaleSentStatus,
    values.timezoneName,
  ])

  const doorlistDate = useMemo(() => {
    if (!values.offSaleDate || !values.date) {
      return null
    }

    return parseISO(values.offSaleDate) > parseISO(values.date)
      ? subHours(parseISO(values.date), 1)
      : parseISO(values.offSaleDate)
  }, [values.date, values.offSaleDate])

  return { doorlistStatus, doorlistDate }
}

export default useDoorlistStatus
