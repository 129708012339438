import React, { lazy } from 'react'
import { Route, Routes } from 'react-router'
import GenericError from '../../components/GenericError'
import IntlConsumer from '../../components/IntlConsumer'
import DashboardPage from '../../flows/Dashboard/DashboardPage'
import PermissionGate from '../gates/PermissionGate'
import DiceGate from '../gates/DiceGate'
import PartnerGate from '../gates/PartnerGate'
import RedirectGate from '../gates/RedirectGate'

const NtsFiscalInfoPage = lazy(
  () => import(/* webpackChunkName: "nts" */ '../../flows/NtsFiscalInfo/NtsFiscalInfoPage')
)

const Account = lazy(() => import(/* webpackChunkName: "account" */ '../../flows/Account/Account'))
const HelpCentrePage = lazy(() => import(/* webpackChunkName: "helpcentre" */ '../../flows/HelpCentre/HelpCentrePage'))

const GenericSection = () => (
  <Routes>
    <Route path="dashboard" element={<DashboardPage />} />
    <Route path="account" element={<Account />} />
    <Route path="support" element={<HelpCentrePage />} />

    <Route
      path="nts-fiscal-info"
      element={
        <>
          <PartnerGate requiresV2>
            <RedirectGate find="/nts-fiscal-info" replace="/settings/nts-fiscal-info" />
          </PartnerGate>
          <PermissionGate permission="read_attractive_fiscal_info:event">
            <NtsFiscalInfoPage />
          </PermissionGate>
        </>
      }
    />

    <Route
      path="*"
      element={
        <IntlConsumer>
          {(intl) => (
            <GenericError
              title={intl.formatMessage({ id: 'generic_error.title' })}
              description={intl.formatMessage({ id: 'generic_error.not_found_description' })}
              backText={intl.formatMessage({ id: 'generic_error.back_to_dashboard_button' })}
              backLink="/dashboard"
            />
          )}
        </IntlConsumer>
      }
    />
  </Routes>
)

export default GenericSection
