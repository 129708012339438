/**
 * @generated SignedSource<<3e86dd896a058ff8d58dbffe342f057e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DuplicateEventInput = {
  clientMutationId: string;
  id: string;
};
export type useCopyEventMutation$variables = {
  input: DuplicateEventInput;
};
export type useCopyEventMutation$data = {
  readonly duplicateEvent: {
    readonly event: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};
export type useCopyEventMutation = {
  response: useCopyEventMutation$data;
  variables: useCopyEventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DuplicateEventPayload",
    "kind": "LinkedField",
    "name": "duplicateEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCopyEventMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCopyEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d6532cece318f93f97a8ffa9615bb324",
    "id": null,
    "metadata": {},
    "name": "useCopyEventMutation",
    "operationKind": "mutation",
    "text": "mutation useCopyEventMutation(\n  $input: DuplicateEventInput!\n) {\n  duplicateEvent(input: $input) {\n    event {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fe65506253164ce736e0d0ced1305c1";

export default node;
