import React, { FC, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components/macro'
import { compact, compose, filter, orderBy } from 'lodash/fp'
import { isPast, parseISO } from 'date-fns'
import { ContractReviewModal } from '../../../components/ContractReviewModal'
import { EventFeeConfigurationPreviewQuery } from '../../../__generated__/EventFeeConfigurationPreviewQuery.graphql'
import { EventCostCurrency } from '../../../enums.generated'

import { convertContract } from '../../CreateContract/services/convertContract'

interface IProps {
  promoterId?: string | null
  venueId?: string | null
  eventId: string | null
  currency: EventCostCurrency
  timezone: string | null
}

const HintLink = styled.a`
  color: #0000ff;
`

const EventFeeConfigurationPreview: FC<React.PropsWithChildren<IProps>> = ({
  timezone,
  currency,
  promoterId,
  eventId,
  venueId,
}) => {
  const intl = useIntl()

  const { viewer, venue } = useLazyLoadQuery<EventFeeConfigurationPreviewQuery>(
    graphql`
      query EventFeeConfigurationPreviewQuery(
        $promoterId: ID!
        $eventId: ID
        $venueId: ID!
        $includeVenue: Boolean!
        $includePromoter: Boolean!
      ) {
        viewer {
          feeConfigurationByPromoter(promoterId: $promoterId, eventId: $eventId) @include(if: $includePromoter) {
            id
            effectiveDate
            endDate
            taxId
            taxExempt
            boxOfficeFee
            basePriceFees
            postFanPriceFees
            allowedAdhocFeeTypes {
              amount
              type
              unit
              split {
                unit
                destination
                amount
              }
            }
            feesApplicationRules {
              __typename
              id
              feeTarget
              feeTargetCategories {
                value: id
                label: name
                parentCategory {
                  value: id
                  label: name
                }
              }
              feeRanges {
                __typename
                id
                fromBasePrice
                fees {
                  amount
                  type
                  unit
                  split {
                    unit
                    destination
                    amount
                  }
                }
              }
            }
          }
        }

        venue: node(id: $venueId) @include(if: $includeVenue) {
          ... on Venue {
            contracts {
              id
              effectiveDate
              endDate
              taxId
              taxExempt
              boxOfficeFee
              basePriceFees
              postFanPriceFees
              allowedAdhocFeeTypes {
                amount
                type
                unit
                split {
                  unit
                  destination
                  amount
                }
              }
              feesApplicationRules {
                __typename
                id
                feeTarget
                feeTargetCategories {
                  value: id
                  label: name
                  parentCategory {
                    value: id
                    label: name
                  }
                }
                feeRanges {
                  id
                  fromBasePrice
                  fees {
                    amount
                    type
                    unit
                    split {
                      unit
                      destination
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      promoterId: promoterId || '',
      venueId: venueId || '',
      eventId,
      includeVenue: !!venueId,
      includePromoter: !!promoterId,
    },
    { fetchPolicy: 'store-or-network' }
  )

  const activeVenueContract = useMemo(() => {
    // According to backend docs:
    // `end_date` is only for information and does not affect any fee calculation

    const contracts = compose([orderBy(['effectiveDate', 'id'], ['desc', 'asc']), compact])(venue?.contracts || [])
    const currentContracts = filter((c) => c && c.effectiveDate && isPast(parseISO(c.effectiveDate)), contracts)

    return currentContracts?.length ? currentContracts[0] : null
  }, [venue?.contracts])

  const [showPromoterModal, setShowPromoterModal] = useState(false)
  const doShowPromoterModal = useCallback(() => setShowPromoterModal(true), [])
  const doHidePromoterModal = useCallback(() => setShowPromoterModal(false), [])

  const [showVenueModal, setShowVenueModal] = useState(false)
  const doShowVenueModal = useCallback(() => setShowVenueModal(true), [])
  const doHideVenueModal = useCallback(() => setShowVenueModal(false), [])

  const hintKey = useMemo(() => {
    if (viewer?.feeConfigurationByPromoter && venue?.contracts?.length)
      return 'new_event.tickets.review_fee_configurations'
    return viewer?.feeConfigurationByPromoter
      ? 'new_event.tickets.review_fee_configuration'
      : 'new_event.tickets.review_fee_configuration_venue'
  }, [venue?.contracts, viewer?.feeConfigurationByPromoter])

  if (!(viewer?.feeConfigurationByPromoter || venue?.contracts?.length)) return null

  return (
    <>
      {showPromoterModal && viewer?.feeConfigurationByPromoter && (
        <ContractReviewModal
          feesConfiguration={convertContract(viewer.feeConfigurationByPromoter, false)}
          currency={currency}
          onClose={doHidePromoterModal}
          timezone={timezone}
        />
      )}
      {showVenueModal && activeVenueContract && (
        <ContractReviewModal
          feesConfiguration={convertContract(activeVenueContract, false)}
          currency={currency}
          onClose={doHideVenueModal}
          timezone={timezone}
        />
      )}
      {intl.formatMessage(
        { id: hintKey },
        {
          a: (str: string) => <HintLink onClick={doShowPromoterModal}>{str}</HintLink>,
          v: (str: string) => <HintLink onClick={doShowVenueModal}>{str}</HintLink>,
        }
      )}
    </>
  )
}

export default EventFeeConfigurationPreview
