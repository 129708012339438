import { object, string, array, number, boolean, ValidationError } from 'yup'
import { findIndex, reject } from 'lodash/fp'
import { type EditorState } from 'react-draft-wysiwyg'
import { renderMarkdown } from '../../../utils/markdown'

const MIN_IMG_WIDTH = 768
const MIN_IMG_HEIGHT = 768

const FlagSchema = object().nullable().optional().shape({
  active: boolean().nullable().optional(),
})

const FlagsSchema = object().nullable().optional().shape({
  alcoholFree: FlagSchema,
  disableDayOfEventComms: FlagSchema,
})

const MediaSchema = object().shape({
  type: string().required(),
  values: object().required(),
})

const ImageSchema = object().shape({
  id: string().required(),
  attachmentId: string().nullable(),
  type: string().required(),
  cropRegion: object().shape({
    x: number().integer().min(0),
    y: number().integer().min(0),
    width: number().integer().min(0),
    height: number().integer().min(0),
  }),
})

const AdditionalInfoSchema = object()
  .shape({
    content: string().nullable().max(2000),
    includeOnPurchaseEmail: boolean().nullable(),
    includeOnReminderEmail: boolean().nullable(),
    ctaLabel: string().nullable().max(50),
    ctaLink: string().nullable(),
  })
  .test('ctaLink', 'ctaLink must have ctaLabel', function (values) {
    const valid = !(values.ctaLink?.length && (!values.ctaLabel || values.ctaLabel.length === 0))
    return (
      valid ||
      this.createError({
        path: `${this.path}.ctaLabel`,
        message: 'new_event.information.information_for_fans.cta_error',
      })
    )
  })
  .test('ctaLabel', 'ctaLabel must have ctaLink', function (values) {
    const valid = !(values.ctaLabel?.length && (!values.ctaLink || values.ctaLink.length === 0))
    return (
      valid ||
      this.createError({
        path: `${this.path}.ctaLink`,
        message: 'new_event.information.information_for_fans.cta_error',
      })
    )
  })
  .test('ctaLabel', 'ctaLabel max length', function (values) {
    const valid = values.ctaLabel?.length || 0 <= 50
    return valid || this.createError({ path: `${this.path}.ctaLabel`, message: 'validation.yup.string.max' })
  })
  .test('content', 'content can not be empty if CTA present', function (values) {
    const valid = !((values.ctaLink || values.ctaLabel) && !values.content)
    return (
      valid ||
      this.createError({
        path: `${this.path}.content`,
        message: 'new_event.information.information_for_fans.cta_content_error',
      })
    )
  })

const InformationSchema = object()
  .shape({
    description: string().required(),
    presentedBy: string(),
    ageLimit: string().nullable().required(),
    media: array().nullable().of(MediaSchema),
    eventImages: array().nullable().of(ImageSchema),
    additionalInfos: array().nullable().of(AdditionalInfoSchema),
    flags: FlagsSchema,
    eventRules: object().nullable().shape({
      maskRequired: boolean().nullable(),
      socialDistancing: boolean().nullable(),
      proofOfBeingHealthy: boolean().nullable(),
      covidPcr: boolean().nullable(),
      covidRecovery: boolean().nullable(),
      covidVaccination: boolean().nullable(),
      covidPcrValidHours: number().nullable(),
      covidPolicyUrl: string().nullable().url(),
    }),
    faqs: array().of(
      object().shape({
        title: string().required(),
        body: string().test('bodyDraft', ' ', function () {
          const body = this.parent.body
          const bodyDraft = this.parent.bodyDraft as EditorState | undefined
          return !!(bodyDraft ? renderMarkdown(bodyDraft) : body)
        }),
      })
    ),
  })
  // .test('maskRequired', 'maskRequired presence', function (values) {
  //   if (values.eventType === 'STREAM') return true

//   if (isNil(values.eventRules?.maskRequired)) {
//     return this.createError({
//       path: 'eventRules.maskRequired',
//       message: ' ',
//     })
//   }
//   return true
// })
// .test('socialDistancing', 'socialDistancing presence', function (values) {
//   if (values.eventType === 'STREAM') return true

//   if (isNil(values.eventRules?.socialDistancing)) {
//     return this.createError({
//       path: 'eventRules.socialDistancing',
//       message: ' ',
//     })
//   }
//   return true
// })
// .test('proofOfBeingHealthy', 'proofOfBeingHealthy presence', function (values) {
//   if (values.eventType === 'STREAM') return true

  //   if (isNil(values.eventRules?.proofOfBeingHealthy)) {
  //     return this.createError({
  //       path: 'eventRules.proofOfBeingHealthy',
  //       message: ' ',
  //     })
  //   }
  //   return true
  // })
  .test('covidProofs', 'If covid proof is required at least one should be accepted', function (values) {
    if (values.eventType === 'STREAM') return true

    if (values.eventRules?.proofOfBeingHealthy) {
      const hasProof =
        !!values.eventRules?.covidPcr || !!values.eventRules?.covidRecovery || !!values.eventRules?.covidVaccination

      if (!hasProof) {
        return this.createError({
          path: 'eventRules.proofOfBeingHealthy',
          message: 'new_event.information.covid.select_proof_error',
        })
      }
    }
    return true
  })
  .test('covidPcrValidHours', 'If PCR is required it must have expire period set', function (values) {
    if (values.eventType === 'STREAM') return true

    if (values.eventRules?.covidPcr) {
      const h = Number(values.eventRules?.covidPcrValidHours)

      if (h <= 0 || h >= 100) {
        return this.createError({
          path: 'eventRules.covidPcrValidHours',
          message: 'new_event.information.covid.pcr_hours_error',
        })
      }
    }
    return true
  })
  .test('eventImages', 'Event should have images uploaded', function (values) {
    const imgs = reject(['type', 'brand'], values.eventImages || [])
    if (imgs.length === 0) {
      return this.createError({ path: 'eventImages', message: ' ' })
    }

    return true
  })
  .test('rawImage', 'Event should have properly sized image', function (values) {
    const imgCrop = values.rawImage?.cropRegion

    if (imgCrop) {
      if (imgCrop.width >= MIN_IMG_WIDTH && imgCrop.height >= MIN_IMG_HEIGHT) {
        return true
      } else if (imgCrop.width < MIN_IMG_WIDTH && imgCrop.height < MIN_IMG_HEIGHT) {
        return this.createError({ path: 'eventImages.size', message: 'event_images.too_small.both' })
      }

      const error = new ValidationError(' ', values, 'eventImages')
      error.inner = []
      if (imgCrop.width < MIN_IMG_WIDTH) {
        error.inner.push(this.createError({ path: 'eventImages.width', message: 'event_images.too_small.width' }))
      }
      if (imgCrop.height < MIN_IMG_HEIGHT) {
        error.inner.push(this.createError({ path: 'eventImages.height', message: 'event_images.too_small.height' }))
      }

      return error
    }

    return true
  })
  .test('limitDescription', 'Description must not exceed the char limit', function (values) {
    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff, dicePartner } = ctx

    const charLimit = diceStaff || dicePartner ? null : 1500
    const valid = !charLimit || (values.description || '').length <= charLimit
    return valid || this.createError({ path: 'description', message: 'validation.yup.string.max' })
  })
  .test('trailerUrl', 'Trailer url', function (values) {
    const ctx = (this.options.context as any).viewer || {}
    const { diceStaff } = ctx

    if (!diceStaff) return true

    if (!values.media || values.media.length === 0) return true

    const trailerIdx = findIndex(['type', 'trailer'], values.media)

    if (trailerIdx < 0) return true

    const trailerUrl = values.media[trailerIdx].values?.video

    if (!trailerUrl) {
      return this.createError({ path: `media.${trailerIdx}.values.video`, message: ' ' })
    }

    try {
      string().url().nullable().required().validateSync(trailerUrl)
      return true
    } catch (e) {
      return this.createError({ path: 'trailerUrl', message: e.message })
    }
  })

export default InformationSchema
