import React, { FC, memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { compose, find, get } from 'lodash/fp'
import { Link } from 'react-router-dom'
import { parseISO } from 'date-fns'
import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { color, font, mediaQuery, spacing } from '../../../../utils/variables'
import { DATETIME_FORMATS } from '../../../../utils/formatters/datetime'

import Svg from '../../../../components/Svg'
import VenueBadge from '../../../../components/VenueBadge'

import { DashboardEventCardInfo_event$key } from '../../../../__generated__/DashboardEventCardInfo_event.graphql'
import EventCardValue from '../../../../components/Event/EventCardValue'
import { authContext } from '../../../../context/auth'
import { textStyle } from '../../../../utils/typography'

const EventImage = styled.div<{ src: string }>`
  position: relative;
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 200px;
  overflow: hidden;
  background-image: ${({ src }) => (src ? `url(${src})` : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
`

const EventImageMissed = styled(Svg)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Clamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const EventTitle = styled(Clamp)`
  ${textStyle.interactive.lg}
  margin-bottom: 4px;
  ${mediaQuery.lessThan('desktopLarge')`
    font-size: ${font.size.base}px;
    line-height: 20px;
  `}
`

const Venue = styled.span`
  display: block;
  font-size: ${font.size.base}px;
  margin-bottom: ${spacing.xs}px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${mediaQuery.lessThan('desktopLarge')`
    font-size: ${font.size.sm}px;
    margin: 0;
  `}
`

const Date = styled.span`
  display: block;
  font-size: ${font.size.sm}px;
  color: ${color.darkgrey};
`

const VenueDatePart = styled.div<{ textAlign?: string; overflow?: string }>`
  display: block;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  overflow: ${({ overflow }) => overflow || 'visible'};
  ${mediaQuery.lessThan('desktopLarge')``}
`

interface IProps {
  event: DashboardEventCardInfo_event$key
}

const DashboardEventCardInfo: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  const event = useFragment(
    graphql`
      fragment DashboardEventCardInfo_event on Event {
        id
        name
        state
        date
        timezoneName
        statusAsOfNow
        flags {
          hidden
        }
        eventImages {
          type
          cdnUrl
        }
        eventType
        venueName
        primaryVenue {
          id
          name
        }
        venues {
          id
          name
        }
        billingPromoter {
          id
          name
        }
        ticketTypes(doorSalesOnly: false, includeArchived: false) {
          streamLink
        }
      }
    `,
    eventKey
  )

  const imgUrl = useMemo(
    () =>
      compose(
        (url) => (url ? url + '&h=550&w=550&auto=compress' : undefined),
        get('cdnUrl'),
        find(['type', 'square'])
      )(event.eventImages),
    [event.eventImages]
  )

  const eventPath = event.state === 'DRAFT' ? `/events/${event.id}/edit` : `/events/${event.id}/overview`

  // prettier-ignore
  const eventDate = event.date
    ? intl.formatDate(parseISO(event.date), {
      ...DATETIME_FORMATS.MEDIUM,
      timeZone: event.timezoneName || undefined,
    })
    : intl.formatMessage({ id: 'na' })

  const promoter = event?.billingPromoter ? (
    <Link to={`/promoters/${event.billingPromoter.id}`}>{event.billingPromoter.name}</Link>
  ) : (
    intl.formatMessage({ id: 'na' })
  )

  return (
    <>
      <Link to={eventPath}>
        <EventImage src={imgUrl || ''}>{!imgUrl && <EventImageMissed icon="image-placeholder" />}</EventImage>
      </Link>
      <EventTitle>
        <Link to={eventPath}>{event.name}</Link>
      </EventTitle>
      <VenueDatePart overflow="hidden">
        <Venue>
          <EventCardValue
            primary={<VenueBadge event={event} />}
            primaryLimit="2"
            secondary={user.diceStaff ? promoter : undefined}
          />
        </Venue>
        <Date>{eventDate}</Date>
      </VenueDatePart>
    </>
  )
}

export default memo(DashboardEventCardInfo)
