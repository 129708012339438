/**
 * @generated SignedSource<<bad38d6d48aef730e880d8ac05f0a68e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRevenueReportQuery$variables = {
  eventId: string;
};
export type useRevenueReportQuery$data = {
  readonly event: {
    readonly " $fragmentSpreads": FragmentRefs<"useRevenueReportQuery_event">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"useRevenueReportQuery_viewer">;
  } | null;
};
export type useRevenueReportQuery = {
  response: useRevenueReportQuery$data;
  variables: useRevenueReportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eventId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "eventId",
  "variableName": "eventId"
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": "sold",
    "args": null,
    "kind": "ScalarField",
    "name": "soldItems",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSold",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allocation",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRevenueReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "useRevenueReportQuery_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "event",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useRevenueReportQuery_event"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRevenueReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "tickets",
            "args": (v2/*: any*/),
            "concreteType": "RevenueReportItem",
            "kind": "LinkedField",
            "name": "revenueReport",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": "sold",
                "args": null,
                "kind": "ScalarField",
                "name": "soldTickets",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "extras",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "rootType",
                "value": "EXTRAS"
              }
            ],
            "concreteType": "ProductsRevenueReportItem",
            "kind": "LinkedField",
            "name": "productsRevenueReport",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": "merch",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "rootType",
                "value": "MERCH"
              }
            ],
            "concreteType": "ProductsRevenueReportItem",
            "kind": "LinkedField",
            "name": "productsRevenueReport",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "event",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "costCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezoneName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onSaleDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "offSaleDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Promoter",
                "kind": "LinkedField",
                "name": "billingPromoter",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extrasEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "merchEnabled",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Sales",
                "kind": "LinkedField",
                "name": "sales",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalPayoutValue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductsSales",
                "kind": "LinkedField",
                "name": "productsSales",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductBreakdownItem",
                    "kind": "LinkedField",
                    "name": "productBreakdown",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalFaceValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TicketType",
                "kind": "LinkedField",
                "name": "ticketTypes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceTier",
                    "kind": "LinkedField",
                    "name": "priceTiers",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "789c71e3e319d93c968d72b58f00caa5",
    "id": null,
    "metadata": {},
    "name": "useRevenueReportQuery",
    "operationKind": "query",
    "text": "query useRevenueReportQuery(\n  $eventId: ID!\n) {\n  viewer {\n    ...useRevenueReportQuery_viewer_32qNee\n    id\n  }\n  event: node(id: $eventId) {\n    __typename\n    ...useRevenueReportQuery_event\n    id\n  }\n}\n\nfragment useRevenueReportQuery_event on Event {\n  costCurrency\n  timezoneName\n  onSaleDate\n  offSaleDate\n  billingPromoter {\n    extrasEnabled\n    merchEnabled\n    id\n  }\n  sales {\n    totalSold\n    totalPayoutValue\n  }\n  productsSales {\n    productBreakdown {\n      product {\n        rootType\n        id\n      }\n      totalSold\n      totalFaceValue\n    }\n  }\n  allocation\n  products {\n    rootType\n    allocation\n    faceValue\n    id\n  }\n  ticketTypes {\n    faceValue\n    priceTiers {\n      faceValue\n      id\n    }\n    id\n  }\n}\n\nfragment useRevenueReportQuery_viewer_32qNee on Viewer {\n  tickets: revenueReport(eventId: $eventId) {\n    time\n    faceValue\n    sold: soldTickets\n  }\n  extras: productsRevenueReport(eventId: $eventId, rootType: EXTRAS) {\n    time\n    faceValue\n    sold: soldItems\n  }\n  merch: productsRevenueReport(eventId: $eventId, rootType: MERCH) {\n    time\n    faceValue\n    sold: soldItems\n  }\n}\n"
  }
};
})();

(node as any).hash = "168a01767dfef97fcef5b633ea86d87c";

export default node;
