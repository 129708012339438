import React, { FC, useCallback, useContext } from 'react'
import styled from 'styled-components/macro'
import { filter } from 'lodash/fp'
import arrayMove from 'array-move'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { localeContext } from '../../../../context/locale'
import { DATETIME_FORMATS } from '../../../../utils/formatters/datetime'
import { textStyle } from '../../../../utils/typography'
import { color, mediaQuery } from '../../../../utils/variables'

import IEventFormTimeline, { IAbbonamentoEvent } from '../../types/Timeline'
import Svg from '../../../../components/Svg'
import IconButton from '../../../../components/IconButton'

const DragHandle = styled.div<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  color: ${color.grey};

  ${({ disabled }) =>
    disabled
      ? `
      cursor: not-allowed;
    `
      : `
      cursor: grab;

      &:hover {
        color: ${color.text};
      }
  `}

  svg {
    pointer-events: none;
  }
`

const ListItem = styled.div`
  padding: 8px 0;
  user-select: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.black}19;

  ${IconButton} {
    margin: 0;
    flex-shrink: 0;
  }
`

const Data = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 8px;
  margin: 0 8px;

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
    gap: 4px;
  `}
`

const EventName = styled.div`
  ${textStyle.functional.md}
  flex-grow: 1;
  margin-right: 0 8px;
`

const Date = styled.div`
  ${textStyle.functional.sm};
  color: ${color.darkgrey};
  min-width: 155px;
`

const ListContainer = styled.div`
  padding: 8px 0;
`

interface IDragHandleProps {
  disabled?: boolean
}

const SortableDragHandle = SortableHandle<IDragHandleProps>(({ disabled }: IDragHandleProps) => (
  <DragHandle disabled={disabled}>
    <Svg icon="hamburger" />
  </DragHandle>
))

interface IItemProps {
  event: IAbbonamentoEvent
  actionsDisabled?: boolean
}

const SortableListItem = SortableElement<IItemProps>(({ event, actionsDisabled }: IItemProps) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { values, setFieldValue } = useFormikContext<IEventFormTimeline>()

  const removeEvent = useCallback(() => {
    setFieldValue(
      'attractiveFields.linkedEvents',
      filter((e) => e?.id !== event.id, values.attractiveFields?.linkedEvents)
    )
  }, [event.id, setFieldValue, values.attractiveFields?.linkedEvents])

  return (
    <ListItem className="draggable">
      <SortableDragHandle disabled={actionsDisabled} />
      <Data>
        <EventName>{event.name}</EventName>
        {event.date && (
          <Date>
            {intl.formatDate(event.date, {
              ...DATETIME_FORMATS.DATETIME(locale),
              timeZone: event.timezoneName || undefined,
            })}
          </Date>
        )}
      </Data>
      <IconButton icon="open" height={18} href={`/events/${event.id}`} target="_blank" />
      <IconButton icon="trash" height={18} onClick={removeEvent} disabled={actionsDisabled} />
    </ListItem>
  )
})

interface IProps {
  events: (IAbbonamentoEvent | null)[] | null
  disabled?: boolean
}

const SortableList = SortableContainer<IProps>(({ events, disabled }: IProps) =>
  events ? (
    <div>
      {events.map(
        (event, i) =>
          event && (
            <SortableListItem index={i} key={event.id} event={event} disabled={disabled} actionsDisabled={disabled} />
          )
      )}
    </div>
  ) : null
)

const EventAbbonamentoSortableList: FC<IProps> = ({ events, disabled }) => {
  const { setFieldValue } = useFormikContext<IEventFormTimeline>()

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: any) => {
      if (events) {
        setFieldValue('attractiveFields.linkedEvents', arrayMove(events, oldIndex, newIndex))
      }
    },
    [events, setFieldValue]
  )

  return (
    <ListContainer>
      <SortableList
        lockAxis="y"
        useDragHandle
        lockOffset="20%"
        lockToContainerEdges
        events={events}
        onSortEnd={handleSortEnd}
        disabled={disabled}
      />
    </ListContainer>
  )
}

export default EventAbbonamentoSortableList
