import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router'
import { Loader, LoaderContainer } from '../../components/Loader'
import DiceGate from '../gates/DiceGate'
import PartnerGate from '../gates/PartnerGate'
import PermissionGate from '../gates/PermissionGate'
import RedirectGate from '../gates/RedirectGate'

const IntegrationsPage = lazy(
  () => import(/* webpackChunkName: "integrations" */ '../../flows/Integrations/IntegrationsPage')
)
const FeaturedPage = lazy(() => import(/* webpackChunkName: "featured" */ '../../flows/Featured/FeaturedPage'))
const FanConnectPage = lazy(() => import(/* webpackChunkName: "connect" */ '../../flows/Connect/FanConnectPage'))

const UsersAndPermissionsHeader = lazy(
  () => import(/* webpackChunkName: "access" */ '../../flows/UsersAndPermissions/UsersAndPermissionsHeader')
)
const SimplePermissionsHeader = lazy(
  () => import(/* webpackChunkName: "access" */ '../../flows/UsersAndPermissions/SimplePermissionsHeader')
)
const UsersPage = lazy(() => import(/* webpackChunkName: "access" */ '../../flows/Users/UsersPage'))
const CreateUserPage = lazy(() => import(/* webpackChunkName: "access" */ '../../flows/CreateUser/CreateUserPage'))
const InviteUserPage = lazy(() => import(/* webpackChunkName: "access" */ '../../flows/InviteUser/InviteUserPage'))
const PermissionsPage = lazy(() => import(/* webpackChunkName: "access" */ '../../flows/Permissions/PermissionsPage'))
const CreatePermissionProfilePage = lazy(
  () => import(/* webpackChunkName: "access" */ '../../flows/CreatePermissionProfile/CreatePermissionProfilePage')
)
const PermissionsActivityPage = lazy(() => import(/* webpackChunkName: "access" */ '../../flows/Activity/ActivityPage'))

const TagsAndHierarchyHeader = lazy(
  () => import(/* webpackChunkName: "tags" */ '../../flows/TagsAndHierarchy/TagsAndHierarchyHeader')
)
const TagsPage = lazy(() => import(/* webpackChunkName: "tags" */ '../../flows/Tags/TagsPage'))
const HierarchyPage = lazy(() => import(/* webpackChunkName: "tags" */ '../../flows/Hierarchy/HierarchyPage'))

const QRGeneratorPage = lazy(() => import(/* webpackChunkName: "qr" */ '../../flows/QRGenerator/QRGeneratorPage'))

const PlatformInspectorPage = lazy(
  () => import(/* webpackChunkName: "pip" */ '../../flows/PlatformInspector/PlatformInspectorPage')
)

const ToolsSection = () => {
  const loader = (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )

  return (
    <Routes>
      <Route
        path="integrations"
        element={
          <>
            <PartnerGate requiresV2>
              <RedirectGate find="/tools" replace="/settings" />
            </PartnerGate>
            <IntegrationsPage />
          </>
        }
      />
      <Route path="featured" element={<FeaturedPage />} />
      <Route path="connect" element={<FanConnectPage />} />
      <Route
        path="qr"
        element={
          <DiceGate>
            <QRGeneratorPage />
          </DiceGate>
        }
      />
      <Route
        path="inspector"
        element={
          <DiceGate>
            <PlatformInspectorPage />
          </DiceGate>
        }
      />

      <Route
        path="tags"
        element={
          <>
            <TagsAndHierarchyHeader />
            <Suspense fallback={loader}>
              <TagsPage />
            </Suspense>
          </>
        }
      />
      <Route
        path="hierarchy"
        element={
          <>
            <TagsAndHierarchyHeader />
            <Suspense fallback={loader}>
              <HierarchyPage />
            </Suspense>
          </>
        }
      />

      <Route
        path="users"
        element={
          <>
            <PartnerGate requiresV2>
              <RedirectGate find="/tools" replace="/settings" />
            </PartnerGate>

            <UsersAndPermissionsHeader />
            <Suspense fallback={loader}>
              <UsersPage />
            </Suspense>
          </>
        }
      />
      <Route
        path="permissions"
        element={
          <>
            <PartnerGate requiresV2>
              <RedirectGate find="/tools" replace="/settings" />
            </PartnerGate>

            <UsersAndPermissionsHeader />
            <Suspense fallback={loader}>
              <PermissionsPage />
            </Suspense>
          </>
        }
      />

      <Route
        path="users/new"
        element={
          <>
            <PartnerGate requiresV2>
              <RedirectGate find="/tools" replace="/settings" />
            </PartnerGate>
            <DiceGate>
              <CreateUserPage />
            </DiceGate>
            <PartnerGate>
              <InviteUserPage />
            </PartnerGate>
          </>
        }
      />

      <Route
        path="permissions/new"
        element={
          <>
            <PartnerGate requiresV2>
              <RedirectGate find="/tools" replace="/settings" />
            </PartnerGate>

            <PermissionGate permission="manage:permission_profile">
              <CreatePermissionProfilePage />
            </PermissionGate>
          </>
        }
      />

      <Route
        path="permissions/:id/activity"
        element={
          <DiceGate>
            <SimplePermissionsHeader />
            <Suspense fallback={loader}>
              <PermissionsActivityPage noHeader />
            </Suspense>
          </DiceGate>
        }
      />
    </Routes>
  )
}

export default ToolsSection
