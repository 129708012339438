import React, { useMemo, memo, FC, useContext } from 'react'

import { OnDesktop } from '../../../components/Breakpoints'

import StaleSuspense from '../../../components/StaleSuspense'
import NotesPanel from '../../../components/NotesPanel'
import eventNotesContext, { INotesEvent } from '../context/eventNotes'
import { useDebounce } from '../../../utils/hooks/useDebounce'
import useEventNotes from '../../../utils/hooks/useEventNotes'

interface IProps {
  loading: boolean
  promoterIds: string[]
  venueIds: string[]
  eventValues: INotesEvent | null
}

const EventNotesBody: FC<IProps> = ({ promoterIds, venueIds, eventValues, loading }) => {
  const { toggleNotesOpen } = useContext(eventNotesContext)

  const {
    event: eventDetails,
    notes,
    internalNotes,
  } = useEventNotes(eventValues?.id, promoterIds, venueIds, eventValues)

  const event = eventDetails
    ? eventDetails
    : ({
      ...eventValues,
      billingPromoter: {
        ...eventValues?.billingPromoter,

        id: eventValues?.billingPromoter?.value,
        name: (eventValues?.billingPromoter || ({} as any)).label,
      },
    } as any)

  return (
    <NotesPanel event={event} notes={notes} internalNotes={internalNotes} loading={loading} onClose={toggleNotesOpen} />
  )
}

const EventNotesView: FC = () => {
  const { promoterIds, venueIds, event, notesOpen } = useContext(eventNotesContext)

  const props = useMemo(
    () => ({
      promoterIds,
      venueIds,
      eventValues: event,
    }),
    [event, promoterIds, venueIds]
  )

  // Used to keep the notes rendered while the slide-out animation is completing
  const debouncedNotesOpen = useDebounce(notesOpen, 400)

  return notesOpen || debouncedNotesOpen ? (
    <OnDesktop>
      <StaleSuspense component={EventNotesBody} props={props} />
    </OnDesktop>
  ) : null
}

export default memo(EventNotesView)
