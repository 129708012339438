import React, { useContext, useCallback, useMemo, FC, useEffect, memo } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { find, get } from 'lodash/fp'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Checkbox from '../../../../components/Checkbox'
import FormField from '../../../../components/FormField'
import { Form, FormRow } from '../../../../components/Form'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'

import { notificationContext } from '../../../../context/notification'
import { color } from '../../../../utils/variables'
import unwrapId from '../../../../utils/unwrapId'
import { trackingContext } from '../../../../context/tracking'
import { ChangeAllocationModalMutation } from '../../../../__generated__/ChangeAllocationModalMutation.graphql'
import useVenueAllocationCheck from '../../hooks/useVenueAllocationCheck'
import useTierSelector from '../../hooks/useTierSelector'
import { ChangeAllocationModalQuery } from '../../../../__generated__/ChangeAllocationModalQuery.graphql'
import FormSalesProgress from '../../../../components/FormSalesProgress'

const AgreementCheckbox = styled(Checkbox)`
  margin-top: 24px;
`

const TermsLink = styled.a`
  font-weight: bold;
  &:hover {
    color: ${color.primary};
  }
`

const Red = styled.span`
  &&& {
    color: ${color.error};
  }
`

interface IProps {
  eventId: string
  onClose: () => void
  preSelectTtyId?: string | null
  preSelectTierId?: string | null
}

const ChangeAllocationModal: FC<IProps> = ({ eventId, onClose, preSelectTtyId, preSelectTierId }) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)

  const { event } = useLazyLoadQuery<ChangeAllocationModalQuery>(
    graphql`
      query ChangeAllocationModalQuery($id: ID!) {
        event: node(id: $id) {
          ... on Event {
            id
            eventIdLive
            ticketPools {
              id
            }

            ...useVenueAllocationCheck_event
            ...useTierSelector_event
          }
        }
      }
    `,
    { id: eventId },
    { fetchPolicy: 'network-only' }
  )

  const { ticketTypesOptions, defaultTTy, getPriceTierOptions, defaultTier } = useTierSelector(
    event,
    preSelectTtyId,
    preSelectTierId
  )

  const pooled = !!event?.ticketPools?.length

  const trackData = useMemo(
    () => ({
      event_id: unwrapId(event?.id),
      event_id_live: event?.eventIdLive || null,
      ticket_type_id: unwrapId(defaultTTy?.value || preSelectTtyId),
    }),
    [defaultTTy, event?.eventIdLive, event?.id, preSelectTtyId]
  )

  const initialValues = useMemo(
    () => ({
      ticketType: defaultTTy,
      priceTier: defaultTier,
      allocation: (defaultTier ? defaultTier.allocation : defaultTTy?.allocation) || 0,
      userAgreed: false,
    }),
    [defaultTTy, defaultTier]
  )

  const [commitUpdate, updating] = useMutation<ChangeAllocationModalMutation>(graphql`
    mutation ChangeAllocationModalMutation($input: ChangeAllocationInput!) {
      changeAllocation(input: $input) {
        object {
          id
          allocation
          lockVersion
          previewToken
          sales {
            ticketTypesBreakdown {
              ticketType {
                id
                name
                allocation
              }
              totalAppSold
              totalPosSold
              totalReserved
              priceTiersBreakdown {
                appSold
                posSold
                reserved
                priceTier {
                  id
                  name
                  allocation
                }
              }
            }
          }
        }
      }
    }
  `)

  const onSubmit = useCallback(
    async ({ ticketType, priceTier, allocation }: typeof initialValues, formikProps: any) => {
      const obj = priceTier || ticketType
      if (!obj) return
      const id = obj.value

      const isAllocationIncreased = (allocation || 0) >= (obj.allocation || 0)

      // Allocation increases are always allowed, no matter what state we stuck in
      if (!isAllocationIncreased) {
        const soldOut = obj.remaining <= 0

        // When sold out, "reserved" flag is reused for PWL purposes by backend
        // so a single ticket can be BOTH sold and reserved at the same time
        // Sad but true :(
        const minAllocation = soldOut ? obj.allocation || 0 : (obj.sold || 0) + (obj.reserved || 0)

        if ((allocation || 0) < minAllocation) {
          return formikProps.setErrors({
            ticketType: {
              allocation: intl.formatMessage(
                { id: 'change_allocation_modal.allocation.validation_error' },
                { minAllocation }
              ),
            },
          })
        }
      }

      trackEvent('allocation_change_submitted', {
        ...trackData,
        from_int: obj.allocation || 0,
        to_int: allocation || 0,
        tickets_sold: obj.sold || 0,
      })

      try {
        await new Promise<void>((resolve, reject) => {
          commitUpdate({
            variables: {
              input: {
                clientMutationId: nanoid(),
                id,
                allocation: allocation || 0,
              },
            },
            onCompleted: (_data, errors) => {
              if (errors && errors.length > 0) {
                reject()
                return
              }

              resolve()
            },
            onError: reject,
          })
        })

        addNotification(
          'success',
          intl.formatMessage({ id: 'change_allocation_modal.success' }, { ticketTypeName: obj.label })
        )

        onClose()
      } catch (e) {
        addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
      }
    },
    [addNotification, commitUpdate, intl, onClose, trackData, trackEvent]
  )

  const { values, touched, errors, setFieldValue, handleChange, handleSubmit } = useFormik({ initialValues, onSubmit })

  const setTicketType = useCallback(
    (_id: any, tty: any) => {
      setFieldValue('ticketType', tty)
      setFieldValue('allocation', tty.allocation || 0)
    },
    [setFieldValue]
  )

  const setPriceTier = useCallback(
    (_id: any, pt: any) => {
      setFieldValue('priceTier', pt)
      setFieldValue('allocation', pt.allocation || 0)
    },
    [setFieldValue]
  )

  const priceTierOptions = useMemo(
    () => getPriceTierOptions(values?.ticketType?.value || null),
    [getPriceTierOptions, values?.ticketType?.value]
  )

  useEffect(() => {
    if (!values.ticketType?.isABTiers) return
    if (preSelectTtyId || preSelectTierId) return

    if (!values.priceTier || !find(['value', values.priceTier.value], priceTierOptions)) {
      const pt = priceTierOptions[0]
      if (pt) {
        setPriceTier(pt.value, pt)
      }
    }
  }, [preSelectTierId, preSelectTtyId, priceTierOptions, setPriceTier, values.priceTier, values.ticketType?.isABTiers])

  const [isOverallocated, totalCapacityInfo] = useVenueAllocationCheck(
    event,
    values.ticketType || null,
    values.priceTier || null,
    values.allocation
  )

  return (
    <Modal
      trackId="allocation_change"
      trackData={trackData}
      modalTitle={intl.formatMessage({ id: pooled ? 'change_quantity' : 'change_allocation' })}
      closeButton
      onClose={onClose}
    >
      <ModalBody>
        <Form>
          <FormRow>
            <FormField
              label={intl.formatMessage({ id: 'ticket_type' })}
              placeholder={intl.formatMessage({ id: 'ticket_type.placeholder' })}
              control="select"
              searchable
              value={values.ticketType}
              onChange={setTicketType}
              options={ticketTypesOptions}
              disabled={!!preSelectTtyId}
            />
            {values.ticketType?.isABTiers && (
              <FormField
                label={intl.formatMessage({ id: 'price_tier' })}
                placeholder={intl.formatMessage({ id: 'price_tier.placeholder' })}
                control="select"
                searchable
                value={values.priceTier}
                onChange={setPriceTier}
                options={priceTierOptions}
                disabled={!!preSelectTierId}
              />
            )}
          </FormRow>
          <FormRow columnOnMobile>
            <FormField
              name="allocation"
              label={intl.formatMessage({ id: pooled ? 'new_quantity' : 'new_allocation' })}
              value={values.allocation}
              error={
                get('ticketType.allocation', errors) && get('ticketType.allocation', touched)
                  ? (errors.ticketType as any)?.allocation
                  : null
              }
              hint={
                !(get('ticketType.allocation', errors) && get('ticketType.allocation', touched)
                  ? (errors.ticketType as any)?.allocation
                  : null) && (!isOverallocated ? totalCapacityInfo : <Red>{totalCapacityInfo}</Red>)
              }
              type="number"
              min={0}
              step={1}
              onChange={handleChange}
            />
            <FormSalesProgress
              sold={values.priceTier ? values.priceTier.sold : values.ticketType?.sold}
              limit={values.allocation}
            />
          </FormRow>
        </Form>
        <AgreementCheckbox
          name="userAgreed"
          label={
            <>
              {intl.formatMessage({ id: 'i_accept_the' })}{' '}
              <TermsLink href="https://dice.fm/terms_and_conditions.html" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'terms_and_conditions' })}
              </TermsLink>
            </>
          }
          checked={values.userAgreed}
          onChange={handleChange}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl
          disabled={!values.userAgreed || (!values.priceTier && !values.ticketType)}
          loading={updating}
          onClick={handleSubmit}
        >
          {intl.formatMessage({ id: pooled ? 'change_quantity' : 'change_allocation' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ChangeAllocationModal)
