import React, { memo, forwardRef, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { Text } from './Text'
import { color } from '../utils/variables'
import Button, { IButtonProps } from './Button'
import Svg from './Svg'
import { textStyle } from '../utils/typography'

const TheButton = styled(Button)`
  height: unset;
  border-width: 2px;
  border-color: ${({ disabled }) => (disabled ? color.disabled : color.grey)};
  border-radius: 10px;
  margin: 16px;
  padding: 16px 32px;
  height: 162px;
  text-transform: none;

  ${textStyle.heading.sm}

  && {
    font-weight: bold;
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  & + & {
    margin: 16px;
  }

  .button_content {
    white-space: normal;
    color: ${({ disabled }) => (disabled ? color.disabled : color.text)};
    pointer-events: initial;
  }

  ${Text} {
    margin-top: 4px;
    color: ${({ disabled }) => (disabled ? color.disabled : color.text)};
  }

  &.button.-preset-outline:hover {
    border-color: ${({ disabled }) => (disabled ? color.disabled : color.primary)};

    color: ${({ disabled }) => (disabled ? color.disabled : color.primary)};

    ${Text}, .button_content {
      color: ${({ disabled }) => (disabled ? color.disabled : color.primary)};
    }
  }
`

interface IProps {
  title: ReactNode
  subtitle: ReactNode
}

const HugeButton = forwardRef<typeof TheButton, IButtonProps & IProps>(({ title, subtitle, icon, ...props }, ref) => {
  return (
    <TheButton {...props} preset="outline" ref={ref}>
      {icon && <Svg icon={icon} width={64} height={64} />}
      <div>{title}</div>
      <Text fontSize="sm">{subtitle}</Text>
    </TheButton>
  )
})

export default styled(memo(HugeButton))``
