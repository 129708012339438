export const SIDEBAR_TOGGLER_TRIGGER_ID = 'globalSidebarTogglerElement'
export const USER_ACCOUNT_TRIGGER_ID = 'globalUserAccountElement'

export const showAccountSwitcher = () => {
  const sidebarTrigger = document.getElementById(SIDEBAR_TOGGLER_TRIGGER_ID)
  if (sidebarTrigger) {
    sidebarTrigger.click()
  }

  setTimeout(() => {
    const accountTrigger = document.getElementById(USER_ACCOUNT_TRIGGER_ID)
    if (accountTrigger) {
      accountTrigger.click()
    }
  }, 0)
}
