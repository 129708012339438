import styled from 'styled-components/macro'
import { color } from '../utils/variables'

const Warning = styled.div`
  background: ${color.warningBg};
  border: 2px solid ${color.warning};
  border-radius: 4px;
  padding: 10px 16px;

  && a {
    color: ${color.primary};
    vertical-align: inherit;
  }

  & > div + div {
    margin-top: 8px;
  }
`

export default Warning
