/**
 * @generated SignedSource<<f8cf1f50ed176ec4727b95631b94dc6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardSales_viewer$data = {
  readonly revenueReport: ReadonlyArray<{
    readonly revenue: number;
    readonly soldTickets: number;
  } | null> | null;
  readonly totalEvents: {
    readonly onSaleEventsCount: number;
  } | null;
  readonly " $fragmentType": "DashboardSales_viewer";
};
export type DashboardSales_viewer$key = {
  readonly " $data"?: DashboardSales_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardSales_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "currency",
    "variableName": "currency"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currency"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardSales_viewer",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "RevenueReportItem",
      "kind": "LinkedField",
      "name": "revenueReport",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "soldTickets",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "totalEvents",
      "args": (v0/*: any*/),
      "concreteType": "AggregatedSalesReport",
      "kind": "LinkedField",
      "name": "aggregatedSales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onSaleEventsCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "0042c35d88c8479ff9c2e8b25b53877c";

export default node;
