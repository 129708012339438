/**
 * @generated SignedSource<<19779194f5ac6c2631e7cda27d52397c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaxRates_event$data = {
  readonly taxRates: ReadonlyArray<{
    readonly faceValueRate: number | null;
    readonly feesRates: {
      readonly additionalPromoterFee: number | null;
      readonly bookingFee: number | null;
      readonly charityFee: number | null;
      readonly extraCharge: number | null;
      readonly facilityFee: number | null;
      readonly foodAndBeverage: number | null;
      readonly fulfillment: number | null;
      readonly meetAndGreet: number | null;
      readonly presale: number | null;
      readonly processingFee: number | null;
      readonly pwlFee: number | null;
      readonly tierDiff: number | null;
      readonly vendor: number | null;
      readonly venueFee: number | null;
      readonly venueLevy: number | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "TaxRates_event";
};
export type TaxRates_event$key = {
  readonly " $data"?: TaxRates_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaxRates_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxRates_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxRate",
      "kind": "LinkedField",
      "name": "taxRates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "faceValueRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FeesRates",
          "kind": "LinkedField",
          "name": "feesRates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "additionalPromoterFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "charityFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extraCharge",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "facilityFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "foodAndBeverage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fulfillment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "meetAndGreet",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "presale",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "processingFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pwlFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tierDiff",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "venueFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "venueLevy",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vendor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "c1a82eff25443b8975ca92b20b23fc19";

export default node;
