import { eachWeekOfInterval, endOfWeek, startOfDay, startOfWeek } from 'date-fns'
import { map, reverse } from 'lodash/fp'
import { dateFnsLocales, ILocale } from '../../../intl'

const dateOptions = (locale: ILocale) => {
  const weekStartOnMonday = !(locale === 'en-US' || locale === 'en-CA')
  return { locale: dateFnsLocales[locale], weekStartsOn: weekStartOnMonday ? 1 : 0 } as const
}

export const alignDateStart = <D extends Date | null>(d: D, locale: ILocale): D => {
  if (!d) return null as D
  return startOfDay(startOfWeek(d, dateOptions(locale))) as D
}

export const alignDateEnd = <D extends Date | null>(d: D, locale: ILocale) => {
  if (!d) return null as D
  return startOfDay(endOfWeek(d, dateOptions(locale))) as D
}

export const getWeekCalendar = (locale: ILocale, start: Date, end: Date) => {
  const weeks = map(
    (d) => [startOfWeek(d, dateOptions(locale)), startOfDay(endOfWeek(d, dateOptions(locale)))] as const,
    eachWeekOfInterval({ start, end }, dateOptions(locale))
  )

  return reverse(weeks)
}
