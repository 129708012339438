import React, { FC, memo, useContext, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { trackingContext, PageViewTracker } from '../../../context/tracking'
import { useDebounce } from '../../../utils/hooks/useDebounce'

const SmartViewTracker: FC<React.PropsWithChildren<{ listType: string }>> = ({ listType }) => {
  const { trackEvent } = useContext(trackingContext)

  const { values } = useFormikContext<{ search?: string }>()
  const debouncedSearch = useDebounce(values.search, 500)

  const trackingDataPage = useMemo(
    () => (debouncedSearch ? null : { event_category: listType }),
    [debouncedSearch, listType]
  )

  const trackingDataSearch = useMemo(
    () => (debouncedSearch ? { search_query: debouncedSearch } : null),
    [debouncedSearch]
  )

  useEffect(() => {
    if (!trackingDataSearch) return

    trackEvent('search_submitted', trackingDataSearch)
  }, [trackingDataSearch, trackEvent])

  return trackingDataPage && <PageViewTracker trackId="events_list" trackData={trackingDataPage} />
}

export default memo(SmartViewTracker)
