import React, { ComponentType, useContext, useEffect, useRef } from 'react'
import * as Sentry from '@sentry/react'
import EventListTypeContext, { IEventListType } from '../../util/eventListTypeContext'

const ListTypeProtector = <P extends {}>(
  Component: ComponentType<React.PropsWithChildren<P>>,
  expectedListType: IEventListType
) =>
  // eslint-disable-next-line func-names
    function ProtectedComponent(props: P) {
      const myListType = useContext(EventListTypeContext)

      const reported = useRef(false)
      useEffect(() => {
        if (reported.current || myListType === expectedListType) return
        reported.current = true

        Sentry.captureException(
          new Error(`Protected component ${Component.displayName} is rendered in forbidden ${myListType} context`)
        )
      }, [myListType])

      if (myListType !== expectedListType) {
        return null
      }

      return <Component {...props} />
    }

export default ListTypeProtector
