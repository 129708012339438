import React, { FC, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { notificationContext } from '../../../context/notification'

import { TextCenter } from '../../../components/Text'

import FormField from '../../../components/FormField'
import Button from '../../../components/Button'
import Svg from '../../../components/Svg'
import { Form, FormRow } from '../../../components/Form'

import { AuthFormHeader, AuthForm, AuthFormSuccess, AuthFormWrapper } from '../Auth'

import REQUESTS from '../../../utils/requests'
import { trackingContext } from '../../../context/tracking'

const RecoverPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
})

const RecoverPassword: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)

  const formik = useFormik({
    initialValues: {
      email: '',
      submitted: false,
    },
    validationSchema: RecoverPasswordSchema,
    onSubmit: async ({ email }, { setFieldValue }) => {
      const { status } = await REQUESTS.FORGOT_PASSWORD(email)
      if (status && status === 201) {
        setFieldValue('submitted', true)
      } else {
        addNotification('error', 'Invalid email')
      }
    },
  })

  const trackLogin = useCallback(() => trackEvent('login_started', { source_name: 'recover_password' }), [trackEvent])

  const { values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting } = formik

  return (
    <AuthFormWrapper>
      {values.submitted ? (
        <>
          <AuthFormSuccess>
            <Svg icon="check" />
            <p>{intl.formatMessage({ id: 'auth.reset_password_success' })}</p>
          </AuthFormSuccess>
          <Form>
            <FormRow>
              <TextCenter color="text" bold>
                <Button preset="link" color="primary" to="/auth/login" onClick={trackLogin}>
                  {intl.formatMessage({ id: 'auth.back_to_signin' })}
                </Button>
              </TextCenter>
            </FormRow>
          </Form>
        </>
      ) : (
        <>
          <AuthFormHeader
            title={intl.formatMessage({ id: 'auth.forgot_password' })}
            description={intl.formatMessage({ id: 'auth.description' })}
            backLink="/auth/login"
          />
          <AuthForm onSubmit={handleSubmit}>
            <Form>
              <FormRow>
                <FormField
                  name="email"
                  label={intl.formatMessage({ id: 'auth.email_address' })}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                />
              </FormRow>
              <FormRow>
                <TextCenter display="block">
                  <Button
                    block
                    type="submit"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting || !values.email}
                  >
                    {intl.formatMessage({ id: 'auth.reset_password' })}
                  </Button>
                </TextCenter>
              </FormRow>
            </Form>
          </AuthForm>
        </>
      )}
    </AuthFormWrapper>
  )
}

export default RecoverPassword
