import React, { lazy } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router'
import PermissionGate from '../gates/PermissionGate'
import VenuesSection from './VenuesSection'
import InviteUserPage from '../../flows/InviteUser/InviteUserPage'

import CreatePermissionProfilePage from '../../flows/CreatePermissionProfile/CreatePermissionProfilePage'

const PersonalDetails = lazy(
  () => import(/* webpackChunkName: "account" */ '../../flows/PromoterSettings/flows/PersonalDetails')
)
const PromoterDetails = lazy(
  () => import(/* webpackChunkName: "account" */ '../../flows/PromoterSettings/flows/PromoterDetails')
)
const NtsFiscalInfoPage = lazy(
  () => import(/* webpackChunkName: "account" */ '../../flows/NtsFiscalInfo/NtsFiscalInfoPage')
)
const Users = lazy(() => import(/* webpackChunkName: "users" */ '../../flows/PromoterSettings/flows/Users'))
const Permissions = lazy(
  () => import(/* webpackChunkName: "permissions" */ '../../flows/PromoterSettings/flows/Permissions')
)
const IntegrationsPage = lazy(
  () => import(/* webpackChunkName: "integrations" */ '../../flows/Integrations/IntegrationsPage')
)

const PromoterSettingsSection = () => (
  <Routes>
    <Route path="*" element={<Outlet />}>
      <Route path="*" element={<Navigate replace to="personal-details" />} />
      <Route path="personal-details" element={<PersonalDetails />} />
      <Route path="promoter-details" element={<PromoterDetails />} />
      <Route path="bank-details" element={<PromoterDetails />} />
      <Route
        path="nts-fiscal-info"
        element={
          <PermissionGate permission="read_attractive_fiscal_info:event">
            <NtsFiscalInfoPage />
          </PermissionGate>
        }
      />
      <Route path="integrations" element={<IntegrationsPage />} />
      <Route path="venues/*" element={<VenuesSection />} />

      <Route
        path="users/*"
        element={
          <Routes>
            <Route index element={<Users />} />
            <Route path="new" element={<InviteUserPage />} />
          </Routes>
        }
      />

      <Route
        path="permissions/*"
        element={
          <Routes>
            <Route index element={<Permissions />} />
            <Route
              path="new"
              element={
                <PermissionGate permission="manage:permission_profile">
                  <CreatePermissionProfilePage />
                </PermissionGate>
              }
            />
          </Routes>
        }
      />
    </Route>
  </Routes>
)

export default PromoterSettingsSection
