import React, { FC, createContext, useMemo, useState, useCallback, useEffect, ReactNode } from 'react'
import { filter, isString, noop } from 'lodash/fp'
import * as Sentry from '@sentry/react'
import { nanoid } from 'nanoid'

import Notifications from '../components/Notifications'
import { INotificationType } from '../components/NotificationMessage'

export interface INotification {
  id: string
  type: INotificationType
  message: string | ReactNode
}

export interface INotificationContext {
  addNotification: (type: INotificationType, message: string | ReactNode) => void
  removeNotification: (id: string) => void
}

export const notificationContext = createContext<INotificationContext>({
  addNotification: noop,
  removeNotification: noop,
})

declare global {
  interface Window {
    __notifications?: {
      addNotification: (type: INotificationType, message: string) => void
      removeNotification: (id: string) => void
    }
  }
}

const NotificationProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<INotification>>([])

  const addNotification = useCallback(
    (type: any, message: string | ReactNode) => {
      Sentry.addBreadcrumb({
        category: 'notification',
        message: isString(message) ? message : '<React node>',
        data: { type },
        level: 'info',
      })
      setNotifications((arr) => [...arr, { id: nanoid(), type, message }])
    },
    [setNotifications]
  )

  const removeNotification = useCallback(
    (id: any) => {
      setNotifications(filter((n) => n.id !== id))
    },
    [setNotifications]
  )

  const ctxValue = useMemo(
    () => ({
      addNotification,
      removeNotification,
    }),
    [addNotification, removeNotification]
  )

  useEffect(() => {
    window.__notifications = ctxValue
    return () => {
      window.__notifications = undefined
    }
  }, [ctxValue])

  return (
    <notificationContext.Provider value={ctxValue}>
      <Notifications notifications={notifications} removeNotification={removeNotification} />
      {children}
    </notificationContext.Provider>
  )
}

export default NotificationProvider
