import React, { FC, useState, useCallback, useContext, useMemo, useRef } from 'react'
import styled from 'styled-components/macro'

import { Editor, EditorProps, EditorState } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { color, mediaQuery } from '../utils/variables'

import boldIcon from '../assets/icons/editor-bold.svg'
import italicIcon from '../assets/icons/editor-italic.svg'
import unorderedIcon from '../assets/icons/editor-unordered.svg'
import orderedIcon from '../assets/icons/editor-ordered.svg'
import { localeContext } from '../context/locale'

interface IContainerProps {
  hasError?: boolean
  focused?: boolean
  disabled?: boolean
}

const borderColor = ({ hasError, focused }: IContainerProps) => {
  if (focused) return color.primary
  if (hasError) return color.error
  return color.lightgrey
}

const activeColor = ({ disabled, focused }: IContainerProps) => {
  if (disabled) return color.lightgrey
  return focused ? color.primary : color.white
}

const Container = styled.div<IContainerProps>`
  width: 100%;
  border: 2px solid ${borderColor};
  border-radius: 4px;
  color: ${({ disabled }) => (disabled ? color.darkgrey : 'inherit')};
  background-color: ${({ disabled }) => (disabled ? color.palegrey : color.white)};

  overflow: auto;
  resize: vertical;
  min-height: 108px;
  max-height: 300px;

  ${mediaQuery.lessThan('desktopLarge')`
    min-height: 128px;
  `}

  &:hover {
    border-color: ${({ focused }) => (focused ? color.primary : color.grey)};
  }

  .rdw-editor-main {
    padding: 6px 16px;
    max-height: 254px;
  }

  .rdw-editor-toolbar {
    padding: 0;
    margin: 0;

    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid ${color.lightgrey};

    background-color: ${({ disabled }) => (disabled ? color.palegrey : color.white)};
  }

  .rdw-inline-wrapper,
  .rdw-list-wrapper {
    margin: 0;
  }

  .rdw-option-wrapper {
    position: relative;
    width: 44px;
    height: 40px;
    border: none;
    margin: 0;
    border-radius: 0;
    background-color: ${({ disabled }) => (disabled ? color.palegrey : color.white)};

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 2px solid ${color.lightgrey};
    }
  }

  .rdw-option-wrapper:hover {
    box-shadow: none;
  }

  .rdw-option-active {
    box-shadow: none;

    background-color: ${activeColor};

    z-index: ${({ disabled, focused }) => (!focused || disabled ? 'initial' : '1')};

    & > img {
      filter: ${({ disabled, focused }) => (!focused || disabled ? 'none' : 'invert(1)')};
    }

    &:before {
      border-color: ${({ disabled, focused }) => (!focused || disabled ? color.lightgrey : color.primary)};
    }
  }

  .public-DraftEditorPlaceholder-inner {
    margin-left: 4px;
  }
`

const TOOLBAR = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic'],
    bold: { icon: boldIcon },
    italic: { icon: italicIcon },
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: { icon: unorderedIcon },
    ordered: { icon: orderedIcon },
  },
}

const TOOLBAR_MINIMAL = {
  options: ['inline'],
  inline: {
    options: ['bold', 'italic'],
    bold: { icon: boldIcon },
    italic: { icon: italicIcon },
  },
}

export interface IMarkdownProps {
  toolbar?: 'full' | 'minimal'
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  value: EditorState
  placeholder?: string
}

const MarkdownEditor: FC<
  React.PropsWithChildren<
    Omit<EditorProps, 'onChange'> & {
      name?: string
      hasError?: boolean
      required?: boolean
      disabled?: boolean
      value: EditorState
      toolbar?: 'full' | 'minimal'
      onChange: EditorProps['onEditorStateChange']
    }
  >
> = ({
  name,
  hasError,
  // eslint-disable-next-line no-unused-vars
  required,
  disabled,
  value,
  onChange,
  onBlur,
  onFocus,
  toolbar,
  ...props
}) => {
  const { locale } = useContext(localeContext)
  const [focused, setFocus] = useState(false)

  const doFocus = useCallback(() => {
    setFocus(true)
    if (onFocus) onFocus({ target: { name } } as any)
  }, [name, onFocus])

  const doBlur = useCallback(() => {
    setFocus(false)
    if (onBlur) onBlur({ target: { name } } as any)
  }, [name, onBlur])

  const localization = useMemo(() => ({ locale: locale.substring(0, 2) }), [locale])

  const ref = useRef<any>(null)
  const setEditorRef = useCallback((newRef: any) => {
    ref.current = newRef
  }, [])

  const focusEditor = useCallback(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  return (
    <Container
      hasError={hasError}
      focused={focused}
      disabled={disabled}
      onClick={focusEditor}
      data-name={name}
      data-disabled={disabled}
    >
      <Editor
        toolbar={toolbar === 'minimal' ? TOOLBAR_MINIMAL : TOOLBAR}
        onFocus={doFocus}
        onBlur={doBlur}
        readOnly={disabled}
        {...props}
        editorState={value}
        onEditorStateChange={onChange}
        localization={localization}
        stripPastedStyles
        editorRef={setEditorRef}
      />
    </Container>
  )
}

export default MarkdownEditor
