import React, { lazy } from 'react'
import { Route, Routes } from 'react-router'
import PermissionGate from '../gates/PermissionGate'

const MarketeersPage = lazy(() => import(/* webpackChunkName: "marketeers" */ '../../flows/Marketeers/MarketeersPage'))
const MarketeerPage = lazy(() => import(/* webpackChunkName: "marketeers" */ '../../flows/Marketeer/MarketeerPage'))

const MarketeersSection = () => (
  <Routes>
    <Route index element={<MarketeersPage />} />

    <Route
      path="new"
      element={
        <PermissionGate permission="create:marketeer">
          <MarketeerPage />
        </PermissionGate>
      }
    />

    <Route path=":id" element={<MarketeerPage />} />
  </Routes>
)

export default MarketeersSection
