import React, { FC, ChangeEvent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import Radio from './Radio'
import { color } from '../utils/variables'

export type IRadioOptionValue = string | boolean

interface IRadioOption {
  value: IRadioOptionValue
  label: ReactNode
  children?: ReactNode
  selectedSuffix?: ReactNode
}

export interface IRadioGroupProps {
  name: string
  value?: IRadioOptionValue | null
  options: IRadioOption[]
  onChange: (value: IRadioOptionValue) => void
  layout?: 'normal' | 'boxed'
}

const Group = styled.div<{ layout: 'normal' | 'boxed' }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  ${(props) =>
    props.layout === 'boxed'
      ? css`
          padding: 16px;
          border: 2px solid ${color.lightgrey};
          border-radius: 4px;
          background: ${color.white};
          gap: 0;
          margin-top: 0;

          > div {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding-bottom: 16px;
            margin-bottom: 16px;
          }

          > div:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        `
      : css``}
`

const OptionChildren = styled.div`
  margin-top: 16px;
  margin-left: 32px;
`

const OptionRow = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectedSuffixWrapper = styled.div`
  margin-top: 16px;
  margin-left: 32px;
`

const RadioGroup: FC<IRadioGroupProps> = ({ name, value, options, onChange, layout = 'normal' }) => {
  const onChangeCallback = (value: IRadioOptionValue) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onChange(value)
      }
    }
  }

  return (
    <Group layout={layout}>
      {options.map((opt) => (
        <OptionRow key={opt.value.toString()}>
          <Radio checked={value === opt.value} name={name} onChange={onChangeCallback(opt.value)} label={opt.label} />
          {value === opt.value && opt.children ? <OptionChildren>{opt.children}</OptionChildren> : null}
          {opt.selectedSuffix && opt.value === value ? (
            <SelectedSuffixWrapper>{opt.selectedSuffix}</SelectedSuffixWrapper>
          ) : null}
        </OptionRow>
      ))}
    </Group>
  )
}

export default RadioGroup
