import React, { useCallback, useMemo, FC, memo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { find, isNil } from 'lodash/fp'
import { useLazyLoadQuery } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import * as Yup from 'yup'

import FormField from '../../../../components/FormField'
import { Form, FormRow } from '../../../../components/Form'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../../../../components/Modal'

import { ChangeTicketSalesLimitModalQuery } from '../../../../__generated__/ChangeTicketSalesLimitModalQuery.graphql'
import { ITicketType } from '../../../EventForm/types/Tickets'
import { TicketBreakdown_event$data } from '../../../../__generated__/TicketBreakdown_event.graphql'
import FormSalesProgress from '../../../../components/FormSalesProgress'

const SalesLimitSchema = Yup.object()
  .shape({
    salesLimit: Yup.number().nullable(),
  })
  .test('salesLimitAboveSales', 'Sales limit must be higher than current sales', function (values) {
    const valid = values.salesLimit >= values.totalSales || isNil(values.salesLimit)
    return (
      valid ||
      this.createError({
        path: 'salesLimit',
        message: `new_event.tickets.ticket_type_edit.sales_limit.min_sales_error%${values.totalSales}`,
      })
    )
  })

interface IProps {
  event: TicketBreakdown_event$data
  eventId: string
  ttyId?: string | null
  onClose: () => void
  onSave: (ticketType: ITicketType) => void
}

const ChangeTicketSalesLimitModal: FC<IProps> = ({ eventId, ttyId, onClose, onSave }) => {
  const intl = useIntl()

  const { event } = useLazyLoadQuery<ChangeTicketSalesLimitModalQuery>(
    graphql`
      query ChangeTicketSalesLimitModalQuery($id: ID!) {
        event: node(id: $id) {
          ... on Event {
            id
            eventIdLive

            ticketTypes(doorSalesOnly: false, includeArchived: true) {
              id
              name
              archived
              hidden

              ticketPoolId
              salesLimit
              additionalPaymentMethods
              activateCodeDateOffset
              venueScheduleId
              codeLocked
              startDate
              endDate
              isStream
              description
              allocation
              faceValue
              requiresAddress
              presale
              icon
              increment
              maximumIncrements
              doorSalesPrice
              doorSalesEnabled
              doorSalesPriceTaxed
              doorSalesTax
              announceDate
              onSaleDate
              offSaleDate
              attractiveSeatingAreaType
              attractivePriceType
              seatmapUrl
              priceTierType
              streamLink
              externalSkus

              allowSeatChange
              reservedSeating
              reservedSeatingType
              seatCategories {
                id
                name
                seatsIoKey
              }

              fees {
                amount
                type
                unit
                applicable

                split {
                  amount
                  destination
                  unit
                }
              }

              priceBreakdown {
                breakdown {
                  computed
                  type
                  applicable
                  split {
                    amount
                    computed
                    destination
                  }
                }
                total
                totalWithPwl
                totalWithoutPwl
                faceValue
                split {
                  computed
                  destination
                }
                friendlyPrice
                friendlyFaceValue
              }

              priceTiers {
                id
                name
                doorSalesPrice
                doorSalesPriceTaxed
                faceValue
                allocation
                time
                attractivePriceType
                fees {
                  amount
                  type
                  unit
                  applicable

                  split {
                    amount
                    destination
                    unit
                  }
                }
                priceBreakdown {
                  breakdown {
                    computed
                    type
                    applicable
                    split {
                      amount
                      computed
                      destination
                    }
                  }
                  total
                  totalWithPwl
                  totalWithoutPwl
                  faceValue
                  split {
                    computed
                    destination
                  }
                  friendlyPrice
                  friendlyFaceValue
                }
              }
            }

            sales {
              ticketTypesBreakdown {
                ticketTypeId
                totalAppSold
                totalPosSold
                totalTerminalSold
                totalReserved
              }
            }
          }
        }
      }
    `,
    { id: eventId },
    { fetchPolicy: 'network-only' }
  )

  const ticketType = useMemo(
    () => find((tty) => tty?.id === ttyId, event?.ticketTypes || []),
    [event?.ticketTypes, ttyId]
  )

  const initialValues = useMemo(
    () => ({
      salesLimit: ticketType?.salesLimit ?? null,
      userAgreed: false,
    }),
    [ticketType?.salesLimit]
  )

  const onSubmit = useCallback(
    async ({ salesLimit }: typeof initialValues, formikProps: any) => {
      if (!ticketType?.id) return

      onSave({
        ...ticketType,
        salesLimit: salesLimit,
      } as ITicketType)
    },
    [onSave, ticketType]
  )

  const { values, touched, errors, handleBlur, handleSubmit, setFieldValue, isValid } = useFormik({
    initialValues,
    validationSchema: SalesLimitSchema,
    validateOnBlur: true,
    onSubmit,
  })

  const totalTicketTypeSales: number = useMemo(() => {
    const ttyBreakdown = find((b) => b?.ticketTypeId === ttyId, event?.sales?.ticketTypesBreakdown || [])
    if (!ttyBreakdown) return 0
    const { totalAppSold = 0, totalPosSold = 0, totalTerminalSold = 0, totalReserved = 0 } = ttyBreakdown

    return totalAppSold + totalPosSold + totalTerminalSold + totalReserved || 0
  }, [event?.sales?.ticketTypesBreakdown, ttyId])

  useEffect(() => {
    setFieldValue('totalSales', totalTicketTypeSales)
  }, [setFieldValue, totalTicketTypeSales])

  const handleSalesLimitChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value
      const int = parseInt(newVal) ?? null
      setFieldValue('salesLimit', isNaN(int) ? null : int)
    },
    [setFieldValue]
  )

  return (
    <Modal modalTitle={intl.formatMessage({ id: 'change_sales_limit' })} closeButton onClose={onClose}>
      <ModalBody>
        <Form>
          <FormRow>
            <FormField
              label={intl.formatMessage({ id: 'ticket_type' })}
              placeholder={intl.formatMessage({ id: 'ticket_type.placeholder' })}
              control="select"
              value={{
                value: ttyId,
                label: ticketType?.name,
              }}
              options={[
                {
                  value: ttyId,
                  label: ticketType?.name,
                },
              ]}
              disabled
            />
          </FormRow>
          <FormRow columnOnMobile>
            <FormField
              name="salesLimit"
              label={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.sales_limit.label' })}
              hint={intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.sales_limit.hint' })}
              value={values.salesLimit}
              error={touched.salesLimit && errors.salesLimit}
              type="number"
              min={0}
              step={1}
              onChange={handleSalesLimitChange}
              onBlur={handleBlur}
            />

            <FormSalesProgress sold={totalTicketTypeSales} limit={values.salesLimit} />
          </FormRow>
        </Form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl disabled={!isValid} onClick={handleSubmit}>
          {intl.formatMessage({ id: 'change_sales_limit' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ChangeTicketSalesLimitModal)
