import { always } from 'lodash/fp'
import { Dictionary } from 'ts-essentials'
import { IBlockers } from '../../../utils/payoutBlockers'

export type IPayoutStatus = 'isOverPaid' | 'noPayout' | 'isHeld' | 'isPaid' | 'isAuto' | 'isRolling' | 'isManual'

export const PAYOUT_STATUSES: ReadonlyArray<IPayoutStatus> = [
  'isManual',
  'isPaid',
  'isOverPaid',
  'isHeld',
  'noPayout',
  'isRolling',
  'isAuto',
]

export const PAYOUTS_STATUS_LABELS: Dictionary<string, IPayoutStatus> = {
  isManual: 'event_payouts.payouts.status.manual',
  isPaid: 'event_payouts.payouts.status.paid',
  isOverPaid: 'overpaid',
  isHeld: 'event_payouts.payouts.status.held',
  noPayout: 'event_status.no_payout',
  isRolling: 'event_payouts.payouts.status.auto_rolling',
  isAuto: 'event_payouts.payouts.status.auto_payout',
}

interface IEvent {
  autopaymentFailed: boolean | null
  sales: null | {
    totalPayoutValue: number | null
    totalPromoterIncome: number | null
  }
  selfPayoutBlockers: IBlockers | null
}

type IPredicate = (ev: IEvent) => boolean

const CONDITIONS: Array<[IPayoutStatus, IPredicate]> = [
  ['isOverPaid', (ev: IEvent) => (ev.sales?.totalPayoutValue || 0) < 0],
  ['isPaid', (ev: IEvent) => (ev.sales?.totalPromoterIncome || 0) > 0 && (ev.sales?.totalPayoutValue || 0) === 0],
  ['noPayout', (ev: IEvent) => (ev.sales?.totalPromoterIncome || 0) === 0 || !!ev.selfPayoutBlockers?.event_postponed],

  ['isHeld', (ev: IEvent) => !!ev.selfPayoutBlockers?.hold_payouts],
  ['isRolling', (ev: IEvent) => !!ev.selfPayoutBlockers?.rolling_payments],
  [
    'isAuto',
    (ev: IEvent) =>
      !ev.autopaymentFailed &&
      !ev.selfPayoutBlockers?.refunds_after &&
      !ev.selfPayoutBlockers?.stripe_account_inconsistent,
  ],
  ['isManual', always(true)],
]

const payoutStatus = (ev: IEvent) => {
  for (const [status, predicate] of CONDITIONS) {
    if (predicate(ev)) return status
  }
  throw new Error('Unknown event payment status')
}

export default payoutStatus
