import { useMemo, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { filter, find, concat, compact, reject } from 'lodash/fp'

import IEventFormSettings from '../types/Settings'
import { markAsClientOnly } from '../../../utils/entityStatus'
import { IImage } from '../types/Information'

export default function useSettingsBrandLogo() {
  const { values, setFieldValue } = useFormikContext<IEventFormSettings>()

  const rawImage = values.rawBrandLogo
  const setRawImage = useCallback((rawImage: any) => setFieldValue('rawBrandLogo', rawImage), [setFieldValue])
  const brandLogo = useMemo(() => filter(['type', 'brand'], compact(values.eventImages || [])), [values.eventImages])

  const setBrandLogo = useCallback(
    (newImages: any) =>
      setFieldValue('eventImages', concat(newImages, reject(['type', 'brand'], values.eventImages || []))),
    [setFieldValue, values.eventImages]
  )

  useEffect(() => {
    if (rawImage) return

    const logoImage = find(['type', 'brand'], brandLogo)

    if (logoImage && logoImage.cdnUrl.startsWith('http')) {
      let stillActive = true

      let url = logoImage.cdnUrl
      url = url.indexOf('?') >= 0 ? url.substring(0, url.indexOf('?')) : url

      const realImg = new Image()
      realImg.crossOrigin = 'anonymous'
      realImg.onload = () => {
        if (stillActive) {
          setRawImage(
            markAsClientOnly<IImage>({
              type: 'original',
              cdnUrl: url,
              attachment: null,
              cropRegion: {
                x: 0,
                y: 0,
                width: realImg.naturalWidth,
                height: realImg.naturalHeight,
              },
            })
          )
        }
      }
      realImg.src = url

      return () => {
        stillActive = false
      }
    }
  }, [brandLogo, rawImage, setRawImage])

  return {
    rawImage,
    setRawImage,
    brandLogo,
    setBrandLogo,
  }
}
