import styled from 'styled-components/macro'
import { color, font, mediaQuery } from '../utils/variables'
import IconButton from './IconButton'
import { Loader } from './Loader'
import { textStyle } from '../utils/typography'

export const Uploader = styled.div<{ error?: boolean; focus?: boolean }>`
  border: 2px solid ${({ error, focus }) => (focus ? color.primary : error ? color.error : color.lightgrey)};
  height: 120px;
  display: flex;
  margin-bottom: 8px;
  border-radius: 4px;
  position: relative;

  input[type='file'] {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
  }

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
    height: auto;
  `}
`

export const ImagePreviewWrapper = styled.div`
  position: relative;
  display: flex;
  height: 116px;
  width: 159px;
  align-items: center;
  justify-content: center;

  ${mediaQuery.lessThan('tablet')`
    width: 100%;
    padding-bottom: 100%;
  `}

  & > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;

  ${mediaQuery.lessThan('tablet')`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`

export const UploadButton = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  height: 116px;
  width: 159px;
  padding: 20px;
  font-weight: bold;
  cursor: pointer;

  svg {
    pointer-events: none;
    display: block;
  }

  ${mediaQuery.lessThan('tablet')`
    width: 100%;
    height: 56px;
    line-height: 56px;
    padding: 0;
    flex-direction: row;
  `}
`

export const UploadInfoPanel = styled.div<{ error?: boolean; focus?: boolean }>`
  position: relative;
  border-style: solid;
  border-width: 0 0 0 2px;
  border-color: ${({ error, focus }) => (focus ? color.primary : error ? color.error : color.lightgrey)};
  padding: 24px;
  color: ${color.darkgrey};
  flex: 1;
  overflow: hidden;

  strong {
    color: ${color.text};
  }

  ${mediaQuery.lessThan('tablet')`
    border-width: 2px 0 0 0;
    font-size: ${font.size.sm}px;
    padding: 16px;
  `}
`

export const UploadFileName = styled.div`
  padding-top: 3px;
  color: ${color.text};
  ${textStyle.interactive.lg}
  margin-bottom: 8px;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${mediaQuery.lessThan('tablet')`
    max-width: calc(100vw - 120px);
    ${textStyle.interactive.md}
    padding: 0;
    margin-bottom: 4px;
  `}
`

export const RemoveButton = styled(IconButton)`
  position: absolute;
  top: calc(50% - 17px);
  right: 24px;

  color: ${color.black};

  &:hover {
    color: ${color.black};
  }

  ${mediaQuery.lessThan('tablet')`
    right: 8px;
  `}
`

export const LoaderContainer = styled.div`
  position: relative;
  height: 116px;
  width: 159px;

  ${mediaQuery.lessThan('tablet')`
    width: 100%;
    padding-bottom: 100%;
  `}

  ${Loader} {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`
