import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../../../utils/variables'
import { IOptions } from '../types/options'

const STORAGE_KEY = 'eschart_options_v3'

const DEFAULT_OPTIONS: IOptions = {
  groupBy: 'day',
  mode: 'sold',
}

const DEFAULT_MOBILE_OPTIONS: IOptions = {
  groupBy: 'week',
  mode: 'sold',
}

function useOptions() {
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const [options, setOptions] = useState<IOptions>(() => {
    if (isMobile) {
      return DEFAULT_MOBILE_OPTIONS
    }
    const s = localStorage.getItem(STORAGE_KEY)
    if (s) {
      try {
        const options = JSON.parse(s)
        return options as IOptions
      } catch (_) {
        // OOPS, whatever
      }
    }
    return DEFAULT_OPTIONS
  })

  useEffect(() => {
    if (isMobile) return
    localStorage.setItem(STORAGE_KEY, JSON.stringify(options))
  }, [isMobile, options])

  return [options, setOptions] as const
}

export default useOptions
