import { FC, memo, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'

interface IProps {
  delay?: number
}

const IdleValidator: FC<React.PropsWithChildren<IProps>> = ({ delay = 300 }) => {
  const { values, validateForm } = useFormikContext()

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      validateForm()
    }, delay)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [delay, validateForm, values])

  return null
}

export default memo(IdleValidator)
