/**
 * @generated SignedSource<<ce5f3ba7f5bd4134650bc1d44ee86150>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionView_viewer$data = {
  readonly permissionProfileStructure: {
    readonly subjects: ReadonlyArray<{
      readonly actions: ReadonlyArray<{
        readonly category: string | null;
        readonly name: string | null;
      } | null> | null;
      readonly name: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "PermissionView_viewer";
};
export type PermissionView_viewer$key = {
  readonly " $data"?: PermissionView_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermissionView_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PermissionView_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PermissionProfileStructure",
      "kind": "LinkedField",
      "name": "permissionProfileStructure",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Subject",
          "kind": "LinkedField",
          "name": "subjects",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Action",
              "kind": "LinkedField",
              "name": "actions",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "category",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "b249a9a82b48c3b63e11f0383732c193";

export default node;
