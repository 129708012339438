import React, { FC, memo, Suspense, useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { mediaQuery } from '../../../../../utils/variables'
import unwrapId from '../../../../../utils/unwrapId'

import { Modal } from '../../../../../components/Modal'
import { Loader, LoaderContainer } from '../../../../../components/Loader'

import RemittanceModalData from './RemittanceModalData'

import { RemittanceModal_event$key } from '../../../../../__generated__/RemittanceModal_event.graphql'

const StyledLoaderContainer = styled(LoaderContainer)`
  min-height: 250px;
  ${mediaQuery.lessThan('tablet')`
    min-height: 100%;
  `}
`

interface IRemittanceModal {
  event: RemittanceModal_event$key
  onClose: () => void
}

const RemittanceModal: FC<React.PropsWithChildren<IRemittanceModal>> = ({ event: eventKey, onClose }) => {
  const intl = useIntl()

  const event = useFragment(
    graphql`
      fragment RemittanceModal_event on Event {
        id
        name
        eventIdLive
      }
    `,
    eventKey
  )

  const trackData = useMemo(
    () => ({
      event_id: unwrapId(event.id),
      event_id_live: event.eventIdLive,
    }),
    [event.eventIdLive, event.id]
  )

  return (
    <Modal
      trackId="event_remittance_detailed"
      trackData={trackData}
      modalTitle={
        <>
          <span>{intl.formatMessage({ id: 'remittance.remittance_for' })}</span> <strong>{event.name}</strong>
        </>
      }
      closeButton
      onClose={onClose}
    >
      <Suspense
        fallback={
          <StyledLoaderContainer>
            <Loader />
          </StyledLoaderContainer>
        }
      >
        <RemittanceModalData eventId={event.id} onClose={onClose} />
      </Suspense>
    </Modal>
  )
}

export default memo(RemittanceModal)
