import React, { FC, HTMLProps, Fragment, memo, useCallback, useContext, useMemo, useState, Suspense } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { some, reject, getOr, isNumber, reduce } from 'lodash/fp'
import { isPast, parseISO } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import { useFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { authContext } from '../../../../context/auth'
import { localeContext } from '../../../../context/locale'
import { trackingContext } from '../../../../context/tracking'
import { breakpoints, color, font, mediaQuery } from '../../../../utils/variables'
import { CURRENCY } from '../../../../utils/formatters/number'
import { DATETIME_FORMATS } from '../../../../utils/formatters/datetime'
import { closedPriceTier, isPriceTierActive } from '../../../../utils/tiers'
import unwrapId from '../../../../utils/unwrapId'

import { Text as RealText } from '../../../../components/Text'
import Svg from '../../../../components/Svg'
import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../components/Dropdown'
import IconButton from '../../../../components/IconButton'
import { Menu, MenuItem } from '../../../../components/Menu'
import { TitleTooltip } from '../../../../components/Tooltip'
import ChangeAllocationModal from '../../../EventList/components/Modals/ChangeAllocationModal'
import {
  DetailsToggle,
  PriceTier,
  PriceTierCol,
  PriceTiers,
  PriceTiersBreakdown,
  PriceTierName,
  PriceTierStatusDot,
} from './TicketBreakdownStyles'
import PromotionsBreakdown from './PromotionsBreakdown'
import CloseTierModal from './CloseTierModal'

import { PriceTiersBreakdownTable_event$key } from '../../../../__generated__/PriceTiersBreakdownTable_event.graphql'
import {
  PriceTiersBreakdownTable_ticketType$data,
  PriceTiersBreakdownTable_ticketType$key,
} from '../../../../__generated__/PriceTiersBreakdownTable_ticketType.graphql'
import DetailedPriceBreakdownTooltip from '../../../../components/Event/DetailedPriceBreakdownTooltip'
import { Loader, LoaderContainer } from '../../../../components/Loader'

const CloseTier = styled.span<HTMLProps<HTMLSpanElement> & { disabled: boolean }>`
  display: flex;
  position: absolute;
  align-items: center;
  right: 48px;
  font-weight: ${font.weight.bold};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
    opacity: .25;
  `}
  &:hover {
    color: ${color.primary};
  }
  & > svg {
    margin-right: 8px;
  }
  ${mediaQuery.lessThan('desktopLarge')`
    font-size: 0;
    & > svg {
      margin: 0;
    }
  `}
`

const ChangePTAllocation = styled(CloseTier)`
  ${mediaQuery.lessThan('desktopLarge')`
    font-size: 0;
    & > svg {
      margin: 0;
    }
  `}
`

const PTName = styled(PriceTierName)`
  display: flex;
`

const PTPrice = styled.span`
  display: flex;
`

const Additional = styled.div`
  position: absolute;
  right: 0;
`

type IPriceTiers = NonNullable<PriceTiersBreakdownTable_ticketType$data['priceTiersBreakdown']>
type IPriceTier = NonNullable<IPriceTiers[number]>
interface IProps {
  allocationId: string | null
  closeAllocation: () => void
  dropdownId: string | null
  closeDropdown: () => void
  toggleDropdown: (e: any) => void
  doChangeAllocation: (e: any) => void
  hasBoxOffice: boolean
  hasDiscount?: boolean
  event: PriceTiersBreakdownTable_event$key
  ticketType: PriceTiersBreakdownTable_ticketType$key
  isOnSale?: boolean
}

const PriceTiersBreakdownTable: FC<React.PropsWithChildren<IProps>> = (props) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { hasPermission, user } = useContext(authContext)
  const { trackEvent } = useContext(trackingContext)

  const event = useFragment(
    graphql`
      fragment PriceTiersBreakdownTable_event on Event {
        id
        eventIdLive
        costCurrency
        timezoneName
        offSaleDate
        allowedActions {
          minorUpdate
          manageTickets
        }
      }
    `,
    props.event
  )

  const tt = useFragment(
    graphql`
      fragment PriceTiersBreakdownTable_ticketType on TicketTypeBreakdown {
        ticketType {
          id
          priceTierType
          allocation
          ticketPoolId
          salesLimit
          priceHidden
        }
        priceTiersBreakdown {
          priceTier {
            id
            name
            allocation
            time
            faceValue
            price
            order

            priceBreakdown {
              total
              totalWithPwl
              totalWithoutPwl
              faceValue
            }
          }
          appSold
          posSold
          digitalValue
          reserved
          promotionsBreakdown {
            eventPromotion {
              name
              promotionType
            }
            faceValue
            price
            priceWithPwl
            priceWithoutPwl
            posSold
            value
            sold
            payoutValue
            appSold
            rebate
            fees {
              computed
              rebate
              type
            }
          }
        }
      }
    `,
    props.ticketType
  )

  const {
    allocationId,
    closeAllocation,
    dropdownId,
    closeDropdown,
    toggleDropdown,
    doChangeAllocation,
    hasBoxOffice,
    hasDiscount,
    isOnSale,
  } = props
  const isSeated = getOr(false, 'flags.seated.active', event)

  const [closeTierId, setCloseTierId] = useState<string | null>(null)
  const closeTierModal = useCallback(() => setCloseTierId(null), [])
  const closeTier = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      setCloseTierId(id)

      trackEvent('price_tier_closed', {
        event_id: unwrapId(id),
        event_id_live: event.eventIdLive,
        ticket_type_id: unwrapId(tt.ticketType.id),
        price_tier_id: unwrapId(id),
        price_tier_type: tt.ticketType.priceTierType === 'time' ? 'time' : 'allocation',
      })
    },
    [event.eventIdLive, trackEvent, tt.ticketType.id, tt.ticketType.priceTierType]
  )

  const isOffSale = useMemo(
    () => (event.offSaleDate ? isPast(parseISO(event.offSaleDate)) : false),
    [event.offSaleDate]
  )
  const isReadOnly = !event?.allowedActions?.minorUpdate && !event?.allowedActions?.manageTickets

  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.desktop}px)` })
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const canReopenTiers = hasPermission('full_manage_access:event') || user.diceStaff

  const PriceTierActions: FC<React.PropsWithChildren<{ pt: IPriceTier; isLast: boolean; pooled: boolean }>> = useMemo(
    () =>
      ({ pt, isLast, pooled }) => {
        if (pooled && isLast) return null

        const shouldShowChangeAllocationItem =
          !pooled || (!closedPriceTier(tt.ticketType.priceTierType, tt.priceTiersBreakdown, pt) && !isLast)
        const shouldShowCloseTierItem =
          !pooled && !closedPriceTier(tt.ticketType.priceTierType, tt.priceTiersBreakdown, pt)

        const shouldShowActionsMenu = shouldShowChangeAllocationItem || shouldShowCloseTierItem
        if (!shouldShowActionsMenu) return null

        return (
          <>
            {!closedPriceTier(tt.ticketType.priceTierType, tt.priceTiersBreakdown, pt) && !isTablet && !pooled && (
              <ChangePTAllocation
                disabled={isOffSale}
                data-id={pt.priceTier.id}
                data-name="quickChangeAllocationPriceTier"
                onClick={doChangeAllocation}
              >
                <Svg icon="ticket" />
                {intl.formatMessage({
                  id: 'event_overview.ticket_breakdown.actions.change_allocation',
                })}
              </ChangePTAllocation>
            )}
            <Additional>
              <Dropdown active={dropdownId === pt.priceTier.id} onClickOutside={closeDropdown}>
                <DropdownTrigger data-id={pt.priceTier.id} data-name="openMenuPriceTier" onClick={toggleDropdown}>
                  <IconButton icon="more" color={isMobile && 'transparent'} />
                </DropdownTrigger>
                <DropdownContent active={dropdownId === pt.priceTier.id}>
                  <Menu>
                    {shouldShowChangeAllocationItem && (
                      <MenuItem
                        data-id={pt.priceTier.id}
                        data-name="menuChangeAllocationPriceTier"
                        onClick={doChangeAllocation}
                        disabled={isOffSale || (!canReopenTiers && !isLast)}
                      >
                        {intl.formatMessage({
                          id: pooled ? 'change_quantity' : 'event_overview.ticket_breakdown.actions.change_allocation',
                        })}
                      </MenuItem>
                    )}
                    {shouldShowCloseTierItem && (
                      <MenuItem
                        data-id={pt.priceTier.id}
                        data-name="menuClosePriceTier"
                        onClick={closeTier}
                        disabled={isOffSale}
                      >
                        {intl.formatMessage({
                          id: 'event_overview.ticket_breakdown.actions.close_tier',
                        })}
                      </MenuItem>
                    )}
                  </Menu>
                </DropdownContent>
              </Dropdown>
            </Additional>
          </>
        )
      },
    [
      tt.ticketType.priceTierType,
      tt.priceTiersBreakdown,
      isOffSale,
      doChangeAllocation,
      intl,
      dropdownId,
      closeDropdown,
      toggleDropdown,
      isTablet,
      isMobile,
      canReopenTiers,
      closeTier,
    ]
  )

  const [expanded, setExpanded] = useState<any>([])
  const toggleDetails = useCallback(
    (e: any) => {
      const tty = e.currentTarget.dataset['pt']
      setExpanded(some((id) => id === tty, expanded) ? reject((id) => id === tty, expanded) : [...expanded, tty])
    },
    [expanded]
  )

  const pooled = !!tt.ticketType.ticketPoolId

  const finalTierRemainingTickets = useMemo(() => {
    const salesLimit = tt.ticketType.salesLimit
    if (!isNumber(salesLimit)) {
      return '∞'
    }

    const totalPreviousTiersAllocatons = reduce(
      (acc, ptb) => acc + (ptb?.priceTier.allocation ?? 0),
      0,
      tt.priceTiersBreakdown
    )
    return intl.formatNumber(salesLimit - totalPreviousTiersAllocatons, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }, [intl, tt.priceTiersBreakdown, tt.ticketType.salesLimit])

  return (
    <>
      {tt.priceTiersBreakdown && tt.priceTiersBreakdown.length > 0 && (
        <PriceTiersBreakdown>
          <PriceTiers>
            {tt.priceTiersBreakdown.map(
              (pt, ptIdx) =>
                pt && (
                  <Fragment key={pt?.priceTier.id}>
                    <PriceTier isActive={isPriceTierActive(tt, pt)} minHeight>
                      <PriceTierCol>
                        <PriceTierStatusDot />
                        <PTName
                          data-pt={pt.priceTier.id}
                          className={pt.promotionsBreakdown?.length > 0 ? 'cursor-pointer' : ''}
                          onClick={pt.promotionsBreakdown?.length > 0 ? toggleDetails : undefined}
                        >
                          <span className={isMobile ? 'bold' : ''}>{pt.priceTier.name}</span>
                          {hasDiscount ? (
                            <DetailsToggle expanded={some((id) => id === pt.priceTier.id, expanded)}>
                              <Svg icon="collapsible" />
                            </DetailsToggle>
                          ) : (
                            <>
                              {isMobile ? (
                                <br />
                              ) : (
                                <RealText fontSize="base" color="darkgrey">
                                  &nbsp;&mdash;&nbsp;
                                </RealText>
                              )}
                              <PTPrice className="nowrap">
                                {tt.ticketType.priceHidden && (
                                  <TitleTooltip
                                    title={intl.formatMessage({
                                      id: isOnSale
                                        ? 'new_event.tickets.ticket_types.price_hidden.on_sale.tooltip'
                                        : 'new_event.tickets.ticket_types.price_hidden.tooltip',
                                    })}
                                  >
                                    <Svg icon="eye-crossed" height="20px" color={isOnSale ? color.error : undefined} />
                                  </TitleTooltip>
                                )}
                                {intl.formatNumber(
                                  (pt.priceTier.faceValue || 0) / 100,
                                  CURRENCY(pt.priceTier.faceValue, event.costCurrency)
                                )}
                                &nbsp;
                                <DetailedPriceBreakdownTooltip
                                  title={intl.formatMessage(
                                    { id: 'price.fees_title' },
                                    {
                                      name:
                                        pt.priceTier.name ||
                                        intl.formatMessage({ id: 'new_event.basics.name.placeholder' }),
                                    }
                                  )}
                                  currency={event.costCurrency || null}
                                  priceBreakdown={pt.priceTier.priceBreakdown}
                                  placement="right"
                                />
                                <RealText fontSize={isMobile ? 'sm' : 'base'} color="darkgrey">
                                  {pt.promotionsBreakdown?.length > 0 && (
                                    <DetailsToggle expanded={some((id) => id === pt.priceTier.id, expanded)}>
                                      <Svg icon="collapsible" />
                                    </DetailsToggle>
                                  )}
                                </RealText>
                              </PTPrice>
                            </>
                          )}
                        </PTName>
                      </PriceTierCol>
                      <PriceTierCol justify="space-between" nowrap>
                        <span>
                          {intl.formatNumber((pt.appSold || 0) + (pt.posSold || 0), {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                          {tt.ticketType.priceTierType === 'allocation' &&
                            (!pooled ? (
                              <>
                                {' / '}
                                {intl.formatNumber(pt.priceTier.allocation || 0, {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </>
                            ) : (
                              <>
                                {' / '}
                                {ptIdx === tt.priceTiersBreakdown.length - 1
                                  ? finalTierRemainingTickets
                                  : intl.formatNumber(pt.priceTier.allocation || 0, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                              </>
                            ))}
                        </span>
                        {!isTablet && (
                          <>
                            &nbsp;
                            <RealText fontSize="sm" color="darkgrey">
                              {tt.ticketType?.priceTierType === 'allocation' ? (
                                !pooled ? (
                                  <>
                                    {(pt.priceTier.allocation || 0) - ((pt.appSold || 0) + (pt.posSold || 0))}{' '}
                                    {intl.formatMessage({ id: 'event_overview.ticket_breakdown.tickets_remaining' })}
                                  </>
                                ) : (
                                  ptIdx !== tt.priceTiersBreakdown.length - 1 && (
                                    <>
                                      {(pt.priceTier.allocation || 0) - ((pt.appSold || 0) + (pt.posSold || 0))}{' '}
                                      {intl.formatMessage({ id: 'event_overview.ticket_breakdown.tickets_remaining' })}
                                    </>
                                  )
                                )
                              ) : (
                                pt.priceTier.time &&
                                intl.formatDate(pt.priceTier.time, {
                                  ...DATETIME_FORMATS.DATETIME(locale),
                                  timeZone: event.timezoneName || undefined,
                                })
                              )}
                            </RealText>
                          </>
                        )}
                      </PriceTierCol>
                      <PriceTierCol shifted={hasBoxOffice}>
                        {intl.formatNumber(
                          (pt.digitalValue || 0) / 100,
                          CURRENCY(pt.digitalValue || 0, event.costCurrency)
                        )}

                        {closedPriceTier(tt.ticketType.priceTierType, tt.priceTiersBreakdown, pt) &&
                          !isMobile &&
                          !(pooled && ptIdx === tt.priceTiersBreakdown.length - 1) && (
                          <CloseTier disabled>
                            <Svg icon="skip" />
                            {intl.formatMessage({ id: 'event_overview.ticket_breakdown.actions.closed' })}
                          </CloseTier>
                        )}

                        {!isReadOnly && !isSeated && tt.ticketType.priceTierType !== 'time' && (
                          <PriceTierActions
                            pt={pt}
                            isLast={ptIdx === tt.priceTiersBreakdown.length - 1}
                            pooled={pooled}
                          />
                        )}

                        <Suspense
                          fallback={
                            <LoaderContainer>
                              <Loader />
                            </LoaderContainer>
                          }
                        >
                          {closeTierId && <CloseTierModal tierId={closeTierId} onClose={closeTierModal} />}
                          {allocationId === pt.priceTier.id && (
                            <ChangeAllocationModal
                              eventId={event.id}
                              onClose={closeAllocation}
                              preSelectTtyId={tt.ticketType.id}
                              preSelectTierId={allocationId}
                            />
                          )}
                        </Suspense>
                      </PriceTierCol>
                    </PriceTier>
                    {pt.promotionsBreakdown?.length > 0 && some((id) => id === pt.priceTier.id, expanded) && (
                      <PromotionsBreakdown
                        promotions={pt.promotionsBreakdown}
                        currency={event.costCurrency}
                        type="priceTier"
                        hasBoxOffice={hasBoxOffice}
                        hasDiscount={hasDiscount}
                      />
                    )}
                  </Fragment>
                )
            )}
          </PriceTiers>
        </PriceTiersBreakdown>
      )}
    </>
  )
}

export default memo(PriceTiersBreakdownTable)
