import { nanoid } from 'nanoid'

import {
  pick,
  map,
  compact,
  filter,
  compose,
  toPairs,
  fromPairs,
  isNil,
  set,
  reject as rejectFn,
  getOr,
  update,
  findIndex,
  unset,
  find,
  sortBy,
  isNumber,
} from 'lodash/fp'
import { DeepReadonly } from 'ts-essentials'
import { Environment } from 'react-relay'

import IEventForm from '../types'
import { EventType } from '../../../enums.generated'
import { ITicketType, IPriceTier, ISeatCategory } from '../types/Tickets'
import { IProduct } from './../types/Extras'
import { isItalianEvent } from '../../../utils/isCountryEvent'
import { isClientOnly, markAsServer } from '../../../utils/entityStatus'
import { splitId } from '../../../utils/unwrapId'
import uploadAttachment from './uploadAttachment'
import {
  PriceTiersInput,
  SeatCategoriesInput,
  TicketTypesInput,
  ValidateDraftEventInput,
} from '../../../__generated__/validateDraftMutation.graphql'
import uploadEventImages from './uploadEventImages'
import uploadProductImages from './uploadProductImages'
import convertMedia from './convertMedia'
import createOrUpdateTicketPools from './createOrUpdateTicketPools'
import createOrUpdateProducts from './createOrUpdateProducts'
import { ILocale } from '../../../intl'
import { getAlpha2ByName } from '../../../utils/countries'
import { IAbbonamentoEvent } from '../types/Timeline'
import { EXTRAS_TYPES_ORDER } from '../steps/Extras'

type IEvent = Partial<IEventForm>
type ConvertFn = (
  environment: Environment,
  event: IEvent,
  admin: boolean,
  locale: ILocale,
  partial?: boolean
) => Promise<ValidateDraftEventInput>

const removeNulls = compose([fromPairs, filter((p: [string, any]) => !isNil(p[1])), toPairs])

const convertPriceTier: (pt: IPriceTier) => any = compose([
  update('fees', (fees) =>
    fees ? compose([map(pick(['active', 'amount', 'split', 'type', 'unit'])), compact])(fees) : []
  ),
  pick(['allocation', 'attractivePriceType', 'faceValue', 'doorSalesPrice', 'fees', 'id', 'name', 'time']),
  markAsServer,
])

const convertSeatCategory: (pt: ISeatCategory) => any = compose([
  set('value', 0),
  pick(['id', 'name', 'seatsIoKey']),
  markAsServer,
])

export const convertTty = async (
  environment: Environment,
  tt: DeepReadonly<ITicketType>,
  announceDate: string | null | undefined,
  onSaleDate: string | null | undefined,
  offSaleDate: string | null | undefined,
  eventType: EventType | null | undefined,
  venueSchedules: ReadonlyArray<{ id: string } | null> | null,
  products: Array<IProduct | null> | null,
  admin: boolean
): Promise<TicketTypesInput | null> => {
  if (!tt) return null

  const seatmapUrl =
    tt.seatmapUrl && !tt.seatmapUrl.startsWith('http')
      ? await uploadAttachment(environment, tt.seatmapUrl, 'png').then((arr) => arr[1])
      : tt.seatmapUrl

  let priceTiers = map(convertPriceTier, tt.priceTiers || []) as PriceTiersInput[]

  if (priceTiers.length > 0 && tt.priceTierType === 'time') {
    //SIC! Set first TB tier time to tty on sale || event on sale (no matter what it contains actually)
    const [first, ...rest] = priceTiers
    priceTiers = [set('time', tt.onSaleDate || onSaleDate, first), ...rest]
  } else if (priceTiers.length > 0 && tt.priceTierType === 'allocation' && !tt.ticketPoolId) {
    priceTiers = map(
      update('allocation', (a: number | null) => a || 0),
      priceTiers
    )
  }

  const customTimes = !!tt.onSaleDate || !!tt.offSaleDate || !!tt.announceDate

  let isStream = tt.isStream || false
  if (eventType === 'STREAM') {
    isStream = true
  } else if (eventType === 'LIVE') {
    isStream = false
  }

  const seatCategories = map(convertSeatCategory, tt.seatCategories || []) as SeatCategoriesInput[]

  const fees = compose([map(pick(['active', 'amount', 'split', 'type', 'unit'])), compact])(tt.fees || [])

  const venueScheduleIndex =
    venueSchedules && tt.venueScheduleId && venueSchedules.length > 0
      ? findIndex(['id', tt.venueScheduleId], venueSchedules)
      : null

  const productIds = products
    ? map(
      'id',
      filter(
        (product) =>
          product?.allTicketTypes ||
            find((productTTY) => productTTY && productTTY.id === tt.id, product?.ticketTypes || []),
        products
      )
    )
    : null

  const result = {
    ...pick(
      [
        'id',
        'ticketPoolId',
        'name',
        'archived',
        'hidden',
        'codeLocked',
        'description',
        'allocation',
        'faceValue',
        'icon',
        'increment',
        'maximumIncrements',
        'doorSalesPrice',
        'doorSalesEnabled',
        'streamLink',
        'attractiveSeatingAreaType',
        'attractivePriceType',
        'priceTierType',
        'externalSkus',
        'allowSeatChange',
        'reservedSeating',
        'reservedSeatingType',
        'requiresAddress',
        'presale',
        'activateCodeDateOffset',
        'additionalPaymentMethods',
        'startDate',
        'endDate',
        'salesLimit',
        'priceHidden',
        'requiresOtherTypeIds',
      ],
      tt
    ),
    announceDate: customTimes ? tt.announceDate || announceDate : null,
    onSaleDate: customTimes ? tt.onSaleDate || onSaleDate : null,
    offSaleDate: customTimes ? tt.offSaleDate || offSaleDate : null,
    productIds,
    seatmapUrl,
    priceTiers,
    isStream,
    seatCategories,
    fees,
    venueScheduleId: tt.venueScheduleId && isClientOnly({ id: tt.venueScheduleId }) ? null : tt.venueScheduleId,
    venueScheduleIndex: !isNil(venueScheduleIndex) && venueScheduleIndex >= 0 ? venueScheduleIndex : null,
  }

  return markAsServer(result)
}

export const convertProduct = async (environment: Environment, product: IProduct | null) => {
  const productImages = await uploadProductImages(environment, product)
  const result = {
    id: product?.id || null,
    productType: product?.productType,
    categoryId: getOr(null, 'category.value', product),
    name: product?.name || 'Unnamed product',
    sellingPoints: product?.sellingPoints || [],
    productImages,
    hasSeparateAccessBarcodes: !!product?.hasSeparateAccessBarcodes,
    venueId: getOr(null, 'venue.value', product),
    locationNote: product?.locationNote || null,
    date: product?.date || null,
    endDate: product?.endDate || null,
    description: product?.description || '',
    faceValue: product?.faceValue || 0,
    allocation: product?.hasVariants ? 0 : product?.allocation || 0,
    sku: product?.hasVariants ? null : product?.sku || null,
    fees: compose([map(pick(['active', 'amount', 'split', 'type', 'unit'])), compact])(product?.fees || []),
    onSaleDate: product?.onSaleDate || null,
    offSaleDate: product?.offSaleDate || null,
    allTicketTypes: product?.allTicketTypes,
    archived: product?.archived,
    purchaseConfirmationMessage: product?.purchaseConfirmationMessage || '',
    hasVariants: product?.hasVariants,
    optionType: product?.hasVariants ? product?.optionType : null,
    variants: product?.hasVariants
      ? map(
        (v) =>
          v && isClientOnly(v)
            ? compose([pick(['id', 'name', 'allocation', 'sku']), set('id', null)])(v)
            : pick(['id', 'name', 'allocation', 'sku'], v),
        product?.variants
      )
      : [],
    fulfilledBy: product?.fulfilledBy || null,
    customCover: !!product?.customCover,
  }

  return result
}

export const convertDraft: ConvertFn = async (environment, event, admin, locale, partial) => {
  const e = event as IEventForm

  const eventImages = await uploadEventImages(environment, e)

  const product = await createOrUpdateProducts(environment, e.products)
  const productList = sortBy((product: IProduct) => {
    const idx = EXTRAS_TYPES_ORDER.indexOf(product?.category?.parentCategory?.type || '')
    return idx >= 0 ? idx : 99999999
  }, product?.products)
  const products = await Promise.all(map(async (p) => await convertProduct(environment, p as IProduct), productList))
  const productsMap = product?.productsMap

  const ticketPool = await createOrUpdateTicketPools(environment, e.ticketPools)
  const ticketPoolIds = ticketPool?.ids || []
  const ticketPoolMap = ticketPool?.poolsMap

  let ticketTypes = await Promise.all(
    map(
      (tt) =>
        convertTty(
          environment,
          tt as DeepReadonly<ITicketType>,
          e.announceDate,
          e.onSaleDate,
          e.offSaleDate,
          e.eventType,
          e.venueSchedules,
          e.products,
          admin
        ),
      compact(e.ticketTypes)
    )
  )

  if (e.eventType === 'STREAM' && !isNil(e.maxTicketsLimit)) {
    // These fields are absent in UI for stream events, so setting to event-level default
    ticketTypes = map(compose([set('increment', 1), set('maximumIncrements', e.maxTicketsLimit || 0)]), ticketTypes)
  }

  if (e.ticketPools && ticketPoolMap) {
    ticketTypes = map(
      compose([
        (t) => (t && t.ticketPoolId ? set('ticketPoolId', ticketPoolMap.get(t.ticketPoolId) || null, t) : t),
        set('allocation', 0),
      ]),
      ticketTypes
    )
  }

  if (e.products && productsMap) {
    ticketTypes = map(
      (t) =>
        t && t.productIds
          ? set(
            'productIds',
            map((productId) => productId && productsMap.get(productId), t.productIds || []),
            t
          )
          : t,
      ticketTypes
    )
  }

  const recurrentEventSchedule = !e.eventSeatingChart ? e.recurrentEventSchedule || {} : undefined

  // Unset allocation fields when we're updating live seats.io (seating v2) events,
  // So we don't overwrite proper seatmap capacity,
  // As this is forbidden by backend.
  const isAdminUpdateOfLiveSeatedEvent = !!event.eventIdLive && !!event.eventSeatingChart?.id

  if (isAdminUpdateOfLiveSeatedEvent) {
    ticketTypes = map(
      (tty) =>
        // prettier-ignore
        !!tty?.reservedSeating
          ? (compose([
            update('priceTiers', (arr) => arr && map(unset('allocation'), arr)),
            unset('allocation'),
          ])(tty) as typeof tty)
          : tty,
      ticketTypes
    )
  }

  const valuesWithoutNulls = removeNulls({
    ...pick(
      [
        'lockVersion',
        'addressCountry',
        'addressLocality',
        'addressRegion',
        'addressCapacity',
        'addressSiaeCode',
        'addressState',
        'ageLimit',
        'announceDate',
        'barcodeType',
        'charityId',
        'charityEvent',
        'disableUsTax',
        'closeEventDate',
        'completedSteps',
        'costCurrency',
        'date',
        'description',
        'diceTvPlatform',
        'doorlistAdditionalRecipients',
        'endDate',
        'eventType',
        'extraNotes',
        'feesBehaviour',
        'basePriceFees',
        'postFanPriceFees',
        'fullAddress',
        'lineup',
        'latitude',
        'longitude',
        'maxTicketsLimit',
        'offSaleDate',
        'onSaleDate',
        'postalCode',
        'presentedBy',
        'printedTicketFormat',
        'streetAddress',
        'stripeAccountId',
        'platformAccountCode',
        'taxSettings',
        'timezoneName',
        'venueName',
        'restrictCountries',
        'restrictCountriesKind',
        'diceStreamDuration',
        'diceStreamDvrEnabled',
        'diceStreamRewatchEnabledUntil',
        'requiresTicketNomination',
        'requiresBoxOfficeTicketNomination',
        'manualValidationEnabled',
        'sendReceiptViaSms',
        'notes',
        'billingNotes',
        'onSaleNotification',
        'onSaleNotificationAt',
        'ticketType',
        'isTicketAvailableAtDoor',
        'licenseNumber',
        'isTest',
        'freeEvent',
      ],
      e
    ),
    name: e.name || 'Untitled',
    countryCode: (partial ? e.countryCode : true) && (e.countryCode || getAlpha2ByName(e.addressCountry, locale) || ''),
    fees:
      (partial ? e.fees : true) &&
      compose([map(pick(['active', 'amount', 'split', 'type', 'unit'])), compact])(e.fees || []),
    artists:
      (partial ? e.eventArtists : true) &&
      compact(
        map((ea) => {
          if (!ea) return null

          try {
            const split = splitId(ea.artist?.value)

            if (!split) return null

            const [kind] = split

            return {
              headliner: !!ea.headliner,
              id: kind === 'Artist' ? ea.artist?.value : null,
              musicbrainzArtistId: kind === 'MusicbrainzArtist' ? ea.artist?.value : null,
              name: ea.artist?.label,
              description:
                e.artistForBio?.artist?.value === ea.artist?.value ? e.artistForBio?.description : ea.description,
            }
          } catch (e) {
            console.error(`Error processing artist (draft) ${JSON.stringify(ea)}`, e)
            return null
          }
        }, e.eventArtists || [])
      ),
    additionalInfos:
      (partial ? e.additionalInfos : true) &&
      e.additionalInfos &&
      rejectFn((v) => !(v?.content || (v?.ctaLink && v?.ctaLabel)), e.additionalInfos),
    additionalArtists:
      (partial ? e.additionalArtists : true) &&
      map(
        (artist) => ({
          ...pick(['id', 'name', 'description'])(artist),
          hierarchicalTagIds: map((ht) => ht?.value, artist?.hierarchicalTags),
        }),
        e.additionalArtists
      ),

    eventImages: (partial ? e.eventImages : true) && eventImages,
    eventPromoters:
      (partial ? e.promoters : true) &&
      map(
        (p) => ({
          billingPromoter: compact(e.promoters || []).length === 1 || p.value === e.billingPromoter?.value,
          promoterId: p.value,
        }),
        compact(e.promoters)
      ),
    flags:
      (partial ? e.flags : true) &&
      compose(
        set('generateNewCodeOnTransfer', {
          active: isItalianEvent(e, locale) ? true : getOr(false, 'flags.generateNewCodeOnTransfer.active', e),
        }),
        set('autoRescheduledEventRefunds', {
          active: e.flags?.autoRescheduledEventRefunds?.active || false,
          cutoff_days: Number(e.flags?.autoRescheduledEventRefunds?.cutoff_days) || null,
        }),
        set('coolingOffPeriod', {
          active: e.flags?.coolingOffPeriod?.active || false,
          hours: isNumber(parseInt(e.flags?.coolingOffPeriod?.hours + ''))
            ? parseInt(e.flags?.coolingOffPeriod?.hours + '')
            : 24,
        })
      )(e.flags),
    labelIds: (partial ? e.labels : true) && map('value', e.labels),
    characteristicIds: (partial ? e.characteristics : true) && map('value', e.characteristics),
    cityIds: (partial ? e.cities : true) && map('value', e.cities),
    bundleIds: (partial ? e.bundles : true) && map('value', e.bundles),
    marketeerIds: (partial ? e.marketeers : true) && map('value', e.marketeers),
    hierarchicalTagIds: (partial ? e.hierarchicalTags : true) && map('value', e.hierarchicalTags),
    tagIds: (partial ? e.tags : true) && map('value', e.tags),
    media: (partial ? e.media : true) && convertMedia(e),
    ticketTypes: (partial ? e.ticketTypes : true) && ticketTypes,
    ticketPools: (partial ? e.ticketPools : true) && ticketPoolIds,
    products: (partial ? e.products : true) && products,
    // prettier-ignore
    doorlistRecipientIds: !admin
      ? null
      : (
        (partial ? e.doorlistRecipients : true)  ?
          map('id', e.doorlistRecipients || []) : null
      ),
    faqs: e.faqs
      ?.map((faq: any, index) => ({
        id: faq.id,
        order: index,
        title: faq.title,
        body: faq.body || '',
      }))
      .filter((faq) => faq.title || faq.body),
    eventRules:
      (partial ? e.eventRules : true) &&
      pick(
        [
          'maskRequired',
          'socialDistancing',
          'proofOfBeingHealthy',
          'covidPcr',
          'covidPcrValidHours',
          'covidRecovery',
          'covidVaccination',
          'covidPolicyUrl',
        ],
        e.eventRules || {}
      ),
    eventVenues:
      (partial ? e.venues : true) &&
      map(
        (p) => ({
          primary: p.value === e.primaryVenue?.value,
          venueId: p.value,
        }),
        compact(e.venues)
      ),
    recurrentEventSchedule: (partial ? e.recurrentEventSchedule : true) && recurrentEventSchedule,
    venueConfigurationId: (partial ? e.venueConfiguration : true) && e.venueConfiguration?.value,
    attractiveFields: (partial ? e.attractiveFields : true) && {
      ...pick(
        [
          'compatibilityAe',
          'integrationDisabled',
          'streamingTicketsIntegrationDisabled',
          'siaeGenreType',
          'subscriptionCode',
          'subscriptionEventsLimit',
          'entertainmentPercent',
          'taxFree',
          'forceSubscription',
          'subscriptionCode',
          'author',
          'distributor',
          'nationality',
          'performer',
          'producer',
        ],
        e.attractiveFields || {}
      ),
      forceSubscriptionLimit: e.attractiveFields?.forceSubscriptionLimit || 0,
      seatingAreaConfigs: map(
        (sa) => ({ seatingArea: sa?.seatingArea, capacity: sa?.capacity || 0 }),
        e.attractiveFields?.seatingAreaConfigs || []
      ),
      linkedEventIds: compact(map((e: IAbbonamentoEvent) => e.id, e.attractiveFields?.linkedEvents || [])),
    },
    links: (partial ? e.links : true) && map(pick(['name', 'url']), e.links),
    eventSeatingChartId: (partial ? e.eventSeatingChart : true) && e.eventSeatingChart?.id,
    seatingChannels:
      (partial ? e.eventSeatingChart?.seatingChannels : true) &&
      compose([map(pick(['channelType', 'name', 'seatsIoChannel'])), compact])(
        e.eventSeatingChart?.seatingChannels || []
      ),
    salesforceContractId: e.salesforceContract?.id || null,
    featuredAreas:
      (partial ? e.featuredAreas : true) &&
      map(
        compose([
          markAsServer,
          pick([
            'countryCode',
            'description',
            'endDate',
            'id',
            'locationLat',
            'locationLng',
            'locationRadius',
            'locationString',
            'locationUnits',
            'mode',
            'startDate',
            'weight',
          ]),
        ]),
        e.featuredAreas
      ),
    eventSharingObjects:
      (partial ? e.eventSharingObjects : true) &&
      map(
        compose([
          markAsServer,
          (so) => ({
            id: so.id,
            email: so.email,
            permissionProfileId: so.permissionProfile?.id,
          }),
        ]),
        compact(e.eventSharingObjects || [])
      ),
    waitingListExchangeWindows:
      (partial ? e.waitingListExchangeWindows : true) &&
      map(
        compose([
          markAsServer,
          (wnd) => ({
            id: wnd.id,
            offset: wnd.offset,
            duration: wnd.duration || 0,
          }),
        ]),
        compact(e.waitingListExchangeWindows || [])
      ),
    thirdPartySettingsId: (partial ? e.thirdPartySettings : true) && e.thirdPartySettings?.value,
    venueSchedules:
      (partial ? e.venueSchedules : true) &&
      map(
        compose([markAsServer, pick(['id', 'name', 'venueId', 'venueConfigurationId', 'date', 'endDate'])]),
        compact(e.venueSchedules || [])
      ),
    showArtistDescription: e.showArtistDescription,
    eventLoadPredictions: e.eventLoadPredictions?.filter((p) => p?.expectedStartTime && p.expectedRequestsPerMinute),
    clientMutationId: nanoid(),
  })

  return {
    ...valuesWithoutNulls,
    ...pick(['diceStreamRewatchEnabledUntil'], e),
    scheduleStatus: e.scheduleStatus || null,
    venueSpaceId: e.venueSpace?.value || null,
    ...(admin ? { fanSupportNotes: e.fanSupportNotes?.body ? e.fanSupportNotes : null } : {}),
  }
}
