import React, { FC, memo, useContext } from 'react'
import { useIntl } from 'react-intl'

import { useLocation } from 'react-router'
import { PROMOTER_SETTINGS_SIDEBAR_ROUTER } from '../services/promoterSettingsSidebarNav'
import { SidebarMenuIcon, SidebarMenuItem } from '../../Sidebar/components/legacy/Styles'

import CollapsibleSidebarList from '../../Sidebar/components/CollapsibleSidebarList'
import { authContext } from '../../../context/auth'
import { IFactory, preload } from '../../Sidebar/services/sidebarNav'

const AccountSettings: FC = () => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { pathname } = useLocation()

  return (
    <CollapsibleSidebarList
      title={user.email ?? intl.formatMessage({ id: 'promoter_settings_sidebar.personal_details' })}
    >
      <SidebarMenuItem
        active={pathname && pathname.startsWith(PROMOTER_SETTINGS_SIDEBAR_ROUTER.account.personalDetails[0] as string)}
        to={PROMOTER_SETTINGS_SIDEBAR_ROUTER.account.personalDetails[0]}
        onMouseEnter={preload(PROMOTER_SETTINGS_SIDEBAR_ROUTER.account.personalDetails[1] as IFactory)}
        data-id="settingsSidebarPersonalDetails"
      >
        <SidebarMenuIcon icon="user-alt" />
        <span>{intl.formatMessage({ id: 'promoter_settings_sidebar.personal_details' })}</span>
      </SidebarMenuItem>
    </CollapsibleSidebarList>
  )
}

export default memo(AccountSettings)
