import React, { FC, memo, useContext, useRef, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useFormikContext, FormikHelpers } from 'formik'

import Svg from '../../../components/Svg'
import EventSubmissionWizard from '../../EventForm/EventSubmissionWizard'
import { TitleTooltip } from '../../../components/Tooltip'
import { OnDesktop } from '../../../components/Breakpoints'
import IdleValidator from '../../../components/IdleValidator'
import { mediaQuery } from '../../../utils/variables'
import useExposeHeightToCss from '../../../utils/useExposeHeightToCss'
import { PageHeader, PageTitle, PageBody, PageControls } from '../../../components/Page'
import { trackingContext } from '../../../context/tracking'
import IEventForm from '../../EventForm/types'
import scrollToTopError from '../../../utils/scrollToTopError'
import unwrapId from '../../../utils/unwrapId'
import { EventSubmission_viewer$data } from '../../../__generated__/EventSubmission_viewer.graphql'
import ScrollToError from '../../../components/ScrollToError'
import { EventKind } from '../../EventHeader/EventHeader'
import EventNotesPageWrapper from '../../EventNotes/EventNotesPageWrapper'

const PageBodyNoPadding = styled(PageBody)`
  padding: 0;
  margin: 0;

  ${mediaQuery.lessThan('desktop')`
    padding: 0;
    margin: 0;
  `}
`

interface IKnownSizeHeaderProps {
  deps?: [any] | any
}

const KnownSizeHeader: FC<React.PropsWithChildren<IKnownSizeHeaderProps>> = ({ deps, children }) => {
  const headerRef = useExposeHeightToCss<HTMLDivElement>('--page-header-height', deps)
  return <PageHeader ref={headerRef}>{children}</PageHeader>
}

const EventSubmissionForm: FC<
  React.PropsWithChildren<{
    viewer: EventSubmission_viewer$data
    eventId?: string
    eventIdLive?: string | null
    onSaveDraft: (vals: IEventForm, helpers?: FormikHelpers<IEventForm>, saveDraft?: boolean) => Promise<void>
    readOnly?: boolean
  }>
> = ({ viewer, eventId, eventIdLive, onSaveDraft, readOnly }) => {
  const intl = useIntl()
  const { trackPageView } = useContext(trackingContext)

  const { values, handleSubmit, handleReset, errors, setTouched } = useFormikContext<IEventForm>()

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const trackStep = useCallback(
    (step: string) => {
      // Turn off tracking extras
      if (step === 'extras' || step === 'merch') return

      let trackingStep = `create_event_${step}`
      if (step === 'information') trackingStep = 'create_event_info'

      const trackData =
        trackingStep === 'create_event_basics' || !eventId
          ? null
          : { event_id: unwrapId(eventId), event_id_live: eventIdLive }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => trackPageView(trackingStep, trackData), 1000)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId, trackPageView]
  )

  const maybePortalHeader = useMemo(() => {
    const portalEl = document.getElementById('headerPortal')

    const headerEl = (
      <KnownSizeHeader deps={values.name || null}>
        <PageTitle>
          {(!values.id ? '' : values.name) ||
            intl.formatMessage({ id: `new_event.header_${(values.eventType || 'LIVE').toLowerCase()}` })}
        </PageTitle>
        <OnDesktop>
          <PageControls>
            <TitleTooltip
              placement="bottom"
              title={intl.formatMessage({
                id: `new_event.select_type.options.${(values.eventType || 'LIVE').toLowerCase()}`,
              })}
            >
              <EventKind>
                <Svg icon={`event-type-${(values.eventType || 'LIVE').toLowerCase()}`} />
              </EventKind>
            </TitleTooltip>
          </PageControls>
        </OnDesktop>
      </KnownSizeHeader>
    )

    return portalEl ? createPortal(headerEl, portalEl) : headerEl
  }, [intl, values.eventType, values.id, values.name])

  return (
    <EventNotesPageWrapper eventId={eventId}>
      <form noValidate onSubmit={handleSubmit} onReset={handleReset}>
        <IdleValidator />
        <Helmet>
          <title>
            {(!values.id ? '' : values.name) ||
              intl.formatMessage({ id: `new_event.header_${(values.eventType || 'LIVE').toLowerCase()}` })}{' '}
            | DICE MIO
          </title>
        </Helmet>
        {maybePortalHeader}
        {values.id && (
          <ScrollToError key={values.id} doScroll={scrollToTopError} errors={errors} setTouched={setTouched} />
        )}
        <PageBodyNoPadding>
          <EventSubmissionWizard
            submissionFlow
            viewer={viewer}
            onActivateStep={trackStep}
            onSaveDraft={onSaveDraft}
            readOnly={readOnly}
          />
        </PageBodyNoPadding>
      </form>
    </EventNotesPageWrapper>
  )
}

export default memo(EventSubmissionForm)
