import React, { Dispatch, FC, memo, SetStateAction, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Checkbox from '../../../components/Checkbox'
import { AgreementCheckbox, AgreementCheckboxItem, TermsLink } from '../../Auth/AuthStyles'

interface IProps {
  countryCode: string
  isAgreed: boolean
  setIsAgreed: Dispatch<SetStateAction<boolean>>
}

const TermsAndConditions: FC<IProps> = ({ countryCode, isAgreed, setIsAgreed }) => {
  const intl = useIntl()

  const handleIsAgreed = useCallback(() => setIsAgreed((v) => !v), [setIsAgreed])

  const termsLink = useMemo(() => {
    switch (countryCode) {
      case 'AU':
        return 'https://support.dice.fm/article/769-mio-ticketing-terms-and-conditions-australia'
      case 'FR':
        return 'https://support.dice.fm/article/773-mio-ticketing-terms-and-conditions-france'
      case 'IT':
        return 'https://support.dice.fm/article/775-mio-ticketing-terms-and-conditions-italy'
      case 'ES':
        return 'https://support.dice.fm/article/771-mio-ticketing-terms-and-conditions-spain'
      case 'PT':
        return 'https://support.dice.fm/article/777-mio-ticketing-terms-and-conditions-portugal'
      case 'CA':
      case 'US':
        return 'https://support.dice.fm/article/764-mio-ticketing-terms-and-conditions-us'
      case 'DE':
        return 'https://support.dice.fm/article/949-dice-self-sign-up-terms-and-conditions-germany'
      default:
        return 'https://support.dice.fm/article/758-mio-ticketing-terms-and-conditions-uk'
    }
  }, [countryCode])

  const userGuideLink = useMemo(() => {
    switch (countryCode) {
      case 'FR':
        return 'https://support.dice.fm/article/774-mio-user-guidelines-french-language'
      case 'IT':
        return 'https://support.dice.fm/article/776-mio-user-guidelines-italian-language'
      case 'ES':
        return 'https://support.dice.fm/article/770-mio-user-guidelines-spanish-language'
      case 'PT':
        return 'https://support.dice.fm/article/779-mio-user-guidelines-portuguese-language'
      case 'DE':
        return 'https://support.dice.fm/article/948-mio-user-guidelines-german-language'
      default:
        return 'https://support.dice.fm/article/761-mio-user-guidelines-english-language'
    }
  }, [countryCode])

  return (
    <AgreementCheckbox>
      <AgreementCheckboxItem>
        <Checkbox
          name="termsAndConditions"
          checked={isAgreed}
          onChange={handleIsAgreed}
          label={
            <>
              {intl.formatMessage(
                { id: 'account.i_agree_new' },
                {
                  t: (str: string) => (
                    <TermsLink href={termsLink} target="_blank" rel="noopener noreferrer">
                      {str}
                    </TermsLink>
                  ),
                  g: (str: string) => (
                    <TermsLink href={userGuideLink} target="_blank" rel="noopener noreferrer">
                      {str}
                    </TermsLink>
                  ),
                }
              )}
            </>
          }
        />
      </AgreementCheckboxItem>
    </AgreementCheckbox>
  )
}

export default memo(TermsAndConditions)
