import styled from 'styled-components'
import Table, { Cell, HeaderCell, Row, TableHeader } from '../../../../components/Table'
import { font, color, zIndex, input, mediaQuery } from '../../../../utils/variables'
import { Card, EventName, HeaderCol } from '../../../EventList/components/EventListItemGrid'
import { textStyle } from '../../../../utils/typography'

export const TableWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 32px;
  margin-left: -32px;
  margin-right: -32px;

  ${mediaQuery.greaterThan('tablet')`
      height: 430px;
  `}

  ${mediaQuery.lessThan('tablet')`
    padding: 0;
    margin-left: -20px;
    margin-right: -20px;
  `}
`

export const EmptyTableState = styled.div`
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQuery.lessThan('tablet')`
      height: 100%;
  `}
`

export const StyledTable = styled(Table)<{ isLoading?: boolean; expanded?: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    `
      tbody {
        opacity: .4;
      }
    `}

  td,
  th {
    white-space: normal;
    word-break: break-word;
    min-width: 170px;

    &.-wide {
      text-align: left;
      min-width: 350px;
    }

    &.-narrow {
      min-width: 40px;
      max-width: 40px;
      width: 40px;
    }
  }

  td {
    font-size: ${font.size.base}px;
    vertical-align: middle;
    padding: 8px 8px 8px 0;
    line-height: 125%;

    ${mediaQuery.lessThan('tablet')`
      padding: 16px 8px 16px 0;
    `}
  }

  ${TableHeader} {
    border: none;

    ${mediaQuery.lessThan('tablet')`
      display: none;
    `}

    ${HeaderCell} {
      position: sticky;
      top: 0;
      background: ${color.white};
      z-index: ${zIndex.formField};
      box-shadow: inset 0 0px 0 ${input.borderColor};
      height: 40px;
      padding: 20px 16px 16px 0;
    }
  }

  ${HeaderCol} {
    height: unset;
  }
`

export const EventInfoContainer = styled.div`
  position: relative;
  display: flex;
  margin-left: 4px;

  ${Card} {
    justify-content: center;
  }

  .mobile-date {
    margin-top: 16px;
  }
`

export const LoadMoreFooter = styled.tfoot`
  td {
    padding: 0;
    text-align: center;
  }
`

export const DisablableAbbonamentoRow = styled(Row)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    td:not(:first-child) {
      opacity: 0.5
    }
  `}

  ${mediaQuery.lessThan('tablet')`
    border-bottom: 1px solid ${input.borderColor};

    ${EventName} {
      ${textStyle.functional.md}
      margin-bottom: 4px;
      margin-left: 0;
    }

    ${Cell}:first-child {
      padding-left: 20px;
      width: 60px;
      min-width: 60px;
      max-width: 60px;
      vertical-align: top;
      padding-top: 24px;
    }

    ${Cell}:last-child {
      padding-right: 20px;
    }
  `}
`
