import { useCallback, useContext, useState } from 'react'
import { trackingContext } from '../../../context/tracking'
import unwrapId from '../../../utils/unwrapId'

function useBreakdownState(eventId: string, eventIdLive: string | null) {
  const { trackEvent } = useContext(trackingContext)

  const [dropdownId, setDropdownId] = useState<string | null>(null)
  const closeDropdown = useCallback(() => setDropdownId(null), [setDropdownId])
  const toggleDropdown = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      if (id === dropdownId) {
        closeDropdown()
      } else {
        setDropdownId(id)
      }
    },
    [closeDropdown, dropdownId, setDropdownId]
  )

  const [allocationId, setAllocationId] = useState<string | null>(null)
  const closeAllocation = useCallback(() => setAllocationId(null), [])
  const doChangeAllocation = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      if (!id) return

      closeDropdown()
      setAllocationId(id)
    },
    [closeDropdown]
  )

  const [editId, setEditId] = useState<string | null>(null)
  const closeEdit = useCallback(() => setEditId(null), [])
  const doEdit = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']

      const trackData = {
        event_id: unwrapId(eventId),
        event_id_live: eventIdLive,
        ticket_type_id: unwrapId(id),
      }

      trackEvent('ticket_type_edit_clicked', trackData)

      setEditId(id)
    },
    [eventId, eventIdLive, trackEvent]
  )

  const [salesLimitId, setSalesLimitId] = useState<string | null>(null)
  const closeSalesLimit = useCallback(() => setSalesLimitId(null), [])
  const doSalesLimit = useCallback((e: any) => {
    const id = e.currentTarget.dataset['id']
    setSalesLimitId(id)
  }, [])

  const [problemAlert, setProblemAlert] = useState(false)
  const closeProblemAlert = useCallback(() => setProblemAlert(false), [])

  return {
    dropdownId,
    closeDropdown,
    toggleDropdown,
    allocationId,
    closeAllocation,
    doChangeAllocation,
    editId,
    closeEdit,
    doEdit,
    salesLimitId,
    closeSalesLimit,
    doSalesLimit,
    problemAlert,
    closeProblemAlert,
    setProblemAlert,
  }
}

export default useBreakdownState
