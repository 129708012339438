import React, { ComponentProps, FC } from 'react'
import styled from 'styled-components/macro'
import RealTextareaAutosize from 'react-textarea-autosize'
import { color } from '../utils/variables'

type IProps = ComponentProps<typeof RealTextareaAutosize>

// eslint-disable-next-line no-unused-vars
const Textarea: FC<React.PropsWithChildren<IProps & { hasError?: boolean; required?: boolean }>> = ({
  hasError,
  required,
  ...props
}) => <RealTextareaAutosize {...(props as any)} />

const TextareaAutosize = styled(Textarea)`
  width: 100%;
  border: 2px solid ${({ hasError }) => (hasError ? color.error : color.lightgrey)};
  padding: 8px 16px;
  min-height: 96px;
  max-height: 288px;
  outline: none;
  resize: vertical;
  border-radius: 4px;
  &:hover {
    border-color: #bfbfbf;
  }

  &:focus {
    border: 2px solid ${color.primary};
  }
` as typeof Textarea

export default TextareaAutosize
