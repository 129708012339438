import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { mediaQuery, font } from '../utils/variables'

import { Modal, ModalBody, ModalDialog, ModalFooter, ModalFooterControl } from './Modal'
import Svg from './Svg'

interface IProps {
  title: string
  description?: string
  onClose?: () => void
}

const StyledModal = styled(Modal)`
  ${ModalDialog} {
    width: 400px;
    text-align: center;
    padding: 16px 32px;
    ${mediaQuery.lessThan('tablet')`
      width: 100%;
      padding: 32px 20px;
    `}
  }
  ${ModalFooter} {
    padding: 16px;
    border-top-width: 0;
    margin-top: 16px;
    justify-content: center;

    ${ModalFooterControl} {
      min-width: 115px;
    }

    ${mediaQuery.lessThan('tablet')`
      display: block;
      border-top-width: 2px;
      bottom: -32px;
      margin: 20px -20px -32px;
      padding: 20px;
    `}
  }
`

const Title = styled.p`
  font-size: ${font.size.lg}px;
  line-height: 125%;
`

const Description = styled.p`
  font-size: ${font.size.sm}px;
  line-height: 125%;
  margin: 8px 0;
`

export const AlertModal: FC<React.PropsWithChildren<IProps>> = ({ title, description, onClose }) => {
  const intl = useIntl()

  return (
    <StyledModal onClose={onClose}>
      <ModalBody>
        <Svg icon="alert" width={64} height={64} />
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl onClick={onClose}>{intl.formatMessage({ id: 'ok' })}</ModalFooterControl>
      </ModalFooter>
    </StyledModal>
  )
}
