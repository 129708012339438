/**
 * @generated SignedSource<<e184b5adbfe61493db3592aa0f078541>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useAutoPinnedEventQuery$variables = {
  d1: string;
  d2: string;
  hasPin: boolean;
  id: string;
  unpinnedIds?: ReadonlyArray<string> | null;
};
export type useAutoPinnedEventQuery$data = {
  readonly currentPin?: {
    readonly endDate?: string | null;
    readonly id?: string;
  } | null;
  readonly viewer: {
    readonly pinCandidates: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly werePinned: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly endDate: string | null;
          readonly id: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useAutoPinnedEventQuery = {
  response: useAutoPinnedEventQuery$data;
  variables: useAutoPinnedEventQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "d1"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "d2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasPin"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unpinnedIds"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": [
    "dateASC"
  ]
},
v10 = {
  "alias": "pinCandidates",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 20
    },
    (v9/*: any*/),
    {
      "kind": "Literal",
      "name": "scopes",
      "value": {
        "eventState": [
          "ON_SALE"
        ],
        "lifeCycleState": [
          "LIVE"
        ]
      }
    },
    {
      "fields": [
        {
          "fields": [
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "between.0",
                  "variableName": "d1"
                },
                {
                  "kind": "Variable",
                  "name": "between.1",
                  "variableName": "d2"
                }
              ],
              "kind": "ListValue",
              "name": "between"
            }
          ],
          "kind": "ObjectValue",
          "name": "date"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "EventConnection",
  "kind": "LinkedField",
  "name": "events",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Event",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": "werePinned",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100
    },
    (v9/*: any*/),
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "in",
              "variableName": "unpinnedIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "EventConnection",
  "kind": "LinkedField",
  "name": "events",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Event",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useAutoPinnedEventQuery",
    "selections": [
      {
        "condition": "hasPin",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "currentPin",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "Event",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useAutoPinnedEventQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasPin",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "currentPin",
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "Event",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a1b5ed42c4c3ac55e77cf4849a3a956b",
    "id": null,
    "metadata": {},
    "name": "useAutoPinnedEventQuery",
    "operationKind": "query",
    "text": "query useAutoPinnedEventQuery(\n  $d1: Time!\n  $d2: Time!\n  $unpinnedIds: [ID!]\n  $id: ID!\n  $hasPin: Boolean!\n) {\n  currentPin: node(id: $id) @include(if: $hasPin) {\n    __typename\n    ... on Event {\n      id\n      endDate\n    }\n    id\n  }\n  viewer {\n    pinCandidates: events(first: 20, orderBy: [dateASC], scopes: {eventState: [ON_SALE], lifeCycleState: [LIVE]}, where: {date: {between: [$d1, $d2]}}) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    werePinned: events(first: 100, orderBy: [dateASC], where: {id: {in: $unpinnedIds}}) {\n      edges {\n        node {\n          id\n          endDate\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6489e2d1cca97bea1dafcf1b21805da2";

export default node;
