/**
 * @generated SignedSource<<83a79f317684a944b97326f4d789b6b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventState = "APPROVED" | "ARCHIVED" | "CANCELLED" | "DECLINED" | "DRAFT" | "REVIEW" | "SUBMITTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventListItem_event$data = {
  readonly date: string | null;
  readonly endDate: string | null;
  readonly eventIdLive: string | null;
  readonly flags: {
    readonly hidden: IBackendMap | null;
  } | null;
  readonly id: string;
  readonly isTest: boolean | null;
  readonly name: string | null;
  readonly state: EventState | null;
  readonly statusAsOfNow: string | null;
  readonly timezoneName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"EventPartDraftProgress_event" | "EventPartImage_event" | "EventPartRevenue_event" | "EventPartSales_event" | "EventPartStatus_event" | "EventPartVenue_event" | "QuickActionsDrafts_event" | "QuickActionsLive_event" | "QuickActionsPast_event">;
  readonly " $fragmentType": "EventListItem_event";
};
export type EventListItem_event$key = {
  readonly " $data"?: EventListItem_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventListItem_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "hideFreeTag",
  "variableName": "needBalance"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isDraft"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "needBalance"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "noSelfPayouts"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventListItem_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezoneName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusAsOfNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventIdLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventFlags",
      "kind": "LinkedField",
      "name": "flags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hidden",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventPartDraftProgress_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventPartImage_event"
    },
    {
      "condition": "noSelfPayouts",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "isDraft",
              "variableName": "isDraft"
            },
            {
              "kind": "Variable",
              "name": "needBalance",
              "variableName": "needBalance"
            }
          ],
          "kind": "FragmentSpread",
          "name": "EventPartStatus_event"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventPartVenue_event"
    },
    {
      "condition": "isDraft",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EventPartSales_event"
        },
        {
          "args": [
            (v0/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "EventPartRevenue_event"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuickActionsLive_event"
        }
      ]
    },
    {
      "condition": "needBalance",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "noSelfPayouts",
              "variableName": "noSelfPayouts"
            }
          ],
          "kind": "FragmentSpread",
          "name": "QuickActionsPast_event"
        }
      ]
    },
    {
      "condition": "isDraft",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuickActionsDrafts_event"
        }
      ]
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "353d9a63152f74ddcedda35cf91781f1";

export default node;
