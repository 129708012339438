import { object, string, array } from 'yup'
import { ISO_DATE_REGEX } from '../../../utils/calendar'
import { timezoneNameMatchesPrimaryVenueTimezoneFunction } from './Timeline'

const EventLaxSchema = object()
  .shape({
    name: string().required(),
    timezoneName: string().required(),
    costCurrency: string().required(),
    date: string().nullable().matches(ISO_DATE_REGEX).required(),
    endDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    onSaleDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    offSaleDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    announceDate: string().nullable().matches(ISO_DATE_REGEX).required(),
    ticketTypes: array().min(1).required(),
    billingPromoter: object().nullable().required(),
  })
  .test(
    'timezoneNameMatchesPrimaryVenueTimezone',
    'Timezone should match primary venue timezone',
    timezoneNameMatchesPrimaryVenueTimezoneFunction
  )

export default EventLaxSchema
