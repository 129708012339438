import React, { forwardRef, ReactNode } from 'react'
import styled from 'styled-components/macro'
import cn from 'classnames'
import ButtonBase from './ButtonBase'
import Svg from './Svg'
import { Loader } from './Loader'

export interface IButtonProps {
  className?: string
  disabled?: boolean
  loading?: boolean
  block?: boolean
  preset?: string
  color?: string
  size?: 'base' | 'small'
  align?: 'left' | 'right' | 'center'
  icon?: string
  iconAfter?: string
  onClick?: any
  type?: string
  to?: string
  children?: ReactNode
  href?: string
  target?: string
}

const Button = forwardRef<typeof ButtonBase, IButtonProps>((props, ref) => {
  const {
    className,
    children,
    preset: propsPreset,
    block,
    icon,
    iconAfter,
    size,
    align,
    loading,
    color,
    ...rest
  } = props

  const preset = propsPreset || 'primary-outline'

  const resultClassName = cn('button', {
    [className || '']: !!className,
    '-loading': loading,
    '-block': block,
    [`-align-${align}`]: !!align,
    [`-preset-${preset}`]: !!preset,
    [`-size-${size}`]: !!size,
    [`-color-${color}`]: !!color,
  })

  return (
    <ButtonBase className={resultClassName} {...rest} ref={ref}>
      <span className="button_container">
        {icon && (
          <span className="button_icon-container">
            <Svg icon={icon} className="button_icon" />
          </span>
        )}
        <span className="button_content">{children}</span>
        {iconAfter && (
          <span className="button_icon-container">
            <Svg icon={iconAfter} className="button_icon" />
          </span>
        )}
      </span>
      {loading && <Loader size="small" />}
    </ButtonBase>
  )
})

export default styled(Button)``
