import React, { useState, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { Dropdown, DropdownTrigger, DropdownContent } from '../../../../../../components/Dropdown'
import { Menu, MenuItem } from '../../../../../../components/Menu'
import Svg from '../../../../../../components/Svg'
import { FilterAdditionalItemDropdown } from '../../../../../../components/FilterStyles'
import EventListTypeContext from '../../../../util/eventListTypeContext'

function MobileOrdering() {
  const intl = useIntl()
  const listType = useContext(EventListTypeContext)

  const orderingOptions = useMemo(() => {
    const options = [
      { value: 'nameASC', i18n: 'ordering.name_asc' },
      { value: 'nameDESC', i18n: 'ordering.name_desc' },
    ]

    switch (listType) {
      case 'submission':
        return [
          ...options,
          { value: 'submittedAtASC', i18n: 'ordering.submitted_at_asc' },
          { value: 'submittedAtDESC', i18n: 'ordering.submitted_at_desc' },
          { value: 'announceDateASC', i18n: 'ordering.announce_asc' },
          { value: 'announceDateDESC', i18n: 'ordering.announce_desc' },
          { value: 'onSaleDateASC', i18n: 'ordering.on_sale_asc' },
          { value: 'onSaleDateDESC', i18n: 'ordering.on_sale_desc' },
        ]
      case 'draft':
        return [
          ...options,
          { value: 'dateASC', i18n: 'ordering.date_asc' },
          { value: 'dateDESC', i18n: 'ordering.date_desc' },
          { value: 'venueASC', i18n: 'ordering.venue_asc' },
          { value: 'venueDESC', i18n: 'ordering.venue_desc' },
        ]
      case 'live':
      case 'past':
      case 'cancelled':
        return [
          ...options,
          { value: 'dateASC', i18n: 'ordering.date_asc' },
          { value: 'dateDESC', i18n: 'ordering.date_desc' },
          { value: 'venueASC', i18n: 'ordering.venue_asc' },
          { value: 'venueDESC', i18n: 'ordering.venue_desc' },
          { value: 'ticketsSoldASC', i18n: 'ordering.tickets_sold_asc' },
          { value: 'ticketsSoldDESC', i18n: 'ordering.tickets_sold_desc' },
          {
            value: 'totalFaceValueASC',
            i18n: 'ordering.total_face_value_asc',
          },
          {
            value: 'totalFaceValueDESC',
            i18n: 'ordering.total_face_value_desc',
          },
        ]
      default:
        return options
    }
  }, [listType])

  const { values, handleSubmit, setFieldValue } = useFormikContext<{
    orderBy?: string
  }>()
  const [open, setOpen] = useState(false)

  const onClickOutside = useCallback(() => open && setOpen(false), [open, setOpen])
  const toggleDropdown = useCallback(() => setOpen(!open), [open, setOpen])

  const toggleOrder = useCallback(
    (e: any) => {
      const value = e.currentTarget.dataset['order']
      setFieldValue('orderBy', value)
      onClickOutside()
      handleSubmit()
    },
    [setFieldValue, onClickOutside, handleSubmit]
  )

  return (
    <FilterAdditionalItemDropdown as={Dropdown} active={open} onClickOutside={onClickOutside}>
      <DropdownTrigger onClick={toggleDropdown}>
        <Svg icon="ordering" />
      </DropdownTrigger>
      <DropdownContent active={open}>
        <Menu>
          {orderingOptions.map((option) => (
            <MenuItem
              key={option.i18n}
              data-order={option.value}
              active={values.orderBy === option.value}
              onClick={toggleOrder}
            >
              {intl.formatMessage({ id: option.i18n })}
            </MenuItem>
          ))}
        </Menu>
      </DropdownContent>
    </FilterAdditionalItemDropdown>
  )
}

export default MobileOrdering
