import React, { FormEvent, FC, memo, useMemo, useCallback, useState, useContext, useLayoutEffect, useRef } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useFragment, useMutation, useRelayEnvironment } from 'react-relay'
import { Formik, FormikHelpers, useFormikContext, yupToFormErrors } from 'formik'
import { useIntl } from 'react-intl'
import { DeepWritable } from 'ts-essentials'
import { useLocation } from 'react-router-dom'
import { filter, drop, getOr, isArray, isEmpty, isEqual, isNil, isString, sortBy, map, reject, some } from 'lodash/fp'
import { nanoid } from 'nanoid'
import { isFuture, isPast, parseISO } from 'date-fns'
import EventSubmissionWizard from '../EventForm/EventSubmissionWizard'
import { EventDetails_event$data, EventDetails_event$key } from '../../__generated__/EventDetails_event.graphql'
import { EventDetails_viewer$key } from '../../__generated__/EventDetails_viewer.graphql'
import IEventForm from '../EventForm/types'
import { PageViewTracker, trackingContext } from '../../context/tracking'
import { collectTrackingData } from '../../utils/tracking'
import { notificationContext } from '../../context/notification'
import EventSchema from '../EventForm/validation/Event'
import { parseMarkdown } from '../../utils/markdown'
import { scrollToSection } from '../../components/AutoScroller'
import { getExposedHeight } from '../../utils/useExposeHeightToCss'
import IdleValidator from '../../components/IdleValidator'
import { parseAdditionalInfo } from '../EventForm/components/EventAdditionalInfos'
import { authContext } from '../../context/auth'
import { isItalianEvent } from '../../utils/isCountryEvent'
import scrollToTopError from '../../utils/scrollToTopError'
import createOrUpdateEvent from '../EventForm/services/createOrUpdateEvent'
import { minorUpdateEvent } from './services/minorUpdateEvent'
import { localeContext } from '../../context/locale'
import TicketFeesConfirmationModal from './TicketFeesConfirmationModal'
import ChangeEventNotificationModal from './ChangeEventNotificationModal'
// eslint-disable-next-line max-len
import { CreateEventChangedNotificationInput } from '../../__generated__/EventDetailsSendEventChangedNotificationMutation.graphql'
import EventIsFreeModal from '../../components/EventIsFreeModal'

interface IProps {
  event: EventDetails_event$key | null
  viewer: EventDetails_viewer$key
}

const convertEvent = (evt: EventDetails_event$data | undefined): IEventForm | undefined => {
  if (!evt) {
    return
  }

  const headliners = filter((eventArtist) => !!eventArtist?.headliner, evt.eventArtists || [])
  const artistForBio =
    evt && evt.eventArtists?.length === 1 && evt.eventArtists[0]
      ? evt.eventArtists[0]
      : headliners.length === 1 && headliners[0]
        ? headliners[0]
        : null

  const mappedEventArtists = map(
    (ea) =>
      ea
        ? {
          ...ea,
          descriptionDraft: parseMarkdown(ea?.description),
        }
        : null,
    (evt as DeepWritable<EventDetails_event$data>).eventArtists || []
  )

  const mappedFaqs = map(
    (faq) =>
      faq
        ? {
          ...faq,
          bodyDraft: parseMarkdown((faq as any)?.body || ''),
        }
        : null,
    (evt as DeepWritable<EventDetails_event$data>).faqs || []
  )

  return {
    ...(evt as DeepWritable<EventDetails_event$data>),
    additionalInfos: parseAdditionalInfo(evt),
    descriptionDraft: parseMarkdown(evt.description),
    showArtistDescription: evt.showArtistDescription
      ? evt.showArtistDescription
      : artistForBio?.artist?.description
        ? 'DICE'
        : 'NONE',
    faqs: mappedFaqs,
    eventArtists: mappedEventArtists,
    artistForBio: artistForBio
      ? {
        ...(artistForBio as DeepWritable<NonNullable<typeof artistForBio>>),
        descriptionDraft: parseMarkdown(artistForBio.description),
      }
      : null,
    waitingListExchangeWindows: sortBy((w) => {
      if (isNil(w?.offset)) return -Infinity
      return w?.offset || 0
    }, evt.waitingListExchangeWindows || []) as any as Array<
      null | ({ id: string } & Omit<NonNullable<NonNullable<typeof evt.waitingListExchangeWindows>[number]>, 'id'>)
    > | null,
  }
}

const SmartForm: FC<React.PropsWithChildren<{ onSubmit: () => void }>> = ({ onSubmit, children }) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { user, hasPermission } = useContext(authContext)

  const { isValid, handleReset, errors } = useFormikContext()

  const doSubmit = useMemo(
    () =>
      //prettier-ignore
      isValid || user.diceStaff || hasPermission('full_manage_access:event')
        ? (e: FormEvent<HTMLFormElement> | undefined) => {
          if (e && e.preventDefault) {
            e.preventDefault()
          }
          onSubmit()
        }
        : (e: FormEvent<HTMLFormElement> | undefined) => {
          e && e.preventDefault()
          scrollToTopError(errors)
          addNotification('error', intl.formatMessage({ id: 'new_event.cant_save_invalid_event_form' }))
        },
    [addNotification, errors, hasPermission, intl, isValid, onSubmit, user.diceStaff]
  )

  return (
    <form noValidate onSubmit={doSubmit} onReset={handleReset}>
      {children}
    </form>
  )
}

const EventDetails: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey, viewer: viewerKey }) => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)
  const { user, hasPermission } = useContext(authContext)
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const { state: locationState } = useLocation()

  const event = useFragment(
    graphql`
      fragment EventDetails_event on Event {
        id
        name
        completedSteps
        state

        eventIdLive
        announceDate
        onSaleDate
        date
        endDate

        lockVersion
        previewToken

        allowedActions {
          minorUpdate
        }

        addressCountry
        countryCode
        venues {
          addressCountry
          countryCode
        }

        ...Basics_event @relay(mask: false)

        ...Timeline_event @relay(mask: false)

        ...Information_event @relay(mask: false)

        ...Settings_event @relay(mask: false)

        ...Tickets_event @relay(mask: false)

        ...Extras_event @relay(mask: false)

        ...Merch_event @relay(mask: false)
      }
    `,
    eventKey
  )

  const viewer = useFragment(
    graphql`
      fragment EventDetails_viewer on Viewer {
        diceStaff
        dicePartner

        ...Basics_viewer
        ...Tickets_viewer
        ...Extras_viewer
        ...Merch_viewer
        ...Settings_viewer
      }
    `,
    viewerKey
  )

  const isItalian = useMemo(() => isItalianEvent(event, locale), [event, locale])

  const bypassFeesModal = user.diceStaff && event?.state === 'CANCELLED'
  const [showFeesModal, setFeesModal] = useState<boolean>(false)
  const toggleFeesModal = useCallback(
    (e?: FormEvent<HTMLFormElement> | undefined) => {
      if (e) {
        e.preventDefault()
      }
      setFeesModal(!showFeesModal)
    },
    [showFeesModal]
  )

  const [showIsFreeModal, setShowIsFreeModal] = useState(false)

  const toggleIsFreeModal = useCallback(() => setShowIsFreeModal(!showIsFreeModal), [showIsFreeModal])

  // Change event notification
  const [sendChangeNotification, sending] = useMutation(graphql`
    mutation EventDetailsSendEventChangedNotificationMutation($input: CreateEventChangedNotificationInput!) {
      createEventChangedNotification(input: $input) {
        successful
      }
    }
  `)
  const [notificationSent, setNotificationSent] = useState<boolean>(false)
  const [majorChanges, setMajorChanges] = useState([])
  const cleanupChanges = useCallback(() => {
    setMajorChanges([])
    setNotificationSent(false)
  }, [])

  const eventId = event?.id
  const trackData = useMemo(
    () => collectTrackingData(event),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId]
  )

  const saveAndContinue = useCallback(
    async (
      values: Partial<IEventForm>,
      formik: FormikHelpers<IEventForm>,
      notification?: { changes: Array<string | null> | null; message: string | null; sendMeACopy: boolean } | null
    ) => {
      setFeesModal(false)
      formik.setSubmitting(true)

      if (!notification) cleanupChanges()

      trackEvent('event_details_saved', trackData)

      try {
        const saverFn = user.diceStaff
          ? (v: Partial<IEventForm>) => createOrUpdateEvent(environment, v, locale, false, user.diceStaff)
          : (v: Partial<IEventForm>) => minorUpdateEvent(environment, v, locale)

        await saverFn(values)

        addNotification('success', intl.formatMessage({ id: 'event_details.notification.details_saved' }))

        if (notification) {
          const input: CreateEventChangedNotificationInput = {
            clientMutationId: nanoid(),
            eventId: event?.id || '',
            message: notification.message,
            changes: notification.changes,
            sendMeACopy: notification.sendMeACopy,
          }

          sendChangeNotification({
            variables: {
              input,
            },
            onCompleted(data) {
              const success = getOr(false, 'createEventChangedNotification.successful', data)
              if (success) {
                addNotification('success', intl.formatMessage({ id: 'fan_connect.notification.create.success' }))
                setNotificationSent(true)
              } else {
                addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
                cleanupChanges()
              }
            },
            onError() {
              addNotification('error', intl.formatMessage({ id: 'notification.general_error' }))
              cleanupChanges()
            },
          })
        }
      } catch (err) {
        if (err && (err.messages || isArray(err))) {
          const msgArr = isArray(err) ? err : err.messages
          msgArr.forEach((msg: any) => {
            if (isString(msg)) addNotification('error', msg)
            if (msg?.message) addNotification('error', msg.message)
          })
        } else {
          console.error(err)
          addNotification('error', intl.formatMessage({ id: 'event_details.notification.details_save_error' }))
        }
      } finally {
        formik.setSubmitting(false)
      }
    },
    [
      addNotification,
      environment,
      intl,
      locale,
      trackData,
      trackEvent,
      user.diceStaff,
      cleanupChanges,
      sendChangeNotification,
      event?.id,
    ]
  )

  const checkChanges = useCallback(
    (values: Partial<IEventForm>, formik: FormikHelpers<IEventForm>) => {
      const isPastEvent = event?.endDate && isPast(parseISO(event.endDate))
      const isPreOnSale = event?.onSaleDate && isFuture(parseISO(event.onSaleDate))
      const eventIsLive = event?.state === 'APPROVED' && event.eventIdLive
      // Skip tracking changes flow for non diceStaff users and Italian events, and if event isn't live
      if (
        !(user.diceStaff && hasPermission('create:notification_batch')) ||
        isItalian ||
        !eventIsLive ||
        isPastEvent ||
        isPreOnSale
      )
        return saveAndContinue(values, formik)

      const changes = [] as any

      const dateChanged = event?.date && values.date && event?.date !== values.date
      const scheduledStatusChanged = values.scheduleStatus && event?.scheduleStatus !== values.scheduleStatus
      const venueChanged =
        event?.primaryVenue && values.primaryVenue && event?.primaryVenue.value !== values.primaryVenue.value
      const lineupChanged = !isEqual(drop(1, event?.lineup), drop(1, values.lineup))

      if (dateChanged) changes.push('date')
      if (scheduledStatusChanged) changes.push('schedule_status')
      if (venueChanged) changes.push('venue')
      if (lineupChanged) changes.push('lineup')

      if (changes.length > 0) {
        setMajorChanges(changes)
      } else {
        saveAndContinue(values, formik)
      }
    },
    [
      event?.endDate,
      event?.onSaleDate,
      event?.state,
      event?.eventIdLive,
      event?.date,
      event?.scheduleStatus,
      event?.primaryVenue,
      event?.lineup,
      user.diceStaff,
      hasPermission,
      isItalian,
      saveAndContinue,
    ]
  )

  const toggleModals = useCallback(
    (values: Partial<IEventForm>, formik: FormikHelpers<IEventForm>) => {
      setFeesModal(false)
      setShowIsFreeModal(false)
      const nonArchivedTtys = values.ticketTypes ? reject('archived', values.ticketTypes) : []

      const shouldPromptFreeEvent =
        !user.diceStaff &&
        !values.freeEvent &&
        isItalian &&
        !some((tt) => tt?.priceTierType || (tt?.faceValue || 0) > 0, nonArchivedTtys) &&
        values.state === 'DRAFT'

      if (shouldPromptFreeEvent) {
        toggleIsFreeModal()
        return
      }

      if (bypassFeesModal) {
        checkChanges(values, formik)
        return
      }

      toggleFeesModal()
    },
    [bypassFeesModal, checkChanges, isItalian, toggleFeesModal, toggleIsFreeModal, user.diceStaff]
  )

  const allowUpdate = !!event?.allowedActions?.minorUpdate && event.state !== 'CANCELLED'

  const trackReset = useCallback(() => {
    trackEvent('event_details_cancelled', trackData)
  }, [trackData, trackEvent])

  const initialValues = useMemo(() => event && convertEvent(event), [event])

  const validateEventSchema = useCallback(
    async (evt: any) => {
      if (!allowUpdate) return true
      try {
        await EventSchema.validate(evt, { context: { initialValues, viewer, locale }, abortEarly: false })
      } catch (err) {
        return yupToFormErrors(err)
      }
    },
    [allowUpdate, initialValues, locale, viewer]
  )

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  useLayoutEffect(() => {
    const state = locationState as any

    if (!initialValues || !state?.scrollSlug) return

    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      if (state.scrollSlug) {
        scrollToSection(state.scrollSlug, false, getExposedHeight('--page-nav-height'))
      }
    }, 500)
  }, [initialValues, locationState])

  if (!initialValues || !event) return null

  return (
    <div>
      <PageViewTracker trackId="event_details" trackData={trackData} />
      <Formik<IEventForm>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={toggleModals}
        validateOnMount
        validateOnBlur
        validateOnChange={false}
        validate={validateEventSchema}
      >
        {(formik) => {
          const onCloseFeesModal = (e?: FormEvent<HTMLFormElement> | undefined) => {
            if (e) {
              e.preventDefault()
            }
            setFeesModal(false)
            setShowIsFreeModal(false)
            checkChanges(formik.values, formik)
          }

          const doSubmit = (e?: FormEvent<HTMLFormElement> | undefined) => {
            if (e) {
              e.preventDefault()
            }
            toggleModals(formik.values, formik)
          }

          return (
            <SmartForm
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={doSubmit}
            >
              <IdleValidator />
              <EventSubmissionWizard
                readOnly={!allowUpdate}
                submissionFlow={false}
                viewer={viewer}
                onCancel={trackReset}
              />

              {showFeesModal && (
                <TicketFeesConfirmationModal
                  values={formik.values}
                  // eslint-disable-next-line react/jsx-no-bind
                  onConfirm={onCloseFeesModal}
                  onReject={toggleFeesModal}
                />
              )}

              {/* eslint-disable-next-line react/jsx-no-bind */}
              {showIsFreeModal && <EventIsFreeModal onClose={doSubmit} />}

              {!isEmpty(majorChanges) && (
                <ChangeEventNotificationModal
                  loading={formik.isSubmitting || sending}
                  completed={notificationSent}
                  event={event}
                  values={formik.values}
                  changes={majorChanges}
                  // eslint-disable-next-line react/jsx-no-bind
                  onSave={(values, notification) => saveAndContinue(values, formik, notification)}
                  onClose={cleanupChanges}
                />
              )}
            </SmartForm>
          )
        }}
      </Formik>
    </div>
  )
}

export default memo(EventDetails)
