import React, { FC, memo } from 'react'
import TextInput, { ITextInputProps } from './TextInput'
import Icon from './Svg'
import { Loader } from './Loader'

export interface ISearchInputProps extends ITextInputProps {
  loading?: boolean
}

const SearchInput: FC<React.PropsWithChildren<ISearchInputProps>> = (props) => (
  <TextInput
    {...props}
    affix={
      <span className="text-input_search">
        {props.loading && <Loader size="small" className="text-input_search-loading" />}
        <Icon icon="search" className="text-input_search-icon" />
      </span>
    }
    type="text"
  />
)

export default memo(SearchInput)
