import React, { FC, useContext } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router'

import { notificationContext } from '../../../context/notification'

import FormField from '../../../components/FormField'
import Button from '../../../components/Button'
import { Form, FormRow } from '../../../components/Form'

import REQUESTS from '../../../utils/requests'
import { setAuthToken, restoreLocation } from '../../../utils/api'

import { AuthFormHeader, AuthForm, AuthFormWrapper } from '../Auth'
import { PageViewTracker, trackingContext } from '../../../context/tracking'

export const AuthFormLink = styled(FormRow)`
  margin-top: 0 !important;
`

const Login: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      trackEvent('login_submitted')

      const { access_token } = await REQUESTS.OBTAIN_TOKEN({ email, password })
      if (access_token) {
        setAuthToken(access_token)
        const goToUrl = restoreLocation()

        Sentry.configureScope(function (scope) {
          scope.setExtra('loginEmail', email)
          scope.setExtra('loginRedirectUrl', goToUrl || '/')
        })

        navigate(goToUrl || '/', { replace: true })
      } else {
        addNotification('error', intl.formatMessage({ id: 'auth.error.invalid_login_or_password' }))
      }
    },
  })

  const { values, handleSubmit, handleChange, isSubmitting } = formik

  return (
    <AuthFormWrapper>
      <PageViewTracker trackId="login" />
      <AuthFormHeader
        title={intl.formatMessage({ id: 'sign_in' })}
        description={intl.formatMessage({ id: 'auth.description' })}
      />
      <AuthForm onSubmit={handleSubmit}>
        <Form>
          <FormRow>
            <FormField
              name="email"
              label={intl.formatMessage({ id: 'auth.email_address' })}
              value={values.email}
              onChange={handleChange}
              autoFocus
            />
          </FormRow>
          <FormRow>
            <FormField
              name="password"
              label={intl.formatMessage({ id: 'auth.password' })}
              type="password"
              value={values.password}
              onChange={handleChange}
            />
          </FormRow>
          <AuthFormLink>
            <Button preset="link" color="primary" to="/auth/recover">
              {intl.formatMessage({ id: 'auth.forgot_password' })}
            </Button>
          </AuthFormLink>
          <FormRow>
            <Button
              block
              type="submit"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting || !values.email || !values.password}
            >
              {intl.formatMessage({ id: 'sign_in' })}
            </Button>
          </FormRow>
        </Form>
      </AuthForm>
    </AuthFormWrapper>
  )
}

export default Login
