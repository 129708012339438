const PINNED_EVENT_ID_KEY = 'mioPinnedEventId'
const UNPINNED_EVENT_IDS_KEY = 'mioUnpinnedEventIds'

export const loadUnpinned = (accountId: string | undefined | null): Set<string> => {
  const key = `${UNPINNED_EVENT_IDS_KEY}:${accountId || 'global'}`
  const unpinnedArr = JSON.parse(localStorage.getItem(key) || '[]') as string[]
  const set = new Set(unpinnedArr)
  return set
}

export const saveUnpinned = (accountId: string | undefined | null, set: Set<string>) => {
  const key = `${UNPINNED_EVENT_IDS_KEY}:${accountId || 'global'}`
  const arr = [...set.values()]
  localStorage.setItem(key, JSON.stringify(arr))
}

export const getPinnedEventId = (accountId: string | undefined | null) => {
  const key = `${PINNED_EVENT_ID_KEY}:${accountId || 'global'}`
  return localStorage.getItem(key) || null
}

export const isPinned = (accountId: string | undefined | null, id: string) =>
  getPinnedEventId(accountId) === id && !isUnpinned(accountId, id)

export const isUnpinned = (accountId: string | undefined | null, id: string) => {
  const unpinned = loadUnpinned(accountId)
  return unpinned.has(id)
}

export const pinEvent = (accountId: string | undefined | null, id: string) => {
  unpinEvent(accountId)

  const unpinned = loadUnpinned(accountId)
  unpinned.delete(id)
  saveUnpinned(accountId, unpinned)

  const key = `${PINNED_EVENT_ID_KEY}:${accountId || 'global'}`
  localStorage.setItem(key, id)
}

export const unpinEvent = (accountId: string | undefined | null) => {
  const oldPin = getPinnedEventId(accountId)
  if (oldPin) {
    const unpinned = loadUnpinned(accountId)
    unpinned.add(oldPin)
    saveUnpinned(accountId, unpinned)
  }

  const key = `${PINNED_EVENT_ID_KEY}:${accountId || 'global'}`
  localStorage.removeItem(key)
}
