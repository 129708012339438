import React, { ComponentType, PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components/macro'
import { isString, mergeAll, omit, isNil, set, unset } from 'lodash/fp'

import FormGroup, { FormGroupControl, IFormGroupProps } from './FormGroup'
import TextInput, { ITextInputProps } from './TextInput'
import Select, { ISelectProps } from './Select/Select'
import Checkbox, { ICheckboxProps } from './Checkbox'
import Toggle, { IToggleProps } from './Toggle'
import SearchInput, { ISearchInputProps } from './SearchInput'
import DateTimePicker, { IDateTimePickerProps } from './DateTimePicker'
import NewPasswordInput, { INewPasswordProps } from './NewPasswordInput'
import NewPhoneInput, { IPhoneInputProps } from './NewPhoneInput'
import DiceBadge from './DiceBadge'
import RadioGroup, { IRadioGroupProps } from './RadioGroup'
import MarkdownEditor, { IMarkdownProps } from './MarkdownEditor'

type IText = { control: 'text' } & ITextInputProps
type ISelect = { control: 'select' } & ISelectProps
type ICheckbox = { control: 'checkbox' } & ICheckboxProps
type ISearch = { control: 'search' } & ISearchInputProps
type IToggle = { control: 'toggle' } & IToggleProps
type IDateTimePicker = { control: 'datetime' } & IDateTimePickerProps
type INewPassword = { control: 'newpassword' } & INewPasswordProps
type IPhone = { control: 'phone' } & IPhoneInputProps
type IRadioGroup = { control: 'radioGroup' } & IRadioGroupProps
type IMarkdown = { control: 'markdown' } & IMarkdownProps

type IVariant =
  | IText
  | ISelect
  | ICheckbox
  | ISearch
  | IToggle
  | IDateTimePicker
  | INewPassword
  | IPhone
  | IRadioGroup
  | IMarkdown

// prettier-ignore
type IControlProps<C> =
  C extends undefined | 'text' ? Omit<IText, 'control'> & { control?: C } :
  C extends IVariant['control'] ? Extract<IVariant, { control: C }> :
  C extends ComponentType<infer P> ? { control?: ComponentType<P> } & P :
  never

interface IOwnProps {
  controlClassName?: string
  id?: string
}

type IFormFieldProps<C> = PropsWithChildren<IOwnProps> & IControlProps<C> & IFormGroupProps

// eslint-disable-next-line comma-spacing
const FormField = <C = 'text',>(props: IFormFieldProps<C>) => {
  let formGroupProps: IFormGroupProps = {
    label: props.label,
    labelFor: props.id,
    error: props.error,
    hint: props.hint,
    help: props.help,
    required: props.required,
    disabled: props.disabled,
    className: props.className,
    size: props.size,
    timezone: props.timezone,
    dice: props.dice,
  }

  let controlProps: any = mergeAll([
    {
      className: props.controlClassName,
      hasError: !isNil(props.error) && props.error !== false,
    },
    omit(
      [
        'controlClassName',
        'controlProps',
        'control',
        'label',
        'error',
        'hint',
        'help',
        'className',
        'children',
        'dice',
      ],
      props as any
    ),
  ])

  let Control: ComponentType<React.PropsWithChildren<any>> = TextInput

  if (props.control) {
    if (isString(props.control)) {
      switch (props.control) {
        case 'search':
          Control = SearchInput
          break
        case 'markdown':
          Control = MarkdownEditor
          break
        case 'select':
          Control = Select
          break
        case 'checkbox':
          Control = Checkbox
          controlProps = set(
            'label',
            props.dice ? (
              <>
                <DiceBadge />
                {props.label}
              </>
            ) : (
              props.label
            ),
            controlProps
          )
          formGroupProps = unset('label', formGroupProps)
          break
        case 'toggle':
          Control = Toggle
          break
        case 'datetime':
          Control = DateTimePicker
          break
        case 'newpassword':
          Control = NewPasswordInput
          break
        case 'phone':
          Control = NewPhoneInput
          break
        case 'radioGroup':
          Control = RadioGroup
          break
        default:
      }
    } else {
      Control = props.control as any
    }
  }

  return (
    <FormGroup {...formGroupProps}>
      {Control && <Control {...controlProps} />}
      {props.children}
    </FormGroup>
  )
}

export interface IStyledFormField<C = any> {
  (props: IFormFieldProps<C>): ReactElement<any, any> | null
}

export default FormField

export const FlexFormField = styled(FormField)`
  ${FormGroupControl} {
    position: relative;
    display: flex;
    align-items: center;

    & > div {
      flex: 1;
    }

    & > button {
      margin-left: 8px;
    }
  }
` as IStyledFormField

export const ReverseFlexFormField = styled(FlexFormField)`
  ${FormGroupControl} {
    flex-direction: row-reverse;
  }
` as IStyledFormField

export const SmallFormField = styled(FormField)`
  max-width: 224px;
` as IStyledFormField
