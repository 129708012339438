import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { authContext } from '../../../context/auth'
import IEventFormBasics from '../types/Basics'
import { FormRow } from '../../../components/Form'
import FormField, { IStyledFormField } from '../../../components/FormField'
import Radio from '../../../components/Radio'
import FormGroup from '../../../components/FormGroup'
import IdTag from '../../../components/IdTag'
import { color, font } from '../../../utils/variables'
import InputWithCopy from '../../../components/InputWithCopy'

const EventLicenseNumber: FC = () => {
  const intl = useIntl()
  const { user, account } = useContext(authContext)
  const { values, setFieldValue, handleBlur, isSubmitting } = useFormikContext<IEventFormBasics>()

  const isFrenchPromoter = values?.billingPromoter?.countryCode === 'FR'
  const [isUsingCustomLicense, setIsUsingCustomLicense] = useState<boolean>(!!values.licenseNumber || false)

  const toggleCustomLicense = useCallback(() => {
    if (isUsingCustomLicense) {
      setFieldValue('licenseNumber', '')
    }
    setIsUsingCustomLicense(!isUsingCustomLicense)
  }, [isUsingCustomLicense, setFieldValue])

  const setLicenseNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('licenseNumber', e.target.value),
    [setFieldValue]
  )

  const mailto = useMemo(
    () => `mailto:clientsuccess@dice.fm?subject=[${account?.name || 'DICE'}] Event license number`,
    [account?.name]
  )

  /**
   * If user submits event with "use a different license number" radio selected, but the
   * license number field is blank, then set radio back to "use billing promoter's license"
   */
  useEffect(() => {
    if (isSubmitting && !values.licenseNumber) {
      setIsUsingCustomLicense(false)
    }
  }, [isSubmitting, values.licenseNumber])

  if (!values.billingPromoter || !values.billingPromoter?.licenseNumber) return null

  return user.diceStaff || isFrenchPromoter ? (
    <FormRow>
      <FormGroup
        label={intl.formatMessage({
          id: 'new_event.settings.license_number.label',
        })}
        required
      >
        <FormRow>
          <Radio
            name="useCustomLicense"
            label={
              <>
                <span>
                  {intl.formatMessage({
                    id: 'new_event.settings.license_number.options.use_promoter',
                  })}
                </span>{' '}
                <IdTag id={values.billingPromoter.licenseNumber} noLabel />
              </>
            }
            value="no"
            checked={!isUsingCustomLicense}
            onChange={toggleCustomLicense}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow className="mt-sm">
          <Radio
            name="useCustomLicense"
            label={intl.formatMessage({
              id: 'new_event.settings.license_number.options.use_custom',
            })}
            value="yes"
            checked={isUsingCustomLicense}
            onChange={toggleCustomLicense}
            onBlur={handleBlur}
          />
        </FormRow>
        {isUsingCustomLicense && (
          <IndentedFormField
            name="licenseNumber"
            placeholder={intl.formatMessage({ id: 'new_event.settings.license_number.placeholder' })}
            control="text"
            value={values.licenseNumber}
            onChange={setLicenseNumber}
            onBlur={handleBlur}
          />
        )}
      </FormGroup>
    </FormRow>
  ) : (
    <FormRow>
      <FormField
        name="licenseNumber"
        label={intl.formatMessage({
          id: 'new_event.settings.license_number.label',
        })}
        placeholder={intl.formatMessage({ id: 'new_event.settings.license_number.placeholder' })}
        control={InputWithCopy}
        value={values.licenseNumber || values.billingPromoter.licenseNumber || ''}
        disabled
        hint={intl.formatMessage(
          { id: 'new_event.settings.license_number.hint' },
          {
            a: (str: string) => (
              <ContactLink href={mailto} target="_blank" rel="noopener noreferrer">
                {str}
              </ContactLink>
            ),
          }
        )}
      />
    </FormRow>
  )
}

export default EventLicenseNumber

const IndentedFormField = styled(FormField)`
  margin-left: 32px;
  margin-top: 8px;
  max-width: 320px;
` as IStyledFormField

const ContactLink = styled.a`
  color: ${color.text};
  font-weight: ${font.weight.bold};
`
