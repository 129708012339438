import React, { FC, forwardRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { authContext } from '../context/auth'

import { color } from '../utils/variables'
import Svg from './Svg'
import { Text } from './Text'

const StyledSvg = styled(Svg)<{ $isHuge?: boolean }>`
  margin: 0 2px ${({ $isHuge }) => ($isHuge ? -2 : -4)}px 0;
`

const CaptionSvg = styled(Svg)`
  color: ${color.greyer};
  margin: 0 2px -4px 8px;
`

const Overlay = styled.div`
  position: relative;

  ${StyledSvg} {
    margin: 0;

    position: absolute;
    top: -6px;
    right: 4px;
  }
`

interface IProps {
  huge?: boolean
  className?: string
  size?: number
}

const DiceBadge = forwardRef<SVGSVGElement, IProps>(({ huge, className, size }, ref) => {
  const { user } = useContext(authContext)

  if (!user.diceStaff) return null

  return (
    <StyledSvg
      ref={ref}
      className={className}
      $isHuge={huge}
      icon="dice-badge"
      width={size || 20}
      height={size || 20}
    />
  )
})

export const DiceBadgeOverlay: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { user } = useContext(authContext)

  if (!user.diceStaff) return <>{children}</>

  return (
    <Overlay>
      <DiceBadge />
      {children}
    </Overlay>
  )
}

export const DiceBadgeCaption = forwardRef<HTMLSpanElement, {}>((_, ref) => {
  const intl = useIntl()
  const { user } = useContext(authContext)

  if (!user.diceStaff) return null

  return (
    <Text ref={ref} color="greyer" fontSize="sm">
      <CaptionSvg icon="eye" width={16} height={16} />
      {intl.formatMessage({ id: 'dice_badge.caption' })}
    </Text>
  )
})

export default styled(DiceBadge)``
