import React, { FC, memo, useMemo, useCallback, useState } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { useIntl } from 'react-intl'
import { Dictionary } from 'ts-essentials'
import { compose, concat, groupBy, keys, sortBy, startCase, uniq } from 'lodash/fp'
import styled from 'styled-components/macro'

import { FeeType } from '../../../enums.generated'
import { TaxRates_event$data, TaxRates_event$key } from '../../../__generated__/TaxRates_event.graphql'
import { color } from '../../../utils/variables'

const SmallTable = styled.table`
  border-collapse: collapse;
  min-width: 200px;

  tbody {
    td {
      padding: 4px 8px;
    }

    td:first-child {
      text-align: left;
      padding-left: 0;
    }

    td:last-child {
      text-align: right;
      padding-right: 0;
    }
  }
`

const Group = styled.a`
  text-decoration: underline;

  opacity: 0.8;

  &:hover {
    color: ${color.white};
    opacity: 1;
  }
`

interface IProps {
  event: TaxRates_event$key
}

type ITaxRate = NonNullable<NonNullable<NonNullable<TaxRates_event$data['taxRates']>[number]>>

const MAPPING: Dictionary<FeeType | null, keyof NonNullable<ITaxRate['feesRates']>> = {
  bookingFee: 'booking',
  charityFee: 'charityDonation',
  processingFee: 'processing',
  pwlFee: 'paidWaitingList',
  tierDiff: 'tierDiff',
  extraCharge: 'extraCharge',
  additionalPromoterFee: 'additionalPromoterFee',
  vendor: 'vendor',
  facilityFee: 'facilityFee',
  venueFee: 'venueFee',
  venueLevy: 'venueLevy',
  presale: 'presale',
  fulfillment: 'postal',
  foodAndBeverage: null,
  meetAndGreet: null,
}

const TaxRate: FC<React.PropsWithChildren<{ rate: ITaxRate }>> = ({ rate }) => {
  const intl = useIntl()

  const [expanded, setExpanded] = useState<number[]>([])
  const expandGroup = useCallback((e: any) => {
    const idx = e.currentTarget.dataset['idx']
    setExpanded((s) => uniq(concat(s, Number(idx))))
  }, [])

  const groupedFeeKeys: Dictionary<Array<keyof typeof MAPPING>> = useMemo(
    () =>
      compose([
        groupBy((k: keyof typeof MAPPING) => rate.feesRates?.[k] || 0),
        sortBy((k: keyof typeof MAPPING) => rate.feesRates?.[k] || 0),
      ])(keys(MAPPING)),
    [rate.feesRates]
  )

  return (
    <tbody>
      <tr>
        <td>{intl.formatMessage({ id: 'face_value' })}</td>
        <td>
          {intl.formatNumber(rate?.faceValueRate || 0, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            style: 'percent',
          })}
        </td>
      </tr>
      {keys(groupedFeeKeys).map((grp, gidx) =>
        groupedFeeKeys[grp].length > 5 && expanded.indexOf(gidx) < 0 ? (
          <tr key={gidx}>
            <td>
              <Group data-idx={gidx} onClick={expandGroup}>
                {intl.formatMessage(
                  { id: 'tax_rates.group_title' },
                  {
                    num: intl.formatNumber(groupedFeeKeys[grp].length || 0, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }
                )}
              </Group>
            </td>
            <td>
              {intl.formatNumber(Number(grp) || 0, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                style: 'percent',
              })}
            </td>
          </tr>
        ) : (
          groupedFeeKeys[grp].map((key) => (
            <tr key={key}>
              <td>{intl.formatMessage({ id: `fees.${MAPPING[key] || key}`, defaultMessage: startCase(key) })}</td>
              <td>
                {intl.formatNumber(rate?.feesRates?.[key] || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  style: 'percent',
                })}
              </td>
            </tr>
          ))
        )
      )}
    </tbody>
  )
}

const TaxRates: FC<React.PropsWithChildren<IProps>> = ({ event: eventKey }) => {
  const intl = useIntl()

  const { taxRates } = useFragment(
    graphql`
      fragment TaxRates_event on Event {
        taxRates {
          faceValueRate
          feesRates {
            additionalPromoterFee
            bookingFee
            charityFee
            extraCharge
            facilityFee
            foodAndBeverage
            fulfillment
            meetAndGreet
            presale
            processingFee
            pwlFee
            tierDiff
            venueFee
            venueLevy
            vendor
          }
        }
      }
    `,
    eventKey
  )

  if (!taxRates || taxRates.length === 0) return <>{intl.formatMessage({ id: 'na' })}</>

  return (
    <SmallTable>
      <thead>
        <tr>
          <th colSpan={2}>{intl.formatMessage({ id: 'tax_rates.label' })}</th>
        </tr>
      </thead>
      {taxRates.map((tr, idx) => tr && <TaxRate key={idx} rate={tr} />)}
    </SmallTable>
  )
}

export default memo(TaxRates)
