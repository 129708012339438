import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components/macro'
import { debounce } from 'lodash/fp'
import { zIndex } from '../utils/variables'
import ClickOutside, { ClickOutsideElement } from './ClickOutside'

type Direction = 'up' | 'down'
type Alignment = 'left' | 'right'

export const Dropdown = styled(ClickOutside)`
  display: inline-block;
  position: relative;
`

export const DropdownTrigger = styled.button<{ disabled?: boolean }>`
  border: 0;
  padding: 0;
  background: none;
  display: inline-block;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    outline: none;
  }
`

const dropdownContentStyles = css`
  background: #fff;
  border-radius: 8px;
  position: absolute;
  white-space: nowrap;
  z-index: ${zIndex.tooltip};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
`

export const StyledDropdownContent = styled.div<IProps>`
  ${dropdownContentStyles}

  ${({ align }) => (align === 'left' ? 'left: 0;' : 'right: 0;')}
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  ${({ direction }) => (direction === 'up' ? 'bottom: 100%;' : 'top: 100%;')}
  ${({ direction }) => (direction === 'up' ? 'margin-bottom: 8px;' : 'margin-top: 8px;')}
`

const PortalDropdownContent = styled(ClickOutsideElement)<{ $x: number; $y: number }>`
  ${dropdownContentStyles}
  top: 0;
  left: 0;

  ${({ $x, $y }) => css`
    transform: translate3d(${$x}px, ${$y}px, 0px);
  `}
`

interface IPortalProps {
  direction: Direction
  content: React.RefObject<HTMLDivElement>
}

/**
 * Render dropdown content in a portal using the existing hidden content to position
 * portal dropdown relative to window.
 */
const Portal: FC<React.PropsWithChildren<IPortalProps>> = ({ children, content, direction }) => {
  const calculateStyles = useCallback(() => {
    const { x = 0, y = 0 } = content.current?.getBoundingClientRect() || {}
    const offsetY = direction === 'up' ? -8 : 0

    return {
      $x: x,
      $y: window.scrollY + y + offsetY,
    }
  }, [content, direction])

  const [styles, setStyles] = useState(calculateStyles())

  useEffect(() => {
    const updateStyles = debounce(200, () => setStyles(calculateStyles()))

    window.addEventListener('wheel', updateStyles)
    window.addEventListener('resize', updateStyles)

    return () => {
      window.removeEventListener('wheel', updateStyles)
      window.removeEventListener('resize', updateStyles)
    }
  }, [calculateStyles])

  return createPortal(
    <PortalDropdownContent data-is-portal="true" {...styles}>
      {children}
    </PortalDropdownContent>,
    document.body
  )
}

interface IProps {
  portal?: boolean
  active?: boolean
  direction?: Direction
  align?: Alignment
}

export const DropdownContent: FC<React.PropsWithChildren<IProps>> = ({
  children,
  active,
  direction,
  align,
  portal,
  ...restProps
}) => {
  const content = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<boolean>(false)
  const [showPortal, setShowPortal] = useState<boolean>(false)
  // Start in 'up' position to prevent hidden menu from adding space to bottom of page
  const [idealDirection, setIdealDirection] = useState<Direction>('up')

  useEffect(() => {
    if (active && content.current) {
      const { top, bottom } = content.current.getBoundingClientRect()
      const contentHeight = content.current.clientHeight
      const windowHeight = window?.innerHeight
      const contentYOffset = idealDirection === 'up' ? bottom + 30 : top // 30 is approximation for trigger height

      // If there's no room to open downward and there is room to open upward, open upward
      const newDirection =
        contentYOffset + contentHeight > windowHeight && contentYOffset > contentHeight ? 'up' : 'down'
      setIdealDirection(newDirection)
    }

    setShow(!!active)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  /**
   * Set show portal after ideal direction calculation to ensure the placeholder
   * content is rendered in the correct position before positioning the portal content.
   */
  useEffect(() => {
    if (portal) {
      setShowPortal(show)
    }
  }, [portal, show])

  return (
    <>
      <StyledDropdownContent
        ref={content}
        active={!portal && show}
        direction={direction ?? idealDirection}
        align={align}
        {...restProps}
      >
        {children}
      </StyledDropdownContent>
      {portal && showPortal && (
        <Portal content={content} direction={direction ?? idealDirection}>
          {children}
        </Portal>
      )}
    </>
  )
}
