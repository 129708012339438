import countries from 'i18n-iso-countries'
import currencies from 'country-currency'
import timezones from 'countries-and-timezones'
import {
  map,
  compose,
  toPairs,
  mapValues,
  get,
  keyBy,
  sortBy,
  findIndex,
  isEmpty,
  snakeCase,
  fromPairs,
} from 'lodash/fp'

import { IntlShape } from 'react-intl'

import { ILocale, DEFAULT_LOCALE } from '../intl'
import phoneCodes from './phoneCodes'
import { OPERATING_COUNTRIES } from '../constants/operatingCountries'
import { EventCostCurrency } from '../enums.generated'

const COUNTRY_CURRENCIES = currencies.byCountry()

const phoneMap = compose(mapValues(get('dial')), keyBy('iso'))(phoneCodes)

export interface Country {
  value: string
  label: string
  alpha2: string
  dial?: string
}

function getCountries(intl: IntlShape, locale?: ILocale): Array<Country> {
  let data = countries.getNames((locale || DEFAULT_LOCALE).substring(0, 2))

  if (!data || isEmpty(data)) {
    const opCountriesMap = keyBy('alpha2', OPERATING_COUNTRIES)

    data = compose([
      fromPairs,
      map(([k, v]) => [
        k,
        intl.formatMessage({
          id: opCountriesMap[k]?.i18n || `countries.${snakeCase(v.toLowerCase())}`,
          defaultMessage: v,
        }),
      ]),
      toPairs,
    ])(countries.getNames(DEFAULT_LOCALE.substring(0, 2))) as typeof data
  }

  if (!data || isEmpty(data)) {
    console.error(
      'No fallback country names found for locale',
      locale || DEFAULT_LOCALE,
      '(using operating countries fallback)'
    )

    data = mapValues(
      (c: typeof OPERATING_COUNTRIES[number]) => intl.formatMessage({ id: c.i18n, defaultMessage: c.label }),
      keyBy('alpha2', OPERATING_COUNTRIES)
    ) as typeof data
  }

  return compose([
    sortBy(({ alpha2 }) => {
      const idx = findIndex(['alpha2', alpha2], OPERATING_COUNTRIES)
      return idx >= 0 ? idx : 9999999
    }),
    map(([k, v]) => ({ value: v, label: v, alpha2: k, dial: phoneMap[k.toUpperCase()] || undefined })),
    toPairs,
  ])(data)
}

export const getAlpha2ByName = (name?: string | null, locale?: ILocale): string | undefined => {
  if (!name) return undefined

  if ((name || '').trim().toLowerCase() === 'united kingdom') return 'GB'
  if ((name || '').trim().toLowerCase() === 'united states') return 'US'
  if ((name || '').trim().toLowerCase() === 'canada') return 'CA'
  const localeCode = locale && countries.getAlpha2Code(name, locale.substring(0, 2))
  const defaultCode = countries.getAlpha2Code(name, DEFAULT_LOCALE.substring(0, 2))
  return localeCode || defaultCode
}

export const getNameByAlpha2 = (alpha2: string, locale?: ILocale) =>
  countries.getName(alpha2, (locale || DEFAULT_LOCALE).substring(0, 2))

export const getTimezonesByAlpha2 = (code: string) => map('name', timezones.getTimezonesForCountry(code) || [])

export const getCurrencyByCountryCode = (countryCode: string | undefined) => {
  const currency = countryCode && COUNTRY_CURRENCIES.get(countryCode)
  return (currency || 'GBP') as EventCostCurrency
}

export default getCountries
