/**
 * @generated SignedSource<<9346d487c97a597e0e3175ecf30e7461>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type useSeatedTicketTypesGenerateQuery$variables = {
  eventId?: string | null;
  id: string;
};
export type useSeatedTicketTypesGenerateQuery$data = {
  readonly seatingChart: {
    readonly seatsIoEventReport?: IBackendMap | null;
  } | null;
  readonly viewer: {
    readonly account: {
      readonly allowedAdhocFees: ReadonlyArray<{
        readonly amount: number | null;
        readonly split: ReadonlyArray<{
          readonly amount: number | null;
          readonly destination: FeeDestination | null;
          readonly unit: FeeUnit;
        } | null> | null;
        readonly type: FeeType;
        readonly unit: FeeUnit;
      } | null> | null;
    } | null;
  } | null;
};
export type useSeatedTicketTypesGenerateQuery = {
  response: useSeatedTicketTypesGenerateQuery$data;
  variables: useSeatedTicketTypesGenerateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "eventId",
      "variableName": "eventId"
    }
  ],
  "concreteType": "Fee",
  "kind": "LinkedField",
  "name": "allowedAdhocFees",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeeSplit",
      "kind": "LinkedField",
      "name": "split",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "destination",
          "storageKey": null
        },
        (v3/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "group",
          "value": "BY_CATEGORY_KEY"
        }
      ],
      "kind": "ScalarField",
      "name": "seatsIoEventReport",
      "storageKey": "seatsIoEventReport(group:\"BY_CATEGORY_KEY\")"
    }
  ],
  "type": "EventSeatingChart",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSeatedTicketTypesGenerateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "seatingChart",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSeatedTicketTypesGenerateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "seatingChart",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4afde8acb1b9a97f3eb7dd2591042f6",
    "id": null,
    "metadata": {},
    "name": "useSeatedTicketTypesGenerateQuery",
    "operationKind": "query",
    "text": "query useSeatedTicketTypesGenerateQuery(\n  $id: ID!\n  $eventId: ID\n) {\n  viewer {\n    account {\n      allowedAdhocFees(eventId: $eventId) {\n        type\n        unit\n        amount\n        split {\n          destination\n          amount\n          unit\n        }\n      }\n      id\n    }\n    id\n  }\n  seatingChart: node(id: $id) {\n    __typename\n    ... on EventSeatingChart {\n      seatsIoEventReport(group: BY_CATEGORY_KEY)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "17b82ca416a295a889e8eb069a60b2d6";

export default node;
