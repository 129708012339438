import React, { FC, useEffect, useCallback, useMemo, useRef, memo } from 'react'
import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { filter } from 'lodash/fp'
import InfiniteScroll from 'react-infinite-scroller'

import { usePaginationFragment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { mediaQuery, zIndex } from '../../../../utils/variables'

import { LoaderContainer, Loader } from '../../../../components/Loader'
import Button from '../../../../components/Button'
import EmptyState from '../../../../components/EmptyState'

import DashboardEventCardInfo from '../DashboardEvent/DashboardEventCardInfo'
import { DashboardEventCard, DashboardEventCardList } from '../DashboardEvent/DashboardEvent'

import { SearchResults_viewer$key } from '../../../../__generated__/SearchResults_viewer.graphql'

const ResultsOverlay = styled.div`
  display: block;
  position: fixed;
  top: 162px;
  bottom: 0;
  left: 242px;
  right: 0;
  padding: 24px 32px;
  background: #fff;
  overflow-y: auto;
  z-index: ${zIndex.dropdown};

  .-impersonating & {
    top: 224px;
  }

  ${mediaQuery.lessThan('desktopLarge')`
    left: 74px;
  `}

  ${mediaQuery.lessThan('tablet')`
    left: 0;
    top: 116px;
    padding: 0;
  `}
`

const ResultsOverlayContent = styled.div`
  max-width: 1130px;
  margin: 0 auto;
`

const LoadingMore = styled.div`
  display: block;
  margin: 24px auto 0;
  text-align: center;
`

export const SearchResultsLoader = () => (
  <ResultsOverlay>
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  </ResultsOverlay>
)

interface IProps {
  viewer: SearchResults_viewer$key
}

const SearchResults: FC<React.PropsWithChildren<IProps>> = (props) => {
  const {
    data: viewer,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    graphql`
      fragment SearchResults_viewer on Viewer
      @refetchable(queryName: "SearchResultsPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
        searchTerm: { type: "String" }
      ) {
        events(first: $count, after: $cursor, searchTerm: $searchTerm) @connection(key: "SearchResults_events") {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              ...DashboardEventCardInfo_event
            }
          }
        }
      }
    `,
    props.viewer
  )

  const intl = useIntl()

  const scrollParent = useRef<HTMLDivElement>(null)
  const getParent = useCallback(() => scrollParent.current || null, [scrollParent])

  const loadMoreEvents = useCallback(() => {
    if (!hasNext || isLoadingNext) return
    loadNext(12)
  }, [hasNext, isLoadingNext, loadNext])

  const doLoadMore = useCallback(() => !isLoadingNext && loadMoreEvents(), [isLoadingNext, loadMoreEvents])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'initial'
    }
  })

  const validEdges = useMemo(() => filter('node', viewer.events?.edges || []), [viewer.events?.edges])
  return (
    <ResultsOverlay ref={scrollParent}>
      <ResultsOverlayContent>
        {validEdges && validEdges.length ? (
          <>
            <InfiniteScroll
              loadMore={doLoadMore}
              hasMore={hasNext}
              element={DashboardEventCardList}
              useWindow={false}
              getScrollParent={getParent}
              threshold={50}
            >
              {validEdges.map(
                (edge) =>
                  edge?.node && (
                    <DashboardEventCard key={edge.node.id}>
                      <DashboardEventCardInfo event={edge.node} />
                    </DashboardEventCard>
                  )
              )}
            </InfiniteScroll>
            {isLoadingNext && (
              <LoadingMore>
                <Loader />
              </LoadingMore>
            )}
            {!isLoadingNext && hasNext && (
              <Button onClick={loadMoreEvents} className="block mt-lg mb-lg ml-auto mr-auto">
                {intl.formatMessage({ id: 'actions.load_more' })}
              </Button>
            )}
          </>
        ) : (
          <EmptyState icon="search" title={intl.formatMessage({ id: 'select.no_results' })} />
        )}
      </ResultsOverlayContent>
    </ResultsOverlay>
  )
}

export default memo(SearchResults)
