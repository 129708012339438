import React, { FC, useCallback, useContext, useMemo, HTMLProps, Suspense } from 'react'
import { isPast, parseISO } from 'date-fns'
import { compact, find, getOr, map } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { DeepReadonly } from 'ts-essentials'
import styled from 'styled-components/macro'
import { useRelayEnvironment } from 'react-relay'

import { notificationContext } from '../../../../context/notification'
import { trackingContext } from '../../../../context/tracking'
import { breakpoints, spacing } from '../../../../utils/variables'
import unwrapId from '../../../../utils/unwrapId'

import useBreakdownState from '../../hooks/useBreakdownState'
import saveTicketType from '../../services/saveTicketType'
import hideUnhideTicketType from '../../services/hideUnhideTicketType'

import { OnDesktop } from '../../../../components/Breakpoints'
import { Dropdown, DropdownContent, DropdownTrigger } from '../../../../components/Dropdown'
import IconButton from '../../../../components/IconButton'
import { Menu, MenuItem } from '../../../../components/Menu'

import ChangeAllocationModal from '../../../EventList/components/Modals/ChangeAllocationModal'
import EventTicketTypeModal from '../../../EventForm/components/EventTicketTypeModal'
import { ITicketType } from '../../../EventForm/types/Tickets'

import { TicketBreakdown_event$data } from '../../../../__generated__/TicketBreakdown_event.graphql'
import { Loader, LoaderContainer } from '../../../../components/Loader'
import { AlertModal } from '../../../../components/AlertModal'
import { authContext } from '../../../../context/auth'
import DiceBadge from '../../../../components/DiceBadge'
import ChangeTicketSalesLimitModal from './ChangeTicketSalesLimitModal'

export const Actions = styled.div<HTMLProps<HTMLSpanElement> & { disabled: boolean }>`
  position: absolute;
  right: 0;
  top: ${spacing.xs}px;

  && {
    opacity: 1;
  }

  flex: 1;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
    && {
      opacity: .25;
    }
  `}

  ${IconButton} {
    margin-left: ${spacing.sm}px;
  }
`

interface IProps {
  event: TicketBreakdown_event$data
  ticketType: NonNullable<NonNullable<NonNullable<TicketBreakdown_event$data['sales']>['ticketTypesBreakdown']>[number]>
}

const TicketActions: FC<React.PropsWithChildren<IProps>> = ({ event, ticketType: tt }) => {
  const intl = useIntl()
  const { user } = useContext(authContext)
  const { addNotification } = useContext(notificationContext)
  const { trackEvent } = useContext(trackingContext)
  const environment = useRelayEnvironment()

  const {
    dropdownId,
    closeDropdown,
    toggleDropdown,
    allocationId,
    closeAllocation,
    doChangeAllocation,
    editId,
    closeEdit,
    doEdit,
    salesLimitId,
    closeSalesLimit,
    doSalesLimit,
    problemAlert,
    closeProblemAlert,
  } = useBreakdownState(event.id, event.eventIdLive)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const ttys = useMemo(() => compact(event.sales?.ticketTypesBreakdown || []), [event.sales?.ticketTypesBreakdown])

  const isOffSale = useMemo(
    () => (event.offSaleDate ? isPast(parseISO(event.offSaleDate)) : false),
    [event.offSaleDate]
  )

  const saveTicketTypeCallback = useCallback(
    async (ticketType: ITicketType) => {
      try {
        await saveTicketType(environment, event, ticketType as DeepReadonly<ITicketType>, user.diceStaff)

        closeEdit()
        closeSalesLimit()
      } catch (e) {
        console.error(e)
        closeEdit()
        closeSalesLimit()
        addNotification('error', intl.formatMessage({ id: 'event_overview.ticket_breakdown.tty_save_error' }))
      }
    },
    [addNotification, closeEdit, closeSalesLimit, environment, event, intl, user.diceStaff]
  )

  const doToggleHideTicketType = useCallback(
    async (e: any) => {
      closeDropdown()

      try {
        const id = e.currentTarget.dataset['id']
        const hidden = e.currentTarget.dataset['hidden'] === 'true'

        const ttList = map('ticketType', ttys)

        await hideUnhideTicketType(
          environment,
          event.id,
          id,
          hidden,
          ttList as DeepReadonly<typeof ttList>,
          event.lockVersion
        )

        trackEvent('ticket_type_displayed', {
          event_id: unwrapId(event.id),
          event_id_live: event.eventIdLive,
          ticket_type_id: unwrapId(id),
          user_selection_bool: hidden,
        })
      } catch (e) {
        console.error(e)
        addNotification('error', intl.formatMessage({ id: 'event_overview.ticket_breakdown.tty_hide_error' }))
      }
    },
    [
      addNotification,
      closeDropdown,
      environment,
      event.eventIdLive,
      event.id,
      event.lockVersion,
      intl,
      trackEvent,
      ttys,
    ]
  )

  const editedTicketType = useMemo(() => find(['ticketType.id', editId], ttys)?.ticketType, [editId, ttys])

  const onEditSalesLimit = useCallback(
    (e: any) => {
      closeDropdown()
      doSalesLimit(e)
    },
    [closeDropdown, doSalesLimit]
  )
  const onEdit = useCallback(
    (e: any) => {
      closeDropdown()
      doEdit(e)
    },
    [closeDropdown, doEdit]
  )

  const isSeated = getOr(false, 'flags.seated.active', event) && !!tt.ticketType.reservedSeating

  const pooled = !!tt.ticketType.ticketPoolId

  const canManageTickets = !!event.allowedActions?.manageTickets

  return (
    <Actions disabled={isOffSale}>
      {!isSeated && !pooled && canManageTickets && tt.ticketType?.priceTierType !== 'allocation' && (
        <OnDesktop>
          <IconButton
            data-id={tt.ticketType.id}
            icon="ticket"
            outlineColor={tt.ticketType.archived ? 'tertiary' : null}
            onClick={doChangeAllocation}
            title={intl.formatMessage({
              id: 'event_overview.ticket_breakdown.actions.change_allocation',
            })}
          />
        </OnDesktop>
      )}
      <Dropdown active={dropdownId === tt.ticketType.id} onClickOutside={closeDropdown}>
        <DropdownTrigger data-id={tt.ticketType.id} onClick={toggleDropdown}>
          <IconButton icon="more" color={isMobile && 'lightgrey'} />
        </DropdownTrigger>
        <DropdownContent active={dropdownId === tt.ticketType.id}>
          <Menu>
            {!isSeated && !pooled && canManageTickets && tt.ticketType?.priceTierType !== 'allocation' && (
              <MenuItem data-id={tt.ticketType.id} onClick={doChangeAllocation}>
                {tt.ticketType.archived && <DiceBadge />}
                {intl.formatMessage({
                  id: 'event_overview.ticket_breakdown.actions.change_allocation',
                })}
              </MenuItem>
            )}
            {!tt.ticketType.archived && (
              <>
                {pooled && (
                  <MenuItem data-id={tt.ticketType.id} onClick={onEditSalesLimit}>
                    {intl.formatMessage({
                      id: 'event_overview.ticket_breakdown.actions.change_sales_limit',
                    })}
                  </MenuItem>
                )}

                <MenuItem data-id={tt.ticketType.id} onClick={onEdit}>
                  {intl.formatMessage({
                    id: 'event_overview.ticket_breakdown.actions.edit',
                  })}
                </MenuItem>

                <MenuItem
                  data-id={tt.ticketType.id}
                  data-hidden={!!tt.ticketType.hidden}
                  onClick={doToggleHideTicketType}
                >
                  {intl.formatMessage({
                    id: tt.ticketType.hidden
                      ? 'event_overview.ticket_breakdown.actions.unhide'
                      : 'event_overview.ticket_breakdown.actions.hide',
                  })}
                </MenuItem>
              </>
            )}
          </Menu>
        </DropdownContent>
      </Dropdown>
      <Suspense
        fallback={
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        }
      >
        {salesLimitId === tt.ticketType.id && (
          <ChangeTicketSalesLimitModal
            event={event}
            eventId={event.id}
            ttyId={tt.ticketType.id}
            onClose={closeSalesLimit}
            onSave={saveTicketTypeCallback}
          />
        )}
        {editedTicketType?.id === tt.ticketType.id && (
          <EventTicketTypeModal
            event={event}
            ticketTypeId={editedTicketType.id}
            onClose={closeEdit}
            onSave={saveTicketTypeCallback}
          />
        )}
        {allocationId === tt.ticketType.id && (
          <ChangeAllocationModal
            eventId={event.id}
            onClose={closeAllocation}
            preSelectTtyId={allocationId}
            preSelectTierId={null}
          />
        )}
        {problemAlert && (
          <AlertModal
            title={intl.formatMessage({ id: 'active_ticket_error.title' })}
            description={intl.formatMessage({ id: 'active_ticket_error.description' })}
            onClose={closeProblemAlert}
          />
        )}
      </Suspense>
    </Actions>
  )
}

export default TicketActions
