import React, { FC, useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { authContext } from '../../context/auth'
import AcceptInvite from '../../flows/Auth/Parts/AcceptInvite'
import Login from '../../flows/Auth/Parts/Login'
import RecoverPassword from '../../flows/Auth/Parts/RecoverPassword'
import ResetPassword from '../../flows/Auth/Parts/ResetPassword'
import SignupPage from '../../flows/Signup/SignupPage'
import AnonymousLayout from '../../layout/AnonymousLayout'
import AuthenticationLayout from '../../layout/AuthenticationLayout'

const ForceLogout: FC = () => {
  const { logOut } = useContext(authContext)
  useEffect(() => {
    logOut()
  }, [logOut])
  return null
}

const AuthSection = () => (
  <Routes>
    <Route path="*" element={<AnonymousLayout />}>
      <Route path="logout" element={<ForceLogout />} />
      <Route path="signup" element={<SignupPage />} />

      <Route path="*" element={<AuthenticationLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="partner/*" element={<Navigate to="/dashboard" replace />} />

        <Route path="recover" element={<RecoverPassword />} />
        <Route path="recover_partner" element={<Navigate to="recover" replace />} />

        <Route path="password_reset" element={<ResetPassword />} />
        <Route path="accept_invite" element={<AcceptInvite />} />
      </Route>
    </Route>
  </Routes>
)

export default AuthSection
