import { compact, compose, filter, find, flatMap, reject, sumBy } from 'lodash/fp'
import { EventType, PriceTierTypes } from '../enums.generated'
import { ILocale } from '../intl'
import { isItalianEvent, ICountrySpecificEvent } from './isCountryEvent'

interface ITicketType {
  isStream: boolean | null
  archived: boolean | null
  id: string
  allocation: number | null
  priceTierType: PriceTierTypes | null
  priceTiers: ReadonlyArray<{ allocation: number | null } | null> | null
}

interface IEvent extends ICountrySpecificEvent {
  eventType: EventType | null | undefined
  ticketTypes: ReadonlyArray<ITicketType | null> | null | undefined
  venues:
    | ReadonlyArray<{
        value: string
        capacity: number | null
        addressCountry?: string | null
        countryCode?: string | null
      } | null>
    | null
    | undefined
  primaryVenue?: { value: string } | null | undefined
}

const totalAllocationFn: (tickets: ITicketType[]) => number = compose([
  sumBy('allocation'),
  filter((item: { allocation: number | null }) => (item?.allocation || 0) > 0),
  flatMap((tt: ITicketType) => (tt.priceTierType === 'allocation' ? tt.priceTiers : [tt])),
])

const checkOverallocation = (event: IEvent | null | undefined, locale?: ILocale, overrideCapacity?: number) => {
  const { ticketTypes, venues, primaryVenue } = event || {}

  const ttys = compact(reject((tt) => !tt || tt.archived, ticketTypes || [])) as NonNullable<ITicketType>[]

  const streamTtys = filter((tty) => event?.eventType === 'STREAM' || tty.isStream, ttys) as typeof ttys
  const liveTtys = reject((tty) => event?.eventType === 'STREAM' || tty.isStream, ttys) as typeof ttys

  const totalAllocationStream = totalAllocationFn(streamTtys) || 0
  const totalAllocationLive = totalAllocationFn(liveTtys) || 0

  let venueCapacity = !overrideCapacity
    ? (venues?.length === 1 ? venues?.[0] : find(['value', primaryVenue?.value], venues))?.capacity || 0
    : overrideCapacity

  let overallocation = totalAllocationLive - venueCapacity
  let underallocation = venueCapacity - totalAllocationLive

  if (venueCapacity <= 0 || isItalianEvent(event, locale || 'en-GB') || event?.eventType === 'STREAM') {
    venueCapacity = 0
    overallocation = 0
    underallocation = 0
  }

  if (overallocation < 0) {
    overallocation = 0
  }

  if (underallocation < 0) {
    underallocation = 0
  }

  return { overallocation, underallocation, venueCapacity, totalAllocationLive, totalAllocationStream }
}

export default checkOverallocation
