/**
 * @generated SignedSource<<4fedd9a5e61501224b7eb96c35f74368>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketBreakdownPools_event$data = {
  readonly allowedActions: {
    readonly minorUpdate: boolean | null;
  } | null;
  readonly allowedLifecycleUpdates: {
    readonly ticketPools: {
      readonly canChangeAllocation: boolean | null;
      readonly canUpdate: boolean | null;
    } | null;
  } | null;
  readonly doorTickets: ReadonlyArray<{
    readonly doorSalesEnabled: boolean;
    readonly id: string;
  } | null> | null;
  readonly id: string;
  readonly sales: {
    readonly ticketTypesBreakdown: ReadonlyArray<{
      readonly ticketType: {
        readonly archived: boolean | null;
        readonly id: string;
        readonly ticketPoolId: string | null;
      };
      readonly totalPosSold: number;
      readonly totalSold: number;
    } | null>;
  } | null;
  readonly ticketPools: ReadonlyArray<{
    readonly id: string;
    readonly maxAllocation: number | null;
    readonly name: string | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"TicketBreakdown_event" | "TicketPoolSalesProgress_event" | "TicketPoolSales_event">;
  readonly " $fragmentType": "TicketBreakdownPools_event";
};
export type TicketBreakdownPools_event$key = {
  readonly " $data"?: TicketBreakdownPools_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketBreakdownPools_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketBreakdownPools_event",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketBreakdown_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketPoolSalesProgress_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TicketPoolSales_event"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedActions",
      "kind": "LinkedField",
      "name": "allowedActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "doorTickets",
      "args": [
        {
          "kind": "Literal",
          "name": "doorSalesOnly",
          "value": true
        }
      ],
      "concreteType": "TicketType",
      "kind": "LinkedField",
      "name": "ticketTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doorSalesEnabled",
          "storageKey": null
        }
      ],
      "storageKey": "ticketTypes(doorSalesOnly:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedLifecycleUpdates",
      "kind": "LinkedField",
      "name": "allowedLifecycleUpdates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AllowedLifecycleUpdatesTicketPools",
          "kind": "LinkedField",
          "name": "ticketPools",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canChangeAllocation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canUpdate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketPool",
      "kind": "LinkedField",
      "name": "ticketPools",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sales",
      "kind": "LinkedField",
      "name": "sales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketTypeBreakdown",
          "kind": "LinkedField",
          "name": "ticketTypesBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalPosSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketType",
              "kind": "LinkedField",
              "name": "ticketType",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ticketPoolId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "0e3233ea9783599e3d7c44562c6e38c3";

export default node;
