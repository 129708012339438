import React, { FC, memo, useContext, useMemo } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useLazyLoadQuery } from 'react-relay'
import styled from 'styled-components/macro'
import graphql from 'babel-plugin-relay/macro'
import { compose, find, get } from 'lodash/fp'
import { differenceInDays, parseISO } from 'date-fns'

import { PinnedEventQuery } from '../../../../__generated__/PinnedEventQuery.graphql'
import { ListTitle } from '../../DashboardEvents'
import { color, font, mediaQuery } from '../../../../utils/variables'
import IconButton from '../../../../components/IconButton'
import { DATETIME_FORMATS } from '../../../../utils/formatters/datetime'
import { localeContext } from '../../../../context/locale'

const Container = styled.div`
  padding: 32px;

  ${ListTitle} {
    margin: 0 0 16px 0;
  }

  ${mediaQuery.lessThan('tablet')`
    padding: 16px;
  `}
`

const EventImage = styled.img`
  margin-right: 16px;
  border-radius: 2px;

  width: 64px;
  height: 64px;
`

const EventInfo = styled.div`
  margin: auto 0;
  letter-spacing: 0.02em;
  font-size: ${font.size.sm}px;

  & > strong {
    display: block;
    letter-spacing: normal;
    margin-bottom: 3px;
    font-size: ${font.size.base}px;
  }

  &:hover > strong {
    color: ${color.primary};
  }
`

const PinContainer = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  cursor: pointer;

  max-width: 50%;

  padding: 8px;

  border-radius: 6px;
  border: 1px solid ${color.grey};

  padding-right: 48px;

  ${IconButton} {
    position: absolute;
    right: 2px;
    top: 2px;
  }

  &:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.08);
  }

  ${mediaQuery.lessThan('tablet')`
    max-width: unset;
  `}
`

const EventDate = styled.span`
  color: ${color.primary};
  text-transform: capitalize;
  margin-right: 8px;

  ${mediaQuery.lessThan('tablet')`
    display: block;
  `}
`

const formatEventDateRel = (intl: IntlShape, d: string | null) => {
  if (!d) return null
  const now = new Date()
  const then = parseISO(d)
  const diffDays = differenceInDays(then, now)
  return intl.formatRelativeTime(diffDays, 'day', { numeric: 'auto', style: 'long' })
}

interface IProps {
  eventId: string
  onSwitchView: () => void
  onUnpin: () => void
}

const PinnedEvent: FC<React.PropsWithChildren<IProps>> = ({ eventId, onSwitchView, onUnpin }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)

  const { event } = useLazyLoadQuery<PinnedEventQuery>(
    graphql`
      query PinnedEventQuery($id: ID!) {
        event: node(id: $id) {
          ... on Event {
            id
            name
            date
            endDate
            timezoneName
            eventImages {
              type
              cdnUrl
            }
          }
        }
      }
    `,
    { id: eventId },
    { fetchPolicy: 'store-or-network' }
  )

  const imgUrl = useMemo(
    () =>
      compose([(url) => (url ? url + '&h=64&w=64&auto=compress' : undefined), get('cdnUrl'), find(['type', 'square'])])(
        event?.eventImages
      ),
    [event]
  )

  return (
    event && (
      <Container>
        <ListTitle>{intl.formatMessage({ id: 'dashboard.pinned_event' })}</ListTitle>
        <PinContainer onClick={onSwitchView}>
          {imgUrl && <EventImage src={imgUrl} alt="Event pic" />}
          <EventInfo>
            <strong>{event.name}</strong>
            {event.date && (
              <div>
                <EventDate>{formatEventDateRel(intl, event.date)}</EventDate>
                <span>
                  {intl.formatDate(parseISO(event.date), {
                    ...DATETIME_FORMATS.TIME(locale),
                    timeZone: event.timezoneName,
                  })}
                  {' – '}
                  {event.endDate &&
                    intl.formatDate(parseISO(event.endDate), {
                      ...DATETIME_FORMATS.TIME(locale),
                      timeZone: event.timezoneName,
                    })}
                </span>
              </div>
            )}
          </EventInfo>
          <IconButton
            icon="unpin"
            onClick={onUnpin}
            title={intl.formatMessage({ id: 'event_list.quick_actions.unpin_event' })}
          />
        </PinContainer>
      </Container>
    )
  )
}

export default memo(PinnedEvent)
