import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { capitalize, compact, compose, filter, find, groupBy, map, orderBy } from 'lodash/fp'
import Fuse from 'fuse.js'
import { SalesforceContractStatus } from '../enums.generated'
import { DATETIME_FORMATS } from '../utils/formatters/datetime'
import { getSalesforceContractUrl, getSalesforceStatusColor } from '../utils/salesforce'
import { textStyle } from '../utils/typography'
import { color, mediaQuery } from '../utils/variables'
import Badge from './Badge'
import { FilterSearch, FilterSearchInput, SearchIcon } from './FilterStyles'
import IconButton from './IconButton'
import InfoField from './InfoField'
import { Modal, ModalBody, ModalFooter, ModalFooterControl, ModalDialog } from './Modal'
import Radio from './Radio'
import Table, { Cell, HeaderCell, Row, TableBody, TableFooter, TableHeader } from './Table'
import { TabMenu, TabMenuItem } from './TabMenu'
import Collapsible from './Collapsible'
import useRendered from '../utils/useRendered'

const Title = styled.span`
  ${textStyle.heading.sm}
  margin: 0;
`

const SFilterSearch = styled(FilterSearch)`
  border-bottom: 1px solid ${color.lightgrey};
  padding: 16px 32px;
  margin: 0 -32px;

  ${IconButton} {
    height: 24px;
    width: 24px;
    max-width: 24px;
  }

  ${mediaQuery.lessThan('tablet')`
    padding: 16px 20px;
    margin: 0 -20px;
  `}
`

const SModal = styled(Modal)`
  ${ModalDialog} {
    width: 980px;
  }

  ${mediaQuery.lessThan('tablet')`
    ${ModalDialog} {
      width: unset;
    }
  `}
`

const STable = styled(Table)`
  width: 100%;

  th {
    font-weight: bold;
    border-bottom: 1px solid ${color.lightgrey};
    padding-top: 16px;
    padding-bottom: 16px;
  }

  td,
  th {
    vertical-align: middle;
    min-width: unset;
    width: 160px;
    padding-left: 24px;
  }

  td.-wide,
  th.-wide {
    width: unset;
  }

  td.-mid,
  th.-mid {
    width: 130px;
  }

  td.-short,
  th.-short {
    padding-left: 0;
    padding-right: 0;

    width: 40px;
    min-width: 40px;

    + td,
    + th {
      padding-left: 8px;
    }
  }

  td:first-child {
    padding-right: 16px;
  }

  td:last-child {
    width: 40px;
    padding: 0;
  }

  ${mediaQuery.lessThan('tablet')`
    td, th {
      display: none;
    }

    td.-mobile, th.-mobile {
      display: table-cell;
    }
  `}
`

const SModalBody = styled(ModalBody)`
  && {
    max-width: 980px;
    min-height: 60vh;
  }

  ${mediaQuery.lessThan('tablet')`
    && {
      max-width: unset;
      min-height: unset;
    }
  `}
`

const SModalFooter = styled(ModalFooter)`
  && {
    padding: 16px 32px;
  }

  ${mediaQuery.lessThan('tablet')`
    && {
      padding: 16px;
    }
  `}
`

const SRow = styled(Row)`
  td {
    cursor: pointer;
  }

  td.-no-finger {
    cursor: unset;
  }
`

const SInfoField = styled(InfoField)`
  display: inline-block;
  margin-top: -5px;
`

const STabMenu = styled(TabMenu)`
  margin-top: 32px;
  margin-bottom: 16px;
`

const SCell = styled(Cell)`
  &&& {
    padding: 24px;
  }
`

const SortableHeader = styled(HeaderCell)<{ direction: 'asc' | 'desc' }>`
  font-weight: bold;
  cursor: pointer;
  user-select: none;

  color: ${color.primary};
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -2px;
    width: 0px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid currentColor;
    transform: ${({ direction }) => (direction === 'desc' ? 'rotate(180deg)' : 'none')};
  }
`

const SCollapsible = styled(Collapsible)`
  & > * {
    margin: 16px 0;
  }

  & button {
    ${textStyle.interactive.sm}
  }
`

const Clamp = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`

const Placeholder = styled.div`
  height: 20px;
`

export interface IContract {
  id: string
  sfId: string | null
  sfAccountId: string | null
  name: string | null
  num: string | null
  opportunityName: string | null
  startDate: string | null
  status: SalesforceContractStatus | null
}

interface IProps {
  accountId: string
  parentAccountId: string | null

  selectedId: string | null
  contracts: null | ReadonlyArray<IContract | null>

  showExpired?: boolean

  onClose: () => void
  onSelect: (contract: IContract | null) => void
}

const stopPropagation = (e: any) => {
  e.stopPropagation()
}

interface IRowsProps {
  selectedId: string | null
  setSelectedId: (id: string | null) => void
  contracts: IContract[]
}

const ContractRows: FC<IRowsProps> = ({ selectedId, setSelectedId, contracts }) => {
  const intl = useIntl()

  const changeSelected = useCallback(
    (e: any) => {
      const id = e.target.value
      setSelectedId(id || null)
    },
    [setSelectedId]
  )

  const rowClick = useCallback(
    (e: any) => {
      const id = e.currentTarget.dataset['id']
      setSelectedId(id || null)
    },
    [setSelectedId]
  )

  return (
    <>
      {contracts.map(
        (it) =>
          it && (
            <SRow key={it.id} data-id={it.id} onClick={rowClick}>
              <Cell className="-short -mobile">
                <Radio name="contractId" value={it.id} checked={it.id === selectedId} onChange={changeSelected} />
              </Cell>
              <Cell wrapText data-id="name" className="-wide -mobile">
                <Clamp>{it.name || it.opportunityName || intl.formatMessage({ id: 'na' })}</Clamp>
              </Cell>
              <Cell data-id="num" className="-mid -mobile -no-finger" onClick={stopPropagation}>
                <SInfoField value={it.num} />
              </Cell>
              <Cell data-id="startDate">
                {it.startDate
                  ? intl.formatDate(it.startDate, DATETIME_FORMATS.MEDIUM)
                  : intl.formatMessage({ id: 'na' })}
              </Cell>
              <Cell className="-mid" data-id="status">
                <Badge color={getSalesforceStatusColor(it.status)}>
                  {intl.formatMessage({
                    id: `promoter_form.salesforce_integration.contract_status.options.${(
                      it.status || 'unknown'
                    )?.toLowerCase()}`,
                    defaultMessage: capitalize(it.status || 'unknown'),
                  })}
                </Badge>
              </Cell>
              <Cell className="-short -no-finger" onClick={stopPropagation}>
                <IconButton
                  data-id="salesforceLink"
                  href={getSalesforceContractUrl(it.sfId)}
                  target="_blank"
                  icon="open"
                />
              </Cell>
            </SRow>
          )
      )}
    </>
  )
}

const SalesforceContractsModal: FC<IProps> = ({
  selectedId: preSelectedId,
  contracts,
  onClose,
  onSelect,
  accountId,
  parentAccountId,
  showExpired,
}) => {
  const intl = useIntl()

  const [selectedId, setSelectedId] = useState(preSelectedId)

  const doSelect = useCallback(() => {
    const selected = find((it) => it?.id === selectedId, contracts) || null
    onSelect(selected)
  }, [contracts, onSelect, selectedId])

  const [term, setTerm] = useState<string | null>(null)
  const changeTerm = useCallback((e: any) => setTerm(e.target.value), [])
  const clearTerm = useCallback(() => setTerm(null), [])

  const [currentAccountId, setCurrentAccountId] = useState(
    () => (selectedId ? find((it) => it?.id === selectedId, contracts)?.sfAccountId : null) || accountId
  )
  const changeCurrentAccount = useCallback((e: any) => {
    const id = e.currentTarget.value
    setCurrentAccountId(id)
  }, [])

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const toggleSort = useCallback(() => setSortOrder((v) => (v === 'asc' ? 'desc' : 'asc')), [])

  const searchIndex: Fuse<IContract> = useMemo(
    () =>
      new Fuse(compact(contracts || []) as IContract[], {
        threshold: 0,
        shouldSort: false,
        ignoreLocation: true,
        isCaseSensitive: false,
        keys: [
          { name: 'combinedName', getFn: (it) => it.name || it.opportunityName || intl.formatMessage({ id: 'na' }) },
          'num',
        ],
      }),
    [contracts, intl]
  )

  const visibleContracts = useMemo(
    () =>
      compose([
        groupBy((it: IContract) => (it.status !== 'CANCELLED' && it.status !== 'EXPIRED' ? 'alive' : 'expired')),
        orderBy(['startDate'], [sortOrder]),
        filter((it: IContract) => it.sfAccountId === currentAccountId),
      ])(term ? map('item', searchIndex.search(term)) : compact(contracts || [])) as {
        [k in 'alive' | 'expired']: IContract[] | undefined
      },
    [contracts, currentAccountId, searchIndex, sortOrder, term]
  )

  const [aliveCollapsed, setAliveCollpased] = useState(false)
  const [aliveExpanded, AliveSpy] = useRendered()
  useEffect(() => setAliveCollpased(!aliveExpanded), [aliveExpanded])

  const [expiredCollapsed, setExpiredCollpased] = useState(() => {
    const preSelectedStatus = find((it) => it?.id === selectedId, contracts)?.status
    return !(preSelectedStatus === 'EXPIRED' || preSelectedStatus === 'CANCELLED')
  })
  const [expiredExpanded, ExpiredSpy] = useRendered()
  useEffect(() => setExpiredCollpased(!expiredExpanded), [expiredExpanded])

  useEffect(() => {
    if (!currentAccountId) return
    setTimeout(() => {
      setAliveCollpased(false)
      setExpiredCollpased((term?.length || 0) === 0)
    }, 0)
  }, [currentAccountId, term])

  return (
    <SModal modalTitle={<Title>{intl.formatMessage({ id: 'salesforce_contract_modal.title' })}</Title>}>
      <SModalBody>
        <SFilterSearch>
          <SearchIcon icon="search" />
          <FilterSearchInput
            name="search"
            placeholder={intl.formatMessage({ id: 'salesforce_contract_modal.search' })}
            value={term || ''}
            onChange={changeTerm}
          />
          {term && <IconButton icon="close-view" onClick={clearTerm} />}
        </SFilterSearch>
        {parentAccountId ? (
          <STabMenu>
            <TabMenuItem value={accountId} active={accountId === currentAccountId} onClick={changeCurrentAccount}>
              {intl.formatMessage({ id: 'salesforce_contract_modal.account_options.current' })}
            </TabMenuItem>
            <TabMenuItem
              value={parentAccountId}
              active={parentAccountId === currentAccountId}
              onClick={changeCurrentAccount}
            >
              {intl.formatMessage({ id: 'salesforce_contract_modal.account_options.parent' })}
            </TabMenuItem>
          </STabMenu>
        ) : (
          <Placeholder />
        )}
        <STable>
          <TableHeader>
            <Row>
              <HeaderCell className="-short -mobile">&nbsp;</HeaderCell>
              <HeaderCell className="-wide -mobile">
                {intl.formatMessage({ id: 'promoter_form.salesforce_integration.default_contract.opportunity.label' })}
              </HeaderCell>
              <HeaderCell className="-mid -mobile">
                {intl.formatMessage({ id: 'promoter_form.salesforce_integration.default_contract.number.label' })}
              </HeaderCell>
              <SortableHeader direction={sortOrder} data-sort={sortOrder} onClick={toggleSort}>
                {intl.formatMessage({ id: 'promoter_form.salesforce_integration.default_contract.start_date.label' })}
              </SortableHeader>
              <HeaderCell className="-mid">
                {intl.formatMessage({ id: 'promoter_form.salesforce_integration.default_contract.status.label' })}
              </HeaderCell>
              <HeaderCell className="-short">&nbsp;</HeaderCell>
            </Row>
          </TableHeader>
          {(visibleContracts.alive?.length || 0) > 0 && (
            <TableBody>
              {showExpired && (
                <Row>
                  <Cell colSpan={6}>
                    <SCollapsible
                      key={aliveCollapsed ? 'a' : 'b'}
                      label={intl.formatMessage({ id: 'salesforce_contract_modal.sections.alive' })}
                      initialCollapsed={aliveCollapsed}
                    >
                      <AliveSpy />
                    </SCollapsible>
                  </Cell>
                </Row>
              )}
              {!aliveCollapsed && (
                <ContractRows
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  contracts={visibleContracts.alive!}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                />
              )}
            </TableBody>
          )}
          {showExpired && (visibleContracts.expired?.length || 0) > 0 && (
            <TableBody>
              <Row>
                <Cell colSpan={6}>
                  <SCollapsible
                    key={expiredCollapsed ? 'a' : 'b'}
                    label={intl.formatMessage({ id: 'salesforce_contract_modal.sections.expired' })}
                    initialCollapsed={expiredCollapsed}
                  >
                    <ExpiredSpy />
                  </SCollapsible>
                </Cell>
              </Row>
              {!expiredCollapsed && (
                <ContractRows
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  contracts={visibleContracts.expired!}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                />
              )}
            </TableBody>
          )}
          {(visibleContracts.alive?.length || 0) + (showExpired ? visibleContracts.expired?.length || 0 : 0) === 0 && (
            <TableFooter>
              <Row>
                <SCell textAlign="center" colSpan={6}>
                  {intl.formatMessage({
                    id: term ? 'salesforce_contract_modal.nothing_found' : 'salesforce_contract_modal.empty',
                  })}
                </SCell>
              </Row>
            </TableFooter>
          )}
        </STable>
      </SModalBody>
      <SModalFooter>
        <ModalFooterControl onClick={doSelect}>
          {intl.formatMessage({ id: 'promoter_form.salesforce_integration.no_default_contract.cta' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" onClick={onClose}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </SModalFooter>
    </SModal>
  )
}

export default memo(SalesforceContractsModal)
