import { FormikErrors } from 'formik'
import { compact, map } from 'lodash/fp'
import * as Sentry from '@sentry/react'

import getDeepKeys from './getDeepKeys'

const scrollToTopError = (errors: FormikErrors<any>) => {
  const errorFields = getDeepKeys(errors)

  const inputs = map((f) => document.querySelectorAll(`input[name="${f}"]`), errorFields)
  const textareas = map((f) => document.querySelectorAll(`textarea[name="${f}"]`), errorFields)
  const dataElements = map((f) => document.querySelectorAll(`*[data-name="${f}"]`), errorFields)
  const nodeLists = compact([...inputs, ...textareas, ...dataElements])

  let min: HTMLElement | null = null

  nodeLists.forEach((list) => {
    list.forEach((el) => {
      let myScrollTop = el.getBoundingClientRect().top

      let parent = null
      if (!myScrollTop) {
        parent = el.parentElement
        while (parent !== null && !myScrollTop) {
          myScrollTop = parent.getBoundingClientRect().top
          parent = parent.parentElement
        }
      }

      if (!min || min.getBoundingClientRect().top > myScrollTop) {
        const candidate = (parent || el) as HTMLElement
        if (
          !candidate.getAttribute('disabled') &&
          candidate.dataset['disabled'] !== 'true' &&
          !candidate.classList.contains('-disabled') &&
          !(candidate as HTMLInputElement).disabled
        ) {
          min = candidate
        }
      }
    })
  })

  if (min) {
    const dist = (min as any as HTMLElement).getBoundingClientRect().top - window.innerHeight / 2

    if (Math.abs(dist) < window.innerHeight / 8) return

    window.scrollBy({
      left: 0,
      top: dist,
      behavior: 'smooth',
    })

    Sentry.addBreadcrumb({
      category: 'autoscroll',
      message: `Scroll to first error ${
        (min as HTMLElement).dataset['name'] || (min as HTMLInputElement).name || 'unknown'
      }`,
      data: { errors, element: (min as HTMLElement).dataset['name'] || (min as HTMLInputElement).name || 'unknown' },
      level: 'info',
    })
  }
}

export default scrollToTopError
