import { useMemo } from 'react'
import { FormikErrors } from 'formik'
import { compose, concat, filter, get, map, omit, uniq } from 'lodash/fp'
import { useIntl } from 'react-intl'
import IEventForm from '../types'
import { EventType } from '../../../enums.generated'

interface IErrorRow {
  label: string
  field:
    | keyof FormikErrors<Partial<IEventForm>>
    | 'genres'
    | 'attractiveFields.siaeGenreType'
    | 'attractiveFields.entertainmentPercent'
    | 'flags.enabledPwl.deadline'
    | 'flags.enabledPwl.active'
    | 'innerTicketTypesErrors'
    | 'trailerUrl'
}

const FIELDS: (eventType: EventType | null) => Array<IErrorRow> = (eventType) => [
  { label: 'basics.name', field: 'name' },
  {
    label: 'basics.event_type',
    field: 'eventType',
  },
  {
    label: eventType === 'STREAM' ? 'basics.streaming_from' : 'basics.venue',
    field: 'venueName',
  },
  {
    label: eventType === 'STREAM' ? 'basics.streaming_from' : 'basics.venue',
    field: 'primaryVenue',
  },
  {
    label: eventType === 'STREAM' ? 'basics.streaming_from' : 'basics.venue',
    field: 'venues',
  },
  {
    label: 'basics.artists',
    field: 'eventArtists',
  },
  {
    label: 'timeline.start',
    field: 'date',
  },
  {
    label: 'timeline.end',
    field: 'endDate',
  },
  {
    label: 'tickets.transfer_deadline',
    field: 'closeEventDate',
  },
  {
    label: 'tickets.flags.pwl_enabled',
    field: 'flags.enabledPwl.deadline',
  },
  {
    label: 'tickets.flags.pwl_enabled',
    field: 'flags.enabledPwl.active',
  },
  {
    label: 'timeline.announced',
    field: 'announceDate',
  },
  {
    label: 'timeline.on_sale',
    field: 'onSaleDate',
  },
  {
    label: 'timeline.off_sale',
    field: 'offSaleDate',
  },
  {
    label: 'review_details.line_up',
    field: 'lineup',
  },
  {
    label: 'review_details.recurring',
    field: 'recurrentEventSchedule',
  },
  { label: 'review_details.ticket_limit', field: 'maxTicketsLimit' },
  {
    label: 'review_details.ticketing',
    field: 'ticketTypes',
  },
  { label: 'review_details.ticketing', field: 'innerTicketTypesErrors' },
  { label: 'tickets.pwl_windows', field: 'waitingListExchangeWindows' },
  { label: 'information.description', field: 'description' },
  { label: 'information.presented_by', field: 'presentedBy' },
  { label: 'information.age_limit', field: 'ageLimit' },
  {
    label: 'settings.doorlist',
    field: 'doorlistRecipients',
  },
  { label: 'settings.guest_access', field: 'eventSharingObjects' },
  { label: 'settings.billing_account', field: 'billingPromoter' },
  { label: 'settings.billing_account', field: 'promoters' },
  { label: 'basics.genre', field: 'genres' },
  { label: 'basics.images', field: 'eventImages' },
  { label: 'information.tags', field: 'hierarchicalTags' },
  { label: 'tickets.siae_genre_type', field: 'attractiveFields.siaeGenreType' },
  { label: 'tickets.entertainment_percent', field: 'attractiveFields.entertainmentPercent' },
  { label: 'basics.venue_configuration', field: 'venueConfiguration' },
  { label: 'information.covid', field: 'eventRules' },
  { label: 'tickets.on_sale_notification_date', field: 'onSaleNotificationAt' },
  { label: 'information.trailer_url', field: 'trailerUrl' },
  { label: 'timeline.timezone', field: 'timezoneName' },
]

function useEventFormErrors(eventType: EventType | undefined | null, errors: FormikErrors<Partial<IEventForm>>) {
  const intl = useIntl()

  const fields: Array<{
    label: string
    key: string
    value: string | undefined | null
    field: keyof FormikErrors<Partial<IEventForm>>
  }> = useMemo(
    () =>
      compose([
        filter('value'),
        map((f: IErrorRow) => ({
          key: f.field,
          field: f.field,
          label: intl.formatMessage({ id: `new_event.${f.label}.label` }),
          value: get(f.field, errors as any) || '',
        })),
      ])(FIELDS(eventType || null)),
    [errors, intl, eventType]
  )

  const remainingErrors = useMemo(
    () => omit(concat(['attractiveFields', 'flags', 'additionalInfos'], uniq(map('field', fields))), errors),
    [errors, fields]
  )

  return { fields, remainingErrors }
}

export default useEventFormErrors
