import React, { FC, memo, useContext, useMemo, useCallback, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { number, object, string } from 'yup'
import styled from 'styled-components/macro'
import { map, find, compact, isNil, filter } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'
import { useRelayEnvironment } from 'react-relay'
import { parseISO, startOfMinute } from 'date-fns'
import graphql from 'babel-plugin-relay/macro'

import { Form, FormRow } from '../Form'
import FormField, { FlexFormField, IStyledFormField } from '../FormField'
import { Modal, ModalBody, ModalFooter, ModalFooterControl } from '../Modal'
import { localeContext } from '../../context/locale'
import { markAsClientOnly } from '../../utils/entityStatus'
import Radio from '../Radio'
import { breakpoints, color, font, mediaQuery } from '../../utils/variables'
import getCountries, { getAlpha2ByName } from '../../utils/countries'
import { gtDate, ltDate } from '../../utils/calendar'
import { radiusToZoom, suggestCities } from '../../utils/mapbox'
import MapboxMap from '../MapboxMap'
import COUNTRY_BOXES from '../../utils/countryBoxes'
import useFeatured from '../../flows/Featured/hooks/useFeatured'
import graphqlOptionsLoader from '../../utils/graphqlOptionsLoader'
import { DATETIME_FORMATS } from '../../utils/formatters/datetime'
import EventSelector from './EventSelector'
import { ILockSafeEvent } from '../../flows/Featured/hooks/useFeaturedOps'
import { isUSEvent } from '../../utils/isCountryEvent'

const PaddedLabel = styled.div`
  display: inline-block;
  padding-top: 9px;

  ${mediaQuery.lessThan('tablet')`
    padding: 0;
  `}
`

const InlineFormField = styled(FormField)`
  > label {
    display: inline-block;
  }
` as IStyledFormField<'select'>

interface ICounterProps {
  count: number
  maxCount: number
  minCount: number
}

const Counter = styled.div<ICounterProps>`
  position: absolute;
  top: 0;
  right: 0;
  white-space: nowrap;
  font-size: ${font.size.sm}px;
  ${({ count, maxCount, minCount }) => (count < minCount || count > maxCount ? `color: ${color.error}` : undefined)}
`

const Description = styled(FormField)`
  textarea {
    min-height: 122px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
` as IStyledFormField

const Divider = styled.div`
  margin: 32px 0;
  height: 0;
  border-top: 1px solid ${color.lightgrey};
`

const FeaturedSchema = object()
  .shape({
    eventAnnounceDate: string().nullable(),
    eventEndDate: string().nullable(),
    startDate: string().nullable().required(),
    endDate: string().nullable().required(),
    mode: string().nullable().required(),
    locationString: string().nullable(),
    locationRadius: number().nullable().min(0, ' '),
    locationUnits: string().nullable(),
    countryCode: string().nullable(),
    weight: number().nullable().required().integer().min(0, ' ').max(10, ' '),
    description: string().nullable().max(125, ' '),
  })
  .test('hasCountryCode', 'Should have country code if mode is country', function (values) {
    return values.mode === 'country' && !values.countryCode
      ? this.createError({ path: 'countryCode', message: ' ' })
      : true
  })
  .test('hasLocationString', 'Should have location if mode is location', function (values) {
    return values.mode === 'location' && !values.locationString
      ? this.createError({ path: 'locationString', message: ' ' })
      : true
  })
  .test('hasRadius', 'Should have radius if mode is location', function (values) {
    return values.mode === 'location' && !values.locationRadius
      ? this.createError({ path: 'locationRadius', message: ' ' })
      : true
  })
  .test('hasUnits', 'Should have units if mode is location', function (values) {
    return values.mode === 'location' && !values.locationUnits
      ? this.createError({ path: 'locationUnits', message: ' ' })
      : true
  })
  .test('startDateBeforeEnd', 'Feature start date should be before feature end date', function (values) {
    const valid =
      isNil(values.startDate) ||
      isNil(values.endDate) ||
      startOfMinute(parseISO(values.startDate)) < startOfMinute(parseISO(values.endDate))
    return valid || this.createError({ path: 'startDate', message: 'event_feature_modal.errors.date_after_end' })
  })
  .test('startDateAfterAccounce', 'Feature start date should be after event annouce date', function (values) {
    const valid =
      isNil(values.startDate) ||
      startOfMinute(parseISO(values.startDate)) >= startOfMinute(parseISO(values.eventAnnounceDate))
    return (
      valid ||
      this.createError({ path: 'startDate', message: 'event_feature_modal.errors.start_date_before_event_announce' })
    )
  })
  .test('endDateAfterAccounce', 'Feature end date should be after event annouce date', function (values) {
    const valid =
      isNil(values.endDate) ||
      startOfMinute(parseISO(values.endDate)) >= startOfMinute(parseISO(values.eventAnnounceDate))
    return (
      valid ||
      this.createError({ path: 'endDate', message: 'event_feature_modal.errors.end_date_before_event_announce' })
    )
  })
  .test('startDateBeforeEventEnd', 'Feature start date should be before event end date', function (values) {
    const valid =
      isNil(values.startDate) ||
      startOfMinute(parseISO(values.startDate)) <= startOfMinute(parseISO(values.eventEndDate))
    return valid || this.createError({ path: 'startDate', message: 'event_errors.timeline.start_date_after_event_end' })
  })
  .test('endDateBeforeEventEnd', 'Feature end date should be before event end date', function (values) {
    const valid =
      isNil(values.endDate) || startOfMinute(parseISO(values.endDate)) <= startOfMinute(parseISO(values.eventEndDate))
    return (
      valid || this.createError({ path: 'endDate', message: 'event_feature_modal.errors.end_date_after_event_end' })
    )
  })

export interface IFeaturedArea {
  id: string
  startDate: string | null
  endDate: string | null
  mode: 'location' | 'country' | 'worldwide' | string
  locationString: string | null
  locationRadius: number | null
  locationUnits: 'kilometers' | 'miles' | string | null
  locationLat: number | null
  locationLng: number | null
  countryCode: string | null
  weight: number | null
  description: string | null
}

interface IFeaturedEvent {
  timezoneName: string | null
  announceDate: string | null
  endDate: string | null
  addressCountry: string | null
  countryCode: string | null
  addressLocality: string | null
  latitude: number | null
  longitude: number | null
  addressLocalityLat?: number
  addressLocalityLon?: number
}

const DEFAULT_RADIUS = 5
interface IProps {
  event: IFeaturedEvent | null
  featured?: IFeaturedArea

  readOnly?: boolean

  onClose: () => void
  onSave: (values: IFeaturedArea) => Promise<void>
  onChangeEvent?: (event: ILockSafeEvent) => void
}

const EventFeatureModal: FC<React.PropsWithChildren<IProps>> = ({
  event: preselectedEvent,
  featured,
  onClose,
  onSave,
  onChangeEvent,
  readOnly,
}) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const [event, setEvent] = useState(preselectedEvent || null)

  const isDisabledDate = useMemo(
    () =>
      compact([
        event?.announceDate ? ltDate(event.announceDate, event.timezoneName) : null,
        event?.endDate ? gtDate(event.endDate, event.timezoneName) : null,
      ]),
    [event]
  )

  const defaultUnits = useMemo(() => (isUSEvent(event, locale) ? 'miles' : 'kilometers'), [event, locale])

  const eventLoader = useMemo(
    () =>
      graphqlOptionsLoader(
        environment,
        graphql`
          query EventFeatureModalEventsQuery($searchTerm: String) {
            viewer {
              options: events(searchTerm: $searchTerm, first: 20, scopes: { lifeCycleState: [LIVE] }) {
                edges {
                  node {
                    id
                    name
                    timezoneName
                    announceDate
                    date
                    endDate
                    addressCountry
                    addressLocality
                    latitude
                    longitude
                    lockVersion
                    eventImages {
                      type
                      cdnUrl
                    }
                  }
                }
              }
            }
          }
        `,
        {
          fullText: true,
          postProcess: map((opt) => ({
            ...opt,
            hint: intl.formatDate(opt.hint, { ...DATETIME_FORMATS.DATETIME(locale), timeZone: opt.timezoneName }),
          })),
        }
      ),
    [environment, intl, locale]
  )

  // Get current location/radius from Featured Events screen
  const { location, coordinates, radius } = useFeatured()

  const initialValues = useMemo(
    () =>
      featured ||
      markAsClientOnly<IFeaturedArea>({
        startDate: event?.announceDate || null,
        endDate: event?.endDate || null,
        mode: 'location',
        locationString: event?.addressLocality || location || null,
        locationUnits: !!preselectedEvent ? defaultUnits : radius[0],
        locationRadius: !!preselectedEvent ? DEFAULT_RADIUS : radius[1],
        locationLat: event?.addressLocalityLat || event?.latitude || coordinates?.lat || null,
        locationLng: event?.addressLocalityLon || event?.longitude || coordinates?.lon || null,
        countryCode: null,
        weight: 0,
        description: null,
      }),
    [
      featured,
      event?.announceDate,
      event?.endDate,
      event?.addressLocality,
      event?.addressLocalityLat,
      event?.latitude,
      event?.addressLocalityLon,
      event?.longitude,
      location,
      preselectedEvent,
      defaultUnits,
      radius,
      coordinates?.lat,
      coordinates?.lon,
    ]
  )

  const {
    values,
    setFieldValue,
    isValid,
    touched,
    errors,
    handleSubmit,
    handleReset,
    handleChange,
    handleBlur,
    isSubmitting,
    submitForm,
    validateForm,
  } = useFormik<IFeaturedArea>({
    initialValues,
    onSubmit: onSave,
    validationSchema: FeaturedSchema,
  })

  useEffect(() => {
    // Keeps these hidden fields updated - they're used for validation (see FeaturedSchema)
    setFieldValue('eventAnnounceDate', event?.announceDate)
    setFieldValue('eventEndDate', event?.endDate)
  }, [event?.announceDate, event?.endDate, setFieldValue])

  const setEventField = useCallback(
    (_: any, event: any) => {
      // Reassign value and label fields to id and name
      const { value, label } = event
      const newEvent = {
        id: value,
        name: label,
        ...event,
      }
      setEvent(newEvent)
      if (onChangeEvent) onChangeEvent(newEvent)
    },
    [onChangeEvent]
  )

  const countryOptions = useMemo(
    () =>
      filter(
        (c: any) => !!c.value && !!COUNTRY_BOXES[c.value] && c.value !== 'AQ',
        map((c) => ({ value: c.alpha2, label: c.label }), getCountries(intl, locale))
      ),
    [intl, locale]
  )
  const countryOption = useMemo(
    () => find(['value', values.countryCode], countryOptions),
    [countryOptions, values.countryCode]
  )
  const defaultCountryOption = useMemo(
    () => find(['value', event?.countryCode || getAlpha2ByName(event?.addressCountry, locale)], countryOptions),
    [countryOptions, event?.addressCountry, event?.countryCode, locale]
  )

  const setCountryCode = useCallback((code: string) => setFieldValue('countryCode', code), [setFieldValue])

  const changeMode = useCallback(
    (e: any) => {
      const mode = e.target.value

      if (mode !== 'location') {
        setFieldValue('locationString', null)
        setFieldValue('locationLat', null)
        setFieldValue('locationLng', null)
        setFieldValue('locationRadius', null)
        setFieldValue('locationUnits', 'kilometers')
      } else {
        setFieldValue('locationString', event?.addressLocality || null)
        setFieldValue('locationLat', event?.addressLocalityLat || event?.latitude || null)
        setFieldValue('locationLng', event?.addressLocalityLon || event?.longitude || null)
        setFieldValue('locationRadius', DEFAULT_RADIUS)
        setFieldValue('locationUnits', defaultUnits)
      }

      if (mode !== 'country') {
        setFieldValue('countryCode', null)
      } else {
        setFieldValue('countryCode', defaultCountryOption?.value || null)
      }

      handleChange(e)

      setTimeout(() => validateForm(), 0)
    },
    [
      defaultCountryOption?.value,
      defaultUnits,
      event?.addressLocality,
      handleChange,
      setFieldValue,
      validateForm,
      event?.latitude,
      event?.longitude,
      event?.addressLocalityLat,
      event?.addressLocalityLon,
    ]
  )

  const unitOptions = useMemo(
    () =>
      map(
        (k) => ({ value: k, label: intl.formatMessage({ id: `event_feature_modal.units.options.${k}` }) }),
        ['kilometers', 'miles']
      ),
    [intl]
  )
  const currentUnitOption = useMemo(
    () => find(['value', values.locationUnits], unitOptions),
    [unitOptions, values.locationUnits]
  )
  const onUnitChange = useCallback((id: any) => setFieldValue('locationUnits', id), [setFieldValue])

  const onRadiusChange = useCallback(
    (e: any) => {
      const s = e.target.value
      setFieldValue('locationRadius', s ? Number(s) : null, true)
    },
    [setFieldValue]
  )

  const onWeightChange = useCallback(
    (e: any) => {
      const s = e.target.value
      setFieldValue('weight', s ? Number(s) : null, true)
    },
    [setFieldValue]
  )

  const mapLocationName = values.locationString || countryOption?.label || ''

  const zoom = useMemo(() => {
    if (!mapLocationName) return 0

    switch (values.mode) {
      case 'location':
        if (!values.locationString || !values.locationRadius) return 0

        const z = radiusToZoom(values.locationRadius, (values.locationUnits || 'kilometers') as any)

        return z > 1 ? z - 1 : z
      case 'country':
        return 1
      default:
        return 0
    }
  }, [mapLocationName, values.locationRadius, values.locationString, values.locationUnits, values.mode])

  const locationValue = useMemo(() => {
    const s = values.locationString || event?.addressLocality || ''
    return {
      value: '',
      label: s,
    }
  }, [event?.addressLocality, values.locationString])

  const loadCities = useMemo(() => suggestCities(undefined, locale), [locale])

  const changeLocation = useCallback(
    (_: any, v: any) => {
      setFieldValue('locationString', v.value)
      setFieldValue('locationLat', v.lat)
      setFieldValue('locationLng', v.lon)
    },
    [setFieldValue]
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  return (
    <Modal closeButton modalTitle={intl.formatMessage({ id: 'event_feature_modal.title' })} onClose={onClose}>
      <ModalBody>
        <form noValidate onSubmit={handleSubmit} onReset={handleReset}>
          <Form>
            {!preselectedEvent && (
              <FormRow columnOnMobile>
                <EventSelector
                  name="events"
                  label={intl.formatMessage({ id: 'bundle.events.label' })}
                  placeholder={intl.formatMessage({ id: 'bundle.events.placeholder' })}
                  control="select"
                  async
                  searchable
                  value={event}
                  onChange={setEventField}
                  onBlur={handleBlur}
                  loadOptions={eventLoader}
                  disabled={readOnly}
                />
              </FormRow>
            )}

            <FormRow columnOnMobile>
              <FormField
                name="startDate"
                timezone={event?.timezoneName || undefined}
                label={intl.formatMessage({ id: 'event_feature_modal.start_date.label' })}
                placeholder={intl.formatMessage({ id: 'event_feature_modal.start_date.placeholder' })}
                control="datetime"
                value={values.startDate as string}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                error={touched.startDate && errors.startDate}
                locale={locale}
                disabled={readOnly || !event}
                disabledDates={isDisabledDate}
                required
              />
              <FormField
                name="endDate"
                timezone={event?.timezoneName || undefined}
                label={intl.formatMessage({ id: 'event_feature_modal.end_date.label' })}
                placeholder={intl.formatMessage({ id: 'event_feature_modal.end_date.placeholder' })}
                control="datetime"
                value={values.endDate as string}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                error={touched.endDate && errors.endDate}
                locale={locale}
                disabled={readOnly || !event}
                disabledDates={isDisabledDate}
                required
              />
            </FormRow>
            <Divider />
            <FormRow columnOnMobile>
              <InlineFormField
                name="locationString"
                onBlur={handleBlur}
                error={touched.locationString && errors.locationString}
                value={locationValue}
                control="select"
                searchable
                async
                loadOptions={loadCities || undefined}
                onChange={changeLocation}
                label={
                  <Radio
                    name="mode"
                    value="location"
                    label={intl.formatMessage({ id: 'event_feature_modal.location.label' })}
                    onChange={changeMode}
                    onBlur={handleBlur}
                    checked={values.mode === 'location'}
                    disabled={readOnly || !event}
                    data-id="locationMode"
                  />
                }
                disabled={!loadCities || readOnly || values.mode !== 'location' || !event}
              />
              <FlexFormField
                name="locationRadius"
                value={values.mode !== 'location' ? DEFAULT_RADIUS : values.locationRadius || ''}
                onChange={onRadiusChange}
                onBlur={handleBlur}
                error={touched.locationRadius && errors.locationRadius}
                label={<PaddedLabel>{intl.formatMessage({ id: 'event_feature_modal.radius.label' })}</PaddedLabel>}
                required={values.mode === 'location'}
                disabled={readOnly || values.mode !== 'location' || !event}
                type="number"
                min={0}
                step={1}
              >
                <FormField
                  className="ml-sm"
                  name="locationUnits"
                  control="select"
                  onChange={onUnitChange}
                  onBlur={handleBlur}
                  value={currentUnitOption}
                  options={unitOptions}
                  disabled={readOnly || values.mode !== 'location' || !event}
                  error={touched.locationUnits && errors.locationUnits}
                />
              </FlexFormField>
            </FormRow>
            <FormRow columnOnMobile>
              <InlineFormField
                name="countryCode"
                control="select"
                searchable
                value={values.mode !== 'country' ? defaultCountryOption : countryOption}
                options={countryOptions}
                onChange={setCountryCode}
                onBlur={handleBlur}
                error={touched.countryCode && errors.countryCode}
                label={
                  <Radio
                    name="mode"
                    value="country"
                    label={intl.formatMessage({ id: 'event_feature_modal.country.label' })}
                    onChange={changeMode}
                    onBlur={handleBlur}
                    checked={values.mode === 'country'}
                    disabled={readOnly || !event}
                    data-id="countryMode"
                  />
                }
                disabled={readOnly || values.mode !== 'country' || !event}
              />
            </FormRow>
            <FormRow columnOnMobile>
              <Radio
                name="mode"
                value="worldwide"
                label={<strong>{intl.formatMessage({ id: 'event_feature_modal.worldwide.label' })}</strong>}
                onChange={changeMode}
                onBlur={handleBlur}
                checked={values.mode === 'worldwide'}
                disabled={readOnly || !event}
                data-id="worldwideMode"
              />
            </FormRow>
            <FormRow columnOnMobile>
              <MapboxMap
                bbox={values.countryCode ? COUNTRY_BOXES[values.countryCode] : undefined}
                radiusKm={
                  values.locationUnits === 'miles' ? (values.locationRadius || 0) * 1.60934 : values.locationRadius || 0
                }
                latitude={values.locationLat || 0}
                longitude={values.locationLng || 0}
                zoom={zoom}
                aspect={isMobile ? 0.6 : undefined}
              />
            </FormRow>
            <FormRow columnOnMobile>
              <FormField
                name="weight"
                label={intl.formatMessage({ id: 'event_feature_modal.weight.label' })}
                help={intl.formatMessage({ id: 'event_feature_modal.weight.help' })}
                hint={intl.formatMessage({ id: 'event_feature_modal.weight.hint' })}
                value={isNil(values.weight) ? '' : values.weight}
                onChange={onWeightChange}
                onBlur={handleBlur}
                error={touched.weight && errors.weight}
                disabled={readOnly || !event}
                required
                type="number"
                min={0}
                max={10}
                step={1}
              />
            </FormRow>
          </Form>
        </form>
      </ModalBody>
      <ModalFooter>
        <ModalFooterControl disabled={!isValid || readOnly} data-id="save" loading={isSubmitting} onClick={submitForm}>
          {intl.formatMessage({ id: 'actions.save' })}
        </ModalFooterControl>
        <ModalFooterControl preset="secondary" data-id="cancel" onClick={onClose} disabled={isSubmitting}>
          {intl.formatMessage({ id: 'actions.cancel' })}
        </ModalFooterControl>
      </ModalFooter>
    </Modal>
  )
}

export default memo(EventFeatureModal)
