/**
 * @generated SignedSource<<825cf5edefafa49881ea6ff70f66721e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FeeDestination = "billingPromoter" | "keep" | "%future added value";
export type FeeType = "additionalPromoterFee" | "booking" | "boxOfficeFee" | "charityDonation" | "deposit" | "extraCharge" | "facilityFee" | "paidWaitingList" | "postal" | "presale" | "processing" | "salesTax" | "tierDiff" | "vendor" | "venueFee" | "venueLevy" | "%future added value";
export type FeeUnit = "fixed" | "percentage" | "%future added value";
export type PaymentMethods = "AFTERPAY_CLEARPAY" | "DICE_SPLIT" | "GIROPAY" | "%future added value";
export type PriceTierTypes = "allocation" | "time" | "%future added value";
export type ReservedSeatingTypes = "assignBestSeat" | "selectSeat" | "%future added value";
export type CreateTicketPoolInput = {
  archived?: boolean | null;
  clientMutationId: string;
  maxAllocation?: number | null;
  name?: string | null;
  ticketTypes?: ReadonlyArray<TicketTypesInput | null> | null;
  unlimitedAllocation?: boolean | null;
};
export type TicketTypesInput = {
  activateCodeDateOffset?: number | null;
  additionalPaymentMethods?: ReadonlyArray<PaymentMethods | null> | null;
  allocation?: number | null;
  allowSeatChange?: boolean | null;
  announceDate?: string | null;
  archived?: boolean | null;
  area?: CreateOrUpdateVenueAreaInput | null;
  attractivePriceType?: string | null;
  attractiveSeatingAreaType?: string | null;
  attractiveTaxFree?: boolean | null;
  codeLocked?: boolean | null;
  description?: string | null;
  doorSalesEnabled?: boolean | null;
  doorSalesPrice?: number | null;
  endDate?: string | null;
  externalSkus?: ReadonlyArray<string | null> | null;
  faceValue?: number | null;
  fees?: ReadonlyArray<FeeInput | null> | null;
  hidden?: boolean | null;
  icon?: string | null;
  id?: string | null;
  increment?: number | null;
  isStream?: boolean | null;
  maximumIncrements?: number | null;
  name?: string | null;
  offSaleDate?: string | null;
  onSaleDate?: string | null;
  presale?: boolean | null;
  priceGrade?: string | null;
  priceHidden?: boolean | null;
  priceTierType?: PriceTierTypes | null;
  priceTiers?: ReadonlyArray<PriceTiersInput | null> | null;
  productIds?: ReadonlyArray<string | null> | null;
  requiresAddress?: boolean | null;
  requiresOtherTypeIds?: ReadonlyArray<string | null> | null;
  reservedSeating?: boolean | null;
  reservedSeatingType?: ReservedSeatingTypes | null;
  salesLimit?: number | null;
  seatCategories?: ReadonlyArray<SeatCategoriesInput | null> | null;
  seatmapUrl?: string | null;
  startDate?: string | null;
  streamEmbedCode?: string | null;
  streamLink?: string | null;
  ticketPoolId?: string | null;
  venueScheduleId?: string | null;
  venueScheduleIndex?: number | null;
};
export type CreateOrUpdateVenueAreaInput = {
  code?: string | null;
  id?: string | null;
  name?: string | null;
};
export type FeeInput = {
  active?: boolean | null;
  amount: number;
  split: ReadonlyArray<FeeSplitInput | null>;
  type: FeeType;
  unit: FeeUnit;
};
export type FeeSplitInput = {
  amount: number;
  destination: FeeDestination;
  unit: FeeUnit;
};
export type PriceTiersInput = {
  allocation?: number | null;
  attractivePriceType?: string | null;
  doorSalesPrice?: number | null;
  faceValue?: number | null;
  fees?: ReadonlyArray<FeeInput | null> | null;
  id?: string | null;
  name?: string | null;
  time?: string | null;
};
export type SeatCategoriesInput = {
  id?: string | null;
  name?: string | null;
  seatsIoKey?: string | null;
  value?: number | null;
};
export type createOrUpdateTicketPoolsCreateMutation$variables = {
  input: CreateTicketPoolInput;
};
export type createOrUpdateTicketPoolsCreateMutation$data = {
  readonly createTicketPool: {
    readonly result: {
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};
export type createOrUpdateTicketPoolsCreateMutation = {
  response: createOrUpdateTicketPoolsCreateMutation$data;
  variables: createOrUpdateTicketPoolsCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTicketPoolPayload",
    "kind": "LinkedField",
    "name": "createTicketPool",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TicketPool",
        "kind": "LinkedField",
        "name": "result",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createOrUpdateTicketPoolsCreateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createOrUpdateTicketPoolsCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c17968eb53fee3d8f921598341045823",
    "id": null,
    "metadata": {},
    "name": "createOrUpdateTicketPoolsCreateMutation",
    "operationKind": "mutation",
    "text": "mutation createOrUpdateTicketPoolsCreateMutation(\n  $input: CreateTicketPoolInput!\n) {\n  createTicketPool(input: $input) {\n    result {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "adeed3806c03c99fd3f6e422f38dd8a5";

export default node;
