import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { Navigate, useParams } from 'react-router'
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { Formik, yupToFormErrors } from 'formik'
import { filter, isNil, map, get, find, sortBy } from 'lodash/fp'
import { DeepWritable } from 'ts-essentials'
import {
  EventSubmission_event$data,
  EventSubmission_event$key,
} from '../../__generated__/EventSubmission_event.graphql'

import EventReviewModal from '../../components/EventReviewModal'
import EventIsFreeModal from '../../components/EventIsFreeModal'
import {
  EventSubmission_viewer$data,
  EventSubmission_viewer$key,
} from '../../__generated__/EventSubmission_viewer.graphql'

import useEventSubmission from './hooks/useEventSubmission'

import IEventForm from '../EventForm/types'
import EventSchema from '../EventForm/validation/Event'

import { parseMarkdown } from '../../utils/markdown'
import { authContext } from '../../context/auth'

import EventSubmissionForm from './components/EventSubmissionForm'
import FormBackupSupport, { FormBackuper } from './components/FormBackupSupport'
import { parseAdditionalInfo } from '../EventForm/components/EventAdditionalInfos'
import { useDestinationPromoters } from '../EventForm/hooks/usePromoters'
import { localeContext } from '../../context/locale'
import useRecentlyViewed from '../EventHeader/hooks/useRecentlyViewed'

type ISinglePromoterCandidate = NonNullable<
  NonNullable<NonNullable<EventSubmission_viewer$data['singlePromoterCandidate']>['edges']>[number]
>['node']

interface IEventSubmissionProps {
  event?: EventSubmission_event$key
  viewer: EventSubmission_viewer$key
}

const convertEvent = (evt: EventSubmission_event$data | undefined): IEventForm | undefined => {
  if (!evt) {
    return
  }

  const headliners = filter((eventArtist) => !!eventArtist?.headliner, evt.eventArtists || [])
  const artistForBio =
    evt && evt.eventArtists?.length === 1 && evt.eventArtists[0]
      ? evt.eventArtists[0]
      : headliners.length === 1 && headliners[0]
        ? headliners[0]
        : null

  const mappedFaqs = map(
    (faq) =>
      faq
        ? {
          ...faq,
          bodyDraft: parseMarkdown((faq as any)?.body || ''),
        }
        : null,
    (evt as DeepWritable<EventSubmission_event$data>).faqs || []
  )

  return {
    ...(evt as DeepWritable<EventSubmission_event$data>),
    additionalInfos: parseAdditionalInfo(evt),
    descriptionDraft: parseMarkdown(evt.description),
    faqs: mappedFaqs,
    showArtistDescription: evt.showArtistDescription
      ? evt.showArtistDescription
      : artistForBio?.artist?.description
        ? 'DICE'
        : 'NONE',
    eventArtists: evt.eventArtists
      ? (evt as DeepWritable<EventSubmission_event$data>).eventArtists?.map((ea) =>
        ea
          ? {
            ...ea,
            descriptionDraft: parseMarkdown(ea?.description),
          }
          : null
      ) || []
      : null,
    artistForBio: artistForBio
      ? {
        ...(artistForBio as DeepWritable<NonNullable<typeof artistForBio>>),
        descriptionDraft: parseMarkdown(artistForBio.description),
      }
      : null,
    waitingListExchangeWindows: sortBy((w) => {
      if (isNil(w?.offset)) return -Infinity
      return w?.offset || 0
    }, evt.waitingListExchangeWindows || []) as any as Array<
      null | ({ id: string } & Omit<NonNullable<NonNullable<typeof evt.waitingListExchangeWindows>[number]>, 'id'>)
    > | null,
  }
}

const EventSubmission: FC<React.PropsWithChildren<IEventSubmissionProps>> = ({
  viewer: viewerKey,
  event: eventKey,
}) => {
  const { id: urlId } = useParams<{ id: string }>()
  const { account } = useContext(authContext)
  const { locale } = useContext(localeContext)

  // Unmasked fragments are used to populate formik state
  const event = useFragment(
    graphql`
      fragment EventSubmission_event on Event {
        id
        eventIdLive
        state
        name
        completedSteps

        eventType

        lockVersion
        previewToken

        allowedActions {
          minorUpdate
        }

        ...Basics_event @relay(mask: false)

        ...Timeline_event @relay(mask: false)

        ...Information_event @relay(mask: false)

        ...Tickets_event @relay(mask: false)

        ...Extras_event @relay(mask: false)
        ...Merch_event @relay(mask: false)

        ...Settings_event @relay(mask: false)

        ...EventReviewModal_event
      }
    `,
    eventKey || null
  )

  const viewer = useFragment(
    graphql`
      fragment EventSubmission_viewer on Viewer {
        diceStaff
        dicePartner

        ...usePromoters_viewer

        hierarchicalTags(first: 100, where: { kind: "type" }) {
          edges {
            node {
              value: id
              label: name
              kind
              parent {
                name
              }
            }
          }
        }

        singlePromoterCandidate: promoters(first: 10) {
          count
          edges {
            node {
              value: id
              label: name
              isTest
              displayName
              stripeAccountId
              stripeFallbackAccountId
              stripeFallbackPlatformCode
              platformAccountCode
              showPriceSuggestions
              addressCountry
              countryCode
              accountId
              licenseNumber
              allowSkipReview
              resoldEnabled
              disableUsTax
              holdPayouts
              tags {
                value: id
                label: name
              }
              labels {
                value: id
                label: name
              }
              billingNotes
              fanSupportNotes {
                body
              }
              coolingOffPeriod
              coolingOffPeriodHours
              sendReceiptViaSms

              associatedMarketeers {
                value: id
                label: name
              }

              autoRescheduledEventRefunds {
                active
                cutoffDays
              }

              defaultEventTimings {
                offset
                sourceField
                targetField
                type
              }

              eventDefaults {
                addArtistsToEvent
                disableAttractiveIntegration
                disableDayOfEventComms
                hideFromDiscovery
                manualValidationEnabled
                printedTicketFormat
                requiresBoxOfficeTicketNomination
                restrictCountries
                restrictCountriesKind
                taxFree
                ticketTransfer
                waitingList
              }
              salesforceFields {
                defaultContract {
                  id
                  name
                  num
                  opportunityName
                  sfAccountId
                  sfId
                  startDate
                  status
                }
              }
            }
          }
        }

        ...Basics_viewer
        ...Tickets_viewer
        ...Extras_viewer
        ...Merch_viewer
        ...Settings_viewer
      }
    `,
    viewerKey
  )

  useRecentlyViewed(event?.id || null)

  const singlePromoter: ISinglePromoterCandidate = useMemo(() => {
    if (viewer.singlePromoterCandidate?.count === 1) return get(['edges', 0, 'node'], viewer.singlePromoterCandidate)
    return find((pc) => pc?.node?.accountId === account?.id, viewer.singlePromoterCandidate?.edges || [])?.node || null
  }, [account, viewer.singlePromoterCandidate])

  const destinationPromoters = useDestinationPromoters(viewer)

  const {
    initialValues,
    saveAndContinue,
    reviewModalId,
    onReviewOk,
    closeReviewModal,
    showIsFreeModal,
    closeIsFreeModal,
  } = useEventSubmission(
    convertEvent(event || undefined),
    map('node', viewer.hierarchicalTags?.edges || []),
    singlePromoter,
    destinationPromoters
  )

  const validateEventSchema = useCallback(
    async (evt: any) => {
      try {
        await EventSchema.validate(evt, { context: { viewer, initialValues, locale }, abortEarly: false })
      } catch (err) {
        return yupToFormErrors(err)
      }
    },
    [initialValues, locale, viewer]
  )

  const readOnly = !!event?.id && !event.allowedActions?.minorUpdate

  if (!event && urlId) return <Navigate replace to="/404" />

  if (event && event.state !== 'DRAFT' && !reviewModalId) return <Navigate replace to={`/events/${event.id}/details`} />

  return (
    <Formik<IEventForm>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={saveAndContinue}
      validateOnMount
      validateOnBlur
      validateOnChange={false}
      validate={validateEventSchema}
    >
      {({ values, isSubmitting }) => (
        <FormBackupSupport theKey="newEventForm" disabled={!!urlId}>
          <>
            <EventSubmissionForm
              viewer={viewer}
              eventId={event?.id}
              eventIdLive={event?.eventIdLive}
              onSaveDraft={saveAndContinue}
              readOnly={readOnly}
            />
            {!urlId && !readOnly && <FormBackuper theKey="newEventForm" delay={1000} />}
            {reviewModalId && !isSubmitting && (
              <EventReviewModal
                id={reviewModalId}
                onSave={onReviewOk}
                onClose={closeReviewModal}
                event={values as any}
              />
            )}
            {showIsFreeModal && <EventIsFreeModal onClose={closeIsFreeModal} onSaveDraft={saveAndContinue} />}
          </>
        </FormBackupSupport>
      )}
    </Formik>
  )
}

export default memo(EventSubmission)
