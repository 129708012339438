import { nanoid } from 'nanoid'
import React, { FC, useCallback, useEffect, useRef, ComponentType } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  onClickOutside: () => void
  active?: boolean
  element?: ComponentType<React.PropsWithChildren<any>>
}

/**
 * data-click-outside allows elements outside of the ClickOutside root
 * to have click outside behaviour - see Dropdown with portal
 */
export const ClickOutsideElement = styled.div.attrs({
  'data-click-outside': true,
})``

const ClickOutside: FC<React.PropsWithChildren<IProps>> = ({
  className,
  children,
  element,
  onClickOutside,
  active,
}) => {
  const ref = useRef<any>(null)

  const handleClickOutside = useCallback(
    (e: any) => {
      const me = ref.current
      const closestClickOutside = e.target.closest('[data-click-outside]')
      const isPortal = !!e.target.closest('[data-is-portal]')
      // Ignore clicks only on/in this clickOutside, in case there are several on screen (PX-1730)
      // Ignore clicks inside of a portal, as it breaks the above logic
      if (me?.contains(e.target) || (closestClickOutside && me.isEqualNode(closestClickOutside)) || isPortal) {
        return
      }
      onClickOutside()
    },
    [ref, onClickOutside]
  )

  useEffect(() => {
    if (!active) return
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [active, handleClickOutside])

  return (
    <ClickOutsideElement as={element} className={className} ref={ref}>
      {children}
    </ClickOutsideElement>
  )
}

export default ClickOutside
