import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { noop, reject } from 'lodash/fp'

import { FormRow } from '../../../components/Form'
import IEventFormTickets from '../types/Tickets'
import SwitchField from '../../../components/SwitchField'
import FormField from '../../../components/FormField'
import { localeContext } from '../../../context/locale'
import IEventForm from '../types'
import { autofillDate, generateDateFieldHint } from '../services/autofillDates'
import { authContext } from '../../../context/auth'

interface IProps {
  readOnly: boolean
}

const EventOnSaleReminder: FC<React.PropsWithChildren<IProps>> = ({ readOnly }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const { user } = useContext(authContext)
  const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<IEventFormTickets>()

  const disabled = readOnly || !!values.onSaleNotificationStatus

  const ttysWithCustomOnSaleDate = useMemo(
    () => reject((tty) => !tty || !tty.onSaleDate || tty.archived, values.smsTtys || []) as typeof values.smsTtys,
    [values]
  )

  const onChange = useCallback(() => {
    const isActive = !!values.onSaleNotification
    if (isActive) {
      setFieldValue('onSaleNotification', false)
      setFieldValue('onSaleNotificationAt', null)
    } else {
      setFieldValue('onSaleNotification', true)
      setFieldValue(
        'onSaleNotificationAt',
        autofillDate(values as IEventForm, 'onSaleNotificationAt', user.diceStaff, true)
      )
    }
  }, [values, setFieldValue, user.diceStaff])

  return (
    <>
      <FormRow columnOnMobile>
        <SwitchField
          label={intl.formatMessage({ id: 'new_event.tickets.on_sale_notification.label' })}
          hint={intl.formatMessage({ id: 'new_event.tickets.on_sale_notification.hint' })}
          name="onSaleNotification"
          disabled={disabled}
          checked={!!values.onSaleNotification}
          onChange={onChange}
          onBlur={handleBlur}
        />
      </FormRow>

      {values.onSaleNotification && (
        <FormRow columnOnMobile>
          <FormField
            name="onSaleNotificationAt"
            timezone={values.timezoneName || undefined}
            label={intl.formatMessage({ id: 'new_event.tickets.on_sale_notification_date.label' })}
            control="datetime"
            value={values.onSaleNotificationAt}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            error={touched.onSaleNotificationAt && errors.onSaleNotificationAt}
            locale={locale}
            disabled={disabled}
            required
            hint={
              (values.onSaleNotificationStatus
                ? intl.formatMessage(
                  { id: 'new_event.tickets.on_sale_notification_sent.note' },
                  {
                    num: intl.formatNumber(values.onSaleSmsReminders || 0, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }
                )
                : generateDateFieldHint(
                  intl,
                    values as IEventForm,
                    'ON_SALE_REMINDER_TIME',
                    values.onSaleNotificationAt
                )) || intl.formatMessage({ id: 'new_event.tickets.on_sale_notification_sent.hint' })
            }
          />
        </FormRow>
      )}

      {ttysWithCustomOnSaleDate?.map(
        (tty) =>
          tty && (
            <FormRow columnOnMobile key={tty.id}>
              {tty.onSaleNotificationSentAt ? (
                <FormField
                  name={`smsTtys[${tty.id}].onSaleNotificationSentAt`}
                  timezone={values.timezoneName || undefined}
                  label={`${intl.formatMessage({ id: 'new_event.tickets.on_sale_notification_date.label' })} / ${
                    tty.name
                  }`}
                  control="datetime"
                  setFieldValue={noop}
                  value={tty.onSaleNotificationSentAt}
                  locale={locale}
                  disabled
                />
              ) : (
                <FormField
                  name={`smsTtys[${tty.id}].onSaleNotificationSentAt`}
                  label={`${intl.formatMessage({ id: 'new_event.tickets.on_sale_notification_date.label' })} / ${
                    tty.name
                  }`}
                  value={intl.formatMessage({ id: 'new_event.tickets.on_sale_notification_date.not_yet' })}
                  disabled
                />
              )}
            </FormRow>
          )
      )}
    </>
  )
}

export default memo(EventOnSaleReminder)
