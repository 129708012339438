import { getOr, filter, cloneDeep, concat } from 'lodash/fp'
import { IntlShape } from 'react-intl'
import { DeepPartial, DeepReadonly } from 'ts-essentials'

import IEventForm from '../types'
import { parseMarkdown } from '../../../utils/markdown'
import { IFee, ITicketType } from '../types/Tickets'
import { markAsClientOnly, markAsNew } from '../../../utils/entityStatus'
import { EventCostCurrency, EventType } from '../../../enums.generated'
import { DEFAULT_ADDITIONAL_INFO } from '../components/EventAdditionalInfos'
import { getFallbackPromoter, IDestinationPromoter } from '../hooks/usePromoters'
import { officialOrFallbackCurrency } from '../../../utils/currency'
import { ILocale } from '../../../intl'
import { IAccount } from '../../../context/auth/util/types'

export const TZ_DEFAULT = 'Europe/London'
export const QR_OFFSET_STREAM = -1800
export const QR_OFFSET_LIVE = -7200

export const getDefaultLineup = (intl: IntlShape) => [
  {
    details: intl.formatMessage({ id: 'doors_open' }),
    time: '',
  },
]

type IPWLWindow = NonNullable<NonNullable<IEventForm['waitingListExchangeWindows']>[number]>

export type ISinglePromoter = DeepReadonly<NonNullable<IEventForm['billingPromoter']>>

const getDefaultEvent = (
  intl: IntlShape,
  account: IAccount | null,
  hierarchicalTypes: IEventForm['hierarchicalTags'] | null,
  singlePromoter: DeepReadonly<ISinglePromoter | null>,
  destinationPromoters: null | IDestinationPromoter[],
  currency?: EventCostCurrency,
  eventType?: EventType | null,
  allowedAdhocFees?: DeepReadonly<IFee[]>
): Partial<IEventForm> => {
  const fallbackPromoter =
    destinationPromoters &&
    getFallbackPromoter(
      destinationPromoters,
      singlePromoter?.addressCountry || null,
      singlePromoter?.countryCode || null,
      singlePromoter?.stripeFallbackAccountId || null
    )

  const enabledPwl = singlePromoter?.resoldEnabled || singlePromoter?.allowSkipReview || false
  const coolingOffPeriod = singlePromoter?.coolingOffPeriod || false
  const coolingOffPeriodHours = singlePromoter?.coolingOffPeriodHours || 24

  return {
    eventType: eventType || null,
    additionalInfos: DEFAULT_ADDITIONAL_INFO,
    costCurrency: officialOrFallbackCurrency(currency || 'GBP'),
    timezoneName: null,
    ticketType: 'dice',
    maxTicketsLimit: 6,
    announceDate: null,
    onSaleDate: null,
    offSaleDate: null,
    date: null,
    endDate: null,
    closeEventDate: null,
    ticketPools: [],
    ticketTypes: [],
    marketeers: concat([], singlePromoter?.associatedMarketeers || []),
    lineup: getDefaultLineup(intl),
    feesBehaviour: (allowedAdhocFees?.length || 0) > 0 ? 'APPEND_TO_CONTRACT' : 'USE_CONTRACT',
    flags: {
      autoRescheduledEventRefunds: { active: true, cutoff_days: null },
      codeLocked: { active: false },
      competition: { active: false },
      hidden: { active: false },
      ticketTransfer: { active: singlePromoter?.eventDefaults?.ticketTransfer ?? true },
      waitingList: { active: singlePromoter?.eventDefaults?.waitingList ?? true },
      disableDayOfEventComms: { active: singlePromoter?.eventDefaults?.disableDayOfEventComms ?? false },
      paperTicket: { active: false },
      seated: { active: false },
      generateNewCodeOnTransfer: { active: false },
      enabledPwl: { active: enabledPwl, deadline: null },
      alcoholFree: { active: false },
      coolingOffPeriod: { active: coolingOffPeriod, hours: coolingOffPeriodHours },
      claimTickets: { active: false },
      unicorn: { active: false },
      shoppingCart: { active: false },
      hideFromDiscovery: { active: singlePromoter?.eventDefaults?.hideFromDiscovery ?? false },
    },
    waitingListExchangeWindows: [
      markAsClientOnly<IPWLWindow>(
        {
          duration: 60 * 60,
          offset: null,
        },
        'default-window'
      ),
    ],
    tags: singlePromoter?.tags ? [...singlePromoter.tags] : null,
    labels: singlePromoter?.labels ? [...singlePromoter.labels] : null,
    hierarchicalTags: filter(['label', 'gig'], hierarchicalTypes || []),
    media: [],
    state: 'DRAFT',
    ageLimit: intl.formatMessage({ id: 'event_defaults.age_limit' }),
    presentedBy: intl
      .formatMessage(
        { id: 'event_defaults.presented_by' },
        { name: singlePromoter?.displayName || account?.name || singlePromoter?.label || '' }
      )
      .replace(/ \.$/, ''),
    description: null,
    descriptionDraft: parseMarkdown(null),
    promoters: singlePromoter ? ([singlePromoter] as IEventForm['promoters']) : null,
    products: [],
    billingPromoter: (singlePromoter || null) as IEventForm['billingPromoter'],
    billingNotes: singlePromoter && singlePromoter.billingNotes ? singlePromoter.billingNotes : null,
    fanSupportNotes: null,
    salesforceContract: singlePromoter?.salesforceFields?.defaultContract
      ? { ...singlePromoter?.salesforceFields?.defaultContract }
      : null,
    stripeAccountId: singlePromoter?.stripeAccountId
      ? singlePromoter?.stripeAccountId
      : singlePromoter?.stripeFallbackAccountId || fallbackPromoter?.stripeAccountId || null,
    platformAccountCode: singlePromoter?.stripeAccountId
      ? singlePromoter?.platformAccountCode
      : singlePromoter?.stripeFallbackPlatformCode || fallbackPromoter?.platformAccountCode || null,
    diceTvPlatform: 'DICE',
    lockVersion: 0,
    diceStreamDuration: 7200,
    diceStreamDvrEnabled: true,
    diceStreamRewatchEnabledUntil: null,
    restrictCountries: (singlePromoter?.eventDefaults?.restrictCountries || []) as IEventForm['restrictCountries'],
    restrictCountriesKind: singlePromoter?.eventDefaults?.restrictCountriesKind || 'ALLOW',
    barcodeType: 'qr-code',
    printedTicketFormat: singlePromoter?.eventDefaults?.printedTicketFormat || 'STAR_RECEIPT',
    manualValidationEnabled: singlePromoter?.eventDefaults?.manualValidationEnabled ?? false,
    onSaleNotification: true,
    onSaleNotificationAt: null,
    onSaleNotificationStatus: false,
    sendReceiptViaSms: singlePromoter ? singlePromoter.sendReceiptViaSms : null,
    eventRules: {
      covidPcr: null,
      covidRecovery: null,
      covidVaccination: null,
      proofOfBeingHealthy: null,
      maskRequired: null,
      socialDistancing: null,
      covidPcrValidHours: null,
      covidPolicyUrl: null,
    },
    taxSettings: {
      clubNight: false,
      franceMainstream: false,
    },
    attractiveFields: {
      integrationDisabled:
        eventType === 'STREAM' || singlePromoter?.eventDefaults?.disableAttractiveIntegration || false,
      streamingTicketsIntegrationDisabled: true,
      compatibilityAe: '_0',
      siaeGenreType: null,
      taxFree: singlePromoter?.eventDefaults?.taxFree ?? false,
      entertainmentPercent: null,
      forceSubscription: false,
      forceSubscriptionLimit: 0,
      subscriptionCode: null,
      seatingAreaConfigs: null,
      author: null,
      distributor: null,
      nationality: null,
      performer: null,
      producer: null,
    },
    disableUsTax: !!singlePromoter?.disableUsTax,
    requiresBoxOfficeTicketNomination: singlePromoter?.eventDefaults?.requiresBoxOfficeTicketNomination ?? false,
    freeEvent: false,
  }
}

export const getDefaultTicketType = (
  event: DeepPartial<IEventForm>,
  intl: IntlShape,
  allowedAdhocFees: DeepReadonly<IFee[]>,
  locale: ILocale
): ITicketType => {
  const capacity =
    event.venueConfiguration && (event.venueConfiguration.capacity || 0) > 0
      ? event.venueConfiguration.capacity
      : getOr(0, '0.capacity', event.venues || [])

  return markAsNew<ITicketType>({
    name:
      event.eventType === 'STREAM'
        ? intl.formatMessage({ id: 'new_event.defaults.ticket_type.name_streaming' })
        : intl.formatMessage({ id: 'new_event.defaults.ticket_type.name' }),
    ticketPoolId: null,
    description: null,
    archived: false,
    hidden: false,
    codeLocked: false,
    startDate: null,
    endDate: null,
    priceHidden: false,
    isStream: event.eventType === 'STREAM',
    icon: event.eventType === 'STREAM' ? 'streaming' : 'standing',
    allocation: capacity > 0 ? capacity : 50,
    faceValue: 0,
    increment: 1,
    maximumIncrements: event.maxTicketsLimit || 0,
    doorSalesEnabled: false,
    doorSalesPrice: 0,
    doorSalesPriceTaxed: null,
    doorSalesTax: null,
    priceTierType: null,
    priceTiers: null,
    announceDate: null,
    offSaleDate: null,
    onSaleDate: null,
    activateCodeDateOffset: event.eventType === 'STREAM' ? QR_OFFSET_STREAM : QR_OFFSET_LIVE,
    seatmapUrl: null,
    attractiveSeatingAreaType: null,
    attractivePriceType: null,
    streamLink: null,
    priceBreakdown: null,
    externalSkus: null,
    fees: cloneDeep(allowedAdhocFees) as IFee[],
    allowSeatChange: false,
    reservedSeating: false,
    reservedSeatingType: null,
    seatCategories: null,
    requiresAddress: false,
    presale: false,
    venueScheduleId: null,
    additionalPaymentMethods: null,
    salesLimit: null,
    requiresOtherTypeIds: [],
  })
}

export const getDefaultProduct = (event: DeepPartial<IEventForm>, intl: IntlShape, locale: ILocale): any => {
  return markAsNew({
    category: null,
    productType: 'ADDON',
    name: null,
    productImages: [],
    sellingPoints: [{ name: '' }],
    description: null,
    price: 0,
    allocation: null,
    onSaleDate: null,
    offSaleDate: null,
    allTicketTypes: true,
    fees: null,
    linkTo: 'ALL',
    saleDate: 'DEFAULT',
    ticketTypes: [],
    archived: false,
    purchaseConfirmationMessage: null,
    variants: [],
    fulfilledBy: null,
  })
}

export default getDefaultEvent
