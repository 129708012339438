import { useFragment, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useCallback, useMemo } from 'react'
import { capitalize, compact, compose, concat, filter, find, map, reject, sortBy, uniqBy } from 'lodash/fp'
import { FormikProps, useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { useHierarchicalTags_viewer$key } from '../../__generated__/useHierarchicalTags_viewer.graphql'
import graphqlOptionsLoader from '../graphqlOptionsLoader'

export type IHierarchicalTags = ReadonlyArray<{
  value: string
  label: string | null
  kind: string | null
  parent: {
    name: string | null
  } | null
} | null> | null

type IHierarchicalTag = NonNullable<NonNullable<IHierarchicalTags>[number]>

const PRE_SORTED_TYPES = [
  'gig',
  'dj',
  'party',
  'comedy',
  'film',
  'podcast',
  'talks',
  'workshop',
  'foodanddrink',
  'theatre',
  'wellbeing',
  'sport',
  'playback',
  'social',
  'art',
]

const typeSort = sortBy((t: IHierarchicalTag) => {
  const idx = PRE_SORTED_TYPES.indexOf(t.label || '')
  return idx >= 0 ? idx : 999999999
})

const useHierarchicalTags = (viewerKey: useHierarchicalTags_viewer$key, formik?: FormikProps<any>) => {
  const intl = useIntl()
  const environment = useRelayEnvironment()

  const viewer = useFragment(
    graphql`
      fragment useHierarchicalTags_viewer on Viewer {
        hierarchicalTags(first: 100, where: { kind: "type" }) {
          edges {
            node {
              value: id
              label: name
              kind
              parent {
                name
              }
            }
          }
        }
      }
    `,
    viewerKey
  )

  const hierarchicalTypes = useMemo(() => map('node', viewer?.hierarchicalTags?.edges), [viewer])

  const formikContext = useFormikContext<{
    hierarchicalTags: IHierarchicalTags
  }>()

  const { values, setFieldValue, setFieldTouched, validateForm } = formik || formikContext

  const genresLoader = useMemo(() => {
    const type = find(['kind', 'type'], values.hierarchicalTags || [])
    const where = type ? { kind: 'genre', parentId: { in: type.value } } : { kind: 'genre' }
    return graphqlOptionsLoader(
      environment,
      graphql`
        query useHierarchicalTagsGenresQuery($where: HierarchicalTagWhereInput) {
          viewer {
            options: hierarchicalTags(where: $where, first: 100) {
              edges {
                node {
                  id
                  name
                  kind
                }
              }
            }
          }
        }
      `,
      { postProcess: (opts) => opts, where }
    )
  }, [environment, values.hierarchicalTags])

  const genres = useMemo(() => filter(['kind', 'genre'], values.hierarchicalTags || []), [values.hierarchicalTags])
  const setGenres = useCallback(
    (_ids: any, selection: any) => {
      setFieldValue(
        'hierarchicalTags',
        uniqBy('value', concat(reject(['kind', 'genre'], values.hierarchicalTags || []), selection || []))
      )
      setFieldTouched('hierarchicalTags', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm, values.hierarchicalTags]
  )

  const typeOptions: Array<IHierarchicalTag> = useMemo(
    () =>
      compose([
        map((type: IHierarchicalTag) => ({
          ...type,
          label: capitalize(
            intl.formatMessage({
              // @intl-meta project:Tags
              id: `tags_hierarchy.${type.parent?.name ? `${type.parent.name}_` : ''}${type.label}`,
              defaultMessage: type.label || undefined,
            })
          ),
        })),
        typeSort,
        compact,
      ])(hierarchicalTypes || []),
    [hierarchicalTypes, intl]
  )

  const type = useMemo(() => {
    const rawType = find(['kind', 'type'], values.hierarchicalTags || [])
    return (rawType && find(['value', rawType.value], typeOptions)) || null
  }, [typeOptions, values.hierarchicalTags])

  const setType = useCallback(
    (_id: any, type: any) => {
      setFieldValue('hierarchicalTags', uniqBy('value', [type]))
      setFieldTouched('hierarchicalTags', true, true)
      setTimeout(() => validateForm(), 0)
    },
    [setFieldTouched, setFieldValue, validateForm]
  )

  return { genres, setGenres, genresLoader, type, setType, typeOptions }
}

export default useHierarchicalTags
