/**
 * @generated SignedSource<<7688edb3c7e61e2e46aa9a72dad0cd83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePermissionProfileInput = {
  accountId?: string | null;
  caption?: string | null;
  clientMutationId: string;
  subjects?: ReadonlyArray<SubjectInput | null> | null;
};
export type SubjectInput = {
  actions?: ReadonlyArray<ActionInput | null> | null;
  name?: string | null;
};
export type ActionInput = {
  name?: string | null;
};
export type CreatePermissionProfileMutation$variables = {
  input: CreatePermissionProfileInput;
};
export type CreatePermissionProfileMutation$data = {
  readonly createPermissionProfile: {
    readonly permissionProfile: {
      readonly caption: string;
      readonly id: string;
      readonly roleName: string;
      readonly subjects: ReadonlyArray<{
        readonly actions: ReadonlyArray<{
          readonly name: string | null;
        } | null> | null;
        readonly name: string | null;
      } | null> | null;
    } | null;
  } | null;
};
export type CreatePermissionProfileMutation = {
  response: CreatePermissionProfileMutation$data;
  variables: CreatePermissionProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePermissionProfilePayload",
    "kind": "LinkedField",
    "name": "createPermissionProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PermissionProfile",
        "kind": "LinkedField",
        "name": "permissionProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "caption",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Subject",
            "kind": "LinkedField",
            "name": "subjects",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Action",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePermissionProfileMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePermissionProfileMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c130c3a39a1f381f1896dc7571d5173c",
    "id": null,
    "metadata": {},
    "name": "CreatePermissionProfileMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePermissionProfileMutation(\n  $input: CreatePermissionProfileInput!\n) {\n  createPermissionProfile(input: $input) {\n    permissionProfile {\n      id\n      roleName\n      caption\n      subjects {\n        name\n        actions {\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4c198a67897a6ca1148d3aee45e9f5a";

export default node;
