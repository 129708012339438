import { useCallback, useContext, useEffect, useMemo } from 'react'
import { fetchQuery_DEPRECATED, useFragment, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useIntl } from 'react-intl'
import { compact, map } from 'lodash/fp'

import { clearAuthToken, setAuthToken } from '../../../utils/api'
import { useSwitchAccountQuery } from '../../../__generated__/useSwitchAccountQuery.graphql'
import { useSwitchAccount_viewer$key } from '../../../__generated__/useSwitchAccount_viewer.graphql'
import { toAccount } from '../util/convert'
import { localeContext } from '../../locale'
import { AccountMembershipType } from '../../../enums.generated'

declare global {
  interface Window {
    __switchAccount?: (id: string) => Promise<string | null>
  }
}

function useSwitchAccount(viewerKey: useSwitchAccount_viewer$key, onUserChange?: () => void) {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const switchAccount = useCallback(
    (accountId: string) => {
      const promise: Promise<useSwitchAccountQuery['response']> = fetchQuery_DEPRECATED<useSwitchAccountQuery>(
        environment,
        graphql`
          query useSwitchAccountQuery($accountId: ID!) {
            viewer {
              switchAccount(accountId: $accountId)
            }
          }
        `,
        {
          accountId,
        }
      )

      return promise.then((data) => {
        const newToken = data.viewer?.switchAccount || null
        if (newToken) {
          clearAuthToken()
          setAuthToken(newToken)
          if (onUserChange) onUserChange()
        }
        return newToken
      })
    },
    [environment, onUserChange]
  )

  useEffect(() => {
    window.__switchAccount = switchAccount
    return () => {
      window.__switchAccount = undefined
    }
  }, [switchAccount])

  const viewer = useFragment(
    graphql`
      fragment useSwitchAccount_viewer on Viewer {
        availableAccounts {
          account {
            id
            name
            allowSkipReview
            extrasEnabled
            merchEnabled
            addressCountry
            countryCode
            isDisabled
            forbidSelfPayouts
            automaticRollingPaymentsEnabled
          }
          membershipType
          permissionProfile {
            id
            caption
            roleName
          }
        }
      }
    `,
    viewerKey
  )

  const availableAccounts = useMemo(
    () =>
      compact(
        map(
          (it) =>
            it?.account &&
            it?.permissionProfile && {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...toAccount(it, locale)!,
              membershipType: it.membershipType || ('MEMBER' as AccountMembershipType),
              permissionProfileName:
                // prettier-ignore
                it.permissionProfile.roleName === 'custom'
                  ? it.permissionProfile.caption
                  : intl.formatMessage({
                    // @intl-meta project:Permissions
                    id: `permissions_role.${it.permissionProfile.roleName}`,
                    defaultMessage: it.permissionProfile.caption,
                  }),
            },
          viewer.availableAccounts || []
        )
      ),
    [intl, locale, viewer.availableAccounts]
  )

  return { switchAccount, availableAccounts }
}

export default useSwitchAccount
