import React, { FC, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { find, get, isNil, map, pick, reject, set, findIndex } from 'lodash/fp'
import styled from 'styled-components/macro'
import { FormikContextType } from 'formik/dist/types'

import { IFee, IFeeTemplate } from '../types/Tickets'
import { Form, FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import IconButton from '../../../components/IconButton'
import useFeeTypeOptions from '../hooks/useFeeTypeOptions'
import { EventCostCurrency, FeeType, FeeUnit } from '../../../enums.generated'
import { color, font, input, mediaQuery, spacing } from '../../../utils/variables'
import { IFeeContractPreview } from '../hooks/useFeeContractPreview'
import Button from '../../../components/Button'
import ListAddButton from '../../../components/ListAddButton'
import Svg from '../../../components/Svg'
import { DEFAULT_FEE_AMOUNTS, PERCENTAGE_DEFAULT_FEES, PROMOTER_DEFAULT_FEES } from '../../../constants/fees'

const rejectWithIdx = (reject as any).convert({ cap: false })

const StyledSvg = styled(Svg)`
  margin-left: 8px;
  margin-right: -10px;
  pointer-events: none;

  z-index: 1;
`

const ButtonRow = styled(FormRow)`
  min-height: 32px;
`

const StyledListAddButton = styled(ListAddButton)`
  margin-top: 0;
`

const CopyContractButton = styled(Button)`
  ${mediaQuery.greaterThan('tablet')`
    margin-left: auto;
    display: block;
  `}
`

const FeesWrapper = styled(Form)`
  border: 1px solid ${input.borderColor};
  border-radius: ${input.borderRadius}px;
  padding: ${spacing.md}px;
`
const SplitHint = styled.div`
  font-size: ${font.size.sm}px;
  color: ${color.darkgrey};
  margin: ${spacing.md}px 0 ${spacing.sm}px;
`

const RemoveFeeButton = styled(IconButton)``
const FieldsWrapper = styled.div``
export const FeeWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${input.borderColor};
    padding-bottom: ${spacing.md}px;
    margin-bottom: ${spacing.md}px;
  }
  display: flex;
  ${FieldsWrapper} {
    flex-grow: 1;
  }
  ${RemoveFeeButton} {
    flex-grow: 0;
    margin-left: ${spacing.md}px;
  }
`

interface IProps {
  contract?: IFeeContractPreview | null | undefined
  currency: EventCostCurrency | null
  fees: Array<IFee | null> | null
  name: string
  readOnly?: boolean
  formikContext: FormikContextType<any>
}

interface IFeeRowProps {
  currency: EventCostCurrency | null | '_ANY_'
  fee: IFee | IFeeTemplate
  idx: number
  superIdx?: number
  onRemove: null | ((e: any) => void)
  name: string
  feeTypeOptions: Array<{ value: string; label: string }>
  unitOptions: Array<{ value: string; label: string }>
  readOnly?: boolean
  formikContext: FormikContextType<any>
  hideAmount?: boolean
}

export const FeeRow: FC<React.PropsWithChildren<IFeeRowProps>> = ({
  currency,
  fee,
  name,
  onRemove,
  idx,
  superIdx,
  feeTypeOptions,
  unitOptions,
  readOnly,
  formikContext,
  hideAmount,
}) => {
  const intl = useIntl()
  const { touched, errors, handleBlur, setFieldValue } = formikContext

  const onUnitChange = useCallback(
    (value: FeeUnit) => {
      if (hideAmount) return

      if (value === 'percentage') {
        const newSplit = map(set('unit', value), fee.split)
        setFieldValue(`${name}[${idx}].split`, newSplit)
      }
      setFieldValue(`${name}[${idx}].amount`, value === 'fixed' ? DEFAULT_FEE_AMOUNTS[fee.type] || 0 : 0)

      setFieldValue(`${name}[${idx}].unit`, value)
    },
    [hideAmount, setFieldValue, name, idx, fee.type, fee.split]
  )

  const onAmountChange = useCallback(
    (e: any) => {
      setFieldValue(
        `${name}[${idx}].amount`,
        e.target.value && (fee.unit === 'percentage' ? Math.min(Number(e.target.value), 100) : Number(e.target.value))
      )
    },
    [setFieldValue, name, idx, fee.unit]
  )

  const feeAmount = 'amount' in fee ? fee.amount : 0

  const keepAmount = useMemo(() => (find(['destination', 'keep'], fee.split) as any)?.amount, [fee.split])
  const promoterAmount = useMemo(
    () => (find(['destination', 'billingPromoter'], fee.split) as any)?.amount,
    [fee.split]
  )

  const onSplitUnitChange = useCallback(
    (unit: any) => {
      let newSplit
      if (unit === 'percentage') {
        const keep = PROMOTER_DEFAULT_FEES.has(fee.type) ? 0 : 100

        newSplit = [
          { destination: 'keep', unit, amount: keep },
          { destination: 'billingPromoter', unit, amount: 100 - keep },
        ]
      }

      if (unit === 'fixed') {
        const keep = PROMOTER_DEFAULT_FEES.has(fee.type) ? 0 : feeAmount || 0

        newSplit = [
          { destination: 'keep', unit, amount: keep },
          { destination: 'billingPromoter', unit, amount: (feeAmount || 0) - keep },
        ]
      }

      setFieldValue(`${name}[${idx}].split`, newSplit)
    },
    [setFieldValue, name, idx, fee.type, feeAmount]
  )

  const onSplitChange = useCallback(
    (e: any) => {
      const destination: string = e.target.name.match(/\w+$/)[0]
      const value: string = (e.target.value || '').replace(/-|\s/g, '')

      const unit = fee.split?.[0]?.unit

      let amount: number | null = null
      if (value !== '') {
        amount = Math.max(0, Math.min(Number(value), unit === 'percentage' ? 100 : feeAmount || 0))
      }

      const newSplit = [
        { destination, unit, amount },
        {
          destination: destination === 'keep' ? 'billingPromoter' : 'keep',
          unit,
          amount: unit === 'percentage' ? 100 - (amount || 0) : (feeAmount || 0) - (amount || 0),
        },
      ]

      setFieldValue(`${name}[${idx}].split`, newSplit)
    },
    [setFieldValue, feeAmount, name, idx, fee.split]
  )

  const onTypeChange = useCallback(
    (value: FeeType) => {
      setFieldValue(`${name}[${idx}].type`, value)

      if (!hideAmount) {
        onUnitChange(PERCENTAGE_DEFAULT_FEES.has(value) ? 'percentage' : 'fixed')
        setFieldValue(`${name}[${idx}].amount`, DEFAULT_FEE_AMOUNTS[value] || 0)
      }

      const keepPercent = PROMOTER_DEFAULT_FEES.has(value) ? 0 : 100

      setFieldValue(`${name}[${idx}].split`, [
        { amount: keepPercent, destination: 'keep', unit: 'percentage' },
        { amount: 100 - keepPercent, destination: 'billingPromoter', unit: 'percentage' },
      ])
    },
    [setFieldValue, name, idx, hideAmount, onUnitChange]
  )

  const blurWithDefault = useCallback(
    (e: any) => {
      if (!e.target.value) {
        if (e.target.name.indexOf('split') >= 0) {
          const splitName = `${name}[${idx}].split`
          const splitKind = e.target.name.replace(`${splitName}.`, '')
          const splitIdx = findIndex(['destination', splitKind], fee.split)

          setFieldValue(splitName, fee.split && set([splitIdx, 'amount'], 0, fee.split), true)
        } else {
          setFieldValue(e.target.name, 0, true)
        }
      }

      handleBlur(e)
    },
    [fee.split, handleBlur, idx, name, setFieldValue]
  )

  const feeTypeOption = useMemo(
    () => ({
      value: fee.type,
      label: intl.formatMessage({ id: `fees.${fee.type}`, defaultMessage: `???? (${fee.type})` }),
    }),
    [fee.type, intl]
  )

  const feeSplitUnit = get('split[0].unit', fee)

  return (
    <FeeWrapper data-test-id={`${name}[${idx}]`}>
      <FieldsWrapper>
        <FormRow columnOnMobile spacing="small">
          <FormField
            name={`${name}[${idx}].type`}
            control="select"
            options={feeTypeOptions}
            value={feeTypeOption}
            disabled={readOnly}
            onChange={onTypeChange}
            error={get([name, idx, 'type'].join('.'), errors)}
          />
          <FormField
            name={`${name}[${idx}].unit`}
            control="select"
            options={unitOptions}
            value={find(['value', fee.unit], unitOptions)}
            disabled={readOnly || hideAmount}
            onChange={onUnitChange}
            error={get([name, idx, 'unit'].join('.'), errors)}
          />

          {!hideAmount && (
            <FormField
              name={`${name}[${idx}].amount`}
              type="number"
              currency={fee.unit === 'fixed' ? currency : undefined}
              prefix={fee.unit === 'percentage' ? <StyledSvg icon="percent" /> : undefined}
              placeholder={fee.unit === 'percentage' ? '0' : undefined}
              value={isNil(feeAmount) ? '' : feeAmount}
              onChange={onAmountChange}
              onBlur={blurWithDefault}
              required
              disabled={readOnly}
              min={0}
              step={1}
              max={fee.unit === 'percentage' ? 100 : undefined}
              error={get([name, idx, 'amount'].join('.'), touched) && get([name, idx, 'amount'].concat('.'), errors)}
            />
          )}
        </FormRow>
        <SplitHint>{intl.formatMessage({ id: 'new_event.tickets.fee_split.hint' })}</SplitHint>
        <FormRow columnOnMobile spacing="small">
          <FormField
            name={`${name}[${idx}].split.unit`}
            control="select"
            options={unitOptions}
            value={find(['value', feeSplitUnit], unitOptions)}
            disabled={readOnly || hideAmount || fee.unit === 'percentage'}
            onChange={onSplitUnitChange}
          />
          <FormField
            name={`${name}[${idx}].split.keep`}
            type="number"
            currency={feeSplitUnit === 'fixed' ? currency : undefined}
            prefix={feeSplitUnit === 'percentage' ? <StyledSvg icon="percent" /> : undefined}
            placeholder={feeSplitUnit === 'percentage' ? '0' : undefined}
            value={isNil(keepAmount) ? '' : keepAmount}
            onChange={onSplitChange}
            onBlur={blurWithDefault}
            required
            disabled={readOnly}
            min={0}
            max={feeSplitUnit === 'percentage' ? 100 : undefined}
            step={1}
            hint={intl.formatMessage({ id: 'new_event.tickets.fee_split_keep.hint' })}
          />
          <FormField
            name={`${name}[${idx}].split.billingPromoter`}
            type="number"
            currency={feeSplitUnit === 'fixed' ? currency : undefined}
            prefix={feeSplitUnit === 'percentage' ? <StyledSvg icon="percent" /> : undefined}
            placeholder={feeSplitUnit === 'percentage' ? '0' : undefined}
            value={isNil(promoterAmount) ? '' : promoterAmount}
            onChange={onSplitChange}
            onBlur={blurWithDefault}
            required
            disabled={readOnly}
            min={0}
            max={feeSplitUnit === 'percentage' ? 100 : undefined}
            step={1}
            hint={intl.formatMessage({ id: 'new_event.tickets.fee_split_billingPromoter.hint' })}
          />
        </FormRow>
      </FieldsWrapper>
      <RemoveFeeButton
        icon="trash"
        disabled={readOnly || !onRemove}
        onClick={onRemove}
        data-idx={idx}
        data-sidx={superIdx}
      />
    </FeeWrapper>
  )
}

const EventFeesOverride: FC<React.PropsWithChildren<IProps>> = ({
  contract,
  currency,
  fees,
  name,
  readOnly,
  formikContext,
}) => {
  const intl = useIntl()
  const { setFieldValue } = formikContext

  const feeTypeOptions = useFeeTypeOptions()
  const unitOptions = useMemo(() => {
    return [
      { value: 'fixed', label: intl.formatMessage({ id: 'fees.fee_unit.fixed' }) },
      { value: 'percentage', label: intl.formatMessage({ id: 'fees.fee_unit.percentage' }) },
    ]
  }, [intl])

  const onRemoveFee = useCallback(
    (e: any) => {
      const removedIdx = Number(e.currentTarget.dataset['idx'])
      if (!fees || isNil(removedIdx)) return

      const newFees = rejectWithIdx((_: any, idx: number) => idx === removedIdx, fees)
      setFieldValue(name, newFees)
    },
    [name, fees, setFieldValue]
  )

  const onAddFee = useCallback(
    (e: any) => {
      const newFees = [
        ...(fees || []),
        {
          amount: 0,
          type: feeTypeOptions[0].value,
          unit: 'percentage',
          split: [
            { amount: 100, destination: 'keep', unit: 'percentage' },
            { amount: 0, destination: 'billingPromoter', unit: 'percentage' },
          ],
        },
      ]
      setFieldValue(name, newFees)
    },
    [name, feeTypeOptions, fees, setFieldValue]
  )

  const onCopyContract = useCallback(() => {
    if (!contract) return

    setFieldValue(
      name,
      contract.map((row) => ({
        ...pick(['amount', 'type', 'unit'], row),
        split: map(pick(['amount', 'destination', 'unit']), row.split),
      }))
    )
  }, [contract, name, setFieldValue])

  return (
    <FeesWrapper spacing="small">
      {fees &&
        fees.map(
          (fee, idx) =>
            fee && (
              <FeeRow
                key={idx}
                currency={currency}
                fee={fee}
                idx={idx}
                name={name}
                readOnly={readOnly}
                feeTypeOptions={feeTypeOptions}
                unitOptions={unitOptions}
                onRemove={onRemoveFee}
                formikContext={formikContext}
              />
            )
        )}

      {!readOnly && (
        <ButtonRow columnOnMobile>
          <StyledListAddButton label={intl.formatMessage({ id: 'new_event.tickets.add_fee' })} onClick={onAddFee} />

          {contract && contract.length > 0 && (
            <CopyContractButton onClick={onCopyContract} preset="outline">
              {intl.formatMessage({ id: 'new_event.tickets.copy_contract_fees' })}
            </CopyContractButton>
          )}
        </ButtonRow>
      )}
    </FeesWrapper>
  )
}

export default EventFeesOverride
