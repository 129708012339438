import { isNil } from 'lodash/fp'
import React, { FC, memo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { textStyle } from '../utils/typography'

import { color } from '../utils/variables'
import { TitleTooltip } from './Tooltip'

const CircleProgressControl = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 8px;
`
const CircleProgressBar = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`
const CenteredText = styled.span`
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  ${textStyle.functional.xs}
`
const PrimaryColor = ['#0000FF', '#00D8AF']
const SecondaryColor = ['#C900FF', '#FFA81A']

interface IProps {
  percentage: number
  value?: number
  title?: string

  width?: number
  strokeWidth?: number
  fill?: string
  lineColor?: string
}

const CircleProgress: FC<React.PropsWithChildren<IProps>> = ({
  percentage,
  value,
  title,
  width = 40,
  strokeWidth = 6,
  fill = 'transparent',
  lineColor = color.lightgrey,
}) => {
  const intl = useIntl()

  const radius = width / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (percentage / 100) * circumference
  const color = percentage === 100 ? SecondaryColor : PrimaryColor

  const shortValue =
    isNil(value) || value < 1000
      ? value
      : intl.formatNumber(value, {
        maximumFractionDigits: 0,
        maximumSignificantDigits: 2,
        notation: 'compact',
      })

  const circle = (
    <CircleProgressControl style={{ height: `${width}px`, width: `${width}px`, flex: `0 0 ${width}px` }}>
      <CenteredText>{shortValue}</CenteredText>
      <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
        {color && (
          <linearGradient id={`${color}`} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor={color[0]} />
            <stop offset="100%" stopColor={color[1]} />
          </linearGradient>
        )}
        <circle
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={width / 2}
          cy={width / 2}
          stroke={lineColor}
          strokeDasharray={`${circumference} ${circumference}`}
        />
        <CircleProgressBar
          strokeWidth={strokeWidth}
          fill={fill}
          r={radius}
          cx={width / 2}
          cy={width / 2}
          stroke={`url(#${color})`}
          strokeLinecap="round"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
        />
      </svg>
    </CircleProgressControl>
  )

  return title ? <TitleTooltip title={title}>{circle}</TitleTooltip> : circle
}

export default memo(CircleProgress)
