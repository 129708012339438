import React, { FC, memo, PropsWithChildren, useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { compact, compose, concat, filter, find, get, groupBy, map, reduce, reject, set, some, update } from 'lodash/fp'

import { isFuture, parseISO } from 'date-fns'
import IEventFormTickets, { ITicketPool } from '../types/Tickets'

import { color, font } from '../../../utils/variables'

import { FormRow } from '../../../components/Form'
import FormField from '../../../components/FormField'
import { RequiredMark } from '../../../components/FormGroup'
import IconButton from '../../../components/IconButton'
import ListAddButton from '../../../components/ListAddButton'

import { allowedEventAction } from '../services/allowedEventAction'
import EventTicketTypes from './EventTicketTypes'
import { markAsClientOnly, markAsClientOnlyNewId } from '../../../utils/entityStatus'
import useTicketPoolTotalSales from '../hooks/useTicketPoolTotalSales'

const TicketPool = styled.div`
  border: 2px solid ${color.lightgrey};
  border-radius: 6px;
  padding: 24px;
  margin: 8px 0 16px;
  &:last-child {
    margin-bottom: 0;
  }
`

const AddRow = styled(FormRow)`
  margin-top: 24px;
`

const Controls = styled.div`
  display: flex;
  align-items: flex-end;

  & > *:first-child {
    flex-grow: 1;
    margin-right: 16px;
  }

  & > ${IconButton} {
    flex-shrink: 0;
  }
`

const NameHint = styled.div`
  display: flex;
  align-items: center;
`
const CharCounter = styled.div<{ count: number; maxCount: number }>`
  margin: 0 0 0 auto;
  white-space: nowrap;
  font-weight: normal;
  font-size: ${font.size.sm}px;
  ${({ count, maxCount }) => (count === 0 || count > maxCount ? `color: ${color.error}` : undefined)}
`

interface IProps {
  readOnly?: boolean
}

const EventTicketPools: FC<PropsWithChildren<IProps>> = ({ children, readOnly }) => {
  const intl = useIntl()

  const { values, setFieldValue, errors, touched, handleChange, handleBlur } = useFormikContext<IEventFormTickets>()

  const allowedLifecycleUpdates = values.allowedLifecycleUpdates
  const canAdd =
    !readOnly && (values.state === 'DRAFT' || allowedEventAction(allowedLifecycleUpdates, 'ticketPools', 'canAdd'))

  const addTicketPool = useCallback(() => {
    setFieldValue(
      'ticketPools',
      concat(
        values.ticketPools || [],
        markAsClientOnly<ITicketPool>({
          name: `${intl.formatMessage({ id: 'new_event.tickets.ticket_type_edit.ticket_pool_id.label' })} ${String(
            (values.ticketPools || []).length + 1
          ).padStart(2, '0')}`,
          maxAllocation: 0,
        })
      )
    )
  }, [setFieldValue, values.ticketPools, intl])

  const cloneTicketPool = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const poolId = e.currentTarget.dataset.poolId
      const poolToClone = find((p) => p?.id === poolId, values.ticketPools || [])

      if (poolToClone) {
        const clonedPool = compose([
          markAsClientOnlyNewId,
          set(
            'name',
            intl.formatMessage(
              { id: 'copy_of' },
              {
                something: poolToClone.name,
              }
            )
          ),
        ])(poolToClone)

        const ticketsToClone = filter((tty) => tty?.ticketPoolId === poolId, values.ticketTypes || [])
        const newTickets = map(
          (tty) =>
            compose([
              set('ticketPoolId', clonedPool.id),
              markAsClientOnlyNewId,
              update('priceTiers', (ptArr) => ptArr && map(markAsClientOnlyNewId, ptArr)),
            ])(tty),
          ticketsToClone || []
        )

        setFieldValue('ticketPools', concat(values.ticketPools || [], clonedPool))
        setFieldValue('ticketTypes', concat(values.ticketTypes || [], newTickets))
      }
    },
    [intl, setFieldValue, values.ticketPools, values.ticketTypes]
  )

  const removeTicketPool = useCallback(
    (e: any) => {
      const removeId = e.currentTarget.dataset.id
      setFieldValue('ticketPools', reject({ id: removeId }, values.ticketPools))
      setFieldValue('ticketTypes', reject({ ticketPoolId: removeId }, values.ticketTypes))
    },
    [setFieldValue, values.ticketPools, values.ticketTypes]
  )

  const handleNumChange = useCallback(
    (e: any) => {
      const fieldName = e.target.name
      const value = e.target.value
      setFieldValue(fieldName, value ? parseInt(value, 10) : 0)
    },
    [setFieldValue]
  )

  const groupedTtys = useMemo(
    () =>
      groupBy(
        (tty) => (tty.archived ? 'archived' : tty.ticketPoolId || 'standalone'),
        compact(values.ticketTypes || [])
      ),
    [values.ticketTypes]
  )

  const poolSales = useMemo(
    () =>
      reduce((sales: any, tt: any) => {
        if (!tt || tt.ticketType.archived) return
        const poolId = tt.ticketType.ticketPoolId
        sales[poolId] = (sales[poolId] || 0) + (tt.totalAppSold || 0) + (tt.totalPosSold || 0) + (tt.totalReserved || 0)
        return sales
      }, {})(values.sales?.ticketTypesBreakdown || []),
    [values.sales?.ticketTypesBreakdown]
  )

  const maxAllocationError = useCallback(
    (idx: number) => {
      const allocationTouched = get(['ticketPools', idx, 'maxAllocation'], touched)
      const allocationError = get(['ticketPools', idx, 'maxAllocation'], errors)

      if (allocationTouched && allocationError === 'minAllocation') {
        const poolId = values.ticketPools ? values.ticketPools[idx]?.id : undefined
        const idxPoolSales = poolId ? poolSales[poolId] : undefined
        return intl.formatMessage(
          { id: 'change_allocation_modal.allocation.validation_error' },
          { minAllocation: idxPoolSales }
        )
      } else {
        return allocationTouched && allocationError
      }
    },
    [errors, intl, poolSales, touched, values.ticketPools]
  )

  const ticketPoolTotalSales = useTicketPoolTotalSales()

  const isAnyTicketTypeInPoolOnSale = useCallback(
    (poolId: string): boolean => {
      const poolTickets = filter((tty) => tty?.ticketPoolId === poolId, values.ticketTypes)
      return some((tty) => !isFuture(parseISO(tty?.onSaleDate || values.onSaleDate || '')), poolTickets)
    },
    [values.onSaleDate, values.ticketTypes]
  )

  return (
    <>
      {values.ticketPools &&
        values.ticketPools.map(
          (pool, idx) =>
            pool && (
              <TicketPool key={pool.id}>
                <FormRow columnOnMobile>
                  <FormField
                    name={`ticketPools[${idx}].name`}
                    label={
                      <NameHint>
                        <span>{intl.formatMessage({ id: 'new_event.tickets.ticket_pool.name.label' })}</span>
                        <RequiredMark />
                        <CharCounter maxCount={30} count={(pool.name || '').length}>
                          {(pool.name || '').length}/{30}
                        </CharCounter>
                      </NameHint>
                    }
                    hint={intl.formatMessage({ id: 'new_event.tickets.ticket_pool.name.help' })}
                    value={pool.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={get(['ticketPools', idx, 'name'], touched) && get(['ticketPools', idx, 'name'], errors)}
                    disabled={
                      readOnly ||
                      (values.state !== 'DRAFT' &&
                        !allowedEventAction(allowedLifecycleUpdates, 'ticketPools', 'canUpdate'))
                    }
                  />
                  <Controls>
                    <FormField
                      name={`ticketPools[${idx}].maxAllocation`}
                      type="tel"
                      pattern="\d*"
                      label={intl.formatMessage({ id: 'new_event.tickets.ticket_pool.max_allocation.label' })}
                      value={pool.maxAllocation ?? ''}
                      onChange={handleNumChange}
                      onBlur={handleBlur}
                      error={maxAllocationError(idx)}
                      required
                      disabled={
                        readOnly ||
                        (values.state !== 'DRAFT' &&
                          !allowedEventAction(allowedLifecycleUpdates, 'ticketPools', 'canChangeAllocation'))
                      }
                    />
                    <IconButton
                      title={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.copy_button' })}
                      icon="copy"
                      onClick={cloneTicketPool}
                      data-id={`copyTicketPool[${idx}]`}
                      data-pool-id={pool.id}
                      disabled={!canAdd}
                    />
                    <IconButton
                      title={intl.formatMessage({ id: 'new_event.tickets.ticket_types.list.remove_button' })}
                      icon="trash"
                      onClick={removeTicketPool}
                      data-id={pool.id}
                      disabled={
                        readOnly ||
                        (ticketPoolTotalSales[pool.id] || 0) > 0 ||
                        (values.state !== 'DRAFT' &&
                          !allowedEventAction(allowedLifecycleUpdates, 'ticketPools', 'canRemove')) ||
                        // Backend disallows deleting pool if not draft and ticket onSaleDate is not in future
                        (values.state !== 'DRAFT' && isAnyTicketTypeInPoolOnSale(pool.id))
                      }
                    />
                  </Controls>
                </FormRow>

                <EventTicketTypes readOnly={readOnly} ticketTypes={groupedTtys[pool.id] || []} ticketPool={pool} />
              </TicketPool>
            )
        )}
      {(children || canAdd) && (
        <AddRow columnOnMobile>
          {canAdd && (
            <ListAddButton
              className="mt-zero"
              color="lightgrey"
              label={intl.formatMessage({ id: 'new_event.tickets.ticket_pools.list.add_button' })}
              onClick={addTicketPool}
            />
          )}
          {children}
        </AddRow>
      )}
    </>
  )
}

export default memo(EventTicketPools)
