import styled, { css } from 'styled-components/macro'
import { textStyle } from '../utils/typography'
import { color, font, mediaQuery, zIndex } from '../utils/variables'
import Button from './Button'
import { DropdownTrigger } from './Dropdown'
import IconButton from './IconButton'
import Svg from './Svg'

export const HeaderFilterBarWrapper = styled.div`
  position: relative;
  display: flex;
  min-width: 100%;
  flex-grow: 1;
  margin: 16px -32px -16px;
  border-top: 2px solid #000;
  height: 58px;
  ${mediaQuery.lessThan('tablet')`
    margin: 12px -58px -16px -58px;
  `}
`

export const SearchBar = styled.div<{ noPageHeader?: boolean | null }>`
  border-bottom: 2px solid ${color.text};

  position: sticky;
  top: ${({ noPageHeader }) => (noPageHeader ? 0 : '119px')};
  background: ${color.white};
  z-index: ${zIndex.dropdown - 1};

  display: flex;
  min-width: 100%;
  flex-grow: 1;
  height: 58px;

  ${mediaQuery.lessThan<{ noPageHeader?: boolean | null }>('tablet')`
      top: ${({ noPageHeader }) => (noPageHeader ? 0 : '119px')};
  `}
`

export const FilterSearch = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 8px 24px 8px 32px;
  ${mediaQuery.lessThan('tablet')`
    padding: 8px 16px;
  `}
`

export const FilterAdditional = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 8px 32px 8px 16px;
  border-left: 2px solid #000;
  ${mediaQuery.lessThan('tablet')`
    border: 0;
    padding: 8px 16px 8px 8px;
  `}
`

export const FilterAdditionalItem = styled.button<{ disabled?: boolean; isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  border: none;
  font-size: ${font.size.sm}px;
  & + & {
    margin-left: 8px;
  }
  strong {
    margin-left: 8px;
  }

  background: ${({ isActive }) => (isActive ? color.lightgrey : 'unset')};
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'unset')};

  &:hover {
    background: ${color.lightgrey};
  }
  ${mediaQuery.lessThan('tablet')`
    strong {
      display: none;
    }
  `}
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      color: ${color.lightgrey};
      &:hover {
        background: none;
      }
  `}
`

export const FilterAdditionalItemDropdown = styled(FilterAdditionalItem)`
  &.-active {
    color: ${color.primary};
  }
  ${DropdownTrigger} {
    font-size: ${font.size.sm}px;
    margin: -8px;
    padding: 8px;
  }
  ${DropdownTrigger} {
    display: flex;
    align-items: center;
  }
`

export const FilterSearchInput = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border: 0;
  outline: none;
  padding: 0 8px;
  &:placeholder {
    color: ${color.grey};
  }
  & + ${IconButton} {
    flex: 0 0 40px;
  }
`

export const ClearFilters = styled(Button)`
  position: fixed;
  left: calc(50% + 242px);
  transform: translateX(-50%);
  margin-left: -121px;
  bottom: 36px;
  height: 28px;
  padding: 0 12px;
  font-size: ${font.size.base}px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  min-width: 0;
  svg {
    display: inline-block;
    margin-left: 4px;
    vertical-align: top;
    width: 16px;
    height: 16px;
    margin: 1px 0 0 4px;
  }
  ${mediaQuery.lessThan('desktopLarge')`
    bottom: 24px;
    left: calc(50% + 74px);
    margin-left: -37px;
  `}
  ${mediaQuery.lessThan('tablet')`
    bottom: 64px;
    left: 50%;
    margin-left: 0;
    width: calc(100% - 32px);
    height: 40px;
  `}
  z-index: ${zIndex.dropdown};
`

export const ClearFiltersText = styled.span`
  display: inline-block;
  line-height: 18px;
  vertical-align: top;
`

export const ClearIcon = styled(Svg)`
  margin-left: 4px;
  color: ${color.grey};
  cursor: pointer;
  flex: 24px 0 0;
  &:hover {
    color: ${color.text};
  }
  ${mediaQuery.lessThan('tablet')`
    margin-left: 0;
  `}
`

export const SearchIcon = styled(Svg)<{ $huge?: boolean }>`
  flex: 24px 0 0;
  width: 24px;
  height: 24px;
  ${({ $huge }) =>
    $huge &&
    css`
      ${mediaQuery.greaterThan('tablet')`
      flex: 32px 0 0;
      width: 32px;
      height: 32px;
    `}
    `}
`

export const FilterSearchInputHuge = styled(FilterSearchInput)`
  ${textStyle.heading.lg}

  &:placeholder {
    color: ${color.lightgrey};
  }

  ${mediaQuery.lessThan('tablet')`
    ${textStyle.heading.sm}
  `}
`
