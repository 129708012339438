import { useCallback, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { compact, concat, findIndex, set, without } from 'lodash/fp'

import IEventFormInformation from '../types/Information'
import { IFeaturedArea } from '../../../components/Event/EventFeatureModal'

function useEventFeatured() {
  const { values, setFieldValue } = useFormikContext<IEventFormInformation>()

  const featurings = useMemo(() => values.featuredAreas || [], [values.featuredAreas])

  const [creating, setCreating] = useState(false)
  const openCreateModal = useCallback(() => setCreating(true), [])
  const closeCreateModal = useCallback(() => setCreating(false), [])
  const doCreate = useCallback(
    async (values: IFeaturedArea) => {
      setFieldValue('featuredAreas', concat(compact(featurings), values))
      closeCreateModal()
    },
    [closeCreateModal, featurings, setFieldValue]
  )

  const doRemove = useCallback(
    (e: any) => {
      const idx = Number(e.currentTarget.dataset['idx'])

      const it = featurings[idx]
      setFieldValue('featuredAreas', compact(without([it], featurings)))
    },
    [featurings, setFieldValue]
  )

  const [editingIdx, setEditingIdx] = useState<null | number>(null)
  const openEditModal = useCallback((e: any) => {
    const idx = Number(e.currentTarget.dataset['idx'])
    setEditingIdx(idx)
  }, [])
  const closeEditModal = useCallback(() => setEditingIdx(null), [])
  const doSave = useCallback(
    async (values: IFeaturedArea) => {
      const idx = findIndex(['id', values.id], featurings)
      setFieldValue('featuredAreas', compact(set([idx], values, featurings)))
      closeEditModal()
    },
    [closeEditModal, featurings, setFieldValue]
  )

  return {
    featurings,

    doRemove,

    creating,
    openCreateModal,
    closeCreateModal,
    doCreate,

    editingIdx,
    openEditModal,
    closeEditModal,
    doSave,
  }
}

export default useEventFeatured
