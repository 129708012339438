import React, { FC, useEffect, useContext, useLayoutEffect, useMemo, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import ClassList from 'classlist'
import styled, { createGlobalStyle, css } from 'styled-components/macro'

import { mediaQuery, spacing } from '../utils/variables'

import IconButton from './IconButton'
import Button from './Button'
import { PageViewTracker } from '../context/tracking'
import { localeContext } from '../context/locale'
import { authContext } from '../context/auth'

type ModalSize = 'default' | 'fullscreen'

interface IProps {
  trackId?: string
  trackData?: any
  className?: string
  children?: React.ReactNode
  sidecar?: React.ReactNode
  onClose?: () => void
  modalTitle?: any
  closeButton?: boolean
  containerClassName?: string
  size?: ModalSize
}

export const Modal: FC<React.PropsWithChildren<IProps>> = ({
  trackId,
  trackData,
  className,
  children,
  onClose,
  modalTitle,
  closeButton,
  containerClassName,
  sidecar,
  size = 'default',
}) => {
  const { phraseEnabled } = useContext(localeContext)
  const { isImpersonated } = useContext(authContext)

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const handleEscape = useCallback(
    (event: any) => {
      if (event.keyCode === 27 && onClose) onClose()
    },
    [onClose]
  )

  useEffect(() => {
    if (mounted.current) document.addEventListener('keydown', handleEscape, false)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [mounted, handleEscape])

  useEffect(() => {
    const { body } = document
    const classes = ClassList(body)
    if (body) {
      classes.add('modal-open')
      return () => {
        classes.remove('modal-open')
      }
    }
  }, [])

  const portalRoot = useMemo(() => {
    const node = document.createElement('div')
    node.dataset.testid = 'modal'
    return node
  }, [])

  useLayoutEffect(() => {
    document.body.appendChild(portalRoot)
    return () => {
      portalRoot?.parentNode?.removeChild(portalRoot)
    }
  }, [portalRoot])

  const onCloseClick = useCallback(() => {
    if (onClose) onClose(/* no args! */)
  }, [onClose])

  return ReactDOM.createPortal(
    <>
      <ModalBackdrop className={className} ICEenabled={phraseEnabled} isImpersonated={isImpersonated}>
        <ModalOutlineArea onClick={onClose} />
        <ModalDialog size={size} className={containerClassName} isImpersonated={isImpersonated}>
          {(modalTitle || closeButton) && (
            <ModalHeader padded={closeButton}>
              {modalTitle && <h3>{modalTitle}</h3>}
              {closeButton && <ModalClose icon="close-view" onClick={onCloseClick} />}
            </ModalHeader>
          )}
          {sidecar}
          <ModalContent>{children}</ModalContent>
          {trackId && <PageViewTracker trackId={trackId} trackData={trackData} />}
        </ModalDialog>
      </ModalBackdrop>
    </>,
    portalRoot
  )
}

export const GeneralModalStyles = createGlobalStyle`
  .modal-open {
    overflow: hidden;
  }
`

export const ModalBackdrop = styled.div<{ ICEenabled?: boolean; isImpersonated?: boolean }>`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: ${({ ICEenabled }) => (ICEenabled ? '52px' : 0)};
  top: ${({ isImpersonated }) => (isImpersonated ? '62px' : 0)};
  background: rgba(0, 0, 0, 0.5);
  z-index: 9993;
  overflow-y: auto;
`

export const ModalOutlineArea = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const ModalDialog = styled.div<{ size?: ModalSize; isImpersonated?: boolean }>`
  position: relative;
  width: 800px;
  padding: 28px 32px 16px;
  margin: auto;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1;
  h3 {
    text-transform: none;
    letter-spacing: 0;
    margin: 0;
  }
  ${mediaQuery.lessThan('tablet')`
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    border-radius: 0;
    padding: 32px 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  `}

  ${({ size, isImpersonated }) =>
    size === 'fullscreen' &&
    css`
      ${mediaQuery.greaterThan('tablet')`
        width: calc(100% - ${spacing.md * 2}px);
        height: calc(100% - ${spacing.md * 2}px);
        overflow-y: scroll;

        ${ModalHeader} {
          position: fixed;
          left: ${spacing.md + spacing.lg}px;
          right: ${spacing.md + spacing.lg}px;
          background: white;
          z-index: 2;
          padding-top: ${spacing.lg}px;
          padding-bottom: ${spacing.sm}px;
          top: ${isImpersonated ? spacing.md + 62 : spacing.md}px;

          ${ModalClose} {
            top: unset;
            bottom: ${spacing.xs}px;
          }
        }

        ${ModalContent} {
          padding: 64px 0 88px;
        }

        ${ModalBody} {
          max-width: 700px;
          margin: auto;
        }

        ${ModalFooter} {
          position: fixed;
          bottom: ${spacing.md}px;
          margin: 0;
          left: ${spacing.md}px;
          right: ${spacing.md}px;
          padding: 16px calc(49% - 350px);
        }
      `}
    `}
`

export const ModalHeader = styled.div<{ padded?: boolean }>`
  flex: none;
  position: relative;
  margin: 0 0 24px;
  min-height: 24px;
  max-height: none;
  ${({ padded }) => padded && 'padding-right: 63px;'}
  h2 {
    margin: 0;
  }
  ${mediaQuery.lessThan('tablet')`
    margin: 0 0 20px;
  `}
`
export const ModalClose = styled(IconButton)`
  position: absolute;
  display: block;
  top: -8px;
  right: 0;
  cursor: pointer;
`

export const ModalContent = styled.div`
  position: relative;

  ${mediaQuery.lessThan('tablet')`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  `}
`

export const ModalBody = styled.div`
  position: relative;
  ${mediaQuery.lessThan('tablet')`
    flex: 1;
  `}
`

type ModalFooterAlign = 'left' | 'right' | null

export const ModalFooter = styled.div<{ align?: ModalFooterAlign }>`
  display: flex;
  margin: 40px -32px -16px;
  padding: 16px 32px;
  border-top: 2px solid #000;
  position: sticky;
  bottom: 0;
  background: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: 5;

  ${mediaQuery.greaterThan<{ align?: ModalFooterAlign }>('tablet')`
    justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : null)};
  `};

  ${mediaQuery.lessThan('tablet')`
    display: block;
    bottom: -32px;
    margin: 20px -20px -32px;
    padding: 20px;
  `};
`

export const ModalFooterControl = styled(Button)`
  &:last-child {
    margin-left: auto;
  }
  &:first-child {
    margin-left: 0;
  }
  ${mediaQuery.lessThan('tablet')`
    display: block;
    width: 100%;
    & + & {
      margin-top: 8px;
    }
  `}
`

export const FullscreenModal = styled(Modal)<{ noFooter?: boolean }>`
  ${mediaQuery.greaterThan<{ noFooter?: boolean }>('tablet')`
    ${ModalDialog} {
      width: 100%;
      height: 100%;
    }

    ${ModalContent} {
      width: 100%;
      height: calc(100% - 32px);
    }

    ${ModalBody} {
      height: calc(100% - ${({ noFooter }) => (noFooter ? 32 : 122)}px);
    }

    ${ModalFooter} {
      position: absolute;
      bottom: 0;
      width: calc(100% + 64px);
      margin: 0 -32px;
    }
  `}
`
