import graphql from 'babel-plugin-relay/macro'
import { commitMutation, Environment } from 'react-relay'

import { ILocale } from '../../../intl'
import { assertServerCompatibleDeep } from '../../../utils/entityStatus'
import { createOrUpdateEventCreateMutation } from '../../../__generated__/createOrUpdateEventCreateMutation.graphql'
import IEventForm from '../types'
import { convertDraft } from './convertEvent'

const CREATE_DRAFT_MUTATION = graphql`
  mutation createOrUpdateEventCreateMutation($input: CreateDraftEventInput!) {
    createDraftEvent(input: $input) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      successful
      result {
        id
        state
        ...EventSubmission_event
      }
    }
  }
`

const UPDATE_DRAFT_MUTATION = graphql`
  mutation createOrUpdateEventUpdateMutation($input: UpdateDraftEventInput!) {
    updateDraftEvent(input: $input) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      successful
      result {
        id
        state
        ...EventSubmission_event
      }
    }
  }
`

const ADMIN_UPDATE_EVENT_MUTATION = graphql`
  mutation createOrUpdateEventAdminMutation($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      warnings
      result: event {
        id
        state
        ...EventSubmission_event
      }
    }
  }
`

type SaveFn = (
  environment: Environment,
  event: Partial<IEventForm>,
  locale: ILocale,
  partial?: boolean,
  admin?: boolean
) => Promise<Partial<IEventForm>>

const createOrUpdateEvent: SaveFn = async (environment, event, locale, partial, admin) => {
  const draft = await convertDraft(environment, event, !!admin, locale, partial)

  const input = {
    ...draft,
    id: event.id,
  }

  let mutation = CREATE_DRAFT_MUTATION
  if (event.id) {
    if (event.state !== 'DRAFT') {
      if (!admin) {
        throw new Error('Assertion failed: saveEvent called on non-draft - use minorUpdateSrvice instead')
      }
      mutation = ADMIN_UPDATE_EVENT_MUTATION
    } else {
      mutation = UPDATE_DRAFT_MUTATION
    }
  }

  return new Promise<IEventForm>((resolve, reject) => {
    commitMutation<createOrUpdateEventCreateMutation>(environment, {
      mutation,
      variables: {
        input: assertServerCompatibleDeep(input),
      },
      onCompleted: (data: any) => {
        const payload = data.updateEvent || data.updateDraftEvent || data.createDraftEvent
        if (!payload) {
          reject([])
          return
        }

        const { result, successful, messages, warnings } = payload
        if (data.updateEvent ? !!result : successful) {
          resolve(result)
        } else {
          reject({ messages: messages || warnings })
        }
      },
      onError: reject,
    })
  })
}

export default createOrUpdateEvent
