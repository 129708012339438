import React, { lazy, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useBlocker } from 'react-router-dom'

import styled from 'styled-components/macro'
import ClassList from 'classlist'

import { authContext } from '../../context/auth'
import Dashboard from './Dashboard'
import DashboardEmpty from './DashboardEmpty'
import useAutoPinnedEvent from './hooks/useAutoPinnedEvent'
import { breakpoints, color, zIndex } from '../../utils/variables'
import { ModalBackdrop } from '../../components/Modal'
import { localeContext } from '../../context/locale'
import ClickOutside from '../../components/ClickOutside'
import NewUserDashboard from './NewUserDashboard'

const EventOnTheDayDesktopContainer = styled(ClickOutside)`
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;

  height: 100%;
  background-color: ${color.white};

  overflow: auto;
  z-index: ${zIndex.modal};
`

const EventOnTheDayPage = lazy(() => import(/* webpackChunkName: "ev_otd" */ '../EventOnTheDay/EventOnTheDayPage'))

function DashboardPage() {
  const { user, hasPermission } = useContext(authContext)
  const { phraseEnabled } = useContext(localeContext)

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet}px)` })

  const canPin = !user.diceStaff && isMobile

  const [pinnedEventId, unpin] = useAutoPinnedEvent(canPin)

  const [showPin, setShowPin] = useState(!!pinnedEventId && canPin)
  const togglePin = useCallback(() => setShowPin((v) => !v), [])

  const prevPin = useRef(pinnedEventId)
  useEffect(() => {
    if (showPin || !canPin || prevPin.current === pinnedEventId) return

    prevPin.current = pinnedEventId
    setShowPin(true)
  }, [canPin, pinnedEventId, showPin])

  const hasPin = !!pinnedEventId && showPin
  const hasDashboard = !isMobile || !hasPin

  useEffect(() => {
    if (!hasDashboard || !hasPin) return
    const bodyClasses = ClassList(document.body)
    bodyClasses.add('modal-open')
    return () => {
      bodyClasses.remove('modal-open')
    }
  }, [hasDashboard, hasPin])

  const blocker = useBlocker(({ nextLocation }) => !hasDashboard && hasPin && nextLocation.pathname === '/dashboard')
  useEffect(() => {
    if (blocker.state !== 'blocked') return
    togglePin()
    blocker.reset()
  }, [blocker, hasDashboard, hasPin, togglePin])

  if (!user.hasEvent) {
    return hasPermission('create:event') ? <NewUserDashboard /> : <DashboardEmpty />
  }

  return (
    <>
      {hasDashboard && (
        <Dashboard
          pinnedEventId={pinnedEventId}
          onSwitchView={canPin ? togglePin : undefined}
          onUnpin={canPin ? unpin : undefined}
        />
      )}
      {hasPin &&
        pinnedEventId &&
        (isMobile ? (
          <EventOnTheDayPage eventId={pinnedEventId} onSwitchView={togglePin} />
        ) : (
          <ModalBackdrop ICEenabled={phraseEnabled}>
            <EventOnTheDayDesktopContainer active onClickOutside={togglePin}>
              {pinnedEventId && <EventOnTheDayPage eventId={pinnedEventId} onSwitchView={togglePin} onUnpin={unpin} />}
            </EventOnTheDayDesktopContainer>
          </ModalBackdrop>
        ))}
    </>
  )
}

export default DashboardPage
