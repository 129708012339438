import { FC, memo, useLayoutEffect, useRef } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import { isEmpty } from 'lodash/fp'

import errorsToTouched from '../utils/errorsToTouched'

const ScrollToError: FC<
  React.PropsWithChildren<{
    doScroll: (errors: FormikErrors<any>) => void
    errors: FormikErrors<any>
    setTouched: (t: FormikTouched<any>) => void
  }>
> = ({ doScroll, errors, setTouched }) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const triggered = useRef<boolean>(false)

  useLayoutEffect(() => {
    if (!errors || isEmpty(errors) || triggered.current) return

    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setTouched(errorsToTouched(errors))
      doScroll(errors)
      triggered.current = true
    }, 300)
  }, [doScroll, errors, setTouched])

  return null
}

export default memo(ScrollToError)
