/* eslint-disable max-lines */
import React, {
  FC,
  memo,
  useMemo,
  useCallback,
  HTMLProps,
  useRef,
  useState,
  forwardRef,
  useEffect,
  useLayoutEffect,
} from 'react'
import styled, { createGlobalStyle, css } from 'styled-components/macro'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { isArray, some, isNaN, includes, concat } from 'lodash/fp'
import cn from 'classnames'
import 'react-datepicker/dist/react-datepicker.css'
import { parse, endOfDay, format } from 'date-fns'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import ClassList from 'classlist'

import Svg from './Svg'
import { dateFnsLocales, DEFAULT_LOCALE, ILocale } from '../intl'
import { parseAtTimezone, formatAtTimezone, IDatePredicate, parseFreeformDate, isValidDate } from '../utils/calendar'
import { USE_12HR, DATETIME_FORMATS } from '../utils/formatters/datetime'
import IconButton from './IconButton'
import { zIndex, color, font, mediaQuery, breakpoints, spacing } from '../utils/variables'
import Button from './Button'

const InlineSvg = styled(Svg)`
  position: absolute;
  margin: 8px;
  z-index: 1;

  &.-disabled {
    color: ${color.darkgrey};
  }
`

export interface IDateTimePickerProps {
  name: string
  locale?: ILocale | null
  timezone?: string
  setFieldValue: (name: string, value: any) => void
  disabledDates?: Array<IDatePredicate>
  minDate?: Date | null
  maxDate?: Date | null
  clearable?: boolean
  enableRange?: boolean
  value?: string | null | [string | null, string | null]
  className?: string
  disabled?: boolean
  onlyButton?: boolean
  required?: boolean
  hasError?: boolean
  placeholder?: string
  placeholderDate?: string | null
  onBlur?: (e: Event) => void
  placement?: ReactDatePickerProps['popperPlacement']
  setPlaceholderOnClick?: boolean
  mode?: 'year' | 'month' | 'dateTime' | 'date' | 'time'
}

function isInViewport(element: HTMLElement): boolean {
  const rect = element.getBoundingClientRect()
  const html = document.documentElement
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  )
}

// eslint-disable-next-line no-unused-vars
const DatePickerButton = forwardRef((props: HTMLProps<HTMLInputElement>, _ref: any) => {
  return <IconButton icon="date-picker" className={props.className} onClick={props.onClick} />
})

interface IStyledPickerContainerProps {
  clearButtonVisible?: boolean
  mode?: IDateTimePickerProps['mode']
  enableRange?: boolean
}

const StyledPickerContainer = styled.div<IStyledPickerContainerProps>`
  .react-datepicker__today-button {
    ${mediaQuery.lessThan<IStyledPickerContainerProps>('desktop')`
      ${(p) => p.mode === 'dateTime' && `bottom: ${p.clearButtonVisible ? 250 : 208}px`}
      ${(p) => p.clearButtonVisible && p.mode === 'date' && 'bottom: 40px'}
    `}
  }

  ${({ mode, enableRange }) =>
    !includes(mode, ['year', 'month']) && !enableRange
      ? css`
          .react-datepicker__month-container {
            padding-bottom: 47px;
          }
        `
      : ''}
`

const ClearButton = styled.button`
  height: 42px;
  color: ${color.primary};
  font-weight: ${font.weight.bold};
  font-size: ${font.size.sm}px;
  padding: ${spacing.sm}px;
  background: ${color.white};
  border: none;
  border-radius: 8px;
  box-shadow: 0px -2px 10px ${color.grey};
  z-index: 1;

  ${mediaQuery.greaterThan('desktop')`
    display: none;
  `}
`

const DateTimePicker: FC<React.PropsWithChildren<IDateTimePickerProps>> = ({
  name,
  locale,
  setFieldValue,
  value,
  timezone,
  disabled,
  required,
  hasError,
  disabledDates,
  enableRange,
  className,
  onlyButton,
  placeholder,
  placeholderDate,
  onBlur,
  placement,
  setPlaceholderOnClick,
  mode = 'dateTime',
  minDate,
  maxDate,
  clearable,
}) => {
  const intl = useIntl()
  const is24hr = useMemo(() => !USE_12HR.has(locale || DEFAULT_LOCALE), [locale])
  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.desktop}px)` })

  const disableDate = useMemo(() => mode === 'time', [mode])
  const disableTime = useMemo(() => includes(mode, ['date', 'month', 'year']), [mode])
  const usePortalVersion = isTablet && !disableDate

  const smartParsing = !disableDate && !enableRange && !usePortalVersion

  const [highlight, setHighlight] = useState<Array<Date>>([])

  const onChange = useCallback(
    (v: Date | null) => {
      setHighlight([])

      if (setFieldValue) {
        if (disableDate) {
          setFieldValue(name, v && intl.formatDate(v, { ...DATETIME_FORMATS.TIME_24HR }))
        } else if (!enableRange) {
          setFieldValue(name, formatAtTimezone(v, timezone))
        } else if (v && isArray(v)) {
          setFieldValue(name, [
            v[0] ? formatAtTimezone(v[0], timezone) : null,
            v[1] ? formatAtTimezone(endOfDay(v[1]), timezone) : null,
          ])
        }
      }
    },
    [disableDate, enableRange, intl, name, setFieldValue, timezone]
  )

  const tzValue: Date | (Date | null)[] | string | null = useMemo(() => {
    if (disableDate) {
      if (!value) return null
      try {
        const strTime = value as string
        const now = new Date()

        const str = strTime
          .toLowerCase()
          .replace(/\.\s*/g, '')
          .replace(/[^0-9amp :]/g, '')
        const format = str.match(/(a|p)m/) ? 'h:mm a' : 'HH:mm'
        const dt = parse(strTime, format, now)

        return !isNaN(dt.getTime()) ? dt : null
      } catch (e) {
        console.error(e)
        return null
      }
    }
    if (!enableRange && !isArray(value)) return value ? parseAtTimezone(value, timezone) : null
    if (isArray(value)) {
      const dOne = parseAtTimezone(value[0], timezone)
      const dTwo = parseAtTimezone(value[1], timezone)
      return dOne || dTwo ? [dOne, dTwo] : null
    }
    return null
  }, [disableDate, enableRange, value, timezone])

  const ref = useRef<any>(null)

  const [manualMode, setManualMode] = useState(false)

  const [focused, setFocused] = useState(false)
  const prevFocused = useRef(focused)
  useEffect(() => {
    if (!focused && prevFocused.current) {
      setManualMode(false)
      if (highlight.length > 0) {
        onChange(highlight[0])
      }
      if (onBlur) {
        onBlur({ target: { name } } as any)
      }
    }
    prevFocused.current = focused
  }, [focused, highlight, name, onBlur, onChange])

  const onKeyDown = useCallback(
    (e: any) => {
      if (manualMode && (e.keyCode === 13 || e.keyCode === 10)) {
        setManualMode(false)
        if (highlight.length > 0) {
          onChange(highlight[0])
        }
      }

      if (manualMode || e.key.length > 1) return
      setManualMode(true)
    },
    [highlight, manualMode, onChange]
  )

  const filterDate = useCallback(
    (dt: Date) =>
      disabledDates && disabledDates.length > 0 && !manualMode ? !some((f) => f(dt, 'date'), disabledDates) : true,
    [disabledDates, manualMode]
  )

  const filterTime = useCallback(
    (t: Date) =>
      disabledDates && disabledDates.length > 0 && !manualMode
        ? !some((f) => {
          if (!tzValue || isArray(tzValue)) return true
          const date = new Date(tzValue)
          date.setHours(t.getHours())
          date.setMinutes(t.getMinutes())
          date.setSeconds(t.getSeconds())
          date.setMilliseconds(t.getMilliseconds())
          return f(date, 'time')
        }, disabledDates)
        : true,
    [disabledDates, manualMode, tzValue]
  )

  const timeFormat = useMemo(() => (is24hr ? 'HH:mm' : 'h:mm a'), [is24hr])

  const popperMods = useMemo(
    () => [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: true,
          rootBoundary: 'viewport',
        },
      },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: concat(onlyButton ? ['top', 'bottom'] : [], [
            'top-start',
            'top-end',
            'bottom-start',
            'bottom-end',
          ]),
        },
      },
    ],
    [onlyButton]
  )

  const handleCalendarOpen = useCallback(() => {
    const dp = ref.current

    const node: HTMLElement | undefined | null = dp?.calendar?.componentNode
    setTimeout(() => {
      if (node && !isInViewport(node)) {
        node.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' })
      }
    }, 200)

    setFocused(true)

    if (usePortalVersion) {
      const portal = document.getElementById('mobile-date-picker-portal')
      if (portal) {
        const bodyClasses = ClassList(document.body)
        bodyClasses.add('modal-open')

        const portalClasses = ClassList(portal)
        portalClasses.add('open')
      }
    }
  }, [usePortalVersion])

  const handleCalendarClose = useCallback(() => {
    setFocused(false)
    if (usePortalVersion) {
      setTimeout(() => {
        const portal = document.getElementById('mobile-date-picker-portal')
        if (portal) {
          const portalClasses = ClassList(portal)
          portalClasses.remove('open')

          const bodyClasses = ClassList(document.body)
          bodyClasses.remove('modal-open')
        }
      }, 0)
    }
  }, [usePortalVersion])

  const doFocus = useCallback(() => {
    const dp = ref.current
    if (dp) {
      dp.setFocus()
    }
  }, [])

  useLayoutEffect(() => {
    if (ref.current) {
      const input: HTMLInputElement = ref.current.input
      if (input) {
        input.autocomplete = 'off'
        input.readOnly = usePortalVersion
      }
    }
  }, [usePortalVersion])

  const dateFormat = useMemo(() => {
    switch (mode) {
      case 'year':
        return 'yyyy'
      case 'month':
        return 'MMMM yyyy'
      case 'time':
        return timeFormat
      case 'date':
        return 'EEE, PP'
      case 'dateTime':
      default:
        return `EEE, PP, ${timeFormat}`
    }
  }, [mode, timeFormat])

  const placeholderText = useMemo(() => {
    if (placeholder) return placeholder
    if (placeholderDate) {
      const parsed = parseAtTimezone(placeholderDate, timezone)
      return parsed ? format(parsed, dateFormat) : undefined
    }
    return undefined
  }, [dateFormat, placeholder, placeholderDate, timezone])

  const openToDate = useMemo(() => {
    if (placeholderDate) {
      return !isArray(tzValue) ? tzValue || parseAtTimezone(placeholderDate, timezone) || undefined : undefined
    }
    return undefined
  }, [placeholderDate, timezone, tzValue])

  const onFocus = useMemo(
    () =>
      setPlaceholderOnClick && openToDate && placeholderDate && !tzValue
        ? () => {
          onChange(openToDate || null)
        }
        : undefined,
    [onChange, openToDate, placeholderDate, tzValue, setPlaceholderOnClick]
  )

  const onInputChange = useCallback(
    (e: any) => {
      const str = e.target.value || ''

      const startOfWeekDay = locale === 'en-US' || locale === 'en-CA' ? 0 : 1

      let dt: Date | null = parse(str, dateFormat, new Date(), {
        locale: dateFnsLocales[locale || DEFAULT_LOCALE],
        weekStartsOn: startOfWeekDay,
      })

      if (!dt || !isValidDate(dt)) {
        dt = parseFreeformDate(str, locale)
      }

      if (dt && isValidDate(dt) && ref.current) {
        ref.current.setPreSelection(dt)
        setHighlight([dt])
      } else {
        setHighlight([])
      }
    },
    [dateFormat, locale]
  )

  const clearDate = useCallback(() => {
    setFieldValue(name, null)
    ref.current?.setOpen(false)
  }, [name, setFieldValue])

  const clearButtonVisible = useMemo(() => !!value && !required, [value, required])

  const DatePickerContainer: FC<React.PropsWithChildren<{ className: string }>> = React.memo(
    ({ className, children }) => {
      return (
        <StyledPickerContainer
          className={className}
          clearButtonVisible={clearButtonVisible}
          mode={mode}
          enableRange={enableRange}
        >
          {children}
          {clearButtonVisible && (
            <ClearButton onClick={clearDate}>{intl.formatMessage({ id: 'datepicker.clear_button' })}</ClearButton>
          )}
        </StyledPickerContainer>
      )
    }
  )

  return (
    <div>
      {!onlyButton && (
        <InlineSvg
          icon={disableDate ? 'clock' : 'date-picker'}
          onClick={doFocus}
          className={cn({
            '-disabled': disabled,
          })}
        />
      )}
      <DatePicker
        calendarContainer={DatePickerContainer}
        name={name}
        placeholderText={placeholderText}
        openToDate={openToDate}
        showPopperArrow={false}
        className={cn(className, { 'text-input_input': !onlyButton, '-left-pad': !onlyButton })}
        wrapperClassName={cn(
          { 'text-input': !onlyButton },
          {
            '-focus': focused,
            '-disabled': disabled,
            '-required': required,
            '-has-error': hasError,
          }
        )}
        selected={isArray(tzValue) ? tzValue[0] : tzValue}
        startDate={enableRange && isArray(tzValue) ? tzValue[0] : undefined}
        endDate={enableRange && isArray(tzValue) ? tzValue[1] : undefined}
        onChange={onChange}
        showTimeSelect={!disableTime}
        showTimeSelectOnly={disableDate}
        timeIntervals={15}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        locale={dateFnsLocales[locale || DEFAULT_LOCALE]}
        /*
          We don't use custom input for input styling because of various
          bugs due to use of React.cloneElement and ref inside of datepicker
        */
        customInput={onlyButton ? <DatePickerButton /> : undefined}
        disabled={disabled}
        filterDate={disableDate ? undefined : filterDate}
        filterTime={disableTime ? undefined : filterTime}
        timeCaption={intl.formatMessage({ id: 'datepicker.time_header' })}
        selectsRange={enableRange}
        shouldCloseOnSelect={!enableRange}
        popperPlacement={placement || 'bottom-start'}
        popperModifiers={popperMods}
        closeOnScroll={onlyButton}
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}
        ref={ref}
        showMonthDropdown
        showYearDropdown
        showYearPicker={mode === 'year'}
        showMonthYearPicker={mode === 'month'}
        yearDropdownItemNumber={2}
        dropdownMode="select"
        disabledKeyboardNavigation
        todayButton={
          !includes(mode, ['year', 'month']) && !enableRange ? (
            <Button preset="secondary">{intl.formatMessage({ id: 'datepicker.today_button' })}</Button>
          ) : undefined
        }
        fixedHeight={!onlyButton && !disableTime}
        onKeyDown={onKeyDown}
        portalId={usePortalVersion ? 'mobile-date-picker-portal' : undefined}
        onFocus={onFocus}
        highlightDates={highlight}
        onChangeRaw={smartParsing ? onInputChange : undefined}
        strictParsing={smartParsing}
        minDate={minDate}
        maxDate={maxDate}
        isClearable={clearable}
      />
    </div>
  )
}

const CHEVRON_LEFT_SVG =
  // eslint-disable-next-line max-len
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5586 18.1436L9.26459 11.8496L15.5586 5.55655L14.1436 4.14355L6.43659 11.8496L14.1436 19.5576L15.5586 18.1436Z" fill="currentColor"/></svg>'

const CHEVRON_RIGHT_SVG =
  // eslint-disable-next-line max-len
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.43555 5.55762L13.7295 11.8516L7.43555 18.1446L8.85055 19.5576L16.5575 11.8516L8.85055 4.14362L7.43555 5.55762Z" fill="currentColor"/></svg>'

export const DateTimePickerStyles = createGlobalStyle`
  #mobile-date-picker-portal {
    display: none;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.max + 1};

    overflow-y: auto;

    & > div {
      margin: auto;
    }

    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none;

    &.open {
      pointer-events: auto;

      ${mediaQuery.lessThan('desktop')`
        display: flex;
      `}
    }

    .react-datepicker-popper {
      position: relative !important;
      transform: none !important;
      margin: 0;
    }
  }

  .react-datepicker {
    display: flex;
    flex-wrap: nowrap;

    font-family: ${font.family.base};

    border: none;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);

    ${mediaQuery.lessThan('desktop')`
      flex-direction: column;
    `}
  }

  .react-datepicker-popper {
    z-index: ${zIndex.tooltip};

    ${mediaQuery.lessThan('desktop')`
      z-index: ${zIndex.max};
    `}
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__time-container, .react-datepicker__time-container--with-today-button {
    display: block;
    float: none;
    border-left: 2px solid ${color.text};
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
    width: 112px;
    position: unset;
    top: unset;
    right: unset;

    ${mediaQuery.lessThan('desktop')`
      width: 100%;
      border-left: none;
      border-top: 2px solid ${color.text};
    `}
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;

    &.-disabled {
      color: ${color.darkgrey};
      background: ${color.palegrey};
    }
  }

  .react-datepicker__input-container {
    input.-left-pad {
      margin-left: 22px;
      width: calc(100% - 22px);
    }
  }

  .react-datepicker-time__header {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid ${color.lightgrey};
  }

  .react-datepicker__time-list {
    ${mediaQuery.lessThan('desktop')`
      max-height: 150px;
    `}
  }

  .react-datepicker__time-list-item {
    font-size: ${font.size.base}px;
  }

  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
    background-color: ${color.primary};
    font-weight: normal;
  }

  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
    background-color: ${color.primary};
    font-weight: normal;
  }

  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
    padding: 6px 10px;
    margin: 4px 0;
  }

  .react-datepicker__navigation {
    width: 40px;
    height: 40px;
    top: 8px !important;
    border: 2px solid transparent;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      border: 2px solid ${color.primary};
    }
  }

  .react-datepicker__navigation--next--with-time {
    right: 118px;

    ${mediaQuery.lessThan('desktop')`
      right: 10px;
    `}
  }

  .react-datepicker__navigation--previous {
    left: 10px;
    background-image: url('data:image/svg+xml;utf8,${CHEVRON_LEFT_SVG}');
  }

  .react-datepicker__navigation--next {
    background-image: url('data:image/svg+xml;utf8,${CHEVRON_RIGHT_SVG}');
  }

  .react-datepicker__navigation-icon {
    display: none;
  }

  .react-datepicker__day-names {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 0.4rem;
    margin: 0;

    background-color: ${color.white};
    border-bottom: 1px solid ${color.lightgrey};

    .react-datepicker__day-name {
      flex: 1;

      color: ${color.text};
      font-weight: normal;
      text-transform: uppercase;
      font-size: ${font.size.xs}px;
      letter-spacing: 0.1em;
    }
  }

  .react-datepicker__header {
    background-color: ${color.white};
    border: none;
    padding: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__header__dropdown {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    select {
      background-color: transparent;
      border: none;
      font-size: ${font.size.base}px;
      font-weight: bold;
      outline: none;
      appearance: none;
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;

      &:hover {
        background-color: ${color.palegrey};
      }
    }
  }

  .react-datepicker__navigation {
    top: 16px;

    &:focus {
      outline: none;
    }
  }

  .react-datepicker-year-header, .react-datepicker-month-header {
    line-height: 56px;
  }

  .react-datepicker__year-wrapper {
    max-width: 280px;
  }

  .react-datepicker__year {
    .react-datepicker__year-text {
      width: 88px;
      line-height: 40px;
      font-size: ${font.size.base}px;
      &--selected, &--selected:hover,
      &--keyboard-selected, &--keyboard-selected:hover {
        background-color: ${color.primary};
      }
    }
  }

  .react-datepicker__month {
    .react-datepicker__month-text {
      width: 88px;
      line-height: 40px;
      font-size: ${font.size.base}px;
      &--keyboard-selected, &--keyboard-selected:hover {
        background-color: ${color.primary};
      }
    }
    &--selected, &--selected:hover {
      background-color: ${color.primary};
    }
  }

  .react-datepicker__week {
    display: flex;
    flex-wrap: nowrap;
    margin: 4px 0;
    position: relative;
    z-index: 1;
  }

  .react-datepicker__day {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 36px;
    margin: 0;

    font-size: ${font.size.base}px;
    border-radius: 100%;
    outline: none;

    border: 2px solid ${color.white};

    &:hover {
      border-radius: 100%;
    }
  }

  .react-datepicker__day--outside-month {
    opacity: 0.2;
  }

  .react-datepicker__day--disabled {
    color: ${color.palegrey};
  }

  .react-datepicker__day--highlighted {
    && {
      color: ${color.white};
    }
    background-color: ${color.secondary};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: inherit;

    &:hover {
      background-color: inherit;
      color: inherit;
    }
  }

  .react-datepicker__day--today {
    color: ${color.primary};
    font-weight: normal;

    &.react-datepicker__day--disabled {
      opacity: 0.5;
    }
  }

  .react-datepicker__day--in-range.react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range {
    background-color: ${color.palepurple};
    color: ${color.text};
    border-radius: 0;
    border: 2px solid ${color.palepurple};
    opacity: 1;

    &:hover {
      border-radius: 0;
      background-color: ${color.palepurple};
    }

    &:first-child {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
    }

    &:last-child {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
    }
  }

  .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start,
  .react-datepicker__day--range-start.react-datepicker__day--range-start,
  .react-datepicker__day--range-end.react-datepicker__day--range-end,
  .react-datepicker__day--selected.react-datepicker__day--selected
  {
    background-color: ${color.primary};
    color: ${color.white};
    border-radius: 100%;
    border: 2px solid ${color.white};

    &:hover {
      background-color: ${color.primary};
      color: ${color.white};
      border-radius: 100%;
    }
  }

  .react-datepicker__day--selecting-range-start:not(:last-child)::before,
  .react-datepicker__day--range-start:not(:last-child)::before {
    content: ' ';
    display: block;
    position: absolute;
    width: 20px;
    height: 40px;
    left: 50%;
    top: -2px;
    z-index: -1;
    background-color: ${color.palepurple};
  }

  .react-datepicker__day--range-end:not(:first-child)::before {
    content: ' ';
    display: block;
    position: absolute;
    width: 20px;
    height: 40px;
    right: 50%;
    top: -2px;
    z-index: -1;
    background-color: ${color.palepurple};
  }

  .react-datepicker__day--range-start.react-datepicker__day--range-end {
    &::before {
      display: none;
    }
  }

  .react-datepicker__today-button {
    position: absolute;
    bottom: 0;
    width: 292px;

    background: none;
    border: none;
    cursor: unset;
    padding: 16px;

    .button {
      width: 100%;
      height: 32px;
      background-color: ${color.lightgrey};
      color: ${color.text};
      border-color: ${color.lightgrey};

      &:hover {
        opacity: 0.9;
      }
    }

    .button_content, .button_container {
      font-size: ${font.size.xs}px;
      margin: 0;
    }
  }

  .react-datepicker--time-only {
    .react-datepicker__time-container {
      border-radius: 8px;
      border: none;
    }

    .react-datepicker__time {
      border-radius: 8px;
    }

    .react-datepicker__time-list {
      ${mediaQuery.lessThan('desktop')`
        max-height: unset;
      `}
    }
  }

  ul.react-datepicker__time-list {
    ${mediaQuery.greaterThan('desktop')`
        height: 353px !important;
      `}
  }

  .react-datepicker__close-icon {
    padding-right: 8px;

    ::after {
      background: none;
      color: ${color.grey};
      width: 24px;
      height: 24px;
      padding: 0;

      font-weight: ${font.weight.light};
      font-size: 30px;
      line-height: 24px;
    }

    :hover::after {
      color: ${color.text};
    }
  }
`

export default memo(DateTimePicker)
