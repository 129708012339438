import styled from 'styled-components/macro'
import { color, font, mediaQuery } from '../utils/variables'

export const Block = styled.div`
  max-width: 100%;
  padding: 32px;

  ${mediaQuery.lessThan('tablet')`
    padding: 16px;
  `}
`

export const SubSection = styled(Block)<{ noBorder?: boolean }>`
  & + & {
    border-left: 2px solid ${color.text};
  }

  && {
    ${({ noBorder }) => (noBorder ? 'border-left: none;' : '')}
  }

  ${mediaQuery.lessThan('tablet')`
    & + & {
      border-left: none;
      border-top: 2px solid ${color.text};
    }
  `}

  ${mediaQuery.lessThan('desktopLarge')`
    max-width: calc(50vw - 37px);
  `}

  ${mediaQuery.lessThan('tablet')`
    min-width: 100vw;
  `}
`

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;

  & + & {
    border-top: 2px solid ${color.text};
  }

  ${Block} {
    flex: 1;
    min-width: 0;

    ${mediaQuery.lessThan('tablet')`
      min-width: 100vw;
    `}
  }

  ${mediaQuery.lessThan('tablet')`
    flex-direction: column;
  `}
`

export const SectionTitle = styled.h2`
  margin-bottom: 32px;
  font-weight: bold;
  font-size: ${font.size.base}px;
`
