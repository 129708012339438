import React, { memo, useState, useCallback, useMemo, useEffect, FC, useContext } from 'react'
import styled from 'styled-components/macro'
import { useIntl, IntlShape } from 'react-intl'
import { useFormikContext } from 'formik'
import { get, getOr, map, find, compose, sortBy, compact, reject } from 'lodash/fp'
import { fetchQuery_DEPRECATED, useRelayEnvironment } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { Link } from 'react-router-dom'
import { differenceInDays, endOfMonth, getDate, getWeekOfMonth } from 'date-fns'

import { mediaQuery, color } from '../../../utils/variables'

import FormField, { FlexFormField } from '../../../components/FormField'
import Button from '../../../components/Button'
import Svg from '../../../components/Svg'

import { EventRecurringScheduleQuery } from '../../../__generated__/EventRecurringScheduleQuery.graphql'
import { DATETIME_FORMATS } from '../../../utils/formatters/datetime'
import { localeContext } from '../../../context/locale'
import IEventFormSettings from '../types/Settings'
import IconButton from '../../../components/IconButton'
import { parseAtTimezone } from '../../../utils/calendar'
import { dateFnsLocales } from '../../../intl'

const RecurringButton = styled(Button)`
  line-height: 24px;

  svg {
    margin-bottom: -8px;
    margin-right: 8px;
  }
`

const RecurringEvent = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 8px;
  }

  & > div {
    flex: 1;
    margin-right: 8px;
  }

  ${mediaQuery.lessThan('desktop')`
    flex-direction: column;

    & > div {
      margin-right: 0;
      margin-top: 8px;
    }
  `}
`

const RecurringEventContainer = styled.div`
  position: relative;
  background-color: ${color.white};

  ${mediaQuery.lessThan('desktop')`
    width: calc(100% - 32px);
  `}
`

const Schedule = styled.div`
  margin-top: 16px;
  margin-bottom: -8px;
`

const ScheduleItem = styled.div`
  background-color: ${color.lightgrey};
  border-radius: 4px;
  display: inline-block;
  padding: 4px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
`

// eslint-disable-next-line no-unused-vars
const RemoveButton = styled(({ extraOffset, ...props }) => <IconButton {...props} />)<{ extraOffset: boolean }>`
  ${mediaQuery.lessThan<{ extraOffset: boolean }>('desktop')`
    margin: 0;
    position: absolute;
    right: -40px;
    top: -${({ extraOffset }) => (extraOffset ? 74 : 48)}px;
  `}
`

const Placeholder = styled.div`
  margin-bottom: 32px;

  ${mediaQuery.lessThan('tablet')`
    display: none;
  `}
`

const toOptions = (intl: IntlShape, key: string) => (value: string) => ({
  value,
  label: intl.formatMessage({ id: `${key}.${value.toLowerCase()}` }),
})

const EVENT_SCHEDULE_QUERY = graphql`
  query EventRecurringScheduleQuery(
    $announceDate: Time!
    $onSaleDate: Time!
    $offSaleDate: Time!
    $date: Time!
    $endDate: Time!
    $frequency: RepeatFrequency!
    $repeatEnds: RepeatEnds!
    $occurrences: Int
    $until: Time
    $repeatOn: RepeatOn
    $timezoneName: String
  ) {
    viewer {
      schedule: recurrentEventsSchedule(
        announceDate: $announceDate
        onSaleDate: $onSaleDate
        offSaleDate: $offSaleDate
        date: $date
        endDate: $endDate

        frequency: $frequency
        repeatEnds: $repeatEnds
        occurrences: $occurrences
        until: $until
        timezoneName: $timezoneName
        repeatOn: $repeatOn
      ) {
        date
      }
    }
  }
`

const EventRecurring: FC<React.PropsWithChildren<{ disabled?: boolean }>> = ({ disabled }) => {
  const intl = useIntl()
  const { locale } = useContext(localeContext)
  const environment = useRelayEnvironment()

  const frequencyOptions = useMemo(
    () =>
      map(toOptions(intl, 'new_event.timeline.recurring.frequency.options'), [
        'BI_WEEKLY',
        'DAILY',
        'MONTHLY',
        'WEEKLY',
      ]),
    [intl]
  )

  const repeatEndsOptions = useMemo(
    () => map(toOptions(intl, 'new_event.timeline.recurring.ends_at.options'), ['OCCURRENCES', 'UNTIL']),
    [intl]
  )

  const { values: event, setFieldValue, handleBlur, touched, errors } = useFormikContext<IEventFormSettings>()

  const calendarRestrictions = useMemo(() => {
    const date: Date | null = event.date ? parseAtTimezone(event.date, event.timezoneName || undefined) : null
    const startOfWeekDay = locale === 'en-US' || locale === 'en-CA' ? 0 : 1

    return date
      ? {
        sameDayAllowed: getDate(date) <= 28,
        lastWeekDayAllowed: differenceInDays(endOfMonth(date), date) <= 7,
        sameWeekAndDayAllowed:
            getWeekOfMonth(date, {
              locale: dateFnsLocales[locale],
              weekStartsOn: startOfWeekDay,
            }) <= 4,
      }
      : {
        sameDayAllowed: false,
        lastWeekDayAllowed: false,
        sameWeekAndDayAllowed: false,
      }
  }, [event.date, event.timezoneName, locale])

  const repeatOnOptions = useMemo(
    () =>
      compose([
        map(toOptions(intl, 'new_event.timeline.recurring.repeat_on.options')),
        reject((it) => it === 'SAME_DAY' && !calendarRestrictions.sameDayAllowed),
        reject((it) => it === 'LAST_WEEK_DAY' && !calendarRestrictions.lastWeekDayAllowed),
        reject((it) => it === 'SAME_WEEK_AND_DAY' && !calendarRestrictions.sameWeekAndDayAllowed),
      ])(['LAST_WEEK_DAY', 'SAME_DAY', 'SAME_WEEK_AND_DAY']),
    [
      calendarRestrictions.lastWeekDayAllowed,
      calendarRestrictions.sameDayAllowed,
      calendarRestrictions.sameWeekAndDayAllowed,
      intl,
    ]
  )

  const values: Partial<IEventFormSettings['recurrentEventSchedule']> = event.recurrentEventSchedule || {}

  const [schedule, setSchedule] = useState<Array<{ date: string; id?: string }>>([])

  const active = !!event.recurrentEventSchedule?.frequency

  const activate = useCallback(() => {
    setFieldValue('recurrentEventSchedule.frequency', 'DAILY')
    setSchedule([])
  }, [setFieldValue])

  const passivate = useCallback(() => {
    setFieldValue('recurrentEventSchedule', null)
    setSchedule([])
  }, [setFieldValue])

  const setFrequency = useCallback((id: any) => setFieldValue('recurrentEventSchedule.frequency', id), [setFieldValue])
  const setRepeatOn = useCallback((id: any) => setFieldValue('recurrentEventSchedule.repeatOn', id), [setFieldValue])
  const setRepeatEnds = useCallback(
    (id: any) => {
      setFieldValue('recurrentEventSchedule.repeatEnds', id)
      if (id === 'UNTIL') {
        setFieldValue('recurrentEventSchedule.occurrences', null)
      } else if (id === 'OCCURRENCES') {
        setFieldValue('recurrentEventSchedule.until', null)
      }
    },
    [setFieldValue]
  )

  useEffect(() => {
    const isRecurringEventPartAlready = (event.recurrentEventsGroup?.length || 0) > 1

    if (isRecurringEventPartAlready) {
      setSchedule(compose([compact, sortBy('date')])(event.recurrentEventsGroup || []))
      return
    }

    if (!(event.date && event.endDate && event.announceDate && event.onSaleDate && event.offSaleDate)) return
    if (!values.frequency) return
    if (!values.repeatEnds) return
    if (values.repeatEnds === 'OCCURRENCES' && !values.occurrences) return
    if (values.repeatEnds === 'UNTIL' && !values.until) return
    if (values.frequency === 'MONTHLY' && !values.repeatOn) return

    let stillNeeded = true
    fetchQuery_DEPRECATED<EventRecurringScheduleQuery>(environment, EVENT_SCHEDULE_QUERY, {
      frequency: values.frequency,
      repeatEnds: values.repeatEnds,
      repeatOn: values.repeatOn,
      occurrences: values.occurrences,
      until: values.until,
      date: event.date,
      endDate: event.endDate,
      announceDate: event.announceDate,
      onSaleDate: event.onSaleDate,
      offSaleDate: event.offSaleDate,
      timezoneName: event.timezoneName,
    })
      .then((rs: EventRecurringScheduleQuery['response']) => {
        if (stillNeeded) {
          setSchedule(getOr([], 'viewer.schedule', rs))
        }
      })
      .catch(console.error)
    return () => {
      stillNeeded = false
    }
  }, [
    values.frequency,
    values.repeatEnds,
    values.repeatOn,
    values.occurrences,
    values.until,
    event.date,
    event.endDate,
    event.announceDate,
    event.onSaleDate,
    event.offSaleDate,
    event.timezoneName,
    event.recurrentEventsGroup,
    environment,
  ])

  const frequencyOption = useMemo(
    () => find(['value', values.frequency], frequencyOptions),
    [frequencyOptions, values.frequency]
  )

  const repeatEndsOption = useMemo(
    () => find(['value', values.repeatEnds], repeatEndsOptions),
    [repeatEndsOptions, values.repeatEnds]
  )

  const repeatOnOption = useMemo(
    () => values.repeatOn && toOptions(intl, 'new_event.timeline.recurring.repeat_on.options')(values.repeatOn),
    [intl, values.repeatOn]
  )

  const handleOccurencesChange = useCallback(
    (e: any) => {
      setFieldValue(
        'recurrentEventSchedule.occurrences',
        e.target.value ? parseInt(e.target.value.replace(/[^0-9]/, ''), 10) : ''
      )
    },
    [setFieldValue]
  )

  const isRecurringEventPartAlready = (event.recurrentEventsGroup?.length || 0) > 1

  return active ? (
    <>
      <RecurringEventContainer>
        <RecurringEvent>
          <FormField
            name="recurrentEventSchedule.frequency"
            onChange={setFrequency}
            value={frequencyOption}
            placeholder={intl.formatMessage({ id: 'new_event.timeline.recurring.frequency.placeholder' })}
            control="select"
            options={frequencyOptions}
            onBlur={handleBlur}
            disabled={disabled || isRecurringEventPartAlready}
            error={get('recurrentEventSchedule.frequency', touched) && get('recurrentEventSchedule.frequency', errors)}
          />
          {values.frequency === 'MONTHLY' ? (
            <FormField
              name="recurrentEventSchedule.repeatOn"
              onChange={setRepeatOn}
              value={repeatOnOption}
              placeholder={intl.formatMessage({ id: 'new_event.timeline.recurring.repeat_on.placeholder' })}
              control="select"
              options={repeatOnOptions}
              onBlur={handleBlur}
              disabled={disabled || isRecurringEventPartAlready}
              error={get('recurrentEventSchedule.repeatOn', touched) && get('recurrentEventSchedule.repeatOn', errors)}
            />
          ) : null}
          <FormField
            name="recurrentEventSchedule.repeatEnds"
            onChange={setRepeatEnds}
            value={repeatEndsOption}
            placeholder={intl.formatMessage({ id: 'new_event.timeline.recurring.ends_at.placeholder' })}
            control="select"
            options={repeatEndsOptions}
            onBlur={handleBlur}
            disabled={disabled || isRecurringEventPartAlready}
            error={
              get('recurrentEventSchedule.repeatEnds', touched) && get('recurrentEventSchedule.repeatEnds', errors)
            }
          />
          {!values.repeatEnds && (
            <FlexFormField value="" disabled>
              <RemoveButton
                extraOffset={values.frequency === 'MONTHLY'}
                icon="trash"
                onClick={passivate}
                data-id="removeRecurrent"
                disabled={disabled || isRecurringEventPartAlready}
              />
            </FlexFormField>
          )}
          {values.repeatEnds === 'OCCURRENCES' && (
            <FlexFormField
              name="recurrentEventSchedule.occurrences"
              onChange={handleOccurencesChange}
              value={values.occurrences || ''}
              placeholder={intl.formatMessage({ id: 'new_event.timeline.recurring.occurrences.placeholder' })}
              type="number"
              min={2}
              step={1}
              onBlur={handleBlur}
              disabled={disabled || isRecurringEventPartAlready}
              error={
                get('recurrentEventSchedule.occurrences', touched) && get('recurrentEventSchedule.occurrences', errors)
              }
            >
              <RemoveButton
                extraOffset={values.frequency === 'MONTHLY'}
                icon="trash"
                onClick={passivate}
                data-id="removeRecurrent"
                disabled={disabled || isRecurringEventPartAlready}
              />
            </FlexFormField>
          )}
          {values.repeatEnds === 'UNTIL' && (
            <FlexFormField
              name="recurrentEventSchedule.until"
              setFieldValue={setFieldValue}
              value={getOr('', 'until', values)}
              placeholder={intl.formatMessage({ id: 'new_event.timeline.recurring.repeatUntil.placeholder' })}
              control="datetime"
              timezone={event.timezoneName || undefined}
              locale={locale}
              mode="date"
              onBlur={handleBlur}
              disabled={disabled || isRecurringEventPartAlready}
              error={get('recurrentEventSchedule.until', touched) && get('recurrentEventSchedule.until', errors)}
            >
              <RemoveButton
                extraOffset={values.frequency === 'MONTHLY'}
                icon="trash"
                onClick={passivate}
                data-id="removeRecurrent"
                disabled={disabled || isRecurringEventPartAlready}
              />
            </FlexFormField>
          )}
        </RecurringEvent>
      </RecurringEventContainer>
      {schedule && schedule.length > 0 ? (
        <Schedule>
          {schedule.map((ev) => (
            <ScheduleItem key={ev.id || ev.date}>
              {ev.id && ev.id !== event.id ? (
                <Link to={`/events/${ev.id}`}>
                  {intl.formatDate(ev.date, {
                    ...DATETIME_FORMATS.MEDIUM,
                    timeZone: event.timezoneName || undefined,
                  })}
                </Link>
              ) : (
                intl.formatDate(ev.date, {
                  ...DATETIME_FORMATS.MEDIUM,
                  timeZone: event.timezoneName || undefined,
                })
              )}
            </ScheduleItem>
          ))}
        </Schedule>
      ) : (
        <Placeholder />
      )}
    </>
  ) : (
    <RecurringButton preset="secondary" onClick={activate} data-id="addRecurrent" disabled={disabled}>
      <Svg icon="calendar" />
      {intl.formatMessage({ id: 'new_event.timeline.recurring.schedule_button' })}
    </RecurringButton>
  )
}

export default memo(EventRecurring)
